import store from '../../store'
import {
    getResource,
    patchResource,
    deleteResource,
    postResource,
} from '../axiosService'
import { Location } from '../../models/locations'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    const locationBaseURL = `api/account/${accountId}/mileage_tracker/location`
    const allLocationURL = `api/account/${accountId}/mileage_tracker/location`
    return {
        locationBaseURL,
        allLocationURL
    }
}

export function getAllLocations() {
    const { allLocationURL } = getStoreData()
    return getResource(
        allLocationURL,
        undefined,
        {}
    )
}

export function getLocations(params: Location) {
    const { locationBaseURL } = getStoreData()
    return getResource(
        locationBaseURL,
        undefined,
        params
    )
}

export function locationDetail(
    locationId: string
) {
    const { locationBaseURL } = getStoreData()
    return getResource(
        `${locationBaseURL}/${locationId}`
    )
}

export function createLocation(
    params: Location
) {
    const { locationBaseURL } = getStoreData()
    return postResource(
        `${locationBaseURL}`,
        params
    )
}

export function updateLocation(
    locationId: string,
    params: Location
) {
    const { locationBaseURL } = getStoreData()
    return patchResource(
        `${locationBaseURL}/${locationId}`,
        params
    )
}

export function deleteLocation(
    locationId: string
) {
    const { locationBaseURL } = getStoreData()
    return deleteResource(
        `${locationBaseURL}/${locationId}`
    )
}
