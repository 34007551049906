import store from '../../../store'
import { getResource, patchResource, postResource, putResource } from '../../axiosService'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id
    const accountId = currentStore.appData.current_account_id

    return {
        businessId,
        accountId,
    }
}

const companyBaseURL = () => {
    const { businessId } = getStoreData()
    return `api/business/${businessId}/payroll_organizer/company`
}

const ADPPayrollBaseURL = () => {
    const { businessId } = getStoreData()
    return `api/business/${businessId}/adp/payroll_organizer`
}

export const getCompanyDetails = () => {
    let URL = ADPPayrollBaseURL()
    return getResource<any>(`${URL}/company`)
}

export function getOnboardingStepInfo(): Promise<any> {
    let URL = companyBaseURL()
    return getResource<any>(`${URL}/onboarding_steps_info`)
}

export function finishCompanyOnboarding(): Promise<unknown> {
    let URL = companyBaseURL()
    return putResource(`${URL}/finish_onboarding`, {})
}

export function updateBusinessInformation(data: any): Promise<unknown> {
    const adpURL = ADPPayrollBaseURL()
    let URL = `${adpURL}/company`
    if(data.companyCreated) {
        return putResource(`${URL}`, data);   
    }
    return postResource(`${URL}`, data)
}

export function markStepAsCompleted(params: any) {
    let URL = companyBaseURL()
    return putResource(
        `${URL}/mark_step_complete`,
        params
    )
}

// POST /api/business/:business_id/adp/payroll_organizer/company/accept_tos
export function acceptTOS(params: {
    "legalConfirmation" : boolean,
    "signature": string
}) {
    const adpURL = ADPPayrollBaseURL()
    return postResource(`${adpURL}/accept_tos`, params)
}


// POST /api/business/:business_id/adp/payroll_organizer/company/first_employee
export function createFirstEmployee(params: any) {
    const adpURL = ADPPayrollBaseURL()
    return postResource(`${adpURL}/first_employee`, params)
}


// POST /api/business/:business_id/adp/payroll_organizer/company/pay_schedule
export function createPayrollSchedule(params: any) {
    const adpURL = ADPPayrollBaseURL()
    return postResource(`${adpURL}/pay_schedule`, params)

}

export function companyOnBoardingStatus() {
    const adpURL = ADPPayrollBaseURL() 
    return getResource(`${adpURL}/onboarding_status`)
}

export function fetchTOSDetails(business_id: string) {
    return getResource(`/api/business/${business_id}/adp/payroll_organizer/tos`)
}

export function submitTOS(business_id: string, body: any) {
    return postResource(`/api/business/${business_id}/adp/payroll_organizer/tos`, body)
}

export function getPayrollBusiness() {
   return getResource(`/api/current/adp/payroll_organizer/business`)
}

export function updateProcessorToken(business_id: string, is_bank_change?: boolean) {
    const base_url = `/api/business/${business_id}/adp/payroll_organizer/link_plaid_token`;
    const url = is_bank_change ? `${base_url}?change_bank_account=true` : base_url;
    return patchResource(url, {});
}