import React, { useState, Fragment } from 'react';
import UiDialog from '../../../common/ui/UiDialog';
import {
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { Purpose } from '../../../../models/purposes';
import PurposeForm from '../forms/PurposeForm';
interface PurposeModalProps {
    open: boolean;
    handleClose: () => void;
    purpose?: Purpose;
    setSelectedPurpose?: React.Dispatch<Purpose | undefined>;
    loadPurposes?: () => void;
    formikRef?: any;
    purposeCallback?: (purpose: Purpose) => void;
}

const useStyles = makeStyles(() => ({
    root: {
        minHeight: '100%'
    }
}));

export default function PurposeModal({
    open,
    handleClose,
    loadPurposes,
    formikRef,
    purposeCallback,
    purpose
}: PurposeModalProps) {
    const styles = useStyles();
    const theme = useTheme();

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size="xs"
            hideTitleSection={true}
        >
            <div className={styles.root}>
                <PurposeForm
                    handleClose={handleClose}
                    loadPurposes={loadPurposes}
                    formikRef={formikRef}
                    purposeCallback={purposeCallback}
                    purpose={purpose}
                />
            </div>
        </UiDialog>
    );
}