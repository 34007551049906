import {
    Button,
    Divider,
    Grid,
    Hidden,
    makeStyles,
    Theme,
    Typography,
    Box,
    useTheme
} from '@material-ui/core'
import {  useEffect } from 'react'
import AddIcon from '@material-ui/icons/Add'
import OpenInNew from '@material-ui/icons/OpenInNew'
import { ApplicationStore } from '../../../../models'
import { connect, useDispatch } from 'react-redux'

import Loader from '../../../common/Loader'
import { showInfo } from '../../../../store/actions/feedback'

import BankGroupList from '../BankGroupList'
import BankGroup from './BankGroup'
import { initCategories } from '../../../../store/actions/categories'
import { commonStyles } from '../../../../styles/commonStyles'
import BankOfAmericaImg from '../../../../assets/images/bankOfAmerica.svg'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import UpgradePlanCard from '../../../common/UpgradePlanCard'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { useBankDetailsState } from '../provider/BankDetailsProvider'
import { hasPermission } from '../../../../services/aclService'

const styles = makeStyles((theme: Theme) => ({
    newBankButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    TextCenter: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    adContainer: {
        margin: theme.spacing(1, '0', 0),
    },
}))

function BankingPage(props: any) {
    const {
        bankGroups,
        activeBankGroupAccounts,
        selectedInstitution,
        activeBankGroup,
        loadBankAccounts,
        newBankAccount,
        showBanks
    } = useBankDetailsState()

    const classes = styles()
    const commonClasses = commonStyles()
    const dispatch = useDispatch()
    const { accessLevel, currentUser } = useCurrentStore();
    const { personal_account } = props.appData
    const BOOKKEEPING_PERMISSION = 'ALL_BOOKKEEPING'

    useEffect(() => {
        dispatch(initCategories())
    }, [dispatch])

    const BankControls = (
        <Grid container>
            <Hidden implementation="js" mdUp>
                <Typography variant="h6" className={commonClasses.mt16}>
                    Banking
                </Typography>
            </Hidden>
            {
                (
                    !accessLevel.platform &&
                    !hasPermission(BOOKKEEPING_PERMISSION, personal_account) &&
                    !currentUser.accountant_mode
                ) &&
                 <UpgradePlanCard>
                    You’ve reached the limit of bank account you can connect with your current plan. If you need to connect more accounts, upgrade your plan.
                </UpgradePlanCard>   
            }

            <Grid item container direction="row" alignItems="center" xs={12}>
                <Button
                    className={classes.newBankButton}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => newBankAccount()}
                    disabled={!props.accessLevel.platform && !hasPermission(BOOKKEEPING_PERMISSION, personal_account) && bankGroups.length > 0}
                >
                    {activeBankGroupAccounts.length
                        ? 'Add Account'
                        : 'Add Bank'}
                </Button>
            </Grid>
            <Hidden implementation="js" mdUp>
                <Divider className={classes.divider} variant="fullWidth" />
            </Hidden>
        </Grid>
    )

    return (
        <Grid
            container
            direction="row"
            style={{
                height: '100%',
            }}
        >
            {bankGroups.length  ? (
                <>
                    <Grid
                        container
                        item
                        md={4}
                        sm={12}
                        direction="column"
                        justify="space-between"
                        alignItems="flex-start"
                        alignContent="flex-start"
                        wrap="nowrap"
                        style={{
                            height: '100%',
                        }}
                    >
                        <Grid
                            style={{
                                overflow: 'auto',
                            }}
                            container
                            item
                        >
                            {BankControls}
                            <BankGroupList
                                institutions={bankGroups}
                                showBanks={showBanks}
                                selectedInstitution={selectedInstitution}
                            />
                        </Grid>
                        <Box>
                            <Grid
                                xs={12}
                                container
                                className={classes.adContainer}
                            >
                                <img
                                    src={BankOfAmericaImg}
                                    width="180"
                                    alt="BankOfAmerica"
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                container
                                className={classes.adContainer}
                            >
                                <Typography
                                    style={{
                                        fontSize: '0.625rem',
                                    }}
                                >
                                    Now is the time to learn more about small
                                    business banking with Bank of America. You
                                    could earn a $100 to $500 bonus with
                                    qualifying activities when you open a new
                                    small business checking account and credit
                                    card.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                xs={12}
                                className={classes.adContainer}
                            >
                                <Button
                                    className={classes.newBankButton}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<OpenInNew />}
                                    onClick={() =>
                                        window.open(
                                            'https://promotions.bankofamerica.com/smallbusiness/alliance/layoutj.formac9?cm_mmc=SB-General-_-1800Acct-_-StrategicAlliance-_-I800Acct-Dashboard'
                                        )
                                    }
                                >
                                    {'Learn More'}
                                </Button>
                            </Grid>
                            <Typography
                                style={{
                                    fontSize: '10px',
                                }}
                            >
                                *Terms and conditions apply
                            </Typography>
                        </Box>
                        {/* </Grid> */}
                    </Grid>

                    <Hidden smDown implementation="js">
                        <Grid
                            sm={1}
                            item
                            container
                            direction="row"
                            justify="center"
                            alignItems="stretch"
                        >
                            <Divider
                                orientation="vertical"
                                flexItem
                                style={{
                                    height: '100%',
                                }}
                            />
                        </Grid>

                        <Grid item md={7}>
                            {activeBankGroupAccounts.length ? (
                                <BankGroup
                                    activeBankGroup={activeBankGroup}
                                    reloadBankAccountGroups={loadBankAccounts}
                                />
                            ) : (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="subtitle1">
                                        No Bank selected
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Hidden>
                </>
            ) : (
                <Loader />
            )}
        </Grid>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    accessLevel: state.auth.accessLevel
})
export default connect(mapStateToProps, { showInfo })(BankingPage)
