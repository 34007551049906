import { useMediaQuery, useTheme } from '@material-ui/core'
import UiDialog from '../common/ui/UiDialog'
import { useContext, useEffect, useState } from 'react'
import { Box, Theme, makeStyles } from '@material-ui/core'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'

interface DeflectionModalProps {
    open: boolean
    handleClose: () => void
    firstName: string;
    lastName: string;
    email: string;
    schedulerTopic?: string;
    schedulerSubTopic?: string;
}

declare global {
    interface Window {
        embeddedservice_bootstrap: any
    }
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        position: 'absolute',
        right: '-.5rem',
        bottom: '-.5rem',
        width: '23rem',
        height: '45rem'
    }
}))

const DeflectionModal = ({
    open,
    handleClose,
    firstName,
    lastName,
    email,
    schedulerTopic,
    schedulerSubTopic
}: DeflectionModalProps) => {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const DRIFT_HIDE_CLASS = 'hideDriftWidget';

    const getChatControlElements = () => {
        const chatBox = document.querySelector('#drift-frame-controller');
        const chatWindow = document.querySelector('#drift-frame-chat');
        return { chatBox, chatWindow };
    }

    const hideDriftWidget = () => {
        const { chatBox, chatWindow } = getChatControlElements();
        chatBox?.classList.add(DRIFT_HIDE_CLASS);
        chatWindow?.classList.add(DRIFT_HIDE_CLASS);
    }

    const showDriftWidget = () => {
        const { chatBox, chatWindow } = getChatControlElements();
        chatBox?.classList.remove(DRIFT_HIDE_CLASS);
        chatWindow?.classList.remove(DRIFT_HIDE_CLASS);
    }

    useEffect(() => {
       
        const script = document.createElement('script')
        const sf_config = (window as any).ApiConfig;
        script.src = sf_config.sf_messaging_url_script;
        script.async = false;
        document.body.appendChild(script);
        script.addEventListener('load', function() {
            try {
                const div = document.getElementById("messagingDiv");
                window.embeddedservice_bootstrap.settings.language = 'en_US';
                window.embeddedservice_bootstrap.settings.targetElement = div;

                window.addEventListener("onEmbeddedMessagingReady", function (e) {
                    window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
                        "Scheduler_Topic" : schedulerSubTopic,
                        "Subject_Custom" : schedulerTopic
                    });
                    window.embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields({
                        "_firstName": {
                          "value": firstName,
                          "isEditableByEndUser": true
                        },
                        "_lastName": {
                            "value": lastName,
                            "isEditableByEndUser": true
                        },
                        "_email": {
                            "value": email,
                            "isEditableByEndUser": true
                        }
                    });
                    setTimeout(() => {
                        window.embeddedservice_bootstrap.utilAPI.launchChat();     
                    }, 100)

                    hideDriftWidget();
                });

                window.embeddedservice_bootstrap.init(
                    sf_config.sf_messaging_id,
                    sf_config.sf_messaging_user,
                    sf_config.sf_messaging_url,
                    {
                        scrt2URL: sf_config.sf_messaging_scrt_url
                    }
                );
                
            } catch (err) {
                console.error('Error loading Embedded Messaging: ', err);
            }
        });
    }, [])

    return (
        
        <UiDialog
            open={open}
            handleClose={() => {
                showDriftWidget();
                handleClose();
            }}
            title="How can we help?"
            size="sm"
            customDialogStyles={
                { paper: classes.container }
            }
        >
            <div id="messagingDiv" ></div>

        </UiDialog>
    )
}

export default DeflectionModal