import {
    Box,
    Typography,
    CircularProgress,
    Theme,
    Chip,
} from '@material-ui/core';
import {
    ArrowDropDown,
    ArrowRight,
    Folder as FolderIcon,
    FolderOpen,
    LockOutlined,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { Folder, FolderTree } from '../../models';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { setInLocalStorage } from '../../services/localStorageService';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

export default function DocumentsTree({
    folders,
    hideEmptyFolders,
    businessFolderTree,
    personalFolderTree,
    setUploadableFolderTree
}: {
    folders: Folder[];
    hideEmptyFolders: boolean;
    businessFolderTree: FolderTree | undefined;
    personalFolderTree: FolderTree | undefined;
    setUploadableFolderTree: React.Dispatch<FolderTree | undefined>
}) {
    const { colorTheme } = useThemeContext()

    const styles = makeStyles((theme: Theme) => ({
        treeIconContainer: {
            width: 48,
            color: '#12172480', // to do
            '& svg': {
                fontSize: 24,
            },
        },
        treeItemContent: {
            height: 40,
        },
        treeItemLabel: {
            background: 'transparent !important',
            '&:hover': {
                background: 'transparent',
            },
        },
        notification: {
            height: '20px !important',
            color: `${colorTheme.primaryWhite} !important`,
            backgroundColor: theme.palette.primary.main + ' !important',
            fontWeight: 'bold',
            cursor: 'pointer !important',
        },
        notificationLabel: {
            padding: '3px 10px !important',
        },
    }))();

    const [businessExpanded, setBusinessExpanded] = useState<string[]>([]);
    const [personalExpanded, setPersonalExpanded] = useState<string[]>([]);
    const history = useHistory();

    const handleOpenFolder = (folder: Folder & FolderTree) => {
        setInLocalStorage('selectedFolder', {
            path: folder.path,
            displayPath: folder.pathToDisplay,
        });
        setUploadableFolderTree(folder.is_uploadable ? folder : undefined)
        history.push(
            `/documents/folder?folder=${encodeURIComponent(
                folder.path
            )}&accountId=${folder.portal_account_id}`
        );
    };

    const getTreeItem = (folder: Folder & FolderTree, expanded: string[]) => {
        const treeItem = (
            <TreeItem
                classes={{
                    iconContainer: styles.treeIconContainer,
                    content: styles.treeItemContent,
                    label: styles.treeItemLabel,
                }}
                label={
                    <Box
                        display='flex'
                        alignItems='center'
                        onClick={() =>
                            (folder.is_uploadable || !folder.folders) && handleOpenFolder(folder)
                        }
                    >
                        <Typography variant='body2'>{folder.title}</Typography>
                        {!expanded.includes(folder.path) && (
                            <Box mx={1}>
                                <Typography
                                    variant='caption'
                                    color='textSecondary'
                                >
                                    ({folder.files_count})
                                </Typography>
                            </Box>
                        )}

                        {!expanded.includes(folder.path) &&
                        folder.unread_files_count ? (
                            <Chip
                                label={folder.unread_files_count}
                                size='small'
                                classes={{
                                    root: styles.notification,
                                    labelSmall: styles.notificationLabel,
                                }}
                            />
                        ) : null}
                    </Box>
                }
                nodeId={folder.path}
                key={folder.path}
                icon={!folder.folders && <FolderIcon color='inherit' />}
            >
                {folder.folders &&
                    Object.keys(folder.folders).map(
                        (key) =>
                            folder.folders &&
                            getTreeItem(folder.folders[key], expanded)
                    )}
            </TreeItem>
        );
        return hideEmptyFolders ? !!folder.files_count && treeItem : treeItem;
    };

    /**
     * Opens folder with respective folder query if openable folder contains documents 
     * and sub folder (i.e is_uploadable)
     * @param tree {BusinessFolderTree | PersonalFolderTree}
     * @param nodes [] paths array for recently opened tree node[]
     */
    const handleNodeToggle = (tree: (FolderTree | undefined) , nodes: string[]) => {
        if(nodes.length > 0) {
            let folder = tree?.[nodes[0]] // nodes[0] contains recently accessed folder path
            folder.is_uploadable && handleOpenFolder(folder)
        }
    }
    return (
        <div>
            {!folders.length ? (
                <Box width='100%' textAlign='center' my={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <div>
                    <Box>
                        <Typography variant='h6' color='secondary'>
                            Business
                        </Typography>
                    </Box>

                    <TreeView
                        onNodeToggle={(e: any, nodes) => {
                            handleNodeToggle(businessFolderTree, nodes)
                            setBusinessExpanded(nodes)
                        }}
                        defaultCollapseIcon={
                            <Box
                                display='flex'
                                alignItems='center'
                                color={colorTheme.grey1700}
                            >
                                <ArrowDropDown
                                    color='inherit'
                                    fontSize='large'
                                />
                                <FolderOpen color='inherit' fontSize='large' />
                            </Box>
                        }
                        defaultExpandIcon={
                            <Box
                                display='flex'
                                alignItems='center'
                                color={colorTheme.grey1800}
                            >
                                <ArrowRight color='inherit' fontSize='large' />
                                <FolderIcon color='inherit' fontSize='large' />
                            </Box>
                        }
                    >
                        {businessFolderTree?.folders &&
                            Object.keys(businessFolderTree.folders).map(
                                (key) =>
                                    businessFolderTree.folders &&
                                    getTreeItem(
                                        businessFolderTree.folders[key],
                                        businessExpanded
                                    )
                            )}
                    </TreeView>

                    <Box mt={2}>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='h6' color='secondary'>
                                Private
                            </Typography>
                            <Box ml={2}>
                                <LockOutlined fontSize='small' />
                            </Box>
                        </Box>
                        <Typography variant='caption'>
                            Documents in this section are only visible to you
                        </Typography>

                        <TreeView
                            onNodeToggle={(e: any, nodes: any[]) => {
                                handleNodeToggle(personalFolderTree, nodes)
                                setPersonalExpanded(nodes)
                            }}
                            defaultCollapseIcon={
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    color={colorTheme.grey1700}
                                >
                                    <ArrowDropDown
                                        color='inherit'
                                        fontSize='large'
                                    />
                                    <FolderOpen
                                        color='inherit'
                                        fontSize='large'
                                    />
                                </Box>
                            }
                            defaultExpandIcon={
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    color={colorTheme.grey1800}
                                >
                                    <ArrowRight
                                        color='inherit'
                                        fontSize='large'
                                    />
                                    <FolderIcon
                                        color='inherit'
                                        fontSize='large'
                                    />
                                </Box>
                            }
                        >
                            {personalFolderTree?.folders &&
                                Object.keys(personalFolderTree.folders).map(
                                    (key) =>
                                        personalFolderTree.folders &&
                                        getTreeItem(
                                            personalFolderTree.folders[key],
                                            personalExpanded
                                        )
                                )}
                        </TreeView>
                    </Box>
                </div>
            )}
        </div>
    );
}
