import { useCallback, useEffect } from 'react';
import Drift from 'react-driftjs';
import { useLocation } from 'react-router-dom';

const MESSAGE_CENTER_ROUTE = '/message_center'
const DRIFT_HIDE_CLASS = 'hideDriftWidget'

export default function DriftWidget() {
    const location = useLocation();

    const getChatControlElements = () => {
        const chatBox = document.querySelector('#drift-frame-controller');
        const chatWindow = document.querySelector('#drift-frame-chat');
        return { chatBox, chatWindow };
    }

    const hideDriftWidget = useCallback(() => {
        const { chatBox, chatWindow } = getChatControlElements();
        chatBox?.classList.add(DRIFT_HIDE_CLASS);
        chatWindow?.classList.add(DRIFT_HIDE_CLASS);
    }, [])

    const showDriftWidget = useCallback(() => {
        const { chatBox, chatWindow } = getChatControlElements();
        chatBox?.classList.remove(DRIFT_HIDE_CLASS);
        chatWindow?.classList.remove(DRIFT_HIDE_CLASS);
    }, [])

    const driftWidgetCallback = useCallback(() => {
        if (window.location.href?.includes(MESSAGE_CENTER_ROUTE)) {
            hideDriftWidget();
        } else {
            showDriftWidget();
        }
    }, [hideDriftWidget, showDriftWidget])

    const observeDriftWidget = useCallback(() => {
        const options = {
            root: document.querySelector('body'),
            rootMargin: '0px',
            threshold: 1.0,
        }
        const observer = new IntersectionObserver(driftWidgetCallback, options);
        const target = document.querySelector('#drift-frame-controller');
        target && observer.observe(target);
    }, [driftWidgetCallback])

    useEffect(() => {
        observeDriftWidget(); 
    }, [location, observeDriftWidget])

    return (
        <Drift appId={(window as any).ApiConfig.drift_app_id} />
    )
}
 