import React, { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import Loader from '../../common/Loader'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import ComponentMobileHeader from '../../common/ComponentMobileHeader'
import RequestInputDialog from '../../payroll/employees/RequestInputDialog'
import UiText from '../../common/ui/UiText'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { getCurrentEmployee } from '../../../services/apiService/adp-payroll/employee'
import {
    AssociateLegalAddress,
    Employee,
} from '../../../models/adp-payroll/employee'
import PersonalDetails from './Details/PersonalDetails'
import JobDetailsView from './Details/JobDetailsView'
import AddressDetailsView from './Details/AddressDetailsView'
import BankDetailsView from './Details/BankDetailsView'
import EditEmployeeModal from './EditEmployeeModal'
import OwnershipDetails from './Details/OwnershipDetails'
import MobileHeader from '../../common/MobileHeader'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { COLORS } from '../../../variables/colors'

const useStyles = makeStyles((theme) => ({
    listView: {
        marginTop: '30px',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    },
    sectionMargin: {
        marginTop: theme.spacing(6),
    },
    editMobileButton: {
        color: `${COLORS.blue200} !important`,
        marginTop: '5px',
        paddingTop: 4,
        paddingBottom: 4,
    },
}))

interface EmployeeDetailsProps {
    selectedData?: Employee
    reloadState?: () => void
    setSelectedData?: React.Dispatch<React.SetStateAction<Employee | undefined>>
}

const ADPEmployeeDetails = ({
    selectedData,
    setSelectedData,
    reloadState,
}: EmployeeDetailsProps) => {
    const history = useHistory()
    const params: { id: string } = useParams()
    const { appData } = useCurrentStore()
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [openRequestDialog, setOpenRequestDialog] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const businessId = appData?.current_business_id
    const classes = useStyles()
    const styles = commonStyles()
    const isBirthdateMissing = !!selectedEmployee?.birthDate

    const fetchCurrentEmployee = (id: string) => {
        setLoading(true)
        getCurrentEmployee(id)
            .then((employee: any) => {
                if (employee) {
                    setSelectedEmployee(employee)
                    setSelectedData?.(employee)
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setSelectedEmployee(selectedData as Employee)
    }, [selectedData])

    useEffect(() => {
        if (params?.id) {
            fetchCurrentEmployee(params?.id)
        } 
    }, [params?.id])

    if (loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '100%' }}
            >
                <Loader />
            </Grid>
        )
    }
    return (
        <div className={isSmallDevice ? '' : classes.listView}>
            <MobileHeader showBottomDivider>
                    <div
                        className={mergeStyle(
                            styles.flex,
                            styles.spaceBetween
                        )}
                    >
                        <IconButton
                            onClick={() => history.goBack()}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        {!editLoading && <Button
                            variant="text"
                            classes={{ root: classes.editMobileButton }}
                            onClick={() => {
                                setEditLoading(true);
                                setOpenModal(true);
                            }}
                        >
                            Edit
                        </Button>}
                        {editLoading && <Loader />}
                    </div>
            </MobileHeader>
            <RequestInputDialog
                businessId={businessId}
                openDialog={openRequestDialog}
                setOpenDialog={setOpenRequestDialog}
                employeeId={selectedEmployee?.associateID}
            />
            {/* <ADPEmployeeFormModal
                openModal={openModal}
                handleClose={() => {
                    setOpenModal(false)
                }}
                formSubmitCallback={() => {
                    setOpenModal(false)
                    reloadState?.()
                    fetchCurrentEmployee()
                }}
                isEdit={false}
            /> */}
            <EditEmployeeModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                setEditLoading={setEditLoading}
                refreshData={fetchCurrentEmployee}
                selectedData={selectedEmployee}
                setSelectedData={setSelectedData || setSelectedEmployee}
                reloadState={reloadState}
            />
            <div className={styles.mt24}>
                <UiText variant="suv_150">
                    {selectedEmployee?.legalName?.firstName}{' '}
                    {selectedEmployee?.legalName?.lastName}
                </UiText>
            </div>
            <Button
                startIcon={<MailOutlineIcon />}
                variant="outlined"
                color="secondary"
                className={styles.mt24}
                disabled={!isBirthdateMissing}
                onClick={() => {
                    setOpenRequestDialog(true)
                }}
            >
                Request Employee Input
            </Button>
            <div className={classes.sectionMargin}>
                <PersonalDetails
                    selectedEmployee={selectedEmployee}
                    setOpenModal={setOpenModal}
                    editLoading={editLoading}
                />
            </div>
            <div className={classes.sectionMargin}>
                <JobDetailsView
                    selectedEmployee={selectedEmployee}
                    setOpenModal={setOpenModal}
                />
            </div>
            <div className={classes.sectionMargin}>
                <OwnershipDetails selectedEmployee={selectedEmployee} />
            </div>
            <div className={classes.sectionMargin}>
                <AddressDetailsView
                    address={
                        selectedEmployee?.associateLegalAddress as AssociateLegalAddress
                    }
                    setOpenRequestDialog={setOpenRequestDialog}
                />
            </div>
            <div className={classes.sectionMargin}>
                <BankDetailsView
                    employee={selectedEmployee as Employee}
                    setOpenRequestDialog={setOpenRequestDialog}
                />
            </div>
        </div>
    )
}

export default ADPEmployeeDetails
