import { useEffect, useState, CSSProperties } from 'react';
import { Grid } from '@material-ui/core'
import EntitySpecialistSection from './EntitySpecialistSection'
import RegisteredAgentSection from './RegisteredAgentSection'
import BusinessDetailsSection from './business-details/BusinessDetailsSection'
import SpecialInstructions from './SpecialInstructions'
import RequiredSteps from './required-steps/RequiredSteps'
import RecommendedSteps from './recommended-steps/RecommendedSteps'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function EntityTab() {
    const theme = useTheme();
    const screenIsMdMax = useMediaQuery(theme.breakpoints.up('md'));
    const [scrollableHeight, setScrollableHeight] = useState(window.innerHeight - 273);
    useEffect(() => {
        window.addEventListener('resize', event => setScrollableHeight(window.innerHeight - 273));
    }, [scrollableHeight])

    const scrollStyles: CSSProperties = {
        height: scrollableHeight, 
        overflowY: 'auto', 
        paddingTop: 0, 
        paddingBottom: 0
    }

    return (
        <Grid container spacing={2}>
            <Grid
                item
                md={4}
                xs={12}
                style={screenIsMdMax ? scrollStyles : {}}
            >
                <EntitySpecialistSection />
                <RegisteredAgentSection />
                <BusinessDetailsSection />
            </Grid>
            <Grid
                item
                md={8}
                xs={12}
                style={screenIsMdMax ? scrollStyles : {}}
            >
                <SpecialInstructions />
                <RequiredSteps />
                <RecommendedSteps />
            </Grid>
        </Grid>
    )
}
