import { CircularProgress } from "@material-ui/core";
import { useStyles } from "../../styles/styles";
import { useThemeContext } from './whiteLabel/ColorThemeContext';

export default function LoaderPage() {
  const { colorTheme } = useThemeContext()
  const classes = useStyles(colorTheme);
  return (
    <div className={classes.loaderPageDiv}>
      <CircularProgress style={{ color: colorTheme.tertiary }}/>
    </div>
  );
}