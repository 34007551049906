import { useEffect } from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import TOSFlowHeader from './TOSFlowHeader'
import UiText from '../../../common/ui/UiText'
import Monocle from '../../../../assets/animations/Monocle.json'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { usePayrollMigrationState } from '../../Providers/PayrollMigrationProvider'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
  bankVerificationSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
    bankBtn: {
        width: '22.5rem',
        boxSizing: 'border-box',
        background: (colorTheme) => colorTheme.primary,
        padding: '0.563rem 1rem',
        color: (colorTheme) => colorTheme.primaryWhite,
        marginTop: '1.5rem',
        '&:hover': {
            background: (colorTheme) => colorTheme.primary,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
        '&:disabled': {
            opacity: 0.25,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
    },
    changeBankBtn: {
      padding: '0.5rem',
      color: (colorTheme) => colorTheme.blue200,
      marginTop: '1.5rem',
      marginBottom: '1rem',
  },
}))

const BankVerificationSection = () => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useStyles(colorTheme)
    const {
        bankInfo,
        openUpdateMode,
        updateTokenReady,
        disableBankVerifyButton,
        fetchUpdateModeToken,
        openPlaidLink,
        linkToken
    } = usePayrollMigrationState()


    useEffect(() => {
        fetchUpdateModeToken()
    }, [])

    return (
        <div className={classes.bankVerificationSection}>
            <TOSFlowHeader icon={Monocle} headerText="Enter the 3-letter code">
                <UiText>
                    Look for a <b>$0.01 deposit</b> in your account ending in
                    ••••{bankInfo?.mask}. Your code is the first 3 letters
                    after the # symbol.
                </UiText>
            </TOSFlowHeader>
            <div>
                <Button
                    onClick={() => openUpdateMode()}
                    className={classes.bankBtn}
                >
                    <UiText weight="semi_bold_600">Verify Bank Account</UiText>
                </Button>
            </div>
            <div>
                {linkToken && (
                    <Button
                        variant="text"
                        className={classes.changeBankBtn}
                        onClick={() => openPlaidLink()}
                    >
                        <UiText variant="motorcycle_90" weight="bold_700">
                            Change Bank Account
                        </UiText>
                    </Button>
                )}
            </div>
        </div>
    )
}

export default BankVerificationSection