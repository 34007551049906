import { Link, makeStyles, Theme } from '@material-ui/core'
import Icon from '../../../common/Icon'
import UiText from '../../../common/ui/UiText'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    alertContainer: {
        display: 'flex',
        border: colorTheme => `1px solid ${colorTheme.red200}`,
        gap: '1rem',
        alignItems: 'flex-start',
        borderRadius: '0.25rem',
        color: colorTheme => colorTheme.black100,
        boxSizing: 'border-box',
        padding: '0.5rem 1rem',
    },
    supportText: {
        color: colorTheme => colorTheme.blue200,
        cursor: 'pointer',
    },
}))

type error400Type = {
    is400Error?: boolean | undefined
    error400String?: string | undefined
}

const Form8879ErrorAlert = ({
    is400Error,
    error400String,
}: error400Type) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    return (
        <div className={classes.alertContainer}>
            <Icon icon="alert" />
            <div>
                <UiText textColor="inherit" variant="motorcycle_90">
                    {
                        is400Error 
                            ? error400String 
                            : 'Something went wrong on our end while e-filing your tax return. Please try again in a few moments. If this issue keeps occurring,'
                    }
                </UiText>
                <div>
                    <Link href="tel:1-800-222-6868">
                        <UiText
                            variant="motorcycle_90"
                            weight="medium_500"
                            className={classes.supportText}
                        >
                            {' '}
                            Contact Support.
                        </UiText>
                    </Link>{' '}
                </div>
            </div>
        </div>
    )
}

export default Form8879ErrorAlert
