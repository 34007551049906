import React, { useState } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import UiText from '../../common/ui/UiText'
import { commonStyles } from '../../../styles/commonStyles'
import { sendEmailRequestForInput } from '../../../services/apiService/payroll/employee'

const useStyles = makeStyles(() => ({
    container: {
        "& .MuiPaper-root": {
            maxWidth: '480px !important',
        }
    },
}))

const RequestInputDialog = ({
    openDialog,
    setOpenDialog,
    businessId,
    employeeId,
}: {
    openDialog: boolean
    setOpenDialog: React.Dispatch<boolean>
    businessId?: string
    employeeId?: string
}) => {
    const [loading, setLoading] = useState(false)
    const styles = commonStyles()
    const classes = useStyles()

    const requestEmployeeInput = () => {
        setLoading(true)
        sendEmailRequestForInput(businessId as string, employeeId as string)
            .then((response) => {
                if (response) {
                    setLoading(false)
                    setOpenDialog(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }
    return (
        <UiConfirmationDialog
            open={openDialog}
            customRootClass={classes.container}
            message={
                <>
                    <Typography variant="body1" gutterBottom>
                        <Box my={2}>
                            <UiText
                                weight="semi_bold_600"
                                variant="hatchback_125"
                            >
                                Request Employee Input
                            </UiText>
                        </Box>
                        <Box my={2}>
                            We’ll send an email with a link allowing the
                            employee to complete their portion of the payroll
                            setup process.
                            <div className={styles.mt16}>
                                The emailed link will automatically expire in 4
                                days.
                            </div>
                            <div className={styles.mt16}>
                                If the link expires, or the user has 3 failed
                                attempts to enter the correct birthday, you will
                                need to send a new request.
                            </div>
                        </Box>
                    </Typography>
                </>
            }
            handleClose={() => {
                setOpenDialog(false)
            }}
            showLoader={loading}
            confirmNode={
                <Button
                    startIcon={<MailOutlineIcon />}
                    variant="contained"
                    color="primary"
                    onClick={requestEmployeeInput}
                >
                    {' '}
                    Send Email
                </Button>
            }
            cancelButtonText="Cancel"
        />
    )
}

export default RequestInputDialog
