import React from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles, Theme, ListItemText } from '@material-ui/core'
import ListElement from '../../common/ListElement'

import { ProductDetail } from '../../../models'
import { trimText } from '../../../utils/utility'

const useStyles = makeStyles((theme: Theme) => {
    return {
        emptyDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        rightItemText: {
            textTransform: 'capitalize',
            textAlign: 'right',
        },
        listItemIconRootStyle: {
            minWidth: '40px',
        },
        listItemGutterStyle: {
            paddingLeft: '5px',
            paddingRight: '8px'
        }
    }
})

export default function ProductItem({
    product,
    selectedProduct,
    setSelectedProduct,
    categories,
    handleCheck,
    checkedProducts,
    lastItem
}: {
    productId: string
    product: ProductDetail
    selectedProduct: ProductDetail | undefined
    setSelectedProduct: React.Dispatch<ProductDetail | undefined>
    categories: any
    handleCheck: (checked: boolean, product: any) => void
    checkedProducts: string[]
    lastItem: boolean
}) {
    const styles = useStyles()
    const history = useHistory()
    const mDash = () => {
        return <span className={styles.emptyDiv}>—</span>
    }
    let productCategory = categories?.[product.category]
    return (
        <ListElement
            handleItemClick={() => {
                setSelectedProduct(product)
                history.push(`/products/${product.id}`)
            }}
            listItemIconRootStyle={styles.listItemIconRootStyle}
            listItemGutterStyle={styles.listItemGutterStyle}
            renderCheckbox
            isItemSelected={product.id === selectedProduct?.id}
            handleCheck={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleCheck(e.target.checked, product)
            }}
            checkedItem={checkedProducts.includes(product.id)}
            primaryNode={
                <ListItemText
                    primary={
                        product?.title ? trimText(product?.title, 15) : mDash()
                    }
                    secondary={product?.type}
                />
            }
            secondaryNode={
                <ListItemText
                    className={styles.rightItemText}
                    primary={`$${product.price}`}
                    secondary={`${productCategory ? productCategory : ''}`}
                />
            }
            lastItem={lastItem}
        />
    )
}