import ContentLoader from 'react-content-loader';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

const WidgetSkeletonLoader = ({
    justifyContent = 'center',
    alignItems = 'center',
}: {
    justifyContent?:
        | 'flex-start'
        | 'center'
        | 'space-between'
        | 'space-evenly'
        | 'space-around'
        | 'flex-end';
    alignItems?: 'flex-start' | 'center' | 'flex-end';
}) => {
    const { colorTheme } = useThemeContext()
    return(
    <div
        style={{
            display: 'flex',
            justifyContent,
            alignItems,
            height: '100%',
            width: '100%',
        }}
    >
        <ContentLoader
            speed={2}
            width={'100%'}
            viewBox='0 0 400 160'
            backgroundColor={colorTheme.grey2200}
            foregroundColor={colorTheme.grey300}
        >
            <rect x='0' y='0' rx='3' ry='3' width='25%' height='28' />
            <rect x='0' y='40' rx='3' ry='3' width='12%' height='16' />

            <rect x='0' y='72' rx='3' ry='3' width='100%' height='8' />
            <rect x='0' y='88' rx='3' ry='3' width='30%' height='16' />
            <rect x='85%' y='88' rx='3' ry='3' width='15%' height='16' />
            
            <rect x='0' y='120' rx='3' ry='3' width='100%' height='8' />
            <rect x='0' y='136' rx='3' ry='3' width='30%' height='16' />
            <rect x='85%' y='136' rx='3' ry='3' width='15%' height='16' />
        </ContentLoader>
    </div>
    )
}

export default WidgetSkeletonLoader;
