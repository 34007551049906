const arraybufferToUri = function (response: any) {
  let TYPED_ARRAY = new Uint8Array(response.data);
  const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    return data + String.fromCharCode(byte);
  }, '');
  const b64Response = btoa(STRING_CHAR);
  return `data:${response.headers['content-type']};base64,${b64Response}`;
};

const expenseCaterories = (allCategories: any) => {

  let expenseCaterory = allCategories.filter((item: any) => item.level === 1 && item.abbr === 'expenses');
  //get the childs
  let result = { parent: {} as any, childs: {} as any };
  if (expenseCaterory.length > 0) {
    result.parent[expenseCaterory[0].id] = expenseCaterory[0];
    result.childs[expenseCaterory[0].id] = expenseCaterory[0];
    let parentChild = getParentChild(allCategories, expenseCaterory[0].id);

    parentChild.parent.forEach((categoryItem: any) => {
      //next level
      result.parent[categoryItem.id] = categoryItem;
      getParentChildNested(allCategories, result, categoryItem);
    });
    parentChild.child.forEach((categoryItem: any) => { 
      result.childs[categoryItem.id] = categoryItem;
      getParentChildNested(allCategories, result, categoryItem);
    });
  }
  return result;
};

const getParentChildNested = (allCategories:any, resultSet: any, item: any) => {
  let nested: any = getParentChild(allCategories, item.id);
  nested.child.forEach((categoryItem: any) => { 
    if(categoryItem) {
      resultSet.childs[categoryItem.id] = categoryItem; 
      getParentChildNested(allCategories, resultSet, categoryItem);
    }
  });
  nested.parent.forEach((categoryItem: any) => { 
    if(categoryItem) {
      resultSet.parent[categoryItem.id] = categoryItem;
      getParentChildNested(allCategories, resultSet, categoryItem);
    }
  });
}
const getParentChild = (data: any, parentId: any) => {

  // eslint-disable-next-line array-callback-return
  let parent = data.filter((item: any) => {
    if (item.parent === parentId && item.can_have_children) {
      return item;
    }
  }
  );
  let child = data.filter((item: any) => item.parent === parentId);
  return { parent, child };
}
export { arraybufferToUri, expenseCaterories };

export const trimText = (text = '', characterLength: number) => {
  return text.length > characterLength
    ? `${text.substring(0, characterLength).trim()}...`
    : text
}; 

const DRIFT_HIDE_CLASS = 'hideDriftWidget'

const getChatControlElements = () => {
  const chatBox = document.querySelector('#drift-frame-controller');
  const chatWindow = document.querySelector('#drift-frame-chat');
  return { chatBox, chatWindow };
}

export const hideDriftWidget = () => {
  const { chatBox, chatWindow } = getChatControlElements();
  chatBox?.classList?.add(DRIFT_HIDE_CLASS);
  chatWindow?.classList?.add(DRIFT_HIDE_CLASS);
}

export const showDriftWidget = () => {
  const { chatBox, chatWindow } = getChatControlElements();
  chatBox?.classList?.remove(DRIFT_HIDE_CLASS);
  chatWindow?.classList?.remove(DRIFT_HIDE_CLASS);
}