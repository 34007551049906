import { List, ListItem, makeStyles, Typography } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import { useEffect, useState } from "react";
import UiSection from "../../common/ui/UiSection";
import ObtainEINPopup from "../entity-tab/recommended-steps/ObtainEINPopup";
import ScheduleTaxSavingsAnalysisPopup from "../entity-tab/recommended-steps/ScheduleTaxSavingsAnalysisPopup";
import SubmitAnnualReportPopup from "../entity-tab/required-steps/SubmitAnnualReportPopup";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ApplicationStore } from "../../../models";
import { getEinData } from "../../../services/apiService";
import ListItemContent from "../common/ListItemContent";
import moment from "moment";
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
interface productsTabProps {
    annualReportDescription: string;
    annualReportDueDate: number;
    formationState: string;
    history: any;
    businessEin: string;
    accountId: string;
}

function ProductsTab({
    annualReportDescription,
    annualReportDueDate,
    formationState,
    history,
    businessEin,
    accountId,
}: productsTabProps) {
    const { colorTheme } = useThemeContext()
    const [openAnnualReportPopup, setOpenAnnualReportPopup] = useState(false);
    const handleSubmitAnnualPopupClose = (result: any) => {
        setOpenAnnualReportPopup(false);
    };
    const annualReportDate: any = annualReportDueDate && {
        date: moment(annualReportDueDate * 1000).format("MMM DD, YYYY"),
        diff: moment(new Date(annualReportDueDate * 1000)).diff(
            moment(),
            "days"
        ),
    };
    const statesNotAllowedForAnnualReport = ["AZ", "MO", "NM", "SC", "OH"];

    const styles = makeStyles({
        listHeading: {
            fontWeight: 500,
        },
        listItem: {
            paddingBottom: 10,
            paddingTop: 10,
            "&:first-child": {
                paddingTop: 0,
            },
            "&:last-child": {
                paddingBottom: 0,
            },
        },
        primaryColor: {
            color: colorTheme.primaryGreen,
        },
        orangeColor: {
            color: colorTheme.primary,
        },
        redColor: {
            color: colorTheme.red100,
        },
        iconMargin: {
            marginRight: 5,
        },
    })();

    const [einModalState, setEinModalState] = useState(false);
    const [einAlreadyApplied, setEinAlreadyApplied] = useState(true); // kept true to hide ein step initially
    const [taxSavingsModalState, setTaxSavingsModalState] = useState(false);

    const handleEinModal = (
        state: { modalState: boolean; setEIN?: boolean | undefined } = {
            modalState: false,
        }
    ) => {
        if (state && state.setEIN) {
            history.push("/settings/business?edit=true");
        }
        setEinModalState(state.modalState);
    };

    const handleTaxSavingsModal = (state: boolean) => {
        setTaxSavingsModalState(state);
    };

    useEffect(() => {
        getEinData(accountId).then((res) => {
            setEinAlreadyApplied(!!res);
        });
    }, [accountId]);

    return (
        <UiSection expanded={true} sectionHeading="All Products">
            {statesNotAllowedForAnnualReport.includes(formationState) ? (
                <ListItem
                    disableGutters
                    className={styles.listItem}
                    divider={true}
                >
                    <Typography
                        variant="body1"
                        align="center"
                        style={{ width: "100%" }}
                    >
                        No Required Steps
                    </Typography>
                </ListItem>
            ) : (
                <ListItem
                    disableGutters
                    className={styles.listItem}
                    divider={true}
                >
                    <div className="w-100">
                        <ListItemContent
                            contentHeader={
                                <>
                                    Submit Annual Report
                                    {annualReportDueDate && (
                                        <div className="d-flex align-items-center">
                                            <Typography
                                                component="span"
                                                className={`d-flex align-items-center ${styles.listHeading}`}
                                                classes={{
                                                    root:
                                                        annualReportDate.diff ===
                                                        0
                                                            ? styles.orangeColor
                                                            : annualReportDate.diff >
                                                              0
                                                            ? styles.primaryColor
                                                            : styles.redColor,
                                                }}
                                            >
                                                <CalendarToday
                                                    fontSize="small"
                                                    className={
                                                        styles.iconMargin
                                                    }
                                                />{" "}
                                                Due{" "}
                                                {annualReportDate.diff === 0
                                                    ? "Today"
                                                    : annualReportDate.date}
                                            </Typography>
                                            &nbsp;
                                            <Typography color="textSecondary">
                                                {annualReportDate.diff < 0
                                                    ? "(Overdue)"
                                                    : annualReportDate.diff > 0
                                                    ? `(in ${
                                                          annualReportDate.diff
                                                      } day${
                                                          annualReportDate.diff !==
                                                          1
                                                              ? "s"
                                                              : ""
                                                      })`
                                                    : ""}
                                            </Typography>
                                        </div>
                                    )}
                                </>
                            }
                            contentSecondaryText={annualReportDescription}
                            buttonText={"Schedule"}
                            clickEvent={() => setOpenAnnualReportPopup(true)}
                        />
                    </div>
                </ListItem>
            )}
            <List>
                <ListItem
                    disableGutters
                    className={styles.listItem}
                    divider={!businessEin && !einAlreadyApplied}
                >
                    <ListItemContent
                        contentHeader={"Free Tax Savings Analysis"}
                        contentSecondaryText={
                            "Maximizing tax deductions puts more money in \
                            your pocket. In fact, our clients received \
                            double the average federal refund last year. \
                            We’ll show you the savings you qualify for."
                        }
                        buttonText={"Schedule"}
                        clickEvent={() => handleTaxSavingsModal(true)}
                    />
                </ListItem>
                {!businessEin && !einAlreadyApplied && (
                    <ListItem disableGutters className={styles.listItem}>
                        <ListItemContent
                            contentHeader={
                                "Obtain Your Employer IdentificationNumber"
                            }
                            contentSecondaryText={
                                "An Employer Identification Number is \
                                needed for various compliance \
                                requirements such as but not limited to: \
                                filing your business tax return, opening \
                                a business bank account, and hiring \
                                employees."
                            }
                            disableButton={!annualReportDueDate}
                            buttonText={annualReportDueDate
                                ? 'Resolve'
                                : 'Pending Formation'}
                            clickEvent={() =>
                                handleEinModal({ modalState: true })
                            }
                        />
                    </ListItem>
                )}
            </List>
            <ObtainEINPopup open={einModalState} handleClose={handleEinModal} />
            <ScheduleTaxSavingsAnalysisPopup
                open={taxSavingsModalState}
                handleClose={handleTaxSavingsModal}
            />
            <SubmitAnnualReportPopup
                open={openAnnualReportPopup}
                handleClose={(result: any) =>
                    handleSubmitAnnualPopupClose(result)
                }
            />
        </UiSection>
    );
}

const mapStateToProps = ({ appData, entityManagement }: ApplicationStore) => ({
    businessEin: appData.current_business.ein || "",
    accountId: appData.current_account_id,
    annualReportDescription:
        entityManagement.entityTab &&
        entityManagement.entityTab.annualReportDescription,
    annualReportDueDate:
        entityManagement.entityTab &&
        entityManagement.entityTab.annualReportDueDate,
    formationState: entityManagement.banner.formationState,
});
export default withRouter(connect(mapStateToProps)(ProductsTab));
