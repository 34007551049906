import React, { useState, useContext, useEffect } from 'react'
import {
    getBankAccount,
    getLinkToken,
    exchangeToken,
} from '../../../../services/apiService'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'

export const BankingInfoContext = React.createContext(null)

export function useBankingInfoState(): any {
    const state = useContext(BankingInfoContext)
    return state
}

export const BankingInfoProvider = ({ children }: any) => {
    const [linkToken, setLinkToken] = useState<any>(null)
    const [loading, setLoading] = useState<any>(null)
    const [loadToken, setLoadToken] = useState<any>(null)
    const [bankInfo, setBankInfo] = useState<any>(null)
    const [alert, setAlert] = useState(false)
    const [publicToken, setPublicToken] = useState(null)
    const [metadata, setMetaData] = useState<any>(null)
    const [tokenError, setTokenError] = useState<any>(null)
    const [dialog, setDialog] = useState<any>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { currentBusinessId } = useCurrentStore()
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=')

    useEffect(() => {
        const token = localStorage.getItem('linkToken')
        if (token && isOAuthRedirect) {
            setLinkToken(token)
            return
        }
        setLoadToken(true)
        currentBusinessId &&
            getLinkToken(currentBusinessId)
                .then((res) => {
                    setLinkToken(res.link_token)
                    setLoadToken(false)
                    localStorage.setItem('linkToken', res.link_token)
                })
                .catch((err) => {
                    setTokenError(true)
                })
    }, [currentBusinessId])

    useEffect(() => {
        setLoading(true)
        currentBusinessId &&
            getBankAccount(currentBusinessId).then((res) => {
                if (res?.routing_number) {
                    setBankInfo(res)
                }
                setLoading(false)
            })
    }, [currentBusinessId])

    useEffect(() => {
        setLoading(true)
        if (publicToken && metadata?.account_id) {
            const payload = {
                business_id: currentBusinessId,
                public_token: publicToken,
                account_id: metadata?.account_id,
            }
            exchangeToken(payload)
                .then((res) => {
                    setBankInfo(res)
                    setLoading(false)
                    localStorage.removeItem('linkToken')
                })
                .catch((err) => {
                    setTokenError(true)
                })
        }
    }, [publicToken, metadata, currentBusinessId])

    const providerValue: any = {
        linkToken,
        loading,
        bankInfo,
        setBankInfo,
        publicToken,
        setPublicToken,
        alert,
        setAlert,
        metadata,
        setMetaData,
        tokenError,
        loadToken,
        dialog,
        setDialog,
        isModalOpen,
        setIsModalOpen,
        isOAuthRedirect,
    }

    return (
        <BankingInfoContext.Provider value={providerValue}>
            {children}
        </BankingInfoContext.Provider>
    )
}
