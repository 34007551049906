import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Hidden, Container, makeStyles, Theme, Box } from '@material-ui/core';
import Journal from '../bookkeeping/journal/Journal';
import JournalTread from '../bookkeeping/journal/JournalTread';
import { JournalStateProvider } from '../bookkeeping/journal/JournalProvider';

export const JournalRoutes: React.FC = () => {
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        };
    })();
    return (
        <Switch>
            <JournalStateProvider>
                <Fragment>
                    <Hidden mdUp>
                        <Route path='/journal' exact>
                            <Box
                                className={classes.container}
                            >
                                <Journal />
                            </Box>
                        </Route>
                        <Route path={`/journal/:id`} exact>
                            <Box
                                className={classes.container}
                            >
                                <JournalTread />
                            </Box>
                        </Route>
                    </Hidden>
                    <Hidden smDown>
                        <Route path='/journal'>
                            <Container
                                className={classes.container}
                                maxWidth='lg'
                            >
                                <Journal />
                            </Container>
                        </Route>
                    </Hidden>
                </Fragment>
            </JournalStateProvider>
        </Switch>
    );
};
