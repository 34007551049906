
import React from 'react'
import {
    Grid,
    makeStyles,
    Theme,
    Box
} from '@material-ui/core'
import MultipleBusiness from '../../assets/images/MultipleBusiness.svg'
import UiText from '../common/ui/UiText';
import UpgradePlanButton from '../common/UpgradePlanButton'

const useClass = makeStyles((theme: Theme) => ({
    upgradeBox: {
        width: '50%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    upgradeText: {
        padding: theme.spacing(3, 0, 3),
        width: '90%',
        textAlign: 'center'
    }
}))

const UpgradePlanBusiness = () => {
    const classes = useClass()
    
    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Box
                className={classes.upgradeBox}
            >
                <img
                    src={MultipleBusiness}
                    alt='MultipleBusiness'
                />
                <UiText variant='truck_175' className={classes.upgradeText}>
                    Add Multiple Businesses
                </UiText>
                <UiText className={classes.upgradeText}>
                    Your current plan lets you add a single business to your account. Upgrade your plan to add multiple businesses.
                </UiText>
                <UpgradePlanButton/>
            </Box>
        </Grid>
    )
}

export default UpgradePlanBusiness;