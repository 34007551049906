import {
    Typography,
    List,
    Divider,
    ListItem,
    makeStyles,
    Theme,
    ListItemText,
    ListItemIcon,
    Link,
    Grid,
    Hidden,
    Box,
    Button,
    IconButton,
    MenuItem,
    Menu,
    useMediaQuery,
    TextField,
    colors,
} from '@material-ui/core'
import { Fragment, useEffect, useState, useRef} from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { BankDetails } from '../../../../models/bankAccount'
import { BankAccountGroup } from '../../../../models/bankAccountGroup'
import { AppData, ApplicationStore, Config } from '../../../../models/store'
import { dateTimeFormat } from '../../../../utils/dateUtil'
import UiTextView from '../../../common/ui/UiTextView'
import MenuDropdownBank from './MenuDropdownBank'
import EditIcon from '../../../../assets/icons-svg/Edit.svg'
import DeleteIcon from '../../../../assets/icons-svg/Delete.svg'
import { currencyFormatter } from '../../../../utils/appUtil'
import {
    getBankAccountGroup,
    getBankAccountDetails,
    removeBankAccount,
    getBankingPageUpdateToken,
    getAccountStatusSync,
} from '../../../../services/apiService/bankAccount'
import { usePlaidLink } from "react-plaid-link";
import Loader from '../../../common/Loader'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Deleteicon from '../../../../assets/icons/Deleteicon'
import ManualAccountModal from '../ManualAccountModal'
import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog'
import { commonStyles } from '../../../../styles/commonStyles'
import UiText from '../../../common/ui/UiText'
import UiAlert from '../../../common/ui/UiAlert'
import UiButton from '../../../common/ui/UiButton'
import { BANKING_PAGE_ERRORS, PLAID_ERROR_CODES, PLAID_ERROR_CODES_MAPPING } from '../constants/banking.const'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import ErrorDialog from '../dialog/UpdateModeConfirmationDialog'
import { showAlert } from '../../../../store/actions/feedback'
import { useBankDetailsState } from '../provider/BankDetailsProvider'

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    listBanks: {
        width: '100%',
    },
    listBanksItem: {
        display: 'block',
    },
    listBanksInfo: {
        marginBottom: theme.spacing(0),
    },
    listBanksInfoSecondary: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
    },
    listBanksInfoConnectionStatus: {
        fontSize: '1.125rem',
        fontWeight: 500,
    },
    connectionStatusPosition: {
        position: 'relative',
        top: '-0.625rem',
    },
    connectionStatusNotSyncdPosition: {
        position: 'relative',
        top: '-0.188rem',
    },
    listBanksInfoTimeStamp: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: (colorTheme) => colorTheme.black100,
    },
    timeStampPosition: {
        top: '-0.75rem',
        height: '0.5rem',
        left: '2.125rem',
        position: 'relative',
        display: 'block',
    },
    bankName: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        marginBottom: theme.spacing(3),
    },
    bankInfo: {
        marginBottom: theme.spacing(3),
        color: (colorTheme) => colorTheme.blue600,
        textDecoration: 'none',
    },

    bankInfoLink: {
        color: (colorTheme) => colorTheme.blue600,
        textDecoration: 'none',
        overflowWrap: 'break-word',
    },
    listRoot: {
        width: '100%',
        overflow: 'auto',
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
    },
    navListItemGutters: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    seconderyActionButtonOutline: {
        height: 36,
        color: (colorTheme) => `${colorTheme.black100} !important`,
    },
    buttonGap: {
        marginRight: theme.spacing(2),
    },
    alertIcon: {
        position: 'relative',
        top: theme.spacing(0),
        marginRight: theme.spacing(1),
    },
    subjectText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    deleteIcon: {
        color: (colorTheme) => colorTheme.red400,
    },
    deleteButton: {
        color: (colorTheme) => colorTheme.primaryWhite,
        background: (colorTheme) => colorTheme.red200,
        '&:hover': {
            color: (colorTheme) => colorTheme.primaryWhite,
            background: (colorTheme) => colorTheme.red200,
        },
    },
    deleteButtonDisabled: {
        color: (colorTheme) => colorTheme.primaryWhite + ' !important',
        background: (colorTheme) => colorTheme.red200 + ' !important',
        opacity: 0.5,
    },
    dividerInset: {
        marginLeft: theme.spacing(7),
    },
    alertContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    uiAlert: {
        width: '100%',
    },
    noDisplay: {
        display: 'none',
    },
    reLoginBtn : {
        padding: '0.5rem 2rem',
    }
}))

function AlertContent(props: any) {
    const { colorTheme } = useThemeContext();
    const classes = styles(colorTheme);
    return (
        <div className={classes.alertContent}>
            {props.message}{' '}
            {props?.showReloginBtn && (
                <>
                    <UiButton customClass={classes.reLoginBtn} label="Re-login" btnType="primary" handleClick={props?.reloginAction} />
                </>
            )}
        </div>
    )
}

const capitalizeFirstLetter = (sentence: string): string => {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

function BankGroup(props: {
    activeBankGroup?: BankAccountGroup
    appData: AppData
    config: Config
    categories: any[]
    reloadBankAccountGroups?: (slectGroup?: boolean) => void
}) {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme);
    const commonClasses = commonStyles()
    const [loading, setLoading] = useState(false)

    const { id }: any = useParams()
    const history = useHistory()
    const [activeBankGroup, setActiveBankGroup] = useState<BankAccountGroup>()
    const [accountData, setAccountData] = useState<any>()
    const [openManualAccountModal, setOpenManualAccountModal] = useState(false)
    const dispatch = useDispatch()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )
    const [anchorElDot, setAnchorElDot] = useState<any>(null)
    const handleCloseDotMenu = () => {
        setAnchorElDot(null)
    }
    const openDot = Boolean(anchorElDot)

    const [showDeleteModal, setShowDeletetModal] = useState(false)
    const [deleteModalInputText, setDeleteModalInputText] = useState('')
    const [bankLoadinIndex, setBankLoadingIndex] = useState<any>(null)
    const [updateLinkToken, setUpdateLinkToken] = useState<any>(null)
    const [selectedAccount, setSelectedAccount] = useState<any>(null)
    const buttonRef = useRef<HTMLButtonElement | null>(null);   
    const {dialog, setDialog} = useBankDetailsState()  

    const loadBankGroup = (bankId: any) => {
        getBankAccountGroup(
            props.appData.current_account_id,
            props.appData.current_business_id,
            bankId
        ).then((res: any) => {
            setActiveBankGroup(res)
            setLoading(false)
            setBankLoadingIndex(null)
        })
    }
    const { open: openUpdateMode, ready } = usePlaidLink({
        token: updateLinkToken,
        onSuccess: (publicToken, metaData) => {
            setLoading(true)
            getAccountStatusSync(selectedAccount?.id).then((res: any) => {
                if (props.reloadBankAccountGroups) {
                    props.reloadBankAccountGroups(false)
                }
            }).catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            err?.statusText ||
                            BANKING_PAGE_ERRORS.GENERIC_ERROR
                    })
                )
            }).finally(() => {
                setLoading(false)
            })
            setDialog(false)         
        },
    })

    const handleUpdateModeProcess = () => {
        setLoading(false)
        buttonRef?.current?.click()
    }

    useEffect(() => {
        if (props.activeBankGroup) {
            setActiveBankGroup(props.activeBankGroup)
            setBankLoadingIndex(null)
            setLoading(false)
        } else {
            setLoading(true)
            loadBankGroup(id)
        }
    }, [props.activeBankGroup, id])

    const getCountryName = (countryId: string): string | null => {
        const country = props.config.apiConfig.generic.countries.find(
            (item) => item.abbr === countryId
        )

        return country ? country.name : null
    }

    const editManualBankAccount = (bank: any) => {
        setOpenManualAccountModal(true)
        getBankAccountDetails(
            props.appData.current_account_id,
            props.appData.current_business_id,
            bank.id
        ).then((res: any) => {
            if (res) {
                setAccountData(res)
            }
        })
    }

    const fetchCurrentBankGroup = (bank: any) => {
        setLoading(true)
        loadBankGroup(bank.id)
    }

    const updateBankData = (bank: any) => {
        let activeBankGroupCopy: any = { ...activeBankGroup }
        activeBankGroupCopy.bank_accounts[
            activeBankGroup?.bank_accounts.findIndex(
                (acc) => acc.id === bank.id
            ) || 0
        ] = bank

        setActiveBankGroup(activeBankGroupCopy)
    }

    const removeBankAction = () => {
        setLoading(true)
        return removeBankAccount(
            props.appData.current_account_id,
            props.appData.current_business_id,
            activeBankGroup?.bank_accounts[0].id || ''
        )
            .then((res: any) => {
                if (props.reloadBankAccountGroups) {
                    props.reloadBankAccountGroups(false)
                } else {
                    history.push('/bank_accounts')
                }
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            err?.statusText ||
                            BANKING_PAGE_ERRORS.GENERIC_ERROR
                    })
                )
            }).finally(() => {
                setLoading(false)
            })
    }

    const reloginAction = (bank: any) => {
        setSelectedAccount(bank)
        setLoading(true)
        getBankingPageUpdateToken(bank?.id)
            .then((res: any) => {
                setUpdateLinkToken(res?.link_token)
                setDialog(true)
            })
            .catch((err) => {
                setSelectedAccount(null)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            err?.statusText ||
                            BANKING_PAGE_ERRORS.GENERIC_ERROR
                    })
                )
            }).finally(() => {
                setLoading(false)
            })
    }

    const BankSingle = (bank: any, index: number) => {
        const bankError = bank?.error_code;
        return index === bankLoadinIndex ? (
            <Box width="100%" height="9.375rem">
                <Loader />
            </Box>
        ) : (
            <ListItem
                className={classes.listBanksItem}
                classes={{
                    gutters: classes.navListItemGutters,
                }}
            >
                {bank.type !== 'manual' && (
                    <MenuDropdownBank
                        bank={bank}
                        appData={props.appData}
                        disabled={false}
                        setLoading={(loading) => {
                            if (loading) {
                                setBankLoadingIndex(index)
                            } else {
                                setBankLoadingIndex(null)
                            }
                        }}
                        updateBankAccountData={updateBankData}
                        categories={props.categories}
                        accountantMode={props.appData.user.accountant_mode}
                        reloadBankAccountGroups={
                            smDevice
                                ? fetchCurrentBankGroup.bind(null, { id })
                                : props.reloadBankAccountGroups
                        }
                    />
                )}
                <Typography className={classes.listBanksInfo} variant="body1">
                    {bank.name}
                </Typography>
                {bank.type === 'manual' ||
                (bank.sync_from_date && !bank.disconnected_at) ? (
                    <UiText
                        weight="medium_500"
                        variant="hatchback_125"
                        className={classes.listBanksInfo}
                    >
                        {currencyFormatter.format(bank.balance)}
                    </UiText>
                ) : (
                    ''
                )}
                {bank.type !== 'manual' ? (
                    <Fragment>
                        {bank.sync_from_date && !bank.disconnected_at && (
                            <Typography
                                className={classes.listBanksInfoSecondary}
                                variant="body2"
                                style={{
                                    fontSize: '0.75rem',
                                }}
                            >
                                Last synced:{' '}
                                {dateTimeFormat(bank.sync_from_date * 1000)}
                            </Typography>
                        )}
                        {bank.disconnected_at && (
                            <>
                                <img
                                    alt={`Alert`}
                                    src={
                                        require(`../../../../assets/icons-svg/AlertTriangle.svg`)
                                            .default
                                    }
                                    className={classes.alertIcon}
                                />
                                <span
                                    className={`
                                        ${classes.listBanksInfoConnectionStatus} ${classes.connectionStatusPosition}`}
                                >
                                    Disconnected
                                </span>
                                <br></br>
                                <span
                                    className={`
                                    ${classes.listBanksInfoTimeStamp} ${classes.timeStampPosition}`}
                                >
                                    {dateTimeFormat(
                                        bank.disconnected_at * 1000
                                    )}
                                </span>
                            </>
                        )}
                        {!bank?.sync_from_date && !bank?.disconnected_at && (
                            <>
                                <img
                                    alt={`Alert`}
                                    src={
                                        require(`../../../../assets/icons-svg/InfoI.svg`)
                                            .default
                                    }
                                    className={classes.alertIcon}
                                />
                                <span
                                    className={`
                                        ${classes.listBanksInfoConnectionStatus} ${classes.connectionStatusNotSyncdPosition}`}
                                >
                                    Not synced
                                </span>
                            </>
                        )}
                    </Fragment>
                ) : (
                    ''
                )}
                <Typography
                    className={classes.listBanksInfoSecondary}
                    variant="body2"
                >
                    {
                        props.config.apiConfig.bank_account.account_types.find(
                            (type) => type.id === bank.account_type
                        )?.name
                    }
                </Typography>
                <Typography
                    className={classes.listBanksInfoSecondary}
                    variant="body2"
                >
                    {bank.number}
                </Typography>
                <>
                    <ErrorDialog
                        openModal={dialog}
                        closeModal={() => setDialog(false)}
                        handleProceedBtn={handleUpdateModeProcess}
                        selectedAccount={selectedAccount}
                    />
                </>
                {bankError && (
                    <UiAlert
                        icon={'alert'}
                        severity={'error'}
                        customStyles={{
                            message: classes.uiAlert,
                        }}
                        customContent={
                            <AlertContent
                                message={bank?.error_msg ? capitalizeFirstLetter(bank?.error_msg) : ""}
                                reloginAction={() => reloginAction(bank)}
                                showReloginBtn={
                                    bankError ===
                                    PLAID_ERROR_CODES.ITEM_LOGIN_REQUIRED
                                }
                            />
                        }
                    ></UiAlert>
                )}
            </ListItem>
        )
    }

    const Banks = ({ banks }: { banks: any[] }) => {
        return (
            <>
                {banks.map((bank, index) => (
                    <Fragment key={index}>
                        {BankSingle(bank, index)}
                        {index !== banks.length - 1 || banks.length === 1 ? (
                            <Divider />
                        ) : (
                            ''
                        )}
                    </Fragment>
                ))}
            </>
        )
    }
    
    const BankDetailsSection = (bankDetails: BankDetails) => (
        <>
            <List className={classes.listRoot}>
                {bankDetails?.website && (
                    <>
                        <ListItem
                            classes={{
                                gutters: classes.navListItemGutters,
                            }}
                        >
                            <ListItemIcon
                                style={{
                                    minWidth: '3rem',
                                }}
                            >
                                <img
                                    alt={`Website`}
                                    src={
                                        require(`../../../../assets/icons-svg/link.svg`)
                                            .default
                                    }
                                />
                            </ListItemIcon>

                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Website
                                    </Typography>
                                }
                                secondary={
                                    <Link
                                        href={
                                            bankDetails?.website.startsWith(
                                                'http://'
                                            ) ||
                                            bankDetails?.website.startsWith(
                                                'https://'
                                            )
                                                ? bankDetails?.website
                                                : `//${bankDetails?.website}`
                                        }
                                        target="_blank"
                                        className={classes.bankInfoLink}
                                    >
                                        {bankDetails?.website}
                                    </Link>
                                }
                            />
                        </ListItem>
                        <Hidden implementation="js" mdUp>
                            <Divider
                                className={classes.dividerInset}
                                variant="inset"
                            ></Divider>
                        </Hidden>
                    </>
                )}
                {bankDetails?.phone && (
                    <>
                        <ListItem
                            classes={{
                                gutters: classes.navListItemGutters,
                            }}
                        >
                            <ListItemIcon
                                style={{
                                    minWidth: '3rem',
                                }}
                            >
                                <img
                                    alt={`Phone`}
                                    src={
                                        require(`../../../../assets/icons-svg/phone.svg`)
                                            .default
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Phone Number
                                    </Typography>
                                }
                                secondary={
                                    <Link
                                        href={`tel:${bankDetails?.phone}` || ''}
                                        className={classes.bankInfoLink}
                                    >
                                        {activeBankGroup?.is_manual ? (
                                            <UiTextView
                                                type="phone-us"
                                                value={bankDetails?.phone}
                                            />
                                        ) : (
                                            bankDetails?.phone
                                        )}
                                    </Link>
                                }
                            />
                        </ListItem>
                        <Hidden implementation="js" mdUp>
                            <Divider
                                className={classes.dividerInset}
                                variant="inset"
                            ></Divider>
                        </Hidden>
                    </>
                )}
                {bankDetails?.address && (
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                    >
                        <ListItemIcon
                            style={{
                                minWidth: '3rem',
                            }}
                        >
                            <img
                                alt={`Location`}
                                src={
                                    require(`../../../../assets/icons-svg/Locations.svg`)
                                        .default
                                }
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Address
                                </Typography>
                            }
                            secondary={
                                <Typography variant="body1">
                                    {bankDetails?.address?.address
                                        ? `${bankDetails?.address.address}, `
                                        : ''}
                                    {bankDetails?.address?.city
                                        ? `${bankDetails?.address?.city}, `
                                        : ''}
                                    {bankDetails?.address?.county
                                        ? `${bankDetails?.address?.county}, `
                                        : ''}
                                    {bankDetails?.address?.state
                                        ? `${bankDetails?.address?.state}, `
                                        : ''}
                                    {bankDetails?.address?.zip_code
                                        ? `${bankDetails?.address?.zip_code}, `
                                        : ''}
                                    {bankDetails?.address?.country
                                        ? `${getCountryName(
                                              bankDetails?.address?.country
                                          )}`
                                        : ''}
                                </Typography>
                            }
                        />
                    </ListItem>
                )}
                {bankDetails?.description && (
                    <>
                        <Hidden implementation="js" mdUp>
                            <Divider
                                className={classes.dividerInset}
                                variant="inset"
                            ></Divider>
                        </Hidden>
                        <ListItem
                            classes={{
                                gutters: classes.navListItemGutters,
                            }}
                        >
                            <ListItemIcon
                                style={{
                                    minWidth: '3rem',
                                }}
                            >
                                <img
                                    alt={`Note`}
                                    src={
                                        require(`../../../../assets/icons-svg/Note.svg`)
                                            .default
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Description
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="body1">
                                        {bankDetails?.description}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </>
                )}
            </List>
        </>
    )
    


    return (
        <>
          {(updateLinkToken && ready) &&  <button
                className={classes.noDisplay}
                ref={buttonRef}
                onClick={() => openUpdateMode()}
            >
                Update Bank Account
            </button> }
            {
                loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <Grid container alignItems="center">
                            {/* Modals */}
                            <ManualAccountModal
                                openModal={openManualAccountModal}
                                handleClose={(fetchDetails: any) => {
                                    setOpenManualAccountModal(false)
                                    if (fetchDetails) {
                                        fetchCurrentBankGroup(
                                            activeBankGroup?.bank_accounts?.[0]
                                        )
                                        props.reloadBankAccountGroups?.()
                                    }
                                }}
                                isNew={false}
                                loadingData={!accountData}
                                bankData={accountData}
                            />
            
                            <UiConfirmationDialog
                                open={showDeleteModal}
                                message={
                                    <>
                                        <Typography variant="h6">
                                            Delete Account?
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={commonClasses.mt16}
                                        >
                                            All connected transactions will be permanently
                                            deleted.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={commonClasses.mt16}
                                        >
                                            Reports and balances that use these transactions
                                            will also be affected.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className={commonClasses.mt16}
                                        >
                                            This cannot be undone.
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            className={commonClasses.mt16}
                                        >
                                            Type “Delete” to confirm.
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            className={commonClasses.mt16}
                                            InputProps={{
                                                style: {
                                                    height: '3rem',
                                                    width: '14.25rem',
                                                },
                                            }}
                                            defaultValue=""
                                            onChange={(event) =>
                                                setDeleteModalInputText(event.target.value)
                                            }
                                        />
                                    </>
                                }
                                handleClose={() => {
                                    setShowDeletetModal(false)
                                    setDeleteModalInputText('')
                                }}
                                confirmNode={
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setShowDeletetModal(false)
                                            setDeleteModalInputText('')
                                            removeBankAction()
                                        }}
                                        classes={{
                                            root: classes.deleteButton,
                                            disabled: classes.deleteButtonDisabled,
                                        }}
                                        disabled={deleteModalInputText !== 'Delete'}
                                    >
                                        Delete
                                    </Button>
                                }
                                cancelButtonText="Cancel"
                            />
                            {/* Modals end */}
            
                            <Hidden mdUp>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    sm={12}
                                    container
                                    alignItems="center"
                                    direction="row"
                                    justify="space-between"
                                >
                                    <Grid
                                        style={{ display: 'flex' }}
                                        item
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <div>
                                            <IconButton
                                                onClick={() => {
                                                    history.goBack()
                                                }}
                                                style={{
                                                    paddingLeft: 0,
                                                }}
                                            >
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </div>
                                        <div>
                                            <Typography
                                                variant="h6"
                                                className={classes.subjectText}
                                                noWrap
                                            >
                                                {activeBankGroup?.bank_details?.name}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    {activeBankGroup?.is_manual && (
                                        <Grid item>
                                            <div>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu-edt"
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        anchorElDot
                                                            ? setAnchorElDot(null)
                                                            : setAnchorElDot(
                                                                  event.currentTarget
                                                              )
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu-edt"
                                                    anchorEl={anchorElDot}
                                                    keepMounted
                                                    open={openDot}
                                                    onClose={handleCloseDotMenu}
                                                >
                                                    <MenuItem>
                                                        <Button
                                                            className={
                                                                classes.seconderyActionButtonOutline
                                                            }
                                                            startIcon={
                                                                <img
                                                                    alt={`Edit`}
                                                                    src={EditIcon}
                                                                />
                                                            }
                                                            onClick={() => {
                                                                editManualBankAccount(
                                                                    activeBankGroup
                                                                        ?.bank_accounts?.[0]
                                                                )
                                                                handleCloseDotMenu()
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </MenuItem>
            
                                                    {props.appData.user.accountant_mode && (
                                                        <>
                                                            <Divider />
                                                            <MenuItem
                                                                onClick={handleCloseDotMenu}
                                                            >
                                                                <Button
                                                                    className={
                                                                        classes.deleteIcon
                                                                    }
                                                                    startIcon={
                                                                        <Deleteicon />
                                                                    }
                                                                    onClick={() =>
                                                                        setShowDeletetModal(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </MenuItem>
                                                        </>
                                                    )}
                                                </Menu>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Hidden>
            
                            <Hidden smDown>
                                <Grid
                                    item
                                    md={12}
                                    container
                                    alignItems="center"
                                    justify="space-between"
                                >
                                    <Box>
                                        <Typography variant="h6">
                                            {activeBankGroup?.bank_details?.name}
                                        </Typography>
                                    </Box>
                                    {activeBankGroup?.is_manual && (
                                        <Box>
                                            {props.appData.user.accountant_mode && (
                                                <Button
                                                    className={
                                                        classes.seconderyActionButtonOutline
                                                    }
                                                    classes={{
                                                        root: classes.buttonGap,
                                                    }}
                                                    variant="outlined"
                                                    startIcon={
                                                        <img
                                                            alt={`Delete`}
                                                            src={DeleteIcon}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        setShowDeletetModal(true)
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            )}
            
                                            <Button
                                                className={
                                                    classes.seconderyActionButtonOutline
                                                }
                                                variant="outlined"
                                                startIcon={
                                                    <img alt={`Edit`} src={EditIcon} />
                                                }
                                                onClick={() => {
                                                    editManualBankAccount(
                                                        activeBankGroup?.bank_accounts?.[0]
                                                    )
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </Box>
                                    )}
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Hidden mdUp>
                            <Divider variant="fullWidth"></Divider>
                        </Hidden>
                        {activeBankGroup &&
                            BankDetailsSection(activeBankGroup?.bank_details)}
            
                        <Typography variant="h6">
                            {activeBankGroup?.is_manual
                                ? 'Account Details'
                                : 'Connected Accounts'}
                        </Typography>
                        <List className={classes.listBanks}>
                            {Banks({ banks: activeBankGroup?.bank_accounts || [] })}
                        </List>
                                          </Fragment>
                )
            }
        </>
    ) 
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    config: state.config,
    categories: state.category.categories,
})
export default connect(mapStateToProps)(BankGroup)
