import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppData, ApplicationStore } from '../../../models';
import { hasPermission } from '../../../services/aclService';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import GettingStarted from '../GettingStarted';
import CompanySetup from './CompanySetup';

interface CompanyProps {
    appData: AppData;
}

function Company({ appData }: CompanyProps) {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    useEffect(() => {
        setActiveRouteHeading('Company Details');
    }, [setActiveRouteHeading]);

    return hasPermission('PAYROLL', appData.current_account) ? (
        <CompanySetup />
    ) : (
        <GettingStarted />
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(Company);
