import {
    Button,
    Box,
    Container,
    createStyles,
    Link,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

export default function ReconciliationsEmptyState() {
    
    const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
        createStyles({
            pageMarginTop: {
                marginTop: '10px',
            },
            pageLabel: {
                fontSize: 24,
                fontWeight: 500,
                lineHeight: '36px',
                letterSpacing: 0.18,
                color: (colorTheme) => colorTheme.primaryBlack,
            },
            pageSubLabel: {
                fontSize: 18,
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: 0.18,
                color: (colorTheme) => colorTheme.primaryBlack,
            },
            emptyTextWrapper: {
                marginTop: theme.spacing(2),
            },
            newMessageButton: {
                height: '36px',
                marginTop: '30px',
            },
            subEmptyTextWrapper: {
                marginTop: '80px',
                textAlign: 'center',
            },
            linkMargin: {
                marginBottom: '20px',
            },
        })
    )
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const history = useHistory()

    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                justifyItems="center"
            >
                <div className={classes.pageMarginTop}>
                    <img
                        alt={`ReconciliationsEmpty`}
                        src={
                            require(`../../../assets/icons-svg/ReconciliationsEmpty.svg`)
                                .default
                        }
                    />
                </div>
                <p className={classes.pageLabel}>
                    Know where your business's money is
                </p>
                <div className={classes.emptyTextWrapper}>
                    <Typography variant="body1" align="center">
                        Reconciling your bank accounts regularly will help
                        ensure that your <br></br>
                        business's balance sheet is up-to-date and correct.
                    </Typography>
                </div>
                <Button
                    className={classes.newMessageButton}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => history.push('/bank_accounts/new')}
                >
                    Reconcile my Account
                </Button>

                <div className={classes.subEmptyTextWrapper}>
                    <p className={classes.pageSubLabel}>
                        Save time by letting us do your bookkeeping
                    </p>
                    <Typography
                        className={classes.linkMargin}
                        variant="body1"
                        align="center"
                    >
                        Let us handle your everyday bookkeeping tasks so you can
                        focus <br></br>
                        on what you do best - running your business.
                    </Typography>
                    <Link href="#/bank_accounts/new"> Get Started</Link>
                </div>
            </Box>
        </Container>
    )
}
