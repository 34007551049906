import { Grid, Typography } from '@material-ui/core'
import UiFormControlSelection from './UiFormControlSelection';
import { UiFormFieldProps } from './UiFormFieldTypes';

export default function UiFormField({ label, labelSize = 12, fieldSize = 12, type = 'text', containerBottomMargin = 8, labelStyles = {}, required, cypressId, ...rest }: UiFormFieldProps) {
    return (
        <Grid container spacing={1} style={{ marginBottom: containerBottomMargin }}>
            {
                labelSize
                    ? <Grid item xs={labelSize}>
                        <Typography variant="body2" style={labelStyles}>
                            {label}&nbsp;
                            <span className="MuiTypography-colorError">{required ? '*' : ''}</span>
                        </Typography>
                    </Grid>
                    : null
            }

            <Grid item xs={fieldSize}>
                <UiFormControlSelection label={label} type={type} required={required} cypressId={cypressId} {...rest} />
            </Grid>
        </Grid>
    )
}