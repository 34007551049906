import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
    createStyles,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core'
import { useDispatch, useStore } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
    excludeTransaction,
    getBankAccountList,
    getGeneralLedgerReports,
    getGeneralLedgerReportsCsv,
    saveGeneralLedgerReportsCsv,
    getTransactionDetailsById,
    createFile,
    downloadGeneralLedgerPdf,
} from '../../../../services/apiService/reports'
import {
    currencyFormatter,
    getNumberfromString,
    isEmpty,
} from '../../../../utils/appUtil'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import {
    Account,
    GeneralLadgerReportRoot,
    Transactions,
    transactionType,
} from '../models/reports-interface'
import { useReportsStyles } from '../styles/reports-styles'
import Loader from '../../../common/Loader'
import EditIcon from '../../../../assets/icons-svg/Edit.svg'
import { convertUnixToGivenDateFormat } from '../../../../utils/dateUtil'
import UiText from '../../../common/ui/UiText'
import NewTransactionModal from '../../transactions/NewTransactionModal'
import { initCategories } from '../../../../store/actions/categories'
import { deleteTransactionById } from '../../../../services/transactionsService'
import InfoTooltip from '../../../common/InfoTooltip'
import { InfoOutlined } from '@material-ui/icons'
import {
    deleteJournalById,
    getAccount,
    getJournalById,
} from '../../../../services/journalServices'
import NewJournalModal from '../../journal/NewJournalModal'
import { useJournalState } from '../../journal/JournalProvider'
import SaveReports from '../utils/SaveReports/SaveReports'
import { showError } from '../../../../services/formService'
import {
    DOCUMENT_DOWNLOAD_SUCCESS,
    EMPTY_PARAMS,
    saveOptions,
    REPORTS_MENU_VIEWS
} from '../constants/reports.const'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo'
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading'
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider'
import ErrorAlert from '../utils/Alerts/ErrorAlert'
import UncategorizedTransactionsAlert from '../utils/Alerts/UncategorizedTransactionsAlert'
import { resetReportsData } from '../../../../store/actions/reportsData'
import { LeftArrowIcon } from '../Icons/LeftArrowIcon'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { getTripDetails } from '../../../../services/apiService/trips';
import { Trip } from '../../../../models';
import TripOverlayForm from '../../../mileage-log/trips/Form/TripOverlayForm';

interface QueryParams {
    ymdStartDate: string
    ymdEndDate: string
    periodText: string
    time: number
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        accountName: { margin: '40px 0 40px 0' },
        hideTooltip: {
            display: 'block',
            width: '3rem',
            height: 'auto',
        },
        priceWaste: {
            color: (colorTheme) => colorTheme.red100,
        },
        descriptionTooltip: {
            padding: 0,
            '& button': {
                padding: 0,
            },
        },
        editIconCell: {
            width: '5%',
        },
        amountSection: {
            width: '17.11% !important',
        },
        generalLedgerTable: {
            '& .MuiTableCell-root': {
                width: '11.11%',
            },
        },
    })
)

const TOOLTIP_TEXT =
    'The General Ledger Report shows a complete record of all transactions on your account over the selected period of time.'
const EMPTY_YMD_START = 'startDate'
const EMPTY_YMD_END = 'endDate'
export default function GeneralLedgerDetailedReport() {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [reportsData, setReportsData] = useState<GeneralLadgerReportRoot>()
    const store = useStore().getState()
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const businessId = currentStore.currentBusinessId!
    const businessName = currentStore.currentBusiness.name!
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme)
    const [selectedReport, setSelectedReport] = useState<Transactions>()
    const query: QueryParams = useParams() as unknown as QueryParams
    const { ymdStartDate, ymdEndDate, periodText, time } = query
    const ymdStartDateParam =
        ymdStartDate === EMPTY_YMD_START ? '' : ymdStartDate
    const ymdEndDateParam = ymdEndDate === EMPTY_YMD_END ? '' : ymdEndDate
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState<transactionType | null>(null)
    const [bankAccount, setBankAccount] = useState<any>([])
    const [loadEdit, setLoadEdit] = useState<boolean>(false)
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false)
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    )
    const [uncategorizedTransactions, setUncategorizedTransactions] =
        useState<number>()
    const [logoImage, setLogoImage] = useState<string | undefined>('')
    const [selectedTrip, setSelectedTrip] = useState<Trip>();
    const { listData, setListData } = useJournalState()
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('')
    const localClasses = useLocalStyles(colorTheme)
    const history = useHistory()    
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)

    useEffect(() => {
        setActiveRouteHeading(' ')
        return () => {
            dispatch(resetReportsData())
        }
    }, [setActiveRouteHeading])
    const getTransactionsCount = (message: string): number => {
        return getNumberfromString(message)
    }
    useEffect(() => {
        const warning = reportsData?.warning
        if (!warning) return
        const count = getTransactionsCount(warning)
        setUncategorizedTransactions(count)
    }, [reportsData?.warning])
    const getAccountdata = useCallback((): void => {
        setListData((prev: any) => ({ ...prev, loadingAccountRecord: true }))
        getAccount(accountId, businessId)
            .then((res: any) => {
                const parentCategories = {}
                const cache = {}
                res.forEach((caty: any) => {
                    if (caty.can_have_children) {
                        //@ts-ignore
                        parentCategories[caty?.id] = caty
                    }
                    //@ts-ignore
                    cache[caty?.id] = caty
                })
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: cache,
                    accountParentList: parentCategories,
                    loadingAccountRecord: false,
                }))
            })
            .catch((err) => {
                setListData((prev: any) => ({ ...prev, loading: false }))
            })
    }, [])

    useEffect(() => {
        const oldText = 'for the period from'
        const newText = 'From'
        const formmatedText = periodText
            .replaceAll('+', ' ')
            .replace(oldText, newText)
        setFormattedPeriodText(formmatedText)
    }, [periodText, ymdEndDateParam, ymdStartDateParam])

    useEffect(() => {
        getAccountdata()
        dispatch(initCategories())
    }, [dispatch])
    useEffect(() => {
        setIsLoading(true)
        async function fetchData() {
            await getBankAccountList(accountId, businessId).then(
                (result: any) => {
                    setBankAccount(result)
                }
            )
        }
        fetchData()
        setIsLoading(false)
    }, [])

    useEffect(() => {
        const fetchGeneralLedgerReports = () => {
            setIsLoading(true)
            getGeneralLedgerReports(
                accountId,
                businessId,
                ymdStartDateParam,
                ymdEndDateParam
            )
                .then((result: unknown) => {
                    const reports = result as GeneralLadgerReportRoot
                    setReportsData(reports)
                    setIsLoading(false)
                    return reports
                })
                .catch((error) => {
                    setIsLoading(false)
                    showError(error?.statusText)
                })
        }
        const reportsFromStore = store.reportsData.reports
        const reports = isEmpty(reportsFromStore)
            ? fetchGeneralLedgerReports()
            : reportsFromStore
        setReportsData(reports)
    }, [accountId, businessId, query])

    useEffect(() => {
        const logo = reportsData?.business.logo
        setLogoImage(logo)
    }, [reportsData?.business.logo])

    const handleEditClick = (data: Transactions) => {
        setLoadEdit(true)
        const type =
                data.type === transactionType.TRIP 
                    ? transactionType.TRIP
                    : data.type === transactionType.JOURNAL_ENTRY
                      ? transactionType.JOURNAL_ENTRY
                      : transactionType.TRANSACTION
        let getData =
            data.type === transactionType.JOURNAL_ENTRY
                ? getJournalById
                : getTransactionDetailsById

            if (type === transactionType.TRIP) {
                getTripDetails(data.id).then((result: any) => {
                    setSelectedTrip(result)
                    setLoadEdit(false)
                    setOpenModal(type)
                })
                .catch((error) => {
                    setLoadEdit(false)
                    showError(error?.statusText)
                    setIsLoading(false)
                })
            } else {
                getData(accountId, businessId, data.id)
                .then((result: any) => {
                    setSelectedReport(result)
                    setLoadEdit(false)
                    setOpenModal(type)
                })
                .catch((error) => {
                    setLoadEdit(false)
                    setIsLoading(false)
                    showError(error?.statusText)
                })
            }
    }
    const refreshTransactions = () => {
        setIsLoading(true)
        getGeneralLedgerReports(
            accountId,
            businessId,
            ymdStartDateParam,
            ymdEndDateParam
        )
            .then((result: unknown) => {
                const reports = result as GeneralLadgerReportRoot
                setReportsData(reports)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error?.statusText)
            })
    }

    const handleSaveToPortal = (
        values: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const requestData = {
            start_date_ymd:
                ymdStartDateParam === EMPTY_PARAMS.START_DATE_YMD
                    ? ''
                    : ymdStartDateParam,
            end_date_ymd:
                ymdEndDateParam === EMPTY_PARAMS.END_DATE_YMD
                    ? ''
                    : ymdEndDateParam,
            period_text: periodText.replaceAll('+', ' '),
            time,
            comment: values.comment,
        }
        saveGeneralLedgerReportsCsv(accountId, businessId, requestData)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    )
                    closeModal()
                    setIsSaveSnackbar(true)
                })
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error?.statusText)
            })
    }
    const formatDate = (date: number) => {
        const format = 'DD MMM yyyy'
        const dateInMilliSecs = date * 1000
        return convertUnixToGivenDateFormat(format, dateInMilliSecs)
    }
    const generateReportsTable = (items: Account[]) => {
        if (!items) return
        return items.map((account: Account, accountIndex: number) => {
            const totalBalanece =
                account.transactions[account.transactions.length - 1].balance
            return (
                <Fragment key={`__${accountIndex}`}>
                    <UiText
                        className={localClasses.accountName}
                        weight="semi_bold_600"
                        variant="hatchback_125"
                    >
                        {account.title}
                    </UiText>
                    <Table className={localClasses.generalLedgerTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Date
                                    </UiText>
                                </TableCell>
                                <TableCell>
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Type
                                    </UiText>
                                </TableCell>
                                <TableCell align="right">
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Ref #
                                    </UiText>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Customer
                                    </UiText>
                                </TableCell>
                                <TableCell>
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Category
                                    </UiText>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={localClasses.amountSection}
                                >
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Amount
                                    </UiText>
                                </TableCell>
                                <TableCell align="right">
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Balance
                                    </UiText>
                                </TableCell>
                                <TableCell
                                    className={localClasses.editIconCell}
                                    align="right"
                                >
                                    {''}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {account.transactions.map(
                                (
                                    transaction: Transactions,
                                    transactionIndex: number
                                ) => {
                                    return (
                                        <Fragment
                                            key={`${transactionIndex}__${accountIndex}`}
                                        >
                                            {transactionIndex === 0 && (
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell>
                                                        Starting Balance
                                                    </TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell align="right">
                                                        {currencyFormatter.format(
                                                            parseFloat(
                                                                account?.beginning_balance ??
                                                                    '0'
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {currencyFormatter.format(
                                                            parseFloat(
                                                                account.balance
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            )}
                                            <TableRow
                                                key={`${transactionIndex}__`}
                                            >
                                                <TableCell>
                                                    {formatDate(
                                                        transaction.date
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {transaction.type}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {transaction.number
                                                        ? `# ${transaction.number}`
                                                        : ''}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    className={
                                                        localClasses.descriptionTooltip
                                                    }
                                                >
                                                    {transaction.description ? (
                                                        <InfoTooltip
                                                            tooltipText={
                                                                transaction.description
                                                            }
                                                            customNode={
                                                                <IconButton aria-label="info">
                                                                    <InfoOutlined />
                                                                </IconButton>
                                                            }
                                                        />
                                                    ) : (
                                                        <span
                                                            className={
                                                                localClasses.hideTooltip
                                                            }
                                                        />
                                                    )}
                                                </TableCell>

                                                <TableCell>
                                                    {transaction.customer_name}
                                                </TableCell>
                                                <TableCell>
                                                    {transaction.category}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={
                                                        localClasses.amountSection
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            parseFloat(
                                                                transaction.amount
                                                            ) < 0
                                                                ? localClasses.priceWaste
                                                                : ''
                                                        }
                                                    >
                                                        {currencyFormatter.format(
                                                            parseFloat(
                                                                transaction.amount
                                                            )
                                                        )}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormatter.format(
                                                        parseFloat(
                                                            transaction.balance
                                                        )
                                                    )}
                                                </TableCell>
                                                {transaction.type !==
                                                    transactionType.CLOSING_ENTRY && (
                                                    <TableCell align="right">
                                                        <IconButton
                                                            disabled={loadEdit}
                                                            onClick={() =>
                                                                handleEditClick(
                                                                    transaction
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                alt={`Edit`}
                                                                src={EditIcon}
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </Fragment>
                                    )
                                }
                            )}
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell
                                    align="right"
                                    className={localClasses.amountSection}
                                >
                                    {account.title} Total:{' '}
                                </TableCell>
                                <TableCell align="right">
                                    <UiText
                                        weight="semi_bold_600"
                                        className={
                                            parseFloat(totalBalanece) < 0
                                                ? localClasses.priceWaste
                                                : ''
                                        }
                                    >
                                        {currencyFormatter.format(
                                            parseFloat(totalBalanece)
                                        )}
                                    </UiText>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </Fragment>
            )
        })
    }
    const setExcludeSingleEntry = (data: any) => {
        excludeTransaction(accountId, businessId, data.id, {
            excluded: true,
        })
            .then(() => {
                setOpenModal(null)
                refreshTransactions()
            })
            .catch((error) => {
                showError(error?.statusText)
            })
    }
    const deleteTransaction = (data: any) => {
        data?.id &&
            deleteTransactionById(accountId, businessId, data?.id)
                .then(() => {
                    setOpenModal(null)
                    refreshTransactions()
                })
                .catch((error) => {
                    showError(error)
                })
    }

    const deleteJournalEntry = (id: string) => {
        id &&
            deleteJournalById(accountId, businessId, id)
                .then(() => {
                    setOpenModal(null)
                    refreshTransactions()
                })
                .catch((error) => {
                    showError(error?.statusText)
                })
    }
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false)
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath)
    }
    return (
        <div className={reportsClasses.reportDetailsContainer}>
            {uncategorizedTransactions && (
                <UncategorizedTransactionsAlert
                    transactionsCount={uncategorizedTransactions}
                />
            )}
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText="General Ledger"
            />
            <div className={reportsClasses.reportsCommonHeader}>
                <div className="backLinkAndBtn">
                    <Link
                        data-cy="generate-new-report-btn"
                        to="/reports?name=generalLedger"
                    >
                        <LeftArrowIcon color={colorTheme.blue200} />
                        <span>Generate New Report</span>
                    </Link>
                    <SaveReports
                        businessName={businessName ?? ''}
                        downloadReportPDFUrl={downloadGeneralLedgerPdf(
                            accountId,
                            businessId,
                            ymdStartDateParam,
                            ymdEndDateParam,
                            periodText,
                            time
                        )}
                        downloadReportCSVUrl={getGeneralLedgerReportsCsv(
                            accountId,
                            businessId,
                            ymdStartDateParam,
                            ymdEndDateParam,
                            periodText,
                            time
                        )}
                        options={saveOptions.slice(-2)}
                        handleSaveToPortal={handleSaveToPortal}
                        reportName={REPORTS_MENU_VIEWS.GENERAL_LEDGER}
                        isDisabled={!reportsData?.accounts.length}
                    />
                </div>

                <div className="headerTextAndBusinessNameLogo">
                    <div>
                        <h2> General Ledger</h2>
                        <span>{` ${formattedPeriodText}`}</span>
                    </div>
                    <BusinessNameAndLogo
                        logoFromApi={logoImage}
                        accountId={accountId}
                        businessName={businessName!}
                    />
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {!reportsData ? (
                        <ErrorAlert />
                    ) : (
                        <>
                            <div
                                className={reportsClasses.reportDetailsContent}
                            >
                                {generateReportsTable(
                                    reportsData?.accounts as Account[]
                                )}
                            </div>

                            <NewTransactionModal
                                open={openModal === transactionType.TRANSACTION}
                                handleClose={() => setOpenModal(null)}
                                isEdit={true}
                                deleteData={{
                                    deleteHandleOption: deleteTransaction,
                                }}
                                selectedData={selectedReport}
                                accountList={bankAccount}
                                selectedAccount={{ account: selectedReport }}
                                setExcludeSingleEntry={setExcludeSingleEntry}
                                refreshTransactions={refreshTransactions}
                            />
                            {!loadEdit && (
                                <NewJournalModal
                                    open={
                                        openModal ===
                                        transactionType.JOURNAL_ENTRY
                                    }
                                    handleClose={() => setOpenModal(null)}
                                    datasource={listData}
                                    getJournalEntry={refreshTransactions}
                                    setListData={setListData}
                                    isEdit
                                    deleteData={{
                                        deleteHandleOption: deleteJournalEntry,
                                    }}
                                    selectedData={selectedReport}
                                />
                            )}
                            <TripOverlayForm
                                isOpen={openModal === transactionType.TRIP}
                                trip={selectedTrip}
                                handleClose={() => setOpenModal(null)}
                                submitCallback={refreshTransactions}
                                isNew={false}
                            />
                            <UiSnackbarAlert
                                open={isSaveSnackbar}
                                message={DOCUMENT_DOWNLOAD_SUCCESS}
                                handleClose={handleGoToDocument}
                                actionButtonColor={'primary'}
                                actionButtonMessage="View Document"
                                closeMessage="view"
                            />
                        </>
                    )}
                </>
            )}
        </div>
    )
}
