export const setInLocalStorage = (key: string, value: any) => {
    localStorage.setItem(`portal_r.${key}`, JSON.stringify(value));
};

export const getFromLocalStorage = (key: string): any => {
    let value = localStorage.getItem(`portal_r.${key}`) || '';

    try {
        let parsedValue = JSON.parse(value);
        return parsedValue;
    } catch (error) {
        return value;
    }
};

export const removeFromLocalStorage = (key: string) => {
    localStorage.setItem(`portal_r.${key}`, '');
};

// class LocalStorageService {
//     private static instance: LocalStorageService;
//     private constructor() {};
    
//     static getInstance() {
//         if(!LocalStorageService.instance) {
//             LocalStorageService.instance = new LocalStorageService();
//         }
//         return LocalStorageService.instance
//     }

//     setInLocalStorage = (key: string, value: any) => {
//         localStorage.setItem(`portal_r.${key}`, JSON.stringify(value));
//     }

//     getFromLocalStorage = (key: string): any => {
//         let value = localStorage.getItem(`portal_r.${key}`) || '';
    
//         try {
//             let parsedValue = JSON.parse(value);
//             return parsedValue;
//         } catch (error) {
//             return value;
//         }
//     }

//     removeFromLocalStorage = (key: string) => {
//         localStorage.setItem(`portal_r.${key}`, '');
//     }
// }

// export default LocalStorageService.getInstance()