import { useState } from 'react';
import {
    Button,
    makeStyles,
    Theme,
    Box,
    TextField,
    InputAdornment,
    Paper,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Field, getIn } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiText from '../../common/ui/UiText';
import SearchIcon from '@material-ui/icons/Search';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useClasses = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    textFieldRoot: {
        '& .MuiInputLabel-root': {
            top: '-7px',
        },
    },
    autocompleteRoot: {
        padding: '0px 0px !important',

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: theme.spacing('0', 3),
        },
    },
    splitButtonContainer: {
        width: '100%',
        margin: theme.spacing(3, '0'),
    },
    splitTransactionBtn: {
        '& .MuiButton-startIcon': {
            transform: 'rotate(90deg)',
        },
    },
    closeItem: {
        padding: '12px 0px !important',
        cursor: 'pointer',
    },
    autocomplete: {
        '& .MuiInputBase-fullWidth': {
            height: '40px',
            width: '222px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& .MuiAutocomplete-input': {
                padding: '0px',
            },
        },
    },
    createSegment: {
       borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    parentMenu: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    parentTitle: {
        width: '33%',
        color:  (colorTheme) => colorTheme.secondary
    },
}));

const menuLink = ({
    children,
    onAddNewItem,
    enteredSegment,
    showAddButton,
    createSegmentClass,
    colorTheme,
    ...other
}: any) => {
    return (
        <Paper {...other}>
            <div className={createSegmentClass}>
                {showAddButton && enteredSegment && (
                    <Button
                        fullWidth
                        startIcon={<Add />}
                        onMouseDown={(event) => {
                            event.preventDefault();
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            onAddNewItem(enteredSegment);
                        }}
                        style={{ justifyContent: 'flex-start' }}
                    >
                        New Segment{enteredSegment && ':'}
                        {enteredSegment && (
                            // to do - change to class
                            <span style={{ color: colorTheme.primaryWhite, marginLeft: '5px' }}>
                                {enteredSegment} 
                            </span>
                        )}
                    </Button>
                )}
            </div>
            {children}
        </Paper>
    );
};

const FormikAutocomplete = ({
    formValues,
    listData,
    setFieldValue,
    onAddNewItem,
    showAddButton,
    titleKey,
    idKey,
    label,
    placeholder,
    childIdToParent,
    isSegment = false,
    ...props
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useClasses(colorTheme);
    const fieldError = getIn(props.form.errors, props.field.name);
    const showError =
        getIn(props.form.touched, props.field.name) && !!fieldError;
    const [enteredSegment, setEnteredSegment] = useState<string>('');
    let isOptionExist = false;
    if (showAddButton) {
        listData.forEach((option: any) => {
            const enteredValue = enteredSegment?.toLowerCase();
            const optionName = option?.name?.toLowerCase();
            if (
                optionName.includes(enteredValue) ||
                option.id === props.field?.value
            ) {
                isOptionExist = true;
            }
        });
    }
    return (
        <Autocomplete
            {...props}
            fullWidth
            classes={{ root: classes.autocompleteRoot }}
            value={formValues[props.field.name]}
            options={listData?.filter((c: any) => {
                return isSegment
                    ? !c.is_hidden
                    : !c.is_hidden && c.can_have_children;
            })}
            onBlur={() =>
                props.form.setTouched({
                    ...props.form.touched,
                    [props.field.name]: true,
                })
            }
            getOptionLabel={(option: any) => option[titleKey] || ''}
            getOptionSelected={(option: any, value) => option === value}
            onChange={(e, item: any) => setFieldValue(props.field.name, item)}
            renderOption={(option: any) => {
                return (
                    <div className={classes.parentMenu}>
                        <div>
                            <UiText>{option[titleKey]}</UiText>
                        </div>
                        {!showAddButton && (
                            <div className={classes.parentTitle}>
                                <UiText>
                                    {childIdToParent[option.id]?.title}
                                </UiText>
                            </div>
                        )}
                    </div>
                );
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        variant="outlined"
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setEnteredSegment(event.target.value);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        data-cy='select-parent-account'
                        error={showError}
                        helperText={
                            showError ? props.form.errors[props.field.name] : ''
                        }
                    />
                );
            }}
            PaperComponent={(child: any) => {
                return menuLink({
                    ...child,
                    name: props.field.name,
                    onAddNewItem,
                    enteredSegment: enteredSegment,
                    showAddButton: showAddButton && !isOptionExist,
                    createSegmentClass: classes.createSegment,
                    colorTheme: colorTheme
                });
            }}
        />
    );
};

const NewCategoryForm = ({
    formValues,
    setFieldValue,
    addNewSegment,
    segmentList,
    parentsCategories,
    childIdToParent,
    initialValuesForCategory,
}: any) => {
    return (
        <>
            <Box mt={4} mb={6}>
                <UiFormControlSelection
                    label="Name (Required)"
                    type="text"
                    fieldName="title"
                    showFloatingLabel={true}
                    cypressId="new-account-name"
                />
            </Box>
            {!initialValuesForCategory.title && (
                <Box mb={6}>
                    <UiText>Parent Account (Required)</UiText>
                    <Field
                        name="parent"
                        required
                        validate={(value: any) => {
                            return JSON.stringify(value) === JSON.stringify({})
                                ? 'Parent account is required'
                                : '';
                        }}
                    >
                        {({ ...InputProps }: any) => (
                            <FormikAutocomplete
                                {...InputProps}
                                label="Select parent account"
                                placeholder="Select parent account"
                                formValues={formValues}
                                titleKey="title"
                                idKey="id"
                                listData={parentsCategories}
                                setFieldValue={setFieldValue}
                                onAddNewItem={addNewSegment}
                                showAddButton={false}
                                childIdToParent={childIdToParent}
                                id="select-parent-account"
                            />
                        )}
                    </Field>
                </Box>
            )}

            {childIdToParent[formValues?.parent?.id]?.level === 2 && (
                <Box mb={6}>
                    <UiText>Segment (Optional)</UiText>
                    <Field name="segment">
                        {({ ...InputProps }: any) => (
                            <FormikAutocomplete
                                {...InputProps}
                                label="Select segment"
                                placeholder="Select segment"
                                formValues={formValues}
                                listData={segmentList}
                                titleKey="name"
                                idKey="id"
                                showAddButton
                                setFieldValue={setFieldValue}
                                onAddNewItem={addNewSegment}
                                childIdToParent={childIdToParent}
                                isSegment={true}
                                id="select-segment"
                            />
                        )}
                    </Field>
                </Box>
            )}
        </>
    );
};

export default NewCategoryForm;
