import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    getCompanyDetails,
    getOnboardingStepInfo,
} from '../../../services/apiService/adp-payroll/company'
import {
    ADPCompanyDetails,
    OnboardingStepInfo,
    SETUP_TYPE,
} from '../../../models/adp-payroll/company'

const ADPCompanyContext = createContext(null)

export function useADPCompanyContext(): any {
    const state = useContext(ADPCompanyContext)
    return state
}

export interface ADPContextProps {
    companyStepInfo: OnboardingStepInfo
    companyDetails: ADPCompanyDetails | undefined
    setRefetchCompany: (refetch: boolean) => void
    setRecallStepsData: (recall: boolean) => void
    loadingData: boolean
}

const ONBOARDING = 'Onboarding'
const ACTIVE = 'Active'

const ADPCompanyProvider = ({ children }: any) => {
    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [recallStepsData, setRecallStepsData] = useState<boolean>(false)
    const [isNewOrOld, setIsNewOrOld] = useState<SETUP_TYPE>(SETUP_TYPE.NEW)
    const [isPurchaseDone, setIsPurchaseDone] = useState<boolean>(false)
    const [companyStepInfo, setCompanyStepInfo] = useState<OnboardingStepInfo>({
        adp_company_created: false,
        is_adp: false,
        adp_onboarding_status: '',
        current_step: 'company_combined_data_collection',
        onboarding_steps_status: {
            company_combined_data_collection: false,
            payroll_frequency_addition: false,
            bank_account_addition: false,
            first_employee: false,
            tos_acceptance: false,
            company_created: false,
        },
    })
    const [refetchCompany, setRefetchCompany] = useState<boolean>(false)
    const [companyDetails, setCompanyDetails] = useState<
        ADPCompanyDetails | undefined
    >()
    const formikRef = useRef<any>(null)

    const isAllStepsCompleted =
        companyStepInfo?.current_step === 'finish_onboarding'
    const isCompanyOnboarding = !!companyStepInfo.adp_onboarding_status
    const isCompanyActive = companyStepInfo.adp_onboarding_status === ACTIVE

    const fetchOnboardingStepInfo = useCallback(() => {
        getOnboardingStepInfo().then((res: OnboardingStepInfo) => {
            setCompanyStepInfo(res)
        })
    }, [])

    const fetchCompanyDetails = useCallback(() => {
        getCompanyDetails().then((res: any) => {
            setCompanyDetails(res)
        })
    }, [])

    useEffect(() => {
        if (recallStepsData) {
            fetchOnboardingStepInfo()
            setRecallStepsData(false)
        }
    }, [fetchOnboardingStepInfo, recallStepsData])

    useEffect(() => {
        if (refetchCompany) {
            fetchCompanyDetails()
            setRefetchCompany(false)
        }
    }, [fetchCompanyDetails, refetchCompany])

    useEffect(() => {
        setLoadingData(true)
        getOnboardingStepInfo().then((res: OnboardingStepInfo) => {
            setCompanyStepInfo(res)
            if (res) {
                getCompanyDetails()
                    .then((res: any) => {
                        if (res) {
                            setCompanyDetails(res)
                            setLoadingData(false)
                        } else {
                            setLoadingData(false)
                        }
                    })
                    .catch((err) => {
                        setLoadingData(false)
                    })
            } else {
                setLoadingData(false)
            }
        })
    }, [fetchCompanyDetails, fetchOnboardingStepInfo])

    const providerValue: any = {
        companyStepInfo,
        companyDetails,
        setRefetchCompany,
        setRecallStepsData,
        loadingData,
        isCompanyActive,
        isCompanyOnboarding,
        isAllStepsCompleted,
        formikRef,
        isNewOrOld,
        setIsNewOrOld,
        isPurchaseDone,
        setIsPurchaseDone,
    }

    return (
        <ADPCompanyContext.Provider value={providerValue}>
            {children}
        </ADPCompanyContext.Provider>
    )
}

export default ADPCompanyProvider
