import { useEffect, useState } from 'react'
import { Button, Theme, createStyles, makeStyles } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import { Item } from '../models/vehicle.model'
import { toRem16 } from '../../../../styles/commonStyles'
import { DeleteVehicle } from './DeleteVehicle'
import Icon from '../../../common/Icon'
import { OdometerReadings } from './OdometerReadings'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

interface VehicleDetails {
    selectedVehicle: Item
    currentAccountId: string
    refreshVehicles: () => void
    onEditVehicle: (selectedVehicle: Item) => void
    setSelectedVehicle: (vehicle: Item | undefined) => void
    vehicles?: Item[]
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        headerAndActionButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
            margin: `0 0 1rem 0`,
            position: 'sticky',
            left: 0,
            top: 0,
            background: (colorTheme) => colorTheme.primaryWhite,
            '& .actionButtons':{
                display: 'flex'
            }
        },
        editButton: {
            margin: '0 0 0 1rem',
            color: (colorTheme) => colorTheme.black100,
        },
        defaultVehicleChip: {
            background: (colorTheme) => colorTheme.grey100,
            borderRadius: '1rem',
            padding: `${toRem16(4)} ${toRem16(12)}`,
            width: 'fit-content',
            margin: `${toRem16(10)} 0`,
        },
        detailsContainer: {
            margin: `${toRem16(24)} 0 0 0`,
            '& .labelAndValue:not(:last-child)': {
               borderBottom: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey300}`,
            },
            '& .labelAndValue': {
                padding: `${toRem16(15)} 0`,
                '& .label': {
                    color: (colorTheme) => colorTheme.grey400
                },
            },
        },
        deleteButton: {
            color: (colorTheme) => colorTheme.black100,
        },
        detailsAndOdometerContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            minHeight: 0,
            overflow: 'auto',
        },
    })
)
export const RenderVehicleDetails = (props: VehicleDetails) => {
    const {
        selectedVehicle,
        currentAccountId,
        refreshVehicles,
        onEditVehicle,
        setSelectedVehicle,
        vehicles,
    } = props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
    const [odometerReadingsInDescOrder, setOdometerReadingsInDescOrder] =
        useState<[string, number][]>()

    useEffect(() => {
        if (!selectedVehicle?.odometer_readings) return
        const sortedEntries: [string, number][] = Object.entries(
            selectedVehicle?.odometer_readings
        ).sort((a: [string, number], b: [string, number]) => {
            return parseInt(b[0]) - parseInt(a[0])
        })
        setOdometerReadingsInDescOrder(sortedEntries)
    }, [selectedVehicle?.odometer_readings])

    const capitalizeFirstLetter = (str: string | null): string | null => {
        if (!str) {
            return null
        }
        return str.charAt(0).toUpperCase() + str.slice(1)
    }


    const deleteVehicleCallBack = (deletedVehicleId: string) => {
        const remainingItems = vehicles?.filter(vehicle => vehicle.id !== deletedVehicleId);
        setSelectedVehicle(remainingItems?.[0])
    }


    return (
        <>
            {selectedVehicle?.id && (
                <DeleteVehicle
                    isOpen={showDeletePopup}
                    handleCloseModal={() => setShowDeletePopup(false)}
                    vehicleId={selectedVehicle.id}
                    accountId={currentAccountId}
                    refreshVehicles={refreshVehicles}
                    deleteVehicleCallBack={deleteVehicleCallBack}
                />
            )}
            <div className={classes.headerAndActionButtons}>
                <div>
                    <UiText variant="suv_150" weight="medium_500">
                        {selectedVehicle?.make}
                        {' ' + selectedVehicle?.model}{' '}
                    </UiText>
                    {selectedVehicle?.is_default && (
                        <div className={classes.defaultVehicleChip}>
                            <UiText variant="car_100" weight="medium_500">
                                Default Vehicle
                            </UiText>
                        </div>
                    )}
                </div>
                <div className='actionButtons'>
                    <Button
                        variant="outlined"
                        startIcon={<Icon icon="delete" size="1.125rem" />}
                        onClick={() => {
                            setShowDeletePopup(true)
                        }}
                        className={classes.deleteButton}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<Icon icon="edit" size="1.125rem" />}
                        className={classes.editButton}
                        onClick={() => {
                            onEditVehicle(selectedVehicle)
                        }}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <div className={classes.detailsAndOdometerContainer}>
                <div className={classes.detailsContainer}>
                    <div className={'labelAndValue'}>
                        <UiText
                            variant="motorcycle_90"
                            weight="regular_400"
                            className="label"
                        >
                            Status
                        </UiText>
                        <UiText>
                            {capitalizeFirstLetter(selectedVehicle?.status)}
                        </UiText>
                    </div>
                    <div className={'labelAndValue'}>
                        <UiText
                            variant="motorcycle_90"
                            weight="regular_400"
                            className="label"
                        >
                            Year
                        </UiText>
                        <UiText>{selectedVehicle?.year}</UiText>
                    </div>
                    {selectedVehicle?.license_plate_number && (
                        <div className={'labelAndValue'}>
                            <UiText
                                variant="motorcycle_90"
                                weight="regular_400"
                                className="label"
                            >
                                License Plate
                            </UiText>
                            <UiText>
                                {selectedVehicle?.license_plate_number}
                            </UiText>
                        </div>
                    )}
                    {selectedVehicle?.vin && (
                        <div className={'labelAndValue'}>
                            <UiText
                                variant="motorcycle_90"
                                weight="regular_400"
                                className="label"
                            >
                                VIN
                            </UiText>
                            <UiText>{selectedVehicle?.vin}</UiText>
                        </div>
                    )}
                    {selectedVehicle?.description && (
                        <div className={'labelAndValue'}>
                            <UiText
                                variant="motorcycle_90"
                                weight="regular_400"
                                className="label"
                            >
                                Description
                            </UiText>
                            <UiText>{selectedVehicle?.description}</UiText>
                        </div>
                    )}
                </div>
                <OdometerReadings
                    odometerReadings={odometerReadingsInDescOrder}
                />
            </div>
        </>
    )
}
