import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
    Button,
    Box,
    Grid,
    IconButton,
    List,
    makeStyles,
    Theme,
    Typography,
    Hidden,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { ArrowBack, Add, Clear } from '@material-ui/icons';
import { AppData, ApplicationStore, Category } from '../../../models';
import { initCategories } from '../../../store/actions/categories';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import {
    getReconciliation,
    getTransactions,
    updateReconciliation,
} from '../../../services/apiService'
import Loader from '../../common/Loader'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import ReconciliationModal from './ReconciliationModal'
import ReconcileDetailHeader from './ReconcileDetailHeader'
import ReconcileTransactions from './ReconcileTransactions'
import { getBankAccounts } from '../../../services/bankAccountService'
import { saveReconciliationData } from '../../../services/apiService/reconciliations'
import { currencyFormatter } from '../../../utils/appUtil'
import ComponentMobileHeader from '../../common/ComponentMobileHeader'
import NewTransactionModal from '../transactions/NewTransactionModal'
import UnsaveChangeDialog from './UnsaveChangeDialog'
import SubmitActionDialog from './SubmitActionDialog'
import { showAlert } from '../../../store/actions/feedback'
import { loadCategories } from '../../../store/actions/categories'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface ReconciliationEditProps {
    appData: AppData;
    category: Category;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        display: 'grid',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
        },
        overflow: 'auto',
        '& .MuiDivider-root': {
            marginLeft: '0',
        },
        '& .list-container': {
            '& .MuiDivider-root': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '58px',
                },
            },
        },
    },
    inline: {
        display: 'inline',
    },
    timeText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 'normal',
    },
    cardIcon: {
        width: '38px',
        left: '1px',
        opacity: '0.8',
        top: '7px',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },

    refeshButton: {
        fontSize: theme.spacing(3),
    },
    searchInput: {
        marginTop: theme.spacing(3),
        height: '40px',
        width: '100%',
    },
    archivedStatus: {
        marginRight: theme.spacing(3),
    },
    statusIcon: {
        position: 'relative',
        top: '3px',
        marginRight: theme.spacing(0),
    },
    selectedMessage: {
        background: (colorTheme) =>`${colorTheme.green500} !important`,
    },
    messageBody: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    attachmentIconStyle: {
        fontSize: '15px',
        top: theme.spacing(0),
        position: 'relative',
    },
    subjectText: {
        maxWidth: 'calc(100% - 80px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    ListDetail: {
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
        },
        '& .MuiListItemText-primary': {
            maxWidth: '275px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '175px',
            },
        },
    },
    pageMarginTop: {
        marginTop: '10px',
    },
    pageLabel: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '36px',
        letterSpacing: 0.18,
        color: (colorTheme) => colorTheme.primaryBlack,
    },
    pageSubLabel: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '28px',
        letterSpacing: 0.18,
        color: (colorTheme) => colorTheme.primaryBlack,
    },
    emptyTextWrapper: {
        marginTop: theme.spacing(2),
    },
    newMessageButton: {
        height: '36px',
        marginTop: '30px',
    },
    subEmptyTextWrapper: {
        marginTop: '80px',
        textAlign: 'center',
    },
    linkMargin: {
        marginBottom: '20px',
    },
    textsb24: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: 0.18,
    },
    summary: {
        border: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        padding: '8px 16px 8px 16px',
    },
    alignEnd: {
        marginLeft: 'auto',
        flexBasis: 0,
    },
    columnPadding: {
        paddingLeft: '30px',
    }
}));

const reconciliationDiffZeroText = `To submit the reconciliation the difference 
\n between the starting and ending balance must 
\n be zero. All transactions must also be checked. 
\n excluded or deleted.`;
const reconciliationCheckText = `There are unchecked transactions left. Please 
    \n delete or exclude 
    \n them in order to submit reconciliation.`;

function ReconciliationEdit({ appData, category }: ReconciliationEditProps) {
    const { colorTheme } = useThemeContext()
    const styles = commonStyles();
    const classes = useStyles(colorTheme);
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const query = new URLSearchParams(useLocation().search);
    const { id }: any = useParams();
    const account = query.get('account')?.split('?')[0];

    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const [loading, setLoading] = useState(true);
    const [reconciliation, setReconciliation] = useState<any>([]);
    const [transactions, setTransactions] = useState<any>([]);
    const [transactionData, setTransactionData] = useState<any>(null);
    const [breadcrumb, setBreadcrumb] = useState<any>('');
    const [openReconcileModal, setOpenReconcileModal] = useState({
        type: 'new',
        open: false,
    });
    const [openTransactionModal, setOpenTransactionModal] = useState({
        type: 'new',
        open: false,
    });
    const [currentAcc, setCurrentAcc] = useState<any>();
    const [reconcileCategory, setReconcileCategory] = useState<any>();
    const [checkAllTransactions, setCheckAllTransactions] =
        useState<boolean>(false);
    const [allBankAccounts, setAllBAnkAccounts] = useState<any>([]);
    const [reconcileErrorMsg, setReconcileErrorMsg] = useState<string | null>(
        ''
    );
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [openUnsaveDialog, setOpenUnsaveDialog] = useState(false);
    const [loadingTransactions, setLoadingTransactions] =
        useState<boolean>(true)
    const [isReconcilePageDirty,setIsReconcilePageDirty] = useState(false);
    
    const [isNewTransaction, setIsNewTransaction] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false)
    const [hideDiscardChangesButton, setHideDiscardChangesButton] = useState(false);
    const reconcileEditMobileAction = useMemo(() => {
        return [
            {
                title: 'Save Progress',
                showDivider: true,
                action: 'save',
            },
            {
                title: `Submit`,
                showDivider: false,
                style: { paddingBottom: '2px' },
                action: 'submit',
            },
        ]
    }, [])
    const fetchData = useCallback(
        (sortOrder: string, reconcileCategory: any) => {
            setLoading(true);
            setLoadingTransactions(true);
            getReconciliation(
                appData.current_account_id,
                appData.current_business_id,
                id
            )
                .then((res: any) => {
                    if (res) {
                        setReconciliation(res);
                        getTransactions(
                            appData.current_account_id,
                            appData.current_business_id,
                            {
                                account,
                                reconciliation: res.id,
                                page: 1,
                                perPage: 100000,
                                from_date: res.start_date,
                                to_date: res.end_date,
                                sort_filters: JSON.stringify({
                                    date: sortOrder,
                                }),
                            }
                        )
                            .then((trans: any) => {
                                let balance_multiplier =
                                    reconcileCategory?.balance_multiplier;
                                let balance =
                                    parseFloat(res.start_balance) || 0;
                                let transactions = [...trans.items].map(
                                    (t: any) => {
                                        t.reconcile = !!t.reconciliation;
                                        if (t.is_initial) {
                                            // check initial by default
                                            t.reconcile = true;
                                        }
                                        t.balance =
                                            balance +
                                            balance_multiplier *
                                                parseFloat(t.amount);
                                        t.balance_multiplier =
                                            balance_multiplier;
                                        balance = t.balance;
                                        return t;
                                    }
                                );
                                let sortedTransactions = transactions.sort(
                                    (x: any, y: any) =>
                                        sortOrder === 'asc'
                                            ? x.date - y.date || parseInt(x.number) - parseInt(y.number)
                                            : y.date - x.date || parseInt(y.number) - parseInt(x.number)
                                );
                                let selectedTran = [...trans.items].filter(
                                    (e: any) => e.reconcile
                                );
                                setCheckAllTransactions(
                                    transactions.length === selectedTran.length
                                );
                                setTransactions(sortedTransactions);
                                setLoadingTransactions(false);
                                setLoading(false);
                            })
                            .catch((err) => {
                                setLoading(false);
                                setLoadingTransactions(false);
                            });
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setLoadingTransactions(false);
                });
        },
        [account, appData.current_account_id, appData.current_business_id, id]
    );

    useEffect(() => {
        const getBankAccData = () => {
            getBankAccounts(false).then((list) => {
                if (list) {
                    let aggregatedAndManualAccounts = list.list.filter(
                        (list: any) =>
                            list.type === 'aggregated' || list.type === 'manual' || list.type === 'plaid'
                    );
                    let currentAccount = aggregatedAndManualAccounts?.find(
                        (acc) => acc.account === account
                    );
                    setCurrentAcc(currentAccount);
                    setAllBAnkAccounts(aggregatedAndManualAccounts);
                }
            });
        };
        getBankAccData();
    }, [account]);

    useEffect(() => {
        dispatch(initCategories());
    }, [dispatch]);

    const backIconClicked = useCallback(() =>{
        if (
            !isReconcilePageDirty
        ) {
            history.push(`/reconcile?account=${account}`);
        } else {
            setOpenUnsaveDialog(true);
        }
    },[account, history, isReconcilePageDirty])

    useEffect(() => {
        setActiveRouteHeading(
            <Box display='flex' alignItems='center' component='span'>
                <IconButton
                    onClick={() => {
                       backIconClicked()
                    }}
                >
                    <ArrowBack />
                </IconButton>
                Reconcile: {breadcrumb}
            </Box>
        );
    }, [account, breadcrumb, history, setActiveRouteHeading, isReconcilePageDirty, backIconClicked]);
    
    useEffect(() => {
        let currentCategory: any = [...category.categories].find(
            (c: any) => c.id === account
        );
        setReconcileCategory(currentCategory);
        setBreadcrumb(currentCategory?.title);
        fetchData('asc', currentCategory);
    }, [account, category.categories, fetchData]);

    const handleModalClose = (result: any) => {
        setOpenReconcileModal((prev: any) => ({ ...prev, open: false }));
    };

    const checkAll = (checked: boolean) => {
        setIsReconcilePageDirty(true);
        let reconciledTrans = transactions.map((e: any) => {
            return { ...e, reconcile: checked };
        });
        setCheckAllTransactions(checked);
        setTransactions(reconciledTrans);
    };

    const checkTransactions = (checked: string, id: string) => {
       
        let reconciledTrans = transactions.map((e: any) => {
            if (e.id === id) {
                return { ...e, reconcile: checked };
            } else {
                return { ...e };
            }
        });
        let reconTrans = reconciledTrans.filter((e: any) => e.reconcile);
        setCheckAllTransactions(reconTrans.length === transactions.length);
        setTransactions(reconciledTrans);
        setIsReconcilePageDirty(true);
    };

    const reconcileBalance = () => {
        let total = 0;
        transactions.forEach(function (t: any) {
            if (t.reconcile) {
                total =
                    Number(total.toFixed(2)) +
                    t.balance_multiplier * parseFloat(t.amount);
            }
        });
        return total;
    };

    const reconcileTotal = () => {
        return parseFloat(reconciliation.start_balance) + reconcileBalance();
    };

    const reconcileDifference = () => {
        return currencyFormatter.format(reconcileDifferenceRaw());
    };

    const reconcileDifferenceRaw = () => {
        return reconciliation.end_balance - reconcileTotal();
    };

    const handleButtonClick = (type: string) => {
        if (type === 'submit') {
            submitReconciliation();
        }
        if (type === 'save') {
            saveReconciliation();
        }
    };

    const submitReconciliation = () => {
        let reconciledDiff = reconcileDifferenceRaw()
        let reconcilledDiffZero = (reconciledDiff > -0.005 && reconciledDiff < 0.005)
        if(!(reconcilledDiffZero && checkAllTransactions)) {
            if (isSmDown) {
                setShowSubmitPopup(true);
            } else {
                if(!checkAllTransactions) setReconcileErrorMsg(reconciliationCheckText);
                if(!reconcilledDiffZero) setReconcileErrorMsg(reconciliationDiffZeroText);
            }

        } else {
            setSavingChanges(true)
            setReconcileErrorMsg(null)
            setShowSubmitPopup(false)
            saveReconciliation()
            submitCurrentReconciliation()
        }
    };

    const submitCurrentReconciliation = () => {
        let params = {
            id: reconciliation.id,
            is_submitted: true,
        };

        updateReconciliation(
            appData.current_account_id,
            appData.current_business_id,
            reconciliation.id,
            params
        )
            .then((res) => {
                if (res) {
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Reconciliation has been submitted successfully`,
                        })
                    )
                    setSavingChanges(false)
                    dispatch(loadCategories())
                    handlePageBack()
                }
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err.message,
                    })
                );
                setSavingChanges(false)
                handlePageBack()
            });
    };

    const handlePageBack = () => {
        if (isSmDown) {
            history.push(`/reconcile/${reconciliation?.id}?account=${account}`);
        } else {
            history.push(`/reconcile?account=${account}`);
        }
    };


    const bulkSaveReconciliation = (
        transactionIds: string[], 
        reconciliationId: string | number | null
        ) => {
        if(transactionIds.length > 0) {
            saveReconciliationData(
                appData.current_account_id,
                appData.current_business_id,
                {
                    filters: { id: transactionIds },
                    reconciliation: reconciliationId,
                }
            ).then((res: any) => {
                setIsReconcilePageDirty(false);
                setOpenUnsaveDialog(false);
                
                if (res && !isNewTransaction) {
                    handlePageBack();
                }else{
                    newTransactionalModal();
                    setIsNewTransaction(false)
                }
                setSavingChanges(false);
            })
        }
    }
    const clickNewTransaction = () => {
        setIsNewTransaction(true);
        if(isReconcilePageDirty){
            setHideDiscardChangesButton(true);
            setOpenUnsaveDialog(true);
            return;
        }
        newTransactionalModal();
    };
    const newTransactionalModal = () =>{
        setOpenTransactionModal((prev: any) => ({
            ...prev,
            type: 'new',
            open: true,
        }));
    }
    const saveReconciliation = () => {
        setSavingChanges(true)
        let checkedIds: string[]=[], uncheckedIds: string[]=[]
        transactions.forEach((transaction: any) => {
            if(transaction.reconcile) {
                checkedIds.push(transaction.id)
            } else {
                uncheckedIds.push(transaction.id)
            }
        })
        bulkSaveReconciliation(checkedIds, reconciliation.id)
        bulkSaveReconciliation(uncheckedIds, null)        
    }
    const closeNewTransactionModal = () =>{
        setIsNewTransaction(false);
        setOpenTransactionModal((prev: any) => ({
            ...prev,
            open: false
        }))
    }
    if (loading) {
        return (
            <Grid container direction='row' style={{ height: '100%' }}>
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                    style={{ height: '100%' }}
                >
                    <Loader />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Grid container direction='row' md={12}>
                <NewTransactionModal
                    open={openTransactionModal.open}
                    handleClose={() =>
                        closeNewTransactionModal()
                    }
                    isEdit={openTransactionModal.type === 'edit'}
                    selectedData={transactionData}
                    accountList={allBankAccounts}
                    selectedAccount={currentAcc}
                    refreshTransactions={() => {
                        fetchData(sortOrder, reconcileCategory);
                    }}
                />
                {/* Used in mobile view only*/}
                <SubmitActionDialog
                    openActionDialog={showSubmitPopup}
                    handleClose={() => {
                        setShowSubmitPopup(false);
                    }}
                    handleButtonClick={handleButtonClick}
                />
                <UnsaveChangeDialog
                    open={openUnsaveDialog}
                    handleClose={() => {
                        setOpenUnsaveDialog(false);
                        setHideDiscardChangesButton(false);
                        setIsNewTransaction(false);
                    }}
                    hideDiscardChangesButton={hideDiscardChangesButton}
                    saveReconciliation={saveReconciliation}
                    discardChanges={handlePageBack}
                />
                <ReconciliationModal
                    open={openReconcileModal.open}
                    isNew={openReconcileModal.type === 'new'}
                    handleClose={(result: any) => {
                        handleModalClose(result);
                        fetchData(sortOrder, reconcileCategory);
                    }}
                    reconciliation={reconciliation}
                    category={reconcileCategory}
                />
                {/* Header used in Transaction Header Actions in Mobile*/}
                <ComponentMobileHeader
                    leftSectionItems={
                        <div
                            className={mergeStyle(
                                styles.flex,
                                styles.alignCenter
                            )}
                        >
                            <IconButton
                                onClick={() => {
                                    if (
                                        !isReconcilePageDirty
                                    ) {
                                        history.push(
                                            `/reconcile/${reconciliation?.id}?account=${account}`
                                        );
                                    } else {
                                        setOpenUnsaveDialog(true);
                                    }
                                }}
                            >
                                <Clear />
                            </IconButton>
                            <div>
                                <Typography>Reconcile</Typography>
                            </div>
                        </div>
                    }
                    showBottomDivider
                    menuItemActions={reconcileEditMobileAction}
                    handleItemClickType={handleButtonClick}
                    buttonMenuText={'Save'}
                    threeDotMenu={false}
                />
                <ReconcileDetailHeader
                    transactions={transactions}
                    reconciliation={reconciliation}
                    setOpenReconcileModal={setOpenReconcileModal}
                    saveReconciliation={() => {
                        saveReconciliation()
                        handlePageBack()
                    }}
                    reconcileErrorMsg={reconcileErrorMsg}
                    submitReconciliation={submitReconciliation}
                    threeDotMenu={false}
                    savingChanges={savingChanges}
                />
                <List className={classes.listRoot}>
                    <Hidden smDown>
                        <div
                            className={mergeStyle(
                                styles.flex,
                                styles.spaceBetween
                            )}
                        >
                            <Typography gutterBottom>
                                Transaction List
                            </Typography>
                            <Button
                                className={classes.refeshButton}
                                variant='outlined'
                                color='secondary'
                                startIcon={<Add />}
                                onClick={() => {
                                    clickNewTransaction();
                                }}
                            >
                                Transaction
                            </Button>
                        </div>
                    </Hidden>
                    <ReconcileTransactions
                        transactions={transactions}
                        loadingTransactions={loadingTransactions}
                        checkAllTransactions={checkAllTransactions}
                        checkAll={checkAll}
                        reconciliation={reconciliation}
                        selectTransactions={checkTransactions}
                        isReconcilePageDirty={isReconcilePageDirty}
                        setIsReconcilePageDirty={setIsReconcilePageDirty}
                        reloadReconciliationData={(result: any) => {
                            handleModalClose(true);
                            fetchData(sortOrder, reconcileCategory);
                        }}
                        reconcileDifference={reconcileDifference}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        setTransactionData={setTransactionData}
                        setTransactions={setTransactions}
                        setOpenTransactionModal={setOpenTransactionModal}
                        setReconcileErrorMsg={setReconcileErrorMsg}
                        reconcileCategory={reconcileCategory}
                    />
                </List>
            </Grid>
        </>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    category: state.category,
});
export default connect(mapStateToProps)(ReconciliationEdit);
