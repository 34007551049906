import React, { Fragment, useEffect, useState } from 'react';
import {
    makeStyles,
    Theme,
    Box,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    Button,
    useMediaQuery,
    Fade,
    useTheme,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import { Add, ArrowBack, BorderColor, Clear, Delete } from '@material-ui/icons';
import UiText from '../../common/ui/UiText';
import UiDialog from '../../common/ui/UiDialog';
import DeletePopup from './DeletePopup';
import SearchBox from '../../common/SearchBox';
import {
    createNewSegment,
    deleteSegmentById,
    updateSegment,
} from '../../../services/apiService/chartOfAccountants';
import { useDispatch } from 'react-redux';
import Loader from '../../common/Loader';
import { showAlert } from '../../../store/actions/feedback';
import { CreateSegment, SegmentList } from '../../../models/chartOfAccountants';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    addSegment: {
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        marginTop: '16px',
        height: 40,
        fontWeight: 500,
        fontSize: '1rem',
        textTransform: 'capitalize',
        color: (colorTheme) => colorTheme.blue200,
    },
    actionButton: {
        margin: '0 10px 10px 0',
    },
    modalRoot: {
        '& .MuiPaper-root': {
            minHeight: '700px',
            height: '700px',
            '& .MuiDialogContent-root': {
                padding: 0,
            },
        },
    },
    container: {
        position: 'relative',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 24px 10px 24px',
        position: 'sticky',
        top: '0',
        left: '0',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        zIndex: 1,
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    wrapper: {
        padding: '24px',
    },
    visibilitySegmentModal: {
        '& >div': {
            display: 'none',
        },
    },
    itemName: {
        maxWidth: '25rem',
        wordWrap: 'break-word',
    },
    loaderBtn: {
        display: 'flex',
        '& div': {
            width: 'auto',
            margin: '0 0 0 1rem',
        },
    },
}));
interface Props {
    isOpen: boolean;
    setIsSegmentPage: (value: boolean) => void;
    appData: any;
    segments: SegmentList[] | [];
    refreshSegment: () => void;
}
const SegmentPage = ({
    isOpen,
    setIsSegmentPage,
    segments,
    appData,
    refreshSegment,
}: Props) => {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const [segmentModal, setSegmentModal] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [initialName, setInitialName] = useState<string>('');
    const [popupHeading, setPopupHeading] = useState<string>('New');
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchSegments, setSearchSegments] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchSegmentResult, setSearchSegmentResult] = useState<
        SegmentList[] | []
    >([]);
    const accountId = appData.current_account_id;
    const businessId = appData.current_business_id;
    const dispatch = useDispatch();
    const [selectedSegment, setSelectedSegment] = useState<SegmentList>();
    const [allSegmentsResult, setAllSegmentResults] = useState<
        SegmentList[] | []
    >([]);
    const [editSegment, setEditSegment] = useState<SegmentList>();
    const dispatchErrorToStore = (error: { errors: { name: string } }) => {
        const message = error?.errors?.name;
        dispatch(
            showAlert({
                alertType: 'error',
                alertText: message ?? 'Something went wrong',
            })
        );
    };
    const updateSegmentsDataWithEditedVal = (value: SegmentList) => {
        searchSegmentResult.forEach((segment) => {
            if (segment.id === value.id) {
                segment.name = value.name;
            }
        });
        refreshSegment();
    };
    const updateSegmentData = (name: string) => {
        const id = editSegment?.id;
        if (!id) return;
        const body = {
            name: name,
        };
        setIsLoading(true);
        updateSegment(accountId, businessId, id, body)
            .then((res: any) => {
                setIsLoading(false);
                updateSegmentsDataWithEditedVal(res);
                setSegmentModal(false);
            })
            .catch((error) => {
                setIsLoading(false);
                dispatchErrorToStore(error);
            });
    };
    const handleFormSubmit = (values: any) => {
        const { name } = values;
        if (popupHeading === 'Edit') {
            updateSegmentData(name);
            return;
        }
        const apiParam: CreateSegment = {
            name: name,
        };
        setIsLoading(true);
        createNewSegment(accountId, businessId, apiParam)
            .then((res: SegmentList) => {
                setIsLoading(false);
                setSearchSegmentResult([...searchSegmentResult, res]);
                refreshSegment();
                setSegmentModal(false);
            })
            .catch((error) => {
                setIsLoading(false);
                dispatchErrorToStore(error);
            });
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Account name is required'),
    });
    useEffect(() => {
        setAllSegmentResults(segments);
        setSearchSegmentResult(segments);
    }, [segments]);

    const handleOpen = () => {
        setSegmentModal(true);
        setPopupHeading('New');
    };

    const handleEdit = (editItem: SegmentList) => {
        const { name } = editItem;
        setSegmentModal(true);
        setPopupHeading('Edit');
        setInitialName(name);
        setEditSegment(editItem);
    };

    const handleClose = () => {
        setSegmentModal(false);
    };

    const filterSegments = (key: string) => {
        if (!key) return setSearchSegmentResult(allSegmentsResult);
        const filteredSegment = allSegmentsResult.filter(
            (segment: SegmentList) => {
                const searchFrom = segment.name.toLowerCase();
                const searchKey = key.toLowerCase();
                return searchFrom.indexOf(searchKey) !== -1;
            }
        );
        setSearchSegmentResult(filteredSegment);
    };

    const handleSearchChange = (value: string) => {
        const nextPhrase = value;
        setInitialName(nextPhrase);
        setSearchSegments(nextPhrase);
        filterSegments(nextPhrase);
    };
    const removeDeletedSegmentFromSegments = (selectedSegment: SegmentList) => {
        const id = selectedSegment.id;
        const filteredSegments = searchSegmentResult.filter((segment) => {
            return segment.id !== id;
        });
        setSearchSegmentResult(filteredSegments);
        refreshSegment();
    };
    const handleDeleteConfirm = () => {
        const businessId = selectedSegment?.business_id;
        const id = selectedSegment?.id;
        if (!businessId || !id) return;
        setIsLoading(true);
        setDeletePopup(false);
        deleteSegmentById(accountId, businessId, id)
            .then(() => {
                setIsLoading(false);
                selectedSegment &&
                    removeDeletedSegmentFromSegments(selectedSegment);
            })
            .catch((error) => {
                setIsLoading(false);
                dispatchErrorToStore(error);
            });
    };
    const handleDelete = (item: any) => {
        const segment = item;
        if (!segment) return;
        setSelectedSegment(segment);
        setDeletePopup(true);
    };

    const segmentsTable = (segments: any) => {
        return (
            <List>
                {segments.map(
                    (item: any, index: React.Key | null | undefined) => (
                        <Fragment key={index}>
                            <ListItem>
                                <ListItemText>
                                    <Box>
                                        <UiText className={classes.itemName}>
                                            {item.name}
                                        </UiText>
                                    </Box>
                                    <Box>
                                        <UiText variant="motorcycle_90">
                                            {item.coa_business_category_count}{' '}
                                            accounts
                                        </UiText>
                                    </Box>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleEdit(item)}
                                        aria-label="comments"
                                        data-cy={`edit-item-${item.name}`}
                                    >
                                        <BorderColor />
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleDelete(item)}
                                        aria-label="comments"
                                        data-cy={`delete-item-${item.name}`}
                                    >
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </Fragment>
                    )
                )}
            </List>
        );
    };

    return (
        <div>
            <UiDialog
                open={isOpen}
                handleClose={() => setIsSegmentPage(false)}
                title=""
                size="sm"
                fullScreen={isSmallDevice ? true : false}
                hideTitleSection
                customRootClass={classes.modalRoot}
                contentStyles={{ height: '100%' }}
            >
                <div className={classes.container}>
                    <div className={classes.modalHeader}>
                        <Box display="flex" alignItems="center">
                            {Boolean(segmentModal) && (
                                <IconButton onClick={handleClose}>
                                    <ArrowBack />
                                </IconButton>
                            )}
                            <UiText variant="hatchback_125" weight="medium_500">
                                {Boolean(segmentModal)
                                    ? `${popupHeading} Segment`
                                    : 'Manage Segments'}
                            </UiText>
                        </Box>
                        <Box>
                            <IconButton onClick={() => setIsSegmentPage(false)}  data-cy="cross-modal-close-btn">
                                <Clear />
                            </IconButton>
                        </Box>
                    </div>
                    <div className={classes.wrapper}>
                        <div
                            className={
                                !segmentModal
                                    ? classes.visibilitySegmentModal
                                    : ''
                            }
                        >
                            <Fade in={segmentModal}>
                                <div>
                                    <Formik
                                        initialValues={{
                                            name: initialName,
                                        }}
                                        enableReinitialize={true}
                                        validationSchema={validationSchema}
                                        onSubmit={handleFormSubmit}
                                        validateOnMount={true}
                                    >
                                        {({
                                            submitForm,
                                            values: formValues,
                                        }) => (
                                            <div>
                                                <Box mt={4} mb={6}>
                                                    <UiFormControlSelection
                                                        label="Name"
                                                        placeholder="Segment Name"
                                                        type="text"
                                                        fieldName="name"
                                                        required={true}
                                                        errorMessage="Required"
                                                        showFloatingLabel={true}
                                                        cypressId="segment-name-field"
                                                    />
                                                </Box>
                                                <div
                                                    className={
                                                        classes.loaderBtn
                                                    }
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={handleClose}
                                                        className={
                                                            classes.actionButton
                                                        }
                                                        data-cy="cancel-segment-name-btn"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    {isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={
                                                                classes.actionButton
                                                            }
                                                            onClick={submitForm}
                                                            disabled={
                                                                !formValues.name
                                                            }
                                                            data-cy="save-segment-name-btn"
                                                        >
                                                            {`${
                                                                popupHeading ===
                                                                'New'
                                                                    ? 'Save Segment'
                                                                    : 'Save Changes'
                                                            }`}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Formik>
                                </div>
                            </Fade>
                        </div>

                        <div
                            className={
                                segmentModal
                                    ? classes.visibilitySegmentModal
                                    : ''
                            }
                        >
                            <Fade in={!segmentModal}>
                                <div>
                                    <div>
                                        <SearchBox
                                            searchTerm={searchSegments}
                                            setInput={setSearchSegments}
                                            placeHolder={'Search Segments'}
                                            notched={false}
                                            fullWidth={false}
                                            onKeyPressEvent={() => {}}
                                            onChangeCallback={
                                                handleSearchChange
                                            }
                                            clearSearch={() => {
                                                setSearchSegments('');
                                                setSearchSegmentResult(
                                                    allSegmentsResult
                                                );
                                            }}
                                            cypressId="search-segments-field"
                                        />
                                    </div>
                                    <Button
                                        startIcon={<Add />}
                                        onClick={handleOpen}
                                        className={classes.addSegment}
                                        data-cy="add-new-segment-btn"
                                    >
                                        New Segment
                                    </Button>
                                    {segmentsTable(searchSegmentResult)}
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </UiDialog>
            <DeletePopup
                heading="Delete Segment?"
                deleteMessage={
                    <>
                        <div>
                            You’ll no longer be able to generate reports for
                            this segment.
                        </div>
                        <div>
                            This segment will be removed from any accounts that
                            currently use it.
                        </div>
                        <div>This cannot be undone.</div>
                    </>
                }
                isOpen={deletePopup}
                handleClose={setDeletePopup}
                isSegment
                handleConfirm={handleDeleteConfirm}
                selectedData={selectedSegment}
            />
        </div>
    );
};
export default SegmentPage;
