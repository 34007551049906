import { createContext, useState, useContext } from 'react';
/**
 * TODO - set proper type for values
 */
export const EmployeeContext = createContext(null)

export function useEmployeeState(): any {
    const state = useContext(EmployeeContext)
    return state
}

export const EmployeeProvider = ({ children }: any) => {
    const [companyDetails, setCompanyDetails] = useState<any>(null)
    const [companyAddress, setCompanyAddress] = useState<any>(null)

    const providerValue: any = {
        companyDetails,
        companyAddress,
        setCompanyDetails,
        setCompanyAddress,
    }

    return (
        <EmployeeContext.Provider value={providerValue}>
            {children}
        </EmployeeContext.Provider>
    )
}
