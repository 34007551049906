import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import Contacts from '../bookkeeping/contacts/Contacts'
import ContactPreview from '../bookkeeping/contacts/preview/ContactPreview'
import { ProductServicesProvider } from '../bookkeeping/products-services/ProductServicesProvider'
import InvoiceProvider from '../bookkeeping/invoices/InvoiceProvider';

export default function ContactRoutes() {
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()
    return (
        <InvoiceProvider>
            <ProductServicesProvider>
                <Switch>
                    <Fragment>
                        <Hidden mdUp>
                            <Route path="/contacts" exact>
                                <Contacts />
                            </Route>
                            <Route path={`/contacts/:id`} exact>
                                <Container className={classes.container} maxWidth="lg">
                                    <ContactPreview />
                                </Container>
                            </Route>
                        </Hidden>
                        <Hidden smDown>
                            <Route path="/contacts">
                                <Container className={classes.container} maxWidth="lg">
                                    <Contacts />
                                </Container>
                            </Route>
                        </Hidden>
                    </Fragment>
                </Switch>
            </ProductServicesProvider>
        </InvoiceProvider>
    )
}