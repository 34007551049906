import { Typography, Theme, Button, Box, Tooltip } from '@material-ui/core';
import { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import UiFormField from '../common/ui/UiFormField';
import Loader from '../common/Loader';
import { ApplicationStore } from '../../models';
import { connect } from 'react-redux';
import { createMessage } from '../../services/apiService';
import UiText from '../common/ui/UiText';
import { useHistory } from 'react-router-dom';
import Help from '../../assets/icons-svg/DarkHelp.svg';
import { COLORS } from '../../variables/colors';
import moment from 'moment';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

interface NewMessageFormProps {
    sendMessage?: any;
    appData: any;
    topics: any[];
    topic?: string;
    handleClose?: (data: any) => void;
}

function NewMessageForm({
    sendMessage,
    topics,
    appData,
    topic,
    handleClose,
}: NewMessageFormProps) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles((theme: Theme) => ({
        formActions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
        },
        messageBox: {
            backgroundColor: colorTheme.blue300,
            borderRadius: '8px',
            padding: '1rem',
            marginTop: '1.5rem',
        },
        dashboardLink: {
            cursor: 'pointer',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center'
        },
        helpIcon: {
            marginRight: '9px'
        }
    }))();

    let retryValue: any;

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);
    const history = useHistory();

    const validationSchema = Yup.object({
        body: Yup.string().required('Message is required'),
        subject: Yup.string().required('Subject is required'),
        topic_id: Yup.string().required('Recipient is required'),
        is_private: Yup.boolean(),
    });

    const retry = () => {
        onSubmit(retryValue);
    };

    const goToDashboard = () => {
        handleClose && handleClose?.({});
        history.push('/dashboard');
    }

    const shouldDisplayMessage = () => {
        const today = moment();
        const startDate = moment([today.year(),  1, 15]) // from 15 Feb
        const endDate = moment([today.year(), 4, 2])     // To 1 May

        return today.isBetween(startDate, endDate);
    }

    const onSubmit = ({ body, subject, is_private, topic_id }: any) => {
        setSendingMessage(true);
        createMessage(
            is_private
                ? appData.personal_account.id
                : appData.current_account_id,
            {
                body,
                subject,
                topic_id,
                accountants: (
                    topics.find((t: any) => t.id === topic_id) || {
                        assigned_accountant_ids: [],
                    }
                ).assigned_accountant_ids,
            }
        )
            .then((res: any) => {
                sendMessage({ id: res.id });
            })
            .catch((err) => {
                setSendingMessage(false);
                retryValue = { body, subject, is_private, topic_id };
                sendMessage('fail', () => retry);
            });
    };

    return (
        <Formik
            initialValues={{
                body: '',
                subject: '',
                topic_id:
                    topics.find((item) => {
                        return item.title.includes(topic);
                    })?.id || '',
                is_private: false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formik) => {
                return (
                    <Form>
                        {shouldDisplayMessage() && (
                            <div className={styles.messageBox}>
                                <UiText>
                                    Do you have questions about your tax return
                                    status?
                                </UiText>
                                <div onClick={goToDashboard} className={styles.dashboardLink}>
                                    <img className={styles.helpIcon} src={Help} alt='help' />
                                    <UiText variant='motorcycle_90' weight='semi_bold_600'>
                                        Check Your Tax Return Status Here
                                    </UiText>
                                </div>
                            </div>
                        )}
                        <UiFormField
                            type='text'
                            placeholder='Subject'
                            label={''}
                            fieldName={'subject'}
                            fieldSize={9}
                        />

                        <UiFormField
                            type='select'
                            placeholder='Select Recipient'
                            label={''}
                            fieldName={'topic_id'}
                            optionsData={topics}
                            optionKey='title'
                            optionValue='id'
                            fieldSize={7}
                        />
                        <UiFormField
                            type='checkbox'
                            label={''}
                            fieldName={'is_private'}
                            inlineLable={
                                <Fragment>
                                    Private{' '}
                                    <Box
                                        component='span'
                                        position='relative'
                                        top={'3px'}
                                    >
                                        <Tooltip
                                            title='Private conversations will not be displayed for business partners.'
                                            aria-label='private'
                                        >
                                            <img
                                                alt={`Private`}
                                                src={
                                                    require(`../../assets/icons-svg/Private.svg`)
                                                        .default
                                                }
                                            />
                                        </Tooltip>
                                    </Box>
                                </Fragment>
                            }
                        />

                        <UiFormField
                            type='textarea'
                            placeholder='Write your message'
                            label={''}
                            fieldName={'body'}
                        />

                        <div className={styles.formActions}>
                            <div>
                                {formSubmitted && !formik.isValid ? (
                                    <Typography variant='caption' color='error'>
                                        Enter required info above
                                    </Typography>
                                ) : (
                                    ''
                                )}
                            </div>
                            {sendingMessage ? (
                                <div>
                                    <Loader />
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        endIcon={
                                            <img
                                                alt={`Send`}
                                                src={
                                                    require(`../../assets/icons-svg/Send.svg`)
                                                        .default
                                                }
                                            />
                                        }
                                        disabled={!formik.isValid}
                                        onClick={() => {
                                            formik.validateForm();
                                            formik.setTouched({
                                                body: true,
                                                subject: true,
                                                topic_id: true,
                                            });
                                            setFormSubmitted(true);
                                        }}
                                    >
                                        Send
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(NewMessageForm);
