import { Box, Button, IconButton, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react';
import Dropzone from 'react-dropzone'
import { isValidDocumentMime } from '../../../../utils/documentUtil'
import { useDispatch } from 'react-redux'
import { showInfo } from '../../../../store/actions/feedback'
import { InfoOutlined } from '@material-ui/icons'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { taxDocumentUpload } from '../../../../services/apiService'
import { VtoType } from '../models/tax-prep.interface'
import Icon from '../../../common/Icon'
import CloseIcon from '@material-ui/icons/Close'
import ThankYouPage from './ThankYouPage'
import Form8879ErrorAlert from './Form8879ErrorAlert'

import {
    DOCUMENTS_UPLOAD,
    MIME_IMAGES,
    MIME_TYPES,
    renderFor,
    MAX_FILE_SIZE,
} from '../constants/tax-prep.const'
import Loader from '../../../common/Loader'
import { COLORS } from '../../../../variables/colors'
import UiText from '../../../common/ui/UiText'
import {
    SUPPORTED_MIME_TYPE,
    ALLOWED_DOCUMENT_TYPES,
} from '../constants/tax-prep.const'
import { useWidgetContext } from '../provider/WidgetProvider'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

interface Form8879UploadFlowProps {
    handleClose: (showReviewModal : boolean) => void
    title?: string
    vtoType: renderFor
    taxYear: number
    setView?: (view: string) => void
    taxPrepStatus?: string
    taxApId: string | undefined
    flow?: string
}

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    content: {
        padding: '1.25rem 3rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 1rem',
        },
    },
    fileName: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        '& span': {
            padding: '0 0 0 0.5rem',
        },
    },
    eFileButton: {
        padding: '0.563rem 1rem',
        background: colorTheme => colorTheme.primary,
        color: colorTheme => colorTheme.primaryWhite,
        boxSizing: 'border-box',
        borderRadius: '4px',
        '&:disabled': {
            opacity: 0.25,
            color: colorTheme => colorTheme.primaryWhite,
        },
        '&:hover': {
            background: colorTheme => colorTheme.primary,
        },
    },
    deleteIcon: {
        color: colorTheme => colorTheme.red200,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    fileContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginBottom: '2rem',
    },
    dragZoneContainer: {
        borderRadius: '0.5rem',
        color: colorTheme => colorTheme.black100,
    },
    uploadBtn: {
        background: colorTheme => colorTheme.tertiary,
        color: colorTheme => colorTheme.primaryWhite,
        borderRadius: '4px',
        padding: '0.8rem',
        '&:hover': {
            background: colorTheme => colorTheme.tertiary,
        },
    },
    modalContentContainer: {
        width: '40rem',
        padding: '0.5rem 0px',
        height: '50rem',
        backgroundColor: colorTheme => colorTheme.primaryWhite,
        borderRadius: '8px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            borderRadius: 0,
            overflow: 'auto',
        },
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1.375rem',
        boxSizing: 'border-box',
        borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
        backgroundColor: colorTheme => colorTheme.primaryWhite,
    },
    closeIconButton: {
        padding: 0,
        fontSize: '1.2rem',
    },
    dropZoneContainer: {
        marginBottom: '2rem',
    },
    uploadHeaderContainer: {
        marginLeft: '0.5rem',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    uploadHeaderText: {
        marginLeft: '0.5rem',
    },
}))

const allowedMimeTypes = SUPPORTED_MIME_TYPE
const allowedDocumentTypes = ALLOWED_DOCUMENT_TYPES
export interface TaxUpload{
    show_review_widget? : boolean
}

interface FileLink {
    size:                   number;
    is_preview_available:   boolean;
    mime_type:              string;
    id:                     string;
    filename:               string;
    portal_account_id:      string;
    deleted:                boolean;
    upload_date:            number;
    type:                   string;
    is_read_by_user:        boolean;
    is_read_by_accountant:  boolean;
    path:                   string;
    is_owned_by_accountant: boolean;
    
}

interface DocumentUpload{
    file_links:         FileLink[]
    show_review_widget: boolean;
}
export default function Form8879UploadFlow(props: Form8879UploadFlowProps) {
    const { handleClose, vtoType, setView, taxYear, taxApId, flow } = props
    const { colorTheme } = useThemeContext()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [files, setFiles] = useState<File[] | null>(null)
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false)
    const { isError, setIsError } = useWidgetContext()
    const [is400Error, setIs400Error] = useState<boolean>(false)
    const [error400String, setError400String] = useState<string>('')
    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setFiles(null)
        setUploadSuccess(false)
        handleClose(showReviewModal)
    }
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const styles = useStyle(colorTheme)

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )
    const isFileSizeSupported = (files: File[]): boolean => {
        const fileSize = files[0].size / 1024 / 1024
        return fileSize <= MAX_FILE_SIZE
    }

    const handleFileUnsupported = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <UiText>File(s) has unsupported format.</UiText>
                        <UiText>
                            Please upload only{' '}
                            <b>{ALLOWED_DOCUMENT_TYPES.join(', ')}</b>
                        </UiText>
                    </div>
                ),
            })
        )
    }

    const handleFileUnsupportedSize = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <UiText>File size is too large.</UiText>
                        <UiText>Please upload file size less than 15 MB</UiText>
                    </div>
                ),
            })
        )
    }

    const getFormattedVtoType = (vtoType: string): VtoType => {
        if (vtoType === renderFor.BUSINESS) {
            return DOCUMENTS_UPLOAD.BUSINESS
        }
        return DOCUMENTS_UPLOAD.PERSONAL
    }

    const uploadFiles = async (files: File[]) => {
        const year = taxYear.toString()
        const type = getFormattedVtoType(vtoType)
        setIsLoading(true)
        if (!taxApId) {
            return
        }
        taxDocumentUpload(type, year, accountId, taxApId, files)
            .then((res: unknown) => {
                const data = res as DocumentUpload[]
                setIsLoading(false)
                setShowReviewModal(data[0].show_review_widget ?? false)
                setUploadSuccess(true)
            })
            .catch((error) => {
                if(error.status === 400){
                    setError400String(error.statusText);
                    setIs400Error(true);
                }
                setIsLoading(false)
                setIsError(true)
            })
    }
    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }

        if (isFileTypeUnsupported(files)) {
            handleFileUnsupported()
            return
        }

        if (!isFileSizeSupported(files)) {
            handleFileUnsupportedSize()
            return
        }

        setFiles(files)
    }

    const getUploadedDocImageType = (mimeType: string) => {
        if (
            mimeType === MIME_TYPES.IMG_JPEG ||
            mimeType === MIME_TYPES.IMG_PNG
        ) {
            return MIME_IMAGES.ATTACHMENT_IMG
        }
        if (mimeType === MIME_TYPES.APP_PDF) {
            return MIME_IMAGES.PDF
        }
        return MIME_IMAGES.GENERIC
    }
    const getImageBasedOnMimeType = (mimeType: string) => {
        const imageType = getUploadedDocImageType(mimeType)
        return (
            <img
                src={
                    require(`../../../../assets/icons-svg/${imageType}.svg`)
                        .default
                }
                alt="document"
            />
        )
    }
    const renderSelectedFiles = () => {
        if (!files) return
        return files.map((file: File) => {
            return (
                <div className={styles.fileContainer}>
                    <div className={styles.fileName}>
                        {getImageBasedOnMimeType(file.type)}{' '}
                        <span>{file.name}</span>
                    </div>
                    <Button
                        className={styles.deleteIcon}
                        startIcon={
                            <Icon
                                icon="delete"
                                svgColor={colorTheme.red200}
                            />
                        }
                        onClick={() => setFiles(null)}
                    >
                        Remove
                    </Button>
                </div>
            )
        })
    }

    const uploadDocument = () => {
        if (!files) {
            return
        }
        uploadFiles(files)
    }

    const acceptedFormat = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
    ]

    return (
        <>
            {uploadSuccess ? (
                <ThankYouPage handleClose={handleModalClose} />
            ) : (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={styles.modalContentContainer}
                >
                    <div className={styles.modalHeader}>
                        <div className={styles.uploadHeaderContainer}>
                            {flow !== 'upload' && (
                                <Box
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setView && setView('landing')
                                    }}
                                >
                                    {' '}
                                    <Icon
                                        icon="leftArrow"
                                        svgColor={colorTheme.grey400}
                                    />
                                </Box>
                            )}

                            <UiText
                                className={styles.uploadHeaderText}
                                variant="car_100"
                                weight="medium_500"
                            >
                                Upload Signed Form 8879
                            </UiText>
                        </div>

                        <IconButton
                            aria-label="close"
                            classes={{ root: styles.closeIconButton }}
                            onClick={handleModalClose}
                            data-cy="cross-modal-close-btn"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={styles.content}>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                mt={2}
                                mb={4}
                            >
                                {files ? (
                                    renderSelectedFiles()
                                ) : (
                                    <div className={styles.dropZoneContainer}>
                                        <Dropzone onDrop={handleUploadFile}>
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => {
                                                return (
                                                    <Box
                                                        {...getRootProps({})}
                                                        border={`2px dashed ${colorTheme.grey200}`}
                                                        textAlign="center"
                                                        p={4}
                                                        className={
                                                            styles.dragZoneContainer
                                                        }
                                                    >
                                                        <UiText
                                                            weight="regular_400"
                                                            variant="suv_150"
                                                            textColor="inherit"
                                                        >
                                                            Drop files here to
                                                            upload
                                                        </UiText>
                                                        <Box my={3}>
                                                            <input
                                                                {...getInputProps()}
                                                                name="files[]"
                                                                accept={acceptedFormat.toString()}
                                                            />
                                                        </Box>
                                                        <Box my={3}>
                                                            <Button
                                                                className={
                                                                    styles.uploadBtn
                                                                }
                                                                startIcon={
                                                                    <Icon
                                                                        icon="upload"
                                                                        svgColor={
                                                                            colorTheme.primaryWhite
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <UiText
                                                                    variant="motorcycle_90"
                                                                    weight="semi_bold_600"
                                                                >
                                                                    Upload Files
                                                                </UiText>
                                                            </Button>
                                                        </Box>
                                                        <Box mt={4}>
                                                            <UiText
                                                                variant="motorcycle_90"
                                                                textColor="secondary"
                                                                weight="regular_400"
                                                            >
                                                                Supported file
                                                                types: .pdf,
                                                                .png, .jpeg,
                                                                .jpg
                                                            </UiText>
                                                            <UiText
                                                                variant="motorcycle_90"
                                                                textColor="secondary"
                                                                weight="regular_400"
                                                            >
                                                                15 MB Max File
                                                                Size
                                                            </UiText>
                                                        </Box>
                                                    </Box>
                                                )
                                            }}
                                        </Dropzone>
                                    </div>
                                )}

                                <Button
                                    onClick={uploadDocument}
                                    disabled={!files}
                                    className={styles.eFileButton}
                                >
                                    <UiText
                                        variant="car_100"
                                        weight="semi_bold_600"
                                    >
                                        E-File My Tax Returns
                                    </UiText>
                                </Button>
                                {isError && (
                                    <Box mt={4}>
                                        <Form8879ErrorAlert 
                                             is400Error={is400Error}
                                             error400String={error400String}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
