import { Fragment, useContext, useEffect, useState } from 'react'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import {
    Box,
    Button,
    Hidden,
    IconButton,
    Theme,
    debounce,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowDownward } from '@material-ui/icons'
import { updateEmployee } from '../../../services/apiService/adp-payroll/employee'
import * as Yup from 'yup'
import { dispatchInfo, showError } from '../../../services/formService'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import { commonStyles, toRem16 } from '../../../styles/commonStyles'
import { Formik } from 'formik'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import EditEmployeeForm from './EditEmployeeForm'
import { Employee } from '../../../models/adp-payroll/employee'
import Loader from '../../common/Loader'
import { UnsavedSectionWarning } from '../../payroll/company/UnsavedSectionWarning'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { showInfo } from '../../../store/actions/feedback'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up(1601)]: {
            justifyContent: 'flex-start',
        },
    },
    container: {
        '& .MuiPaper-root': {
            height: 816,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                margin: '0px',
                top: '0px',
                '& .MuiDialogTitle-root': {
                    display: 'none',
                },
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                    '& form': {
                        padding: `${toRem16(20)} ${toRem16(20)}`,
                    },
                },
                '& .top-section': {
                    '& .alert': {
                        height: '36px',
                        width: 'inherit',
                        marginBottom: theme.spacing(2),
                    },
                    '& .delete-section': {
                        display: 'flex',
                    },
                    [theme.breakpoints.down('sm')]: {
                        height: '100%',
                        maxHeight: '100%',
                        padding: '0px',
                    },
                },
            },
        },
    },
    dialogDetails: {
        '& .MuiDialogContent-root': {
            maxHeight: '600px',
            height: '550px',
        },
        '& .MuiPaper-root': {
            '& .MuiDialogTitle-root': {
                padding: '12px 16px ',
            },
        },
    },
    scrollContainer: {
        position: 'relative',
    },
    scrollBottom: {
        position: 'fixed',
        bottom: '13rem',
        right: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        width: toRem16(56),
        height: toRem16(56),
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: (colorTheme) => `${colorTheme.primary}`,
        color: (colorTheme) => `${colorTheme.primaryWhite}`,
    },
    loader: {
        width: toRem16(60),
        height: toRem16(60),
    },
    mobileLoader: {
        width: '113px',
        marginRight: '10px',
        '& .MuiCircularProgress-root': {
            width: `${toRem16(30)} !important`,
            height: `${toRem16(30)} !important`,
        },
    },
    topHeader:{
        borderBottom: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        padding: '10px',
    },
    actionButtonContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gridGap: toRem16(15)  
    }
}))

const MODAL_CONTENT_DIV_ID = 'modal-content'
const HandleScroll = (props: any) => {
    const handleScrollToElement = debounce((event: any) => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        if (scrollDemo) {
            const currentPosition = scrollDemo.scrollTop
            if (currentPosition === 0) {
                props.setIsScrollable(true)
            } else {
                props.setIsScrollable(false)
            }
        }
    }, 100)

    useEffect(() => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        scrollDemo.addEventListener('scroll', handleScrollToElement, {
            passive: true,
        })
        return () => {
            scrollDemo.removeEventListener('scroll', handleScrollToElement)
        }
    }, [handleScrollToElement])

    return <div />
}

interface Props {
    openModal: boolean
    setOpenModal: (value: boolean) => void
    setEditLoading: (value: boolean) => void
    refreshData: (id: string) => void
    selectedData?: Employee
    setSelectedData?: React.Dispatch<React.SetStateAction<Employee | undefined>>
    reloadState?: () => void
}

enum PaymentType {
    DIRECT = 'direct',
    PHYSICAL = 'physical',
}

const EditEmployeeModal = (props: Props) => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [loading, setLoading] = useState(false)
    const [addNewJob, setAddNewJob] = useState(false)
    const [isScrollable, setIsScrollable] = useState<boolean>(true)
    const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState('')

    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const common = commonStyles()
    const theme = useTheme()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    const { isMobileDevice } = useDeviceSize()

    let submitCloser: any = null
    let userInputs: any = {}
    const dispatch = useDispatch()

    const {
        refreshData,
        openModal,
        setOpenModal,
        setEditLoading,
        selectedData,
        setSelectedData,
        reloadState,
    } = props
    let initialData = {}
    if (selectedData) {
        const hasOwnership = selectedData?.ownershipDetails
            ?.ownershipPercentage as number
        initialData = {
            first_name: selectedData.legalName.firstName,
            middle_initial: selectedData.legalName.middleName,
            last_name: selectedData.legalName.lastName,
            email: selectedData.personalEmailAddress,
            dob: selectedData.birthDate ?? '',
            street_1: selectedData?.associateLegalAddress?.address1,
            street_2: selectedData?.associateLegalAddress?.address2 ? selectedData?.associateLegalAddress?.address2 : '',
            city: selectedData?.associateLegalAddress?.city ?? '',
            state: selectedData?.associateLegalAddress?.stateCode ?? '' ,
            zip: selectedData?.associateLegalAddress?.postalCode ?? '' ,
            account_number:
                selectedData?.moneyDistributionInstructions?.accountNumber,
            bank_name:
                selectedData?.moneyDistributionInstructions
                    ?.moneyDistributionInstructionName,
            routing_number:
                selectedData?.moneyDistributionInstructions?.routingNumber,
            primaryOwnerIndicator: selectedData?.ownershipDetails
                ?.primaryOwnerIndicator
                ? 'yes'
                : 'no',
            isOwner: !!hasOwnership ? 'yes' : 'no',
            ownership: !!hasOwnership ? hasOwnership * 100 : 0,
        }
    }

    const validationSchema = Yup.object({
        first_name: Yup.string().required('First name is required'),
        middle_initial: Yup.string(),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .max(255)
            .required('Email is required'),
        dob: Yup.string().required('Date of birth is required'),
        ssn: Yup.string(),
        street_1: Yup.string().required('Street is required'),
        street_2: Yup.string(),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip: Yup.string().required('Zip Code is required'),
        bank_name: Yup.string().required('Bank name is required'),
        routing_number: Yup.string().required('Routing number is required'),
        account_number: Yup.string().required('Bank number is required'),
        isOwner: Yup.string().required('Is owner of the company is required'),
        ownership: Yup.number().when('isOwner', {
            is: (isOwner: string) => isOwner === 'yes',
            then: Yup.number()
                .test(
                    'is-decimal',
                    'Only numbers with up to two decimal places are allowed',
                    (value: any) => {
                        if (isNaN(value)) return false
                        return value === parseFloat(value.toFixed(2))
                    }
                )
                .required('Ownership percentage should be greater than 0')
                .min(0, 'Ownership percentage should be greater than 0')
                .max(100, 'Ownership percentage should be less than 100'),
            otherwise: Yup.number().notRequired(),
        }),
    })

    useEffect(() => {
        setActiveRouteHeading('Employees')
    }, [setActiveRouteHeading])

    const handleUnsavedWanringModalAction = (
        action: 'close' | 'keep' | 'discard' | 'save'
    ) => {
        switch (action) {
            case 'close':
                setShowUnsavedWarningModal('')
                break
            case 'keep':
                setShowUnsavedWarningModal('')
                break
            case 'discard':
                setOpenModal(false)
                setShowUnsavedWarningModal('')
                break
            case 'save':
                setShowUnsavedWarningModal('')
                submitCloser?.()
                break
        }
    }

    function isObjectEqual(firstObj: any, secondObj: any) {
        return JSON.stringify(firstObj) === JSON.stringify(secondObj)
    }

    const handleModalClose = () => {
        if (isObjectEqual(userInputs, initialData)) {
            setOpenModal(false);
            setEditLoading(false);
        } else {
            setShowUnsavedWarningModal('open')
        }
    }

    const actions = () =>
        loading ? (
            <div className={classes.loader}>
                <Loader />
            </div>
        ) : (
            <>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleModalClose}
                    data-cy="cancel-new-cat-btn"
                >
                    Cancel
                </Button>

                <Box my={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => submitCloser?.(e)}
                        disabled={loading}
                    >
                        Save
                    </Button>
                </Box>
            </>
        )

    const onSubmit = (data: any) => {
        setLoading(true)
        const enteredData: any = {
            legalName: {
                firstName: data.first_name,
                middleName: data.middle_initial,
                lastName: data.last_name,
            },
            email: data.email,
            birthDate: data.dob,
            personalAddress: {
                address1: data.street_1,
                address2: data.street_2 ? data.street_2 : "",
                city: data.city,
                stateCode: data.state,
                postalCode: data.zip,
            },
        }
        if (data.ssn) {
            enteredData.documentNumber = data.ssn.replace(
                /^(\d{3})(\d{2})(\d{4})$/,
                '$1-$2-$3'
            )
        }
        enteredData.paymentMethod = {
            accountNumber: data.account_number,
            routingNumber: data.routing_number,
            moneyDistributionInstructionName: data.bank_name,
        }
        if (data.isOwner === 'yes' && data?.primaryOwnerIndicator !== 'yes') {
            enteredData.ownershipDetails = {
                primaryOwnerIndicator: false              ,
                ownershipPercentage: data.ownership,
            }
        }

        const employeeId = selectedData?.associateID ?? ''
            setOpenModal(false)
            setLoading(false)
            setEditLoading(true);
        updateEmployee(enteredData, employeeId)
            .then((data) => {
                if (data) {
                    setEditLoading(false);
                    refreshData(employeeId)
                    reloadState?.()
                }
            })
            .catch((error) => {
                setEditLoading(false);
                setLoading(false)
                showError(
                    error?.message ||
                        error?.statusText ||
                        'Something went wrong',
                    'error'
                )
                error?.errors?.length && dispatchInfo(error?.errors)
                if(error.errors?.length > 0 && error.errors[0]?.address1) {
                    dispatch(
                        showInfo({
                            infoData: (
                                <Fragment>
                                    <UiText> Possible Correct address could be </UiText>
                                    <ul>
                                        {error.errors.map((address: any) => (
                                            <li key={address}>{address?.address1}, {address?.address2}, {address?.city}, {address?.postalCode}, {address?.stateCode}</li>
                                        ))}
                                    </ul>
                                </Fragment>
                            ),
                        })
                    )
                }
            })
    }
    return (
        <div className={classes.scrollContainer}>
            <UnsavedSectionWarning
                open={showUnsavedWarningModal ? true : false}
                handleModalAction={handleUnsavedWanringModalAction}
                size="xs"
            />
            <UiDialog
                open={openModal}
                handleClose={handleModalClose}
                disableBackdropClick={true}
                titleNode={
                    <>
                        <div className={common.flex}>
                            <UiText variant="hatchback_125" weight="medium_500">
                                Edit employee
                            </UiText>{' '}
                        </div>
                        <IconButton
                            aria-label="close"
                            onClick={handleModalClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
                size="sm"
                customRootClass={
                    isXsDown ? classes.container : classes.dialogDetails
                }
                actions={isMobileDevice ? null : actions()}
            >
                <HandleScroll setIsScrollable={setIsScrollable} />
                <Formik
                    initialValues={initialData}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ handleSubmit, values, errors, setFieldValue }) => {
                        submitCloser = handleSubmit
                        userInputs = values
                        return (
                            <>
                            <Hidden mdUp>
                                        <div className={classes.topHeader}>
                                            <div className={`${common.flex} ${common.alignCenter}`}
                                            >
                                                <div>
                                                    <IconButton
                                                        aria-label='close'
                                                        color='inherit'
                                                        onClick={
                                                            handleModalClose
                                                        }
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                                <div className='title'>
                                                    Edit employee
                                                </div>
                                            </div>
                                            <div
                                                className={classes.actionButtonContainer}
                                            >
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    fullWidth
                                                    onClick={handleModalClose}
                                                >
                                                    Cancel
                                                </Button>
                                                {loading ? (
                                                    <div
                                                        className={
                                                            classes.mobileLoader
                                                        }
                                                    >
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        fullWidth
                                                        color='primary'
                                                        type='submit'
                                                        disabled={loading}
                                                        onClick={(e) => {
                                                            submitCloser?.(e)
                                                        }}
                                                        data-cy="save-changes-button"
                                                    >
                                                        Save
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </Hidden>
                                <EditEmployeeForm
                                    addNewJob={addNewJob}
                                    setAddNewJob={setAddNewJob}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                                {isScrollable && (
                                    <div
                                        className={classes.scrollBottom}
                                        onClick={() => {
                                            const scrollableDiv =
                                                document.getElementById(
                                                    MODAL_CONTENT_DIV_ID
                                                )!
                                            scrollableDiv.scrollTo({
                                                top: scrollableDiv.scrollHeight,
                                                behavior: 'smooth',
                                            })
                                        }}
                                    >
                                        <ArrowDownward />
                                    </div>
                                )}
                            </>
                        )
                    }}
                </Formik>
            </UiDialog>
        </div>
    )
}

export default EditEmployeeModal
