import React from 'react';
import Alert from '@material-ui/lab/Alert'
import { makeStyles, Theme } from '@material-ui/core'
import UiText from './UiText'
import Icon from '../Icon'
import IconList from '../IconList'
import { useThemeContext } from '../whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    textColor: {
        color: (colorTheme) => colorTheme.primaryBlack,
    },
}))

const UiAlert = ({
    header,
    description,
    customContent,
    severity = 'warning',
    icon = 'info',
    iconColor,
    variant = 'standard',
    customStyles,
}: {
    header?: string
    description?: string | React.ReactNode
    customContent?: React.ReactNode
    severity?: 'info' | 'success' | 'warning' | 'error'
    icon?: keyof typeof IconList
    iconColor?: string
    variant?: 'standard' | 'filled' | 'outlined'
    customStyles?: any
}) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    return (
        <Alert
            className="alert"
            severity={severity}
            icon={<Icon icon={icon} svgColor={iconColor} />}
            variant={variant}
            classes={customStyles}
        >
            {customContent ? (
                customContent
            ) : (
                <>
                    <div>
                        <UiText
                            weight="medium_500"
                            variant="car_100"
                            className={classes.textColor}
                        >
                            {header}
                        </UiText>
                    </div>
                    {description}
                </>
            )}
        </Alert>
    )
}

export default UiAlert
