import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
    Button,
    Divider,
    Grid,
    Hidden,
    makeStyles,
    Theme,
    Typography,
    TablePagination
} from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import {
    Route,
    Switch,
    useRouteMatch,
    useHistory,
    useLocation,
} from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { ApplicationStore } from '../../../models'
import { IBankAccount } from '../../../models/bank-account-models'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import Loader from '../../common/Loader'
import ReconciliationsEmptyState from './ReconciliationsEmptyState'
import ReconciliationsListView from './ReconciliationsListView'
import ReconciliationsDetails from './ReconciliationsDetails'
import ReconciliationModal from './ReconciliationModal'
import AccountSelector from '../transactions/AccountSelector'
import { getReconciliations } from '../../../services/apiService'
import { getBankAccounts } from '../../../services/bankAccountService'
import DeleteLastReconciliationDialog from './DeleteLastReconciliationDialog'
import ComponentMobileHeader from '../../common/ComponentMobileHeader'
import { loadCategories } from '../../../store/actions/categories'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

let per_page: any = 10;
const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up(1601)]: {
            justifyContent: 'flex-start',
        },
    },
    listRoot: {
        maxWidth: '464px',
        paddingBottom: theme.spacing(3),
        width: 'inherit',
        height: '100%',
    },
    newMessageButton: {
        height: '36px',
    },
    refeshButton: {
        fontSize: theme.spacing(3),
    },
    searchInput: {
        marginTop: theme.spacing(3),
        height: '40px',
        width: '100%',
        '&.Mui-focused fieldset': {
            border: (colorTheme) => `1px solid ${colorTheme.primaryBlack} !important`,
        },
    },
    dividerStyle: {
        marginTop: theme.spacing(3),
        marginLeft: '-12px',
        marginRight: '-12px',
    },
    warningModal: {
        '& div:first-child': {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.grey200,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
    accountContainer: {
        margin: theme.spacing(5, '0', 3),
    },
    pagination: {
        overflow: 'hidden',
    },
    search: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
}))

function Reconciliations(props: any) {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const history = useHistory()
    let { path } = useRouteMatch()
    const dispatch = useDispatch()
    //common data
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [emptyState, setEmptyState] = useState(false)
    const [loading, setLoading] = useState(false)
    const [bankLoading, setBankLoading] = useState(false)
    const [selectedData, setSelectedData] = useState<any>(null)
    const [showNewModal, setShowNewModal] = useState(false)
    const [openWarningDialog, setOpenWarningDialog] = useState(false)
    const [accountId, setAccountId] = useState('')
    const [selectedAccount, setSelectedAccount] = useState('')

    //combined data
    const [reconciliationsData, setReconciliationsData] = useState<any>({
        perPage: per_page,
        items: [],
        itemsCount: 0
    })
    const [category, setCategory] = useState<any>([])
    const [allBankAccounts, setAllBAnkAccounts] = useState<IBankAccount[]>([])
    const query = new URLSearchParams(useLocation().search)
    const accountQuery = query.get('account')

    const getReconciliationsWithFilter = useCallback(
        (page: number, account: string) => {
            return getReconciliations(props.accountId, props.businessId, {
                account,
                page,
                perPage: per_page,
            })
        },
        [props.accountId, props.businessId]
    )

    const accountChangeHandler = useCallback(
        (account: any, page: any = 1) => {
            setSelectedAccount(account)
            setAccountId(account.id)
            setLoading(true)
            Promise.all([getReconciliationsWithFilter(page, account.account)])
                .then((res: any) => {
                    setReconciliationsData((prev: any) => ({
                        page: page,
                        ...prev,
                        items: res[0]?.items,
                        itemsCount: res[0].itemsCount
                    }))
                    setEmptyState(res[0]?.items?.length === 0)
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        },
        [getReconciliationsWithFilter]
    )

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {  
            paginatorChange(newPage, null, true);
           
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setReconciliationsData((prev: any) => ({
            ...prev,
            perPage: parseInt(event.target.value, 10),
        }));
        let account: any = allBankAccounts?.filter((acc: any) => acc.id === accountQuery) || [];
        if (account.length) {
            paginatorChange(1, parseInt(event.target.value, 10), false);
            
        } 
    };

    const paginatorChange = (
       page: any,
       perPage: any,
       changePage: any
    ) => {
        if(changePage){
            page = page + 1;
        }else{
            per_page = perPage;
        }
        let account: any = allBankAccounts?.filter((acc: any) => acc.id === accountQuery) || []
        if(account.length) {
            query.set('page', page.toString());
            history.push({
                pathname: history.location.pathname,
                search: query.toString(),
            });
            setReconciliationsData((prev: any) => ({
                ...prev,
                page: page,
            }))
            accountChangeHandler(account?.[0], page)
        }
    };

    const handleNewModal = () => {
        if (reconciliationsData.items?.length > 0) {
            let inProgressData = reconciliationsData.items.filter(
                (item: any) => !item.is_submitted
            )
            if (inProgressData.length > 0) {
                setOpenWarningDialog(true)
            } else {
                setShowNewModal(true)
            }
        } else {
            setShowNewModal(true)
        }
    }

    const handleModalClose = (result: any) => {
        setShowNewModal(false)
    }

    const loaderMoreItems = () => {
        let currentPage = reconciliationsData.page + 1
        setReconciliationsData((prev: any) => ({
            ...prev,
            page: currentPage,
        }))
        getReconciliationsWithFilter(currentPage, accountId)
    }

    useEffect(() => {
        dispatch(loadCategories())
    }, [dispatch])

    /**
     * This Effect will set the category after finding exact match WRT to the account selected
     * Category is required for creating a new reconciliation (i.e end date)
     */
    useEffect(() => {
        if (props.category.categories.length > 0) {
            let account: any = allBankAccounts?.find((acc: any) => acc.id === accountQuery)
            if(account) {
                let category = [...props.category.categories].find(
                    (c: any) => c.id === account?.account
                )
                setCategory(category)
            }
        }
    }, [accountId, accountQuery, allBankAccounts, props.category])

    useEffect(() => {
        setActiveRouteHeading('Reconciliations')
        const getBankAccData = () => {
            setBankLoading(true)
            getBankAccounts(false).then((list) => {
                                if (list) {
                    let aggregatedAndManualAccounts = list.list.filter(
                        (list) =>
                            list.type === 'aggregated' || list.type === 'manual' || list.type === 'plaid'
                    )
                    setAllBAnkAccounts(aggregatedAndManualAccounts)
                    setBankLoading(false)
                    
                }
            })
        }
        getBankAccData()
    }, [setActiveRouteHeading])

    /**
     * This effect will handle the reload scenario
     * Will take accountQuery from URL and fetch the reconciliation Details.
     */
    useEffect(() => {
        if(accountQuery && !selectedAccount) {
            let account: any = allBankAccounts?.filter((acc: any) => acc.id === accountQuery) || []
            if(account.length) {
                accountChangeHandler(account?.[0])
            }
        }
    },[accountQuery, accountChangeHandler, allBankAccounts, selectedAccount])

    // No bank account setup
    if(bankLoading) {
        return (
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ height: '100%' }}
                >
                    <Loader />
                </Grid>
            </Grid>
        )
    } else {
        if (allBankAccounts?.length === 0) {
            return (
                <Grid container direction="row" style={{ height: '100%' }}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                
                    >
                        <ReconciliationsEmptyState />
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <>
            <Grid container direction="row" style={{ height: '100%' }}>
                <DeleteLastReconciliationDialog
                    openWarningDialog={openWarningDialog}
                    setOpenWarningDialog={setOpenWarningDialog}
                    reloadReconciliationData={() => {
                        accountChangeHandler(selectedAccount);
                        setSelectedData(null)
                        dispatch(loadCategories())

                    }}
                    data={reconciliationsData?.items}
                    setShowNewModal={setShowNewModal}
                />
                <ReconciliationModal
                    open={showNewModal}
                    handleClose={(result: any) => {
                        handleModalClose(result)
                        dispatch(loadCategories())
                    }}
                    category={category}
                    isNew={true}
                    reconciliation={category}
                />
                <ComponentMobileHeader title={'Reconciliations'} />
                <Grid container direction="row" style={{ height: '100%' }}>
                    <Fragment>
                        <Grid
                            container
                            item
                            md={5}
                            sm={12}
                            justify="flex-start"
                            alignItems="flex-start"
                            className={classes.content}
                        >
                            <Grid
                                container
                                item
                                alignItems="flex-start"
                                className={classes.listRoot}
                            >
                                    <Grid xs={12} container className={classes.accountContainer}>
                                        {allBankAccounts.length > 0 && <AccountSelector
                                            accountList={allBankAccounts}
                                            disabled={false}
                                            onChange={(account) => {
                                                if (account) {
                                                    if (query.get('account') !== account?.id) {
                                                        setSelectedData(null)
                                                        query.set('account', account?.id);
                                                        history.push({
                                                            pathname: '/reconcile',
                                                            search: query.toString(),
                                                        })
                                                        accountChangeHandler(account);
                                                    }
                                                }
                                                
                                            }}
                                            currentAccountId={accountQuery}
                                            setSelectedAccount={setSelectedAccount}
                                        />}
                                        <Grid
                                            item
                                            container
                                            direction='row'
                                            justify='space-between'
                                            alignItems='center'
                                            xs={12}
                                            className={classes.search}
                                        >
                                            {!emptyState && (
                                                <Button
                                                    className={
                                                        classes.newMessageButton
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AddIcon />}
                                                    onClick={handleNewModal}
                                                >
                                                    Reconcile
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                    
                                {loading ? (
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <Loader />
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item>
                                            {reconciliationsData && reconciliationsData.itemsCount ? (
                                                <TablePagination
                                                    component='div'
                                                    labelRowsPerPage='Show:'
                                                    count={
                                                        reconciliationsData?.itemsCount as number
                                                    }
                                                    page={
                                                        (reconciliationsData?.page -
                                                            1) as number
                                                    }
                                                    className={
                                                        classes.pagination
                                                    }
                                                    onChangePage={
                                                        handleChangePage
                                                    }
                                                    rowsPerPage={
                                                        reconciliationsData?.perPage as number
                                                    }
                                                    onChangeRowsPerPage={
                                                        handleChangeRowsPerPage
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>{' '}
                                        <ReconciliationsListView
                                            emptyState={emptyState}
                                            items={reconciliationsData}
                                            loadMore={loaderMoreItems}
                                            path={path}
                                            handleNewModal={handleNewModal}
                                            setSelectedData={setSelectedData}
                                            selectedData={selectedData}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Hidden smDown implementation="js">
                            <Grid
                                sm={1}
                                item
                                container
                                direction="row"
                                justify="center"
                                alignItems="stretch"
                            >
                                <Divider orientation="vertical" flexItem />
                            </Grid>

                            <Grid item sm={6}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Switch>
                                        <Route exact path="/reconcile">
                                            <Grid
                                                container
                                                direction="column"
                                                justify="center"
                                                alignItems="center"
                                                style={{ height: '100%' }}
                                            >
                                                {selectedData ? (
                                                    <ReconciliationsDetails
                                                        category={category}
                                                        selectedData={
                                                            selectedData
                                                        }
                                                        setSelectedData={
                                                            setSelectedData
                                                        }
                                                        reloadCurrentState={() => {
                                                            setSelectedData(
                                                                null
                                                            )
                                                            accountChangeHandler(
                                                                selectedAccount
                                                            )
                                                            dispatch(loadCategories())
                                                        }}
                                                        hasOneItem={
                                                            reconciliationsData
                                                                ?.items?.[0].id === selectedData?.id
                                                        }
                                                    />
                                                ) : (
                                                    <Typography variant="subtitle1">
                                                        No Reconciliation
                                                        Selected
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Route>
                                    </Switch>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Fragment>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    accountId: state.appData.current_account_id,
    businessId: state.appData.current_business_id,
    category: state.category,
})
export default connect(mapStateToProps)(Reconciliations)
