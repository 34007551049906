import React, { useState } from 'react';
import { makeStyles, Typography, Theme, Grid, Link } from '@material-ui/core';
import LinkButton from './LinkButton';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

type pageContentProps = {
    handleClose?: () => void;
    align?: 'center' | 'flex-start' | 'flex-end';
};
export default function PopularPageContent({
    handleClose,
    align = 'center',
}: pageContentProps) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            linkStyle: {
                display: 'block',
                fontWeight: 500,
            },
            noTextDecoration: {
                textDecoration: 'none !important',
                color: `${colorTheme.blue600} !important`,
            },
            pageHeader: {
                fontWeight: 500,
                fontSize: '20px',
                marginTop: theme.spacing(4),
            },
            contactNumber: {
                fontWeight: 500,
                fontSize: ' 16px',
                color: colorTheme.blue600,
            },
        };
    })();

    const [links, setLinks] = useState([
        { text: 'Dashboard', link: '/dashboard' },
        { text: 'Documents', link: '/documents' },
        { text: 'Help Center', link: '/' },
        { text: 'Transactions', link: '/transactions' },
    ]);

    const goToLink = (pageLink: string) => {
        window.location.href = `#${pageLink}`;
        handleClose?.();
    };

    return (
        <Grid direction='column' container justify='center' alignItems={align}>
            <Typography
                variant='subtitle2'
                gutterBottom
                className={classes.pageHeader}
            >
                Popular Pages
            </Typography>
            <div className='page-links'>
                {links.map((e) => {
                    return (
                        <LinkButton className={classes.linkStyle}>
                            <span
                                onClick={() => {
                                    goToLink(e.link);
                                }}
                            >
                                {e.text}
                            </span>
                        </LinkButton>
                    );
                })}
            </div>
            <div style={{ marginTop: '40px' }}>
                <Typography variant='overline' gutterBottom>
                    Questions? Call us at{' '}
                    <span className={classes.contactNumber}>
                        <Link href='tel:1-800-222-6868'>1-800-222-6868</Link>
                    </span>
                </Typography>
            </div>
        </Grid>
    );
}
