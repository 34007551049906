import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import OnBoardingForm from './OnBoardingForm'
import MenuBar from './MenuBar'
import UiText from '../../common/ui/UiText'
import Loader from '../../common/Loader'
import { ONBOARDING_PAGE_INFO } from './constant/business-onboarding.const'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { useStore } from 'react-redux'
import { ApplicationStore } from '../../../models/store'
import defaultLogo from '../../../assets/logo/Logo.svg'
import { getPartnerLogo } from '../../common/whiteLabel/updateThemeAttibutes'

const useStyle = makeStyles((theme: Theme) => ({
    layout: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        marginBottom: '2rem',
    },
    navbar: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingRight: '0.5rem',
        paddingLeft: '3rem',
        boxSizing: 'border-box',
        marginBottom: '1rem',
    },
    formContainer: {
        marginBottom: '2rem',
    },
    logoRoot: {
        height: '4rem',
        marginTop: '1rem',
    },
    headerText: {
        marginBottom: '1.5rem',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
    onboardingFlowData: {
        width: '40rem',
        [theme.breakpoints.down('xs')]: {
            width: 'auto'
        },
    },
    description: {
        marginBottom: '2rem',
    },
    alertText: {
        width: '13.4rem',
        marginBottom: '2rem',
    },
    loader: {
        marginTop: '22rem',
    },
}))

const OnBoardingDetails = ({ isFormSubmitting }: any) => {
    const classes = useStyle()
    const { currentUser } = useCurrentStore()
    const appStore: ApplicationStore = useStore().getState();
    const { user } = appStore.appData;
    const [logoPath, setLogoPath] = useState<string>('');
    
    useEffect(() =>{
        if(!user || !user.partner_details){
            setLogoPath(defaultLogo)
            return;
        }
        const partnerLogoUrl = getPartnerLogo(user.partner_details.partner_key ?? '');
        setLogoPath(partnerLogoUrl);
     },[logoPath, user])
     
    return (
        <div className={classes.layout}>
            {isFormSubmitting ? (
                <div className={classes.loader}>
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={classes.navbar}>
                        <div className={classes.logoRoot}>
                            <img
                                src={logoPath}
                                alt="1800-Accountant logo"
                                height="40"
                            />
                        </div>
                        <div className="menu">
                            <MenuBar />
                        </div>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.onboardingFlowData}>
                            <div className={classes.headerText}>
                                <UiText variant="truck_175" weight="medium_500">
                                    Hello {currentUser?.first_name} 👋
                                </UiText>

                                <UiText variant="truck_175" weight="medium_500">
                                    {ONBOARDING_PAGE_INFO.WELCOME_MESSAGE}
                                </UiText>
                            </div>
                            <UiText
                                className={classes.description}
                                variant="hatchback_125"
                            >
                                {ONBOARDING_PAGE_INFO.PAGE_DESCRIPTION}
                            </UiText>
                            <div className={classes.alertText}>
                                <Alert severity="info">
                                    {ONBOARDING_PAGE_INFO.FORM_FIELD_INFO}
                                </Alert>
                            </div>

                            <div className={classes.formContainer}>
                                <OnBoardingForm />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default OnBoardingDetails
