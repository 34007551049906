import React, { useState } from 'react'
import UiDialog from '../../../common/ui/UiDialog'
import { useStyles } from './Styles'
import IntroScreen from './IntroScreen'
import CollectInfoForm from './CollectInfoForm'
import NoThanksScreen from './NoThanksScreen'
import ThankYouScreen from './ThankYouScreen'
import { useDashboardContext } from '../../Provider/DashboardProvider'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

export enum MODAL_SCREEN {
    INTRODUCTION = 'INTRODUCTION',
    COLLECT_INFO_SCREEN = 'COLLECT_INFO',
    THANKS_SCREEN = 'THANK_YOU',
    NO_THANKS_SCREEN = 'NO_THANKS',
}
interface TaxInfoModalProps {
    open: boolean
    handleClose: () => void
}

const CarryoverTaxInfoModal = ({ open, handleClose }: TaxInfoModalProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { fetchAccountProgress } = useDashboardContext()
    const [currentScreen, setCurrentScreen] = useState(
        MODAL_SCREEN.INTRODUCTION
    )

    const resetOnClose = () => {
        setCurrentScreen(MODAL_SCREEN.INTRODUCTION)
        handleClose()
    }

    const RenderScreen = () => {
        switch (currentScreen) {
            case MODAL_SCREEN.INTRODUCTION:
                return (
                    <IntroScreen
                        moveToCollectInfoScreen={() => {
                            setCurrentScreen(MODAL_SCREEN.COLLECT_INFO_SCREEN)
                        }}
                        moveToNoThanksScreen={() => {
                            setCurrentScreen(MODAL_SCREEN.NO_THANKS_SCREEN)
                        }}
                        closeModal={resetOnClose}
                    />
                )
            case MODAL_SCREEN.COLLECT_INFO_SCREEN:
                return (
                    <CollectInfoForm
                        closeModal={resetOnClose}
                        setCurrentScreen={setCurrentScreen}
                    />
                )
            case MODAL_SCREEN.NO_THANKS_SCREEN:
                return (
                    <NoThanksScreen
                        closeModal={resetOnClose}
                        moveToCollectInfoScreen={() => {
                            setCurrentScreen(MODAL_SCREEN.COLLECT_INFO_SCREEN)
                        }}
                        moveToIntroScreen={() => {
                            setCurrentScreen(MODAL_SCREEN.INTRODUCTION)
                        }}
                    />
                )
            case MODAL_SCREEN.THANKS_SCREEN:
                return <ThankYouScreen closeModal={()=> {
                    fetchAccountProgress()
                    resetOnClose()
                }} />
            default:
                return null
        }
    }

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size="sm"
            customRootClass={styles.modalRoot}
            hideTitleSection={true}
        >
            <RenderScreen />
        </UiDialog>
    )
}

export default CarryoverTaxInfoModal