import { Accountant } from '../../../models/accountant';

export interface GDSessionDetails {
    session_id: string
    client_token: string
    selected_business_id?: string
    expires_at?: number
    show_gd_modal?: boolean
}
export interface Attachments{
    id: string,
    mimeType: string,
    size: number,
    file_name: string,
    download_link: string
}
export interface Content{
    type: string
    message: string
    portal_account_id: string
    heading: string
    created: number
    is_read: boolean
    id: string
    attachments: any[]
    topicId: string
    topicTitle: string
    status: string
    accountants: Accountant[]
    portalAccountId: string
    ref_id? :string
    lastUnreadMessage: {
        id: string
        body: string
        date: number
        is_read: boolean
        accountant: Accountant
        attachments: Attachments[],
    }
    statusChangeDate: string
    subject: string
    gd_session_details?: GDSessionDetails
}
export interface NotificationData {
    type: 'unread_conversation' | 'alert'
    content: Content
    date: number
}
export const NOTIFICATION_TYPES = {
    UNREAD_CONVERSATION: 'unread_conversation',
    ALERT: 'alert'
}
export const ALLOWED_NOTIFICATION_CONTENT_TYPE = 'bank_account_reconciled';