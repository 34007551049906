import { 
    useState, 
    useContext,
    useRef,
    useCallback,
    useEffect 
} from 'react';
import { useStore } from 'react-redux'
import { FormikProps, FormikValues } from 'formik'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { getContacts } from '../../../services/apiService/contacts'
import {
    ApplicationStore,
    PaginatedContactsResponse,
    Contact,
} from '../../../models'


export type LoadingStates = {
    loading: boolean
    hasMore: boolean
}

const dropdownOptions: string[] = [
    'All Customers & Vendors',
    'Customers',
    'Vendors',
]

const customerTypeMappings: any = {
    'All Customers & Vendors': null,
    Customers: 'customer',
    Vendors: 'vendor',
}



const useContactsHook = () => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    /**
     * This formikRef is for contact form ref.
     * This is needed to check if form is touched and show Confirmation popup
     * (Required for modal close with X button)
     */
    const formikRef = useRef<FormikProps<FormikValues>>(null)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    const [searchTerm, setSearchTerm] = useState('')
    const [contactType, setContactType] = useState(0)
    const [selectedContact, setSelectedContact] = useState<Contact>()
    const [contactUpdated, setContactUpdated] = useState<boolean>(false)
    const [contactsData, setContactsData] = useState<
        PaginatedContactsResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 25,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    })

    // @return {string | null} -> 'Customers': 'customer'
    let customerType: string | null =
        customerTypeMappings[`${dropdownOptions[contactType]}`]

    // Handling of X button open the confirm popup for exit if form is touched
    // Cancel button handling added in the ContactForm itself.
    const handleContactModalClose = (result: any) => {
        // if form submitted
        if (result === true) {
            setShowConfirmModal(false)
            setOpenModal(false)
        } else {
            formikRef.current?.dirty ?  setShowConfirmModal(true) : setOpenModal(false)
        }
    }

    const handleConfirmModal = (result: any) => {
        if (result) {
            // i.e when discard/delete clicked
            setShowConfirmModal(false)
            setOpenModal(false)
        } else {
            setShowConfirmModal(false)
        }
    }

    const getContactsList = useCallback(
        (params) => {
            params = {
                ...params,
                ...(customerType ? { type: customerType } : null),
            }
            getContacts(params).then((response: any) => {
                if (response) {
                    const { itemsCount, page, perPage, pageCount, items } =
                        response
                    setContactsData((prev: any) => ({
                        ...prev,
                        items: page === 1 ? items : prev.items.concat(items),
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    }))
                }
            })
        },
        [customerType]
    )


    // load contacts
    const loadContacts = useCallback(
        (params?: any) => {
            setContactsData((prev: any) => ({
                ...prev,
                loading: true,
            }))
            getContactsList({
                page: 1,
                perPage: 25,
                ...params,
            })
        },
        [getContactsList]
    )

    const loadMore = () => {
        const { page } = contactsData
        getContactsList({
            page: page + 1,
            perPage: 25,
        })
    }

    useEffect(() => {
        if (contactUpdated) {
            let newItems: any = [...contactsData?.items]
            const contactIndex: number = newItems.findIndex(
                (contact: Contact) => contact.id === selectedContact?.id
            )
            newItems[contactIndex] = selectedContact
            setContactsData((prev: any) => ({
                ...prev,
                items: newItems,
            }))
            setContactUpdated(false)
        }
    }, [contactUpdated, contactsData?.items, selectedContact])


    useEffect(() => {
        setActiveRouteHeading('Contacts')
    }, [setActiveRouteHeading])


    useEffect(() => {
        loadContacts()
    }, [loadContacts])


    return {
        dropdownOptions,
        openModal,
        setOpenModal,
        showConfirmModal,
        setShowConfirmModal,
        searchTerm,
        setSearchTerm,
        contactType,
        setContactType,
        selectedContact,
        setSelectedContact,
        contactUpdated,
        setContactUpdated,
        contactsData,
        setContactsData,
        formikRef,
        handleContactModalClose,
        handleConfirmModal,
        loadMore,
        loadContacts
    }
}
export default useContactsHook