import { Button, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import bannerImg from '../../../../assets/images/ObtainEINIntro.png';
import LinkButton from '../../../common/LinkButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ObtainEINPopupIntro({ setIntroCompleted, handleClose }: {
    setIntroCompleted: any;
    handleClose: (result?: { modalState: boolean; setEIN?: boolean }) => void;
}) {
    const theme = useTheme()
    const screenIsSmMax = useMediaQuery(theme.breakpoints.up('sm'));
    const styles = makeStyles({
        bannerImage: {
            padding: 30
        },
        mainContainer: {
            textAlign: 'center',
            minHeight: 600,
            padding: 15
        },
        marginVertical: {
            marginTop: 15,
            marginBottom: 15
        },
    })()
    return (
        <div className={styles.mainContainer}>
            <div className={styles.bannerImage}>
                <img src={bannerImg} width={screenIsSmMax ? 338 : 180} alt="Banner" />
            </div>
            <Typography
                variant="body1"
                gutterBottom
                align="justify"
                className={styles.marginVertical}
            >
                An Employer Identification Number (EIN) or Tax Identification Number (Tax ID, or TIN) is required to form a business, create a trust or add new employees.
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                classes={{ root: styles.marginVertical }}
                className='flex-center'
            >
                <AccessTime /> &nbsp; This application takes on average 10 minutes to complete.
            </Typography>
            <Button
                variant="contained"
                fullWidth
                color="primary"
                className={styles.marginVertical}
                type="submit"
                onClick={() => {
                    setIntroCompleted(true)
                }}
            >
                Get an EIN
            </Button>
            <LinkButton
                fullWidth
                type="submit"
                onClick={() => handleClose({ modalState: false, setEIN: true })}
            >
                I already have my EIN
            </LinkButton>
        </div>
    )
}