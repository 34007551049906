import { 
    useState, 
    useContext,
    useRef,
    useCallback,
    useEffect 
} from 'react';
import { FormikProps, FormikValues } from 'formik'
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import { getPurposes } from '../../../services/apiService/purposes'
import { Purpose } from '../../../models/purposes'

const usePurposesHook = () => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    /**
     * This formikRef is for purpose form ref.
     * This is needed to check if form is touched and show Confirmation popup
     * (Required for modal close with X button)
     */
    const formikRef = useRef<FormikProps<FormikValues>>(null)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedPurpose, setSelectedPurpose] = useState<Purpose>()
    const [justCreatedPurpose, setJustCreatedPurpose] = useState<Purpose>()
    const [purposesData, setPurposesData] = useState<any>()

    // Handling of X button open the confirm popup for exit if form is touched
    // Cancel button handling added in the ContactForm itself.
    const handlePurposeModalClose = () => {
        setOpenModal(false)
    }

    const getPurposesList = useCallback(
        (params) => {
            params = {
                ...params,
            }
            setLoading(true)
            getPurposes(params).then((response: any) => {
                if (response) {
                    const { itemsCount, page, perPage, pageCount, items } =
                        response
                    setPurposesData(response);
                    setLoading(false)
                    setFirstLoad(false)
                }
            })
        },
        []
    )

    useEffect(()=>{
        if(firstLoad && purposesData){
            setSelectedPurpose(purposesData?.[0])
        }
    },[firstLoad, purposesData])


    // load Purposes
    const loadPurposes = useCallback(
        (params?: any) => {
            getPurposesList({
                ...params
            })
        },
        [getPurposesList]
    )

    const loadMore = () => {
        const { page } = purposesData
        getPurposesList({
            page: page + 1,
            perPage: 25,
        })
    }


    useEffect(() => {
        setActiveRouteHeading('Purposes')
    }, [setActiveRouteHeading])


    useEffect(() => {
        loadPurposes()
    }, [loadPurposes])


    return {
        openModal,
        setOpenModal,
        selectedPurpose,
        setSelectedPurpose,
        purposesData,
        setPurposesData,
        formikRef,
        handlePurposeModalClose,
        loading,
        loadPurposes,
        firstLoad,
        justCreatedPurpose,
        setJustCreatedPurpose
    }
}
export default usePurposesHook