import store from '../../store'
import {
    getResource,
    patchResource,
    deleteResource,
    postResource,
    getApiUrl
} from '../axiosService'
import moment from 'moment'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    const invoiceBaseURL = `api/account/${accountId}/business/${businessId}/invoice`
    return {
        businessId: businessId,
        accountId: accountId,
        invoiceBaseURL: invoiceBaseURL
    }

}

export function getInvoiceStats() {
    const { invoiceBaseURL } = getStoreData()
    return getResource(
        `${invoiceBaseURL}/totals`,
        undefined,
    )
}

export function getInvoices(params: any) {
    const { invoiceBaseURL } = getStoreData()
    return getResource(
        `${invoiceBaseURL}`,
        undefined,
        params
    )
}

export function invoiceDetail(invoiceId: string) {
    const { invoiceBaseURL } = getStoreData()
    return getResource(
        `${invoiceBaseURL}/${invoiceId}`
    )
}

export function createInvoice(params: any) {
    const { invoiceBaseURL } = getStoreData()
    return postResource(
        `${invoiceBaseURL}`,
        params
    )
}

export function updateInvoice(invoiceId: string, params: any) {
    const { invoiceBaseURL } = getStoreData()
    return patchResource(
        `${invoiceBaseURL}/${invoiceId}`,
        params
    )
}

export function deleteInvoice(invoiceId: string) {
    const { invoiceBaseURL } = getStoreData()
    return deleteResource(
        `${invoiceBaseURL}/${invoiceId}`
    )
}

export function invoicePdfURL(invoiceId: string) {
    const { invoiceBaseURL } = getStoreData()
    const invoiceURL = `${invoiceBaseURL}/${invoiceId}/pdf/async?generated_on=${moment().format('lll')}`
    return getApiUrl(invoiceURL)
}

export function updatePaymentStatus(invoiceId: string, paymentStatus: string) {
    const { invoiceBaseURL } = getStoreData()
    return patchResource(
        `${invoiceBaseURL}/${invoiceId}`,
        { payment_status: paymentStatus }
    )
}

export function sendEmailInvoice(invoiceId: string, data: any) {
    const { invoiceBaseURL } = getStoreData()
    return postResource(
        `${invoiceBaseURL}/${invoiceId}/email`,
        data
    )
}