import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getAllContacts } from '../../../services/apiService/contacts'
import { getAllProducts } from '../../../services/apiService/products'

const InvoiceContext = createContext(null)

export function useInvoiceContext(): any {
    const state = useContext(InvoiceContext)
    return state
}

const InvoiceProvider = ({ children }: any) => {
    const [allContacts, setAllContacts] = useState()
    const [allProducts, setAllProducts] = useState<boolean>(false)
    const [fetchInProgress, setFetchInProgress] = useState<boolean>(false)
    const [recallData, setRecallData] = useState<boolean>(false)

    const fetchRequiredData = useCallback(() => {
        setFetchInProgress(true)
        Promise.all([getAllContacts(), getAllProducts()])
            .then((res: any) => {
                const [contacts, products] = res
                setAllContacts(contacts)
                setAllProducts(products)
                setFetchInProgress(false)
                setRecallData(false)
            })
            .catch((err) => {
                setFetchInProgress(false)
                setRecallData(false)
            })
    }, [])

    useEffect(() => {
        fetchRequiredData()
    }, [fetchRequiredData])

    useEffect(() => {
        if (recallData) {
            fetchRequiredData()
        }
    }, [fetchRequiredData, recallData])

    const providerValue: any = {
        allContacts,
        allProducts,
        fetchInProgress,
        setFetchInProgress,
        setRecallData,
    }

    return (
        <InvoiceContext.Provider value={providerValue}>
            {children}
        </InvoiceContext.Provider>
    )
}

export default InvoiceProvider
