import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationStore, AppData, Product } from '../../models';
import { getProducts } from '../../services/apiService';
import Loader from '../common/Loader';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import { currencyFormatter } from '../../utils/appUtil';
import ChargeoverIframeModal from './ChargeoverIframeModal';

function AdditionalProducts({ appData }: { appData: AppData }) {
    const classes = makeStyles((theme: Theme) => {
        return {
            btnContainer: {
                backgroundColor: 'white',
                zIndex: 100,
                [theme.breakpoints.down('md')]: {
                    top: '50px',
                    paddingTop: '1em'
                }
            },
            btnDivider: {
                marginTop: '1em',
            },
            listRoot: {
                width: '100%',
                maxWidth: 573,
            },
            description: {
                marginTop: theme.spacing(1),
                whiteSpace: 'pre-line',
                wordWrap: 'break-word',
            },
        };
    })();

    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const [loading, setLoading] = useState(true);
    const [products, setPeoducts] = useState<Product[]>([]);

    const [checked, setChecked] = useState<string[]>([]);
    const [total, setTotal] = useState(0);
    const [openChargeoverModal, setOpenChargeoverModal] = useState(false);
    const utmContent = 'Additional+Products';
    useEffect(() => setActiveRouteHeading('Additional Products'));

    const loadProducts = useCallback(() => {
        setLoading(true);
        getProducts(appData.current_account_id).then((res) => {
            setPeoducts(res);
            setLoading(false);
        });
    }, [appData.current_account_id]);

    useEffect(() => {
        loadProducts();
    }, [loadProducts]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        let total = newChecked.reduce(
            (total: any, p: any) =>
                +(
                    products.find((product) => product.product_id === p)
                        ?.price_book.first_charge_amount || 0
                ) + total,
            0
        );

        setTotal(total);
        setChecked(newChecked);
    };

    const handleClose = (message?: string) => {
        setOpenChargeoverModal(false);

        if (message === 'success') {
            setLoading(true);
            setChecked([]);
            setTotal(0);
            loadProducts();
        }
    };

    return (
        <Fragment>
            <ChargeoverIframeModal
                open={openChargeoverModal}
                productIds={checked}
                handleClose={handleClose}
                utmContent={utmContent}
            />
            {loading ? (
                <Loader />
            ) : products.length ? (
                <Box>
                    <div className={classes.btnContainer}>
                        <Container>
                            <Button
                                variant='contained'
                                color='primary'
                                disabled={!checked.length}
                                onClick={() => setOpenChargeoverModal(true)}
                            >
                                <Typography variant='button'>
                                    Checkout{'  '}|{'  '}
                                    {currencyFormatter.format(total)}
                                </Typography>
                            </Button>
                        </Container>
                        <Divider className={classes.btnDivider} />
                    </div>
                    <Container fixed>
                        <List className={classes.listRoot}>
                            {products.map((p, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ListItem
                                            role={undefined}
                                            dense
                                            button
                                            style={{
                                                alignItems: 'flex-start',
                                            }}
                                            onClick={handleToggle(p.product_id)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    color='primary'
                                                    edge='start'
                                                    checked={
                                                        checked.indexOf(
                                                            p.product_id
                                                        ) !== -1
                                                    }
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Fragment>
                                                        <div>
                                                            <Typography
                                                                component='span'
                                                                variant='h6'
                                                            >
                                                                {p.title}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Box
                                                                component='span'
                                                                marginRight='16px'
                                                            >
                                                                <Typography
                                                                    component='span'
                                                                    variant='h6'
                                                                    color='primary'
                                                                >
                                                                    {currencyFormatter.format(
                                                                        Number.parseFloat(
                                                                            p
                                                                                .price_book
                                                                                .first_charge_amount
                                                                        )
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Typography
                                                                component='span'
                                                                variant='subtitle1'
                                                                color='textSecondary'
                                                            >
                                                                {
                                                                    p.price_book
                                                                        .billing_frequency
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Fragment>
                                                }
                                                secondary={
                                                    <Typography
                                                        component='span'
                                                        variant='body2'
                                                        color='textPrimary'
                                                        className={
                                                            classes.description
                                                        }
                                                    >
                                                        {p.description}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <Divider
                                            variant='inset'
                                            component='li'
                                        />
                                    </Fragment>
                                );
                            })}
                        </List>
                    </Container>
                </Box>
            ) : (
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                    style={{ height: '100%' }}
                >
                    <Typography variant='subtitle1' color='textSecondary'>
                        We know you have a world full of choices. Thank you for
                        selecting us.
                    </Typography>
                </Grid>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(AdditionalProducts);
