import React from 'react'
import { useHistory } from 'react-router-dom'
import { toRem16 } from '../../../styles/commonStyles'
import { makeStyles, Theme } from '@material-ui/core'
import InfoCard from '../Cards/InfoCard'
import UiText from '../../common/ui/UiText'
import Icon from '../../common/Icon'
import { currencyFormatter } from '../../../utils/appUtil'
import { InvoiceData } from '../../../models/dashboardModel'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        cardContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: toRem16(122),
        },
        container: {
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
        },
        headerStyle: {
            display: 'grid',
            gridTemplateColumns: `${toRem16(72)} 1fr`,
        },
        iconStyle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1rem',
            width: toRem16(40),
            height: toRem16(40),
            borderRadius: '50%',
            backgroundColor: colorTheme =>`${colorTheme.yellow100}`,
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
    }
})

const InvoiceInfoCard = ({
    updateDimissInfo,
    invoiceData,
}: {
    invoiceData: InvoiceData | undefined
    updateDimissInfo: () => void
}) => {
    const history = useHistory()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconContainer}>
                    <div className={styles.iconStyle}>
                        <Icon icon="hourglass" svgColor={colorTheme.yellow700}/>
                    </div>
                </div>

                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <>
                            <UiText textColor="textSecondary">Invoicing</UiText>
                            <UiText>
                                You have{' '}
                                <b>
                                    {currencyFormatter.format(
                                        invoiceData?.late as number
                                    )}
                                </b>{' '}
                                in {invoiceData?.late_count} overdue invoices.
                            </UiText>
                        </>
                    }
                    buttonText={'View Invoices'}
                    buttonClick={() => {
                        history.push({
                            pathname: '/invoices',
                            state: {
                                paymentStatus: 'Overdue',
                                from: 'dashboard',
                            },
                        })
                        updateDimissInfo()
                    }}
                />
            </div>
        </div>
    )
}

export default InvoiceInfoCard