import {
    DOWNLOAD_REPORT_OPTION_TYPES,
    DOWNLOAD_REPORT_TYPES,
} from '../models/reports-interface';

export enum REPORTS_MENU_VIEWS {
    BALANCE_SHEET = 'balanceSheet',
    INCOME_STATEMENT = 'incomeStatement',
    INVOICES = 'invoices',
    MILEAGE_LOG = 'mileageLog',
    RECONCILIATIONS = 'reconciliations',
    TRANSACTIONS_BY_ACCOUNT = 'transactionsByAccount',
    JOURNAL_ENTRIES = 'journalEntries',
    GENERAL_JOURNAL = 'generalJournal',
    GENERAL_LEDGER = 'generalLedger',
}

export enum DATE_TYPES {
    TODAY = 'Today',
    THIS_WEEK = 'This Week',
    THIS_YEAR = 'This Year',
    LAST_YEAR = 'Last Year',
    LAST_QUARTER = 'Last Quarter',
    THIS_MONTH = 'This Month',
    THREE_MONTHS = 'Three Months',
    ALL_TIME = 'All Time',
}

export enum DATE_FORMATS {
    periodText = 'DD MMM yyyy',
    ymdDate = 'YYYY-MM-DD',
}

export const findSpaceRegex = /\s+/g;

export enum VIEW_INVOIDES_BY {
    ISSUE_DATE = 'Issue Date',
    DUE_DATE = 'Due Date',
}
export enum VIEW_INVOIDES_BY_API_PARAMS{
    ISSUE = 'issue',
    DUE = 'due'
}

export enum REPORT_STATUSES {
    PAID = 'Paid',
    UNPAID = 'Unpaid',
    OVERDUE = 'Overdue',
}
export enum REPORT_MENU_OPTIONS {
    MARK_AS_PAID = 'Mark as Paid',
    MARK_AS_UNPAID = 'Mark as Unpaid',
    EDIT_INVOICE = 'Edit Invoice',
    EDIT_RECIPIENT = 'Edit Recipient',
}
export enum ALLOWED_BANK_TYPES {
    MANUAL = 'manual',
    AGGREGATED = 'aggregated',
}

export enum EMPTY_PARAMS {
    ACCOUNT_ID = 'account-id',
    START_DATE_YMD = 'start-date-ymd',
    END_DATE_YMD = 'end-date-ymd',
    BANKS_ACC_IDS = 'bank-account-id',
    VEHICLE_IDS = 'vehicle-id',
}

export const ERRORS = {
    ACTIVITY: 'Please select an activity.',
    GENERIC:  'There are no reports for the selected time period.',
    DATE: 'Date is required',
}
    
export enum ACTIVITIES {
    BUSINESS = 'business',
    PERSONAL = 'personal',
    MEDICAL = 'medical',
    MOVING = 'moving',
    CHARITY = 'charity',
}

export const saveOptions = [
    {
        label: 'Download as .pdf',
        type: DOWNLOAD_REPORT_OPTION_TYPES.DOWNLOAD,
        fileType: DOWNLOAD_REPORT_TYPES.PDF,
    },
    {
        label: 'Save to portal as .pdf',
        type: DOWNLOAD_REPORT_OPTION_TYPES.SAVE,
        fileType: DOWNLOAD_REPORT_TYPES.PDF,
    },
    {
        label: 'Download as .csv',
        type: DOWNLOAD_REPORT_OPTION_TYPES.DOWNLOAD,
        fileType: DOWNLOAD_REPORT_TYPES.CSV,
    },
    {
        label: 'Save to portal as .csv',
        type: DOWNLOAD_REPORT_OPTION_TYPES.SAVE,
        fileType: DOWNLOAD_REPORT_TYPES.CSV,
    },
];
export const DOCUMENT_DOWNLOAD_SUCCESS = 'The document was successfully saved to the portal.';
export const SHOW_ODOMETER = true;
export enum REPORTS_QUERY_PARAMS {
    BALANCE_SHEET  = 'balanceSheet',
    INCOME_STATEMENT = 'incomeStatement',
    INVOICES = 'invoices',
    MILEAGE_LOG = 'mileageLog',
    RECONCILIATIONS = 'reconciliations',
    TRANSACTIONS = 'transactions',
    JOURNAL_ENTRIES = 'journalEntries',
    GENERAL_JOURNAL = 'generalJournal',
    GENERAL_LEDGER = 'generalLedger'
}