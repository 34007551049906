import { Box, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React, { CSSProperties, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationStore, Config } from '../../models';
import UiFormControlSelection from './ui/UiFormControlSelection';
import { useFormikContext } from 'formik'

interface Iprop {
  fieldName: string;
  config: Config;
  showCounty?: boolean;
  showCountry?: boolean;
}

const US_COUNTRY_CODE = 'US'

const AddressForm: React.FC<Iprop> = ({
  fieldName,
  config: { apiConfig: config },
  showCountry = false,
  showCounty = false,
}) => {
  const theme = useTheme()
  const { getFieldMeta, setFieldValue } = useFormikContext();
  const smDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const xsDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [isUsCountrySelected, setIsUsCountrySelected] = useState(false);
  const currentOwnerAddress: any = getFieldMeta(fieldName)?.value

  useEffect(() => {
      const currentCountry = currentOwnerAddress?.country
      if (!currentCountry) {
          setFieldValue(`${fieldName}.country`, 'US')
          setIsUsCountrySelected(true)
      } else {
          setIsUsCountrySelected(currentCountry === US_COUNTRY_CODE)
      }
  }, [fieldName, currentOwnerAddress, setFieldValue])

  const zipCodeStyle = (!xsDevice && smDevice && !showCounty) ? theme.spacing(3) : 
                    isUsCountrySelected ? 0 : theme.spacing(3)

  const classes = makeStyles((theme: Theme) => {
    return {
      subHeading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      gridContainer: {
        marginTop: theme.spacing(3),
        '& > div': {
          marginBottom: theme.spacing(3),
        },
      },
      zipCodeStyle: {
        paddingRight: zipCodeStyle
      }
    };
  })();

 

  const getCountryStyle = () => {
    const style: CSSProperties = {
      paddingLeft: 0,
      paddingRight: 0
    }

    if (xsDevice) {
      return style;
    } else if (smDevice && showCounty) {
      style.paddingRight = theme.spacing(3);
    } else if (!smDevice && !showCounty) {
      style.paddingRight = theme.spacing(3);
    } else if (!smDevice) {
      style.paddingLeft = isUsCountrySelected ? theme.spacing(3) : 0 ;
    }
    return style;
  }
  
  return (
    <Box>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <UiFormControlSelection
            label='Street Address'
            type='text'
            fieldName={`${fieldName}.address`}
            showFloatingLabel={true}
          ></UiFormControlSelection>
        </Grid>
        <Grid xs={12} sm={6} md={ showCounty ? 6 : 5} item style={{ paddingRight: xsDevice ? 0 : theme.spacing(3) }}>
          <UiFormControlSelection
            label='City'
            type='text'
            fieldName={`${fieldName}.city`}
            showFloatingLabel={true}
          ></UiFormControlSelection>
        </Grid>
        {showCounty ? (
          <Grid xs={12} sm={6} item>
            <UiFormControlSelection
              label='County'
              type='text'
              fieldName={`${fieldName}.county`}
              showFloatingLabel={true}
            ></UiFormControlSelection>
          </Grid>
        ) : null}
        { isUsCountrySelected && <Grid xs={12} sm={6} md={4} item style={{ paddingRight: xsDevice || (smDevice && !showCounty) ? 0 : theme.spacing(3) }}>
          <UiFormControlSelection
            label='State'
            type='select'
            optionsData={config.generic.states}
            optionKey='name'
            optionValue='id'
            fieldName={`${fieldName}.state`}
            showFloatingLabel={true}
          ></UiFormControlSelection>
        </Grid>}
        <Grid xs={12} sm={6} md={isUsCountrySelected ? 3 : 6} item className={classes.zipCodeStyle}>
          <UiFormControlSelection
            label='Zip'
            type='mask'
            fieldName={`${fieldName}.zip_code`}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            minLength={5}
            showFloatingLabel={true}
          ></UiFormControlSelection>
        </Grid>
        {showCountry ? (
          <Grid xs={12} sm={6} md={isUsCountrySelected ? 5 : 6} item style={getCountryStyle()}>
            <UiFormControlSelection
              label='Country'
              type='select'
              optionsData={config.generic.countries}
              optionKey='name'
              optionValue='iso_abbr'
              fieldName={`${fieldName}.country`}
              showFloatingLabel={true}
              onChange={(event)=> { 
                const selectedCountryCode = event?.target?.value
                if(selectedCountryCode !== US_COUNTRY_CODE) {
                  setFieldValue(`${fieldName}.state`, "");
                }
              }}
            ></UiFormControlSelection>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationStore) => ({
  config: state.config,
});
export default connect(mapStateToProps)(AddressForm);
