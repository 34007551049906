import React from 'react';
import { Box } from '@material-ui/core';

import InfoTooltip from '../../common/InfoTooltip';

interface Props {
    id?: string;
    title: string;
    children: React.ReactNode;
    className?: any;
    tooltipText: string | null;
}

const Section = React.forwardRef(
    ({ id, title, children, className, tooltipText }: Props, ref: any) => (
        <section ref={ref} id={id} className={className}>
            <Box display='flex' alignItems='center'>
                <h2>{title}</h2>
                {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
            </Box>
            {children}
        </section>
    )
);

export default Section;
