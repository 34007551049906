import { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    useMediaQuery,
    useTheme,
    makeStyles,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { ApplicationStore } from '../../../models'
import { EmpData } from '../../../models/payroll/employee'
import {
    employeeStatusCheck,
    getAlertInfoText,
} from '../employees/EmployeeAlerts'
import { getCurrentEmployee } from '../../../services/apiService/payroll/employee'
import {
    getCompany,
    getBusinessAddress,
} from '../../../services/apiService/payroll/company'
import { useEmployeeState } from './EmployeeProvider'
import ComponentMobileHeader from '../../../components/common/ComponentMobileHeader'
import RequestInputDialog from './RequestInputDialog'
import EmployeeFormModal from './EmployeeFormModal'
import UiText from '../../common/ui/UiText'
import UiAlert from '../../common/ui/UiAlert'
import Loader from '../../common/Loader'
import PersonalDetails from './views/PersonalDetails'
import JobDetails from './views/JobDetails'
import Address from './views/Address'
import PaymentMethod from './views/PaymentMethod'
import ElectronicSignature from './views/ElectronicSignature'
import TaxInformation from './views/TaxInformation'
import { commonStyles } from '../../../styles/commonStyles'

interface EmployeeDetailsProps {
    selectedData?: EmpData
    businessId?: string
    reloadState?: () => void
    setSelectedData?: any 
}

const Forms = ['PersonalDetails', 'JobDetails', 'JobCompensation']
const useStyles = makeStyles((theme) => ({
    listView: {
        marginTop: '30px',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    },
    sectionMargin: {
        marginTop: theme.spacing(6)
    }
}))

const EmployeeDetails = ({
    selectedData,
    setSelectedData,
    businessId,
    reloadState
}: EmployeeDetailsProps) => {
    const history = useHistory()
    const { id }: any = useParams()
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [openRequestDialog, setOpenRequestDialog] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selectedJob, setSelectedJob] = useState<any>(undefined)
    const [selectedCompensation, setSelectedCompensations] =
        useState<any>(undefined)
    const [currentForm, setCurrentForm] = useState(Forms?.[0])
    const [selectedEmployee, setSelectedEmployee] = useState<EmpData>()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const employeeAlert = getAlertInfoText(selectedData)
    const {
        companyDetails,
        companyAddress,
        setCompanyDetails,
        setCompanyAddress,
    } = useEmployeeState()

    let [isBirthdateMissing, isJobAndCompensationMissing] =
        employeeStatusCheck(selectedEmployee)
    let employeeId = selectedData?.id || id
    let currentAddress = companyAddress?.[0]
    const classes = useStyles()
    const styles = commonStyles()

    const disableRequestInputButton: any = (isBirthdateMissing || isJobAndCompensationMissing)

    useEffect(() => {
        if (!companyDetails) {
            getCompany(businessId as string).then((company: any) => {
                if (company) {
                    setCompanyDetails(company)
                }
            })
        }
    }, [businessId, companyDetails, setCompanyDetails])

    useEffect(() => {
        if (!companyAddress?.length) {
            setLoading(true)
            getBusinessAddress(businessId as string).then(
                (address: any) => {
                    if (address) {
                        setCompanyAddress([address])
                        setLoading(false)
                    }
                }
            )
        }
    }, [companyAddress?.length, businessId, setCompanyAddress])


    const fetchCurrentEmployee = () => {
        setLoading(true)
        getCurrentEmployee(
            businessId as string,
            employeeId as string
        )
        .then((employee: any) => {
            if (employee) {
                setSelectedEmployee(employee)
                setSelectedData(employee)
                setLoading(false)
            }
        })
        .catch((err) => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setSelectedEmployee(selectedData)
    }, [selectedData])

    if (loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '100%' }}
            >
                <Loader />
            </Grid>
        )
    }

    return (
        <div className={isSmallDevice ? '' : classes.listView}>
            {/* Header used in Transaction Header Actions in Mobile*/}
            <ComponentMobileHeader
                showBottomDivider
                title={''}
                handleBackAction={() => {
                    history.goBack()
                }}
                buttonMenuText={'Save'}
                threeDotMenu={false}
            />
            <RequestInputDialog
                businessId={businessId}
                openDialog={openRequestDialog}
                setOpenDialog={setOpenRequestDialog}
                employeeId={selectedEmployee?.employee_uuid}
            />
            <EmployeeFormModal
                open={openModal}
                currentForm={currentForm}
                handleClose={() => {
                    setOpenModal(false)
                }}
                formSubmitCallback={() => {
                    setOpenModal(false)
                    reloadState?.()
                    fetchCurrentEmployee()
                }}
                employeeData={selectedEmployee}
                job={selectedJob}
                compensation={selectedCompensation}
            />

            { employeeAlert?.header &&  <UiAlert
                header={employeeAlert?.header}
                description={employeeAlert?.description}
            />}
            <div className={styles.mt24}>
                <UiText variant="suv_150">
                    {selectedData?.first_name} {selectedData?.last_name}
                </UiText>
            </div>
            <Button
                startIcon={<MailOutlineIcon />}
                variant="outlined"
                color="secondary"
                className={styles.mt24}
                disabled={
                    (isBirthdateMissing ||
                        isJobAndCompensationMissing) as boolean
                }
                onClick={() => {
                    setOpenRequestDialog(true)
                }}
            >
                Request Employee Input
            </Button>
            <div className={classes.sectionMargin}>
                <PersonalDetails
                    selectedEmployee={selectedEmployee}
                    setOpenModal={setOpenModal}
                    setCurrentForm={setCurrentForm}
                />
            </div>
            <div className={classes.sectionMargin}>
                <JobDetails
                    selectedEmployee={selectedEmployee}
                    currentAddress={currentAddress}
                    setOpenModal={setOpenModal}
                    setCurrentForm={setCurrentForm}
                    setSelectedJob={setSelectedJob}
                    setSelectedCompensations={setSelectedCompensations}
                    formSubmitCallback={() => {
                        reloadState?.()
                        fetchCurrentEmployee()
                    }}
                />
            </div>
            
            {selectedEmployee?.employee_uuid && (
                <>
                    <div id="taxinfo" className={classes.sectionMargin}>
                        <Address
                            address={selectedEmployee?.home_address}
                            setOpenRequestDialog={setOpenRequestDialog}
                            disableRequestInputButton={disableRequestInputButton}
                        />
                    </div>
                    <div id="taxinfo" className={classes.sectionMargin}>
                        <PaymentMethod
                            employeeId={selectedEmployee?.employee_uuid}
                            businessId={businessId}
                            employeeAddress={selectedEmployee?.home_address}
                            setOpenRequestDialog={setOpenRequestDialog}
                            disableRequestInputButton={disableRequestInputButton}
                        />
                    </div>
                    <div id="taxinfo" className={classes.sectionMargin}>
                        <ElectronicSignature
                            employeeId={selectedEmployee?.employee_uuid}
                            businessId={businessId}
                            setOpenRequestDialog={setOpenRequestDialog}
                            disableRequestInputButton={disableRequestInputButton}
                        />
                    </div>
                    <div id="taxinfo" className={classes.sectionMargin}>
                        <TaxInformation
                            employeeId={selectedEmployee?.employee_uuid}
                            businessId={businessId}
                            setOpenRequestDialog={setOpenRequestDialog}
                            disableRequestInputButton={disableRequestInputButton}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = ({ appData }: ApplicationStore) => ({
    businessId: appData.current_business_id,
})

export default connect(mapStateToProps)(EmployeeDetails)
