import { createContext, useState, useContext, useEffect } from 'react'
import { getCompanyDetails } from '../../../services/apiService/adp-payroll/company'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { ADPCompanyDetails } from '../../../models/adp-payroll/company'
import usePermissions from '../../../hooks/usePermissions'

export const ADPEmployeesContext = createContext(null)

export function useADPEmployeesContext(): any {
    const state = useContext(ADPEmployeesContext)
    return state
}

export const ADPEmployeesProvider = ({ children }: any) => {
    const { currentBusiness } = useCurrentStore()
    const { hasPayrollPermission } = usePermissions()
    const shouldFetchCompanyDetails =
        !hasPayrollPermission || !currentBusiness.has_payroll_company_created

    const [companyDetails, setCompanyDetails] = useState<ADPCompanyDetails>()
    const [refetchCompanyDetails, setRefetchCompanyDetails] =
        useState<boolean>(true)

    const fetchCompanyDetails = () => {
        getCompanyDetails().then((res: any) => {
            setCompanyDetails(res)
        })
    }

    useEffect(() => {
        if (refetchCompanyDetails) {
            fetchCompanyDetails()
            setRefetchCompanyDetails(false)
        }
    }, [refetchCompanyDetails])

    useEffect(() => {
        if (shouldFetchCompanyDetails) {
            fetchCompanyDetails()
        }
    }, [shouldFetchCompanyDetails])

    const providerValue: any = {
        companyDetails,
        setRefetchCompanyDetails,
    }

    return (
        <ADPEmployeesContext.Provider value={providerValue}>
            {children}
        </ADPEmployeesContext.Provider>
    )
}
