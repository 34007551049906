import { useState } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Conversation from '../../assets/images/Conversation.png';
import UiText from '../../components/common/ui/UiText';
import { commonStyles, mergeStyle } from '../../styles/commonStyles';
import Loader from '../../components/common/Loader';

const useStyles = makeStyles(() => {
    return {
        marginContent: {
            marginTop: '40px',
            marginBottom: '40px',
        },
        contentWidth: {
            width: '560px',
        },
    };
});

const PayrollProcessedInfo = () => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const history = useHistory();
    const styles = commonStyles();
    const classes = useStyles();

    return (
        <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: '100%',
            }}
            data-cy={'payroll-connect-to-specialist'}
        >
            {!imageLoaded && <Loader />}
            <img
                src={Conversation}
                alt="conversations"
                onLoad={() => {
                    setImageLoaded(true);
                }}
            />
            {imageLoaded && (
                <div>
                    <div
                        className={mergeStyle(
                            styles.mt16,
                            styles.textCenter,
                            classes.contentWidth
                        )}
                    >
                        <UiText className={styles.mb16} variant="van_225">
                            We’ll be in touch
                        </UiText>
                        <UiText variant="car_100">
                            We’re excited to get your business’s payroll up and
                            running! <br />
                            There are a few extra things we’ll need to take care
                            of first. Your Payroll Specialist will contact you
                            through the Message Center and help you every step
                            of the way.
                        </UiText>
                    </div>
                    <div
                        className={mergeStyle(
                            classes.marginContent,
                            styles.textCenter
                        )}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                history.push('/message_center');
                            }}
                            data-cy={'go-to-message-center'}
                        >
                            Go to Message Center
                        </Button>
                        <div className={styles.mt16}>
                            <UiText textColor="textSecondary">
                                Payroll is handled through Gusto
                            </UiText>
                        </div>
                    </div>
                </div>
            )}
        </Grid>
    );
};

export default PayrollProcessedInfo;
