export function convertBytesToSize(size: number): {
    size: number;
    sizeType: 'MB' | 'KB';
} {
    if (size >= 1024 * 1024) {
        // MB
        return {
            size: Math.floor(size / 1024 / 1024),
            sizeType: 'MB',
        };
    } else if (size > 0) {
        return { size: Math.floor(size / 1024), sizeType: 'KB' };
    } else {
        return { size, sizeType: 'KB' };
    }
}

export function convertToBytes(size: number, fileSizeType: 'MB' | 'KB') {
    return fileSizeType === 'MB' ? size * 1024 * 1024 : size * 1024;
}

export function isValidDocumentMime(
    {
        allowedMimeTypes,
        allowedDocumentTypes,
    }: { allowedDocumentTypes: string[]; allowedMimeTypes: string[] },
    file: File
): boolean {
    let allowTypesSubst = allowedDocumentTypes.map((t) => {
        return t.substr(-4);
    });
    return file.type && file.type.substr(0, 5) !== 'like/'
        ? !allowedMimeTypes.find((mime) => mime.indexOf(file.type) !== -1)
        : !allowTypesSubst.find(
              (type) => type.indexOf(file.name.substr(-4)) !== -1
          );
}
