import {
    Avatar,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
    Link,
    Box
} from "@material-ui/core";
import { Fragment, useState } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationStore } from "../../../models";
import PortalTabs from "../../common/PortalTabs";
import TabPanelHolder from "../../common/TabPanelHolder";
import { useHistory } from 'react-router-dom';
import { AlertData, showAlert } from "../../../store/actions/feedback";
import AppoinmentModal from "../../calender/AppoinmentModal";
import UiConfirmationDialog from "../../common/ui/UiConfirmationDialog";
import LinkButton from '../../common/LinkButton';
import PastDueNoticeModal from '../../calender/PastDueNoticeModal'
import AssignedAccountant from '../../dashboard/tax-prep/AssignedAccountant';

function TeamContent(props: {
    width: string;
    children: React.ReactNode,
    user: any;
    setDrawerState: any;
    showAlert: (data: AlertData) => void;
    setOpenNewMessageModal: any;
    setMessageTopic: any;
    accounts: any;
}) {
    const classes = makeStyles((theme: Theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: props.width,
        },
        title: {
            padding: theme.spacing(2),
            top: theme.spacing(0),
            position: "relative",
            display: "inline",
        },
        taskTitle: {
            padding: theme.spacing(2),
            marginTop: theme.spacing(7),
        },
        listRoot: {
            width: "100%",
            maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: "inline",
        },
        linkButton: {
            display: "flex",
        },
        timeText: {
            whiteSpace: "nowrap",
            paddingTop: "6px",
        },
        cardIcon: {
            width: "24px",
            height: "24px",
            opacity: "0.8",
            position: "relative",
            left: theme.spacing(1),
            top: theme.spacing(0),
        },
        navListItemGutters: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        actionBox: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 104,
            background: "#EFF3FD",
            borderRadius: theme.spacing(0),
            marginTop: theme.spacing(3),
        },
        pdfIcon: {
            position: "relative",
            top: theme.spacing(0),
            marginRight: theme.spacing(1),
        },
        listText: {
            maxWidth: '210px',
        }
    }))();

    enum TAX_RETURN_ROLES  {
        PTR = 'personal tax return',
        BTR = 'business tax return'
    }

    let userFinancePastDue = props?.accounts.filter((item: any) => 
        item?.account_type === 'personal' && item?.finance_status === 'Past Due');
    const [openPastDueModal, setOpenPastDueModal] = useState<boolean>(false)
    const [appoinmentTopic, setAppoinmentTopic] = useState<any>([]);
    const [appoinmentSubTopic, setAppoinmentSubTopic] = useState<any>([]);
    const [openAppoinmentModal, setOpenAppoinmentModal] = useState(false);
    const [deflectionFilter, setDeflectionFilter] = useState(false);
    const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
    const tax_advisor_role = 'tax advisor';

    const [learnMoreModalData, setLearnMoreModalData] = useState({
        title: '',
        content: <div></div>,
        confirmationButtonText: '',
        accountantType: '',
        TID: ''
    });
    const [appointmentAccountantId, setAppointmentAccountantId] = useState('');
    const { financial_pod, sf_contact_id, financial_pod_tasks } = props.user;
    const history = useHistory();

    const getAccountantDisplayName = (name: string) => {
        let lastSpaceIndex = name.lastIndexOf(' ');
        if (lastSpaceIndex > 0) {
            return name.substr(0, lastSpaceIndex + 1) + '' + name.substr(lastSpaceIndex + 1, 1);
        }
        return name;
    };

    const handleAppoinmentModalCLose = (data: any) => {
        setOpenAppoinmentModal(false);
        setAppoinmentTopic([]);
        if (data === 'success') {
            props.showAlert({
                alertText: 'Appointment is Scheduled Successfully!',
                alertType: 'success'
            });
            props.setDrawerState(false);
        }
    };

    const handleNewMessage = (financialTeam: any) => {
        props.setMessageTopic(financialTeam.message_center_topic);
        props.setOpenNewMessageModal(true);
    }

    function isFinancialTaskTaxReturnRole(role: string|null|undefined){
        if (!role) return false;

        return role.toLowerCase().indexOf(TAX_RETURN_ROLES.PTR) > -1 ||
               role.toLowerCase().indexOf(TAX_RETURN_ROLES.BTR) > -1 ;
    }

    const handleScheduleAppointment = (ftp: { 
                                                member_appointment_topic: string[]; 
                                                accountant_id: string; 
                                                member_appointment_sub_topic: string[]; 
                                                role: string;
                                                task_name: string;
                                            }) => {
        setAppoinmentTopic(ftp.member_appointment_topic);
        setAppointmentAccountantId(ftp.accountant_id);
        setDeflectionFilter(false);
        if(ftp.role){
            if(ftp.role.toLocaleLowerCase() === tax_advisor_role){
                setDeflectionFilter(true);
            }
        }else if(ftp.task_name){
            if(isFinancialTaskTaxReturnRole(ftp.task_name)){
                setDeflectionFilter(true);
            }
        }
        setOpenAppoinmentModal(true);
        setAppoinmentSubTopic(ftp.member_appointment_sub_topic);
    }

    const handleLearnMore = (accountantType: string, accountantRole: string) => {
        setLearnMoreModalData({
            title: '',
            content: <div></div>,
            confirmationButtonText: '',
            accountantType: '',
            TID: ''
        })
        learnMoreModalData.accountantType = accountantType;
        learnMoreModalData.title = accountantRole;
        switch (accountantType) {
            case "tax_advisor":
                learnMoreModalData.content = (<p>Work with an experienced Tax Advisor to:
                    <br /><br />
                    - Reduce your tax liability <br /><br />
                    - Keep you business tax compliant <br /><br />
                    - Help you plan for the future <br /><br />
                </p>)
                learnMoreModalData.confirmationButtonText = "Schedule a No-Cost Consultation";
                break;
            case "tax_accountant":
                learnMoreModalData.content = (<p>Purchase Business Tax Preparation to get Your accountant assigned.
                    <br /><br />
                    If you have already purchased Business Tax Preparation, your analyst will be assigned when you submit your
                    <Link href="#/tax-organizer/business"> Business Tax Information</Link>
                </p>)
                learnMoreModalData.confirmationButtonText = "Buy Business Tax Preparation";
                break;
            case "tax_analyst":
                learnMoreModalData.content = (<p>Purchase Business Tax Preparation to get Your accountant assigned.
                    <br /><br />
                    If you have already purchased Business Tax Preparation, your analyst will be assigned when you submit your
                    <Link href="#/tax-organizer/business"> Business Tax Information</Link>
                </p>)
                learnMoreModalData.confirmationButtonText = "Buy Business Tax Preparation";
                break;
            case "bookkeeping_specialist":
                learnMoreModalData.content = (
                    <p>Focus on making money. Let us handle your bookkeeping. Learn more about our bookkeeping services.</p>
                )
                learnMoreModalData.confirmationButtonText = "Schedule a No-Cost Consultation";
                break;
            case "payroll_specialist":
                learnMoreModalData.content = (
                    <p>Focus on making money. Let us handle your payroll. Learn more about our payroll services.</p>
                )
                learnMoreModalData.confirmationButtonText = "Schedule a No-Cost Consultation";
                break;
            case "retirement_specialist":
                learnMoreModalData.content = (
                    <p>
                        Does your business make more than $75K in annual profits
                        and/or have existing retirement plans? Let us help you
                        achieve additional tax savings while preparing for
                        retirement.
                    </p>
                );
                learnMoreModalData.confirmationButtonText = "Schedule a No-Cost Consultation";
                break;
        }
        if (accountantType === 'retirement_specialist') {
			if(financial_pod['tax_advisor'] && financial_pod['tax_advisor'].accountant_id) {
				learnMoreModalData.TID = '401K';
			} else {
				learnMoreModalData.TID = '1on1';
			}
		} else {
		    learnMoreModalData.TID = 'BNA';
		}
        setLearnMoreModalData({...learnMoreModalData});
        setShowLearnMoreModal(true);
    }

    const handleConfirmation = (result:boolean) => {
        if(result) {
            if (learnMoreModalData.accountantType  === 'tax_accountant' || learnMoreModalData.accountantType  === 'tax_analyst') {
                history.push('/purchase/products');
            } else {
                const url:any = `https://1800accountant.com/sfapi/schedule/index.php?WID=${sf_contact_id}&TID=${learnMoreModalData.TID}`;
                window.location = url; 
            }
            props.setDrawerState(false);
        }
        setShowLearnMoreModal(false);
    }

    // function Activity() {
    //     return (
    //         <Fragment>
    //             <List className={classes.listRoot}>
    //                 <ListItem
    //                     classes={{
    //                         gutters: classes.navListItemGutters,
    //                     }}
    //                     alignItems="flex-start"
    //                 >
    //                     <ListItemAvatar>
    //                         <img
    //                             alt={`Clock Icon`}
    //                             src={require(`../../../assets/icons-svg/clock.svg`)}
    //                             className={classes.cardIcon}
    //                         />
    //                     </ListItemAvatar>
    //                     <ListItemText
    //                         secondary={
    //                             <Fragment>
    //                                 {"We’ve worked "}
    //                                 <span style={{ fontWeight: 700 }}>
    //                                     17 hours
    //                                 </span>
    //                                 {
    //                                     " on your account since September 29, 2020."
    //                                 }
    //                             </Fragment>
    //                         }
    //                     />
    //                 </ListItem>
    //                 <ListItem
    //                     classes={{
    //                         gutters: classes.navListItemGutters,
    //                     }}
    //                     alignItems="flex-start"
    //                 >
    //                     <ListItemAvatar>
    //                         <Avatar
    //                             alt="Remy Sharp"
    //                             src="https://material-ui.com/static/images/avatar/3.jpg"
    //                         />
    //                     </ListItemAvatar>
    //                     <ListItemText
    //                         primary="Christopher C"
    //                         secondary={
    //                             <Fragment>
    //                                 <span>
    //                                     Federal and State taxes submitted
    //                                     successfully.
    //                                 </span>
    //                                 <Link href="#" component="button">
    //                                     <img
    //                                         className={classes.pdfIcon}
    //                                         alt={`PDF Icon`}
    //                                         src={require(`../../../assets/icons-svg/PDF.svg`)}
    //                                     />
    //                                     Name of uploaded document.pdf
    //                                 </Link>
    //                             </Fragment>
    //                         }
    //                     />
    //                     <Typography
    //                         className={classes.timeText}
    //                         variant="caption"
    //                     >
    //                         6h
    //                     </Typography>
    //                 </ListItem>
    //                 <Divider variant="fullWidth" component="li" />
    //                 <ListItem
    //                     classes={{
    //                         gutters: classes.navListItemGutters,
    //                     }}
    //                     alignItems="flex-start"
    //                 >
    //                     <ListItemAvatar>
    //                         <Avatar
    //                             alt="Remy Sharp"
    //                             src="https://material-ui.com/static/images/avatar/3.jpg"
    //                         />
    //                     </ListItemAvatar>
    //                     <ListItemText
    //                         primary="Angela H"
    //                         secondary={
    //                             "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do "
    //                         }
    //                     />
    //                     <Typography
    //                         className={classes.timeText}
    //                         variant="caption"
    //                     >
    //                         7h
    //                     </Typography>
    //                 </ListItem>
    //                 <Divider variant="fullWidth" component="li" />
    //             </List>
    //         </Fragment>
    //     );
    // }

    const handleAppointment = (ftp: any) => {
        if(userFinancePastDue.length > 0) {
            setOpenPastDueModal(true);
        } else {
            handleScheduleAppointment(ftp)
        }
    }

    const iconsNotAllowed = (memberType: string) => {
        return [
            'tax_analyst',
            'tax_accountant',
        ].includes(memberType);
    }

    const permissionExemption = (memberType: string) => {
        return [
            'tax_advisor',
            'retirement_specialist',
            'tax_accountant',
            'tax_analyst',
        ].includes(memberType);
    };

    function Members() {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    {Object.keys(financial_pod).map((key, index) => (
                        <Fragment key={index}>
                            <ListItem
                                classes={{
                                    gutters: classes.navListItemGutters,
                                }}
                                alignItems='center'
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={financial_pod[key]?.name}
                                        src={financial_pod[key]?.member_avatar}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    className={classes.listText}
                                    primary={
                                        financial_pod[key].permission_exists ||
                                        permissionExemption(key) ? (
                                            financial_pod[key].accountant_id ? (
                                                getAccountantDisplayName(
                                                    financial_pod[key].name
                                                )
                                            ) : financial_pod[key]
                                                  .permission_exists ? (
                                                'Pending'
                                            ) : (
                                                <LinkButton
                                                    style={{
                                                        fontWeight: 400,
                                                        height: 'fit-content',
                                                    }}
                                                    onClick={() =>
                                                        handleLearnMore(
                                                            key,
                                                            financial_pod[key]
                                                                .role
                                                        )
                                                    }
                                                >
                                                    Learn More
                                                </LinkButton>
                                            )
                                        ) : (
                                            <LinkButton
                                                style={{
                                                    fontWeight: 400,
                                                    height: 'fit-content',
                                                }}
                                                onClick={() =>
                                                    handleLearnMore(
                                                        key,
                                                        financial_pod[key].role
                                                    )
                                                }
                                            >
                                                Learn More
                                            </LinkButton>
                                        )
                                    }
                                    secondary={financial_pod[key].role}
                                />
                                {(financial_pod[key].permission_exists ||
                                permissionExemption(key)) && !iconsNotAllowed(key) 
                                    ? financial_pod[key].accountant_id && (
                                          <ListItemSecondaryAction>
                                              <IconButton
                                                  edge='end'
                                                  onClick={() => {
                                                      handleNewMessage(
                                                          financial_pod[key]
                                                      );
                                                  }}
                                              >
                                                  <img
                                                      alt={`Messages Icon`}
                                                      src={
                                                          require(`../../../assets/icons-svg/Messages.svg`)
                                                              .default
                                                      }
                                                  />
                                              </IconButton>
                                              {key !== 'tax_analyst' &&
                                                  financial_pod[key]
                                                      .show_calendar_icon && (
                                                      <IconButton
                                                          edge='end'
                                                          onClick={() =>
                                                              handleAppointment(
                                                                  financial_pod[
                                                                      key
                                                                  ]
                                                              )
                                                          }
                                                      >
                                                          <img
                                                              alt={`Calendar Icon`}
                                                              src={
                                                                  require(`../../../assets/icons-svg/Calendar.svg`)
                                                                      .default
                                                              }
                                                          />
                                                      </IconButton>
                                                  )}
                                          </ListItemSecondaryAction>
                                      )
                                    : null}
                            </ListItem>
                            <Divider variant='inset' component='li' />
                        </Fragment>
                    ))}
                </List>
                {/* <Box className={classes.actionBox}>
                    <Typography variant="body1" gutterBottom>
                        Pay up to 20% less in taxes
                    </Typography>
                    <LinkButton className={classes.linkButton}>
                        Talk with a Tax Analyst
                    </LinkButton>
                </Box>
                <Box className={classes.actionBox}>
                    <Typography variant="body1" gutterBottom>
                        Get advice on 401k options and plans
                    </Typography>
                    <LinkButton className={classes.linkButton}>
                        Talk with a Retirement Specialist
                    </LinkButton>
                </Box> */}
            </Fragment>
        );
    }

    return (
        <div className={classes.root}>
            {props.children}
            <Typography className={classes.title} variant="h5">
                Team
            </Typography>
            <PortalTabs>
                <TabPanelHolder index={0} title="Members">
                    <>
                        <Members />
                        {financial_pod_tasks?.length > 0 && (
                            <Typography
                                className={classes.taskTitle}
                                variant='h5'
                            >
                                Tasks
                            </Typography>
                        )}
                        {financial_pod_tasks?.length > 0 && financial_pod_tasks.map((member: any, index: number) => {
                            return (
                                <Box p={2} key={`member_${index}`}>
                                    <AssignedAccountant
                                        name={member?.name || 'Tax Accountant'}
                                        role={member.task_name}
                                        accountName={member.account_name}
                                        profileImage={member?.member_avatar}
                                        accountantId={member?.accountant_id}
                                        appoinmentTopic={member.member_appointment_topic}
                                        messageTopic={member?.message_center_topic}
                                        handleNewMessage={handleNewMessage}
                                        handleAppointment={handleAppointment}
                                        appointmentSubTopic={member.member_appointment_sub_topic}
                                    />
                                </Box>
                            )
                        })}
                    </>
                </TabPanelHolder>
                {/* <TabPanelHolder index={1} title="Activity">
                    <Activity />
                </TabPanelHolder> */}
            </PortalTabs>
            <PastDueNoticeModal
                openModal={openPastDueModal}
                closeModal={()=> { 
                    setOpenPastDueModal(false)
                    props.setDrawerState(false);
                }}
                handleAction={() => {
                    setOpenPastDueModal(false)
                    props.setDrawerState(false);
                    history.push('/purchase/billing');
                }}
            />
            {appoinmentTopic.length && appointmentAccountantId ? (
                <AppoinmentModal
                    topics={appoinmentTopic}
                    open={openAppoinmentModal}
                    handleClose={handleAppoinmentModalCLose}
                    accountantId={
                        appointmentAccountantId
                    }
                    subTopics={appoinmentSubTopic}
                    deflectionFilter={deflectionFilter}
                />
            ) : (
                ''
            )}
            <UiConfirmationDialog
                open={showLearnMoreModal}
                title={learnMoreModalData.title}
                hideTitleSection={false}
                message={
                    <Fragment>
                        <Typography
                            variant='body1'
                            style={{ wordWrap: 'break-word' }}
                            gutterBottom
                            component="div"
                        >{learnMoreModalData.content}</Typography>
                    </Fragment>
                }
                handleClose={handleConfirmation}
                confirmButtonText={learnMoreModalData.confirmationButtonText}
                confirmButtonColor="primary"
                cancelButtonText="Cancel"
            />
        </div>
    );
}
const mapStateToProps = ({ appData }: ApplicationStore) => ({
    accounts: appData.accounts,
    user: appData.user
})
export default connect(mapStateToProps, { showAlert })(TeamContent);
