export interface Business {
    id: string;
    name: string;
    logo: string;
}

export interface Assets {
    account_id: string;
    title: string;
    children: NodeChild[];
    balance: string;
    class_id?: string;
    class_name?: string;
}

export type Revenue = {
    account_id: string;
    title: string;
    children: NodeChild[];
    balance: string;
    class_id?: string;
    class_name?: string;
};

export type Expenses = {
    account_id: string;
    title: string;
    children: NodeChild[];
    balance: string;
    class_id?: string;
    class_name?: string;
};

export interface Liabilities {
    account_id: string;
    title: string;
    children: NodeChild[];
    balance: string;
    class_id?: string;
    class_name?: string;
}

export interface NodeChild {
    account_id: string;
    title: string;
    children: any[];
    balance: string;
    class_id?: string;
    class_name?: string;
}
export interface Capital {
    account_id: string;
    title: string;
    children: NodeChild[];
    balance: string;
    class_id?: string;
    class_name?: string;
}

export interface BalanceSheetReportRoot {
    business: Business;
    end_date: number;
    assets: Assets;
    liabilities: Liabilities;
    capital: Capital;
    total_liabilities_and_capital: string;
    debt_to_assets_ratio: string;
    warning?: string;
    selected_classes: any;
}
export interface BalanceSheetReportAttributes {
    assets?: Assets;
    liabilities?: Liabilities;
    capital?: Capital;
}

export interface IncomeStatementReportAttributes {
    revenue?: Revenue;
    expenses?: Expenses;
}

export interface IncomeStatementReportRoot {
    business: Business;
    start_date: number;
    end_date: number;
    revenue: Revenue;
    expenses: Expenses;
    total_net_income: string;
    net_profit_margin: string;
    warning?: string;
    selected_classes: any;
}

export interface saveIncomeReportToPortalData {
    comment: string;
    period_text: string;
    start_date_ymd: string;
    end_date_ymd: string;
    time: number;
}
export interface SaveReconciliationDocumentData {
    comment: string;
    period_text: string;
    start_date_ymd: string;
    end_date_ymd: string;
}
export interface SaveJournal {
    location: string;
}
export interface SaveReportsToPortalData {
    comment: string;
    period_text: string;
    time: number;
    start_date_ymd?: string;
    end_date_ymd?: string;
}
export interface SaveTransactionDocument {
    start_date_ymd: string;
    end_date_ymd: string;
    period_text: string;
    time: number;
    comment: string;
}
export interface InvoiceItem {
    id: string;
    type: string;
    issue_date: number;
    due_date: number;
    number: string;
    contact_name: string;
    contact_id: string;
    status: string;
    amount_due: string;
}

export interface InvoiceReportRoot {
    business: Business;
    start_date: number;
    end_date: number;
    total_quantity: number;
    total_paid: string;
    total_unpaid: string;
    date_filter: string;
    items: InvoiceItem[];
}

export interface Transactions {
    amount: string;
    balance: string;
    category: string;
    customer_name: string;
    date: number;
    description: string;
    id: string;
    number: string;
    type: transactionType | string;
}

export enum transactionType {
    TRIP = "Trip",
    JOURNAL_ENTRY = "Journal Entry",
    TRANSACTION = "Transaction",
    CLOSING_ENTRY = "Closing Entry",
}

export interface Account {
    balance: string;
    children: any;
    title: string;
    beginning_balance?: string;
    transactions: Transactions[];
}

export interface TransactionReportRoot {
    account: Account;
    business: Business;
    warning?: string;
}

export interface GeneralLadgerReportRoot {
    accounts: Account[];
    warning?: string;
    business: Business;
}

export interface InvoiceUpdate {
    payment_status: string;
}
export interface Address {
    address: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
}
export interface ContactData {
    type: string;
    name: string;
    email: string;
    address: Address;
    phone: string;
}
export interface OfferingData {
    type: string;
    title: string;
    description: string;
    tax_rate: number;
    price: string;
    category: string;
}
export interface InvoiceLine {
    offering_data: OfferingData;
    offering_quantity: number;
    amount: string;
}
export interface InvoiceUpdatedRoot {
    is_overdue: boolean;
    is_payrix_merchant_approved: boolean;
    is_email_sent: boolean;
    id: string;
    number: string;
    contact_data: ContactData;
    payment_status: string;
    invoice_date: number;
    due_date: number;
    message_to_customer: string;
    use_taxes: boolean;
    subtotal: string;
    tax_total: string;
    total: string;
    invoice_lines: InvoiceLine[];
    online_payment_enabled: boolean;
    token: string;
    show_review_widget: boolean;
    business: string;
    is_newtek_merchant_approved: boolean;
}
export interface Reconciliation {
    id: string;
    is_submitted: boolean;
    start_balance: string;
    start_date: number;
    end_balance: string;
    end_date: number;
    submit_date: number;
}
export interface Account {
    id: string;
    title: string;
    reconciliations: Reconciliation[];
}
export interface ReconciliationResponse {
    business: Business;
    accounts: Account[];
}
export interface TransactionList {
    id: string;
    date: number;
    number: string;
    amount: string;
    balance?: string;
    customer_name: string;
}
export interface ReconciliationDetails {
    business: Business;
    category: string;
    start_date: number;
    end_date: number;
    start_balance: string;
    end_balance: string;
    balance: string;
    transaction_count: number;
    transaction_list: TransactionList[];
    account: string;
}

export interface JETransactionAccount {
    account: string;
    debit?: string;
    credit?: string;
}

export interface JETransaction {
    amounts: JETransactionAccount[];
    date: number;
    description: string;
    id: string;
    number: string;
    total_credit: string;
    total_debit: string;
    type: string;
}
export interface JournalEntriesReportRoot {
    business: Business;
    end_date: number;
    start_date: number;
    subtotal_credit: string;
    subtotal_debit: string;
    transactions: JETransaction[];
}
export interface Trip {
    tax_deductions: string;
    location_from_name: string;
    location_to_name: string;
    purpose_name: string;
    expenses: string;
    vehicle_name: string;
    id: string;
    number: string;
    date: number;
    miles: number;
    show_odometer: boolean;
    odometer_start?: any;
    odometer_end?: any;
}
export interface Total {
    mileage: number;
    expenses: string;
    deductions: string;
}
export interface MileageLogReport {
    vehicle_names: string;
    activity_name: string;
    business?: Business;
    period: string;
    total: Total;
    show_odometer: boolean;
    trips: Trip[];
}

export interface ApiError {
    code: number;
    message: string;
    errors: any[];
    errorCode?: any;
    statusText?: string;
    status: number;
}
export interface ErrorAlertReport {
    isShow: boolean;
    message: string;
}
export enum DOWNLOAD_REPORT_TYPES {
    PDF = 'PDF',
    CSV = 'CSV',
}

export enum DOWNLOAD_REPORT_OPTION_TYPES {
    SAVE = 'save',
    DOWNLOAD = 'download',
}
export interface Amount {
    account: string;
    debit: string;
    credit: string;
}
export interface Transaction {
    id: string;
    date: number;
    type: string;
    number: string;
    description: string;
    total_credit: string;
    total_debit: string;
    amounts: Amount[];
}
export interface GeneralJournalReport {
    business: Business;
    start_date: number;
    end_date: number;
    transactions: Transaction[];
    warning?: string;
    subtotal_debit: string;
    subtotal_credit: string;
}
export interface LogoImage {
    image: string;
    isLoading: boolean;
}
export type DownloadModalFormData = {
    comment?: string;
};
/* Can be removed after merging chart of accounts module */

export interface CoaBusinessCategory {
    balance_multiplier: number;
    level: number;
    can_change_visibility: boolean;
    can_edit: boolean;
    can_remove: boolean;
    type: string;
    can_have_children: boolean;
    can_be_transaction_from: boolean;
    can_be_transaction_to: boolean;
    can_be_transaction_split: boolean;
    can_be_journal_entry_account: boolean;
    is_hidden: boolean;
    is_aggregated_account: boolean;
    id: string;
    title: string;
    abbr: string;
    description: string;
    order: number;
    debit: boolean;
    reconciliation_count: number;
    balance: string;
    entries_count: number;
    parent: string;
    industries: string[];
    colorHex: string;
    is_personal_fund: boolean;
    coa_default_category: string;
    business: string;
    is_leaf: boolean;
    next_reconciliation_start_date?: number;
    next_reconciliation_start_balance: string;
    bank_account_account_type: string;
    bankAccount: string;
    displayDescription: string;
    displayTitle: string;
}

export interface CoaBusinessClass {
    id: string;
    business_id: string;
    name: string;
    created_at: number;
    updated_at: number;
}
export interface ChartOfAccounts {
    balance_multiplier: number;
    level: number;
    can_change_visibility: boolean;
    can_edit: boolean;
    can_remove: boolean;
    type: string;
    can_have_children: boolean;
    can_be_transaction_from: boolean;
    can_be_transaction_to: boolean;
    can_be_transaction_split: boolean;
    can_be_journal_entry_account: boolean;
    is_hidden: boolean;
    is_aggregated_account: boolean;
    id: string;
    title: string;
    abbr: string;
    description: string;
    mongo_base_account_id: string;
    order: number;
    debit: boolean;
    balance: string;
    entries_count: number;
    displayDescription: string;
    displayTitle: string;
    parent: string;
    always_show?: boolean;
    reconciliation_count?: number;
    industries: string[];
    colorHex: string;
    is_personal_fund?: boolean;
    business: string;
    coa_business_category: CoaBusinessCategory;
    is_leaf?: boolean;
    coa_business_class: CoaBusinessClass;
    next_reconciliation_start_date?: number;
    next_reconciliation_start_balance: string;
    bank_account_account_type: string;
    bankAccount: string;
    coa_default_category: string;
}
/*chart of accounts - end */
