import { useState } from 'react';
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from '@material-ui/core';
import { Clear, ArrowForward } from '@material-ui/icons';
import * as Yup from 'yup';
import UiDialog from '../../common/ui/UiDialog';
import { Form, Formik } from 'formik';
import UiText from '../../common/ui/UiText';
import { ChartOfAccountantsData } from '../../../models/chartOfAccountants';
import { moveCatoryToResource } from '../../../services/apiService/chartOfAccountants';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../store/actions/feedback';
import Loader from '../../common/Loader';
import { L1AndL2Parents } from './ChartOfAccounts';
import { MoveAccountConfirm } from './MoveAccountConfirm';
import { loadCategories } from '../../../store/actions/categories';

interface MoveAccountType {
    isOpen: boolean;
    handleClose: any;
    classes: any;
    level3: ChartOfAccountantsData[];
    srcCategoryDetails: ChartOfAccountantsData | undefined;
    appData: any;
    handleMoveAccountClose: (
        isDataChanged: boolean,
        updatedCategory: ChartOfAccountantsData
    ) => void;
    currentParentOfL4: ChartOfAccountantsData | undefined;
    l1AndL2Parents: L1AndL2Parents;
}

export interface MoveSegmentBody {
    coa_business_category_id: string;
}
const useStyles = makeStyles((theme: Theme) => ({
    loaderBtn: {
        display: 'flex',
    },
    formErrorText: {
        color: theme.palette.error.main,
        fontSize: '0.75rem',
        margin: '0.5rem 0 0 0.5rem',
    },
    actionButton: {
        margin: '0 1rem 1rem 0'
    }
}));
export function MoveAccount(props: MoveAccountType) {
    const {
        isOpen,
        handleClose,
        classes,
        level3,
        srcCategoryDetails,
        appData,
        handleMoveAccountClose,
        currentParentOfL4,
        l1AndL2Parents,
    } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const accountId = appData.current_account_id;
    const businessId = appData.current_business_id;
    const dispatch = useDispatch();
    const localClasses = useStyles();
    const [isShowConvertPopup, setIsShowConvertPopup] =
        useState<boolean>(false);
    const [destCategory, setDestCategory] = useState<ChartOfAccountantsData>();
    const validationSchema = Yup.object({
        destination: Yup.string().required('Destination is required'),
    });

    const dispatchErrorToStore = (error: { errors: { name: string } }) => {
        const message = error?.errors?.name;
        dispatch(
            showAlert({
                alertType: 'error',
                alertText: message ?? 'Something went wrong',
            })
        );
    };
    const saveNewlyMovedCategory = (
        srcCategoryId: string,
        body: MoveSegmentBody
    ) => {
        moveCatoryToResource(accountId, businessId, srcCategoryId, body)
            .then((res: ChartOfAccountantsData) => {
                const updatedCategory = res;
                setIsLoading(false);
                handleMoveAccountClose(true, updatedCategory);
                handleClose(false);
                dispatch(loadCategories());
            })
            .catch((error) => {
                setIsLoading(false);
                dispatchErrorToStore(error);
                handleClose(false);
            });
    };
    const setDestinationCategoryData = (destCategoryId: string) => {
        const destCategory = level3.filter((l3Values) => {
            return l3Values.id === destCategoryId;
        });
        setDestCategory(destCategory[0]);
    };
    const showConvertWarningPopup = (destCategoryId: string) => {
        setDestinationCategoryData(destCategoryId);
        setIsShowConvertPopup(true);
    };
    const handleFormSubmit = (
        values: { destination: string },
        { setFieldError }: any
    ) => {
        const destCategoryId = values.destination;
        const body: MoveSegmentBody = {
            coa_business_category_id: destCategoryId,
        };
        setIsLoading(true);
        const srcCategoryId = srcCategoryDetails?.id;
        const srcCategoryLevel = srcCategoryDetails?.level;
        if (!srcCategoryId) return;
        if (srcCategoryLevel === 4) {
            saveNewlyMovedCategory(srcCategoryId, body);
        } else {
            showConvertWarningPopup(destCategoryId);
        }
    };

    //level3Parents
    const getTargetCategoriesList = () => {
        return level3.map((l3Values: any, index) => {
            return (
                !l3Values?.bankAccount &&
                l3Values?.can_have_children &&
                !l3Values?.is_journal_entry_line && (
                    <MenuItem key={index} value={l3Values.id}>
                        {l3Values.title}
                    </MenuItem>
                )
            );
        });
    };
    const getCurrentParentObject = () => {
        if (!currentParentOfL4) return;
        return (
            <MenuItem value={currentParentOfL4.title} disabled={true}>
                {currentParentOfL4.title} (Current)
            </MenuItem>
        );
    };
    const handleCloseWarningPopup = () => {
        setIsShowConvertPopup(false);
        setIsLoading(false);
    };
    const handleMoveAccountConfirm = (
        isDataChanged: boolean,
        updatedCategory: ChartOfAccountantsData
    ) => {
        handleMoveAccountClose(isDataChanged, updatedCategory);
        handleClose(false);
    };
    const getParentL1AndL2CategoryList = () => {
        return (
            <div>
                <MenuItem value={l1AndL2Parents.l1Parent.title} disabled={true}>
                    {l1AndL2Parents.l1Parent.title} (Parent)
                </MenuItem>
                <MenuItem value={l1AndL2Parents.l1Parent.title} disabled={true}>
                    {l1AndL2Parents.l2Parent.title} (Parent)
                </MenuItem>
            </div>
        );
    };

    return (
        <UiDialog
            open={isOpen}
            handleClose={() => handleClose(false)}
            title="Move Account"
            hideTitleSection
            size="xs"
        >
            <>
                <div className={classes.flexContainer}>
                    <div>
                        <UiText variant="hatchback_125" weight="medium_500">
                            Move Account
                        </UiText>
                    </div>
                    <div>
                        <IconButton onClick={() => handleClose(false)} data-cy="close-move-modal">
                            <Clear />
                        </IconButton>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        destination: '',
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                    validateOnMount={true}
                >
                    {({
                        submitForm,
                        handleSubmit,
                        submitCount,
                        setFieldValue,
                        values: formValues,
                        isValid,
                        errors,
                        touched,
                    }) => (
                        <Form>
                            <div className={classes.moveInput}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel>Select Destination</InputLabel>
                                    <Select
                                        label="Select Destination"
                                        onChange={(event) => {
                                            setFieldValue(
                                                'destination',
                                                event.target.value
                                            );
                                        }}
                                        value={formValues.destination}
                                        name="destination"
                                        id="destination"
                                        data-cy='destination-input'
                                        className={
                                            errors.destination &&
                                            touched.destination
                                                ? ' Mui-error '
                                                : ''
                                        }
                                    >
                                        {getParentL1AndL2CategoryList()}
                                        {currentParentOfL4 &&
                                            getCurrentParentObject()}
                                        {getTargetCategoriesList()}
                                    </Select>
                                    {errors.destination &&
                                    touched.destination ? (
                                        <p
                                            className={
                                                localClasses.formErrorText
                                            }
                                        >
                                            {errors.destination}{' '}
                                        </p>
                                    ) : null}
                                </FormControl>
                            </div>
                            <div className={localClasses.loaderBtn}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleClose(false)}
                                    className={localClasses.actionButton}
                                    data-cy="cancel-move-acc-btn"
                                >
                                    Cancel
                                </Button>

                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={localClasses.actionButton}
                                        endIcon={<ArrowForward />}
                                        data-cy="move-acc-btn"
                                    >
                                        Move
                                    </Button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
            <MoveAccountConfirm
                isOpen={isShowConvertPopup}
                srcCategory={srcCategoryDetails!}
                destCategory={destCategory!}
                handleClose={handleCloseWarningPopup}
                accountId={accountId}
                businessId={businessId}
                handleMoveAccountConfirm={handleMoveAccountConfirm}
            />
        </UiDialog>
    );
}
