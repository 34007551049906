import React, { useState, useContext } from 'react'

export const OnBoardingContext = React.createContext(null)

export function useOnBoardingState(): any {
    const state = useContext(OnBoardingContext)
    return state
}

export const OnBoardingProvider = ({ children }: any) => {
    const [formState, setFormState] = useState({} as any)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [isInitialValuesSet, setIsInitialValuesSet] = useState(false)

    const providerValue: any = {
        formState,
        setFormState,
        isFormSubmitting,
        setIsFormSubmitting,
        isInitialValuesSet,
        setIsInitialValuesSet,
    }

    return (
        <OnBoardingContext.Provider value={providerValue}>
            {children}
        </OnBoardingContext.Provider>
    )
}
