import React from 'react';
import Icon from '../../common/Icon';
import UiText from '../../common/ui/UiText';
import InfoCard from '../Cards/InfoCard';
import { useStyles } from './BookKeepingInfoCard';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';


const CarryoverTaxInfoCard = ({ onButtonClick } : { onButtonClick: () => void }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconStyle}>
                    <Icon icon="bookkeepingInfo" />
                </div>
                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <UiText variant="hatchback_125" weight="medium_500">
                            You may qualify for Carryover Tax Benefits
                        </UiText>
                    }
                    subtitle={
                        <UiText variant="motorcycle_90">
                            When we prepare your next tax return, we’ll check your tax benefits eligibility for you 
                            by reviewing your previous personal tax returns. 
                        </UiText>
                    }
                    buttonText={'Check for Benefits'}
                    buttonClick={onButtonClick}
                />
            </div>
        </div>
    )
}

export default CarryoverTaxInfoCard