import React, { useEffect, useCallback } from 'react';
import Modal from '@material-ui/core/Modal'
import { Box, Divider, IconButton } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import UiText from '../../../common/ui/UiText'
import { COLORS } from '../../../../variables/colors'
import IconList from '../../../common/IconList'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import {
    ESTIMATED_COMPLETION_MESSAGES,
    DISCARDED_SCHEDULE_C,
    progressStepperContent,
    progressStepperForDiscardedScheduleC,
    TAX_PREP_STEPS
} from '../constants/tax-prep.const'
import { useWidgetContext } from '../provider/WidgetProvider'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContentContainer: {
        width: '40rem',
        backgroundColor: colorTheme => colorTheme.primaryWhite,
        borderRadius: '1rem',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            borderRadius: 0,
            overflow: 'auto',
        },
        '&:focus-visible': {
            outline: 'none'
        }
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1.375rem',
        boxSizing: 'border-box',
        borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
        backgroundColor: colorTheme => colorTheme.primaryWhite,
        borderRadius: '1rem',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.down('xs')]: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            flexDirection: 'row-reverse',
            paddingRight: '5.5rem',
            paddingLeft: '1rem',
        },
    },
    closeIconButton: {
        padding: 0,
    },
    modalContent: {
        padding: '1rem 1rem 1.5rem 1rem',
    },
    estimateCompletionInfoContainer: {
        display: 'flex',
    },
    estimateCompletionInfo: {
        marginLeft: '1.125rem',
    },
    clockIcon: {
        width: '1.25rem',
        height: '1.25rem',
    },
    estimateCompletionInfoText: {
        padding: '0.875rem 1rem',
        backgroundColor: colorTheme => colorTheme.blue300,
        color: colorTheme => colorTheme.black100,
        marginTop: '0.5rem',
    },
    stepperContent: {
        overflow: '',
    },
    stepper: {
        paddingBottom: 0,
        '&.MuiStepper-root': {
            padding: '1rem 0',
            '& .MuiStepLabel-iconContainer': {
                paddingRight: '18px',
            },
        },
        '& .MuiStep-root .MuiStepLabel-root.Mui-disabled .MuiStepLabel-label': {
            color: colorTheme => colorTheme.black500,
            fontWeight: 500,
        },
        '& .MuiStepContent-root': {
            marginTop: '0.125rem',
            marginBottom: '0.25rem',
            paddingLeft: '1.75rem',
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: colorTheme => colorTheme.tertiary,
        },
        '& .MuiStep-root .MuiStepContent-root': {
            borderLeft: colorTheme =>`2px solid ${colorTheme.grey200}`,
        },
        '& .MuiStep-completed .MuiStepContent-root': {
            borderLeft: colorTheme =>`2px solid ${colorTheme.tertiary}`,
        },
        '& .MuiStep-root:nth-of-type(13)': {
            '& .MuiDivider-root': {
                display: 'none',
            },
            '& .MuiStepContent-root': {
                borderLeft: 'none',
            },
        },
        '& .MuiStepConnector-vertical': {
            display: 'none ',
        },
    },
    discarded: {
        '& .MuiStep-root:nth-of-type(9)': {
            '& .MuiDivider-root': {
                display: 'none',
            },
            '& .MuiStepContent-root': {
                borderLeft: 'none',
            },
        },
    },
    stepContent: {
        paddingTop: 0,
        marginTop: 0,
        color: colorTheme => colorTheme.black100,
    },
    activeIcon: {
        width: '1.5rem',
        height: '1.5rem',
        boxSizing: 'border-box',
        border: colorTheme => `2px solid ${colorTheme.tertiary}`,
        borderRadius: '50%',
    },
    nonActiveIcon: {
        width: '1.5rem',
        height: '1.5rem',
        boxSizing: 'border-box',
        border: colorTheme => `2px solid ${colorTheme.grey800}`,
        borderRadius: '50%',
    },
    currentStepIndicator: {
        padding: '2px 4px',
        backgroundColor: colorTheme => colorTheme.cyan100,
        borderRadius: '4px',
        marginBottom: '6px',
        width: '5.75rem',
    },
    divider: {
        margin: '7px 0',
    },
}))

type ProgressStepSummaryModalProps = {
    open: boolean
    handleClose: () => void
    title: string
    estimatedCompletionDate?: string
}

const CustomStepIcon = () => {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    return <div className={classes.activeIcon}></div>
}

const CustomNotActiveStepIcon = () => {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    return <div className={classes.nonActiveIcon}></div>
}

const ProgressStepSummaryModal = ({
    open,
    handleClose,
    title,
    estimatedCompletionDate,
}: ProgressStepSummaryModalProps) => {
    const [activeStep, setActiveStep] = React.useState(0)
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const {
        taxPrepData: { business: businessData, personal: personalData },
        taxYear,
    } = useWidgetContext()

    const handleCloseModal = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        handleClose()
    }

    const isDiscardedScheduleC: boolean = DISCARDED_SCHEDULE_C === businessData?.tax_ap_status && title === 'Business Tax Prep'

    const progressStepWithData = () => {
        return isDiscardedScheduleC && title === 'Business Tax Prep' ? progressStepperForDiscardedScheduleC : progressStepperContent
    }

    const getTaxPrepStatus = useCallback(() => {
        const status =
            title === 'Business Tax Prep'
                ? businessData?.tax_preparation_current_step?.title ?? ''
                : personalData?.tax_preparation_current_step?.title ?? ''

        return status
    }, [businessData, personalData, title])

    useEffect(() => {
        const taxPrepStatus = getTaxPrepStatus()
        const stepIndex = TAX_PREP_STEPS.findIndex(
            (step) => step === taxPrepStatus
        )
        setActiveStep(isDiscardedScheduleC ? 5 : stepIndex)
    }, [businessData, personalData, getTaxPrepStatus, isDiscardedScheduleC])

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalContainer}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={classes.modalContentContainer}
            >
                <div className={classes.modalHeader}>
                    <UiText variant="car_100" weight="medium_500">
                        {`${taxYear} ${title} Steps`}
                    </UiText>
                    <IconButton
                        aria-label="close"
                        classes={{ root: classes.closeIconButton }}
                        onClick={handleCloseModal}
                        data-cy="cross-modal-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.modalContent}>
                    {!isDiscardedScheduleC && <div className={classes.estimateCompletionInfoContainer}>
                        <div className={classes.clockIcon}>
                            {IconList['clock']}
                        </div>
                        <div className={classes.estimateCompletionInfo}>
                            <UiText variant="car_100" weight="medium_500">
                                Estimated Completion: {estimatedCompletionDate}
                            </UiText>
                            <div className={classes.estimateCompletionInfoText}>
                                <UiText
                                    variant="motorcycle_90"
                                    weight="regular_400"
                                >
                                    {ESTIMATED_COMPLETION_MESSAGES}
                                </UiText>
                            </div>
                        </div>
                    </div>}

                    <div className={classes.stepperContent}>
                        <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            className={`${classes.stepper} ${isDiscardedScheduleC && classes.discarded}`}
                        >
                            {progressStepWithData().map((step) => (
                                <Step key={step.label} expanded={true}>
                                    <StepLabel
                                        StepIconComponent={
                                            activeStep === step.currentStep
                                                ? CustomStepIcon
                                                : activeStep < step.currentStep
                                                    ? CustomNotActiveStepIcon
                                                    : undefined
                                        }
                                    >
                                        {activeStep === step.currentStep && (
                                            <Box
                                                className={
                                                    classes.currentStepIndicator
                                                }
                                            >
                                                <UiText
                                                    variant="moped_75"
                                                    weight="medium_500"
                                                >
                                                    Current Step
                                                </UiText>
                                            </Box>
                                        )}
                                        <UiText
                                            variant="car_100"
                                            weight="medium_500"
                                        >
                                            {step.label}
                                        </UiText>
                                    </StepLabel>
                                    <StepContent
                                        className={classes.stepContent}
                                    >
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="regular_400"
                                        >
                                            {step.content}
                                        </UiText>
                                        <Divider className={classes.divider} />
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ProgressStepSummaryModal
