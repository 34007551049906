import {
    Grid,
    Typography,
    Theme,
    Button,
    Box,
    Hidden,
    makeStyles,
} from '@material-ui/core'
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import UiFormField from '../../common/ui/UiFormField'
import Loader from '../../common/Loader'
import { ApplicationStore } from '../../../models'

import {
    createReconciliation,
    updateReconciliation,
} from '../../../services/apiService'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { showAlert, showError } from '../../../store/actions/feedback'
import ModalMobileHeader from '../../common/ModalMobileHeader'
import { commonStyles } from '../../../styles/commonStyles'

interface ReconciliationFormProps {
    reconciliation: any
    handleClose: (result?: any) => void
    isNew: boolean
    appData: any
    category: any
}

function ReconciliationForm({
    reconciliation,
    handleClose,
    isNew,
    appData,
    category,
}: ReconciliationFormProps) {
    let classes = commonStyles()

    const styles = makeStyles((theme: Theme) => ({
        formActions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
        },
        fieldContainer: {
            padding: '1rem 0rem',
        },
    }))()

    const [formSubmitted, setFormSubmitted] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const validationSchema = Yup.object({
        end_date: Yup.number().required('End Date is required!'),
        ending_balance: Yup.number().required('Ending Balance required!'),
    })

    const onSubmit = (data: any, formik: any) => {
        setFormSubmitted(true)
        const localDate = moment(data.end_date*1000);
        const reconcileData: {
            account: string
            start_date: number
            start_balance: number
            end_date: number
            end_balance: number
            description: string
        } = {
            account: isNew ? reconciliation.id : reconciliation.account,
            start_date: data.start_date,
            start_balance: data.beginning_balance,
            end_date: moment.utc({
                year: localDate.year(),
                month: localDate.month(),
                date: localDate.date(),
                hour: 12,
                minute: 0,
                second: 0,
            }).unix(),
            end_balance: data.ending_balance,
            description: data.description,
        }

        if (isNew) {
            createReconciliation(
                appData.current_account_id,
                appData.current_business_id,
                reconcileData
            )
                .then((res: any) => {
                    setFormSubmitted(false)
                    handleClose(false)
                    dispatch(
                        showAlert({
                            alertText:
                                'Reconciliation data created successfully',
                            alertType: 'success',
                        })
                    )
                    history.push(
                        `/reconcile/${res.id}/reconcile?account=${res.account}`
                    )
                })
                .catch((err: any) => {
                    console.log(err)
                    dispatch(
                        showError({ errorCode: err.status, showError: true })
                    )
                    setFormSubmitted(false)
                })
        } else {
            updateReconciliation(
                appData.current_account_id,
                appData.current_business_id,
                reconciliation.id,
                reconcileData
            )
                .then((res: any) => {
                    setFormSubmitted(false)
                    handleClose(true)

                    dispatch(
                        showAlert({
                            alertText:
                                'Reconciliation data updated successfully',
                            alertType: 'success',
                        })
                    )
                    history.push(
                        `/reconcile/${res.id}/reconcile?account=${res.account}`
                    )
                })
                .catch((err: any) => {
                    console.log(err)
                    dispatch(
                        showError({ errorCode: err.status, showError: true })
                    )
                    setFormSubmitted(false)
                })
        }
    }

    const checkInitialValues = () => {
        if (isNew)
            return {
                account: reconciliation.title,
                start_date: reconciliation.next_reconciliation_start_date,
                beginning_balance:
                    reconciliation.next_reconciliation_start_balance
                        ? reconciliation.next_reconciliation_start_balance
                        : 0,
                ending_balance: 0,
                end_date: '',
            }
        else
            return {
                account: category.title,
                start_date: reconciliation.start_date
                    ? reconciliation.start_date
                    : moment.utc().hour(12).minute(0).second(0).unix(), //getMessageTime(reconciliation.start_date*1000),
                beginning_balance: reconciliation.start_balance,
                ending_balance: reconciliation.end_balance
                    ? reconciliation.end_balance
                    : 0,
                end_date: reconciliation.end_date ? reconciliation.end_date : 0,
                description: reconciliation.description
                    ? reconciliation.description
                    : null,
            }
    }

    function getUTCDate(date: any){
        let utcDate = moment(date*1000);
        return moment.utc({
            year: utcDate.year(),
            month: utcDate.month(),
            date: utcDate.date(),
            hour: 12,
            minute: 0,
            second: 0,
        }).unix();
    }

    return (
        <Formik
            initialValues={checkInitialValues()}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount={true}
            enableReinitialize={true}
            validate={(values: any) => {
                const errors: any = {};
                if (values.end_date && getUTCDate(values.end_date) <= getUTCDate(values.start_date)) {
                    errors.end_date = 'Should be greater than start date';
                }
                return errors
            }}
        >
            {(formik) => {
                return (
                    <>
                        {/* <Typography variant="h6">
                            Bank Statement Information
                        </Typography> */}
                        <Form>
                            <ModalMobileHeader
                                title={
                                    isNew
                                        ? 'Create Reconcile'
                                        : 'Edit Reconcile'
                                }
                                buttonText={'save'}
                                loading={formik.isSubmitting}
                                handleClose={handleClose}
                                handleButtonAction={() => {
                                    formik.submitForm()
                                }}
                            />
                            <Box className={styles.fieldContainer}>
                                <UiFormControlSelection
                                    type="text"
                                    placeholder=""
                                    label={'Account'}
                                    fieldName={'account'}
                                    showFloatingLabel={true}
                                    disabled={true}
                                />
                            </Box>
                            <Grid container direction="row" spacing={5}>
                                <Grid item xs={12} md={6}>
                                    <UiFormControlSelection
                                        type="date"
                                        placeholder=""
                                        label={'Start Date'}
                                        fieldName={'start_date'}
                                        disabled={true}
                                        showFloatingLabel={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <UiFormControlSelection
                                        type="text"
                                        placeholder=""
                                        label={'Beginning Balance'}
                                        fieldName={'beginning_balance'}
                                        showFloatingLabel={true}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" spacing={5}>
                                <Grid item xs={12} md={6}>
                                    <UiFormControlSelection
                                        type="date"
                                        placeholder=" "
                                        label={'End Date (Required)'}
                                        fieldName={'end_date'}
                                        showFloatingLabel={true}
                                        required={true}
                                        dateType="string"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <UiFormControlSelection
                                        type="currency"
                                        placeholder=""
                                        label={'Ending Balance (Required)'}
                                        fieldName={'ending_balance'}
                                        showFloatingLabel={true}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>

                            <UiFormField
                                type="textarea"
                                placeholder="Details on the transaction here"
                                label={'Description (Optional)'}
                                fieldName={'description'}
                            />

                            <div className={styles.formActions}>
                                <div>
                                    {formSubmitted && !formik.isValid ? (
                                        <Typography
                                            variant="caption"
                                            color="error"
                                        >
                                            Enter required info above
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <Hidden xsDown>
                                    {formSubmitted ? (
                                        <div>
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                variant="outlined"
                                                onClick={handleClose}
                                                className={classes.mr16}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={!formik.isValid}
                                                onClick={() => {
                                                    formik.validateForm()
                                                }}
                                            >
                                                {isNew
                                                    ? 'Start Now'
                                                    : 'Save Changes'}
                                            </Button>
                                        </div>
                                    )}
                                </Hidden>
                            </div>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
})
export default connect(mapStateToProps)(ReconciliationForm)
