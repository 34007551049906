import React from 'react'
import InfoCard from './InfoCard'
import UiText from '../../common/ui/UiText'
import { AppointmentDetails, Header } from './CommonComponents'
import { AccountProgress } from '../../../models/dashboardModel'

interface DiscoveryCallProps {
    primaryText: string
    secondaryText: string
    completeButtonText: string
    inCompleteButtonText?: string
    scheduleAppointment?: () => void
    accountProgress?: AccountProgress
    fetchAccountProgress?: () => void
}

const DiscoveryCall = ({
    primaryText,
    secondaryText,
    completeButtonText,
    inCompleteButtonText,
    scheduleAppointment,
    accountProgress,
    fetchAccountProgress,
}: DiscoveryCallProps) => {

    const hasDiscoveryScheduled =
        accountProgress?.scheduled_discovery_appointment &&
        accountProgress?.discovery_appointment_details?.start_date

    const meetingStartDate =
        accountProgress?.discovery_appointment_details?.start_date

    const isDiscoveryCallCompleted = accountProgress?.completed_discovery_appointment

    if(isDiscoveryCallCompleted) {
        return (<Header text='Discovery call completed'/>)
    }   
    

    return (
        <InfoCard
            customTitle={
                <UiText variant="hatchback_125" weight="medium_500">
                    {primaryText}
                </UiText>
            }
            subtitle={<UiText variant="motorcycle_90">{secondaryText}</UiText>}
            {...(hasDiscoveryScheduled
                ? {
                      rightButton: <AppointmentDetails meetingStartDate={meetingStartDate} reschedule={()=>{
                        scheduleAppointment?.()
                    }}/>,
                  }
                : { buttonText: completeButtonText })}
            buttonClick={() => {
                scheduleAppointment?.()
            }}
        />
    )
}

export default DiscoveryCall
