import { Box, Theme, makeStyles, styled } from '@material-ui/core'
import React from 'react'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { TOTAL_STEPS } from '../constants/tax-prep.const'

type ProgressTrackerProps = {
    totalStep?: number
    currentStep?: number
    progressBarColor?: string
}

const ProgressContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
})

const ProgressBar = styled(Box)({
    height: '8px',
    flexBasis: '60px',
    borderRadius: '8px',
    flexShrink: 1,
    flexGrow: 1,
})

const useStyle = (stepCompleteColor: string, colorTheme: ThemeColors) =>
    makeStyles((theme: Theme) => ({
        step: {
            background: colorTheme.black400,
        },
        currentStep: {
            border: `2px solid ${colorTheme.tertiary}`,
        },
        completedStep: {
            background: stepCompleteColor,
        },
    }))

const ProgressTracker = (props: ProgressTrackerProps) => {
    const { colorTheme } = useThemeContext()
    const {totalStep = TOTAL_STEPS,
        currentStep = 0,
        progressBarColor = colorTheme.tertiary} = props;
    const classes = useStyle(progressBarColor, colorTheme)()

    return (
        <ProgressContainer>
            {Array.from(Array(totalStep).keys()).map((step) => (
                <ProgressBar
                    key={step}
                    className={
                        currentStep > step + 1
                            ? classes.completedStep
                            : currentStep < step + 1
                            ? classes.step
                            : classes.currentStep
                    }
                ></ProgressBar>
            ))}
        </ProgressContainer>
    )
}

export default ProgressTracker
