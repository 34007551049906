// 'use strict';

import { IBankAccount } from '../models/bank-account-models';
import {
    BulkCategoryReq,
    CategoryRule,
    IMostLikelyCategory,
    ITransaction,
} from '../models/transaction-models';
import store from '../store';
import {
    deleteResource,
    getResource,
    patchResource,
    poll,
    postResource,
} from './axiosService';
import { finicity } from './bankAccountService';
import { PageService } from './pageService';

let transactionPageInstance: PageService<ITransaction> | null = null;

function _syncTransactions(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/bank_account/${
        bankId && !bankId.includes('null') ? bankId + '/sync' : 'sync_all'
    }`
    return poll(apiUrl, 'post', {})
}

function _syncPlaidTransactions(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null,
    syncDate?: string | null,
    fullSync?: boolean
) {
    let apiUrl = `/api/account/${currentAcc}/business/${businessAcc}/bank_account${
        bankId ? `/${bankId}` : ''
    }/plaid/${bankId ? 'sync' : 'sync_all'}`
    const data = syncDate
        ? { sync_from_date: syncDate }
        : fullSync
        ? { full_sync: 1 }
        : {}
    return poll(apiUrl, 'post', data)
}

function _fullSync(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/bank_account/${bankId}/sync_full`;
    return poll(apiUrl, 'post', {});
}

function _updateBalance(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/bank_account/${bankId}/match_balance`;
    return poll(apiUrl, 'post', {});
}

function _updatePlaidBalance(
    bankId: string | null
) {
    const apiUrl= `api/plaid/bank_account/${bankId}/update_balance`
    return poll(apiUrl, 'get', {});
}

function _excludeDuplicates(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/bank_account/${bankId}/exclude_duplicates`;
    return poll(apiUrl, 'post', {});
}

const _activateBank = (
    currentAcc: string,
    currentBusiness: string,
    bank: IBankAccount
) => {
    return finicity
        .refreshAccount(currentAcc, currentBusiness, bank.id)
        .then(function (data: any) {
            if (data.questions) {
                return Promise.reject(data)
            } else if (data.message) {
                return Promise.reject(data);
            }
            bank.need_activate = false;
            return Promise.resolve(data);
        }, Promise.reject.bind(Promise));
};

export function transactionPage(
    currentAcc: string,
    businessAcc: string,
    accountId = '',
    newVersion: boolean,
    excluded = false,
    onLoad?: any
): PageService<ITransaction> {
    const transactionApiUrl = `api/account/${currentAcc}/business/${businessAcc}/transaction?${
        accountId ? '&account=' + accountId : ''
    }${excluded ? '&excluded=' + excluded : ''}`;
    if (!transactionPageInstance) {
        transactionPageInstance = new PageService<ITransaction>(
            transactionApiUrl,
            onLoad,
            newVersion
        );
    }
    transactionPageInstance.updateUrl(transactionApiUrl);

    return transactionPageInstance;
}

export function getMostLikelyCategories(
    currentAcc: string,
    businessAcc: string,
    transactionData: ITransaction | null
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/transaction/most_likely_categories`;
    return postResource<Record<string, IMostLikelyCategory[]>>(apiUrl, [
        transactionData,
    ]);
}

export function applyAutoCategorization(
    currentAcc: string,
    businessAcc: string
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/mass_categorization`;
    return postResource(apiUrl,{});
}

export function getAutoCategorizationProcessStatus(
    currentAcc: string,
    businessAcc: string
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/mass_categorization_status`;
    return getResource(apiUrl);
}

export function getTransaction(
    currentAcc: string,
    businessAcc: string,
    transactionId: string
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/transaction/${transactionId}`;
    return getResource<ITransaction>(apiUrl);
}

export function assignCategory(
    currentAcc: string,
    businessAcc: string,
    body: BulkCategoryReq
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/transaction`;
    return patchResource(apiUrl, body);
}

export function deleteTransactionById(
    currentAcc: string,
    businessAcc: string,
    transactionId: string
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/transaction/${transactionId}`;
    return deleteResource(apiUrl);
}

export function categorizeTransactions(
    accountId: string,
    businessId: string,
    params: any
) {
    return patchResource(
        `api/account/${accountId}/business/${businessId}/transaction`,
        params
    );
}

export function categorizeTransaction(
    accountId: string,
    businessId: string,
    transactionId: string,
    params: any
) {
    return patchResource(
        `api/account/${accountId}/business/${businessId}/transaction/${transactionId}`,
        params
    );
}

export function syncTransactions(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null,
    bank: IBankAccount | IBankAccount[],
): Promise<any> {
    if (!bank) {
        return _syncTransactions(currentAcc, businessAcc, bankId);
    } else if (bankId) {
        if ((bank as IBankAccount).need_activate) {
            return _activateBank(
                currentAcc,
                businessAcc,
                bank as IBankAccount
            ).then(() => _syncTransactions(currentAcc, businessAcc, bankId));
        } else {
            return _syncTransactions(currentAcc, businessAcc, bankId);
        }
    } else {
        const filteredBank = (bank as IBankAccount[]).map((b) => {
            if (b.disconnected) {
                b.need_activate = false;
            }
            return b;
        });
        const inactiveBank = filteredBank.find((b) => b.need_activate === true);
        return new Promise((resolve, reject) => {
            if (inactiveBank) {
                _activateBank(currentAcc, businessAcc, inactiveBank).then(
                    () => {
                        return syncTransactions(
                            currentAcc,
                            businessAcc,
                            bankId,
                            filteredBank
                        ).then((res) => {
                            resolve(res);
                        })
                    }
                );
            } else {
                return _syncTransactions(currentAcc, businessAcc, bankId).then(
                    (res) => {
                        resolve(res);
                    }
                );
            }
        });
    }
}

export function syncPlaidTransactions(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null,
    bank: IBankAccount | IBankAccount[],
    syncFromDate?: string | null,
    fullSync?: boolean
): Promise<any> { 
        return _syncPlaidTransactions(currentAcc, businessAcc, bankId, syncFromDate, fullSync);
}

export function fullSync(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null,
): Promise<any> {
    return _fullSync(currentAcc, businessAcc, bankId);
}

export function updateBalance(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null,
): Promise<any> {
    return _updateBalance(currentAcc, businessAcc, bankId);
}
export function updatePlaidBalance(
    bankId: string | null,
): Promise<any> {
    return _updatePlaidBalance(bankId);
}

export function excludeDuplicates(
    currentAcc: string,
    businessAcc: string,
    bankId: string | null,
): Promise<any> {
    return _excludeDuplicates(currentAcc, businessAcc, bankId);
}

export function updateTransaction(
    currentAcc: string,
    businessAcc: string,
    transactionId: string,
    body: ITransaction
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/transaction/${transactionId}`;
    return patchResource(apiUrl, body);
}
export function addCategoryRule(
    currentAcc: string,
    businessAcc: string,
    body: CategoryRule
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/category_rule`;
    return postResource<CategoryRule>(apiUrl, body);
}

export function getTransactionsWIthCategoryRule(
    currentAcc: string,
    businessAcc: string,
    categoryId: string,
    query?: string
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/category_rule/${categoryId}/transactions${
        query ? '?' + query : ''
    }`;
    return getResource<{ items: ITransaction[] }>(apiUrl);
}

export function getCategoryRule(currentAcc: string, businessAcc: string) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/category_rule`;
    return getResource<Record<string, CategoryRule[]>>(apiUrl);
}

export function getCategoryRuleCount(currentAcc: string, businessAcc: string) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/category_rule/count`;
    return getResource<any>(apiUrl);
}

export function deleteCategoryRule(
    currentAcc: string,
    businessAcc: string,
    ruleId: string
) {
    const apiUrl = `api/account/${currentAcc}/business/${businessAcc}/category_rule/${ruleId}`;
    return deleteResource(apiUrl);
}

export function addTransaction(
    data: any,
    account_id: string,
    business_id: string
) {
    return postResource(
        `api/account/${account_id}/business/${business_id}/transaction`,
        data
    );
}

export function getUnCategorizedTransactions() {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    return getResource(`api/account/${accountId}/business/${businessId}/transaction?type=uncategorized`);
}
