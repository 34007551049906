import React from 'react'
import { dismissBlock, GetCurrentUserContactId } from '../AccountSetupItems/AccountSetupUtility'
import { BOOKKEEPING_AD_BLOCK } from '../TodoSection/Todo.const'
import AdvertisementCard from './AdvertisementCard'
import BookkeepingAdImage from '../../../assets/images/BookkeepingAdImage.svg'

const BookkeepingAd = ({
    reload,
}: {
    reload: () => void
}) => {
    const userContactId = GetCurrentUserContactId()
    const meetingLink = `${window.origin}/sfapi/schedule/index.php?WID=${userContactId}&EO=1&TID=BNA`
    
    return (
        <AdvertisementCard
            title="Full-Service Bookkeeping For Your Small Business"
            subtitle="Focus on your business, not your books, with 1-800Accountant’s bookkeeping service wth easy-to-understand reporting."
            buttonText="Schedule a Call to Learn More"
            cardImage={BookkeepingAdImage}
            onDismissClick={() => {
                reload()
                dismissBlock(BOOKKEEPING_AD_BLOCK)
            }}
            buttonClick={() => {
                window.open(meetingLink, '_blank');
            }}
        />
    )
}

export default BookkeepingAd
