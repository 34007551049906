import {
    Badge,
    Box,
    createStyles,
    makeStyles,
    Tab,
    Tabs,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';

const AntTabs = withStyles((theme: Theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
}))(Tabs);

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        selected: {},
    })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
    label: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    disablePadding?: boolean;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, disablePadding, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    style={{
                        paddingLeft: disablePadding ? 0 : 12,
                        paddingRight: disablePadding ? 0 : 12,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function PortalTabs(props: { children: any; disablePadding?: boolean; onTabChange?: Function, selectedValue?: number }) {
    const classes = makeStyles((theme: Theme) => ({
        root: {
            flexGrow: 1,
        },
        padding: {
            padding: theme.spacing(3),
        },
        tabsWrapper: {
            backgroundColor: theme.palette.background.paper,
        },
    }))();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        setValue(props?.selectedValue ?? 0);
    }, [props.selectedValue])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        if (props.onTabChange) {
            props.onTabChange(newValue);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.tabsWrapper}>
                {
                    Array.isArray(props.children)
                        ? (
                            <>
                                <AntTabs value={value} onChange={handleChange}>
                                    {props.children.map((c: any) => (
                                        <AntTab
                                            key={c.props.index}
                                            label={
                                                <Badge
                                                    color='primary'
                                                    variant='dot'
                                                    invisible={!c.props.badgeVisible}
                                                >
                                                    <Typography>{c.props.title}</Typography>
                                                </Badge>
                                            }
                                        />
                                    ))}
                                </AntTabs>
                                {props.children.map((c: any) => (
                                    <TabPanel
                                        key={c.props.index}
                                        value={value}
                                        index={c.props.index}
                                        disablePadding={props.disablePadding}
                                    >
                                        {c}
                                    </TabPanel>
                                ))}
                            </>
                        )
                        : <TabPanel
                            key={props.children.props.index}
                            value={value}
                            index={props.children.props.index}
                            disablePadding={props.disablePadding}
                        >
                            {props.children}
                        </TabPanel>
                }
            </div>
        </div>
    );
}

export default PortalTabs;
