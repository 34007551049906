
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { AccountProgress, InvoiceData } from '../../../models/dashboardModel';
import { hasPermission } from '../../../services/aclService';
import { getAccountProgress, getDismissedPopups, getInvoicesWidgetData } from '../../../services/apiService';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import { isBankSetupCompletedOrDismissed, 
    isMerchantSetupCompletedORDismissed } from '../AccountSetupItems/AccountSetupUtility';
import { 
    ACCOUNT_SETUP_KEYS, 
    COMPLETION_PERCENTAGE, 
    CONNECT_BANK_ACCOUNT, 
    FREE_ACCOUNT_SETUP_KEYS, 
    MERCHANT_ACCOUNT_SETUP 
} from '../TodoSection/Todo.const';

export interface DashboardProviderValue {
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    invoiceData: InvoiceData | undefined,
    accountProgress: AccountProgress | undefined,
    dismissPopupInfo: string[],
    completedStepCount: number,
    setupCompletionPercent: number,
    hasLoginFromMobile: boolean | undefined
    fetchAccountProgress: ()=> void
}


const DashboardContext =  React.createContext<DashboardProviderValue | null>(null)


export function useDashboardContext(): DashboardProviderValue {
    const state = useContext(DashboardContext)
    if (!state) {
        throw new Error('useDashboardContext must be used within DashboardProvider')
    }
    return state
}


const DashboardProvider = ({ children }: any)=> {
    const { currentAccountId, currentBusinessId, personalAccount } = useCurrentStore()
    const [loading, setLoading] = useState(false)
    const [accountProgress, setAccountProgress] = useState<AccountProgress>()
    const [dismissPopupInfo, setDismissPopupInfo] = useState<string[]>([])
    const [completedStepCount, setCompletedStepCount] = useState<number>(1)
    const [setupCompletionPercent, setSetupCompletionPercent] =
        useState<number>(0)
    const [invoiceData, setInvoiceData] = useState<InvoiceData>()

    const hasLoginFromMobile = accountProgress?.logged_in_from_mobile_app

    const getStepCompletionPercentage = useCallback(
        (progress: AccountProgress, dismissPopupInfo: string[]) => {
            let completedStepCount = 1

            const isBankStepCompleted = (key: string) =>
                key === CONNECT_BANK_ACCOUNT &&
                isBankSetupCompletedOrDismissed(progress, dismissPopupInfo)
            const isMerchantStepCompleted = (key: string) =>
                key === MERCHANT_ACCOUNT_SETUP &&
                isMerchantSetupCompletedORDismissed(progress, dismissPopupInfo)
            const AccountSetupKeys = hasPermission(
                'TAX_ADVISORY',
                personalAccount
            )
                ? ACCOUNT_SETUP_KEYS
                : FREE_ACCOUNT_SETUP_KEYS

            const totalSteps = AccountSetupKeys.length + 1
            AccountSetupKeys.forEach(
                (key: keyof AccountProgress, index: number) => {
                    const isStepCompleted = progress[key]
                    if (
                        isStepCompleted ||
                        isBankStepCompleted(key) ||
                        isMerchantStepCompleted(key)
                    ) {
                        completedStepCount += 1
                    }
                }
            )
            setCompletedStepCount(completedStepCount)
            const percent = Math.round(
                (completedStepCount / totalSteps) * COMPLETION_PERCENTAGE
            )
            setSetupCompletionPercent(percent)
        },
        [personalAccount]
    )

    const fetchInvoiceData = useCallback(() => {
        getInvoicesWidgetData(currentAccountId, currentBusinessId as string)
            .then((res: InvoiceData) => {
                setInvoiceData(res)
            })
            .catch((error) => {
                setInvoiceData(undefined)
                setLoading(false)
            })
    }, [currentAccountId, currentBusinessId])

    const fetchAccountProgress = useCallback(() => {
        setLoading(true)
        Promise.all([
            getDismissedPopups(currentAccountId),
            getAccountProgress({
                business_id: currentBusinessId,
            }),
        ])
            .then((res: any) => {
                const [dismissedPopupDetails, accountProgress] = res
                setLoading(false)
                setAccountProgress(accountProgress)
                setDismissPopupInfo(dismissedPopupDetails?.pop_ups)
                getStepCompletionPercentage(
                    accountProgress,
                    dismissedPopupDetails?.pop_ups
                )
            })
            .catch((error) => {
                setLoading(false)
            })
    }, [currentAccountId, currentBusinessId, getStepCompletionPercentage])

    useEffect(() => {
        fetchAccountProgress()
        fetchInvoiceData()
    }, [fetchAccountProgress, fetchInvoiceData])

    const providerValue: DashboardProviderValue = {
        loading,
        setLoading,
        invoiceData,
        accountProgress,
        dismissPopupInfo,
        completedStepCount,
        setupCompletionPercent,
        hasLoginFromMobile,
        fetchAccountProgress
    }

    return (
        <DashboardContext.Provider value={providerValue}>
            {children}
        </DashboardContext.Provider>
    )
}

export default DashboardProvider
