import React from 'react'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import ConnectBank from '../../../assets/images/ConnectBank.svg'
import { ReactComponent as Lock } from '../../../assets/icons-svg/Lock.svg'
import InfoCard from './InfoCard'
import { useHistory } from 'react-router-dom'
import { TODO_CONNECT_BANK } from '../TodoSection/Todo.const'
import { updateDismissPopup } from '../../../services/apiService'
import { showError } from '../../../services/formService'
import UiText from '../../common/ui/UiText'

interface ConnectBankProps {
    primaryText: string
    secondaryText: string
    completeButtonText: string
    inCompleteButtonText?: string
    fetchAccountProgress: () => void
    borderView?: boolean
    dismissBlock?: () => void
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const FinicityConnect = ({
    primaryText,
    secondaryText,
    completeButtonText,
    inCompleteButtonText,
    fetchAccountProgress,
    borderView=false,
    dismissBlock,
    setLoading
}: ConnectBankProps) => {
    const history = useHistory()
    const { currentAccountId } = useCurrentStore()

    const dismissConnectBankAccount = () => {
        setLoading?.(true)
        let data = {
            pop_up: TODO_CONNECT_BANK
        }
        updateDismissPopup(currentAccountId as string, data)
            .then(() => {
                fetchAccountProgress()
            })
            .catch((error) => {
                showError(error)
            })
    }

    return (
        <InfoCard
            customTitle={
                <UiText variant="hatchback_125" weight="medium_500">
                    {primaryText}
                </UiText>
            }
            subtitle={
                <UiText variant="motorcycle_90">{secondaryText}</UiText>
            }
            buttonText={completeButtonText}
            buttonClick={() => {
                history.push('/bank_accounts')
            }}
            buttonIcon={<Lock />}
            cardImage={ConnectBank}
            onDismissClick={()=>{
                dismissBlock ? dismissBlock?.() : dismissConnectBankAccount()
            }}
            borderView={borderView}
        />
    )
}

export default FinicityConnect
