import { Fragment, useState, useRef, useMemo } from 'react';
import {
    Box,
    Grow,
    Theme,
    Paper,
    Popper,
    Button,
    Divider,
    MenuItem,
    MenuList,
    makeStyles,
    ButtonGroup,
    ClickAwayListener,
} from '@material-ui/core';
import clsx from 'clsx';

//icons
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import ExcludeDark from '../../../assets/icons-svg/ExcludeDark.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        deleteOption: {
            color: (colorTheme) => `${colorTheme.red200} !important`,
        },
        menuItemFont: {
            fontSize: '15px',
            fontWeight: 400,
        },
    };
});

const SnackbarActions = ({
    itemCount,
    handleClickType,
    isManualAccount,
}: {
    itemCount?: number;
    handleClickType: (action: string) => void;
    isManualAccount?: boolean;
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme);
    const [openPopup, setOpenPopup] = useState(false);
    const popoverRef = useRef<any>(null);

    const bulkActionOptions: any = useMemo(() => {
        return [
            {
                title: 'Categorize',
                icon: <LabelOutlinedIcon />,
                showDivider: false,
                action: 'categorize',
            },
            {
                title: `Exclude`,
                iconType: 'image',
                iconAlt: 'exclude',
                icon: ExcludeDark,
                showDivider: false,
                style: { paddingBottom: '2px' },
                action: 'exclude',
            },
        ];
    }, []);

    const handleClose = (event: any) => {
        if (popoverRef.current && popoverRef.current?.contains(event.target)) {
            return;
        }
        setOpenPopup(false);
    };
    return (
        <Box mx={2}>
            <ButtonGroup
                variant='contained'
                size='small'
                color='primary'
                aria-label='split button'
                ref={popoverRef}
            >
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<LabelOutlinedIcon />}
                    onClick={() => {
                        handleClickType('categorize');
                    }}
                >
                    Categorize
                </Button>
                {!isManualAccount && (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            setOpenPopup(!openPopup);
                        }}
                    >
                        {openPopup ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </Button>
                )}
            </ButtonGroup>
            <Popper
                open={openPopup}
                anchorEl={popoverRef.current}
                role={undefined}
                transition
                disablePortal
                placement={'top-end'}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'end top'
                                    : 'end bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id='split-button-menu'>
                                    {bulkActionOptions.map(
                                        (option: any, index: number) => (
                                            <Fragment key={index}>
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        handleClickType(
                                                            option.action
                                                        );
                                                    }}
                                                    className={
                                                        option?.deleteOption
                                                            ? clsx(
                                                                  styles.deleteOption,
                                                                  styles.menuItemFont
                                                              )
                                                            : clsx(
                                                                  styles.menuItemFont
                                                              )
                                                    }
                                                >
                                                    {option.iconType ===
                                                    'image' ? (
                                                        <img
                                                            alt={
                                                                option?.iconAlt
                                                            }
                                                            src={option.icon}
                                                        />
                                                    ) : (
                                                        option.icon
                                                    )}{' '}
                                                    <Box
                                                        mx={2}
                                                        style={option?.style}
                                                    >
                                                        {option.title}{' '}
                                                    </Box>
                                                </MenuItem>
                                                {option.showDivider && (
                                                    <Divider />
                                                )}
                                            </Fragment>
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default SnackbarActions;
