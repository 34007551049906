import React, { useContext, useEffect, Fragment, useState } from 'react'
import {
    Container,
    Theme,
    makeStyles,
    ListItem,
    Button,
    ListItemIcon,
    ListItemText,
    Grid,
    Paper,
    List,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider'
import { UpgradePortalAccessPlan, UpgradePortalAccessItemPlan } from '../../models'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import Chip from '@material-ui/core/Chip'
import { toRem16 } from '../../styles/commonStyles'
import CheckIcon from '@material-ui/icons/Check'
import { useCurrentStore } from '../common/hooks/useCurrentStore'
import UiText from '../common/ui/UiText'
import ChargeoverIframeModal from './ChargeoverIframeModal'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { getProductBySfId } from '../../services/apiService'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../store/actions/feedback'
import Loader from '../common/Loader'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        textAlign: 'center',
        margin: `${toRem16(15)}`,
    },
    planDescription: {
        textAlign: 'center',
        margin: `${toRem16(15)}`,
    },
    price: {
        display: 'inline-block'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(8),
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        position: 'relative',
    },
    actionButton: {
        maxWidth: `${toRem16(250)}`,
        minWidth: `${toRem16(250)}`,
        margin: `${toRem16(15)}`,
    },
    list: {
        backgroundColor: theme.palette.background.default,
        margin: `${toRem16(1)}`,
    },
    chip: {
        top: '-1%',
        position: 'absolute',
        left: '36%',
        backgroundColor: (colorTheme) => colorTheme.mint100,
        borderRadius: 5,
    },
    hideChip: {
        display: 'none',
    },
    checkDisabled: {
        marginRight: '.5rem',
    },
    priceMarginFreeV: {
        display: 'inline-block',
        margin: '0rem 0rem 1.6rem 0rem'
    }
}))

export enum PLAN_LEVEL_NAMES  {
    FREE = 'free',
    PLATFORM = 'platform',
    ADVISORy = 'advisory'
}

const plan_level: Array<PlanLevel> = [
    { name: 'free', level: 1 },
    { name: 'platform', level: 2 },
    { name: 'advisory', level: 3 },
]
interface PlanLevel {
    name?: string
    level?: number
}

enum BUTTON {
    ENABLED = 'Upgrade Now',
    CURRENT = 'Your Current Plan',
    DISABLED = 'Included in Current Plan',
}

interface priceBook {
    sf_product_id: string
    price: string
}

const utmContentConst = '+Product';

function UpgradePortalAccess() {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const { currentAccount, currentConfig } = useCurrentStore()
    const plans: Array<UpgradePortalAccessPlan> =
        currentConfig.local.portal_access_plan

    const currentPlan: string = currentAccount.client_tier_permission
    const [openChargeoverModal, setOpenChargeoverModal] = useState(false);
    const [utmContent, setUtmContent] = useState('');
    const [productId, setProductId] = useState('');
    const [priceProducts, setPriceProducts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveRouteHeading('Upgrade Portal Access')
        let sfProductIds = [];
        for (const element of plans) {
            if(element.productLink){
                sfProductIds.push(element.productLink);
            }
        }

        getProductBySfId({'product_ids' : sfProductIds}).then((res: any) => {
            setPriceProducts(res);
         })
         .catch((error) => {
            dispatch(
                showAlert({
                    alertType: 'error',
                    alertText: error || 'Something went wrong',
                })
            );
         });
    }, [])
    const getPlanLevel = (name: string) => {
        const planLevel: PlanLevel = plan_level.find(
            (element) => element?.name === name
        )!
        return planLevel?.level as number
    }

    const isDisabledPlan = (planName: string) => {
        return (
            currentPlan === planName ||
            getPlanLevel(planName) < getPlanLevel(currentPlan)
        )
    }

    const getPlanButtonText = (planName: string) => {
        return currentPlan === planName
            ? BUTTON.CURRENT
            : getPlanLevel(planName) < getPlanLevel(currentPlan)
            ? BUTTON.DISABLED
            : BUTTON.ENABLED
    }

    const handleClose = (message?: string) => {
        setOpenChargeoverModal(false);
    }

    const actionButton = (disabled: boolean, product: string, text: string, plan: string) => {
        if (disabled) {
            return (
                <Button
                    variant={'outlined'}
                    color="primary"
                    size="medium"
                    className={classes.actionButton}
                    disabled={true}
                >
                    <CheckIcon
                        color="disabled"
                        className={classes.checkDisabled}
                    />
                    {text}
                </Button>
            )
        } else {
            return (
                <Button
                    variant={'contained'}
                    color="primary"
                    size="medium"
                    className={classes.actionButton}
                    disabled={false}
                    onClick={() => {
                        setUtmContent(plan+utmContentConst);
                        setProductId(product);
                        setOpenChargeoverModal(true);
                    }}
                >
                    {text}
                </Button>
            )
        }
    }

    const getPriceProductBySfId = (sf_product_id: string) => {
        const price: any = priceProducts.find((element:priceBook) => element.sf_product_id === sf_product_id);
        if(price !== undefined){
            return '$'+price.price!;
        }else{
            return '$0.00'
        }
    }

    return (
        <Container>
            <ChargeoverIframeModal
                open={openChargeoverModal}
                productIds={[]}
                handleClose={handleClose}
                utmContent={utmContent}
                sfProductIds={[productId]}
            />
            <div className={classes.root}>
                <Grid container spacing={5}>
                    {plans.map(
                        (element: UpgradePortalAccessPlan, index: number) => (
                            <Grid item xs key={index}>
                                <Paper className={classes.paper} elevation={3}>
                                    <Chip
                                        size="small"
                                        label={element.chip}
                                        className={
                                            element.chip
                                                ? classes.chip
                                                : classes.hideChip
                                        }
                                    />
                                    <UiText variant='van_225' className={classes.title}>
                                      {element.title}
                                    </UiText>
                                    <UiText variant='motorcycle_90' className={classes.planDescription}>
                                      {element.description}
                                    </UiText>
                                    {
                                        priceProducts.length > 0 ?
                                        <>
                                            <UiText
                                            variant='van_225' 
                                            className={classes.price}
                                            weight='extra_bold_800'
                                            >
                                            {element.productLink ? getPriceProductBySfId(element.productLink) :  '$0'}
                                            </UiText>
                                            <UiText
                                                variant='hatchback_125' 
                                                className={
                                                    !element.billedAnually 
                                                        ? classes.priceMarginFreeV
                                                        : classes.price
                                                }
                                                weight='extra_bold_800'
                                            >
                                                /mo
                                            </UiText>
                                        </>
                                        : 
                                        <Loader />
                                    }
                                    {
                                        element.billedAnually && 
                                            <UiText variant='moped_75'>
                                                Billed Annually*
                                            </UiText>
                                    }
                                    {actionButton(
                                        isDisabledPlan(element.plan),
                                        element.productLink ?? '',
                                        getPlanButtonText(element.plan),
                                        element.plan
                                    )}
                                    <div className={classes.list}>
                                        <List dense={true}>
                                            {element.items.map(
                                                (
                                                    item: UpgradePortalAccessItemPlan,
                                                    index: number
                                                ) => (
                                                    <Fragment key={index}>
                                                        {item.active ? (
                                                            <ListItem
                                                                key={index}
                                                            >
                                                                <ListItemIcon>
                                                                    <CheckCircleIcon color="primary" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        item.text
                                                                    }
                                                                />
                                                            </ListItem>
                                                        ) : (
                                                            <ListItem
                                                                key={index}
                                                            >
                                                                <ListItemIcon>
                                                                    <CancelOutlinedIcon color="disabled" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    secondary={
                                                                        item.text
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )}
                                                    </Fragment>
                                                )
                                            )}
                                        </List>
                                    </div>
                                </Paper>
                            </Grid>
                        )
                    )}
                </Grid>
            </div>
        </Container>
    )
}

export default UpgradePortalAccess