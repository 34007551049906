import { Folder, FolderTree } from '../models';

const getParent = function (folder: Folder, root: FolderTree) {
    const parentPath = folder.path.split('/');
    parentPath.pop(); // Removing current folder path so remaining will be its parent's path

    let parent = root;

    for (let i = 1; i < parentPath.length; i++) {
        const path = parentPath.slice(0, i + 1).join('/');
        parent = (
            parent.folders as {
                [key: string]: Folder & FolderTree;
            }
        )[path];
    }

    return parent;
};

export const buildTree = function (
    folders: Folder[],
    accountId: string,
    type: 'business' | 'personal'
) {
    const foldersForAccount = folders.filter(
        (folder) => folder.portal_account_id === accountId
    );
    const root: FolderTree = {
        folders: {},
        type,
    };

    let sortedFolders = foldersForAccount
        .sort((folder1, folder2) => folder1.level - folder2.level)

        sortedFolders.forEach((folder) => {
            const parent = folder.level > 0 ? getParent(folder, root) : root;
            if (!parent) {
                return;
            }

            if (!parent.folders) {
                parent.folders = {};
            }

            folder.pathToDisplay = parent.pathToDisplay
                ? `${parent.pathToDisplay} / ${folder.title}`
                : `${type === 'business' ? 'Business' : 'Private'} / ${
                      folder.title
                  }`;
            parent.folders[folder.path as keyof typeof parent.folders] = {
                ...folder,
                type,
            };     
            root[folder.path] =  parent?.folders[folder.path]   
        });        
    return root;
};
