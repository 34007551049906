import { Employee } from '../../../../models/adp-payroll/employee'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import SectionView from '../../../payroll/common/SectionView'
import { commonStyles } from '../../../../styles/commonStyles'
import { Typography } from '@material-ui/core'
import EmptySectionView from '../../../payroll/common/EmptySectionView'

const OwnershipDetails = ({
    selectedEmployee,
}: {
    selectedEmployee?: Employee | undefined
}) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = commonStyles()

    return (
        <div>
            {selectedEmployee?.ownershipDetails ? (
                <SectionView
                    key={'ownership-details'}
                    headerText={'Ownership Details'}
                    sectionInfoData={[
                        {
                            label: 'Is primary owner',
                            value: (
                                <>
                                    {selectedEmployee?.ownershipDetails
                                        ?.primaryOwnerIndicator === true
                                        ? 'Yes'
                                        : 'No'}{' '}
                                    <br />
                                </>
                            ),
                        },
                        {
                            label: 'Ownership percentage',
                            value: (
                                <>
                                    {(selectedEmployee?.ownershipDetails
                                        ?.ownershipPercentage as number ?? '0')*100 }{' '}
                                    <br />
                                </>
                            ),
                        },
                    ]}
                />
            ) : (
                <EmptySectionView
                    headerText={'Ownership Details'}
                    primaryText={'No Ownership Details Found'}
                />
            )}
        </div>
    )
}

export default OwnershipDetails
