import { makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { MERCHANT_TYPE_PAYRIX } from './MerchantAccount/merchantFormUtils'

const useStyles = makeStyles(() => ({
    alertStyles: {
        margin: '10px 0px 10px 0px',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    alertRootStyle: {
        borderRadius: '8px',
    },
}));

const MerchantHelpBanner = (props: { merchantType: string | null }) => {
    const styles = useStyles();
    const isPayrixMerchant = props.merchantType === MERCHANT_TYPE_PAYRIX;
    return (
        <div className={styles.alertStyles}>
            <Alert
                classes={{ root: styles.alertRootStyle }}
                icon={false}
                severity="warning"
            >
                <Typography>
                    {isPayrixMerchant
                        ? 'If you have any questions or issues regarding your merchant account or the processing of a payment, please contact Payrix at 1-844-577-5570 or invoicing@1800accountant.com'
                        : 'If you have any questions or issues regarding your merchant account or the processing of a payment, please contact Newtek at 1-800-277-6980 or service@newtekone.com'}
                </Typography>
            </Alert>
        </div>
    )
}

export default MerchantHelpBanner
