import { createRef, useCallback, useEffect, useState, useRef } from 'react';
import {
    createStyles,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Theme,
    Typography,
} from '@material-ui/core';
import bankIcon from '../../../assets/icons-svg/BankGeneric.svg';
import connectedBankIcon from '../../../assets/icons-svg/BankGenericConnected.svg';
import { UnfoldMoreRounded } from '@material-ui/icons';
import { IBankAccount } from '../../../models/bank-account-models';
import moment from 'moment';
import { currencyFormatter } from '../../../utils/appUtil';
import { commonStyles } from '../../../styles/commonStyles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { base64ToImage, isUrl } from '../../../utils/imageUtil';

interface AccountSelectorProps {
    excluded?: boolean;
    accountList?: IBankAccount[];
    onChange?: (account?: IBankAccount) => void;
    disabled?: boolean;
    setSelectedAccount?: any;
    currentAccountId?: any;
    updateCurrentAccount?: boolean;
}



export default function AccountSelector({
    excluded = false,
    accountList,
    onChange,
    disabled = false,
    setSelectedAccount,
    currentAccountId,
    updateCurrentAccount = true,
}: AccountSelectorProps) {
    const [selectedAcc, setSelectedAcc] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    let selectRef: any = createRef()
    const { colorTheme } = useThemeContext()
    const [selectWidth, setSelectWidth] = useState<number | null>()
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accountIconContainer: {
            paddingTop: theme.spacing(0),
            marginRight: theme.spacing(2),
            color: colorTheme.grey1000,

            '& > svg': {
                fontSize: '44px',
            },
        },
        blueTintBg: {
            background: colorTheme.grey2100,
            '&.MuiInputBase-root.Mui-disabled': {
                color: colorTheme.black500,
            },
        },
        bankLogo: {
            height: '40px',
            width: '40px',
            borderRadius: '50%',
        },
        accountName: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '2px'
        },
        accountInfo: {
            maxWidth: 'calc(100% - 100px)'
        },
        selectMenuWidth: {
            [theme.breakpoints.up('xs')]: {
               maxWidth: selectWidth
            }
            
        }
    })
);
    const classes = useStyles();
    const styles = commonStyles()

    const getAccountList = () => {
        return accountList?.map((acc) => {
            if (
                !excluded ||
                (excluded &&
                    (acc.type === 'aggregated' ||
                        acc.type === 'aggregated_group'))
            ) {
                return (
                    <MenuItem value={acc.id} key={acc.id}>
                        <Grid container>
                            <div className={classes.accountIconContainer}>
                                <img
                                    className={classes.bankLogo}
                                    src={
                                        acc?.logo
                                            ? isUrl(acc?.logo)
                                                ? acc?.logo
                                                : base64ToImage(acc?.logo)
                                            : acc?.connected
                                            ? connectedBankIcon
                                            : bankIcon
                                    }
                                    alt='bank logo'
                                ></img>
                            </div>
                            <div className={classes.accountInfo}>
                                <div className={styles.flex}>
                                    <div className={classes.accountName}>
                                        {acc.name}
                                    </div>
                                    <div>
                                        {isOpen ? (
                                            <Typography
                                                display='inline'
                                                variant='caption'
                                            >
                                                {acc.disconnected
                                                    ? ' - Disconnected'
                                                    : ''}
                                                {!acc.disconnected &&
                                                (acc.not_synced || acc.need_activate)
                                                    ? ' - Not synced'
                                                    : ''}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                display='inline'
                                                variant='caption'
                                            >
                                                {(acc.type === 'intuit' ||acc.type ==='finicity') && !(acc.disconnected ||acc.not_synced || acc.need_activate) ? ` - on ${moment(
                                                          (acc?.synced as number) *
                                                              1000
                                                      ).format('DD MMM YYYY')}` : ''}
                                                {acc.disconnected
                                                    ? ` - Disconnected on ${moment(
                                                          (acc?.disconnected as number) *
                                                              1000
                                                      ).format('DD MMM YYYY')}`
                                                    : ''}
                                                {!acc.disconnected && acc.not_synced
                                                    && !acc.need_activate
                                                    ? ` - Not synced since ${moment(
                                                          (acc?.synced as number) *
                                                              1000
                                                      ).format('DD MMM YYYY')}`
                                                    : ''}
                                                { !acc.disconnected && acc.need_activate ? ' - Not synced' : ''}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <Typography variant='body2'>
                                    {acc.balance ? `${currencyFormatter.format(parseFloat(acc.balance))}` : ''}
                                </Typography>
                            </div>
                        </Grid>
                    </MenuItem>
                );
            }
            return null;
        });
    };

    const accountChange = useCallback(
        (accId: any) => {
            let currentAccount = accountList?.find((acc) => acc.id === accId);
            if (!selectedAcc || disabled) {
                currentAccount = accountList?.find(
                    (acc) => acc.id === accId
                );

                let filteredAccounts = accountList?.filter((acc) => acc.account === accId) || [];
                if(filteredAccounts.length === 2){
                    currentAccount = filteredAccounts[1];
                }
            }
            if (currentAccount) {
                setSelectedAcc(currentAccount?.id);
                updateCurrentAccount && setSelectedAccount?.(currentAccount);
                onChange?.(currentAccount);
            }
        },
        [accountList, disabled, onChange, selectedAcc, setSelectedAccount, updateCurrentAccount]
    );

    useEffect(() => {
        let currentAccount, accId
        if(currentAccountId) {
            currentAccount = accountList?.find((acc) => acc.id === currentAccountId) || 
            accountList?.find((acc) => acc.account === currentAccountId);
            accId = currentAccount?.id
        } else {
            accId = accountList?.[0]?.id  
        }
    
        accountChange(accId); 
    },[accountChange, accountList, disabled, currentAccountId])

    useEffect(() => {
        let {left, right } = selectRef.current.getBoundingClientRect()
        setSelectWidth(right - left)
    },[selectRef])


    return (
        <FormControl fullWidth variant='outlined' ref={selectRef} style={{ minHeight: '85px'}}>
            <InputLabel>Account</InputLabel>
            <Select
                label='Account'
                IconComponent={!disabled ? UnfoldMoreRounded : () => null}
                className={disabled ? classes.blueTintBg : ''}
                onChange={(e: any) => {
                    accountChange(e.target.value);
                }}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={selectedAcc}
                disabled={disabled}
                MenuProps={{
                    style: {
                        maxHeight: '600px'
                    },
                    classes: {list: classes.selectMenuWidth},
                    keepMounted: true,
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
            >
                {getAccountList()}
            </Select>
        </FormControl>
    );
}
