import { Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons'
import { connect } from 'react-redux';
import { ApplicationStore } from '../../../models';
import UiSection from '../../common/ui/UiSection'

function SpecialInstructions({ specialInstruction }: {
    specialInstruction: {
        id: string;
        title: string;
        body: string;
        status: string;
        created_at: number;
        created_by: string;
        updated_by: string;
    }[];
}) {
    return specialInstruction.length
        ? (
            <UiSection backgroundColor="#FAF56C" expanded={true} sectionHeading={(
                <div className="d-flex align-items-center">
                    <InfoOutlined style={{ marginRight: 10 }} /> Special Instructions
                </div>
            )}>
                {
                    specialInstruction.map((sp, index) => <Typography
                        gutterBottom
                        key={index}
                    >
                        {index + 1}. {sp.body}
                    </Typography>)
                }
            </UiSection>
        )
        : null
}

const mapStateToProps = ({ entityManagement }: ApplicationStore) => ({
    specialInstruction: entityManagement.entityTab.specialInstruction
});
export default connect(mapStateToProps)(SpecialInstructions);