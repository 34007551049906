import * as Yup from "yup";
import {
    ownershipPaymentError,
    payCheckUnitError,
} from "./CompensationFormUtils";

export const personalValidation = (ssnValidation = true) =>
    Yup.object({
        first_name: Yup.string().required("First name is required"),
        middle_initial: Yup.string().max(
            1,
            "Middle initial must be a single alphabet character"
        ),
        last_name: Yup.string().required("Last Name is required"),
        email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        date_of_birth: Yup.string().required("Date of Birth is required"),
        ...(ssnValidation
            ? {
                  ssn: Yup.string().required("SSN is required"),
              }
            : {}),
    });

export const ownershipValidation = Yup.object({
    two_percent_shareholder: Yup.string().required(
        "Two Percent Shareholder is required"
    ),
});

export const jobFormValidation = Yup.object({
    title: Yup.string().required("Title is required"),
    hire_date: Yup.string().required("Hire Date is required"),
    location_id: Yup.string().required("Location is required"),
});

export const compensationValidation = Yup.object({
    rate: Yup.number()
        .moreThan(0, "Payment Rate should be greater than zero")
        .required("Payment rate is required"),
    payment_unit: Yup.string().required("Payment Frequency is required"),
    flsa_status: Yup.string().required(
        "FLSA status for this compensation is required"
    ),
}).test("paymentUnitError", (value, { createError }) => {
    let { flsa_status, payment_unit } = value;
    let payCheckUnit = payment_unit === "Paycheck";
    return flsa_status === "Owner"
        ? payCheckUnit
            ? true
            : createError(ownershipPaymentError)
        : payCheckUnit
        ? createError(payCheckUnitError)
        : true;
});

export const formattedParams = (
    data: any,
    currentPage: string,
    addSsn = false
) => {
    const formData: any = {
        PersonalDetails: {
            first_name: data?.first_name,
            middle_initial: data?.middle_initial,
            last_name: data?.last_name,
            email: data?.email,
            date_of_birth: data?.date_of_birth,
            ...(addSsn
                ? {
                      ssn: data?.ssn,
                  }
                : {}),
        },
        OwnershipDetails: {
            two_percent_shareholder: data?.two_percent_shareholder === "yes",
        },
        JobDetails: {
            title: data?.title,
            hire_date: data?.hire_date,
            location_id: data?.location_id,
        },
        CompensationDetails: {
            rate: data?.rate,
            payment_unit: data?.payment_unit,
            flsa_status: data?.flsa_status,
        },
    };
    return formData[currentPage];
};
