import { combineReducers } from 'redux';
import loading from './loading';
import appData from './appData';
import auth from './auth';
import config from './config';
import entityManagement from './entityManagement';
import feedback from './feedback';
import count from './count';
import appointmentEvents from './appointmentEvents';
import category from './categories';
import featureConfig from './featureConfig';
import { ApplicationStore } from '../../models';
import product from './products';
import company from './company';
import reportsData from './reportsData';
import bankAccounts from './bankAccounts';
export default combineReducers<ApplicationStore>({
    loading,
    appData,
    auth,
    config,
    entityManagement,
    feedback,
    count,
    appointmentEvents,
    category,
    featureConfig,
    product,
    company,
    reportsData,
    bankAccounts
});
