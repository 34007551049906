import { Button, Theme, makeStyles } from '@material-ui/core'
import InfoI from '../../assets/icons-svg/info-blue.svg'
import { Container, InlineFlex } from './Wrapper'
import { toRem16 } from '../../styles/commonStyles'
import { COLORS } from '../../variables/colors'
import { showYears } from './utills/contractor.utils'
import UiText from '../common/ui/UiText'

interface Props {
    message?: string
    showButton?: boolean
    buttonLabel?: string
    maxWith?: number
    handleDismiss?: () => void
    buttonPosition?: 'inline' | 'block'
    backgroundColor?: string
    style?: React.CSSProperties
}

export const InformationBlock = (props: Props) => {
    const {
        message,
        showButton,
        buttonLabel,
        maxWith,
        handleDismiss,
        buttonPosition,
        backgroundColor,
        style
    } = props
    const useStyles = makeStyles((theme: Theme) => {
        return {
            dismiss: {
                color: COLORS.black100,
                textDecoration: 'none',
                margin:
                    buttonPosition === 'inline'
                        ? 'none'
                        : `${toRem16(8)} 0 0 ${toRem16(48)}`,
            },
            inlineButton: {
                display: buttonPosition === 'inline' ? 'flex' : 'block',
            },
        }
    })
    const classes = useStyles()
    const year = showYears()

    return (
        <Container
            padding={toRem16(16)}
            backgroundcolor={backgroundColor || COLORS.blue300}
            borderradius={toRem16(4)}
            margin="0 0 2rem 0"
            maxwidth={toRem16(maxWith || 768)}
            minheight="auto"
        >
            <div className={classes.inlineButton}>
                <InlineFlex gap={toRem16(13)}>
                    <div>
                        <img src={InfoI} alt="circular check icon" />
                    </div>
                    <UiText style={style} variant="motorcycle_90">
                        {message ||
                            `If you paid any contractor over $600 in the calendar year, you must file a 1099-NEC by January 31, ${
                                year
                            }. Failure to file may result in penalties.`}
                    </UiText>
                </InlineFlex>
                {showButton && (
                    <div>
                        <Button
                            variant="text"
                            className={classes.dismiss}
                            onClick={handleDismiss}
                        >
                            <UiText
                                variant="motorcycle_90"
                                weight="semi_bold_600"
                            >
                                {buttonLabel || 'Okay, Got it'}
                            </UiText>
                        </Button>
                    </div>
                )}
            </div>
        </Container>
    )
}
