import { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function MenuDropdown(props: {
    options: any[];
    selectedIndex: any;
    setSelectedIndex: any;
    disabled?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event: any, index: number) => {
        props.setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                style={!props.variant ? { height: 'auto', padding: 1 } : {}}
                onClick={handleClick}
                color='secondary'
                variant={props.variant}
                disabled={props.disabled}
            >
                {typeof props.options[props.selectedIndex] === 'string' ? 
                    props.options[props.selectedIndex] : 
                    props.options[props.selectedIndex]?.label
                }
                {!!anchorEl ? (
                    <KeyboardArrowUpIcon />
                ) : (
                    <KeyboardArrowDownIcon />
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            >
                {props.options.map((option, index) => (
                    <MenuItem
                        key={option?.label || option}
                        selected={index === props.selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        disabled={option?.disabled}
                    >
                        {option?.label || option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default MenuDropdown;
