import React from 'react'
import {
    makeStyles,
    Theme,
    Box
} from '@material-ui/core'
import NoMessage from '../../assets/images/NoMessage.svg'
import UiText from '../common/ui/UiText';
import UpgradePlanButton from '../common/UpgradePlanButton'

const useClass = makeStyles((theme: Theme) => ({
    messageEmptyBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    descriptionEmptyMessages: {
        padding: theme.spacing(3, 0, 3),
    }
}))

const UpgradePlanMessageCenter = () => {
    const classes = useClass()
    
    return (
        <Box
            className={classes.messageEmptyBox}
        >
            <img
                src={NoMessage}
                alt='NoMessage'
            />
            <UiText variant='truck_175' className={classes.descriptionEmptyMessages}>
                Message your Accountant Team
            </UiText>
            <UiText className={classes.descriptionEmptyMessages}>
                Get answers to your questions directly from the members of your accountant team.
            </UiText>
                <UpgradePlanButton/>
        </Box>
    )
}

export default UpgradePlanMessageCenter;