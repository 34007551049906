import { SvgIcon } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import moment from 'moment'
import * as Yup from 'yup'
import UiText from '../../common/ui/UiText'

const ssnInfo = (
    <UiText variant="motorcycle_90" textColor="textSecondary">
        {' '}
        For security purposes your SSN has been hidden. You can update it by
        typing in the field.
    </UiText>
)

export const personalInfoFormMapping: any = (isEdit: boolean) => [
    {
        key: 'first_name',
        type: 'text',
        placeholder: 'First Name (Required)',
        md: 10,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        showFloatingLabel: true,
    },
    {
        key: 'middle_initial',
        type: 'text',
        placeholder: 'Middle Initial (Optional)',
        md: 10,
        xs: 12,
        showFloatingLabel: true,
    },
    {
        key: 'last_name',
        type: 'text',
        placeholder: 'Last Name (Required)',
        md: 10,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        showFloatingLabel: true,
    },
    {
        key: 'email',
        type: 'text',
        icon: 'Email',
        placeholder: 'Email (Required)',
        md: 8,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        label: 'Email (Required)',
        showFloatingLabel: true,
    },
    {
        md: 8,
        xs: 12,
        key: 'dob',
        label: 'Date of Birth (Required)',
        required: true,
        type: 'KeyboardDatePicker',
        fieldName: 'date_of_birth',
        dateType: 'string',
        disableFutureDate: true,
        showFloatingLabel: true,
        errorMessage: 'Required',
        endIcon: <SvgIcon component={TodayIcon} color="action" />,
        placeholder: 'MM/DD/YYYY',
    },
    {
        md: 8,
        xs: 12,
        label: `${
            isEdit
                ? 'Social Security Number'
                : 'Social Security Number (Required)'
        }`,
        type: 'mask',
        mask: [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        placeholder: 'Hidden',
        fieldSize: 6,
        minLength: 9,
        key: 'ssn',
        infoText: ssnInfo,
    },
]

export const frequencyOptions = [
    { code: 'Hourly', shortName: 'Hourly' },
    { code: 'PerPay', shortName: 'Per Pay Period' },
    { code: 'PerPayOvertime', shortName: 'Per Pay Period with OT' },
    { code: 'SalaryNoOvertime', shortName: 'Annual salary' },
    {
        code: 'SalaryOvertimeEligible',
        shortName: 'Annual salary with OT',
    },
    { code: 'FlatAmount', shortName: 'Flat Amount' },
]

export const empCompensationFormMappings = (values: any) => {
    return [
        {
            md: 10,
            xs: 12,
            key: 'amount',
            label: 'Amount (Required)',
            type: 'currency',
            showFloatingLabel: true,
            required: true,
        },
        {
            key: 'frequency',
            type: 'select',
            placeholder: 'Frequency (Required)',
            label: 'Frequency (Required)',
            md: 10,
            xs: 12,
            required: true,
            data: frequencyOptions,
            showFloatingLabel: true,
            optionKey: 'shortName',
            optionValue: 'code',
        },
    ]
}

export const createEmployeeSchemaValidation = (isNewJob: boolean) => {
    return Yup.object({
        first_name: Yup.string().required('First name is required'),
        middle_initial: Yup.string(),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        dob: Yup.string().required('Date of birth is required'),
        ssn: Yup.string().required('SSN is required'),
        job_start_date: Yup.string().required('Hire date is required')
            .test('is-after-birth', 'Employee must be at least 14 years old at the time of hiring',
            function (value: any) {
                const birthDate = new Date(this.parent.dob);
                const hireDate = new Date(value);
                return (birthDate.getFullYear() <= hireDate.getFullYear() - 14)
            }),
        job: Yup.object().when('jobData', {
            is: () => !isNewJob,
            then: Yup.object().required('Job is required').nullable(),
            otherwise: Yup.object().notRequired(),
        }),
        job_title: Yup.string().when('data', {
            is: () => isNewJob,
            then: Yup.string().required('Job title is required'),
            otherwise: Yup.string().notRequired(),
        }),
        job_location: Yup.string().when('location', {
            is: () => isNewJob,
            then: Yup.string().required('Job location is required'),
            otherwise: Yup.string().notRequired(),
        }),
        amount: Yup.number()
            .positive('Amount should be greater than 0')
            .test(
                'is-decimal',
                'Only numbers with up to two decimal places are allowed',
                (value: any) => {
                    if (isNaN(value)) return false
                    return value === parseFloat(value.toFixed(2))
                }
            )
            .required('Amount is required')
            .typeError('Amount must be a number'),
        frequency: Yup.string().required('Frequency is required'),
        isOwner: Yup.string().required('Is owner of the company is required'),
        ownership: Yup.number().when('isOwner', {
            is: (isOwner: string) => isOwner === 'yes',
            then: Yup.number()
                .required('Ownership percentage should be greater than 0')
                .min(0, 'Ownership percentage should be greater than 0')
                .max(100, 'Ownership percentage should be less than 100'),
            otherwise: Yup.number().notRequired(),
        }),
    })
}
