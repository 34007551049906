import { makeStyles, Theme } from '@material-ui/core'
import { useState } from 'react';
import UiDialog from '../../common/ui/UiDialog'
import VideoIcon from '../../../assets/icons-svg/Play-Video.svg'
import { useHistory } from 'react-router'
import UiButton from '../../common/ui/UiButton'
import { commonStyles } from '../../../styles/commonStyles'
const styles = makeStyles((theme: Theme) => ({
    footer: {
        padding: '3px 10px 10px 0 !important', // footer styles for popup needs to be generic
        textAlign: 'right',
    },
    watchButton: {
        '& .MuiButton-startIcon': {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
    },
}))
export default function WatchVideo(props: any) {
    const classes = styles()
    const [showVideo, setShowVideo] = useState(false)
    const history = useHistory()
    const commonStyle = commonStyles()
    const getStarted = () => {
        history.push('/tax-organizer/business')
    }
    const returnIframe = () => {
        const video = 'https://www.youtube.com/embed/pWImXONua00'
        return {
            __html: `<iframe src="${video}" style="width: 100%;" height="400" frameBorder="0"></iframe>`,
        }
    }
    return (
        <>
            <UiButton
                handleClick={(e) => {
                    e.stopPropagation()
                    setShowVideo(true)
                }}
                customClass={classes.watchButton}
                label="Watch video"
                startIconSvg={VideoIcon}
                btnType="secondary"
            />

            <UiDialog
                open={showVideo}
                title={'Tax Organizer Intro'}
                handleClose={() => {
                    setShowVideo(false)
                }}
                size="sm"
                contentStyles={{ padding: '0 !important' }}
            >
                <div dangerouslySetInnerHTML={returnIframe()}></div>
                <div className={classes.footer}>
                    <span className={`${commonStyle.mr3}`}>
                        <UiButton
                            handleClick={() => {
                                setShowVideo(false)
                            }}
                            label="Cancel"
                            btnType="secondary"
                        />
                    </span>
                    <UiButton
                        handleClick={getStarted}
                        label="Get Started"
                        btnType="tertiary"
                    />
                </div>
            </UiDialog>
        </>
    )
}
