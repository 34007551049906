import { useEffect, useRef } from 'react';

export const useInterval = (callback: any, delay: any) => {
  const callbackRef: any = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  },[callback]);

  useEffect(() => {
    if (!delay) {
      return () => {};
    }

    const interval = setInterval(() => {
     callbackRef.current && callbackRef.current();
    }, delay);

    return () => clearInterval(interval);
  }, [delay]);
}