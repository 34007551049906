export const USER = {
    TOS : 'api/user/current/agreement'
}
export const API_PATHS = {
    VEHICLES : {
        ALL : '/api/account/:id/mileage_tracker/vehicle?page=:page&perPage=:perPage',
        DELETE: '/api/account/:accountId/mileage_tracker/vehicle/:vehicleId',
        EDIT: '/api/account/:accountId/mileage_tracker/vehicle/:vehicleId',
        ADD: '/api/account/:accountId/mileage_tracker/vehicle'
    },
    USER: {
        TOS : 'api/user/current/agreement'
    }
}
