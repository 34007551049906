import { useCurrentStore } from '../components/common/hooks/useCurrentStore';
import { 
    BASIC_BOOKKEEPING_PERMISSION, 
    PAYROLL_PERMISSION, 
    TAX_ADVISORY,
    ESTIMATED_TAXES_PERMISSION
} from '../components/dashboard/TodoSection/Todo.const';
import { hasPermission } from '../services/aclService';

interface Permission {
    hasTaxAdvisoryPermission:boolean
    hasPayrollPermission: boolean
    hasBasicBookkeepingPermission: boolean
    hasEstimatedTaxPermission: boolean
}

const usePermission = (): Permission => {
    const { personalAccount, currentAccount } = useCurrentStore();
    const hasTaxAdvisoryPermission = hasPermission(TAX_ADVISORY, personalAccount)
    const hasEstimatedTaxPermission = hasPermission(
        ESTIMATED_TAXES_PERMISSION,
        personalAccount
    )
    const hasPayrollPermission = hasPermission(
        PAYROLL_PERMISSION,
        currentAccount
    )
    const hasBasicBookkeepingPermission = hasPermission(
        BASIC_BOOKKEEPING_PERMISSION,
        currentAccount
    )
    
    return {
        hasTaxAdvisoryPermission,
        hasPayrollPermission,
        hasBasicBookkeepingPermission,
        hasEstimatedTaxPermission
    }

}

export default usePermission