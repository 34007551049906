import React from 'react';
import { Box, Button, Typography, Divider, Theme, makeStyles } from '@material-ui/core'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
interface HeaderProps {
    headerNode?: React.ReactNode
    headerText?: string
    buttonIcon?: React.ReactNode
    buttonText?: string
    onClickAction?: (params: any) => void
}
interface SectionViewProps extends HeaderProps {
    customNode?: React.ReactNode
    sectionAlert?: React.ReactNode
    sectionInfoData?: {
        label: string | React.ReactNode
        value: string | React.ReactNode
    }[] 
    customComponent?: React.ReactNode
    disableSection?: boolean
}


const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    disableSection: {
        color: colorTheme => colorTheme.grey1900,
        cursor: "default",
        pointerEvents: "none",
    },
    hyperTextColor: {
        color: colorTheme => colorTheme.blue200
    }
}))

const DisableSection = ({ children, disableSection } : any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    return (
        <div className={disableSection ? classes.disableSection : ''}>{children}</div>
    )
}
const SectionView = (props: SectionViewProps) => {
    const styles = commonStyles()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)

    const  {
        disableSection,
        buttonText,
        buttonIcon,
        headerText,
        headerNode,
        customNode,
        sectionAlert
    } = props
    const infoItem = (
        label: string | React.ReactNode,
        value: string | React.ReactNode
    ) => (
        <Box my={3}>
            <Typography variant="body2" color="textSecondary">
                {label}
            </Typography>
            <Typography variant="body2">{value}</Typography>
        </Box>
    )

    return (
        <div>
            <Box my={4}>
                {headerNode ? (
                    headerNode
                ) : (
                    <Box
                        className={mergeStyle(styles.flex, styles.spaceBetween)}
                    >
                        <Typography variant="h6">
                            {headerText}
                        </Typography>
                        <Button
                            startIcon={buttonIcon}
                            variant="text"
                            onClick={props?.onClickAction}
                            className={disableSection ? classes.disableSection: classes.hyperTextColor}
                        >
                            {' '}
                            {buttonText}
                        </Button>
                    </Box>
                )}
                <Divider></Divider>
                {customNode ? (
                    <Box my={2}>{customNode}</Box>
                ) : (
                    <>
                        <Box my={2}>{sectionAlert}</Box>
                        <DisableSection disableSection={disableSection}>
                            {props?.sectionInfoData?.map(
                                (item: {
                                    label: string | React.ReactNode
                                    value: string | React.ReactNode
                                }) => <>{infoItem(item.label, item.value)}</>
                            )}
                        </DisableSection>
                    </>     
                )}
                <>{props?.customComponent}</>
            </Box>
        </div>
    )
}
export default SectionView
