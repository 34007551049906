import React from 'react'
import InfoCard from './InfoCard'
import UiText from '../../common/ui/UiText'
import { AppointmentDetails, Header } from './CommonComponents'

interface AdvisoryCallProps {
    primaryText: string
    secondaryText: string
    completeButtonText: string
    inCompleteButtonText?: string
    btnPrimary?: boolean
    btnSecondary?: boolean
    accountProgressInfo?: any
    fetchAccountProgress?: () => void
    scheduleAppointment?: () => void
}
const AdvisoryCall = ({
    primaryText,
    secondaryText,
    completeButtonText,
    inCompleteButtonText,
    btnPrimary,
    btnSecondary,
    accountProgressInfo,
    scheduleAppointment,
    fetchAccountProgress,
}: AdvisoryCallProps) => {
    const isDiscoveryCallCompleted =
        accountProgressInfo?.completed_discovery_appointment
    const isAdvisoryCallCompleted =
        accountProgressInfo?.completed_advisory_appointment

    const hasAdvisoryScheduled =
        accountProgressInfo?.scheduled_advisory_appointment &&
        accountProgressInfo?.advisory_appointment_details?.start_date

    const meetingStartDate =
        accountProgressInfo?.advisory_appointment_details?.start_date

    const rightButtonText = isDiscoveryCallCompleted ? completeButtonText : inCompleteButtonText    

    if (isAdvisoryCallCompleted) {
        return <Header text="Advisory call completed" />
    }

    return (
        <InfoCard
            customTitle={
                <UiText variant="hatchback_125" weight="medium_500">
                    {primaryText}
                </UiText>
            }
            subtitle={<UiText variant="motorcycle_90">{secondaryText}</UiText>}
            {...(hasAdvisoryScheduled
                ? {
                      rightButton: (
                          <AppointmentDetails
                              meetingStartDate={meetingStartDate}
                              reschedule={() => {
                                  scheduleAppointment?.()
                              }}
                          />
                      ),
                  }
                : { buttonText: rightButtonText })}
            disableClick={!isDiscoveryCallCompleted}
            btnContained={isDiscoveryCallCompleted}
            btnPrimary={isDiscoveryCallCompleted}
            buttonClick={() => {
                scheduleAppointment?.()
            }}
        />
    )
}

export default AdvisoryCall