import { Fragment, useEffect, useState } from 'react';
import {
    makeStyles,
    Theme,
    Box,
    Typography,
    Grid,
    InputAdornment,
    TextField,
    Hidden,
    Button,
    Paper,
    Divider,
    useTheme,
} from '@material-ui/core';

import { ApplicationStore, Category } from '../../../models';
import { connect, useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { addCategory } from '../../../services/journalServices';
import UiDialog from '../../common/ui/UiDialog';
import CreateCategory from '../journal/CreateCategory';
import { formSubmitErrorHandler } from '../../../services/formService';
import { Field, FieldArray, getIn, useFormikContext } from 'formik';
import { ITransaction } from '../../../models/transaction-models';
import CallSplitRoundedIcon from '@material-ui/icons/CallSplitRounded';
import ClearIcon from '@material-ui/icons/Clear';
import AmountField from './AmountField';
import Loader from '../../common/Loader';
import { currencyFormatter } from '../../../utils/appUtil';
import { difference, splitAmount } from '../../../utils/transactionUtil';
import { loadCategories } from '../../../store/actions/categories';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface CategorySelectorProps {
    accountId: string;
    businessId: string;
    isInline?: boolean;
    category: Category;
    isEdit?: boolean;
    renderedOutsideForm?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    textFieldRoot: {
        '& .MuiInputLabel-root': {
            top: '-7px',
        },
    },
    autocompleteRoot: {
        padding: '0px 0px !important',

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: theme.spacing('0', 3),
        },
    },
    splitButtonContainer: {
        width: '100%',
        margin: theme.spacing(3, '0'),
    },
    splitTransactionBtn: {
        '& .MuiButton-startIcon': {
            transform: 'rotate(90deg)',
        },
    },
    closeItem: {
        padding: '12px 0px !important',
        cursor: 'pointer',
    },
    autocomplete: {
        '& .MuiInputBase-fullWidth': {
            height: '40px',
            width: '222px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& .MuiAutocomplete-input': {
                padding: '0px',
            },
        },
    },
}));

const menuLink = ({ children, setOpenCategoryModal,colorTheme, ...other }: any) => {
    return (
        <Paper {...other}>
            <Box borderBottom={`1px solid ${colorTheme.grey200}`}>
                <Button
                    fullWidth
                    startIcon={<AddIcon />}
                    onMouseDown={(event) => {
                        event.preventDefault();
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        setOpenCategoryModal(other.name);
                    }}
                >
                    Create New Category
                </Button>
            </Box>
            {children}
        </Paper>
    );
};

const byString = (o: any, s: any) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

const FormikAutocomplete = ({
    formValues,
    listData,
    setFieldValue,
    setOpenCategoryModal,
    ...props
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles();
    const fieldError = getIn(props.form.errors, props.field.name);
    const showError =
        getIn(props.form.touched, props.field.name) && !!fieldError;

    return (
        <Autocomplete
            {...props}
            fullWidth
            classes={{ root: classes.autocompleteRoot }}
            value={byString(formValues, props.field.name) as any}
            options={listData?.categoryOptions?.filter(
                (c: any) => !c.is_hidden && c.can_be_transaction_to
            )}
            onBlur={() =>
                props.form.setTouched({
                    ...props.form.touched,
                    [props.field.name]: true,
                })
            }
            getOptionLabel={(option: any) => {
                if (typeof option === 'string') {
                    const data = listData?.accountRecord[option];
                    return data ? data?.title : '';
                } else {
                    return option.title;
                }
            }}
            getOptionSelected={(option: any, value) => {
                return option.id === value;
            }}
            onChange={(event, item: any) => {
                setFieldValue(props.field.name, item ? item.id : '');
            }}
            renderOption={(option) => {
                let title;
                if (typeof option === 'string') {
                    const data = listData?.accountRecord[option];
                    title = data ? data?.title : '';
                } else {
                    title = option.title;
                }
                return (
                    <Fragment>
                        <div>
                            <Typography variant='body1'>{title}</Typography>

                            <Typography variant='caption'>
                                {option.parent.title}
                            </Typography>
                        </div>
                    </Fragment>
                );
            }}
            renderInput={(params) => {
                return (
                    <>
                        <Hidden implementation='js' smDown>
                            <TextField
                                {...params}
                                placeholder={
                                    props.isInline
                                        ? 'Select a different category'
                                        : 'Select a category'
                                }
                                variant='outlined'
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                error={showError}
                                helperText={
                                    showError ? props.form.errors.category : ''
                                }
                            />
                        </Hidden>
                        <Hidden implementation='js' mdUp>
                            <TextField
                                {...params}
                                classes={{
                                    root: classes.textFieldRoot,
                                }}
                                variant='outlined'
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                error={showError}
                                helperText={
                                    showError ? props.form.errors.category : ''
                                }
                            />
                        </Hidden>
                    </>
                );
            }}
            PaperComponent={(child: any) => {
                return menuLink({
                    ...child,
                    name: props.field.name,
                    setOpenCategoryModal,
                    colorTheme: colorTheme
                });
            }}
        />
    );
};

function CategorySelector(props: CategorySelectorProps) {
    const classes = useStyles();
    const [openCategoryModal, setOpenCategoryModal] = useState<string>('');
    const [listData, setListData] = useState<any>({});

    const [categoryFormSubmitted, setCategoryFormSubmitted] = useState(false);
    const [lastRemovedSplitItem, setLastRemovedSplitItem] = useState<any>({});

    const {
        values: formValues,
        setFieldValue,
        initialValues,
        isSubmitting,
        isValid,
        validateForm,
        submitForm,
    } = useFormikContext<ITransaction>();
    const dispatch = useDispatch();
    const theme = useTheme()
    useEffect(() => {
        const parentCategories = {};
        const cache = {};
        [...props.category.categories].forEach((caty: any) => {
            if (caty.can_have_children) {
                //@ts-ignore
                parentCategories[caty?.id] = { ...caty };
            }
            //@ts-ignore
            cache[caty?.id] = { ...caty };
        });
        let categoryOptions = Object.values({ ...cache });
        categoryOptions.forEach((c: any) => {
            if (c.parent) {
                c.parent = categoryOptions.find(
                    (pc: any) => c.parent === pc.id
                );
            }
        });
        setListData((prev: any) => ({
            ...prev,
            accountRecord: cache,
            accountParentList: parentCategories,
            categoryOptions: categoryOptions,
        }));
    }, [props.category.categories]);

    const onCategorySubmit = (data: any, { setFieldError }: any) => {
        setCategoryFormSubmitted(true);
        formSubmitErrorHandler(
            addCategory(data, props.accountId, props.businessId).then(
                (res: any) => {
                    setCategoryFormSubmitted(false);
                    dispatch(loadCategories());
                    setFieldValue(openCategoryModal, res.id);
                    setOpenCategoryModal('');
                }
            ),
            () => {
                setCategoryFormSubmitted(false);
            },
            setFieldError
        );
    };

    const handleSplitTransctionClick = () => {
        if (props.isEdit) {
            setFieldValue('split', [
                {
                    category:
                        lastRemovedSplitItem.category ||
                        initialValues.category ||
                        '',
                    amount:
                        lastRemovedSplitItem.amount ||
                        initialValues.amount ||
                        '',
                },
                { category: '', amount: '' },
            ]);
        } else {
            setFieldValue('split', [
                {
                    category: lastRemovedSplitItem.category || '',
                    amount: lastRemovedSplitItem.amount || '',
                },
                { category: '', amount: '' },
            ]);
            setFieldValue('category', '');
        }
        setLastRemovedSplitItem({});
    };

    const deleteSplitItem = (index: number, remove: any) => {
        setLastRemovedSplitItem(
            isFilledLine(formValues.split?.[index])
                ? formValues.split?.[index]
                : {}
        );
        if (formValues.split?.length === 2) {
            setFieldValue('split', []);
        } else {
            remove(index);
            //@ts-ignore
            formValues.split.splice(index, 1);
            setFieldValue('split', formValues.split);
        }
    };

    const isFilledLine = function (line: any) {
        return line.category && line.amount;
    };

    const addLine = function (line?: any) {
        formValues?.split?.push(line || { category: '', amount: '' });
        setFieldValue('split', formValues.split);
    };

    useEffect(() => {
        if (formValues.split?.length) {
            checkLines();
        }
    }, [formValues.split]);

    const checkLines = function () {
        let unFilledLine = formValues?.split?.find(function (line) {
            return !isFilledLine(line);
        });

        if (!unFilledLine) {
            addLine();
        } else {
            validateForm();
        }
    };

    return (
        <Box>
            <UiDialog
                open={!!openCategoryModal}
                handleClose={() => {
                    setOpenCategoryModal('');
                }}
                title='Add New Category'
                size='sm'
            >
                <CreateCategory
                    categoryFormSubmitted={categoryFormSubmitted}
                    accountParentList={listData?.categoryOptions?.filter(
                        (c: any) => c.can_have_children
                    )}
                    onCategorySubmit={onCategorySubmit}
                    handleCategoryClose={() => {
                        setOpenCategoryModal('');
                    }}
                />
            </UiDialog>
            {listData?.categoryOptions ? (
                <Grid container>
                    <Grid
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='flex-start'
                    >
                        {props.isEdit !== undefined && (
                            <Typography variant='h6'>
                                {formValues?.split?.length || 0 > 1
                                    ? 'Split Transaction (Required)'
                                    : 'Category (Required)'}
                            </Typography>
                        )}
                        {(props.isEdit &&
                            !formValues.is_manual &&
                            formValues?.split?.length) ||
                        0 > 1 ? (
                            <Typography variant='h6' color='primary'>
                                {currencyFormatter.format(
                                    Number.parseFloat(formValues.amount || '')
                                )}
                            </Typography>
                        ) : (
                            ''
                        )}
                    </Grid>

                    {!formValues?.split?.length || 0 > 1 ? (
                        <>
                            {props.renderedOutsideForm && (
                                <Typography variant='h6'>Category</Typography>
                            )}
                            <Box
                                my={2}
                                width='100%'
                                style={{ display: 'flex' }}
                            >
                                <Field
                                    name='category'
                                    render={({ ...InputProps }: any) => (
                                        <FormikAutocomplete
                                            {...InputProps}
                                            formValues={formValues}
                                            listData={listData}
                                            setFieldValue={setFieldValue}
                                            setOpenCategoryModal={
                                                setOpenCategoryModal
                                            }
                                        />
                                    )}
                                    required
                                    validate={(value: any) => {
                                        return !value
                                            ? 'Category is required'
                                            : '';
                                    }}
                                    {...(props.renderedOutsideForm
                                        ? { style: { marginRight: '10px' } }
                                        : '')}
                                />
                                {props.renderedOutsideForm && (
                                    <Box>
                                        {isSubmitting ? (
                                            <Loader />
                                        ) : (
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                type='submit'
                                                disabled={
                                                    !isValid || isSubmitting
                                                }
                                                onClick={() => {
                                                    submitForm();
                                                }}
                                                style={{ marginRight: '5px' }}
                                            >
                                                Categorize
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Box>
                            <Box className={classes.splitButtonContainer}>
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    className={classes.splitTransactionBtn}
                                    startIcon={<CallSplitRoundedIcon />}
                                    onClick={handleSplitTransctionClick}
                                >
                                    Split Transaction
                                </Button>
                            </Box>
                        </>
                    ) : (
                        ''
                    )}

                    {props.renderedOutsideForm &&
                        (formValues?.split?.length || 0 > 1) && (
                            <Typography variant='h6'>
                                {' '}
                                Split Transaction
                            </Typography>
                        )}
                    <FieldArray name='split'>
                        {({ push, remove, form, ...rest }) => {
                            return formValues.split ? (
                                <>
                                    <Box width='100%' marginTop='12px'>
                                        {formValues.split.map(
                                            (splitItem: any, index: number) => {
                                                return (
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        key={`row-${index}`}
                                                    >
                                                        <Grid
                                                            container
                                                            item
                                                            xs={11}
                                                            md={5}
                                                        >
                                                            <Field
                                                                name={`split[${index}].category`}
                                                                render={({
                                                                    ...InputProps
                                                                }: any) => (
                                                                    <FormikAutocomplete
                                                                        {...InputProps}
                                                                        formValues={
                                                                            formValues
                                                                        }
                                                                        listData={
                                                                            listData
                                                                        }
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                        setOpenCategoryModal={
                                                                            setOpenCategoryModal
                                                                        }
                                                                    />
                                                                )}
                                                                validate={
                                                                    index !==
                                                                        (formValues
                                                                            ?.split
                                                                            ?.length ||
                                                                            0) -
                                                                            1 ||
                                                                    formValues
                                                                        ?.split?.[
                                                                        index
                                                                    ].amount ||
                                                                    Number.parseFloat(
                                                                        formValues
                                                                            ?.split?.[
                                                                            index
                                                                        ]
                                                                            .amount ||
                                                                            '0'
                                                                    ) !== 0
                                                                        ? (
                                                                              value: any
                                                                          ) => {
                                                                              return !value
                                                                                  ? 'Category is required'
                                                                                  : '';
                                                                          }
                                                                        : ''
                                                                }
                                                            />
                                                        </Grid>
                                                        <Hidden
                                                            implementation='js'
                                                            mdUp
                                                        >
                                                            <Grid
                                                                className={
                                                                    classes.closeItem
                                                                }
                                                                container
                                                                item
                                                                xs={1}
                                                                md={1}
                                                            >
                                                                <ClearIcon
                                                                    onClick={() =>
                                                                        deleteSplitItem(
                                                                            index,
                                                                            remove
                                                                        )
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Hidden>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={10}
                                                            md={6}
                                                        >
                                                            <Field
                                                                component={
                                                                    AmountField
                                                                }
                                                                name={`split[${index}].amount`}
                                                                fieldname={`split[${index}].amount`}
                                                                value={
                                                                    formValues
                                                                        ?.split?.[
                                                                        index
                                                                    ].amount
                                                                }
                                                                validate={(
                                                                    value: any
                                                                ) => {
                                                                    if (
                                                                        index !==
                                                                            (formValues
                                                                                ?.split
                                                                                ?.length ||
                                                                                0) -
                                                                                1 ||
                                                                        formValues
                                                                            ?.split?.[
                                                                            index
                                                                        ]
                                                                            .category
                                                                    ) {
                                                                        return !value ||
                                                                            Number.parseFloat(
                                                                                value
                                                                            ) ==
                                                                                0
                                                                            ? 'Amount is required'
                                                                            : '';
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                }}
                                                            ></Field>
                                                        </Grid>

                                                        {index !=
                                                        (formValues.split
                                                            ?.length || 0) -
                                                            1 ? (
                                                            <Hidden
                                                                implementation='js'
                                                                smDown
                                                            >
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={1}
                                                                    md={1}
                                                                >
                                                                    <ClearIcon
                                                                        onClick={() =>
                                                                            deleteSplitItem(
                                                                                index,
                                                                                remove
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Hidden>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Grid>
                                                );
                                            }
                                        )}
                                    </Box>
                                </>
                            ) : null;
                        }}
                    </FieldArray>

                    {formValues?.split?.length || 0 > 1 ? (
                        <>
                            <Box width='100%' marginBottom='12px'>
                                <Divider variant='fullWidth'></Divider>
                            </Box>
                            <Grid
                                container
                                direction='column'
                                justify='flex-start'
                                alignItems='stretch'
                            >
                                <Grid
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='flex-start'
                                >
                                    <Typography variant='subtitle1'>
                                        Original Amount
                                    </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ marginRight: '10px' }}
                                    >
                                        {/* <CurrencyText value={80}></CurrencyText> */}
                                        {currencyFormatter.format(
                                            parseFloat(formValues.amount || '0')
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='flex-start'
                                >
                                    <Typography variant='subtitle1'>
                                        Split Amount
                                    </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ marginRight: '10px' }}
                                    >
                                        {/* <CurrencyText value={80}></CurrencyText> */}
                                        {currencyFormatter.format(
                                            splitAmount(formValues) || 0
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='flex-start'
                                >
                                    <Typography variant='subtitle1'>
                                        Difference
                                    </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ marginRight: '10px' }}
                                    >
                                        {/* <CurrencyText value={80}></CurrencyText> */}
                                        {currencyFormatter.format(
                                            difference(formValues)
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        ''
                    )}
                    {props.renderedOutsideForm &&
                        formValues?.split &&
                        formValues?.split?.length > 0 && (
                            <Box className={classes.splitButtonContainer}>
                                <Box width='100%'>
                                    {formValues?.split &&
                                    formValues?.split?.length ? (
                                        <Box>
                                            <Divider
                                                variant='fullWidth'
                                                style={{
                                                    marginBottom:
                                                        theme.spacing(3),
                                                }}
                                            ></Divider>
                                            {isSubmitting ? (
                                                <Loader />
                                            ) : (
                                                <>
                                                    <Button
                                                        variant='outlined'
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            setFieldValue(
                                                                'split',
                                                                []
                                                            );
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        style={{
                                                            marginLeft:
                                                                theme.spacing(
                                                                    3
                                                                ),
                                                        }}
                                                        type='submit'
                                                        disabled={
                                                            !isValid ||
                                                            isSubmitting
                                                        }
                                                        onClick={() => {
                                                            submitForm();
                                                        }}
                                                    >
                                                        Categorize
                                                    </Button>
                                                </>
                                            )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        )}
                </Grid>
            ) : (
                <Loader />
            )}
        </Box>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    category: state.category,
});
export default connect(mapStateToProps)(CategorySelector);
