import { Box, Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { memo } from 'react';
import Loader from '../../common/Loader';
import UiFormField from '../../common/ui/UiFormField';
import * as Yup from 'yup';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface Props {
    handleCategoryClose: () => void;
    initialCategoryValues?: any;
    onCategorySubmit: (data: any, helper: FormikHelpers<any>) => void;
    accountParentList: any;
    categoryFormSubmitted: Boolean;
}
const useStyle = makeStyles <Theme, ThemeColors>({
    marginVertical: {
        marginTop: 20,
        marginBottom: 20,
    },
    marginBottom: {
        marginBottom: 20,
    },
    reviewOrderButton: {
        '&:not(:disabled)': {
            backgroundColor: (colorTheme) => colorTheme.orange300,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
    },
    footer: {
        display: 'grid',
        gridTemplateColumns: '80% 20%',
        alignItems: 'center',
    },
});

const initialFormValues = {
    parent: '',
    title: '',
};

const CreateCategory = ({
    handleCategoryClose,
    onCategorySubmit,
    accountParentList,
    categoryFormSubmitted,
}: Props) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme);
    const categoryValidationSchema = Yup.object({
        parent: Yup.string().required('Type is required'),
        title: Yup.string().required('Title is required'),
    });
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={categoryValidationSchema}
            onSubmit={onCategorySubmit}
            enableReinitialize
            validateOnMount={true}
        >
            {(categoryForm) => {
                return (
                    <Form>
                        <Box>
                            <Grid item xs={12}>
                                <UiFormField
                                    type='select'
                                    placeholder='Select Type'
                                    label=''
                                    labelSize={false}
                                    fieldName='parent'
                                    optionsData={accountParentList}
                                    optionKey='title'
                                    optionValue='id'
                                    fastField={false}
                                    secondaryTextKey='parent.title'
                                    data-cy="category-select-field"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UiFormField
                                    type='text'
                                    placeholder='Enter Category Name'
                                    label='Enter Category Name'
                                    fieldName='title'
                                    data-cy="category-name-field"
                                />
                            </Grid>
                            <Box display='flex' alignItems='center'>
                                <Grid item xs={6}>
                                    <Button
                                        variant='text'
                                        fullWidth
                                        type='button'
                                        data-cy="cancel-journal-category-btn"
                                        onClick={() => handleCategoryClose()}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    {categoryFormSubmitted ? (
                                        <div className={styles.marginVertical}>
                                            <Loader />
                                        </div>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            fullWidth
                                            color='primary'
                                            className={styles.marginVertical}
                                            type='submit'
                                            disabled={!categoryForm.isValid}
                                            onClick={() => {
                                                categoryForm.validateForm();
                                            }}
                                            data-cy="create-journal-category-btn"
                                        >
                                            Create
                                        </Button>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default memo(CreateCategory);
