import React, { useContext, useEffect, useState } from 'react'
import { Button, Theme, makeStyles } from '@material-ui/core'
import Finance from '../../../assets/images/Finance.svg'
import { ReactComponent as Upload } from '../../../assets/icons-svg/Upload.svg'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import UiButton from '../../common/ui/UiButton'
import {
    CentereAligned,
    Container,
    InlineFlex,
    YcentereAligned,
} from '../Wrapper'
import { ImportYourW9 } from './ImportYourW9'
import { ImportYourW9Step2 } from './ImportYourW9Step2'
import { MatchYouColunms } from './MatchYouColunms'
import { YerifyYourData } from './VerifyYourData'
import { File1099Necs } from './File1099Necs'
import { AddContractorModal } from '../AddContractorModal'
import { STEPS, pages } from '../constants/contractor.const'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { useHistory, useLocation } from 'react-router-dom'
import Loader from '../../common/Loader'
import {
    importInitialValues,
    useContractorContext,
} from '../ContractorProvider'
import { contractorImportData } from '../apiServices/contractor.api'
import { InformationBlock } from '../InformationBlock'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        mainImage: {
            marginBottom: toRem16(24),
        },
        textCustomStyle: {
            marginBottom: toRem16(16),
            textAlign: 'center',
        },
        centerText: {
            textAlign: 'center',
            marginBottom: toRem16(16),
        },
        stepCircle: {
            minWidth: toRem16(40),
            height: toRem16(40),
            backgroundColor: colorTheme => `${colorTheme.teal200}`,
            borderRadius: toRem16(40),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        uploadBtn: {
            marginTop: '1rem',
            color: colorTheme => `${colorTheme.blue200}`,
            textDecoration: 'none',
        },
    }
})

type StepDetailsProps = {
    steps: string[]
    colorTheme: any
}

const StepDetails: React.FC<StepDetailsProps> = ({ steps, colorTheme }): any => {
    const classes = useStyles(colorTheme)
    return steps.map((stepText: string, index: number) => (
        <InlineFlex
            key={`step_${index}`}
            gap={toRem16(16)}
            marginbottom={toRem16(24)}
        >
            <YcentereAligned key={`step_${index}`} gap={toRem16(16)}>
                <div className={classes.stepCircle}>
                    <UiText variant="hatchback_125" weight="semi_bold_600">
                        {index + 1}
                    </UiText>
                </div>
                <UiText variant="motorcycle_90">{stepText}</UiText>
            </YcentereAligned>
        </InlineFlex>
    ))
}

interface NoContractorPageProps {
    handleClickOnImportW9: () => void
    handleOpenModal: () => void
}

const NoContractorPage = (props: NoContractorPageProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { handleClickOnImportW9, handleOpenModal } = props
    return (
        <>
            <InformationBlock />
            <CentereAligned>
                <img
                    src={Finance}
                    alt="back to back"
                    className={classes.mainImage}
                />
            </CentereAligned>
            <div className={classes.centerText}>
                <UiText variant="truck_175" weight="medium_500">
                    Add contractors to start filing 1099-NECs
                </UiText>
                <UiText
                    variant="motorcycle_90"
                    className={classes.textCustomStyle}
                >
                    US Only. Not available for Puerto Rico
                </UiText>
            </div>
            <StepDetails steps={STEPS} colorTheme={colorTheme} />
            <div className={classes.centerText}>
                <UiButton
                    handleClick={handleOpenModal}
                    label="Add a Contractor"
                    btnType="tertiary"
                />
            </div>
            <div className={classes.centerText}>
                <Button
                    startIcon={<Upload />}
                    variant="text"
                    className={classes.uploadBtn}
                    onClick={handleClickOnImportW9}
                >
                    Import W-9s
                </Button>
            </div>
        </>
    )
}

export const LandingPage = () => {
    const [isEmpotyState, setIsEmpotyState] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const history = useHistory()
    const query = new URLSearchParams(useLocation().search)
    const searchQuery = query.get('import')

    const {
        contractors,
        currentBusinessId,
        isLoading,
        fetchContractors,
        setImportData,
        setIsLoading,
    } = useContractorContext()

    useEffect(() => {
        setActiveRouteHeading(
            searchQuery ? 'Import your W-9s' : 'File 1099-NECs'
        )
        fetchContractors()
        if (searchQuery) {
            setIsLoading(true)
            contractorImportData(currentBusinessId!)
                .then((res: any) => {
                    setImportData(res ?? importInitialValues)
                    setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                })
        }
    }, [setActiveRouteHeading])

    useEffect(() => {
        if (contractors) {
            setIsEmpotyState(contractors?.items?.length ? true : false)
        }
    }, [contractors])

    const handleClickOnImportW9 = () => {
        history.push('/contractors/file-1099-nec?import=true')
    }

    const handleOpenModal = () => {
        setIsOpen(true)
    }

    const handlePages = () => {
        let renderPage: pages = searchQuery
            ? pages.IMPORT_W_9
            : isEmpotyState
            ? pages.MAIN
            : pages.EMPTY_STATE
        switch (renderPage) {
            case pages.EMPTY_STATE:
                return (
                    <NoContractorPage
                        handleOpenModal={handleOpenModal}
                        handleClickOnImportW9={handleClickOnImportW9}
                    />
                )
            case pages.IMPORT_W_9:
                return (
                    <>
                        <ImportYourW9 />
                        <ImportYourW9Step2 />
                        <MatchYouColunms />
                        <YerifyYourData />
                    </>
                )
            default:
                return <File1099Necs />
        }
    }

    return (
        <>
            <CentereAligned>
                <Container maxwidth={toRem16(1024)} padding={toRem16(16)}>
                    {isLoading ? <Loader /> : handlePages()}
                </Container>
            </CentereAligned>
            <AddContractorModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}
