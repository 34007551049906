import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import {
    OnBoardingFormType,
    OwnerType,
    OwnersArray,
} from './models/onboarding.interface'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import OnBoardingDetails from './OnBoardingDetails'
import CreateNewAccount from './CreateNewAccount'
import * as Yup from 'yup'
import { omit } from 'lodash'
import { Theme, makeStyles } from '@material-ui/core'
import { useOnBoardingState } from './OnBoardingProvider'
import { useHistory } from 'react-router-dom'
import { formSubmitErrorHandler } from '../../../services/formService'
import { createBusiness } from '../../../services/apiService'
import { loadPostAuthData } from '../../../store/actions/appData'
import { connect } from 'react-redux'
import { setInLocalStorage } from '../../../services/localStorageService'
import { initPermissionCheck } from '../../../utils/appUtil'
import { ONBOARDING_FORM_MISSING_FIELDS } from './constant/business-onboarding.const'

const useStyle = makeStyles((theme: Theme) => ({
    layout: {
        height: '100%',
        width: '100%',
        marginBottom: '2rem',
        overflow: 'auto',
    },
    form: {
        height: '100%',
        width: '100%',
    },
}))

const onboardingPageValidationSchema = Yup.object({
    industry: Yup.string().required('Required'),
    business_type: Yup.string().required('Required'),
    form_state: Yup.string().required('Required'),
    business_owners: Yup.array().of(
        Yup.object().shape({
            first_name: Yup.string().required('First Name is required.'),
            last_name: Yup.string().required('Last Name is required.'),
            ownership: Yup.number()
                .required('Required')
                .min(1, 'This should be between 1% and 99%.')
                .max(100, 'This should be between 1% and 99%.'),
        })
    ),
})

const getMissingProperties = (currentUser: any): string[] => {
    const missingProperties: string[] = [];
  
    if (!currentUser.hasOwnProperty(ONBOARDING_FORM_MISSING_FIELDS.COMPANY)) {
      missingProperties.push(ONBOARDING_FORM_MISSING_FIELDS.COMPANY);
    }
  
    if (!currentUser.hasOwnProperty(ONBOARDING_FORM_MISSING_FIELDS.FIRST_NAME)) {
      missingProperties.push(ONBOARDING_FORM_MISSING_FIELDS.FIRST_NAME);
    }
  
    if (!currentUser?.hasOwnProperty(ONBOARDING_FORM_MISSING_FIELDS.LAST_NAME)) {
      missingProperties.push(ONBOARDING_FORM_MISSING_FIELDS.LAST_NAME);
    }
  
    return missingProperties;
  }

const CreateNewBusiness = ({ loadPostAuthData }: any) => {
    const classes = useStyle()
    const { currentUser, currentBusinessId, currentAccountId } =
        useCurrentStore()
    const missingProperties = getMissingProperties(currentUser);
    const { formState, setIsFormSubmitting, isInitialValuesSet } =
        useOnBoardingState()

    let companyName = currentUser.company ?? formState?.name ?? ''  
    let firstName = currentUser.first_name ?? formState?.first_name ?? ''
    let lastName = currentUser?.last_name ?? formState?.last_name ?? ''

    let initialValues: OnBoardingFormType = {
        name: companyName,
        first_name: firstName,
        last_name: lastName,
        industry: currentUser?.extra_info?.industry,
        business_type: '',
        form_state: '',
        only_owner: 'Yes',
        totalSharesError: '',
        business_owners: [
            {
                first_name: firstName,
                last_name: lastName,
                ownership: 100,
            },
        ],
    }

    const history = useHistory()
    if (currentBusinessId) {
        history.push('/dashboard')
    }

    const calculateTotalShares = (owners: OwnersArray) => {
        let totalShares = 0
        owners.forEach((owner: OwnerType) => {
            totalShares += owner.ownership
        })
        return totalShares
    }

    const handleFormSubmit = (
        values: OnBoardingFormType,
        actions: FormikHelpers<OnBoardingFormType>
    ) => {
        const totalShare = calculateTotalShares(values.business_owners)
        if (totalShare !== 100) {
            actions.setFieldError(
                'totalSharesError',
                'Total shares must be 100'
            )
            return
        }
        setIsFormSubmitting(true)
        const payload = omit(values, ['totalSharesError'])
        formSubmitErrorHandler(
            createBusiness(payload, currentAccountId).then((response: any) => {
                loadPostAuthData(response.portal_account).then((res: any) => {
                    setInLocalStorage('permission_check', {
                        count: 10,
                    })
                    initPermissionCheck(10)
                    history.push('/dashboard')
                    setIsFormSubmitting(false)
                })
            }),
            () => {
                setIsFormSubmitting(false)
            },
            actions.setFieldError
        )
    }

    return (
        <div className={classes.layout}>
            {missingProperties.length > 0 && !isInitialValuesSet?  (
                <CreateNewAccount missingProperties={missingProperties} />
            ) : (
                <>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validationSchema={onboardingPageValidationSchema}
                        enableReinitialize={true}
                        validateOnBlur={true}
                        validateOnMount={true}
                    >
                        {(formik) => {
                            return (
                                <Form className={classes.form}>
                                    <OnBoardingDetails />
                                </Form>
                            )
                        }}
                    </Formik>
                </>
            )}
        </div>
    )
}

export default connect(null, { loadPostAuthData })(CreateNewBusiness)
