import React, { useEffect, useState } from 'react'
import UiText from '../../../common/ui/UiText'
import {
    Button,
    FormControl,
    FormHelperText,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog'
import { toRem16 } from '../../../../styles/commonStyles'
import { requestErrorHandler } from '../../../../services/formService'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../store/actions/feedback'
import { Container } from '../../Wrapper'
import { makeTaxYearDropdown } from '../../utills/contractor.utils'
import { sendW9Email } from '../../apiServices/contractor.api'
import { Contractor } from '../../models/contractor.model'
import { useContractorContext } from '../../ContractorProvider'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiPaper-root': {
            maxWidth: `${toRem16(450)} !important`,
        },
    },
    secondaryText: {
        marginTop: '1.5rem',
    },
    formControl: {
        margin: 0,
        width: '100%',
    },
}))

interface RequestInformationDialogProps {
    openDialog: boolean
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
    contractorId: string
    selectedContractor: Contractor
    setSelectedContractor: any
    contractors: any
}
const RequestInformationDialog = ({
    openDialog,
    setOpenDialog,
    contractorId,
    selectedContractor,
    setSelectedContractor,
    contractors,
}: RequestInformationDialogProps) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [taxYear, setTaxYear] = React.useState<string>('')
    const { loadContractors } = useContractorContext()

    useEffect(() => {
        return () => {
            setTaxYear('')
        }
    }, [contractorId])

    useEffect(() => {
        setSelectedContractor(
            contractors?.items.find(
                (data: any) => data.id === selectedContractor.id
            )
        )
    }, [contractors?.items])

    const requestW9Input = () => {
        if (!taxYear) {
            return
        }
        setLoading(true)
        requestErrorHandler(
            sendW9Email(taxYear, contractorId).then(() => {
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: 'Email sent successfully',
                    })
                )
                setLoading(false)
                setOpenDialog(false)
                loadContractors()
            }),
            true,
            () => {
                setLoading(false)
            }
        )
    }

    const handleTaxYearChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setTaxYear(event.target.value as string)
    }

    const needToshowYear = (year: string): boolean => {
        let needToshow = false
        selectedContractor?.contractor_w_nine.forEach((data: any) => {
            if (data.status !== 'filed' && data.year === year) needToshow = true
        })
        return needToshow
    }

    const yearOptions = makeTaxYearDropdown().filter((item) =>
        needToshowYear(item.year)
    )

    return (
        <UiConfirmationDialog
            open={openDialog}
            customRootClass={classes.container}
            message={
                <div>
                    <UiText weight="semi_bold_600" variant="hatchback_125">
                        Send a W-9 to the Contractor
                    </UiText>
                    <div className={classes.secondaryText}>
                        <UiText variant="motorcycle_90">
                            Your contractor will receive a W-9 where they can
                            provide any missing information necessary to file
                            Form 1099-NEC
                        </UiText>
                    </div>
                    <Container
                        maxwidth={toRem16(240)}
                        margin={`${toRem16(24)} 0`}
                    >
                        {yearOptions?.length > 0 ? (
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size="small"
                                error={Boolean(!taxYear)}
                            >
                                <Select
                                    labelId="tax_yar"
                                    id="tax_yar_outlined"
                                    value={taxYear}
                                    onChange={handleTaxYearChange}
                                    displayEmpty
                                    error={Boolean(!taxYear)}
                                >
                                    <MenuItem disabled value="">
                                        Select Tax Year
                                    </MenuItem>
                                    {yearOptions.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.year}
                                                value={item.year}
                                            >
                                                {`Tax Year ${item.year}`}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {!taxYear && (
                                    <FormHelperText>
                                        Tax Year Required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        ) : (
                            <UiText>
                                The 1099-NEC has already been filed.
                            </UiText>
                        )}
                    </Container>
                </div>
            }
            handleClose={() => {
                setOpenDialog(false)
            }}
            showLoader={loading}
            confirmNode={
                <Button
                    startIcon={<MailOutlineIcon />}
                    variant="contained"
                    color="primary"
                    onClick={requestW9Input}
                    disabled={!yearOptions?.length}
                >
                    {' '}
                    Send a W9
                </Button>
            }
            cancelButtonText="Cancel"
        />
    )
}

export default RequestInformationDialog
