import {
    Avatar,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Fragment } from 'react';
import { BankAccount } from '../../../models/bankAccount';
import { BankAccountGroup } from '../../../models/bankAccountGroup';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { currencyFormatter } from '../../../utils/appUtil';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { base64ToImage } from '../../../utils/imageUtil';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    listRoot: {
        width: '100%',
        overflow: 'auto',
        marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    selectedBank: {
        background: (colorTheme) =>`${colorTheme.cyan100} !important`,
        borderRadius: theme.spacing(0),
    },
}))

function BankGroupList(props: {
    institutions: BankAccount[]
    selectedInstitution?: string | number
    showBanks: (bankAccountGroup: BankAccountGroup) => void
}) {
    const theme = useTheme();
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const dissconncetedAccounts = (accounts: BankAccount[]) => {
        let dissconncetedAccounts = accounts.filter(
            (acc) => acc.disconnected
        ).length
        return dissconncetedAccounts === accounts.length
            ? 'All'
            : dissconncetedAccounts
    }

    return (
        <List className={classes.listRoot}>
            {props.institutions.map((institution: any, index: any) => (
                <Fragment key={index}>
                    <ListItem
                        component="div"
                        button
                        classes={{
                            gutters: classes.navListItemGutters,
                            selected: classes.selectedBank,
                        }}
                        onClick={() => props.showBanks(institution)}
                        alignItems="flex-start"
                        selected={
                            (!isSmallDevice &&
                                props.selectedInstitution ===
                                    institution.institution_id) ||
                            props.selectedInstitution ===
                                institution.bank_accounts[0].id
                        }
                        style={{ cursor: 'pointer' }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                alt="User Avatar"
                                src={
                                    base64ToImage(institution?.logo) ||
                                    institution.logo_url ||
                                    require(`../../../assets/icons-svg/${
                                        institution.is_manual
                                            ? 'BankGeneric'
                                            : 'BankGenericConnected'
                                    }.svg`).default
                                }
                            />
                        </ListItemAvatar>

                        <ListItemText
                            primary={
                                <Fragment>
                                    {institution.bank_details?.name || (
                                        <span>&nbsp;</span>
                                    )}
                                </Fragment>
                            }
                            secondary={
                                <Fragment>
                                    {institution.is_manual ? (
                                        currencyFormatter.format(
                                            institution.bank_accounts[0].balance
                                        )
                                    ) : (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                {
                                                    institution.bank_accounts
                                                        .length
                                                }{' '}
                                                Account
                                                {institution.bank_accounts
                                                    .length > 1
                                                    ? 's'
                                                    : ''}
                                            </Grid>
                                            <Grid item>
                                                {dissconncetedAccounts(
                                                    institution.bank_accounts
                                                )
                                                    ? `${dissconncetedAccounts(
                                                          institution.bank_accounts
                                                      )} disconnected`
                                                    : ''}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Fragment>
                            }
                        />
                    </ListItem>
                    {index === props.institutions.length - 1 ||
                    props.selectedInstitution === institution.id ? (
                        ''
                    ) : (
                        <Divider variant="inset" />
                    )}
                </Fragment>
            ))}
        </List>
    )
}

export default BankGroupList
