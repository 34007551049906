import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
    Button,
    Theme,
    makeStyles,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Trip } from '../../../models'
import Loader from '../../common/Loader'
import TripDetails from './TripDetails/'
import TripOverlayForm from './Form/TripOverlayForm'
import MobileHeader from '../../common/MobileHeader'
import { getTripDetails, deleteTrip } from '../../../services/apiService/trips'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import { ContainedDeleteButton } from '../../common/DeleteButton'
import UiText from '../../common/ui/UiText'
import { showError } from './../../../services/formService'

const useStyles = makeStyles((theme: Theme) => ({
    previewTrip: {
        marginTop: theme.spacing(4),
    },
    deleteLoader: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        marginBottom: '0.125rem',
    },
    flexButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    mt1: {
        marginTop: '1rem',
    },
}))

function TripPreview({
    selectedTrip,
    setSelectedTrip,
    reloadData,
    trips
}: Readonly<{
    selectedTrip?: Trip
    setSelectedTrip?: React.Dispatch<React.SetStateAction<any>>
    reloadData?: () => void
    trips?: Trip[]
}>) {
    const styles = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    const isSMDown = useMediaQuery(theme.breakpoints.down('sm'))
    const params: { id: string } = useParams()
    const [loading, setLoading] = useState(false)
    const [showDeleteOverlay, setShowDeleteOverlay] = useState(false)
    const [showEditOverlay, setShowEditOverlay] = useState(false)
    const [showReturnOverlay, setShowReturnOverlay] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [currentTrip, setCurrentTrip] = useState<Trip>()

    const tripDetails = selectedTrip || currentTrip;

    const openEditOverlay = () => setShowEditOverlay(true)
    const promptDelete = () => setShowDeleteOverlay(true)
    const openReturnOverlay = () => setShowReturnOverlay(true)

    const deleteCurrentTrip = () => {
        setIsDeleting(true)
        deleteTrip(tripDetails?.id as string, (isError) => {
            if(!isError){
                const remainingTrips = trips?.filter(trip => trip.id !== tripDetails?.id);
                setSelectedTrip?.(remainingTrips?.[0])
            }
            setIsDeleting(false)
            setShowDeleteOverlay(false)
            reloadData?.()
            isSMDown && history.push('/mileage/trips')
        })
    }

    const getCurrentTrip = useCallback(() => {
        setLoading(true)
        let tripId = tripDetails?.id as string || params?.id;
        getTripDetails(tripId)
            .then((response: unknown) => {
                const trip = response as Trip
                setSelectedTrip ? setSelectedTrip(trip) : setCurrentTrip(trip);
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                showError(
                    'Could not load trip details. Please try again later.'
                )
            })
    }, [params?.id, tripDetails?.id, setSelectedTrip])

    useEffect(() => {
        if (params?.id) {
            getCurrentTrip()
        } 
    }, [params?.id])

    return (
        <div>
            {!tripDetails || loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MobileHeader showBottomDivider={isXsDown}>
                        <div className={styles.flexButtons}>
                            <IconButton onClick={() => history.push('/mileage/trips')}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Button
                                variant="text"
                                onClick={() => setShowDeleteOverlay(true)}
                            >
                                Edit
                            </Button>
                        </div>
                    </MobileHeader>
                    {/* Edit Overlay */}
                    <TripOverlayForm
                        isOpen={showEditOverlay}
                        trip={tripDetails}
                        handleClose={() => setShowEditOverlay(false)}
                        reloadData={reloadData}
                        submitCallback={getCurrentTrip}
                        isNew={false}
                    />
                    {/* Return trip overlay */}
                    <TripOverlayForm
                        isOpen={showReturnOverlay}
                        trip={tripDetails}
                        handleClose={() => setShowReturnOverlay(false)}
                        reloadData={reloadData}
                        isNew={true}
                        isReturnTrip={true}
                        submitCallback={setSelectedTrip}
                    />
                    <UiConfirmationDialog
                        open={showDeleteOverlay}
                        message={
                            <div>
                                <UiText weight="semi_bold_600">
                                    Delete trip #{tripDetails?.number} ?
                                </UiText>
                                <UiText className={styles.mt1}>
                                    The trip will be deleted from your account.
                                </UiText>
                                <UiText className={styles.mt1}>
                                    This can not be undone.
                                </UiText>
                            </div>
                        }
                        handleClose={() => {
                            setShowDeleteOverlay(false)
                        }}
                        confirmNode={
                            <>
                                {isDeleting ? (
                                    <div className={styles.deleteLoader}>
                                        <Loader size={30} />
                                    </div>
                                ) : (
                                    <ContainedDeleteButton
                                        variant="contained"
                                        showDeleteIcon
                                        onClick={deleteCurrentTrip}
                                        data-cy="delete-trip-btn"
                                    >
                                        Delete
                                    </ContainedDeleteButton>
                                )}
                            </>
                        }
                        cancelButtonText="Cancel"
                    />
                    {tripDetails && (
                        <TripDetails
                            trip={tripDetails}
                            promptDelete={promptDelete}
                            openEditOverlay={openEditOverlay}
                            openCreateOverlay={openReturnOverlay}
                        />
                    )}
                </Fragment>
            )}
        </div>
    )
}

export default TripPreview
