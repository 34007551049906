export const getClassWiseBalance = (items: any, segmentId : string) : string=>{
    const zeroClasswiseBalance = "";
    const classwiseBalanceValue = items['class_wise_balances'] && items['class_wise_balances'][segmentId];
    if(!classwiseBalanceValue){
        return zeroClasswiseBalance;
    }
    return classwiseBalanceValue;
}

export const getSegmentsData = (selectedClasses : any) =>{
    let classNameAndIdObjectArray: any = [];
    for (const [key, value] of Object.entries(selectedClasses)) {
        let classNameAndIdObject : any = {};
        classNameAndIdObject.segmentId = key;
        classNameAndIdObject.segmentName = value;
        classNameAndIdObjectArray.push(classNameAndIdObject);
      }
      return classNameAndIdObjectArray;
}