import { Box, Theme, makeStyles } from '@material-ui/core';
import { Fragment } from 'react';
import UiButton from '../../common/ui/UiButton';
import UiDialog from '../../common/ui/UiDialog';
import UiText from '../../common/ui/UiText';

interface UnsavedSectionWarningProps {
    open: boolean;
    handleModalAction: (action: 'close' | 'keep' | 'discard' | 'save') => void;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | undefined
}

const useStyles = makeStyles<Theme>((theme) => ({
    dialogContent: {
        padding: '20px'
    },
    actionButtonContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            ['& button']: {
                width: '100%'
            }
        }
    }
}));


export function UnsavedSectionWarning(props: UnsavedSectionWarningProps) {
    const classes = useStyles()
    
    return (
        <UiDialog
            customContentStyle={classes.dialogContent}
            open={props.open}
            handleClose={() => props.handleModalAction('close')}
            title=''
            size={props.size ? props.size : 'sm'}
            hideTitleSection={true}
        >
            <div data-cy="unsave-changes-warning">
                <UiText variant='hatchback_125' weight='semi_bold_600'>
                    You have unsaved changes
                </UiText>
                <Box mt={1}>
                    <UiText variant='car_100' weight='regular_400'>
                        Navigating away without saving will lose any changes you
                        have made.
                    </UiText>
                </Box>
                <div className={classes.actionButtonContainer}>
                <Box mt={5}>
                    <UiButton
                        btnType='tertiary'
                        label='Save Changes'
                        handleClick={() => props.handleModalAction('save')}
                    />
                </Box>
                <Box mt={3}>
                    <UiButton
                        btnType='secondary'
                        label='Discard Changes'
                        handleClick={() => props.handleModalAction('discard')}
                    />
                </Box>
                <Box mt={4}>
                    <UiButton
                        btnType='hyperlink'
                        label='Keep Editing'
                        handleClick={() => props.handleModalAction('keep')}
                    />
                </Box>
                </div>
            </div>
        </UiDialog>
    );
}
