import {
    Divider,
    makeStyles,
    Theme,
    Box,
    Button,
    Grid,
    Switch,
    Chip
} from '@material-ui/core';

import { useCallback, useEffect, useState } from 'react';

import { COLORS } from '../../../variables/colors';
import UiDialog from '../../common/ui/UiDialog';
import { AppData, ApplicationStore } from '../../../models';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';
import { ArrowForward } from '@material-ui/icons';
import MassCategorizationRules from './MassCategorizationRules';
import { patchResource } from '../../../services/axiosService';
import { loadPostAuthData } from '../../../store/actions/appData';
import { saveBusinessDetails } from '../../../services/apiService';
import { getFromLocalStorage, setInLocalStorage } from '../../../services/localStorageService';
import { getCategoryRuleCount } from '../../../services/transactionsService';
import UiText from '../../common/ui/UiText';
import { showError } from '../../../services/formService';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface Props {
    open: boolean;
    appData: AppData;
    loadPostAuthData: any;
    triggerAutoCategorization:any;
    handleClose: () => void;
    handleSuccess?: () => void;
}
const CATEGORY_CONFIRMATION_REMINDER = 'category_confirmation_reminder';
const AUTO_CATEGORIZATION_ALERT = 'auto_categorization_alert';
const UNABLE_TO_UPDATE_ERROR = 'Unable to update the settings. ';

function SettingsModal(props: Props) {
    const { colorTheme } = useThemeContext()
    const catSettingNewTagDisplayName = 'catSettingNewTagDisplayName';
    const useClasses = makeStyles((theme: Theme) => ({
        dividerCls: {
            width: '100%',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4)
        },
        featureChip : {
            backgroundColor: colorTheme.cyan100,
            borderRadius: '4px'
        },
        toggleBg: {
            '& .MuiSwitch-colorPrimary.Mui-checked': {
                color: colorTheme.primary,
            },
            '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
                backgroundColor: colorTheme.primary,
            }
        },
        modalDesc1: {
            width: '100%',
            marginBottom: theme.spacing(4)
        },
        modalDesc2: {
            marginTop: theme.spacing(4)
        }
    }));
    const classes = useClasses();
    const [isLoading, setIsLoading] = useState(false);
    const [isNewTagDisplay, setIsNewTagDisplay] = useState(false);
    const [openRuleSetting, setOpenRuleSetting] = useState(false);
    const [rulesCount, setRulesCount] = useState<number>(0);

    const [alertBeforeCategorize, setAlertBeforeCategorize] = useState<boolean>(false);
    const [enableSmartCategorize, setEnableSmartCategorize] = useState<boolean>(false);
    const [askSimilarToCategorize, setAskSimilarToCategorize] = useState<boolean>(false);

    const [isUpdatingFlags, setIsUpdatingFlags] = useState<boolean>(false);
    
    const { user, current_business, current_business_id, current_account_id } = props?.appData;

    const setSettingsFromProps = useCallback(() => {
        setAlertBeforeCategorize(user.category_confirmation_reminder);
        setEnableSmartCategorize(current_business.enable_auto_categorization as boolean);
        setAskSimilarToCategorize(user.auto_categorization_alert);
    },[current_business.enable_auto_categorization, user.auto_categorization_alert, user.category_confirmation_reminder])
 
    useEffect(() => {
        setSettingsFromProps();
        if(!getFromLocalStorage(catSettingNewTagDisplayName)) {
            setIsNewTagDisplay(true);
            setInLocalStorage(catSettingNewTagDisplayName, true);
        }
        else {
            setIsNewTagDisplay(false);
        }
        setIsLoading(true);
        getCategoryRuleCount(current_account_id, current_business_id).then((res)=>{
            setIsLoading(false);
            if(res && res.count) {
                setRulesCount(res.count);
            }
        }).catch((e)=>{
            setIsLoading(false);
        });
    }, [current_account_id, current_business_id, setSettingsFromProps]);

    const handleSettings = (type:string ,flag:boolean) => {
        
        const data: any = {};
        data[type] = flag;
        setIsUpdatingFlags(true);
        if(type === CATEGORY_CONFIRMATION_REMINDER || type === AUTO_CATEGORIZATION_ALERT) {

            type === CATEGORY_CONFIRMATION_REMINDER ? setAlertBeforeCategorize(flag): setAskSimilarToCategorize(flag);
            
            patchResource ('api/user/current', data)
            .then(() => {
                props.loadPostAuthData().then(() => {
                    setIsUpdatingFlags(false);
                });
            }).catch((e)=>{
                showError(UNABLE_TO_UPDATE_ERROR +" "+e.statusText);
                setSettingsFromProps();
                setIsUpdatingFlags(false);
            });
        }
        else {
            setEnableSmartCategorize(flag);
            const businessData = {...current_business, enable_auto_categorization:flag};
            saveBusinessDetails(businessData, current_account_id, current_business_id as string )
            .then((res)=>{
                setInLocalStorage('smart_category_confirm_modal_display', 3);
                if(flag) {
                    props.triggerAutoCategorization();
                }
                props.loadPostAuthData(current_account_id).then(() => {
                    setIsUpdatingFlags(false);
                });
            })
            .catch((e)=>{
                showError(UNABLE_TO_UPDATE_ERROR +" "+ e.statusText);
                setSettingsFromProps();
                setIsUpdatingFlags(false);
            })
        }
    }
   

   return (
    <>
        <UiDialog
            open={props.open && !openRuleSetting}
            handleClose={props.handleClose}
            title='Categorization Settings'
            size='sm'
        >
        
                <div>                            
                    <Grid container>
                        <Grid item xs={10}>
                            <UiText>
                                Ask Me Before Categorizing
                            </UiText>
                            <UiText variant='motorcycle_90'  textColor='textSecondary'>
                                You'll be asked to confirm before categorizing a transaction.
                            </UiText>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container                            
                                spacing={1}
                                direction='row'
                                alignItems='center'
                                justify='flex-end'>
                                <Switch
                                    disabled={isUpdatingFlags}
                                    className={classes.toggleBg}
                                    checked={alertBeforeCategorize}
                                    onChange={(e)=>handleSettings('category_confirmation_reminder',e.target.checked)}
                                    color='primary'
                                    size="small" />
                            </Grid>
                        </Grid>
                        
                        <Divider className={classes.dividerCls} variant='fullWidth'></Divider>
                        
                        <Grid item xs={10}>
                            <UiText>
                                { isNewTagDisplay ? (
                                    <>
                                        <Chip size='small'
                                            className={classes.featureChip}
                                            label={
                                                    <UiText variant="moped_75"  
                                                    weight="semi_bold_600">
                                                        New
                                                    </UiText>
                                                }
                                            variant='default'
                                        /><br/>
                                    </>)
                                    : ''
                                }
                                Smart-Categorize
                            </UiText>
                            <div className={classes.modalDesc1}>
                                <UiText variant='motorcycle_90'  textColor='textSecondary'>
                                    Transactions will automatically be categorized when a suggested category has a high likelihood of accuracy.
                                </UiText>
                            </div>
                            <UiText className={classes.modalDesc2} variant='motorcycle_90'  textColor='textSecondary'>
                                When a transaction would be categorized by a custom rule, that rule will take priority over Smart-Categorize.
                            </UiText>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container
                                    spacing={1}
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'>
                                <Switch 
                                    disabled={isUpdatingFlags}
                                    className={classes.toggleBg} 
                                    checked={enableSmartCategorize} 
                                    onChange={(e)=>handleSettings('enable_auto_categorization',e.target.checked)} 
                                    color='primary' 
                                    size="small" />
                            </Grid>
                        </Grid>
                        <Divider className={classes.dividerCls} variant='fullWidth'></Divider>
                        

                        <Grid item xs={10}>
                            <UiText>
                            Ask to Categorize Similar Incoming Transactions
                            </UiText>
                            <UiText variant='motorcycle_90'  textColor='textSecondary'>
                                When choosing one of the suggested categories for a transaction, you’ll be asked if you want to automatically assign similar incoming transactions to the same category.
                            </UiText>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container
                                    spacing={1}
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'>
                                <Switch 
                                    disabled={isUpdatingFlags}
                                    className={classes.toggleBg} 
                                    checked={askSimilarToCategorize} 
                                    onChange={(e)=>handleSettings('auto_categorization_alert',e.target.checked)} 
                                    color='primary' 
                                    size="small" />
                            </Grid>
                        </Grid>
                        <Box width={'100%'} my={4}>
                            {isLoading? <Loader/> : 
                                <Button
                                    color='secondary'
                                    onClick={()=>{
                                        setOpenRuleSetting(true);
                                    }}
                                    variant='outlined'>
                                        View {rulesCount} Custom Rules <ArrowForward/>
                                </Button>
                            }
                        </Box>
                    </Grid>
                </div>
            
        </UiDialog>
        <MassCategorizationRules
            isOpen={openRuleSetting}
            handleClose={() => {
                setOpenRuleSetting(false);
            }}
        />
    </>
    );
}

// export default SettingsModal;
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps,{ loadPostAuthData })(SettingsModal);
