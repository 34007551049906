import Icon from '../../common/Icon';
import { InvoiceStatistics } from '../../../models';
import { makeStyles, Theme } from '@material-ui/core';
import { mergeStyle, commonStyles } from '../../../styles/commonStyles';
import Loader from '../../../components/common/Loader';
import UiText from '../../common/ui/UiText';
import { currencyFormatter } from '../../../utils/appUtil';

const useStyles = makeStyles((theme: Theme) => {
    return {
        marginLeft: {
            marginLeft: '5px',
        },
        displayFlex: {
            display: 'flex',
        },
        divMargin: {
            marginLeft: '15px',
        },
        invoiceTotal: {
            fontWeight: 'bold',
            marginBottom: '10px',
            fontSize: '18px',
            marginLeft: '5px',
        },
        infoWidth: {
            width: '70%',
        },
    };
});
const InvoiceStats = ({
    invoiceStats,
}: {
    invoiceStats: InvoiceStatistics;
}) => {
    const styles = useStyles();
    const common = commonStyles();
    const { overdueCount, unpaidCount, unpaidTotal } = invoiceStats;

    const renderInfo = (icon: any, amount: number, text: string) => {
        return (
            amount !== 0 && (
                <div className={common.flex}>
                    <Icon icon={icon} />
                    <span className={styles.marginLeft}>
                        {amount} {text}{' '}
                    </span>
                </div>
            )
        );
    };
    return (
        <>
            {unpaidTotal && (
                <div className={styles.displayFlex}>
                    <span className={styles.invoiceTotal}>
                        {currencyFormatter.format(parseFloat(unpaidTotal))}
                    </span>
                    <span className={styles.marginLeft}>Unpaid Total</span>
                </div>
            )}
            <div
                className={mergeStyle(
                    styles.infoWidth,
                    common.flex,
                    common.spaceBetween
                )}
            >
                {renderInfo('clock', unpaidCount, 'Unpaid')}
                <UiText className={styles.marginLeft}>
                    {renderInfo('hourglass', overdueCount, 'Overdue')}
                </UiText>
            </div>
        </>
    );
};

export default InvoiceStats;
