import { useState, useEffect, useCallback } from 'react';
import ContentLoader from 'react-content-loader'
import { Box, makeStyles, Theme, FormLabel } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import UiText from './ui/UiText'
import Icon from '../common/Icon'
import { useDispatch } from 'react-redux'
import { useCurrentStore } from './hooks/useCurrentStore'
import { mergeStyle } from '../../styles/commonStyles'
import { previewUrl } from '../../services/apiService/business'
import { arraybufferToUri } from '../../utils/utility'
import { documentFilter } from '../../services/uploadService'
import {
    uploadBusinessLogo,
    saveBusinessDetails,
} from '../../services/apiService/business'
import { loadPostAuthData } from '../../store/actions/appData'
import { FullHeight } from '../../components/common/StyledComponents'
import { useThemeContext } from './whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../styles/models/Colors.interface'

const UseStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    logoContainer: {
        cursor: 'pointer',
    },
    logoText: {
        paddingTop: '10px',
        color: (colorTheme) => colorTheme.blue600,
        display: 'flex',
    },
    parentBorder: {
        border: (colorTheme) => `1px dashed ${colorTheme.grey200}`,
    },
    parentContainer: {
        textAlign: 'center',
        borderRadius: '8px',
        cursor: 'pointer',
        marginLeft: '8px',
        marginRight: '8px',
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageStyle: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    withImage: {
        height: 'auto',
        padding: '16px',
    },
}))

const MAX_FILE_SIZE = 5242880
const acceptedFormat = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
]

export const BusinessLogo = ({
    customParentStyle,
}: {
    customParentStyle?: any
}) => {
    const { colorTheme } = useThemeContext()
    const styles = UseStyles(colorTheme)
    const dispatch = useDispatch()
    const { currentBusiness, currentAccountId, currentBusinessId } =
        useCurrentStore()
    const [logoImage, setLogoImage] = useState<string | null>(null)
    const [uploadError, setUploadError] = useState<string>('')
    const [uploadedFile, setUploadFile] = useState<any>()
    const [path, setPath] = useState('')
    const [fetchInProgress, setFetchInProgress] = useState(false)

    const MyLoader = () => (
        <ContentLoader
            speed={0.5}
            width={130}
            height={120}
            viewBox="0 0 130 120"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="12" y="-30" rx="0" ry="0" width="136" height="211" />
        </ContentLoader>
    )

    const updateBusinessDetails = useCallback(
        (logoURL: string) => {
            let businessData = { ...currentBusiness, logo: logoURL }
            saveBusinessDetails(
                businessData,
                currentAccountId,
                currentBusinessId as string
            ).then((data) => {
                if (data) {
                    dispatch(loadPostAuthData(currentAccountId))
                }
            })
        },
        [currentAccountId, currentBusiness, currentBusinessId, dispatch]
    )

    const UploadLogo = useCallback(
        (uploadedFile: File) => {
            uploadBusinessLogo(uploadedFile, currentAccountId).then(
                ({ data }) => {
                    if (data) {
                        updateBusinessDetails(data?.id)
                    }
                }
            )
        },
        [currentAccountId, updateBusinessDetails]
    )

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 1) {
                setUploadError('Please upload only one file.')
            } else {
                let check: any = documentFilter(
                    acceptedFiles?.[0],
                    MAX_FILE_SIZE
                )
                if (check === true) {
                    setPath(URL.createObjectURL(acceptedFiles?.[0]))
                    setUploadFile(acceptedFiles?.[0])
                    UploadLogo(acceptedFiles?.[0])
                } else {
                    setUploadError(check)
                }
            }
        },
        [UploadLogo]
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    })

    useEffect(() => {
        if (!logoImage && currentBusiness?.logo) {
            setFetchInProgress(true)
            previewUrl(currentAccountId, currentBusiness?.logo, 'logo').then(
                (response) => {
                    if (response?.status === 200) {
                        const imageuri = arraybufferToUri(response)
                        setLogoImage(imageuri)
                        setFetchInProgress(false)
                    }
                }
            )
        }
    }, [currentAccountId, currentBusiness?.logo, logoImage])

    if (fetchInProgress) {
        return MyLoader()
    }

    const parentContainerStyles =
        logoImage || uploadedFile
            ? mergeStyle(styles.parentContainer, styles.withImage)
            : mergeStyle(styles.parentContainer, styles.parentBorder)

    return (
        <div
            className={
                customParentStyle ? customParentStyle : parentContainerStyles
            }
        >
            {logoImage || uploadedFile ? (
                <img
                    src={(logoImage as string) || (path as string)}
                    className={styles.imageStyle}
                    alt="logo"
                />
            ) : (
                <div {...getRootProps({})}>
                    <FullHeight>
                        <UiText
                            variant="motorcycle_90"
                            weight="semi_bold_600"
                            className={styles.logoText}
                        >
                            <Icon icon="upload" /> Upload logo
                        </UiText>
                    </FullHeight>
                    
                    <Box my={3}>
                        <input
                            {...getInputProps()}
                            name="files[]"
                            accept={acceptedFormat.toString()}
                        />
                    </Box>
                </div>
            )}
            {uploadError && <FormLabel error={true}>{uploadError}</FormLabel>}
        </div>
    )
}
