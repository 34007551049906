import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    makeStyles,
    Theme,
    ListItemText,
} from '@material-ui/core'
import { Trip } from '../../../models'
import ListElement from '../../common/ListElement'
import { longDateFormat } from '../../../utils/dateUtil'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import useDeviceSize from '../../../hooks/useDeviceSize'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { Purpose } from '../../../models/purposes'
import { useFiltersContext } from './Provider/FiltersProvider'
import { fixedTo2Decimal } from './TripUtil'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        emptyDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        rightItemText: {
            textTransform: 'capitalize',
            textAlign: 'right',
        },
        listItemIconRootStyle: {
            minWidth: '2.5rem',
        },
        listItemIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '0.375rem',
        },
        listRootStyles: {
            minWidth: '1.875rem',
            marginLeft: '0.5rem',
        },
        defaultStatusBackground: {
            backgroundColor: colorTheme => colorTheme.grey100,
        },
        paidChipColor: {
            backgroundColor: colorTheme => colorTheme.teal200,
        },
        overDueChipColor: {
            backgroundColor: colorTheme => colorTheme.yellow100,
        },
    }
})

export default function TripItem({
    trip,
    tripId,
    selectedTrip,
    setSelectedTrip,
    lastItem,
    calledFromPurposes,
    selectedPurpose
}: Readonly<{
    trip: Trip
    tripId: string
    selectedTrip: Trip | undefined
    setSelectedTrip: React.Dispatch<React.SetStateAction<Trip | undefined>>
    lastItem: boolean
    calledFromPurposes?:boolean
    selectedPurpose?:Purpose
}>) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const history = useHistory()
    const formattedDate = longDateFormat(trip.date);
    const licenseNumber = trip.vehicle_detail.license_plate_number ? '('+trip.vehicle_detail.license_plate_number+')' : '';
    const vehicleInfo = `${trip.vehicle_detail.make} ${trip.vehicle_detail.model} ${trip.vehicle_detail.year} ${licenseNumber}`;
    const { isMobileDevice } = useDeviceSize()
    const { setQueryObject } = useFiltersContext();
    return (
        <div>
            <ListElement
                handleItemClick={() => {
                    if (calledFromPurposes){
                        setQueryObject((prev:any) => ({...prev, purpose: selectedPurpose}));
                        history.push(`/mileage/trips`)
                    } else {
                        setSelectedTrip(trip)
                        if (isMobileDevice) {
                           history.push(`/mileage/trips/${tripId}`)
                        }
                    }
                }}
                isItemSelected={tripId === selectedTrip?.id}
                primaryNode={
                    <ListItemText
                        primary={`${formattedDate} · #${trip.number}`}
                        secondary={vehicleInfo}
                    />
                }
                secondaryNode={
                    <ListItemText
                        className={styles.rightItemText}
                        primary={`${fixedTo2Decimal(trip.miles)} Mi`}
                        secondary={trip.activity}
                    />
                }
                lastItem={lastItem}
            />
        </div>
    )
}
