import store from '../../../store'
import {
    createContact,
    updateContact,
    deleteContact,
} from '../../../services/apiService/contacts'
import { showAlert } from '../../../store/actions/feedback'
import { Contact } from '../../../models'
import { formSubmitErrorHandler } from '../../../services/formService'

const postAlertHandling = (type: 'success' | 'error', text: string) => {
    store.dispatch(
        showAlert({
            alertText: text,
            alertType: type,
        })
    )
}

export const handleCreateContact = (
    params: any,
    actions: any,
    callback: (params: any) => void
) => {
    formSubmitErrorHandler(
        createContact(params).then((contact) => {
            if (contact) {
                callback?.(contact)
                postAlertHandling('success', 'Contact created successfully')
            }
        }),
        () => {
            actions.setFormSubmitted(false)
        },
        actions.setFieldError
    )
}

export const handleUpdateContact = (
    contactId: string,
    params: any,
    actions: any,
    callback: (contact: Contact) => void
) => {
    formSubmitErrorHandler(
        updateContact(contactId, params).then((res) => {
            if (res) {
                callback(res as Contact)
                postAlertHandling('success', 'Contact updated successfully')
            }
        }),
        () => {
            actions.setFormSubmitted(false)
        },
        actions.setFieldError
    )
}

export const handleDeleteContact = (
    contactId: string,
    callback: () => void
) => {
    formSubmitErrorHandler(
        deleteContact(contactId).then((contact) => {
            callback?.()
            postAlertHandling('success', 'Contact deleted successfully')
        })
    )
}