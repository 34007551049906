import {
    Avatar,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { ApplicationStore, Config, BusinessOwner } from '../../models';
import { ThemeColors } from '../../styles/models/Colors.interface';
import { COLORS } from '../../variables/colors';
import UiTextView from '../common/ui/UiTextView';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

interface OwnerCardProps {
    owner: BusinessOwner;
    lastItem: boolean;
    onBusinessPage?: boolean;
    config: Config;
}

const OwnerCard = function ({
    config: { apiConfig: config },
    owner,
    lastItem,
    onBusinessPage = true,
}: OwnerCardProps) {
    const theme= useThemeContext()
    const colorTheme: ThemeColors = theme.colorTheme
    const { first_name, last_name, ssn, address, ownership } = owner;
    const { city, state, zip_code, county, country } = (address ? address : {city: "", state: "", zip_code: "", county: "", country: ""});
    const styles = makeStyles((theme: Theme) => ({
        ownerName: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: -5,
            textTransform: 'capitalize',
        },
        ownerSsn: {
            display: 'flex',
        },
        ownerListItem: {
            paddingBottom: 0,
        },
        listItemAvatar: {
            minWidth: 40,
        },
        listItemText: {
            paddingLeft: theme.spacing(2),
            marginBottom: 0,
        },
        ownerDetailsContainer: {
            marginBottom: 14,
        },
        ownerAddress: {
            textTransform: 'capitalize',
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
            color: colorTheme.primaryWhite
        },
    }))();
    const avatar = `${owner?.first_name?.charAt(0)}${owner?.last_name?.charAt(
        0
    )}`;

    const getCountryName = (countryId: string): string | null => {
        const country = config.generic.countries.find(
            (item) => item.iso_abbr === countryId
        );

        return country ? country.name : null;
    };
    return (
        <ListItem
            alignItems='flex-start'
            classes={{ root: styles.ownerListItem }}
            disableGutters
        >
            <ListItemAvatar classes={{ root: styles.listItemAvatar }}>
                <Avatar className={styles.avatar} alt={first_name}>
                    {avatar}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                secondaryTypographyProps={{ component: 'div' }}
                classes={{ root: styles.listItemText }}
                primary={
                    <div className={styles.ownerName}>
                        {`${first_name} ${last_name}`}
                        {ownership && !onBusinessPage ? (
                            <Typography
                                variant='subtitle2'
                                color='textSecondary'
                            >
                                {ownership}%
                            </Typography>
                        ) : (
                            ''
                        )}
                    </div>
                }
                secondary={
                    <>
                        <Typography
                            color='textSecondary'
                            component='div'
                            classes={
                                !lastItem
                                    ? { root: styles.ownerDetailsContainer }
                                    : {}
                            }
                        >
                            <Typography variant='subtitle1' gutterBottom>
                                {owner.ownership
                                    ? `${owner.ownership}% Ownership`
                                    : owner.ownership
                                    ? `${owner.ownership} shares`
                                    : '-- shares'}
                            </Typography>
                            <Typography
                                variant='subtitle2'
                                className={styles.ownerSsn}
                                gutterBottom
                            >
                                {onBusinessPage && 'SSN:'}&nbsp;
                                <UiTextView
                                    type='ssn'
                                    value={ssn}
                                    hideSSN={true}
                                    variant='body2'
                                />
                            </Typography>
                            <Typography
                                variant='body2'
                                classes={{ root: styles.ownerAddress }}
                            >
                                {address?.address ? `${address.address}, ` : ''}
                                {city ? `${city}, ` : ''}
                                {county ? `${county}, ` : ''}
                                {state ? `${state}, ` : ''}
                                {zip_code ? `${zip_code}, ` : ''}
                                {country ? `${getCountryName(country)}.` : ''}
                                {!address?.address &&
                                    !city &&
                                    !state &&
                                    !zip_code &&
                                    !country &&
                                    'n/a'}
                            </Typography>
                        </Typography>
                        {!lastItem && <Divider />}
                    </>
                }
            />
        </ListItem>
    );
};
const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
});
export default connect(mapStateToProps)(OwnerCard);
