import store from '../../../store'
import {
    createLocation,
    updateLocation,
    deleteLocation,
} from '../../../services/apiService/locations'
import { showAlert } from '../../../store/actions/feedback'
import { Location } from '../../../models/locations'
import { showError } from '../../../services/formService';

enum alertType {
    SUCCESS = 'success',
    ERROR   = 'error',
}

const postAlertHandling = (type: alertType.SUCCESS | alertType.ERROR, text: string) => {
    store.dispatch(
        showAlert({
            alertText: text,
            alertType: type,
        })
    )
}

export const handleCreateLocation = (
    params: any,
    callback: (params: any) => void
) => {
    createLocation(params).then((location) => {
        if (location) {
            callback?.(location)
            postAlertHandling(alertType.SUCCESS, 'Location created successfully')
        }
    }).catch((err) => {
        callback?.(null)
        showError(err?.statusText)
    })
}

export const handleUpdateLocation = (
    locationId: string,
    params: any,
    callback: (location: Location | null) => void
) => {
    updateLocation(locationId, params).then((res) => {
        if (res) {
            callback(res as Location)
            postAlertHandling(alertType.SUCCESS, 'Location updated successfully')
        }
    }).catch((err) => {
        callback?.(null)
        showError(err?.statusText)
    })
}

export const handleDeleteLocation = (
    locationId: string,
    callback: (isError: boolean) => void
) => {
    deleteLocation(locationId).then((location) => {
        callback?.(false)
        postAlertHandling(alertType.SUCCESS, 'Location deleted successfully')
    }).catch((err) => {
        callback?.(true)
        showError(err?.statusText)
    })
}