import {
    Divider,
    ListItem,
    ListItemText,
    ListItemAvatar,
    makeStyles,
    Theme,
} from '@material-ui/core'
import Icon from '../../../../components/common/Icon'
import UiText from '../../../common/ui/UiText'

import type { Trip } from '../../../../models'

type PropsType = {
    trip: Trip
}

const useStyles = makeStyles((theme: Theme) => ({
    tripItemText: {
        lineHeight: 1.5,
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    mt2: {
        marginTop: theme.spacing(2),
    },
    my1: {
        marginTop: '1rem',
        marginBottom: '1rem',
    } 
}))

const Details = ({ trip }: PropsType) => {
    const styles = useStyles()

    const locationFrom =
        trip.embedded_location_from ?? trip.location_from_detail
    const locationTo = trip.embedded_location_to ?? trip.location_to_detail

    const addressFrom = {
        name: locationFrom?.name ?? '',
        street: locationFrom?.address.address ?? '',
        city: locationFrom?.address.city ?? '',
        state: locationFrom?.address.state ?? '',
        zip: locationFrom?.address.zip_code ?? '',
    }

    const addressTo = {
        name: locationTo?.name ?? '',
        street: locationTo?.address.address ?? '',
        city: locationTo?.address.city ?? '',
        state: locationTo?.address.state ?? '',
        zip: locationTo?.address.zip_code ?? '',
    }

    let vehicleStr = `${trip.vehicle_detail.make} ${trip.vehicle_detail.model} ${trip.vehicle_detail.year}`
    if (trip.vehicle_detail.license_plate_number) {
        vehicleStr = vehicleStr.concat(` (${trip.vehicle_detail.license_plate_number})`)
    }

    return (
        <>
            <UiText variant="suv_150" weight='medium_500' className={styles.my1}>
                Details
            </UiText>
            {/* Start Location */}
            <ListItem>
                <ListItemAvatar>
                    <Icon icon="location" size="1.5rem" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <UiText
                            textColor="textSecondary"
                            className={styles.tripItemText}
                        >
                            Start Location
                        </UiText>
                    }
                    secondary={
                        <UiText
                            textColor="textPrimary"
                            className={styles.tripItemText}
                        >
                            {addressFrom.name} <br />
                            {`${addressFrom.street} ${addressFrom.city} ${addressFrom.state} ${addressFrom.zip}`}
                        </UiText>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" />
            {/* Destination */}
            <ListItem>
                <ListItemAvatar>
                    <Icon icon="location" size="1.5rem" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <UiText
                            textColor="textSecondary"
                            className={styles.tripItemText}
                        >
                            Destination
                        </UiText>
                    }
                    secondary={
                        <UiText
                            textColor="textPrimary"
                            className={styles.tripItemText}
                        >
                            {addressTo.name} <br />
                            {`${addressTo.street} ${addressTo.city} ${addressTo.state} ${addressTo.zip}`}
                        </UiText>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" />
            {/* Vehicle */}
            <ListItem>
                <ListItemAvatar>
                    <Icon icon="vehicle" size="1.5rem" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <UiText
                            textColor="textSecondary"
                            className={styles.tripItemText}
                        >
                            Vehicle
                        </UiText>
                    }
                    secondary={
                        <UiText
                            textColor="textPrimary"
                            className={styles.tripItemText}
                        >
                            {vehicleStr}
                        </UiText>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" />
            {/* Purpose */}
            <ListItem>
                <ListItemAvatar>
                    <Icon icon="purpose" size="1.5rem" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <UiText
                            textColor="textSecondary"
                            className={styles.tripItemText}
                        >
                            Purpose
                        </UiText>
                    }
                    secondary={
                        <UiText
                            textColor="textPrimary"
                            className={styles.tripItemText}
                        >
                            {trip.purpose_name}
                        </UiText>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" />
            {/* Description */}
            <ListItem>
                <ListItemAvatar>
                    <Icon icon="note" size="1.5rem" />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <UiText
                            textColor="textSecondary"
                            className={styles.tripItemText}
                        >
                            Description
                        </UiText>
                    }
                    secondary={
                        <UiText
                            textColor="textPrimary"
                            className={styles.tripItemText}
                        >
                            {trip.description
                                ? trip.description
                                : 'No additional notes'}
                        </UiText>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" />
        </>
    )
}

export default Details
