import store from '../../../store'
import {
    createPurpose,
    updatePurpose,
    deletePurpose
} from '../../../services/apiService/purposes'
import { showAlert } from '../../../store/actions/feedback'
import { showError } from '../../../services/formService';
import { Purpose } from '../../../models/purposes'

enum alertType {
    SUCCESS = 'success',
    ERROR   = 'error',
}

const successMessage = (text: string) => {
    store.dispatch(
        showAlert({
            alertText: text,
            alertType: alertType.SUCCESS,
        })
    )
}

export const handleCreatePurpose = (
    params: any,
    callback: (purpose: Purpose | null) => void
) => {
    createPurpose(params).then((purpose) => {
        if (purpose) {
            callback?.(purpose as Purpose)
            successMessage('Purpose created successfully')
        }
    }).catch((err) => {
        callback?.(null)
        showError(err?.statusText + ' ' + err?.errors?.name?.[0])
    })
}

export const handleUpdatePurpose = (
    purposeId: string,
    params: any,
    callback: (purpose: Purpose | null) => void
) => {
    updatePurpose(purposeId, params).then((res) => {
        if (res) {
            callback(res as Purpose)
            successMessage('Purpose updated successfully')
        }
    }).catch((err) => {
        callback?.(null)
        showError(err?.statusText  + ' ' + err?.errors?.name?.[0])
    })
}

export const handleDeletePurpose = (
    purposeId: string,
    callback: (isError: boolean) => void
) => {
    deletePurpose(purposeId).then((res) => {
        callback?.(false)
        successMessage('Purpose deleted successfully')
    }).catch((err) => {
        callback?.(true)
        showError(err?.statusText)
    })
}