import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { ChatBubbleOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import AvatarImg from "../../../assets/icons-svg/Avatar.svg";
import { ApplicationStore } from '../../../models';
import UiSection from '../../common/ui/UiSection';

interface EntitySpecialistSectionProps {
    entitySpecialist: {
        first_name: string
        last_name: string
        photo_link: string;
        version: string;
    }
}

function EntitySpecialistSection({ entitySpecialist }: EntitySpecialistSectionProps) {
    if (!entitySpecialist) {
        return null;
    }
    const { first_name, last_name, photo_link, version } = entitySpecialist;

    return (
        <UiSection expanded={false}>
            <List disablePadding>
                <ListItem disableGutters dense>
                    <ListItemAvatar>
                        <Avatar
                            alt={`${first_name} ${last_name}`}
                            src={`${photo_link + (version || '')}` || AvatarImg}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${first_name} ${last_name}`}
                        secondary="Entity Specialist"
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="conversation">
                            <ChatBubbleOutline />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </UiSection>
    )
}

const mapStateToProps = ({ appData }: ApplicationStore) => ({
    entitySpecialist: appData.personal_account.entity_specialist
});
export default connect(mapStateToProps)(EntitySpecialistSection);
