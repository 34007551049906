import { useEffect, useState } from 'react'
import { getAllNotifications } from '../../../services/apiService/common';
import { showError } from '../../../services/formService';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import { NotificationData } from '../ActivityFeed/ActivityFeed.model';

export const useAllActivityFeedNotifications = () =>{
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [notificationsData, setNotificationsData] = useState<NotificationData[]>();
    const storeData = useCurrentStore();
    const { currentAccountId } = storeData;

    useEffect(() => {
        getAllNotifications(currentAccountId)
            .then((data: unknown) => {
                setNotificationsData(data as NotificationData[])
                setIsDataLoading(false)
            })
            .catch((error) => {
                showError(error?.statusText)
                setIsDataLoading(false)
            })
    }, [currentAccountId])
    return {
        isDataLoading,
        notificationsData
    }
}