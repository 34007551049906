import React from 'react'
interface Color {
    color: string
}
export const LeftArrowIcon = (props: Color) => {
    const { color } = props
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.07584 12.3828C4.12353 12.4981 4.1937 12.6062 4.28635 12.7006C4.28878 12.703 4.29122 12.7055 4.29368 12.7079L11.2929 19.7071C11.6834 20.0976 12.3165 20.0976 12.7071 19.7071C13.0976 19.3166 13.0976 18.6834 12.7071 18.2929L7.41417 13L19 13C19.5522 13 20 12.5523 20 12C20 11.4477 19.5522 11 19 11L7.41417 11L12.7071 5.70711C13.0976 5.31658 13.0976 4.68342 12.7071 4.29289C12.3165 3.90237 11.6834 3.90237 11.2929 4.29289L4.29285 11.2929C4.09994 11.4858 4.00232 11.7379 4 11.9908M4.07584 12.3828C4.02805 12.2676 4.0012 12.1415 4 12.0092L4.07584 12.3828Z"
                fill={color}
            />
        </svg>
    )
}