export const SET_LOCAL_CONFIG = 'SET_LOCAL_CONFIG';
export interface EventAction {
    type: string;
    state: any
}
export type Action = EventAction;

export const setFeatureData = (feature: any ): EventAction => ({
    type: SET_LOCAL_CONFIG,
    state: feature
});
export function loadLocalConfig() {
    return (dispatch: any, data: any) => {
        dispatch(setFeatureData(data));
    };
}
