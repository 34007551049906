import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './components/core/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import store from './store';
import { ColorThemeProvider } from './components/common/whiteLabel/ColorThemeContext';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
    <ColorThemeProvider>
      <App />
      </ColorThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
