import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import { Business, ApplicationStore } from '../../models';
import { Box } from '@material-ui/core';
import BusinessSettingView from './BusinessSettingView';
import BusinessSettingEdit from './BusinessSettingEdit';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { getBusinessDetails } from '../../services/apiService/business';

const BusinessSetting: React.FC = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const [showEdit, setShowEdit] = useState<boolean>(
        query.get('edit') === 'true'
    );
    const [businessDetails, setBusinessDetails] =
        useState<Business | null>(null);

    const getBusinessDetailsCallback = useCallback(() => {
        const { current_account_id, current_business_id } = props.appData;
        setActiveRouteHeading('Business Details');
        getBusinessDetails(current_account_id, current_business_id).then(
            (res: Business) => {
                setBusinessDetails(res);
            }
        );
    }, [props, setActiveRouteHeading]);

    useEffect(() => {
        getBusinessDetailsCallback();
    }, [getBusinessDetailsCallback]);

    const toggleView = (refreshDetails = false) => {
        if (refreshDetails) {
            setBusinessDetails(null);
            getBusinessDetailsCallback();
        }
        setShowEdit(!showEdit);
    };

    return (
        <Box>
            {showEdit ? (
                <BusinessSettingEdit
                    data={businessDetails}
                    toggleView={toggleView}
                />
            ) : (
                <BusinessSettingView
                    data={businessDetails}
                    toggleView={toggleView}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(BusinessSetting);
// export default BusinessSetting;
