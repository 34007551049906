import { useState } from "react";
import { makeStyles, Theme, useTheme, useMediaQuery } from "@material-ui/core";
import UiDialog from "../../../components/common/ui/UiDialog";
import {
    EmpData,
    Compensation,
    EmployeeJobDetails,
} from "../../../models/payroll/employee";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import UiText from "../../../components/common/ui/UiText";
import { commonStyles } from "../../../styles/commonStyles";
//forms
import PersonalDetailsForm from "./Forms/PersonalDetailsForm";
import OwnershipForm from "./Forms/OwnershipForm";
import JobDetailsFrom from "./Forms/JobDetailsForm";
import CompensationForm from "./Forms/CompensationForm";
import NewEmployeeForm from "./Forms/NewEmployeeForm";
import { personalDetailFormMapping } from "./Forms/PersonalDetailsFormUtils";
import { jobDetailFormMapping } from "./Forms/JobDetailsFormUtils";
import { compensationFormMappings } from "./Forms/CompensationFormUtils";
import { useEmployeeState } from "../employees/EmployeeProvider";
import UnsaveWarningModal from "./Forms/UnsaveWarningModal";
import { COLORS } from '../../../variables/colors';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
interface EmployeeFormModalProps {
    open: boolean;
    handleClose: () => void;
    employeeData?: EmpData | undefined;
    currentForm?: string;
    job?: EmployeeJobDetails;
    compensation?: Compensation;
    mode?: string;
    locationData?: any;
    formSubmitCallback?: () => void;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    container: {
        "& .MuiPaper-root": {
            height: 816,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                margin: "0px",
                top: "0px",
                "& .MuiDialogTitle-root": {
                    display: "none",
                },
            },
            "& .MuiDialogContent-root": {
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 20px",
                    marginTop: "60px",
                },
                "& .top-section": {
                    "& .alert": {
                        height: "36px",
                        width: "inherit",
                        marginBottom: theme.spacing(2),
                    },
                    "& .delete-section": {
                        display: "flex",
                    },
                    [theme.breakpoints.down("sm")]: {
                        height: "100%",
                        maxHeight: "100%",
                        padding: "0px",
                    },
                },
            },
        },
    },
    dialogDetails: {
        "& .MuiDialogContent-root": {
            maxHeight: "600px",
            height: "550px",
        },
        "& .MuiPaper-root": {
            "& .MuiDialogTitle-root": {
                padding: "12px 16px ",
            },
        },
    },
    formFooterStyles: {
        height: "45px",
        position: `absolute`,
        bottom: 0,
        right: 0,
        width: "100%",
        background: (colorTheme) => colorTheme.primaryWhite,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: "0.5rem",
        "& button:nth-child(1)": {
            marginRight: "24px",
            maxWidth: 150,
        },
        "& button:nth-child(2)": {
            marginRight: "16px",
            maxWidth: 150,
        },
    },
}));

const EditableForms: any = {
    PersonalDetails: PersonalDetailsForm,
    JobDetails: JobDetailsFrom,
    CompensationDetails: CompensationForm,
};

// edit
const EmployeeFormModal = ({
    mode = "Edit",
    open,
    job,
    compensation,
    handleClose,
    employeeData,
    currentForm,
    locationData,
    formSubmitCallback,
}: EmployeeFormModalProps) => {
    const common = commonStyles();
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme);
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const { companyDetails } = useEmployeeState();
    // new mode
    const FormInputElements: any = {
        PersonalDetails: personalDetailFormMapping,
        JobDetails: jobDetailFormMapping(locationData),
        CompensationDetails: compensationFormMappings,
        OwnershipDetails: OwnershipForm,
    };

    const FormHeaders: any = {
        PersonalDetails:
            mode !== "Edit" ? "New Employee" : "Edit Personal Details",
        JobDetails:
            mode !== "Edit"
                ? "Add Job"
                : job
                ? "Edit Job Details"
                : "Create New Job",
        CompensationDetails:
            mode !== "Edit" ? "Add Compensation" : "Edit Compensation Details",
        OwnershipDetails: "Ownership Percentage",
    };

    // Editable Mode
    const FormComponent = EditableForms[currentForm as string];
    // New Mode
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const isSCorp = companyDetails?.entity_type === "S-Corporation";

    const nextPageText: any = {
        PersonalDetails: "Job",
        JobDetails: "Compensation",
        CompensationDetails: isSCorp ? "Ownership Percentage" : "Job",
        OwnershipDetails: "",
    };
    const availableForms = isSCorp
        ? [
              "PersonalDetails",
              "JobDetails",
              "CompensationDetails",
              "OwnershipDetails",
          ]
        : ["PersonalDetails", "JobDetails", "CompensationDetails"];
    const currentPage = currentForm
        ? currentForm
        : availableForms[currentIndex];

    const prevPage = () => setCurrentIndex(currentIndex - 1);
    const nextPage = () => setCurrentIndex(currentIndex + 1);

    let currentFormInputs = FormInputElements[currentPage as string];

    const modalCloseActions = () => {
        if (mode === "Edit") {
            handleClose();
        } else {
            // is touched/dirty then open warning
            // if not always check for request submitted or not
            if (isFormDirty) {
                setOpenWarning(true);
            } else {
                if (requestSubmitted) {
                    // callback on request submission and closing modal
                    formSubmitCallback?.();
                }
                handleClose();
            }
        }
    };

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            disableBackdropClick={true}
            titleNode={
                <>
                    <div className={common.flex}>
                        {currentIndex !== 0 && (
                            <IconButton onClick={prevPage}>
                                <ArrowBackIcon />
                            </IconButton>
                        )}{" "}
                        <UiText
                            style={{ marginTop: "10px", marginLeft: "5px" }}
                            variant="hatchback_125"
                            weight="medium_500"
                        >
                            {FormHeaders[currentPage as string]}
                        </UiText>{" "}
                    </div>
                    <IconButton aria-label="close" onClick={modalCloseActions}>
                        <CloseIcon />
                    </IconButton>
                </>
            }
            size="sm"
            customRootClass={isXsDown ? styles.container : styles.dialogDetails}
        >
            <>
                <UnsaveWarningModal
                    open={openWarning}
                    discardChanges={() => {
                        // close warning.
                        // check if requested and then close main modal
                        setOpenWarning(false);
                        handleClose();
                        if (requestSubmitted) {
                            // callback on request submission and closing modal
                            setCurrentIndex(0)
                            formSubmitCallback?.();
                        }
                    }}
                    keepEditing={() => {
                        setOpenWarning(false);
                    }}
                />
                {mode === "Edit" ? (
                    <FormComponent
                        employee={employeeData}
                        job={job}
                        compensation={compensation}
                        callback={() => {
                            setCurrentIndex(0)
                            formSubmitCallback?.()
                        }}
                        handleClose={handleClose}
                        formFooterStyles={styles.formFooterStyles}
                    />
                ) : (
                    <NewEmployeeForm
                        handleClose={modalCloseActions}
                        formSubmitCallback={() => {
                            setCurrentIndex(0)
                            formSubmitCallback?.()
                        }}
                        currentPage={currentPage}
                        currentPageIndex={currentIndex}
                        nextPage={nextPage}
                        CurrentFormInputs={currentFormInputs}
                        formTitle={FormHeaders[currentPage as string]}
                        setRequestSubmitted={setRequestSubmitted}
                        setIsFormDirty={setIsFormDirty}
                        formFooterStyles={styles.formFooterStyles}
                        nextPageText={nextPageText}
                        isLastPage={currentIndex === availableForms.length - 1}
                    />
                )}
            </>
        </UiDialog>
    );
};

export default EmployeeFormModal;
