import { Fragment, useState, useRef, useMemo } from 'react';
import {
    Box,
    Grow,
    Theme,
    Paper,
    Popper,
    Button,
    Divider,
    MenuItem,
    MenuList,
    makeStyles,
    ButtonGroup,
    ClickAwayListener,
} from '@material-ui/core'
import clsx from 'clsx'

//icons
import AddIcon from '@material-ui/icons/Add'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        deleteOption: {
            color: (colorTheme) => `${colorTheme.red200} !important`,
        },
        menuItemFont: {
            fontSize: '15px',
            fontWeight: 400,
        },
    }
})

const ActionButton = ({
    itemCount,
    handleClickType,
}: {
    itemCount: number
    handleClickType: (action: string) => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const [openPopup, setOpenPopup] = useState(false)
    const popoverRef = useRef<any>(null)

    const bulkActionOptions = useMemo(() => {
        return [
            {
                title: 'New Invoice',
                icon: <AddIcon />,
                showDivider: false,
                action: 'createNewInvoice',
            },
            {
                title: `Categorize ${itemCount} Items `,
                icon: <LabelOutlinedIcon />,
                showDivider: true,
                style: { paddingBottom: '4px' },
                action: 'categorize',
            },
            {
                title: `Delete ${itemCount} Items `,
                icon: <DeleteForeverIcon />,
                showDivider: false,
                style: { paddingTop: '4px' },
                deleteOption: true,
                action: 'deleteItems',
            },
        ]
    }, [itemCount])

    const handleClose = (event: any) => {
        if (popoverRef.current && popoverRef.current?.contains(event.target)) {
            return
        }
        setOpenPopup(false)
    }
    return (
        <Box mx={2}>
            <ButtonGroup
                variant="contained"
                size="small"
                color="primary"
                aria-label="split button"
                ref={popoverRef}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        handleClickType('createNewInvoice')
                    }}
                >
                    New Invoice
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setOpenPopup(!openPopup)
                    }}
                >
                    {openPopup ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Button>
            </ButtonGroup>
            <Popper
                open={openPopup}
                anchorEl={popoverRef.current}
                role={undefined}
                transition
                disablePortal
                placement={'top-end'}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'end top'
                                    : 'end bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {bulkActionOptions.map((option, index) => (
                                        <Fragment key={index}>
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => {
                                                    handleClickType(
                                                        option.action
                                                    )
                                                }}
                                                className={
                                                    option.deleteOption
                                                        ? clsx(
                                                              styles.deleteOption,
                                                              styles.menuItemFont
                                                          )
                                                        : clsx(
                                                              styles.menuItemFont
                                                          )
                                                }
                                            >
                                                {option.icon}{' '}
                                                <Box
                                                    mx={2}
                                                    style={option?.style}
                                                >
                                                    {option.title}{' '}
                                                </Box>
                                            </MenuItem>
                                            {option.showDivider && <Divider />}
                                        </Fragment>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

export default ActionButton