import React, { useState, createContext } from 'react'

import { useStyles } from '../../../styles/styles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import AdminLoginBanner from '../../core/AdminLoginBanner'
import AppDrawer from '../../core/AppDrawer'
import AppToolbar from '../../core/AppToolbar'
import AppView from '../../core/AppView'

export const ActiveRoutingContext = createContext<any>({})
export const ActiveTourStepContext = createContext<any>({})

const ActiveRoutingProvider = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [activeRouteHeading, setActiveRouteHeading] = useState('')
    const [activeRouteHeadingIcon, setActiveRouteHeadingIcon] = useState(null)
    const [activeTourStep, setActiveTourStep] = useState(-1)

    return (
        <ActiveRoutingContext.Provider
            value={{
                activeRouteHeading,
                setActiveRouteHeading,
                activeRouteHeadingIcon,
                setActiveRouteHeadingIcon,
                activeTourStep,
                setActiveTourStep,
            }}
        >
            <div className={classes.drawerAndHeaderContainer}>
                <AdminLoginBanner />
                <ActiveTourStepContext.Provider
                    value={{
                        activeTourStep,
                        setActiveTourStep,
                    }}
                >
                    <div className={classes.leftNavigationAndContent}>
                        <div className={classes.leftNavigationAndContent}>
                            <AppDrawer
                                drawerOpen={drawerOpen}
                                setDrawerOpen={setDrawerOpen}
                            />

                            <div className={classes.headerAndContent}>
                                <AppToolbar setDrawerOpen={setDrawerOpen} />
                                <AppView />
                            </div>
                        </div>
                    </div>
                </ActiveTourStepContext.Provider>
            </div>
        </ActiveRoutingContext.Provider>
    )
}

export default ActiveRoutingProvider
