import { useContext, useEffect, useMemo } from 'react'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { Typography, useMediaQuery } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import BalanceSheetReport from './BalanceSheetReport'
import IncomeStatementReport from './IncomeStatementReport'
import Invoices from './InvoiceReport'
import MileageReport from './MileageReport'
import ReconciliationReport from './ReconciliationReport'
import JournalEntries from './JournalEntries'
import GeneralLedger from './GeneralLedger'
import GeneralJournal from './GeneralJournal'
import Transactions from './TransactionsByAccount'
import { Link, useLocation } from 'react-router-dom'
import { useStyles } from '../../../styles/styles'
import { REPORTS_QUERY_PARAMS } from './constants/reports.const'
import { toRem16 } from '../../../styles/commonStyles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useTheme } from '@material-ui/core'

interface Name {
    name: string | null
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        reportsParent: {
            display: 'flex',
            flex: 1,
            padding: '0 1.5rem',
            height: '100%',
            margin: `0 ${toRem16(148.5)}`,
            '& .reportsViewRegular': {
                flex: 1,
                padding: `1rem 3.5rem 0 ${toRem16(104)}`,
            },
            '& .reportsViewLarge': {
                flex: 1,
                padding: `1rem ${toRem16(331)} 0 6.5rem`,
            },
        },
        reportsNavMenu: {
            flex: 1,
            borderRight: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: `0 ${toRem16(104)} 0 0`,
            '& ul': {
                padding: '0',
                '& li': {
                    listStyleType: 'none',
                    padding: '0.5rem 1rem',
                    cursor: 'pointer',
                    '&:not(:last-child)': {
                        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                    },
                    '& a': {
                        textDecoration: 'none',
                        display: 'inline-block',
                        width: '100%',
                        color: (colorTheme) => colorTheme.primaryBlack,
                        '& img': {
                            padding: '0 1rem 0 0',
                        },
                        '& span:hover': {
                            cursor: 'pointer',
                            background: 'transparent',
                            padding: '0',
                        },
                    },
                },
                '& li:hover': {
                    cursor: 'pointer',
                    background: (colorTheme) => colorTheme.grey900,
                },
            },
        },
        
        activeLink: {
            background: (colorTheme) => colorTheme.green500,
            borderBottom: 'none !important',
            borderRadius: '0.2rem',
        },
    })
)

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search]);
}

function SelectedView(nameParam: Name) {
    const { name } = nameParam
    if (!name || name === REPORTS_QUERY_PARAMS.BALANCE_SHEET) {
        return <BalanceSheetReport />
    }
    if (name === REPORTS_QUERY_PARAMS.INCOME_STATEMENT) {
        return <IncomeStatementReport />
    }
    if (name === REPORTS_QUERY_PARAMS.INVOICES) {
        return <Invoices />
    }
    if (name === REPORTS_QUERY_PARAMS.MILEAGE_LOG) {
        return <MileageReport />
    }
    if (name === REPORTS_QUERY_PARAMS.RECONCILIATIONS) {
        return <ReconciliationReport />
    }
    if (name === REPORTS_QUERY_PARAMS.TRANSACTIONS) {
        return <Transactions />
    }
    if (name === REPORTS_QUERY_PARAMS.JOURNAL_ENTRIES) {
        return <JournalEntries />
    }
    if (name === REPORTS_QUERY_PARAMS.GENERAL_JOURNAL) {
        return <GeneralJournal />
    }
    if (name === REPORTS_QUERY_PARAMS.GENERAL_LEDGER) {
        return <GeneralLedger />
    }
    return <BalanceSheetReport />
}
function ReportsNavMenu() {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const location = useLocation();
    const { search } = location;
    const queryParam = search.split('?name=')[1];
    const classes = useLocalStyles(colorTheme);

    const isActiveLinkClass = (param: string): boolean | void => {
        if (!queryParam) {
            return
        }
        return queryParam.indexOf(param) > -1 ?? false
    }
   
    return (
        <ul>
            <li
                className={
                    isActiveLinkClass('balanceSheet')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=balanceSheet">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Report-Doc.svg`)
                                .default
                        }
                    />
                    <span>Balance Sheet </span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('incomeStatement')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=incomeStatement">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Report-Doc.svg`)
                                .default
                        }
                    />
                    <span>Income Statement</span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('invoices') ? `${classes.activeLink}` : ''
                }
            >
                <Link to="/reports?name=invoices">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Invoice.svg`)
                                .default
                        }
                    />
                    <span>Invoices</span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('mileageLog')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=mileageLog">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Mileage.svg`)
                                .default
                        }
                    />
                    <span> Mileage Log </span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('reconciliations')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=reconciliations">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Reconciliations.svg`)
                                .default
                        }
                    />
                    <span>Reconciliations</span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('transactions')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=transactions">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Transactions-By-Account.svg`)
                                .default
                        }
                    />
                    <span>Transactions by Account</span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('journalEntries')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=journalEntries">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/EditJournalIcon.svg`)
                                .default
                        }
                    />
                    <span>Journal Entries</span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('generalJournal')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=generalJournal">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Ledger.svg`)
                                .default
                        }
                    />
                    <span>General Journal</span>
                </Link>
            </li>
            <li
                className={
                    isActiveLinkClass('generalLedger')
                        ? `${classes.activeLink}`
                        : ''
                }
            >
                <Link to="/reports?name=generalLedger">
                    <img
                        alt=""
                        src={
                            require(`../../../assets/icons-svg/Ledger.svg`)
                                .default
                        }
                    />
                    <span>General Ledger</span>
                </Link>
            </li>
        </ul>
    )
}

export default function Reports() {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    const query = useQuery()
    const globalClasses = useStyles(colorTheme)
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const isLargeDevice = useMediaQuery(theme.breakpoints.up('xl'));

    useEffect(() => {
        setActiveRouteHeading('Reports')
    })
    
    return (
        <div className={classes.reportsParent}>
            <div className={classes.reportsNavMenu}>
                <Typography className={globalClasses.pageDesc}>
                    Select a Report Type
                </Typography>

                <ReportsNavMenu />
            </div>
           
            <div className={`${isLargeDevice ? 'reportsViewLarge' : 'reportsViewRegular'}`}>
                <SelectedView name={query.get('name')} />
            </div>
        </div>
    )
}
