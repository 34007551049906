import { Box } from '@material-ui/core';
import UiText from '../../common/ui/UiText';
import { COLORS } from '../../../variables/colors';
import { HelpOutlineTwoTone } from '@material-ui/icons';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface Props {
    text: string;
    heading?: string;
}

function InfoText(props: Props) {
    const { text, heading } = props;
    const { colorTheme } = useThemeContext()
    return (
        <Box display='flex' bgcolor={colorTheme.beige100} p={3}>
            <Box mr={3}>
                <HelpOutlineTwoTone />
            </Box>
            <Box>
                {heading && (
                    <UiText weight='semi_bold_600' variant='car_100'>
                        {heading}
                    </UiText>
                )}
                <UiText weight='regular_400' variant='motorcycle_90'>
                    {text}
                </UiText>
            </Box>
        </Box>
    );
}

export default InfoText;
