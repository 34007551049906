export const PAGE_TYPE = {
    INITIAL_SECTION: 'INITIAL_SECTION',
    COMPLETED_SECTION: 'COMPLETED_SECTION',
    VERIFICATION_PENDING_SECTION: 'VERIFICATION_PENDING_SECTION',
}

export const ERROR_MESSAGE = {
    GENERIC_ERROR: 'Something went wrong. Please try again later.',
    GET_BANK_DETAILS_ERROR: 'Failed to fetch bank account, please reload the page and try again',
    GET_LINK_TOKEN_ERROR: 'Something went wrong. please reload the page and try again',
    ITEM_ERROR: 'The Item you requested cannot be found.',
    ITEM_LOGIN_REQUIRED: "the login details of this item have changed",
    BANK_RECONNECT_REQUIRED: 'You need to reauthenticate the connection between your financial institution and Plaid.',
    PLAID_AUTH_FAILED: 'Plaid authorization failed',
    UPDATE_PROCESSOR_TOKEN_ERROR: 'Failed to reconnect to the bank account, please try again later',
}

export const ERROR_MESSAGE_MAP = [
        {
            error_message : 'The ‘Business Name’ you entered in step 1 doesnt match tax-agency guidelines. Business names can only contain letters, numbers, spaces, ampersands (&) and hyphens (-). If you have any other characters – like periods or commas – in your business name, you need to remove or replace them.',
            criteria      : '^[a-zA-Z0-9 &-]+$',
            vocabulary    : 'company.clientName'
        }
];


