import React, { Fragment, useCallback } from 'react';
import {
    Hidden,
    Typography,
    IconButton,
    Box,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import imageIcon from '../../assets/icons-svg/Image.svg';
import moment from 'moment';
import {
    ArrowForwardRounded,
    CloseRounded,
    CloudDownloadOutlined,
    DeleteOutlined,
    EditOutlined,
    ErrorOutline,
    LockOutlined,
    MoreVert,
} from '@material-ui/icons';
import { Folder, UploadedFile, ApplicationStore } from '../../models';
import { useState } from 'react';
import { connect } from 'react-redux';
import { convertBytesToSize } from '../../utils/documentUtil';
import UiDialog from '../common/ui/UiDialog';
import DocumentsDefault from '../../assets/images/DocumentsDefault.png';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import DocumentRename from './DocumentRename';
import DocumentMove from './DocumentMove';
import { getFile, updateFileRead } from '../../services/apiService';
import UiConfirmationDialog from '../common/ui/UiConfirmationDialog';
import PersonalDocMove from '../entity-management/documents-tab/PersonalDocMove'
import { COLORS } from '../../variables/colors';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
function DocumentView({
    modalOpen,
    handleClearFile,
    currentFile,
    handleConfirmDelete,
    accountId,
    personalAccountId,
    reloadCurrentState,
    folders,
    accountantMode,
    resetFiles,
    setCurrentFile
}: {
    modalOpen: boolean;
    handleClearFile: () => void;
    currentFile: UploadedFile | undefined;
    handleConfirmDelete: () => void;
    accountId: string;
    personalAccountId: string;
    reloadCurrentState: () => void;
    folders: Folder[];
    accountantMode: boolean | undefined;
    resetFiles: () => void;
    setCurrentFile: React.Dispatch<UploadedFile>
}) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        defaultText: {
            color: colorTheme.grey800,
        },
        closeIcon: {
            paddingLeft: 0,
        },
        dialogFileName: {
            wordBreak: 'break-all',
        },
        menu: {
            padding: 0,
        },
    })();

    const { path } = useRouteMatch();
    const isEntityManagementTab = path.includes('/entity-management');
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [pdfFile, setPdfFile] = useState<any>();
    const [openRename, setOpenRename] = useState(false);
    const [openMove, setOpenMove] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const handleConfirmDeleteDocument = (result: boolean) => {
        setOpenDelete(false);
        if (result === true) {
            handleConfirmDelete();
        }
    };

    const handleMenuDropdown = (event: any) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleRenameClose = (result: any) => {
        setOpenRename(false);
        if (result) {
            reloadCurrentState();
        }
    };

    const handleMoveClose = (result: any) => {
        setOpenMove(false);
        if (result) {
            resetFiles()
            reloadCurrentState();
        }
    };

    const convertBlobToUrl = (blob: any) => {
        const fileURL = URL.createObjectURL(blob);
        return fileURL;
    };

    // Mark as Read
    const updateFile = useCallback(() => {
        if(!currentFile?.is_read_by_user as boolean && !accountantMode) {
            updateFileRead(accountId, currentFile?.id as string, {is_read_by_user: true}).then(res => {
                reloadCurrentState();
            })
        }
        
    }, [accountId, 
        currentFile?.id, 
        currentFile?.is_read_by_user, 
        reloadCurrentState, 
        accountantMode])

    const getFileUrl = useCallback(() => {
        getFile(accountId, currentFile?.id as string).then((res) => {
            setPdfFile(`${convertBlobToUrl(res)}#toolbar=0`);
            updateFile()
        });
    }, [accountId, currentFile?.id, updateFile]);

    useEffect(() => {
        if (currentFile?.mime_type) {
            getFileUrl();
        }
    }, [currentFile?.mime_type, getFileUrl]);

    const {size, sizeType} = convertBytesToSize(currentFile?.size as number)
    const dropdownMenu = (
        <Menu
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            classes={{ list: styles.menu }}
        >
            <MenuItem
                onClick={() => {
                    setOpenRename(true);
                    setMenuAnchor(null);
                }}
                data-cy="open-document-rename-modal"
            >
                <ListItemIcon>
                    <EditOutlined fontSize='small' />
                </ListItemIcon>
                <Typography>Rename</Typography>
            </MenuItem>
            <MenuItem
                divider
                onClick={() => {
                    setOpenMove(true);
                    setMenuAnchor(null);
                }}
                data-cy="move-document"
            >
                <ListItemIcon>
                    <ArrowForwardRounded fontSize='small' />
                </ListItemIcon>
                <Typography>Move Document</Typography>
            </MenuItem>
            <MenuItem
                component='a'
                target='_blank'
                data-cy="download-document"
                href={`${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/api/account/${accountId}/file_center/file/${currentFile?.id}/download`}
            >
                <ListItemIcon>
                    <CloudDownloadOutlined fontSize='small' />
                </ListItemIcon>
                <Typography>Download</Typography>
            </MenuItem>
            <Hidden mdUp>
                <MenuItem data-cy="delete-document-button" onClick={() => setOpenDelete(true)}>
                    <ListItemIcon>
                        <DeleteOutlined fontSize='small' color='error' />
                    </ListItemIcon>
                    <Typography color='error'>Delete</Typography>
                </MenuItem>
            </Hidden>
        </Menu>
    );
    const fileHeading = (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>{currentFile?.filename}</Typography>
            <Box>
                <IconButton  data-cy="delete-document-button" onClick={() => setOpenDelete(true)}>
                    <DeleteOutlined fontSize='small' />
                </IconButton>
                <IconButton data-cy="more-vert-button" onClick={handleMenuDropdown}>
                    <MoreVert fontSize='small' />
                </IconButton>
                {dropdownMenu}
            </Box>
        </Box>
    );

    const dialogHeading = (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <IconButton onClick={handleClearFile} className={styles.closeIcon}>
                <CloseRounded fontSize='small' />
            </IconButton>
            <Box flex={1}>
                <Typography
                    variant='h6'
                    gutterBottom
                    className={styles.dialogFileName}
                >
                    {currentFile?.filename}
                </Typography>
            </Box>
            <IconButton onClick={handleMenuDropdown}>
                <MoreVert fontSize='small' />
            </IconButton>
            {dropdownMenu}
        </Box>
    );

    const fileImage = (
        /* to do */
        <Box minHeight='300px' border='1px solid #f5f5f9' bgcolor='#f5f5f9'>
            {(currentFile?.mime_type as string) === 'image/jpeg' ||
            (currentFile?.mime_type as string) === 'image/png' ? (
                <img
                    style={{ minWidth: 100, maxWidth: '100%' }}
                    src={
                        currentFile?.is_preview_available
                            ? `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/api/account/${accountId}/file_center/file/${currentFile?.id}/view`
                            : imageIcon
                    }
                    alt='Preview'
                />
            ) : (
                <object
                    data={pdfFile}
                    type={currentFile?.mime_type}
                    width='100%'
                    height='500px'
                    aria-label={currentFile?.filename}
                />
            )}
        </Box>
    );

    const fileInfo = (
        <Box p={2} width='100%'>
            <Box display='flex' alignItems='center'>
                <Box mr={2} display='flex'>
                    <LockOutlined fontSize='small' color='secondary' />
                </Box>
                <Typography variant='body2' color='textSecondary'>
                    Only you can see this file
                </Typography>
            </Box>
            <Box fontSize={14} py={3}>
                <Typography variant='body2'>Uploaded On</Typography>
                <Typography variant='subtitle1'>
                    {moment((currentFile?.upload_date as number) * 1000).format(
                        'DD MMM YYYY'
                    )}
                </Typography>
            </Box>
            {currentFile?.uploaded_by_accountant && <Box fontSize={14} py={3}>
                <Typography variant='body2'>Uploaded By</Typography>
                <Typography variant='subtitle1'>
                    {`${currentFile?.uploaded_by_accountant?.first_name} ${currentFile?.uploaded_by_accountant?.last_name}`}
                </Typography>
            </Box>}
            <Box fontSize={14} py={3}>
                <Typography variant='body2'>File Size</Typography>
                <Typography variant='subtitle1'>
                    {currentFile?.size as number > 1024 ? `${size} ${sizeType}`: `${currentFile?.size} Bytes`}
                </Typography>
            </Box>
        </Box>
    );

    const fileDisplay = (
        <div>
            {fileHeading}
            {fileImage}
            {fileInfo}
        </div>
    );

    return currentFile ? (
        <Fragment>
            <Hidden smDown>{modalOpen && fileDisplay}</Hidden>
            <Hidden mdUp>
                {modalOpen && (
                    <UiDialog
                        fullScreen
                        open={modalOpen}
                        // TransitionComponent={Transition}
                        handleClose={handleClearFile}
                        hideTitleSection={true}
                        title=''
                    >
                        {dialogHeading}
                        {fileImage}
                        {fileInfo}
                    </UiDialog>
                )}
            </Hidden>

            <DocumentRename
                fileName={currentFile.filename}
                openRename={openRename}
                handleRenameClose={handleRenameClose}
                fileAccountId={currentFile.portal_account_id}
                fileId={currentFile.id}
                setCurrentFile={setCurrentFile}
            />
            {isEntityManagementTab ? 
                <PersonalDocMove
                    openMove={openMove}
                    handleMoveClose={handleMoveClose}
                    accountId={accountId}
                    personalAccountId={personalAccountId}
                    fileId={currentFile.id}
                    folders={folders}
                /> : 
                <DocumentMove
                    openMove={openMove}
                    handleMoveClose={handleMoveClose}
                    accountId={accountId}
                    personalAccountId={personalAccountId}
                    fileId={currentFile.id}
                    folders={folders}
                />
            }
            
            <UiConfirmationDialog
                open={openDelete}
                title='Confirm Delete'
                hideTitleSection={false}
                message={
                    <Fragment>
                        <Box fontSize='4rem' textAlign='center'>
                            <ErrorOutline fontSize='inherit' />
                            <Typography
                                variant='body1'
                                style={{ wordWrap: 'break-word' }}
                                gutterBottom
                                component='div'
                            >
                                Do you really want to remove this document? This
                                can not be undone.
                            </Typography>
                        </Box>
                    </Fragment>
                }
                handleClose={handleConfirmDeleteDocument}
                confirmButtonText='Delete'
                confirmButtonColor='primary'
                cancelButtonText='Cancel'
                confirmButtonIcon={<DeleteOutlined />}
                confirmButtonId="confirm-delete-btn"
                cancelButtonId="cancel-delete-btn"
            />
        </Fragment>
    ) : (
        <Hidden smDown>
            <Box
                width='100%'
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
            >
                <img
                    src={DocumentsDefault}
                    width='280'
                    alt='DocumentsDefault'
                />
                <Typography variant='h6' className={styles.defaultText}>
                    Select a File to Preview
                </Typography>
            </Box>
        </Hidden>
    );
}

const mapStateToProps = ({ appData }: ApplicationStore) => ({
    accountId: appData.current_account_id,
    accountantMode: appData.user?.accountant_mode,
    personalAccountId: appData.personal_account.id,
});

export default connect(mapStateToProps)(DocumentView);
