import React from 'react';
import IconList from './IconList'
import { makeStyles } from '@material-ui/core'

export type iconListType = keyof typeof IconList
export type IconProps = {
    icon: iconListType
    customClass?: string
    callout?: (icon: string) => void
    children?: React.ReactNode
    svgColor?: string
    strokeColor?: string
    size?:
        | string
        | {
              height: string
              width: string
          }
    rectColor?: string
}


const Icon = ({
    icon,
    svgColor ,
    customClass = '',
    callout,
    children,
    size,
    strokeColor,
    rectColor
}: IconProps): JSX.Element => {

const useStyles = makeStyles(() => {
    return {
        cursorPointer: {
            cursor: 'pointer',
        },
        svgStyles: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                height: 'var(--svg-height)',
                width: 'var(--svg-width)',
                '& rect': {
                    fill: rectColor ? 'var(--rect-color) !important' : '',
                },
                '& path': {
                    fill: svgColor ? 'var(--fill-color) !important' : '',
                    stroke: strokeColor ? 'var(--stoke-color) !important' : '',
                }
            },
        },
    }
})

    const classes = useStyles()

    let height = size,
        width = size
    if (typeof size === 'object') {
        height = size?.height
        width = size?.width
    }

    const inlineStyles = {
        '--svg-height': height,
        '--svg-width': width,
        '--fill-color': svgColor,
        '--stoke-color': strokeColor,
        '--rect-color': rectColor,
    } as React.CSSProperties
    return (
        <span
            className={`icon ${icon} ${customClass} ${classes.svgStyles} ${
                callout ? classes.cursorPointer : ''
            }`}
            style={inlineStyles}
            onClick={() => {
                callout && callout(icon)
            }}
        >
            {IconList[icon]}
            {children && children}
        </span>
    )
}

export default Icon