import React, { createContext, ReactNode, useContext, useState } from 'react'
import { COLORS } from '../../../variables/colors'

export const ColorThemeContext = createContext<any>(null)
interface ColorThemeProviderProps {
    children: ReactNode
}

export function useThemeContext() {
    const state = useContext(ColorThemeContext)

    if (!state) {
        throw new Error(
            'useThemeContext must be used within ColorThemeProvider'
        )
    }
    return state
}

export const ColorThemeProvider = ({ children }: ColorThemeProviderProps) => {
    const [colorTheme, setColorTheme] = useState(COLORS)

    const updateColorTheme = (newColorTheme: { [key: string]: string }) => {
        setColorTheme((prevColorTheme) => ({
            ...prevColorTheme,
            ...newColorTheme,
        }))
    }

    return (
        <ColorThemeContext.Provider value={{ colorTheme, updateColorTheme }}>
            {children}
        </ColorThemeContext.Provider>
    )
}
