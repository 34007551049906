import React, { Fragment } from 'react';
import {
    IconButton,
    Button,
    makeStyles,
    Theme,
    Typography,
    Hidden,
    Box,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Loader from './Loader'
import clsx from 'clsx'
import UiButton from '../common/ui/UiButton'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from './whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        right: '0',
        top: '0',
        width: '100%',
        height: '54px',
        display: 'flex',
        position: 'absolute',
        background: (colorTheme) => colorTheme.primaryWhite,
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        'z-index': 2,
    },
    loaderPadding: {
        paddingTop: '8px',
        paddingBottom: '7px',
    },
    containerPadding: {
        paddingTop: '15px',
    },
    leftContent: {
        marginLeft: '20px',
    },
    actionButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
        paddingTop: 4,
        marginTop: '12px',
    },
    closeButton: {
        padding: '0px !important',
        marginRight: '10px',
        marginBottom: '15px',
    },
    title: {
        marginBottom: '15px',
    },
    justifySpaceBetween: {
        justifyContent: 'space-between',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
}))

type modalMobileHeaderProps = {
    children?: React.ReactNode
    title?: string
    handleClose?: () => void
    buttonText?: string
    handleButtonAction?: () => void
    loading?: boolean
    buttonType?: muiButtonTypes | uiButtonTypes
    disabled?: boolean;
    buttonVariant?: string;
}

type muiButtonTypes = 'text' | "contained"
type uiButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'hyperlink'

const ModalMobileHeader = (props: modalMobileHeaderProps) => {
    const {
        children,
        loading,
        title,
        handleClose,
        buttonText,
        handleButtonAction,
        buttonType = "text",
        disabled,
    } = props
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <Hidden smUp>
            {children ? (
                children
            ) : (
                <Fragment>
                    {loading ? (
                        <Box
                            className={clsx(
                                styles.container,
                                styles.justifyCenter,
                                styles.loaderPadding
                            )}
                        >
                            <Loader />
                        </Box>
                    ) : (
                        <>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={clsx(
                                    styles.container,
                                    styles.justifySpaceBetween,
                                    styles.containerPadding
                                )}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    className={styles.leftContent}
                                >
                                    <Box>
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            onClick={handleClose}
                                            classes={{
                                                root: styles.closeButton,
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </Box>
                                    <Box className={styles.title}>
                                        <Typography variant="h6">
                                            {' '}
                                            {title}{' '}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    { (['text', 'contained'].includes(buttonType)) ? (
                                        <Button
                                            variant={buttonType as muiButtonTypes}
                                            color="primary"
                                            onClick={handleButtonAction}
                                            className={styles.closeButton}
                                            disabled={disabled}
                                        >
                                            {buttonText}
                                        </Button>
                                    ) : (
                                        <UiButton
                                            handleClick={handleButtonAction}
                                            label={buttonText}
                                            btnType={buttonType as uiButtonTypes}
                                            customClass={styles.closeButton}
                                            disabled={disabled}
                                        />
                                        
                                    )}
                                </Box>
                            </Box>
                        </>
                    )}
                </Fragment>
            )}
        </Hidden>
    )
}

export default ModalMobileHeader
