import React from 'react'
import {
    makeStyles,
    Theme
} from '@material-ui/core'
import UpgradePlanButton from '../common/UpgradePlanButton'
import UiText from '../common/ui/UiText';
import { useThemeContext } from './whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../styles/models/Colors.interface';

const useClass = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    widgetRoot: {
       border: (colorTheme) => `0.063rem solid ${colorTheme.grey200}`,
        borderRadius: '0.5rem',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
        },
        margin: '0 0 1.25rem 0'
    },
    wdgeBody: {
        padding: theme.spacing(3, 3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 0, 2),
        },
    },
    wdgeFooter: {
        padding: theme.spacing(3, 3, 3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 0, 2),
        },
    },
}))

const UpgradePlanCard = (props: { children: string} ) => {
    const { colorTheme } = useThemeContext()
    const classes = useClass(colorTheme)
    
    return (
        <div className={classes.widgetRoot}>
            <div className={classes.wdgeBody}>
            <UiText variant='motorcycle_90'>
                {props.children}
            </UiText>
            </div>
            <div className={classes.wdgeFooter}>
                <UpgradePlanButton/>
            </div>
        </div>
    )
}

export default UpgradePlanCard;