import { CircularProgress } from "@material-ui/core";

export default function Loader({ justifyContent = 'center', alignItems = 'center', size = 40, width = '100%', customClass }: {
  justifyContent?: 'flex-start' | 'center' | 'space-between' | 'space-evenly' | 'space-around' | 'flex-end',
  alignItems?: 'flex-start' | 'center' | 'flex-end',
  size?: number;
  width?: number | string;
  customClass? : string;
}) {
  return <div style={{
    display: 'flex',
    justifyContent,
    alignItems,
    height: '100%',
    width
  }}
  className={customClass ?? ''}
  >
    <CircularProgress size={size} />
  </div>;
}