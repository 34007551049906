import { IError, PageContent } from '../models';
import { getResource } from './axiosService';
export class PageService<T> {
    items?: T[];
    loading?: boolean;
    page: number;
    perPage: number;
    useApi?: boolean;
    onLoad?: () => void;
    itemsLength?: number;
    params?: Record<string, string | number | undefined>;
    itemsCount?: number;
    pageCount?: number;
    totalItem?: number;
    private loadUrl?: string;

    constructor(apiUrl: string, onLoad: () => void, state: boolean) {
        this.page = 1;
        this.perPage = 6;
        this.useApi = !state;
        this.onLoad = onLoad;
        this.itemsLength = 0;
        this.params = {};
        this.loadUrl = apiUrl;
    }

    isNoItems() {
        return (
            !this.itemsLength &&
            this.page === 1 &&
            this.params &&
            Object.keys(this.params).reduce((acc: boolean, k: string) => {
                return (acc &&
                    (['page', 'perPage'].indexOf(k) !== -1 ||
                        (this.params && this.params[k]))) as boolean;
            }, true)
        );
    }
    private loadItemsPage(
        params?: Record<string, string | number>,
        itemsOnLoad?: any,
        useApi = (this.useApi = false)
    ) {
        if (this.loading) {
            return false;
        }
        this.loading = true;
        if (params) {
            this.params = params;
        }
        const query = params || this.params || {};
        query.page = this.page;
        query.perPage = this.perPage;
        if (useApi || this.useApi) {
            getResource<PageContent<T>>(this.loadUrl, undefined, query)
                .then((data: PageContent<T>) => {
                    data.itemsLength = data.items ? data.items.length : 0;
                    Object.keys(data).forEach((k: string) => {
                        // @ts-ignore
                        this[k] = data[k];
                    });
                    if (itemsOnLoad) {
                        itemsOnLoad();
                    } else if (this.onLoad) {
                        this.onLoad();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    updateUrl(newUrl: string) {
        this.loadUrl = newUrl;
    }

    load(
        page: number,
        perPage: number,
        params: Record<string, string>,
        returnPromise = false,
        useApi = false
    ) {
        this.page = page;
        this.perPage = perPage;

        if (!returnPromise) {
            this.loadItemsPage(params, this.onLoad, useApi);
        } else {
            return new Promise((resolve: (value: PageService<T>) => void) => {
                this.loadItemsPage(
                    params,
                    () => {
                        if (this.onLoad) {
                            this.onLoad();
                        }
                        resolve(this);
                    },
                    useApi
                );
            });
        }
    }

    reload() {
        this.loadItemsPage();
    }

    forceLoad(
        page: number,
        perPage: number,
        params: Record<string, string>
    ): Promise<PageService<T>> {
        return new Promise((resolve, reject) => {
            const promise = this.load(page, perPage, params, true, true);
            if (promise)
                promise.then((items: PageService<T> | IError) => {
                    //handle errors
                    if ((items as IError).code) {
                        reject(items);
                        return;
                    }
                    //handle empty results after remove
                    if (
                        (items as PageService<T>).itemsLength !== 0 &&
                        page > 1
                    ) {
                        this.load(page, perPage, params);
                    }
                    resolve(items as PageService<T>);
                }, reject);
        });
    }
}
