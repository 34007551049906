import { Link, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import LineChartWidget from '../../common/LineChartWidget'
import WidgetBox from '../../common/WidgetBox'
import MenuDropdown from '../../common/MenuDropdown'
import { connect } from 'react-redux'
import { getInvoicesWidgetData } from '../../../services/apiService'
import { ApplicationStore } from '../../../models'
import {
    isTrialEnded,
    getTimeParams,
    currencyFormatter,
} from '../../../utils/appUtil'
import WidgetSkeletonLoader from '../../common/WidgetSkeletonLoader'
import { useStyles } from '../../../styles/styles'
import { useHistory } from 'react-router-dom'
import { COLORS } from '../../../variables/colors'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import {
    INVOICES_CHART_TITLES,
    DATE_FILTER,
    INVOICES_DATE_DROPDOWN_OPTIONS,
    DATE_OPTIONS,
} from './constants'
import EmptyState from './EmptyState'
import InvoicestEmpty from '../../../assets/icons-svg/InvoicestEmpty.svg'

interface InvoiceWidgetData {
    total: string
    paid: string
    unpaid: string
    late: string
}
function Invoices(props: any) {
    const [loading, setLoading] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [totalInvoiceValue, setTotalInvoiceValue] = useState<any>()
    const [lineChartValues, setLineChartValues] = useState<any>([])
    const accountID = props.appData.current_account_id
    const businessID = props.appData.current_business_id

    useEffect(() => {
        if (isTrialEnded()) {
            setTotalInvoiceValue(undefined)
            setLoading(false)
            return
        }
        setLoading(true)

        const renameToThreeMonths = (period: DATE_OPTIONS) => {
            if (period === DATE_OPTIONS.LAST_QUARTER) {
                return DATE_OPTIONS.THREE_MONTHS
            }
            return period
        }

        const widgetParams = {
            ...getTimeParams(
                renameToThreeMonths(INVOICES_DATE_DROPDOWN_OPTIONS[selectedIndex])
            ),
            date_filter: DATE_FILTER,
        }
        const setValuesForLineChart = (data: InvoiceWidgetData) => {
            setLineChartValues([
                {
                    title: INVOICES_CHART_TITLES.UNPAID,
                    amount: Number.parseFloat(data.unpaid),
                    color: COLORS.test5,
                },
                {
                    title: INVOICES_CHART_TITLES.PAID,
                    amount: Number.parseFloat(data.paid),
                    color: COLORS.orange400,
                },
                {
                    title: INVOICES_CHART_TITLES.LATE,
                    amount: Number.parseFloat(data.late),
                    color: COLORS.red100,
                },
            ])
        }
        getInvoicesWidgetData(accountID, businessID, widgetParams)
            .then((res: unknown) => {
                const data = res as InvoiceWidgetData
                const totalInvoiceValue = data.total
                setTotalInvoiceValue(totalInvoiceValue)
                setValuesForLineChart(data)
                setLoading(false)
            })
            .catch(() => {
                setTotalInvoiceValue(undefined)
                setLoading(false)
            })
    }, [accountID, businessID, selectedIndex])

    const formateDuePeriod = () => {
        if (
            INVOICES_DATE_DROPDOWN_OPTIONS[selectedIndex] ===
            DATE_OPTIONS.THIS_YEAR
        ) {
            return 'This year'
        } else if (
            INVOICES_DATE_DROPDOWN_OPTIONS[selectedIndex] ===
            DATE_OPTIONS.LAST_QUARTER
        ) {
            return 'Three months'
        }
        return DATE_OPTIONS.ALL_TIME
    }

    const history = useHistory()
    const redriect = (type?: string) => {
        history.push({
            pathname: '/invoices',
            state: {
                paymentStatus: redirectParams(type || ''),
                invoice_period: formateDuePeriod(),
                from: 'dashboard',
            },
        })
    }

    const redirectParams = (type: string) => {
        return type === 'Late' ? 'Overdue' : type
    }

    function ChartSummary() {
        const { colorTheme } = useThemeContext()
        const commonClasses = useStyles(colorTheme)
        return (
            <Fragment>
                <div>
                    <Typography
                        component={Link}
                        color="textPrimary"
                        className={commonClasses.dashboardTextLink}
                        onClick={() => redriect()}
                        variant="h4"
                    >
                        {currencyFormatter.format(totalInvoiceValue)}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        Total Invoices
                    </Typography>
                </div>
                <div></div>
            </Fragment>
        )
    }

    return (
        <WidgetBox
            heading="Invoices"
            actionControl={
                props.trialEnded ? (
                    ''
                ) : (
                    <MenuDropdown
                        options={INVOICES_DATE_DROPDOWN_OPTIONS}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        disabled={loading}
                    />
                )
            }
        >
            {loading ? (
                <WidgetSkeletonLoader
                    justifyContent="flex-start"
                    alignItems="flex-start"
                />
            ) : totalInvoiceValue ? (
                <LineChartWidget
                    values={lineChartValues}
                    summary={<ChartSummary />}
                    handleAmountClick={redriect}
                />
            ) : (
                <EmptyState
                    alt="Invoice empty image"
                    src={InvoicestEmpty}
                    text="Easily see the total amounts of your outgoing invoices for the selected time."
                />
            )}
        </WidgetBox>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    trialEnded: state.auth.isTrialEnded,
})
export default connect(mapStateToProps)(Invoices)
