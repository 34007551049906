import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { Formik, Form, FormikProps, Field,  FormikValues, FieldProps } from 'formik'
import { useHistory } from 'react-router-dom'
import {
    Theme,
    Box,
    Grid,
    GridSize,
    makeStyles,
    Button,
    Hidden,
    useMediaQuery,
    useTheme,
    Paper,
    TextField,
    InputAdornment,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
// icons
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import { ProductDetail, ProductCategory } from '../../../models'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import Loader from '../../common/Loader'
import ModalMobileHeader from '../../common/ModalMobileHeader'
import ConfirmProductModal from './ConfirmProductModal'
import CreateCategoryModal from './CreateCategoryModal'
import {
    defaultValues,
    ProductFormProps,
    propertyIconMapping,
    ProductFormValidationSchema,
} from './ProductFormUtil'
import {
    createProductAction,
    updateProductAction,
    deleteProductAction,
} from './actions'
import { useProductServiceContextState } from './ProductServicesProvider'
import Icon from '../../../components/common/Icon'
import { OutlinedDeleteButton } from '../../../components/common/DeleteButton'
import { FullHeight } from '../../../components/common/StyledComponents';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    iconClass: {
        width: '20px',
        height: '20px',
        marginRight: '16px',
        marginTop: '8px',
    },
    footerAction: {
        right: '0',
        width: '100%',
        bottom: '0',
        display: 'flex',
        padding: '0.5rem',
        position: 'absolute',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderTop:  (colorTheme) =>`1px solid ${colorTheme.grey200}`,
        'justify-content': 'flex-end',
        'z-index': 1,
        height: '45px',
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
    /* Styles applied to the endAdornment element. */
    endAdornment: {
        // We use a position absolute to support wrapping tags.
        position: 'absolute',
        right: 0,
        top: 'calc(50% - 14px)', // Center vertically
    },
    optionLabel: {
        display: 'block',
    },
    justifySpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    formGrid: {
        marginTop: '6px',
        marginBottom: '6px',
    },
}))

function ProductForm({
    product,
    handleClose,
    setSelectedProduct,
    loadProducts,
    setProductUpdated,
    formikRef,
    productCallback,
    showFormSaveOptions = false,
}: ProductFormProps) {
    const { colorTheme } = useThemeContext()
    const classNames = useStyles(colorTheme)
    const theme = useTheme()
    const history = useHistory()
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
    const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    // Used options in invoice modal -> edit product
    const [saveFormOption, setSaveFormOption] = useState<"save" | "softSave">('save')
    // open on default once category items loaded
    const [openAutoCompleteDropdown, setOpenAutoCompleteDropdown] =
        useState(false)
    const formRef = useRef<FormikProps<FormikValues>>(null)
    const { productCategories, refetchData, setRefetchData } =
        useProductServiceContextState()

    const isSaveOptionSelected = saveFormOption === 'save'  

    const currentCategory = useMemo(() => {
        return  productCategories?.find(
            (category: ProductCategory) => category?.id === product?.category
        )
    },[product?.category, productCategories])

    let initialValues = defaultValues
    if (product) {
        initialValues = {...product, category: currentCategory ? currentCategory : null }
    }


    const updateProductData = (params: any, formik: any) => {
        updateProductAction(
            product.id,
            params,
            (res: ProductDetail) => {
                setSelectedProduct &&
                    setSelectedProduct(res as ProductDetail)
                handleClose()
                productCallback?.(res)
                setProductUpdated?.(true)
            },
            formik
        )
    }

    const createProduct = (params: any, formik: any) => {
        createProductAction(
            params,
            (product: any) => {
                handleClose(true)
                productCallback?.(product)
                loadProducts?.()
            },
            formik
        )
    }

    const onSubmit = (data: any, formik: any) => {
        const submitProduct = () => {
            product?.id ? updateProductData(data, formik) : createProduct(data, formik)
        }
        if(showFormSaveOptions) {
            isSaveOptionSelected ? submitProduct() : productCallback?.(data)
        } else {
            submitProduct()
        }
    }

    useEffect(() => {
        if (refetchData) {
            setRefetchData(true)
        }
    }, [refetchData, setRefetchData])

    const gridItems = (property: any, children: any) => {
        return (
            <Grid
                key={property?.label}
                item
                xs={property?.xs as GridSize}
                md={property?.md as GridSize}
                className={classNames.formGrid}
            >
                <div>
                    <Box my={1}>{property.label} </Box>
                    {children}
                </div>
            </Grid>
        )
    }

    const renderFormFields = (property: any) => {
        return (
            <UiFormControlSelection
                placeholder={property?.placeholder}
                type={property.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                {...(property.type === 'toggleGroup'
                    ? { optionsData: property?.optionsData }
                    : {})}
                {...(property.type === 'select'
                    ? {
                          optionsData: productCategories,
                          optionKey: 'title',
                          optionValue: 'id',
                      }
                    : {})}
                startIcon={
                    property?.startIcon && <Icon icon={property?.startIcon} />
                }
                endIcon={property?.endIcon && <Icon icon={property?.endIcon} />}
                {...(property.key === 'tax_rate' || property.key === 'price'
                    ? {
                          onBlur: (event) => {
                              if (event.target.value) {
                                  const numberVal = parseFloat(
                                      event?.target?.value
                                  )
                                  formRef?.current?.setFieldValue(
                                      property.key,
                                      numberVal.toFixed(2)
                                  )
                                  formikRef?.current?.setFieldValue(
                                      property.key,
                                      numberVal.toFixed(2)
                                  )
                              }
                          },
                      }
                    : {})}
            />
        )
    }

    const menuLink = ({ children, ...other }: any) => {
        return (
            <Paper {...other}>
                <Box borderBottom={`1px solid ${colorTheme.grey200}`}>
                    <Button
                        fullWidth
                        startIcon={<AddIcon />}
                        onMouseDown={(event) => {
                            event.preventDefault()
                        }}
                        id="create-new-category-button"
                        onClick={(event) => {
                            event.preventDefault()
                            setOpenCategoryModal(true)
                            setOpenAutoCompleteDropdown(false)
                        }}
                    >
                        Create New Category
                    </Button>
                </Box>
                {children}
            </Paper>
        )
    }

    const autoCompleteField = (formValue: any) => {
        return (
            <Autocomplete
                id="product-category-selector"
                fullWidth
                classes={{ option: classNames.optionLabel }}
                options={productCategories as any}
                value={formValue}
                open={openAutoCompleteDropdown}
                onClose={() =>
                    setOpenAutoCompleteDropdown(!openAutoCompleteDropdown)
                }
                onOpen={() =>
                    setOpenAutoCompleteDropdown(!openAutoCompleteDropdown)
                }
                getOptionLabel={(option: any) => option?.title}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(option: any) => (
                    <div className={classNames.justifySpaceBetween}>
                        <div>{option.title}</div>
                        <Typography variant="caption">
                            {option?.parent_title}
                        </Typography>
                    </div>
                )}
                onChange={(event, item: any) => {
                    formikRef?.current?.setFieldValue('category', item)
                    formRef?.current?.setFieldValue('category', item)
                }}
                PaperComponent={(child: any) => {
                    return menuLink(child)
                }}
            />
        )
    }

    /**
     * Handle FORM Modal closing
     * @param touched | FormikProps
     */
    const handleFormClose = (touched: any) => {
        if (product) {
            handleClose() // on edit no check touch check added direct close
        } else {
            // if form is touched then confirmation modal opens
            if (Object.keys(touched).length > 0) {
                setOpenConfirmationModal(true)
            } else {
                handleClose(true)
            }
        }
    }

    /**
     * Confirmation Pop up Handling
     * This modal only appears on creating New Product / Delete Product
     */
    const handleConfirmModal = (result: any) => {
        // close the modal // result === true when user clicks right side options
        setOpenConfirmationModal(false)
        if (result === true) {
            if (product) {
                // on Edit
                setLoading(true)
                deleteProductAction(product.id, () => {
                    handleClose()
                    setOpenConfirmationModal(false)
                    history.push('/products')
                    loadProducts?.()
                    setSelectedProduct?.(undefined)
                })
            } else {
                // else close the mainProductModal
                setOpenConfirmationModal(false)
                handleClose(true)
            }
        }
    }

    if (loading) {
        return (
            <FullHeight>
                <Loader />
            </FullHeight>
        )
    }

    const handleRadioChange = (event: any, value: string | "save" | "softSave") => {
        setSaveFormOption(value as "save" | "softSave")
    }

    return (
        <>
            <ConfirmProductModal
                isEditable={product}
                product={product}
                showConfirmModal={openConfirmationModal}
                handleClose={handleConfirmModal}
            />
            <CreateCategoryModal
                open={openCategoryModal}
                handleClose={() => {
                    setOpenCategoryModal(false)
                }}
                productCategories={productCategories}
                setFetchCategory={setRefetchData}
                categoryCallback={(category) => {
                    formRef?.current?.setFieldValue('category', category)
                    formikRef?.current?.setFieldValue('category', category)
                }}
            />
            {/** Used in invoice modal -> edit product */}
            {showFormSaveOptions && (
                <RadioGroup
                    name="formSaveOptions"
                    value={saveFormOption}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel
                        value="save"
                        control={<Radio />}
                        label="Save changes for all future invoices"
                    />
                    <FormControlLabel
                        value="softSave"
                        control={<Radio />}
                        label="Only apply changes to this invoice"
                    />
                </RadioGroup>
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={ProductFormValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formikRef || formRef}
                validateOnChange
            >
                {({ submitForm, touched, isSubmitting, values }) => {
                    return (
                        <Form>
                            <ModalMobileHeader
                                title={
                                    product
                                        ? 'Edit Product/Services'
                                        : ' Create Product/Services'
                                }
                                buttonText={'save'}
                                loading={isSubmitting}
                                handleClose={() => {
                                    handleFormClose(touched)
                                }}
                                handleButtonAction={() => submitForm()}
                            />
                            <Box style={isSmDown ? { marginTop: '60px' } : {}}>
                                {propertyIconMapping.map((property: any) => (
                                    <Fragment key={property.key}>
                                        <Grid container spacing={1}>
                                            {!property?.group ? (
                                                property.type ===
                                                'autocomplete' ? (
                                                    gridItems(
                                                        property,
                                                        autoCompleteField(
                                                            values?.category
                                                        )
                                                    )
                                                ) : (
                                                    gridItems(
                                                        property,
                                                        renderFormFields(
                                                            property
                                                        )
                                                    )
                                                )
                                            ) : (
                                                <>
                                                    {property.formfields.map(
                                                        (field: any) =>
                                                            gridItems(
                                                                field,
                                                                renderFormFields(
                                                                    field
                                                                )
                                                            )
                                                    )}{' '}
                                                </>
                                            )}
                                        </Grid>
                                    </Fragment>
                                ))}
                            </Box>

                            <Box my={1} style={{ marginBottom: '60px' }}>
                                {!showFormSaveOptions && product && (
                                    <OutlinedDeleteButton
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth={isSmDown}
                                        onClick={() => {
                                            setOpenConfirmationModal(true)
                                        }}
                                        showDeleteIcon
                                        className={classNames.cancelButton}
                                    >
                                        Delete Product
                                    </OutlinedDeleteButton>
                                )}
                            </Box>
                            <Hidden xsDown>
                                <div className={classNames.footerAction}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid justify="flex-end" container>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => {
                                                    handleFormClose(touched)
                                                }}
                                                className={
                                                    classNames.cancelButton
                                                }
                                                data-cy="cancel-product-changes"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={submitForm}
                                                data-cy="save-product-changes"
                                            >
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    )}
                                </div>
                            </Hidden>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default ProductForm
