import store from '../../../store'
import { getResource, patchResource, postResource, poll } from '../../axiosService'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id
    const accountId = currentStore.appData.current_account_id

    return {
        businessId,
        accountId,
    }
}

const baseURL = () => {
    const { businessId } = getStoreData()
    return `api/business/${businessId}/adp/employee`
}
/**
 * business/businessID/adp/employee/employeeId
 */
export function getCurrentEmployee(associateID: string) {
    const URL = baseURL()
    const employeeURL = `${URL}/${associateID}`
    return getResource(employeeURL, undefined, {})
}

export function getEmployeeList() {
    const URL = baseURL()
    return getResource(URL, undefined, {})
}
// TODO - add data type for employee
export function createEmployee(data: any) {
    const URL = baseURL()
    return postResource(URL, data)
}

export function updateEmployee(data: any, id: string) {
    const URL = baseURL()
    return poll(`${URL}/${id}`, 'patch', data)
}
