import { Box, Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateDocument } from '../../services/apiService';
import { showAlert, showError } from '../../store/actions/feedback';
import UiDialog from '../common/ui/UiDialog';
import UiFormField from '../common/ui/UiFormField';
import { UploadedFile } from '../../models'

export default function DocumentRename({
    openRename,
    handleRenameClose,
    fileName,
    fileAccountId,
    fileId,
    setCurrentFile,
}: {
    openRename: boolean;
    handleRenameClose: (result: boolean) => void;
    fileName: string;
    fileAccountId: string;
    fileId: string;
    setCurrentFile: React.Dispatch<UploadedFile>
}) {
    const spiltFileName = fileName?.split('.')
    const fileExtension = spiltFileName?.pop()
    const [newDocumentName, setNewDocumentName] = useState(fileName);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleRenameDocument = (newFileName: string) => {
        setLoading(true);
        updateDocument(fileAccountId, fileId, { filename: `${newFileName}.${fileExtension}` })
            .then((response: any) => {
                if(response) {
                    handleRenameClose(true);
                    // updating the currently selected file to get the updated fileName
                    setCurrentFile(response)
                    dispatch(
                        showAlert({
                            alertText: 'Document Renamed Successfully!',
                            alertType: 'success',
                        })
                    );
                    setLoading(false);
                }
                
            })
            .catch((err) => {
                dispatch(showError({ errorCode: err.status, showError: true }));
                setLoading(false);
            });
    };

    useEffect(() => {
        const fileNameWithoutextenstion = fileName?.substr(0, fileName.lastIndexOf('.'))
        setNewDocumentName(fileNameWithoutextenstion);
    }, [fileName]);

    return (
        <UiDialog
            open={openRename}
            handleClose={handleRenameClose}
            title='Rename Document'
            size='xs'
        >
            <UiFormField
                type='text'
                value={newDocumentName}
                placeholder={'File Name (Required)'}
                label='File Name (Required)'
                cypressId="document-rename-field"
                required={true}
                onChange={(event: any) =>
                    setNewDocumentName(event.target.value)
                }
                onKeyPress={(event: any) => {
                    if (event.key === 'Enter') {
                        setNewDocumentName(event.target.value);
                        handleRenameDocument(event.target.value);
                    }
                }}
            />
            {loading ? (
                <Box display='flex' justifyContent='center'>
                    <CircularProgress size={22} />
                </Box>
            ) : (
                <Box display='flex' justifyContent='center'>
                    <Button
                        variant='contained'
                        color='primary'
                        data-cy="save-document-rename-button"
                        disabled={newDocumentName?.trim()?.length === 0}
                        onClick={() =>
                            handleRenameDocument(newDocumentName as string)
                        }
                    >
                        Save
                    </Button>
                    <Box mx={1} />
                    <Button
                        data-cy="cancel-document-rename-button"
                        variant='outlined'
                        onClick={() => handleRenameClose(false)}
                    >
                        Cancel
                    </Button>
                </Box>
            )}
        </UiDialog>
    );
}
