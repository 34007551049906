export const getPrevYearOptions = (): [number, number, number] => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const cutoffDate = new Date(`April 16, ${currentYear}`)

    let baseYear
    if (currentDate >= cutoffDate) {
        baseYear = currentYear - 4
    } else {
        baseYear = currentYear - 5
    }

    return [baseYear + 1, baseYear + 2, baseYear + 3]
}