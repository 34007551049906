import * as Yup from 'yup'
export const TOSValidationSchema = () => {
    return Yup.object({
        agreement_1: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        agreement_2: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        agreement_3: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        agreement_4: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        agreement_5: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        agreement_6: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        agreement_7: Yup.boolean().oneOf(
            [true],
            'Must Accept all the Terms and Conditions'
        ),
        signature: Yup.string().required('Signature is required'),
    })
}
