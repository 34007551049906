import { useEffect, useState } from 'react'
import UiDialog from '../common/ui/UiDialog'
import {
    Button,
    Checkbox,
    Theme,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { Container, InlineFlex } from './Wrapper'
import { toRem16 } from '../../styles/commonStyles'
import UiText from '../common/ui/UiText'
import { debounce } from '../../utils/debounce'
import SearchBox from '../common/SearchBox'
import UiButton from '../common/ui/UiButton'
import { useContractorContext } from './ContractorProvider'
import {
    addContractorIntoYear,
    getContractorList,
} from './apiServices/contractor.api'
import { requestErrorHandler } from '../../services/formService'
import Loader from '../common/Loader'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    scrollButtom: {
        position: 'fixed',
        bottom: '7rem',
        right: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        width: toRem16(56),
        height: toRem16(56),
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: (colorTheme) => `${colorTheme.primary}`,
        color: (colorTheme) => `${colorTheme.primaryWhite}`,
    },
    listItem: {
        borderBottom: (colorTheme) =>
            `${toRem16(1)} solid ${colorTheme.grey1200}`,
    },
}))

const MODAL_CONTENT_DIV_ID = 'modal-content'
const HandleScroll = (props: any) => {
    const handleScrollToElement = debounce((event: any) => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        const currentPosition = scrollDemo.scrollTop
        if (currentPosition === 0) {
            props.setIsScrollable(true)
        } else {
            props.setIsScrollable(false)
        }
    }, 100)

    useEffect(() => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        scrollDemo.addEventListener('scroll', handleScrollToElement, {
            passive: true,
        })
        return () => {
            scrollDemo.removeEventListener('scroll', handleScrollToElement)
        }
    }, [handleScrollToElement])

    return <div />
}

interface Props {
    isOpen: boolean
    handleClose: (data: boolean) => void
    taxYear: string
    reloadData?: (year: string) => void;
}

export const ContractorTaxYearModal = (props: Props) => {
    const { isOpen, handleClose, taxYear, reloadData } = props
    const [isScrollable, setIsScrollable] = useState<boolean>(true)
    const [searchInput, setSearchInput] = useState<string>('')
    const [selected, setSelected] = useState<any[]>([])
    const [contractorList, setContractorList] = useState<any[]>([])
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [apiLoading, setApiLoading] = useState<boolean>(false)

    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const theme = useTheme()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

    const { contractors } = useContractorContext()

    useEffect(() => {
        setApiLoading(true)
        getContractorList({ exclude_year: taxYear }).then((data: any) => {
            setApiLoading(false)
            setContractorList(data.items)
        })
        return () => {
            setContractorList([])
        }
    }, [setContractorList, taxYear])

    const toLowerCase = (str: string) => {
        return str.toLocaleLowerCase()
    }

    const handleSearch = (name: string) => {
        setSearchInput(name)
        if (name.length >= 2) {
            setContractorList(
                contractors.items.filter((item) =>
                    toLowerCase(item.first_name + item.last_name).includes(
                        toLowerCase(name)
                    )
                )
            )
        } else {
            setContractorList(contractors.items)
        }
    }

    const handleClick = (contarctor: any) => {
        let newSelected: any[] = [...selected]
        const selectedIndex = selected.findIndex(
            (item: any) => item.id === contarctor.id
        )
        if (selectedIndex >= 0) {
            newSelected.splice(selectedIndex, 1)
        } else {
            newSelected = [...newSelected, contarctor]
        }
        setSelected(newSelected)
    }

    const handleAddContractorIntoYear = () => {
        setIsloading(true)
        requestErrorHandler(
            addContractorIntoYear(
                taxYear,
                selected.map((data) => data.id)
            ).then(() => {
                reloadData?.(taxYear)
                setIsloading(false)
                handleClose(false)
            }),
            true,
            () => {
                setIsloading(false)
            }
        )
    }

    const actions = () =>
        isLoading ? (
            <Loader />
        ) : (
            <>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleClose(false)}
                    data-cy="cancel-new-cat-btn"
                >
                    Cancel
                </Button>

                <UiButton
                    handleClick={handleAddContractorIntoYear}
                    label={`Add ${selected.length} Contractor`}
                    btnType="tertiary"
                />
            </>
        )

    const labelId = `enhanced-table-checkbox-${1}`
    return (
        <UiDialog
            open={isOpen}
            handleClose={() => handleClose(false)}
            title={`Add Contractors to Tax Year ${taxYear}`}
            size="sm"
            fullScreen={isSmallDevice ? true : false}
            actions={actions()}
        >
            <HandleScroll setIsScrollable={setIsScrollable} />
            <Container minheight={toRem16(500)}>
                <div>
                    <SearchBox
                        searchTerm={searchInput}
                        placeHolder={'Search'}
                        setInput={setSearchInput}
                        onChangeCallback={(value: string) => {
                            handleSearch(value)
                        }}
                        clearSearch={() => {
                            setSearchInput('')
                            setContractorList(contractors.items)
                        }}
                        onKeyPressEvent={(event) => {
                            if (event.key === 'Enter') {
                                // do stuff
                            }
                        }}
                    />
                </div>
                {apiLoading && <Loader />}
                {contractorList?.map((item: any, index: number) => {
                    const isSelected = selected.findIndex(
                        (contractor: any) => item.id === contractor.id
                    )
                    return (
                        <InlineFlex
                            key={item.id}
                            padding={`${toRem16(10)} 0 0 0`}
                        >
                            <div>
                                <Checkbox
                                    checked={isSelected >= 0}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    color="primary"
                                    onClick={() => handleClick(item)}
                                />
                            </div>
                            <InlineFlex
                                justifycontent="space-between"
                                padding={`0 0 ${toRem16(10)} 0`}
                                className={classes.listItem}
                            >
                                <div>
                                    <UiText>
                                        {item.first_name + ' ' + item.last_name}
                                    </UiText>
                                    <UiText
                                        variant="motorcycle_90"
                                        textColor="textSecondary"
                                    >
                                        {item.email}
                                    </UiText>
                                </div>
                                {item.waiting && (
                                    <UiText textColor="textSecondary">
                                        Waiting on W-9
                                    </UiText>
                                )}
                            </InlineFlex>
                        </InlineFlex>
                    )
                })}
            </Container>
        </UiDialog>
    )
}
