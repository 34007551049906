import { Fragment, useState, useEffect } from 'react';
import {
    Box,
    Chip,
    List,
    Hidden,
    Button,
    Divider,
    ListItem,
    makeStyles,
    ListItemAvatar,
    ListItemText,
    Theme,
} from '@material-ui/core';
import { commonStyles, mergeStyle } from '../../../styles/commonStyles';
import UiText from '../../../components/common/ui/UiText';
import Icon, { iconListType } from '../../../components/common/Icon';
import { useProductServiceContextState } from './ProductServicesProvider';
import Loader from '../../common/Loader';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    itemText: {
        marginLeft: '0px',
    },
    buttonRootStyle: {
        padding: '0px !important',
    },
    blueLink: {
        color: (colorTheme) =>  `${colorTheme.blue600} !important`,
    },
}));

const propertyIconMapping = [
    { key: 'item_id', icon: 'hash', label: 'Item Id' },
    { key: 'category', icon: 'tag', label: 'Category' },
    { key: 'description', icon: 'text', label: 'Description' },
];

const ProductDetails = ({ product, setOpenEditModal }: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme);
    const commonClass = commonStyles();
    const [loading, setLoading] = useState(true);

    const { productCategories, formattedProdCategoryObj } =
        useProductServiceContextState();

    useEffect(() => {
        if (productCategories) {
            setLoading(false);
        }
    }, [productCategories]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <Hidden smDown>
                <Box
                    my={4}
                    className={mergeStyle(
                        commonClass.flex,
                        commonClass.spaceBetween
                    )}
                >
                    <div>
                        <UiText variant="suv_150" weight="regular_400">{product?.title}</UiText>
                    </div>
                    <div style={{ minWidth: '120px' }}>
                        <UiText
                            variant="suv_150"
                            weight="semi_bold_600"
                            textAlign="right"
                        >
                            ${product?.price}
                        </UiText>
                        <UiText textAlign="right">
                            Tax Rate:{' '}
                            {product?.tax_rate}%
                        </UiText>
                    </div>
                </Box>
            </Hidden>
            <Box my={3}>
                <Chip
                    label={product?.type === 'product' ? 'Product' : 'Service'}
                    className={product.contactType}
                />
            </Box>
            <Hidden mdUp>
                <UiText variant="truck_175">${product?.price}</UiText>
                <div>
                    Tax Rate:{' '}
                    {product?.tax_rate}%
                </div>
            </Hidden>
            <List>
                {propertyIconMapping.map((property) => (
                    <Fragment key={property.key}>
                        <ListItem>
                            <ListItemAvatar>
                                <Icon icon={property.icon as iconListType} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <UiText textColor="textSecondary">
                                        {property?.label}
                                    </UiText>
                                }
                                classes={{ root: styles.itemText }}
                                secondary={
                                    <>
                                        {product?.[`${property?.key}`] ? (
                                            property?.key === 'category' ? (
                                                <UiText textColor="textPrimary">
                                                    {
                                                        formattedProdCategoryObj?.[
                                                            product.category
                                                        ]
                                                    }
                                                </UiText>
                                            ) : (
                                                <UiText 
                                                    textColor="textPrimary" 
                                                    className={
                                                        property?.key === "description" 
                                                            ? commonClass.preWhiteSpace :''
                                                    }>
                                                    {product?.[`${property?.key}`]}
                                                </UiText>
                                            )
                                        ) : property?.key === 'category' ? (
                                            <Button
                                                classes={{
                                                    root: styles.buttonRootStyle,
                                                }}
                                                onClick={() => {
                                                    setOpenEditModal(true);
                                                }}
                                            >
                                                <UiText
                                                    textColor="textPrimary"
                                                    className={styles.blueLink}
                                                >
                                                    Add Category
                                                </UiText>
                                            </Button>
                                        ) : (
                                            '-'
                                        )}
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </Fragment>
                ))}
            </List>
        </div>
    );
};

export default ProductDetails;
