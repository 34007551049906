import { useCallback, useContext, useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import ADPEmployeeDetails from './ADPEmployeeDetails'
import {
    Box,
    Button,
    Divider,
    Grid,
    Hidden,
    Theme,
    Typography,
    makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Loader from '../../common/Loader'
import { getEmployeeList } from '../../../services/apiService/adp-payroll/employee'
import { Employee } from '../../../models/adp-payroll/employee'
import { requestErrorHandler } from '../../../services/formService'
import ADPEmployeeListView from './ADPEmployeeListView'
import CreateNewEmployeeModal from './CreateNewEmployeeModal'

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up(1601)]: {
            justifyContent: 'flex-start',
        },
    },
    container: {
        '& .MuiPaper-root': {
            height: 816,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                margin: '0px',
                top: '0px',
                '& .MuiDialogTitle-root': {
                    display: 'none',
                },
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.down('sm')]: {
                    padding: '20px 20px',
                    marginTop: '60px',
                },
                '& .top-section': {
                    '& .alert': {
                        height: '36px',
                        width: 'inherit',
                        marginBottom: theme.spacing(2),
                    },
                    '& .delete-section': {
                        display: 'flex',
                    },
                    [theme.breakpoints.down('sm')]: {
                        height: '100%',
                        maxHeight: '100%',
                        padding: '0px',
                    },
                },
            },
        },
    },
    dialogDetails: {
        '& .MuiDialogContent-root': {
            maxHeight: '600px',
            height: '550px',
        },
        '& .MuiPaper-root': {
            '& .MuiDialogTitle-root': {
                padding: '12px 16px ',
            },
        },
    },
}))

const AdpEmployees = () => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>()
    const [employeesData, setEmployeesData] = useState<Employee[]>([])
    let { path } = useRouteMatch()
    const classes = useStyles()

    const fetchEmployees = useCallback(() => {
        setLoading(true)
        requestErrorHandler(
            getEmployeeList().then((res: any) => {
                setEmployeesData([...res])
                setLoading(false)
            }),
            true,
            () => {
                setLoading(false)
            }
        )
    }, [])

    useEffect(() => {
        setActiveRouteHeading('Employees')
    }, [setActiveRouteHeading])

    useEffect(() => {
        fetchEmployees()
    }, [fetchEmployees])

    return (
        <Grid container direction="row" style={{ height: '100%' }}>
            <CreateNewEmployeeModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                refreshData={fetchEmployees}
            />
            <Grid
                container
                md={5}
                sm={12}
                justify="flex-start"
                className={classes.content}
            >
                <Grid container item direction="column" alignItems="flex-start">
                    {loading ? (
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: '100%' }}
                        >
                            <Loader />
                        </Grid>
                    ) : (
                        <>
                            <Box my={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    disabled={false}
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                >
                                    New Employee
                                </Button>
                            </Box>
                            {employeesData.length > 0 && (
                                <ADPEmployeeListView
                                    items={employeesData}
                                    selectedEmployee={selectedEmployee}
                                    setSelectedEmployee={setSelectedEmployee}
                                />
                            )}
                        </>
                    )}
                </Grid>
            </Grid>

            <Hidden smDown implementation="js">
                <Grid
                    sm={1}
                    md={1}
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Divider orientation="vertical" flexItem />
                </Grid>
                <Grid item sm={6}>
                    <Switch>
                        <Route exact path={path}>
                            {!selectedEmployee ? (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="subtitle1">
                                        Select an employee to see the details
                                    </Typography>
                                </Grid>
                            ) : (
                                <ADPEmployeeDetails
                                    selectedData={selectedEmployee}
                                    setSelectedData={setSelectedEmployee}
                                    reloadState={fetchEmployees}
                                />
                            )}
                        </Route>
                    </Switch>
                </Grid>
            </Hidden>
        </Grid>
    )
}

export default AdpEmployees
