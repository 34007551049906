import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Box,
    Button,
    Grid,
    Checkbox,
    FormControlLabel,
    Hidden,
    useTheme,
    useMediaQuery,
    Typography,
    Paper,
    TextField,
    FormHelperText,
    FormControl
} from "@material-ui/core";
import * as Yup from "yup";
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import UiDialog from '../../common/ui/UiDialog';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Loader from '../../common/Loader';

import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import { Form, Formik, FormikProps, FormikValues, Field, FieldProps } from 'formik';
import { ApplicationStore } from '../../../models/store';
import { verifyScanProps, JournalEntryOptionsProps, JournalEntryInputProps, useLocalStyles } from './ScanUtils'
import { createJournalEntiesFromReceipts } from '../../../services/apiService/receiptsServices';

import { useStyles } from '../../../styles/styles';

import {
    addCategory
} from '../../../services/journalServices';
import CreateCategory from '../journal/CreateCategory';
import { formSubmitErrorHandler } from '../../../services/formService';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';



const toTimestamp = (strDate: string) => {
    let datum = Date.parse(strDate);
    return datum / 1000;
}

function VerifyScanResult({
    open,
    handleClose,
    accountId,
    businessId,
    filesToScan,
    scanResult,
    allowedFileTypesToPreview,
    getFileType,
    datasource,
    setListData
}: verifyScanProps) {
    const classes = useLocalStyles();
    const { colorTheme } = useThemeContext()
    const globalClasses = useStyles(colorTheme);
    const [initialValues, setInitialValues] = useState<JournalEntryInputProps>({
        debit: [],
        moveToDoc: []
    });
    const theme = useTheme();
    let fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [entryFormSubmitted, setEntryFormSubmitted] = useState(false);
    const [addnewCategoryOpen, setAddnewCategoryOpen] = useState(false);
    const [categoryFormSubmitted, setCategoryFormSubmitted] = useState(false);
    const [scannedFiles, setScannedFiles] = useState<any[]>([]);
    const [currentImg, setCurrentImg] = useState<string>('');
    const [checkBoxOpetionList, setCheckBoxOpetionList] = useState<JournalEntryOptionsProps>({
        moveToDoc: []
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [showClose, setShowClose] = React.useState(false);
    const [skippedItems, setSkippedItems] = React.useState<number[]>([]);
    const [entryRowMemory, setEntryRowMemory] = useState<any>();

    const maxSteps = scannedFiles.length;
    const scrollToPreview = useRef<HTMLDivElement>(null);
    const scrollToTop = useRef<HTMLDivElement>(null);
    const formikRef = useRef<FormikProps<FormikValues>>(null)

    const validationSchema = Yup.object({
        description: Yup.string(),
        debit: Yup.array().of(
            Yup.object().shape({
                isSkipped: Yup.boolean(),
                date: Yup.date().when('isSkipped', {
                    is: true,
                    then: Yup.date(),
                    otherwise: Yup.date().required('Date is required').nullable()
                }),
                account: Yup.string().when('isSkipped', {
                    is: true,
                    then: Yup.string(),
                    otherwise: Yup.string().required('Category is required')
                }),
                amount: Yup.number().when('isSkipped', {
                    is: true,
                    then: Yup.number(),
                    otherwise: Yup.number().moreThan(0, 'Amount should be more than 0').required('Amount required')
                })
            })
        )
    })

    const showFilePreview = useCallback((ind: number) => {
        if (filesToScan[ind]) {
            let reader = new FileReader();
            reader.onload = function (event: any) {
                setCurrentImg(event.target.result);
            };
            reader.readAsDataURL(filesToScan[ind]);
        }

    }, [filesToScan]);

    useEffect(() => {
        //combine files and scan result
        if (datasource.categoryOptions.length === 0) {
            let categoryOptions = Object.values(datasource.accountRecord);
            categoryOptions.forEach((c: any) => {
                if (c.parent) {
                    c.parent = categoryOptions.find(
                        (pc: any) => c.parent === pc.id
                    );
                }
            });
            datasource.categoryOptions = categoryOptions;

        }
        let sf: any[] = [];
        let journalData: JournalEntryInputProps = {
            debit: [],
            moveToDoc: []
        };
        filesToScan.forEach((file, ind) => {
            if (scanResult[ind] && !scanResult[ind].error) {
                sf.push({
                    ...scanResult[ind],
                    file: file
                });
                journalData.debit.push({
                    date: scanResult[ind].date ? toTimestamp(scanResult[ind].date) : scanResult[ind].date,
                    account: scanResult[ind].category?.id,
                    amount: scanResult[ind].amount,
                    isSkipped: false, // Default to false,
                });

            }
        });
        setInitialValues(journalData);
        setScannedFiles(sf);
    }, [datasource, datasource.categoryOptions, filesToScan, scanResult]);


    useEffect(() => {
        showFilePreview(activeStep);
    }, [activeStep, showFilePreview])

    const setCurrentFormFieldTouched = () => {
        let fields = ["date", "account", 'amount']
        fields.forEach((field) => {
            formikRef?.current?.setFieldTouched(`debit[${activeStep}][${field}]`, true, true);
        })
    }

    const validateCurrentForm = (form: any, step: number) => {
        setCurrentFormFieldTouched()
        if (form.errors?.debit && form.errors.debit[step]) {
            return false;
        }
        return true;
    }

    const handleBack = () => {
        setCurrentFormFieldTouched()
        showFilePreview(activeStep - 1);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = (form: any) => {
        showFilePreview(activeStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };


    const handleDeleteOptionChange = (event: any, form: any, fileId: string) => {
        let newCheckBoxOpetionList = checkBoxOpetionList;

        if (event.target.checked) {
            newCheckBoxOpetionList.moveToDoc.push(fileId);
            form.setFieldValue(event.target.name, fileId);
        }
        else {
            const index = newCheckBoxOpetionList.moveToDoc.indexOf(fileId);
            if (index > -1) {
                newCheckBoxOpetionList.moveToDoc.splice(index, 1);
                form.setFieldValue(event.target.name, '');
            }
        }
        setCheckBoxOpetionList(newCheckBoxOpetionList);
    };
    /**
     * Autocomplete Form Field Handling 
     * @param form formikRef || Field form Ref
     * @param item Value - ie. category
     * @param reason one of "createOption", "selectOption", "removeOption", "blur" or "clear".
     */
    const handleAutoCompleteOnChange = (form: any, item: any, reason: string) => {
        setCurrentFormFieldTouched()
        if (item?.id) {
            form.setFieldValue(
                `debit[${activeStep}][account]`,
                item.id
            );
        } else {
            // on first render set as empty
            form.setFieldValue(
                `debit[${activeStep}][account]`,
                ""
            );
        }
        if (reason === 'clear') {
            form.setFieldValue(
                `debit[${activeStep}][account]`,
                ""
            );
        }
    }

    const onSubmit = (data: any) => {
        let JournalDataToSubmit = new FormData();
        let orderOfData: number = 0;
        let counter = 0;
        data.debit.forEach((dataItem: any, ind: number) => {

            let utcTime = new Date(dataItem.date*1000);
            utcTime.setUTCHours(12, 0, 0, 0);
            dataItem.date = utcTime.getTime() / 1000;

            if (!dataItem.isSkipped) {
                JournalDataToSubmit.append('receipts[]', JSON.stringify({
                    order: orderOfData,
                    date: dataItem.date,
                    description: scannedFiles[ind].description,
                    debit: [dataItem],
                    id: ind
                }));
                orderOfData++;
                JournalDataToSubmit.append(`files[]`, scannedFiles[ind].file);
                if (data.moveToDoc.includes(ind)) {
                    JournalDataToSubmit.append(`moveToDoc[]`, counter + '');
                }
                counter++;
            }

        });
        setEntryFormSubmitted(true);
        createJournalEntiesFromReceipts(accountId, businessId, JournalDataToSubmit).then(async (res: any) => {
            let successResp = { success: 0, error: 0 }
            if (res.entries) {
                successResp.success = res.entries.length;
                if (res.entries.length !== orderOfData) { // check if count is same
                    successResp.error = orderOfData - res.entries.length;
                }
            }
            else {
                successResp.success = -1; // indicates error                
            }

            setEntryFormSubmitted(false);
            handleClose(successResp);
        }).catch((err: any) => {
            setEntryFormSubmitted(false);
        })
    }

    const handleScroll = (eleRef: any, showClose: boolean) => {
        if (eleRef && eleRef.current) {
            eleRef.current.scrollIntoView({ behavior: "smooth" });
            setShowClose(showClose);
        }
    }

    const menuLink = (
        { children, ...other }: any,
        index: number,
        formik: any
    ) => (
        <Paper {...other}>
            <Box borderBottom={`1px solid ${colorTheme.grey200}`}>
                <Button
                    fullWidth
                    startIcon={<AddIcon />}
                    onMouseDown={(event) => {
                        event.preventDefault()
                    }}
                    onClick={(event) => {
                        event.preventDefault()
                        setEntryRowMemory({ formik, index });
                        setAddnewCategoryOpen(true);
                    }}
                >
                    Create New Category
                </Button>
            </Box>
            {children}
        </Paper>
    );
    const handleCategoryClose = () => {
        setAddnewCategoryOpen(false);
    };

    // onsubmit category
    const onCategorySubmit = (data: any, { setFieldError }: any) => {
        setCategoryFormSubmitted(true);
        formSubmitErrorHandler(
            addCategory(data, accountId, businessId).then((res: any) => {
                setCategoryFormSubmitted(false);
                const newData = { [res?.id]: res };
                res.parent = datasource.accountRecord[res.parent];
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: { ...prev?.accountRecord, ...newData },
                    categoryOptions: prev.categoryOptions.concat(res),
                }));
                setTransCategory(
                    res,
                    entryRowMemory.index,
                    entryRowMemory.formik
                );
                setEntryRowMemory({});
                setAddnewCategoryOpen(false);
            }),
            () => {
                setCategoryFormSubmitted(false);
            },
            setFieldError
        );
    };
    const setTransCategory = (item: any, index: number, formik: any) => {
        if (item?.id) {
            //Reset the value
            const entryRow = formik.values.debit;
            entryRow[index]['account'] = '';
            formik.setFieldValue('debit', entryRow);
            //Set new value
            entryRow[index]['account'] = item.id;
            formik.setFieldValue('debit', entryRow);
        }
    };
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validateOnMount={true}
                innerRef={formikRef}
            >
                {

                    entriesForm => {
                        let totalSelectedScanCount = entriesForm.values.debit.filter((e: any) => !e.isSkipped).length;
                        return (
                            <UiDialog
                                fullScreen={fullScreen}
                                open={open}
                                handleClose={handleClose}
                                title='Review Scans'
                                size='md'
                                appBarTitle='Review Scans'
                                appBarSaveText='Create journals'
                                onAppBarSave={entriesForm.submitForm}
                                disableSaveButton={!totalSelectedScanCount || activeStep !== (maxSteps - 1)}
                                showLoaderOnSave={entryFormSubmitted}
                            >
                                <div className={''}>
                                    {initialValues.debit.length > 0 ? [(
                                        datasource.categoryOptions.length > 0 ? (
                                            <>
                                                <Form>
                                                    <Box>
                                                        <Grid container>
                                                            <div ref={scrollToTop}></div>
                                                            <Grid item xs={12} md={6}>
                                                                <Box p={2}>
                                                                    <Paper square elevation={0} className={''}>
                                                                        <Box my={2}>
                                                                            <Grid container>
                                                                                <Grid item xs={3} sm={3}>
                                                                                    <Button variant="outlined" color="secondary" onClick={handleBack} disabled={activeStep === 0}>
                                                                                        <Hidden smDown>
                                                                                            Previous
                                                                                        </Hidden>
                                                                                        <Hidden mdUp>
                                                                                            Back
                                                                                        </Hidden>

                                                                                    </Button>
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6}>
                                                                                    <Hidden smDown>
                                                                                        <Typography variant={'h5'} align='center'>{`Scan ${activeStep + 1} of ${maxSteps}`}</Typography>
                                                                                    </Hidden>
                                                                                    <Hidden mdUp>
                                                                                        <Typography variant={'h6'} align='center'>{`Scan ${activeStep + 1} of ${maxSteps}`}</Typography>
                                                                                    </Hidden>
                                                                                </Grid>
                                                                                <Grid container item xs={3} sm={3} justify="flex-end" >
                                                                                    <Button variant='contained' color="primary" onClick={() => {
                                                                                        if (validateCurrentForm(entriesForm, activeStep)) {
                                                                                            handleNext(entriesForm);
                                                                                        }

                                                                                    }} disabled={activeStep === maxSteps - 1}>
                                                                                        Next
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>

                                                                    </Paper>
                                                                    <Box>
                                                                        <Box my={5}>
                                                                            <span className={
                                                                                globalClasses.fileTypeIconStyle
                                                                            }>
                                                                                {getFileType(scannedFiles[activeStep].file.type)}</span>
                                                                            {scannedFiles[activeStep].file.name}
                                                                            <Hidden mdUp>
                                                                                <span className={`${globalClasses.linkText} ${globalClasses.pullRight}`} onClick={() => { handleScroll(scrollToPreview, true) }}>View</span>
                                                                            </Hidden>
                                                                        </Box>
                                                                        <Grid container justify="space-between" style={{ margin: "2em 0" }}>
                                                                            <Box className={`${entriesForm.values?.debit[activeStep]?.isSkipped ? classes.skippedFormEntry : ''}`}
                                                                                style={entriesForm.values?.debit[activeStep]?.isSkipped ? { pointerEvents: 'none' } : {}}
                                                                            >
                                                                                <Grid item xs={12}>
                                                                                    <Box className={classes.fieldContainer}>
                                                                                        <Field
                                                                                            name={`debit[${activeStep}][account]`}
                                                                                        >
                                                                                            {({
                                                                                                field,
                                                                                                form,
                                                                                                meta,
                                                                                            }: FieldProps<any>) => {
                                                                                                let hasError = meta.touched && meta.error ? true : false,
                                                                                                    formFieldValue = entriesForm.values?.debit[activeStep]?.account
                                                                                                return (
                                                                                                    <FormControl className='w-100'>
                                                                                                        <Autocomplete
                                                                                                            fullWidth
                                                                                                            className={classes.autocomplete}
                                                                                                            onChange={(
                                                                                                                event,
                                                                                                                item,
                                                                                                                reason
                                                                                                            ) => {
                                                                                                                handleAutoCompleteOnChange(form, item, reason);
                                                                                                            }}
                                                                                                            options={datasource?.categoryOptions.filter(
                                                                                                                (
                                                                                                                    c: any
                                                                                                                ) =>
                                                                                                                    !c.is_hidden &&
                                                                                                                    c.can_be_journal_entry_account
                                                                                                            )}
                                                                                                            value={formFieldValue ? formFieldValue : null}
                                                                                                            getOptionLabel={(
                                                                                                                option: any
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    typeof option ===
                                                                                                                    'string'
                                                                                                                ) {
                                                                                                                    const data =
                                                                                                                        datasource
                                                                                                                            ?.accountRecord[
                                                                                                                        option
                                                                                                                        ]
                                                                                                                    return data
                                                                                                                        ? data?.title
                                                                                                                        : ''
                                                                                                                } else {
                                                                                                                    return option.title
                                                                                                                }
                                                                                                            }}
                                                                                                            renderOption={(
                                                                                                                option
                                                                                                            ) => {
                                                                                                                let title
                                                                                                                if (
                                                                                                                    typeof option ===
                                                                                                                    'string'
                                                                                                                ) {
                                                                                                                    const data =
                                                                                                                        datasource
                                                                                                                            ?.accountRecord[
                                                                                                                        option
                                                                                                                        ]
                                                                                                                    title =
                                                                                                                        data
                                                                                                                            ? data?.title
                                                                                                                            : ''
                                                                                                                } else {
                                                                                                                    title =
                                                                                                                        option?.title
                                                                                                                }
                                                                                                                return (
                                                                                                                    <React.Fragment>
                                                                                                                        <div>
                                                                                                                            <Typography variant="body1">
                                                                                                                                {
                                                                                                                                    title
                                                                                                                                }
                                                                                                                            </Typography>

                                                                                                                            <Typography variant="caption">
                                                                                                                                {
                                                                                                                                    option
                                                                                                                                        .parent
                                                                                                                                        .title
                                                                                                                                }
                                                                                                                            </Typography>
                                                                                                                        </div>
                                                                                                                    </React.Fragment>
                                                                                                                )
                                                                                                            }}
                                                                                                            onOpen={() => {
                                                                                                                setCurrentFormFieldTouched()
                                                                                                            }}
                                                                                                            renderInput={(
                                                                                                                params
                                                                                                            ) => (
                                                                                                                <>
                                                                                                                    <Hidden
                                                                                                                        implementation="js"
                                                                                                                        smDown
                                                                                                                    >
                                                                                                                        <TextField
                                                                                                                            {...params}
                                                                                                                            error={hasError}
                                                                                                                            placeholder="Category"
                                                                                                                            variant="outlined"
                                                                                                                            data-cy='select-category-input'
                                                                                                                        />
                                                                                                                    </Hidden>
                                                                                                                    <Hidden
                                                                                                                        implementation="js"
                                                                                                                        mdUp
                                                                                                                    >
                                                                                                                        <TextField
                                                                                                                            {...params}
                                                                                                                            classes={{
                                                                                                                                root: classes.textFieldRoot,
                                                                                                                            }}
                                                                                                                            error={hasError}
                                                                                                                            label="Category"
                                                                                                                            variant="outlined"
                                                                                                                            data-cy='select-category-input'
                                                                                                                        />
                                                                                                                    </Hidden>
                                                                                                                </>
                                                                                                            )}
                                                                                                            PaperComponent={(
                                                                                                                child: any
                                                                                                            ) => {
                                                                                                                return menuLink(
                                                                                                                    child,
                                                                                                                    activeStep,
                                                                                                                    entriesForm
                                                                                                                )
                                                                                                            }}
                                                                                                        />

                                                                                                        <FormHelperText
                                                                                                            error={hasError}
                                                                                                            style={{ marginLeft: '12px' }}
                                                                                                        >
                                                                                                            {meta.touched ? meta.error
                                                                                                                : ''}
                                                                                                        </FormHelperText>
                                                                                                    </FormControl>
                                                                                                )
                                                                                            }}
                                                                                        </Field>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Box className={classes.fieldContainer}>
                                                                                        <UiFormControlSelection
                                                                                            type="date"
                                                                                            label='Date'
                                                                                            dateType='string'
                                                                                            disableDatePickerToolbar={true}
                                                                                            disableFutureDate={true}
                                                                                            fieldName={`debit[${activeStep}][date]`}
                                                                                            showFloatingLabel
                                                                                            placeholder={' '}

                                                                                        />
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Box className={classes.fieldContainer}>
                                                                                        <UiFormControlSelection
                                                                                            type="number"
                                                                                            step='any'
                                                                                            placeholder="Amount"
                                                                                            label="Amount"
                                                                                            fieldName={`debit[${activeStep}][amount]`}
                                                                                            showFloatingLabel
                                                                                        />
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Box>
                                                                            <Grid item xs={12}>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            name={`moveToDoc[${activeStep}]`}
                                                                                            checked={checkBoxOpetionList.moveToDoc.includes(scannedFiles[activeStep].id)}
                                                                                            onChange={(event) => { handleDeleteOptionChange(event, entriesForm, scannedFiles[activeStep].id); }}
                                                                                            value={scannedFiles[activeStep].id}
                                                                                        />
                                                                                    }
                                                                                    label={"Upload the image to the document center."}
                                                                                    disabled={entriesForm.values?.debit[activeStep]?.isSkipped}
                                                                                />
                                                                            </Grid>
                                                                            {entriesForm.values?.debit[activeStep]?.isSkipped}
                                                                            <Grid item xs={12}>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            checked={skippedItems.includes(activeStep)}
                                                                                            onChange={(event) => {
                                                                                                let skipped = skippedItems
                                                                                                if (event.target.checked) {
                                                                                                    skipped.push(activeStep)
                                                                                                    setSkippedItems(skipped)
                                                                                                } else {
                                                                                                    let filteredSteps = skipped.filter((item: number) => item !== activeStep)
                                                                                                    setSkippedItems(filteredSteps);
                                                                                                }
                                                                                                entriesForm.setFieldValue
                                                                                                    (
                                                                                                        `debit[${activeStep}][isSkipped]`,
                                                                                                        event.target.checked
                                                                                                    );
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label="Don't create a journal entry for this scan."
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Hidden smDown>
                                                                            <Grid item xs={12}>
                                                                                <Box>

                                                                                    <Grid container
                                                                                        spacing={1}
                                                                                        direction='row'
                                                                                        alignItems='center'
                                                                                        justify='flex-end'>
                                                                                        {entryFormSubmitted ? <Loader /> :
                                                                                            <>
                                                                                                <Button
                                                                                                    className={`${classes.marginRight}`}
                                                                                                    type='reset'
                                                                                                    variant='outlined'
                                                                                                    color='secondary'
                                                                                                    onClick={() =>
                                                                                                        handleClose({ success: -2 })
                                                                                                    }
                                                                                                >
                                                                                                    Cancel
                                                                                                </Button>

                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    type="submit"
                                                                                                    disabled={!totalSelectedScanCount || activeStep !== (maxSteps - 1)}
                                                                                                    data-cy='create-journal-entries-button'
                                                                                                >
                                                                                                    Create {totalSelectedScanCount} journal entries
                                                                                                </Button>
                                                                                            </>
                                                                                        }
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Hidden>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <div ref={scrollToPreview}></div>
                                                                <Box p={2}>
                                                                    <Grid container
                                                                        spacing={1}
                                                                        direction='row'
                                                                        alignItems='center'
                                                                        justify='center'>
                                                                        <Box>

                                                                            <Typography variant="h6" align="center">
                                                                                {showClose ?
                                                                                    <span
                                                                                        className={`${globalClasses.linkPointer} ${globalClasses.pullLeft}`} onClick={() => { handleScroll(scrollToTop, false) }}>
                                                                                        <ArrowUpwardIcon />
                                                                                    </span> : ''}
                                                                            </Typography>
                                                                            {allowedFileTypesToPreview.includes(scannedFiles[activeStep].file.type) ?
                                                                                (
                                                                                    <img className={classes.receiptImgPreview}
                                                                                        src={currentImg} alt={scannedFiles[activeStep].file.name} />
                                                                                ) : (

                                                                                    <Typography>Preview not available</Typography>

                                                                                )

                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Form>
                                            </>) : <p>Loading...</p>

                                    )] : <p>No files found</p>}
                                </div>
                            </UiDialog>
                        );
                    }
                }
            </Formik>
            <UiDialog
                open={addnewCategoryOpen}
                handleClose={handleCategoryClose}
                title='Add New Category'
                size='sm'
            >
                <CreateCategory
                    categoryFormSubmitted={categoryFormSubmitted}
                    accountParentList={datasource?.categoryOptions?.filter(
                        (c: any) => c.can_have_children
                    )}
                    onCategorySubmit={onCategorySubmit}
                    //initialCategoryValues={}
                    handleCategoryClose={handleCategoryClose}
                />
            </UiDialog>
        </>
    );
}
const mapStateToProps = ({ config, appData }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,

})
export default connect(mapStateToProps)(VerifyScanResult);