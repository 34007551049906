import { Link, Typography } from '@material-ui/core'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import LineChartWidget from '../../common/LineChartWidget'
import WidgetBox from '../../common/WidgetBox'
import MenuDropdown from '../../common/MenuDropdown'
import WidgetSkeletonLoader from '../../common/WidgetSkeletonLoader'
import { getIncomeStatementReport } from '../../../services/apiService'
import { ApplicationStore } from '../../../models'
import { connect } from 'react-redux'
import { currencyFormatter, isTrialEnded } from '../../../utils/appUtil'
import ExpensesModal from '../ExpensesModal'
import { useHistory } from 'react-router-dom'
import { useStyles } from '../../../styles/styles'
import LinkButton from '../../common/LinkButton'
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil'
import { IncomeStatementReportRoot } from '../../bookkeeping/reports/models/reports-interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import {
    EXPANCE_DATE_DROPDOWN_OPTIONS,
    expansesColors,
    findSpaceRegex,
    periodTextFormat,
} from './constants'
import { toRem16 } from '../../../styles/commonStyles'
import { COLORS } from '../../../variables/colors'
import EmptyState from './EmptyState'
import ExpensesEmpty from '../../../assets/icons-svg/ExpensesEmpty.svg'

function Expenses(props: any) {
    const [loading, setLoading] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(1)
    const [values, setValues] = useState<any>()
    const [lineChartValues, setLineChartValues] = useState<any>([])
    const [openExpensesModal, serOpenExpensesModal] = useState(false)
    const history = useHistory()
    const accountId = props.appData.current_account_id
    const businessId = props.appData.current_business_id

    const getPeriodText = (
        fromDate: number | undefined,
        toDate: number | undefined
    ) => {
        if (!fromDate || !toDate) {
            return
        }
        const fromDateInMs = fromDate * 1000
        const toDateInMs = toDate * 1000
        const convertedFromDate = convertUnixToGivenDateFormat(
            periodTextFormat,
            fromDateInMs
        )
        const convertedToDate = convertUnixToGivenDateFormat(
            periodTextFormat,
            toDateInMs
        )
        const periodText = `From ${convertedFromDate} to ${convertedToDate}`
        return periodText.replace(findSpaceRegex, '+')
    }
    const getDateAndTimeParams = useCallback(() => {
        const selectedDateType = EXPANCE_DATE_DROPDOWN_OPTIONS[selectedIndex]
        const getDateTillToday = true
        const { startDate, endDate } = getTimeParamsWithoutEST(
            selectedDateType,
            getDateTillToday
        )
        const startDateYmd = getDateInYmd(startDate!)
        const endDateYmd = getDateInYmd(endDate!)
        const currentTimeStamp = getCurrentTimeStamp()
        return {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        }
    }, [selectedIndex])

    useEffect(() => {
        if (isTrialEnded()) {
            setValues(undefined)
            setLoading(false)
            return
        }
        const {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        } = getDateAndTimeParams()
        const periodText = getPeriodText(startDate, endDate)
        const classIds = ''
        if (!startDate || !endDate || !periodText) {
            return
        }

        const sortItemsAndSetChartData = (data: IncomeStatementReportRoot) => {
            data.expenses.children[0]?.children.sort(
                (expense1: any, expense2: any) =>
                    Number.parseFloat(expense2.balance) -
                    Number.parseFloat(expense1.balance)
            )
            setLineChartValues(
                data.expenses.children[0]?.children
                    .slice(0, 3)
                    .map((val: any, index: number) => {
                        return {
                            ...val,
                            amount: Number.parseFloat(val.balance),
                            color: expansesColors[index],
                        }
                    })
            )
        }
        setLoading(true)
        getIncomeStatementReport(
            accountId,
            businessId,
            startDateYmd,
            endDateYmd,
            periodText,
            currentTimeStamp,
            classIds
        )
            .then((res: unknown) => {
                const data = res as IncomeStatementReportRoot
                setValues(data)
                sortItemsAndSetChartData(data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setValues(undefined)
            })
    }, [accountId, businessId, getDateAndTimeParams])

    const getRedirectionUrl = (
        startDateTimeStamp: number,
        endDateTimeStamp: number,
        startDateYmd: string,
        endDateYmd: string,
        time: number
    ) => {
        const url = `/reports/income-statement/${startDateTimeStamp}/${endDateTimeStamp}/${startDateYmd}/${endDateYmd}/${time}`
        return url
    }
    const redirectToIncomeStatementReports = () => {
        const {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        } = getDateAndTimeParams()
        if (!startDate || !endDate) {
            return
        }
        const redirectionUrl = getRedirectionUrl(
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp
        )
        history.push(redirectionUrl)
    }

    function ChartSummary() {
        const { colorTheme } = useThemeContext()
        let commonClasses = useStyles(colorTheme)
        return (
            <Fragment>
                <div>
                    <Typography
                        component={Link}
                        color="textPrimary"
                        className={commonClasses.dashboardTextLink}
                        onClick={() => redirectToIncomeStatementReports()}
                        variant="h4"
                    >
                        {currencyFormatter.format(values.expenses.balance)}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        Total Spent
                    </Typography>
                </div>
                <div
                    style={{
                        border: `${toRem16(1)} solid ${COLORS.grey200}`,
                        borderRadius: toRem16(4),
                        padding: `${toRem16(6)} ${toRem16(8)}`,
                        top: toRem16(5),
                        position: 'relative',
                    }}
                >
                    <Typography
                        component={Link}
                        color="textPrimary"
                        className={commonClasses.dashboardTextLink}
                        onClick={() =>
                            history.push(
                                '/transactions?search=type=uncategorized'
                            )
                        }
                        variant="button"
                    >
                        {currencyFormatter.format(
                            values.uncategorized_amount ?? 0
                        )}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        Uncategorized
                    </Typography>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {openExpensesModal ? (
                <ExpensesModal
                    open={openExpensesModal}
                    handleClose={() => serOpenExpensesModal(false)}
                    getRedirectionUrl={getRedirectionUrl}
                    preSelectedIndex={selectedIndex}
                />
            ) : (
                ''
            )}
            <WidgetBox
                heading="Expenses"
                actionControl={
                    props.trialEnded ? (
                        ''
                    ) : (
                        <MenuDropdown
                            options={EXPANCE_DATE_DROPDOWN_OPTIONS}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            disabled={loading}
                        />
                    )
                }
            >
                {loading ? (
                    <WidgetSkeletonLoader
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    />
                ) : values && lineChartValues ? (
                    <Fragment>
                        <LineChartWidget
                            values={lineChartValues}
                            summary={<ChartSummary />}
                            reduce={3}
                            handleAmountClick={redirectToIncomeStatementReports}
                        ></LineChartWidget>
                        {values?.expenses?.children[0]?.children?.length > 3 ? (
                            <LinkButton
                                onClick={() => serOpenExpensesModal(true)}
                            >
                                View All Categories
                            </LinkButton>
                        ) : (
                            ''
                        )}
                    </Fragment>
                ) : (
                    <EmptyState
                        alt="Expanses empty image"
                        src={ExpensesEmpty}
                        text="Categorizing transactions allows you to have a clear picture of where your money is going."
                    />
                )}
            </WidgetBox>
        </Fragment>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    trialEnded: state.auth.isTrialEnded,
})
export default connect(mapStateToProps)(Expenses)
