import React, { useState } from 'react'
import {
    Button,
    makeStyles,
    Theme,
    Grid
} from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import UiDialog from '../../../common/ui/UiDialog';
import UiButton from '../../../common/ui/UiButton'
import Icon from '../../../../components/common/Icon';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
           
        }
    },
    keepButton: {
        margin: '0rem 1rem 0rem 0rem !important',
    },
    bodyContent: {
        display: 'flex',
        alignItems: 'center',
        margin: '0rem 0rem .5rem 0rem !important',
        [theme.breakpoints.down('xs')]:{
            flexWrap: 'wrap'
        }
    },
    title: {
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1rem'
        }
    }
}))

interface ConfirmationDialogProps {
    open: boolean,
    handleClose: (result?: any) => void
}

const DiscardChangesModal = (
    {open,
    handleClose}: ConfirmationDialogProps
) => {
    const style = useStyles();

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size={'sm'}
            hideTitleSection
        >
            <div className={style.bodyContent}>
                <div className={style.title}>
                    <UiText
                        variant="car_100" 
                        weight="medium_500">
                        Discard unsaved changes?
                    </UiText>
                </div>
                <div>
                    <UiButton
                        customClass={style.keepButton}
                        btnType="tertiary"
                        label="Keep Editing"
                        disabled={false}
                        handleClick={() => {
                            handleClose(false);
                        }} 
                    />  
                    <UiButton 
                        btnType="secondary"
                        label="Discard"
                        disabled={false}
                        icon={<Icon icon="delete" />}
                        handleClick={() => {
                            handleClose(true);
                        }} 
                    /> 
                </div>
            </div>
        </UiDialog>
    )
}

export default DiscardChangesModal;