import React, { Fragment, useRef, useState } from 'react'
import {
    Divider,
    FormHelperText,
    Hidden,
    makeStyles,
    Theme,
} from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { AgreementData } from './AgreementData'
import { toRem16 } from '../../../../styles/commonStyles'
import UiButton from '../../../common/ui/UiButton'
import { Form, Formik } from 'formik'
import { TOSValidationSchema } from '../ValidationSchema/TOSValidationSchema'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { useADPCompanyContext } from '../../provider/CompanyProvider'
import {
    acceptTOS,
    companyOnBoardingStatus,
} from '../../../../services/apiService/adp-payroll/company'
import Loader from '../../../common/Loader'
import { useDispatch } from 'react-redux'
import { showAlert, showInfo } from '../../../../store/actions/feedback'
import UiAlert from '../../../common/ui/UiAlert'
import {
    ADPCompanyDetails,
    TosAgreement,
    OnboardingStepInfo,
} from '../../../../models/adp-payroll/company'
import SignatureCanvas from 'react-signature-canvas'
import { loadPostAuthData } from '../../../../store/actions/appData'
import FormHeaderText from '../../../payroll/common/FormHeaderText'
import useDeviceSize from '../../../../hooks/useDeviceSize'
import { useStyles } from '../CompanyCommonStyles'
import { ERROR_MESSAGE_MAP } from '../../constants/adp-payroll.const'

const useAgreementStyles = makeStyles<Theme, ThemeColors>((theme: any) => ({
    containerStyle: {
        marginTop: '1rem',
        maxHeight: 'calc(100svh - 18rem)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '4rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
        },
    },
    dividerStyle: {
        marginBottom: '1rem',
    },
    checkboxStyling: {
        marginBottom: '0.5rem',
    },
    lastIndex: {
        marginBottom: '2rem',
    },
    agreementText: {
        textAlign: 'justify',
        paddingRight: '1rem',
    },
    signatureField: {
        '& .MuiOutlinedInput-input': {
            height: '.5rem',
            width: toRem16(330),
            fontFamily: 'Fasthand',
            fontSize: '1.5rem',
        },
    },
    submitButtonStyle: {
        marginTop: '1.5rem',
        marginBottom: '2rem',
    },
    warningMargin: {
        marginTop: '1.5rem',
    },
    signaturePadStyle: {
        border: '1px solid black',
        marginRight: '1rem',
    },
    disableStyle: {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}))

const TOS_INITIAL_VALUES = {
    agreement_1: false,
    agreement_2: false,
    agreement_3: false,
    agreement_4: false,
    agreement_5: false,
    agreement_6: false,
    agreement_7: false,
}

const TOS_UPDATED = {
    agreement_1: true,
    agreement_2: true,
    agreement_3: true,
    agreement_4: true,
    agreement_5: true,
    agreement_6: true,
    agreement_7: true,
}
const Agreements = ({
    refreshSteps,
    openPayrollPopup,
    refetchCompanyStatus,
    goBack,
}: {
    refreshSteps: () => void
    openPayrollPopup: () => void
    refetchCompanyStatus: () => void
    goBack: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const classes = useAgreementStyles(colorTheme)
    const styles = useStyles(colorTheme)
    const [loading, setLoading] = useState<boolean>(false)
    const {
        companyDetails,
        companyStepInfo,
        setRefetchCompany,
        isCompanyOnboarding,
        setRecallStepsData,
        isCompanyActive,
        formikRef,
    }: {
        companyDetails: ADPCompanyDetails
        companyStepInfo: OnboardingStepInfo
        setRefetchCompany: React.Dispatch<React.SetStateAction<boolean>>
        isCompanyOnboarding: boolean
        setRecallStepsData: React.Dispatch<React.SetStateAction<boolean>>
        isCompanyActive: boolean
        formikRef: any
    } = useADPCompanyContext()
    const dispatch = useDispatch()
    const isAgreementStepCompleted =
        companyStepInfo?.onboarding_steps_status?.tos_acceptance
    const isBankStepCompleted =
        companyStepInfo?.onboarding_steps_status?.bank_account_addition
    const isADPcompanyCreated = companyStepInfo?.adp_company_created || false
    const tosData: TosAgreement = companyDetails?.tosAgreement
    const signPad: any = useRef()
    let initialValues =
        isAgreementStepCompleted && isADPcompanyCreated
            ? { signature: tosData?.signature, ...TOS_UPDATED }
            : { signature: '', ...TOS_INITIAL_VALUES }

    const disableCompanyEdit = isCompanyOnboarding || isCompanyActive
    // if the tos flow completed then no need to fetch the API.
    // if tos flow not completed and we post the data then fetch and adp_created = true then show the popup
    const onSubmit = (values: any) => {
        setLoading(true)
        submitTOS(values)
    }

    const dispatchError = (errors: any) => {
        dispatch(
            showInfo({
                infoData: (
                    <Fragment>
                        <ul>
                            {errors?.map((err: any) => (
                                <li>{getErrorMessage(err)}</li>
                            ))}
                        </ul>
                    </Fragment>
                ),
            })
        )
    }
    const getErrorMessage = (err: any) => {
        const isCustomErrorMessage: any = ERROR_MESSAGE_MAP.find(function (item){
            return item.vocabulary === err?.vocabulary && err?.message.indexOf(item.criteria) != 1;
        });

        if(isCustomErrorMessage !== undefined){
            return isCustomErrorMessage.error_message;
        }

        return err.message;
    }
    const submitTOS = (values: any) => {
        acceptTOS({
            legalConfirmation: true,
            signature: values.signature,
        })
            .then((res: any) => {
                if (res) {
                    refreshSteps()
                    getCompanyStatus()
                    setRefetchCompany(true)
                    setRecallStepsData(true)
                    refetchCompanyStatus()
                    dispatch(loadPostAuthData())
                }
            })
            .catch((error) => {
                setLoading(false)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            error?.message ||
                            error?.statusText ||
                            'Something went wrong',
                    })
                )
                error?.errors?.length && dispatchError(error?.errors)
            })
    }

    const getCompanyStatus = () => {
        companyOnBoardingStatus().then((res: any) => {
            if (res) {
                setLoading(false)
                if (res.status === 'Active') {
                    openPayrollPopup()
                }
            }
        })
    }
    const { isSmDevice } = useDeviceSize()

    return (
        <div className={''}>
            <FormHeaderText heading="Agreements" hideOnMobile={isSmDevice} />

            {!isBankStepCompleted && (
                <div className={classes.warningMargin}>
                    <UiAlert
                        customContent={
                            <div className={classes.dividerStyle}>
                                <UiText
                                    variant="hatchback_125"
                                    weight="semi_bold_600"
                                >
                                    Missing Information
                                </UiText>
                                <UiText
                                    variant="car_100"
                                    className={classes.dividerStyle}
                                >
                                    it looks like we are missing bank details
                                    about your company. <br /> Once you complete
                                    those sections you can agree to the terms.{' '}
                                    <br />
                                </UiText>
                                <UiText variant="car_100">
                                    - Banking Information
                                </UiText>
                            </div>
                        }
                    />
                </div>
            )}

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={TOSValidationSchema}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ values, submitForm, setFieldValue, errors }) => {
                    return (
                        <Form>
                            <div className={classes.containerStyle}>
                                {AgreementData(companyDetails).map(
                                    (agreement, index) => {
                                        let isLastIndex =
                                            Object.keys(values).length - 1 ===
                                            index
                                        return (
                                            <Fragment
                                                key={`${agreement.field}-${index}`}
                                            >
                                                <UiText
                                                    className={
                                                        classes.agreementText
                                                    }
                                                >
                                                    {agreement.title}
                                                </UiText>
                                                <div
                                                    className={
                                                        classes.checkboxStyling
                                                    }
                                                >
                                                    <UiFormControlSelection
                                                        inlineLable={
                                                            <UiText weight="medium_500">
                                                                {
                                                                    agreement.label
                                                                }
                                                            </UiText>
                                                        }
                                                        type="checkbox"
                                                        fieldName={
                                                            agreement.field
                                                        }
                                                        checkboxColor={
                                                            'primary'
                                                        }
                                                        required={true}
                                                    />
                                                </div>
                                                {isLastIndex ? (
                                                    <div
                                                        className={
                                                            classes.lastIndex
                                                        }
                                                    ></div>
                                                ) : (
                                                    <Divider
                                                        variant="fullWidth"
                                                        className={
                                                            classes.dividerStyle
                                                        }
                                                    />
                                                )}
                                            </Fragment>
                                        )
                                    }
                                )}
                                {!disableCompanyEdit && (
                                    <div>
                                        <div className={classes.dividerStyle}>
                                            We’ll need your signature to confirm
                                            that you accept the terms of this
                                            agreement. <br />
                                            <br /> By providing your signature
                                            below, you agree to our Terms of
                                            Service and confirm that all the
                                            info you provided above is accurate.
                                        </div>
                                        <div
                                            className={
                                                classes.signaturePadStyle
                                            }
                                        >
                                            <SignatureCanvas
                                                penColor="black"
                                                ref={(ref) => {
                                                    signPad.current = ref
                                                }}
                                                canvasProps={{
                                                    width: '500',
                                                    height: 130,
                                                    className: 'sigCanvas',
                                                }}
                                                onEnd={(event) =>
                                                    setFieldValue(
                                                        'signature',
                                                        signPad.current.toDataURL()
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className={classes.warningMargin}>
                                            <UiButton
                                                btnType="primary"
                                                label="Clear"
                                                handleClick={() => {
                                                    signPad.current.clear()
                                                    setFieldValue(
                                                        'signature',
                                                        ''
                                                    )
                                                }}
                                            />
                                        </div>
                                        <FormHelperText
                                            error={!!errors.signature}
                                        >
                                            {errors.signature}
                                        </FormHelperText>

                                        <Hidden smDown>
                                            <div
                                                className={
                                                    classes.submitButtonStyle
                                                }
                                            >
                                                {loading ? (
                                                    <Loader
                                                        justifyContent="flex-start"
                                                        size={30}
                                                    />
                                                ) : (
                                                    <UiButton
                                                        btnType="tertiary"
                                                        handleClick={() => {
                                                            setFieldValue(
                                                                'signature',
                                                                signPad.current.toDataURL()
                                                            )
                                                            submitForm()
                                                        }}
                                                        label="Submit Payroll"
                                                        fullWidth={isSmDevice}
                                                    />
                                                )}
                                            </div>
                                        </Hidden>
                                    </div>
                                )}
                            </div>
                            <Hidden mdUp>
                                <div className={styles.nextPrevContainer}>
                                    {loading ? (
                                        <Loader
                                            justifyContent={isSmDevice ? "center" :"flex-start"}
                                            size={30}
                                        />
                                    ) : (
                                        <>
                                            <UiButton
                                                btnType="tertiary"
                                                handleClick={() => {
                                                    setFieldValue(
                                                        'signature',
                                                        signPad.current.toDataURL()
                                                    )
                                                    submitForm()
                                                }}
                                                label="Submit Payroll"
                                                fullWidth
                                                customClass={styles.nxtBtn}
                                            />
                                            <UiButton
                                                btnType="hyperlink"
                                                label="Previous"
                                                customClass={styles.nxtBtn}
                                                fullWidth
                                                handleClick={goBack}
                                            />
                                        </>
                                    )}
                                </div>
                            </Hidden>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default Agreements
