import React, { useState, useRef } from 'react'
import {
    Button,
    Grid,
    IconButton,
    Box,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { FormikProps, FormikValues } from 'formik'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import FilterForm from './FilterForm'
import UiButton from '../../common/ui/UiButton'
import { TripQueryObject } from '../../../models'
import { defaultQueryObject, getFilterChips } from './TripUtil'
import { useHistory } from 'react-router-dom'
import { useTripsContext } from './Provider/TripsProvider'

type Props = {
    open: boolean
    handleClose: () => void
    queryObject: TripQueryObject
    setQueryObject: React.Dispatch<React.SetStateAction<TripQueryObject>>
    setChipsToDisplay: React.Dispatch<
        React.SetStateAction<
            {
                label: string
                key: keyof TripQueryObject
            }[]
        >
    >
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        mr2: {
            marginRight: theme.spacing(2),
        },
        cover: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(200, 200, 200, .5)',
            zIndex: 1,
        },
    }
})

const FilterOverlay = ({
    open,
    handleClose,
    queryObject,
    setQueryObject,
    setChipsToDisplay,
}: Props) => {
    const { allVehicles, allLocations, allPurposes } = useTripsContext()
    const history = useHistory()
    const classes = useStyles()
    const [resetKey, setResetKey] = useState(0)

    const onSubmit = (formData: TripQueryObject) => {
        const filterChips = getFilterChips(formData)
        setQueryObject(formData)
        setChipsToDisplay(filterChips)
        history.push('/mileage/trips')
    }

    const formikRef = useRef<FormikProps<FormikValues>>(null)

    const resetFields = () => {
        Object.keys(defaultQueryObject).forEach((fieldName) => {
            formikRef?.current?.setFieldValue(
                fieldName,
                defaultQueryObject?.[fieldName as keyof TripQueryObject]
            )
        })
    }

    const handleReset = () => {
        formikRef?.current?.resetForm()
        resetFields()
        setQueryObject(defaultQueryObject)
        setChipsToDisplay(getFilterChips(defaultQueryObject))
        setResetKey((prev) => prev + 1)
    }

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size="sm"
            disableBackdropClick={true}
            titleNode={
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <UiText>Filters</UiText>
                        <UiButton
                            btnType="hyperlink"
                            label="Clear all"
                            handleClick={() => {
                                handleReset()
                            }}
                        />
                    </Box>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </>
            }
            actions={
                <Grid justify="flex-end" container>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.mr2}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            formikRef?.current?.submitForm()
                            handleClose()
                        }}
                    >
                        Apply Filters
                    </Button>
                </Grid>
            }
        >
            <FilterForm
                onSubmit={onSubmit}
                vehicles={allVehicles}
                locations={allLocations}
                purposes={allPurposes}
                formikRef={formikRef}
                queryObject={queryObject}
                resetKey={resetKey}
            />
        </UiDialog>
    )
}

export default FilterOverlay
