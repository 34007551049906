import { toRem16 } from '../../../../../styles/commonStyles'
import { COLORS } from '../../../../../variables/colors'
import UiText from '../../../../common/ui/UiText'
import {
    Assets,
    BalanceSheetReportRoot,
    Capital,
    IncomeStatementReportRoot,
    Liabilities,
} from '../../models/reports-interface'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { currencyFormatter } from '../../../../../utils/appUtil'
import LinkButton from '../../../../common/LinkButton'
import { useHistory } from 'react-router-dom'
import { reportTypes } from './ComparisionReport'
import { REPORT_ITEM_ASSETS } from '../../generated-reports/BalanceSheetDetailedReport'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'

interface BalanceReportsListDataProps {
    reportsData: BalanceSheetReportRoot,
    startDate?: any,
    endDate?: any,
}
interface IncomReportsListDataProps {
    reportsData: IncomeStatementReportRoot,
    reportType?: string,
    startDate?: any,
    endDate?: any,
}

interface GenerateReportsProps {
    items: Assets | Liabilities | Capital | undefined,
    reportType?: string,
    startDate?: any,
    endDate?: any
}
interface GenerateTotalSectionProps {
    reportsData: BalanceSheetReportRoot | undefined
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        balanceSheetReports: {
            '& .reportContainer': {
                margin: '0 1.5rem 6rem 1.5rem',
            },
            '& .l1Title': {
                borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
                margin: '1rem 0 0 0',
                paddingBottom: toRem16(10),
                fontSize: toRem16(24),
            },
            '& .withNoBorderl1Title': {
                margin: '1rem 0 0 0',
                paddingBottom: toRem16(10),
                fontSize: toRem16(24),
            },
            '& button.level3ItemTitle': {
                color: colorTheme => colorTheme.primaryBlack,
                textDecoration: 'underline',
                fontSize: toRem16(15),
            },
            '& button.level4ItemTitle': {
                color: colorTheme => colorTheme.primaryBlack,
                textDecoration: 'underline',
                fontSize: toRem16(15),
            },
            '& .l2Title': {
                borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
                padding: '0 1rem',
                margin: '0',
                paddingTop: toRem16(10),
                paddingBottom: toRem16(10),
            },
            '& .level3Item': {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0.3rem 1rem 0.3rem 2rem',
                alignItems: 'center',
                borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
                '& .level3ItemTitle': {
                    padding: '0.5rem 0',
                },
                '& a.level3ItemTitle': {
                    color: colorTheme => colorTheme.black200,
                },
                '& button': {
                    minWidth: 0,
                },
            },
            '& .level3ParentWithChildren': {
                background: colorTheme => colorTheme.blue500,
                borderRadius: toRem16(5),
                margin: '1rem 0 0 0',
            },
            '& .level3ItemWithChildren': {
                borderBottom: 0,
                padding: '0.6rem 1rem 0.6rem 2rem',
            },
            '& .level4ItemContainer': {
                '& .level4Item:not(:last-child)': {
                    borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
                },
            },

            '& .level4Item': {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0.6rem 10rem 0.6rem 2rem',
                margin: '0 1rem',
                alignItems: 'center',

                '& .level4ItemTitle': {
                    padding: '0.5rem 0',
                },
                '& a.level4ItemTitle': {
                    color: colorTheme => colorTheme.black200,
                },
                '& button': {
                    minWidth: 0,
                },
            },
            '& .priceWaste': {
                color: colorTheme => colorTheme.red100,
            },
            '& .emptyTitle': {
                height: toRem16(24),
            },
            '& .l2Total': {
                fontSize: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0.5rem 1rem 0 2rem',
            },
            '& .l2HeaderNoChild': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '1rem 1rem 0 2rem',
                borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
                '& h2': {
                    fontSize: '1rem',
                },
            },
            '& .level1TotalBalanceSheet': {
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: colorTheme => `${toRem16(2)} solid ${colorTheme.grey200}`,
                margin: '3rem 0',
                padding: '0.7rem 1rem 0 0',
            },
            '& .level1TotalIncomeStatement': {
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: colorTheme => `${toRem16(2)} solid ${colorTheme.grey200}`,
                margin: '3rem 0',
                padding: '0.7rem 1rem 0 0',
            },
            '& .level1TotalBalanceSheetAssets':{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: colorTheme => `${toRem16(4)} solid ${colorTheme.grey400}`,
                margin: '3rem 0',
                padding: '0.7rem 1rem 0 0',
            },
            '& .level2ParentContainer': {
                '& .Assetslevel2Items:not(:first-child)': {
                    margin: '3rem 0 0 0',
                },
                '& .Liabilitieslevel2Items:not(:first-child)': {
                    margin: '3rem 0 0 0',
                },
                '& .Capitallevel2Items:not(:first-child)': {
                    margin: '3rem 0 0 0',
                },
            },
            '& .reportContainer > div.Assetslevel2Items:first-child': {
                margin: '0',
            },
            '& .level2WithoutChildrenBalanceSheet':{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: colorTheme => `${toRem16(2)} solid ${colorTheme.grey200}`,
                padding: '1rem 0',
                margin: '0 0 2rem 0'
            },
            '& .level2WithoutChildrenBalanceSheetAssets':{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: colorTheme => `${toRem16(4)} solid ${colorTheme.grey400}`,
                padding: '1rem 0',
                margin: '0 0 2rem 0'
            },
            '& .level2WithoutChildrenIncomeStatement':{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: colorTheme => `${toRem16(2)} solid ${colorTheme.grey200}`,
                padding: '1rem 0',
                margin: '0 0 2rem 0'
            },
            '& .totalLiabilities': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: toRem16(18),
                padding: '1rem 1rem 0 0',
                borderTopWidth: toRem16(4),
                borderTopStyle: 'solid',
                borderTopColor: colorTheme => colorTheme.grey400,
            },
            '& .level4Total': {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0.5rem 0 0.5rem 0.6rem',
                margin: '0 1rem',
                borderTop: colorTheme => `${toRem16(2)} solid ${colorTheme.grey200}`,
                '& p': {
                    padding: '0.8rem 0 0.8rem 0',
                },
            },
        },
    })
)
function GenerateTotalSection(props: GenerateTotalSectionProps) {
    const { reportsData } = props
    if (!reportsData) return null
    return (
        <UiText
            weight="medium_500"
            className="totalLiabilities"
            typographyVariant="h3"
        >
            <span>
                Total {reportsData.liabilities.title} and{' '}
                {reportsData.capital.title}
            </span>
            <UiText
                weight="bold_700"
                className={
                    parseFloat(reportsData.total_liabilities_and_capital) < 0
                        ? 'priceWaste'
                        : ''
                }
            >
                {currencyFormatter.format(
                    parseFloat(reportsData.total_liabilities_and_capital)
                )}
            </UiText>
        </UiText>
    )
}
const GenerateReportsTable = (props: GenerateReportsProps) => {
    const { items, reportType, startDate, endDate } = props
    const history = useHistory()
    const navigateToTransactionsByAccount = (accountId: string) => {
        history.push({
            pathname: '/reports',
            search: `name=transactions&account=${accountId}&startDate=${startDate}&endDate=${endDate}`,
        })
    }
  
    const getClassNameForLevel1Total = (itemName : string) =>{
        if(itemName === REPORT_ITEM_ASSETS){
            return 'level1TotalBalanceSheetAssets';
        }
        if( reportType === reportTypes.INCOME_STATEMENT){
            return 'level1TotalIncomeStatement';
        }
        return 'level1TotalBalanceSheet';
    }
    const getClassNameForLevel1TotalWithoutChildren = (itemName : string) =>{
        if(itemName === REPORT_ITEM_ASSETS){
            return 'level2WithoutChildrenBalanceSheetAssets';
        }
        if( reportType === reportTypes.INCOME_STATEMENT){
            return 'level2WithoutChildrenIncomeStatement';
        }
        return 'level2WithoutChildrenBalanceSheet';
    }
    if (!items) return null
    return (
        <>
            {items.children.length > 0 ? (
                <div className="level2ParentContainer">
                    {items.children.map(
                        (
                            l2Items: Assets | Liabilities | Capital,
                            index: number
                        ) => {
                            return (
                                <div
                                    key={index}
                                    className={`${items.title}level2Items`}
                                >
                                    {l2Items.children.length > 0 ? (
                                        <div className="level2Item">
                                            <UiText
                                                variant="hatchback_125"
                                                weight="medium_500"
                                                className="l2Title"
                                                typographyVariant="h3"
                                            >
                                                {l2Items.title}
                                            </UiText>
                                            {l2Items.children.map(
                                                (
                                                    l3Items:
                                                        | Assets
                                                        | Liabilities
                                                        | Capital,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={
                                                                l3Items.children
                                                                    .length > 0
                                                                    ? 'level3Parent level3ParentWithChildren'
                                                                    : 'level3Parent'
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    l3Items
                                                                        .children
                                                                        .length >
                                                                    0
                                                                        ? 'level3Item level3ItemWithChildren'
                                                                        : 'level3Item'
                                                                }
                                                            >
                                                                {l3Items.account_id && (
                                                                    <LinkButton
                                                                        className="level3ItemTitle"
                                                                        onClick={() => {
                                                                            navigateToTransactionsByAccount(
                                                                                l3Items.account_id
                                                                            )
                                                                        }}
                                                                    >
                                                                        <UiText weight="regular_400">
                                                                            {
                                                                                l3Items.title
                                                                            }
                                                                        </UiText>
                                                                    </LinkButton>
                                                                )}
                                                                {!l3Items.account_id && (
                                                                    <span className="level3ItemTitle">
                                                                        {
                                                                            l3Items.title
                                                                        }
                                                                    </span>
                                                                )}
                                                                {!l3Items
                                                                    .children
                                                                    .length && (
                                                                    <span
                                                                        className={
                                                                            parseFloat(
                                                                                l3Items.balance
                                                                            ) <
                                                                            0
                                                                                ? 'priceWaste'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        {currencyFormatter.format(
                                                                            parseFloat(
                                                                                l3Items.balance
                                                                            )
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="level4ItemContainer">
                                                                {l3Items.children.map(
                                                                    (
                                                                        l4Item:
                                                                            | Assets
                                                                            | Liabilities
                                                                            | Capital,
                                                                        index: number
                                                                    ) => {
                                                                        return (
                                                                            l4Item.account_id && (
                                                                                <div
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="level4Item"
                                                                                >
                                                                                    <LinkButton
                                                                                        onClick={() => {
                                                                                            navigateToTransactionsByAccount(
                                                                                                l4Item.account_id
                                                                                            )
                                                                                        }}
                                                                                        className="level4ItemTitle"
                                                                                    >
                                                                                        <UiText weight="regular_400">
                                                                                            {
                                                                                                l4Item.title
                                                                                            }
                                                                                        </UiText>
                                                                                    </LinkButton>
                                                                                    <span
                                                                                        className={
                                                                                            parseFloat(
                                                                                                l4Item.balance
                                                                                            ) <
                                                                                            0
                                                                                                ? 'priceWaste'
                                                                                                : ''
                                                                                        }
                                                                                    >
                                                                                        {currencyFormatter.format(
                                                                                            parseFloat(
                                                                                                l4Item.balance
                                                                                            )
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    }
                                                                )}
                                                            </div>
                                                            {l3Items.children
                                                                .length > 0 && (
                                                                <div className="level4Total">
                                                                    <UiText
                                                                        variant="motorcycle_90"
                                                                        weight="medium_500"
                                                                    >
                                                                        Total{' '}
                                                                        {
                                                                            l3Items.title
                                                                        }
                                                                    </UiText>
                                                                    <UiText
                                                                        weight="bold_700"
                                                                        className={
                                                                            parseFloat(
                                                                                l3Items.balance
                                                                            ) <
                                                                            0
                                                                                ? 'priceWaste level4TotalValue'
                                                                                : 'level4TotalValue'
                                                                        }
                                                                    >
                                                                        {currencyFormatter.format(
                                                                            parseFloat(
                                                                                l3Items.balance
                                                                            )
                                                                        )}
                                                                    </UiText>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            )}
                                            <div className="l2Total">
                                                <UiText
                                                    weight="medium_500"
                                                    variant="motorcycle_90"
                                                >
                                                    Total {l2Items.title}
                                                </UiText>
                                                <UiText weight="bold_700">
                                                    <span
                                                        className={
                                                            parseFloat(
                                                                l2Items.balance
                                                            ) < 0
                                                                ? 'priceWaste'
                                                                : ''
                                                        }
                                                    >
                                                        {currencyFormatter.format(
                                                            parseFloat(
                                                                l2Items.balance
                                                            )
                                                        )}
                                                    </span>
                                                </UiText>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="l2HeaderNoChild">
                                            <UiText
                                                weight="medium_500"
                                                typographyVariant="h3"
                                            >
                                                {l2Items.title}
                                            </UiText>
                                            <span
                                                className={
                                                    parseFloat(
                                                        l2Items.balance
                                                    ) < 0
                                                        ? 'priceWaste'
                                                        : ''
                                                }
                                            >
                                                {' '}
                                                {l2Items.balance}{' '}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    )}
                    
                    <div className={getClassNameForLevel1Total(items.title)}>
                        <UiText
                            weight="medium_500"
                            variant="hatchback_125"
                            typographyVariant="h3"
                        >
                            Total {items.title} 
                        </UiText>
                        <UiText
                            weight="bold_700"
                            typographyVariant="h3"
                            className={
                                parseFloat(items.balance) < 0
                                    ? 'priceWaste'
                                    : ''
                            }
                        >
                            {' '}
                            {currencyFormatter.format(
                                parseFloat(items.balance)
                            )}{' '}
                        </UiText>
                    </div>
                </div>
            ) : (
                <div className={getClassNameForLevel1TotalWithoutChildren(items.title)}>
                    <UiText
                            weight="medium_500"
                            variant="hatchback_125"
                            typographyVariant="h3"
                        >
                            
                        
                           Total {items.title}
                    </UiText>
                    <UiText
                            weight="bold_700"
                           
                            typographyVariant="h3"
                            className={
                                parseFloat(items.balance) < 0
                                    ? 'priceWaste'
                                    : ''
                            }
                        >
                            {' '}
                            {currencyFormatter.format(
                                parseFloat(items.balance)
                            )}{' '}
                        </UiText>
                </div>
            )}
        </>
    )
}

export function ReportsListData(props: BalanceReportsListDataProps) {
    const { reportsData, startDate, endDate } = props
    const { colorTheme } = useThemeContext()
    const localClasses = useLocalStyles(colorTheme)
    return (
        <div className={localClasses.balanceSheetReports}>
            <div className="reportContainer">
                <UiText
                    weight="medium_500"
                    className="l1Title"
                    typographyVariant="h2"
                >
                    {reportsData?.assets.title}
                </UiText>
                <GenerateReportsTable
                    items={reportsData?.assets}
                    startDate={startDate}
                    endDate={endDate}
                />
                <UiText
                    weight="medium_500"
                    className="l1Title"
                    typographyVariant="h2"
                >
                    {reportsData?.liabilities.title}
                </UiText>
                <GenerateReportsTable
                    items={reportsData?.liabilities}
                    startDate={startDate}
                    endDate={endDate}
                />

                <UiText
                    weight="medium_500"
                    className="l1Title"
                    typographyVariant="h2"
                >
                    {reportsData?.capital.title}
                </UiText>
                <GenerateReportsTable
                    items={reportsData?.capital}
                    startDate={startDate}
                    endDate={endDate}
                />
                <GenerateTotalSection reportsData={reportsData} />
            </div>
        </div>
    )
}

export const GenerateIncomeTotalSection = (props: {
    report: IncomeStatementReportRoot
}) => {
    const { report } = props
    if (!report) return <></>
    return (
        <UiText
            weight="medium_500"
            className="totalLiabilities"
            typographyVariant="h3"
        >
            <span>
                Net Income{' - '}
                {parseFloat(report.total_net_income) <= 0 ? 'Loss' : 'Profit'}
            </span>

            <UiText weight="extra_bold_800" typographyVariant="h2">
                <span
                    className={
                        parseFloat(report.total_net_income) < 0
                            ? 'priceWaste'
                            : ''
                    }
                >
                    {currencyFormatter.format(
                        parseFloat(report.total_net_income)
                    )}
                </span>
            </UiText>
        </UiText>
    )
}

export const GenerateIncomeReportsData = (props: IncomReportsListDataProps) => {
    const { reportsData, reportType, startDate, endDate } = props
    const { colorTheme } = useThemeContext()
    const localClasses = useLocalStyles(colorTheme)
    return (
        <div className={localClasses.balanceSheetReports}>
            <div className="reportContainer">
                <UiText
                    weight="medium_500"
                    className={reportsData?.revenue?.children?.length ? "l1Title" : "withNoBorderl1Title"}
                    typographyVariant="h2"
                >
                    {reportsData?.revenue.title}
                </UiText>
                <GenerateReportsTable items={reportsData?.revenue} reportType={reportType} startDate={startDate} endDate={endDate} />
                <UiText
                    weight="medium_500"
                    className={reportsData?.expenses?.children?.length ? "l1Title" : "withNoBorderl1Title"}
                    typographyVariant="h2"
                >
                    {reportsData?.expenses.title}
                </UiText>
                <GenerateReportsTable items={reportsData?.expenses} reportType={reportType} startDate={startDate} endDate={endDate}/>
                <GenerateIncomeTotalSection report={reportsData} />
            </div>
        </div>
    )
}
