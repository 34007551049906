import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import UiText from '../../common/ui/UiText'

interface CircularProgressWithLabelProps{
    size: number,
    value: number,
    bgColor : string,
    color: string
}
export default function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
    const {size, value, bgColor, color} = props;
    const classes = makeStyles(() => {
        return {
            bottom: {
                color: bgColor,
            },
            top: {
                color: color,
                position: 'absolute',
                left: 0,
            },
            circle: {
                strokeLinecap: 'round',
            },
            percentageTextContainer: {
                top: '0',
                left: '12%',
                right: '0',
                bottom: '5px',
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center'
            },
            progressContainer: {
                position: 'relative'
            }
        }
    })()
    
    return (
        <div className={classes.progressContainer}>
            <CircularProgress
                variant="static"
                className={classes.bottom}
                size={size ? size : 40}
                thickness={1}
                value={100}
            />
            <CircularProgress
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={size ? size : 40}
                thickness={4}
                variant="static"
                value={value}
            />
            <div
               className={classes.percentageTextContainer}
            >
                <UiText
                    variant="car_100"
                    weight="semi_bold_600"
                >
                    {`${Math.round(value)}%`}
                </UiText>
            </div>
        </div>
    )
}
