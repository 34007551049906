import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid, IconButton, Theme, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { FormikProps, FormikValues } from 'formik'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import FilterForm from './FilterForm'
import { commonStyles } from '../../../styles/commonStyles'
import UiButton from '../../common/ui/UiButton'
import Loader from '../../common/Loader'
import { getAllContacts } from '../../../services/apiService/contacts'
import { getAllProducts } from '../../../services/apiService/products'
import { getFilterChips } from './FilterFormUtils'
import { InvoiceQueryObject } from '../../../models'
import {
    defaultQueryObject
} from './InvoiceUtil'
import { useHistory } from 'react-router-dom'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        modalContent: {
            width: '480px',
            height: '600px'
        },
        modalTitle: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            justifyContent: 'space-between',
        },
    }
})

type Props = {
    open: boolean
    handleClose: () => void
    queryObject: InvoiceQueryObject
    setInvoiceType: React.Dispatch<React.SetStateAction<number>>
    setQueryObject: React.Dispatch<React.SetStateAction<InvoiceQueryObject>>
    setChipsToDisplay: React.Dispatch<
        React.SetStateAction<
            {
                label: string
                key: string
            }[]
        >
    >
}

const FilterModal = ({
    open,
    handleClose,
    queryObject,
    setQueryObject,
    setInvoiceType,
    setChipsToDisplay,
}: Props) => {
    const [loading, setLoading] = useState(false)
    const [contacts, setContacts] = useState([])
    const [products, setProducts] = useState([])
    const { colorTheme } = useThemeContext()
    const common = commonStyles()
    const classes = useStyles(colorTheme)
    const history = useHistory()

    useEffect(() => {
        if(open) {
            setLoading(true)
            Promise.all([getAllContacts(), getAllProducts()])
                .then((res: any) => {
                    setContacts(res[0])
                    setProducts(res[1])
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        }
    }, [open])

    const onSubmit = (formData: any) => {
        const filterChips = getFilterChips(formData)
        setQueryObject(formData)
        setChipsToDisplay(filterChips)
        history.push('/invoices')

    }

    const formikRef = useRef<FormikProps<FormikValues>>(null)
    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size="sm"
            disableBackdropClick={true}
            customDialogStyles={{ paper: classes.modalContent }}
            modalTitleStyles={classes.modalTitle}
            titleNode={
                <>
                    <div className={common.flex}>
                        <UiText style={{ marginTop: '6px' }}>
                            Filter Invoice
                        </UiText>{' '}
                        <UiButton
                            btnType="hyperlink"
                            label="Clear all"
                            handleClick={() => {
                                formikRef?.current?.resetForm()
                                setQueryObject(defaultQueryObject)
                                const filterChips = getFilterChips(defaultQueryObject)
                                setChipsToDisplay(filterChips)
                            }}
                        />
                    </div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </>
            }
            actions={
                <Grid justify="flex-end" container>
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: '4px' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            formikRef?.current?.submitForm()
                            handleClose()
                        }}
                    >
                        Apply
                    </Button>
                </Grid>
            }
        >
            {loading ? (
                <Loader />
            ) : (
                <FilterForm
                    onSubmit={onSubmit}
                    contacts={contacts}
                    products={products}
                    formikRef={formikRef}
                    queryObject={queryObject}
                />
            )}
        </UiDialog>
    )
}

export default FilterModal
