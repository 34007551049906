import { MoveSegmentBody } from '../../components/bookkeeping/chart-of-accounts/MoveAccount';
import {
    CreateSegment,
    newCategoryData,
} from '../../models/chartOfAccountants';
import {
    getResource,
    postResource,
    patchResource,
    deleteResource,
} from '../axiosService';

export function getSegmentList(accountId: string, businessId: string) {
    const api = `api/account/${accountId}/business/${businessId}/coa_business_class`;
    return getResource<any>(api);
}

export function getChartOfAccountantsData(
    accountId: string,
    businessId: string
): Promise<any> {
    const apiUrl = `api/account/${accountId}/business/${businessId}/account`;
    return getResource<any>(apiUrl);
}

export function createNewSegment(
    accountId: string,
    businessId: string,
    data: CreateSegment
): Promise<any> {
    const apiUrl = `api/account/${accountId}/business/${businessId}/coa_business_class`;
    return postResource(apiUrl, data);
}

export function createNewCategory(
    accountId: string,
    businessId: string,
    data: newCategoryData
): Promise<any> {
    const apiUrl = `api/account/${accountId}/business/${businessId}/account`;
    return postResource<any>(apiUrl, data);
}

export function updateCategory(
    accountId: string,
    businessId: string,
    data: newCategoryData,
    categoryId: string
): Promise<any> {
    const apiUrl = `api/account/${accountId}/business/${businessId}/account/${categoryId}`;
    return patchResource<any>(apiUrl, data);
}

export function deleteCategory(
    accountId: string,
    businessId: string,
    categoryId: string
): Promise<any> {
    const apiUrl = `api/account/${accountId}/business/${businessId}/account/${categoryId}`;
    return deleteResource(apiUrl);
}
export function deleteSegmentById(
    accountId: string,
    businessId: string,
    segment: string
): Promise<any> {
    const api = `api/account/${accountId}/business/${businessId}/coa_business_class/${segment}`;
    return deleteResource(api);
}
export function updateSegment(accountId: string,
    businessId: string,
    segmentId: string,
    body: any){
       const url = `api/account/${accountId}/business/${businessId}/coa_business_class/${segmentId}`;
    return patchResource(url, body);
}
export function moveCatoryToResource(accountId: string,
    businessId: string,
    srcCategoryId: string,
    body: MoveSegmentBody){
    const apiUrl = `api/account/${accountId}/business/${businessId}/move_category/${srcCategoryId}`;
    return patchResource<any>(apiUrl, body);
} 
