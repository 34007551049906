import { Product } from '../../models';
import {
    deleteResource,
    getResource,
    patchResource,
    postMultipartResource,
    postResource,
} from '../axiosService';

export function entityFormationGET<T>(
    accountId: string,
    businessId: string
): Promise<T> {
    return getResource<T>(
        `api/account/${accountId}/business/${businessId}/entity_formation`
    );
}

export const annualReportDocument = {
    saveData: function (accountId: string, businessId: string, body: any) {
        return postResource(
            `api/account/${accountId}/business/${businessId}/annual_report`,
            body
        );
    },
    upload: function <T>(accountId: string, files: any, year: string) {
        const formData = new FormData();
        formData.append('files', files);
        formData.append('year', year);
        return postMultipartResource<T>(
            `api/account/${accountId}/annual_report_document/upload`,
            formData
        );
    },
    getUploadedFiles: function <T>(accountId: string) {
        return getResource<T>(
            `api/account/${accountId}/annual_report_document/list`
        );
    },
    linkFiles: function (accountId: string, files: string[], year: string) {
        return patchResource(
            `api/account/${accountId}/annual_report_document/link`,
            {
                files,
                year,
            }
        );
    },
    unlinkFile: function (accountId: string, fileId: string, year: string) {
        return deleteResource(
            `api/account/${accountId}/annual_report_document/unlink/${fileId}?year=${year}`
        );
    },
    updateNotice: function <T>(
        accountId: string,
        businessId: string,
        dueDate: number
    ) {
        return patchResource<T>(
            `/api/account/${accountId}/business/${businessId}/annual_report`,
            { dueDate }
        );
    },
};

export function obtainEin(
    accountId: string,
    businessId: string,
    entityType: string,
    body: any
) {
    return postResource(
        `api/account/${accountId}/business/${businessId}/ein_number_questions/${entityType}`,
        body
    );
}

export function getEinData(accountId: string) {
    return getResource<{} | null>(`/api/account/${accountId}/ein`);
}

export function getEntityManagementProducts(
    accountId: string,
    businessId: string
) {
    return getResource<{ required: Product[]; recommended: Product[] }>(
        `/api/account/${accountId}/business/${businessId}/entity_management_products`
    );
}
