import { 
    useState, 
    useContext,
    useRef,
    useCallback,
    useEffect 
} from 'react';
import { FormikProps, FormikValues } from 'formik'
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import { getLocations } from '../../../services/apiService/locations'
import { Location, PaginatedLocationsResponse } from '../../../models/locations'
import { defaultValues } from './forms/LocationFormUtils'


export type LoadingStates = {
    loading: boolean
    hasMore: boolean
}

const useLocationsHook = () => {
    const addressKey = 'address';
    const countryKey = 'country';
    const usKey = 'US';
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    /**
     * This formikRef is for location form ref.
     * This is needed to check if form is touched and show Confirmation popup
     * (Required for modal close with X button)
     */
    const formikRef = useRef<FormikProps<FormikValues>>(null)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedLocation, setSelectedLocation] = useState<Location>()
    const [locationUpdated, setLocationUpdated] = useState<boolean>(false)
    const [discardChanges, setDiscardChanges] = useState<boolean>(false)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [locationsData, setLocationsData] = useState<
        PaginatedLocationsResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 25,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    })

    // Handling of X button open the confirm popup for exit if form is touched
    // Cancel button handling added in the ContactForm itself.
    const handleLocationModalClose = (result: boolean) => {
        let values: FormikValues = formikRef.current?.values!
        if(!result && values){
            // if form is touched then confirmation modal opens
            if(isFormTouched(values)){
                setDiscardChanges(true)
                setShowConfirmModal(true)
            }else{
                setOpenModal(false)
            }
            return;
        }

        // form submitted
        setShowConfirmModal(false)
        setOpenModal(false)
    }

    const handleConfirmModal = (result: boolean) => {
        if (result === true) {
            // i.e when discard/delete clicked
            setShowConfirmModal(false)
            setOpenModal(false)
        } else {
            setShowConfirmModal(false)
        }
    }

    const getLocationsList = useCallback(
        (params) => {
            params = {
                ...params,
            }
            getLocations(params).then((response: any) => {
                if (response) {
                    const { itemsCount, page, perPage, pageCount, items } =
                        response
                    setLocationsData((prev: PaginatedLocationsResponse & LoadingStates) => ({
                        ...prev,
                        items: page === 1 ? items : prev?.items?.concat(items),
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    }))
                    setFirstLoad(false)
                }
            })
        },
        []
    )

    useEffect(()=>{
        if(firstLoad && locationsData.items?.length){
            setSelectedLocation(locationsData.items?.[0])
        }
    },[firstLoad, locationsData])

    function isFormTouched(values: FormikValues, location?: Location|undefined){
        return formikRef.current?.dirty
        // const originalLocation = location ?? locationsData?.items?.find(({ id }) => id === values.id);
        // if(originalLocation){
        //     if(!locationObjectsAreEquals(values, originalLocation)){
        //         return true;
        //     }
        // }else{
        //     if(!locationObjectsAreEquals(values, defaultValues)){
        //         return true;
        //     }
        //     return false;
        // }
    }
    
    function locationObjectsAreEquals(originalLocation: any, location: any){
        const keys1 = Object.keys(originalLocation);

        for (let key of keys1) {
            if(key === addressKey){
                if (!locationObjectsAreEquals(originalLocation[key], location[key])) return false;
            }else{
                if(!(key === countryKey && originalLocation[key] === '' && location[key] === usKey)){
                    if (originalLocation[key] !== location[key]) {
                        return false;
                    }
                }
            }
        }
        
        return true;
    }

    function setCurrentLocations(values: Location[]){
        setLocationsData((prev: PaginatedLocationsResponse & LoadingStates) => ({
            ...prev,
            items: values
        }))
    }


    // load locations
    const loadLocations = useCallback(
        (params?: any) => {
            setLocationsData((prev: any) => ({
                ...prev,
                loading: true,
            }))
            getLocationsList({
                page: 1,
                perPage: 25,
                ...params,
            })
        },
        [getLocationsList]
    )

    const loadMore = () => {
        const { page } = locationsData
        getLocationsList({
            page: page + 1,
            perPage: 25,
        })
    }

    useEffect(() => {
        if (locationUpdated) {
            let newItems: any = [...locationsData?.items!]
            const locationIndex: number = newItems.findIndex(
                (location: Location) => location.id === selectedLocation?.id
            )
            newItems[locationIndex] = selectedLocation
            setLocationsData((prev: PaginatedLocationsResponse & LoadingStates) => ({
                ...prev,
                items: newItems,
            }))
            setLocationUpdated(false)
        }
    }, [locationUpdated, locationsData?.items, selectedLocation])

    useEffect(() => {
        loadLocations()
    }, [loadLocations])


    return {
        openModal,
        setOpenModal,
        showConfirmModal,
        setShowConfirmModal,
        searchTerm,
        setSearchTerm,
        selectedLocation,
        setSelectedLocation,
        locationUpdated,
        setLocationUpdated,
        locationsData,
        setLocationsData,
        formikRef,
        handleLocationModalClose,
        handleConfirmModal,
        loadMore,
        loadLocations,
        isFormTouched,
        discardChanges,
        setDiscardChanges,
        setCurrentLocations
    }
}
export default useLocationsHook