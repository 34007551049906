import React, { Fragment, useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    Divider,
    Grid,
    List,
    Button,
    ListItem,
    Theme,
    useTheme,
    Hidden,
    makeStyles,
    Typography,
    ListItemText,
    ListItemIcon,
    ListSubheader,
    useMediaQuery,
    IconButton,
    ListItemSecondaryAction,
} from '@material-ui/core'
import { Add, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import { showAlert } from '../../../store/actions/feedback'
import { ApplicationStore } from '../../../models'
import { connect, useDispatch } from 'react-redux'
import { currencyFormatter, getMessageTime } from '../../../utils/appUtil'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import Delete from '../../../assets/icons-svg/Delete.svg'
import Exclude from '../../../assets/icons-svg/Exclude.svg'
import ExcludeDark from '../../../assets/icons-svg/ExcludeDark.svg'
import {
    deleteTransactionById,
    updateTransaction,
} from '../../../services/transactionsService'
import Loader from '../../common/Loader'
import UnsaveChangeDialog from './UnsaveChangeDialog'
import { saveReconciliationData } from '../../../services/apiService/reconciliations'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    navListItemGutters: {
        paddingLeft: '16px',
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    listDetail: {
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
        },
        '& .MuiListItemText-primary': {
            maxWidth: '275px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '175px',
            },
        },
    },
    deleteButton: {
        background: (colorTheme) => colorTheme.red400,
        borderRadius: '4px',
    },
    excludeButton: {
        background: (colorTheme) => colorTheme.black200,
        borderRadius: '4px',
    },
    confirmModal: {
        '& div:first-child': {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
    customerText: {
        whiteSpace: 'nowrap',
        fontWeight: 'normal',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
        },
    },
    secondaryText: {
        color: (colorTheme) => colorTheme.grey400,
    },
    iconButton: {
        padding: '0px !important',
    },
    listIconRoot: {
        minWidth: '38px',
    },
}))
const ReconcileTransactions = ({
    transactions,
    selectTransactions,
    isReconcilePageDirty,
    setIsReconcilePageDirty,
    checkAllTransactions,
    checkAll,
    appData,
    reloadReconciliationData,
    sortOrder,
    setSortOrder,
    setTransactionData,
    setOpenTransactionModal,
    loadingTransactions,
    setTransactions,
    setReconcileErrorMsg,
    reconciliation,
    reconcileCategory
 //   excludeTransaction,
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const dispatch = useDispatch()
    const theme = useTheme()
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
    const [listRef, setListRef] = useState<any>()
    const [listHeight, setListHeight] = useState('')
    const [selectedTransaction, setSelectedTransaction] = useState<any>()
    const [openConfirmDialogue, setOpenConfirmDialogue] = useState({
        open: false,
        showLoader: false,
        type: 'exclude',
    })
    const [openUnsaveDialog, setOpenUnsaveDialog] = useState(false);
    const [excludedElement, setExcludedElement] = useState('');
    const deleteTransaction = () => { 
        deleteTransactionById(
            appData.current_account_id,
            appData.current_business_id,
            selectedTransaction.id as string
        )
            .then(() => {
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: `Transactions #${selectedTransaction.number} deleted`,
                    })
                )
                reloadReconciliationData()
            })
    }
   
    const saveReconciliation = () => {
        let checkedIds: string[]=[], uncheckedIds: string[]=[]
        transactions.forEach((transaction: any) => {
            if(transaction.reconcile) {
                checkedIds.push(transaction.id)
            } else {
                uncheckedIds.push(transaction.id)
            }
        })
       const promise1 = bulkSaveReconciliation(checkedIds, reconciliation.id)
       const promise2 = bulkSaveReconciliation(uncheckedIds, null)
       Promise.all([promise1, promise2]).then(() =>{
        setIsReconcilePageDirty(false);
        setOpenUnsaveDialog(false);
        setSelectedTransaction(excludedElement)
            setOpenConfirmDialogue((prev: any) => ({
                ...prev,
                open: true,
                type: 'exclude',
            }))
       })
    }
    const bulkSaveReconciliation = (
        transactionIds: string[], 
        reconciliationId: string | number | null
    ) =>{
        if(transactionIds.length > 0) {
            return saveReconciliationData(
                appData.current_account_id,
                appData.current_business_id,
                {
                    filters: { id: transactionIds },
                    reconciliation: reconciliationId,
                }
            )
        }
    }

    const excludedTransaction = () => {
        updateTransaction(
            appData.current_account_id,
            appData.current_business_id,
            selectedTransaction.id as string,
            {
                excluded: true,
            }
        )
            .then(() => {
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: `Transactions #${selectedTransaction.number} excluded`,
                    })
                )
            })
            .finally(() => {
                reloadReconciliationData()
            })
    }
    const onExcludeTransaction = (element: any) =>{
        if(isReconcilePageDirty){
            setOpenUnsaveDialog(true);
            setExcludedElement(element);
            return;
        }
        setSelectedTransaction(element)
            setOpenConfirmDialogue((prev: any) => ({
                ...prev,
                open: true,
                type: 'exclude',
            }))
         setExcludedElement('');                
    }
    const sortTransactions = (sortOrder: string) => {
        let trans = [...transactions]
        
        let balance_multiplier =
            reconcileCategory?.balance_multiplier;
        let balance =
            parseFloat(reconciliation.start_balance) || 0;
        
        let sortedTransactions = trans.sort(
            (x: any, y: any) =>
                sortOrder === 'asc'
                    ? x.date - y.date || parseInt(x.number) - parseInt(y.number)
                    : y.date - x.date || parseInt(y.number) - parseInt(x.number)
        );

        let transactionsToDisplay = sortedTransactions.map(
            (t: any) => {
                t.balance =
                    balance +
                    balance_multiplier *
                        parseFloat(t.amount);
                t.balance_multiplier =
                    balance_multiplier;
                balance = t.balance;
                return t;
            }
        );

        setSortOrder(sortOrder)
        setTransactions(transactionsToDisplay)
    }

    const { open, type, showLoader } = openConfirmDialogue

    useEffect(() => {
        if (listRef) {
            let height = isSmDown ? 240 : 300
            setListHeight(`calc(100vh - ${listRef?.offsetHeight + height}px)`)
        }
    }, [listRef, isSmDown])

    const transactionActions = (element: any) => (
        <>
            {element.is_manual && (
                <img
                    alt="remove"
                    src={Delete}
                    onClick={() => {
                        setSelectedTransaction(element)
                        setOpenConfirmDialogue((prev: any) => ({
                            ...prev,
                            open: true,
                            type: 'delete',
                        }))
                    }}
                />
            )}
            {(element.is_aggregated || element?.is_plaid) && (
                <img
                    alt="exclude"
                    src={ExcludeDark}
                    onClick={() => {
                        onExcludeTransaction(element)
                    }}
                />
            )}
        </>
    )

    if (loadingTransactions) {
        return (
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ height: '100%' }}
                >
                    <Loader />
                </Grid>
            </Grid>
        )
    }

    if (transactions?.length === 0) {
        return (
            <Box my={5} textAlign="center">
                <Typography variant="subtitle1" color="textSecondary">
                    No Transaction Were Found
                </Typography>
            </Box>
        )
    }

    return (
        <div>
            <UiConfirmationDialog
                open={open}
                message={
                    <>
                        <Typography
                            variant="body1"
                            className={classes.confirmModal}
                            gutterBottom
                        >
                            {type === 'exclude' ? (
                                <>
                                    <div>
                                        Exclude Transaction{' '}
                                        {selectedTransaction?.number} ?
                                    </div>
                                    <div>
                                        It will no longer be visible in
                                        reconciliations.
                                    </div>
                                    <div>
                                        You can change this at any time under
                                        the Exclude Transactions tab in
                                        Bookkeeping
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        Delete Transaction{' '}
                                        {selectedTransaction?.number}` ?
                                    </div>
                                    <div>
                                        it will be deleted from your account.
                                    </div>
                                    <div>This cannot be undone.</div>
                                </>
                            )}
                        </Typography>
                    </>
                }
                handleClose={() => {
                    setOpenConfirmDialogue((prev: any) => ({
                        ...prev,
                        open: false,
                    }))
                }}
                showLoader={showLoader}
                confirmNode={
                    <Button
                        className={
                            type === 'delete'
                                ? classes.deleteButton
                                : classes.excludeButton
                        }
                        variant="contained"
                        startIcon={
                            <>
                                {type === 'exclude' ? (
                                    <img src={Exclude} alt="excludeicon" />
                                ) : (
                                    <img src={Delete} alt="deleteicon" />
                                )}
                            </>
                        }
                        onClick={() => {
                            type === 'delete'
                                ? deleteTransaction()
                                : excludedTransaction()
                        }}
                        color="secondary"
                    >
                        {type === 'exclude' ? 'Exclude' : 'Delete'}
                    </Button>
                }
                cancelButtonText="Cancel"
            />
            <UnsaveChangeDialog
                    open={openUnsaveDialog}
                    handleClose={() => {
                        setOpenUnsaveDialog(false);
                    }}
                    hideDiscardChangesButton={true}
                    saveReconciliation={saveReconciliation}
                />
            {/* Start Dekstop view of reconciliation Transactions */}
            <Hidden mdDown>
                <List>
                    <ListSubheader ref={(ref: any) => setListRef(ref)}>
                        <div style={{ display: 'flex' }}>
                            <ListItemIcon>
                                <Checkbox
                                    size="small"
                                    checked={checkAllTransactions}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        checkAll(e.target.checked)
                                        setReconcileErrorMsg(null)
                                    }}
                                />
                            </ListItemIcon>
                            <Grid container direction="row" xs={12}>
                                <Grid item xs={2}>
                                    <IconButton
                                        classes={{ root: classes.iconButton }}
                                        onClick={() => {
                                            sortTransactions(
                                                sortOrder === 'asc'
                                                    ? 'desc'
                                                    : 'asc'
                                            )
                                        }}
                                    >
                                        {sortOrder === 'desc' ? (
                                            <KeyboardArrowUp />
                                        ) : (
                                            <KeyboardArrowDown />
                                        )}
                                    </IconButton>
                                    Date
                                </Grid>
                                <Grid item xs={2}>
                                    Ref #
                                </Grid>
                                <Grid item xs={4}>
                                    Customer/Payee
                                </Grid>
                                <Grid item xs={2}>
                                    Amount
                                </Grid>
                                <Grid item xs={2} style={{ display: 'flex' }}>
                                    Balance
                                </Grid>
                            </Grid>
                        </div>
                    </ListSubheader>
                    <div
                        className="list-container"
                        style={{
                            overflow: 'auto',
                            height: listHeight,
                            overflowX: 'hidden',
                        }}
                    >
                        {transactions?.map((element: any, index: number) => (
                            <Fragment key={index}>
                                <ListItem
                                    classes={{
                                        gutters: classes.navListItemGutters,
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={element.reconcile}
                                            size="small"
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                selectTransactions(
                                                    e.target.checked,
                                                    element.id
                                                )
                                                setReconcileErrorMsg(null)
                                            }}
                                        />
                                    </ListItemIcon>

                                    <Grid container direction="row" xs={12}>
                                        <Grid item xs={2}>
                                            <Typography noWrap>
                                                {getMessageTime(
                                                    element.date * 1000
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography
                                                noWrap
                                                style={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e: any) => {
                                                    setOpenTransactionModal(
                                                        (prev: any) => ({
                                                            type: 'edit',
                                                            open: true,
                                                        })
                                                    )
                                                    setTransactionData(element)
                                                }}
                                            >
                                                #{element.number}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography noWrap>
                                                {element?.customer_name?.substring(0,60)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography noWrap>
                                                {currencyFormatter.format(
                                                    element.amount
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography noWrap>
                                                {currencyFormatter.format(
                                                    element.balance
                                                )}
                                            </Typography>

                                            <ListItemIcon
                                                style={{
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {transactionActions(element)}
                                            </ListItemIcon>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                {index === transactions.length - 1 ? (
                                    ''
                                ) : (
                                    <Divider variant="inset" />
                                )}
                            </Fragment>
                        ))}
                    </div>
                </List>
            </Hidden>
            {/* End Desktop View for Reconciliation Transaction List */}
            {/* Mobile  View of Reconcile Transaction List */}
            <Hidden mdUp>
                {/*  Start Mobile view List header  */}
                <div ref={(ref: any) => setListRef(ref)}>
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                        style={{ cursor: 'pointer' }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                size="small"
                                checked={checkAllTransactions}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setReconcileErrorMsg(null)
                                    checkAll(e.target.checked)
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <ListItemIcon
                                    onClick={() => {
                                        sortTransactions(
                                            sortOrder === 'asc' ? 'desc' : 'asc'
                                        )
                                    }}
                                >
                                    {sortOrder === 'asc' ? (
                                        <KeyboardArrowDown />
                                    ) : (
                                        <KeyboardArrowUp />
                                    )}
                                    <span style={{ color: '#000000' }}>
                                        {' '}
                                        Show{' '}
                                        {sortOrder === 'asc'
                                            ? 'Older'
                                            : 'Newest'}{' '}
                                        first{' '}
                                    </span>
                                </ListItemIcon>
                            }
                        ></ListItemText>
                        <ListItemSecondaryAction>
                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<Add />}
                                onClick={() => {
                                    setOpenTransactionModal((prev: any) => ({
                                        type: 'new',
                                        open: true,
                                    }))
                                }}
                            >
                                New
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant="fullWidth" />
                </div>
                {/*  End Mobile view List header  */}
                <div
                    style={{
                        overflow: 'auto',
                        height: listHeight,
                        overflowX: 'hidden',
                    }}
                >
                    {transactions.map((element: any, index: number) => (
                        <Fragment key={index}>
                            <ListItem
                                classes={{
                                    gutters: classes.navListItemGutters,
                                }}
                                alignItems="flex-start"
                                style={{ cursor: 'pointer' }}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        size="small"
                                        checked={element.reconcile}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            selectTransactions(
                                                e.target.checked,
                                                element.id
                                            )
                                            setReconcileErrorMsg(null)
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.customerText}
                                            onClick={() => {
                                                setOpenTransactionModal(
                                                    (prev: any) => ({
                                                        type: 'edit',
                                                        open: true,
                                                    })
                                                )
                                                setTransactionData(element)
                                            }}
                                        >
                                            <span style={{ cursor: 'pointer' }}>
                                                #{element.number}{' '}
                                            </span>
                                            {element.customer_name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            noWrap
                                            className={classes.secondaryText}
                                        >
                                            {getMessageTime(
                                                element.date * 1000
                                            )}
                                        </Typography>
                                    }
                                ></ListItemText>
                                <ListItemSecondaryAction>
                                    <ListItemText
                                        primary={
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <Typography noWrap>
                                                        {currencyFormatter.format(
                                                            element.balance
                                                        )}
                                                    </Typography>
                                                    <div
                                                        className={
                                                            classes.secondaryText
                                                        }
                                                    >
                                                        {currencyFormatter.format(
                                                            element.amount
                                                        )}{' '}
                                                    </div>
                                                </div>
                                                <ListItemIcon
                                                    classes={{
                                                        root: classes.listIconRoot,
                                                    }}
                                                    style={{
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent: 'end',
                                                    }}
                                                >
                                                    {transactionActions(
                                                        element
                                                    )}
                                                </ListItemIcon>
                                            </div>
                                        }
                                    ></ListItemText>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Fragment>
                    ))}
                </div>
            </Hidden>
        </div>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
})
export default connect(mapStateToProps)(ReconcileTransactions)
