import { Action, SET_NOTIFICATION_CLICK, SET_NOTIFICATION_COUNT } from '../actions/count';

export default function count(state = {}, action: Action): any {
    switch (action.type) {
        case SET_NOTIFICATION_COUNT:
            return action.state;
        case SET_NOTIFICATION_CLICK:
            return {...state, clickAction: action.state}
        default:
            return state;
    }
}
