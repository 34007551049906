import * as Yup from 'yup'
import type {
    InitialFormValues,
    RequestData,
    Activity,
} from '../../../../models'
import { FormikValues } from 'formik'
import { fixedTo2Decimal } from '../TripUtil'

const todayTimestamp = new Date().getTime() * 1000

export const initialFormValues: InitialFormValues = {
    vehicle: '',
    date: todayTimestamp,
    activity: 'business',
    startLocation: '',
    destination: '',
    purpose: '',
    miles: 0,
    showOdometer: false,
    odometerStart: 0,
    odometerEnd: 0,
    mileageRate: undefined,
    mileageDeductions: undefined,
    parking: 0.0,
    tolls: 0.0,
    description: '',
    embedded_location_from: undefined,
    embedded_location_to: undefined,
}

export const validationSchema = Yup.object({
    vehicle: Yup.string().required('Vehicle info is required'),
    date: Yup.number().required('Date is required'),
    activity: Yup.string().required('Activity is required'),
    startLocation: Yup.string().required('Start Location info is required'),
    destination: Yup.string().required('Destination info is required'),
    purpose: Yup.string().required('Purpose info is required'),
    miles: Yup.number().min(0, 'Miles cannot be negative').required('Miles is required'),
    mileage_rate: Yup.number().min(
        0,
        'Please select a date and activity to calculate mileage rate'
    ),
    mileage_deductions: Yup.number().min(0, 'Mileage deductions is required'),
    odometerStart: Yup.number().integer('Odometer start cannot be decimal').min(0, 'Odometer start cannot be negative'),
    odometerEnd: Yup.number().integer('Odometer end cannot be decimal')
    .when('odometerStart', {
      is: (odometerStart:number) => odometerStart > 0,
      then: Yup.number().min(Yup.ref('odometerStart'), 'Must be greater than Odometer start')
    })
    .min(0, 'Odometer end cannot be negative'),
    parking: Yup.number().min(0, 'Parking cannot be negative'),
    tolls: Yup.number().min(0, 'Tolls cannot be negative'),
    description: Yup.string(),
})

export type FormValuesType = typeof initialFormValues

export type ActivityIdType =
    | 'personal'
    | 'business'
    | 'medical'
    | 'moving'
    | 'charity'

export const getMileageRate = (
    activities: Activity[],
    activityId: ActivityIdType,
    timestamp: number
) => {
    if (activityId === 'personal') {
        return 0
    }
    const actualTimestamp = standardizeTimestamp(timestamp)
    const date = new Date(actualTimestamp)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const monthWithYear = `${year}-${month.toString().padStart(2, '0')}`
    const activity = activities.find((a) => a.id === activityId)
    if (activity) {
        if (Object.keys(activity.monthly_rates ?? {}).length > 0) {
            return (
                activity.monthly_rates[monthWithYear] ??
                activity.year_rates[year] ??
                0
            )
        }
        return activity.year_rates[year] ?? 0
    }
    return 0
}

export function standardizeTimestamp(timestamp: number) {
    let timestampStr = timestamp.toString()

    if (timestampStr.length > 13) {
        timestampStr = timestampStr.slice(0, 13)
    } else if (timestampStr.length < 13) {
        timestampStr = timestampStr.padEnd(13, '0')
    }
    return parseInt(timestampStr.padStart(13, '0'))
}

export const formatRequestData = (values: FormValuesType | FormikValues) => {
    const {
        date,
        vehicle,
        activity,
        purpose,
        miles,
        mileageDeductions,
        showOdometer,
        odometerStart,
        odometerEnd,
        mileageRate,
        parking,
        tolls,
        description
    } = values
    const timestamp = date.toString()
    const requestData: RequestData = {
        vehicle,
        date: parseInt(timestamp.slice(0, 10)),
        activity,
        purpose,
        miles: miles ? parseFloat(fixedTo2Decimal(parseFloat(miles))) : 0,
        mileage_rate: mileageRate,
        tax_deductions: parseFloat(mileageDeductions),
        show_odometer: showOdometer,
        odometer_start: odometerStart,
        odometer_end: odometerEnd,
        parking: parking ? parseFloat(parking) : 0,
        tolls: tolls ? parseFloat(tolls) : 0,
        description,
        embedded_location_from: {},
        embedded_location_to: {},
        $location_from: {},
        $location_to: {},
    }
    // if (!values.embedded_location_from) {
        requestData.location_from = values.startLocation
    // }
    // if (!values.embedded_location_to) {
        requestData.location_to = values.destination
    // }
    return requestData
}

export const embeddedLocationTempIds = {
    embedded_location_from_id: '101',
    embedded_location_to_id: '102',
}