import React from 'react';
import EmptyView from './EmptyView';
import NoDeadline from '../../../assets/images/NoDeadline.svg'
const NoDeadLineView = () => {
    return (
        <div>
            <EmptyView 
                title="You don’t have any upcoming events or deadlines."
                subtitle="Need to talk to your accountant? Schedule a call"
                image={NoDeadline}
            />
        </div>
    )
}

export default NoDeadLineView