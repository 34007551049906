import React, { useMemo, useEffect, useState } from 'react'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import {
    AccountProgress,
    AccountStep,
    BookkeepingType,
    InvoiceData,
} from '../../../models/dashboardModel'
import InvoiceInfoCard from './InvoiceInfoCard'
import { TransactionCard } from './TransactionCard'
import {
    BANK_SETUP_AD_BLOCK,
    CONNECT_BANK_ACCOUNT,
    INVOICE_INFO_CARD,
    LAST_1040_POPUP_DATE,
    MERCHANT_ACCOUNT_SETUP,
    MERCHANT_SETUP_AD_BLOCK,
    TRANSACTION_INFO_CARD,
    UPLOAD_1040_INFO_CARD,
    UPLOAD_1040_POPUP,
} from './Todo.const'
import {
    dismissBlock,
    isBankSetupCompletedOrDismissed,
    isBlockDismissed,
    isMerchantSetupCompletedORDismissed,
} from '../AccountSetupItems/AccountSetupUtility'
import MerchantProcessingCard from '../Cards/MerchantProcessingCard'
import FinicityConnect from '../Cards/FinicityConnect'
import BookkeepingInfoCard from './BookKeepingInfoCard'
import UiText from '../../common/ui/UiText'
import { makeStyles, Theme } from '@material-ui/core'
import NoTodo from '../emptyViews/NoTodo'
import useDeviceSize from '../../../hooks/useDeviceSize'
import CarryoverTaxInfoCard from './CarryoverTaxInfoCard'
import FinCenInfoCard from './FinCenInfoCard'
import PayrollMigrationInfoCard from './PayrollMigrationInfoCard'
import CarryoverTaxInfoModal from './CarryoverTaxModal/CarryoverTaxInfoModal'
import { useTodoBlockInfo } from './hooks/useTodoBlockInfo'
import { getAccountProgress, getFolders } from '../../../services/apiService'
import { useWidgetContext } from '../tax-prep/provider/WidgetProvider'
import { Folder } from '../../../models'
import {
    getFromLocalStorage,
    setInLocalStorage,
} from '../../../services/localStorageService'
import FinCenInfoModal from './FinCenInfoModal/FinCenInfoModal'
import useModal from '../tax-prep/tax-prep-statuses/hooks/useModal'
import Form1040UploadModal from '../tax-prep/modals/Form1040UploadModal'
import Upload1040InfoCard from './Upload1040InfoCard'
import { getCurrentTimeStamp } from '../../../utils/dateUtil'

const useStyles = makeStyles((theme: Theme) => {
    return {
        headerText: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
    }
})

const payrollMigrationCard = {
    cardHeader: 'Update your Payroll Bank Information',
    cardDescription:
        'We’re optimizing how we handle payroll. Reconnect your business bank account to keep your payroll running smoothly.',
    buttonText: 'Reconnect My Bank',
}

const verifyBankCard = {
    cardHeader: 'Verify your Payroll Bank Information',
    cardDescription:
        'Enter the 3 digit code from the verification deposit that was sent to your bank account.',
    buttonText: 'Verify Your Bank',
}

const TodoAdBlocks = ({
    accountProgress,
    dismissPopupInfo,
    fetchAccountProgress,
    invoiceData,
    showToDoTitle,
    remainingToDos,
}: {
    accountProgress: AccountProgress | undefined
    dismissPopupInfo: string[]
    fetchAccountProgress: () => void
    invoiceData: InvoiceData | undefined
    showToDoTitle: boolean
    remainingToDos: number
}) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [openTaxBenefitModal, setOpenTaxBenefitModal] = useState(false)
    const [isPopup, setIsPopup] = useState(false);
    const { open, openModal, closeModal } = useModal()
    const {
        open: open1040,
        openModal: open1040Modal,
        closeModal: close1040Modal,
    } = useModal()
    const {
        currentConfig,
        currentAccountId,
        personalAccount,
        currentBusinessId,
    } = useCurrentStore()
    const {
        taxPrepData: { show_fincen_data_collection_form },
    } = useWidgetContext()
    const { isMobileDevice } = useDeviceSize()
    const {
        isAllTodoCompleted,
        showBankSetupCard,
        showInvoiceCard,
        showTransactionCard,
        showCarryoverTaxBenefitCard,
        hasBookkeepingPermissions,
        bookkeepingInfo,
        showMerchantCard,
        showPayrollMigrationInfoCard,
        plaidVerificationStatus,
        show1040Card,
        setRefetch1040Info,
        show1040Popup
    } = useTodoBlockInfo()

    const {
        taxPrepData: { personal: personalData },
        taxYear,
    } = useWidgetContext()

    const isBankMarkedCompleted = isBankSetupCompletedOrDismissed(
        accountProgress as AccountProgress,
        dismissPopupInfo
    )
    const isMerchantCompleted = isMerchantSetupCompletedORDismissed(
        accountProgress as AccountProgress,
        dismissPopupInfo
    )
    const accountSteps: AccountStep[] = currentConfig.local.account_steps

    const MerchantAccountStep: AccountStep = useMemo(() => {
        return accountSteps.find(
            (step: AccountStep) => step.id === MERCHANT_ACCOUNT_SETUP
        ) as AccountStep
    }, [accountSteps])

    const BankAccountStep: AccountStep = useMemo(() => {
        return accountSteps.find(
            (step: AccountStep) => step.id === CONNECT_BANK_ACCOUNT
        ) as AccountStep
    }, [accountSteps])

    enum FORM_8821_CHECK {
        FOLDER_TITLE = 'Tax Return',
        FOLDER_PATH = 'taxes',
        SUB_FOLDER_PATH = 'ty2022',
        TAX_YEAR = 2022,
    }

    const taxBenefitsModalFlag = getFromLocalStorage('taxBenefitsModalFlag')

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
        if (!taxBenefitsModalFlag) {
            Promise.all([
                getFolders(currentAccountId),
                getAccountProgress({
                    business_id: currentBusinessId,
                }),
            ]).then((res: any) => {
                const [folders, accountProgress] = res
                display8821Dialog(folders, accountProgress)
            })
        }
    }, [loading])
    
    useEffect(() => {
        if(show1040Popup && !isBlockDismissed(UPLOAD_1040_POPUP)){
            setIsPopup(true);
            open1040Modal()
        }
    },[show1040Popup])

    const getFileCount = (items: Array<Folder> = []) => {
        return items.find((item) => {
            const folderPath = item.path.split('/')

            return (
                item.portal_account_id === personalAccount.id &&
                item.title === FORM_8821_CHECK.FOLDER_TITLE &&
                folderPath[1] === FORM_8821_CHECK.FOLDER_PATH &&
                folderPath[2] === FORM_8821_CHECK.SUB_FOLDER_PATH &&
                item.files_count === 0
            )
        })
    }

    const display8821Dialog = (
        items: Array<Folder>,
        accountProgress: AccountProgress
    ) => {
        const noFileCount = getFileCount(items)
        if (
            noFileCount &&
            accountProgress.carryover_tax_benefits === false &&
            personalData?.status !== 1 &&
            taxYear === FORM_8821_CHECK.TAX_YEAR
        ) {
            setOpenTaxBenefitModal(true)
        }
    }

    return (
        <>
            {showToDoTitle && (
                <UiText
                    variant={isMobileDevice ? 'hatchback_125' : 'suv_150'}
                    weight="semi_bold_600"
                    className={classes.headerText}
                >
                    Your To-Do ({remainingToDos})
                </UiText>
            )}
            {isAllTodoCompleted && <NoTodo />}
            {isBankMarkedCompleted && showBankSetupCard && (
                <FinicityConnect
                    primaryText={BankAccountStep.primary_text}
                    secondaryText={BankAccountStep.secondary_text}
                    completeButtonText={BankAccountStep.incomplete_button_text}
                    inCompleteButtonText={
                        BankAccountStep.incomplete_button_text
                    }
                    fetchAccountProgress={fetchAccountProgress}
                    borderView={true}
                    dismissBlock={() => {
                        dismissBlock(BANK_SETUP_AD_BLOCK)
                        setLoading(true)
                    }}
                />
            )}
            {isMerchantCompleted && showMerchantCard && (
                <MerchantProcessingCard
                    primaryText={MerchantAccountStep.primary_text}
                    secondaryText={MerchantAccountStep.secondary_text}
                    completeButtonText={
                        MerchantAccountStep.incomplete_button_text
                    }
                    accountProgress={accountProgress}
                    dismissPopupInfo={dismissPopupInfo}
                    fetchAccountProgress={fetchAccountProgress}
                    borderView={true}
                    dismissBlock={() => {
                        dismissBlock(MERCHANT_SETUP_AD_BLOCK)
                        setLoading(true)
                    }}
                />
            )}
            {showInvoiceCard && (
                <InvoiceInfoCard
                    updateDimissInfo={() => {
                        dismissBlock(INVOICE_INFO_CARD)
                    }}
                    invoiceData={invoiceData}
                />
            )}
            {showTransactionCard && (
                <TransactionCard
                    accountProgress={accountProgress}
                    updateDimissInfo={() => {
                        dismissBlock(TRANSACTION_INFO_CARD)
                    }}
                />
            )}
            {showCarryoverTaxBenefitCard && (
                <CarryoverTaxInfoCard
                    onButtonClick={() => {
                        setOpenTaxBenefitModal(true)
                    }}
                />
            )}
            {showPayrollMigrationInfoCard && (
                <PayrollMigrationInfoCard
                    cardHeader={payrollMigrationCard.cardHeader}
                    cardDescription={payrollMigrationCard.cardDescription}
                    buttonText={payrollMigrationCard.buttonText}
                />
            )}
            {plaidVerificationStatus && (
                <PayrollMigrationInfoCard
                    cardHeader={verifyBankCard.cardHeader}
                    cardDescription={verifyBankCard.cardDescription}
                    buttonText={verifyBankCard.buttonText}
                />
            )}
            {show_fincen_data_collection_form && (
                <FinCenInfoCard onButtonClick={openModal} />
            )}
            {show1040Card && (
                <Upload1040InfoCard onButtonClick={open1040Modal} />
            )}
            {!!bookkeepingInfo?.length &&
                hasBookkeepingPermissions &&
                bookkeepingInfo.map((bookkeepingInfo: BookkeepingType) => {
                    return (
                        <React.Fragment key={bookkeepingInfo.pop_up}>
                            {!dismissPopupInfo.includes(
                                bookkeepingInfo.pop_up
                            ) && (
                                <BookkeepingInfoCard
                                    bookkeepingInfo={bookkeepingInfo}
                                    fetchAccountProgress={fetchAccountProgress}
                                />
                            )}
                        </React.Fragment>
                    )
                })}
            <CarryoverTaxInfoModal
                open={openTaxBenefitModal}
                handleClose={() => {
                    setInLocalStorage('taxBenefitsModalFlag', true)
                    setOpenTaxBenefitModal(false)
                }}
            />
            <FinCenInfoModal open={open} handleClose={closeModal} />
            {show1040Card && (
                <Form1040UploadModal
                    open={open1040}
                    handleClose={() => {
                        if (isPopup) {
                            setInLocalStorage(
                                `${currentAccountId}_${LAST_1040_POPUP_DATE}`,
                                getCurrentTimeStamp()
                            )
                        }

                        setIsPopup(false)
                        close1040Modal()
                    }}
                    setRefetch1040Info={setRefetch1040Info}
                />
            )}
        </>
    )
}

export default TodoAdBlocks
