import { useState, useRef } from 'react';
import UiDialog from '../../../components/common/ui/UiDialog'
import {
    Grid,
    Button,
    makeStyles,
    Theme,
    TextField,
    InputAdornment,
    Box,
    Paper,
    Typography
} from '@material-ui/core'
import { Formik, Form, FormikProps, FormikValues } from 'formik'
import Loader from '../../../components/common/Loader'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'
import * as Yup from 'yup'
import { handleBulkProductActions } from './actions'
import AddIcon from '@material-ui/icons/Add'
import CreateCategoryModal from './CreateCategoryModal'
import { useProductServiceContextState } from './ProductServicesProvider'
import { commonStyles } from '../../../styles/commonStyles'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
interface ProductCategorizeModalProps {
    open: boolean
    handleClose: () => void
    productCount: number
    loadProducts: () => void
    checkedProducts: string[]
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    formContainer: {
        height: '150px',
    },
    footerAction: {
        right: '0',
        width: '100%',
        bottom: '0',
        display: 'flex',
        padding: '0.5rem',
        position: 'absolute',
        background: (colorTheme) => colorTheme.primaryWhite,
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        'justify-content': 'flex-end',
        'z-index': 1,
        height: "45px"
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
    renderOptionStyle: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },

}))

function ProductCategorizeModal({
    open,
    handleClose,
    loadProducts,
    productCount,
    checkedProducts,
}: ProductCategorizeModalProps) {
    const { colorTheme } = useThemeContext()
    const classNames = useStyles(colorTheme)
    const [loading, setLoading] = useState(false)
    const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false)
    const {
        productCategories,
        setRefetchData,
    } = useProductServiceContextState()

    const formikRef = useRef<FormikProps<FormikValues>>(null)

    const onSubmit = (data: any) => {
        let params = {
            category: data.category?.id,
            filters: { id: checkedProducts },
        }

        handleBulkProductActions(
            'categorize',
            checkedProducts.length,
            params,
            () => {
                handleClose()
                loadProducts()
            }
        )
    }

    const initialValues = {
        category: null,
    }

    const validationSchema = Yup.object({
        category: Yup.object().required('Select a category'),
    })

    const menuLink = ({ children, ...other }: any) => {
        return (
            <Paper {...other}>
                <Box borderBottom={`1px solid ${colorTheme.grey200}`}>
                    <Button
                        fullWidth
                        startIcon={<AddIcon />}
                        onMouseDown={(event) => {
                            event.preventDefault()
                        }}
                        onClick={(event) => {
                            event.preventDefault()
                            setOpenCategoryModal(true)
                        }}
                    >
                        Create New Category
                    </Button>
                </Box>
                {children}
            </Paper>
        )
    }

    return (
        <div className="productCategory">
            <CreateCategoryModal
                open={openCategoryModal}
                handleClose={() => {
                    setOpenCategoryModal(false)
                }}
                productCategories={productCategories}
                setFetchCategory={setRefetchData}
                categoryCallback={(category) => {
                    formikRef?.current?.setFieldValue('category', category);
                }}
            />
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={`Select a Category`}
                size="sm"
            >
                <div className={classNames.formContainer}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                            innerRef={formikRef}
                        >
                            {({ submitForm, isSubmitting, isValid, values }) => (
                                <Form>
                                    <Box my={2}>
                                        <Autocomplete
                                            id="category-select-options"
                                            fullWidth
                                            options={productCategories as any}
                                            value={values?.category}
                                            getOptionLabel={(option: any) =>
                                                option?.title
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            renderOption={(option: any) => (
                                                <div className={classNames.renderOptionStyle}>
                                                    <div>{option.title}</div>
                                                    <Typography variant="caption">
                                                        {option?.parent_title}
                                                    </Typography>
                                                </div>
                                            )}
                                            onChange={(event, item: any) => {
                                                formikRef?.current?.setFieldValue(
                                                    'category',
                                                    item
                                                )
                                            }}
                                            PaperComponent={(child: any) => {
                                                return menuLink(child)
                                            }}
                                        />
                                    </Box>
                                    <div className={classNames.footerAction}>
                                        {isSubmitting ? (
                                            <Loader />
                                        ) : (
                                            <Grid justify="flex-end" container>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={handleClose}
                                                    className={
                                                        classNames.cancelButton
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!isValid}
                                                    onClick={submitForm}
                                                >
                                                    Save Changes
                                                </Button>
                                            </Grid>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </UiDialog>
        </div>
    )
}

export default ProductCategorizeModal