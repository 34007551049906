import { useEffect } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core'
import WidgetBoxhiddenSM from '../../common/WidgetBoxHiddenSM'
import BusinessAndPersonal from './BusinessAndPersonal'
import TaxSubmissionReminderModal from './../TaxSubmissionReminderModal'
import { useWidgetContext } from './provider/WidgetProvider'
import TaxPrepLoader from './TaxPrepLoader'

const useStyles = makeStyles(()=> {
    return {
        container: {
            marginTop: '1rem 0 1rem 0',
            
        }
    }
})

const TAX_FORM_UPLOADED = 'tax_form_uploaded';
function TaxPrep() {
    const {
        taxPrepData,
        apiLoaded,
        taxYear,
        taxHeader,
        config,
        isHideWidget,
        vtoExtensionWidgets,
        appData,
        onclickOfNotification
    } = useWidgetContext()
    const classes = useStyles()

    useEffect(() => {
        if(sessionStorage.getItem(TAX_FORM_UPLOADED)){
            onclickOfNotification({open: TAX_FORM_UPLOADED});
        }
        return () => {
            sessionStorage.removeItem(TAX_FORM_UPLOADED);
        }
    }, [onclickOfNotification]);

    return (
        <div className={classes.container}>
            {vtoExtensionWidgets && !isHideWidget && (
                <WidgetBoxhiddenSM heading={taxHeader}>
                    <Box marginTop={apiLoaded ? 1 : 4}>
                        <Grid container spacing={4}>
                            {apiLoaded ? (
                                <BusinessAndPersonal
                                    taxPrepData={taxPrepData}
                                    taxYear={taxYear}
                                    appData={appData}
                                    config={config}
                                />
                            ) : (
                                <TaxPrepLoader />
                            )}
                            {apiLoaded ? (
                                <TaxSubmissionReminderModal
                                    taxData={taxPrepData}
                                />
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Box>
                </WidgetBoxhiddenSM>
            )}
        </div>
    )
}

export default TaxPrep;
