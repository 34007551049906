import { Fragment } from 'react';
import { Formik, Form } from 'formik';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
    Box,
    Grid,
    GridSize,
    Button,
    Hidden,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import Loader from '../../../common/Loader';
import ModalMobileHeader from '../../../common/ModalMobileHeader';
import { ApplicationStore } from '../../../../models';
import { EmpData } from '../../../../models/payroll/employee';
import OwnershipForm from './OwnershipForm';
import { commonStyles } from '../../../../styles/commonStyles';
import {
    personalDetailFormMapping,
    personalDetailValues,
} from './PersonalDetailsFormUtils';
import { useEmployeeState } from '../EmployeeProvider';
import { showAlert } from '../../../../store/actions/feedback';
import { formSubmitErrorHandler } from '../../../../services/formService';
import { updateEmployeeData } from '../../../../services/apiService/payroll/employee';
import { RenderFormFields } from './FormUtils';

const PersonalDetailsForm = ({
    employee,
    businessId,
    callback,
    handleClose,
    formFooterStyles,
}: {
    employee: EmpData | undefined;
    businessId: string;
    callback?: () => void;
    handleClose: () => void;
    formFooterStyles: any;
}) => {
    const styles = commonStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { companyDetails } = useEmployeeState();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isSCorpCompany = companyDetails?.entity_type === 'S-Corporation';
    let initialValues = isSCorpCompany
        ? {
              ...personalDetailValues(employee),
              two_percent_shareholder: employee?.two_percent_shareholder
                  ? 'yes'
                  : 'no',
          }
        : personalDetailValues(employee);

    const validationSchema = Yup.object({
        first_name: Yup.string().required('First name is required'),
        middle_initial: Yup.string(),
        last_name: Yup.string().required('Last Name is required'),
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        date_of_birth: Yup.string().required('Date of Birth is required'),
        ...(!employee?.has_ssn ? { ssn: Yup.string().required('SSN is required') } : {}),
        ...(isSCorpCompany
            ? {
                  two_percent_shareholder: Yup.string().required(
                      'Two Percent Shareholder is required'
                  ),
              }
            : {}),
    });

    const onSubmit = (data: any, { setFieldError, setSubmitting }: any) => {
        let params = {
            ...data,
            version: employee?.version,
            employee_uuid: employee?.employee_uuid,
        };
        if (isSCorpCompany) {
            params = {
                ...params,
                two_percent_shareholder:
                    data?.two_percent_shareholder === 'yes',
            };
        } else {
        }
        // remove ssn from payload if ssn is not there
        let { ssn, ...rest } = params;
        params = ssn ? params : rest;

        formSubmitErrorHandler(
            updateEmployeeData(businessId, params).then((response: any) => {
                if (response) {
                    callback?.();
                    dispatch(
                        showAlert({
                            alertText: `employee details updated successfully`,
                            alertType: 'success',
                        })
                    );
                }
            }),
            () => {
                setSubmitting(false);
            },
            setFieldError,
            false
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ submitForm, touched, isSubmitting, values }) => {
                return (
                    <Form>
                        <ModalMobileHeader
                            title={'Edit Personal Info'}
                            buttonText={'save'}
                            loading={isSubmitting}
                            handleClose={handleClose}
                            handleButtonAction={() => {
                                submitForm();
                            }}
                        />
                        <Box className={isSmDown ? styles.mt24 : ''}>
                            {personalDetailFormMapping.map((property: any) => (
                                <Fragment key={property.key}>
                                    <Grid container spacing={1}>
                                        <Grid
                                            item
                                            xs={property?.xs as GridSize}
                                            md={property?.md as GridSize}
                                        >
                                            <div className={styles.mt16}>
                                                <RenderFormFields property={property} />
                                                {property?.infoText}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            ))}
                            {isSCorpCompany && (
                                <OwnershipForm
                                    
                                />
                            )}
                        </Box>
                        <Box my={1} style={{ marginBottom: '60px' }}></Box>

                        <Hidden smDown>
                            <div className={formFooterStyles}>
                                {isSubmitting ? (
                                    <Loader />
                                ) : (
                                    <Grid justify="flex-end" container>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleClose}
                                            className={styles.mr16}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={submitForm}
                                        >
                                            Save Changes
                                        </Button>
                                    </Grid>
                                )}
                            </div>
                        </Hidden>
                    </Form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
});

export default connect(mapStateToProps)(PersonalDetailsForm);
