import { makeStyles, Theme } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import LinkButton from '../../../common/LinkButton'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    tosFooter: {
        textAlign: 'center',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '1.5rem 1.5rem 1rem 1.5rem',
        width: '40rem',
        boxSizing: 'border-box',
        color: (colorTheme) => colorTheme.black100,

        '& div': {
            margin: '0.5rem 0',
            padding: '0.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem',
        },
    },
}))
const TOSFooterSection = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    return (
        <div className={classes.tosFooter}>
            <UiText variant="motorcycle_90">
                If you encounter any issues or concerns, <br />
                connect with our support team for help.
            </UiText>
            <div>
                <LinkButton
                    onClick={() =>
                        window.open(
                            'https://1800accountant.my.site.com/support/s/contactsupport'
                        )
                    }
                >
                    <UiText variant="motorcycle_90" weight="semi_bold_600">
                        Contact Us
                    </UiText>
                </LinkButton>
            </div>
        </div>
    )
}

export default TOSFooterSection
