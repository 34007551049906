import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

export default function CircularProgressWithLabel(
   props: any
) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            bottom: {
                color: props.bgColor || colorTheme.grey2100,
            },
            top: {
                color: props.color || theme.palette.primary,
                position: 'absolute',
                left: 0,
            },
            circle: {
                strokeLinecap: 'round',
            },
        };
    })();
    return (
        <Box position='relative'>
            <CircularProgress
                variant='static'
                className={classes.bottom}
                size={40}
                thickness={4}
                value={100}
            />
            <CircularProgress
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={40}
                thickness={4}
                variant='static'
                value={props.value}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                width={props.value}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    variant='caption'
                    component='div'
                    color='textSecondary'
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
