import { Invoice } from '../../../models'
import moment from 'moment'
/**
 * @param invoice 
 * @returns invoiceStatus 
 */

export const isOverDue = (invoice: Invoice) => {
    return invoice.is_overdue ||
        (invoice.payment_status === 'unpaid' &&
            (invoice.due_date*1000) <= moment.now())
            ? 'Overdue'
            : 'Unpaid'
}

export const IsOverDueInvoicePaid = (invoice: Invoice) => {
    return invoice.payment_status === 'paid' && ((invoice.due_date*1000) <= moment.now())
}

export const getInvoiceStatus = (invoice: Invoice) => {
    let isPaid = invoice.payment_status === 'paid'
    return isPaid ? 'Paid': isOverDue(invoice)
}

export const statusIcon: any = {
    'Unpaid': 'clock',
    'Paid': 'check',
    'Overdue': 'hourglass',
    'Sent': 'emailSent'
}

export const defaultQueryObject: any = {
    term: '',
    paymentStatus: 'All Invoices',
    contact: '',
    offering: '',
    due_period: 'All Time',
    invoice_period: 'All Time',
    refType: 'Contains',
    refNumber: '',
    amountType: 'Greater Than',
    amount: '',
    minDueDate: '',
    maxDueDate: '',
    minInvoiceDate: '',
    maxInvoiceDate: ''
}


export const resetParams = (key: string) => {
    switch (key) {
        case 'due_period': 
            return { 
                due_period: 'All Time',
                minDueDate: '',
                maxDueDate: ''
            }
        case 'invoice_period': 
            return { 
                invoice_period: 'All Time',
                minInvoiceDate: '',
                maxInvoiceDate: ''
            }
        case 'amount': 
            return { 
                amountType: 'Greater Than',
                amount: '',
            }
        case 'refNumber': 
            return {
                refType: 'Contains',
                refNumber: '',
            }  
        case 'contact': 
            return {
                contact: ''
            }
        case 'paymentStatus': 
            return {
                paymentStatus: 'All Invoices'
            }     
        case 'offering':
            return {
                offering: '',
            } 
        case 'term':
            return {
                term: '',
            }         
        default: 
            break;    
    }
}


export const statusProperty: any = {
    'Unpaid': 'clock',
    'Paid': 'check',
    'Overdue': 'hourglass',
    'Sent': 'emailSent'
}

export const paymentTermsOptions: any = [
    {
        label: 'Custom',
        value: '-',
        disabled: true
    },
    {
        label: 'Net 10',
        value: 'net10',
        disabled: false
    },
    {
        label: 'Net 15',
        value: 'net15',
        disabled: false
    },
    {
        label: 'Net 30',
        value: 'net30',
        disabled: false
    }
]

export const paymentTermsMapping: any = {
    '': 'Custom',
    'net10': 'Net 10',
    'net15': 'Net 15',
    'net30': 'Net 30'
}
export const paymentTermsIndex: any = {
    '': 0,
    'net10': 1,
    'net15': 2,
    'net30': 3
}
export const paymentTerms = ['', 'net10', 'net15', 'net30']
export const paymentTermDays  = ['', '10', '15','30']
export const dropdownOptions: string[] = ['All Invoices', 'Paid', 'Unpaid', 'Overdue'];
export const invoiceTypeMapping: any = {
    'All Invoices': 'all',
    Paid: 'paid',
    Unpaid: 'unpaid',
    Overdue: 'overdue',
};

export const paymentTermsKeys: any = {
    "": 'Custom',
    '-': 'Custom',
    'net10': 'Net 10',
    'net15': 'Net 15',
    'net30': 'Net 30'
}