import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';

export default function Calendar() {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        setActiveRouteHeading('Calendar');
    });

    const iframe = () => {
        return {
            __html: `<iframe src="${window.origin
                }/portal-embed/#/calendar?${query || ''
                }" width="100%" height="100%"></iframe>`
        };
    };
    return (
        <div
            style={{ height: '100%', overflow: 'hidden' }}
            dangerouslySetInnerHTML={iframe() as any}
        ></div>
    );
}
