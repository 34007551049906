import Loader from '../../common/Loader'
import { useADPCompanyContext } from '../provider/CompanyProvider'
import CompanyDetails from './CompanyDetails'
import {
    OnboardingStepInfo,
    SETUP_TYPE,
} from '../../../models/adp-payroll/company'
import PayrollConnectionInfo from './PayrollConnectInfo'

const SetupCompany = () => {
    const {
        loadingData,
        companyStepInfo,
        isNewOrOld,
    }: {
        loadingData: boolean
        companyStepInfo: OnboardingStepInfo
        isNewOrOld: SETUP_TYPE
    } = useADPCompanyContext()

    return loadingData ? (
        <Loader />
    ) : isNewOrOld === SETUP_TYPE.OLD &&
      companyStepInfo.current_step === 'company_combined_data_collection' ? (
        <PayrollConnectionInfo />
    ) : (
        <CompanyDetails />
    )
}
export default SetupCompany
