import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
    Grid,
    GridSize,
    Button,
    Hidden,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { commonStyles } from "../../../../styles/commonStyles";
import Loader from "../../../common/Loader";
import { jobDetailFormMapping } from "./JobDetailsFormUtils";
import { ApplicationStore } from "../../../../models";
import { EmpData, Compensation } from "../../../../models/payroll/employee";
import ModalMobileHeader from "../../../common/ModalMobileHeader";
import { getBusinessAddress } from "../../../../services/apiService/payroll/company";
import {
    updateJob,
    createJob,
} from "../../../../services/apiService/payroll/employee";
import { formSubmitErrorHandler } from "../../../../services/formService";
import { showAlert } from "../../../../store/actions/feedback";
import { RenderFormFields } from "./FormUtils";

const JobDetaisForm = ({
    employee,
    businessId,
    callback,
    job,
    compensation,
    handleClose,
    formFooterStyles,
}: {
    employee: EmpData | undefined;
    businessId: string;
    job?: any;
    callback?: () => void;
    compensation?: Compensation | undefined;
    handleClose: () => void;
    formFooterStyles: any;
}) => {
    const styles = commonStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    const [initialValues, setInitialValues] = useState<{
        hire_date: string;
        title: string;
        location_id: string;
    }>({
        hire_date: "",
        title: "",
        location_id: "",
    });
    const [locationData, setLocationData] = useState<any>();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        title: Yup.string().required("Title is required"),
        hire_date: Yup.string().required("Hire Date is required"),
        location_id: Yup.string().required("Location is required"),
    });

    useEffect(() => {
        setLoading(true);
        getBusinessAddress(businessId).then((address: any) => {
            if (address) {
                setLocationData([address]);
            }
        });
    }, [businessId]);

    useEffect(() => {
        if (locationData) {
            let formValues = {
                hire_date: job?.hire_date,
                title: job?.title,
                location_id: locationData?.find(
                    (l: any) => l?.id === job?.location_id
                )?.id,
            };
            setInitialValues(formValues);
            setLoading(false);
        }
    }, [job?.hire_date, job?.location_id, job?.title, locationData]);

    const onSubmit = (data: any, { setFieldError, setSubmitting }: any) => {
        let params = {
            ...data,
            location_id: Number.parseInt(data?.location_id),
        };

        params = job
            ? { ...params, uuid: job?.uuid, version: job?.version }
            : { ...params, employee_uuid: employee?.employee_uuid };

        let Api = job?.version
            ? updateJob(businessId, params)
            : createJob(businessId, params);
        formSubmitErrorHandler(
            Api.then((response: any) => {
                if (response) {
                    callback?.();
                    dispatch(
                        showAlert({
                            alertText: `Employee job ${
                                job ? `details updated` : "created"
                            } successfully`,
                            alertType: "success",
                        })
                    );
                }
            }),
            () => {
                setSubmitting(false);
            },
            setFieldError
        );
    };

    return (
        <Grid style={{ height: "100%" }}>
            {loading ? (
                <Loader />
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ submitForm, isSubmitting }) => {
                        return (
                            <Form>
                                <ModalMobileHeader
                                    title={
                                        job ? "Edit Job Info" : "Create New Job"
                                    }
                                    buttonText={"save"}
                                    loading={isSubmitting}
                                    handleClose={handleClose}
                                    handleButtonAction={submitForm}
                                />
                                <div className={isSmDown ? styles.mt24 : ""}>
                                    {jobDetailFormMapping(locationData).map(
                                        (property: any) => {
                                            return (
                                                <Grid container spacing={1}>
                                                    <Grid
                                                        item
                                                        xs={
                                                            property?.xs as GridSize
                                                        }
                                                        md={
                                                            property?.md as GridSize
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.mt16
                                                            }
                                                        >
                                                            <RenderFormFields property={property}/>
                                                            {property?.infoText}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    )}
                                </div>
                                <Hidden smDown>
                                    <div className={formFooterStyles}>
                                        {isSubmitting ? (
                                            <Loader />
                                        ) : (
                                            <Grid justify="flex-end" container>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={handleClose}
                                                    className={styles.mr16}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={submitForm}
                                                >
                                                    Save Changes
                                                </Button>
                                            </Grid>
                                        )}
                                    </div>
                                </Hidden>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </Grid>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
});

export default connect(mapStateToProps)(JobDetaisForm);
