import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
    Button,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Theme,
    withStyles,
    createStyles,
    useMediaQuery,
    Box,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    useTheme,
} from '@material-ui/core';
import { Add, ArrowUpward, MoreVert } from '@material-ui/icons';
import * as Yup from 'yup';
import { ReactComponent as Manage } from '../../../assets/icons-svg/manage.svg';
import Section from './Section';
import { debounce } from '../../../utils/debounce';
import UiDialog from '../../common/ui/UiDialog';
import { Formik } from 'formik';
import NewCategoryForm from './NewCategoryForm';
import SegmentPage from './SegmentManagement';
import DeletePopup from './DeletePopup';
import {
    getChartOfAccountantsData,
    getSegmentList,
    createNewSegment,
    createNewCategory,
    updateCategory,
    deleteCategory,
} from '../../../services/apiService/chartOfAccountants';
import { connect, useDispatch } from 'react-redux';
import { ApplicationStore } from '../../../models';
import Loader from '../../common/Loader';
import { tableOptions } from './coa.const';
import TableMenusList from './TableMenuList';
import { MoveAccount } from './MoveAccount';
import { formSubmitErrorHandler } from '../../../services/formService';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import { showAlert } from '../../../store/actions/feedback';
import { ChartOfAccountantsData } from '../../../models/chartOfAccountants';
import { ReactComponent as FileText } from '../../../assets/icons-svg/FileTextSmall.svg';
import { useHistory } from 'react-router-dom';
import { loadCategories } from '../../../store/actions/categories';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        padding: theme.spacing(4),
        position: 'relative',
        width: '81%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '95%',
        }
    },
    manageSegments: {
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        marginTop: '16px',
        height: 40,
        fontWeight: 500,
        fontSize: '1rem',
        border: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        textTransform: 'capitalize',
        color: (colorTheme) => colorTheme.blue200,
        '&:hover': {
            border: (colorTheme) => `1px solid ${colorTheme.blue200}`,
        },
    },
    content: {
        width: 'calc(100% - 250px)',
        [theme.breakpoints.down('sm')]: {
            marginTop: '110px',
            width: '95%',
        },
    },
    navigation: {
        position: 'fixed',
        right: '8%',
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            right: '1%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'fixed',
            top: '5%',
            right: 'auto',
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            paddingTop: '50px',
        },
    },
    navSection: {
        marginTop: '20px',
    },
    section: {
        '&:last-child': {
            minHeight: '600px',
        },
    },
    borderLeft: {
        display: 'block',
        width: '4px',
        height: '140px',
        position: 'absolute',
        bottom: '10px',
        [theme.breakpoints.down('sm')]: {
            bottom: 'auto',
        },
        backgroundColor: (colorTheme) => colorTheme.grey200,

    },
    segment: {
        right: '50%',
        backgroundColor: (colorTheme) => colorTheme.grey100,
        borderRadius: '16px',
        padding: '4px 12px 4px 12px',
        marginRight: '5.1rem',
    },
    levelTwo: {
        fontSize: '1.125rem',
        fontWeight: 600,
    },
    listItem: {
        paddingLeft: 0,
    },
    darkDivider: {
        backgroundColor: (colorTheme) => colorTheme.grey800,
    },
    actionButton: {
        margin: '0 10px 10px 0',
        width: '170px',
    },
    deleteButton: {
        backgroundColor: (colorTheme) => colorTheme.red200,
        color: (colorTheme) => colorTheme.primaryWhite,
        margin: '0 10px 10px 0',
        width: '170px',
        '&:hover': {
            backgroundColor: (colorTheme) => colorTheme.red200,
            border: (colorTheme) =>  `1px solid ${colorTheme.red200}`,
        },
    },
    scrollButtom: {
        position: 'fixed',
        right: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        bottom: '1rem',
        width: '2.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: (colorTheme) => colorTheme.primary,
        color: (colorTheme) => colorTheme.primaryWhite,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    moveInput: {
        margin: '24px 0 40px 0',
    },
    buttonLoader: {
        width: '135px',
    },
}));

const AntTabs = withStyles((theme: Theme) => ({
    root: {
        display: 'block',
    },
    fixed: {
        width: '60%',
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        left: '0',
        width: '4px',
        borderRadius: '4px',
        height: '20px',
    },
}))(Tabs);

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            paddingLeft: '10px',
            margin: 1,
            minHeight: '10px',
            fontWeight: 400,
        },
        wrapper: {
            alignItems: 'flex-start',
        },
    })
)((props: any) => <Tab {...props} />);

export interface TableMenuProps {
    handleMenuClick: (
        e: React.MouseEvent<HTMLButtonElement>,
        data: any
    ) => void;
    rowData: any;
    runReport: any;
}

const TableMenus = ({ handleMenuClick, rowData = {}, runReport }: TableMenuProps) => {
    return (
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="comments" onClick={runReport}>
                <FileText />
            </IconButton>
            <IconButton
                edge="end"
                aria-label="comments"
                onClick={(e) => handleMenuClick(e, rowData)}
                data-cy={`${rowData?.title
                    .toLocaleLowerCase()
                    .replaceAll(' ', '-')}-open-more-btn`}
            >
                <MoreVert />
            </IconButton>
        </ListItemSecondaryAction>
    );
};

const childToParentMap = (
    formattedCategories: any,
    setChildIdToParent: any,
    childToParent: any = {},
    parent: any = null
) => {
    formattedCategories.forEach((data: any) => {
        if(data){
            childToParent[data.id] = parent;
            if (data.children.length) {
                childToParentMap(
                    data.children,
                    setChildIdToParent,
                    childToParent,
                    data
                );
            }
        }
    });
    setChildIdToParent(childToParent);
};

const findChildren = (dataList: any, parentId: any) => {
    const children: any[] = [];
    dataList.forEach((item: any) => {
        if (item.parent === parentId) {
            children.push({ ...item, children: [] });
        }
    });
    return children;
};

const formatCategoryData = (dataList: []) => {
    const resultData: any[] = [];
    dataList.forEach((item: any) => {
        if (item.level === 1) {
            resultData.push({ ...item, children: [] });
        } else {
            resultData.forEach((parentLevel1: any, i: number) => {
                resultData[i].children = findChildren(
                    dataList,
                    parentLevel1.id
                );
                resultData[i].children.forEach(
                    (parentLevel2: any, j: number) => {
                        resultData[i].children[j].children = findChildren(
                            dataList,
                            parentLevel2.id
                        );
                        resultData[i].children[j].children.forEach(
                            (parentLevel3: any, k: number) => {
                                resultData[i].children[j].children[k].children =
                                    findChildren(dataList, parentLevel3.id);
                            }
                        );
                    }
                );
            });
        }
    });
    return [
        resultData[0],
        resultData[3],
        resultData[4],
        resultData[2],
        resultData[1],
    ];
};

const sortCategories: any = (dataList: []) => {
    return dataList.sort((firstObject: any, secondObject: any) => {
        const firstTitle = firstObject.title.toUpperCase();
        const secondTitle = secondObject.title.toUpperCase();
        return firstTitle < secondTitle ? -1 : firstTitle > secondTitle ? 1 : 0;
    });
};
export interface L1AndL2Parents {
    l1Parent: ChartOfAccountantsData;
    l2Parent: ChartOfAccountantsData;
}
function ChartOfAccounts(props: any) {
    const theme = useTheme();
    const { appData } = props;
    const { colorTheme } = useThemeContext();
    const classes = useStyles(colorTheme);
    const dispatch = useDispatch();
    const history = useHistory();
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const firstRef: any = useRef(null);
    const secondRef: any = useRef(null);
    const ThirdRef: any = useRef(null);
    const fourthRef: any = useRef(null);
    const fifthRef: any = useRef(null);
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [allCategories, setAllCategories] = useState<any>([]);
    const [formattedAllCategories, setFormattedAllCategories] = useState<any>(
        []
    );
    const [isNewAccountModalOpen, setIsNewAccountModalOpen] =
        useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [movePopup, setMovePopup] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [isSegmentPage, setIsSegmentPage] = useState(false);
    const [isScrollable, setIsScrollable] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [segmentList, setSegmentList] = useState<any>([]);
    const [parentsCategories, setParentsCategories] = useState<any>([]);
    const [childIdToParent, setChildIdToParent] = useState<any>({});
    const [initialValuesForCategory, setInitialValuesForCategory] = useState({
        title: '',
        parent: {},
        segment: {},
    });

    const [level3Categories, setLevel3Categories] = useState<
        ChartOfAccountantsData[]
    >([]);
    const [currentParentOfL4, setCurrentParentOfL4] =
        useState<ChartOfAccountantsData>();

    const [dataForMenu, setDataForMenu] = useState<any>({});
    const [sections, setSections] = useState<string[]>([
        'Assets',
        'Liabilities',
        'Revenue',
        'Expenses',
        'Capital',
    ]);
    const [srcCategory, setSrcCategory] = useState<ChartOfAccountantsData>();
    const [isFormSubmiting, setIsFormSubmiting] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [l1AndL2Parents, setL1AndL2Parents] = useState<L1AndL2Parents>();
    
    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        data: any
    ) => {
        setDestCategoriesForMove(data);
        setAnchorEl(event.currentTarget);
        setDataForMenu(data);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const getTheIndexRef = (id: string) => {
        switch (id) {
            case sections[0]:
                return firstRef;
            case sections[1]:
                return secondRef;
            case sections[2]:
                return ThirdRef;
            case sections[3]:
                return fourthRef;
            case sections[4]:
                return fifthRef;
            default:
                return fifthRef;
        }
    };
    const ifSrcCategoryLeafNode = (
        srcCategoryDetails: ChartOfAccountantsData | undefined
    ) => {
        return srcCategoryDetails?.is_leaf;
    };

    const getLevel3CategoriesWithLevel2Parents = (
        allCategories: ChartOfAccountantsData[],
        srcCategoryDetails: ChartOfAccountantsData
    ): ChartOfAccountantsData[] => {
        const srcCategoryParent = srcCategoryDetails.parent;
        const level2Parent = childIdToParent[srcCategoryParent].id;

        const level3 = getChildrenFromLevel2Parent(
            level2Parent,
            allCategories,
            srcCategoryParent
        );
        return level3;
    };

    const fetchLevel3CategoriesforMove = (
        srcCategoryDetails: ChartOfAccountantsData
    ) => {
        if (!ifSrcCategoryLeafNode(srcCategoryDetails)) return;

        const srcCategoryLevel = srcCategoryDetails?.level;
        const srcCategoryId = srcCategoryDetails?.id;
        const srcCategoryParent = srcCategoryDetails?.parent;
        if (!srcCategoryLevel || !srcCategoryId) {
            return;
        }
        setSrcCategory(srcCategoryDetails);
        let level3: ChartOfAccountantsData[] = [];
        /*
            Items can be moved from level3/4 to level3
            If src = level3, dest should have same level2 parents
            If src= level4, dest should have same level3 parents
            Also, l3 categories with children cant be moved (must be a leaf node)
        */
        if (srcCategoryDetails && srcCategoryLevel === 4) {
            level3 = getLevel3CategoriesWithLevel2Parents(
                allCategories,
                srcCategoryDetails
            );
        } else {
            // if src category is level 3
            level3 = getLevel3CategoriesWithLevel3Parents(
                allCategories,
                srcCategoryParent,
                srcCategoryId
            );
        }
        return level3;
    };

    const isCurrentCategoryLevel3 = (
        srcCategoryDetails: ChartOfAccountantsData
    ) => {
        return srcCategoryDetails?.level === 3;
    };

    const getParentCategoryObjectFromId = (
        parentId: string
    ): ChartOfAccountantsData => {
        const parentCatgory = allCategories.filter(
            (category: ChartOfAccountantsData) => {
                return category.id === parentId;
            }
        );
        return parentCatgory[0];
    };

    const getLevel3ParentObject = (
        srcCategoryDetails: ChartOfAccountantsData | undefined
    ): ChartOfAccountantsData | undefined => {
        if (!srcCategoryDetails) return;
        const srcCategoryParent = srcCategoryDetails.parent;
        const parentCategoryObj =
            getParentCategoryObjectFromId(srcCategoryParent);
        return parentCategoryObj;
    };

    const fetchcurrentParentOfL4 = (
        srcCategoryDetails: ChartOfAccountantsData
    ) => {
        if (isCurrentCategoryLevel3(srcCategoryDetails)) {
            return;
        }
        return getLevel3ParentObject(srcCategoryDetails);
    };

    const fetchL1AndL2Parents = (
        srcCategoryDetails: ChartOfAccountantsData | undefined,
        currentParentOfL4: ChartOfAccountantsData | undefined
    ) => {
        if (!srcCategoryDetails) return;
        if (currentParentOfL4) {
            // if current category is level4
            const level2Parent = childIdToParent[currentParentOfL4.id];
            const level1Parent = childIdToParent[level2Parent.id];
            const l1l2Parents = {
                l1Parent: level1Parent,
                l2Parent: level2Parent,
            };
            return l1l2Parents;
        }
        // if current category is level3
        const level2Parent = childIdToParent[srcCategoryDetails.id];
        const level1Parent = childIdToParent[level2Parent.id];
        const l1l2Parents = {
            l1Parent: level1Parent,
            l2Parent: level2Parent,
        };
        return l1l2Parents;
    };
    const setL1AndL2ParentsForMoveDropdown = (
        srcCategoryDetails: ChartOfAccountantsData
    ) => {
        const currentParentOfL4 = fetchcurrentParentOfL4(srcCategoryDetails);

        const level1And2Parents = fetchL1AndL2Parents(
            srcCategoryDetails,
            currentParentOfL4
        );
        setCurrentParentOfL4(currentParentOfL4);
        setL1AndL2Parents(level1And2Parents);
    };
    const setDestCategoriesForMove = (
        srcCategoryDetails: ChartOfAccountantsData
    ) => {
        const level3DestCategories =
            fetchLevel3CategoriesforMove(srcCategoryDetails);
        if (!level3DestCategories) {
            setLevel3Categories([]);
            return;
        }
        setL1AndL2ParentsForMoveDropdown(srcCategoryDetails);
        setLevel3Categories(level3DestCategories);
    };

    const getLevel3CategoriesWithLevel3Parents = (
        allCategories: ChartOfAccountantsData[],
        srcCategoryParent: string | undefined,
        srcCategoryId: string
    ) => {
        const level3 = allCategories.filter(
            (category: ChartOfAccountantsData) => {
                return (
                    category.level === 3 &&
                    category.parent === srcCategoryParent &&
                    category.id !== srcCategoryId
                );
            }
        );
        return level3;
    };

    const getChildrenFromLevel2Parent = (
        parentId: string,
        allCategories: ChartOfAccountantsData[],
        srcCategoryParent: string
    ) => {
        const level3 = allCategories.filter((category) => {
            return (
                category.parent === parentId &&
                category.id !== srcCategoryParent
            );
        });
        return level3;
    };

    const handleUpdateCategoryData = (categories: ChartOfAccountantsData[]) => {
        const sortedData = sortCategories(categories);
        const formattedCategories = formatCategoryData(sortedData);
        setSections(
            formattedCategories.map((item: any) => {
                return item?.title === 'Equity' ? 'Capital' : item?.title;
            })
        );
        setAllCategories(sortedData);
        setFormattedAllCategories(formattedCategories);
        childToParentMap(formattedCategories, setChildIdToParent);
        setParentsCategories(
            sortedData.filter((category: any) => category.level !== 4)
        );
    };

    useEffect(() => {
        setActiveRouteHeading('Chart of Accounts');
    }, [setActiveRouteHeading]);

    const fetchData = () => {
        setIsLoading(true);
        Promise.all([
            getChartOfAccountantsData(
                appData?.current_account_id,
                appData?.current_business_id
            ),
            getSegmentList(
                appData?.current_account_id,
                appData?.current_business_id
            ),
        ])
            .then((response: any) => {
                handleUpdateCategoryData(response[0]);
                setSegmentList(response[1]);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
    useEffect(() => {
        fetchData();
    }, [appData?.current_account_id, appData?.current_business_id]);

    useEffect(() => {
        props.category?.categories && handleUpdateCategoryData(props.category.categories);
    }, [props.category])

    const [value, setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const clickedSection: any = event.currentTarget;
        const activeRef = getTheIndexRef(clickedSection.id);
        activeRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setValue(newValue);
    };

    const tooltipText = (section: string) => {
        switch (section) {
            case sections[0]:
                return 'An asset is simply something your business owns. It could be a physical good like cash or equipment, or it could be a non-tangible item such as a trademark or goodwill.';
            case sections[1]:
                return 'A liability represents money your businesses owes to someone else. Some examples are money owed to your vendors (Accounts Payable), a credit card company, or a bank.';
            case sections[2]:
                return 'Revenue is a cash inflow generated by the business through the sale of goods or services.';
            case sections[3]:
                return 'An expense is a cash outflow used to pay a vendor for a service needed to run your business. These include things such as payroll for your employees, rent, etc.';
            case sections[4]:
                return 'Capital is the total remaining assets in the business minus the amount of capital distributed back to the owners and any outstanding liabilities.';
            default:
                return '';
        }
    };

    const handleScrollToElement = debounce((event: any) => {
        const scrollDemo = document.querySelector('#content-scroll')!;
        const currentPosition = scrollDemo.scrollTop;
        let finalValue = 0;
        if (firstRef?.current?.scrollHeight >= currentPosition) {
            finalValue = 0;
        } else if (
            firstRef?.current?.scrollHeight +
                secondRef?.current?.scrollHeight >=
            currentPosition
        ) {
            finalValue = 1;
        } else if (
            firstRef?.current?.scrollHeight +
                secondRef?.current?.scrollHeight +
                ThirdRef?.current?.scrollHeight >=
            currentPosition
        ) {
            finalValue = 2;
        } else if (
            firstRef?.current?.scrollHeight +
                secondRef?.current?.scrollHeight +
                ThirdRef?.current?.scrollHeight +
                fourthRef?.current?.scrollHeight >=
            currentPosition + 300
        ) {
            finalValue = 3;
        } else {
            finalValue = 4;
        }
        setValue(finalValue);
        if (currentPosition > 100) {
            setIsScrollable(true);
        } else {
            setIsScrollable(false);
        }
    }, 50);

    useEffect(() => {
        const scrollDemo = document.querySelector('#content-scroll')!;
        scrollDemo.addEventListener('scroll', handleScrollToElement, {
            passive: true,
        });
        return () => {
            scrollDemo.removeEventListener('scroll', handleScrollToElement);
        };
    }, [handleScrollToElement]);

    const getDataForSegmentUpdate = (values: any) => {
        return dataForMenu.level === 4
            ? {
                  title: values.title,
                  coa_business_class: values?.segment?.id ?? '',
              }
            : {
                  title: values.title,
              };
    };

    const handleNewAccountSubmit = (values: any, { setFieldError }: any) => {
        setIsFormSubmiting(true);
        const isEdit = initialValuesForCategory.title ? true : false;
        const data = {
            title: values.title,
            parent: values.parent.id,
            coa_business_class: values?.segment?.id,
        };
        const hitApi = isEdit
            ? updateCategory(
                  appData?.current_account_id,
                  appData?.current_business_id,
                  getDataForSegmentUpdate(values),
                  dataForMenu.id
              )
            : createNewCategory(
                  appData?.current_account_id,
                  appData?.current_business_id,
                  data
              );

        formSubmitErrorHandler(
            hitApi.then((category: any) => {
                const categories = isEdit
                    ? allCategories.map((item: any) => {
                          if (item.id === category.id) {
                              return category;
                          }
                          return item;
                      })
                    : [...allCategories, category];
                handleUpdateCategoryData(categories);
                setIsNewAccountModalOpen(false);
                setIsFormSubmiting(false);
                dispatch(loadCategories());
                getSegmentList(
                    appData?.current_account_id,
                    appData?.current_business_id
                ).then((response: any) => {
                    setSegmentList(response);
                })
            }),
            () => {
                setIsFormSubmiting(false);
            },
            setFieldError
        );
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Account name is required'),
        parent: Yup.object().required('Parent account is required').nullable(),
        segment: Yup.object().nullable(),
    });

    let submitCloser: any;
    const actions = () => (
        <>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsNewAccountModalOpen(false)}
                data-cy="cancel-new-cat-btn"
            >
                Cancel
            </Button>
            {isFormSubmiting ? (
                <div className={classes.buttonLoader}>
                    <Loader />
                </div>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => submitCloser(e)}
                    disabled={isFormSubmiting}
                    data-cy="save-new-cat-btn"
                >
                    Save Account
                </Button>
            )}
        </>
    );

    const showAlertError = (error: { errors: { name: string } }) => {
        const message = error?.errors?.name;
        dispatch(
            showAlert({
                alertType: 'error',
                alertText: message ?? 'Something went wrong',
            })
        );
    };

    const handleAddCategoryChild = (parent: any) => {
        setInitialValuesForCategory({
            parent: parent,
            title: '',
            segment: {},
        });
        setIsNewAccountModalOpen(true);
    };

    const addNewSegment = (segment: string) => {
        const data = {
            name: segment,
        };
        createNewSegment(
            appData?.current_account_id,
            appData?.current_business_id,
            data
        )
            .then((newSegment) => {
                setSegmentList([...segmentList, newSegment]);
            })
            .catch((error) => {
                showAlertError(error);
            });
    };

    const handleDeleteCategory = () => {
        setIsDeleting(true);
        deleteCategory(
            appData?.current_account_id,
            appData?.current_business_id,
            dataForMenu.id
        )
            .then(() => {
                const categories = allCategories.filter(
                    (item: any) => item.id !== dataForMenu.id
                );
                handleUpdateCategoryData(categories);
                setDeletePopup(false);
                setIsDeleting(false);
                dispatch(loadCategories());
            })
            .catch((error) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: error.message ?? 'Something went wrong',
                    })
                );
                setIsDeleting(false);
            });
    };

    const handleChangeVisibility = () => {
        updateCategory(
            appData?.current_account_id,
            appData?.current_business_id,
            { is_hidden: !dataForMenu.is_hidden },
            dataForMenu.id
        )
            .then(() => {
                const categories = allCategories.map((item: any) => {
                    if (item.id === dataForMenu.id) {
                        return { ...item, is_hidden: !dataForMenu.is_hidden };
                    }
                    return item;
                });
                handleUpdateCategoryData(categories);
            })
            .catch((error) => {
                showAlertError(error);
            });
    };

    const navigateToTransactionsByAccount = (id: string) => {
        history.push({
            pathname: '/reports',
            search: `name=transactions&account=${id}`,
        });
    };

    const handleMenuClicked = (e: any, clickedMenu: string) => {
        switch (clickedMenu) {
            case tableOptions.runReport:
                navigateToTransactionsByAccount(dataForMenu.id);
                break;
            case tableOptions.move:
                setMovePopup(true);
                break;
            case tableOptions.delete:
                setDeletePopup(true);
                break;
            case tableOptions.hide:
                handleChangeVisibility();
                break;
            case tableOptions.addCategory:
                setInitialValuesForCategory({
                    parent: dataForMenu,
                    title: '',
                    segment: {},
                });
                setIsNewAccountModalOpen(true);
                break;
            case tableOptions.edit:
                setInitialValuesForCategory({
                    parent: childIdToParent[dataForMenu.id],
                    title: dataForMenu.title,
                    segment: dataForMenu?.coa_business_class ?? {},
                });
                setIsNewAccountModalOpen(true);
        }
    };

    const handleMoveAccountClose = (
        isDataUpdated: boolean,
        updatedCategory: ChartOfAccountantsData
    ) => {
        if (!isDataUpdated) return;
        allCategories.every((category: ChartOfAccountantsData, key: number) => {
            if (category.id === updatedCategory.id) {
                allCategories[key] = updatedCategory;
                handleUpdateCategoryData(allCategories);
                return false;
            }
            return true;
        });
    };
    const handleSegmentRefresh = () => {
        fetchData();
    };
    return isLoading || props.category?.loading ? (
        <Loader />
    ) : (
        <div className={classes.container}>
            <div className={classes.navigation}>
                <div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => handleAddCategoryChild({})}
                            data-cy="add-new-acc-btn"
                        >
                            New Account
                        </Button>
                    </div>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Manage />}
                        className={classes.manageSegments}
                        onClick={() => setIsSegmentPage(true)}
                        data-cy="manage-seg-btn"
                    >
                        Manage Segments
                    </Button>
                </div>
                {!isSmallDevice && (
                    <div className={classes.navSection}>
                        <Grid item xs={12}>
                            <div className={classes.borderLeft} />
                            <AntTabs
                                orientation="vertical"
                                value={value}
                                onChange={handleChange}
                            >
                                {sections.map((tab) => (
                                    <AntTab
                                        disableRipple
                                        label={tab}
                                        id={tab}
                                        key={tab}
                                    />
                                ))}
                            </AntTabs>
                        </Grid>
                    </div>
                )}
            </div>
            <div className={classes.content}>
                {isSmallDevice && (
                    <div className={classes.navSection}>
                        <Grid item xs={12}>
                            <div className={classes.borderLeft} />
                            <AntTabs
                                orientation="vertical"
                                value={value}
                                onChange={handleChange}
                            >
                                {sections.map((tab) => (
                                    <AntTab
                                        disableRipple
                                        label={tab}
                                        id={tab}
                                        key={tab}
                                    />
                                ))}
                            </AntTabs>
                        </Grid>
                    </div>
                )}
                {formattedAllCategories.map((category: any, index: number) => {
                    const title =
                        category?.title === 'Equity'
                            ? 'Capital'
                            : category?.title;
                    return (
                        <Section
                            ref={getTheIndexRef(title)}
                            title={title}
                            key={title}
                            className={classes.section}
                            tooltipText={tooltipText(title)}
                        >
                            <List>
                                {category?.children?.map(
                                    (level2: any, j: number) => {
                                        return (
                                            <Fragment key={level2.id}>
                                                <ListItem
                                                    className={classes.listItem}
                                                >
                                                    <ListItemText>
                                                        <span
                                                            className={
                                                                classes.levelTwo
                                                            }
                                                        >
                                                            {level2.title}
                                                        </span>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="comments"
                                                            data-cy={`${level2.title.split(' ').join('-')}-add-btn`}
                                                            onClick={() =>
                                                                handleAddCategoryChild(
                                                                    level2
                                                                )
                                                            }
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider
                                                    className={
                                                        classes.darkDivider
                                                    }
                                                />
                                                {level2?.children?.map(
                                                    (level3: any) => {
                                                        return (
                                                            <Fragment
                                                                key={level3.id}
                                                            >
                                                                <ListItem
                                                                    className={
                                                                        classes.listItem
                                                                    }
                                                                    disabled={
                                                                        level3.is_hidden
                                                                    }
                                                                >
                                                                    <ListItemText>
                                                                        {
                                                                            level3.title
                                                                        }
                                                                    </ListItemText>
                                                                    <TableMenus
                                                                        handleMenuClick={(
                                                                            e
                                                                        ) =>
                                                                            handleMenuClick(
                                                                                e,
                                                                                level3
                                                                            )
                                                                        }
                                                                        rowData={
                                                                            level3
                                                                        }
                                                                        runReport={()=>navigateToTransactionsByAccount(level3.id)}
                                                                    />
                                                                </ListItem>
                                                                <Divider
                                                                    variant="fullWidth"
                                                                    component="li"
                                                                />
                                                                <Box pl={4}>
                                                                    {level3?.children?.map(
                                                                        (
                                                                            level4: any
                                                                        ) => (
                                                                            <Fragment
                                                                                key={
                                                                                    level4.id
                                                                                }
                                                                            >
                                                                                <ListItem
                                                                                    className={
                                                                                        classes.listItem
                                                                                    }
                                                                                    disabled={
                                                                                        level4.is_hidden
                                                                                    }
                                                                                >
                                                                                    <ListItemText>
                                                                                        <Grid
                                                                                            container
                                                                                            justify="space-between"
                                                                                        >
                                                                                            <Grid
                                                                                                item
                                                                                            >
                                                                                                {
                                                                                                    level4.title
                                                                                                }
                                                                                            </Grid>
                                                                                            {level4
                                                                                                ?.coa_business_class
                                                                                                ?.name && (
                                                                                                <Grid
                                                                                                    item
                                                                                                    className={
                                                                                                        classes.segment
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        level4
                                                                                                            ?.coa_business_class
                                                                                                            ?.name
                                                                                                    }
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Grid>
                                                                                    </ListItemText>
                                                                                    <TableMenus
                                                                                        handleMenuClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleMenuClick(
                                                                                                e,
                                                                                                level4
                                                                                            )
                                                                                        }
                                                                                        rowData={
                                                                                            level4
                                                                                        }
                                                                                        data-cy="run-report"
                                                                                        runReport={()=>navigateToTransactionsByAccount(level4.id)}
                                                                                    />
                                                                                </ListItem>
                                                                                <Divider
                                                                                    variant="fullWidth"
                                                                                    component="li"
                                                                                />
                                                                            </Fragment>
                                                                        )
                                                                    )}
                                                                </Box>
                                                            </Fragment>
                                                        );
                                                    }
                                                )}
                                            </Fragment>
                                        );
                                    }
                                )}
                            </List>
                        </Section>
                    );
                })}
            </div>
            <TableMenusList
                handleMenuClose={handleMenuClose}
                anchorEl={anchorEl}
                handleClick={handleMenuClicked}
                dataForMenu={dataForMenu}
                level3Categories={level3Categories}
            />
            <UiDialog
                open={isNewAccountModalOpen}
                handleClose={() => setIsNewAccountModalOpen(false)}
                title={`${
                    initialValuesForCategory.title ? 'Edit' : 'New'
                } Account`}
                size="sm"
                fullScreen={isSmallDevice ? true : false}
                actions={actions()}

            >
                <Formik
                    initialValues={initialValuesForCategory}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleNewAccountSubmit}
                    validateOnMount={true}
                >
                    {({
                        handleSubmit,
                        setFieldValue,
                        values: formValues,
                        setTouched,
                    }) => {
                        submitCloser = handleSubmit;
                        return (
                            <NewCategoryForm
                                formValues={formValues}
                                setFieldValue={setFieldValue}
                                addNewSegment={addNewSegment}
                                segmentList={segmentList}
                                parentsCategories={parentsCategories}
                                childIdToParent={childIdToParent}
                                setTouched={setTouched}
                                initialValuesForCategory={
                                    initialValuesForCategory
                                }
                            />
                        );
                    }}
                </Formik>
            </UiDialog>
            <MoveAccount
                isOpen={movePopup}
                handleClose={setMovePopup}
                classes={classes}
                level3={level3Categories}
                currentParentOfL4={currentParentOfL4}
                l1AndL2Parents={l1AndL2Parents!}
                srcCategoryDetails={srcCategory}
                appData={appData}
                handleMoveAccountClose={handleMoveAccountClose}
            />
            <DeletePopup
                isOpen={deletePopup}
                handleClose={setDeletePopup}
                heading="Delete Account?"
                deleteMessage={'This cannot be undone.'}
                handleConfirm={handleDeleteCategory}
                isDeleting={isDeleting}
                isSegment={false}
                isCategorized={dataForMenu.is_journal_entry_line ?? false}
            />
            <SegmentPage
                isOpen={isSegmentPage}
                setIsSegmentPage={setIsSegmentPage}
                segments={segmentList}
                refreshSegment={handleSegmentRefresh}
                appData={appData}
            />
            {isScrollable && isSmallDevice && (
                <div
                    className={classes.scrollButtom}
                    onClick={() => {
                        const scrollableDiv =
                            document.querySelector('#content-scroll')!;
                        scrollableDiv.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <ArrowUpward />
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    category: state.category,
});
export default connect(mapStateToProps)(ChartOfAccounts);
