import {
    Box,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    createFile,
    excludeTransaction,
    getReconciliationAccountsReportToPortal,
    getReconciliationDetails,
    saveReconciliationAccountsReportToPortal,
} from '../../../../services/apiService/reports';
import { showError } from '../../../../services/formService';
import { currencyFormatter, isEmpty } from '../../../../utils/appUtil';
import { convertUnixToGivenDateFormat, formatDateDDMMYY, getDateInYmd } from '../../../../utils/dateUtil';
import Loader from '../../../common/Loader';
import UiText from '../../../common/ui/UiText';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore';
import {
    ReconciliationDetails,
    TransactionList,
} from '../models/reports-interface';
import { useReportsStyles } from '../styles/reports-styles';
import NewTransactionModal from '../../transactions/NewTransactionModal';
import { deleteTransactionById, getTransaction } from '../../../../services/transactionsService';
import EditIcon from '../../../../assets/icons-svg/Edit.svg';
import { ITransaction } from '../../../../models/transaction-models';
import { initCategories } from '../../../../store/actions/categories';
import { useDispatch, useStore } from 'react-redux';
import { getBankAccounts } from '../../../../services/bankAccountService';
import { IBankAccount } from '../../../../models/bank-account-models';
import LoaderOverlay from '../../../common/LoaderOverlay';
import SaveReports from '../utils/SaveReports/SaveReports';
import { DATE_FORMATS, DOCUMENT_DOWNLOAD_SUCCESS, saveOptions, REPORTS_MENU_VIEWS } from '../constants/reports.const';
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert';
import InfoTooltip from '../../../common/InfoTooltip';
import { toRem16 } from '../../../../styles/commonStyles';
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading';
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider';
import { resetReportsData } from '../../../../store/actions/reportsData';
import { LeftArrowIcon } from '../Icons/LeftArrowIcon';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../styles/models/Colors.interface';

interface QueryParams {
    reconciliationId: string;
}
const GENERIC_ERROR = 'Something went wrong.';
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        totalPaid: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (colorTheme) =>`1px solid ${colorTheme.grey200}`,
            padding: '0 0 0.5rem 0',
            '& .totalValue': {},
        },
        endingBalance: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (colorTheme) =>`1px solid ${colorTheme.grey200}`,
            padding: '0.5rem 0',
        },
        reconciledBalance: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 0',
        },
        reconciledBalanceText: {
            margin: 0,
            '& p':{
                margin: 0
            },
            '& button':{
              padding: '0 0 0 0.5rem'  
            },
            '& img':{
                width:toRem16(16.67),
                height: toRem16(16.67),
            }
        },
        backToReport: {
            display: 'flex',
            alignItems: 'center',
            border: 0,
            background: 'transparent',
            color: (colorTheme) => colorTheme.blue200,
            '& span':{
                margin: '0 0 0 0.5rem',
                fontSize: 'initial'
            }
        },
        refNoCellValue:{
            padding: '0 2rem 0 0 !important'
        },
        refNoCellHeader:{
            padding: '0 2rem 0 0 !important'
        },
        reportsTableBody: {
            '& .negativeBalance' : {
                color: (colorTheme) => colorTheme.red100,
            }
        },
        amountValueCell: {
            padding: '0 !important'
        },
        balanceValueCell:{
            padding: '0 !important'
        }
        
    })
);
const RECONCILED_BALANCE_TEXT = "The balance of all transactions that are checked as matching in bank statemnent and business portal.";
const TOOLTIP_TEXT =
    'The Reconciliations Report shows all reconciliations for the specified accounts during the selected period of time.';

export function ReconciliationReportForAccount() {
    const currentStore = useCurrentStore();
    const accountId = currentStore.currentAccountId;
    const businessId = currentStore.currentBusinessId!;
    const query: QueryParams = useParams() as unknown as QueryParams;
    const {
        reconciliationId,
       
    } = query;
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingTransactionEdit, setIsLoadingTransactionEdit] =
        useState<boolean>(false);
    const [transactionsData, setTransactionsData] =
        useState<ReconciliationDetails>();
    
    const localClasses = useLocalStyles(colorTheme);
    const history = useHistory();
    const [bankAccountsDetails, setBankAccountsDetails] =
        useState<IBankAccount[]>();
    const [transactionData, setTransactionData] =
        useState<ITransaction | null>();
    const [openTransactionModal, setOpenTransactionModal] =
        useState<boolean>(false);
    const store = useStore().getState();
    const bankAccountsFromStore = store.bankAccounts.bankAccountsDetails;
    const dispatch = useDispatch();
    const [reconciledBankAccName, setReconciledBankAccName] =
        useState<string>('');
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false);
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    );
    const [startDateYmd, setStartDateYmd] = useState<string>('');
    const [endDateYmd, setEndDateYmd] = useState<string>('');
    const [periodText, setPeriodText] = useState<string>('');
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const queryParam = new URLSearchParams(useLocation().search);
    const fromReports = queryParam.get('fromReports');

    useEffect(() => {
        dispatch(initCategories());
    }, [dispatch]);

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading]);
    
    const sortTransactionsListByDate = (transactionList: TransactionList[]) =>{
        return transactionList.sort((transaction1:any, transaction2: any ) =>{
            return transaction1.date - transaction2.date;
        })
    }
    useEffect(() =>{
        const startDate = transactionsData?.start_date;
        const endDate = transactionsData?.end_date;
        const startDateInYmd = startDate && getDateInYmd(startDate);
        const endDateInYmd = endDate && getDateInYmd(endDate);
        startDateInYmd && setStartDateYmd(startDateInYmd);
        endDateInYmd && setEndDateYmd(endDateInYmd);
    },[transactionsData?.end_date, transactionsData?.start_date])

    useEffect(() =>{
        const getFormattedDate = (dateInSecs: number, periodTextFormat: string) => {
            const dateInMs = dateInSecs * 1000;
            const date = convertUnixToGivenDateFormat(periodTextFormat, dateInMs);
            return date;
        };
        const startDate = transactionsData?.start_date;
        const endDate = transactionsData?.end_date;
        const periodTextFormat = DATE_FORMATS.periodText;
        const fromDate = startDate && getFormattedDate(startDate, periodTextFormat);
        const toDate = endDate && getFormattedDate(endDate, periodTextFormat);
        const periodText = `From ${fromDate} to ${toDate}`;
        setPeriodText(periodText);
    },[endDateYmd, startDateYmd, transactionsData?.end_date, transactionsData?.start_date])

    const getBankAccountsDetails = useCallback(() => {
        if (!isEmpty(bankAccountsFromStore)) {
            return Promise.resolve(bankAccountsFromStore);
        }
        return getBankAccounts();
    },[bankAccountsFromStore])

    const getReconciliationsOnLoad = useCallback(() =>{
        setIsLoading(true);
        Promise.all([
            getReconciliationDetails(accountId, businessId, reconciliationId),
            getBankAccountsDetails(),
        ])
            .then((result) => {
                setIsLoading(false);
                const [reconciliationDetails, bankAccounts] = result;
                let reconciledTransactionsDetails = reconciliationDetails as ReconciliationDetails;
                setTransactionsData(
                    reconciledTransactionsDetails
                );
                setReconciledBankAccName(reconciledTransactionsDetails?.category);
                const allBankAccounts = bankAccounts.list
                    ? bankAccounts.list
                    : (bankAccounts as IBankAccount[]);
                setBankAccountsDetails(allBankAccounts);
            })
            .catch((error) => {
                showError(error);
            });
    },[accountId, businessId, getBankAccountsDetails, reconciliationId])
    
    useEffect(() => {
        getReconciliationsOnLoad();
    }, [accountId, businessId, bankAccountsFromStore, reconciliationId, getReconciliationsOnLoad]);

    const refreshTransactions = () =>{
        getReconciliationsOnLoad();
    }
    const handleSaveToPortal = (
        data: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const requestData = {
            comment: data.comment,
            period_text: periodText,
            start_date_ymd: startDateYmd,
            end_date_ymd: endDateYmd
        };

        saveReconciliationAccountsReportToPortal(
            accountId,
            businessId,
            reconciliationId,
            requestData
        )
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data;
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    );
                    closeModal();
                    setIsSaveSnackbar(true);
                });
            })
            .catch((error) => {
                showError(error);
            });
    };
    const showTransactionEdit = (transactionId: string) => {
        setIsLoadingTransactionEdit(true);
        getTransaction(accountId, businessId, transactionId)
            .then((result: unknown) => {
                setIsLoadingTransactionEdit(false);
                if (!result) return;
                setTransactionData(result as ITransaction);
                setOpenTransactionModal(true);
            })
            .catch((error) => {
                setIsLoadingTransactionEdit(false);
                showError(error);
            });
    };

    const fetchAllTransactionsData = () => {
        setIsLoading(true);
        getReconciliationDetails(accountId, businessId, reconciliationId)
            .then((result: unknown) => {
                setIsLoading(false);
                const reconciliationDetails = result as ReconciliationDetails;
                const sortedTransactionsByDate = sortTransactionsListByDate(reconciliationDetails?.transaction_list);
                reconciliationDetails.transaction_list = sortedTransactionsByDate;
                setTransactionsData(reconciliationDetails);
            })
            .catch((error) => {
                const statusText = error.statusText;
                showError(statusText);
            });
    };
    const handleTransactionUpdate = () => {
        fetchAllTransactionsData();
    };
    const getClassName = (amount: string | undefined) : string =>{
        if(!amount){
            return '';
        }
        if(parseFloat(amount) < 0){
            return 'negativeBalance';
        }
        return '';
    }
    const generateTransactionsTable = (
        transactions: TransactionList[] | undefined
    ) => {
        if (!transactions) return;
        return (
            <Box mt={10}>
                <Table className={reportsClasses.reportsTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell><UiText weight="semi_bold_600" variant="motorcycle_90">Date</UiText></TableCell>
                            <TableCell align="right" className={localClasses.refNoCellHeader}><UiText weight="semi_bold_600" variant="motorcycle_90">Ref #</UiText></TableCell>
                            <TableCell><UiText weight="semi_bold_600" variant="motorcycle_90">Customer/Payee</UiText></TableCell>
                            <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Amount</UiText></TableCell>
                            <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Balance</UiText></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={localClasses.reportsTableBody}>
                        {transactions.map(
                            (transaction: TransactionList, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {formatDateDDMMYY(transaction.date)}
                                        </TableCell>
                                        <TableCell align="right" className={localClasses.refNoCellValue}>
                                            {
                                                transaction.number && <span>
                                                     # {transaction.number}
                                                    </span>
                                            }
                                           
                                        </TableCell>
                                        <TableCell>
                                            {transaction.customer_name}
                                        </TableCell>
                                        <TableCell className={`${getClassName(transaction.amount)} ${localClasses.amountValueCell}`} align="right">
                                            {transaction.amount &&
                                                currencyFormatter.format(
                                                    parseFloat(
                                                        transaction.amount
                                                    )
                                                )}
                                        </TableCell>
                                        <TableCell align="right" className={localClasses.balanceValueCell}>
                                            {transaction.balance &&
                                                currencyFormatter.format(
                                                    parseFloat(
                                                        transaction.balance
                                                    )
                                                )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <img
                                                alt={`Edit`} //to do make this a button
                                                src={EditIcon}
                                                onClick={() => {
                                                    showTransactionEdit(
                                                        transaction.id
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </Box>
        );
    };
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false);
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath);
    };
    const deleteTransaction = (data: any) => {
        const transactionID = data.id;
        if(!transactionID || !businessId){
            return;
        }
            deleteTransactionById(accountId, businessId, transactionID)
                .then(() => {
                    setOpenTransactionModal(false);
                    refreshTransactions();
                })
                .catch((error) => {
                    const errorText = error?.statusText ?? GENERIC_ERROR;
                    showError(errorText);
                });
    };
    const setExcludeSingleEntry = (data: any) => {
        const transactionID = data.id
        if (!businessId || !transactionID) {
            return
        }
            excludeTransaction(accountId, businessId, transactionID, {
                excluded: true,
            })
                .then(() => {
                    setOpenTransactionModal(false);
                    refreshTransactions();
                })
                .catch((error : any) => {
                    showError(error?.statusText)
                })
    }
    return (
        <div className={reportsClasses.reportDetailsContainer}>
             <ActiveRouterHeadingSection tooltipText={TOOLTIP_TEXT} headingText={'Reconciliation'}/> 
            {isLoading ? (
                <Loader />
            ) : (
                <>
                   
                        <div className={reportsClasses.reportsCommonHeader}>
                            <div
                            className={'backLinkAndBtn'}
                            >
                                {
                                    <button onClick={() =>{history.goBack()}} className={localClasses.backToReport}>
                                    <LeftArrowIcon color={colorTheme.blue200} />
                                    {
                                        fromReports && <span>Back to Report</span>
                                    }
                                    {
                                        !fromReports && <span>Reconciliation Details Report</span>
                                    }
                                </button>
                                }
                                <SaveReports
                                    businessName={
                                        transactionsData?.business.name ?? ''
                                    }
                                    downloadReportPDFUrl={getReconciliationAccountsReportToPortal(
                                        accountId,
                                        businessId,
                                        reconciliationId,
                                        startDateYmd,
                                        endDateYmd,
                                        periodText,
                                    )}
                                    
                                    options={saveOptions.slice(0, 2)}
                                    handleSaveToPortal={handleSaveToPortal}
                                    reportName={REPORTS_MENU_VIEWS.RECONCILIATIONS}
                                    isDisabled={!transactionsData?.transaction_list.length}
                                />
                            </div>
                            <div>
                                <UiText weight='medium_500' variant='suv_150'>
                                    {reconciledBankAccName}
                                </UiText>
                                {periodText}
                            </div>
                        </div>
                    
                    <div className={reportsClasses.reportDetailsContent}>
                        <Box mt={5} mb={7}>
                            <div className={localClasses.totalPaid}>
                                <span>Beginning Balance </span>
                                <UiText weight='semi_bold_600'>
                                    {transactionsData?.start_balance &&
                                        currencyFormatter.format(
                                            parseFloat(
                                                transactionsData?.start_balance
                                            )
                                        )}
                                </UiText>
                            </div>
                            <div className={localClasses.endingBalance}>
                                <span> Ending Balance </span>
                                <UiText weight='semi_bold_600'>
                                    {transactionsData?.end_balance &&
                                        currencyFormatter.format(
                                            parseFloat(
                                                transactionsData?.end_balance
                                            )
                                        )}
                                </UiText>
                            </div>
                            <div className={localClasses.reconciledBalance}>
                                <p className = {localClasses.reconciledBalanceText}> Reconciled Balance 

                                <InfoTooltip tooltipText={RECONCILED_BALANCE_TEXT} />
                                </p>
                                
                                <UiText weight='semi_bold_600'>
                                    {transactionsData?.balance &&
                                        currencyFormatter.format(
                                            parseFloat(
                                                transactionsData?.balance
                                            )
                                        )}
                                </UiText>
                            </div>
                        </Box>

                        {generateTransactionsTable(
                            transactionsData?.transaction_list
                        )}
                    </div>
                </>
            )}
            {isLoadingTransactionEdit ? (
                <LoaderOverlay />
            ) : (
                <NewTransactionModal
                    open={openTransactionModal}
                    handleClose={() => setOpenTransactionModal(false)}
                    isEdit={true}
                    selectedData={transactionData}
                    accountList={bankAccountsDetails}
                    selectedAccount={{
                        account: transactionData?.account,
                    }}
                    setUpdatedTransaction={() => {
                        handleTransactionUpdate();
                    }}
                    isDisableDate={true}
                    isDisableAmount={true}
                    deleteData={{ deleteHandleOption: deleteTransaction }}
                    setExcludeSingleEntry={setExcludeSingleEntry}
                />
            )}
            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage='View Document'
                closeMessage='view'
            />
        </div>
    );
}


