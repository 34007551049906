import React from 'react'
import moment from 'moment'
import UiText from '../../../common/ui/UiText'
import MdashCheck from '../../../common/MdashCheck'
import Icon from '../../../common/Icon'
import UiAlert from '../../../common/ui/UiAlert'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { Employee } from '../../../../models/adp-payroll/employee'
import SectionView from '../../../payroll/common/SectionView'
import { Typography } from '@material-ui/core'
import useDeviceSize from '../../../../hooks/useDeviceSize'
import Loader from '../../../common/Loader'

interface PersonalDetailsProps {
    selectedEmployee?: Employee
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    editLoading?: boolean
}

const PersonalDetails = ({
    selectedEmployee,
    setOpenModal,
    editLoading
}: PersonalDetailsProps) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const { isMobileDevice } = useDeviceSize()
    let sectionData = [
        {
            label: 'Name',
            value: (
                <UiText>
                    {selectedEmployee?.legalName.firstName}
                    &nbsp;
                    {selectedEmployee?.legalName.middleName}
                    &nbsp;
                    {selectedEmployee?.legalName.lastName}
                </UiText>
            ),
        },
        {
            label: 'Date of Birth',
            value: (
                <MdashCheck customCheck={!!selectedEmployee?.birthDate}>
                    {moment(selectedEmployee?.birthDate).format('MMM D, YYYY')}
                </MdashCheck>
            ),
        },
        {
            label: 'Email',
            value: (
                <MdashCheck
                    customCheck={!!selectedEmployee?.personalEmailAddress}
                >
                    {selectedEmployee?.personalEmailAddress}
                </MdashCheck>
            ),
        },
    ]

    return (
        <>
            {editLoading && <Loader />
            }
            {!editLoading && <SectionView
                key={'Personal Details '}
                headerText={'Personal Details'}
                headerNode={isMobileDevice ? (
                    <Typography variant="h6">
                        Personal Details
                    </Typography>
                ) : null}
                buttonIcon={<Icon icon="edit" svgColor={colorTheme.blue200} />}
                buttonText={'Edit'}
                disableSection={false}
                sectionInfoData={sectionData}
                onClickAction={() => {
                        setOpenModal(true);
                    }
                }
            />}
        </>
    )
}

export default PersonalDetails
