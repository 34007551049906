import { useState } from 'react';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import { makeStyles, Theme, Typography, Button } from '@material-ui/core'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        fontWeight: 600,
    },
}))

const SubmitActionDialog = ({
    openActionDialog,
    handleClose,
    handleButtonClick,
}: any) => {
    const classes = useStyles()
    const styles = commonStyles()
    const [loading, setLoading] = useState(false)
    return (
        <UiConfirmationDialog
            open={openActionDialog}
            handleClose={handleClose}
            message={
                <>
                    <Typography variant="body1" gutterBottom>
                        <div
                            className={mergeStyle(styles.mb16, classes.header)}
                        >
                            Incomplete Reconciliation
                        </div>
                        <div className={styles.mb16}>
                            To submit this reconciliation the difference between
                            the starting and ending balances must be zero
                        </div>
                        <div>
                            All transactions must also be checked, excluded, or
                            deleted.
                        </div>
                    </Typography>
                </>
            }
            showLoader={loading}
            confirmNode={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setLoading(true)
                        handleButtonClick('save')
                    }}
                >
                    Save Progress
                </Button>
            }
            cancelButtonText="Ok"
        />
    )
}

export default SubmitActionDialog
