import { Box, Theme, makeStyles } from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'
import UiDialog from '../../../common/ui/UiDialog'
import UiText from '../../../common/ui/UiText'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiPaper-root': {
            maxWidth: '450px',
        },
    },
}))

const UnsaveWarningModal = ({ open, keepEditing, discardChanges }: any) => {
    const classes = useStyles()
    return (
        <UiDialog
            open={open}
            handleClose={keepEditing}
            title=""
            size="sm"
            hideTitleSection={true}
            customRootClass={classes.container}
        >
            <UiText variant="hatchback_125" weight="semi_bold_600">
                You have unsaved changes
            </UiText>
            <Box mt={1}>
                <UiText variant="car_100" weight="regular_400">
                    Navigating away without saving will lose any changes you
                    have made.
                </UiText>
            </Box>
            <Box mt={3}>
                <UiButton
                    btnType="secondary"
                    label="Discard Changes"
                    handleClick={discardChanges}
                />
            </Box>
            <Box mt={3}>
                <UiButton
                    btnType="hyperlink"
                    label="Keep Editing"
                    handleClick={keepEditing}
                />
            </Box>
        </UiDialog>
    )
}

export default UnsaveWarningModal
