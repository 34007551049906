import {
    Box,
    Button,
    Grid,
    Hidden,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { ApplicationStore, DocumentQueryObject } from '../../models';
import UiDialog from '../common/ui/UiDialog';
import UiFormField from '../common/ui/UiFormField';
import { CalendarTodayOutlined } from '@material-ui/icons';
import moment from 'moment';
import Close from '@material-ui/icons/Close';
import { convertToBytes } from '../../utils/documentUtil';

interface DocumentsFilterProps {
    open: boolean;
    handleClose: (queryObject: DocumentQueryObject | boolean) => void;
    documentFiltersConfig: {
        periods: {};
        size: {
            operators: { gt: 'Greater Than'; lt: 'Less Than' };
        };
    };
    queryObject: DocumentQueryObject;
}

function DocumentsFilter({
    open,
    handleClose,
    documentFiltersConfig,
    queryObject,
}: DocumentsFilterProps) {
    const onSubmit = (data: DocumentQueryObject) => {
        let { size, period, fileSizeType, minDate, maxDate } = data;
        size.value = convertToBytes(size.value, fileSizeType);
        if (period !== 'all' && period !== 'custom' && period !== 'quarter') {
            /**
             * Setting date filters on the basis of period, if all time is selected then no date filter
             */
            minDate = moment()
                .startOf(period as any)
                .unix();
            maxDate = moment().endOf('day').unix();
        } else if(period === 'quarter'){
            // minDate = currentDate - 3.months 
            minDate = moment().subtract(2, 'month').startOf('month').unix()
            maxDate = moment().endOf('day').unix();
        }
        handleClose({
            minDate,
            maxDate,
            period,
            size,
            fileSizeType,
        });
    };

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            hideTitleSection={true}
            title=''
            size='xs'
        >
            <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                mt={-2}
                mb={2}
                mr={-2}
            >
                <Typography variant='h6'>Filter Documents</Typography>
                <IconButton onClick={() => handleClose(false)}>
                    <Close />
                </IconButton>
            </Box>
            <Formik
                initialValues={queryObject}
                onSubmit={onSubmit}
                enableReinitialize
                validateOnMount={true}
            >
                {(formik) => {
                    return (
                        <Form>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <UiFormField
                                        label='Upload Date'
                                        fieldName='period'
                                        type='select'
                                        optionsData={Object.keys(
                                            documentFiltersConfig.periods
                                        ).map((key) => ({
                                            key,
                                            value: (
                                                documentFiltersConfig.periods as any
                                            )[key],
                                        }))}
                                        optionKey='value'
                                        optionValue='key'
                                    />
                                </Grid>
                            </Grid>

                            {formik.values.period === 'custom' && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <UiFormField
                                            label={'Start'}
                                            type='date'
                                            fieldName='minDate'
                                            showFloatingLabel={true}
                                            labelSize={false}
                                            maxDate={moment(
                                                formik.values.maxDate * 1000
                                            ).toDate()}
                                            endIcon={
                                                <CalendarTodayOutlined fontSize='small' />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <UiFormField
                                            label={'End'}
                                            type='date'
                                            fieldName='maxDate'
                                            showFloatingLabel={true}
                                            labelSize={false}
                                            maxDate={moment().toDate()}
                                            endIcon={
                                                <CalendarTodayOutlined fontSize='small' />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={1}>
                                <Grid item xs={9} md={6}>
                                    <UiFormField
                                        label='File Size'
                                        fieldName='size.operator'
                                        type='select'
                                        optionsData={Object.keys(
                                            documentFiltersConfig.size.operators
                                        ).map((key) => ({
                                            key,
                                            value: (
                                                documentFiltersConfig.size
                                                    .operators as any
                                            )[key],
                                        }))}
                                        optionKey='value'
                                        optionValue='key'
                                        containerBottomMargin={0}
                                    />
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <Hidden smDown>
                                        <Box pt={3} mt={2}></Box>
                                    </Hidden>
                                    <UiFormField
                                        label=''
                                        labelSize={false}
                                        fieldName='size.value'
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={5} md={3}>
                                    <Hidden smDown>
                                        <Box pt={3} mt={2}></Box>
                                    </Hidden>
                                    <UiFormField
                                        label=''
                                        labelSize={false}
                                        fieldName='fileSizeType'
                                        type='select'
                                        optionsData={['KB', 'MB']}
                                        optionKey=''
                                        optionValue=''
                                    />
                                </Grid>
                            </Grid>
                            <Box my={2} display='flex'>
                                <Button
                                    variant='outlined'
                                    type='button'
                                    onClick={() => handleClose(false)}
                                >
                                    Cancel
                                </Button>
                                <Box mx={2}>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        color='primary'
                                    >
                                        Apply Filters
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </UiDialog>
    );
}

const mapStateToProps = ({ config }: ApplicationStore) => ({
    documentFiltersConfig: config.local.filters,
});
export default connect(mapStateToProps)(DocumentsFilter);
