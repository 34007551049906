import { Field, Form } from 'formik'
import UiText from '../../common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { Fragment,} from 'react'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { RenderForm } from '../company/FirstEmployee/FormData'
import {
    Button,
    Grid,
    Theme,
} from '@material-ui/core'
import {
    empCompensationFormMappings,
    personalInfoFormMapping,
} from './EmployeeFormData'
import LocationAutocomplete from './LocationAutoComplete'
import { useADPEmployeesContext } from './ADPEmployeesProvider'
import { makeStyles } from '@material-ui/styles'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { Close } from '@material-ui/icons'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { AddressAutocompletion } from '../AddressAutocompletion'
const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    flex: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
    },
    clear: {
        color: (colorTheme) => colorTheme.red100,
    },
    fieldMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}))

const CreateNewEmployeeForm = (props: any) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const { addNewJob, setAddNewJob, setFieldValue, values, errors } = props
    const { companyDetails } = useADPEmployeesContext()
    const styles = useStyles(colorTheme)
    const { currentConfig } = useCurrentStore()

    const makeLocationList = () => {
        const locationList: Array<any> = []
        if (companyDetails?.companyLocation) {
            companyDetails?.companyLocation.forEach((data: any) => {
                locationList.push({
                    name: `${data.legalAddress.address1}`,
                    id: data.locationID,
                })
            })
        }
        return locationList
    }

    const makeJobList = () => {
        const jobList: Array<any> = []
        if (companyDetails?.jobs) {
            companyDetails?.jobs?.forEach((data: any, index: number) => {
                jobList.push({
                    name: `${data.jobName}`,
                    id: data.jobID,
                    title: `${data.jobName}`,
                    parent_title: ``,
                    location: ``,
                })
            })
        }
        return jobList
    }

    return (
        <div className={styles.formStyle}>
            <Form>
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Personal Info
                </UiText>
                {personalInfoFormMapping(false).map((property: any) => (
                    <Fragment key={property.key}>
                        {RenderForm(property)}
                    </Fragment>
                ))}
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Home address
                </UiText>
                <div className={styles.fieldMargin}>
                    <div className={styles.fieldMargin}>
                        <AddressAutocompletion setFieldValue={setFieldValue} />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Street (Required)"
                            label="Street (Required)"
                            fieldName="street_1"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Street 2 (Optional)"
                            label="Street 2 (Optional)"
                            fieldName="street_2"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="City (Required)"
                            label="City (Required)"
                            fieldName="city"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="select"
                            optionsData={currentConfig.apiConfig.generic.states}
                            optionKey="name"
                            optionValue="id"
                            placeholder="State"
                            label="State"
                            showFloatingLabel={true}
                            fieldName="state"
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Zip Code (Required)"
                            label="Zip Code (Required)"
                            fieldName="zip"
                            showFloatingLabel={true}
                        />
                    </div>
                </div>
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Job Details
                </UiText>
                {[
                    {
                        md: 10,
                        xs: 12,
                        key: 'job_start_date',
                        label: 'Date of Hire (Required)',
                        required: true,
                        type: 'KeyboardDatePicker',
                        fieldName: 'job_start_date',
                        dateType: 'string',
                        disableFutureDate: true,
                        showFloatingLabel: true,
                        errorMessage: 'Required',
                        placeholder: 'MM/DD/YYYY',
                        infoText: (
                            <UiText
                                variant="motorcycle_90"
                                textColor="textSecondary"
                            >
                                {' '}
                                This can be the date the employee was hired,{' '}
                                <br /> or the date they started this specific
                                job.
                            </UiText>
                        ),
                    },
                ].map((property: any) => (
                    <Fragment key={property.key}>
                        {RenderForm(property)}
                    </Fragment>
                ))}
                <Grid item md={10} xs={12}>
                    {addNewJob ? (
                        <>
                            <div className={styles.fieldMargin}>
                                <div className={styles.flex}>
                                    <UiText>New Job</UiText>
                                    <Button
                                        className={styles.clear}
                                        onClick={() => setAddNewJob(false)}
                                        startIcon={<Close />}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>

                            <div className={styles.fieldMargin}>
                                <UiFormControlSelection
                                    label="Job Title (Required)"
                                    placeholder="Job Title (Required)"
                                    type="text"
                                    fieldName={`job_title`}
                                />
                            </div>
                            <div className={styles.fieldMargin}>
                                <UiFormControlSelection
                                    label="Location (Required)"
                                    placeholder="Location (Required)"
                                    type="select"
                                    showFloatingLabel
                                    optionsData={makeLocationList()}
                                    optionKey="name"
                                    optionValue="id"
                                    fieldName={`job_location`}
                                />
                            </div>
                        </>
                    ) : (
                        <div className={styles.fieldMargin}>
                            <Field name="job">
                                {({ ...InputProps }: any) => (
                                    <LocationAutocomplete
                                        {...InputProps}
                                        label="Job (Required)"
                                        placeholder="Job (Required)"
                                        formValues={{}}
                                        setFieldValue={setFieldValue}
                                        listData={makeJobList()}
                                        listButtonAction={setAddNewJob}
                                        validationError={errors?.job}
                                    />
                                )}
                            </Field>
                        </div>
                    )}
                </Grid>

                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Compensation
                </UiText>
                {empCompensationFormMappings({}).map((property: any) => (
                    <Fragment key={property.key}>
                        {RenderForm(property)}
                    </Fragment>
                ))}
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Ownership Status
                </UiText>

                <UiText>
                    Is the employee an owner of the company? (Required)
                </UiText>
                <div className={styles.fieldMargin}>
                    <UiFormControlSelection
                        type="radio"
                        label="Is the employee an owner of the company? (Required)"
                        fieldName="isOwner"
                        radioOptionsData={[
                            {
                                label: 'Yes',
                                value: 'yes',
                            },
                            {
                                label: 'No',
                                value: 'no',
                            },
                        ]}
                        onChange={(e: any) => {
                            if (e.target.value === 'no') {
                                setFieldValue('ownership', 0)
                            }
                        }}
                    />
                </div>

                {values?.isOwner === 'yes' && (
                    <>
                        <UiText>
                            Is this employee the primary owner of the company?
                            (Required)
                        </UiText>
                        <div className={styles.fieldSpacing}>
                            <UiFormControlSelection
                                type="radio"
                                label="Is this employee the primary owner of the company? (Required)"
                                fieldName="primaryOwnerIndicator"
                                disabled={true}
                                radioOptionsData={[
                                    {
                                        label: 'Yes',
                                        value: 'yes',
                                    },
                                    {
                                        label: 'No',
                                        value: 'no',
                                    },
                                ]}
                            />
                        </div>
                        <UiText>
                            What percentage of the company does the employee
                            own? (Required)
                        </UiText>
                        <div className={styles.fieldMargin}>
                            <UiFormControlSelection
                                fastField={false}
                                type="number"
                                fieldName={`ownership`}
                                placeholder="0"
                                endIcon="%"
                            />
                        </div>
                    </>
                )}
            </Form>
        </div>
    )
}

export default CreateNewEmployeeForm
