import React, { useState } from 'react';
import {
    Button,
    Menu,
    MenuItem,
    IconButton,
    Typography,
    TextField,
    makeStyles,
    Hidden,
    Grid,
    Theme,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RefreshIcon from '../../../assets/icons-svg/Refresh.svg';

import {
    disconnectBankAccount,
    getFinicityFixModal,
    reconnectBankAccount,
    removeBankAccount,
    updateBankAccountSync,
} from '../../../services/apiService/bankAccount';
import {
    syncTransactions,
    fullSync,
    updateBalance,
    excludeDuplicates,
} from '../../../services/transactionsService';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import { commonStyles } from '../../../styles/commonStyles';
import { AppData } from '../../../models';
import ImportTransactionsModal from './ImportTransactionsModal';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../../store/actions/feedback';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const styles = makeStyles<Theme, ThemeColors>(() => ({
    deleteButton: {
        color: (colorTheme) => colorTheme.primaryWhite,
        background: (colorTheme) => colorTheme.red200,
        '&:hover': {
            color: (colorTheme) => colorTheme.primaryWhite,
            background: (colorTheme) => colorTheme.red200,
        },
    },
    deleteButtonDisabled: {
        color: (colorTheme) => colorTheme.primaryWhite + ' !important',
        background: (colorTheme) => colorTheme.red200 + ' !important',
        opacity: 0.5,
    },
    overlay: {
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        background: (colorTheme) => colorTheme.primaryBlack,
        position: 'fixed',
        zIndex: 1400,
    },
}));

function MenuDropdownBank(props: {
    bank: any;
    appData: AppData;
    disabled?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    setLoading: (loading: boolean) => void;
    updateBankAccountData: (data: any) => void;
    categories: any[];
    accountantMode: boolean;
    reloadBankAccountGroups?: (slectGroup?: boolean) => void;
}) {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme);
    const commonClasses = commonStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { setLoading, accountantMode } = props;
    const { current_account_id, current_business_id } = props.appData;
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [showFullSyncModal, setShowFullSyncModal] = useState(false);
    const [showUpdateBalanceModal, setShowUpdateBalanceModal] = useState(false);

    const [showDeleteModal, setShowDeletetModal] = useState(false);
    const [deleteModalInputText, setDeleteModalInputText] = useState('');

    const [
        showImportTransactionModal,
        setShowImportTransactionModal,
    ] = useState(false);

    // import transaction modal
    const [importStep, setImportStep] = useState<
        'start' | 'in-progress' | 'complete'
    >('start');
    const [importedTransactions, setImportedTransactions] = useState(0);

    const [showFinicityModal, setShowFinicityModal] = useState(false);

    const handleMenuItemClick = (index: number) => {
        switch (options[index]) {
            case 'Sync':
                syncBankAccountAction();
                break;
            case 'Disconnect':
                setShowDisconnectModal(true);
                break;
            case 'Reconnect':
                reconnectBankAction();
                break;
            case 'Remove':
                setShowDeletetModal(true);
                break;
            case 'Update credentials':
                updateCredential();
                break;
            case 'Import transactions':
                setImportStep('start');
                setShowImportTransactionModal(true);
                break;
            case 'Full Sync':
                setShowFullSyncModal(true);
                break;
            case 'Update Balance':
                setShowUpdateBalanceModal(true);
                break;
            case 'Exclude Duplicates':
                excludeDuplicatesAction();
                break;
            default:
                break;
        }
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const syncBankAccountAction = (
        importingTransaction?: boolean,
        postUpdateCredentialSync?: boolean
    ) => {
        if (!importingTransaction) {
            setLoading(true);
        }

        return syncTransactions(
            current_account_id,
            current_business_id,
            props.bank.id,
            importingTransaction
                ? { ...props.bank, need_activate: true }
                : postUpdateCredentialSync
                ? undefined
                : props.bank
        )
            .then((res: any) => {
                if (postUpdateCredentialSync) {
                    props.reloadBankAccountGroups?.(true);
                } else {
                    props.bank.balance = res[0].balance;
                    props.bank.synced = res[0].date;
                    props.updateBankAccountData(props.bank);
                    if (importingTransaction) {
                        setImportedTransactions(res[0].transactions_number);
                        setImportStep('complete');
                    } else {
                        setLoading(false);
                    }
                }
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err?.message || 'Something went wrong',
                    })
                );
                props.reloadBankAccountGroups?.(true);
            });
    };
    
    
    const excludeDuplicatesAction = () => {
        setLoading(true);
        
        return excludeDuplicates(current_account_id, current_business_id, props.bank.id)
            .then((res: any) => {
                props.bank.balance = res[0].balance;
                props.bank.synced = res[0].date;
                props.updateBankAccountData(props.bank);
                setLoading(false);
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err?.message || 'Something went wrong',
                    })
                );
                props.reloadBankAccountGroups?.(true);
            });
    };

    const fullSyncAction = () => {
        setLoading(true);
        return fullSync(current_account_id, current_business_id, props.bank.id)
            .then((res: any) => {
                props.bank.balance = res[0].balance;
                props.bank.synced = res[0].date;
                props.updateBankAccountData(props.bank);
                setLoading(false);
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: err?.message || 'Something went wrong',
                    })
                );
                props.reloadBankAccountGroups?.(true);
            });
    };

    const updateBalanceAction = () => {
        setLoading(true);
        return updateBalance(current_account_id, current_business_id, props.bank.id)
        .then((res: any) => {
            if (res.success === true) {
                props.bank.balance = res.details?.finicity_balance;
                props.bank.synced = res.details?.date;
                props.updateBankAccountData(props.bank);
                dispatch(showAlert({ 
                    alertType: 'success', 
                    alertText: 'Balance updated successfully.'
                }));
            } else {
                dispatch(showAlert({
                    alertType: 'info',
                    alertText: res.message,
                }));
            }
            setLoading(false);
        })
        .catch((err) => {
            setLoading(false);
            dispatch(showAlert({
                alertType: 'error',
                alertText: err?.message || 'Something went wrong',
            }));
            props.reloadBankAccountGroups?.(true);
        });
    };

    const importTransactionsAction = (sync_from_date: number) => {
        setImportStep('in-progress');
        updateBankAccountSync(
            current_account_id,
            current_business_id,
            props.bank.id,
            { sync_from_date }
        ).then(() => {
            syncBankAccountAction(true);
        });
    };

    const removeBankAction = () => {
        setLoading(true);
        removeBankAccount(
            current_account_id,
            current_business_id,
            props.bank.id
        )
            .then((res: any) => {
                props.reloadBankAccountGroups?.(true);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const updateCredential = () => {
        const script = document.createElement('script');
        script.src =
            'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js';
        script.async = true;
        document.body.appendChild(script);
        openFinicityModal();
    };

    const closeFinicityModal = () => {
        setShowFinicityModal(false);
        window.finicityConnect.destroy();
    };

    const openFinicityModal = () => {
        Promise.all([getFinicityUrl()]).then((res: any) => {
            setShowFinicityModal(true);
            window.finicityConnect.launch(res[0].link, {
                success: function (data: any) {
                    closeFinicityModal();
                    syncBankAccountAction(false, true).then(() => {
                        dispatch(
                            showAlert({
                                alertType: 'success',
                                alertText: `Credentials for ${props.bank.name} are updated successfully.`,
                            })
                        );
                    });
                },
                cancel: function () {
                    closeFinicityModal();
                },
                error: function () {
                    closeFinicityModal();
                    dispatch(
                        showAlert({
                            alertType: 'error',
                            alertText: 'Something went wrong',
                        })
                    );
                },
                loaded: function (data: any) {},
            });
        });
    };

    const getFinicityUrl = () => {
        return getFinicityFixModal(current_business_id, props.bank.id);
    };

    const disconnectBankAction = () => {
        setLoading(true);
        disconnectBankAccount(
            current_account_id,
            current_business_id,
            props.bank.id
        )
            .then(() => {
                props.reloadBankAccountGroups?.(true);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const reconnectBankAction = () => {
        setLoading(true);
        reconnectBankAccount(
            current_account_id,
            current_business_id,
            props.bank.id
        )
            .then(() => {
                props.reloadBankAccountGroups?.(true);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const hasReconcile = (id: string) => {
        return (
            props.categories.find((c) => c.id === id)?.reconciliation_count >= 1
        );
    };

    let options: string[] = [];

    if (
        props.bank.type == 'aggregated' &&
        props.bank.provider == 'finicity' &&
        props.bank.finicity_is_active
    ) {
        if (props.bank.connected) {
            if (props.bank.aggregation_status.error == '') {
                options.push('Sync');
                if (!hasReconcile(props.bank.account)) {
                    options.push('Import transactions');
                }
                options.push('Full Sync');
            } else if (
                ['update_credentials', 'update_mfa'].includes(
                    props.bank.aggregation_status.error
                )
            ) {
                options.push('Update credentials');
            }
            options.push('Exclude Duplicates');
            if (accountantMode && props.bank.aggregation_status.error == '') {
                options.push('Update Balance');
            }
            options.push('Disconnect');
        } else {
            options.push('Reconnect');
        }
    }

    if (props.appData.user.accountant_mode) {
        options.push('Remove');
    }

    return (
        <>
            {showImportTransactionModal && (
                <ImportTransactionsModal
                    open={showImportTransactionModal}
                    handleClose={() => setShowImportTransactionModal(false)}
                    step={importStep}
                    importTransaction={importTransactionsAction}
                    importedTransactions={importedTransactions}
                />
            )}

            {options.length ? (
                <div
                    style={{
                        float: 'right',
                    }}
                >
                    <Hidden smDown>
                        <Button
                            startIcon={
                                options[0] === 'Sync' ? (
                                    <img src={RefreshIcon}></img>
                                ) : null
                            }
                            style={
                                !props.variant
                                    ? {
                                          fontWeight: 500,
                                          fontSize: '15px',
                                          lineHeight: '16px',
                                          borderRadius: '4px',
                                          borderTopRightRadius: 0,
                                          borderBottomRightRadius: 0,
                                          border: `1px solid ${colorTheme.grey200}`,
                                          minWidth: '91px',
                                          height: '36px',
                                          padding: '12px',
                                      }
                                    : {}
                            }
                            onClick={() => handleMenuItemClick(0)}
                            color='secondary'
                            variant={props.variant}
                            disabled={props.disabled}
                        >
                            {options[0]}
                        </Button>

                        <IconButton
                            style={
                                !props.variant
                                    ? {
                                          fontWeight: 500,
                                          fontSize: '15px',
                                          lineHeight: '16px',
                                          borderRadius: '4px',
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                          border: `1px solid ${colorTheme.grey200}`,
                                          height: '36px',
                                      }
                                    : {}
                            }
                            onClick={handleClick}
                            color='secondary'
                            disabled={props.disabled}
                        >
                            {!!anchorEl ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                        >
                            {options.length > 1
                                ? options.slice(1).map((option, index) => (
                                      <MenuItem
                                          key={option}
                                          selected={index === 0}
                                          onClick={(event) =>
                                              handleMenuItemClick(++index)
                                          }
                                      >
                                          {option}
                                      </MenuItem>
                                  ))
                                : []}
                        </Menu>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            aria-label='more'
                            aria-controls='long-menu-edt'
                            aria-haspopup='true'
                            onClick={(event) => {
                                anchorEl
                                    ? handleClick(null)
                                    : handleClick(event);
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                        >
                            {options.length
                                ? options.map((option, index) => (
                                      <MenuItem
                                          key={option}
                                          selected={index === 0}
                                          onClick={(event) =>
                                              handleMenuItemClick(index)
                                          }
                                      >
                                          {option}
                                      </MenuItem>
                                  ))
                                : []}
                        </Menu>
                    </Hidden>

                    <UiConfirmationDialog
                        open={showDisconnectModal}
                        message={
                            <>
                                <Typography variant='h6' gutterBottom>
                                    Disconnect Account?
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    Your transactions will stop syncing. Do you
                                    really want to disconnect this Account?
                                </Typography>
                            </>
                        }
                        handleClose={() => {
                            setShowDisconnectModal(false);
                        }}
                        confirmNode={
                            <Button
                                variant='contained'
                                style={{ background: colorTheme.red200 }}
                                onClick={() => {
                                    setShowDisconnectModal(false);
                                    disconnectBankAction();
                                }}
                                color='secondary'
                            >
                                Disconnect
                            </Button>
                        }
                        cancelButtonText='Cancel'
                    />

                    <UiConfirmationDialog
                        open={showFullSyncModal}
                        message={
                            <>
                                <Typography variant='body1' gutterBottom>
                                    This will import all transactions on the
                                    system. You could get duplicate
                                    transactions. Do a full Sync?
                                </Typography>
                            </>
                        }
                        handleClose={() => {
                            setShowFullSyncModal(false);
                        }}
                        confirmNode={
                            <Button
                                variant='contained'
                                style={{ background: colorTheme.red200 }}
                                onClick={() => {
                                    setShowFullSyncModal(false);
                                    fullSyncAction();
                                }}
                                color='secondary'
                            >
                                Full Sync
                            </Button>
                        }
                        cancelButtonText='Cancel'
                    />

                    <UiConfirmationDialog
                        open={showUpdateBalanceModal}
                        message={
                            <>
                                <Typography variant='body1' gutterBottom>
                                    This will update your account to match the balance your 
                                    financial institution is currently providing us. 
                                    Update bank balance now?
                                </Typography>
                            </>
                        }
                        handleClose={() => {
                            setShowUpdateBalanceModal(false);
                        }}
                        confirmNode={
                            <Button
                                variant='contained'
                                style={{ background: colorTheme.red200 }}
                                onClick={() => {
                                    setShowUpdateBalanceModal(false);
                                    updateBalanceAction();
                                }}
                                color='secondary'
                            >
                                Update Balance
                            </Button>
                        }
                        cancelButtonText='Cancel'
                    />

                    <UiConfirmationDialog
                        open={showDeleteModal}
                        message={
                            <>
                                <Typography variant='h6'>
                                    Delete Account?
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={commonClasses.mt16}
                                >
                                    All connected transactions will be
                                    permanently deleted.
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={commonClasses.mt16}
                                >
                                    Reports and balances that use these
                                    transactions will also be affected.
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={commonClasses.mt16}
                                >
                                    This cannot be undone.
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    className={commonClasses.mt16}
                                >
                                    Type “Delete” to confirm.
                                </Typography>
                                <TextField
                                    variant='outlined'
                                    className={commonClasses.mt16}
                                    InputProps={{
                                        style: {
                                            height: '48px',
                                            width: '228px',
                                        },
                                    }}
                                    defaultValue=''
                                    onChange={(event) =>
                                        setDeleteModalInputText(
                                            event.target.value
                                        )
                                    }
                                />
                            </>
                        }
                        handleClose={() => {
                            setShowDeletetModal(false);
                            setDeleteModalInputText('');
                        }}
                        confirmNode={
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setShowDeletetModal(false);
                                    setDeleteModalInputText('');
                                    removeBankAction();
                                }}
                                classes={{
                                    root: classes.deleteButton,
                                    disabled: classes.deleteButtonDisabled,
                                }}
                                disabled={deleteModalInputText !== 'Delete'}
                            >
                                Delete
                            </Button>
                        }
                        cancelButtonText='Cancel'
                    />

                    {showFinicityModal ? (
                        <Grid
                            className={classes.overlay}
                            onClick={() => closeFinicityModal()}
                        ></Grid>
                    ) : (
                        <Grid></Grid>
                    )}
                </div>
            ) : null}
        </>
    );
}

export default MenuDropdownBank;
