import { Theme, styled } from '@material-ui/core/styles'
import { COLORS } from '../../variables/colors'

export const CentereAligned = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

interface YcentereAlignedProps {
    gap?: string
    margin?: string
}

export const YcentereAligned = styled('div')<Theme, YcentereAlignedProps>(
    ({ gap, margin }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: gap || 0,
        margin: margin || 0,
    })
)

interface InlineFlexProps {
    gap?: string
    marginbottom?: string
    padding?: string
    alignitems?: string
    justifycontent?: string
    width?: string
    backgroundcolor?: string
    borderradius?: string
    flexdirection?: 'row' | 'column'
}

export const InlineFlex = styled('div')<Theme, InlineFlexProps>(
    ({
        gap,
        marginbottom,
        padding,
        alignitems,
        justifycontent,
        width,
        backgroundcolor,
        borderradius,
        flexdirection = 'row',
    }) => ({
        width: width || '100%',
        display: 'flex',
        flexDirection: flexdirection,
        gap: gap || 0,
        marginBottom: marginbottom || 0,
        padding: padding || 0,
        alignItems: alignitems || 'auto',
        justifyContent: justifycontent || 'auto',
        backgroundColor: backgroundcolor || 'initial',
        borderRadius: borderradius || 0,
    })
)

interface ContainerProps {
    width?: string
    maxwidth?: string
    padding?: string
    backgroundcolor?: string
    borderradius?: string
    height?: string
    minheight?: string
    maxheight?: string
    overflow?: string
    margin?: string
}

export const Container = styled('div')<Theme, ContainerProps>(
    ({
        theme,
        width,
        maxwidth,
        padding,
        backgroundcolor,
        borderradius,
        height,
        maxheight,
        minheight,
        overflow,
        margin,
    }) => ({
        width: width || '100%',
        maxWidth: maxwidth || '100%',
        height: height || 'auto',
        maxHeight: maxheight || '100%',
        minHeight: minheight || '100%',
        textAlign: 'left',
        padding: padding || '0',
        backgroundColor: backgroundcolor || COLORS.primaryWhite,
        borderRadius: borderradius || 0,
        margin: margin || '0 auto',
        overflow: overflow || 'none',
    })
)
