import React from 'react'
import { Button } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import { useStyles } from './Styles'
import ThumpsUp from '../../../../assets/icons-svg/ThumpsUp.svg'
import Header from './Header'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const ThankYouScreen = ({ closeModal }: { closeModal: () => void }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div>
            <Header closeModal={closeModal} hideTitleBorder={true} />
            <div className={styles.contentLayout}>
                <div className={styles.TextCenter}>
                    <div>
                        <img alt={`Thanks`} src={ThumpsUp} />
                        <UiText
                            variant="truck_175"
                            weight="bold_700"
                            className={styles.contentSpacing}
                        >
                            Thanks, we’ll be in touch
                        </UiText>
                        <UiText>
                            We’ll contact the IRS about your past tax returns.
                            Typically, they take 4-6 weeks to respond. Once they
                            do, we’ll reach out to you in the Message Center
                            with more information.
                        </UiText>
                    </div>
                    <Button
                        variant="contained"
                        onClick={closeModal}
                        className={styles.okayBtn}
                        >
                        Okay
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ThankYouScreen