import { currencyFormatter } from '../../../../../utils/appUtil';
import UiText from '../../../../common/ui/UiText';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';
import ErrorAlert from '../../utils/Alerts/ErrorAlert';
import {
    GenerateBaseColumn,
    GenerateReportsTable,
    GenerateTotalsColumn,
    useLocalStyles,
} from '../../utils/BalanceSheetAndIncomeStatementReports/ReportsComparisonTableData';

interface Report {
    reportsData: any;
    LEVEL_1_ROWS: Array<string>;
    reportType: reportTypes;
    segmentsIDAndName: any;
}

export enum reportTypes {
    BALANCE_SHEET = 'balanceSheet',
    INCOME_STATEMENT = 'incomeStatement',
}

export default function ComparisionReport(props: Report) {
    const {
        reportsData,
        LEVEL_1_ROWS,
        reportType,
        segmentsIDAndName
    } = props;
    const { colorTheme } = useThemeContext()
    const localClasses = useLocalStyles(colorTheme);

    const getTotalValuesObject = (items: any) =>{
        if(reportType === reportTypes.BALANCE_SHEET){
            return items.liabilities_and_capital_class_wise_balances;
        }
        return items.net_income_class_wise_balances;
    }
    const getClassWiseBalance = (items: any, segmentId : string) : string=>{
        const zeroClasswiseBalance = "";
        const classwiseBalanceValue = items[segmentId];
        if(!classwiseBalanceValue){
            return zeroClasswiseBalance;
        }
        return classwiseBalanceValue;
    }
    const generateSegmentsWiseTotalNode = (items: any, segmentId : string) =>{
        const totalItems = getTotalValuesObject(items);
        const balance = getClassWiseBalance(totalItems, segmentId);
        if(!balance){
            return;
        }
        const classNameValue = balance && parseFloat(balance) < 0 ? 'priceWaste' : '';
        return (
            <UiText
            weight="bold_700"
                variant="motorcycle_90"
           className={classNameValue}
       >
           {currencyFormatter.format(
               parseFloat(
                   balance
               )
           )}
       </UiText>
   )
    }
   

    return (
        <>
            {segmentsIDAndName ? (
                <div className={localClasses.segmentColumns}>
                    <GenerateBaseColumn
                        reportsData={reportsData}
                        LEVEL_1_ROWS={LEVEL_1_ROWS}
                        reportType={reportType}
                    />
                    {segmentsIDAndName.map(
                        (segmentIdAndName: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className="segmentColumn comparisionReport"
                                >
                                    <div className="segmentName">
                                        <UiText
                                            weight="semi_bold_600"
                                            variant="motorcycle_90"
                                        >
                                            {segmentIdAndName.segmentName}
                                        </UiText>
                                    </div>

                                    {LEVEL_1_ROWS.map(
                                        (type: string, index: number) => {
                                            return (
                                                <div key={index}>
                                                    <GenerateReportsTable
                                                        items={
                                                            reportsData[type]
                                                        }
                                                        segmentId={
                                                            segmentIdAndName.segmentId
                                                        }
                                                        reportType={reportType}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                    <div className="totalLiabilities">
                                            {generateSegmentsWiseTotalNode(reportsData, segmentIdAndName.segmentId)}
                                    </div> 
                                </div>
                            );
                        }
                    )}

                    <GenerateTotalsColumn
                        reportsData={reportsData}
                        LEVEL_1_ROWS={LEVEL_1_ROWS}
                        items={reportsData}
                        reportType={reportType}
                    />
                </div>
            ) : (
                <>
                    {' '}
                    <ErrorAlert />
                </>
            )}
        </>
    );
}
