import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Theme,
    Grid,
    GridSize,
    makeStyles,
    Hidden,
    useMediaQuery,
    useTheme,
    Tabs,
    Tab,
    Divider
} from '@material-ui/core';
// icons
import { Location } from '../../../../models/locations';
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection';
import Loader from '../../../common/Loader';
import ModalMobileHeader from '../../../common/ModalMobileHeader';
import ConfirmLocationModal from '../modal/ConfirmLocationModal';
import {
    defaultValues,
    LocationFormProps,
    propertyMappingGeneral,
    propertyMappingManuallyEnter,
    StateCountryProps,
    LocationPropFieldsInterface,
    LocationPayload
} from './LocationFormUtils';
import {
    handleUpdateLocation,
    handleCreateLocation,
    handleDeleteLocation,
} from '../actions';
import { OutlinedDeleteButton } from '../../../common/DeleteButton';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import UiText from '../../../common/ui/UiText';
import Map from '../Map'
import UiButton from '../../../common/ui/UiButton'
import useLocationsHook from '../locations.hook'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { embeddedLocationTempIds } from '../../trips/Form/TripFormUtils';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        marginTop: '0.75rem',
    },
    iconClass: {
        width: '1.25rem',
        height: '1.25rem',
        marginRight: '1rem',
        marginTop: '0.5rem',
    },
    footerAction: {
        right: '0rem',
        width: '100%',
        bottom: '0rem',
        display: 'flex',
        height: '2.813rem',
        padding: '0.5rem',
        position: 'absolute',
        background: '#fff',
        'border-top': '0.063rem solid ' + useThemeContext().grey200,
        'justify-content': 'flex-end',
        'z-index': 1,
    },
    cancelButton: {
        margin: '0rem 1rem 0rem 0rem !important',
    },
    formContainer: {
        margin: `${theme.spacing(5)}px 0 ${theme.spacing(4)}px 0`
    },
    tabForm: {
        padding: '0rem 0rem 5rem 0rem',
    },
    lastItemContainer: {
        height: '9.375rem',
    },
    deleteButtonContainer: {
        padding: '.25rem 0rem .75rem 0rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '4rem',
            marginBottom: '-2rem'
        }
    },
    tabPanelsContainer: {
        paddingTop: theme.spacing(4),
        height: '100%'
    },
    header2: {
        margin: '2rem 0rem 0rem 0rem'
    },
    marginToBottom: {
        margin: '0rem 0rem .5rem 0rem'
    },
    fieldContainer: {
        marginBottom: theme.spacing(4),
    }
}));
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const style = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`panel-${index}`}
            {...other}
            className={style.tabForm}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

function LocationForm({
    location,
    handleClose,
    setSelectedLocation,
    loadLocations,
    formikRef,
    setLocationUpdated,
    locationCallback,
    setLoading,
    isFormTouched,
    onlyForOneTrip
}: LocationFormProps) {
    const classNames = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const locationRoute = useLocation();
    let initialValues = {...defaultValues};
    const { currentConfig } = useCurrentStore();
    const [value, setValue] = useState(0);
    const query = new URLSearchParams(useLocation().search)
    const currentTab = query.get('tab')
    const countries = currentConfig.apiConfig.generic.countries;
    const states = currentConfig.apiConfig.generic.states;
    const formOptionsData: StateCountryProps = {
        country: countries,
        state: states,
    };
    const addressLab = 'address.';
    const addressStateKey = 'address.state';
    const USKey = 'US';
    const {
        showConfirmModal,
        setShowConfirmModal,
        discardChanges,
        setDiscardChanges
    } = useLocationsHook();
    const {embedded_location_from_id, embedded_location_to_id} = embeddedLocationTempIds;
    
    if (location && onlyForOneTrip) {
        initialValues = {...defaultValues,...location};
    } else if (location) {
        initialValues = location
    } else {
        initialValues.save_to_list = 'Yes'
    }

    //formikRef = useRef<FormikProps<FormikValues>>(null);

    useEffect(() => {
        if(currentTab) {
          setValue(1)
        }
      },[currentTab])
  
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        
        setValue(newValue);
        if(newValue === 0){
            resetManuallyEnterForm();
        }else if(newValue === 1){
            resetCoordinates();
        }
    };

    const resetManuallyEnterForm = () => {
        for (const [key, value] of Object.entries(defaultValues.address)) {
            formikRef?.current?.setFieldValue(addressLab+key, value)
        }
    }

    const resetCoordinates = () => {
        setLat(0);
        setLng(0);
    }

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required')
    });

    const onSubmit = (data: FormikValues) => {
       
        data.address.latitude = lat;
        data.address.longitude = lng;

        if(data.save_to_list === 'No'){
            const location = {
                "saveChangesToLocations": false,
                "address": data.address,
                "name": data.name,
                "description": data.description,
                "$submitted": true,
                "$errorMsg": null,
                "$submitting": false
            }
            locationCallback?.(location);
            return;
        }

        if (location && ![embedded_location_from_id,embedded_location_to_id].includes(location.id)) {
            handleUpdateLocation(location.id, data, (res: Location | null) => {
                if(!onlyForOneTrip){
                    history.push(`/mileage/locations`)
                    if(res){
                        setSelectedLocation && setSelectedLocation(res as Location);
                        isSmDown && history.push(`/mileage/locations/${location.id}`)
                        res.saveChangesToLocations = true;
                    }
                    handleClose(true);
                }
                loadLocations?.();
                setLocationUpdated?.(true);
                locationCallback?.(res);
            });
        } else {
            delete data.id;
            handleCreateLocation(data, (response) => {
                if(!onlyForOneTrip){
                    handleClose(true);
                }
                loadLocations?.();
                response.saveChangesToLocations = true;
                locationCallback?.(response);
            });
        }
    };

    const renderFormFields = (property: LocationPropFieldsInterface) => {
        return (
            <UiFormControlSelection
                showFloatingLabel
                placeholder={property?.placeholder}
                type={property?.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                optionsData={property?.optionsData!}
                optionKey={property?.optionKey}
                optionValue={property?.optionValue}
                label={property?.label}
                {...(property.type === 'mask' ? { mask: property?.regex! } : {})}
                {...(property?.minLength
                    ? { minLength: property?.minLength }
                    : {})}
                {...((property.type === 'radio' && onlyForOneTrip && location)
                    ? { radioOptionsData: property?.radioOptionsData! } 
                    : {})}
            />
        );
    };
    /**
     * Handle FORM Modal closing
     * @param values | FormikProps
     */
    const handleFormClose = (values: FormikValues) => {
        if(onlyForOneTrip){
            handleClose(true);
            return;
        }
        if(isFormTouched?.(values)){
            setDiscardChanges(true);
            setShowConfirmModal(true);  
        }else{
            handleClose(true); 
        }
    };

    /**
     * Confirmation Pop up Handling
     * This modal only appears on creating New location / Delete location
     */
    const handleConfirmModal = (result: boolean) => {
        setShowConfirmModal(false);
        if (result === true) {
            if (location && !discardChanges) {
                // on Edit
                const currentPath = locationRoute.pathname;
                const isLocationsPage = currentPath.includes('/mileage/locations');
                setLoading?.(true);
                handleDeleteLocation(location.id, () => {
                    setShowConfirmModal(false);
                    if(isLocationsPage){
                        history.push('/mileage/locations');
                    }
                    loadLocations?.();
                    setSelectedLocation?.(undefined);
                    handleClose();
                    setLoading?.(false);
                });
            } else {
                // else close the main Modal
                setShowConfirmModal(false);
                handleClose(true);
            }
        }
    };

    const GridItem = (property: LocationPropFieldsInterface, data: any = null) => (
        <Grid item xs={property?.xs as GridSize} md={property?.md as GridSize} >
            <div
                className={classNames.container}
            >
                {data ? data : renderFormFields(property)}
            </div>
        </Grid>
    );

    const isFormChanged = () => {
        if (formikRef.current?.values?.address?.country === null)
        delete formikRef.current?.values?.address?.country
        return JSON.stringify(formikRef.current?.values) !== JSON.stringify(formikRef.current?.initialValues)
    }

    return (
        <>
            <ConfirmLocationModal
                location={location}
                showConfirmModal={showConfirmModal}
                handleClose={handleConfirmModal}
                discardChanges={discardChanges}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ submitForm, touched, isSubmitting, values }) => {
                    const isNonUS = values?.address?.country !== USKey;
                    return (
                        <Form>
                            {(location?.id && onlyForOneTrip != true) && (
                                <div
                                    className={classNames.deleteButtonContainer}
                                >
                                    <OutlinedDeleteButton
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth={isSmDown}
                                        onClick={() => {
                                            setDiscardChanges(false)
                                            setShowConfirmModal(true);
                                        }}
                                        showDeleteIcon
                                    >
                                        Remove Location
                                    </OutlinedDeleteButton>
                                </div>
                            )}
                            <ModalMobileHeader
                                title={location ? 'Edit Location' : 'New Location'}
                                buttonText={'save'}
                                loading={isSubmitting}
                                handleClose={() => {
                                    handleFormClose(touched);
                                }}
                                handleButtonAction={() => submitForm()}
                            />
                            <div
                                className={
                                    isSmDown ? classNames.formContainer : ''
                                }
                            >
                                {propertyMappingGeneral.map(
                                    (property: LocationPropFieldsInterface) => (
                                        <Fragment key={property?.key}>
                                            <Grid container spacing={1}>
                                                {GridItem(property)}
                                            </Grid>
                                        </Fragment>
                                    )
                                )}
                                <UiText variant="hatchback_125" weight="medium_500" className={classNames.header2}>
                                    Location
                                </UiText>
                                <Divider className={classNames.marginToBottom} />
                                <Grid item xs={12}>
                                     <Tabs value={value} onChange={handleChange}>
                                        <Tab 
                                            disableRipple
                                            label="Search" 
                                            id="panel-0"
                                        />
                                        <Tab 
                                            disableRipple 
                                            label="Manually Enter" 
                                            id="panel-1"
                                        />
                                    </Tabs>
                                </Grid>
                                <Divider />
                                <TabPanel 
                                    value={value} 
                                    index={0} 
                                >
                                    <Map 
                                        setLat={setLat}
                                        setLng={setLng}
                                        isEditview={location?.id}
                                        initialValues={initialValues.address}
                                        formikRef={formikRef}
                                        formOptionsData={formOptionsData}
                                    />
                                </TabPanel>
                                <TabPanel 
                                    value={value} 
                                    index={1}
                                >
                                    <Grid container spacing={1}>
                                        {propertyMappingManuallyEnter(formOptionsData).map(
                                            (property: LocationPropFieldsInterface) => (
                                                <Fragment key={property.key}>
                                                    {
                                                        !(isNonUS && property.key === addressStateKey) &&
                                                            GridItem(property)
                                                    }
                                                </Fragment>
                                            )
                                        )}
                                    </Grid>
                                </TabPanel>
                            </div>
                            <Hidden smDown>
                                <div className={classNames.footerAction}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid justify="flex-end" container>
                                            <UiButton
                                                 btnType="secondary"
                                                 label="Cancel"
                                                 handleClick={() => {
                                                    handleFormClose(values);
                                                 }}
                                                 customClass={
                                                    classNames.cancelButton
                                                }
                                            />
                                            <UiButton
                                                 btnType="primary"
                                                 label="Save Location"
                                                 handleClick={submitForm}
                                                 disabled={!isFormChanged() && onlyForOneTrip}
                                                 customClass={
                                                    classNames.cancelButton
                                                }
                                            />
                                        </Grid>
                                    )}
                                </div>
                            </Hidden>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default LocationForm;