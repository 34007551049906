import { Container, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { toRem16 } from '../../styles/commonStyles'
import { ActivityFeed } from './ActivityFeed/ActivityFeed'
import { UpcomingEventdDeadlines } from './UpcomingEventsAndDeadlines'
import WidgetProvider from './tax-prep/provider/WidgetProvider'
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider'
import DashboardItems from './Items/DashboardItems'
import useDeviceSize from '../../hooks/useDeviceSize'
import TaxEngagement from './TaxEngagementPlan/TaxEngagement'
import DashboardUtilities from './Utility/DashboardUtilities'
import DashboardProvider from './Provider/DashboardProvider'
import TaxEngagementProvider from './TaxEngagementPlan/TaxEngagementProvider'
import EstimatedTaxSavingHeader from './TaxEngagementPlan/EstimatedTaxSavingHeader'
import IncomeStatement from './ReportWidgets/IncomeStatement'
import Invoices from './ReportWidgets/Invoices'
import BalanceSheet from './ReportWidgets/BalanceSheet'
import Expenses from './ReportWidgets/Expenses'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'

function DashboardBeta() {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const { isMobileDevice } = useDeviceSize()
    const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => 
        createStyles({
            dashboardContainer: {
                display: 'flex',
            },
            leftPanelContents: {
                flex: 2,
                marginRight: toRem16(24),
            },
            message: {
                margin: '1rem 0 1rem 0',
            },
            reportWidgets: {
                display: 'flex',
                flexDirection: isMobileDevice ? 'column' : 'row',
                gap: '1rem',
                marginTop: '1rem',
                '& .MuiLinearProgress-colorPrimary': {
                    backgroundColor: (colorTheme) => `${colorTheme.grey100} !important`,
                }
            },
        })
    )
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)

    useEffect(() => {
        setActiveRouteHeading('Dashboard')
    }, [setActiveRouteHeading])

    return (
        <DashboardProvider>
            <TaxEngagementProvider>
                {isMobileDevice && <EstimatedTaxSavingHeader />}
                <Container maxWidth="lg">
                    <div
                        className={
                            isMobileDevice ? '' : classes.dashboardContainer
                        }
                    >
                        <div
                            className={
                                isMobileDevice ? '' : classes.leftPanelContents
                            }
                        >
                            <WidgetProvider>
                                <DashboardItems />
                            </WidgetProvider>
                            <div className={classes.reportWidgets}>
                                <IncomeStatement />
                                <Invoices />
                            </div>
                            <div className={classes.reportWidgets}>
                                <BalanceSheet />
                                <Expenses />
                            </div>

                            <UpcomingEventdDeadlines />
                            <ActivityFeed />
                        </div>
                        {!isMobileDevice && <TaxEngagement />}
                        <DashboardUtilities />
                    </div>
                </Container>
            </TaxEngagementProvider>
        </DashboardProvider>
    )
}

export default React.memo(DashboardBeta)
