import { withStyles } from '@material-ui/core';


export const FullHeight = withStyles({ 
    root: {
        height: '100%',
    }
})((props: { children: any, classes: any})=>{
    const { children, classes } = props;
    return (
        <div className={classes.root}>
            {children}
        </div>
    )
});
