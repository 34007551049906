import React, { Fragment } from 'react';

import UiDialog from '../../common/ui/UiDialog'
import {
    makeStyles,
    Box,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { ProductDetail } from '../../../models'
import ProductForm from './ProductForm'
interface ProductModalProps {
    open: boolean
    handleClose: (result?: any) => void
    isNew: boolean
    product?: any
    setSelectedProduct?: React.Dispatch<ProductDetail | undefined>
    loadProducts?: () => void
    formikRef?: any
    setProductUpdated?: React.Dispatch<boolean>
    productCallback?: any
    showFormSaveOptions?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: '12px',
    },
    desktopHeight: {
        height: '600px'
    },
    customPaper: {
        height: '100%',
        width: '100%',
        margin: '0px',
        maxHeight: '100%',
    },
}))

export default function ProductModal({
    open,
    handleClose,
    isNew,
    product,
    setSelectedProduct,
    loadProducts,
    formikRef,
    setProductUpdated,
    productCallback,
    showFormSaveOptions = false
}: ProductModalProps) {
    const styles = useStyles()
    const theme = useTheme()
    // isMobileDevice
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <Fragment>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={`${isNew ? 'New': 'Edit'} Product/Service`}
                size="sm"
                hideTitleSection={isXsDown}
                customDialogStyles={
                    isXsDown ? { paper: styles.customPaper } : { paper: styles.desktopHeight }
                }
            >
                <ProductForm
                    product={product}
                    handleClose={handleClose}
                    setSelectedProduct={setSelectedProduct}
                    loadProducts={loadProducts}
                    setProductUpdated={setProductUpdated}
                    formikRef={formikRef}
                    productCallback={productCallback}
                    showFormSaveOptions={showFormSaveOptions}
                />
            </UiDialog>
        </Fragment>
    )
}