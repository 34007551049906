import { useRef } from 'react'
import { useFormikContext } from 'formik'
import SignatureCanvas from 'react-signature-canvas'
import { FormHelperText, makeStyles, Theme } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import UiAlert from '../../../common/ui/UiAlert'
import UiButton from '../../../common/ui/UiButton'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { usePayrollMigrationState } from '../../Providers/PayrollMigrationProvider'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        margin: '1.5rem 0',
        position: 'relative',
    },
    terms: {
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        color: (colorTheme) => colorTheme.black100,
        padding: '1rem 0 0.5rem 0',
        '& div': {
            padding: '0.2rem 0',
        },
        '& .MuiCheckbox-root.Mui-checked': {
            color: (colorTheme) => colorTheme.tertiary
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 0 1.5rem 0',
        },
    },
    lastAgreementTerm: {
        borderBottom: 'none!important',
    },
    signatureSection: {
        padding: '1rem 0',
        color: (colorTheme) => colorTheme.black100,
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        '& > p': {
            marginBottom: '0.5rem',
        },
        overflow: 'hidden',
    },
    signaturePadStyle: {
        border: '1px solid black',
        marginRight: '1rem',
    },
    warningMargin: {
        marginTop: '0.5rem',
        '& button': {
            marginTop: '1rem',
        },
        '& .MuiAlert-standardError': {
            color: (colorTheme) => colorTheme.red200,
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            padding: '0.5rem 0',
        }
    },
}))

const TermsAndConditionsSection = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const { companyDetails, isAgreementAccepted } = usePayrollMigrationState()
    const signPad: any = useRef()
    const { setFieldValue, errors } = useFormikContext()


    return (
        <div className={classes.container}>
            <div className={classes.terms}>
                <UiText>
                    In order for us to run your payroll, you’ll need to
                    authorize ADP as {companyDetails?.company?.business_name}’s
                    power of attorney. This allows ADP to sign and file tax
                    returns and make deposits and payments on your company’s
                    behalf. Note: Once authority has been granted, it will
                    remain in place until revoked by you or the Reporting Agent
                    (ADP).
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        fieldName="agreementOne"
                        required={true}
                        disableRipple={true}
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            <div className={classes.terms}>
                <UiText>
                    Do you authorize ADP to sign and file tax returns for{' '}
                    {companyDetails?.company?.business_name} from the third
                    quarter of 2024 forward?
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        required={true}
                        fieldName="agreementTwo"
                        disableRipple={true}
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            <div className={classes.terms}>
                <UiText>
                    Do you authorize ADP to make deposits and payments on behalf
                    of {companyDetails?.company?.business_name} starting in July
                    2024?
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        required={true}
                        fieldName="agreementThree"
                        disableRipple={true}
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            <div className={classes.terms}>
                <UiText>
                    Do you authorize ADP to receive or request duplicate copies
                    of tax information, notices, and other communications from
                    the IRS, related to the authorizations granted in the two
                    previous questions?
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        required={true}
                        fieldName="agreementFour"
                        disableRipple={true}
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            <div className={classes.terms}>
                <UiText>
                    Do you authorize ADP to receive copies of notices from the
                    IRS?
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        required={true}
                        fieldName="agreementFive"
                        disableRipple={true}
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            <div className={classes.terms}>
                <UiText>
                    Do you authorize ADP to exchange otherwise confidential
                    taxpayer information with the IRS, including responding to
                    certain IRS notices relating to the Form W-2/1099 series
                    information returns? This authority is effective for the
                    calendar year beginning 2024?
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        required={true}
                        fieldName="agreementSix"
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            <div className={`${classes.terms} ${classes.lastAgreementTerm}`}>
                <UiText>
                    Do you appoint ADP as the Reporting Agent and grant ADP a
                    limited power of attorney with the authority to sign and
                    file employment tax returns and make deposits
                    electronically, on magnetic media, or on paper for all state
                    and local jurisdictions in which the taxpayer is required to
                    file tax returns and make tax deposits? ADP is also hereby
                    authorized to receive notices, correspondence, and
                    transcripts from all applicable state and local
                    jurisdictions, resolve matters pertaining to these deposits
                    and filings, and to request and receive deposit frequency
                    data and any other information from applicable state and
                    local jurisdictions related to taxpayer’s employment tax
                    returns and deposits for the tax periods indicated in the
                    previous questions and all returns filed and deposits made
                    by ADP from the date hereof. This authorization shall
                    include all applicable state and local forms and shall
                    commence with the tax period indicated and shall remain in
                    effect through all subsequent periods until either revoked
                    by the taxpayer or terminated by ADP. Unless the taxpayer is
                    required to file or deposit electronically, ADP will, in its
                    discretion, file and make deposits on the taxpayer’s behalf
                    using one of the filing methods: electronic, magnetic media,
                    or paper.
                </UiText>
                <div>
                    <UiFormControlSelection
                        inlineLable={
                            <UiText weight="medium_500">I agree (Required)</UiText>
                        }
                        type="checkbox"
                        required={true}
                        fieldName="agreementSeven"
                        disabled={isAgreementAccepted}
                    />
                </div>
            </div>
            {!isAgreementAccepted && (
                <div className={classes.signatureSection}>
                    <UiText>
                        We’ll need your signature to confirm that you accept the
                        terms of this agreement.
                    </UiText>
                    <UiText>
                        By providing your signature below, you agree to our
                        Terms of Service and confirm that all the info you
                        provided above is accurate.
                    </UiText>
                    <div className={classes.signaturePadStyle}>
                        <SignatureCanvas
                            penColor="black"
                            ref={(ref) => {
                                signPad.current = ref
                            }}
                            canvasProps={{
                                width: '500',
                                height: 130,
                                className: 'sigCanvas',
                            }}
                            onEnd={(event) =>
                                setFieldValue(
                                    'signature',
                                    signPad.current.toDataURL()
                                )
                            }
                        />
                    </div>
                    <div className={classes.warningMargin}>
                        {
                            // @ts-ignore
                            errors.signature && (
                                <FormHelperText>
                                    <UiAlert
                                        icon={'alert'}
                                        severity={'error'}
                                        description={
                                            'Your signature is required to proceed'
                                        }
                                    />
                                </FormHelperText>
                            )
                        }
                        <UiButton
                            btnType="primary"
                            label="Clear"
                            handleClick={() => {
                                signPad.current.clear()
                                setFieldValue('signature', '')
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default TermsAndConditionsSection
