import React, { useState, Fragment } from 'react';
import UiDialog from '../../../common/ui/UiDialog';
import {
    makeStyles,
    Box,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Contact } from '../../../../models';
import ContactForm from '../forms/ContactForm';
import Loader from '../../../common/Loader';
interface ContactModalProps {
    open: boolean;
    handleClose: (result?: any) => void;
    contact?: Contact;
    setSelectedContact?: React.Dispatch<Contact | undefined>;
    loadContacts?: () => void;
    formikRef?: any;
    setContactUpdated?: React.Dispatch<boolean>;
    contactCallback?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: '12px',
    },
    customPaper: {
        height: '100%',
        width: '100%',
        margin: '0px',
        maxHeight: '100%',
    },
    maxDesktopView: {
        height: '37.5rem',
    },
}));

export default function ContactModal({
    open,
    handleClose,
    contact,
    setSelectedContact,
    loadContacts,
    formikRef,
    setContactUpdated,
    contactCallback,
}: ContactModalProps) {
    const styles = useStyles();
    const theme = useTheme();
    // isMobileDevice
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const [loading, setLoading] = useState(false);

    return (
        <Fragment>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={` ${contact ? 'Edit' : 'Create'} Contact`}
                size="sm"
                hideTitleSection={isXsDown}
                customDialogStyles={
                    isXsDown
                        ? { paper: styles.customPaper }
                        : { paper: styles.maxDesktopView }
                }
            >
                <div style={{ height: '100%' }}>{ 
                    loading ? <Loader /> : <ContactForm
                    contact={contact}
                    handleClose={handleClose}
                    setSelectedContact={setSelectedContact}
                    loadContacts={loadContacts}
                    formikRef={formikRef}
                    setContactUpdated={setContactUpdated}
                    contactCallback={contactCallback}
                    setLoading={setLoading}
                />}
                    
                </div>
            </UiDialog>
        </Fragment>
    );
}
