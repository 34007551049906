import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    ListItemText,
    Divider,
    useTheme,
    useMediaQuery
} from '@material-ui/core'
import { Location } from '../../../models/locations'
import { trimText } from '../../../utils/utility'
import  ListElement from '../../common/ListElement'
import { mergeStyle, commonStyles } from '../../../styles/commonStyles'
import IconList from '../../common/IconList'
import Icon from '../../common/Icon'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

export default function LocationItem({
    location,
    selectedLocation,
    setSelectedLocation,
    lastItem
}: {
    locationId: string
    location: Location
    selectedLocation: Location | undefined
    setSelectedLocation: React.Dispatch<Location | undefined>
    lastItem: boolean
}) {
    const theme = useTheme();
    const styles = commonStyles()
    const history = useHistory()
    const { currentConfig } = useCurrentStore()
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const countries = currentConfig.apiConfig.generic.countries;

    const getCountryName = (countryId: string): string | null => {
        const country = countries.find(
            (item) => item.abbr === countryId
        )!;
        return country?.name;
    };

    const mDash = () => <span className={mergeStyle(styles.flex, styles.flexEnd)}>—</span>
    return (
        <>
            <ListElement
                isItemSelected={location.id === selectedLocation?.id}
                handleItemClick={() => {
                        setSelectedLocation(location)
                        isSmDown && history.push(`/mileage/locations/${location.id}`)
                }}
                primaryIcon={<Icon
                    icon={'location' as keyof typeof IconList}
                    customClass={`${styles.mb1} ${styles.ml16}`}
                />}
                primaryNode= {<ListItemText
                    className={styles.capitalize}
                    primary={location?.name ? location?.name : mDash()}
                    secondary={
                        `${location?.address?.address ? location?.address?.address + ', ' : ''}
                        ${location?.address?.city ? location?.address?.city + ',' : ''}
                        ${location?.address?.county ? location?.address?.county + ',' : ''}
                        ${location?.address?.state ? location?.address?.state + ',' : ''}
                        ${location?.address?.zip_code ? location?.address?.zip_code + ',' : ''}
                        ${getCountryName(location?.address?.country) ?? ''}`
                    }
                />}
                lastItem={true}
            />
            {!lastItem && <Divider variant='inset'/>}
        </>
    )
}