import { useState } from 'react';
import {
    Button,
    makeStyles,
    Theme,
    TextField,
    InputAdornment,
    Paper,
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import { getIn } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import UiText from '../../../../common/ui/UiText';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';

const useClasses = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    textFieldRoot: {
        '& .MuiInputLabel-root': {
            top: '-7px',
        },
    },
    autocompleteRoot: {
        padding: '0px 0px !important',

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: theme.spacing('0', 3),
        },
    },
    splitButtonContainer: {
        width: '100%',
        margin: theme.spacing(3, '0'),
    },
    splitTransactionBtn: {
        '& .MuiButton-startIcon': {
            transform: 'rotate(90deg)',
        },
    },
    closeItem: {
        padding: '12px 0px !important',
        cursor: 'pointer',
    },
    autocomplete: {
        '& .MuiInputBase-fullWidth': {
            height: '40px',
            width: '222px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& .MuiAutocomplete-input': {
                padding: '0px',
            },
        },
    },
    createSegment: {
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    parentMenu: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    parentTitle: {
        width: '33%',
    },
}));

const menuLink = ({
    children,
    onAddNewItem,
    enteredSegment,
    showAddButton,
    createSegmentClass,
    ...other
}: any) => {
    return (
        <Paper {...other}>
            <div className={createSegmentClass}>
                {showAddButton && (
                    <Button
                        fullWidth
                        startIcon={<Add />}
                        onMouseDown={(event) => {
                            event.preventDefault();
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            onAddNewItem(enteredSegment);
                        }}
                        style={{ justifyContent: 'flex-start' }}
                    >
                        New Segment{enteredSegment && ':'}
                        {enteredSegment && (
                            <span style={{ color: '#000', marginLeft: '5px' }}>
                                {enteredSegment}
                            </span>
                        )}
                    </Button>
                )}
            </div>
            {children}
        </Paper>
    );
};

const FormikAutocomplete = ({
    formValues,
    listData,
    setFieldValue,
    onAddNewItem,
    showAddButton,
    titleKey,
    idKey,
    label,
    showSubtitle,
    placeholder,
    childIdToParent,
    ...props
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useClasses(colorTheme);
    const fieldError = getIn(props.form.errors, props.field.name);
    const showError =
        getIn(props.form.touched, props.field.name) && !!fieldError;
    const [enteredSegment, setEnteredSegment] = useState<string>('');
    let isOptionExist = false;
    if (showAddButton) {
        listData.forEach((option: any) => {
            const enteredValue = enteredSegment?.toLowerCase();
            const optionName = option?.name?.toLowerCase();
            if (
                optionName.includes(enteredValue) ||
                option.id === props.field?.value
            ) {
                isOptionExist = true;
            }
        });
    }
    return (
        <Autocomplete
            {...props}
            fullWidth
            classes={{ root: classes.autocompleteRoot }}
            value={formValues[props.field.name]}
            options={listData?.filter(
                (c: any) =>
                    !c.is_hidden &&
                    (c.level === 3 || c.level === 4) &&
                    c.is_leaf
            )}
            onBlur={() =>
                props.form.setTouched({
                    ...props.form.touched,
                    [props.field.name]: true,
                })
            }
            getOptionLabel={(option: any) => option[titleKey] || ''}
            getOptionSelected={(option: any, value) => option === value}
            onChange={(e, item: any) => setFieldValue(props.field.name, item)}
            renderOption={(option: any) => {
                return (
                    <div className={classes.parentMenu}>
                        <div>
                            <UiText>{option[titleKey]}</UiText>
                        </div>
                        {showSubtitle && (
                            <div className={classes.parentTitle}>
                                <UiText variant="motorcycle_90">
                                    {option?.parent?.title ?? ''}
                                </UiText>
                            </div>
                        )}
                    </div>
                );
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        variant="outlined"
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setEnteredSegment(event.target.value);
                            }
                        }}
                        data-cy={`${props.field.name.toLocaleLowerCase().replace(' ', '-')}-input`}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        error={showError}
                        helperText={
                            showError ? props.form.errors[props.field.name] : ''
                        }
                    />
                );
            }}
            PaperComponent={(child: any) => {
                return menuLink({
                    ...child,
                    name: props.field.name,
                    onAddNewItem,
                    enteredSegment: enteredSegment,
                    showAddButton: showAddButton && !isOptionExist,
                    createSegmentClass: classes.createSegment,
                });
            }}
        />
    );
};

export default FormikAutocomplete;
