import { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { FormikProps, FormikValues } from 'formik'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { 
    ProductDetail,
    PaginatedProductsResponse,
    LoadingStates,
    ProductCategory
} from '../../../models'
import { handleBulkProductActions } from './actions'
import { getProductServices } from '../../../services/apiService'

const dropdownOptions: string[] = [
    'All Products & Services',
    'Products',
    'Services',
]

const productTypeMappings: any = {
    'All Products & Services': null,
    Products: 'product',
    Services: 'service',
}

const useProducts = () => {
    let { path } = useRouteMatch()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)

    const [openCategorizeModal, setOpenCategorizeModal] =
        useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [productType, setProductType] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState<ProductDetail>()
    const [productUpdated, setProductUpdated] = useState<boolean>(false)
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const [productsData, setProductsData] = useState<
        PaginatedProductsResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 25,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    })
    const [checkedProducts, setCheckedProducts] = useState<string[]>([])
    const [checkedProdObj, setCheckedProdObj] = useState<any[]>([])
    const [openInvoiceModal, setOpenInvoiceModal] = useState<boolean>(false)
    const [selectedProductCategory, setProductCategory] = useState<ProductCategory>()
    const history = useHistory()

    /**
     * This formikRef is for product form ref.
     * This is needed to check if form is touched and show Confirmation popup
     * (Required for modal close with X button)
     */
    const formikRef = useRef<FormikProps<FormikValues>>(null)

    // @return {string | null} -> 'Customers': 'customer'
    let customerType: string | null =
        productTypeMappings[`${dropdownOptions[productType]}`]
    const getProductsList = useCallback(
        (params) => {
            params = {
                ...params,
                ...(customerType ? { type: customerType } : null),
                ...(selectedProductCategory?.id ? { category: selectedProductCategory?.id } : null )
            }

            getProductServices(params).then((response: any) => {
                if (response) {
                    const { itemsCount, page, perPage, pageCount, items } =
                        response
                    setProductsData((prev: any) => ({
                        ...prev,
                        items: page === 1 ? items : prev.items.concat(items),
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    }))
                    // TODO - need to remove if api call is needed
                }
            })
        },
        [customerType, selectedProductCategory]
    )

    const { items } = productsData

    // load products
    const loadProducts = useCallback(
        (params?: any) => {
            setProductsData((prev: any) => ({
                ...prev,
                loading: true,
            }))
            getProductsList({
                page: 1,
                perPage: 25,
                ...params,
            })
        },
        [getProductsList]
    )

    const loadMore = () => {
        const { page } = productsData
        getProductsList({
            page: page + 1,
            perPage: 25,
        })
    }

    const resetCheckedItems = () => {
        setCheckedProducts([])
        setCheckedProdObj([])
        setSelectAll(false)
    }

    // Handling of X button open the confirm popup for exit if form is touched
    // Cancel button handling added in the ContactForm itself.
    const handleContactModalClose = (result: any) => {
        // if form submitted
        if (result === true) {
            setShowConfirmModal(false)
            setOpenModal(false)
        } else {
            if (formikRef.current?.dirty) {
                // open confirm modal
                setShowConfirmModal(true)
            } else {
                // close contact modal
                setOpenModal(false)
            }
        }
    }

    const bulkDeleteProducts = () => {
        // when products selected for bulkActions
        let params = {
            deleted: true,
            filters: { id: checkedProducts },
        }
        handleBulkProductActions(
            'delete',
            checkedProducts.length,
            params,
            () => {
                setShowConfirmModal(false)
                resetCheckedItems()
                loadProducts()
                history.push('/products')
            }
        )
    }

    const handleConfirmModal = (result: any) => {
        if (result) {
            // i.e when discard/delete clicked
            if (checkedProducts.length) {
                bulkDeleteProducts()
            } else {
                setShowConfirmModal(false)
                setOpenModal(false)
            }
        } else {
            setShowConfirmModal(false)
        }
    }

    const handleClickType = (action: string) => {
        switch (action) {
            case 'createNewInvoice':
                setOpenInvoiceModal(true)
                break
            case 'deleteItems':
                setShowConfirmModal(true)
                break
            case 'categorize':
                setOpenCategorizeModal(true)
                break
            default:
                break
        }
    }

    const handleCheck = (checked: boolean, product: any) => {
        if (checked) {
            setCheckedProducts((prev) => [...prev, product?.id])
            setCheckedProdObj((prev) => [...prev, product])
        } else {
            const  filteredProductId = checkedProducts.filter(
                (id) => id !== product?.id
            )
            const filteredProducts = checkedProdObj.filter(
                (prod) => prod?.id !== product?.id
            )
            setCheckedProducts(filteredProductId)
            setCheckedProdObj(filteredProducts)
        }
    }

    const handleCheckAll = (checked: boolean) => {
        setSelectAll(checked)
        if (checked) {
            let items = productsData.items
            let productIds = items.map((item) => item.id)
            setCheckedProducts(productIds)
            setCheckedProdObj(items)
        } else {
            setCheckedProducts([])
            setCheckedProdObj([])
        }
    }

    useEffect(() => {
        loadProducts()
    }, [loadProducts])

    useEffect(() => {
        setActiveRouteHeading('Products/Services')
    }, [setActiveRouteHeading])

    useEffect(() => {
        if (productUpdated) {
            let newItems: any = [...items]
            const contactIndex: number = newItems.findIndex(
                (contact: ProductDetail) => contact.id === selectedProduct?.id
            )
            newItems[contactIndex] = selectedProduct
            setProductsData((prev: any) => ({
                ...prev,
                items: newItems,
            }))
            setProductUpdated(false)
        }
    }, [productUpdated, items, selectedProduct])

    useEffect(() => {
        // mark select all checkbox as selected when checkproducts are equal.
        setSelectAll(checkedProducts.length === productsData.itemsCount)
    }, [checkedProducts.length, productsData.itemsCount])

    return { 
        path,
        openModal,
        setOpenModal,
        openCategorizeModal,
        setOpenCategorizeModal,
        showConfirmModal,
        setShowConfirmModal,
        searchTerm,
        setSearchTerm,
        productType,
        setProductType,
        selectedProduct,
        setSelectedProduct,
        productUpdated,
        setProductUpdated,
        selectAll,
        setSelectAll,
        productsData,
        setProductsData,
        checkedProducts,
        setCheckedProducts,
        formikRef,
        getProductsList,
        loadProducts, 
        loadMore,
        handleContactModalClose,
        bulkDeleteProducts,
        handleConfirmModal,
        handleClickType,
        handleCheck,
        handleCheckAll,
        dropdownOptions,
        checkedProdObj,
        setCheckedProdObj,
        openInvoiceModal,
        setOpenInvoiceModal,
        resetCheckedItems,
        setProductCategory,
        selectedProductCategory
    }
}

export default useProducts