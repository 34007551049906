import { useState, FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import { ActiveTourStepContext } from '../routing/Providers/ActiveRoutingProvider';

const TourGuidePopper: FC<{
    open: boolean;
    anchor: any;
    contentText: string;
    currentStep: number;
    placement?: PopperPlacementType;
    rightOffeset?: boolean;
}> = ({
    open,
    anchor,
    contentText,
    currentStep,
    placement,
    rightOffeset = false,
}): any => {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme) => ({
        popper: {
            zIndex: 9999,
            width: '400px',
            '&[x-placement*="bottom"]': {
                top: '5px!important',
                '& $arrow': {
                    top: 0,
                    left: 0,
                    marginTop: '-0.9em',
                    width: '3em',
                    height: '1em',
                    '&::before': {
                        borderWidth: '0 1em 1em 1em',
                        borderColor: `transparent transparent ${colorTheme.black600} transparent`,
                    },
                },
            },
            '&[x-placement*="top"]': {
                bottom: '5px!important',
                '& $arrow': {
                    bottom: 0,
                    left: 0,
                    marginBottom: '-0.9em',
                    width: '3em',
                    height: '1em',
                    '&::before': {
                        borderWidth: '1em 1em 0 1em',
                        borderColor: `${colorTheme.black600} transparent transparent transparent`,
                    },
                },
            },
            '&[x-placement*="right"]': {
                left: `${rightOffeset ? '242' : '5'}px!important`,
                '& $arrow': {
                    left: 0,
                    marginLeft: '-0.9em',
                    height: '3em',
                    width: '1em',
                    '&::before': {
                        borderWidth: '1em 1em 1em 0',
                        borderColor: `transparent ${colorTheme.black600} transparent transparent`,
                    },
                },
            },
            '&[x-placement*="left"]': {
                right: '5px!important',
                '& $arrow': {
                    right: 0,
                    marginRight: '-0.9em',
                    height: '3em',
                    width: '1em',
                    '&::before': {
                        borderWidth: '1em 0 1em 1em',
                        borderColor: `transparent transparent transparent ${colorTheme.black600}`,
                    },
                },
            },
        },
        arrow: {
            position: 'absolute',
            fontSize: 7,
            width: '3em',
            height: '3em',
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
            },
        },
        paper: {
            background: colorTheme.black600,
            color: colorTheme.primaryWhite,
            borderRadius: '8px',
        },
    }))();
    const [arrowRef, setArrowRef] = useState<any>(null);

    const { setActiveTourStep } = useContext(ActiveTourStepContext);

    const id = open ? 'tour-guide' : undefined;

    const handlePrevious = () => {
        if (currentStep > 1) {
            setActiveTourStep(currentStep - 1);
        }
    };
    const handleNext = () => {
        if (currentStep < 3) {
            setActiveTourStep(currentStep + 1);
        } else if (currentStep === 3) {
            handleEnd();
        }
    };
    const handleEnd = () => {
        setActiveTourStep(-1);
    };

    return anchor ? (
        <Popper
            id={id}
            open={open}
            anchorEl={anchor}
            placement={placement}
            className={classes.popper}
            modifiers={{
                arrow: {
                    enabled: true,
                    element: arrowRef,
                },
            }}
        >
            <span className={classes.arrow} ref={setArrowRef} />
            <Paper className={classes.paper}>
                <DialogTitle style={{ paddingBottom: '0' }}>
                    <Grid
                        container
                        justify='space-between'
                        alignContent='center'
                    >
                        <Grid xs={3} item>
                            <Typography
                                variant='body2'
                                style={{ opacity: '0.53', lineHeight: '2em' }}
                            >
                                {`${currentStep} of 3`}
                            </Typography>
                        </Grid>
                        {currentStep !== 3 ? (
                            <Grid xs={3} item>
                                <Button variant='text' onClick={handleEnd}>
                                    END TOUR
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle1'>{contentText}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        {currentStep !== 1 ? (
                            <Button
                                onClick={handlePrevious}
                                color='primary'
                                variant='outlined'
                                style={{ margin: '0.5em 0.5em 0.5em 1em' }}
                            >
                                Previous
                            </Button>
                        ) : null}

                        <Button
                            onClick={handleNext}
                            color='primary'
                            variant='contained'
                            style={{
                                margin: `${
                                    currentStep === 1
                                        ? '0.5em 0.5em 0.5em 1em'
                                        : '0.5em'
                                }`,
                            }}
                        >
                            {currentStep === 3 ? 'Finish Tour' : 'Next'}
                        </Button>
                    </Grid>
                </DialogActions>
            </Paper>
        </Popper>
    ) : null;
};

export default TourGuidePopper;
