import { makeStyles, createStyles, Theme } from '@material-ui/core'
import React from 'react'
import { toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import {ReactComponent as NotificationIcon } from '../../../assets/icons-svg/NotificationIcon.svg';
import { convertTimeStampToTime } from '../../../utils/dateUtil'
import LinkButton from '../../common/LinkButton'
import { Content } from './ActivityFeed.model'
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface ReconciliationFeedProps {
    content: Content, 
    onViewReconciliation : (refId : string) => void;
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({        
        linkButton: {
            minWidth: 0,
            display: 'flex',
        },
        
        messageTimeStamp: {
            color: colorTheme => colorTheme.black200,
        },
        
        reconciliationItemContent: {
            borderBottom: colorTheme =>`${toRem16(1)} solid ${colorTheme.grey200}`,
            width: '100%',
            padding: '1rem 0',
        },
        
        reconciliationListItem: {
            display: 'flex',
            gap: '1rem',
        },
        
        reconciliationIcon: {
            margin: '2rem 0 0 0'
        }
    })
)
export const ReconciliationFeed = (props: ReconciliationFeedProps) => {
    const { content, onViewReconciliation } = props
    const { message, heading, ref_id, created } = content
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme);

    return (
        <li className={classes.reconciliationListItem}>
            <div className={classes.reconciliationIcon}><NotificationIcon /></div>
            <div className={classes.reconciliationItemContent}>
                <UiText
                    variant="moped_75"
                    weight="regular_400"
                    className={classes.messageTimeStamp}
                >
                    {convertTimeStampToTime(created)}
                </UiText>
                <UiText variant="car_100" weight="medium_500">
                    {heading}
                </UiText>
                <UiText variant="car_100" weight="medium_500">
                    {message}
                </UiText>
                <LinkButton
                    className={classes.linkButton}
                    onClick={() => {
                        onViewReconciliation(ref_id as string);
                    }}
                >
                    View Reconciliation
                </LinkButton>
            </div>
        </li>
    )
}