import React from 'react';
import {
    Grid,
    Theme,
    Button,
    Typography,
    makeStyles,
    Divider,
} from '@material-ui/core'
import { commonStyles } from '../../../styles/commonStyles'
interface EmptySectionViewProps {
    primaryText: string
    secondaryText?: string
    buttonIcon?: React.ReactNode
    buttonText?: string
    onClickAction?: (params: any) => void
    headerText?: string
    showDivider?: boolean
    height?: string
    disableButton?: boolean
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        sectionRoot: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
    }
})

const EmptySectionView = ({
    primaryText,
    secondaryText,
    buttonIcon,
    buttonText,
    onClickAction,
    headerText,
    showDivider = true,
    height,
    disableButton = false
}: EmptySectionViewProps) => {
    const classes = useStyles()
    const styles = commonStyles()
    return (
        <div>
            {headerText && <Typography variant="h6">{headerText}</Typography>}
            {showDivider && <Divider variant="fullWidth"></Divider>}
            <Grid
                className={classes.sectionRoot}
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: height || '200px' }}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={styles.textCenter}
                    gutterBottom
                >
                    {primaryText}
                </Typography>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                >
                    {secondaryText}
                </Typography>
                {buttonText && <Button
                    startIcon={buttonIcon}
                    variant="text"
                    onClick={onClickAction}
                    disabled={disableButton}
                >
                    {' '}
                    {buttonText}
                </Button>}
            </Grid>
        </div>
    )
}

export default EmptySectionView
