import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Theme,
    makeStyles,
    styled,
} from '@material-ui/core'
import { COLORS } from '../../variables/colors'
import { toRem16 } from '../../styles/commonStyles'
import UiText from '../common/ui/UiText'
import { InlineFlex } from './Wrapper'
import { ReactComponent as Checked } from '../../assets/icons-svg/check-circle-xsmall.svg'
import { IMPORT_STEPS, importSectionMode } from './constants/contractor.const'

interface Props {
    mode: importSectionMode
    footer?: React.ReactElement
    header: string
    subHeader: string
    stepNumber: number
    children: React.ReactChild
    preExpanded?: boolean
}

const CustomAccordion = styled((props: AccordionProps) => (
    <Accordion elevation={0} square {...props} />
))(() => ({
    '&.MuiButtonBase-root': {
        padding: 0,
    },
    border: `1px solid ${COLORS.grey200}`,
    borderRadius: toRem16(8),
    '&:before': {
        display: 'none',
    },
}))

export const CollapsibleSection = (props: Props) => {
    const {
        mode,
        footer,
        header,
        subHeader,
        stepNumber,
        preExpanded,
        children,
    } = props
    const [expanded, setExpanded] = useState<boolean>(false)

    useEffect(() => {
        setExpanded(Boolean(preExpanded))
    }, [preExpanded, mode])

    const handleChange = () => {
        setExpanded(!expanded)
    }

    const classes = makeStyles((theme: Theme) => {
        return {
            stepCircle: {
                minWidth: toRem16(40),
                height: toRem16(40),
                backgroundColor:
                    mode === importSectionMode.INACTIVE
                        ? COLORS.primaryWhite
                        : COLORS.teal200,
                borderRadius: toRem16(40),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            accordian: {
                margin: '1rem 0',
            },
            sumery: {
                padding: 0,
                '& .MuiAccordionSummary-content': {
                    margin: 0,
                    padding: toRem16(16),
                },
            },
            details: {
                padding: stepNumber === IMPORT_STEPS.FOUR ? '1rem 0.1rem 0 4.5rem' :`1rem 0.1rem 1rem 4.5rem`,
                display: 'block',
            },
            footer: {
                borderTop: `1px solid ${COLORS.grey200}`,
                padding: toRem16(16),
                display: 'flex',
                justifyContent: 'flex-end',
            },
        }
    })()
    return (
        <CustomAccordion
            disabled={mode === importSectionMode.INACTIVE}
            expanded={expanded}
            onChange={handleChange}
            className={classes.accordian}
        >
            <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.sumery}
            >
                <InlineFlex gap={toRem16(16)} alignitems="center">
                    <div className={classes.stepCircle}>
                        {mode === 'completed' ? (
                            <Checked />
                        ) : (
                            <UiText
                                variant="hatchback_125"
                                weight="semi_bold_600"
                            >
                                {stepNumber}
                            </UiText>
                        )}
                    </div>
                    <div>
                        <UiText variant="hatchback_125" weight="semi_bold_600">
                            {header}
                        </UiText>
                        {expanded && (
                            <UiText variant="motorcycle_90">{subHeader}</UiText>
                        )}
                    </div>
                </InlineFlex>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {children}
            </AccordionDetails>
            {footer && <div className={classes.footer}>{footer}</div>}
        </CustomAccordion>
    )
}
