import * as Yup from 'yup'
import { ProductDetail } from '../../../models'
export interface ProductFormProps {
    type?: string
    product?: any
    action?: string
    handleClose?: any
    setSelectedProduct?: React.Dispatch<ProductDetail | undefined>
    loadProducts?: () => void
    formikRef?: any
    setProductUpdated?: React.Dispatch<boolean>
    productCallback?: any
    showFormSaveOptions?: boolean
}

export const defaultValues = {
    title: '',
    type: 'product',
    price: '',
    tax_rate: 0,
    category: '',
    description: '',
}

export const ProductFormValidationSchema = Yup.object({
    title: Yup.string().max(1024, "Product name should not be greater than 1024 character").required('Product/Service name is required'),
    type: Yup.string().required('Product type is required'),
    price: Yup.number().min(0).required("Product price is required"),
    tax_rate: Yup.number()
        .min(0, 'Tax rate should be greater than 0 ')
        .lessThan(100, 'Tax rate cannot be greater than 100%'),
})

export const propertyIconMapping: any = [
    {
        key: 'title',
        type: 'text',
        placeholder: 'Product/Service Name',
        md: 12,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        label: 'Name (Required)',
    },
    {
        key: 'type',
        type: 'toggleGroup',
        optionsData: [
            { text: 'Product', value: 'product', width: '104px' },
            { text: 'Service', value: 'service', width: '104px' },
        ],
        md: 6,
        xs: 12,
    },
    {
        key: '',
        group: true,
        formfields: [
            {
                key: 'price',
                type: 'number',
                placeholder: '0.0',
                md: 6,
                xs: 12,
                label: 'Price (Required)',
                startIcon: "dollar",
            },
            {
                key: 'tax_rate',
                type: 'number',
                placeholder: '0',
                md: 6,
                xs: 12,
                label: 'Tax Rate (Optional)',
                endIcon: "percentage",
                group: true,
            },
        ],
    },
    {
        key: 'category',
        type: 'autocomplete',
        placeholder: 'Select a Category',
        md: 12,
        xs: 12,
        label: 'Product Group (Optional)',
        my: 2,
    },
    {
        key: 'item_id',
        type: 'text',
        placeholder: 'ie: 12345',
        md: 12,
        xs: 12,
        label: 'Item Id (Optional)',
        my: 2,
    },
    {
        key: 'description',
        type: 'textarea',
        label: 'Description (Optional)',
        placeholder: 'Write a description',
        md: 12,
        xs: 12,
        my: 2,
    },
]