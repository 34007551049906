import Icon from '../../common/Icon'
import UiText from '../../common/ui/UiText'
import InfoCard from '../Cards/InfoCard'
import { useStyles } from './BookKeepingInfoCard'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { useHistory } from 'react-router-dom';

interface PayrollMigrationInfoCardProps {
    cardHeader: string;
    cardDescription: string;
    buttonText: string;
}

const PayrollMigrationInfoCard: React.FC<PayrollMigrationInfoCardProps> = ({cardHeader, cardDescription, buttonText}) => {
    const { colorTheme } = useThemeContext()
    const history = useHistory()    
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconStyle}>
                    <Icon icon="bankingIcon" />
                </div>
                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <UiText variant="hatchback_125" weight="medium_500">
                            {cardHeader}
                        </UiText>
                    }
                    subtitle={
                        <UiText variant="motorcycle_90">
                            {cardDescription}
                        </UiText>
                    }
                    buttonText={`${buttonText}`}
                    buttonClick={() => history.push('/payroll-migration')}
                />
            </div>
        </div>
    )
}

export default PayrollMigrationInfoCard
