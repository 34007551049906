import React, { useContext, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Button,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    Hidden,
    Divider,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ApplicationStore } from '../../../models/store';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import ScanProcess from './ScanProcess';
import Dropzone from "react-dropzone";
import { AlertTitle } from '@material-ui/lab';
import { useStyles } from "../../../styles/styles";
import UiSnackbarAlert from '../../common/ui/UiSnackbarAlert';
import { getAccount } from '../../../services/journalServices';
import { expenseCaterories } from '../../../utils/utility';
import { categoryListProps, maxFileSizeUpload, maxNumberFilesUpload,resizeReceiptImage, minFileSizeToCompress } from './ScanUtils';
import Loader from '../../common/Loader';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => createStyles({
    dropzone: {
        border: (colorTheme) => '2px dashed ' + colorTheme.grey200,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6)
    },
    mobiledropzone: {
        textAlign: 'center',
        border: 'none',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6)
    }
}))

const ReceiptsScan = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme);
    const globalClasses = useStyles(colorTheme);
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [fileList, setFileList] = useState<any[]>([]);
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const [showAlert, setShowAlert] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [listData, setListData] = useState<categoryListProps>({
        data: [],
        categoryOptions: [],
        accountRecord: {},
        loading: false
    });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleModalClose = () => {
        setOpenModal(false)
    }
    const getAccountdata = useCallback(() => {
        setListData((prev: any) => ({ ...prev, loadingAccountRecord: true }));
        getAccount(
            props.appData.appData.current_account_id,
            props.appData.appData.current_business_id,
        )
            .then((res: any) => {
                let expenseCateroriesList = expenseCaterories(res);
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: expenseCateroriesList.childs,
                    accountParentList: expenseCateroriesList.parent,
                    loadingAccountRecord: false,
                }));
            })
            .catch((err) => {
                setListData((prev: any) => ({ ...prev, loading: false }));
            });
    }, [props]);
    useEffect(() => {
        setActiveRouteHeading('Scan Receipts');
        getAccountdata();
    }, [setActiveRouteHeading, getAccountdata])

    const  handleFileUpload = async (files: any[], rejectedFile: any[], event: any) => {
        setShowLoader(true);
        await Promise.all(
            files.map((image: any) => {
                if(image.size>minFileSizeToCompress) {
                    return resizeReceiptImage(image);
                }
                return image;
            })
        ).then((uploadReceiptImages) => {
            setShowLoader(false);
            let prev = [...fileList];
            let isExist = false;
            uploadReceiptImages.forEach((file: any, ind) => {
                isExist = prev.find(obj => obj.name === file.name);
                if (!isExist) {
                    prev.push(file);
                }
            });
            setFileList(prev);
            setOpenModal(true);
            if (rejectedFile.length > 0) {
                let rejectedFileNames: string[] = [];
                rejectedFile.forEach((r, i) => {
                    rejectedFileNames.push(r.file.name);
                });
                setShowAlert(true);
                setAlertMessage('Rejected ' + (rejectedFileNames.join(',')) + ' due to invalid file size/type/count.');
            }
        });
    }

    return (
        <>
            <Box>
                <UiSnackbarAlert
                    open={showAlert}
                    message={alertMessage}
                    handleClose={() => { setShowAlert(false) }}
                    actionButtonColor={'primary'}
                />
                <Hidden mdUp>
                    <Box my={2} px={4}>
                        <Grid item xs={12}>
                            <Typography variant={"h5"}>Scan Receipts</Typography>
                        </Grid>
                    </Box>
                    <Box mb={2}>
                        <Divider />
                    </Box>
                </Hidden>
                <Box px={4}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: fullScreen ? 'auto' : '70vh' }}
                    >
                        <Grid item xs={12} md={6}>

                            <Box my={3}>
                                <Typography align={fullScreen ? 'left' : 'center'} className={globalClasses.pageDesc}>Scanning a receipts will create a journal entry and automatically add it to your financial statements.</Typography>
                            </Box>

                            <Box my={2}>
                                {showLoader? (
                            <Loader />
                                ) : (
                                <Dropzone
                                    onDrop={handleFileUpload}
                                    accept="image/png,image/jpeg"
                                    maxSize={maxFileSizeUpload}
                                    maxFiles={maxNumberFilesUpload}
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragAccept,
                                        isDragReject
                                    }) => {
                                        return (
                                            <Box
                                                className={fullScreen ? classes.mobiledropzone : classes.dropzone}
                                                {...getRootProps({})}
                                            >
                                                <Hidden smDown>
                                                    <Box my={3}>
                                                        <Typography align="center">Drop files here to upload</Typography>
                                                    </Box>
                                                </Hidden>
                                                <Box my={3}>
                                                    <input {...getInputProps()} name="filesToScan[]" />
                                                </Box>
                                                <Box my={3} width={fullScreen ? '100%' : '50%'} mx="auto">
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        data-cy="receipt-upload-button"
                                                        >
                                                        Select Files 
                                                    </Button>
                                                </Box>
                                                <Box my={3}>
                                                    <Typography color='textSecondary' variant='body2' align="center">
                                                        Supported file types: .png,.jpeg,.jpg<br />
                                                        Maximum size per file: {maxFileSizeUpload/(1024*1024)}MB<br/>
                                                        Maximum {maxNumberFilesUpload} files can be uploaded.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </Dropzone>) }
                            </Box>
                            <Box my={3}>
                                <Alert severity="error" className={globalClasses.alertNoteGrey}>
                                    <AlertTitle>Note</AlertTitle>
                                    Scanning a receipt from a connected bank account or credit card will create a duplicate journal entry for the transaction in your financial statements.
                                </Alert>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <ScanProcess
                open={openModal && fileList.length > 0}
                handleClose={(result: any, action: any) => {
                    let msg = '';
                    if (result && result.success >= -1) {
                        if (result.success > 0) {
                            setFileList([]);
                            msg = result.success + ' Journal Entries added.';
                            if (result.error > 0) {
                                msg += ' ' + (result.error) + ' entries skipped due to error.'
                            }
                            handleModalClose()
                        }
                        else {
                            setOpenModal(true);
                            msg = 'Error in creating journal entry';
                        }
                        setAlertMessage(msg);
                        setShowAlert(true);
                    }
                    else if (result && result.success === -2) {
                        // cancel from next step.
                        setOpenModal(true);
                    }
                    else {
                        handleModalClose()
                        setFileList([]);
                    }
                }
                }
                filesToScan={fileList}
                listData={listData}
                setListData={setListData}
            />
        </>
    )
}

const mapStateToProps = (appData: ApplicationStore) => ({
    appData: appData,
});
export default connect(mapStateToProps)(ReceiptsScan);
