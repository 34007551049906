import React, { useEffect } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import {
    Hidden,
    Container,
    makeStyles,
    Theme,
    Grid,
    Divider,
    Box,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core'
import Icon from '../../../components/common/Icon'
import UiText from '../../common/ui/UiText'
import MobileHeader from '../../common/MobileHeader'
// components
import LocationList from './LocationList'
import LocationPreview from './preview/LocationPreview'
import LocationModal from '../locations/modal/LocationModal'
import ConfirmLocationModal from '../locations/modal/ConfirmLocationModal'
// api
import useLocationsHook from './locations.hook'
import LocationEmptyPage from './LocationEmptyPage'
import UiButton from '../../common/ui/UiButton'
import { toRem16 } from '../../../styles/commonStyles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { Location } from '../../../models/locations';
import Loader from '../../common/Loader'


const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
            [theme.breakpoints.up('md')]:{
                display:'flex'
            }
        },
        listContent: {
            paddingBottom: theme.spacing(3),
            width: 'inherit',
            height: '100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up(1601)]: {
                justifyContent: 'flex-start',
            },
        },
        vehiclesContainer: {
            display: 'flex',
            flex: 1,
            padding: '0 1.5rem',
            margin: `0 ${toRem16(148.5)}`,
            overflow: 'auto',
            minHeight: 0,
        },
        vehiclesListContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minHeight: 0,
            borderRight: `${toRem16(1)} solid ${useThemeContext().grey300}`,
            overflow: 'auto',
        },
        vehicleNavItems: {
            flex: 1,
            padding: 0,
            margin: 0,
            '& li': {
                listStyleType: 'none',
            },
        },
    }
})


function Locations() {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles()
    let { path } = useRouteMatch()
    const { colorTheme } = useThemeContext()
    
    const {
        openModal,
        setOpenModal,
        showConfirmModal,
        setShowConfirmModal,
        searchTerm,
        setSearchTerm,
        selectedLocation,
        setSelectedLocation,
        locationUpdated,
        setLocationUpdated,
        locationsData,
        setLocationsData,
        formikRef,
        handleLocationModalClose,
        handleConfirmModal,
        loadMore,
        loadLocations,
        isFormTouched,
        discardChanges
    } = useLocationsHook();

    const { loading, items, hasMore } = locationsData
    const history = useHistory()

    if (loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Loader />
            </Grid>
        )
    }

    return (
        <Container className={classes.container} maxWidth="lg">
            <ConfirmLocationModal
                showConfirmModal={showConfirmModal}
                location={undefined}
                handleClose={handleConfirmModal}
                discardChanges={discardChanges}
            />
            <LocationModal
                open={openModal}
                handleClose={handleLocationModalClose}
                loadLocations={() => loadLocations()}
                formikRef={formikRef}
                isFormTouched={isFormTouched}
                locationCallback={(location: Location) => {
                    if (location) {
                        setSelectedLocation(location)
                        isSmDown && history.push(`/mileage/locations/${location.id}`)
                    }
                }}
            />
            <MobileHeader title={'Locations'} showBottomDivider={true} />
            {
                items?.length 
                ? 
                    (
                        <Grid container spacing={1}>
                            <Grid
                                container
                                item
                                md={5}
                                sm={12}
                                justify="flex-start"
                                alignItems="flex-start"
                                className={classes.content}
                            >
                                <div className={classes.listContent}>
                                    <Box my={2}>
                                        <UiButton
                                            btnType="primary"
                                            label="Add Location"
                                            handleClick={() => {
                                                setOpenModal(true)
                                            }}
                                            icon={<Icon icon="add" svgColor="white" />}
                                        />
                                    </Box>
                                    <Box my={3}>
                                        <UiText>
                                            { locationsData.itemsCount } Saved {items?.length > 1 ? 'Locations' : 'Location'}
                                        </UiText>
                                    </Box>
                                    <LocationList
                                        loading={loading}
                                        data={items ?? []}
                                        loadMore={loadMore}
                                        hasMore={hasMore}
                                        selectedLocation={selectedLocation}
                                        setSelectedLocation={setSelectedLocation}
                                        pageCount={locationsData.pageCount}
                                        currentPage={locationsData.page}
                                    />     
                                </div>
                            </Grid>
                            <Hidden smDown>
                                <Grid
                                    sm={1}
                                    item
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="stretch"
                                >
                                    <Divider orientation="vertical" flexItem />
                                </Grid>
                                <Grid item xs={12} md={6} justify="center">
                                    {!selectedLocation ?  (
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                            style={{ height: '100%' }}
                                        >
                                            <Typography variant="subtitle1">
                                                Select a location to view the details
                                            </Typography>
                                        </Grid>
                                        ) : (
                                        <LocationPreview
                                            selectedLocation={selectedLocation}
                                            setSelectedLocation={setSelectedLocation}
                                            loadLocations={() =>
                                                loadLocations()
                                            }
                                            setLocationUpdated={setLocationUpdated}
                                            locations={items}
                                        />
                                        )}
                                </Grid>
                            </Hidden>
                        </Grid>
                    )
                : 
                    (
                        !loading && (
                                <LocationEmptyPage
                                setOpenModal={setOpenModal}
                            />
                        )
                    )
            }
        </Container>
    )
}

export default Locations