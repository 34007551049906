import {
    Company,
    CompanyAddress,
    OnboardingStepInfo,
    PayrollFrequency,
    PayrollSignatory,
} from '../../../models/payroll/company'
import { getResource, postResource, putResource } from '../../axiosService'

const companyUrl = (business_id: string) =>
    `api/business/${business_id}/payroll_organizer/company`

export function getOnboardingStepInfo(
    business_id: string
): Promise<OnboardingStepInfo> {
    return getResource<OnboardingStepInfo>(
        `${companyUrl(business_id)}/onboarding_steps_info`
    )
}

export function markStepAsCompleted(business_id: string, params: any) {
    return putResource(
        `api/business/${business_id}/payroll_organizer/company/mark_step_complete`,
        params
    )
}

export function getBusinessAddress(business_id: string) {
    return getResource(
        `api/business/${business_id}/payroll_organizer/company/address`
    )
}

export function getCompany(business_id: string): Promise<Company> {
    return getResource<Company>(companyUrl(business_id))
}

export function createCompany(
    business_id: string,
    body: Company
): Promise<any> {
    return postResource(companyUrl(business_id), body)
}

export function updateCompany(
    business_id: string,
    body: Company
): Promise<any> {
    return putResource(companyUrl(business_id), body)
}

export function getCompanyAddress(
    business_id: string
): Promise<CompanyAddress> {
    return getResource<CompanyAddress>(`${companyUrl(business_id)}/address`)
}

export function createCompanyAddress(
    business_id: string,
    body: CompanyAddress
): Promise<any> {
    return postResource(`${companyUrl(business_id)}/address`, body)
}

export function updateCompanyAddress(
    business_id: string,
    body: CompanyAddress
): Promise<any> {
    return putResource(`${companyUrl(business_id)}/address`, body)
}

export function getPayrollFrequency(
    business_id: string
): Promise<PayrollFrequency> {
    return getResource<PayrollFrequency>(
        `${companyUrl(business_id)}/payroll_frequency`
    )
}

export function createPayrollFrequency(
    business_id: string,
    body: PayrollFrequency
): Promise<any> {
    return postResource(`${companyUrl(business_id)}/payroll_frequency`, body)
}

export function getTermsOfService(business_id: string): Promise<string> {
    return getResource<string>(`${companyUrl(business_id)}/tos`)
}

export function addOwnersAsEmployee(business_id: string): Promise<any> {
    return postResource(
        `api/business/${business_id}/payroll_organizer/employee/owners`,
        {}
    )
}

export function getFederalTaxInfo(business_id: string): Promise<any> {
    return getResource<any>(`${companyUrl(business_id)}/federal_tax_info`)
}

export function updateFederalTaxInfo(
    business_id: string,
    body: any
): Promise<any> {
    return putResource(`${companyUrl(business_id)}/federal_tax_info`, body)
}

export function getCompanyIndustry(business_id: string): Promise<any> {
    return getResource<any>(`${companyUrl(business_id)}/industry`)
}

export function updateCompanyIndustry(
    business_id: string,
    body: CompanyAddress
): Promise<any> {
    return putResource(`${companyUrl(business_id)}/industry`, body)
}

export function getBankAccount(business_id: string): Promise<any> {
    return getResource<any>(`${companyUrl(business_id)}/bank_account`)
}

export function getBankAccountForADP(business_id: string): Promise<any> {
    const url = `/api/plaid/payroll/business/${business_id}/bank_account_info`
    return getResource<any>(url)
}


export function addBank(business_id: string, body: any): Promise<any> {
    return postResource(`${companyUrl(business_id)}/bank_account`, body)
}

export function verifyBank(business_id: string, body: any): Promise<any> {
    return postResource(`${companyUrl(business_id)}/bank_account/verify`, body)
}

export function getPayrollSignatory(business_id: string): Promise<any> {
    return getResource(`${companyUrl(business_id)}/payroll_signatory`)
}

export function addPayrollSignatory(
    business_id: string,
    body: PayrollSignatory
): Promise<any> {
    return postResource(`${companyUrl(business_id)}/payroll_signatory`, body)
}

export function updatePayrollSignatory(
    business_id: string,
    body: PayrollSignatory
): Promise<any> {
    return putResource(`${companyUrl(business_id)}/payroll_signatory`, body)
}

export function getESign(business_id: string): Promise<any> {
    return getResource(`${companyUrl(business_id)}/e_signature`)
}

export function makeESign(business_id: string, body: any): Promise<any> {
    return postResource(`${companyUrl(business_id)}/e_signature`, body)
}

export function finishOnboarding(business_id: string): Promise<unknown> {
    return putResource(`${companyUrl(business_id)}/finish_onboarding`, {})
}

export function accceptTos(business_id: string): Promise<any> {
    return postResource(`${companyUrl(business_id)}/tos`, {})
}

export function getLinkToken(business_id: string, is_payroll_migration: boolean = false): Promise<any> {
    return postResource(`/api/plaid/payroll/create_link_token`, { business_id, is_payroll_migration })
}

export function exchangeToken(body: any): Promise<any> {
    return postResource(`/api/plaid/payroll/exchange_public_token`, body)
}

export function fetchUpdateToken(business_id: string): Promise<any> {
    return getResource(`/api/plaid/payroll/business/${business_id}/update_link_token`)
}

