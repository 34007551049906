import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core'
import SectionView from '../../common/SectionView'
import EmailIcon from '@material-ui/icons/Email'
import Loader from '../../../common/Loader'
import EmptySectionView from '../../common/EmptySectionView'
import { getEmployeeForms } from '../../../../services/apiService/payroll/employee'

const ElectronicSignature = ({
    businessId,
    employeeId,
    setOpenRequestDialog,
    disableRequestInputButton
}: any) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<any>()

    useEffect(() => {
        setLoading(true)
        getEmployeeForms(businessId, employeeId)
            .then((res: any) => {
                setLoading(false)
                setFormData(res)
            })
            .catch((err) => {
                setLoading(false)
            })
    }, [businessId, employeeId])

    return (
        <div>
            <SectionView
                key={'electronic signature'}
                headerText={'Electronic Signature'}
                customNode={
                    <>
                        {loading ? (
                            <div style={{ height: '200px' }}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {formData ? (
                                    formData.map((data: any) => (
                                        <div style={{ marginTop: '16px' }}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {data?.title}
                                            </Typography>
                                            <Typography variant="body1">
                                                {data?.requires_signing
                                                    ? 'Waiting on Signature'
                                                    : 'Digital Signature Received'}
                                            </Typography>
                                        </div>
                                    ))
                                ) : (
                                    <EmptySectionView
                                        showDivider={false}
                                        primaryText={'No Signature Found'}
                                        secondaryText={''}
                                        buttonText={'Request employee input'}
                                        buttonIcon={<EmailIcon />}
                                        disableButton={disableRequestInputButton}
                                        onClickAction={() => {
                                            setOpenRequestDialog(true)
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </>
                }
            />
        </div>
    )
}

export default ElectronicSignature
