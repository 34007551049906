import React, { useContext, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { PAGE_TYPE } from '../constants/adp-payroll.const'
import { ERROR_MESSAGE } from '../constants/adp-payroll.const'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { QUERY_SUBSTRING } from '../../bookkeeping/banking/constants/banking.const'
import { REDIRECT_URL } from '../../payroll/banking-info/constants/banking-info.const'
import {
    exchangeToken,
    fetchUpdateToken,
    getBankAccountForADP,
    getLinkToken,
} from '../../../services/apiService'
import { markStepAsCompleted, updateProcessorToken } from '../../../services/apiService/adp-payroll/company'
import { useADPCompanyContext } from './CompanyProvider'

export const BankingInfoContext = React.createContext(null)

export function useADPBankingState(): any {
    const state = useContext(BankingInfoContext)
    return state
}

export const ADPBankingInfoProvider = ({ children, refreshSteps }: any) => {
    const [linkToken, setLinkToken] = useState<any>(null)
    const [loadToken, setLoadToken] = useState<any>(false)
    const [loadBankInfo, setLoadBankInfo] = useState<any>(false)
    const [updateLinkToken, setUpdateLinkToken] = useState<any>(null)
    const [bankInfo, setBankInfo] = useState<any>(null)
    const [page, setPage] = useState<string>('')
    const [publicToken, setPublicToken] = useState<string | null>(null)
    const [metadata, setMetaData] = useState<any>(null)
    const [isError, setIsError] = useState<boolean>(false)
    const [disableBankConnectButton, setDisableBankConnectButton] =
        useState<boolean>(false)
    const [disableBankVerifyButton, setDisableBankVerifyButton] =
        useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<any>(null)
    const [dialog, setDialog] = useState<boolean>(false)
    const [accountEditStatus, setAccountEditStatus] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { currentBusinessId } = useCurrentStore()
    const { companyStepInfo } = useADPCompanyContext()
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=')
    const [isBankAccountChange, setIsBankAccountChange] = useState<boolean>(false)
    const [isProcessorToken, setIsProcessorToken] = useState<any>(null)

    const onSuccess = (public_token: string | null, metadata: any) => {
        setPublicToken(public_token)
        setMetaData(metadata)
    }

    const config: any = {
        token: linkToken,
        onSuccess,
    }

    if (isOAuthRedirect) {
        const baseUrl = window.location.origin
        const url = window.location.href
        const queryString = url.split('?')[1]
        const redirectURL = url.includes('localhost')
            ? `${REDIRECT_URL.UAT}/${QUERY_SUBSTRING}${queryString}`
            : `${baseUrl}/${QUERY_SUBSTRING}${queryString}`
        config.receivedRedirectUri = redirectURL
    }

    async function updateProcessorTokenWithHandling() {
        setLoadToken(true)
        try {
            await updateProcessorToken(currentBusinessId, true);
            setIsProcessorToken(false)
        } catch (err) { 
            setIsProcessorToken(true)
            errorHandler(ERROR_MESSAGE.UPDATE_PROCESSOR_TOKEN_ERROR);
        }finally {
            setLoadToken(false)
        }
    }

    const { open: openUpdateMode, ready: updateTokenReady } = usePlaidLink({
        token: updateLinkToken,
        onSuccess: async (publicToken, metaData) => {
            try {
                setLoadToken(true)
                await getBankAccountForADP(currentBusinessId)
                if (isBankAccountChange || companyStepInfo?.adp_company_created === true) {
                    await updateProcessorTokenWithHandling()
                }
                await  markStepAsCompleted({ step: 'bank_account_addition'});
                setIsBankAccountChange(false)
                refreshSteps()
            } catch (err) {
                errorHandler(ERROR_MESSAGE.GET_BANK_DETAILS_ERROR)
            } finally {
                setLoadToken(false)
            }
        },
    })

    const { open: openPlaidLink, ready } = usePlaidLink({
        ...config,
    })

    const triggerBankAccountChange = () => {
        setIsBankAccountChange(true)
        openPlaidLink()
    }

    const renderPage = (res: any) => {
        if (res === null || Object.keys(res).length === 0) {
            setPage(PAGE_TYPE.INITIAL_SECTION)
            return
        }

        const pageMapping = {
            pending_manual_verification: PAGE_TYPE.VERIFICATION_PENDING_SECTION,
            manually_verified: PAGE_TYPE.COMPLETED_SECTION,
            automatically_verified: PAGE_TYPE.COMPLETED_SECTION,
        }

        const { item_id, verification_status, status, processor_token_linked } = res || {};
        
        const isItemIdInvalid = !item_id || item_id === '';
        const isVerificationStatusInvalid = verification_status !== 'pending_manual_verification' && verification_status !== 'automatically_verified' && verification_status !== 'manually_verified';
        const isStatusInvalid = status !== 'authorized';
        if (item_id && (!processor_token_linked || processor_token_linked === null) && verification_status !== 'pending_manual_verification' && companyStepInfo?.adp_company_created === true) {
            setIsProcessorToken(true)
            setPage(PAGE_TYPE.INITIAL_SECTION)
            return
        }
        if (isItemIdInvalid || isVerificationStatusInvalid || isStatusInvalid ) {       
            setPage(PAGE_TYPE.INITIAL_SECTION)
            return
        }
    
        //@ts-ignore
        setPage(pageMapping[verification_status] || PAGE_TYPE.INITIAL_SECTION)
    }

    const fetchLinkToken = async () => {
        const token = localStorage.getItem('linkToken')
        if (token && isOAuthRedirect) {
            setLinkToken(token)
            return
        }
        setLoadToken(true)
        if (currentBusinessId) {
            try {
                const res = await getLinkToken(currentBusinessId)
                setLinkToken(res.link_token)
                localStorage.setItem('linkToken', res.link_token)
            } catch (err) {
                errorHandler(ERROR_MESSAGE.GET_LINK_TOKEN_ERROR)
                setDisableBankConnectButton(true)
            } finally {
                setLoadToken(false)
            }
        }
    }

    const fetchUpdateModeToken = async () => {
        setLoadToken(true)
        if (currentBusinessId) {
            try {
                const res = await fetchUpdateToken(currentBusinessId)
                setUpdateLinkToken(res.link_token)
            } catch (err) {
                errorHandler(ERROR_MESSAGE.GET_LINK_TOKEN_ERROR)
                setDisableBankVerifyButton(true)
            } finally {
                setLoadToken(false)
            }
        }
    }

    const fetchBankInfo = async () => {
        setLoadBankInfo(true)
        if (currentBusinessId) {
            try {
                const res = await getBankAccountForADP(currentBusinessId)
                setBankInfo(res)
                renderPage(res)
            } catch (err: any) {
                if(err?.status === 401 || err?.statusText.includes(ERROR_MESSAGE.PLAID_AUTH_FAILED) || err?.statusText.includes(ERROR_MESSAGE.ITEM_ERROR) || err?.statusText.includes(ERROR_MESSAGE.ITEM_LOGIN_REQUIRED)) {    
                    errorHandler(ERROR_MESSAGE.BANK_RECONNECT_REQUIRED)
                    setDisableBankConnectButton(false)
                }else {
                    setDisableBankConnectButton(false)
                    errorHandler(ERROR_MESSAGE.GET_BANK_DETAILS_ERROR)
                }
                setPage(PAGE_TYPE.INITIAL_SECTION)
               
            } finally {
                setLoadBankInfo(false)
            }
        }
    }

    useEffect(() => {
        fetchLinkToken()
    }, [currentBusinessId])

    useEffect(() => {
        fetchBankInfo()
    }, [currentBusinessId])

    useEffect(() => {
        if(isProcessorToken === false){
            fetchBankInfo()
        }
    }, [isProcessorToken])

    useEffect(() => {
        const shouldOpenPlaidLink = isOAuthRedirect && ready
        if (shouldOpenPlaidLink) {
            openPlaidLink()
        }
    }, [ready, openPlaidLink, isOAuthRedirect])

    useEffect(() => {
        const exchangeTokenAndSetBankInfo = async () => {            
            if (publicToken && metadata?.account_id) {
                setLoadToken(true)
                const payload = {
                    business_id: currentBusinessId,
                    meta_data: metadata,
                }
                try {
                    const res = await exchangeToken(payload)
                    if(res.verification_status === 'manually_verified' || res.verification_status === 'automatically_verified') {
                        if(isBankAccountChange || companyStepInfo?.adp_company_created === true){
                                await updateProcessorToken(currentBusinessId, true);
                        }
                        
                        await  markStepAsCompleted({ step: 'bank_account_addition'});
                        refreshSteps()
                        setIsBankAccountChange(false)
                    }
                    setBankInfo(res)
                    renderPage(res)
                    localStorage.removeItem('linkToken')
                } catch (err) {
                    if (
                         //@ts-ignore
                        err?.statusText.includes(
                            'LINK_PLAID_TOKEN_ERROR'
                        )
                    ) {
                        setIsProcessorToken(true)
                        errorHandler(ERROR_MESSAGE.GET_BANK_DETAILS_ERROR)
                    } else {
                        errorHandler(err)
                    }
                } finally {
                    setLoadToken(false)
                }
            }
        }
        exchangeTokenAndSetBankInfo()
    }, [publicToken, metadata, currentBusinessId])

    const errorHandler = (err: any) => {
        setErrorMsg(
            typeof err === 'string'
                ? err
                : err?.message || ERROR_MESSAGE.GENERIC_ERROR
        )
        setIsError(true)
    }

    const providerValue: any = {
        linkToken,
        setLoadToken,
        loadBankInfo,
        bankInfo,
        setBankInfo,
        publicToken,
        setPublicToken,
        alert,
        metadata,
        setMetaData,
        isError,
        loadToken,
        dialog,
        setDialog,
        isModalOpen,
        setIsModalOpen,
        isOAuthRedirect,
        openPlaidLink,
        page,
        setPage,
        accountEditStatus,
        setAccountEditStatus,
        errorMsg,
        setErrorMsg,
        setIsError,
        disableBankConnectButton,
        disableBankVerifyButton,
        updateLinkToken,
        fetchUpdateModeToken,
        openUpdateMode,
        updateTokenReady,
        triggerBankAccountChange,
        isProcessorToken,
        updateProcessorTokenWithHandling
    }

    return (
        <BankingInfoContext.Provider value={providerValue}>
            {children}
        </BankingInfoContext.Provider>
    )
}
