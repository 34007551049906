import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { ApplicationStore } from '../../../models/store'
import {
    getTOSData,
    updateAgreeTOS,
} from '../../../services/apiService/userService'
import { showError } from '../../../services/formService'
import { toRem16 } from '../../../styles/commonStyles'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import Loader from '../../common/Loader'
import LoaderOverlay from '../../common/LoaderOverlay'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

export interface TOSUpdate {
    privacy_policy: boolean
    terms_of_service: boolean
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        container: {
            padding: toRem16(48),
            textAlign: 'center',
        },
        header: {
            margin: '0 0 1rem 0',
        },
        link: {
            color: (colorTheme) => colorTheme.blue200,
            textDecoration: 'none',
        },
        descriptionText: {
            margin: `0 0 ${toRem16(58)} 0`,
        },
    })
)
export const TOSModal = () => {
    const { colorTheme } = useThemeContext()
    const [showTOSModal, setShowTOSModal] = useState<boolean>(false)
    const [isUpdatingTOS, setIsUpdatingTOS] = useState<boolean>(false)
    const [isFetchingTOSData, setIsFetchingTOSData] = useState<boolean>(true)
    const store: ApplicationStore = useStore().getState()
    const { appData } = store
    const { user } = appData
    const classes = useLocalStyles(colorTheme)

    useEffect(() => {
        getTOSData()
            .then((res: unknown) => {
                const data = res as TOSUpdate
                setIsFetchingTOSData(false)
                if (!user.accountant_mode && !data.terms_of_service) {
                    setShowTOSModal(true)
                }
            })
            .catch((error) => {
                showError(error)
            })
    }, [user.accountant_mode])

    const isFirstLogin = (): boolean => {
        return user.first_login === user.last_login
    }

    const onAgreeToTerms = () => {
        const agreement = {
            privacy_policy: true,
            terms_of_service: true,
        }
        setIsUpdatingTOS(true)
        updateAgreeTOS(agreement)
            .then(() => {
                setIsUpdatingTOS(false)
                setShowTOSModal(false)
            })
            .catch((error) => {
                setIsUpdatingTOS(false)
                showError(error)
            })
    }
    return (
        <>
            {isFetchingTOSData ? (
                <LoaderOverlay />
            ) : (
                <UiDialog
                    open={showTOSModal}
                    handleClose={() => {}}
                    title=""
                    size="sm"
                    hideTitleSection={true}
                >
                    <div className={classes.container}>
                        {!isFirstLogin() && (
                            <UiText
                                variant="suv_150"
                                weight="medium_500"
                                className={classes.header}
                            >
                                Our Terms of Service and  Privacy Policy have
                                been updated.
                            </UiText>
                        )}

                        <UiText
                            variant="car_100"
                            weight="regular_400"
                            className={classes.descriptionText}
                        >
                            In order to access your account, you must agree to
                            our
                            <a
                                target="_blank"
                                href="https://1800accountant.com/terms-of-services"
                                rel="noreferrer"
                                className={classes.link}
                            >
                                {' Terms of Service,'}
                            </a>{' '}
                            and our
                            <a
                                target="_blank"
                                href="https://1800accountant.com/privacy-policy"
                                rel="noreferrer"
                                className={classes.link}
                            >
                                {' Privacy Policy.'}
                            </a>
                        </UiText>
                        {isUpdatingTOS ? (
                            <Loader />
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => onAgreeToTerms()}
                                color="primary"
                            >
                                I Agree
                            </Button>
                        )}
                    </div>
                </UiDialog>
            )}
        </>
    )
}
