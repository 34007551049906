const DOT_COLORS = [
    '#2FA369',
    '#50BA3A',
    '#CAE34B',
    '#E5E578',
    '#EEDF91',
]
export const getDotColors = (): object => {
    return DOT_COLORS.reduce(
        (acc: Record<string, object>, color: string, index) => {
            acc[`&.dot-color-${index}`] = {
                background: color,
            }
            return acc
        },
        {}
    )
}