import React from 'react'
import { dismissBlock } from '../AccountSetupItems/AccountSetupUtility'
import { FOUND_AD_BLOCK } from '../TodoSection/Todo.const'
import FoundAdImage from '../../../assets/images/FoundBanner.png'
import LogoFound from '../../../assets/images/LogoFound.png'
import { Button, makeStyles, Theme} from '@material-ui/core'
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { toRem16 } from '../../../styles/commonStyles';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import useDeviceSize from '../../../hooks/useDeviceSize';
import UiText from '../../common/ui/UiText';
import Icon from '../../common/Icon';

const useStyles = makeStyles<Theme, ThemeColors>(()=> {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            border: colorTheme => `0.063rem solid ${colorTheme.grey200}`,
            borderRadius: '0.5rem',
            marginBottom: '1rem',
        },
        imageStyle: {
            width: '14.5rem',
            display: 'block',
            borderRadius: '0rem 0.5rem 0.5rem 0re0;',
            marginLeft: '1rem'
            
        },
        buttonStyle: {
            marginTop: '0.5rem'
        },
        dismissBtn: {
            marginTop: '1rem', 
            color: colorTheme => colorTheme.blue200
        },
        mainTextContent: {
            padding: '0 0 0 1.4rem', 
            marginTop: '1rem', 
            marginBottom: '0.125rem'
        },
        divStyle: {
            display: 'grid',
            gridTemplateColumns: '1fr 16rem',
            justifyContent: 'center', /* centers items horizontally */
            alignItems: 'center' /* centers items vertically */

        },
        divWithoutImage: {
            display: 'grid',
            gridTemplateColumns: `1fr 0.5rem`
        },
        checkImg: {
            width: '11.25rem',
            marginBottom: '0.313rem',
        },
        detailsTextMargin: {
            marginTop: '0.75rem'
        },
        detailsText: {
            fontSize: '10px',
            lineHeight : '1rem',
            letterSpacing: '.001rem'
        },
        subtitle: {
            fontSize: '0.875rem',
            fontWeight: 300,
            marginTop: '0.125rem',
            marginBottom: '0.125rem',
        }
    }
})

const FoundAd = ({
    reload,
}: {
    reload: () => void
}) => {
    const pageLink = `http://www.found.com/1800accountant?utm_medium=growth_partnership&utm_source=1800accountant&utm_campaign=banner`
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()

    return (
        <div className={styles.container}>
            <div className={ isMobileDevice ? '' : (FoundAdImage ? styles.divStyle :  styles.divWithoutImage)}>
                <div className={styles.mainTextContent}>
                    <img src={LogoFound} className={styles.checkImg} alt="cardImage" >
                    </img>
                    <UiText variant='hatchback_125' weight='medium_500' >
                        Your $250 bonus is waiting
                    </UiText>
                    <UiText className={styles.subtitle} >
                        Sign up for Found business banking and unlock a $250 bonus.*
                    </UiText>
                    <Button 
                        color="primary" 
                        variant='contained' 
                        className={styles.buttonStyle}
                        onClick={() => {
                            window.open(pageLink, '_blank');
                        }}
                    >
                        Learn more
                    </Button>
                    <div className={styles.detailsTextMargin}>
                        <UiText className={styles.detailsText} textColor='secondary'>
                            *Bonus program terms apply. Found is a financial technology company, not a bank. Banking services are provided by Piermont Bank, Member FDIC. Standard terms of service apply.
                        </UiText>
                    </div>
                    <div>
                        <Button 
                            startIcon={<Icon icon='clock' strokeColor={colorTheme.blue200}/>} 
                            variant="text"
                            className={styles.dismissBtn}
                            onClick={() => {
                                reload()
                                dismissBlock(FOUND_AD_BLOCK)
                            }}
                            >
                            Dismiss
                        </Button>
                    </div>
                </div>
                { !isMobileDevice && FoundAdImage && <img src={FoundAdImage} className={styles.imageStyle} alt="cardImage"></img>}
            </div>
        </div>
    )
}

export default FoundAd