import React from 'react';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import UiText from '../../common/ui/UiText';
import { toRem16 } from '../../../styles/commonStyles';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface StyleProps {
    borderedContainer: boolean,
    colorTheme: ThemeColors
}

const useStyles = makeStyles<Theme, Pick<StyleProps, 'borderedContainer' | 'colorTheme'>>(()=> {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: toRem16(20),
            borderRadius: (props) => props.borderedContainer ? toRem16(8) : 'none',
            border: (props) => props.borderedContainer ? `1px solid ${props.colorTheme.grey200}` : 'none'
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            height: toRem16(160),
            width: toRem16(160),
        },
        textMargin: {
            marginTop: '1rem'
        },
        textCenter: {
            textAlign: 'center'
        }
    }
});

const EmptyView = ({
    title,
    subtitle,
    image,
    borderedContainer = false
}: {
    image?: any, 
    title?: React.ReactNode | string,
    subtitle?: React.ReactNode | string,
    borderedContainer?: boolean
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles({ borderedContainer, colorTheme})
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    return (
        <div className={styles.container}>   
            <div className={styles.imageContainer}>
                <img src={image} alt="emptyImage" className={styles.image} />
            </div>    
            <div className={styles.textCenter}>
                    <UiText variant={smDevice ? 'car_100' : 'hatchback_125'}>{title}</UiText> 
                <div className={styles.textMargin}>
                    <UiText textColor="secondary" variant={smDevice ? 'motorcycle_90' : 'car_100'}>{subtitle}</UiText>
                </div>
            </div>
        </div>
    )
}

export default EmptyView