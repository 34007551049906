import UiText from '../../../common/ui/UiText'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
    content: {
        '& div:not(:last-child)': {
          marginBottom: '1rem',
      },
    },
}))

const FinCenContent = () => {
  const classes = styles()
    return (
        <div  className={classes.content}>
            <div>
                <UiText>
                    {' '}
                    1-800Accountant shall prepare and submit your Beneficial
                    Ownership Information Report (BOIR) to the Financial Crimes
                    Enforcement Network of the U.S. Department of the Treasury
                    (FinCEN).
                </UiText>
            </div>
            <div>
                <UiText>
                    You hereby represent and warrant that all information you
                    have provided or will provide us is, to the best of your
                    knowledge, true and accurate. However, acknowledge that we
                    shall rely on and assume the accuracy and completeness of
                    the information you provide and shall have no duty to you to
                    verify the accuracy or completeness of such information. We
                    shall adopt policies, procedures, or controls designed to
                    safeguard all such information in accordance with
                    1-800Accountant’s privacy policy.
                </UiText>
            </div>
            <div>
                <UiText>
                    If there is any change to the required information about
                    your company or its beneficial owners in a beneficial
                    ownership information report that is filed, your company
                    must file an updated report no later than 30 days after the
                    date of the change. 1-800Accountant shall have, and by
                    preparing your BOIR shall be deemed to assume, no duty to
                    revise or update such information to reflect subsequent
                    developments subsequent to its original disclosure.
                </UiText>
            </div>
            <div>
                <UiText>
                    You acknowledge that 1-800Accountant may provide you general
                    and publicly available information regarding your BOIR
                    reporting requirements and agree that no such information we
                    provide will constitute legal advice. Notwithstanding
                    anything to the contrary, the management of each entity to
                    which 1-800Accountant provides services will be solely
                    responsible – consulting as such management deems necessary
                    or advisable with qualified attorneys with Corporate
                    Transparency Act expertise – for determining whether such
                    entity is a reporting entity or exempt reporting entity and,
                    if a reporting entity, for interpreting the required content
                    and extent of its filing obligation.
                </UiText>
            </div>

            <div>
                <UiText>
                    You acknowledge that, pursuant to the Corporate Transparency
                    Act, individuals who willfully violate the BOIR requirements
                    are subject to civil monetary penalties and, depending on
                    the circumstances, criminal monetary penalties and
                    imprisonment. Such violations include the intentional
                    failure to submit a beneficial ownership information report,
                    the deliberate submission of false beneficial ownership
                    information, or the deliberate failure to rectify or amend
                    previously submitted beneficial ownership information.
                </UiText>
            </div>
            <div>
                <UiText>
                    Without limiting any other obligation of yours to
                    1-800Accountant, you agree to indemnify and hold harmless
                    1-800Accountant with respect to any claim, damages, cost, or
                    expense arising in relation to your BOIR, including without
                    limitation attorney fees, costs for responding to regulatory
                    or law enforcement inquiries, other expenses relating to
                    investigations, and penalties. You agree to advance
                    1-800Accountant funds necessary to discharge your
                    indemnification duties promptly as each claim, damage, cost,
                    or expense arises. Notwithstanding this paragraph, you shall
                    be under no duty to indemnify 1-800Accountant and entitled
                    to reimburse of any indemnification payments previously
                    advanced to the limited extent any claims, damages, costs,
                    or expenses are adjudicated in final arbitration or court
                    proceeding not subject to challenge or appeal to have arisen
                    as a direct result of 1-800Accountant’s gross negligence or
                    willful malfeasance.
                </UiText>
            </div>
            <div>
                <UiText>
                    By electronically signing this document, the information you
                    have submitted will be submitted under your name and you
                    agree to the foregoing terms.
                </UiText>
            </div>
        </div>
    )
}

export default FinCenContent
