import {
    Action,
    SET_AUTH_DATA,
    SET_USER_ROLES,
    SET_ACCESS_LEVEL,
} from '../actions/auth';

export default function auth(state = {financial_pod: {tax_advisor: { accountant_id: '' }}}, action: Action): any {
    switch (action.type) {
        case SET_AUTH_DATA:
            return { ...state, ...action.state };
        case SET_USER_ROLES:
            return { ...state, ...action.state };
        // case SET_TRIAL_EXPIRY:
        //     return { ...state, ...action.state };
        case SET_ACCESS_LEVEL:
            return { ...state, ...action.state };
        default:
            return state;
    }
}
