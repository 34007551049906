import React from 'react'
import { FieldArray, FormikContextType, useFormikContext } from 'formik'
import { makeStyles, Theme } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { Alert } from '@material-ui/lab'
import Icon from '../../common/Icon'
import AddIcon from '@material-ui/icons/Add'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { OnBoardingFormType } from './models/onboarding.interface'
import UiAlert from '../../common/ui/UiAlert'
import { ONBOARDING_PAGE_INFO } from './constant/business-onboarding.const'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    alertText: {
        width: 'fit-content',
        padding: '1rem 0',
    },
    owner: {
        padding: '1rem',
        border: (colorTheme) => `1px solid ${colorTheme.grey1200}`,
        borderRadius: '0.5rem',
    },
    ownerDetails: {
        display: 'flex',
        marginTop: '0.8rem',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    ownerDetailsMobileView: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '1rem',
        },
    },
    otherOwnerContainer: {
        margin: '1rem 0',
    },
    otherOwners: {
        margin: '1rem 0',
        padding: '1rem',
        border: (colorTheme) => `1px solid ${colorTheme.grey1200}`,
        borderRadius: '0.5rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        color: (colorTheme) => colorTheme.red200,
        cursor: 'pointer',
    },
    iconContainerText: {
        marginLeft: '0.5rem',
    },
    addOwnerActionContainer: {
        display: 'flex',
        color: (colorTheme) => colorTheme.blue200,
        cursor: 'pointer',
        width: 'fit-content',
    },
    textInput: {
        width: '14rem',
    },
    errorPercentage: {
        '& label, & label.Mui-focused': {
            color: (colorTheme) => colorTheme.red200,
        },
        '& div fieldset': {
            color: (colorTheme) => colorTheme.red200,
        },

        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                color: (colorTheme) => colorTheme.red200,
            },
        },
    },
    ownerContainerTitle: {
        color: (colorTheme) => colorTheme.black200,
    },
    ownerPercentageInput: {
        width: '7.5rem',
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
                display: 'none',
            },
    },
}))

const OwnerContainer = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const { currentUser } = useCurrentStore()
    const { errors }: FormikContextType<OnBoardingFormType> = useFormikContext()
    const totalOwnershipError =
        errors?.totalSharesError && classes.errorPercentage

    const validatePercentage = (value: number) => {
        if (value === 100) {
            return 'This should be between 1% and 99%.'
        }
        return ''
    }

    return (
        <div>
            <UiText
                className={classes.ownerContainerTitle}
                variant="hatchback_125"
            >
                Please add each owner, as well as their ownership percentage.
            </UiText>

            <div className={classes.alertText}>
                {errors?.totalSharesError ? (
                    <UiAlert
                        icon={'alert'}
                        severity={'error'}
                        description={ONBOARDING_PAGE_INFO.OWNER_PAGE_ALERT_TEXT}
                    />
                ) : (
                    <Alert severity="info">
                        {ONBOARDING_PAGE_INFO.OWNER_PAGE_ALERT_TEXT}
                    </Alert>
                )}
            </div>

            <div className="ownerList">
                <FieldArray name="business_owners">
                    {({ push, remove, form }) => {
                        const [, ...otherOwner] = form.values.business_owners
                        const count = otherOwner?.length

                        return (
                            <>
                                <div className={classes.owner}>
                                    <UiText weight="semi_bold_600">
                                        Owner 1 (You)
                                    </UiText>
                                    <div className={classes.ownerDetails}>
                                        <UiText>
                                            {currentUser?.first_name}{' '}
                                            {currentUser?.last_name}
                                        </UiText>
                                        <div
                                            className={`${totalOwnershipError} ${classes.ownerPercentageInput}`}
                                        >
                                            <UiFormControlSelection
                                                fieldName={`business_owners[0].ownership`}
                                                label="Ownership %"
                                                validationFunction={
                                                    validatePercentage
                                                }
                                                onBlur={(e) => {
                                                    if (e.target.value === '') {
                                                        form.setFieldValue(
                                                            `business_owners[0].ownership`,
                                                            0
                                                        )
                                                    }
                                                }}
                                                fastField={true}
                                                type="number"
                                                showFloatingLabel={true}
                                                endIcon="%"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {otherOwner?.map(
                                    (owner: any, index: number) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`${classes.otherOwnerContainer} ${classes.owner}`}
                                            >
                                                <div className={classes.header}>
                                                    <UiText weight="semi_bold_600">
                                                        Owner {index + 2}
                                                    </UiText>
                                                    <div
                                                        className={
                                                            classes.iconContainer
                                                        }
                                                        onClick={() => {
                                                            if (count <= 1) {
                                                                form.setFieldValue(
                                                                    `only_owner`,
                                                                    'Yes'
                                                                )
                                                            }
                                                            remove(index)
                                                        }}
                                                    >
                                                        <Icon icon="deleteTrash" />
                                                        <UiText
                                                            className={
                                                                classes.iconContainerText
                                                            }
                                                            variant="moped_75"
                                                            weight="semi_bold_600"
                                                        >
                                                            Delete
                                                        </UiText>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${classes.ownerDetailsMobileView} ${classes.ownerDetails}`}
                                                >
                                                    <div
                                                        className={
                                                            classes.textInput
                                                        }
                                                    >
                                                        <UiFormControlSelection
                                                            required={true}
                                                            label="First Name (Required)"
                                                            type="text"
                                                            fieldName={`business_owners[${
                                                                index + 1
                                                            }].first_name`}
                                                            fastField={true}
                                                            showFloatingLabel={
                                                                true
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.textInput
                                                        }
                                                    >
                                                        <UiFormControlSelection
                                                            required={true}
                                                            fieldName={`business_owners[${
                                                                index + 1
                                                            }].last_name`}
                                                            label="Last Name (Required)"
                                                            fastField={true}
                                                            type="text"
                                                            showFloatingLabel={
                                                                true
                                                            }
                                                        />
                                                    </div>

                                                    <div
                                                        className={`${totalOwnershipError} ${classes.ownerPercentageInput}`}
                                                    >
                                                        <UiFormControlSelection
                                                            fieldName={`business_owners[${
                                                                index + 1
                                                            }].ownership`}
                                                            label="Ownership %"
                                                            validationFunction={
                                                                validatePercentage
                                                            }
                                                            onBlur={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value ===
                                                                    ''
                                                                ) {
                                                                    form.setFieldValue(
                                                                        `business_owners[${
                                                                            index +
                                                                            1
                                                                        }].ownership`,
                                                                        0
                                                                    )
                                                                }
                                                            }}
                                                            type="number"
                                                            fastField={true}
                                                            showFloatingLabel={
                                                                true
                                                            }
                                                            endIcon="%"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                                <div
                                    onClick={() => {
                                        push({
                                            first_name: '',
                                            last_name: '',
                                            ownership: 0,
                                        })
                                    }}
                                    className={classes.addOwnerActionContainer}
                                >
                                    <AddIcon />
                                    <UiText
                                        className={classes.iconContainerText}
                                        weight="semi_bold_600"
                                    >
                                        New Owner
                                    </UiText>
                                </div>
                            </>
                        )
                    }}
                </FieldArray>
            </div>
        </div>
    )
}

export default OwnerContainer
