import * as React from "react";

interface TabPanelHolderProps {
  children?: React.ReactNode;
  index: Number;
  title: string;
  badgeVisible?: boolean;
}

export default function TabPanelHolder(props: TabPanelHolderProps) {
  const { children } = props;

  return <div>{children}</div>;
}
