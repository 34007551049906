import React from 'react';
import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog';
import { makeStyles, Theme } from '@material-ui/core';
import Icon from '../../../../components/common/Icon';
import { Location } from '../../../../models/locations';
import { ContainedDeleteButton } from '../../../common/DeleteButton';
import DiscardChangesModal from './DiscardChangesModal'
import UiText from '../../../common/ui/UiText';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';


interface confirmLocationModalProps {
    showConfirmModal: boolean;
    handleClose: (result?: any) => void;
    location?: Location;
    discardChanges?: boolean;
    deleting?:boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogHeader: {
        marginBottom: theme.spacing(4),
        lineHeight: '1.75rem',
    },
    infoText: {
        marginBottom: theme.spacing(4),
    },
    deleteButton: {
        background: useThemeContext().red200,
    },
    modalRoot: {
        '& .MuiPaper-root': {
            padding: '0rem 1rem',
            '& .MuiDialogContent-root': {
                padding: '1.25rem 0.625rem',
            },
        },
    },
}));

const ConfirmLocationModal = ({
    showConfirmModal,
    handleClose,
    location,
    discardChanges,
    deleting
}: confirmLocationModalProps) => {
    const classes = useStyles();
    const infoText = 'This location will be removed from all associated trips. Affected trips will remain on your account.';
    const headerText = 'Delete Saved Location?';
    const { colorTheme } = useThemeContext()

    return (
        location && !discardChanges
        ? 
            (
                <UiConfirmationDialog
                    showLoader={deleting}
                    customRootClass={classes.modalRoot}
                    open={showConfirmModal}
                    message={
                        <>
                            <UiText
                                variant='hatchback_125' weight='bold_700'
                                className={classes.dialogHeader}
                            >
                                {headerText}
                            </UiText>
                            <UiText
                                className={classes.infoText}
                            >
                                {infoText}
                            </UiText>
                            <UiText>
                                This cannot be undone.
                            </UiText>
                        </>
                    }
                    handleClose={handleClose}
                    confirmNode={
                        <ContainedDeleteButton
                            className={classes.deleteButton}
                            variant="contained"
                            startIcon={<Icon icon="delete"  svgColor={colorTheme.primaryWhite} />}
                            onClick={() => {
                                handleClose(true);
                            }}
                            color="secondary"
                            showDeleteIcon
                            id={`delete-confirm-button`}
                        >
                            Delete
                        </ContainedDeleteButton>
                    }
                    cancelButtonText="Cancel"
                />
            )
        : 
            (
                <DiscardChangesModal 
                    open={showConfirmModal}
                    handleClose={handleClose}
                />
            )
       
    );
};

export default ConfirmLocationModal;
