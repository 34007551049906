import store from '../../store'
import {
    getResource,
    patchResource,
    deleteResource,
    postResource,
} from '../axiosService'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    const contactBaseURL = `api/account/${accountId}/business/${businessId}/contact`
    const allContactURL = `api/account/${accountId}/business/${businessId}/contacts`
    return {
        contactBaseURL,
        allContactURL
    }
}

export function getAllContacts() {
    const { allContactURL } = getStoreData()
    return getResource(
        allContactURL,
        undefined,
        {}
    )
}

export function getContacts(
    params: any
) {
    const { contactBaseURL } = getStoreData()
    return getResource(
        contactBaseURL,
        undefined,
        params
    )
}

export function contactDetail(
    contactId: string
) {
    const { contactBaseURL } = getStoreData()
    return getResource(
        `${contactBaseURL}/${contactId}`
    )
}

export function createContact(
    params: any
) {
    const { contactBaseURL } = getStoreData()
    return postResource(
        `${contactBaseURL}`,
        params
    )
}

export function updateContact(
    contactId: string,
    params: any
) {
    const { contactBaseURL } = getStoreData()
    return patchResource(
        `${contactBaseURL}/${contactId}`,
        params
    )
}

export function deleteContact(
    contactId: string
) {
    const { contactBaseURL } = getStoreData()
    return deleteResource(
        `${contactBaseURL}/${contactId}`
    )
}