import {
    Button,
    Chip,
    createStyles,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IBankAccount } from '../../../models/bank-account-models';
import {
    BankAccounts,
    getBankAccounts,
} from '../../../services/bankAccountService';
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil';
import InfoTooltip from '../../common/InfoTooltip';
import UiChip from '../../common/ui/UiChip';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiText from '../../common/ui/UiText';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    findSpaceRegex,
    ALLOWED_BANK_TYPES,
    DATE_FORMATS,
    DATE_TYPES,
    EMPTY_PARAMS,
} from './constants/reports.const';
import { useReportsStyles } from './styles/reports-styles';
import * as Yup from 'yup';
import { getReconciliationReports } from '../../../services/apiService/reports';
import Loader from '../../common/Loader';
import { showError } from '../../../services/formService';
import {
    ApiError,
    ErrorAlertReport,
    ReconciliationResponse,
} from './models/reports-interface';
import store from '../../../store';
import { reportsDataActions } from '../../../store/actions/reportsData';
import { useHistory } from 'react-router-dom';
import { setBankAccDetails } from '../../../store/actions/bankAccountsData';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { FormObserver } from './utils/Form/FormObserver';
import { ReactComponent as SearchIconImage } from '../../../assets/icons-svg/SearchIcon.svg';
import SelectCompareDataModal from './utils/modal/SelectCompareDataModal';
import { ReactComponent as CalendarIcon } from '../../../assets/icons-svg/Calendar.svg';
import UiButton from '../../common/ui/UiButton';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface FormValues {
    fromDate: number;
    toDate: number;
    accounts: string[];
}
type SelectedDatePeriod =
    | DATE_TYPES.THIS_MONTH
    | DATE_TYPES.THREE_MONTHS
    | DATE_TYPES.THIS_YEAR
    | DATE_TYPES.ALL_TIME;

const SELECT_ACCOUNT_HEADER = 'Select Accounts';
const TOOLTIP_TEXT =
    'The Reconciliations Report shows all reconciliations for the specified accounts during the selected period of time.';
const initialFormValues = {
    accounts: [],
    fromDate: moment.utc().hour(12).minute(0).second(0).unix(),
    toDate: moment.utc().hour(12).minute(0).second(0).unix(),
};
const useLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        reconciliationHeader: {
            margin: '2rem 0 1rem 0',
        },
        selectPeriodHeader: {
            margin: '0 0 1rem 0',
        },
        selectAccount: {
            margin: '2rem 0',
            '& p.MuiTypography-root': {
                margin: '0 0 0.8rem 0',
            },
        },
    })
);
const IS_GET_DATE_TILL_TODAY = false;

export default function Reconciliations() {
    const [isLoading, setIsLoading] = useState(false);
    const [allowedBanks, setAllowedBanks] = useState<IBankAccount[]>();
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [showFromAndToDates, setShowFromAndToDates] = useState<boolean>(true);
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const storeData = useCurrentStore();
    const businessName = storeData.currentBusiness?.name;
    const localClasses = useLocalStyles();
    const history = useHistory();
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const [isSelectAccountsModalOpen, setIsSelectAccountsModalOpen] =
        useState<boolean>(false);
    const [checkedAccount, setCheckedAccount] = useState<IBankAccount[]>([]);
    const datePeriods = [
        DATE_TYPES.THIS_MONTH,
        DATE_TYPES.THREE_MONTHS,
        DATE_TYPES.THIS_YEAR,
        DATE_TYPES.ALL_TIME,
    ];

    useEffect(() => {
        const filterAllowedBanks = (allBanks: BankAccounts) => {
            const allowedTypes: string[] = [
                ALLOWED_BANK_TYPES.MANUAL,
                ALLOWED_BANK_TYPES.AGGREGATED,
            ];
            return allBanks.list.filter((bank) => {
                return allowedTypes.includes(bank.type); //Remove bank types that are not manual or aggregated
            });
        };
        const setAllowedBanksData = (allBanks: BankAccounts) => {
            const allowedBanks = filterAllowedBanks(allBanks);
            setAllowedBanks(allowedBanks);
        };
        getBankAccounts()
            .then((res: unknown) => {
                setIsLoading(false);
                const data = res as BankAccounts;
                setAllowedBanksData(data);
                dispatchBankAccountsToStore(data.list);
            })
            .catch((err) => {
                setIsLoading(false);
                showError(err?.statusText);
            });
        setIsLoading(true);
    }, []);

    useEffect(() => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            IS_GET_DATE_TILL_TODAY
        );
        setinitialValues((prev) => ({
            ...prev,
            fromDate: startDate ?? 0,
            toDate: endDate ?? 0,
        }));
    }, []);

    const hideShowFromAndToDates = (selectedPeriod: SelectedDatePeriod) => {
        if (selectedPeriod === DATE_TYPES.ALL_TIME) {
            setShowFromAndToDates(false);
            return;
        }
        setShowFromAndToDates(true);
    };
    const getFormattedTime = (dateType: SelectedDatePeriod) => {
        return getTimeParamsWithoutEST(dateType);
    };
    const getDatesAndChangeFromToValues = (
        selectedPeriod: SelectedDatePeriod
    ) => {
        const formattedDate = getFormattedTime(selectedPeriod);
        setinitialValues((prev) => ({
            ...prev,
            fromDate: formattedDate?.startDate ?? 0,
            toDate: formattedDate?.endDate ?? 0,
        }));
    };

    const updateSelectedPeriodTime = (selectedPeriod: SelectedDatePeriod) => {
        setSelectedDateChip(selectedPeriod);
        hideShowFromAndToDates(selectedPeriod);
        getDatesAndChangeFromToValues(selectedPeriod);
    };
    const createTimePeriodChips = () => {
        return datePeriods.map((period: string, index: number) => {
            return (
                <UiChip
                    label={<Typography variant="body2">{period}</Typography>}
                    onClickCallout={() => {
                        updateSelectedPeriodTime(period as SelectedDatePeriod);
                    }}
                    customRootStyles={
                        selectedDateChip === period
                            ? 'selectedChip'
                            : 'customChip'
                    }
                    key={index}
                    cypressId={`${period
                        .toLocaleLowerCase()
                        .replace(' ', '-')}-chip-btn`}
                />
            );
        });
    };
    const getFormattedDate = (dateInSecs: number, periodTextFormat: string) => {
        const dateInMs = dateInSecs * 1000;
        const date = convertUnixToGivenDateFormat(periodTextFormat, dateInMs);
        return date;
    };
    const getPeriodTextWithFromAndToDate = (
        from: number,
        to: number
    ): string => {
        const periodTextFormat = DATE_FORMATS.periodText;
        const fromDate = getFormattedDate(from, periodTextFormat);
        const toDate = getFormattedDate(to, periodTextFormat);
        const periodText = `for the period from ${fromDate} to ${toDate}`;
        return periodText.replace(findSpaceRegex, '+');
    };
    const getPeriodText = (fromDate: number, toDate: number): string => {
        const periodTextAllTime = DATE_TYPES.ALL_TIME;
        const periodText =
            !fromDate || !toDate
                ? periodTextAllTime.replace(findSpaceRegex, '+')
                : getPeriodTextWithFromAndToDate(fromDate, toDate);
        return periodText;
    };
    const fetchAPIQueryParams = (fromDate: number, toDate: number) => {
        const accountId = storeData.currentAccountId;
        const businessId = storeData.currentBusinessId!;
        const periodText = getPeriodText(fromDate, toDate);
        const time = getCurrentTimeStamp();
        const startDateYmd = !fromDate ? null : getDateInYmd(fromDate);
        const endDateYmd = !toDate ? null : getDateInYmd(toDate);
        return {
            accountId,
            businessId,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        };
    };
    const dispatchReportsDataToStore = (data: ReconciliationResponse) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };
    const dispatchBankAccountsToStore = (data: IBankAccount[]) => {
        store.dispatch({
            type: setBankAccDetails.SET_BANK_ACC_DETAILS,
            state: data,
        });
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };
    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };
    const getReportsDataAndNavigate = (
        fromDate: number,
        toDate: number,
        bankAccountIds: string
    ) => {
        const {
            accountId,
            businessId,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        } = fetchAPIQueryParams(fromDate, toDate);
        setIsLoading(true);
        getReconciliationReports(
            accountId,
            businessId,
            bankAccountIds,
            periodText,
            time,
            startDateYmd,
            endDateYmd
        )
            .then((res: unknown) => {
                setIsLoading(false);
                const data = res as ReconciliationResponse;
                dispatchReportsDataToStore(data);
                navigateToDetailedReconciliationReport(
                    bankAccountIds,
                    periodText,
                    time,
                    startDateYmd,
                    endDateYmd
                );
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };
    const navigateToDetailedReconciliationReport = (
        bankAccountIds: string,
        periodText: string,
        time: number,
        startDate: string | null,
        endDate: string | null
    ) => {
        const accountIds = bankAccountIds || EMPTY_PARAMS.BANKS_ACC_IDS;
        const startDateYmd = startDate || EMPTY_PARAMS.START_DATE_YMD;
        const endDateYmd = endDate || EMPTY_PARAMS.END_DATE_YMD;
        const detailedReportUrl = `/reports/reconciliations/${accountIds}/${periodText}/${time}/${startDateYmd}/${endDateYmd}`;
        history.push(detailedReportUrl);
    };

    const getFormattedAccountIds = () => {
        let accountIds: string[] = [];
        checkedAccount.map((account: IBankAccount) => {
            return accountIds.push(account.account);
        });

        return accountIds.join(',');
    };
    const onSubmit = (values: FormValues) => {
        const { fromDate, toDate } = values;
        const bankAccountIds = getFormattedAccountIds();
        getReportsDataAndNavigate(fromDate, toDate, bankAccountIds);
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        const fromDateChanged = formikObject.touched?.fromDate;
        const toDateChanged = formikObject.touched?.toDate;
        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };
    const openAccountsSelectModal = () => {
        setIsSelectAccountsModalOpen(true);
    };
    const handleCloseAccountsModal = () => {
        setIsSelectAccountsModalOpen(false);
    };
    const getCurrentIndex = (
        checkedAccount: IBankAccount[],
        account: IBankAccount
    ): number => {
        const currentIndex = checkedAccount.indexOf(
            checkedAccount.filter((item: any) => item.id === account.id)[0]
        );
        return currentIndex;
    };
    const addRemoveItemsBasedOnIndex = (
        currentIndex: number,
        newChecked: IBankAccount[],
        account: IBankAccount
    ) => {
        if (currentIndex === -1) {
            newChecked.push(account);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        return newChecked;
    };
    const handleToggleAccount = (value: unknown) => {
        const account = value as IBankAccount;
        const currentIndex = getCurrentIndex(checkedAccount, account);
        const newChechecked = [...checkedAccount];
        const newAccountsArray = addRemoveItemsBasedOnIndex(
            currentIndex,
            newChechecked,
            account
        );
        setCheckedAccount(newAccountsArray);
    };
    const deleteAccountChip = (id: string) => {
        const accounts = checkedAccount.filter((account: IBankAccount) => {
            return account.id !== id;
        });
        setCheckedAccount(accounts);
    };
    const isShowClearAllButton = () : boolean =>{
        return checkedAccount.length > 1;
    }
    const clearAllAccounts = (event: any) =>{
        event.preventDefault();
        setCheckedAccount([]);
    }
    
    return (
        <div className={reportsClasses.reportsViewParent}>
            <span className="businessName">{businessName}</span>

            <UiText
                variant="suv_150"
                weight="semi_bold_600"
                className={reportsClasses.pageTitle}
            >
                Reconciliations
                <InfoTooltip tooltipText={TOOLTIP_TEXT} />
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}

            <>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {(values) => {
                        return (
                            <Form>
                                <FormObserver
                                    onformChange={onformChangeCallback}
                                />
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {allowedBanks && (
                                            <div
                                                className={
                                                    localClasses.selectAccount
                                                }
                                            >
                                                <UiText weight="medium_500">
                                                    Account{' '}
                                                </UiText>
                                                <div
                                                    className={
                                                        reportsClasses.multiSelectInput
                                                    }
                                                >
                                                         {
                                                            isShowClearAllButton() &&  <UiButton
                                                            handleClick={clearAllAccounts}
                                                            label='Clear All'
                                                            btnType='primary'
                                                            customClass={reportsClasses.clearAllSegmentsButton}
                                                        />
                                                        }
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Select Account"
                                                        variant="outlined"
                                                        size="small"
                                                        data-cy='select-account-input'
                                                        onClick={
                                                            openAccountsSelectModal
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    {
                                                                        <SearchIconImage />
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment:
                                                                checkedAccount.map(
                                                                    (
                                                                        account
                                                                    ) => (
                                                                        <Chip
                                                                            key={
                                                                                account.id
                                                                            }
                                                                            tabIndex={
                                                                                -1
                                                                            }
                                                                            label={
                                                                                account.name
                                                                            }
                                                                            onDelete={() => {
                                                                                deleteAccountChip(
                                                                                    account.id
                                                                                );
                                                                            }}
                                                                        />
                                                                    )
                                                                ),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {showFromAndToDates && (
                                            <>
                                                <UiText
                                                    weight="medium_500"
                                                    typographyVariant="h4"
                                                    className={
                                                        localClasses.selectPeriodHeader
                                                    }
                                                >
                                                    Select a period of time
                                                </UiText>
                                                <div
                                                    className={`${reportsClasses.dateSelectors} calendarSection`}
                                                >
                                                    <UiFormControlSelection
                                                        label="From"
                                                        type="date"
                                                        fieldName="fromDate"
                                                        dateType="string"
                                                        showFloatingLabel={true}
                                                        disableFutureDate={false}
                                                        endIcon={
                                                            <CalendarIcon />
                                                        }
                                                    />
                                                    <div className="dateSeparator">
                                                        -
                                                    </div>
                                                    <UiFormControlSelection
                                                        label="To"
                                                        type="date"
                                                        fieldName="toDate"
                                                        dateType="string"
                                                        showFloatingLabel={true}
                                                        disableFutureDate={false}
                                                        endIcon={
                                                            <CalendarIcon />
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div
                                            className={
                                                reportsClasses.timePeriodChips
                                            }
                                        >
                                            {createTimePeriodChips()}
                                        </div>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            data-cy='generate-report-btn'
                                        >
                                            Generate Report
                                        </Button>
                                    </>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
                {allowedBanks && (
                    <SelectCompareDataModal
                        listData={allowedBanks}
                        checkedData={checkedAccount}
                        handleToggle={handleToggleAccount}
                        isOpen={isSelectAccountsModalOpen}
                        handleClose={handleCloseAccountsModal}
                        title={SELECT_ACCOUNT_HEADER}
                        idKey="id"
                        valueKey="name"
                    />
                )}
            </>
        </div>
    );
}
