import UiText from '../../../common/ui/UiText';
import { Box } from '@material-ui/core';
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection';
import { commonStyles } from '../../../../styles/commonStyles';
const OwnershipForm = () => {
    const styles = commonStyles();
    return (
        <div className={styles.mt24}>
            <UiText className={styles.mt24}>
                S-Corporations offer potential tax savings for business
                owners.
            </UiText>

            <Box my={2}>
                <UiText>
                    Does the employee own more than 2% of the business?
                    (Required)
                </UiText>
            </Box>
            <UiFormControlSelection
                type="radio"
                fieldName="two_percent_shareholder"
                radioOptionsData={[
                    {
                        label: 'Yes',
                        value: 'yes',
                    },
                    {
                        label: 'No',
                        value: 'no',
                    },
                ]}
            />
        </div>
    );
};

export default OwnershipForm;
