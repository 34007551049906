import { Button, withStyles, Theme, ButtonProps } from '@material-ui/core';
import { COLORS } from '../../variables/colors';
import Icon from '../../components/common/Icon';

interface OutlinedButtonProps extends ButtonProps {
    showDeleteIcon?: boolean; 
    children: any
}

interface ContainedButtonProps extends ButtonProps {
    showDeleteIcon?: boolean; 
    children: any
}

export const OutlinedDeleteButton = withStyles({
    root: {
        color: `${COLORS.red200} !important`,
    },
    outlinedSecondary: {
        'border-color': `${COLORS.red200} !important`,
    },
})((props: OutlinedButtonProps)=>{
    const { children, showDeleteIcon, ...other } = props;
    return (
        <Button 
            {...(showDeleteIcon
                ? {
                    startIcon: <Icon icon="delete"  svgColor={COLORS.red200} />,
                }
                : {})}
            {...other}>
            {children}
        </Button>
    )
});

export const ContainedDeleteButton = withStyles((theme: Theme) => ({
    root: {
        color: COLORS.primaryWhite,
        background: COLORS.red200,
        '&:hover': {
            color: COLORS.primaryWhite,
            background: COLORS.red200,
        },
    },
}))((props: ContainedButtonProps) => {
    const { children, showDeleteIcon, ...other } = props;
    return (
        <Button
            {...(showDeleteIcon
                ? {
                    startIcon: <Icon icon="delete" svgColor={COLORS.primaryWhite} />,
                  }
                : {})}
            {...other}
        >
            {children}
        </Button>
    );
});

