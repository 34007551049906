import {
    Button,
    Divider,
    Grid,
    Hidden,
    makeStyles,
    Theme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import Icon from '../../common/Icon'
import { COLORS } from '../../../variables/colors'
import SearchBox from '../../common/SearchBox'
import ContractorDetails from './ContractorDetails/ContractorDetails'
import { AddContractorModal } from '../AddContractorModal'
import { Contractor } from '../models/contractor.model'
import ContractorList from './ContractorList'
import { InformationBlock } from '../InformationBlock'
import { useContractorContext } from '../ContractorProvider'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        fullHeight: {
            height: '100%',
        },
        container: {
            height: '100%',
            width: '100%',
            marginTop: '1rem',
            "& .MuiButton-containedPrimary": {
                backgroundColor: (colorTheme) => colorTheme.primary,
            },
        },
        flex: {
            display: 'flex',
        },
        buttonSpacing: {
            marginLeft: '1rem',
        },
        searchBoxStyle: {
            marginTop: '1rem',
        },
        emptySelectView: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
    }
})

const KEY = 'contractorInfo'
const VALUE = 'dismissed'
const SearchDelay = 400

const ManageContractor = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    let { path } = useRouteMatch()
    const history = useHistory()
    const [selectedContractor, setSelectedContractor] = useState<Contractor>()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isDismissed, setIsDismissed] = useState<string | null>(null)
    const {
        loadContractors,
        contractorsData,
        searchContractors,
        loadMore,
        searchTerm,
        setSearchTerm,
        setContractorsData,
    } = useContractorContext()

    const { loading } = contractorsData

    useEffect(() => {
        setActiveRouteHeading('Manage Contractor')
        setIsDismissed(localStorage.getItem(KEY))
    }, [setActiveRouteHeading])

    useEffect(() => {
        setContractorsData((prev: any) => ({
            ...prev,
            loading: true,
        }))
        const timer = setTimeout(() => {
            const searchParams = {
                search: searchTerm,
            }
            loadContractors(searchTerm ? { ...searchParams } : {})
        }, SearchDelay)

        return () => {
            clearTimeout(timer)
        }
    }, [loadContractors, searchTerm, setContractorsData])

    const handleDismiss = () => {
        setIsDismissed(VALUE)
        localStorage.setItem(KEY, VALUE)
    }

    return (
        <Grid container direction="row" className={classes.fullHeight}>
            <Grid container md={5} sm={12} justify="center">
                <Grid container item direction="column" alignItems="flex-start">
                    <div className={classes.container}>
                        {!isDismissed && (
                            <InformationBlock
                                showButton
                                handleDismiss={handleDismiss}
                            />
                        )}
                        <div className={classes.flex}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => setIsOpen(true)}
                            >
                                Add a Contractor
                            </Button>
                            <Button
                                className={classes.buttonSpacing}
                                variant="outlined"
                                color="secondary"
                                startIcon={
                                    <Icon
                                        icon="upload"
                                        svgColor={COLORS.grey400}
                                    />
                                }
                                onClick={() =>
                                    history.push(
                                        '/contractors/file-1099-nec?import=true'
                                    )
                                }
                            >
                                Import Contractors
                            </Button>
                        </div>
                        <div className={classes.searchBoxStyle}>
                            <SearchBox
                                searchTerm={searchTerm}
                                placeHolder={'Search'}
                                setInput={setSearchTerm}
                                clearSearch={() => {
                                    setSearchTerm('')
                                }}
                                onKeyPressEvent={(event) => {
                                    if (event.key === 'Enter') {
                                        setSearchTerm(searchTerm)
                                    }
                                }}
                            />
                        </div>
                        {loading ? (
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                className={classes.fullHeight}
                            >
                                <Loader />
                            </Grid>
                        ) : (
                            <ContractorList
                                contractorsData={contractorsData}
                                hasMoreItems={contractorsData?.hasMore}
                                pageLoading={contractorsData?.loading}
                                loadItems={loadMore}
                                selectedContractor={selectedContractor}
                                setSelectedContractor={setSelectedContractor}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <Hidden smDown implementation="js">
                <Grid
                    sm={1}
                    md={1}
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Divider orientation="vertical" flexItem />
                </Grid>
                <Grid item sm={6}>
                    <Switch>
                        <Route exact path={path}>
                            {!selectedContractor ? (
                                <div className={classes.emptySelectView}>
                                    <UiText>
                                        Select a contractor to see the details
                                    </UiText>
                                </div>
                            ) : (
                                <ContractorDetails
                                    selectedContractor={selectedContractor}
                                    reloadState={() => {
                                        loadContractors()
                                    }}
                                    setSelectedContractor={
                                        setSelectedContractor
                                    }
                                />
                            )}
                        </Route>
                    </Switch>
                </Grid>
            </Hidden>
            <AddContractorModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                reloadData={searchContractors}
            />
        </Grid>
    )
}

export default ManageContractor
