import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { BankAccounts, getBankAccounts } from '../../../../../services/bankAccountService';
import { toRem16 } from '../../../../../styles/commonStyles';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';
import UiText from '../../../../common/ui/UiText';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';

const IS_EXCLUDED_ACCOUNTS = false;
const UNCATEGORIZED_TRANSACTIONS_QUERY_PARAM = '&search=type%253Duncategorized';
const TRANSACTIONS_ROUTE_URL = '#/transactions?account=';

const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        warningAlert: {
            background: (colorTheme) => colorTheme.beige100,
            padding: toRem16(24),
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 0 1.8rem 0',
            '& .actions': {
                display: 'flex',
                '& a': {
                    margin: '0 1.5rem 0 0',
                },
            },
            '& a': {
                color: (colorTheme) => colorTheme.black100,
            },
            '& p': {
                cursor: 'pointer',
            },
        },
    })
);
interface TransactionProps {
    transactionsCount: number;
}
export default function UncategorizedTransactionsAlert(
    props: TransactionProps
) {
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme);
    const transactionsCount = props.transactionsCount;
    const [showWarning, setShowWarning] = useState<boolean>(true);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [transactionsUrl, setTransactionsUrl] =  useState('');

    useEffect(() =>{
        const generateAccountIdQueryParam = (data: BankAccounts) =>{
            let url = TRANSACTIONS_ROUTE_URL;
            const accountsList = data.list;
            const firstAccount = accountsList[0].id;
            if(!firstAccount){
                return;
            }
            url = TRANSACTIONS_ROUTE_URL+  firstAccount + UNCATEGORIZED_TRANSACTIONS_QUERY_PARAM;
            setTransactionsUrl(url);
        }
        getBankAccounts(IS_EXCLUDED_ACCOUNTS).then((data : unknown) => {
            const bankAccounts = data as BankAccounts;
            setIsLoadingData(false);
            generateAccountIdQueryParam(bankAccounts);
        }).catch(() =>{
            setIsLoadingData(false);
        })
    },[])
    return (
        <>
            {showWarning && !isLoadingData && (
                <div className={classes.warningAlert}>
                    <UiText weight='regular_400' variant='motorcycle_90'>
                        There are currently {transactionsCount} uncategorized
                        transactions that can affect this report.
                    </UiText>
                    <div className='actions'>
                        <UiText weight='semi_bold_600' variant='motorcycle_90'>
                            <a href={transactionsUrl}> View Uncategorized </a>
                        </UiText>
                        <div
                            onClick={() => {
                                setShowWarning(false);
                            }}
                        >
                            <UiText
                                weight='semi_bold_600'
                                variant='motorcycle_90'
                            >
                                Dismiss
                            </UiText>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
