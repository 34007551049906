import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { toRem16 } from '../../../../styles/commonStyles'
import { isEmpty, numberFormatter } from '../../../../utils/appUtil'
import UiText from '../../../common/ui/UiText'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        container: {
            margin: `${toRem16(40)} 0 ${toRem16(14)} 0`,
        },
        labelAndValue: {
            '& .label': {
                color: (colorTheme) => colorTheme.grey400,
            },
            padding: `${toRem16(15)} 0`,
            borderBottom: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey300}`,
        },
        readingsList: {
            padding: 0,
            '& li': {
                listStyleType: 'none',
            },
        },
        header: {
            borderBottom: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey300}`,
            padding: '0 0 0.5rem 0'
        }
    })
)
export const OdometerReadings = (props : { odometerReadings: [string, number][] | undefined }) => {
    const  { odometerReadings }  = props;
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)

    const getOdometerReading = (reading : string) =>{
        return numberFormatter(parseFloat(reading))
    }
    if (!odometerReadings || isEmpty(odometerReadings)) {
        return null
    }
    return (
        <div className={classes.container}>
             <UiText variant="hatchback_125" weight="semi_bold_600" className={classes.header}>Odometer Readings:</UiText>
            <ul className={classes.readingsList}>
                {odometerReadings.map(([year, reading]) => (
                    <li key={year} className={classes.labelAndValue}>
                        <UiText variant="motorcycle_90" weight="regular_400" className="label">
                            {year}
                        </UiText>
                        <UiText variant="car_100" weight="regular_400">
                            {getOdometerReading(reading as unknown as string)} Miles
                        </UiText>
                    </li>
                ))}
            </ul>
        </div>
    )
}
