import React, { useRef } from 'react';
import moment from 'moment';
import { makeStyles, Button, Theme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import UiText from '../../../../common/ui/UiText';
import UiDialog from '../../../../common/ui/UiDialog';
import UiFormControlSelection from '../../../../common/ui/UiFormControlSelection';
import { ReactComponent as PDF } from '../../../../../assets/icons-svg/PDF.svg';
import { ReactComponent as Csv } from '../../../../../assets/icons-svg/Csv.svg';
import { DOWNLOAD_REPORT_TYPES } from '../../models/reports-interface';
import { DownloadModalFormData } from '../../models/reports-interface';
import Loader from '../../../../common/Loader';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    handleFormSubmit: (data: DownloadModalFormData, fileType: string) => void;
    businessName: string;
    fileType: DOWNLOAD_REPORT_TYPES;
    isLoading: boolean;
    reportName: string;
}
const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    cancelButton: {
        marginRight: theme.spacing(2),
        color: (colorTheme) => colorTheme.black100,
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(2, 0),
        },
    },
    flexView: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.625rem',
    },
    textfield: {
        marginBottom: '3.75rem',
    },
    fineName: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.625rem',
        margin: '1.875rem 0 1.875rem 0',
        color: (colorTheme) => colorTheme.black100,
    },
}));

export default function SaveDocumentsOnPortalModal({
    isOpen,
    handleClose,
    handleFormSubmit,
    businessName,
    fileType,
    isLoading,
    reportName
}: Props) {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme);
    const formRef: any = useRef(null);
    const handleSubmit = (values: DownloadModalFormData) => {
        handleFormSubmit(values, fileType);
    };
    const today = moment().format('MM-DD-YYYY');
    const addCapitalLetter = (reportName: string) => {
        return reportName.charAt(0).toUpperCase() + reportName.slice(1);
    }
    return (
        <UiDialog
            open={isOpen}
            handleClose={handleClose}
            title='Save to Portal'
            size='sm'
            actions={
                isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                formRef.current?.handleSubmit()
                            }}
                            color="primary"
                        >
                            Save to Portal
                        </Button>
                    </div>
                )
            }
        >
            <Formik
                innerRef={formRef}
                onSubmit={handleSubmit}
                initialValues={{ comment: '' }}
            >
                <Form>
                    <div className={classes.fineName}>
                        {fileType === DOWNLOAD_REPORT_TYPES.PDF ? (
                            <PDF />
                        ) : (
                            <Csv />
                        )}
                        <UiText>{`${businessName.replace(
                            ' ',
                            ''
                        )}-${addCapitalLetter(reportName)}Report-${today}.${fileType}`}</UiText>
                    </div>
                    <div className={classes.flexView}>
                        <UiText weight='medium_500'>Add a comment</UiText>
                        <UiText>(Optional)</UiText>
                    </div>
                    <div className={classes.textfield}>
                        <UiFormControlSelection
                            type='textarea'
                            placeholder='Write a comment'
                            fieldName='comment'
                        />
                    </div>
                </Form>
            </Formik>
        </UiDialog>
    );
}
