import {Action} from '../actions/bankAccountsData';
const initialState : any ={
    bankAccountsDetails: {}
}
export default function bankAccounts(state = initialState, action: Action){
    switch(action.type) {
        case 'SET_BANK_ACC_DETAILS':
            return {
                ...state,
                bankAccountsDetails: action.state
            }
            default: 
            return state;
    }
}