import { useEffect, useState } from 'react'
import { GREETING_MESSAGES } from '../../constants/tax-prep.const'

export const useGreeting = (): string => {
    const [greetingMessage, setGreetingMessage] = useState<string>('')
    useEffect(() => {
        const date = new Date()
        const time = date.getHours()
        if (time >= 2 && time < 12) {
            setGreetingMessage(GREETING_MESSAGES.MORNING)
            return
        }
        if (time >= 12 && time < 18) {
            setGreetingMessage(GREETING_MESSAGES.AFTERNOON)
            return
        }
        setGreetingMessage(GREETING_MESSAGES.EVENING)
    }, [setGreetingMessage])
    return greetingMessage
}
