import React, { useState } from 'react'
import InfoCard from './InfoCard'
import UiText from '../../common/ui/UiText'
import MerchantProcessing from '../../../assets/images/MerchantProcessing.jpg'
import MerchantProcessingForm from './MerchantProcessingForm'
import { Header } from './CommonComponents'

import { updateDismissPopup } from '../../../services/apiService/dashboard';
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { MERCHANT_ACCOUNT_SETUP, TODO_MERCHANT_SETUP } from '../TodoSection/Todo.const'
import { showError } from '../../../services/formService'

interface MerchantProcessingProps {
    primaryText: string
    secondaryText: string
    completeButtonText: string
    inCompleteButtonText?: string
    accountProgress: any
    fetchAccountProgress: () => void
    dismissPopupInfo: any
    borderView?: boolean
    dismissBlock?: () => void
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const MerchantProcessingCard = ({
    primaryText,
    secondaryText,
    completeButtonText,
    inCompleteButtonText,
    accountProgress,
    fetchAccountProgress,
    dismissPopupInfo,
    borderView = false,
    dismissBlock,
    setLoading
}: MerchantProcessingProps) => {
    const [openModal, setOpenModal] = useState(false)
    const { currentAccountId } = useCurrentStore()

    const merchantSetupCompleted = accountProgress?.[MERCHANT_ACCOUNT_SETUP]
    const merchantPopupDismissed = dismissPopupInfo?.includes(TODO_MERCHANT_SETUP)

    const dismissMerchantSetup = () => {
        setLoading?.(true)
        let data = {
            pop_up: TODO_MERCHANT_SETUP
        }
        updateDismissPopup(currentAccountId as string, data)
            .then((res) => {
                if(res) {
                    fetchAccountProgress()
                }
            })
            .catch((error) => {
                showError(error)
            })
    }

    return (
        <>
            {!borderView && (merchantSetupCompleted || merchantPopupDismissed) ? (
                <Header 
                    text={merchantPopupDismissed ? 
                        'Setup Merchant Processing' : 
                        'Merchant Account Setup Completed'} 
                />
            ) : (
                <div>
                    <MerchantProcessingForm
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />
                    <InfoCard
                        customTitle={
                            <UiText variant="hatchback_125" weight="medium_500">
                                {primaryText}
                            </UiText>
                        }
                        subtitle={
                            <UiText variant="motorcycle_90">
                                {secondaryText}
                            </UiText>
                        }
                        buttonText={completeButtonText}
                        buttonClick={() => {
                            setOpenModal(true)
                        }}
                        cardImage={MerchantProcessing}
                        onDismissClick={() => {
                            dismissBlock ? dismissBlock?.() : dismissMerchantSetup()
                        }}
                        borderView={borderView}
                    />
                </div>
            )}
        </>
    )
}

export default MerchantProcessingCard
