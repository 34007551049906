import { OnBoardingFormType } from '../../components/dashboard/business-onboarding/models/onboarding.interface'
import { Business } from '../../models'
import {
    getResource,
    patchResource,
    postMultipartResource,
    postResource,
} from '../axiosService'

export function getBusinessDetails(
    accountId: number | string,
    businessId: number | string
) {
    const api = `api/account/${accountId}/business/${businessId}`
    return getResource<Business>(api)
}

export function createBusiness(
    data: Business | OnBoardingFormType,
    accountId: number | string
) {
    const api = `api/account/${accountId}/business`
    return postResource(api, data)
}

export function createBusinessAccount(data: Business) {
    const api = `api/business/create`
    return postResource(api, data)
}

export function saveBusinessDetails(
    data: Business,
    accountId: number | string,
    businessId: number | string
) {
    const api = `api/account/${accountId}/business/${businessId}`
    return patchResource(api, data)
}

export function uploadBusinessLogo(
    file: any,
    accountId: number | string
): Promise<any> {
    const api = `api/account/${accountId}/business_logo/upload`
    const formData = new FormData()
    formData.append('logo', file)
    return postMultipartResource(api, formData, undefined, true)
}

export function previewUrl(
    accountId: number | string,
    fileId?: string,
    type?: string,
    width?: string
): Promise<any> {
    if (type === 'logo') {
        return getResource<any>(
            `api/account/${accountId}/business_logo/${fileId}/view`,
            undefined,
            null,
            true,
            'arraybuffer'
        )
    } else {
        return getResource<any>(
            `api/account/${accountId}/file_center/file/${fileId}/view`
        )
    }
}

export function saveMerchantData(
    accountId: string,
    businessId: string,
    data: any
) {
    const api = `api/account/${accountId}/business/${businessId}/payrix/merchant/register`
    return postResource(api, data)
}

export function fetchPaymentMethodUpdaterUrl(sfAccountId: string) {
    const api = `api/sales/payment_method_updater_url/${sfAccountId}`
    return getResource(api)
}

export function saveForm8821Data(
    accountId: string,
    businessId: string,
    data: any
) {
    const api = `api/business/${businessId}/account/${accountId}/tax_form_8821`
    return postResource(api, data)
}

export function getNewtekMerchantUrl(
    accountId: string,
    businessId: string,
    data: any
) {
    const api = `api/account/${accountId}/business/${businessId}/newtek/merchant/register`
    return postResource(api, data)
}

export function getCurrentNewtekMerchantUrl(
    accountId: string,
    businessId: string
) {
    const api = `api/account/${accountId}/business/${businessId}/newtek/merchant/url`
    return getResource(api)
}

export function finCenDocumentUpload(
    files: File[],
    accountId: string,
    businessId: string
) {
    const url = `api/account/${accountId}/business/${businessId}/file_center/upload`
    return Promise.all(
        files.map((file: File) => {
            const formData = new FormData()
            formData.append('file', file)
            return postResource(url, formData)
        })
    )
}
