import {
    Button,
    Chip,
    createStyles,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { ApplicationStore, MileageTracker } from '../../../models';
import {
    getAllVehicles,
    getMileageLogReports,
} from '../../../services/apiService/reports';
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil';
import InfoTooltip from '../../common/InfoTooltip';
import UiFormField from '../../common/ui/UiFormField';
import UiText from '../../common/ui/UiText';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
    EMPTY_PARAMS,
    ERRORS,
    SHOW_ODOMETER,
} from './constants/reports.const';
import SelectCompareDataModal from './utils/modal/SelectCompareDataModal';
import { useReportsStyles } from './styles/reports-styles';
import * as Yup from 'yup';
import Loader from '../../common/Loader';
import {
    ApiError,
    ErrorAlertReport,
    MileageLogReport,
} from './models/reports-interface';
import store from '../../../store';
import { useHistory } from 'react-router-dom';
import { showError } from '../../../services/formService';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { FormObserver } from './utils/Form/FormObserver';
import { reportsDataActions } from '../../../store/actions/reportsData';
import SelectDates from './utils/Date/SelectDates';
import { ReactComponent as SearchIconImage } from '../../../assets/icons-svg/SearchIcon.svg';
import UiButton from '../../common/ui/UiButton';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const INIT_ACTIVITY_VALUE = 'business';

const initialFormValues = {
    activity: INIT_ACTIVITY_VALUE,
    from: moment.utc().hour(12).minute(0).second(0).unix(),
    to: moment.utc().hour(12).minute(0).second(0).unix(),
};
const validationSchema = Yup.object({
    activity: Yup.string().required(ERRORS.ACTIVITY),
});
interface FormValues {
    activity: string;
    from: number;
    to: number;
}

const DATE_CHIPS = [
    {
        for: DATE_TYPES.THIS_WEEK,
        id: 1,
    },
    {
        for: DATE_TYPES.THIS_MONTH,
        id: 2,
    },
    {
        for: DATE_TYPES.THREE_MONTHS,
        id: 3,
    },
    {
        for: DATE_TYPES.THIS_YEAR,
        id: 4,
    },
];
const IS_GET_DATE_TILL_TODAY = false;
const SELECT_VEHICLE_HEADER = 'Select Vehicles';
const TOOLTIP_TEXT = 'The Mileage Log Report shows the miles you traveled on each qualifying trip as well as the total number of miles during the selected period of time.';
export interface OdometerReadings {
    year: number;
}
export interface Vehicle {
    id: string;
    make: string;
    model: string;
    year: number;
    status: string;
    is_default: boolean;
    odometer_readings: OdometerReadings;
}

const useLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectPeriodHeader: {
            margin: '0 0 2rem 0',
        },
        mileageReportForm: {
            margin: '1.5rem 0 0 0',
            '& .tripActivity': {
                margin: '0 0 1.5rem 0',
                '& p.MuiTypography-root': {
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    letterSpacing: '0.01rem',
                    fontWeight: 500,
                },
            },
        },
        selectVehicles: {
            '& p.MuiTypography-root': {
                margin: '0 0 0.8rem 0',
            },
        },
    })
);
export default function Mileage() {
    const appStore: ApplicationStore = useStore().getState();
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const storeData = useCurrentStore();
    const localClasses = useLocalStyles();
    const businessName = storeData.currentBusiness?.name;
    const accountID = storeData.currentAccountId;
    const mileageTrackerApiConfig: MileageTracker =
        appStore.config.apiConfig.mileage_tracker;
    const activities = mileageTrackerApiConfig.activities;
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const formRef = useRef<any>();
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const history = useHistory();

    const [tripVehicles, setTripVehicles] = useState<Vehicle[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSelectVehiclesModalOpen, setIsSelectVehiclesModalOpen] =
        useState<boolean>(false);

    const [checkedVehicle, setCheckedVehicle] = useState<Vehicle[]>([]);

    useEffect(() => {
        getAllVehicles(accountID).then((res: unknown) => {
            const allVehicles = res as Vehicle[];
            setTripVehicles(allVehicles);
        });
    }, [accountID]);

    useEffect(() => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            IS_GET_DATE_TILL_TODAY
        );
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    }, []);

    const getFormattedDate = (dateInSecs: number, periodTextFormat: string) => {
        const dateInMs = dateInSecs * 1000;
        const date = convertUnixToGivenDateFormat(periodTextFormat, dateInMs);
        return date;
    };
    const getPeriodTextWithFromAndToDate = (
        from: number,
        to: number
    ): string => {
        const periodTextFormat = DATE_FORMATS.periodText;
        const fromDate = getFormattedDate(from, periodTextFormat);
        const toDate = getFormattedDate(to, periodTextFormat);
        const periodText = `for the period from ${fromDate} to ${toDate}`;
        return periodText.replace(findSpaceRegex, '+');
    };
    const getPeriodText = (fromDate: number, toDate: number) => {
        const periodTextAllTime = DATE_TYPES.ALL_TIME;
        const periodText =
            !fromDate || !toDate
                ? periodTextAllTime.replace(findSpaceRegex, '+')
                : getPeriodTextWithFromAndToDate(fromDate, toDate);
        return periodText;
    };
    const fetchAPIQueryParams = (fromDate: number, toDate: number) => {
        const periodText = getPeriodText(fromDate, toDate);

        const time = getCurrentTimeStamp();
        const startDateYmd = getDateInYmd(fromDate);
        const endDateYmd = getDateInYmd(toDate);
        return {
            accountID,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        };
    };

    const navigateToDetailedMileageReport = (
        vehicleIds: string | null,
        activity: string,
        startDateYmd: string,
        endDateYmd: string,
        periodText: string | null,
        time: number
    ) => {
        const vehicleIDs = vehicleIds || EMPTY_PARAMS.VEHICLE_IDS;
        const mileageNavigateUrl = `reports/mileage/${vehicleIDs}/${activity}/${startDateYmd}/${endDateYmd}/${periodText}/${time}`;
        history.push(mileageNavigateUrl);
    };
    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };
    const dispatchReportsDataToStore = (data: MileageLogReport) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };
    const handleCloseVehicleModal = () => {
        setIsSelectVehiclesModalOpen(false);
    };
    const getCurrentIndex = (
        checkedVehicle: Vehicle[],
        vehicle: Vehicle
    ): number => {
        const currentIndex = checkedVehicle.indexOf(
            checkedVehicle.filter((item: any) => item.id === vehicle.id)[0]
        );
        return currentIndex;
    };
    const addRemoveItemsBasedOnIndex = (
        currentIndex: number,
        newChecked: Vehicle[],
        vehicle: Vehicle
    ) => {
        if (currentIndex === -1) {
            newChecked.push(vehicle);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        return newChecked;
    };
    const handleToggleVehicle = (value: unknown) => {
        const vehicle = value as Vehicle;
        const currentIndex = getCurrentIndex(checkedVehicle, vehicle);
        const newChecked = [...checkedVehicle];
        const newVehiclesArray = addRemoveItemsBasedOnIndex(
            currentIndex,
            newChecked,
            vehicle
        );
        setCheckedVehicle(newVehiclesArray);
    };

    const getFormattedVehicleIDs = () => {
        let vehicleIds: string[] = [];
        checkedVehicle.map((vehicle: Vehicle) => {
            return vehicleIds.push(vehicle.id);
        });

        return vehicleIds.join(',');
    };
    const getReportsDataAndNavigate = (
        activity: string,
        fromDate: number,
        toDate: number
    ) => {
        const { accountID, periodText, time, startDateYmd, endDateYmd } =
            fetchAPIQueryParams(fromDate, toDate);

        const vehicleIds = getFormattedVehicleIDs();
        setIsLoading(true);
        getMileageLogReports(
            accountID,
            activity,
            periodText,
            SHOW_ODOMETER,
            startDateYmd,
            endDateYmd,
            time,
            vehicleIds
        )
            .then((result: unknown) => {
                setIsLoading(false);
                const data = result as MileageLogReport;
                dispatchReportsDataToStore(data);
                navigateToDetailedMileageReport(
                    vehicleIds,
                    activity,
                    startDateYmd,
                    endDateYmd,
                    periodText,
                    time
                );
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };
    const onSubmit = (values: FormValues) => {
        const { activity, from, to } = values;
        getReportsDataAndNavigate(activity, from, to);
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        const fromDateChanged = formikObject.touched?.from;
        const toDateChanged = formikObject.touched?.to;
        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };
    const openVehicleSelectModal = () => {
        setIsSelectVehiclesModalOpen(true);
    };
    const deleteVehicleChip = (id: string) => {
        const segments = checkedVehicle.filter((segment: Vehicle) => {
            return segment.id !== id;
        });
        setCheckedVehicle(segments);
    };
    const getVehicleMakeAndModel = (vehicle: Vehicle) => {
        return vehicle.make + vehicle.model;
    };

    const updateStartAndEndDates = (dateType: { for: string; id: any }) => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            dateType.for,
            IS_GET_DATE_TILL_TODAY
        );
        const currentActivity = formRef.current.values.activity;
        setinitialValues((prev : any) => ({
            ...prev,
            activity: currentActivity,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    };

    const updateDates = (dateType: { for: string; id: any }): void => {
        setSelectedDateChip(dateType.for);
        updateStartAndEndDates(dateType);
    };

    const isShowClearAllButton = () : boolean =>{
        return checkedVehicle.length > 1;
     }
     const clearAllVehicles = (event: any) =>{
        event.preventDefault();
        setCheckedVehicle([]);
     }
    return (
        <div className={reportsClasses.reportsViewParent}>
            <span className="businessName">{businessName}</span>
            <UiText variant="suv_150" weight="semi_bold_600">
                Mileage Log
                <InfoTooltip tooltipText={TOOLTIP_TEXT} />
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                innerRef={formRef}
            >
                {({ values }) => {
                    return (
                        <Form>
                            <FormObserver onformChange={onformChangeCallback} />
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <div className={localClasses.mileageReportForm}>
                                    <div className="tripActivity">
                                        <UiFormField
                                            type="select"
                                            placeholder="Select an Activity"
                                            label="Select Trip Activity"
                                            fieldName="activity"
                                            cypressId='select-activities-input'
                                            optionsData={activities}
                                            optionKey="name"
                                            optionValue="id"
                                        />
                                    </div>
                                    <>
                                        <div
                                            className={
                                                localClasses.selectVehicles
                                            }
                                        >
                                            <UiText weight="medium_500">
                                                Select Vehicles{' '}
                                            </UiText>

                                            <div
                                                className={
                                                    reportsClasses.multiSelectInput
                                                }
                                            >
                                                {
                                             isShowClearAllButton() &&  <UiButton
                                            handleClick={clearAllVehicles}
                                            label='Clear All'
                                            btnType='primary'
                                            customClass={reportsClasses.clearAllSegmentsButton}
                                        />
                                        }
                                                <TextField
                                                    id="outlined-basic"
                                                    label="Search Vehicles"
                                                    variant="outlined"
                                                    size="small"
                                                    data-cy='search-vehicle-input'
                                                    onClick={
                                                        openVehicleSelectModal
                                                    }
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {
                                                                    <SearchIconImage />
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment:
                                                            checkedVehicle.map(
                                                                (vehicle) => (
                                                                    <Chip
                                                                        key={
                                                                            vehicle.id
                                                                        }
                                                                        tabIndex={
                                                                            -1
                                                                        }
                                                                        label={getVehicleMakeAndModel(
                                                                            vehicle
                                                                        )}
                                                                        onDelete={() => {
                                                                            deleteVehicleChip(
                                                                                vehicle.id
                                                                            );
                                                                        }}
                                                                    />
                                                                )
                                                            ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>

                                    <SelectDates
                                        chips={DATE_CHIPS}
                                        handleUpdateDates={updateDates}
                                        selectedChip={selectedDateChip}
                                    />

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        data-cy='generate-report-btn'
                                    >
                                        Generate Report
                                    </Button>
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>

            <SelectCompareDataModal
                listData={tripVehicles!}
                checkedData={checkedVehicle}
                handleToggle={handleToggleVehicle}
                isOpen={isSelectVehiclesModalOpen}
                handleClose={handleCloseVehicleModal}
                title={SELECT_VEHICLE_HEADER}
                idKey="id"
                valueKey="make"
                searchKey="make"
                valueKeySecondary="model"
                searchKeySecondary="model"
            />
        </div>
    );
}
