import { ITransaction } from '../models/transaction-models';

export const isSplitValid = function (
    splitLines: [],
    formValues: ITransaction
) {
    var invalidLine = splitLines.find(function (line) {
        return !isFilledLine(line) && !isEmptyLine(line);
    });
    return !invalidLine && +difference(formValues) === 0;
};

export const difference = function (formValues: ITransaction) {
    return (
        parseFloat(formValues?.amount || '0') - parseFloat((splitAmount(formValues) || 0).toFixed(2))
    );
    //return (formValues?.amount - splitAmount()).toFixed(2);
};

export const splitAmount = function (formValues: ITransaction) {
    return formValues?.split?.reduce((sum, splitLine) => {
        var amount = splitLine.amount ? +splitLine.amount : 0;
        return sum + amount;
    }, 0);
};

export const isFilledLine = function (line: any) {
    return line.category && line.amount;
};

export const isEmptyLine = function (line: any) {
    return !line.category && !line.amount;
};
