import { Button, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import bannerImg from '../../../../assets/images/AnnualReportIntro.png';
import { annualReportDocument } from '../../../../services/apiService';
import { ApplicationStore, EntityManagementState } from '../../../../models';
import { setEntityManagementData } from '../../../../store/actions/entityManagement';
import Loader from '../../../common/Loader';
import LinkButton from '../../../common/LinkButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function SubmitAnnualReportPopupIntro({ dueDate, description, setIntroCompleted, accountId, businessId, handleClose, entityManagement }: {
    dueDate: number;
    description: string;
    setIntroCompleted: any;
    accountId: string;
    businessId: string;
    handleClose: (result?: any) => void;
    entityManagement: EntityManagementState
}) {
    const theme = useTheme()
    const screenIsSmMax = useMediaQuery(theme.breakpoints.up('sm'));
    const styles = makeStyles({
        bannerImage: {
            padding: 30
        },
        mainContainer: {
            textAlign: 'center',
            minHeight: 600,
            padding: 15
        },
        marginVertical: {
            marginTop: 15,
            marginBottom: 15
        }
    })()

    const [updatingData, setUpdatingData] = useState(false);
    const dispatch = useDispatch();
    const handleAlreadySubmitted = () => {
        setUpdatingData(true);
        annualReportDocument.updateNotice<EntityManagementState>(accountId, businessId, dueDate).then(({ entityTab }) => {
            dispatch(setEntityManagementData({
                ...entityManagement,
                entityTab: {
                    ...entityManagement.entityTab,
                    annualReportDueDate: entityTab.annualReportDueDate
                }
            }))
            handleClose()
        })
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.bannerImage}>
                <img src={bannerImg} width={screenIsSmMax ? 338 : 180} alt="Banner" />
            </div>
            {dueDate && (
                <Typography variant="h5" gutterBottom className={styles.marginVertical}>
                    Due {moment(dueDate * 1000).format('MMM DD, yyyy')}
                </Typography>
            )} 
            <Typography
                variant="body1"
                gutterBottom
                align="justify"
                className={styles.marginVertical}
            >
                {description}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                classes={{ root: styles.marginVertical }}
                className='flex-center'
            >
                <AccessTime /> &nbsp; This application takes on average 15 minutes to complete.
            </Typography>
            {
                updatingData
                    ? <Typography className={styles.marginVertical} align="center"><Loader /></Typography>
                    : <>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={styles.marginVertical}
                            type="submit"
                            onClick={() => {
                                setIntroCompleted(true)
                            }}
                        >
                            Prepare my Report
                        </Button>
                        <LinkButton
                            style={{whiteSpace: 'normal'}}
                            fullWidth
                            type="submit"
                            onClick={() => {
                                handleAlreadySubmitted()
                            }}
                        >
                            I've already submitted my Annual Report
                        </LinkButton>
                    </>
            }
        </div >
    )
}

const mapStateToProps = ({ entityManagement, appData }: ApplicationStore) => ({
    dueDate: entityManagement.entityTab.annualReportDueDate,
    description: entityManagement.entityTab.annualReportDescription,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
    entityManagement
})
export default connect(mapStateToProps)(SubmitAnnualReportPopupIntro);