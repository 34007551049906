import { BalanceSheetReportRoot, InvoiceReportRoot, MileageLogReport, ReconciliationResponse } from '../../components/bookkeeping/reports/models/reports-interface';

export interface Action {
    type: string;
    state: BalanceSheetReportRoot | InvoiceReportRoot| ReconciliationResponse | MileageLogReport;
  }
  
  export const reportsDataActions = {
    SET_REPORTS_DATA : 'SET_REPORTS_DATA',
    RESET_REPORTS_DATA: 'RESET_REPORTS_DATA'
  }
  export const setBalanceSheetReportsData = (action: string, data: BalanceSheetReportRoot | InvoiceReportRoot| ReconciliationResponse| MileageLogReport): Action => ({
    type: action,
    state: data,
  });

  export const resetReportsData = () => ({
    type: reportsDataActions.RESET_REPORTS_DATA,
  });