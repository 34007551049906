import { Formik, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
    Grid,
    GridSize,
    Button,
    Hidden,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { ApplicationStore } from "../../../../models";
import {
    EmpData,
    Compensation,
    EmployeeJobDetails,
} from "../../../../models/payroll/employee";
import { compensationInitials } from "./CompensationFormUtils";
import Loader from "../../../common/Loader";
import { commonStyles } from "../../../../styles/commonStyles";
import { compensationFormMappings } from "./CompensationFormUtils";
import { updateJobCompensation } from "../../../../services/apiService/payroll/employee";
import { formSubmitErrorHandler } from "../../../../services/formService";
import { showAlert } from "../../../../store/actions/feedback";
import ModalMobileHeader from "../../../common/ModalMobileHeader";
import { RenderFormFields } from "./FormUtils";
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection';
import {
    ownershipPaymentError,
    payCheckUnitError,
    paymentFlsaMapping
} from "./CompensationFormUtils";


const CompensationForm = ({
    employee,
    compensation,
    businessId,
    callback,
    job,
    handleClose,
    formFooterStyles,
}: {
    employee: EmpData | undefined;
    compensation?: Compensation | undefined;
    businessId: string;
    callback?: () => void;
    job?: EmployeeJobDetails | undefined;
    handleClose: () => void;
    formFooterStyles: any;
}) => {
    const styles = commonStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    let initialValues = compensationInitials(compensation, job);

    const validationSchema = Yup.object({
        rate: Yup.number()
            .moreThan(0, "Payment Rate should be greater than zero")
            .required("Payment rate is required"),
        payment_unit: Yup.string().required("Payment frequency is required"),
        flsa_status: Yup.string().required(
            "FLSA status for this compensation is required"
        ),
    }).test("paymentUnitError", (value, { createError }) => {
        let { flsa_status, payment_unit } = value;
        let payCheckUnit = payment_unit === "Paycheck";
        return flsa_status === "Owner"
            ? payCheckUnit
                ? true
                : createError(ownershipPaymentError)
            : payCheckUnit
            ? createError(payCheckUnitError)
            : true;
    });

    const onSubmit = (data: any, { setSubmitting, setFieldError }: any) => {
        let params = {
            version: compensation?.version,
            uuid: compensation?.uuid,
            ...data,
        };

        formSubmitErrorHandler(
            updateJobCompensation(businessId, params).then((res: any) => {
                if (res) {
                    callback?.();
                    dispatch(
                        showAlert({
                            alertText: `Job compensations updated successfully`,
                            alertType: "success",
                        })
                    );
                }
            }),
            () => {
                setSubmitting(false);
            },
            setFieldError
        );
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnMount
                enableReinitialize
            >
                {({ submitForm, isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form>
                            <ModalMobileHeader
                                title={"Edit Compensation"}
                                buttonText={"save"}
                                loading={isSubmitting}
                                handleClose={handleClose}
                                handleButtonAction={submitForm}
                            />
                            <div className={isSmDown ? styles.mt24 : ""}>
                                {compensationFormMappings(values).map(
                                    (property: any) => {
                                        return (
                                            <Grid container spacing={1}>
                                                <Grid
                                                    item
                                                    xs={
                                                        property?.xs as GridSize
                                                    }
                                                    md={
                                                        property?.md as GridSize
                                                    }
                                                >
                                                    <div
                                                        className={styles.mt16}
                                                    >
                                                        <div>
                                                            {property.key === 'payment_unit' ? 
                                                                <UiFormControlSelection
                                                                    showFloatingLabel
                                                                    placeholder={property?.placeholder}
                                                                    type={property.type}
                                                                    fieldName={property.key}
                                                                    optionsData={property?.data}
                                                                    optionKey={property?.optionKey}
                                                                    optionValue={property?.optionValue}
                                                                    label={property?.label}
                                                                    fastField={property?.fastField}
                                                                    onChange={(e) => {
                                                                        if(e.target.value === 'Month') {
                                                                            setFieldValue("flsa_status", paymentFlsaMapping['Month'])
                                                                        } if(e.target.value === 'Paycheck') {
                                                                            setFieldValue("flsa_status", paymentFlsaMapping['Paycheck'])
                                                                        }
                                                                    }}
                                                                /> : 
                                                                <>
                                                                    <RenderFormFields property={property} />
                                                                    {property?.infoText}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                )}
                            </div>
                            <Hidden smDown>
                                <div className={formFooterStyles}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid justify="flex-end" container>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={submitForm}
                                            >
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    )}
                                </div>
                            </Hidden>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
});

export default connect(mapStateToProps)(CompensationForm);
