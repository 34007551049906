import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Button,
    Typography,
    Theme,
    Grid
} from '@material-ui/core';
import Recycle from '../../../assets/icons-svg/Recycle.svg';
import store from '../../../store/index';
import { showError } from '../../../store/actions/feedback';
import PopularPageContent from '../../common/PopularPageContent';
import UiDialog from '../../common/ui/UiDialog';

type errorCode = number;
interface modalProps {
    errorType?: errorCode;
    openModal: boolean;
}
const ErrorModal: React.FC<modalProps> = ({ errorType = 500, openModal }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(openModal);
    }, [openModal]);
    const classes = makeStyles((theme: Theme) => {
        return {
            headerStyle: {
                marginTop: theme.spacing(5),
                marginBottom: theme.spacing(6),
            },
            marginBottom: {
                marginBottom: theme.spacing(4),
            },
            svgClass: {
                marginTop: theme.spacing(9),
            },
            pageHeader: {
                fontWeight: 500,
                fontSize: '20px',
                marginTop: theme.spacing(4),
            },
            subHeader: {
                fontSize: '20px',
                fontWeight: 'normal',
            },
            dialogContent: {
                textAlign: 'center',
                padding: '0px 0px',
            },
            pageContentSection: {
                textAlign: 'center'
            },
        };
    })();

    const getContent = (code: errorCode) => {
        let statusCode, header, subHeader;
        switch (true) {
            case code >= 500:
                statusCode = errorType;
                header = 'Internal Server Error';
                subHeader = 'Sorry, something went wrong loading this content';
                break;
            case code >= 400:
                statusCode = errorType;
                header = 'Not found';
                subHeader = 'Sorry, something went wrong loading this content';
                break;
            default:
                statusCode = errorType;
                header = 'Internal Server Error';
                subHeader = 'Sorry, something went wrong loading this content';
        }
        return [statusCode, header, subHeader];
    };

    const handleClose = () => {
        setOpen(false);
        // hide the error on modal close
        store.dispatch(
            showError({
                errorCode: errorType,
                showError: false,
            })
        );
    };

    const refreshPage = () => {
        window.location.reload();
    };
    const [statusCode, header, subHeader] = getContent(errorType);
    return (
        <UiDialog
            title=''
            size='sm'
            hideTitleSection={true}
            open={open}
            handleClose={handleClose}
        >
            <div style={{ textAlign: 'center' }}>
                <div className={classes.svgClass}>
                    <img
                        src={Recycle}
                        alt={`${statusCode}-error`}
                        height='48px'
                        width='54px'
                    />
                </div>
                <Typography variant='h4' className={classes.headerStyle}>
                    {statusCode} - {header}
                </Typography>
                <Typography
                    variant='h6'
                    gutterBottom
                    className={`${classes.subHeader} ${classes.marginBottom}`}
                >
                    {subHeader}
                </Typography>
                <Button
                    variant='outlined'
                    color='secondary'
                    onClick={refreshPage}
                    className={classes.marginBottom}
                >
                    Refresh the Page
                </Button>
            </div>
            <div className={classes.pageContentSection}>
                <Grid container alignItems='center' justify='center'>
                    <PopularPageContent handleClose={handleClose} />
                </Grid>
            </div>
        </UiDialog>
    );
};
export default ErrorModal;
