import {
    makeStyles,
    Dialog,
    withStyles,
    IconButton,
    WithStyles,
    DialogContent,
    DialogActions,
    Theme,
    createStyles,
    Typography,
    Button
} from '@material-ui/core';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { COLORS } from '../../../variables/colors';
import Loader from '../Loader';
import { mergeStyle } from '../../../styles/commonStyles';

const styles = (theme: Theme) =>
    createStyles({
        titleRoot: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${COLORS.grey200}`,
            padding: '20px 24px',
        },
        closeIconButton: {
            padding: 0,
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    handleClose: () => void;
    title?: string;
    titleNode?: React.ReactNode;
    closeButtonInHeader: boolean;
    modalTitleStyles?: any
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {
        classes,
        handleClose,
        title,
        titleNode,
        closeButtonInHeader,
        modalTitleStyles,
        ...other
    } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={modalTitleStyles ? modalTitleStyles : classes.titleRoot}
            {...other}
        >
            {titleNode ? (
                titleNode
            ) : (
                <>
                    <Typography variant='h6'>{title}</Typography>
                    {closeButtonInHeader ? (
                        <IconButton
                            aria-label='close'
                            classes={{ root: classes.closeIconButton }}
                            onClick={handleClose}
                            data-cy="cross-modal-close-btn"
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </>
            )}
        </MuiDialogTitle>
    );
});

export interface SimpleDialogProps {
    open: boolean;
    handleClose: (result?: any) => void;
    closeButtonInHeader?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    title?: string;
    titleNode?: React.ReactNode;
    children: React.ReactNode;
    actions?: React.ReactNode;
    hideTitleSection?: boolean;
    keepMounted?: boolean;
    onExit?: any;
    disableBackdropClick?: boolean;
    customRootClass?: any;
    fullScreen?: boolean;
    contentStyles?: any;
    customDialogStyles?: any
    appBarTitle?: string,
    appBarCancelText?: string,
    onAppBarCancel?(): void
    appBarSaveText?: string,
    onAppBarSave?(): void,
    disableSaveButton?: boolean,
    showLoaderOnSave?: boolean,
    disableEnforceFocus?: boolean
    disableEscapeKeyDown?: boolean
    modalTitleStyles?: any
    customDialogActionStyle?: any
    customContentStyle?: any
}

export default function UiDialog(props: SimpleDialogProps) {
    const classes = makeStyles({
        dialog: {
            borderRadius: 8,
        },
        content: {
            ...(props.contentStyles || {}),
        },
        actionWrapper: {
            justifyContent: 'flex-end',
            borderTop: `1px solid ${COLORS.grey200}`,
        },
        appBar: {
            position: 'relative',
            backgroundColor: COLORS.primaryWhite,
            borderBottom: '1px solid ' + COLORS.grey200,
            minHeight: '65px'
        },
        appBarHeaderTitle: {
            marginLeft: '10px',
            flex: 1,
            color: COLORS.primaryBlack
        },
        appBarHeader: {
            minHeight: '60px'
        }
    })();

    const {
        handleClose,
        open,
        title,
        actions,
        children,
        size = 'md',
        closeButtonInHeader = true,
        hideTitleSection = false,
        keepMounted = false,
        onExit,
        disableBackdropClick = false,
        customRootClass = false,
        customDialogStyles,
        fullScreen = false,
        appBarTitle = '',
        appBarCancelText = '',
        onAppBarCancel,
        appBarSaveText = '',
        onAppBarSave,
        disableSaveButton = false,
        showLoaderOnSave = false,
        disableEnforceFocus = false,
        titleNode,
        disableEscapeKeyDown = false,
        modalTitleStyles,
        customDialogActionStyle,
        customContentStyle
    } = props;

    return (
        <Dialog
            fullScreen={fullScreen}
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={size}
            classes={{
                root: customRootClass ? customRootClass : classes.dialog,
                ...(customDialogStyles ? customDialogStyles : {})
            }}
            keepMounted={keepMounted}
            onExit={onExit}
            disableBackdropClick={disableBackdropClick}
            disableEnforceFocus={disableEnforceFocus}
            onClick={(e) => e.stopPropagation()}
            disableEscapeKeyDown={disableEscapeKeyDown}
        >
            {fullScreen ? (
                !titleNode ? (
                    <AppBar position='relative' className={classes.appBar}>
                        <Toolbar className={classes.appBarHeader}>
                            <IconButton
                                edge='start'
                                onClick={handleClose}
                                aria-label='close'

                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant='h6'
                                className={classes.appBarHeaderTitle}
                            >
                                {appBarTitle ? appBarTitle : title}
                            </Typography>
                            {appBarCancelText ? (
                                <Button onClick={onAppBarCancel}>
                                    {appBarCancelText}
                                </Button>
                            ) : (
                                ''
                            )}
                            {showLoaderOnSave ? (
                                <Loader />
                            ) : (
                                [
                                    appBarSaveText ? (
                                        <Button
                                            onClick={onAppBarSave}
                                            color='primary'
                                            variant='text'
                                            disabled={disableSaveButton}
                                        >
                                            {appBarSaveText}
                                        </Button>
                                    ) : (
                                        ''
                                    ),
                                ]
                            )}
                        </Toolbar>
                    </AppBar>
                ) : (
                    titleNode
                )
            ) : (
                [
                    hideTitleSection ? (
                        ''
                    ) : (
                        <DialogTitle
                            key={'dialog-title'}
                            title={title}
                            titleNode={titleNode}
                            closeButtonInHeader={closeButtonInHeader}
                            handleClose={() => handleClose(false)}
                        />
                    ),
                ]
            )}

            <DialogContent id="modal-content" className={mergeStyle(customContentStyle, classes.content)}>
                {children}
            </DialogContent>
            {actions ? (
                <DialogActions className={customDialogActionStyle ? customDialogActionStyle : classes.actionWrapper}>
                    {actions}
                </DialogActions>
            ) : null}
        </Dialog>
    );
}
