import {
    saveIncomeReportToPortalData,
    InvoiceUpdate,
    SaveReconciliationDocumentData,
    SaveTransactionDocument,
    SaveReportsToPortalData,
} from '../../components/bookkeeping/reports/models/reports-interface';
import {
    deleteResource,
    getResource,
    patchResource,
    postResource,
} from '../axiosService';
import axios from 'axios';
import { EMPTY_PARAMS } from '../../components/bookkeeping/reports/constants/reports.const';

export function getBalanceSheetReport(
    account_id: string,
    business_id: string,
    periodText: string,
    time: number,
    endDateYmd: string,
    classIds: string
) {
    const url = `api/account/${account_id}/business/${business_id}/report/balance_sheet?period_text=${periodText}&time=${time}&end_date_ymd=${endDateYmd}&class_id=${classIds}`;
    return getResource(url);
}

export function getIncomeStatementReport(
    account_id: string,
    business_id: string,
    start_date_ymd: string,
    end_date_ymd: string,
    periodText: string,
    time: number,
    classIds: string
) {
    const url = `api/account/${account_id}/business/${business_id}/report/income_statement?start_date_ymd=${start_date_ymd}&end_date_ymd=${end_date_ymd}&period_text=${periodText}&time=${time}&class_id=${
        classIds ?? ''
    }`;
    return getResource(url);
}

export const downloadReportIntoPDF = (
    accountId: string,
    businessId: string,
    startDateYmd: string,
    endDateYmd: string,
    periodText: string,
    time: number
) => {
    return `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/income_statement/pdf?start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}&period_text=${periodText}&time=${time}`;
};
export function downloadReconciliationReportIntoPDF(
    accountId: string,
    businessId: string,
    startDateYmd: string,
    endDateYmd: string,
    periodText: string,
    time: number,
    selectedAccountIds: string,
) {
    return `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/reconciliations/pdf?account_ids=${selectedAccountIds}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}&period_text=${periodText}&time=${time}`;
}

export function getMileageReportPDFUrl(
    accountId: string,
    showOdometer: boolean,
    activity: string,
    vehicleIds: string,
    startDateYmd: string,
    endDateYmd: string,
    periodText: string,
    time: number
) {
    return `/cbapi/app.php/api/account/${accountId}/mileage_tracker/mileage_log/pdf/async?show_odometer=${showOdometer}&activity=${activity}&vehicle_ids=${vehicleIds}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}&period_text=${periodText}&time=${time}`;
}
export function getMileageReportCSVUrl(
    accountId: string,
    showOdometer: boolean,
    activity: string,
    vehicleIds: string,
    startDateYmd: string,
    endDateYmd: string,
    periodText: string,
    time: number
) {
    return `/cbapi/app.php/api/account/${accountId}/mileage_tracker/mileage_log/csv/async?show_odometer=${showOdometer}&activity=${activity}&vehicle_ids=${vehicleIds}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}&period_text=${periodText}&time=${time}`;
}

export const saveMilageReportToPortal = (
    account_id: string,
    fileType: string,
    data: any
) => {
    const url = `/api/account/${account_id}/mileage_tracker/mileage_log/${fileType.toLocaleLowerCase()}/documents/async`;
    return postResource(url, data);
};

export const saveIncomeReportToPortal = (
    account_id: string,
    business_id: string,
    data: saveIncomeReportToPortalData
) => {
    const url = `/api/account/${account_id}/business/${business_id}/report/income_statement/pdf/documents/async`;
    return postResource(url, data);
};

export function saveGeneralJournalPDFReportToPortal(
    accountId: string,
    businessId: string,
    data: SaveReportsToPortalData
) {
    const url = `/api/account/${accountId}/business/${businessId}/report/general_journal/pdf/documents/async`;
    return postResource(url, data);
}
export function saveGeneralJournalCSVReportToPortal(
    accountId: string,
    businessId: string,
    data: SaveReportsToPortalData
) {
    const url = `/api/account/${accountId}/business/${businessId}/report/general_journal/csv/documents/async`;
    return postResource(url, data);
}
export const saveReconciliationReportToPortal = (
    accountId: string,
    businessId: string,
    data: SaveReconciliationDocumentData
) => {
    const url = `/api/account/${accountId}/business/${businessId}/report/reconciliations/pdf/documents/async`;
    return postResource(url, data);
};

export const getReconciliationAccountsReportToPortal = (
    accountId: string,
    businessId: string,
    reconciliationId: string,
    startDateYmd: string,
    endDateYmd: string,
    periodText: string,
) => {
    const url = `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/reconciliation_details/${reconciliationId}/pdf/async?start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}&period_text=${periodText}`;
    return url;
};

export const saveReconciliationAccountsReportToPortal = (
    accountId: string,
    businessId: string,
    reconciliationId: string,
    data: SaveReconciliationDocumentData
) => {
    const url = `/api/account/${accountId}/business/${businessId}/report/reconciliation_details/${reconciliationId}/pdf/documents/async`;
    return postResource(url, data);
};

export const createFile = (url: string) => {
    // const uat = 'https://uat.1800accountant.com';
    // return axios.get(uat + url); //To test this on local use commented code
    return axios.get(url);
};

export function getInvoiceReports(
    accountId: string,
    businessId: string,
    dateFilter: string,
    periodText: string,
    time: number,
    startDateYmd: string | null,
    endDateYmd: string | null
) {
    const url =
        !startDateYmd || !endDateYmd
            ? `api/account/${accountId}/business/${businessId}/report/invoices?date_filter=${dateFilter}&period_text=${periodText}&time=${time}`
            : `api/account/${accountId}/business/${businessId}/report/invoices?date_filter=${dateFilter}&period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;

    return getResource(url);
}

export function getReconciliationReports(
    accountId: string,
    businessId: string,
    accountIds: string,
    periodText: string,
    time: number,
    startDateYmd: string | null,
    endDateYmd: string | null
) {
    const url =
        !startDateYmd || !endDateYmd
            ? `/api/account/${accountId}/business/${businessId}/report/reconciliations?account_ids=${accountIds}&period_text=${periodText}&time=${time}`
            : `/api/account/${accountId}/business/${businessId}/report/reconciliations?account_ids=${accountIds}&period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    return getResource(url);
}

export function updateInvoicePaymentStatus(
    accountId: string,
    businessId: string,
    invoiceID: string,
    body: InvoiceUpdate
) {
    const url = `/api/account/${accountId}/business/${businessId}/invoice/${invoiceID}`;
    return patchResource(url, body);
}
export function getPdfDownloadUrl(
    accountId: string,
    businessId: string,
    dateFilter: string,
    periodText: string,
    time: number,
    startDateYmd: string | null,
    endDateYmd: string | null
): string {
    const url =
        !startDateYmd || !endDateYmd
            ? `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/invoices/pdf/async?date_filter=${dateFilter}&period_text=${periodText}&time=${time}`
            : `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/invoices/pdf/async?date_filter=${dateFilter}&period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    return url;
}

export function getCsvDownloadUrl(
    accountId: string,
    businessId: string,
    dateFilter: string,
    periodText: string,
    time: number,
    startDateYmd: string | null,
    endDateYmd: string | null
): string {
    const url =
        !startDateYmd || !endDateYmd
            ? `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/invoices/csv/async?date_filter=${dateFilter}&period_text=${periodText}&time=${time}`
            : `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/invoices/csv/async?date_filter=${dateFilter}&period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    return url;
}

export function saveInvoiceDocument(
    accountId: string,
    businessId: string,
    requestData: any,
    fileType: string
) {
    const url =
        !requestData.start_date_ymd || !requestData.end_date_ymd
            ? `/api/account/${accountId}/business/${businessId}/report/invoices/${fileType.toLocaleLowerCase()}/documents/async`
            : `/api/account/${accountId}/business/${businessId}/report/invoices/${fileType.toLocaleLowerCase()}/documents/async`;
    return postResource(url, requestData);
}

export function getTransactionsReport(
    accountId: string,
    businessId: string,
    selectedAccount: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let url = `api/account/${accountId}/business/${businessId}/report/transactions_by_account?account=${selectedAccount}&period_text=${periodText}&time=${time}`;
    if (ymdStartDate !== EMPTY_PARAMS.START_DATE_YMD && ymdEndDate !== EMPTY_PARAMS.END_DATE_YMD) {
        url = `api/account/${accountId}/business/${businessId}/report/transactions_by_account?account=${selectedAccount}&start_date_ymd=${ymdStartDate}&end_date_ymd=${ymdEndDate}&period_text=${periodText}&time=${time}`;
    }
    return getResource(url);
}

export function getJournalEntriesReport(
    accountId: string,
    businessId: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let url = `api/account/${accountId}/business/${businessId}/report/journal_entries?period_text=${periodText}&time=${time}`;
    if (ymdStartDate !== EMPTY_PARAMS.START_DATE_YMD && ymdEndDate !== EMPTY_PARAMS.END_DATE_YMD) {
        url = `api/account/${accountId}/business/${businessId}/report/journal_entries?start_date_ymd=${ymdStartDate}&end_date_ymd=${ymdEndDate}&period_text=${periodText}&time=${time}`;
    }
    return getResource(url);
}

export function getJournalEntriesReportPDFUrl(
    accountId: string,
    businessId: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let startDate = ymdStartDate, endDate = ymdEndDate;
    if(ymdStartDate === EMPTY_PARAMS.START_DATE_YMD && ymdEndDate === EMPTY_PARAMS.END_DATE_YMD){
        startDate = "";
        endDate = "";
    }
    return `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/journal_entries/pdf/async?start_date_ymd=${startDate}&end_date_ymd=${endDate}&period_text=${periodText}&time=${time}`;
}
export function getJournalEntriesReportCSVUrl(
    accountId: string,
    businessId: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let startDate = ymdStartDate, endDate = ymdEndDate;
    if(ymdStartDate === EMPTY_PARAMS.START_DATE_YMD && ymdEndDate === EMPTY_PARAMS.END_DATE_YMD){
        startDate = "";
        endDate = "";
    }
    return `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/journal_entries/csv/async?start_date_ymd=${startDate}&end_date_ymd=${endDate}&period_text=${periodText}&time=${time}`;
}

export function saveJournalEntriesDocuments(
    accountId: string,
    businessId: string,
    data: any,
    fileType: string
) {
    const url = `/api/account/${accountId}/business/${businessId}/report/journal_entries/${fileType.toLocaleLowerCase()}/documents/async`;
    return postResource(url, data);
}

export function downloadTransactionReportPDF(
    accountId: string,
    businessId: string,
    selectedAccount: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let startDate = ymdStartDate, endDate = ymdEndDate;
    if(ymdStartDate === EMPTY_PARAMS.START_DATE_YMD && ymdEndDate === EMPTY_PARAMS.END_DATE_YMD){
        startDate = "";
        endDate = "";
    }
    const url = `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/transactions_by_account/pdf/async?account=${selectedAccount}&start_date_ymd=${startDate}&end_date_ymd=${endDate}&period_text=${periodText}&time=${time}`;
    return url;
}

export function downloadTransactionReportCSV(
    accountId: string,
    businessId: string,
    selectedAccount: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let startDate = ymdStartDate, endDate = ymdEndDate;
    if(ymdStartDate === EMPTY_PARAMS.START_DATE_YMD && ymdEndDate === EMPTY_PARAMS.END_DATE_YMD){
        startDate = "";
        endDate = "";
    }
    const url = `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/transactions_by_account/csv/async?account=${selectedAccount}&start_date_ymd=${startDate}&end_date_ymd=${endDate}&period_text=${periodText}&time=${time}`;
    return url;
}

export function getBankAccountList(accountId: string, businessId: string) {
    const url = `api/account/${accountId}/business/${businessId}/bank_account/all?with_personal_funds=true`;
    return getResource(url);
}

export function getTransactionDetailsById(
    accountId: string,
    businessId: string,
    transactionId: string
) {
    const url = `api/account/${accountId}/business/${businessId}/transaction/${transactionId}`;
    return getResource(url);
}

export function saveTransactionReport(
    accountId: string,
    businessId: string,
    fileType: string,
    requestData: SaveTransactionDocument
) {
    const url = `api/account/${accountId}/business/${businessId}/report/transactions_by_account/${fileType.toLocaleLowerCase()}/documents/async`;
    return postResource(url, requestData);
}

export function excludeTransaction(
    accountId: string,
    businessId: string,
    transactionId: string,
    data: { excluded: boolean }
) {
    const url = `api/account/${accountId}/business/${businessId}/transaction/${transactionId}`;
    return patchResource(url, data);
}

export function deleteTransactionById(
    accountId: string,
    businessId: string,
    transactionId: string
) {
    const url = `api/account/${accountId}/business/${businessId}/transaction/${transactionId}`;
    return deleteResource(url);
}
//Reconciliations
export function getReconciliationDetails(
    accountId: string,
    businessId: string,
    reconciliationId: string
) {
    const url = `/api/account/${accountId}/business/${businessId}/report/reconciliation_details/${reconciliationId}`;
    return getResource(url);
}

export function getMileageLogReports(
    accountId: string,
    activity: string,
    periodText: string,
    showOdometer: boolean,
    startDateYmd: string,
    endDateYmd: string,
    time: number,
    vehicleIds: string
) {
    const url = `/api/account/${accountId}/mileage_tracker/mileage_log?activity=${activity}&show_odometer=${showOdometer}&vehicle_ids=${vehicleIds}&period_text=${periodText}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}&time=${time}`;
    return getResource(url);
}

// Should be moved to mileage tracker module later
export function getAllVehicles(accountId: string) {
    const url = `/api/account/${accountId}/mileage_tracker/vehicles`;
    return getResource(url);
}

export function getGeneralJournalReports(
    accountId: string,
    businessId: string,
    periodText: string,
    time: number,
    startDateYmd: string | null,
    endDateYmd: string | null
) {
    const url =
        !startDateYmd || !endDateYmd
            ? `/api/account/${accountId}/business/${businessId}/report/general_journal?period_text=${periodText}&time=${time}`
            : `/api/account/${accountId}/business/${businessId}/report/general_journal?period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    return getResource(url);
}

export function getGeneralJournalPDFDownloadUrl(
    accountId: string,
    businessId: string,
    periodText: string,
    startDateYmd: string,
    endDateYmd: string,
    time: number
) {
    const url = `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/general_journal/pdf/async?period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    return url;
}
export function getGeneralJournalCSVDownloadUrl(
    accountId: string,
    businessId: string,
    periodText: string,
    startDateYmd: string,
    endDateYmd: string,
    time: number
) {
    const url = `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/general_journal/csv/async?period_text=${periodText}&time=${time}&start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    return url;
}

export function getGeneralLedgerReports(
    accountId: string,
    businessId: string,
    startDateYmd: string | null,
    endDateYmd: string | null
) {
    let url = `/api/account/${accountId}/business/${businessId}/report/general_ledger`;
    if (startDateYmd !== EMPTY_PARAMS.START_DATE_YMD && endDateYmd !== EMPTY_PARAMS.END_DATE_YMD) {
        url = `/api/account/${accountId}/business/${businessId}/report/general_ledger?start_date_ymd=${startDateYmd}&end_date_ymd=${endDateYmd}`;
    }
    return getResource(url);
}

export function getGeneralLedgerReportsCsv(
    accountId: string,
    businessId: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let startDate = ymdStartDate, endDate = ymdEndDate;
    if(ymdStartDate === EMPTY_PARAMS.START_DATE_YMD && ymdEndDate === EMPTY_PARAMS.END_DATE_YMD){
        startDate = "";
        endDate = "";
    }
    return `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/general_ledger/csv?start_date_ymd=${startDate}&end_date_ymd=${endDate}&period_text=${periodText}&time=${time}`;
}

export function downloadGeneralLedgerPdf(
    accountId: string,
    businessId: string,
    ymdStartDate: string,
    ymdEndDate: string,
    periodText: string,
    time: number
) {
    let startDate = ymdStartDate, endDate = ymdEndDate;
    if(ymdStartDate === EMPTY_PARAMS.START_DATE_YMD && ymdEndDate === EMPTY_PARAMS.END_DATE_YMD){
        startDate = "";
        endDate = "";
    }
    return `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/general_ledger/csv?start_date_ymd=${startDate}&end_date_ymd=${endDate}&period_text=${periodText}&time=${time}`;
}

export function saveGeneralLedgerReportsCsv(
    accountId: string,
    businessId: string,
    requestData: any
) {
    const url = `api/account/${accountId}/business/${businessId}/report/general_ledger/csv/documents/async`;
    return postResource(url, requestData);
}
export function getBalanceSheetPDFDownloadUrl(
    accountId: string,
    businessId: string,
    periodText: string,
    endDateYmd: string,
    time: number
): string {
    const url = `/cbapi/app.php/api/account/${accountId}/business/${businessId}/report/balance_sheet/pdf/async?period_text=${periodText}&time=${time}&end_date_ymd=${endDateYmd}`;
    return url;
}

export function saveBalanceSheetPDFReportToPortal(
    accountId: string,
    businessId: string,
    data: SaveReportsToPortalData
) {
    const url = `/api/account/${accountId}/business/${businessId}/report/balance_sheet/pdf/documents/async`;
    return postResource(url, data);
}

//To be moved to COA
export function getAllCOASegments(accountId: string, businessId: string) {
    const url = `/api/account/${accountId}/business/${businessId}/coa_business_class`;
    return getResource(url);
}
