import React from 'react'
import {
    makeStyles,
    Theme,
    Box,
    Button
} from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import Icon from '../../../components/common/Icon'
import UiButton from '../../common/ui/UiButton'

const useClass = makeStyles((theme: Theme) => ({
    messageEmptyBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '9rem 0rem 0rem 0rem',
    },
    buttonMargin: {
        margin: '2rem 0rem 0rem 0rem',
    }
}))

function LocationEmptyPage({
    setOpenModal,
}: {
    setOpenModal: React.Dispatch<boolean>
}) {
    const classes = useClass()
    
    return (
        <div
            className={classes.messageEmptyBox}
        >
            <UiText variant='suv_150'>
                Keep track of the locations you travel to here
            </UiText>
            <UiButton
                btnType="primary"
                label="Save Your First Location"
                handleClick={() => {
                    setOpenModal(true)
                }}
                icon={<Icon icon="add" svgColor="white" />}
                customClass={classes.buttonMargin}
            />
        </div>
        
    )
}

export default LocationEmptyPage;