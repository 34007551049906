import store from '../store';
import { Fragment } from 'react';
import { showAlert, showInfo } from '../store/actions/feedback';

export const showError = (errorText?: string, alertType?: "success" | "info" | "warning" | "error") => {
    store.dispatch(
        showAlert({
            alertType: alertType || 'error',
            alertText: errorText || 'Internal error occurred',
        })
    );
}   


const mergeErrors = (key: string, errors: any) => { 
    return errors[key].map((err: any) => err.full_message).join(',')
}



export const dispatchInfo = (errors: any) => {
    store.dispatch(
        showInfo({
            infoData: <Fragment>
            <ul>
                {errors.map((err: any) => (
                    <li>{err?.full_message || err?.message}</li>
                ))}
            </ul>
        </Fragment>
        })
    )
}


const showPayrollFormErrors  = (errors: any, setFieldError: (field: string, error: string) => void) => {
    Object.keys(errors).forEach((key) => {
        if(key === 'base') {
            dispatchInfo(errors[key])
        } else {
            //{name: "", message: ""}
            if(Object.keys(errors).includes('message')) {
                // specific case of payroll error
                showError(errors?.message)
            } else {
                let errorMessage = mergeErrors(key, errors)
                setFieldError(key, errorMessage);
            }
        }
    });
}

export function formSubmitErrorHandler(
    submitRequest: Promise<any>,
    componentErrorHandler?: () => void,
    setFieldError?: (field: string, error: string) => void,
    showGenericError: boolean = true
) {
    submitRequest.catch((err) => {
        if(err?.status >= 400) {
            let payrollOrgError = err?.errors?.payroll_org_errors
            if(payrollOrgError && setFieldError) {
                showPayrollFormErrors(payrollOrgError, setFieldError)
            } else {
                if (err?.errors && setFieldError) {
                    Object.keys(err.errors).forEach((key) => {
                        setFieldError(key, err.errors[key]);
                    });
                } else {
                    if(payrollOrgError){
                        // payroll org error without form
                        Object.keys(payrollOrgError).forEach((key) => {
                            dispatchInfo(payrollOrgError[key])
                        })
                    }
                }
            }
            // if show more than one snackbar alert, only last one will be shown
            showGenericError && showError(err?.statusText)
        }
        componentErrorHandler?.();
    });
}

export function requestErrorHandler(
    submitRequest: Promise<any>,
    showGenericError = true,
    postErrorHandler?: () => void,
) {
    submitRequest.catch((err) => {
        if(err?.status >= 400) {
            // if show more than one snackbar alert, only last one will be shown
            showGenericError && showError(err?.statusText)
            postErrorHandler?.()
        }
    });
}