import { IBankAccount } from '../models/bank-account-models';
import { getResource, patchResource, poll } from './axiosService';
import { getFromLocalStorage, setInLocalStorage } from './localStorageService';

let bankAccounts: BankAccounts | null;

function _init() {
    const accId = getFromLocalStorage('current_account_id');
    const businessId = getFromLocalStorage('current_business_id');
    if (!accId || !businessId) {
        return Promise.resolve([]);
    }
    return getResource<IBankAccount[]>(
        `api/account/${accId}/business/${businessId}/bank_account/all?with_personal_funds=true`
    );
}

export function getBankAccounts(
    excludedAccounts = false
): Promise<BankAccounts | null> {
    if (bankAccounts && excludedAccounts === bankAccounts.excluded) {
        return new Promise((resolve) => resolve(bankAccounts));
    }
    return _init().then((list: IBankAccount[]) => {
        bankAccounts = new BankAccounts(list, excludedAccounts);
        return bankAccounts;
    });
}

export function reconnectBankAccount(
    currentAcc: string,
    currentBusiness: string,
    bankAccId: string
) {
    return patchResource(
        `api/account/${currentAcc}/business/${currentBusiness}/bank_account/${bankAccId}/reconnect`,
        {}
    );
}

export function resetBankAccounts() {
    bankAccounts = null;
}

export const finicity = {
    refreshAccount: function (currentAcc: string, currentBusiness: string, account_id: string, data?: any) {
        return poll(
            `api/account/${currentAcc}/business/${currentBusiness}/bank_account/${account_id}/refresh`,
            'patch',
            data
        ).then(
            finicity.chainResponse,
            finicity.logError
        );
    },
    chainResponse: function (data: any) {
        if (data?.code) {
            setInLocalStorage("last_finicity_error", data);
        }
        return Promise.resolve(data || {});
    },
    logError: function (data: any) {
        setInLocalStorage("last_finicity_error", data);
        return Promise.reject(data || {});
    },
}

export class BankAccounts {
    excluded: boolean;
    manualCount: number;
    aggregatedCount: number;
    connectedCount: number;
    list: IBankAccount[];

    constructor(list: IBankAccount[], excluded = false) {
        this.excluded = excluded;
        this.manualCount = 0;
        this.aggregatedCount = 0;
        this.connectedCount = 0;
        this.list = [];

        this._init(list);
    }

    private _init(list: IBankAccount[]) {
        if (this.excluded) {
            this.list = list.map((ba: any) => {
                ba.balance = ba.excluded_balance;
                return ba;
            });
        } else {
            this.list = list;
        }
        this._calcTotals();
        this._buildGroups();
    }
    private _calcTotals() {
        this.manualCount = 0;
        this.aggregatedCount = 0;
        this.connectedCount = 0;
        this.list.forEach((a: IBankAccount) => {
            if (a.type === 'manual') {
                this.manualCount++;
            } else if (a.type === 'aggregated' || a.type === 'plaid') {
                this.aggregatedCount++;
                if (a.connected) {
                    this.connectedCount++;
                }
            }
        });
    }
    private _buildGroups() {
        let bankAccounts: IBankAccount[] = [];

        if (this.aggregatedCount) {
            const aggregatedAccounts = this.list.filter(
                (account: IBankAccount) => account.type === 'aggregated' || account.type === 'plaid'
            );
            const aggregatedAccountsGroup: IBankAccount = {
                type: 'aggregated_group',
                name: 'Connected Accounts',
                account: aggregatedAccounts.map((acc) => acc.account).join(','),
                id: aggregatedAccounts.map((acc) => acc.account).join(','),
            };

            bankAccounts.push(aggregatedAccountsGroup);
            bankAccounts = [...bankAccounts, ...aggregatedAccounts];
        }

        if (this.manualCount) {
            const manualAccounts = this.list.filter(
                (acc) => acc.type === 'manual'
            );
            const manualAccountsGroup: IBankAccount = {
                type: 'manual_group',
                name: 'Manual Accounts',
                account: manualAccounts.map((acc) => acc.account).join(','),
                id: manualAccounts.map((acc) => acc.account).join(','),
            };

            bankAccounts.push(manualAccountsGroup);
            bankAccounts = [...bankAccounts, ...manualAccounts];
        }

        const personalFundsAccount = this.list.find(
            (acc) => acc.type === 'personal-funds'
        );

        if (personalFundsAccount) {
            bankAccounts.push(personalFundsAccount);
        }

        this.list = bankAccounts;
    }
    findByCategoryId(id: string, allTypes?: boolean) {
        return this.list.find((acc) => {
            return (
                acc.account === id &&
                (allTypes ||
                    acc.type === 'manual' ||
                    acc.type === 'aggregated' ||
                    acc.type === 'personal-funds')
            );
        });
    }
    findById(id: string) {
        return this.list.find((acc) => acc.id === id);
    }
    isHasAccounts() {
        return this.manualCount || this.aggregatedCount;
    }
    updateBalance(data: any, bank: IBankAccount | null) {
        if (!bank) {
            for (let key in data) {
                const bank = this.findById(data[key].bank_account);
                if (bank) {
                    bank.balance = data[key].balance;
                    bank.synced = data[key].date;
                }
            }
        } else {
            bank.balance = data[0].balance;
            bank.synced = data[0].date;
        }
    }
    reload() {
        const accId = getFromLocalStorage('current_account_id');
        const businessId = getFromLocalStorage('current_business_id');
        return getResource<IBankAccount[]>(
            `api/account/${accId}/business/${businessId}/bank_account/all?with_personal_funds=true`
        ).then((list) => {
            this._init(list);
            return new Promise((resolve) => resolve(this))
        });
    }
}

// export default BankAccountService.getInstance();
