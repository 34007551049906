import { Fragment, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
    Box,
    Theme,
    Button,
    Divider,
    Grid,
    Hidden,
    makeStyles,
    Typography,
} from '@material-ui/core';
import ComponentMobileHeader from '../../../components/common/ComponentMobileHeader';
import { ApplicationStore } from '../../../models';
import Loader from '../../common/Loader';
import AddIcon from '@material-ui/icons/Add';
// common
import { commonStyles } from '../../../styles/commonStyles';
import EmployeeListView from './EmployeeListView';
import EmployeeDetails from './EmployeeDetails';
import { getEmployees } from '../../../services/apiService/payroll/employee';
import {
    getCompany,
    getBusinessAddress,
} from '../../../services/apiService/payroll/company';
import EmployeeFormModal from './EmployeeFormModal';
import { useEmployeeState } from './EmployeeProvider';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up(1601)]: {
            justifyContent: 'flex-start',
        },
    },
    listRoot: {
        maxWidth: '464px',
        paddingBottom: theme.spacing(3),
        width: 'inherit',
        height: '100%',
    },
    searchInput: {
        marginTop: theme.spacing(3),
        height: '40px',
        width: '100%',
        '&.Mui-focused fieldset': {
            border: '1px solid black !important',
        },
    },
}));

const defaultEmpState = {
    page: 1,
    perPage: 15,
    employees: []
}

function Employees(props: any) {
    const [loading, setLoading] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [employeesData, setEmployeesData] = useState(defaultEmpState);
    const businessId = props?.appData?.current_business_id;
    const [fetchMore, setFetchMore] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>();
    const styles = commonStyles();
    const classes = useStyles();

    let { path } = useRouteMatch();
    const {
        companyAddress,
        companyDetails,
        setCompanyAddress,
        setCompanyDetails,
    } = useEmployeeState();

    const fetchEmployees = useCallback(
        (page = 1, perPage = 15, params = {}) => {
            getEmployees(businessId, {
                page: page,
                perPage: perPage,
                ...params,
            }).then((res: any) => {
                if (res.length) {
                    setEmployeesData((prev: any) => ({
                        ...prev,
                        employees:
                            page === 1 ? res : prev.employees.concat(res),
                    }));
                    setLoading(false);
                    setFetchingData(false);
                } else {
                    setFetchMore(false);
                    setFetchingData(false);
                }
            });
        },
        [businessId]
    );

    const loadMore = () => {
        const { page } = employeesData;
        let nextPage = page + 1;
        setEmployeesData((prev: any) => ({
            ...prev,
            page: nextPage,
        }));
        if(!fetchingData) {
            setFetchingData(true);
            fetchEmployees(nextPage);
        }
    };

    useEffect(() => {
        if (!companyAddress) {
            getBusinessAddress(businessId).then((address: any) => {
                if (address) {
                    setCompanyAddress([address]);
                }
            });
        }
        if (!companyDetails) {
            getCompany(businessId).then((company: any) => {
                if (company) {
                    setCompanyDetails(company);
                }
            });
        }
    }, [
        businessId,
        companyAddress,
        companyDetails,
        setCompanyAddress,
        setCompanyDetails,
    ]);

    useEffect(() => {
        setLoading(true);
        fetchEmployees();
    }, [fetchEmployees]);

    const { employees } = employeesData;
    return (
        <Grid container direction="row" style={{ height: '100%' }}>
            <ComponentMobileHeader
                leftSectionItems={<Typography>Employees</Typography>}
                threeDotMenu={false}
                showBottomDivider
            />
            <EmployeeFormModal
                open={openModal}
                formSubmitCallback={() => {
                    setOpenModal(false);
                    setLoading(true);
                    setFetchMore(true)
                    setEmployeesData(defaultEmpState)
                    fetchEmployees();
                }}
                handleClose={() => {
                    setOpenModal(false);
                }}
                mode={'New'}
                locationData={companyAddress}
            />
            <Grid
                container
                md={5}
                sm={12}
                justify="flex-start"
                className={classes.content}
            >
                <Grid container item direction="column" alignItems="flex-start">
                    {loading ? (
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: '100%' }}
                        >
                            <Loader />
                        </Grid>
                    ) : (
                        <>
                            <Box my={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    disabled={
                                       false
                                        // TODO - Payroll need to disable for admin user later
                                        // props.appData.user.accountant_mode
                                    }
                                    onClick={() => {
                                        setOpenModal(true);
                                    }}
                                >
                                    New Employee
                                </Button>
                            </Box>
                            {employees.length === 0 ? (
                                <Typography
                                    className={styles.alignCenter}
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    No employees were Found
                                </Typography>
                            ) : (
                                <EmployeeListView
                                    items={employees}
                                    selectedData={selectedData}
                                    setSelectedData={setSelectedData}
                                    loadMore={loadMore}
                                    fetchMore={fetchMore}
                                />
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            <Hidden smDown implementation="js">
                <Grid
                    sm={1}
                    md={1}
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Divider orientation="vertical" flexItem />
                </Grid>
                <Grid item sm={6}>
                    <Switch>
                        <Route exact path={path}>
                            {!selectedData ? (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="subtitle1">
                                        Select an employee to see the details
                                    </Typography>
                                </Grid>
                            ) : (
                                <EmployeeDetails
                                    selectedData={selectedData}
                                    reloadState={() => {
                                        setLoading(true);
                                        setFetchMore(true)
                                        fetchEmployees();
                                        setEmployeesData(defaultEmpState)
                                    }}
                                    setSelectedData={setSelectedData}
                                />
                            )}
                        </Route>
                    </Switch>
                </Grid>
            </Hidden>
        </Grid>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(Employees);
