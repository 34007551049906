import React from 'react'
import {
    Button,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import UiText from '../common/ui/UiText'

const UpgradePlanButton = () => {
    const history = useHistory()
    
    return (
        <Button
            variant='contained'
            color='primary'
            onClick={() => history.push('/purchase/upgrade-portal-access')}
        >
            <UiText weight='medium_500'>
                Upgrade Your Plan
            </UiText>
        </Button>
    )
}

export default UpgradePlanButton;