import { useState, useEffect, useRef } from "react";
import * as React from "react";
import {
    Grid,
    GridSize,
    Button,
    Hidden,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { Formik, Form, FormikProps, FormikValues, FormikHelpers } from "formik";
import { connect, useDispatch } from "react-redux";
import UiText from "../../../common/ui/UiText";
import UiButton from "../../../common/ui/UiButton";
import Loader from "../../../common/Loader";
import ModalMobileHeader from "../../../common/ModalMobileHeader";
import { commonStyles } from "../../../../styles/commonStyles";
import { ApplicationStore } from "../../../../models";
import {
    createEmployee,
    updateEmployeeData,
    createJob,
    updateJob,
    updateJobCompensation,
} from "../../../../services/apiService/payroll/employee";
import { RenderFormFields } from "./FormUtils";
import { formSubmitErrorHandler } from "../../../../services/formService";
import { showAlert } from "../../../../store/actions/feedback";
import { compensationFormMappings, paymentFlsaMapping } from "../Forms/CompensationFormUtils";
import {
    personalValidation,
    ownershipValidation,
    jobFormValidation,
    compensationValidation,
    formattedParams,
} from "../Forms/NewEmployeeFormUtils";
import UiFormControlSelection from '../../../../components/common/ui/UiFormControlSelection'

interface NewEmployeeFormProps {
    setRequestSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
    businessId: string;
    handleClose: () => void;
    formSubmitCallback?: () => void | undefined;
    currentPage: string;
    currentPageIndex: number;
    nextPage: any;
    CurrentFormInputs: any;
    formTitle: string;
    formFooterStyles: any;
    nextPageText: any;
    isLastPage: boolean;
}

const useStyles = makeStyles(() => ({
    formContent: {
        marginBottom: "120px",
    },
}));

const NewEmployeeForm = ({
    businessId,
    handleClose,
    formSubmitCallback,
    currentPage,
    currentPageIndex,
    nextPage,
    CurrentFormInputs,
    formTitle,
    setRequestSubmitted,
    setIsFormDirty,
    formFooterStyles,
    nextPageText,
    isLastPage,
}: NewEmployeeFormProps) => {
    const styles = commonStyles();
    const classes = useStyles();
    const [employeeData, setEmployeeData] = useState<any>();
    const [jobDetails, setJobDetails] = useState<any>();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
    let compensation = jobDetails?.compensations?.[0];
    let formikRef = useRef<FormikProps<FormikValues>>(null);

    const PageValidations: any = {
        PersonalDetails: personalValidation(employeeData ? false : true),
        JobDetails: jobFormValidation,
        CompensationDetails: compensationValidation,
        OwnershipDetails: ownershipValidation,
    };
    const currentFormValidation = PageValidations[currentPage];

    const compPaymentUnit = compensation?.payment_unit
        ? compensation?.payment_unit
        : jobDetails?.title === "Owner"
        ? "Paycheck"
        : "Hour";
    const compPaymentFlsa = compensation?.flsa_status
        ? compensation?.flsa_status
        : jobDetails?.title === "Owner"
        ? "Owner"
        : "Nonexempt";

    let initialValues: any = {
        first_name: employeeData?.first_name || "",
        middle_initial: employeeData?.middle_initial || "",
        last_name: employeeData?.last_name || "",
        email: employeeData?.email || "",
        date_of_birth: employeeData?.date_of_birth || "",
        ssn: employeeData?.ssn || "",
        two_percent_shareholder:
            employeeData?.two_percent_shareholder === "yes" ? "yes" : "no",
        title: jobDetails?.title || "",
        hire_date: jobDetails?.hire_date || "",
        location_id: jobDetails?.location_id
            ? parseInt(jobDetails?.location_id)
            : null,
        rate: compensation?.rate || "",
        payment_unit: compPaymentUnit,
        flsa_status: compPaymentFlsa,
    };

    useEffect(() => {
        if (currentPageIndex > 0) {
            // once user completed first form defaulty make the form dirty
            // show warning while leaving the modal.
            // so once closed we can refresh the page.
            setIsFormDirty(true);
        }
    }, [currentPageIndex, setIsFormDirty]);

    const postApiAction = (actions: any, response: any) => {
        if (currentPage === "PersonalDetails") {
            setEmployeeData?.(response);
        }
        if (currentPage === "JobDetails") {
            setJobDetails?.(response);
        }
        if (actions?.formCallback) {
            actions?.formCallback();
        }
        setRequestSubmitted(true);
        actions?.nextPage();
        actions?.setSubmitting(false);
    };

    const handleAPIRequest = (
        apiRequest: any,
        actions: any,
        formData?: any
    ) => {
        formSubmitErrorHandler(
            apiRequest.then((response: any) => {
                if (response) {
                    if (currentPage === "JobDetails") {
                        response = { ...response, title: formData?.title };
                    }
                    postApiAction(actions, response);
                }
            }),
            () => {
                actions?.setSubmitting(false);
            },
            actions?.setFieldError
        );
    };

    const completedStepsCallback = () => {
        dispatch(
            showAlert({
                alertText: `Employee Created successfully`,
                alertType: "success",
            })
        );
        formSubmitCallback?.();
    };

    const onSubmit = (data: any, actions: FormikHelpers<any>) => {
        const formActions = {
            setSubmitting: actions.setSubmitting,
            setFieldError: actions.setFieldError,
        };
        if (currentPage === "PersonalDetails") {
            const updatableParams = {
                version: employeeData?.version,
                employee_uuid: employeeData?.employee_uuid,
                ...formattedParams(data, currentPage),
            };

            const personalDetailsAPI = employeeData
                ? updateEmployeeData(businessId, updatableParams)
                : createEmployee(
                      businessId as string,
                      formattedParams(data, currentPage, true)
                  );

            handleAPIRequest(personalDetailsAPI, {
                nextPage: nextPage,
                ...formActions,
            });
        }
        if (currentPage === "OwnershipDetails") {
            const params = {
                version: employeeData?.version,
                employee_uuid: employeeData?.employee_uuid,
                ...formattedParams(data, "PersonalDetails"),
                ...formattedParams(data, currentPage),
            };

            const updateOwnershipAPI = updateEmployeeData(businessId, params);
            handleAPIRequest(updateOwnershipAPI, {
                ...formActions,
                ...(isLastPage
                    ? { formCallback: completedStepsCallback }
                    : {}),
            });
        }
        if (currentPage === "JobDetails") {
            const updatedParams = jobDetails
                ? {
                      ...formattedParams(data, currentPage),
                      uuid: jobDetails?.uuid,
                      version: jobDetails?.version,
                  }
                : {
                      ...formattedParams(data, currentPage),
                      employee_uuid: employeeData?.employee_uuid,
                  };

            const jobDetailsAPI = jobDetails
                ? updateJob(businessId, updatedParams)
                : createJob(businessId, updatedParams);
            handleAPIRequest(
                jobDetailsAPI,
                {
                    nextPage: nextPage,
                    ...formActions,
                },
                data
            );
        }
        if (currentPage === "CompensationDetails") {
            let params = {
                version: compensation?.version,
                uuid: compensation?.uuid,
                ...formattedParams(data, currentPage),
            };

            const jobCompensationAPI = updateJobCompensation(
                businessId,
                params
            );
            handleAPIRequest(
                jobCompensationAPI,
                {
                    ...formActions,
                    ...(isLastPage
                        ? { formCallback: completedStepsCallback }
                        : {
                              nextPage: nextPage,
                          }),
                },
                data
            );
        }
    };


    const renderForm = (property: any) => (
        <Grid container spacing={1}>
            <Grid
                item
                xs={property?.xs as GridSize}
                md={property?.md as GridSize}
            >
                <div className={styles.mt16}>
                    <RenderFormFields property={property} />
                    {property?.infoText}
                </div>
            </Grid>
        </Grid>
    )

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                innerRef={formikRef}
                validationSchema={currentFormValidation}
                enableReinitialize
                validateOnMount
            >
                {({ submitForm, isSubmitting, dirty, values, setFieldValue }) => {
                    currentPageIndex === 0 && setIsFormDirty(dirty);
                    return (
                        <Form>
                            <ModalMobileHeader
                                title={formTitle}
                                buttonType={
                                    currentPageIndex === 0
                                        ? "contained"
                                        : "tertiary"
                                }
                                buttonText={
                                    currentPageIndex === 0 ? "Next" : "Save"
                                }
                                loading={isSubmitting}
                                handleClose={handleClose}
                                handleButtonAction={() => {
                                    submitForm();
                                }}
                            />
                            <div
                                className={
                                    isSmDown ? styles.mt24 : classes.formContent
                                }
                            >
                                {currentPage === "OwnershipDetails" ? (
                                    <CurrentFormInputs />
                                ) : <>
                                    {currentPage === "CompensationDetails"
                                        ? compensationFormMappings(values).map((property: any) =>
                                            <>
                                            {property.key === 'payment_unit' ? 
                                                    <div className={styles.mt16}>
                                                        <UiFormControlSelection
                                                            showFloatingLabel
                                                            placeholder={property?.placeholder}
                                                            type={property.type}
                                                            fieldName={property.key}
                                                            optionsData={property?.data}
                                                            optionKey={property?.optionKey}
                                                            optionValue={property?.optionValue}
                                                            label={property?.label}
                                                            fastField={property?.fastField}
                                                            onChange={(e) => {
                                                                if(e.target.value === 'Month') {
                                                                    setFieldValue("flsa_status", paymentFlsaMapping['Month'])
                                                                } if(e.target.value === 'Paycheck') {
                                                                    setFieldValue("flsa_status", paymentFlsaMapping['Paycheck'])
                                                                }
                                                            }}
                                                        />
                                                    </div> : 
                                                    <>
                                                        {renderForm(property)}
                                                        {property?.infoText}
                                                    </>
                                            }
                                            </>
                                        )
                                        : CurrentFormInputs.map((property: any) => renderForm(property))}
                                    </>
                                }
                            </div>
                            <Hidden smDown>
                                <div className={formFooterStyles}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-end"
                                            className={styles.mr24}
                                        >
                                            {isLastPage ? (
                                                <UiButton
                                                    handleClick={(e) => {
                                                        e.stopPropagation();
                                                        submitForm();
                                                    }}
                                                    label="Save Employee"
                                                    btnType="tertiary"
                                                    customClass={styles.mr16}
                                                />
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={submitForm}
                                                >
                                                    Next
                                                </Button>
                                            )}
                                            {!isLastPage && (
                                                <UiText
                                                    style={{ marginTop: "5px" }}
                                                >
                                                    {
                                                        nextPageText[
                                                            currentPage as string
                                                        ]
                                                    }
                                                </UiText>
                                            )}
                                        </Grid>
                                    )}
                                </div>
                            </Hidden>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
});

export default connect(mapStateToProps)(NewEmployeeForm);
