import React from 'react';
import { useHistory } from 'react-router-dom'
import {
    ListItemText,
} from '@material-ui/core'
import { Contact } from '../../../models'
import { trimText } from '../../../utils/utility'
import  ListElement from '../../common/ListElement'
import { mergeStyle, commonStyles } from '../../../styles/commonStyles'
import UiTextView from '../../../components/common/ui/UiTextView'

export default function ContactItem({
    contact,
    selectedContact,
    setSelectedContact,
    lastItem
}: {
    contactId: string
    contact: Contact
    selectedContact: Contact | undefined
    setSelectedContact: React.Dispatch<Contact | undefined>
    lastItem: boolean
}) {
    const styles = commonStyles()
    const history = useHistory()

    const mDash = () => <span className={mergeStyle(styles.flex, styles.flexEnd)}>—</span>
    return (
        <ListElement
            isItemSelected={contact.id === selectedContact?.id}
            handleItemClick={() => {
                setSelectedContact(contact)
                history.push(`/contacts/${contact.id}`)
            }}
            primaryNode= {<ListItemText
                className={styles.capitalize}
                primary={contact?.name ? trimText(contact?.name, 15) : mDash()}
                secondary={contact?.type}
            />}
            secondaryNode={
                <ListItemText
                    className={mergeStyle(styles.capitalize, styles.textRight)}
                    primary={
                        contact?.company
                            ? trimText(contact?.company, 15)
                            : null
                    }
                    secondary={contact.phone ? <UiTextView type='phone' value={contact.phone} /> : null }
                />
            }
            lastItem={lastItem}
        />
    )
}