import {
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Message, Notification, ApplicationStore } from '../../../models'
import {
    getAllNotifications,
    markNotificationsAsRead,
    getUnreadMessages,
} from '../../../services/apiService'
import { getMessageTime } from '../../../utils/appUtil'
import PortalTabs from '../../common/PortalTabs'
import SkeletonLoader from '../../common/SkeletonLoader'
import TabPanelHolder from '../../common/TabPanelHolder'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import {
    loadNotificationCount,
    onclickOfNotification,
} from '../../../store/actions/count'
import { loadPostAuthData } from '../../../store/actions/appData'
import LinkButton from '../../common/LinkButton'
import { TEN_MINUTES_BEFORE } from '../../guided-discovery/constants'

const styles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    linkButton: {
        minWidth: 0,
        display: 'flex',
    },
    timeText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
    },
    cardIcon: {
        width: '38px',
        left: '1px',
        opacity: '0.8',
        top: '7px',
    },
    subjectText: {
        maxWidth: 'calc(100% - 80px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    messageBody: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    attachmentIconStyle: {
        fontSize: '15px',
        top: theme.spacing(0),
        position: 'relative',
    },
    clickListItem: {
        cursor: 'pointer',
    },
    link: {
        textDecoration: 'underline'
    }
}))

function NotificationsContent(props: {
    width: string
    accountId: string
    children: React.ReactNode
    user: any
    loadNotificationCount: any
    onClick: any
    businessId: string
    loadPostAuthData: () => Promise<any>
    setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
    setMeetingDetails?: React.Dispatch<React.SetStateAction<any>>
    onclickOfNotification: any
}) {
    const classes = styles()
    const [loadingNotifications, setLoadingNotifications] = useState(true)
    const [unreadMessages, setUnreadMessages] = useState<Message[]>([])
    const [loadingUnreadMessages, setLoadingUnreadmessages] = useState(true)
    const [notifications, setNotifications] = useState<Notification[]>([])
    const history = useHistory()

    /**
     * TODO - need to remove the show_gd_modal check once we release GD for all
     * Filter the notification and check that if the GD sesstion details contains the flag
     * -show-gd-modal
     */
    const filterGDNotification = useCallback((res: any) => {
        const filteredNotifications: any = res?.filter((notification: any) => {
            const notificationContent = notification.content
            return (
                notificationContent?.gd_session_details?.length === 0 ||
                notificationContent?.gd_session_details?.show_gd_modal
            )
        })
        return filteredNotifications
    }, [])

    useEffect(() => {
        getUnreadMessages(props.accountId).then((res) => {
            setUnreadMessages(res)
            setLoadingUnreadmessages(false)
        })

        getAllNotifications(props.accountId).then((res) => {
            setNotifications(filterGDNotification(res))

            if (!props.user.accountant_mode) {
                let readed_ids: any = res
                    .filter(
                        (n) =>
                            !n.content.is_read &&
                            n.type === 'alert' &&
                            n.content.id
                    )
                    .map((n) => n.content.id)

                if (readed_ids.length) {
                    markNotificationsAsRead(props.accountId, readed_ids).then(
                        () => {
                            props.loadNotificationCount()
                        }
                    )
                }
            }
        })
        props.loadPostAuthData().then(() => {
            setLoadingNotifications(false)
        })
    }, [props.accountId]) //eslint-disable-line react-hooks/exhaustive-deps

    const confirmGDMeeting = (content: any) => {
        const sessionDetails = content?.gd_session_details
        const isMeetingStarted =
            sessionDetails?.start_date - TEN_MINUTES_BEFORE < moment().unix()
        const isMeetingHasNotEnded =
            sessionDetails?.expires_at > moment().unix()

            if(sessionDetails) {
                if(sessionDetails?.launch_modal_instantly) return true   
                return sessionDetails && isMeetingStarted && isMeetingHasNotEnded
            } else {
                return false
            }
       
    }

    const openGDModal = (content: any) => {
        if (confirmGDMeeting(content)) {
            props?.setOpenModal?.(true)
            props?.setMeetingDetails?.({
                gd_session_details: content?.gd_session_details,
            })
        } else {
            return
        }
    }

    const filterHeading = (heading: string) => {
        if (heading.indexOf('<date>') !== -1) {
            heading = heading.replace(
                /<date>(\d+)<\/date>/,
                function (match, date) {
                    return (
                        '<span class="nobr">' +
                        moment(date * 1000).format('D MMM yyyy') +
                        '</span>'
                    )
                }
            )
        }
        return heading
    }

    const filterContent = (content: string) => {
        if (content.indexOf('<date_only>') !== -1) {
            content = content.replace(
                /<date_only>(\d+)<\/date_only>/,
                function (match, date) {
                    return (
                        '<span class="nobr">' +
                        moment(date * 1000).format('D MMM yyyy') +
                        '</span>'
                    )
                }
            )
        }
        if (content.indexOf('<nobr>') !== -1) {
            content = content.replace(
                /<nobr>(.*?)<\/nobr>/,
                function (match, text) {
                    return '<span class="nobr">' + text + '</span>'
                }
            )
        }

        if (content.indexOf('<date>') !== -1) {
            content = content.replace(
                /<date>(\d+)<\/date>/,
                function (match, date) {
                    return (
                        '<span class="nobr">' +
                        moment(date * 1000).format('D MMM yyyy') +
                        '</span>'
                    )
                }
            )
        }

        return content
    }

    const getButtonText = (type: string) => {
        switch (type) {
            case 'upgrade':
                return 'Continue'
            case 'new_appointment':
                return 'Schedule Now'
            case 'retry_payment':
                return 'View'
            case 'retry_payments':
                return 'View'
            case 'bank_accounts_disconnected':
                return 'Reconnect'
            case 'bank_accounts_not_synced':
                return 'Update Account'
            case 'tax-organizer':
                return 'Go to Taxes'
            case 'tax_organizer':
                return 'Go to Taxes'
            default:
                return ''
        }
    }

    const handleNotificationClick = (type: string) => {
        props.onClick('')
        switch (type) {
            case 'upgrade':
                return history.push('/purchase/products')
            case 'new_appointment':
                return history.push('/calendar')
            case 'retry_payment':
                return history.push('/purchase/products')
            case 'retry_payments':
                return history.push('/purchase/products')
            case 'bank_accounts_disconnected':
                return history.push('/banking')
            case 'bank_accounts_not_synced':
                return history.push('/banking')
            case 'tax-organizer':
                return window.open(
                    `${window.origin}/portal/#/tax-organizer/business`,
                    '_blank'
                )
            case 'tax_organizer':
                return window.open(
                    `${window.origin}/portal/#/tax-organizer/business`,
                    '_blank'
                )
            default:
                return ''
        }
    }

    const handleAlertNotificationClick = (content: any) => {
        openGDModal(content)
        if (content?.type === 'tax_form_uploaded') {
            if(history.location.pathname !== '/dashboard'){
                history.push('/dashboard');
                sessionStorage.setItem(content.type, 'true');
            }
            props.onclickOfNotification({ open: content.type })
        }
    }

    const messageItem = (message: any) => {
        return (
            <ListItem
                button
                alignItems="flex-start"
                component={Link}
                to={`/message_center/${message.id}`}
                onClick={() => props.onClick('')}
            >
                <ListItemAvatar>
                    <Avatar
                        alt={message.lastUnreadMessage.accountant.first_name}
                        src={message.lastUnreadMessage.accountant.photo_link}
                    />
                </ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{
                        className: classes.subjectText,
                        style: {
                            fontWeight: 'bold',
                        },
                    }}
                    primary={message.subject}
                    secondary={
                        <Box component="span" className={classes.messageBody}>
                            {`${
                                message.lastUnreadMessage.accountant.first_name
                            } ${message.lastUnreadMessage.accountant.last_name.substr(
                                0,
                                1
                            )}`}

                            {': '}
                            {message.lastUnreadMessage.body}
                            {!message.lastUnreadMessage.body &&
                            message.lastUnreadMessage.attachments.length === 0
                                ? 'This file has been removed.'
                                : ''}
                            {message.lastUnreadMessage.attachments.map(
                                (a: any, index: number) => (
                                    <Fragment key={index}>
                                        <AttachFileIcon
                                            className={
                                                classes.attachmentIconStyle
                                            }
                                        />
                                        {a.file_name}
                                    </Fragment>
                                )
                            )}
                        </Box>
                    }
                />
                <Typography className={classes.timeText} variant="caption">
                    {getMessageTime(message.lastUnreadMessage.date * 1000)}
                </Typography>
            </ListItem>
        )
    }

    function Messages({ messages }: { messages: any[] }) {
        return (
            <>
                {messages.map((message, index) => (
                    <Fragment key={index}>
                        {messageItem(message)}
                        {index === messages.length - 1 ? (
                            ''
                        ) : (
                            <Divider variant="inset" />
                        )}
                    </Fragment>
                ))}
            </>
        )
    }

    function MessageTab() {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    {unreadMessages.length ? (
                        <Messages messages={unreadMessages} />
                    ) : (
                        <ListItem>
                            <Typography color="textSecondary">
                                No unread messages
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </Fragment>
        )
    }

    function All() {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    {notifications.length ? (
                        notifications.map(({ content, date, type }, index) => (
                            <Fragment key={index}>
                                {type === 'alert' ? (
                                    <>
                                        <ListItem
                                            alignItems="flex-start"
                                            onClick={() => {
                                                handleAlertNotificationClick(
                                                    content
                                                )
                                            }}
                                            className={
                                                !confirmGDMeeting(content)
                                                    ? classes.clickListItem
                                                    : ''
                                            }
                                        >
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    style: {
                                                        fontWeight:
                                                            !content.is_read
                                                                ? 'bold'
                                                                : 'normal',
                                                    },
                                                }}
                                                primary={
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: filterHeading(
                                                                content.heading
                                                            ),
                                                        }}
                                                    ></span>
                                                }
                                                secondary={
                                                    <Fragment>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: filterContent(
                                                                    content.message
                                                                ),
                                                            }}
                                                        ></div>
                                                        {content.type === 'tax_form_uploaded' && <div className={classes.link}>Taxpayer Signature</div>}
                                                        {content.type !==
                                                            'noaction' && (
                                                            <LinkButton
                                                                className={
                                                                    classes.linkButton
                                                                }
                                                                onClick={() =>
                                                                    handleNotificationClick(
                                                                        content.type
                                                                    )
                                                                }
                                                            >
                                                                {getButtonText(
                                                                    content.type
                                                                )}
                                                            </LinkButton>
                                                        )}
                                                    </Fragment>
                                                }
                                            />
                                            <Typography
                                                className={classes.timeText}
                                                variant="caption"
                                            >
                                                {getMessageTime(date * 1000)}
                                            </Typography>
                                        </ListItem>
                                        {index === notifications.length - 1 ? (
                                            ''
                                        ) : (
                                            <Divider variant="fullWidth" />
                                        )}
                                    </>
                                ) : (
                                    <Fragment key={index}>
                                        {messageItem(content)}
                                        {index === notifications.length - 1 ? (
                                            ''
                                        ) : (
                                            <Divider variant="inset" />
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        ))
                    ) : (
                        <ListItem>
                            <Typography color="textSecondary">
                                No notifications
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </Fragment>
        )
    }

    return (
        <div className={classes.root} style={{ width: props.width }}>
            {props.children}
            <Typography className={classes.title} variant="h5">
                Notifications
            </Typography>
            <PortalTabs>
                <TabPanelHolder index={0} title="All">
                    {loadingNotifications ? <SkeletonLoader /> : <All />}
                </TabPanelHolder>
                <TabPanelHolder
                    index={1}
                    title="Messages"
                    badgeVisible={!!unreadMessages.length}
                >
                    {loadingUnreadMessages ? (
                        <SkeletonLoader />
                    ) : (
                        <MessageTab />
                    )}
                </TabPanelHolder>
            </PortalTabs>
        </div>
    )
}

const mapStateToProps = ({ appData }: ApplicationStore) => ({
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
    user: appData.user,
})

export default connect(mapStateToProps, {
    loadNotificationCount,
    onclickOfNotification,
    loadPostAuthData,
})(NotificationsContent)
