import React from 'react';
import {
    withStyles,
    Divider,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@material-ui/core';
import {
    Add,
    ArrowForward,
    VisibilityOff,
    Visibility,
} from '@material-ui/icons';
import { COLORS } from '../../../variables/colors';
import { tableOptions } from './coa.const';
import UiText from '../../common/ui/UiText';
import { ChartOfAccountantsData } from '../../../models/chartOfAccountants';
import { ReactComponent as EditPen } from '../../../assets/icons-svg/editPen.svg';
import { ReactComponent as FileText } from '../../../assets/icons-svg/FileTextSmall.svg';
import { ReactComponent as Delete } from '../../../assets/icons-svg/deleteTrash.svg';


const StyledMenuItem = withStyles((theme) => ({
    root: {
        color: COLORS.red200,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: COLORS.red200,
        },
    },
}))(MenuItem);

const StyledItemIcon = withStyles((theme) => ({
    root: {
        minWidth: '45px',
        '& .MuiListItemIcon-root': {
            minWidth: '45px',
        },
    },
}))(ListItemIcon);

export interface TableMenusListProps {
    handleMenuClose: () => void;
    anchorEl: null | HTMLElement;
    handleClick: (
        e: any,
        data: any,
        categoryData?: ChartOfAccountantsData
    ) => void;
    dataForMenu: any;
    level3Categories?: ChartOfAccountantsData[] | undefined;
}

const TableMenusList = ({
    handleMenuClose,
    anchorEl,
    handleClick,
    dataForMenu,
    level3Categories,
}: TableMenusListProps) => {
    const handleClickOnMemuItem = (
        e: React.MouseEvent<HTMLElement>,
        clickedMenu: string
    ) => {
        handleClick(e, clickedMenu);
        handleMenuClose();
    };

    const doDestinationL3ValuesExist = (): boolean => {
        if (!level3Categories) return false;
        return level3Categories.length > 0;
    };

    return (
        <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={(e) =>
                    handleClickOnMemuItem(e, tableOptions.runReport)
                }
                data-cy="run-report"
            >
                <StyledItemIcon>
                    <FileText />
                </StyledItemIcon>
                <UiText>{tableOptions.runReport}</UiText>
            </MenuItem>
            {dataForMenu.level !== 4 && !dataForMenu.is_journal_entry_line && dataForMenu.can_have_children && (
                <MenuItem
                    onClick={(e) =>
                        handleClickOnMemuItem(e, tableOptions.addCategory)
                    }
                    data-cy="add-category"
                >
                    <StyledItemIcon>
                        <Add />
                    </StyledItemIcon>
                    <UiText>{tableOptions.addCategory}</UiText>
                </MenuItem>
            )}
            {dataForMenu.can_change_visibility && (
                <MenuItem
                    data-cy="change-visibility"
                    onClick={(e) => handleClickOnMemuItem(e, tableOptions.hide)}
                >
                    <StyledItemIcon>
                        {dataForMenu.is_hidden ? (
                            <Visibility />
                        ) : (
                            <VisibilityOff />
                        )}
                    </StyledItemIcon>
                    <UiText>
                        {dataForMenu.is_hidden
                            ? tableOptions.show
                            : tableOptions.hide}
                    </UiText>
                </MenuItem>
            )}
            {doDestinationL3ValuesExist() && !dataForMenu?.bankAccount && (
                <MenuItem
                    onClick={(e) => handleClickOnMemuItem(e, tableOptions.move)}
                    data-cy="move-report"
                >
                    <StyledItemIcon>
                        <ArrowForward />
                    </StyledItemIcon>
                    <UiText>{tableOptions.move}</UiText>
                </MenuItem>
            )}
            {dataForMenu.can_edit && (
                <MenuItem
                    onClick={(e) => handleClickOnMemuItem(e, tableOptions.edit)}
                    data-cy="edit-segment"
                >
                    <StyledItemIcon>
                        <EditPen />
                    </StyledItemIcon>
                    <UiText>{tableOptions.edit}</UiText>
                </MenuItem>
            )}
            {dataForMenu.can_remove && (
                <div>
                    <Divider />
                    <StyledMenuItem
                        data-cy="delete-segment"
                        onClick={(e) =>
                            handleClickOnMemuItem(e, tableOptions.delete)
                        }
                    >
                        <StyledItemIcon>
                            <Delete fill={COLORS.red200} />
                        </StyledItemIcon>
                        <UiText>{tableOptions.delete}</UiText>
                    </StyledMenuItem>
                </div>
            )}
        </Menu>
    );
};

export default TableMenusList;
