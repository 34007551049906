import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Background from '../../../assets/bg-images/bg.svg'
import defaultLogo from '../../../assets/logo/Logo.svg'
import { Box, Theme } from '@material-ui/core'
import { Formik } from 'formik'
import { toRem16 } from '../../../styles/commonStyles'
import * as Yup from 'yup'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { useOnBoardingState } from './OnBoardingProvider'
import { logout } from '../../../services/authService'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { ONBOARDING_FORM_MISSING_FIELDS } from './constant/business-onboarding.const'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { useStore } from 'react-redux'
import { ApplicationStore } from '../../../models/store'
import { getPartnerLogo } from '../../common/whiteLabel/updateThemeAttibutes'

export const classes = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        app: {
            display: 'flex',
            flex: 1,
            minWidth: '100%',
            minHeight: '100vh',
        },
        createAccountParentContainer: {
            background: `url(${Background})`,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        createAccountContainer: {
            display: 'flex',
            width: '23%',
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            borderRadius: '1rem',
            minWidth: toRem16(400),
            [theme.breakpoints.down('xs')]: {
                minWidth: 'auto'
            },
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        logoContainer: {
            display: 'flex',
            padding: `${toRem16(20)} 0`,
            borderBottom: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey300}`,
            width: '100%',
            justifyContent: 'center',
        },
        accountForm: {
            padding: `${toRem16(31)} ${toRem16(48)} ${toRem16(48)} ${toRem16(
                48
            )}`,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            '& .MuiFormControl-root ': {
                margin: `0 0 ${toRem16(12)} 0`,
            },
        },
        accountFormContainer: {
            minHeight: 0,
            overflowY: 'auto',
            width: '100%',
        },
        formHeader: {
            textAlign: 'center',
            margin: `0 0 ${toRem16(31)} 0`,
        },
        submitButton: {
            width: '100%',
        },
        backLink: {
            display: 'flex',
            alignItems: 'center',
            marginTop: toRem16(32),
            marginLeft: toRem16(8),
            color: (colorTheme) => colorTheme.blue200,
            cursor: 'pointer',
        },
        backLinkText: {
            marginLeft: toRem16(8),
        },
    }
})

interface CreateNewAccountType {
    missingProperties: string[];
}

const CreateNewAccount = ({missingProperties}: CreateNewAccountType) => {
    const { colorTheme } = useThemeContext()
    const styles = classes(colorTheme)
    const { setFormState, setIsInitialValuesSet } = useOnBoardingState()
    const { currentUser } = useCurrentStore()
    const appStore: ApplicationStore = useStore().getState();
    const { user } = appStore.appData;
    const [logoPath, setLogoPath] = useState<string>('');
    
    useEffect(() =>{
        if(!user || !user.partner_details){
            setLogoPath(defaultLogo)
            return;
        }
        const partnerLogoUrl = getPartnerLogo(user.partner_details.partner_key ?? '');
        setLogoPath(partnerLogoUrl);
     },[logoPath, user])
     
    const handleSubmit = (values: any) => {
        setFormState(values)
        setIsInitialValuesSet(true)
    }

    const initialValues = {
        first_name: currentUser?.first_name || '',
        last_name: currentUser?.last_name || '',
        name: currentUser?.company || '',
    }

    const createNewAccountValidationSchema = Yup.object({
        name: Yup.string().required('Required'),
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
    })

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={createNewAccountValidationSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnMount={true}
            >
                {(form) => {
                    return (
                        <div className={styles.app}>
                            <div
                                className={styles.createAccountParentContainer}
                            >
                                <div className={styles.createAccountContainer}>
                                    <div className={styles.logoContainer}>
                                        <img
                                            src={logoPath}
                                            alt="1800-Accountant logo"
                                            height="40"
                                        />
                                    </div>
                                    <div
                                        className={styles.accountFormContainer}
                                    >
                                        <div className={styles.accountForm}>
                                            <div></div>
                                            <UiText
                                                variant="suv_150"
                                                weight="semi_bold_600"
                                                className={styles.formHeader}
                                            >
                                                Create an Account
                                            </UiText>
                                            {missingProperties.length > 0 &&
                                                missingProperties.includes(
                                                    ONBOARDING_FORM_MISSING_FIELDS.FIRST_NAME
                                                ) && (
                                                    <UiFormControlSelection
                                                        label="First Name (Required)"
                                                        type="text"
                                                        fieldName="first_name"
                                                        showFloatingLabel={true}
                                                    />
                                                )}

                                            {missingProperties.length > 0 &&
                                                missingProperties.includes(
                                                    ONBOARDING_FORM_MISSING_FIELDS.LAST_NAME
                                                ) && (
                                                    <UiFormControlSelection
                                                        label="Last Name (Required)"
                                                        type="text"
                                                        fieldName="last_name"
                                                        showFloatingLabel={true}
                                                    />
                                                )}

                                            {missingProperties.length > 0 &&
                                                missingProperties.includes(
                                                    ONBOARDING_FORM_MISSING_FIELDS.COMPANY
                                                ) && (
                                                    <UiFormControlSelection
                                                        label="Business Name (Required)"
                                                        type="text"
                                                        fieldName="name"
                                                        showFloatingLabel={true}
                                                    />
                                                )}

                                            <Box mt={2}>
                                                <UiButton
                                                    customClass={
                                                        styles.submitButton
                                                    }
                                                    handleClick={() =>
                                                        form.submitForm()
                                                    }
                                                    label="Go to my Account"
                                                    btnType="tertiary"
                                                />
                                            </Box>
                                            <div
                                                onClick={() => logout()}
                                                className={styles.backLink}
                                            >
                                                <ArrowBackIcon />
                                                <UiText
                                                    variant="motorcycle_90"
                                                    weight="semi_bold_600"
                                                    className={
                                                        styles.backLinkText
                                                    }
                                                >
                                                    Back to Sign In
                                                </UiText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </div>
    )
}

export default CreateNewAccount
