import {
    Action,
    LOADING_COMPANY_STEP_INFO,
    SET_COMPANY_STEP_INFO,
    RESET_STEP_INFO,
} from '../actions/company';

let defaultState = {
    loadingStepInfo: false,
    loadedStepInfo: false,
    companyOnboardingStepInfo: {},
};

export default function company(state = defaultState, action: Action): any {
    switch (action.type) {
        case SET_COMPANY_STEP_INFO:
            return { ...state, ...action.state };
        case LOADING_COMPANY_STEP_INFO:
            return { ...state, loadingStepInfo: true };
        case RESET_STEP_INFO:
            return defaultState;
        default:
            return state;
    }
}
