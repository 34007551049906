import { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { useFormikContext } from 'formik'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core'
import TOSFlowHeader from './TOSFlowHeader'
import UiText from '../../../common/ui/UiText'
import IconList from '../../../common/IconList'
import UiAlert from '../../../common/ui/UiAlert'
import { ApplicationStore } from '../../../../models'
import defaultLogo from '../../../../assets/logo/Logo.svg'
import rocket from '../../../../assets/animations/Rocket.json'
import TermsAndConditionsSection from './TermsAndConditionsSection'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { getPartnerLogo } from '../../../common/whiteLabel/updateThemeAttibutes'
import { usePayrollMigrationState } from '../../Providers/PayrollMigrationProvider'
import TOSFooterSection from './TOSFooterSection'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    reconnectBankOption: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '2rem 0',

        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            background: (colorTheme) => colorTheme.primaryWhite,
            padding: '1rem 0',
            borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            boxSize: 'border-box',
            zIndex: 10,
            margin: 0
        },

        '& div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    bankBtnContainer: {
        position: 'relative',
        width: 'fit-content',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    reconnectBankBtn: {
        padding: '0.563rem 1rem',
        background: (colorTheme) => colorTheme.primary,
        color: (colorTheme) => colorTheme.primaryWhite,
        [theme.breakpoints.down('xs')]: {
            width: `calc(100% - 2rem)`, 
            padding: '0.563rem 1rem',
        },
        '&:hover': {
            background: (colorTheme) => colorTheme.primary,
        },
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    termsContainer: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '9rem',
        },
    },
    fabProgress: {
        color: '#F15F22 !important',
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 2,
        background: '#FFFFFF87',
        top: 0,

        '& .MuiCircularProgress-root': {
            color: '#F15F22 !important',
        },
    },
    reconnectContainer: {
        position: 'relative',
    },
    loaderClass: {
        position: 'absolute',
        background: (colorTheme) => colorTheme.grey200,
    },
    actionMessages: {
        margin: '1rem 0',
        display: 'flex',
        flexDirection: 'column',
        '& div': {
            color: (colorTheme) => colorTheme.primaryBlack,
        }
    },
    smDeviceActionMessages: {
        margin: '1rem 0',
        textAlign: 'center',
    },
    reconnectText: {
        color: (colorTheme) => colorTheme.grey400,
    },
    securityMessage: {
        color: (colorTheme) => colorTheme.grey400,
        [theme.breakpoints.down('xs')]: {
            color: (colorTheme) => colorTheme.black100,
        },
        '& p': {
            marginLeft: '0.5rem',
        },
        '& svg': {
            height: '0.9rem',
        }
    },
    tosFooter: {
        textAlign: 'center',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '1.5rem 1.5rem 1rem 1.5rem',
        width: '40rem',
        boxSizing: 'border-box',
        color: (colorTheme) => colorTheme.black100,

        '& div': {
            margin: '0.5rem 0',
            padding: '0.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: '3rem',
        },
    },
}))

const PayrollMigrationLandingPage = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const appStore: ApplicationStore = useStore().getState()
    const { user } = appStore.appData
    const {
        disableBankConnectButton,
        linkToken,
        submittingCompanyData
    } = usePayrollMigrationState()
    const [logoPath, setLogoPath] = useState<string>('')
    const { isValid, submitForm } = useFormikContext()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    useEffect(() => {
        if (!user || !user.partner_details) {
            setLogoPath(defaultLogo)
            return
        }
        const partnerLogoUrl = getPartnerLogo(
            user.partner_details.partner_key ?? ''
        )
        setLogoPath(partnerLogoUrl)
    }, [logoPath, user])

    return (
        <>
            <TOSFlowHeader
                icon={rocket}
                headerText="We’re optimizing how we handle payroll"
            >
                <>
                    <UiText variant='hatchback_125'>
                        Accept the ADP terms of service, and reconnect your
                        business bank
                    </UiText>
                    <UiText variant='hatchback_125'>
                        account you use for Payroll Payments to keep them running
                        smoothly.
                    </UiText>
                </>
            </TOSFlowHeader>

            <div className={classes.termsContainer}>
                <TermsAndConditionsSection />
            </div>

            <div className={classes.reconnectBankOption}>
                <div className={classes.bankBtnContainer}>
                    {submittingCompanyData && (
                        <div className={classes.fabProgress}>
                            <CircularProgress size={24} />{' '}
                        </div>
                    )}
                    <Button
                        className={classes.reconnectBankBtn}
                        variant="contained"
                        disabled={!linkToken}
                        onClick={() => submitForm()}
                    >
                        Reconnect My Bank
                    </Button>
                </div>

                {smDevice && (
                    <div className={classes.smDeviceActionMessages}>
                        {isValid && (
                            <UiText
                                className={classes.reconnectText}
                                variant="moped_75"
                                weight="medium_500"
                            >
                                To reconnect your bank, <br /> accept the terms
                                of service.
                            </UiText>
                        )}
                        {!isValid && (
                            <div>
                                <UiAlert
                                    icon={'alert'}
                                    severity={'error'}
                                    description={
                                        'To reconnect your bank, accept the terms of service.'
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
                {!smDevice && (
                    <div className={classes.actionMessages}>
                        {isValid && (
                            <UiText variant="motorcycle_90">
                                To reconnect your bank, accept the terms of
                                service.
                            </UiText>
                        )}
                        {!isValid && (
                            <div>
                                <UiAlert
                                    icon={'alert'}
                                    severity={'error'}
                                    description={
                                        'To reconnect your bank, accept the terms of service.'
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className={classes.securityMessage}>
                    {IconList['lockIcon']}{' '}
                    {smDevice ? (
                        <UiText variant="moped_75">
                            Your data is securely handled through Plaid.
                        </UiText>
                    ) : (
                        <Typography variant="body2">
                            Your data is securely handled through Plaid.
                        </Typography>
                    )}
                </div>
            </div>
            
            {!smDevice  && <TOSFooterSection />}
        </>
    )
}

export default PayrollMigrationLandingPage
