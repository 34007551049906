import React, { Fragment, useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import Locations from '../mileage-log/locations/Locations'
import LocationPreview from '../mileage-log/locations/preview/LocationPreview'
import { LoadScript } from '@react-google-maps/api'
import { ActiveRoutingContext } from './Providers/ActiveRoutingProvider'

export default function LocationRoutes() {

    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()

    useEffect(()=>{
        setActiveRouteHeading('Locations')
    },[setActiveRouteHeading])
    
    return (
        <Switch>
            <Fragment>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey={(window as any).ApiConfig.google_maps_api_key}
                    libraries={["places"]}
                    loadingElement=" "
                >
                    <Hidden mdUp>
                        <Route exact path="/mileage/locations">
                            <Locations />
                        </Route>
                        <Route path={`/mileage/locations/:id`} exact>
                            <Container className={classes.container} maxWidth="lg">
                                <LocationPreview />
                            </Container>
                        </Route>
                    </Hidden>
                    <Hidden smDown>
                        <Route path="/mileage/locations">
                            <Container className={classes.container} maxWidth="lg">
                                <Locations />
                            </Container>
                        </Route>
                    </Hidden>
                </LoadScript>
            </Fragment>
        </Switch>
    )
}