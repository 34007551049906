import {
    Avatar,
    Box,
    Button,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FieldArray, useFormikContext } from 'formik';
import moment from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
    Business,
    BusinessOwner,
    ApplicationStore,
    Config,
} from '../../models';
import AddressForm from './AddressForm';
import OwnerForm from './OwnerForm';
import UiFormControlSelection from './ui/UiFormControlSelection';
import { arraybufferToUri } from '../../utils/utility';
import Loader from './Loader';
import UiDialog from './ui/UiDialog';
import { COLORS } from '../../variables/colors';
import LinkButton from './LinkButton';
import { previewUrl, uploadBusinessLogo } from '../../services/apiService';
import UiFormField from './ui/UiFormField';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

const BusinessEditForm: React.FC<{
    data?: Business | null | undefined;
    config: Config;
    setField?: any;
    onBusinessSettingPage?: boolean;
    accountId: string;
}> = ({
    data = {},
    config: { apiConfig: config },
    setField,
    onBusinessSettingPage = true,
    accountId,
}): ReactElement => {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            subHeading: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            fieldContainer: {
                marginBottom: theme.spacing(4),
            },
            fieldTitle: {
                color: colorTheme.black200,
                opacity: '0.6',
            },
            businessLogo: {
                width: '60%',
                height: 'auto',
                marginBottom: theme.spacing(1),
                padding: theme.spacing(3),
                border: '1px solid',
                borderColor: colorTheme.grey2300,
            },
            caption: {
                color: colorTheme.black200,
                opacity: '0.6',
            },
            avatar: {
                backgroundColor: theme.palette.primary.main,
                color: colorTheme.primaryWhite,
            },
            deleteBtn: {
                fontSize: '1em',
                color: theme.palette.error.main,
            },
            deleteRedBtn: {
                fontSize: '1em',
                background: theme.palette.error.main,
                color: 'white',
            },
        };
    })();

    const xsDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );

    const inputFile: any = useRef(null);
    const [logoImage, setLogoImage] = useState<{
        image: any;
        name: string;
        isLoading: boolean;
        error: boolean;
    }>({ image: '', name: '', isLoading: false, error: false });
    const { values: formValues }: { values: Business } = useFormikContext();
    const [openModal, setOpenModal] = useState(false);

    const openFileSelect = () => {
        // `current` points to the mounted file input element
        inputFile?.current?.click();
    };

    useEffect(() => {
        if (data?.logo) {
            setLogoImage((image) => ({ ...image, isLoading: true }));
            previewUrl(accountId, data.logo, 'logo').then((response) => {
                if (response?.status === 200) {
                    const imageuri = arraybufferToUri(response);
                    setLogoImage((image) => ({
                        ...image,
                        image: imageuri,
                        name: '',
                        isLoading: false,
                        error: false,
                    }));
                }
            });
        }
    }, [data, accountId]);

    const checkNonUSPhone = (e: any)=> {
        e.target.value = e.target.value.replace(/[^-0-9\(\)+ ]*/g,'');
    }

    const updateImage = (e: any) => {
        if (e?.target?.files?.length > 0) {
            const img = e.target.files[0];
            if (isValidLogoImage(img)) {
                setLogoImage({ ...logoImage, error: false });
                previewImage(e.target.files[0]);
            } else {
                setField('logo', null);
                setLogoImage({
                    image: '',
                    name: '',
                    isLoading: false,
                    error: true,
                });
            }
        }
    };

    const previewImage = (image: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                uploadBusinessLogo(image, accountId).then(({ data }) => {
                    setField('logo', data.id);
                    setLogoImage({
                        ...logoImage,
                        image: reader.result || '',
                        name: image.name,
                        isLoading: false,
                        error: false,
                    });
                });
            }
        };
        setLogoImage({ ...logoImage, isLoading: true, error: false });
        reader.readAsDataURL(image);
    };

    const isValidLogoImage = (image: File): boolean => {
        const allowedImageTypes = ['png', 'jpg', 'jpeg'];
        const imageType = image.type.split('/')[1];
        return allowedImageTypes.includes(imageType) && image.size <= 5000000;
    };

    const removeLogo = () => {
        setField('logo', null);
        setLogoImage({ ...logoImage, image: '', name: '', isLoading: false });
        handleClose();
    };

    const getMonths = () => {
        const months: { id: number; key: string }[] = [];
        for (let i = 0; i < 12; i++) {
            months.push({
                id: i + 1,
                key: moment.months(i),
            });
        }
        return months;
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const openDeletePopup = () => {
        setOpenModal(true);
    };
    return (
        <Box py={onBusinessSettingPage ? 2 : 0}>
            {data ? (
                <Grid item xs={12} md={onBusinessSettingPage ? 6 : 12}>
                    <Grid>
                        <Typography variant='h6' className={classes.subHeading}>
                            Details
                        </Typography>
                        <Box className={classes.fieldContainer}>
                            <UiFormControlSelection
                                label='Business Name (Required)'
                                type='text'
                                fieldName='name'
                                required={true}
                                errorMessage='Required'
                                showFloatingLabel={true}
                            ></UiFormControlSelection>
                        </Box>
                        <Grid
                            container
                            spacing={1}
                            direction={
                                onBusinessSettingPage ? 'column-reverse' : 'row'
                            }
                        >
                            <Grid item md={onBusinessSettingPage ? 8 : 6}>
                                <Box className={classes.fieldContainer}>
                                    <UiFormControlSelection
                                        label='Business Type (Required)'
                                        type='select'
                                        optionsData={config.business.types}
                                        optionKey='title'
                                        optionValue='id'
                                        fieldName='business_type'
                                        required={true}
                                        errorMessage='Required'
                                        showFloatingLabel={true}
                                    ></UiFormControlSelection>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.fieldContainer}>
                                    <UiFormControlSelection
                                        label='EIN (Optional)'
                                        type='mask'
                                        fieldName='ein'
                                        mask={[
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                            '-',
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                            /[0-9,X]/,
                                        ]}
                                        minLength={9}
                                        showFloatingLabel={true}
                                    ></UiFormControlSelection>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className={classes.fieldContainer}>
                            <Grid item md={8}>
                                <UiFormControlSelection
                                    label='Industry (Required)'
                                    type='select'
                                    optionsData={config.business.industries}
                                    optionKey='name'
                                    optionValue='id'
                                    fieldName='industry'
                                    required={true}
                                    errorMessage='Required'
                                    showFloatingLabel={true}
                                    disabled={formValues.id ? true : false}
                                ></UiFormControlSelection>
                            </Grid>
                        </Box>
                        {formValues.industry ===
                        config.industry_type_other?.abbr ? (
                            <Box className={classes.fieldContainer}>
                                <Grid item md={8}>
                                    <UiFormControlSelection
                                        label='Industry Name (Required)'
                                        type='text'
                                        fieldName='industry_name'
                                        required={
                                            formValues.industry ===
                                            config.industry_type_other?.abbr
                                        }
                                        errorMessage='Required'
                                        showFloatingLabel={true}
                                    ></UiFormControlSelection>
                                </Grid>
                            </Box>
                        ) : null}

                        <Box>
                            <UiFormControlSelection
                                label='Description (Optional)'
                                type='textarea'
                                fieldName='description'
                                showFloatingLabel={true}
                            ></UiFormControlSelection>
                        </Box>
                    </Grid>
                    {onBusinessSettingPage && (
                        <>
                            <Grid
                                container
                                justify='space-between'
                                style={{ margin: theme.spacing(5, '0') }}
                            >
                                <Typography variant='h6'>Logo</Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{
                                            marginBottom: theme.spacing(5),
                                        }}
                                    >
                                        Accepted formats: jpg, JPEG, png. 5MB
                                        file size limit.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    className={classes.fieldContainer}
                                    xs={12}
                                    md={4}
                                >
                                    {logoImage.isLoading ? (
                                        <Loader justifyContent='flex-start' />
                                    ) : (
                                        <Avatar
                                            variant='square'
                                            src={logoImage.image}
                                            className={classes.businessLogo}
                                        ></Avatar>
                                    )}
                                    <Typography
                                        variant='caption'
                                        className={classes.caption}
                                    >
                                        {logoImage.name}
                                    </Typography>
                                    {logoImage.error ? (
                                        <Typography
                                            variant='caption'
                                            className={classes.caption}
                                            style={{
                                                color: theme.palette.error.main,
                                                opacity: '1',
                                            }}
                                        >
                                            Please check for the valid image
                                            types and file size limit
                                        </Typography>
                                    ) : null}
                                </Grid>
                                <Grid item md={8}></Grid>
                                <Grid item xs={12} md={5}>
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        onClick={openFileSelect}
                                    >
                                        {logoImage?.image
                                            ? 'Change logo'
                                            : 'Upload logo'}
                                    </Button>
                                    {logoImage?.image ? (
                                        <Button
                                            variant='text'
                                            className={classes.deleteBtn}
                                            startIcon={<DeleteOutlineIcon />}
                                            onClick={openDeletePopup}
                                        >
                                            Remove
                                        </Button>
                                    ) : null}
                                    <input
                                        type='file'
                                        id='file'
                                        accept='.jpg, .jpeg, .png'
                                        ref={inputFile}
                                        style={{ display: 'none' }}
                                        onChange={updateImage}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid container justify='space-between'>
                        <Grid
                            item
                            xs={12}
                            style={{ margin: theme.spacing(5, '0', 3) }}
                        >
                            <Typography
                                variant='h6'
                                className={classes.subHeading}
                            >
                                Formation Info
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                                paddingRight: !xsDevice ? theme.spacing(2) : 0,
                            }}
                        >
                            <Box className={classes.fieldContainer}>
                                <UiFormControlSelection
                                    label='Formation Date'
                                    type='KeyboardDatePicker'
                                    fieldName='form_date'
                                    dateType='string'
                                    disableFutureDate={true}
                                    showFloatingLabel={true}
                                    dateFormat='MM/DD/YYYY'
                                    placeholder='MM/DD/YYYY'
                                ></UiFormControlSelection>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                                paddingLeft: !xsDevice ? theme.spacing(2) : 0,
                            }}
                        >
                            <Box className={classes.fieldContainer}>
                                <UiFormControlSelection
                                    label='Formation State (Required)'
                                    type='select'
                                    optionsData={config.generic.states}
                                    optionKey='name'
                                    optionValue='id'
                                    fieldName='form_state'
                                    required={true}
                                    errorMessage='Required'
                                    showFloatingLabel={true}
                                ></UiFormControlSelection>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography
                            variant='h6'
                            className={classes.subHeading}
                            style={{ margin: theme.spacing(6, '0', 3) }}
                        >
                            Country
                        </Typography>
                        <UiFormControlSelection
                            type='radio'
                            labelClass='align-items-start'
                            fieldName='phone_type_us'

                            radioOptionsData={[
                                {
                                    label: (
                                        <div
                                            style={{
                                                padding: '9px 0',
                                            }}
                                        >
                                            <div>US</div>
                                        </div>
                                    ),
                                    value: 'Yes',
                                },
                                {
                                    label: (
                                        <div
                                            style={{
                                                padding: '9px 0',
                                            }}
                                        >
                                            Non US
                                        </div>
                                    ),
                                    value: 'No',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid>
                        <Typography
                            variant='h6'
                            className={classes.subHeading}
                            style={{ margin: theme.spacing(6, '0', 4) }}
                        >
                            Phone Number
                        </Typography>
                        <Grid md={6} item className={classes.fieldContainer}>
                            {formValues.phone_type_us === 'Yes' && (
                                <UiFormField
                                    label='Phone Number'
                                    labelSize={false}
                                    type='mask'
                                    fieldName='business_phone'
                                    mask={[
                                        '(',
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                        ')',
                                        ' ',
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                        '-',
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                    ]}
                                    showFloatingLabel={true}
                                    minLength={10}
                                ></UiFormField>
                            )}
                            {formValues.phone_type_us === 'No' && (
                                <UiFormField
                                    label='Phone Number'
                                    type='text'
                                    labelSize={false}
                                    fieldName='business_phone'
                                    showFloatingLabel={true}
                                    maxLength={15}
                                    onKeyUp={checkNonUSPhone}
                                ></UiFormField>
                            )}
                        </Grid>
                    </Grid>

                    <Grid>
                        <Typography
                            variant='h6'
                            className={classes.subHeading}
                            style={{ margin: theme.spacing(6, '0', 4) }}
                        >
                            Address
                        </Typography>
                        <AddressForm fieldName='address'></AddressForm>
                    </Grid>

                    <Grid>
                        <Typography
                            variant='h6'
                            className={classes.subHeading}
                            style={{ margin: theme.spacing(6, '0', 4) }}
                        >
                            Start of Fiscal Year
                        </Typography>
                        <Box className={classes.fieldContainer}>
                            <Grid md={6} item>
                                <UiFormControlSelection
                                    label='Month'
                                    type='select'
                                    optionsData={getMonths()}
                                    optionKey='key'
                                    optionValue='id'
                                    fieldName='accounting_settings.fiscal_year_first_month'
                                    showFloatingLabel={true}
                                ></UiFormControlSelection>
                            </Grid>
                        </Box>
                        <Box className={classes.fieldContainer}>
                            <Typography
                                variant='caption'
                                className={classes.caption}
                            >
                                1-800Accountant will automatically close out
                                Income and Expense accounts and roll up net
                                profit or loss into owner capital account on
                                last month of fiscal year.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <Typography
                            variant='h6'
                            className={classes.subHeading}
                            style={{ margin: theme.spacing(6, '0', 4) }}
                        >
                            Ownership
                        </Typography>
                        <Box className={classes.fieldContainer}>
                            <Grid md={6} item>
                                <UiFormControlSelection
                                    label='This Business is owned by a(n)'
                                    type='select'
                                    optionsData={config.business.entity_types}
                                    optionKey='title'
                                    optionValue='name'
                                    fieldName='business_info.entity_type'
                                    showFloatingLabel={true}
                                ></UiFormControlSelection>
                            </Grid>
                        </Box>
                    </Grid>
                    <FieldArray name='business_owners'>
                        {({ push, remove, form }) => {
                            return form.values?.business_owners?.length > 0 ? (
                                <Grid>
                                    <Grid
                                        container
                                        justify='space-between'
                                        style={{
                                            margin: theme.spacing(5, '0', 3),
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            // className={classes.subHeading}
                                        >
                                            Business Owner(s)
                                        </Typography>
                                        <LinkButton
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                push({
                                                    first_name: '',
                                                    last_name: '',
                                                    ownership: 0,
                                                    address: {
                                                        country: 'US'
                                                    }
                                                });
                                            }}
                                        >
                                            New Owner
                                        </LinkButton>
                                    </Grid>
                                    {form.values.business_owners.map(
                                        (
                                            owner: BusinessOwner,
                                            index: number
                                        ) => {
                                            return (
                                                <OwnerForm
                                                    index={index}
                                                    fieldName={`business_owners[${index}]`}
                                                    deleteOwner={(i: number) =>
                                                        remove(i)
                                                    }
                                                    form={form}
                                                    key={`owner-${index}`}
                                                />
                                            );
                                        }
                                    )}
                                </Grid>
                            ) : null;
                        }}
                    </FieldArray>
                    {onBusinessSettingPage && (
                        <>
                            <Grid
                                container
                                justify='space-between'
                                style={{ margin: '2em 0' }}
                            >
                                <Typography variant='h6'>
                                    Current or Prior Year
                                </Typography>
                            </Grid>
                            <Grid>
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        What bookkeeping software did you use?
                                    </Typography>
                                    <Grid sm={6} item>
                                        <UiFormControlSelection
                                            type='select'
                                            optionsData={
                                                config.business
                                                    .bookkeeping_software
                                            }
                                            optionKey='title'
                                            optionValue='name'
                                            fieldName='business_info.bookkeeping_software'
                                        ></UiFormControlSelection>
                                    </Grid>
                                </Box>
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        Was your business operational this year?
                                    </Typography>
                                    <UiFormControlSelection
                                        type='toggle'
                                        optionsData={[
                                            { value: true, label: 'Yes' },
                                            { value: false, label: 'No' },
                                        ]}
                                        fieldName='business_info.was_operational'
                                    ></UiFormControlSelection>
                                </Box>
                                {formValues.business_type &&
                                (formValues.business_type === 'S' ||
                                    formValues.business_type === 'LLC_S') ? (
                                    <Box className={classes.fieldContainer}>
                                        <Typography
                                            variant='body2'
                                            className={classes.fieldTitle}
                                        >
                                            Did you receive your S-Corporation
                                            election acceptance letter?
                                        </Typography>
                                        <UiFormControlSelection
                                            type='toggle'
                                            optionsData={[
                                                { value: true, label: 'Yes' },
                                                { value: false, label: 'No' },
                                            ]}
                                            fieldName='business_info.has_irs_s_corp_approval_letter'
                                        ></UiFormControlSelection>
                                    </Box>
                                ) : null}
                            </Grid>
                        </>
                    )}
                </Grid>
            ) : null}
            <UiDialog
                open={openModal}
                title='Delete Logo'
                handleClose={handleClose}
                size='xs'
            >
                <Typography style={{ marginBottom: '2em' }}>
                    Are you sure to remove this logo?
                </Typography>
                <Typography style={{ marginBottom: '1em' }}>
                    This cannot be undone.
                </Typography>
                <Box>
                    <Button
                        variant='outlined'
                        color='secondary'
                        style={{ margin: '0 10px 10px 0' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        startIcon={<DeleteOutlineIcon />}
                        className={classes.deleteRedBtn}
                        style={{ margin: '0 10px 10px 0' }}
                        onClick={removeLogo}
                    >
                        Delete
                    </Button>
                </Box>
            </UiDialog>
        </Box>
    );
};
const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
    accountId: state.appData.current_account_id,
});
export default connect(mapStateToProps)(BusinessEditForm);
