import React from 'react';
import moment from 'moment'
import UiText from '../../../common/ui/UiText'
import MdashCheck from '../../../common/MdashCheck'
import { EmpData } from '../../../../models/payroll/employee'
import SectionView from '../../common/SectionView'
import { useEmployeeState } from '../EmployeeProvider'
import { COLORS } from '../../../../variables/colors'
import Icon from '../../../common/Icon'
import UiAlert from '../../../common/ui/UiAlert'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
interface PersonalDetailsProps {
    selectedEmployee?: EmpData
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    setCurrentForm: React.Dispatch<React.SetStateAction<string>>
}

const PersonalDetails = ({
    selectedEmployee,
    setOpenModal,
    setCurrentForm,
}: PersonalDetailsProps) => {
    let { companyDetails } = useEmployeeState()
    const { colorTheme }: { colorTheme: ThemeColors} = useThemeContext()
    const isSCorp = companyDetails?.entity_type === 'S-Corporation'
    const hasEmployeeJob = selectedEmployee?.jobs?.length
    let sectionData = [
        {
            label: 'Name',
            value: (
                <UiText>
                    {selectedEmployee?.first_name}
                    &nbsp;
                    {selectedEmployee?.middle_initial}
                    &nbsp;
                    {selectedEmployee?.last_name}
                </UiText>
            ),
        },
        {
            label: 'Date of Birth',
            value: (
                <MdashCheck customCheck={!!selectedEmployee?.date_of_birth}>
                    {moment(selectedEmployee?.date_of_birth).format(
                        'MMM D, YYYY'
                    )}
                </MdashCheck>
            ),
        },
        {
            label: 'Email',
            value: (
                <MdashCheck customCheck={!!selectedEmployee?.email}>
                    {selectedEmployee?.email}
                </MdashCheck>
            ),
        },
    ]

    if (isSCorp) {
        sectionData.push({
            label: '>2% ShareHolder',
            value: (
                <>{selectedEmployee?.two_percent_shareholder ? 'Yes' : 'No'}</>
            ),
        })
    }

    return (
        <SectionView
            key={'Personal Details '}
            headerText={'Personal Details'}
            buttonIcon={<Icon icon="edit" svgColor={colorTheme.blue200}/>}
            buttonText={'Edit'}
            sectionAlert={
                <>{
                    (isSCorp && !hasEmployeeJob) ? <UiAlert
                    icon={'alert'}
                    severity={"error"}
                    description={
                        'This employee needs a job and compensation before you can edit their personal info.'
                    }/> : ''
                }</>
            }
            disableSection={isSCorp && !hasEmployeeJob}
            sectionInfoData={sectionData}
            onClickAction={() => {
                setOpenModal(true)
                setCurrentForm('PersonalDetails')
            }}
        />
    )
}

export default PersonalDetails
