import * as Yup from 'yup';
import moment from 'moment';
import { _timestamp } from '../../../../utils/dateUtil';
import { startOfDayTimeStamp } from '../../../../utils/dateUtil'

export const validationSchema = Yup.object({
    contact_data: Yup.object().required('Contact info is required'),
    invoice_lines: Yup.array().of(
        Yup.object({
            offering_quantity: Yup.number()
                .required('Product quantity is required')
                .label('Product quantity'),
            offering_data: Yup.object({
                title: Yup.string().required('Product is required'),
                price: Yup.number()
                    .min(0)
                    .required('Product price is required')
                    .label('Product price'),
                tax_rate: Yup.number()
                    .min(0)
                    .max(100)
                    .label('Product tax rate'),
            }),
            price: Yup.number().min(0).required('Product price is required').label('Product price'),
            tax_rate: Yup.number().min(0).max(100).label('Product tax rate'),
        })
    ),
    invoice_date: Yup.number()
        .min(1, 'Invoice date is required')
        .required('Invoice date is required'),
    due_date: Yup.number()
        .min(1, 'Due date is required')
        .required('Due date is required'),
    message_to_customer: Yup.string(),
}).test('ProductDataError', (value, { createError }) => {
    const { invoice_date, due_date } = value;
    return invoice_date && due_date && invoice_date <= due_date
        ? true
        : createError({
              message: 'Due Date should be after invoice date',
              path: 'due_date',
          });
});

export const InvoicePrice = (products: any, useTaxes: false) => {
    let subTotal = 0,
        totalTax = 0;
    if (products && products.length > 0) {
        products.forEach((product: any) => {
            subTotal += parseFloat(productAmount(product));
            totalTax += product?.tax_rate
                ? (product?.tax_rate *
                      parseFloat(productAmount(product))) /
                  100
                : 0;
        });
    }

    const SubTotalPrice = subTotal.toFixed(2);
    const TotalTaxPrice = useTaxes
        ? (Math.round(totalTax * 100) / 100).toFixed(2)
        : '0.00';

    const TotalPrice = (
        parseFloat(SubTotalPrice) + parseFloat(TotalTaxPrice)
    ).toFixed(2);

    return [SubTotalPrice, TotalTaxPrice, TotalPrice];
};

export const productAmount = (product: any) => {
    const productPrice = parseFloat(product?.price) || 0
    const productQuantity = product?.offering_quantity || 1
    const roundedPrice = Math.round(productPrice * productQuantity * 100) / 100
    return roundedPrice.toFixed(2);
};

export const NewInvoiceValues = {
    contact_data: '',
    payment_terms: 'net30',
    message_to_customer: '',
    use_taxes: false,
    invoice_lines: [
        {
            amount: '',
            offering: '',
            offering_data: {
                price: 0,
            },
            offering_quantity: 1,
            price: 0,
            tax_rate: 0
        },
    ],
    invoice_date: startOfDayTimeStamp,
    due_date: _timestamp(new Date(moment().add(30,'day').toDate())),
    online_payment_enabled: false
};
