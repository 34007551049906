import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getMessagesCenterTopics } from '../../services/apiService';
import Loader from '../common/Loader';
import UiDialog from '../common/ui/UiDialog';
import UiSnackbarAlert from '../common/ui/UiSnackbarAlert';
import NewMessageForm from './NewMessageForm';

interface NewMessageModalProps {
    open: boolean;
    handleClose: (result?: any) => void;
    topics?: any[];
    topic?: string;
}

export default function NewMessageModal({
    open,
    handleClose,
    topics,
    topic,
}: NewMessageModalProps) {
    const [loading, setLoading] = useState(false);
    const [topicsData, setTopicsData] = useState([]);

    // alert state
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageActionText, setAlertMessageActionText] = useState('');
    const [closeMessage, setCloseMessage] = useState('');
    const [retryAction, setRetryAction] = useState(() => () =>
        console.log('default')
    );

    const history = useHistory();
    const xsDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    const handleAlertClose = (message: any) => {
        if (message === 'fail') {
            retryAction();
        } else if (message.id) {
            history.push(`/message_center/${message.id}`);
        }
        setShowAlert(false);
    };

    const handlePostMessageSentEvent = (result: any, action?: any) => {
        if (result === 'fail') {
            setAlertMessage('Failed to send');
            setAlertMessageActionText('Retry');
            setCloseMessage('fail');
            setRetryAction(action);
            setShowAlert(true);
            return;
        } else if (result.id) {
            setAlertMessage('Message Sent');
            setAlertMessageActionText('View Conversation');
            setCloseMessage(result);
            setShowAlert(true);
            handleClose(result);
        }
    };
    useEffect(() => {
        if (open) {
            if (!topics) {
                setLoading(true);
                getMessagesCenterTopics().then((res: any) => {
                    setTopicsData(res);
                    setLoading(false);
                });
            }
        }
    }, [open, topics]);

    return (
        <>
            <UiSnackbarAlert
                open={showAlert}
                message={alertMessage}
                handleClose={handleAlertClose}
                actionButtonMessage={alertMessageActionText}
                actionButtonColor={'primary'}
                closeMessage={closeMessage}
            />
            <UiDialog
                open={open}
                handleClose={handleClose}
                title='New Message'
                size='sm'
                fullScreen={xsDevice}
            >
                {loading ? (
                    <Box height='100px'>
                        <Loader />
                    </Box>
                ) : (
                    <NewMessageForm
                        topics={topics || topicsData}
                        sendMessage={handlePostMessageSentEvent}
                        topic={topic}
                        handleClose={handleClose}
                    />
                )}
            </UiDialog>
        </>
    );
}