import store from '../../store'
import {
    getResource,
    deleteResource,
    postResource,
    patchResource,
} from '../axiosService'
import { jsonToRql } from '../../utils/rql'
import {
    MilesTypesMapping,
    snackbar,
} from '../../components/mileage-log/trips/TripUtil'
import { showError } from './../../services/formService'
import type {
    TripQueryObject,
    TripList,
    TripResponseError,
    RequestData,
} from '../../models/trips'
import { Trip } from '../../models'

export interface ObjectResponseType {
    id: string
    name: string
    [key: string]: any
}

export type ResponseType = [
    ObjectResponseType[],
    ObjectResponseType[],
    ObjectResponseType[]
]

type TripsRequestParams = {
    perPage: number
    page: number
    rql?: string
}

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id
    const accountId = currentStore.appData.current_account_id
    const mileageTrackerBaseURL = `api/account/${accountId}/mileage_tracker`
    const tripsBaseURL = `api/account/${accountId}/mileage_tracker/trip`
    return { businessId, accountId, mileageTrackerBaseURL, tripsBaseURL }
}

const getRQL = (filters: TripQueryObject) => {
    const {
        term,
        dateFrom,
        dateTo,
        period,
        refNumber,
        refNumberType,
        activity,
        milesType,
        miles,
    } = filters
    const isAnyPeriod = period === 'All Time' || period === ''
    const isDatePresent = dateFrom && dateTo
    const location =
        typeof filters.location === 'object'
            ? filters.location.id
            : filters.location
    const vehicle =
        typeof filters.vehicle === 'string'
            ? filters.vehicle
            : filters.vehicle?.id
    const purpose =
    typeof filters.purpose === 'string'
        ? filters.purpose
        : filters.purpose?.id    
            
    const queryObject = {
        activity: activity?.toLowerCase(),
        term,
        vehicle,
        location,
        purpose,
        period: isAnyPeriod ? null : filters.period,
        number:
            refNumber && refNumberType === 'Contains'
                ? {
                      operator: refNumberType.toLowerCase(),
                      value: refNumber,
                  }
                : refNumber ?? null,
        date: isDatePresent
            ? [
                  { operator: 'ge', value: dateFrom },
                  { operator: 'le', value: dateTo },
              ]
            : null,
        miles: miles && {
            operator: MilesTypesMapping[milesType],
            value: miles,
        },
    }
    return jsonToRql(queryObject)
}

export const fetchTrips = (filters: TripQueryObject, page: number) => {
    const rql = getRQL(filters)

    return getTrips({
        perPage: 25,
        page: page,
        rql,
    })
        .then((response) => {
            return response
        })
        .catch((error?: TripResponseError) => {
            showError(
                error ? error.response.data.message : 'Something went wrong'
            )
        })
}

export function getTrips(params: TripsRequestParams): Promise<TripList> {
    const { tripsBaseURL } = getStoreData()
    return getResource(`${tripsBaseURL}`, undefined, params)
}

export function getTripDetails(id: string) {
    const { tripsBaseURL } = getStoreData()
    return getResource(`${tripsBaseURL}/${id}`)
}

export function createTripResource(data: RequestData) {
    const { tripsBaseURL } = getStoreData()
    return postResource(`${tripsBaseURL}`, data)
}

export function createTrip(
    data: RequestData,
    callback: (isError: boolean, newTrip: Trip) => void
) {
    createTripResource(data)
        .then((payload) => {
            callback?.(false, payload as Trip)
            snackbar('success', 'Trip created successfully')
        })
        .catch((error?: TripResponseError) => {
            showError(
                error ? error.response?.data?.message : 'Something went wrong'
            )
            callback?.(true, {} as Trip)
        })
}

export function updateTripResource(tripId: string, data: RequestData) {
    const { tripsBaseURL } = getStoreData()
    return patchResource(`${tripsBaseURL}/${tripId}`, data)
}

export function updateTrip(
    tripId: string,
    data: RequestData,
    callback: (isError: boolean, newTrip: Trip) => void
) {
    updateTripResource(tripId, data)
        .then((payload) => {
            callback?.(false, payload as Trip)
            snackbar('success', 'Trip updated successfully')
        })
        .catch((error?: TripResponseError) => {
            showError(
                error ? error.response?.data?.message : 'Something went wrong'
            )
            callback?.(true, {} as Trip)
        })
}

export function deleteTripResource(tripId: string) {
    const { tripsBaseURL } = getStoreData()
    return deleteResource(`${tripsBaseURL}/${tripId}`)
}

export function deleteTrip(tripId: string, callback: (isError: boolean) => void) {
    deleteTripResource(tripId)
        .then(() => {
            callback?.(false)
            snackbar('success', 'Trip deleted successfully')
        })
        .catch((error?: TripResponseError) => {
            callback?.(true)
            showError(
                error ? error.response?.data?.message : 'Something went wrong'
            )
        })
}

export function getAllVehicles() {
    const { mileageTrackerBaseURL } = getStoreData()
    const url = `${mileageTrackerBaseURL}/vehicles`
    return getResource<ObjectResponseType[]>(url)
}

export function getAllLocations() {
    const { mileageTrackerBaseURL } = getStoreData()
    const url = `${mileageTrackerBaseURL}/locations`
    return getResource<ObjectResponseType[]>(url)
}

export function getAllPurposes() {
    const { mileageTrackerBaseURL } = getStoreData()
    const url = `${mileageTrackerBaseURL}/purposes`
    return getResource<ObjectResponseType[]>(url)
}
