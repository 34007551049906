import { useEffect, useState } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import InfoTooltip from '../../common/InfoTooltip';
import { useReportsStyles } from './styles/reports-styles';
import { DATE_TYPES } from './constants/reports.const';
import Loader from '../../common/Loader';
import { getTimeParamsWithoutEST } from '../../../utils/dateUtil';
import UiText from '../../common/ui/UiText';
import FormikAutocomplete from './utils/Form/AutoComplete';
import SelectDates from './utils/Date/SelectDates';
import { FormObserver } from './utils/Form/FormObserver';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { ErrorAlertReport } from './models/reports-interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface FormValues {
    from: number;
    to: number;
    account: any;
}

export type Chip = {
    for: string;
    id: any;
};

interface Props {
    chips: Chip[];
    pageTitle: string;
    tooltipText: string;
    businessName: string;
    generateReport: (data: any) => void;
    isLoading?: boolean;
    accountList: any;
    errorAlert: ErrorAlertReport | undefined;
    resetErrorAlert: () => void;
    preSelectAccount?: any;
    setPreselectAccount?: (data: any) => void;
    disableFutureDate? : boolean;
    preSelectedDates?: any
    isThisYearSelected?: boolean | null
}
const initialFormValues = {
    from: moment.utc().hour(12).minute(0).second(0).unix(),
    to: moment.utc().hour(12).minute(0).second(0).unix(),
    account: '',
};

export default function SelectAccountAndDates({
    chips,
    pageTitle,
    tooltipText,
    businessName,
    generateReport,
    isLoading,
    accountList,
    errorAlert,
    resetErrorAlert,
    preSelectAccount,
    setPreselectAccount,
    disableFutureDate = false,
    preSelectedDates,
    isThisYearSelected
}: Props) {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const [isHideFromDateAndToDate, setIsHideFromDateAndToDate] = useState<boolean>(false);

    const onSubmit = (values: FormValues) => {
        generateReport(values);
    };

    useEffect(() => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            true
        );
        setinitialValues((prev) => ({
            ...prev,
            from: preSelectedDates.startDate || startDate,
            to: preSelectedDates.endDate || endDate,
        }));
        
        if (isThisYearSelected === null) {
            setSelectedDateChip(DATE_TYPES.THIS_YEAR)
        } else {
            isThisYearSelected
                ? setSelectedDateChip(DATE_TYPES.THIS_YEAR)
                : resetDateChips()
        }
    }, [isThisYearSelected]);

    useEffect(() => {
        setinitialValues((prev) => ({
            ...prev,
            account: preSelectAccount ?? '',
        }));
    }, [preSelectAccount]);
    
    const checkIfFromAndToDatesAreToBeHidden = (dateType: string)=>{
        if(dateType === DATE_TYPES.ALL_TIME) {
            setIsHideFromDateAndToDate(true);
            return;
        }
        setIsHideFromDateAndToDate(false);
    }

    const updateDates = (dateType: { for: string; id: any }): void => {
        setSelectedDateChip(dateType.for);
        const { startDate, endDate } = getTimeParamsWithoutEST(
            dateType.for,
            true
        );
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
        checkIfFromAndToDatesAreToBeHidden(dateType.for);
    };
    const validationSchema = Yup.object({
        account: Yup.object().required('Account is required').nullable(),
    });
    const resetDateChips = () => {
        setSelectedDateChip('');
    };

    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        values.account && setPreselectAccount && setPreselectAccount(values.account);
        const fromDateChanged = formikObject.touched?.from;
        const toDateChanged = formikObject.touched?.to;
        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };
    return (
        <div className={reportsClasses.reportsViewParent}>
            <UiText className='businessName'>{businessName}</UiText>
            <UiText
                variant='suv_150'
                weight='semi_bold_600'
                className={reportsClasses.pageTitle}
            >
                {pageTitle}
                <InfoTooltip tooltipText={tooltipText} />
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue }) => {
                    return (
                        <Form>
                            <FormObserver onformChange={onformChangeCallback} />
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    <UiText
                                        weight='medium_500'
                                        style={{ marginBottom: '0.625rem' }}
                                    >
                                        Account
                                    </UiText>

                                    <Field
                                        name='account'
                                        required
                                        validate={(value: any) => {
                                            return JSON.stringify(value) ===
                                                JSON.stringify({})
                                                ? 'Account is required'
                                                : '';
                                        }}
                                    >
                                        {({ ...InputProps }: any) => (
                                            <FormikAutocomplete
                                                {...InputProps}
                                                label='Select account'
                                                placeholder='Select Account'
                                                formValues={values}
                                                listData={accountList}
                                                titleKey='title'
                                                idKey='id'
                                                showSubtitle
                                                showAddButton={false}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                    </Field>
                                    <SelectDates
                                        chips={chips}
                                        handleUpdateDates={updateDates}
                                        selectedChip={selectedDateChip}
                                        isHideFromAndToDates={isHideFromDateAndToDate}
                                        disableFutureDate
                                    />
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        color='primary'
                                        data-cy='generate-report-btn'
                                    >
                                        Generate Report
                                    </Button>
                                </>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
