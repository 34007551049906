import React from 'react'
import { Box, Theme, makeStyles } from '@material-ui/core'
import UiDialog from '../../common/ui/UiDialog'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import UiButton from '../../common/ui/UiButton'
import UiText from '../../common/ui/UiText'
import { useBankingInfoState } from './provider/BankingInfoProvider'
interface PastDueNoticeModalProps {
    openModal: boolean
    closeModal: (params: any) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: '31.25rem'
        },
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
            padding: '1rem 1rem 0px',
        },
    },
    title: {
        textAlign: 'center',
        marginBottom: 24,
    },
}))

const ErrorDialog = ({ openModal, closeModal }: PastDueNoticeModalProps) => {
    const classes = useStyles()
    const styles = commonStyles()

    const { setDialog, setIsModalOpen } = useBankingInfoState()

    const handleBankAccount = () => {
        setDialog(false)
        setIsModalOpen(true)
    }

    return (
        <UiDialog
            open={openModal}
            handleClose={closeModal}
            title=""
            size="sm"
            customRootClass={classes.container}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div>
                <UiText
                    variant="hatchback_125"
                    weight="bold_700"
                    className={classes.title}
                >
                    {' '}
                    Something went wrong. Please try again.
                </UiText>
            </div>
            <div className={mergeStyle(styles.flex, styles.justifyCenter)}>
                <UiButton
                    btnType="tertiary"
                    handleClick={() => setDialog(false)}
                    label="Ok"
                />
                <Box marginLeft={2}>
                    <UiButton
                        btnType="tertiary"
                        handleClick={handleBankAccount}
                        label="Add Bank Account Manually"
                    />
                </Box>
            </div>
        </UiDialog>
    )
}

export default ErrorDialog
