import { useEffect, useState, useCallback, Fragment } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    resetParams,
    defaultQueryObject,
    getFilterChips,
    Chip,
} from './TripUtil'
import { showError } from './../../../services/formService'
import { ApplicationStore } from '../../../models/store'
import type { TripQueryObject } from '../../../models'
import { loadPostAuthData } from '../../../store/actions/appData'
import AddIcon from '@material-ui/icons/Add'
import SearchBox from '../../common/SearchBox'
import MobileHeader from '../../common/MobileHeader'
import {
    Container,
    Box,
    Button,
    Divider,
    makeStyles,
    createStyles,
    Theme,
    Grid,
    Hidden,
    IconButton,
} from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import UiChip from '../../../components/common/ui/UiChip'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import { Trip, LoadingStates } from '../../../models'
import FilterOverlay from './FilterOverlay'
import TripOverlayForm from './Form/TripOverlayForm'
import TripList from './TripList'
import TripPreview from './TripPreview'
import { fetchTrips } from '../../../services/apiService/trips'
import Loader from '../../common/Loader'
import EmptyScreen from './EmptyScreen'
import type { PaginatedTripsResponse } from '../../../models/trips'
import { useFiltersContext } from './Provider/FiltersProvider'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up(1601)]: {
                justifyContent: 'flex-start',
            },
            alignSelf: 'flex-start',
        },
        listContent: {
            paddingBottom: theme.spacing(3),
            width: 'inherit',
            height: '100%',
        },
        chip: {
            marginTop: '0.5rem',
            marginRight: '0.25rem',
        },
        filterContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        },
        buttonsParent: {
            display: 'flex',
        },
        filterButton: {
            margin: '1rem 0 0 0.25rem',
            height: 'auto',
            paddingTop: '0',
            paddingBottom: '0',
        },
        newTripBtnParent: {
            display: 'flex',
            marginTop: '1rem',
        },
        mt1: {
            marginTop: '1rem',
        },
    })
)

const Trips = () => {
    const classes = useStyles()
    const { path } = useRouteMatch()
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedTrip, setSelectedTrip] = useState<Trip>()
    const {queryObject, setQueryObject} = useFiltersContext();
    const [isFormOverlayOpen, setIsFormOverlayOpen] = useState<boolean>(false)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [runFilterCallBack, setRunFilterCallBack] = useState<boolean>(false)

    const [filterOverlayOpen, setFilterOverlayOpen] = useState(false)

    const [activeChips, setActiveChips] = useState<Chip[]>(getFilterChips(queryObject))

    const [tripsData, setTripsData] = useState<
        PaginatedTripsResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 10,
        pageCount: 0,
        itemsCount: 0,
        loading: true,
        hasMore: false,
    })

    type TripsData = typeof tripsData

    const { loading, items, itemsCount, page, pageCount } = tripsData


    useEffect(()=>{
        if((firstLoad || runFilterCallBack) && tripsData.items.length){
            setSelectedTrip(tripsData.items[0])
        }
    },[firstLoad, tripsData, runFilterCallBack])

    const setLoadingTripsData = useCallback(() => {
        setTripsData((prev: TripsData) => ({
            ...prev,
            loading: true,
        }))
    }, [])

    const fetchTripList = useCallback(
        (page: number) => {
            setLoadingTripsData()
            fetchTrips(queryObject, page)
                .then((response) => {
                    response &&
                        setTripsData((prev: TripsData) => ({
                            ...prev,
                            ...response,
                            items:
                                response.page === 1
                                    ? response.items
                                    : prev.items.concat(response.items),
                            loading: false,
                            hasMore: response.page < response.pageCount,
                        }))
                        setFirstLoad(false)
                        setRunFilterCallBack(false);
                })
                .catch(() => {
                    setTripsData((prev: TripsData) => ({
                        ...prev,
                        loading: false,
                    }))
                    showError(
                        'Failed to load trips at this time. Please try again later.'
                    )
                })
        },
        [queryObject, setLoadingTripsData]
    )

    const setCurrentTrip = (trip?: Trip) => {
        setSelectedTrip(trip)
    }

    const loadTripList = useCallback(() => {
        setLoadingTripsData()
        fetchTripList(1)
    }, [fetchTripList, setLoadingTripsData])

    const loadMoreTrips = useCallback(() => {
        const { page } = tripsData
        if(!loading) {
            setLoadingTripsData()
            setTripsData((prev: TripsData) => ({
                ...prev,
                page: page + 1,
            }))
            fetchTripList(page + 1)
        }
    },[fetchTripList, loading, tripsData, setLoadingTripsData])

    

    const onChipDeleteCallout = (chipKey: keyof TripQueryObject) => {
        const filteredChips = activeChips.filter((e: Chip) => e.key !== chipKey)
        setActiveChips(filteredChips)
        setQueryObject((prev) => ({
            ...prev,
            ...resetParams(chipKey),
        }))
        chipKey === 'term' && setSearchQuery('')
    }

    const PageLoader = () => {
        return (
            <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.container}
            >
            <Loader />
            </Grid>
        )
    }

    const EmptyView = () => {
        return (
            <Fragment>
                <EmptyScreen openOverlay={() => setIsFormOverlayOpen(true)} />
                <TripOverlayForm
                    isOpen={isFormOverlayOpen}
                    handleClose={() => setIsFormOverlayOpen(false)}
                    reloadData={loadTripList}
                    submitCallback={setCurrentTrip}
                    isNew={true}
                />
            </Fragment>
        )
    }


    useEffect(() => {
        setLoadingTripsData()
        fetchTripList(1)
    }, [fetchTripList, setLoadingTripsData])

    // useEffect(() => {
    //     const sortedTrips = tripsData?.items.sort((a, b) => {
    //         return b.number - a.number
    //     })
    //     if (sortedTrips.length > 0) {
    //         const lastTrip = sortedTrips[0]
    //         setCurrentTrip(lastTrip)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [tripsData])

    if(page === 1 && loading) {
        return <PageLoader />
    } if(!loading && items.length === 0 && queryObject === defaultQueryObject) {
        return <EmptyView />
    }

    return (
        <Container className={classes.container} maxWidth="lg">
            <MobileHeader title={'Trips'} showBottomDivider={true} />
            <TripOverlayForm
                isOpen={isFormOverlayOpen}
                handleClose={() => setIsFormOverlayOpen(false)}
                reloadData={loadTripList}
                submitCallback={setCurrentTrip}
                isNew={true}
            />
            <FilterOverlay
                open={filterOverlayOpen}
                handleClose={() => {
                    setFilterOverlayOpen(false)
                }}
                queryObject={queryObject}
                setQueryObject={(data) => {
                    setRunFilterCallBack(true);
                    setQueryObject(data)
                }}
                setChipsToDisplay={setActiveChips}
            />
            <Grid container spacing={1}>
                <Grid
                    container
                    item
                    md={5}
                    sm={12}
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.content}
                >
                    <div className={classes.listContent}>
                        <div className={classes.newTripBtnParent}>
                            <Box my={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => setIsFormOverlayOpen(true)}
                                >
                                    New Trip
                                </Button>
                            </Box>
                        </div>

                        {activeChips.map((chip) => (
                            <UiChip
                                key={chip.key}
                                customRootStyles={classes.chip}
                                label={
                                    <UiText variant="motorcycle_90">
                                        {chip?.label}
                                    </UiText>
                                }
                                onDeleteCallout={() => {
                                    onChipDeleteCallout(chip.key)
                                }}
                            />
                        ))}
                        <div className={classes.filterContainer}>
                            <Grid item md={12} xs={12} className={classes.mt1}>
                                <SearchBox
                                    searchTerm={searchQuery}
                                    setInput={(term) => {
                                        setSearchQuery(term)
                                    }}
                                    placeHolder={'Search'}
                                    notched={false}
                                    fullWidth={true}
                                    clearSearch={() => {
                                        onChipDeleteCallout('term')
                                    }}
                                    onKeyPressEvent={(event) => {
                                        if (event.key === 'Enter') {
                                            const chipsToDisplay =
                                                getFilterChips({
                                                    ...queryObject,
                                                    term: searchQuery,
                                                })
                                            setActiveChips(chipsToDisplay)
                                            setQueryObject((prev) => ({
                                                ...prev,
                                                term: searchQuery,
                                            }))
                                            setRunFilterCallBack(true)
                                        }
                                    }}
                                />
                            </Grid>
                            <div className={classes.buttonsParent}>
                                <Hidden smDown>
                                    <Button
                                        startIcon={<FilterListRoundedIcon />}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            setFilterOverlayOpen(true)
                                        }}
                                        className={classes.filterButton}
                                    >
                                        Filters
                                    </Button>
                                </Hidden>
                                <Hidden mdUp>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setFilterOverlayOpen(true)
                                        }}
                                        className={classes.filterButton}
                                    >
                                        <FilterListRoundedIcon />
                                    </IconButton>
                                </Hidden>
                            </div>
                        </div>
                    </div>
                    <TripList
                        items={items}
                        itemsCount={itemsCount}
                        isLoading={loading}
                        page={page}
                        pageCount={pageCount}
                        loadMore={loadMoreTrips}
                        hasMore={page < pageCount}
                        selectedTrip={selectedTrip}
                        setSelectedTrip={setSelectedTrip}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid
                        sm={1}
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                    >
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Switch>
                            <Route exact path={path}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    className={classes.container}
                                >
                                    <>
                                        {selectedTrip ? (
                                            <TripPreview
                                                selectedTrip={selectedTrip}
                                                setSelectedTrip={setSelectedTrip}
                                                reloadData={loadTripList}
                                                trips={items}
                                            />
                                        ) : (
                                            <UiText>
                                                Select a trip to view the details
                                            </UiText>
                                        )}
                                    </>
                                </Grid>
                            </Route>
                        </Switch>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    )
}
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    filtersConfig: state.config.local.filters,
    transactionFilterTypes: state.config.apiConfig.transaction.filter_types,
    category: state.category,
})

export default connect(mapStateToProps, { loadPostAuthData })(Trips)
