import {
    Box,
    Theme,
    makeStyles,
    Typography,
    IconButton,
} from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import ProgressTracker from './Progresstracker'
import {
    ESTIMATED_COMPLETION_DAYS,
    STATUS_MAPPING,
    toolTipInfo,
} from '../constants/tax-prep.const'
import { ReactComponent as InfoIcon } from '../../../../assets/icons-svg/InfoI.svg'
import InfoTooltip from '../../../common/InfoTooltip'
import useModal from './hooks/useModal'
import ProgressStepSummaryModal from '../modals/ProgressStepSummaryModal'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    widgetTitle: {
        fontSize: 14,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'self-start',
    },
    subTitle: {
        color: (colorTheme) => colorTheme.grey400,
    },
    headingTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    Icon: {
        height: '11.67px',
        width: '11.67px',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    hyperTextColor: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        cursor: 'pointer',
    },
    taxWidgetHeader: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
        },
    },
}))

interface Props {
    title: string
    status?: string | undefined
    vtoWidgetData?: any
}

const TaxWidgetHeader = ({ title, status, vtoWidgetData }: Props) => {
    //@ts-ignore
    const currentStep = status && STATUS_MAPPING[status]?.currentStep
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    const { open, openModal, closeModal } = useModal()

    const getEstimatedCompletionDate = () => {
        if (vtoWidgetData?.tax_ap_status === 'additional_info_request_sent') {
            return 'Waiting on Additional Info'
        } else if (vtoWidgetData?.estimated_completion < 0) {
            return 'Shortly'
        } else if (vtoWidgetData?.estimated_completion > 14) {
            return ESTIMATED_COMPLETION_DAYS
        } else if (vtoWidgetData?.estimated_completion) {
            return `${vtoWidgetData?.estimated_completion} Days`
        } else {
            return ESTIMATED_COMPLETION_DAYS
        }
    }

    const estimatedCompletionDate = getEstimatedCompletionDate()

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        openModal()
                    }}
                    className={classes.taxWidgetHeader}
                ></div>
                <Box className={classes.headingTitleContainer}>
                    <UiText variant="car_100" weight="semi_bold_600">
                        {title}
                    </UiText>
                    <Box
                        className={classes.hyperTextColor}
                        onClick={(e) => {
                            e.stopPropagation()
                            openModal()
                        }}
                        style={{
                            color: `${
                                status !== 'Completed'
                                    ? colorTheme.blue200
                                    : colorTheme.primaryWhite
                            }`,
                        }}
                    >
                        See More
                    </Box>
                </Box>
                {status !== 'Completed' && (
                    <Typography
                        className={`${classes.subTitle} ${classes.widgetTitle}`}
                        component="p"
                    >
                        Estimated Completion: &nbsp;
                        <Typography
                            className={`${classes.subTitle} ${classes.widgetTitle}`}
                            component="span"
                        >
                            {estimatedCompletionDate}&nbsp;
                            <InfoTooltip
                                tooltipText={toolTipInfo}
                                customStyle={{
                                    width: 'auto',
                                    minWidth: '30rem',
                                }}
                                placement="top"
                                customNode={
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                        className={classes.Icon}
                                        aria-label="info"
                                    >
                                        <InfoIcon className={classes.Icon} />
                                    </IconButton>
                                }
                            />
                        </Typography>
                    </Typography>
                )}
            </div>

            <Box
                style={{
                    width: '100%',
                    marginTop: '0.5rem',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <ProgressTracker
                    progressBarColor={
                        status === 'Completed' ? colorTheme.teal100 : colorTheme.tertiary
                    }
                    currentStep={currentStep}
                />
            </Box>
            <ProgressStepSummaryModal
                open={open}
                handleClose={closeModal}
                title={title}
                estimatedCompletionDate={estimatedCompletionDate}
            />
        </div>
    )
}

export default TaxWidgetHeader
