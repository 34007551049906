import { useEffect, useState } from 'react';
import {
    Button,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getInvoiceReports } from '../../../services/apiService/reports';
import store from '../../../store';
import { reportsDataActions } from '../../../store/actions/reportsData';
import { toRem16 } from '../../../styles/commonStyles';
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil';
import { COLORS } from '../../../variables/colors';
import InfoTooltip from '../../common/InfoTooltip';
import Loader from '../../common/Loader';
import UiChip from '../../common/ui/UiChip';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiText from '../../common/ui/UiText';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
    VIEW_INVOIDES_BY,
    VIEW_INVOIDES_BY_API_PARAMS,
    EMPTY_PARAMS,
} from './constants/reports.const';
import {
    ApiError,
    ErrorAlertReport,
    InvoiceReportRoot,
} from './models/reports-interface';
import { useReportsStyles } from './styles/reports-styles';
import { showError } from '../../../services/formService';
import { FormObserver } from './utils/Form/FormObserver';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { ReactComponent as CalendarIcon } from '../../../assets/icons-svg/Calendar.svg';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface FormValues {
    fromDate: number;
    toDate: number;
}
const initialFormValues = {
    fromDate: moment.utc().hour(12).minute(0).second(0).unix(),
    toDate: moment.utc().hour(12).minute(0).second(0).unix(),
};
type SelectedDatePeriod =
    | DATE_TYPES.THIS_MONTH
    | DATE_TYPES.THREE_MONTHS
    | DATE_TYPES.THIS_YEAR
    | DATE_TYPES.ALL_TIME;

const IS_GET_DATE_TILL_TODAY = false;
const TOOLTIP_TEXT = 'The Invoices Report shows all your company’s invoices based on the issue or due date during the selected period of time.';
const DISABLE_FUTURE_DATES = false;

const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        tabbedButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            border: (colorTheme) => `${toRem16(1)} solid ${colorTheme.black200}`,
            borderRadius: toRem16(2),
            margin: '1rem 0 2rem 0',
            '& .tab': {
                padding: '1.25rem 0',
                flex: 1,
                textAlign: 'center',
                color: (colorTheme) => colorTheme.black100,
            },
            '& .selectedTabBtn': {
                '& p': {
                    fontWeight: '600',
                },
                color: (colorTheme) => colorTheme.primaryWhite,
                background: (colorTheme) => colorTheme.secondary,
                borderRadius: 0,
            }
        },
        viewInvoicesHeader: {
            margin: '2rem 0 1rem 0',
        },
        selectPeriodHeader: {
            margin: '0 0 1rem 0',
        },
    })
);
export default function InvoiceReport() {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const storeData = useCurrentStore();
    const businessName = storeData.currentBusiness?.name;
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const localClasses = useLocalStyles(colorTheme);
    const [showFromAndToDates, setShowFromAndToDates] = useState<boolean>(true);
    const history = useHistory();
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const datePeriods = [
        DATE_TYPES.THIS_MONTH,
        DATE_TYPES.THREE_MONTHS,
        DATE_TYPES.THIS_YEAR,
        DATE_TYPES.ALL_TIME,
    ];

    const [viewInvoiceBy, setViewInvoiceBy] = useState<string>(
        VIEW_INVOIDES_BY.ISSUE_DATE
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    
    useEffect(() => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            IS_GET_DATE_TILL_TODAY
        );
        setinitialValues((prev) => ({
            ...prev,
            fromDate: startDate ?? 0,
            toDate: endDate ?? 0,
        }));
    }, []);
    const getFormattedTime = (
        dateType: SelectedDatePeriod,
        IS_GET_DATE_TILL_TODAY: boolean
    ) => {
        return getTimeParamsWithoutEST(dateType, IS_GET_DATE_TILL_TODAY);
    };

    const getDatesAndChangeFromValues = (
        selectedPeriod: SelectedDatePeriod
    ) => {
        const formattedDate = getFormattedTime(
            selectedPeriod,
            IS_GET_DATE_TILL_TODAY
        );
        setinitialValues((prev) => ({
            ...prev,
            fromDate: formattedDate?.startDate ?? 0,
            toDate: formattedDate?.endDate ?? 0,
        }));
    };
    const hideShowFromAndToDates = (selectedPeriod: SelectedDatePeriod) => {
        if (selectedPeriod === DATE_TYPES.ALL_TIME) {
            setShowFromAndToDates(false);
            return;
        }
        setShowFromAndToDates(true);
    };
    const getFormattedDate = (dateInSecs: number, periodTextFormat: string) => {
        const dateInMs = dateInSecs * 1000;
        const date = convertUnixToGivenDateFormat(periodTextFormat, dateInMs);
        return date;
    };
    const getPeriodTextWithFromAndToDate = (
        from: number,
        to: number
    ): string => {
        const periodTextFormat = DATE_FORMATS.periodText;
        const fromDate = getFormattedDate(from, periodTextFormat);
        const toDate = getFormattedDate(to, periodTextFormat);
        const periodText = `for the period from ${fromDate} to ${toDate}`;
        return periodText.replace(findSpaceRegex, '+');
    };
    const getPeriodText = (fromDate: number, toDate: number): string => {
        const periodTextAllTime = DATE_TYPES.ALL_TIME;
        return !fromDate || !toDate
            ? periodTextAllTime.replace(findSpaceRegex, '+')
            : getPeriodTextWithFromAndToDate(fromDate, toDate);
    };

    const getDateFilterFormattedValue = (): string => {
        return viewInvoiceBy === VIEW_INVOIDES_BY.ISSUE_DATE ? VIEW_INVOIDES_BY_API_PARAMS.ISSUE : VIEW_INVOIDES_BY_API_PARAMS.DUE;
    };
    const fetchAPIQueryParams = (fromDate: number, toDate: number) => {
        const accountId = storeData.currentAccountId;
        const businessId = storeData.currentBusinessId;
        const dateFilter = getDateFilterFormattedValue();
        const periodText = getPeriodText(fromDate, toDate);
        const time = getCurrentTimeStamp();
        const startDateYmd = !fromDate ? null : getDateInYmd(fromDate);
        const endDateYmd = !toDate ? null : getDateInYmd(toDate);
        return {
            accountId,
            businessId,
            dateFilter,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        };
    };
    const dispatchReportsDataToStore = (data: InvoiceReportRoot) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };

    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };
    const navigateToDetailedInvoiceReport = (
        periodText: string,
        time: number,
        dateFilter: string,
        startDateYmd: string | null,
        endDateYmd: string | null
    ) => {
        const startDate = startDateYmd || EMPTY_PARAMS.START_DATE_YMD;
        const endDate = endDateYmd || EMPTY_PARAMS.END_DATE_YMD;
        const detailedReportUrl = `/reports/invoice/${periodText}/${time}/${dateFilter}/${startDate}/${endDate}`;
        history.push(detailedReportUrl);
    };

    const getReportsDataAndNavigate = (fromDate: number, toDate: number) => {
        const {
            accountId,
            businessId,
            dateFilter,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        } = fetchAPIQueryParams(fromDate, toDate);
        if (!businessId) return;
        setIsLoading(true);
        getInvoiceReports(
            accountId,
            businessId,
            dateFilter,
            periodText,
            time,
            startDateYmd,
            endDateYmd
        )
            .then((result: unknown) => {
                setIsLoading(false);
                const data = result as InvoiceReportRoot;
                dispatchReportsDataToStore(data);
                navigateToDetailedInvoiceReport(
                    periodText,
                    time,
                    dateFilter,
                    startDateYmd,
                    endDateYmd
                );
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };
    const onSubmit = (values: FormValues) => {
        const { fromDate, toDate } = values;
        getReportsDataAndNavigate(fromDate, toDate);
    };
    const updateSelectedPeriodTime = (selectedPeriod: SelectedDatePeriod) => {
        setSelectedDateChip(selectedPeriod);
        hideShowFromAndToDates(selectedPeriod);
        getDatesAndChangeFromValues(selectedPeriod);
    };

    const createTimePeriodChips = () => {
        return datePeriods.map((period: string, index: number) => {
            return (
                <UiChip
                    label={<Typography variant='body2'>{period}</Typography>}
                    onClickCallout={() => {
                        updateSelectedPeriodTime(period as SelectedDatePeriod);
                    }}
                    customRootStyles={
                        selectedDateChip === period
                            ? 'selectedChip'
                            : 'customChip'
                    }
                    key={index}
                    cypressId={`${period
                        .toLocaleLowerCase()
                        .replace(' ', '-')}-chip-btn`}
                />
            );
        });
    };
    const toggleTabbedButton = () => {
        if (viewInvoiceBy === VIEW_INVOIDES_BY.ISSUE_DATE) {
            setViewInvoiceBy(VIEW_INVOIDES_BY.DUE_DATE);
            return;
        }
        setViewInvoiceBy(VIEW_INVOIDES_BY.ISSUE_DATE);
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        const fromDateChanged = formikObject.touched?.fromDate;
        const toDateChanged = formikObject.touched?.toDate;

        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };

    return (
        <div className={reportsClasses.reportsViewParent}>
            <span className='businessName'>{businessName}</span>
            <UiText variant='suv_150' weight='semi_bold_600' className={reportsClasses.pageTitle}>
                Invoices
                <InfoTooltip tooltipText={TOOLTIP_TEXT}></InfoTooltip>
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}
            <UiText
                weight='medium_500'
                className={localClasses.viewInvoicesHeader}
            >
                View invoices by
            </UiText>
            <div className={localClasses.tabbedButtons}>
                <Button
                    className={
                        viewInvoiceBy === VIEW_INVOIDES_BY.ISSUE_DATE
                            ? 'selectedTabBtn tab'
                            : 'tab'
                    }
                    onClick={toggleTabbedButton}
                    data-cy='issue-date-btn'
                >
                    <UiText weight='regular_400'>
                        {VIEW_INVOIDES_BY.ISSUE_DATE}
                    </UiText>
                </Button>
                <Button
                    className={
                        viewInvoiceBy === VIEW_INVOIDES_BY.DUE_DATE
                            ? 'selectedTabBtn tab'
                            : 'tab'
                    }
                    onClick={toggleTabbedButton}
                    data-cy='due-date-btn'
                >
                    <UiText weight='regular_400'>
                        {VIEW_INVOIDES_BY.DUE_DATE}
                    </UiText>
                </Button>
            </div>
            <UiText
                weight='medium_500'
                typographyVariant='h4'
                className={localClasses.selectPeriodHeader}
            >
                Select a period of time
            </UiText>

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {(formik) => {
                    return (
                        <Form>
                            <FormObserver onformChange={onformChangeCallback} />
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {showFromAndToDates ? (
                                        <div
                                            className={
                                                `${reportsClasses.dateSelectors} calendarSection` 
                                            }
                                        >
                                            <UiFormControlSelection
                                                label='From'
                                                type='date'
                                                fieldName='fromDate'
                                                dateType='string'
                                                showFloatingLabel={true}
                                                disableFutureDate={DISABLE_FUTURE_DATES}
                                                endIcon={<CalendarIcon />}
                                            />
                                            <div className='dateSeparator'>
                                                -
                                            </div>
                                            <UiFormControlSelection
                                                label='To'
                                                type='date'
                                                fieldName='toDate'
                                                dateType='string'
                                                showFloatingLabel={true}
                                                disableFutureDate={DISABLE_FUTURE_DATES}
                                                endIcon={<CalendarIcon />}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <div
                                        className={
                                            reportsClasses.timePeriodChips
                                        }
                                    >
                                        {createTimePeriodChips()}
                                    </div>

                                    <Button
                                        variant='contained'
                                        type='submit'
                                        color='primary'
                                        data-cy='generate-report-btn'
                                    >
                                        Generate Report
                                    </Button>
                                </>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
