import { EntityManagementState } from "../../models";
import { entityFormationGET } from "../../services/apiService";

export const SET_ENTITY_MANAGEMENT_DATA = "SET_ENTITY_MANAGEMENT_DATA";

export interface EntityManagementAction {
  type: string;
  state: EntityManagementState;
}

export type Action = EntityManagementAction;

export const setEntityManagementData = (data: EntityManagementState): EntityManagementAction => {
  return ({
    type: SET_ENTITY_MANAGEMENT_DATA,
    state: data,
  })
};

export function loadEntityManagamentData(accountId: string, businessId: string) {
  return (dispatch: any) => {
    return entityFormationGET<EntityManagementState>(accountId, businessId)
      .then((res) => dispatch(setEntityManagementData(res)))
      .catch(err => { throw err; })
  };
}
