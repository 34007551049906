import { makeStyles, Theme } from '@material-ui/core'
import { Contractor } from '../../models/contractor.model'
import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog'
import { ContainedDeleteButton } from '../../../common/DeleteButton'
import UiText from '../../../common/ui/UiText'
import { ACTION_TYPE } from '../ContractorItem'

interface ConfirmContractorModalProps {
    isDeletable?: boolean | undefined
    showConfirmModal: boolean
    handleClose: () => void
    contractor?: Contractor
    handleAction: (type: ACTION_TYPE) => void
    loading?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    infoText: {
        marginBottom: theme.spacing(4),
    },
    deleteButton: {
        background: '#E0284A',
    },
}))

const ConfirmContractorModal = ({
    isDeletable,
    showConfirmModal,
    handleClose,
    handleAction,
    contractor,
    loading
}: ConfirmContractorModalProps) => {
    const classes = useStyles()
    let headerText = isDeletable
        ? `Delete Contractor`
        : 'Mark the contractor inactive '
    let infoText = isDeletable
        ? `Do you want to delete the contractor ${contractor?.first_name} ?`
        : `Do you want to mark the contractor ${contractor?.first_name} as inactive?`

    return (
        <UiConfirmationDialog
            open={showConfirmModal}
            message={
                <>
                    <UiText
                        className={classes.infoText}
                        variant="hatchback_125"
                        weight="medium_500"
                    >
                        {headerText}
                    </UiText>
                    <UiText className={classes.infoText}>{infoText}</UiText>
                    <UiText>This cannot be undone.</UiText>
                </>
            }
            handleClose={(result) => {
                handleClose()
            }}
            showLoader={loading}
            confirmNode={
                <ContainedDeleteButton
                    className={classes.deleteButton}
                    variant="contained"
                    showDeleteIcon
                    onClick={() => {
                        handleAction(isDeletable ? ACTION_TYPE.DELETE : ACTION_TYPE.DEACTIVATE)
                    }}
                    color="secondary"
                    data-cy={`${
                        isDeletable ? 'delete' : 'Inactive'
                    }-confirm-button`}
                >
                    {isDeletable ? 'Delete' : 'Inactive'}
                </ContainedDeleteButton>
            }
            cancelButtonText="Cancel"
        />
    )
}

export default ConfirmContractorModal
