import { InputAdornment, TextField } from '@material-ui/core'
import Loader from '../common/Loader'
import { useEffect, useState } from 'react'

export const removeNonNumericCharactors = (value: string) => {
    if (!value) {
        return '0'
    }
    return value.replace(/[^\d.]/g, '')
}

export const formatCurrency = (value: string) => {
    const numericValue = value && value.replace(/[^\d.]/g, '')
    const parts = numericValue.split('.')
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    let formattedValue = '$' + integerPart

    if (parts.length > 1) {
        formattedValue += '.' + parts[1].slice(0, 2) // Limit to two decimal places
    }

    return formattedValue
}

interface InlineCurrencyProps {
    name: string
    value: string
    size: string
    handleOnBlur: (
        data: any,
        cb: (value: boolean) => void,
        initialData?: any
    ) => void
    rowData?: any
    initialValue?: string
    isError?: boolean
    helperText?: string
}

export const CurrencyInputField = (props: InlineCurrencyProps | any) => {
    const [value, setValue] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { handleOnBlur, size, rowData, initialValue, isError, helperText, ...other } = props

    useEffect(() => {
        setValue(formatCurrency(initialValue))
    }, [initialValue])

    const onChange = (event: any) => {
        setValue(event.target.value)
    }
    const handleChange = (event: any) => {
        const inputValue = event.target.value
        const formattedValue = formatCurrency(inputValue)
        onChange({
            target: {
                name: props.name,
                value: formattedValue,
            },
        })
    }
    const onBlur = () => {
        if(formatCurrency(initialValue) === formatCurrency(value)) return;
        setIsLoading(true)
        handleOnBlur(value, setIsLoading, rowData)
    }
    return (
        <TextField
            {...other}
            size={size}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <div style={{ width: '1.25rem' }}>
                            {isLoading && <Loader size={20} />}
                        </div>
                    </InputAdornment>
                ),
            }}
            error={['$','$0'].includes(value)  && isError}
            helperText={['$','$0'].includes(value) && isError ? helperText : null}
        />
    )
}
