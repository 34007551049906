import { Action, reportsDataActions } from '../actions/reportsData'
const initialState: any = {
    reports: {},
}
export default function reportsData(state = initialState, action: Action) {
    switch (action.type) {
        case reportsDataActions.SET_REPORTS_DATA:
            return {
                ...state,
                reports: action.state,
            }
        case reportsDataActions.RESET_REPORTS_DATA:
            return {
                ...state,
                reports: initialState.reports,
            }
        default:
            return state
    }
}
