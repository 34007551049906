const escapeKey = function (s: any) {
    if (typeof s === 'string') {
        s = encodeURIComponent(s).replace(
            /[\-_\.~!\\'\*\(\)]/g,
            function (char) {
                return `%${char.charCodeAt(0).toString(16).toUpperCase()}`;
            }
        );
    }
    return s;
};
const escapeValue = function (value: any) {
    return Array.isArray(value)
        ? `(${value.map(escape).join(',')})`
        : escape(value);
};

const uriDecodeKey = function (s: any) {
    if (typeof s === 'string') {
        s = decodeURIComponent(s);
    }
    return s;
};

const uriDecodeValue = function (value: any) {
    if (value[0] === '(' && value[value.length - 1] === ')') {
        value = value.slice(1, -1);
        return value.split(',').map(uriDecodeKey);
    } else {
        return uriDecodeKey(value);
    }
};

export function rqlToJson<T>(
    rqlQuery: string,
    defaultJson?: T,
    noParseKeys: string[] = []
): T {
    if (!rqlQuery) {
        return defaultJson || ({} as any);
    }
    const rqlQueries = rqlQuery.split('&');
    const rqlJson: any = defaultJson || {};

    rqlQueries.forEach((query) => {
        const queryArray = query.split('=');
        let key, val, operator;

        if (queryArray.length === 2) {
            key = uriDecodeKey(queryArray[0]);
            val = uriDecodeValue(
                isNaN(queryArray[1] as any) ||
                    noParseKeys.includes(queryArray[0])
                    ? queryArray[1]
                    : parseFloat(queryArray[1])
            );
            rqlJson[key] = val;
        } else if (queryArray.length === 3) {
            key = uriDecodeKey(queryArray[0]);
            operator = queryArray[1];
            if (rqlJson[key]) {
                val = uriDecodeValue(
                    isNaN(queryArray[2] as any)
                        ? queryArray[2]
                        : parseFloat(queryArray[2])
                );
                const valueObject = {
                    value: val,
                    operator: operator,
                };
                if (Array.isArray(rqlJson[key])) {
                    rqlJson[key].push(valueObject);
                } else {
                    rqlJson[key] = valueObject;
                }
            } else {
                if (operator === 'like') {
                    rqlJson[key] = {
                        value: uriDecodeValue(queryArray[2].slice(1, -1)),
                        operator: 'contains',
                    };
                } else {
                    rqlJson[key] = {
                        value: uriDecodeValue(queryArray[2]),
                        operator: operator,
                    };
                }
            }
        }
    });

    return rqlJson;
}

export function jsonToRql(json: any) {
    const rql = [];
    for (let key in json) {
        let query;
        const value = json[key];
        if (Array.isArray(value)) {
            let queries: any[] = [];
            value.forEach((val: any) => {
                let subquery = `${escapeKey(key)}=${val.operator}=${escapeValue(
                    val.value
                )}`;
                queries.push(subquery);
            });
            query = queries.join('&');
        } else if (value && typeof value === 'object') {
            if (value.operator === 'contains') {
                query = `${escapeKey(key)}=like=*${escapeValue(value.value)}*`;
            } else {
                query = `${escapeKey(key)}=${value.operator}=${escapeValue(
                    value.value
                )}`;
            }
        } else if (value) {
            query = `${escapeKey(key)}=${escapeValue(value)}`;
        }
        rql.push(query);
    }

    return rql.filter((query) => query).join('&');
}
