import { getResource, patchResource } from '../axiosService';
import {
    UserReviewResponse,
    ShowReviewResponse,
} from '../../models/review.model';

export function showReview(
    reviewSource: string
): Promise<ShowReviewResponse> {
    return getResource(
        `api/user/review/source/${reviewSource}`
    );
}
export function updateRating(
    reviewSource: string,
    rating: number
): Promise<UserReviewResponse> {
    return patchResource(`api/user/review`, {
        review_source: reviewSource,
        review_rating: rating
    });
}
