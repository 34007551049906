import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import Location from '../../../../../assets/icons-svg/Locations.svg';
import { toRem16 } from '../../../../../styles/commonStyles';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';
import { Theme } from '@material-ui/core';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';

interface TooltipProps {
    startLocation: string;
    endLocation: string;
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        tooltipContainer: {
            '& .mileageLogTooltip': {
                position: 'relative',
                display: 'inline-block',
                '& p':{
                    margin: '0 0 0.2rem 0'
                },
                '& .startLocation':{
                    margin: '0 0 0.8rem 0'
                }
            },
            '& .mileageLogTooltip .tooltiptext::after': {
                content: '',
                position: 'absolute',
                top: '-13px',
                left: '13%',
                marginLeft: '-10px',
                borderWidth: '7px',
                borderStyle: 'solid',
                borderColor: (colorTheme) => `transparent transparent ${colorTheme.secondary} transparent`
            },
            '& .mileageLogTooltip .tooltiptext': {
                visibility: 'hidden',
                width: 'auto',
                minWidth: '7rem',
                maxWidth: toRem16(346),
                background: (colorTheme) => colorTheme.secondary,
                color: (colorTheme) => colorTheme.primaryWhite,
                borderRadius: toRem16(4),
                padding: `${toRem16(12)} 1rem`,
                top:'2rem',
                /* Position the tooltip */
                position: 'absolute',
                zIndex: 1,
                left: '-50%',
            },
            '& .mileageLogTooltip:hover .tooltiptext': {
                visibility: 'visible',
            },
        },
    })
);
export default function CustomMileageLogTooltip(props: TooltipProps) {
    const { startLocation, endLocation } = props;
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme);
    return (
        <div className={classes.tooltipContainer}>
            <div className={'mileageLogTooltip'}>
                <img alt={`Location`} className={'mileageLogTooltip'} src={Location} />

                <div className={'tooltiptext'}>
                    <p className='startLocation'>
                        <label>Start Location</label>
                        <br />
                        <span>{startLocation}</span>
                    </p>
                    <p>
                        <label>Destination</label>
                        <br />
                        <span>{endLocation}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}
