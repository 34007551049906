import { Link, Typography } from '@material-ui/core'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import LineChartWidget from '../../common/LineChartWidget'
import WidgetBox from '../../common/WidgetBox'
import MenuDropdown from '../../common/MenuDropdown'
import WidgetSkeletonLoader from '../../common/WidgetSkeletonLoader'
import { ApplicationStore } from '../../../models'
import { connect } from 'react-redux'
import {
    currencyFormatter,
    isTrialEnded,
    percentFormatter,
} from '../../../utils/appUtil'
import { useHistory } from 'react-router-dom'
import { useStyles } from '../../../styles/styles'
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil'
import { getIncomeStatementReport } from '../../../services/apiService/reports'
import { IncomeStatementReportRoot } from '../../bookkeeping/reports/models/reports-interface'
import { COLORS } from '../../../variables/colors'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import {
    findSpaceRegex,
    INCOME_STATEMENT_CHART_TITLES,
    INCOME_STATEMENT_DATE_DROPDOWN_OPTIONS,
    periodTextFormat,
} from './constants'
import EmptyState from './EmptyState'
import IncomeStatementEmpty from '../../../assets/icons-svg/IncomeStatementEmpty.svg'

function IncomeStatement(props: any) {
    const [loading, setLoading] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [values, setValues] = useState<any>()
    const [lineChartValues, setLineChartValues] = useState<any>([])
    const history = useHistory()
    const accountId = props.appData.current_account_id
    const businessId = props.appData.current_business_id

    const getPeriodText = (
        fromDate: number | undefined,
        toDate: number | undefined
    ) => {
        if (!fromDate || !toDate) {
            return
        }
        const fromDateInMs = fromDate * 1000
        const toDateInMs = toDate * 1000
        const convertedFromDate = convertUnixToGivenDateFormat(
            periodTextFormat,
            fromDateInMs
        )
        const convertedToDate = convertUnixToGivenDateFormat(
            periodTextFormat,
            toDateInMs
        )
        const periodText = `From ${convertedFromDate} to ${convertedToDate}`
        return periodText.replace(findSpaceRegex, '+')
    }
    const getDateAndTimeParams = useCallback(() => {
        const selectedDateType =
            INCOME_STATEMENT_DATE_DROPDOWN_OPTIONS[selectedIndex]
        const getDateTillToday = true
        const { startDate, endDate } = getTimeParamsWithoutEST(
            selectedDateType,
            getDateTillToday
        )
        const startDateYmd = getDateInYmd(startDate!)
        const endDateYmd = getDateInYmd(endDate!)
        const currentTimeStamp = getCurrentTimeStamp()
        return {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        }
    }, [selectedIndex])

    useEffect(() => {
        const setValuesIntoLineChart = (
            response: IncomeStatementReportRoot
        ) => {
            setLineChartValues([
                {
                    title: INCOME_STATEMENT_CHART_TITLES.REVENUE,
                    amount: Number.parseFloat(response.revenue.balance),
                    color: COLORS.green900,
                },
                {
                    title: INCOME_STATEMENT_CHART_TITLES.EXPENSES,
                    amount: Number.parseFloat(response.expenses.balance),
                    color: COLORS.teal300,
                },
            ])
        }
        if (isTrialEnded()) {
            setValues(undefined)
            setLoading(false)
            return
        }
        const {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        } = getDateAndTimeParams()
        const periodText = getPeriodText(startDate, endDate)
        const classIds = ''
        if (!startDate || !endDate || !periodText) {
            return
        }
        setLoading(true)
        getIncomeStatementReport(
            accountId,
            businessId,
            startDateYmd,
            endDateYmd,
            periodText,
            currentTimeStamp,
            classIds
        )
            .then((res: unknown) => {
                setLoading(false)
                const response = res as IncomeStatementReportRoot
                setValues(res)
                setValuesIntoLineChart(response)
            })
            .catch(() => {
                setLoading(false)
                setValues(undefined)
            })
    }, [accountId, businessId, getDateAndTimeParams])

    const getRedirectionUrl = (
        startDateTimeStamp: number,
        endDateTimeStamp: number,
        startDateYmd: string,
        endDateYmd: string,
        time: number
    ) => {
        const url = `/reports/income-statement/${startDateTimeStamp}/${endDateTimeStamp}/${startDateYmd}/${endDateYmd}/${time}`
        return url
    }

    const redirectToIncomeStatementReports = () => {
        const {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        } = getDateAndTimeParams()
        if (!startDate || !endDate) {
            return
        }
        const redirectionUrl = getRedirectionUrl(
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp
        )
        history.push(redirectionUrl)
    }

    function ChartSummary() {
        const { colorTheme } = useThemeContext()
        const commonClasses = useStyles(colorTheme)
        return (
            <Fragment>
                <div>
                    <Typography
                        component={Link}
                        color="textPrimary"
                        className={commonClasses.dashboardTextLink}
                        onClick={() => redirectToIncomeStatementReports()}
                        variant="h4"
                    >
                        {currencyFormatter.format(values.total_net_income)}
                    </Typography>

                    <Typography color="textSecondary" variant="body2">
                        Net{' '}
                        {values.total_net_income.includes('-')
                            ? 'Loss'
                            : 'Profit'}
                    </Typography>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <Typography
                        component={Link}
                        align="right"
                        color="textPrimary"
                        className={commonClasses.dashboardTextLink}
                        onClick={() => redirectToIncomeStatementReports()}
                        variant="h4"
                    >
                        {percentFormatter.format(
                            Number.parseFloat(values.net_profit_margin)
                        )}
                    </Typography>

                    <Typography color="textSecondary" variant="body2">
                        Net Profit Margin
                    </Typography>
                </div>
            </Fragment>
        )
    }

    return (
        <WidgetBox
            heading="Income Statement"
            actionControl={
                props.trialEnded ? (
                    ''
                ) : (
                    <MenuDropdown
                        options={INCOME_STATEMENT_DATE_DROPDOWN_OPTIONS}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        disabled={loading}
                    />
                )
            }
        >
            {loading ? (
                <WidgetSkeletonLoader
                    justifyContent="flex-start"
                    alignItems="flex-start"
                />
            ) : values ? (
                <LineChartWidget
                    values={lineChartValues}
                    summary={<ChartSummary />}
                    handleAmountClick={redirectToIncomeStatementReports}
                />
            ) : (
                <EmptyState
                    alt="Income statement empty image"
                    src={IncomeStatementEmpty}
                    text="See how profitable your business is with highlights of its net profit and profit margin."
                />
            )}
        </WidgetBox>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    trialEnded: state.auth.isTrialEnded,
})
export default connect(mapStateToProps)(IncomeStatement)
