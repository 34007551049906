import { Button, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { toRem16 } from '../../../../styles/commonStyles'
import UiText from '../../../common/ui/UiText'
import { InfoCardProps } from '../InfoCard'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        imageMargin: {
            margin: '3% 3% 0 0',
        },
        imageStyle: {
            width: '100%',
            borderRadius: toRem16(8),
        },
        buttonStyle: {
            margin: `${toRem16(8)} 0 ${toRem16(8)} 0`,
            [theme.breakpoints.down('xs')]: {
                maxWidth: '95%',
            },
        },
        mainTextContent: {
            paddingLeft: '8px',
            margin: '3% 0 3% 0',
        },
        textMargin: {
            marginTop: toRem16(6),
        },
        dismissBtn: {
            color: colorTheme => colorTheme.blue200,
            marginTop: toRem16(8),
            marginLeft: toRem16(8),
        },
    }
})

const MobileCardView = ({
    title,
    subtitle,
    customTitle,
    buttonText,
    buttonClick,
    buttonIcon,
    cardImage,
    rightButton,
    containerStyle,
    btnPrimary = true,
    btnContained = true,
    onDismissClick,
    disableClick,
}: InfoCardProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    return (
        <div>
            <div className={styles.mainTextContent}>
                {customTitle}
                {title && (
                    <UiText variant="suv_150" weight="medium_500">
                        {title}
                    </UiText>
                )}
                {subtitle && (
                    <UiText
                        textColor="textSecondary"
                        className={styles.textMargin}
                    >
                        {subtitle}
                    </UiText>
                )}
                {cardImage && (
                    <div className={styles.imageMargin}>
                        <img
                            src={cardImage}
                            className={styles.imageStyle}
                            alt="cardImage"
                        />
                    </div>
                )}
                {buttonText && (
                    <Button
                        color={btnPrimary ? 'primary' : 'secondary'}
                        variant={btnContained ? 'contained' : 'outlined'}
                        startIcon={buttonIcon}
                        className={styles.buttonStyle}
                        onClick={buttonClick}
                        disabled={disableClick}
                    >
                        {buttonText}
                    </Button>
                )}
                {rightButton}
                {onDismissClick && (
                    <Button
                        variant="text"
                        className={styles.dismissBtn}
                        onClick={() => {
                            onDismissClick?.()
                        }}
                    >
                        Dismiss
                    </Button>
                )}
            </div>
        </div>
    )
}

export default MobileCardView