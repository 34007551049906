import React from 'react'
import FinicityConnect from './FinicityConnect'
import { Header } from './CommonComponents'
import { 
    BANK_ACCOUNT_CREATED, 
    CONNECT_BANK_ACCOUNT, 
    TODO_CONNECT_BANK 
} from '../TodoSection/Todo.const'


interface ConnectBankAccountCardsProps {
    primaryText: string
    secondaryText: string
    completeButtonText: string
    inCompleteButtonText?: string
    accountProgress?: any
    dismissPopupInfo: any
    fetchAccountProgress: any
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ConnectBankAccountCards = ({
    primaryText,
    secondaryText,
    completeButtonText,
    inCompleteButtonText,
    accountProgress,
    dismissPopupInfo,
    fetchAccountProgress,
    setLoading
}: ConnectBankAccountCardsProps) => {
    const isBankAccountConnected = accountProgress?.[CONNECT_BANK_ACCOUNT]
    const bankAccountCreated = accountProgress?.[BANK_ACCOUNT_CREATED]
    const isBankSetupDismissed = dismissPopupInfo?.includes(TODO_CONNECT_BANK)

    return (
        <>
            {((isBankAccountConnected || isBankSetupDismissed || bankAccountCreated)) ? (
                <Header text={isBankSetupDismissed ? 'Connect Your Bank Account' : 'Bank account connected'} />
            ) : (
                <FinicityConnect
                    primaryText={primaryText}
                    secondaryText={secondaryText}
                    completeButtonText={completeButtonText}
                    inCompleteButtonText={inCompleteButtonText}
                    fetchAccountProgress={fetchAccountProgress}
                    setLoading={setLoading}
                />
            )}
        </>
    )
}

export default ConnectBankAccountCards
