import store from '../../../store';
import {
    deleteInvoice,
    updateInvoice,
    createInvoice,
} from '../../../services/apiService/invoice';
import { showAlert } from '../../../store/actions/feedback';
import { formSubmitErrorHandler } from '../../../services/formService';

const postAlertHandling = (type: 'success' | 'error', text: string) => {
    store.dispatch(
        showAlert({
            alertText: text,
            alertType: type,
        })
    );
};

export const handleDeleteInvoice = (invoiceId: string, callback: () => void) => {
    formSubmitErrorHandler(
        deleteInvoice(invoiceId).then((contact) => {
            callback?.();
            postAlertHandling('success', 'Invoice deleted successfully');
        }),
        () => {}
    );
};

export const handleCreateInvoice = (
    params: any,
    callback: (data : any) => void,
    formActions: any
) => {  
    formSubmitErrorHandler(
        createInvoice(params).then((contact) => {
            callback?.(contact);
            postAlertHandling('success', 'Invoice created successfully');
        }),
        () => {
            formActions?.setFormSubmitted?.(false);
        },
        formActions?.setFieldError
    );
};

export const handleUpdateInvoice = (
    invoiceId: string,
    params: any,
    callback: () => void,
    formActions: any
) => {
    if(params.invoice_lines) {
        params.invoice_lines.map((item:any)=>{
            if(item.offering_data.category && typeof item.offering_data.category === 'object' && item.offering_data.category.id) {
                item.offering_data.category = item.offering_data.category.id;
            }
            return item;
        });
    }

    formSubmitErrorHandler(
        updateInvoice(invoiceId, params).then((contact) => {
            callback?.();
            postAlertHandling('success', 'Invoice updated successfully');
        }),
        () => {
            formActions?.setFormSubmitted?.(false);
        },
        formActions?.setFieldError
    );
};
