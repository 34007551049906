import { ApiConfig } from '../../../../../models'

export const MERCHANT_TYPE_PAYRIX = 'payrix';
export const MERCHANT_TYPE_NEWTEK = 'newtek';

export const businessFormFields = (config: ApiConfig) => [
    {
        label: 'Business Legal Name (Required)',
        type: 'text',
        required: true,
        fieldName: 'legal_name',
    },
    {
        label: 'Business Type (Required)',
        type: 'select',
        fieldName: 'business_type',
        required: true,
        optionsData: config.payrix.business.types,
        optionKey: 'name',
        optionValue: 'name',
    },
    {
        label: 'Employee Identification Number (Required)',
        type: 'mask',
        fieldName: 'tax_id',
        required: true,
        mask: [
            /[0-9,X]/,
            /[0-9,X]/,
            '-',
            /[0-9,X]/,
            /[0-9,X]/,
            /[0-9,X]/,
            /[0-9,X]/,
            /[0-9,X]/,
            /[0-9,X]/,
            /[0-9,X]/,
        ],
        minLength: 9,
    },
    {
        label: 'Business Industry (Required)',
        type: 'select',
        required: true,
        optionsData: config.business.industries,
        optionKey: 'name',
        optionValue: 'id',
        fieldName: 'mcc',
    },
    {
        label: 'Formation Date (Required)',
        required: true,
        type: 'stringDate',
        fieldName: 'established',
        dateType: 'string',
        disableFutureDate: true,
        showFloatingLabel: true,
    },
    {
        label: 'Business Website (Optional)',
        type: 'text',
        fieldName: 'website',
        required: false,
        showFloatingLabel: true,
    },
]

export const businessAddressFields = (config: ApiConfig) => [
    {
        label: 'Street Address (Required)',
        type: 'text',
        required: true,
        fieldName: `business_address_1`,
        showFloatingLabel: true,
    },
    {
        label: 'Street Address 2 (Optional)',
        type: 'text',
        fieldName: `business_address_2`,
        showFloatingLabel: true,
    },
    {
        label: 'City (Required)',
        type: 'text',
        required: true,
        fieldName: `city`,
        showFloatingLabel: true,
    },
    {
        label: 'State (Required)',
        type: 'select',
        optionsData: config.generic.states,
        optionKey: 'name',
        optionValue: 'id',
        required: true,
        fieldName: `state`,
        showFloatingLabel: true,
    },
    {
        label: 'Zip Code (Required)',
        type: 'mask',
        fieldName: `zipcode`,
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
        minLength: 5,
        showFloatingLabel: true,
        required: true,
    },
]

export const ownerInfoFields = (config: ApiConfig) => [
    {
        label: 'First Name (Required)',
        type: 'text',
        fieldName: `owner_first_name`,
        showFloatingLabel: true,
        required: true,
    },
    {
        label: 'Last Name (Required)',
        type: 'text',
        fieldName: `owner_last_name`,
        showFloatingLabel: true,
        required: true,
    },
    {
        label: 'Email (Required)',
        type: 'text',
        fieldName: `owner_email`,
        showFloatingLabel: true,
        required: true,
    },
    {
        label: 'Phone number (Required)',
        type: 'mask',
        fieldName: 'owner_contact',
        mask: [
            '(',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ')',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        minLength: 10,
        required: true,
    },
    {
        label: 'Date of Birth (Required)',
        required: true,
        type: 'stringDate',
        fieldName: 'owner_date_of_birth',
        dateType: 'string',
        disableFutureDate: true,
        showFloatingLabel: true,
        errorMessage: 'Required',
    },
    {
        label: 'Ownership Percentage (Optional)',
        type: 'number',
        placeholder: '0 % (Required)',
        required: true,
        fieldName: `owner_signer_ownership`,
    },
    {
        label: 'Social Security Number (Required)',
        type: 'mask',
        placeholder: 'XXX-XX-XXXX',
        fieldName: `owner_social_security_number`,
        mask: [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        minLength: 9,
        required: true,
    },
    {
        label: 'Title (Required)',
        type: 'text',
        fieldName: 'owner_title',
        minLength: 10,
        required: true,
    },
]

export const ownerAddressFields = (config: ApiConfig) => [
    {
        label: 'Street Address (Required)',
        type: 'text',
        required: true,
        fieldName: `owner_address1`,
        showFloatingLabel: true,
    },
    {
        label: 'Street Address 2',
        type: 'text',
        fieldName: `owner_address2`,
        showFloatingLabel: true,
    },
    {
        label: 'City (Required)',
        type: 'text',
        required: true,
        fieldName: `owner_city`,
        showFloatingLabel: true,
    },
    {
        label: 'State (Required)',
        type: 'select',
        optionsData: config.generic.states,
        optionKey: 'name',
        optionValue: 'id',
        required: true,
        fieldName: `owner_state`,
        showFloatingLabel: true,
    },
    {
        label: 'Zip Code (Required)',
        type: 'mask',
        fieldName: `owner_zip`,
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
        minLength: 5,
        showFloatingLabel: true,
        required: true,
    },
]

export const termAndConditionFields = [
    {
        type: 'checkbox',
        label: 'I have read and accepted the terms and conditions.',
        fieldName: 'tnc_accept',
        required: true,
    },
    {
        type: 'checkbox',
        label: 'I understand there is a 2.79% + $0.25 fee per invoice.',
        fieldName: 'tnc_accept_price',
        required: true,
    },
    {
        type: 'checkbox',
        label: 'I understand there is a one-time $7.00 application fee \n that will be charged when i send my first invoice',
        fieldName: 'tnc_accept_fee',
        required: true,
    },
]

export const formInitialValues: any = {
    legal_name: '',
    business_type: '',
    tax_id: '',
    mcc: '',
    established: '',
    website: '',
    business_address_1: '',
    business_address_2: '',
    city: '',
    state: '',
    zipcode: '',
    owner_first_name: '',
    owner_last_name: '',
    owner_email: '',
    owner_contact: '',
    owner_date_of_birth: '',
    owner_signer_ownership: '',
    owner_social_security_number: '',
    owner_title: '',
    owner_address1: '',
    owner_address2: '',
    owner_city: '',
    owner_state: '',
    owner_zip: '',
    tnc_accept: false,
    tnc_accept_price: false,
    tnc_accept_fee: false,
    public_token: '',
    account_token: '',
}

export const businessKeys = [
    'legal_name',
    'business_type',
    'tax_id',
    'mcc',
    'established',
    'website',
    'business_address_1',
    'city',
    'state',
    'zipcode',
]
export const ownerKeys = [
    'owner_first_name',
    'owner_last_name',
    'owner_email',
    'owner_contact',
    'owner_date_of_birth',
    'owner_signer_ownership',
    'owner_social_security_number',
    'owner_title',
    'owner_address1',
    'owner_city',
    'owner_state',
    'owner_zip',
]
