import { makeStyles } from '@material-ui/core'
import React from 'react'
import ContentLoader from 'react-content-loader'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'


const styles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
}))
const TaxPrepLoader = () => {
    const { colorTheme } = useThemeContext()
    const classes = styles()
    const { isMobileDevice } = useDeviceSize()

    const Loader = () => {
        return isMobileDevice ? (
            <>
                <rect x="7" y="7" rx="0" ry="0" width="280" height="160" />
                <rect x="6" y="182" rx="0" ry="0" width="280" height="160" />
            </>
        ) : (
            <>
                {' '}
                <rect x="8" y="13" rx="0" ry="0" width="240" height="130" />
                <rect x="271" y="13" rx="0" ry="0" width="240" height="130" />
            </>
        )
    }
    return (
        <div className={classes.container}>
            <ContentLoader
                speed={2}
                width={'100%'}
                viewBox={isMobileDevice ? '0 0 300 350' : '0 0 500 180'}
                backgroundColor={colorTheme.grey2200}
                foregroundColor={colorTheme.grey300}
            >
                <Loader />
            </ContentLoader>
        </div>
    )
}

export default TaxPrepLoader