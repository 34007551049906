import UiDialog from '../../../common/ui/UiDialog'
import {
    List,
    Icon,
    Theme,
    Button,
    ListItem,
    Typography,
    makeStyles,
    IconButton,
    useMediaQuery,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { setInLocalStorage } from '../../../../services/localStorageService'
import { mergeStyle, commonStyles, toRem16 } from '../../../../styles/commonStyles'
import UiText from '../../../common/ui/UiText'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        lineHeight: '40px',
        textAlign: 'center',
        fontSize: '28px',
        fontWeight: 700,
    },
    smTitle: {
        marginTop: '20px',
        marginLeft: '20px',
        textAlign: 'center',
    },
    rootManual: {
        flexGrow: 1,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        flexDirection: 'column',
    },
    checkImg: {
        width: '22px',
    },
    listItem: {
        width: '100%',
        marginLeft: '11px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    dialogHeader: {
        padding: '20px 24px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    container: {
        '& .MuiPaper-root': {
            height: 816,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                margin: '0px',
            },
        },
    },
    noMargin: {
        margin: '0px',
    },
    marginContent: {
        padding: toRem16(22),
    },
    hyperLinkButtonColor: {
        color: (colorTheme) => colorTheme.blue600,
    }
}))

const AdvertisingModal = ({ open, handleClose, openBusinsessModal, hideAlert }: any) => {
    const { currentUser } = useCurrentStore()
    const isAdmin = currentUser?.accountant_mode;
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const commonStyle = commonStyles()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    const checkIcon = (
        <Icon>
            <img
                alt={`link`}
                className={classes.checkImg}
                src={require(`../../../../assets/icons-svg/checkIcon.svg`).default}
            />
        </Icon>
    )
    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size="sm"
            title=""
            hideTitleSection={true}
            customRootClass={classes.container}
        >
            <div className={classes.dialogHeader}>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </div>
            <div
                className={smDevice ? classes.noMargin : classes.marginContent}
            >
                <Typography
                    variant={'h4'}
                    className={mergeStyle(
                        commonStyle.textCenter,
                        classes.title
                    )}
                >
                    Conveniently Accept Payments from Your Customers
                </Typography>
                <UiText variant="hatchback_125"  className={classes.smTitle}>
                    No matter if you take payments online, in-person, or on-the-go, get solutions tailored to any business or industry.
                </UiText>
                <div
                    className={
                        smDevice
                            ? ''
                            : mergeStyle(
                                  commonStyle.flex,
                                  commonStyle.justifyCenter
                              )
                    }
                >
                    <List>
                        <ListItem>
                            {checkIcon}
                            <UiText
                                variant="car_100"
                                className={classes.listItem}
                            >
                                Exclusive offer for 1-800Accountant clients: no set-up cost, no contract, and 2.79% + 25c per processed transaction
                            </UiText>
                        </ListItem>
                        <ListItem>
                            {checkIcon}
                            <UiText
                                variant="car_100"
                                className={classes.listItem}
                            >
                                Improve cash flow, with Instant Funding available to get paid within minutes
                            </UiText>
                        </ListItem>
                        <ListItem>
                            {checkIcon}
                            <UiText
                                variant="car_100"
                                className={classes.listItem}
                            >
                                Low $25 monthly fee
                            </UiText>
                        </ListItem>
                    </List>
                </div>

                <div className={commonStyle.mt24}>
                    <UiText
                        variant="car_100"
                        weight='semi_bold_600'
                        className={mergeStyle(commonStyle.textCenter)}
                    > {
                        isAdmin ? 
                            'No Initial Setup Cost. Competitive 2.79% + 25c per processed transaction.' 
                        : 
                            'One-time $7.00 Initial Setup Cost. Competitive 2.79% + 25¢ per processed transaction'
                      }
                    </UiText>
                </div>
                <div
                    className={mergeStyle(
                        commonStyle.textCenter,
                        commonStyle.mt24
                    )}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleClose()
                            openBusinsessModal()
                        }}
                    >
                        Apply Now
                    </Button>
                </div>

                <div
                    className={mergeStyle(
                        commonStyle.textCenter,
                        commonStyle.mt24
                    )}
                >
                    <Typography variant="body2">
                        {
                            isAdmin ?
                                'The application will open in a new tab and will take around 5 minutes to complete.  Approval is instant. Start accepting online payments within 30 minutes.' 
                            : 
                                'The application will open in a new tab and will take around 5 minutes to complete, and approval is instant. Start accepting payments today!'
                        }
                    </Typography>
                </div>
                <div
                    className={mergeStyle(
                        commonStyle.textCenter,
                        commonStyle.mt24
                    )}
                >
                    <Button
                        variant="text"
                        classes={{ root: classes.hyperLinkButtonColor}}                        
                        onClick={() => {
                            handleClose()
                            hideAlert()
                            setInLocalStorage(
                                'hide_merchant_account_banner',
                                true
                            )
                        }}
                    >
                        No Thanks
                    </Button>
                </div>
            </div>
        </UiDialog>
    )
}

export default AdvertisingModal
