import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { Employee } from '../../../models/adp-payroll/employee'
import React, { Fragment } from 'react'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import UiText from '../../common/ui/UiText'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        maxHeight: 'calc(100vh - 300px)',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        marginTop: '1rem',
    },
    inline: {
        display: 'inline',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    selectedMessage: {
        background: (colorTheme) => `${colorTheme.green500} !important`,
        borderRadius: '4px'
    },
    itemAvatar: {
        backgroundColor: (colorTheme) => colorTheme.primaryGreen,
        marginBottom: '8px',
        marginLeft: '8px',
        marginRight: '8px',
    },
    secondaryText: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        marginLeft: '8px',
    },
    labelStyle: {
        marginTop: '10px',
    },
}))

const ADPEmployeeListView = ({
    items,
    selectedEmployee,
    setSelectedEmployee,
}: {
    items: Employee[]
    selectedEmployee?: any
    setSelectedEmployee?: React.Dispatch<React.SetStateAction<any | undefined>>
}) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()
    const history = useHistory()

    return (
        <List className={classes.listRoot}>
            {items.map((employee: any, index: number) => (
                <Fragment key={employee?.associateID}>
                    <ListItem
                        button
                        classes={{
                            gutters: classes.navListItemGutters,
                            selected: classes.selectedMessage,
                        }}
                        alignItems="flex-start"
                        selected={
                            selectedEmployee?.associateID ===
                            employee?.associateID
                        }
                        onClick={() => {
                            setSelectedEmployee?.(employee)
                            if(isMobileDevice){
                                history.push(`/payroll/employees/${employee?.associateID}`)
                            }
                        }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                classes={{
                                    colorDefault: classes.itemAvatar,
                                }}
                            >
                                {employee?.legalName?.firstName
                                    ?.charAt(0)
                                    .toUpperCase()}
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primaryTypographyProps={{
                                style: {
                                    fontWeight: true ? 'bold' : 'normal',
                                },
                            }}
                            primary={
                                <Fragment>
                                    <UiText style={{ marginLeft: '8px' }}>
                                        {employee?.legalName?.firstName}{' '}
                                        {employee?.legalName?.lastName}
                                    </UiText>
                                </Fragment>
                            }
                            secondary={
                                <UiText className={classes.secondaryText}>
                                    {/* {employee.?.jobs
                                                ?.map((e: any) => e.title)
                                                .join(',')} */}
                                </UiText>
                            }
                        />
                    </ListItem>
                    {index === items.length - 1 ? (
                        ''
                    ) : (
                        <Divider variant="inset" />
                    )}
                </Fragment>
            ))}
        </List>
    )
}

export default ADPEmployeeListView
