import React, { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import EmptySectionView from '../../common/EmptySectionView'
import SectionView from '../../common/SectionView'
import MdashCheck from '../../../common/MdashCheck'
import UiText from '../../../common/ui/UiText'
import Loader from '../../../common/Loader'
import { getEmployeeFedaralTax } from '../../../../services/apiService/payroll/employee'
import CurrencyText from '../../../common/currencyText'

const TaxInformation = ({
    employeeId,
    businessId,
    setOpenRequestDialog,
    disableRequestInputButton,
}: {
    employeeId?: string
    businessId?: string
    setOpenRequestDialog: React.Dispatch<React.SetStateAction<boolean>>
    disableRequestInputButton: boolean | undefined
}) => {
    const [loading, setLoading] = useState(false)
    const [taxInfo, setTaxInfo] = useState<any>()

    useEffect(() => {
        setLoading(true)
        getEmployeeFedaralTax(businessId as string, employeeId as string)
            .then((res: any) => {
                setLoading(false)
                setTaxInfo(res)
            })
            .catch((err: any) => {
                setLoading(false)
            })
    }, [businessId, employeeId])

    return (
        <div style={{ marginTop: '24px' }}>
            <UiText variant="suv_150" weight="medium_500">
                Tax Information
            </UiText>
            {loading ? (
                <div style={{ height: '300px' }}>
                    <Divider />
                    <Loader />
                </div>
            ) : (
                <>
                    {taxInfo?.deductions ? (
                        <div>
                            <SectionView
                                key={'deductions'}
                                headerText={'Deductions'}
                                customNode={
                                    <UiText>
                                        <CurrencyText
                                            value={taxInfo?.deductions}
                                            showPlus={false}
                                        />
                                    </UiText>
                                }
                            />
                            <SectionView
                                key={'extra_withholdings'}
                                headerText={'Extra Withholdings'}
                                customNode={
                                    <UiText>
                                        <CurrencyText
                                            value={taxInfo?.extra_withholding}
                                            showPlus={false}
                                        />
                                    </UiText>
                                }
                            />

                            <SectionView
                                key={'other_tax_info'}
                                headerText={'Other Tax Info'}
                                sectionInfoData={[
                                    {
                                        label: 'Filing Status',
                                        value: (
                                            <MdashCheck
                                                check={taxInfo?.filing_status}
                                            >
                                                {taxInfo?.filing_status}
                                            </MdashCheck>
                                        ),
                                    },
                                    {
                                        label: 'Number of Dependents',
                                        value: (
                                            <MdashCheck
                                                check={
                                                    taxInfo?.dependents_amount
                                                }
                                            >
                                                {taxInfo?.dependents_amount}
                                            </MdashCheck>
                                        ),
                                    },
                                    {
                                        label: 'Other Income',
                                        value: (
                                            <MdashCheck
                                                check={taxInfo?.other_income}
                                            >
                                                <CurrencyText
                                                    value={taxInfo?.other_income}
                                                    showPlus={false}
                                                />
                                            </MdashCheck>
                                        ),
                                    },
                                    {
                                        label: 'Multiple Jobs?',
                                        value: (
                                            <>{taxInfo?.two_jobs
                                                ? 'Yes'
                                                : 'No'}</>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    ) : (
                        <div>
                            <Divider />
                            <EmptySectionView
                                height={'460px'}
                                primaryText={'No tax information found'}
                                secondaryText={''}
                                buttonText={'Request employee input'}
                                buttonIcon={<EmailIcon />}
                                disableButton={disableRequestInputButton}
                                onClickAction={() => {
                                    setOpenRequestDialog(true)
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default TaxInformation
