import React, { useEffect, useState } from 'react'
import usePermission from '../../../hooks/usePermissions'
import BookkeepingAd from '../Cards/BookkeepingAd'
import PayrollAd from '../Cards/PayrollAd'
import FoundAd from '../Cards/FoundAd'
import MyloAd from '../Cards/MyloAd'
import UeniAd from '../Cards/UeniAd'
import {
    BOOKKEEPING_AD_BLOCK,
    PAYROLL_AD_BLOCK,
    MYLO_AD_BLOCK,
    FOUND_AD_BLOCK,
    UENI_AD_BLOCK
} from '../TodoSection/Todo.const'
import { isBlockDismissed } from './AccountSetupUtility'
import { getCookie, setCookie } from '../../../services/cookieService'

const PermissionBlocks = () => {
    const [reload, setReload] = useState(false)
    const { hasPayrollPermission, hasBasicBookkeepingPermission } = usePermission()
    const [adCoookie, setAdCoookie] = useState('')
    useEffect(() => {
        if (reload) {
            setReload(false)
        }
    }, [reload])

    useEffect(() => {
        const cookie = getCookie('adCookie');
        setAdCoookie(cookie ?? MYLO_AD_BLOCK);
        if(!cookie){
            setCookie('adCookie', MYLO_AD_BLOCK, 10);
        }
    }, [])

    return (
        <>
            {   adCoookie === FOUND_AD_BLOCK && !isBlockDismissed(FOUND_AD_BLOCK) ? (
                    <FoundAd
                        reload={() => {
                            setReload(true)
                        }}
                    />
                 ) : (
                    adCoookie === MYLO_AD_BLOCK && !isBlockDismissed(MYLO_AD_BLOCK) ? (
                        <MyloAd
                            reload={() => {
                                setReload(true)
                            }}
                        />
                    ) : ( 
                        adCoookie === UENI_AD_BLOCK && !isBlockDismissed(UENI_AD_BLOCK) &&
                            <UeniAd
                                reload={() => {
                                    setReload(true)
                                }}
                            />
                    )
                )
            }
            {!hasBasicBookkeepingPermission &&
                !isBlockDismissed(BOOKKEEPING_AD_BLOCK) && (
                    <BookkeepingAd
                        reload={() => {
                            setReload(true)
                        }}
                    />
                )}
            {
                
                !hasPayrollPermission && 
                !isBlockDismissed(PAYROLL_AD_BLOCK) && 
                (isBlockDismissed(MYLO_AD_BLOCK) || isBlockDismissed(BOOKKEEPING_AD_BLOCK) || isBlockDismissed(FOUND_AD_BLOCK)) && (
                    <PayrollAd
                        reload={() => {
                            setReload(true)
                        }}
                    />
            )} 
        </>
    )
}

export default PermissionBlocks