import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import UiFormControlSelection from './ui/UiFormControlSelection';
import AddressForm from './AddressForm';
import { Business, BusinessOwner, ApplicationStore, Config } from '../../models';
import { useFormikContext } from 'formik';
import UiDialog from './ui/UiDialog';
import { connect } from 'react-redux';
import { COLORS } from '../../variables/colors';

interface Iprop {
  deleteOwner: Function;
  fieldName: string;
  index: number;
  form?: any;
  config?: Config;
}

const OwnerForm: React.FC<Iprop> = ({
  fieldName,
  deleteOwner,
  index,
  form,
  config,
}) => {
  const theme = useTheme()
  const classes = makeStyles((theme: Theme) => {
    return {
      ownerBox: {
        border: '1px solid',
        borderRadius: '8px',
        padding: '8px 16px ',
        borderColor: COLORS.grey2300,
        marginBottom: theme.spacing(3),
      },
      deleteBtn: {
        fontSize: '16px',
        color: theme.palette.error.main,
      },
      fieldContainer: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
      },
      deleteRedBtn: {
        fontSize: '1em',
        background: theme.palette.error.main,
        color: 'white',
      },
    };
  })();

  const xsDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const { values: formValues }: { values: Business } = useFormikContext();
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const openDeletePopup = () => {
    setOpenModal(true);
  };

  const isUseOwnership = () => {
    const businessType = config?.apiConfig.business.types.find(
      (item) => item.abbr === formValues?.business_type
    );
    return formValues.business_type && businessType?.ownership_is_percent;
  };

  const validateOwnerName = (value: string) => {
    if (
      formValues.business_type &&
      formValues.business_type !== 'S' &&
      formValues.business_type !== 'C' &&
      !value
    ) {
      return 'Required';
    }
    return '';
  };

  const validateOwnership = (value: any) => {
    const businessOwners = formValues.business_owners;
    const error = 'Total ownership percantage should be 100%';
    if (isUseOwnership() && !value) {
      return 'Required';
    }
    const totalOwnership = businessOwners
      ? businessOwners.reduce(
          (acc: number, owner: BusinessOwner) =>
            acc + (owner?.ownership ? owner?.ownership : 0),
          0
        )
      : [];
    if (isUseOwnership() && totalOwnership !== 100) {
      return error;
    }
    return '';
  };

  return (
    <Box className={classes.ownerBox}>
      <Grid container justify='space-between'>
        <Typography variant='h6'>Owner {index + 1}</Typography>
        {(formValues.business_owners?.length || 0) > 1 ? (
          <Button
            variant='text'
            startIcon={<DeleteOutlineIcon />}
            className={classes.deleteBtn}
            onClick={() => openDeletePopup()}
          >
            Delete
          </Button>
        ) : null}
      </Grid>
      <Divider />
      <Box py={4}>
        <InputLabel>Name (Required)</InputLabel>
        <Grid container justify='space-between'>
          <Grid item xs={12} sm={6}  style={{paddingRight: xsDevice ? 0 : theme.spacing(3)}}>
            <Box className={classes.fieldContainer}>
              <UiFormControlSelection
                required={true}
                label='First Name'
                type='text'
                fieldName={`${fieldName}.first_name`}
                showFloatingLabel={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.fieldContainer}>
              <UiFormControlSelection
                required={true}
                label='Last Name'
                type='text'
                fieldName={`${fieldName}.last_name`}
                validationFunction={validateOwnerName}
                showFloatingLabel={true}
              />
            </Box>
          </Grid>
        </Grid>
        {isUseOwnership() ? (
          <>
            <InputLabel>Ownership Percentage (Required)</InputLabel>
            <Grid container justify='space-between'>
              <Grid item md={3}>
                <Box className={classes.fieldContainer}>
                  <UiFormControlSelection
                    fastField={false}
                    type='number'
                    fieldName={`${fieldName}.ownership`}
                    validationFunction={validateOwnership}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <InputLabel>Number of Shares</InputLabel>
            <Grid container justify='space-between'>
              <Grid item md={3}>
                <Box className={classes.fieldContainer}>
                  <UiFormControlSelection
                    type='number'
                    fieldName={`${fieldName}.ownership`}
                    min={1}
                    step={1}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        <InputLabel>Social Security Number (Optional)</InputLabel>
        <Grid container justify='space-between'>
          <Grid item md={4}>
            <Box className={classes.fieldContainer}>
              <UiFormControlSelection
                type='mask'
                fieldName={`${fieldName}.ssn`}
                mask={[
                  /[0-9,X]/,
                  /[0-9,X]/,
                  /[0-9,X]/,
                  '-',
                  /[0-9,X]/,
                  /[0-9,X]/,
                  '-',
                  /[0-9,X]/,
                  /[0-9,X]/,
                  /[0-9,X]/,
                  /[0-9,X]/,
                ]}
                minLength={9}
                placeholder="XXX-XX-XXXX"
              ></UiFormControlSelection>
            </Box>
          </Grid>
        </Grid>
        <InputLabel>Address (Optional)</InputLabel>
        <Box className={classes.fieldContainer} style={{marginBottom: '0'}}>
          <AddressForm fieldName={`${fieldName}.address`} showCountry={true} showCounty={true}/>
        </Box>
      </Box>
      <UiDialog
        open={openModal}
        title={`Delete Owner #${index + 1}`}
        handleClose={handleClose}
        size='xs'
      >
        <Typography
          style={{ marginBottom: theme.spacing(5) }}
        >{`Do you want to remove Owner #${
          index + 1
        } as a business owner?`}</Typography>
        <Typography style={{ marginBottom: theme.spacing(3) }}>
          This cannot be undone.
        </Typography>
        <Box>
          <Button
            variant='outlined'
            color='secondary'
            style={{ margin: '0 10px 10px 0' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            startIcon={<DeleteOutlineIcon />}
            className={classes.deleteRedBtn}
            style={{ margin: '0 10px 10px 0' }}
            onClick={() => {
              handleClose()
              deleteOwner();
            }}
          >
            Delete
          </Button>
        </Box>
      </UiDialog>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationStore) => ({
  config: state.config,
});
export default connect(mapStateToProps)(OwnerForm);
