import {
    createProduct,
    updateProduct,
    deleteProduct,
    createCategory,
    bulkProductsAction,
} from '../../../services/apiService';
import store from '../../../store';
import { showAlert } from '../../../store/actions/feedback';
import { ProductDetail } from '../../../models';
import { formSubmitErrorHandler } from '../../../services/formService';

const postAlertHandling = (type: 'success' | 'error', text: string) => {
    store.dispatch(
        showAlert({
            alertText: text,
            alertType: type,
        })
    );
};

export const createProductAction = (
    params: any,
    callback: (product: any) => void,
    formActions?: any
) => {
    formSubmitErrorHandler(
        createProduct(params).then((product) => {
            if (product) {
                callback?.(product);
                postAlertHandling('success', 'Product Created Successfully');
            }
        }),
        () => {
            formActions?.setSubmitting?.(false);
        },
        formActions?.setFieldError
    )
   
};

export const updateProductAction = (
    productId: string,
    params: any,
    callback: (product: ProductDetail) => void,
    formActions?: any
) => {
    formSubmitErrorHandler(
        updateProduct(productId, params).then((res) => {
            if (res) {
                callback(res as ProductDetail);
                postAlertHandling('success', 'Product Updated Successfully');
            }
        }),
        () => {
            formActions?.setSubmitting?.(false);
        },
        formActions?.setFieldError
    )
};

export const deleteProductAction = (
    productId: string,
    callback: () => void
) => {
    deleteProduct(productId).then((product) => {
        callback?.();
        postAlertHandling('success', 'Product Deleted Successfully');
    });
};

export const createCategoryAction = (params: any, callback: (category: any) => void, formActions: any) => {
    formSubmitErrorHandler(
        createCategory(params).then((category) => {
            if(category) {
                callback?.(category);
                postAlertHandling('success', 'Product Category Created Successfully');
            }
        }),
        () => {
            formActions?.setSubmitting?.(false);
        },
        formActions?.setFieldError
    )
    
};

export const handleBulkProductActions = (
    type: string,
    productCount: number,
    params: any,
    callback: () => void
) => {
    bulkProductsAction(params).then((product) => {
        if (type === 'delete') {
            callback?.();
            postAlertHandling(
                'success',
                `${productCount} Products deleted Successfully`
            );
        } else {
            // categorize
            callback?.();
            postAlertHandling(
                'success',
                `${productCount} Products Categorized Successfully`
            );
        }
    });
};
