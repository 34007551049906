import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
    EMPTY_PARAMS,
} from './constants/reports.const';
import { getJournalEntriesReport } from '../../../services/apiService/reports';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    ApiError,
    BalanceSheetReportRoot,
    ErrorAlertReport,
} from './models/reports-interface';
import { reportsDataActions } from '../../../store/actions/reportsData';
import { getCurrentTimeStamp, getDateInYmd } from '../../../utils/dateUtil';
import { useReportsStyles } from './styles/reports-styles';
import InfoTooltip from '../../common/InfoTooltip';
import Loader from '../../common/Loader';
import { getTimeParamsWithoutEST } from '../../../utils/dateUtil';
import UiText from '../../common/ui/UiText';
import SelectDates from './utils/Date/SelectDates';
import { FormObserver } from './utils/Form/FormObserver';
import { showError } from '../../../services/formService';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const chips = [
    {
        for: DATE_TYPES.THIS_MONTH,
        id: 1,
    },
    {
        for: DATE_TYPES.THREE_MONTHS,
        id: 2,
    },
    {
        for: DATE_TYPES.THIS_YEAR,
        id: 3,
    },
    {
        for: DATE_TYPES.ALL_TIME,
        id: 4,
    },
];
const TOOLTIP_TEXT =
    'The Journal Entries Report shows all manually created journal entries for the selected period of time.';
const initialFormValues = {
    from: moment.utc().hour(12).minute(0).second(0).unix(),
    to: moment.utc().hour(12).minute(0).second(0).unix(),
};
const IS_GET_DATE_TILL_TODAY = true;
export default function JournalEntries() {
    const history = useHistory();
    const storeData = useCurrentStore();
    const dispatch = useDispatch();
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const businessName = storeData.currentBusiness?.name;
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isHideFromDateAndToDate, setIsHideFromDateAndToDate] = useState<boolean>(false);

    useEffect(() => {
        updateDates({ for: selectedDateChip, id: 3 });
    }, []);

    const checkIfFromAndToDatesAreToBeHidden = (dateType: string)=>{
        if(dateType === DATE_TYPES.ALL_TIME) {
            setIsHideFromDateAndToDate(true);
            return;
        }
        setIsHideFromDateAndToDate(false);
    }

    const updateDates = (dateType: { for: string; id: any }): void => {
        setSelectedDateChip(dateType.for);
        const { startDate, endDate } = getTimeParamsWithoutEST(
            dateType.for,
            IS_GET_DATE_TILL_TODAY
        );
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
        checkIfFromAndToDatesAreToBeHidden(dateType.for);
    };

    const fetchAPIQueryParams = (from: number, to: number) => {
        const start_date_ymd = from === 0 ? EMPTY_PARAMS.START_DATE_YMD : getDateInYmd(from);
        const end_date_ymd = to === 0 ? EMPTY_PARAMS.END_DATE_YMD : getDateInYmd(to);
        let periodText = (
            'for the period from ' +
            moment(from * 1000).format(DATE_FORMATS.periodText) +
            ' to ' +
            moment(to * 1000).format(DATE_FORMATS.periodText)
        ).replace(findSpaceRegex, '+');
        if(isHideFromDateAndToDate){
            periodText = 'All Time';
        }
        const time = getCurrentTimeStamp();
        const accountId = storeData.currentAccountId;
        const businessId = storeData.currentBusinessId;
        return {
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
        };
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };

    const navigateToJournalEntriesDetailedReports = (
        start_date_ymd: string,
        end_date_ymd: string,
        periodText: string,
        time: number
    ) => {
        const detailedReportsUrl = `/reports/journal-entries/${start_date_ymd}/${end_date_ymd}/${periodText}/${time}`;
        history.push(detailedReportsUrl);
    };
    const dispatchReportsDataToStore = (data: BalanceSheetReportRoot) => {
        dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };

    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };

    const generateJournalEntriesReports = (data: any) => {
        const {
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
        } = fetchAPIQueryParams(data.from, data.to);
        if (!businessId) return;
        setIsLoading(true);
        getJournalEntriesReport(
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time
        )
            .then((result: unknown) => {
                const responseData = result as BalanceSheetReportRoot;
                dispatchReportsDataToStore(responseData);
                navigateToJournalEntriesDetailedReports(
                    start_date_ymd,
                    end_date_ymd,
                    periodText,
                    time
                );
                setIsLoading(false);
            })
            .catch((error: any) => {
                setIsLoading(false);
                handleError(error);
            });
    };

    const validationSchema = Yup.object({
        from: Yup.number().required('Date is required'),
        to: Yup.number().required('Date is required'),
    });

    const onSubmit = (values: any) => {
        generateJournalEntriesReports(values);
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const onformChangeCallback = (values: unknown, formikObject: any) => {
        const fromDateChanged = formikObject.touched?.from;
        const toDateChanged = formikObject.touched?.to;
        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };
    return (
        <div className={reportsClasses.reportsViewParent}>
            <UiText className="businessName">{businessName}</UiText>
            <UiText
                variant="suv_150"
                weight="semi_bold_600"
                className={reportsClasses.pageTitle}
            >
                Journal Entries
                <InfoTooltip tooltipText={TOOLTIP_TEXT} />
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                <Form>
                    <FormObserver onformChange={onformChangeCallback} />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <SelectDates
                                chips={chips}
                                handleUpdateDates={updateDates}
                                selectedChip={selectedDateChip}
                                disableFutureDate
                                isHideFromAndToDates={isHideFromDateAndToDate}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                data-cy='generate-report-btn'
                            >
                                Generate Report
                            </Button>
                        </>
                    )}
                </Form>
            </Formik>
        </div>
    );
}
