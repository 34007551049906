/**
 * JIRA stories OEHA-17242,OEHA-17879, OEHA-18322, OEHA-18356
 */
import { Button, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import UiDialog from '../../common/ui/UiDialog'
import { useStyles } from './InfoModal'
import usePermission from '../../../hooks/usePermissions'
import { GetCurrentUserContactId } from '../AccountSetupItems/AccountSetupUtility'
import { getCookie, setCookie } from '../../../services/cookieService'
import { getUnCategorizedTransactions } from '../../../services/transactionsService'

const COOKIE_NAME = 'uncategorizedTransactionsReminder'
const TRANSACTION_MIN_COUNT = 300

const formatDate = (expirationDays: number) => {
    return moment()
        .tz('America/New_York')
        .add(expirationDays, 'days')
        .format('YYYY/MM/DD')
}

const getMeetingLink = (contactId: string) => {
    return `https://1800accountant.com/sfapi/schedule/index.php?WID=${contactId}&EO=1&TID=BNA&EmailCampaignID=portal-bk-modal&APSBJ=portal-uncategorized-transactions`
}

function UncategorizedTransactionsModal() {
    const classes = useStyles()
    const [openInfoModal, setOpenInfoModal] = useState(false)
    const { hasBasicBookkeepingPermission } = usePermission()
    const useContactId = GetCurrentUserContactId()

    const setReminderExpiryInCookie = () => {
        const cookieVal = formatDate(60)
        setCookie(COOKIE_NAME, cookieVal, 60)
    }

    const closeModal = () => {
        setReminderExpiryInCookie()
        setOpenInfoModal(false)
    }

    const scheduleAppointment = () => {
        if (useContactId) {
            const meetingLink = getMeetingLink(useContactId)
            window.open(meetingLink, '_blank')
        }
    }

    useEffect(() => {
        const cookieVal = getCookie(COOKIE_NAME)
        if (!cookieVal && !hasBasicBookkeepingPermission) {
            getUnCategorizedTransactions().then((response: any) => {
                if (parseInt(response.itemsCount) > TRANSACTION_MIN_COUNT) {
                    setOpenInfoModal(true)
                }
            })
        }
    }, [hasBasicBookkeepingPermission])

    return (
        <UiDialog
            open={openInfoModal}
            handleClose={closeModal}
            title=""
            size="sm"
            customRootClass={classes.container}
        >
            <Fragment>
                <div>
                    <Fragment>
                        <Typography
                            className={classes.TextCenter}
                            variant="h6"
                            style={{ marginBottom: 24 }}
                        >
                            300+ Uncategorized Transactions
                        </Typography>
                        <Typography
                            className={classes.TextCenter}
                            variant="body1"
                        >
                            Your books are falling behind--and impacting your
                            tax savings! Would you like to learn how one of our
                            bookkeepers can help you keep track of profits and
                            taxes in real-time? Talk to an agent about adding
                            Bookkeeping to your service.
                        </Typography>
                    </Fragment>
                </div>

                <div className={classes.formActions}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            closeModal()
                            scheduleAppointment()
                        }}
                        color="primary"
                    >
                        Schedule an appointment to get help
                    </Button>
                </div>
            </Fragment>
        </UiDialog>
    )
}

export default UncategorizedTransactionsModal
