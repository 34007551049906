import { Fragment, useEffect, useState } from 'react';
import UiDialog from '../../../common/ui/UiDialog';
import { Typography, Theme, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import UiFormField from '../../../common/ui/UiFormField';
import { ApplicationStore, Config, Product } from '../../../../models';
import { connect } from 'react-redux';
import { AccessTime } from '@material-ui/icons';
import Loader from '../../../common/Loader';
import {
    getEntityManagementProducts,
    obtainEin,
} from '../../../../services/apiService';
import ObtainEINPopupIntro from './ObtainEINPopupIntro';
import { hasPermission } from '../../../../services/aclService';
import ProductPurchase from '../../../sales/ProductPurchase';
import { showAlert } from '../../../../store/actions/feedback';
import { formSubmitErrorHandler } from '../../../../services/formService';
import { COLORS } from '../../../../variables/colors';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';

interface ObtainEINPopupProps {
    open: boolean;
    handleClose: (result?: { modalState: boolean; setEIN?: boolean }) => void;
    config: Config;
    businessId: string;
    accountId: string;
    businessAccount: any;
}

const initialFormValues = {
    business_entity_type: '',
    trade_name: '',
    number_of_members: 0,
    taxation_of_llc: '',
    name_of_entity: '',
    org_type: '',
    trust_type: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    ssn: '',
    verify_ssn: '',
    title: '',
    street: '',
    city: '',
    state: '',
    zip_code: '',
    is_different_address: false,
    mailing_street: '',
    mailing_city: '',
    mailing_state: '',
    mailing_zip_code: '',
    trustee_first_name: '',
    trustee_middle_name: '',
    trustee_last_name: '',
    origin_country: '',
    origin_state: '',
    reason_for_applying: 'started_new_business',
    primary_activity: '',
    specific_products_or_services: '',
    has_highway_motor_vehicle: 'no',
    has_gambling: 'no',
    has_alcohol_or_tobacco_or_firearms: 'no',
    has_pay_federal_excise_taxes: 'no',
    has_applied_for_ein_before: 'no',
    previous_federal_id: '',
    annual_or_quarterly_taxes: 'no',
    hire_employees: 'no',
    date_of_first_wage_payment: Math.floor(new Date().valueOf() / 1000),
    agricultural_employees: 0,
    household_employees: 0,
    other_employees: 0,
    is_filing_taxes: 'yes',
    is_appoint_agent: 'yes',
    business_acquired_date: Math.floor(new Date().valueOf() / 1000),
    accounting_year_end: '12',
    phone_number: '',
    email: '',
};

function ObtainEINPopup({
    open,
    handleClose,
    config,
    accountId,
    businessId,
    businessAccount,
}: ObtainEINPopupProps) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles((theme: Theme) => ({
        marginVertical: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        blockButton: {
            width: '70%',
            justifyContent: 'flex-start',
            fontWeight: 400,
            marginBottom: theme.spacing(2),
        },
        reviewOrderButton: {
            '&:not(:disabled)': {
                backgroundColor: colorTheme.orange300,
                color: colorTheme.primaryWhite,
            },
        },
    }))();
    const businessTypeLLCOptionsData = config.apiConfig.business.types.filter(type => !type.title.includes('LLC'))
    const [sectionsCompleted, setSectionsCompleted] = useState<string[]>([]);
    const [initialValues, setInitialValues] = useState(
        Object.assign({}, initialFormValues)
    );
    const [introCompleted, setIntroCompleted] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [product, setProduct] = useState<Product>();

    const validationSchema = Yup.object({
        business_entity_type: Yup.string(),
        trade_name: Yup.string(),
        number_of_members: Yup.number().when('business_entity_type', {
            is: (entityType: string, test: any) =>
                ['partnership', 'LLC'].includes(entityType),
            then: Yup.number().required(
                'Number of Members/Partners is required'
            ),
            otherwise: Yup.number(),
        }),
        taxation_of_llc: Yup.string().when('business_entity_type', {
            is: 'LLC',
            then: Yup.string().required('Taxation of LLC is required'),
            otherwise: Yup.string(),
        }),
        trust_type: Yup.string(),
        org_type: Yup.string().when('business_entity_type', {
            is: 'NON_PROFIT',
            then: Yup.string().required('Organization type is required'),
            otherwise: Yup.string(),
        }),
        name_of_entity: Yup.string().when('business_entity_type', {
            is: (entityType: string) =>
                [
                    'partnership',
                    'corporation',
                    'LLC',
                    'S',
                    'trust',
                    'NON_PROFIT',
                ].includes(entityType),
            then: Yup.string().required('This is is required'),
            otherwise: Yup.string(),
        }),
        first_name: Yup.string().required('First name is required'),
        middle_name: Yup.string(),
        last_name: Yup.string().required('Last name is required'),
        ssn: Yup.string()
            .required('SSN is required'),
        verify_ssn: Yup.lazy((value) =>
            Yup.string()
                .required('Verify SSN is required')
                .when('ssn', {
                    is: (ssnValue: string) => { 
                        return ssnValue?.replaceAll(
                            /[^a-z0-9 ]+/gi,
                            ''
                        ) === value?.replaceAll(
                            /[^a-z0-9 ]+/gi,
                            ''
                        );},
                    otherwise: Yup.string().test(
                        'is-ssn',
                        "SSN don't match",
                        (v) => false
                    ),
                })
        ),
        title: Yup.string().required('Title is required'),
        street: Yup.string().required('Street is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip_code: Yup.string().required('Zip code is required'),
        is_different_address: Yup.boolean(),
        mailing_street: Yup.lazy((value) =>
            Yup.string().when('is_different_address', {
                is: true,
                then: Yup.string().required('Street is required'),
                otherwise: Yup.string(),
            })
        ),
        mailing_city: Yup.lazy((value) =>
            Yup.string().when('is_different_address', {
                is: true,
                then: Yup.string().required('City is required'),
                otherwise: Yup.string(),
            })
        ),
        mailing_state: Yup.lazy((value) =>
            Yup.string().when('is_different_address', {
                is: true,
                then: Yup.string().required('State is required'),
                otherwise: Yup.string(),
            })
        ),
        mailing_zip_code: Yup.lazy((value) =>
            Yup.string().when('is_different_address', {
                is: true,
                then: Yup.string().required('Zip code is required'),
                otherwise: Yup.string(),
            })
        ),
        trustee_first_name: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType === 'trust',
            then: Yup.string().required('First name is required'),
            otherwise: Yup.string(),
        }),
        trustee_middle_name: Yup.string(),
        trustee_last_name: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType === 'trust',
            then: Yup.string().required('Last name is required'),
            otherwise: Yup.string(),
        }),
        origin_country: Yup.string().when('business_entity_type', {
            is: (entityType: string) =>
                ['corporation', 'LLC', 'S'].includes(entityType),
            then: Yup.string().required('Country of Origin is required'),
            otherwise: Yup.string(),
        }),
        origin_state: Yup.string().when('business_entity_type', {
            is: (entityType: string) =>
                ['corporation', 'LLC', 'S'].includes(entityType),
            then: Yup.string().when('origin_country', {
                is: 'US',
                then: Yup.string().required('State of Origin is required'),
                otherwise: Yup.string(),
            }),
            otherwise: Yup.string(),
        }),
        reason_for_applying: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('Reason for applying is required'),
            otherwise: Yup.string(),
        }),
        primary_activity: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('Primary activity is required'),
            otherwise: Yup.string(),
        }),
        specific_products_or_services: Yup.string().when(
            'business_entity_type',
            {
                is: (entityType: string) => entityType !== 'trust',
                then: Yup.string().required(
                    'Specific products or services is required'
                ),
                otherwise: Yup.string(),
            }
        ),
        has_highway_motor_vehicle: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        has_gambling: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        has_alcohol_or_tobacco_or_firearms: Yup.string().when(
            'business_entity_type',
            {
                is: (entityType: string) => entityType !== 'trust',
                then: Yup.string().required('This is required'),
                otherwise: Yup.string(),
            }
        ),
        has_pay_federal_excise_taxes: Yup.string().when(
            'business_entity_type',
            {
                is: (entityType: string) => entityType !== 'trust',
                then: Yup.string().required('This is required'),
                otherwise: Yup.string(),
            }
        ),
        has_applied_for_ein_before: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        previous_federal_id: Yup.lazy((value) =>
            Yup.string().when('has_applied_for_ein_before', {
                is: 'yes',
                then: Yup.string()
                    .required('Previous federal tax ID is required')
                    .matches(
                        /[0-9]{2}-[0-9]{7}/g,
                        'Enter a valid Federal tax ID'
                    ),
                otherwise: Yup.string(),
            })
        ),
        annual_or_quarterly_taxes: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        hire_employees: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        date_of_first_wage_payment: Yup.lazy((value) =>
            Yup.number().when('hire_employees', {
                is: 'yes',
                then: Yup.number().required('Date is required').typeError(''),
                otherwise: Yup.number().nullable(),
            })
        ),
        agricultural_employees: Yup.lazy((value) =>
            Yup.number().when('hire_employees', {
                is: 'yes',
                then: Yup.number().required('This is required'),
                otherwise: Yup.number(),
            })
        ),
        household_employees: Yup.lazy((value) =>
            Yup.number().when('hire_employees', {
                is: 'yes',
                then: Yup.number().required('This is required'),
                otherwise: Yup.number(),
            })
        ),
        other_employees: Yup.lazy((value) =>
            Yup.number().when('hire_employees', {
                is: 'yes',
                then: Yup.number().required('This is required'),
                otherwise: Yup.number(),
            })
        ),
        is_filing_taxes: Yup.string().when('business_entity_type', {
            is: (entityType: string) => entityType !== 'trust',
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        is_appoint_agent: Yup.string().when('business_entity_type', {
            is: (entityType: string) =>
                ['corporation', 'LLC', 'S', 'NON_PROFIT'].includes(entityType),
            then: Yup.string().required('This is required'),
            otherwise: Yup.string(),
        }),
        business_acquired_date: Yup.number()
            .required('Business Acquired Date is required')
            .typeError(''),
        accounting_year_end: Yup.number().required('Fiscal year is required'),
        phone_number: Yup.string().required('Phone number is required'),
        email: Yup.string().required('Email is required'),
    });

    const onSubmit = (values: any, actions: FormikHelpers<any>) => {
        setSubmittingForm(true);
        const data = Object.assign({}, values);
        for (const key in data) {
            if (data[key] === 'yes' || data[key] === 'no') {
                data[key] = data[key] === 'yes';
            }
        }

        formSubmitErrorHandler(
            obtainEin(
                accountId,
                businessId,
                data.business_entity_type,
                data
            ).then((res) => {
                showAlert({
                    alertText: 'You request is submitted.',
                    alertType: 'success',
                });
                handleClose({ modalState: false });
            }),
            () => {
                setSubmittingForm(false);
            },
            actions.setFieldError
        );
  
    };

    const handleEntitySelect = (
        formik: any,
        entityType?: { id: string; title: string }
    ) => {
        if (!entityType) {
            setSectionsCompleted([]);
        }
        initialValues.business_entity_type = entityType ? entityType.id : '';
        setInitialValues({ ...initialValues });
    };

    const handleAddSection = (sectionName: string) => {
        setSectionsCompleted(sectionsCompleted.concat(sectionName));
    };

    const questionnaireRequired = [
        'sole',
        'partnership',
        'corporation',
        'LLC',
        'S',
        'NON_PROFIT',
    ];

    const entityTypesIconsMapping: any = {
        sole: 'User',
        partnership: 'Financial Team',
        corporation: 'Bank',
        LLC: 'LLC',
        S: 'S_Corporation',
        NON_PROFIT: 'Non_Profit',
        trust: 'Trust',
    };

    const entityNameLabelPlaceholderMapping: any = {
        partnership: {
            label: 'Name of Partnership (Required)',
            placeholder: 'Name of Partnership',
        },
        corporation: {
            label: 'Name of Corporation (Required)',
            placeholder: 'Name of Business',
        },
        LLC: {
            label: 'Name of Corporation (Required)',
            placeholder: 'Name of Business',
        },
        S: {
            label: 'Name of Corporation (Required)',
            placeholder: 'Name of Business',
        },
        trust: {
            label: 'Name of Trust (Required)',
            placeholder: 'Name of Trust',
        },
        NON_PROFIT: {
            label: 'Organization Name (Required)',
            placeholder: 'Organization Name',
        },
    };

    const checkIfFieldsValidInSection = (
        sectionName:
            | 'entityTypeInformation'
            | 'personalInformation'
            | 'businessAddress'
            | 'trusteeInformation'
            | 'basicBusinessInformation'
            | 'additionalBusinessDetails',
        formik: any
    ) => {
        const sectionFields: any = {
            entityTypeInformation: {},
            personalInformation: {
                first_name: '',
                middle_name: '',
                last_name: '',
                ssn: '',
                verify_ssn: '',
            },
            businessAddress: {
                street: '',
                city: '',
                state: '',
                zip_code: '',
                is_different_address: '',
                mailing_street: '',
                mailing_city: '',
                mailing_state: '',
                mailing_zip_code: '',
            },
            basicBusinessInformation: {
                origin_country: '',
                origin_state: '',
                reason_for_applying: '',
                primary_activity: '',
                specific_products_or_services: '',
            },
            additionalBusinessDetails: {
                has_highway_motor_vehicle: '',
                has_gambling: '',
                has_alcohol_or_tobacco_or_firearms: '',
                has_pay_federal_excise_taxes: '',
                has_applied_for_ein_before: '',
                previous_federal_id: '',
                annual_or_quarterly_taxes: '',
                hire_employees: '',
                date_of_first_wage_payment: null,
                agricultural_employees: 0,
                household_employees: 0,
                other_employees: 0,
            },
        };
        const entityType = formik.values.business_entity_type;
        if (
            [
                'partnership',
                'corporation',
                'LLC',
                'S',
                'trust',
                'NON_PROFIT',
            ].includes(entityType)
        ) {
            sectionFields.entityTypeInformation = {
                ...sectionFields.entityTypeInformation,
                name_of_entity: '',
            };
        }

        if (['partnership', 'LLC'].includes(entityType)) {
            sectionFields.entityTypeInformation = {
                ...sectionFields.entityTypeInformation,
                number_of_members: '',
            };
        }

        if (['LLC'].includes(entityType)) {
            sectionFields.entityTypeInformation = {
                ...sectionFields.entityTypeInformation,
                taxation_of_llc: '',
            };
        }

        if (['NON_PROFIT'].includes(entityType)) {
            sectionFields.entityTypeInformation = {
                ...sectionFields.entityTypeInformation,
                org_type: '',
            };
        }

        if (formik.values.business_entity_type === 'trust') {
            sectionFields.trusteeInformation = {
                first_name: '',
                last_name: '',
                title: '',
            };
            sectionFields.entityTypeInformation = {
                ...sectionFields.entityTypeInformation,
                trust_type: '',
            };
        } else {
            sectionFields.entityTypeInformation = {
                ...sectionFields.entityTypeInformation,
                trade_name: '',
            };
            sectionFields.personalInformation = {
                ...sectionFields.personalInformation,
                title: '',
            };
        }

        let formInvalid = false;
        const currentSection = sectionFields[sectionName];
        for (const key in currentSection) {
            if (!!formik.getFieldMeta(key).error) {
                formInvalid = true;
            }
        }
        return formInvalid;
    };
    const utmContent = 'Entity+Dashboard+Annual+Report';
    const getPersonalInformationTitle = (businessEntityType: any) => {
        if (businessEntityType === 'partnership') {
            return 'General Partner Information';
        }
        if (businessEntityType === 'S') {
            return 'Principal Officer Information';
        }
        if (businessEntityType === 'trust') {
            return 'Grantor/Creator Information';
        }
        return 'Personal Information';
    };

    useEffect(() => {
        getEntityManagementProducts(accountId, businessId).then((res) => {
            setProduct(
                res?.recommended?.find(
                    (product) => product.code === 'EIN_STANDARD'
                ) || undefined
            );
        });
    }, [accountId, businessId]);

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title='Obtain Your Employer Identification Number'
            size='sm'
            onExit={() => {
                setSectionsCompleted([]);
                setInitialValues(initialFormValues);
                setIntroCompleted(false);
            }}
        >
            {introCompleted ? (
                <>
                    <Typography variant='h6' className={styles.marginVertical}>
                        Select your business entity type
                    </Typography>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                        validateOnMount={true}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    {
                                        /**
                                         * Selected Entity Type
                                         */
                                        initialValues.business_entity_type ? (
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <Button
                                                        type='button'
                                                        color='secondary'
                                                        style={{
                                                            fontWeight: 400,
                                                        }}
                                                        startIcon={
                                                            <img
                                                                src={
                                                                    require(`../../../../assets/icons-svg/${entityTypesIconsMapping[
                                                                        initialValues
                                                                            .business_entity_type
                                                                        ]
                                                                        }.svg`)
                                                                        .default
                                                                }
                                                                style={{
                                                                    marginRight: 10,
                                                                }}
                                                                alt='Entity type'
                                                            />
                                                        }
                                                    >
                                                        {
                                                            config.apiConfig.entity_management.ein_entity_types.find(
                                                                (entityType) =>
                                                                    entityType.id ===
                                                                    initialValues.business_entity_type
                                                            )?.title
                                                        }
                                                    </Button>
                                                    <Button
                                                        type='button'
                                                        variant='text'
                                                        onClick={() =>
                                                            handleEntitySelect(
                                                                formik
                                                            )
                                                        }
                                                    >
                                                        Change
                                                    </Button>
                                                </div>

                                                {sectionsCompleted.includes(
                                                    'selectBusinessType'
                                                ) ? (
                                                    <div
                                                        className={
                                                            styles.marginVertical
                                                        }
                                                    >
                                                        &nbsp;
                                                    </div>
                                                ) : (
                                                    <Fragment>
                                                        <div
                                                            className={
                                                                styles.marginVertical
                                                            }
                                                        >
                                                            <Button
                                                                type='button'
                                                                variant='contained'
                                                                color='primary'
                                                                onClick={() =>
                                                                    handleAddSection(
                                                                        'selectBusinessType'
                                                                    )
                                                                }
                                                            >
                                                                Next
                                                            </Button>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        ) : (
                                            <Fragment>
                                                {
                                                    /**
                                                     * Entity Type List
                                                     */
                                                    config.apiConfig.entity_management.ein_entity_types.map(
                                                        (entityType, index) => (
                                                            <Button
                                                                variant='outlined'
                                                                color='secondary'
                                                                size='large'
                                                                className={
                                                                    styles.blockButton
                                                                }
                                                                key={index}
                                                                type='button'
                                                                startIcon={
                                                                    <img
                                                                        src={
                                                                            require(`../../../../assets/icons-svg/${entityTypesIconsMapping[
                                                                                entityType
                                                                                    .id
                                                                                ]
                                                                                }.svg`)
                                                                                .default
                                                                        }
                                                                        style={{
                                                                            marginRight: 10,
                                                                        }}
                                                                        alt='Entity Type'
                                                                    />
                                                                }
                                                                onClick={() =>
                                                                    handleEntitySelect(
                                                                        formik,
                                                                        entityType
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    entityType.title
                                                                }
                                                            </Button>
                                                        )
                                                    )
                                                }
                                                <div
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    <Button
                                                        type='button'
                                                        variant='contained'
                                                        color='primary'
                                                        disabled
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                                <Typography
                                                    variant='body2'
                                                    color='textSecondary'
                                                    classes={{
                                                        root:
                                                            styles.marginVertical,
                                                    }}
                                                    className='d-flex align-items-center'
                                                >
                                                    <AccessTime /> &nbsp; This
                                                    application takes on average
                                                    10 minutes to complete.
                                                </Typography>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Entity Information Section
                                         */
                                        sectionsCompleted.includes(
                                            'selectBusinessType'
                                        ) && (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    {
                                                        config.apiConfig.entity_management.ein_entity_types.find(
                                                            (entityType) =>
                                                                entityType.id ===
                                                                initialValues.business_entity_type
                                                        )?.title
                                                    }{' '}
                                                    Information
                                                </Typography>
                                                {[
                                                    'partnership',
                                                    'corporation',
                                                    'LLC',
                                                    'S',
                                                    'trust',
                                                    'NON_PROFIT',
                                                ].includes(
                                                    formik.values
                                                        .business_entity_type
                                                ) && (
                                                        <UiFormField
                                                            type='text'
                                                            placeholder={
                                                                entityNameLabelPlaceholderMapping[
                                                                    formik.values
                                                                        .business_entity_type
                                                                ].placeholder
                                                            }
                                                            label={
                                                                entityNameLabelPlaceholderMapping[
                                                                    formik.values
                                                                        .business_entity_type
                                                                ].label
                                                            }
                                                            fieldName='name_of_entity'
                                                            fieldSize={8}
                                                        />
                                                    )}
                                                {[
                                                    'partnership',
                                                    'LLC',
                                                ].includes(
                                                    formik.values
                                                        .business_entity_type
                                                ) && (
                                                        <UiFormField
                                                            type='text'
                                                            placeholder={
                                                                'Number of Members/Partners'
                                                            }
                                                            label={
                                                                'How many number of Members/Partners? (Required)'
                                                            }
                                                            fieldName='number_of_members'
                                                            fieldSize={8}
                                                        />
                                                    )}
                                                {formik.values
                                                    .business_entity_type !==
                                                    'trust' && (
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Trade Name/DBA'
                                                            label='What is your Trade Name/DBA? (Optional)'
                                                            fieldName='trade_name'
                                                            fieldSize={8}
                                                        />
                                                    )}
                                                {formik.values
                                                    .business_entity_type ===
                                                    'LLC' && (
                                                        <UiFormField
                                                            type='select'
                                                            placeholder='LLC will be Taxed as a'
                                                            label='LLC will be Taxed as a (Required)'
                                                            fieldName='taxation_of_llc'
                                                            optionsData={businessTypeLLCOptionsData}
                                                            optionKey='title'
                                                            optionValue='id'
                                                        />
                                                    )}
                                                {formik.values
                                                    .business_entity_type ===
                                                    'trust' && (
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Type of Trust'
                                                            label='Type of Trust (Optional)'
                                                            fieldName='trust_type'
                                                            fieldSize={8}
                                                        />
                                                    )}
                                                {formik.values
                                                    .business_entity_type ===
                                                    'NON_PROFIT' && (
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Organization Type (eg Educational)'
                                                            label='Organization Type (Required)'
                                                            fieldName='org_type'
                                                            fieldSize={8}
                                                        />
                                                    )}
                                                {!sectionsCompleted.includes(
                                                    'entityTypeInformation'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            type='button'
                                                            color='primary'
                                                            disabled={checkIfFieldsValidInSection(
                                                                'entityTypeInformation',
                                                                formik
                                                            )}
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'entityTypeInformation'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Personal Information Section
                                         */
                                        sectionsCompleted.includes(
                                            'entityTypeInformation'
                                        ) && (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    {getPersonalInformationTitle(
                                                        formik.values
                                                            .business_entity_type
                                                    )}
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='First Name'
                                                            label='What is your name? (Required)'
                                                            fieldName='first_name'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Last Name'
                                                            label=''
                                                            fieldName='last_name'
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <UiFormField
                                                    type='mask'
                                                    mask={[
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        '-',
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        '-',
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                    ]}
                                                    placeholder='XXX-XX-XXXX'
                                                    label='What is your Social Security Number? (Required)'
                                                    fieldName='ssn'
                                                    fieldSize={6}
                                                    minLength={9}
                                                />
                                                <UiFormField
                                                    type='mask'
                                                    mask={[
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        '-',
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        '-',
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                        /[0-9]/,
                                                    ]}
                                                    placeholder='XXX-XX-XXXX'
                                                    label='Verify your Social Security Number. (Required)'
                                                    fieldName='verify_ssn'
                                                    fieldSize={6}
                                                    minLength={9}
                                                />
                                                {formik.values
                                                    .business_entity_type !==
                                                    'trust' && (
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='eg General Partner'
                                                            label='Title (Required)'
                                                            fieldName='title'
                                                            fieldSize={6}
                                                        />
                                                    )}
                                                {!sectionsCompleted.includes(
                                                    'personalInformation'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='button'
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            disabled={checkIfFieldsValidInSection(
                                                                'personalInformation',
                                                                formik
                                                            )}
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'personalInformation'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Business Address Section
                                         */
                                        sectionsCompleted.includes(
                                            'personalInformation'
                                        ) && (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    {formik.values
                                                        .business_entity_type ===
                                                        'trust'
                                                        ? 'Trustee Address'
                                                        : "What's your business's address? (Required)"}
                                                </Typography>

                                                <Typography
                                                    variant='body1'
                                                    color='textSecondary'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Cannot be a P.O. box
                                                </Typography>

                                                <UiFormField
                                                    type='text'
                                                    placeholder='Street Address'
                                                    label=''
                                                    labelSize={false}
                                                    fieldName='street'
                                                />
                                                <Grid container spacing={1}>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='City'
                                                            label=''
                                                            labelSize={false}
                                                            fieldName='city'
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <UiFormField
                                                            type='select'
                                                            optionsData={
                                                                config.apiConfig
                                                                    .generic
                                                                    .states
                                                            }
                                                            optionKey='name'
                                                            optionValue='abbr'
                                                            placeholder='State'
                                                            label=''
                                                            labelSize={false}
                                                            fieldName='state'
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Zip Code'
                                                            label=''
                                                            labelSize={false}
                                                            fieldName='zip_code'
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <UiFormField
                                                    type='checkbox'
                                                    inlineLable='Different Mailing Address?'
                                                    label=''
                                                    labelSize={false}
                                                    fieldName='is_different_address'
                                                />

                                                {
                                                    /**
                                                     * Mailing Address Section
                                                     */
                                                    formik.values
                                                        .is_different_address && (
                                                        <Fragment>
                                                            <Typography
                                                                variant='body1'
                                                                color='textSecondary'
                                                                className={
                                                                    styles.marginVertical
                                                                }
                                                            >
                                                                Mailing Address
                                                            </Typography>
                                                            <UiFormField
                                                                type='text'
                                                                placeholder='Street Address'
                                                                label=''
                                                                labelSize={
                                                                    false
                                                                }
                                                                fieldName='mailing_street'
                                                            />
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    md={6}
                                                                >
                                                                    <UiFormField
                                                                        type='text'
                                                                        placeholder='City'
                                                                        label=''
                                                                        labelSize={
                                                                            false
                                                                        }
                                                                        fieldName='mailing_city'
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={3}
                                                                >
                                                                    <UiFormField
                                                                        type='select'
                                                                        optionsData={
                                                                            config
                                                                                .apiConfig
                                                                                .generic
                                                                                .states
                                                                        }
                                                                        optionKey='name'
                                                                        optionValue='abbr'
                                                                        placeholder='State'
                                                                        label=''
                                                                        labelSize={
                                                                            false
                                                                        }
                                                                        fieldName='mailing_state'
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={3}
                                                                >
                                                                    <UiFormField
                                                                        type='text'
                                                                        placeholder='Zip Code'
                                                                        label=''
                                                                        labelSize={
                                                                            false
                                                                        }
                                                                        fieldName='mailing_zip_code'
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Fragment>
                                                    )
                                                }
                                                {!sectionsCompleted.includes(
                                                    'businessAddress'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='button'
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            disabled={checkIfFieldsValidInSection(
                                                                'businessAddress',
                                                                formik
                                                            )}
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'businessAddress'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Basic Information Section OR Trustee Information Section
                                         */
                                        sectionsCompleted.includes(
                                            'businessAddress'
                                        ) &&
                                        (formik.values
                                            .business_entity_type ===
                                            'trust' ? (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Trustee Information
                                                    </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='First Name'
                                                            label='What is your name? (Required)'
                                                            fieldName='trustee_first_name'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Last Name'
                                                            label=''
                                                            fieldName='trustee_last_name'
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <UiFormField
                                                    type='text'
                                                    placeholder='eg General Partner'
                                                    label='Title (Required)'
                                                    fieldName='title'
                                                    fieldSize={6}
                                                />
                                                {!sectionsCompleted.includes(
                                                    'trusteeInformation'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='button'
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            disabled={checkIfFieldsValidInSection(
                                                                'trusteeInformation',
                                                                formik
                                                            )}
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'trusteeInformation'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Basic information about
                                                    your business
                                                    </Typography>
                                                {[
                                                    'corporation',
                                                    'LLC',
                                                    'S',
                                                ].includes(
                                                    formik.values
                                                        .business_entity_type
                                                ) && (
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type='select'
                                                                    optionsData={
                                                                        config
                                                                            .apiConfig
                                                                            .generic
                                                                            .countries
                                                                    }
                                                                    optionKey='name'
                                                                    optionValue='abbr'
                                                                    placeholder='Country of Origin'
                                                                    label=''
                                                                    labelSize={
                                                                        false
                                                                    }
                                                                    fieldName='origin_country'
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type='select'
                                                                    optionsData={
                                                                        config
                                                                            .apiConfig
                                                                            .generic
                                                                            .states
                                                                    }
                                                                    optionKey='name'
                                                                    optionValue='abbr'
                                                                    placeholder='State of Origin'
                                                                    label=''
                                                                    labelSize={
                                                                        false
                                                                    }
                                                                    fieldName='origin_state'
                                                                    disabled={
                                                                        formik
                                                                            .values
                                                                            .origin_country !==
                                                                        'US'
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                <UiFormField
                                                    type='select'
                                                    optionsData={
                                                        config.apiConfig
                                                            .entity_management
                                                            .reason_for_applying
                                                    }
                                                    optionKey='name'
                                                    optionValue='id'
                                                    label='What is your reason for applying? (Required)'
                                                    placeholder='Select a reason'
                                                    fieldName='reason_for_applying'
                                                    fieldSize={7}
                                                />
                                                <UiFormField
                                                    type='select'
                                                    optionsData={
                                                        config.apiConfig
                                                            .entity_management
                                                            .primary_activity
                                                    }
                                                    optionKey='name'
                                                    optionValue='id'
                                                    label='What is your primary activity? (Required)'
                                                    placeholder='Select an activity'
                                                    fieldName='primary_activity'
                                                    fieldSize={7}
                                                />
                                                <UiFormField
                                                    type='text'
                                                    label='What products or services do you offer? (Required)'
                                                    placeholder='Write your answer'
                                                    fieldName='specific_products_or_services'
                                                />
                                                {!sectionsCompleted.includes(
                                                    'basicBusinessInformation'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='button'
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            disabled={checkIfFieldsValidInSection(
                                                                'basicBusinessInformation',
                                                                formik
                                                            )}
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'basicBusinessInformation'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        ))
                                    }
                                    {
                                        /**
                                         * Additional Business Details Section
                                         */
                                        sectionsCompleted.includes(
                                            'basicBusinessInformation'
                                        ) &&
                                        formik.values
                                            .business_entity_type !==
                                        'trust' && (
                                            <Fragment>
                                                {questionnaireRequired.includes(
                                                    formik.values
                                                        .business_entity_type
                                                ) && (
                                                        <Fragment>
                                                            <Typography
                                                                variant='h6'
                                                                className={
                                                                    styles.marginVertical
                                                                }
                                                            >
                                                                Additional
                                                                Business Details
                                                            </Typography>
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='has_highway_motor_vehicle'
                                                                label='Does your business own a highway motor vehicle that weighs 55,000 pounds or more? (Required)'
                                                            />
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='has_gambling'
                                                                label='Does your business involve gambling? (Required)'
                                                            />
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='has_alcohol_or_tobacco_or_firearms'
                                                                label='Does your business sell or manufacture alcohol, tobacco, or firearms? (Required)'
                                                            />
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='has_pay_federal_excise_taxes'
                                                                label='Does your business pay federal excise taxes? (Required)'
                                                            />
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='has_applied_for_ein_before'
                                                                label='Has this entity applied for an EIN before? (Required)'
                                                            />
                                                            {formik.values
                                                                .has_applied_for_ein_before ===
                                                                'yes' && (
                                                                    <UiFormField
                                                                        type='mask'
                                                                        placeholder='XX-XXXXXXX'
                                                                        label='What was the previous federal tax ID (Required)'
                                                                        fieldName='previous_federal_id'
                                                                        mask={[
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            '-',
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                            /[0-9]/,
                                                                        ]}
                                                                        minLength={9}
                                                                    />
                                                                )}
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='annual_or_quarterly_taxes'
                                                                label='If you expect to pay less than $4,000 in wages over the next calendar year, do you wish to file annual instead of quarterly taxes? (Required)'
                                                            />
                                                            <UiFormField
                                                                type='toggleButton'
                                                                fieldName='hire_employees'
                                                                label='Do you already have or are you expecting to hire an employee within 12 months, excluding owners? (Required)'
                                                            />
                                                            {formik.values
                                                                .hire_employees ===
                                                                'yes' && (
                                                                    <Fragment>
                                                                        <UiFormField
                                                                            type='date'
                                                                            placeholder='Select Date'
                                                                            label='What is the date of the first wage payment? (Required)'
                                                                            fieldName='date_of_first_wage_payment'
                                                                        />
                                                                        <UiFormField
                                                                            type='number'
                                                                            placeholder='0'
                                                                            label='How many are agricultural employees?'
                                                                            fieldName='agricultural_employees'
                                                                        />
                                                                        <UiFormField
                                                                            type='number'
                                                                            placeholder='0'
                                                                            label='How many are household employees?'
                                                                            fieldName='household_employees'
                                                                        />
                                                                        <UiFormField
                                                                            type='number'
                                                                            placeholder='0'
                                                                            label='How many are other employees?'
                                                                            fieldName='other_employees'
                                                                        />
                                                                    </Fragment>
                                                                )}
                                                        </Fragment>
                                                    )}
                                                {!sectionsCompleted.includes(
                                                    'additionalBusinessDetails'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='button'
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            disabled={checkIfFieldsValidInSection(
                                                                'additionalBusinessDetails',
                                                                formik
                                                            )}
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'additionalBusinessDetails'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Registered Agent Selection Section
                                         */
                                        sectionsCompleted.includes(
                                            'additionalBusinessDetails'
                                        ) &&
                                        [
                                            'corporation',
                                            'LLC',
                                            'S',
                                            'NON_PROFIT',
                                        ].includes(
                                            formik.values
                                                .business_entity_type
                                        ) && (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Registered Agent
                                                    </Typography>

                                                <UiFormField
                                                    type='radio'
                                                    fieldName='is_appoint_agent'
                                                    label='It is required by law in the state you’re registering in to appoint a registered agent for your selected business type.'
                                                    labelClass='align-items-start'
                                                    radioOptionsData={[
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            '9px 0',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        Have
                                                                        1800Accountant
                                                                        be
                                                                        your
                                                                        Registered
                                                                        Agent.
                                                                        </div>
                                                                    <Typography
                                                                        variant='caption'
                                                                        color='textSecondary'
                                                                    >
                                                                        By
                                                                        appointing
                                                                        us
                                                                        as
                                                                        your
                                                                        registered
                                                                        agent,
                                                                        we’ll
                                                                        communicate
                                                                        with
                                                                        the
                                                                        State
                                                                        and
                                                                        Federal
                                                                        government
                                                                        on
                                                                        your
                                                                        behalf.
                                                                        Try
                                                                        free
                                                                        for
                                                                        10
                                                                        days.
                                                                        After
                                                                        the
                                                                        free
                                                                        trial
                                                                        period
                                                                        you’ll
                                                                        be
                                                                        billed
                                                                        $149/year.
                                                                        </Typography>
                                                                </div>
                                                            ),
                                                            value: 'yes',
                                                        },
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            '9px 0',
                                                                    }}
                                                                >
                                                                    I’ll
                                                                    appoint
                                                                    my own
                                                                    registered
                                                                    agent.
                                                                </div>
                                                            ),
                                                            value: 'no',
                                                        },
                                                    ]}
                                                />
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Business Tax Preparation Selection Section
                                         */
                                        sectionsCompleted.includes(
                                            'additionalBusinessDetails'
                                        ) &&
                                        formik.values
                                            .business_entity_type !==
                                        'trust' && (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Business Tax Preparation
                                                    </Typography>

                                                <UiFormField
                                                    type='radio'
                                                    fieldName='is_filing_taxes'
                                                    label='We’ll handle your federal and state filings. Get all the deductions your business is entitled to. (Required)'
                                                    labelClass='align-items-start'
                                                    radioOptionsData={[
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            '9px 0',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        Have
                                                                        1800Accountant
                                                                        prepare
                                                                        your
                                                                        tax
                                                                        filings.
                                                                        </div>
                                                                    <Typography
                                                                        variant='caption'
                                                                        color='textSecondary'
                                                                    >
                                                                        No
                                                                        matter
                                                                        your
                                                                        tax
                                                                        situation,
                                                                        we’ve
                                                                        got
                                                                        you
                                                                        covered.
                                                                        Get
                                                                        advice,
                                                                        protect
                                                                        yourself
                                                                        from
                                                                        audits
                                                                        and
                                                                        file
                                                                        your
                                                                        business
                                                                        taxes
                                                                        with
                                                                        confidence.
                                                                        Try
                                                                        free
                                                                        for
                                                                        10
                                                                        days.
                                                                        After
                                                                        the
                                                                        free
                                                                        trial
                                                                        period
                                                                        you’ll
                                                                        be
                                                                        billed
                                                                        $320/year.
                                                                        </Typography>
                                                                </div>
                                                            ),
                                                            value: 'yes',
                                                        },
                                                        {
                                                            label: (
                                                                <div
                                                                    style={{
                                                                        padding:
                                                                            '9px 0',
                                                                    }}
                                                                >
                                                                    I’ll do
                                                                    my own
                                                                    taxes.
                                                                </div>
                                                            ),
                                                            value: 'no',
                                                        },
                                                    ]}
                                                />
                                                {!sectionsCompleted.includes(
                                                    'is_filing_taxes'
                                                ) && (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            type='button'
                                                            className={
                                                                styles.marginBottom
                                                            }
                                                            disabled={
                                                                !!formik.getFieldMeta(
                                                                    'is_filing_taxes'
                                                                ).error
                                                            }
                                                            onClick={() =>
                                                                handleAddSection(
                                                                    'is_filing_taxes'
                                                                )
                                                            }
                                                        >
                                                            Next
                                                        </Button>
                                                    )}
                                            </Fragment>
                                        )
                                    }
                                    {
                                        /**
                                         * Date Acquired and Authorization Section
                                         */
                                        ((formik.values.business_entity_type ===
                                            'trust' &&
                                            sectionsCompleted.includes(
                                                'trusteeInformation'
                                            )) ||
                                            (formik.values
                                                .business_entity_type !==
                                                'trust' &&
                                                sectionsCompleted.includes(
                                                    'is_filing_taxes'
                                                ))) && (
                                            <Fragment>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Dates
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='date'
                                                            placeholder='Select Date'
                                                            label='Date Business Start or Acquired'
                                                            fieldName='business_acquired_date'
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='select'
                                                            placeholder='Select a month'
                                                            label='End of Fiscal Year'
                                                            optionsData={
                                                                config.apiConfig
                                                                    .months
                                                            }
                                                            optionKey='full_name'
                                                            optionValue='number'
                                                            fieldName='accounting_year_end'
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        styles.marginVertical
                                                    }
                                                >
                                                    Authorization
                                                </Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='mask'
                                                            placeholder='Your phone number'
                                                            label=''
                                                            labelSize={false}
                                                            fieldName='phone_number'
                                                            mask={[
                                                                '(',
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                                ')',
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                                '-',
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                                /[0-9]/,
                                                            ]}
                                                            minLength={10}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <UiFormField
                                                            type='text'
                                                            placeholder='Your email'
                                                            label=''
                                                            labelSize={false}
                                                            fieldName='email'
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {submittingForm ? (
                                                    <div
                                                        className={
                                                            styles.marginVertical
                                                        }
                                                    >
                                                        <Loader />
                                                    </div>
                                                ) : hasPermission(
                                                    'EIN',
                                                    businessAccount
                                                ) ? (
                                                    <Button
                                                        variant='contained'
                                                        fullWidth
                                                        className={
                                                            styles.marginVertical
                                                        }
                                                        type='submit'
                                                        disabled={
                                                            !formik.isValid
                                                        }
                                                        onClick={() => {
                                                            formik.validateForm();
                                                        }}
                                                        color='primary'
                                                    >
                                                        Submit
                                                    </Button>
                                                ) : product && (
                                                    <div
                                                        className={
                                                            styles.marginVertical
                                                        }
                                                    >
                                                        <ProductPurchase
                                                            product={
                                                                product as Product
                                                            }
                                                            fullWidth={true}
                                                            utmContentParam={utmContent}
                                                        />
                                                    </div>
                                                )}
                                            </Fragment>
                                        )
                                    }
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            ) : (
                <ObtainEINPopupIntro
                    setIntroCompleted={setIntroCompleted}
                    handleClose={handleClose}
                />
            )}
        </UiDialog>
    );
}

const mapStateToProps = ({ appData, config }: ApplicationStore) => ({
    config,
    accountId: appData.personal_account.id,
    businessId: appData.current_business_id,
    businessAccount: appData.current_account,
});
export default connect(mapStateToProps)(ObtainEINPopup);
