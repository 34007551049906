import { Fragment, useEffect, useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Container, makeStyles, Theme, Hidden } from '@material-ui/core'
import Trips from '../mileage-log/trips/Trips'
import { ActiveRoutingContext } from './Providers/ActiveRoutingProvider'
import { LoadScript } from '@react-google-maps/api'
import TripsProvider from '../mileage-log/trips/Provider/TripsProvider'
import TripPreview from '../mileage-log/trips/TripPreview'

const TripsRoutes = () => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()

    useEffect(() => {
        setActiveRouteHeading('Trips')
    },[setActiveRouteHeading])

    return (
        <TripsProvider>
            <Switch>
                <Fragment>
                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey={
                            (window as any).ApiConfig.google_maps_api_key
                        }
                        libraries={['places']}
                        loadingElement=" "
                    > 
                        <Hidden mdUp>
                            <Route path="/mileage/trips" exact>
                                <Trips />
                            </Route>
                            <Route path={`/mileage/trips/:id`} exact>
                                <Container className={classes.container} maxWidth="lg">
                                    <TripPreview />
                                </Container>
                            </Route>
                        </Hidden>
                        <Hidden smDown>
                            <Route path="/mileage/trips">
                                <Container className={classes.container} maxWidth="lg">
                                    <Trips />
                                </Container>
                            </Route>
                        </Hidden>
                    </LoadScript>
                </Fragment>
            </Switch>
        </TripsProvider>
    )
}

export default TripsRoutes
