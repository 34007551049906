import { Fragment, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    makeStyles,
    InputLabel,
    TextField,
    Paper,
    Theme,
    Hidden,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import UiDialog from '../../common/ui/UiDialog';
import { Form, Formik, FieldArray } from 'formik';
import DeleteIcon from '../../../assets/icons-svg/Delete.svg';
import * as Yup from 'yup';

import Loader from '../../common/Loader';
import ClearIcon from '@material-ui/icons/Clear';
import { ApplicationStore, Config } from '../../../models/store';
import {
    addJournalEntry,
    addCategory,
    patchJournalEntry,
} from '../../../services/journalServices';
import CreateCategory from './CreateCategory';
import Alert from '@material-ui/lab/Alert';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import { useJournalState } from './JournalProvider';

import moment from 'moment';
import { formSubmitErrorHandler } from '../../../services/formService';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface NewJournalModalProps {
    open: boolean;
    handleClose: (result?: any, action?: any) => void;
    config: Config;
    accountId: string;
    businessId: string;
    isEdit?: Boolean;
    getJournalEntry: (keepSelectedData?: boolean) => void;
    datasource: any;
    setListData: (data: any) => void;
    editData?: any;
    selectedData?: any;
    deleteData?: any;
}

const defaultJournalRowValues = {
    account: '',
    credit: null,
    debit: null,
    open: false,
    type: null,
};
const initialFormValues = {
    date: moment().hour(12).utc().hour(12).minute(0).second(0).unix(),
    description: '',
    entryRow: [defaultJournalRowValues],
};

const useStyle = makeStyles <Theme, ThemeColors>((theme: Theme) => ({
    marginVertical: {
        marginTop: 20,
        marginBottom: 20,
    },
    container: {
        '& .MuiPaper-root': {
            height: 816,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                margin: '0px',
                '& .MuiDialogTitle-root': {
                    display: 'none',
                },
            },
            '& .MuiDialogContent-root': {
                padding: '0px 0px',
                '& .top-section': {
                    '& .alert': {
                        height: '36px',
                        width: 'inherit',
                        marginBottom: theme.spacing(2),
                    },
                    '& .delete-section': {
                        display: 'flex',
                    },
                    [theme.breakpoints.down('sm')]: {
                        height: '100%',
                        maxHeight: '100%',
                        padding: '0px',
                    },
                },
            },
        },
    },
    marginBottom: {
        marginBottom: 20,
    },
    reviewOrderButton: {
        '&:not(:disabled)': {
            backgroundColor: (colorTheme) => colorTheme.orange300,
            color: (colorTheme) => colorTheme.primaryWhite
        },
    },
    autocomplete: {
        '& .MuiInputBase-fullWidth': {
            height: '40px',
            width: '222px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& .MuiAutocomplete-input': {
                padding: '0px',
            },
        },
    },
    footer: {
        position: `absolute`,
        bottom: 0,
        right: 0,
        width: '100%',
        background: (colorTheme) => colorTheme.primaryWhite,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '0.5rem',
        '& button:nth-child(1)': {
            marginRight: '10px',
            width: 57,
        },
        '& button:nth-child(2)': {
            marginRight: '10px',
            width: 113,
        },
    },
    seconderyActionButtonOutline: {
        height: 36,
        color: (colorTheme) => `${colorTheme.black100} !important`,
    },
    buttonGap: {
        marginRight: theme.spacing(2),
    },
    loader: {
        width: '113px',
        marginRight: '10px',
        '& .MuiCircularProgress-root': {
            width: '30px !important',
            height: '30px !important',
        },
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: (colorTheme) => colorTheme.grey400,
    },
    moveRight: {
        textAlign: 'right',
    },
    topHeader: {
        display: 'flex',
        borderBottom: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        flexDirection: 'column',
        padding: '10px',
        '& .title': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
        },
    },
    formContainer: {
        padding: theme.spacing(5, 4),
        height: '626px',
        overflowY: 'auto',
        maxHeight: '640px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    rowContainer: {
        padding: '0.5rem 0rem 1rem 0rem',
        borderBottom: (colorTheme) =>  `1px solid  ${colorTheme.grey200}`,
    },
    detailContainer: {
        padding: '1rem',
    },
    closeItem: {
        padding: '12px 0px !important',
        cursor: 'pointer',
    },
    fieldContainer: {
        padding: '1rem 0rem',
    },
    textFieldRoot: {
        '& .MuiInputLabel-root': {
            top: '-7px',
        },
    },
}));

function NewJournalModal({
    open,
    handleClose,
    accountId,
    businessId,
    isEdit = false,
    datasource,
    getJournalEntry,
    setListData,
    selectedData,
    deleteData,
}: NewJournalModalProps) {
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [addnewCategoryOpen, setAddnewCategoryOpen] = useState(false);
    const [categoryFormSubmitted, setCategoryFormSubmitted] = useState(false);
    const [record, setrecord] = useState({
        totalDebit: 0,
        totalCredit: 0,
        emptyRowCount: 0,
    });
    const [initialValues, setinitialValues] = useState({
        ...initialFormValues,
    });
    const [showAlert, setshowAlert] = useState({ show: false, message: '' });
    const { setSelectedData } = useJournalState();
    const [entryRowMemory, setEntryRowMemory] = useState<any>();

    useEffect(() => {
        if (isEdit === true && selectedData) {
            const entryRow = normilizeJournal(selectedData);
            //@ts-ignore
            setinitialValues((prev) => ({
                ...prev,
                entryRow: entryRow,
                date: selectedData?.date,
                description: selectedData?.description,
            }));
        }
    }, [isEdit, setinitialValues, selectedData]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!open) {
            setrecord({ totalDebit: 0, totalCredit: 0, emptyRowCount: 0 });
            setshowAlert({ show: false, message: '' });
        } else {
            if (!datasource.categoryOptions) {
                let categoryOptions = Object.values(datasource.accountRecord);
                categoryOptions.forEach((c: any) => {
                    if (c.parent) {
                        c.parent = categoryOptions.find(
                            (pc: any) => c.parent === pc.id
                        );
                    }
                });
                datasource.categoryOptions = categoryOptions;
            }
        }
    }, [open, datasource]);

    const menuLink = (
        { children, ...other }: any,
        index: number,
        formik: any
    ) => (
        <Paper {...other}>
            <Box borderBottom='1px solid rgba(18, 23, 36, 0.2)'>
                <Button
                    fullWidth
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setEntryRowMemory({ formik, index });
                        setAddnewCategoryOpen(true);
                    }}
                >
                    Create New Category
                </Button>
            </Box>
            {children}
        </Paper>
    );

    // validations
    const validationSchema = Yup.object({
        date: Yup.number().required('Date is required!'),
        description: Yup.string(),
        entryRow: Yup.array().of(
            Yup.object().shape({
                account: Yup.string(),
                debit: Yup.number().positive('Invalid value').nullable(),
                credit: Yup.number().positive('Invalid value').nullable(),
            })
        ),
    });

    const _parseAmount = (amount: number) => {
        return parseFloat((amount + '').replace(',', ''));
    };

    const debit = (journal: any) => {
        var debitValue = journal.reduce(function (sum: any, l: any) {
            if (l.type === 'debit') {
                sum += _parseAmount(l.debit || 0);
            }
            return sum;
        }, 0);
        return parseFloat(debitValue.toFixed(2));
    };

    const credit = (journal: any) => {
        var creditValue = journal.reduce(function (sum: any, l: any) {
            if (l.type === 'credit') {
                sum += _parseAmount(l.credit) || 0;
            }
            return sum;
        }, 0);
        return parseFloat(creditValue.toFixed(2));
    };

    const normilizeJournal = (journal: any) => {
        if (journal) {
            if (journal.debit) {
                const _lines: any[] = [];
                let totalDebit = 0;
                let totalCredit = 0;
                journal.debit.forEach((element: any) => {
                    element.type = 'debit';
                    element.debit = element.amount;
                    element.credit = '';
                    element.open = false;
                    _lines.push(element);
                    totalDebit += _parseAmount(element.amount);
                });
                journal.credit.forEach((element: any) => {
                    element.type = 'credit';
                    element.credit = element.amount;
                    element.debit = '';
                    element.open = false;
                    _lines.push(element);
                    totalCredit += _parseAmount(element.amount);
                });

                _lines.sort((a: any, b: any) => a.order - b.order);

                //new line
                const line = {
                    account: '',
                    debit: '',
                    credit: '',
                    open: false,
                };
                _lines.push(line);
                setrecord((prev) => ({
                    ...prev,
                    totalCredit,
                    totalDebit,
                }));
                return _lines;
            }
        }
    };
 
    const onSubmit = (data: any, formik: any) => {
        data.entryRow = data.entryRow.reduce((sum: any, l: any) => {
            if (l.amount || l.account) {
                sum.push(l);
            }
            return sum;
        }, []);
        if (record.totalDebit !== record.totalCredit) {
            setshowAlert({
                show: true,
                message: 'Please balance debits and credits.',
            });
            formik['values'] = { entryRow: data.entryRow };
            validateLines(formik);
            return;
        }

        if (data.entryRow.length < 2) {
            setshowAlert({
                show: true,
                message: 'You must fill out at least two rows.',
            });
            formik['values'] = { entryRow: data.entryRow };
            validateLines(formik);
            return;
        }

        const journalData: {
            date: number;
            description: string;
            credit: any[];
            debit: any[];
        } = {
            date: data.date,
            description: data.description,
            credit: [],
            debit: [],
        };
        data.entryRow.forEach((e: any, i: number) => {
            if (!e?.account && (e.credit || e.debit)) {
                setshowAlert({
                    show: true,
                    message: 'Please provide the required Category.',
                });
                throw {};
            }
            if (e.credit) {
                journalData.credit.push({
                    account: e.account,
                    amount: e.credit,
                    order: i,
                });
            } else if (e.debit) {
                journalData.debit.push({
                    account: e.account,
                    amount: e.debit,
                    order: i,
                });
            }
        });
        setFormSubmitted(true);
        if (isEdit) {
            //@ts-ignore
            journalData.id = selectedData?.id;
            //@ts-ignore
            journalData.number = selectedData?.number;
            formSubmitErrorHandler(
                patchJournalEntry(journalData, accountId, businessId).then(
                    (res: any) => {
                        setFormSubmitted(false);
                        handleClose(false);
                        getJournalEntry?.(true);
                        setSelectedData(res);
                    }
                ),
                () => {
                    setFormSubmitted(false);
                },
                formik.setFieldError
            );
        } else {
            formSubmitErrorHandler(
                addJournalEntry(journalData, accountId, businessId).then(
                    (res: any) => {
                        setFormSubmitted(false);
                        handleClose(false);
                        getJournalEntry(true);
                    }
                ),
                () => {
                    setFormSubmitted(false);
                },
                formik.setFieldError
            );
        }
    };

    // onsubmit category
    const onCategorySubmit = (data: any, { setFieldError }: any) => {
        setCategoryFormSubmitted(true);
        formSubmitErrorHandler(
            addCategory(data, accountId, businessId).then((res: any) => {
                setCategoryFormSubmitted(false);
                const newData = { [res?.id]: res };
                res.parent = datasource.accountRecord[res.parent];
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: { ...prev?.accountRecord, ...newData },
                    categoryOptions: prev.categoryOptions.concat(res),
                }));
                setAddnewCategoryOpen(false);
                setTransCategory(
                    res,
                    entryRowMemory.index,
                    entryRowMemory.formik
                );
                setEntryRowMemory({});
            }),
            () => {
                setCategoryFormSubmitted(false);
            },
            setFieldError
        );
    };

    // deletes the entry
    const deleteJournalEntry = (formik: any, index: number, remove: any) => {
        remove(index);
        formik.values.entryRow.splice(index, 1);
        formik.setFieldValue('entryRow', formik.values.entryRow);
        setrecord((prev) => ({
            ...prev,
            totalCredit: credit(formik.values.entryRow),
            totalDebit: debit(formik.values.entryRow),
        }));
        validateLines(formik, true);
    };

    const addLine = (formik: any, skip_prefill?: boolean) => {
        const values = formik.values.entryRow;
        const diff = debit(values) - credit(values);
        let line = {
            amount: null,
            account: '',
            debit: '',
            credit: '',
            open: false,
        };

        if (diff > 0 && !skip_prefill) {
            //@ts-ignore
            line.credit = diff;
            line = applyChange(line, line.credit, 'credit');
        } else if (diff < 0 && !skip_prefill) {
            //@ts-ignore
            line.debit = -diff;
            line = applyChange(line, line.debit, 'debit');
        }
        const newValue = [...values, ...[line]];
        formik.setFieldValue('entryRow', newValue);

        setrecord((prev) => ({
            ...prev,
            totalCredit: credit(newValue),
            totalDebit: debit(newValue),
        }));
    };

    const applyChange = function (l: any, amount: any, type: string) {
        if (amount) {
            l.amount = _parseAmount(amount);
            l.type = type;
            l[type !== 'credit' ? 'debit' : 'credit'] = l.amount;
            l[type === 'credit' ? 'debit' : 'credit'] = '';
        } else {
            if (typeof amount === 'undefined') {
                l.amount = null;
                l.debit = null;
                l.credit = null;
                l.type = '';
            }
        }
        return l;
    };

    const handleCategoryClose = () => {
        setAddnewCategoryOpen(false);
    };

    const setTransCategory = (item: any, index: number, formik: any) => {
        if (item?.id) {
            const entryRow = formik.values.entryRow;
            entryRow[index]['account'] = item.id;
            entryRow[index]['open'] = false;
            formik.setFieldValue('entryRow', entryRow);
        }
    };

    const displayCurrentCategoryList = (
        index: number,
        form: any,
        value?: boolean
    ) => {
        const updateData = form.values.entryRow.map((item: any, i: number) => {
            if (i === index) {
                return { ...item, open: value };
            }
            return item;
        });
        form?.setFieldValue('entryRow', updateData);
    };

    const checkLine = (l: any) => {
        if (l.amount && l.type && l.account) {
            return true;
        }
    };
    const validateLines = (formik: any, skip_prefill?: boolean) => {
        const values = formik.values.entryRow;
        if (Array.isArray(values) && !formSubmitted) {
            for (var i in values) {
                if (!checkLine(values[i])) {
                    return;
                }
            }
            addLine(formik, skip_prefill === true);
        }
    };

    const handleAmountChange = (formik: any, index: number, type: string) => {
        if (
            formik.values.entryRow[index][type] === null ||
            (formik.values.entryRow[index][type] === '' &&
                type !== formik.values.entryRow[index].type)
        ) {
            return;
        }
        applyChange(
            formik.values.entryRow[index],
            formik.values.entryRow[index][type],
            type
        );
        formik.setFieldValue('entryRow', formik.values.entryRow);
        setrecord((prev) => ({
            ...prev,
            totalCredit: credit(formik.values.entryRow),
            totalDebit: debit(formik.values.entryRow),
        }));
        validateLines(formik);
    };

    return <>
        <UiDialog
            open={open}
            handleClose={handleClose}
            title={
                isEdit
                    ? `Edit Journal Entry ${selectedData?.number}`
                    : 'Create a New Journal Entry'
            }
            size='sm'
            customRootClass={styles.container}
        >
            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    enableReinitialize={true}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <Box className='top-section'>
                                    <Hidden mdUp>
                                        <Box className={styles.topHeader}>
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                            >
                                                <Box>
                                                    <IconButton
                                                        aria-label='close'
                                                        color='inherit'
                                                        onClick={
                                                            handleClose
                                                        }
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Box>
                                                <Box className='title'>
                                                    {isEdit
                                                        ? `Edit Journal Entry ${selectedData?.number}`
                                                        : 'New Journal Entry'}
                                                </Box>
                                            </Box>
                                            <Box
                                                display='grid'
                                                gridTemplateColumns='repeat(2,1fr)'
                                                gridGap='15px'
                                            >
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    fullWidth
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </Button>
                                                {formSubmitted ? (
                                                    <Box
                                                        className={
                                                            styles.loader
                                                        }
                                                    >
                                                        <Loader />
                                                    </Box>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        fullWidth
                                                        color='primary'
                                                        type='submit'
                                                        disabled={
                                                            !formik.isValid ||
                                                            formSubmitted
                                                        }
                                                        onClick={() => {
                                                            formik.validateForm();
                                                        }}
                                                        data-cy="save-changes-button"
                                                    >
                                                        {isEdit
                                                            ? 'Save Changes'
                                                            : 'Create Entry'}
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Hidden>
                                    <Box className={styles.formContainer}>
                                        {showAlert.show && (
                                            <Alert
                                                className='alert'
                                                severity='warning'
                                                onClose={() => {
                                                    setshowAlert({
                                                        show: false,
                                                        message: '',
                                                    });
                                                }}
                                            >
                                                {showAlert.message}
                                            </Alert>
                                        )}
                                        {isEdit && (
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                                className='delete-section'
                                                justify='flex-end'
                                            >
                                                <Button
                                                    className={
                                                        styles.seconderyActionButtonOutline
                                                    }
                                                    classes={{
                                                        root:
                                                            styles.buttonGap,
                                                    }}
                                                    variant='outlined'
                                                    startIcon={
                                                        <img
                                                            alt={`Delete`}
                                                            src={DeleteIcon}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        if (
                                                            selectedData?.id
                                                        )
                                                            deleteData?.deleteHandleOption(
                                                                selectedData?.id
                                                            );
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </Grid>
                                        )}
                                        <Grid item xs={8} md={6}>
                                            <Box
                                                className={
                                                    styles.fieldContainer
                                                }
                                            >
                                                <UiFormControlSelection
                                                    label='Date'
                                                    type='date'
                                                    fieldName='date'
                                                    dateType='string'
                                                    showFloatingLabel={true}
                                                ></UiFormControlSelection>
                                            </Box>
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid container spacing={1}>
                                                <Grid container item md={5}>
                                                    <Box
                                                        className={
                                                            styles.title
                                                        }
                                                    >
                                                        Category
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    container
                                                    justify='flex-end'
                                                    item
                                                    md={3}
                                                >
                                                    <Box
                                                        className={
                                                            styles.title
                                                        }
                                                    >
                                                        Debit($)
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    justify='flex-end'
                                                    md={3}
                                                >
                                                    <Box
                                                        className={
                                                            styles.title
                                                        }
                                                    >
                                                        Credit($)
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={1}
                                                    md={1}
                                                ></Grid>
                                            </Grid>
                                        </Hidden>
                                        <FieldArray name='entryRow'>
                                            {({
                                                push,
                                                remove,
                                                form,
                                                ...rest
                                            }) => {
                                                return formik.values
                                                    ?.entryRow?.length >
                                                    0 ? (
                                                    <Box
                                                        className={
                                                            styles.rowContainer
                                                        }
                                                    >
                                                        {formik.values.entryRow.map(
                                                            (
                                                                journalitem: any,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            5
                                                                        }
                                                                        key={`row-${index}`}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            item
                                                                            xs={
                                                                                11
                                                                            }
                                                                            md={
                                                                                5
                                                                            }
                                                                        >
                                                                            <Autocomplete
                                                                                fullWidth={
                                                                                    true
                                                                                }
                                                                                open={
                                                                                    journalitem?.open
                                                                                }
                                                                                onClose={() => {
                                                                                    setTimeout(
                                                                                        () =>
                                                                                            displayCurrentCategoryList(
                                                                                                index,
                                                                                                formik,
                                                                                                false
                                                                                            ),
                                                                                        300
                                                                                    );
                                                                                }}
                                                                                disableCloseOnSelect={
                                                                                    true
                                                                                }
                                                                                className={
                                                                                    styles.autocomplete
                                                                                }
                                                                                onOpen={() => {
                                                                                    displayCurrentCategoryList(
                                                                                        index,
                                                                                        formik,
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                onChange={(
                                                                                    event,
                                                                                    item
                                                                                ) => {
                                                                                    setTransCategory(
                                                                                        item,
                                                                                        index,
                                                                                        formik
                                                                                    );
                                                                                    displayCurrentCategoryList(
                                                                                        index,
                                                                                        formik,
                                                                                        false
                                                                                    );
                                                                                    validateLines(
                                                                                        formik
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    journalitem?.account
                                                                                }
                                                                                options={datasource?.categoryOptions.filter(
                                                                                    (
                                                                                        c: any
                                                                                    ) =>
                                                                                        !c.is_hidden &&
                                                                                        c.can_be_journal_entry_account
                                                                                )}
                                                                                getOptionLabel={(
                                                                                    option: any
                                                                                ) => {
                                                                                    if (
                                                                                        typeof option ===
                                                                                        'string'
                                                                                    ) {
                                                                                        const data =
                                                                                            datasource
                                                                                                ?.accountRecord[
                                                                                                option
                                                                                            ];
                                                                                        return data
                                                                                            ? data?.title
                                                                                            : '';
                                                                                    } else {
                                                                                        return option.title;
                                                                                    }
                                                                                }}
                                                                                renderOption={(
                                                                                    option
                                                                                ) => {
                                                                                    let title;
                                                                                    if (
                                                                                        typeof option ===
                                                                                        'string'
                                                                                    ) {
                                                                                        const data =
                                                                                            datasource
                                                                                                ?.accountRecord[
                                                                                                option
                                                                                            ];
                                                                                        title = data
                                                                                            ? data?.title
                                                                                            : '';
                                                                                    } else {
                                                                                        title =
                                                                                            option.title;
                                                                                    }
                                                                                    return (
                                                                                        <Fragment>
                                                                                            <div>
                                                                                                <Typography variant='body1'>
                                                                                                    {
                                                                                                        title
                                                                                                    }
                                                                                                </Typography>

                                                                                                <Typography variant='caption'>
                                                                                                    {
                                                                                                        option
                                                                                                            .parent
                                                                                                            .title
                                                                                                    }
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    );
                                                                                }}
                                                                                renderInput={(
                                                                                    params
                                                                                ) => (
                                                                                    <>
                                                                                        <Hidden
                                                                                            implementation='js'
                                                                                            smDown
                                                                                        >
                                                                                            <TextField
                                                                                                {...params}
                                                                                                label={
                                                                                                    false
                                                                                                }
                                                                                                placeholder='Category'
                                                                                                variant='outlined'
                                                                                            />
                                                                                        </Hidden>
                                                                                        <Hidden
                                                                                            implementation='js'
                                                                                            mdUp
                                                                                        >
                                                                                            <TextField
                                                                                                classes={{
                                                                                                    root:
                                                                                                        styles.textFieldRoot,
                                                                                                }}
                                                                                                {...params}
                                                                                                label='Category'
                                                                                                variant='outlined'
                                                                                            />
                                                                                        </Hidden>
                                                                                    </>
                                                                                )}
                                                                                PaperComponent={(
                                                                                    child: any
                                                                                ) => {
                                                                                    return menuLink(
                                                                                        child,
                                                                                        index,
                                                                                        formik
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Grid>

                                                                        <Hidden
                                                                            implementation='js'
                                                                            mdUp
                                                                        >
                                                                            <Grid
                                                                                className={
                                                                                    styles.closeItem
                                                                                }
                                                                                container
                                                                                item
                                                                                xs={
                                                                                    1
                                                                                }
                                                                                md={
                                                                                    1
                                                                                }
                                                                            >
                                                                                <ClearIcon
                                                                                    onClick={() =>
                                                                                        deleteJournalEntry(
                                                                                            formik,
                                                                                            index,
                                                                                            remove
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Hidden>

                                                                        <Grid
                                                                            container
                                                                            item
                                                                            xs={
                                                                                5
                                                                            }
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Hidden
                                                                                implementation='js'
                                                                                mdUp
                                                                            >
                                                                                <UiFormControlSelection
                                                                                    label='Debit'
                                                                                    type='number'
                                                                                    step='any'
                                                                                    fastField={
                                                                                        false
                                                                                    }
                                                                                    fieldName={`entryRow[${index}].debit`}
                                                                                    onBlur={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            formik,
                                                                                            index,
                                                                                            'debit'
                                                                                        );
                                                                                    }}
                                                                                    showFloatingLabel={
                                                                                        true
                                                                                    }
                                                                                ></UiFormControlSelection>
                                                                            </Hidden>
                                                                            <Hidden
                                                                                implementation='js'
                                                                                smDown
                                                                            >
                                                                                <UiFormControlSelection
                                                                                    type='number'
                                                                                    fastField={
                                                                                        false
                                                                                    }
                                                                                    step='any'
                                                                                    placeholder='$0.00'
                                                                                    label={
                                                                                        ''
                                                                                    }
                                                                                    fieldName={`entryRow[${index}].debit`}
                                                                                    onBlur={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            formik,
                                                                                            index,
                                                                                            'debit'
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Hidden>
                                                                        </Grid>
                                                                        <Grid
                                                                            container
                                                                            item
                                                                            xs={
                                                                                6
                                                                            }
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Hidden
                                                                                implementation='js'
                                                                                mdUp
                                                                            >
                                                                                <UiFormControlSelection
                                                                                    label='Credit'
                                                                                    type='number'
                                                                                    step='any'
                                                                                    fastField={
                                                                                        false
                                                                                    }
                                                                                    fieldName={`entryRow[${index}].credit`}
                                                                                    onBlur={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            formik,
                                                                                            index,
                                                                                            'credit'
                                                                                        );
                                                                                    }}
                                                                                    showFloatingLabel={
                                                                                        true
                                                                                    }
                                                                                ></UiFormControlSelection>
                                                                            </Hidden>
                                                                            <Hidden
                                                                                implementation='js'
                                                                                smDown
                                                                            >
                                                                                <UiFormControlSelection
                                                                                    type='number'
                                                                                    placeholder='$0.00'
                                                                                    step='any'
                                                                                    fastField={
                                                                                        false
                                                                                    }
                                                                                    label={
                                                                                        ''
                                                                                    }
                                                                                    fieldName={`entryRow[${index}].credit`}
                                                                                    onBlur={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            formik,
                                                                                            index,
                                                                                            'credit'
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Hidden>
                                                                        </Grid>
                                                                        <Hidden
                                                                            implementation='js'
                                                                            smDown
                                                                        >
                                                                            <Grid
                                                                                className={
                                                                                    styles.closeItem
                                                                                }
                                                                                container
                                                                                item
                                                                                xs={
                                                                                    1
                                                                                }
                                                                                md={
                                                                                    1
                                                                                }
                                                                            >
                                                                                <ClearIcon
                                                                                    onClick={() =>
                                                                                        deleteJournalEntry(
                                                                                            formik,
                                                                                            index,
                                                                                            remove
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Hidden>
                                                                    </Grid>
                                                                );
                                                            }
                                                        )}
                                                    </Box>
                                                ) : null;
                                            }}
                                        </FieldArray>
                                        <Box
                                            className={
                                                styles.detailContainer
                                            }
                                        >
                                            <Grid container spacing={1}>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    md={5}
                                                >
                                                    {' '}
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    md={3}
                                                    direction='column'
                                                    alignItems='flex-end'
                                                    justify='center'
                                                >
                                                    <InputLabel
                                                        className={
                                                            styles.title
                                                        }
                                                    >
                                                        Total Debit
                                                    </InputLabel>
                                                    $
                                                    {record.totalDebit.toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={6}
                                                    md={3}
                                                    direction='column'
                                                    alignItems='flex-end'
                                                    justify='center'
                                                >
                                                    <InputLabel
                                                        className={
                                                            styles.title
                                                        }
                                                    >
                                                        Total Credit
                                                    </InputLabel>
                                                    $
                                                    {record.totalCredit.toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    md={1}
                                                >
                                                    {' '}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <UiFormControlSelection
                                            label='Description (Optional)'
                                            type='textarea'
                                            fieldName='description'
                                            placeholder='Write details of the journal entry here.'
                                            showFloatingLabel={true}
                                        ></UiFormControlSelection>
                                    </Box>
                                    <Hidden smDown>
                                        <Box className={styles.footer}>
                                            <Button
                                                variant='outlined'
                                                color='secondary'
                                                fullWidth
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            {formSubmitted ? (
                                                <Box
                                                    className={
                                                        styles.loader
                                                    }
                                                >
                                                    <Loader />
                                                </Box>
                                            ) : (
                                                <Button
                                                    variant='contained'
                                                    fullWidth
                                                    color='primary'
                                                    type='submit'
                                                    disabled={
                                                        !formik.isValid ||
                                                        formSubmitted
                                                    }
                                                    onClick={() => {
                                                        formik.validateForm();
                                                    }}
                                                >
                                                    {isEdit
                                                        ? 'Save Changes'
                                                        : 'Create Entry'}
                                                </Button>
                                            )}
                                        </Box>
                                    </Hidden>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </UiDialog>
        <UiDialog
            open={addnewCategoryOpen}
            handleClose={handleCategoryClose}
            title='Add New Category'
            size='sm'
        >
            <CreateCategory
                categoryFormSubmitted={categoryFormSubmitted}
                accountParentList={datasource?.categoryOptions?.filter(
                    (c: any) => c.can_have_children
                )}
                onCategorySubmit={onCategorySubmit}
                initialCategoryValues={initialFormValues}
                handleCategoryClose={handleCategoryClose}
            />
        </UiDialog>
    </>;
}
const mapStateToProps = ({ config, appData }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
});
export default connect(mapStateToProps)(NewJournalModal);