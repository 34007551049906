import { Box, Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { AppData, ApplicationStore, CompanyStepInfo } from '../../../models';
import {
    getCompanyIndustry,
    updateCompanyIndustry,
} from '../../../services/apiService';
import { formSubmitErrorHandler } from '../../../services/formService';
import Loader from '../../common/Loader';

import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiText from '../../common/ui/UiText';
import FormHeaderText from '../../routing/Payroll/FormHeaderText';
import NextActionButton from './NextActionButton';

interface BusinessIndustryFormProp {
    appData: AppData;
    companyStepInfo: CompanyStepInfo;
    refreshSteps: () => void;
    industries: any;
    parentLoading: (loading: boolean) => void;
}

const BusinessIndustryForm = forwardRef(
    (
        {
            appData,
            companyStepInfo,
            refreshSteps,
            industries,
            parentLoading
        }: BusinessIndustryFormProp,
        ref
    ) => {
        const [submittingForm, setSubmittingForm] = useState(false);
        const [initialValues, setInitialValues] = useState({
            title: '',
        });
        const payrollFrequencyCreated =
            companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status
                .payroll_frequency_addition;
        const [loading, setLoading] = useState(payrollFrequencyCreated);
        const formRef = useRef<any>();
        const [skipNext, setSkipNext] = useState(false);

        useEffect(() => {
            getCompanyIndustry(appData.current_business_id).then((data) => {
                setInitialValues(data);
                setLoading(false);
            });
        }, []);

        useEffect(() => {
            parentLoading(submittingForm);
            return () => {
                parentLoading(false);
            };
        }, [submittingForm]);

        useImperativeHandle(
            ref,
            () => ({
                isFromDirty() {
                    return formRef.current?.dirty;
                },

                submit() {
                    if (formRef.current.isValid) {
                        setSkipNext(true);
                    }

                    return formRef.current.submitForm;
                },
            }),
            [formRef]
        );

        const onSubmit = (data: any, formHelpers: FormikHelpers<any>) => {
            setSubmittingForm(true);
            formSubmitErrorHandler(
                updateCompanyIndustry(appData.current_business_id, data).then(
                    (val) => {
                        if (skipNext) {
                            setSkipNext(false);
                            setInitialValues(val);
                            formHelpers.resetForm(val);
                        } else {
                            refreshSteps();
                        }

                        setSubmittingForm(false);
                    }
                ),
                () => {
                    setSubmittingForm(false);
                    setSkipNext(false);
                },
                formHelpers.setFieldError
            );
        };
        const validationSchema = Yup.object({
            title: Yup.string().required('Business Type is required'),
        });

        return (
            <Grid
                style={{
                    height: 'calc(100% - 132px)',
                    overflowX: 'hidden',
                    overflowY:
                        loading || companyStepInfo.loadingStepInfo
                            ? 'hidden'
                            : 'auto',
                }}
            >
                <FormHeaderText heading='Business Industry' />
                <UiText>
                    Industry information is required to ensure compliance with
                    all regulations unique to your business.
                </UiText>

                {loading || companyStepInfo.loadingStepInfo ? (
                    <Loader />
                ) : (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                        innerRef={formRef}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <Box my={2} mt={4}>
                                        <UiFormControlSelection
                                            type='select'
                                            label={'Industry (Required)'}
                                            showFloatingLabel={true}
                                            fieldName='title'
                                            optionsData={industries}
                                            cypressId={'business-industries'}
                                        />
                                    </Box>
                                    <Box my={2} mt={2}>
                                        <NextActionButton
                                            loading={submittingForm}
                                            submitAction={formik.submitForm}
                                        />
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Grid>
        );
    }
);
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
    industries: state.config.apiConfig.payroll_org.company.industry_titles,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
    BusinessIndustryForm
);
