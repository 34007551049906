import { makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../styles/models/Colors.interface'
export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    formStyle: {
        paddingRight: theme.spacing(4),
    },
    fieldMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    contentMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    fieldSpacing: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    headerStyle: {
        marginTop: theme.spacing(4),
    },
    containerStyle: {
        marginTop: '1rem',
        maxHeight: 'calc(100svh - 18rem)',
        overflowY: 'auto',
        marginBottom: '3rem',
        paddingRight: '1rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem'
        }
    },
    nextButtonStyle: {
        marginBottom: theme.spacing(4),
    },
    sectionHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    nextPrevContainer: {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        bottom: 0,
        left: 0,
        backgroundColor: colorTheme => colorTheme.primaryWhite,
        width: '100%',
        padding: '1rem',
        borderTop: colorTheme => `1px solid ${colorTheme.grey200}`,
        boxSizing: 'border-box',
        '& > button:first-child': {
            marginBottom: '0.5rem',
        },
    },
    nxtBtn: {
        width: '100%',
        '& span': {
            fontWeight: 600,
        },
    },
}))
