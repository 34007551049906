import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, List, makeStyles, Typography } from '@material-ui/core'

import { Invoice } from '../../../models'
import Loader from '../../common/Loader'
import InvoiceItem from './InvoiceItem'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listLoader: {
        paddingBottom: '16px',
        paddingTop: '16px',
    },
    loaderStyle: {
        height: '100%',
    },
}))

const InvoiceList = ({
    loading,
    loadMore,
    data,
    hasMore,
    selectedInvoice,
    setSelectedInvoice,
    listHeight
}: {
    loading: boolean
    loadMore: () => void
    data: Invoice[]
    hasMore: boolean
    selectedInvoice: Invoice | undefined
    setSelectedInvoice: React.Dispatch<
        React.SetStateAction<Invoice | undefined>
    >
    listHeight: any
}) => {
    const classes = useStyles()
    return (
        <List className={classes.root} id="scrollableDiv" style={{
            height: listHeight,
            overflow: 'auto',
        }}>
            {loading ? (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.loaderStyle}
                >
                    <Loader />
                </Grid>
            ) : (
                <InfiniteScroll
                    dataLength={data?.length}
                    next={() => loadMore()}
                    hasMore={hasMore}
                    scrollableTarget={'scrollableDiv'}
                    loader={
                        <Grid
                            key={'loader'}
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            className={classes.listLoader}
                        >
                            <Loader />
                        </Grid>
                    }
                >
                    {data.length > 0 ? (
                        data.map((invoice: Invoice, index: number) => (
                            <InvoiceItem
                                key={invoice?.id}
                                invoiceId={invoice?.id}
                                invoice={invoice}
                                selectedInvoice={selectedInvoice}
                                setSelectedInvoice={setSelectedInvoice}
                                lastItem={((data.length - 1) === index)}
                            />
                        ))
                    ) : (
                        <Grid container justify="center">
                            <Typography variant="subtitle1">
                                No Invoices were found
                            </Typography>
                        </Grid>
                    )}
                </InfiniteScroll>
            )}
        </List>
    )
}

export default InvoiceList
