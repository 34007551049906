import React, { Fragment, useState, useRef } from 'react';
import {
    Hidden,
    makeStyles,
    Theme,
    IconButton,
    MenuList,
    MenuItem,
    Grow,
    Paper,
    Popper,
    ClickAwayListener,
    Typography,
    Divider,
    Box,
    Button,
    ButtonGroup,
} from '@material-ui/core'
import { commonStyles, mergeStyle } from '../../styles/commonStyles'
import {
    ArrowDropDown,
    ArrowDropUp,
    MoreVert,
    ArrowBack,
} from '@material-ui/icons/'

/**
 * Usecase - Showing Header in Mobile Devices
 */
const useClass = makeStyles((theme: Theme) => ({
    mobileTitle: {
        display: 'flex',
        justifyContent: 'center',
    },
    divider: {
        width: '100%',
        marginBottom: '1rem',
    },
    marginRight: {
        marginRight: '16px',
    },
}))

type mobileHeaderProps = {
    children?: React.ReactNode
    title?: string | React.ReactNode
    showBottomDivider?: boolean
    handleBackAction?: any
    menuItemActions?: any
    handleItemClickType?: any
    leftSectionItems?: any
    threeDotMenu?: boolean
    buttonMenuText?: string
}

const ComponentMobileHeader = (props: mobileHeaderProps) => {
    const classes = useClass()
    const styles = commonStyles()
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<any>()

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const {
        children,
        title,
        showBottomDivider,
        handleBackAction,
        menuItemActions,
        handleItemClickType,
        leftSectionItems,
        threeDotMenu = true,
        buttonMenuText,
    } = props
    return (
        <Hidden mdUp>
            <Fragment>
                {children ? (
                    children
                ) : (
                    <div
                        className={mergeStyle(
                            styles.flex,
                            styles.spaceBetween,
                            styles.fullWidth
                        )}
                    >
                        {leftSectionItems ? (
                            leftSectionItems
                        ) : (
                            <div className={mergeStyle(styles.flex)}>
                                <div>
                                    {handleBackAction && (
                                        <IconButton onClick={handleBackAction}>
                                            <ArrowBack />
                                        </IconButton>
                                    )}
                                </div>
                                <div
                                    className={mergeStyle(
                                        styles.flex,
                                        styles.alignCenter
                                    )}
                                    style={
                                        handleBackAction
                                            ? {}
                                            : { padding: '4px' }
                                    }
                                >
                                    <Typography variant="h6">
                                        {title}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        {menuItemActions && (
                            <div>
                                {threeDotMenu ? (
                                    <IconButton
                                        ref={anchorRef}
                                        onClick={() => {
                                            setOpen(!open)
                                        }}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                ) : (
                                    <ButtonGroup
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        aria-label="split button"
                                        ref={anchorRef}
                                        className={classes.marginRight}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setOpen(!open)
                                            }}
                                        >
                                            {buttonMenuText}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setOpen(!open)
                                            }}
                                        >
                                            {open ? (
                                                <ArrowDropUp />
                                            ) : (
                                                <ArrowDropDown />
                                            )}
                                        </Button>
                                    </ButtonGroup>
                                )}
                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    placement={'top-end'}
                                    transition
                                    disablePortal
                                    style={{ zIndex: 1 }}
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom'
                                                        ? 'end top'
                                                        : 'end bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener
                                                    onClickAway={handleClose}
                                                >
                                                    <MenuList
                                                        autoFocusItem={open}
                                                    >
                                                        {menuItemActions.map(
                                                            (
                                                                option: any,
                                                                index: number
                                                            ) => (
                                                                <Fragment
                                                                    key={index}
                                                                >
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) => {
                                                                            handleItemClickType(
                                                                                option.action
                                                                            )
                                                                        }}
                                                                        disabled={option?.disabled}
                                                                        className={
                                                                            ''
                                                                        }
                                                                    >
                                                                        {option?.iconType ===
                                                                        'image' ? (
                                                                            <img
                                                                                alt={
                                                                                    option?.iconAlt
                                                                                }
                                                                                src={
                                                                                    option.icon
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            option?.icon
                                                                        )}{' '}
                                                                        <Box
                                                                            mx={
                                                                                2
                                                                            }
                                                                            style={
                                                                                option?.style
                                                                            }
                                                                        >
                                                                            {
                                                                                option.title
                                                                            }{' '}
                                                                        </Box>
                                                                    </MenuItem>
                                                                    {option.showDivider && (
                                                                        <Divider />
                                                                    )}
                                                                </Fragment>
                                                            )
                                                        )}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </div>
                )}
                {showBottomDivider && (
                    <div className={classes.divider}>
                        <Divider variant="fullWidth"></Divider>
                    </div>
                )}
            </Fragment>
        </Hidden>
    )
}

export default ComponentMobileHeader
