import { Button, Grid, IconButton, Theme, Tooltip, Typography } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ApplicationStore, Config, Product, UploadedFile } from '../../../../models';
import UiDialog from '../../../common/ui/UiDialog'
import UiFormField from '../../../common/ui/UiFormField'
import * as Yup from "yup";
import { makeStyles } from '@material-ui/styles'
import { Add, Close, Warning } from '@material-ui/icons'
import { annualReportDocument, getEntityManagementProducts } from '../../../../services/apiService';
import { documentFilter } from '../../../../services/uploadService'
import Loader from '../../../common/Loader'
import SubmitAnnualReportPopupIntro from './SubmitAnnualReportPopupIntro'
import ProductPurchase from '../../../sales/ProductPurchase'
import { hasPermission } from '../../../../services/aclService'
import { showAlert } from '../../../../store/actions/feedback'
import { formSubmitErrorHandler } from '../../../../services/formService';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';

interface SubmitAnnualReportPopupProps {
    open: boolean;
    handleClose: (result?: any) => void;
    config: Config,
    accountId: string;
    businessId: string;
    dueDate: number;
    businessAccount: any;
}

const initialFormValues = {
    account: "",
    year: '2021',
    business_information: {
        business_name: "",
        ein: "",
        dba: "",
        date_incorporated_or_organized: null,
        date_business_began: null,
        fiscal_year_end_date: null,
        business_type: "",
        authorized_share_count: null,
        issued_share_count: null,
        value_per_share: null,
        state_of_incorporation: "",
        prior_year_return_filing_payment: null,
        nature_of_business_conducted: "",
    },
    client_information: {
        email: "",
        primary_phone: "",
        secondary_phone: "",
        address: {
            street: "",
            street_line_2: "",
            city: "",
            state: "",
            zip_code: "",
        }
    },
    shareholders: [{
        name: {
            first_name: "",
            last_name: "",
        },
        address: {
            street: "",
            street_line_2: "",
            city: "",
            state: "",
            zip_code: ""
        },
        ssn: "",
        percentage_of_ownership: 0,
        capital_contributed: 0
    }],
    officer_information: {
        president: {
            name: {
                first_name: "",
                last_name: ""
            },
            address: {
                street: "",
                street_line_2: "",
                city: "",
                state: "",
                zip_code: ""
            }
        },
        vice_president: {
            name: {
                first_name: "",
                last_name: ""
            },
            address: {
                street: "",
                street_line_2: "",
                city: "",
                state: "",
                zip_code: ""
            }
        },
        secretary: {
            name: {
                first_name: "",
                last_name: ""
            },
            address: {
                street: "",
                street_line_2: "",
                city: "",
                state: "",
                zip_code: ""
            }
        },
        treasurer: {
            name: {
                first_name: "",
                last_name: ""
            },
            address: {
                street: "",
                street_line_2: "",
                city: "",
                state: "",
                zip_code: ""
            }
        },
        registered_agent: {
            name: {
                first_name: "",
                last_name: ""
            },
            address: {
                street: "",
                street_line_2: "",
                city: "",
                state: "",
                zip_code: ""
            }
        },
        director: [{
            name: {
                first_name: "",
                last_name: ""
            },
            address: {
                street: "",
                street_line_2: "",
                city: "",
                state: "",
                zip_code: ""
            }
        }]
    },
    submission_info: {
        contact_me_to_discuss_tax_preparation_and_filing_return: false,
        need_help_about_quarterly_estimated_taxes_or_state_tax_filings: false,
        agree_with_terms_of_service: false
    }
};

function SubmitAnnualReportPopup({ config, open, handleClose, accountId, businessId, dueDate, businessAccount }: SubmitAnnualReportPopupProps) {

    const { allowed_extensions, max_file_size } = config.apiConfig.file_center;
    const { colorTheme } = useThemeContext()
    const styles = makeStyles((theme: Theme) => ({
        marginVertical: {
            marginTop: 20,
            marginBottom: 20
        },
        marginBottom: {
            marginBottom: 20
        },
        reviewOrderButton: {
            '&:not(:disabled)': {
                backgroundColor: colorTheme.orange300,
                color: colorTheme.primaryWhite,
            }
        },
        filesContainer: {
            border: `1px dashed ${colorTheme.grey200}`,
            borderRadius: '8px',
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 10
        },
        fileIcon: {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    }))();

    const [initialValues, setInitialValues] = useState<any>(initialFormValues);
    const validationSchema = Yup.object({
        account: Yup.string().nullable(),
        year: Yup.string().required('Annual Year is required'),
        business_information: Yup.object({
            business_name: Yup.string().required('Business Name is required'),
            ein: Yup.string().required('EIN is required'),
            dba: Yup.string().nullable(),
            date_incorporated_or_organized: Yup.number().nullable(),
            date_business_began: Yup.number().required('Date Business Began is required').typeError(''),
            fiscal_year_end_date: Yup.number().required('Fiscal Year End Date is required').typeError(''),
            business_type: Yup.string().required('Business Type is required'),
            authorized_share_count: Yup.number().nullable(),
            issued_share_count: Yup.number().nullable(),
            value_per_share: Yup.number().nullable(),
            state_of_incorporation: Yup.string().required('State of Incorporation is required'),
            prior_year_return_filing_payment: Yup.number().nullable(),
            nature_of_business_conducted: Yup.string().nullable()
        }),
        client_information: Yup.object({
            email: Yup.string().required('Email is required'),
            primary_phone: Yup.string().required('Primary phone is required'),
            secondary_phone: Yup.string().nullable(),
            address: Yup.object({
                street: Yup.string().required('This is required'),
                street_line_2: Yup.string(),
                city: Yup.string().required('City is required'),
                state: Yup.string().required('State is required'),
                zip_code: Yup.string().required('Zip code is required')
            })
        }),
        shareholders: Yup.array().of(Yup.object({
            name: Yup.object({
                first_name: Yup.string().nullable(),
                last_name: Yup.string().nullable(),
            }),
            address: Yup.object({
                street: Yup.string().nullable(),
                city: Yup.string().nullable(),
                state: Yup.string().nullable(),
                zip_code: Yup.string().nullable()
            }),
            ssn: Yup.string().nullable(),
            percentage_of_ownership: Yup.number().nullable(),
            capital_contributed: Yup.number().nullable()
        })),
        officer_information: Yup.object({
            president: Yup.object({
                name: Yup.object({
                    first_name: Yup.string().nullable(),
                    last_name: Yup.string().nullable()
                }),
                address: Yup.object({
                    street: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    state: Yup.string().nullable(),
                    zip_code: Yup.string().nullable()
                })
            }),
            vice_president: Yup.object({
                name: Yup.object({
                    first_name: Yup.string().nullable(),
                    last_name: Yup.string().nullable()
                }),
                address: Yup.object({
                    street: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    state: Yup.string().nullable(),
                    zip_code: Yup.string().nullable()
                })
            }),
            secretary: Yup.object({
                name: Yup.object({
                    first_name: Yup.string().nullable(),
                    last_name: Yup.string().nullable()
                }),
                address: Yup.object({
                    street: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    state: Yup.string().nullable(),
                    zip_code: Yup.string().nullable()
                })
            }),
            treasurer: Yup.object({
                name: Yup.object({
                    first_name: Yup.string().nullable(),
                    last_name: Yup.string().nullable()
                }),
                address: Yup.object({
                    street: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    state: Yup.string().nullable(),
                    zip_code: Yup.string().nullable()
                })
            }),
            registered_agent: Yup.object({
                name: Yup.object({
                    first_name: Yup.string().nullable(),
                    last_name: Yup.string().nullable()
                }),
                address: Yup.object({
                    street: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    state: Yup.string().nullable(),
                    zip_code: Yup.string().nullable()
                })
            }),
            director: Yup.array().of(Yup.object({
                name: Yup.object({
                    first_name: Yup.string().nullable(),
                    last_name: Yup.string().nullable()
                }),
                address: Yup.object({
                    street: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    state: Yup.string().nullable(),
                    zip_code: Yup.string().nullable()
                })
            }))
        }),
        submission_info: Yup.object({
            contact_me_to_discuss_tax_preparation_and_filing_return: Yup.boolean().nullable(),
            need_help_about_quarterly_estimated_taxes_or_state_tax_filings: Yup.boolean().nullable(),
            agree_with_terms_of_service: Yup.boolean().oneOf([true]).required('This is required')
        })
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [introCompleted, setIntroCompleted] = useState(false);
    const [sectionsCompleted, setSectionsCompleted] = useState<string[]>([]);
    const [product, setProduct] = useState<Product>();
    const [selectedFiles, setSelectedFiles] = useState<{
        file?: File;
        uploaded: boolean;
        uploading: boolean;
        error: any;
        fileData: {
            filename: string;
            id: string;
            mime_type: string;
            deleted: boolean;
            size: number;
            year: string;
        }
    }[]>([]);
    const utmContent = 'Entity+Dashboard+Annual+Report';
    const handleAddSection = (sectionName: string) => {
        setSectionsCompleted(sectionsCompleted.concat(sectionName));
    }

    const checkIfFieldsValidInSection = (sectionName: 'business_information' | 'client_information', formik: any) => {
        const sectionFields = {
            "business_information": {
                business_name: "",
                ein: "",
                dba: "",
                date_incorporated_or_organized: "",
                date_business_began: "",
                fiscal_year_end_date: "",
                business_type: "",
                authorized_share_count: "",
                issued_share_count: "",
                value_per_share: "",
                state_of_incorporation: "",
                prior_year_return_filing_payment: "",
                nature_of_business_conducted: ""
            },
            "client_information": {
                "email": null,
                "primary_phone": null,
                "secondary_phone": null,
                "address": {
                    "street": null,
                    "street_line_2": null,
                    "city": null,
                    "state": null,
                    "zip_code": null
                }
            }
        }
        let formInvalid = false;
        const currentSection = sectionFields[sectionName];
        for (const key in currentSection) {
            if (!!formik.getFieldMeta(`${sectionName}.${key}`).error) {
                formInvalid = true;
            }
        }
        return formInvalid;
    }

    const handleRemoveOwner = (index: number) => {
        initialValues.shareholders.splice(index, 1);
        const newInitialValues = {
            ...initialValues,
            shareholders: [
                ...initialValues.shareholders
            ]
        }

        setInitialValues(newInitialValues);
    };

    const handleAddOwner = (formik: any) => {
        const newBusinessOwner = {
            name: {
                first_name: '',
                last_name: ''
            },
            address: {
                street: '',
                city: '',
                state: '',
                zip_code: ''
            },
            ssn: '',
            percentage_of_ownership: null,
            captialContributed: null
        };

        const newInitialValues = {
            ...formik.values,
            shareholders: [
                ...formik.values.shareholders,
                newBusinessOwner
            ]
        }

        setInitialValues(newInitialValues);
    }

    const handleRemoveDirector = (index: number) => {
        initialValues.officer_information.director.splice(index, 1);
        const newInitialValues = {
            ...initialValues,
            officers: {
                ...initialValues.officers,
                director: [
                    ...initialValues.officer_information.director
                ]
            }
        }

        setInitialValues(newInitialValues);
    };

    const handleAddDirector = (formik: any) => {
        const newDirector = {
            name: {
                first_name: '',
                last_name: ''
            },
            address: {
                street: '',
                city: '',
                state: '',
                zip_code: ''
            }
        };

        const newInitialValues = {
            ...formik.values,
            officer_information: {
                ...formik.values.officer_information,
                director: [
                    ...formik.values.officer_information.director,
                    newDirector
                ]
            }
        }

        setInitialValues(newInitialValues);
    }

    const handleRemoveFile = (index: number) => {
        const fileToRemove = selectedFiles[index];
        if (fileToRemove.fileData.id) {
            selectedFiles.splice(index, 1, { ...selectedFiles[index], uploading: true, uploaded: false });
            setSelectedFiles([...selectedFiles]);
            annualReportDocument.unlinkFile(accountId, fileToRemove.fileData.id, fileToRemove.fileData.year).then(res => {
                selectedFiles.splice(index, 1);
                setSelectedFiles([...selectedFiles]);
            }).catch(err => { });
        } else {
            selectedFiles.splice(index, 1);
            setSelectedFiles([...selectedFiles]);
        }
    }

    const handleSelectedFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files || [];
        let selectedFilesTmp = [...selectedFiles]; // using a local variable because state change won't reflect here after API response
        Array.from(files).forEach((file) => {
            let check = documentFilter(file as File);
            if (check === true) {
                const index = selectedFiles.length;
                selectedFilesTmp = selectedFilesTmp.concat({
                    file,
                    uploaded: false,
                    uploading: true,
                    error: '',
                    fileData: {
                        filename: file.name,
                        id: '',
                        mime_type: file.type,
                        size: file.size,
                        deleted: false,
                        year: `${new Date().getFullYear()}`
                    }
                });
                setSelectedFiles([...selectedFilesTmp]);
                annualReportDocument.upload<UploadedFile[]>(accountId, file, `${new Date().getFullYear()}`)
                    .then(res => {
                        let uploadedFile = selectedFilesTmp[index];
                        const { filename, deleted, id, mime_type, size, year } = res[0];
                        uploadedFile = {
                            uploaded: true,
                            uploading: false,
                            error: '',
                            fileData: { filename, deleted, id, mime_type, size, year }
                        }
                        selectedFilesTmp.splice(index, 1, uploadedFile)
                        setSelectedFiles([...selectedFilesTmp]);
                    })
                    .catch(err => {
                        let uploadedFile = selectedFilesTmp[index];
                        uploadedFile.uploading = false;
                        uploadedFile.error = err.statusText;
                        selectedFilesTmp.splice(index, 1, uploadedFile)
                        setSelectedFiles([...selectedFilesTmp]);
                    })
            } else {
                selectedFilesTmp = selectedFilesTmp.concat({
                    file,
                    uploaded: false,
                    uploading: false,
                    error: check,
                    fileData: {
                        filename: file.name,
                        id: '',
                        mime_type: file.type,
                        size: file.size,
                        deleted: false,
                        year: ''
                    }
                });
                setSelectedFiles([...selectedFilesTmp]);
            }
        });
        event.target.value = "";
    }

    const getIconType = (mime: string) => {
        switch (mime) {
            case 'image/jpeg':
                return 'Image-Attachment';
            case 'image/png':
                return 'Image-Attachment';
            case 'application/pdf':
                return 'PDF';
            default:
                return 'Generic';
        }
    };

    const onSubmit = (data: any, actions: FormikHelpers<any>) => {
        setFormSubmitted(true);
        formSubmitErrorHandler(
            annualReportDocument
                .saveData(accountId, businessId, data)
                .then((res: any) => {
                    if (res.id) {
                        if (res.submitted_at) {
                            showAlert({
                                alertText:
                                    'Your information has been successfully submitted. Your accountant will be in touch soon.',
                                alertType: 'info',
                            });
                            handleClose(res);
                        }
                    }
                }),
            () => {
                setFormSubmitted(false);
            },
            actions.setFieldError
        );
    };

    useEffect(() => {
        annualReportDocument.getUploadedFiles<{ items: UploadedFile[] }>(accountId).then(res => {
            setSelectedFiles(res.items
                .filter(file => !file.deleted)
                .map(({ filename, deleted, id, mime_type, size, upload_date }) => ({
                    uploaded: true,
                    uploading: false,
                    error: '',
                    fileData: { filename, deleted, id, mime_type, size, year: `${new Date(upload_date * 1000).getFullYear()}` }
                })))
        }).catch(err => { })
        getEntityManagementProducts(accountId, businessId).then(res => {
            setProduct(res?.required?.find(product => product.code === 'ANNUAL_REPORT') || undefined);
        })
    }, [accountId, businessId])

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title="Submit Annual Report"
            size="sm"
            onExit={() => {
                setSectionsCompleted([]);
                setInitialValues(initialFormValues);
                setIntroCompleted(false);
            }}
        >
            {
                introCompleted
                    ? (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                            validateOnMount={true}
                        >
                            {
                                formik => {
                                    return (
                                        <Form>
                                            <UiFormField
                                                type="select"
                                                fieldSize={3}
                                                label="What year is this annual report for? (Required)"
                                                placeholder="Select year"
                                                fieldName="year"
                                                optionsData={config.apiConfig.entity_management.annual_report_years}
                                                optionKey=""
                                                optionValue=""
                                            />
                                            <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                Business Information
                                            </Typography>
                                            <UiFormField
                                                type="text"
                                                placeholder="Business Name (Required)"
                                                label=""
                                                labelSize={false}
                                                fieldName={"business_information.business_name"}
                                            />
                                            <UiFormField
                                                type="mask"
                                                placeholder="XX-XXXXXXX"
                                                label="Employer Identification Number (EIN) (Required)"
                                                fieldName={"business_information.ein"}
                                                mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                minLength={9}
                                            />
                                            <UiFormField
                                                type="text"
                                                placeholder="DBA or Trade Name (Optional)"
                                                label=""
                                                labelSize={false}
                                                fieldName={"business_information.dba"}
                                            />
                                            <UiFormField
                                                placeholder="Select Date"
                                                label={'When did your business begin? (Required)'}
                                                fieldName={"business_information.date_business_began"}
                                                type="date"
                                            />
                                            <UiFormField
                                                placeholder="Select Date"
                                                label={'When is the end of your fiscal year? (Required)'}
                                                fieldName={"business_information.fiscal_year_end_date"}
                                                type="date"
                                            />
                                            <UiFormField
                                                type="select"
                                                placeholder="Select a business type"
                                                label="What is the type of your business? (Required)"
                                                fieldName={"business_information.business_type"}
                                                optionsData={config.apiConfig.business.types}
                                                optionKey="title"
                                                optionValue="abbr"
                                            />
                                            {
                                                ['C', 'S'].includes(formik.values.business_information.business_type) && (
                                                    <>
                                                        <UiFormField
                                                            type="number"
                                                            max={100}
                                                            min={0}
                                                            label="How many shares were authorized? (Optional)"
                                                            placeholder="Number of authorized shares"
                                                            fieldName={"business_information.authorized_share_count"}
                                                        />
                                                        <UiFormField
                                                            type="number"
                                                            max={100}
                                                            min={0}
                                                            label="How many shares were issued? (Optional)"
                                                            placeholder="Number of issued shares"
                                                            fieldName={"business_information.issued_share_count"}
                                                        />
                                                        <UiFormField
                                                            type="text"
                                                            label="What is the value per share? (Optional)"
                                                            placeholder="$ 0.00"
                                                            fieldName={"business_information.value_per_share"}
                                                        />
                                                    </>
                                                )
                                            }
                                            <UiFormField
                                                type="select"
                                                placeholder="Select State"
                                                label="Where was your business incorporated? (Required)"
                                                fieldName={"business_information.state_of_incorporation"}
                                                optionsData={config.apiConfig.generic.states}
                                                optionKey="name"
                                                optionValue="abbr"
                                            />
                                            <UiFormField
                                                type="number"
                                                max={100}
                                                min={0}
                                                label="How much did you pay for your prior return? (Optional)"
                                                placeholder="$ 0.00"
                                                fieldName={"business_information.prior_year_return_filing_payment"}
                                            />
                                            <UiFormField
                                                type="text"
                                                label="What is the nature of your conducted business? (Optional)"
                                                placeholder="Write your answer"
                                                fieldName={"business_information.nature_of_business_conducted"}
                                            />
                                            {
                                                !sectionsCompleted.includes('business_information') && <Button
                                                    variant="contained"
                                                    type="button"
                                                    color="primary"
                                                    disabled={checkIfFieldsValidInSection('business_information', formik)}
                                                    className={styles.marginBottom}
                                                    onClick={() => handleAddSection('business_information')}
                                                >
                                                    Next
                                                </Button>
                                            }

                                            {
                                                /**
                                                 * Client Information Section
                                                 */
                                                sectionsCompleted.includes('business_information') && (
                                                    <Fragment>
                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Client Information (Required)
                                                        </Typography>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Email"
                                                            label=""
                                                            labelSize={false}
                                                            fieldName="client_information.email"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="mask"
                                                                    placeholder="Primary Phone"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="client_information.primary_phone"
                                                                    mask={['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                                    minLength={10}
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="mask"
                                                                    placeholder="Secondary Phone (Optional)"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="client_information.secondary_phone"
                                                                    mask={['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                                    minLength={10}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Street Address"
                                                            label="Address"
                                                            fieldName="client_information.address.street"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={5}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="City"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="client_information.address.city"
                                                                />
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <UiFormField
                                                                    type="select"
                                                                    placeholder="State"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="client_information.address.state"
                                                                    optionsData={config.apiConfig.generic.states}
                                                                    optionKey="name"
                                                                    optionValue="abbr"
                                                                />
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="client_information.address.zip_code"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            !sectionsCompleted.includes('client_information') && <Button
                                                                variant="contained"
                                                                type="button"
                                                                color="primary"
                                                                disabled={checkIfFieldsValidInSection('client_information', formik)}
                                                                className={styles.marginBottom}
                                                                onClick={() => handleAddSection('client_information')}
                                                            >
                                                                Next
                                                            </Button>
                                                        }
                                                    </Fragment>
                                                )
                                            }

                                            {
                                                /**
                                                 * Shareholder Information Section
                                                 */
                                                sectionsCompleted.includes('client_information') && (
                                                    <Fragment>
                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Shareholder Information
                                                        <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        {
                                                            formik.values.shareholders.map((shareholder: any, index: number) => (
                                                                <Fragment key={index}>
                                                                    {
                                                                        index !== 0 ? <Typography
                                                                            variant="h6"
                                                                            gutterBottom
                                                                            className={`${styles.marginVertical} d-flex align-items-center justify-content-between`}
                                                                        >
                                                                            Shareholder #{index + 1}
                                                                            {
                                                                                initialValues.shareholders.length > 1
                                                                                    ? <IconButton size="small" onClick={() => handleRemoveOwner(index)}>
                                                                                        <Close />
                                                                                    </IconButton>
                                                                                    : ''
                                                                            }
                                                                        </Typography> : ''
                                                                    }
                                                                    <Grid container spacing={1}>
                                                                        <Grid item md={6}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="First Name"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`shareholders[${index}].name.first_name`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={6}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="Last Name"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`shareholders[${index}].name.last_name`}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <UiFormField
                                                                        type="text"
                                                                        placeholder="Street Address"
                                                                        label="Address (Optional)"
                                                                        fieldName={`shareholders[${index}].address.street`}
                                                                    />
                                                                    <Grid container spacing={1}>
                                                                        <Grid item md={5}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="City"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`shareholders[${index}].address.city`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={4}>
                                                                            <UiFormField
                                                                                type="select"
                                                                                placeholder="State"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`shareholders[${index}].address.state`}
                                                                                optionsData={config.apiConfig.generic.states}
                                                                                optionKey="name"
                                                                                optionValue="abbr"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={3}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="Zip Code"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`shareholders[${index}].address.zip_code`}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <UiFormField
                                                                        type="mask"
                                                                        placeholder="XXX-XX-XXXX"
                                                                        label="Social Security Number (Optional)"
                                                                        fieldName={`shareholders[${index}].ssn`}
                                                                        mask={[/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                                        minLength={9}
                                                                    />
                                                                    <UiFormField
                                                                        type="number"
                                                                        placeholder="0 %"
                                                                        label="Ownership Percentage (Optional)"
                                                                        fieldName={`shareholders[${index}].percentage_of_ownership`}
                                                                    />
                                                                    <UiFormField
                                                                        type="number"
                                                                        placeholder="$ 0.00"
                                                                        label="Capital Contributed (Optional)"
                                                                        fieldName={`shareholders[${index}].capital_contributed`}
                                                                    />
                                                                </Fragment>
                                                            ))
                                                        }
                                                        <Button startIcon={<Add />} color="secondary" variant="outlined" onClick={() => handleAddOwner(formik)}>
                                                            Add Another Shareholder
                                                        </Button>
                                                        {
                                                            !sectionsCompleted.includes('shareholders') && <div>
                                                                <Button
                                                                    variant="contained"
                                                                    type="button"
                                                                    color="primary"
                                                                    className={styles.marginVertical}
                                                                    onClick={() => handleAddSection('shareholders')}
                                                                >
                                                                    Next
                                                                </Button>
                                                            </div>
                                                        }
                                                    </Fragment>
                                                )
                                            }

                                            {
                                                /**
                                                 * Officers Information Section
                                                 */
                                                sectionsCompleted.includes('shareholders') && (
                                                    <Fragment>
                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Officers Information
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>

                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            President
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.president.name.first_name"
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.president.name.last_name"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Street Address"
                                                            label="Address (Optional)"
                                                            fieldName="officer_information.president.address.street"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={5}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="City"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.president.address.city"
                                                                />
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <UiFormField
                                                                    type="select"
                                                                    placeholder="State"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.president.address.state"
                                                                    optionsData={config.apiConfig.generic.states}
                                                                    optionKey="name"
                                                                    optionValue="abbr"
                                                                />
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.president.address.zip_code"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Vice President
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.vice_president.name.first_name"
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.vice_president.name.last_name"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Street Address"
                                                            label="Address (Optional)"
                                                            fieldName="officer_information.vice_president.address.street"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={5}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="City"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.vice_president.address.city"
                                                                />
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <UiFormField
                                                                    type="select"
                                                                    placeholder="State"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.vice_president.address.state"
                                                                    optionsData={config.apiConfig.generic.states}
                                                                    optionKey="name"
                                                                    optionValue="abbr"
                                                                />
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.vice_president.address.zip_code"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Secretary
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.secretary.name.first_name"
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.secretary.name.last_name"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Street Address"
                                                            label="Address (Optional)"
                                                            fieldName="officer_information.secretary.address.street"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={5}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="City"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.secretary.address.city"
                                                                />
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <UiFormField
                                                                    type="select"
                                                                    placeholder="State"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.secretary.address.state"
                                                                    optionsData={config.apiConfig.generic.states}
                                                                    optionKey="name"
                                                                    optionValue="abbr"
                                                                />
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.secretary.address.zip_code"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Treasurer
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.treasurer.name.first_name"
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.treasurer.name.last_name"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Street Address"
                                                            label="Address (Optional)"
                                                            fieldName="officer_information.treasurer.address.street"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={5}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="City"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.treasurer.address.city"
                                                                />
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <UiFormField
                                                                    type="select"
                                                                    placeholder="State"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.treasurer.address.state"
                                                                    optionsData={config.apiConfig.generic.states}
                                                                    optionKey="name"
                                                                    optionValue="abbr"
                                                                />
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.treasurer.address.zip_code"
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Director
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        {
                                                            formik.values.officer_information.director.map((director: any, index: number) => (
                                                                <Fragment key={index}>
                                                                    {
                                                                        index !== 0 ? <Typography
                                                                            variant="h6"
                                                                            gutterBottom
                                                                            className={`${styles.marginVertical} d-flex align-items-center justify-content-between`}
                                                                        >
                                                                            Director #{index + 1}
                                                                            {
                                                                                initialValues.officer_information.director.length > 1
                                                                                    ? <IconButton size="small" onClick={() => handleRemoveDirector(index)}>
                                                                                        <Close />
                                                                                    </IconButton>
                                                                                    : ''
                                                                            }
                                                                        </Typography> : ''
                                                                    }
                                                                    <Grid container spacing={1}>
                                                                        <Grid item md={6}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="First Name"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`officer_information.director[${index}].name.first_name`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={6}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="Last Name"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`officer_information.director[${index}].name.last_name`}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <UiFormField
                                                                        type="text"
                                                                        placeholder="Street Address"
                                                                        label="Address (Optional)"
                                                                        fieldName={`officer_information.director[${index}].address.street`}
                                                                    />
                                                                    <Grid container spacing={1}>
                                                                        <Grid item md={5}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="City"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`officer_information.director[${index}].address.city`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={4}>
                                                                            <UiFormField
                                                                                type="select"
                                                                                placeholder="State"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`officer_information.director[${index}].address.state`}
                                                                                optionsData={config.apiConfig.generic.states}
                                                                                optionKey="name"
                                                                                optionValue="abbr"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item md={3}>
                                                                            <UiFormField
                                                                                type="text"
                                                                                placeholder="Zip Code"
                                                                                label=""
                                                                                labelSize={false}
                                                                                fieldName={`officer_information.director[${index}].address.zip_code`}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        <Button startIcon={<Add />} color="secondary" variant="outlined" onClick={() => handleAddDirector(formik)}>
                                                            Add Another Director
                                                    </Button>

                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Registered Agent
                                                            <Typography variant="subtitle1" component="span"> (Optional)</Typography>
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.registered_agent.name.first_name"
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.registered_agent.name.last_name"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <UiFormField
                                                            type="text"
                                                            placeholder="Street Address"
                                                            label="Address (Optional)"
                                                            fieldName="officer_information.registered_agent.address.street"
                                                        />
                                                        <Grid container spacing={1}>
                                                            <Grid item md={5}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="City"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.registered_agent.address.city"
                                                                />
                                                            </Grid>
                                                            <Grid item md={4}>
                                                                <UiFormField
                                                                    type="select"
                                                                    placeholder="State"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.registered_agent.address.state"
                                                                    optionsData={config.apiConfig.generic.states}
                                                                    optionKey="name"
                                                                    optionValue="abbr"
                                                                />
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <UiFormField
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                    label=""
                                                                    labelSize={false}
                                                                    fieldName="officer_information.registered_agent.address.zip_code"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            !sectionsCompleted.includes('officer_information') && <Button
                                                                variant="contained"
                                                                type="button"
                                                                color="primary"
                                                                className={styles.marginBottom}
                                                                onClick={() => handleAddSection('officer_information')}
                                                            >
                                                                Next
                                                            </Button>
                                                        }
                                                    </Fragment>
                                                )
                                            }

                                            {
                                                /**
                                                 * Document Upload and Submission Section
                                                 */
                                                sectionsCompleted.includes('officer_information') && (
                                                    <Fragment>
                                                        <Typography variant="h6" gutterBottom className={styles.marginVertical}>
                                                            Document Upload and Submission
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Upload the copy of your most recent profit and loss statement, balance sheet and/or latest tax return.
                                                        </Typography>
                                                        <Button
                                                            component='label'
                                                            variant="contained"
                                                            color="primary"
                                                            className={styles.marginVertical}
                                                        >
                                                            <Typography variant='button'>
                                                                Browse Computer
                                                            </Typography>
                                                            <input
                                                                type='file'
                                                                hidden
                                                                onChange={handleSelectedFiles}
                                                                multiple
                                                            />
                                                        </Button>

                                                        <Typography variant="caption" component="div" gutterBottom>
                                                            {allowed_extensions.join(', ')} file types are allowed with maximum size of {max_file_size / 1024 / 1024}MB
                                                        </Typography>
                                                        {
                                                            selectedFiles.length ? (
                                                                <div className={styles.filesContainer}>
                                                                    {
                                                                        selectedFiles.map((file, index) => (
                                                                            <div key={index} className="d-flex align-items-center justify-content-between">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img
                                                                                        className={styles.fileIcon}
                                                                                        alt={`File`}
                                                                                        src={
                                                                                            require(`../../../../assets/icons-svg/${getIconType(
                                                                                                file.fileData.mime_type
                                                                                            )}.svg`).default
                                                                                        }
                                                                                    />
                                                                                    {file.fileData.filename}
                                                                                </div>
                                                                                {file.uploading && <Loader size={20} width="unset" />}
                                                                                {file.uploaded && <IconButton size="small" onClick={() => handleRemoveFile(index)}>
                                                                                    <Close fontSize="inherit" />
                                                                                </IconButton>}
                                                                                {file.error && <Tooltip title={file.error}>
                                                                                    <IconButton size="small" onClick={() => handleRemoveFile(index)}>
                                                                                        <Warning fontSize="inherit" />
                                                                                    </IconButton>
                                                                                </Tooltip>}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className={styles.filesContainer}>
                                                                    <div className={styles.marginVertical}>
                                                                        No files uploaded..!!
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <UiFormField
                                                            type="checkbox"
                                                            inlineLable="1-800Accountant can contact me to discuss tax preparation and filing for business return"
                                                            label=""
                                                            labelSize={false}
                                                            fieldName="submission_info.contact_me_to_discuss_tax_preparation_and_filing_return"
                                                        />
                                                        <UiFormField
                                                            type="checkbox"
                                                            inlineLable="I need help or have questions about my quarterly estimated tax filings and/or state tax filings"
                                                            label=""
                                                            labelSize={false}
                                                            fieldName="submission_info.need_help_about_quarterly_estimated_taxes_or_state_tax_filings"
                                                        />
                                                        <UiFormField
                                                            type="checkbox"
                                                            inlineLable="I agree to the terms of service"
                                                            label=""
                                                            labelSize={false}
                                                            fieldName="submission_info.agree_with_terms_of_service"
                                                        />
                                                        {
                                                            formSubmitted
                                                                ? <div className={styles.marginVertical}>
                                                                    <Loader />
                                                                </div>
                                                                : hasPermission(
                                                                    'ANNUAL_REPORT',
                                                                    businessAccount
                                                                ) ? (
                                                                    <Button
                                                                        variant='contained'
                                                                        fullWidth
                                                                        className={
                                                                            styles.marginVertical
                                                                        }
                                                                        type='submit'
                                                                        disabled={
                                                                            !formik.isValid
                                                                        }
                                                                        onClick={() => {
                                                                            formik.validateForm();
                                                                        }}
                                                                        color="primary"
                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                ) : product && (
                                                                    <div
                                                                        className={
                                                                            styles.marginVertical
                                                                        }
                                                                    >
                                                                        <ProductPurchase
                                                                            product={
                                                                                product as Product
                                                                            }
                                                                            fullWidth={true}
                                                                            utmContentParam={utmContent}
                                                                        />
                                                                    </div>
                                                                )
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                        </Form>
                                    );
                                }
                            }
                        </Formik >
                    )
                    : <SubmitAnnualReportPopupIntro setIntroCompleted={setIntroCompleted} handleClose={handleClose} />
            }
        </UiDialog >
    );
}

const mapStateToProps = ({ config, appData, entityManagement }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
    dueDate: entityManagement.entityTab.annualReportDueDate,
    businessAccount: appData.current_account,
})
export default connect(mapStateToProps)(SubmitAnnualReportPopup);