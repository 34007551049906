import moment, { DurationInputArg2, unitOfTime } from 'moment-timezone';


interface stringMap{
    [key: string]: string;
}

const USTimezones : stringMap = {
    '-5': 'EST',
    '-6': 'CST',
    '-7': 'MST',
    '-8': 'PST',
};
export const _timestamp = (date: Date) => {
    return Math.floor(date.valueOf() / 1000);
};
export const changeDateTime = (
    dateTimestamp: number,
    h: number,
    m: number,
    s: number
) => {
    if (dateTimestamp === null) {
        return null;
    }
    let date = new Date(dateTimestamp * 1000);
    date.setHours(h);
    date.setMinutes(m);
    date.setSeconds(s);
    return _timestamp(date);
};

export const dayStartTimestamp = (dateTimestamp: number) => {
    let dayStart = new Date(dateTimestamp);
    return changeDateTime(_timestamp(dayStart), 0, 0, 0);
};

export const startOfTimestampEst = (start_of: unitOfTime.StartOf) =>
    moment().tz('America/New_York').startOf(start_of).unix();

export const startOfTimestamp = (startOf: unitOfTime.StartOf) =>
    moment().startOf(startOf).unix();

export const endOfTimestampEst = (end_of: unitOfTime.StartOf) =>
    moment().tz('America/New_York').endOf(end_of).unix();

export const endOfTimestamp = (endOf: unitOfTime.StartOf) =>
    moment().endOf(endOf).unix();

export const getCurrentUTCTimeStamp = () : number =>{
    return moment.utc().unix();
}
export const startOfWithSubtractTimestampEst = (
    what_to_subtract: DurationInputArg2,
    how_many_to_Subtract: number,
    start_Of: unitOfTime.StartOf
) => {
    return moment()
        .tz('America/New_York')
        .subtract(how_many_to_Subtract, what_to_subtract)
        .startOf(start_Of)
        .unix();
};

export const startOfWithSubtractTimestamp = (
    whatToSubtract: DurationInputArg2,
    howManyToSubtract: number,
    startOf: unitOfTime.StartOf
) => {
    return moment()
        .subtract(howManyToSubtract, whatToSubtract)
        .startOf(startOf)
        .unix();
};

export const endOfWithSubtractTimestampEst = (
    what_to_subtract: DurationInputArg2,
    how_many_to_Subtract: number,
    end_of: unitOfTime.StartOf
) => {
    return moment()
        .tz('America/New_York')
        .subtract(how_many_to_Subtract, what_to_subtract)
        .endOf(end_of)
        .unix();
};

export const endOfWithSubtractTimestamp = (
    whatToSubtract: DurationInputArg2,
    howManyToSubtract: number,
    endOf: unitOfTime.StartOf
) => {
    return moment()
        .subtract(howManyToSubtract, whatToSubtract)
        .endOf(endOf)
        .unix();
};
/**
 * @param timestamp | number
 * @returns Date - `9 Sep 2021`
 */
export const longDateFormat = (timestamp: number) => {
    return moment(timestamp * 1000).format("D MMM YYYY")
}

export const dateFormatMDY = (time_stamp: number) =>
    moment(time_stamp).format('MMM DD, YYYY');

export const dateTimeFormat = (time_stamp: number) =>
    moment(time_stamp).format('DD MMM YYYY  • h:mm A');

export function getDayFullName(): string {
    const currentDate = new Date();
    return currentDate.toLocaleString('default', { weekday: 'long' });
}

export function getCurrentDateNumber(): number {
    const currentDate = new Date();
    return currentDate.getDate();
}

export function getCurrentMonth(): string {
    const currentDate = new Date();
    return currentDate.toLocaleString('default', { month: 'long' });
}

export function getCurrentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
}

export function getCurrentLocalTime() {
    const currentDate = new Date();
    return currentDate.toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
    });
}

export function getTimeAndGMTOffset(): string {
    const currentDate = new Date();
    return currentDate.toTimeString().split(' ')[1];
}

export function getCurrentDateWithGMTOffset() {
    return (
        getDayFullName() +
        ' ' +
        getCurrentDateNumber() +
        ' ' +
        getCurrentMonth() +
        ' ' +
        getCurrentYear() +
        ' ' +
        getCurrentLocalTime() +
        ' ' +
        getTimeAndGMTOffset()
    );
}

export function getTimeZoneOffset(): number {
    const currentDate = new Date();
    return currentDate.getTimezoneOffset();
}

export function convertUnixToGivenDateFormat(
    format: string,
    time: number
): string {
    return moment(time).format(format);
}

export function getLastYear(): number {
    const currentYear: number = new Date().getFullYear();
    return currentYear - 1;
}

export function getLastYearEndOfTimeStamp(): number {
    const lastYear: number = getLastYear();
    return moment([lastYear]).endOf('year').unix();
}

export function getCurrentTimeStamp(): number {
    return moment().unix();
}
export function getDateInYmd(date: number): string {
    const dateFormat = 'YYYY-MM-DD';
    const dateInMs = date * 1000; // convert seconds to milliseconds
    const formattedDate = convertUnixToGivenDateFormat(dateFormat, dateInMs);
    return formattedDate;
}
export function formatDateDDMMYY(date: number): string {
    const format = 'DD MMM yyyy';
    const dateInMilliSecs = date * 1000;
    return convertUnixToGivenDateFormat(format, dateInMilliSecs);
}

export const getCurrentDate = () => {
    return new Date();
};
export const getRealTimezoneOffset = (): number => {
    const dtDate = new Date('1/1/' + new Date().getUTCFullYear());
    let intOffset = 10000; //set initial offset high so it is adjusted on the first attempt
    let intMonth;
    for (intMonth = 0; intMonth < 12; intMonth++) {
        //go to the next month
        dtDate.setUTCMonth(dtDate.getUTCMonth() + 1);

        //To ignore daylight saving time look for the lowest offset.
        //Since, during DST, the clock moves forward, it'll be a bigger number.
        if (intOffset > dtDate.getTimezoneOffset() * -1) {
            intOffset = dtDate.getTimezoneOffset() * -1;
        }
    }
    return intOffset;
};
const getFormattedAMPM = (hours : number) =>{
    return hours >= 12 ? 'PM' : 'AM';
}
export const convertTimeStampToTime = (timeStamp: number) : string=>{
    const date = new Date(timeStamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const formattedAMPM = getFormattedAMPM(hours);
    return hours + ':' + minutes.substr(-2) + ' ' + formattedAMPM;
}
export const getUserTimeZone = () => {
    let UTC_offset: any = getRealTimezoneOffset();
    let GMT_offset: string =
        (UTC_offset > 0 ? '-' : '+') +
        Math.floor(Math.abs(UTC_offset) / 60) +
        (UTC_offset % 30 !== 0 ? ':30' : '');
    if (!USTimezones[GMT_offset]) {
        UTC_offset = new Date().getTimezoneOffset();
        GMT_offset =
            (UTC_offset > 0 ? '-' : '+') +
            Math.floor(Math.abs(UTC_offset) / 60) +
            (UTC_offset % 30 !== 0 ? ':30' : '');
        return 'GMT' + GMT_offset;
    }
    return USTimezones[GMT_offset];
};

export function getGeneratedOnDate() {
    const currentDate = getCurrentDate();
    const userTimeZone = getUserTimeZone();
    return `${currentDate} ${userTimeZone}`;
}
export function getTimeParamsWithoutEST(period: string, tillToday?: boolean) {
    switch (period) {
        case 'Today':
            return { endDate: endOfTimestamp('day'), type: 'today' };
        case 'This Month':
            return {
                startDate: startOfTimestamp('month'),
                endDate: tillToday
                    ? endOfTimestamp('day')
                    : endOfTimestamp('month'),
            };
        case 'This Week':
            return {
                startDate: startOfTimestamp('week'),
                endDate: tillToday
                    ? endOfTimestamp('day')
                    : endOfTimestamp('week'),
            };
        case 'Three Months':
            return {
                startDate: startOfWithSubtractTimestamp('month', 2, 'month'),
                endDate: tillToday
                    ? endOfTimestamp('day')
                    : endOfTimestamp('month'),
            };

        case 'Last Quarter':
            return {
                startDate: startOfWithSubtractTimestamp(
                    'quarter',
                    1,
                    'quarter'
                ),
                endDate: endOfWithSubtractTimestamp('quarter', 1, 'quarter'),
                type: 'last_quarter',
            };
        case 'This Year':
            return {
                startDate: startOfTimestamp('year'),
                endDate: tillToday
                    ? endOfTimestamp('day')
                    : endOfTimestamp('year'),
                type: 'year',
            };
        case 'Last Year':
            return {
                startDate: startOfWithSubtractTimestamp('year', 1, 'year'),
                endDate: endOfWithSubtractTimestamp('year', 1, 'year'),
                type: 'last_year',
            };
        default:
            return {};
    }
}


export const startOfDayTimeStamp = _timestamp(new Date(moment().startOf('day').toDate()))
export const endOfDayTimeStamp = _timestamp(new Date(moment().endOf('day').toDate()))


const getPeriodStartTimeStamps = (period: 'day' | 'month' | 'year' | 'week') => _timestamp(new Date(moment().startOf(period).toDate()))
const getPeriodEndTimeStamps = (period: 'day' | 'month' | 'year' | 'week') => _timestamp(new Date(moment().endOf(period).toDate()))


export const getDateRange = (type: string, tillToday?: boolean) => {
    switch (type) {
        case 'This week':
            return { 
                startDate: getPeriodStartTimeStamps('week'),
                endDate: tillToday ? startOfDayTimeStamp : getPeriodEndTimeStamps('week')
            }   
        case 'This month': 
            return { 
                startDate: getPeriodStartTimeStamps('month'),
                endDate:  tillToday ? startOfDayTimeStamp : getPeriodEndTimeStamps('month')
            }   
        case 'Three months':
            return { 
                startDate: _timestamp(moment().subtract(2, 'M').startOf('month').toDate()),
                endDate:  tillToday ? startOfDayTimeStamp : _timestamp(moment().subtract(2, 'M').endOf('month').toDate())

            } 
        case 'This year':
            return { 
                startDate: getPeriodStartTimeStamps('year'),
                endDate:  tillToday ? startOfDayTimeStamp : getPeriodEndTimeStamps('year'),
            }   
        default: 
            break;    
    }
}
    
export const adjustToMiddayUTC = (utcDate: any) => {
    return moment.utc({
        year: utcDate.year(),
        month: utcDate.month(),
        date: utcDate.date(),
        hour: 12,
        minute: 0,
        second: 0,
    }).unix()
}

