import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import UiDialog from '../../common/ui/UiDialog'
import { useHistory } from 'react-router-dom'
import {
    getFromLocalStorage,
    setInLocalStorage,
} from '../../../services/localStorageService'
import { fetchPaymentMethodUpdaterUrl } from '../../../services/apiService/business'
import { toRem16 } from '../../../styles/commonStyles'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
            padding: `${toRem16(15)} ${toRem16(45)}`,
        },
    },
    formActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${toRem16(10)} 0`,
        marginTop: 10,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    TextCenter: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
}))

const pastDueModalFirstLoad = getFromLocalStorage('pastDueModalFirstLoad')
const paymentMethodUpdate = getFromLocalStorage('paymentMethodUpdaterUrl')

function InfoModal() {
    const classes = useStyles()
    const history = useHistory()
    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [modalText, setModalText] = useState('');
    const ninetyOlderText = 'Our records indicate your payment method is expired, or invalid. Your invoice is now more than 90 days past due, causing you to lose access to your tax compliance and/or accounting services. To restore access, we ask you please update this information right away.' ;
    const genericModalText = 'Our records indicate your payment method is expired or invalid. In order to retain your tax compliance and/or accounting services we ask you please update this information right away';
    const { appData, currentUser } = useCurrentStore()

    const resolvePaymentStatus = () => {
        setOpenInfoModal(false)
        let route = '/purchase/billing'
        if (getFromLocalStorage('paymentMethodUpdaterUrl')) {
            route = 'purchase/update-payment-method'
        }
        history.push(route)
    }

    useEffect(() => {
        if (!pastDueModalFirstLoad) {
            const userFinancePastDueStatus = appData?.accounts.filter(
                (item: any) =>
                    item?.account_type === 'personal' &&
                    item?.finance_status === 'Past Due'
            )
            if (!paymentMethodUpdate) {
                let sf_account_id = currentUser.hasOwnProperty('portal_account')
                    ? currentUser.portal_account.sf_account_id
                    : null
                fetchPaymentMethodUpdaterUrl(sf_account_id).then(
                    (response: any) => {
                        setInLocalStorage(
                            'paymentMethodUpdaterUrl',
                            response?.url
                        )
                        setModalText(response?.ninety_days_older ? ninetyOlderText : genericModalText);
                    }
                )
            }
            if (userFinancePastDueStatus.length > 0) {
                setOpenInfoModal(true)
                setInLocalStorage('pastDueModalFirstLoad', true)
            }
        }
    }, [appData?.accounts, currentUser, appData])

    return (
        <UiDialog
            open={openInfoModal}
            handleClose={() => {
                setOpenInfoModal(false)
            }}
            title=""
            size="sm"
            customRootClass={classes.container}
        >
            <Fragment>
                <div>
                    <Fragment>
                        <Typography
                            className={classes.TextCenter}
                            variant="h6"
                            style={{ marginBottom: 24 }}
                        >
                            Past Due Notice
                        </Typography>
                        <Typography
                            className={classes.TextCenter}
                            variant="body1"
                        >
                            { modalText }
                        </Typography>
                    </Fragment>
                </div>

                <div className={classes.formActions}>
                    <Button
                        variant="contained"
                        onClick={resolvePaymentStatus}
                        color="primary"
                    >
                        Resolve Now
                    </Button>
                </div>
            </Fragment>
        </UiDialog>
    )
}

export default InfoModal
