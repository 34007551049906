import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { useTheme, Grid, Box, Divider, GridSize } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useStore } from 'react-redux';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';

import UiText from '../../common/ui/UiText';
import Loader from '../../common/Loader';
import FormHeaderText from './FormHeaderText';
import NextActionButton from './NextActionButton';
import {
    payrollSignatoryFormFields,
    payrollSignatoryAddress,
} from './PayrollSignatoryFormUtils';
import { commonStyles } from '../../../styles/commonStyles';
import {
    getPayrollSignatory,
    addPayrollSignatory,
    updatePayrollSignatory,
} from '../../../services/apiService/payroll/company';
import { formSubmitErrorHandler } from '../../../services/formService';
import moment from 'moment';

const PayrollSignatoryForm = forwardRef(({ refreshSteps, parentLoading }: any, ref) => {
    const theme = useTheme();
    const styles = commonStyles();
    const store = useStore().getState();
    const [skipNext, setSkipNext] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        title: '',
        birthday: '',
        ssn: '',
        street: '',
        street_line_2: '',
        city: '',
        zip: '',
        state: '',
    });
    let currentBusinessId = store.appData.current_business_id;
    const [loading, setLoading] = useState<boolean>(false);

    let payrollSignatoryCreated =
        store.company.companyOnboardingStepInfo.onboarding_steps_status
            .payroll_signatory_addition;
    const formRef = useRef<any>();
    const validationSchema = Yup.object({
        ssn: Yup.string().required('Social Security Number is required'),
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        title: Yup.string().required('Title is required'),
        birthday: Yup.string().required('Birthday is required'),
        street_1: Yup.string().required('Street is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        phone: Yup.string().required('Phone is required'),
        zip: Yup.string().required('Zipcode is required'),
    });

    useEffect(() => {
        setLoading(true);
        if (payrollSignatoryCreated) {
            getPayrollSignatory(currentBusinessId)
                .then((payroll) => {
                    if (payroll) {
                        setLoading(false);
                        setInitialValues(payroll);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [currentBusinessId, payrollSignatoryCreated]);

    useImperativeHandle(
        ref,
        () => ({
            isFromDirty() {
                return formRef.current?.dirty;
            },

            submit() {
                if (formRef.current.isValid) {
                    setSkipNext(true);
                }

                return formRef.current.submitForm;
            },
        }),
        [formRef]
    );

    const renderFormFields = (property: any) => {
        return (
            <UiFormControlSelection
                showFloatingLabel
                placeholder={property?.placeholder}
                type={property.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                optionsData={property?.data}
                optionKey={property?.optionKey}
                optionValue={property?.optionValue}
                label={property?.label}
                cypressId={property?.cypressId}
                {...(property.type === 'KeyboardDatePicker'
                    ? {
                          disableFutureDate: true,
                          dateFormat: 'MM/DD/YYYY',
                          dateType: 'string',
                          nonIsoDate: true,
                      }
                    : {})}
                {...(property.type === 'mask'
                    ? { mask: property?.inputMask, fieldSize: 6 }
                    : {})}
                {...(property?.minLength
                    ? { minLength: property?.minLength }
                    : {})}
                {...(property.endIcon ? { endIcon: property.endIcon } : {})}
            />
        );
    };

    const onSubmit = (data: any, actions: FormikHelpers<any>) => {
        parentLoading(true);
        formSubmitErrorHandler(
            (payrollSignatoryCreated
                ? updatePayrollSignatory
                : addPayrollSignatory)(currentBusinessId, {
                ...data,
                birthday: moment(data.birthday).format('MM/DD/YYYY'),
            }).then((val) => {
                if (skipNext) {
                    setSkipNext(false);
                    setInitialValues(val);
                    actions.resetForm(val);
                } else {
                    refreshSteps();
                }
                actions.setSubmitting(false);
                parentLoading(false);
            }),
            () => {
                actions.setSubmitting(false);
                parentLoading(false);
                setSkipNext(false);
            },
            actions.setFieldError
        );
    };

    return (
        <Grid
            style={{
                height: 'calc(100% - 108px)',
                overflowX: 'hidden',
                overflowY: loading ? 'hidden' : 'auto',
            }}
        >
            <FormHeaderText heading='Payroll Signatory' formType='(Required)' />
            <UiText>
                The Payroll Signatory is an individual who is designated to sign
                forms on behalf of the business.
            </UiText>
            {loading ? (
                <Loader />
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    innerRef={formRef}
                >
                    {({ isSubmitting, submitForm }) => {
                        return (
                            <Form>
                                <Box my={2}>
                                    {payrollSignatoryFormFields.map(
                                        (property: any) => (
                                            <Grid
                                                container
                                                spacing={1}
                                                key={property.key}
                                            >
                                                <Grid
                                                    item
                                                    xs={
                                                        property?.xs as GridSize
                                                    }
                                                    md={
                                                        property?.md as GridSize
                                                    }
                                                    style={{
                                                        marginRight: theme.spacing(
                                                            1
                                                        ),
                                                    }}
                                                >
                                                    <div
                                                        className={styles.mt16}
                                                    >
                                                        {renderFormFields(
                                                            property
                                                        )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </Box>
                                <Divider />
                                <Box mt={4} mb={2}>
                                    <UiText
                                        weight='medium_500'
                                        variant='hatchback_125'
                                    >
                                        Signatory Address
                                    </UiText>
                                    {payrollSignatoryAddress(
                                        store?.config?.apiConfig
                                    ).map((property: any) => (
                                        <Grid
                                            container
                                            spacing={1}
                                            key={property.key}
                                        >
                                            <Grid
                                                item
                                                xs={property?.xs as GridSize}
                                                md={property?.md as GridSize}
                                                style={{
                                                    marginRight: theme.spacing(
                                                        1
                                                    ),
                                                }}
                                            >
                                                <div className={styles.mt16}>
                                                    {renderFormFields(property)}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                                <Box my={2} mt={2}>
                                    <NextActionButton
                                        loading={isSubmitting}
                                        submitAction={submitForm}
                                    />
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </Grid>
    );
});

export default PayrollSignatoryForm;
