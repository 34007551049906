import React, { useEffect, useState } from 'react'
import { InlineFlex } from '../Wrapper'
import { toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import { Form, Formik } from 'formik'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { CollapsibleSection } from '../CollapsibleSection'
import { useContractorContext } from '../ContractorProvider'
import { handleImportMode } from '../utills/contractor.utils'
import { Theme, makeStyles } from '@material-ui/core'
import UiButton from '../../common/ui/UiButton'
import { importContractor } from '../apiServices/contractor.api'
import Loader from '../../common/Loader'
import * as Yup from 'yup'
import { IMPORT_STEP3_DATA, IMPORT_STEPS } from '../constants/contractor.const'
import DiscardModal from './DiscardModal'
import { requestErrorHandler } from '../../../services/formService'
import Icon from '../../common/Icon'
import { COLORS } from '../../../variables/colors'

interface Option {
    key: string;
    value: string;
  }

const useStyles = makeStyles((theme: Theme) => {
    return {
        gap: {
            marginRight: toRem16(24),
        },
    }
})

export const MatchYouColunms: React.FC<any> = (): any => {
    const { currentBusinessId, importData, setImportData } =
        useContractorContext()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openDiscard, setOpenDiscard] = useState<boolean>(false)
    const classes = useStyles()
    let submitCloser: any

    const handleRemount = () => {
        setTimeout(() => setIsLoading(false))
    }

    useEffect(() => {
        setIsLoading(true)
        handleRemount()
    }, [importData.completed_step])
    const handleFormSubmit = (values: any) => {
        if (importData.completed_step > 2) {
            setOpenDiscard(true)
            return
        }
        setIsLoading(true)
        requestErrorHandler(
            importContractor(currentBusinessId!, values).then((res: any) => {
                setIsLoading(false)
                setImportData({ ...importData, completed_step: 3 })
            }),
            true,
            () => {
                setIsLoading(false)
            }
        )
    }
    
    const sanitizedColumnPreferences = Object.fromEntries(
    Object.entries(importData.column_preferences).filter(([key]) =>
        IMPORT_STEP3_DATA.some(({ key: importKey }) => key === importKey))
    );
    
    const makeValidation = () => {
        let validation: any = {}
        Object.keys(sanitizedColumnPreferences).forEach((name: string) => {
            validation[name] = Yup.string().required('Required')
        })
        return validation
    }
    
    const validationSchema = Yup.object(makeValidation()) // to do

    return (
        <CollapsibleSection
            header="Confirm that your columns match up"
            subHeader="Here’s what we imported. Match the columns we imported with the correct title."
            mode={handleImportMode(
                IMPORT_STEPS.THREE,
                importData?.completed_step
            )}
            preExpanded={importData?.completed_step === IMPORT_STEPS.TWO}
            stepNumber={IMPORT_STEPS.THREE}
        >
            <>
                {!isLoading ? (
                    <Formik
                        initialValues={sanitizedColumnPreferences}
                        onSubmit={handleFormSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ handleSubmit }) => {
                            submitCloser = handleSubmit
                            return (
                                <Form className={classes.gap}>
                                    <InlineFlex
                                        gap={toRem16(54)}
                                        marginbottom={toRem16(24)}
                                        width="80%"
                                    >
                                        <UiText
                                            variant="hatchback_125"
                                            weight="semi_bold_600"
                                        >
                                            Uploaded Document Column Titles
                                        </UiText>
                                        <UiText
                                            variant="hatchback_125"
                                            weight="semi_bold_600"
                                        >
                                            What should it match to?
                                        </UiText>
                                    </InlineFlex>
                                    {Object.keys(
                                        sanitizedColumnPreferences
                                    ).map((column: string) => {
                                        return (
                                            <InlineFlex
                                                key={column}
                                                gap={toRem16(24)}
                                                marginbottom={toRem16(24)}
                                                width="80%"
                                            >
                                                <UiFormControlSelection
                                                    type="text"
                                                    fieldName={`${column}_`}
                                                    label={column}
                                                    showFloatingLabel
                                                    disabled
                                                />
                                                <Icon icon='rightArrow' svgColor={COLORS.grey800}/>
                                                <UiFormControlSelection
                                                    type="select"
                                                    optionsData={IMPORT_STEP3_DATA}
                                                    optionKey='key'
                                                    optionValue='value'
                                                    showFloatingLabel
                                                    label="Destination"
                                                    fieldName={column}
                                                    disabled={importData.completed_step > 2}
                                                />
                                            </InlineFlex>
                                        )
                                    })}
                                </Form>
                            )
                        }}
                    </Formik>
                ) : (
                    <Loader />
                )}
                <InlineFlex justifycontent="flex-end">
                    <UiButton
                        btnType="tertiary"
                        label="Import Data"
                        handleClick={(e) => submitCloser(e)}
                        customClass={classes.gap}
                        disabled={isLoading||importData.completed_step > 2}
                    />
                </InlineFlex>
                <DiscardModal
                    openDialog={openDiscard}
                    setOpenDialog={setOpenDiscard}
                />
            </>
        </CollapsibleSection>
    )
}
