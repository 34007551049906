import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormikContextType, useFormikContext } from 'formik'
import { makeStyles, Theme } from '@material-ui/core'
import {
    FormikAutocompleteProps,
    OnBoardingFormType,
} from '../models/onboarding.interface'

const useStyle = makeStyles((theme: Theme) => ({
    autoCompleteField: {
        '& label': {
            fontSize: '1rem',
            top: '-0.375rem',
        },
        '& .MuiAutocomplete-inputRoot': {
            paddingTop: '0.125rem',
            paddingBottom: '0.188rem',
        },
        '& input': {
            paddingTop: '0.438rem',
            paddingBottom: '0.625rem',
        },
    },
}))

const FormikAutocomplete = ({
    type,
    optionsData,
    placeholder,
}: FormikAutocompleteProps) => {
    const {
        values,
        touched,
        setFieldError,
        setFieldValue,
        handleBlur,
    }: FormikContextType<OnBoardingFormType> = useFormikContext()
    const classes = useStyle()

    //@ts-ignore
    const showError = touched[type] && values[type] === ''

    useEffect(() => {
        if (showError) {
            setFieldError(type, 'Required')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, showError])

    return (
        <div>
            <Autocomplete
                id={type}
                options={optionsData}
                getOptionLabel={(option: any) => {
                    return type === 'business_type'
                        ? option?.title
                        : option?.name
                }}
                onChange={(event, value) =>
                    setFieldValue(type, value?.id || '')
                }
                value={optionsData.find(
                    //@ts-ignore
                    (option: any) => option?.id === values[type]
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={true}
                        className={classes.autoCompleteField}
                        label={placeholder}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={showError}
                        helperText={showError ? 'Required' : ''}
                    />
                )}
            />
        </div>
    )
}

export default FormikAutocomplete
