export const REDIRECT_URL = {
    UAT: 'https://uat.1800accountant.com',
}

export const QUERY_SUBSTRING = 'cbapi/app.php/plaid/payroll/handle_redirect?'

export const ERROR_MESSAGE = {
    REQUIRED_ROUTING_NUMBER: 'Routing Number is required',
    INVALID_ROUTING_NUMBER: 'Enter a valid Routing number',
    ROUTING_LENGTH_VALIDATION: 'Routing number must be 9 digits long',
    REQUIRED_ACCOUNT_NUMBER: 'Account Number is required',
    ENTER_ACCOUNT_NUMBER_VALIDATION: 'Enter a valid Account Number',
    REQUIRED_ACCOUNT_TYPE: 'Account Type is required',
    REQUIRED_VALID_AMOUNT: 'Please enter valid amount',
    PAYMENT_ONE_REQUIRED: 'Payment 1 is required',
    PAYMENT_TWO_REQUIRED: 'Payment 2 is required',
    REQUIRED_BANK_NAME: 'Bank Name is required',
}
