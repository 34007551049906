import { useCallback, useContext, useEffect, useState } from 'react';
import {
    createStyles,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core'
import { useDispatch, useStore } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
    createFile,
    downloadTransactionReportCSV,
    downloadTransactionReportPDF,
    excludeTransaction,
    getBankAccountList,
    getTransactionDetailsById,
    getTransactionsReport,
    saveTransactionReport,
} from '../../../../services/apiService/reports'
import {
    currencyFormatter,
    getNumberfromString,
    isEmpty,
} from '../../../../utils/appUtil'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import {
    TransactionReportRoot,
    Transactions,
    transactionType,
} from '../models/reports-interface'
import { useReportsStyles } from '../styles/reports-styles'
import Loader from '../../../common/Loader'
import {
    DOCUMENT_DOWNLOAD_SUCCESS,
    EMPTY_PARAMS,
    saveOptions,
    REPORTS_MENU_VIEWS
} from '../constants/reports.const'
import EditIcon from '../../../../assets/icons-svg/Edit.svg'
import { convertUnixToGivenDateFormat } from '../../../../utils/dateUtil'
import UiText from '../../../common/ui/UiText'
import NewTransactionModal from '../../transactions/NewTransactionModal'
import { initCategories } from '../../../../store/actions/categories'
import { deleteTransactionById } from '../../../../services/transactionsService'
import UncategorizedTransactionsAlert from '../utils/Alerts/UncategorizedTransactionsAlert'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import SaveReports from '../utils/SaveReports/SaveReports'
import { showError } from '../../../../services/formService'
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo'
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading'
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider'
import InfoTooltip from '../../../common/InfoTooltip'
import { InfoOutlined } from '@material-ui/icons'
import {
    deleteJournalById,
    getAccount,
    getJournalById,
} from '../../../../services/journalServices'
import NewJournalModal from '../../journal/NewJournalModal'
import { useJournalState } from '../../journal/JournalProvider'
import { resetReportsData } from '../../../../store/actions/reportsData'
import ErrorAlert from '../utils/Alerts/ErrorAlert'
import { LeftArrowIcon } from '../Icons/LeftArrowIcon'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import TripOverlayForm from '../../../mileage-log/trips/Form/TripOverlayForm';
import { getTripDetails } from '../../../../services/apiService/trips';
import { Trip } from '../../../../models';

interface QueryParams {
    selectedAccount: string
    ymdStartDate: string
    ymdEndDate: string
    periodText: string
    time: number
    selectedAccoumntName: string
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        totalPaid: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '0 0 0.5rem 0',
            marginTop: '1.5rem',
            marginBottom: '5rem',
        },
        noOfInvoices: {
            margin: '1.5rem 0',
        },
        businessName: {
            color: (colorTheme) => colorTheme.black100,
        },

        hideTooltip: {
            display: 'block',
            width: '3rem',
            height: 'auto',
        },
        priceWaste: {
            color: (colorTheme) => colorTheme.red100,
        },
        descriptionValue: {
            padding: 0,
            '& button': {
                padding: 0,
            },
        },
    })
)
const TOOLTIP_TEXT =
    'The Transactions by Account Report lists all transactions you made for the selected period of time that were categorized in the specified account from your chart of accounts.'
export default function TransactionDetailedReport() {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [reportsData, setReportsData] = useState<TransactionReportRoot>()
    const store = useStore().getState()
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const businessId = currentStore.currentBusinessId!
    const businessName = currentStore.currentBusiness.name
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme)
    const [selectedReport, setSelectedReport] = useState<Transactions>()
    const query: QueryParams = useParams() as unknown as QueryParams
    const history = useHistory()
    const { selectedAccount, ymdStartDate, ymdEndDate, periodText, time } =
        query
    const { listData, setListData } = useJournalState()
    const dispatch = useDispatch()
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('')
    const [openModal, setOpenModal] = useState<string | null>(null)
    const [bankAccount, setBankAccount] = useState<any>([])
    const [loadEdit, setLoadEdit] = useState<boolean>(false)
    const [uncategorizedTransactions, setUncategorizedTransactions] =
        useState<number>()
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false)
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    )
    const [logoImage, setLogoImage] = useState<string | undefined>('')
    const [selectedTrip, setSelectedTrip] = useState<Trip>();
    
    const localClasses = useLocalStyles(colorTheme)
    const [errorAlert, setErrorAlert] = useState({
        show: false,
        message: '',
    })
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const accountName = store.reportsData?.reports?.account?.title

    useEffect(() => {
        setActiveRouteHeading(' ')
        return () => {
            dispatch(resetReportsData())
        }
    }, [setActiveRouteHeading])
    useEffect(() => {
        getAccountdata()
        dispatch(initCategories())
    }, [dispatch])
    useEffect(() => {
        setIsLoading(true)
        async function fetchData() {
            if (businessId) {
                await getBankAccountList(accountId, businessId).then(
                    (result: any) => {
                        setBankAccount(result)
                    }
                )
            }
        }
        fetchData()
        setIsLoading(false)
    }, [accountId, businessId])

    useEffect(() => {
        const oldText = 'for the period from'
        const newText = 'From'
        const formmatedText = periodText
            .replaceAll('+', ' ')
            .replace(oldText, newText)
        setFormattedPeriodText(formmatedText)
    }, [ymdEndDate, ymdStartDate])

    const handleError = useCallback((error: any) => {
        if (error.status === 400) {
            setErrorAlert({
                show: true,
                message: error?.statusText ?? '',
            })
        }
    }, [])

    useEffect(() => {
        const fetchTransactionReports = () => {
            setIsLoading(true)
            if (!businessId) return
            getTransactionsReport(
                accountId,
                businessId,
                selectedAccount,
                ymdStartDate,
                ymdEndDate,
                periodText,
                time
            )
                .then((result: unknown) => {
                    const reports = result as TransactionReportRoot
                    setReportsData(reports)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    showError(error?.statusText)
                    handleError(error)
                })
        }
        const reportsFromStore = store.reportsData.reports
        const reports = isEmpty(reportsFromStore)
            ? fetchTransactionReports()
            : reportsFromStore
        setReportsData(reports)
    }, [
        accountId,
        businessId,
        periodText,
        query,
        selectedAccount,
        store.reportsData.reports,
        time,
        ymdEndDate,
        ymdStartDate,
        handleError,
    ])

    useEffect(() => {
        const logo = reportsData?.business?.logo
        logo && setLogoImage(logo)
    }, [reportsData?.business?.logo])

    const getAccountdata = useCallback((): void => {
        setListData((prev: any) => ({ ...prev, loadingAccountRecord: true }))
        getAccount(accountId, businessId)
            .then((res: any) => {
                const parentCategories: any = {}
                const cache: any = {}
                res.forEach((caty: any) => {
                    if (caty.can_have_children) {
                        parentCategories[caty?.id] = caty
                    }
                    cache[caty?.id] = caty
                })
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: cache,
                    accountParentList: parentCategories,
                    loadingAccountRecord: false,
                }))
            })
            .catch((err) => {
                setListData((prev: any) => ({ ...prev, loading: false }))
            })
    }, [])

    const getTransactionsCount = (message: string): number => {
        return getNumberfromString(message)
    }

    useEffect(() => {
        const warning = reportsData?.warning
        if (!warning) return
        const count = getTransactionsCount(warning)
        setUncategorizedTransactions(count)
    }, [reportsData?.warning])

    const handleEditClick = (data: Transactions) => {
            setLoadEdit(true)
            const type =
                data.type === transactionType.TRIP 
                    ? transactionType.TRIP
                    : data.type === transactionType.JOURNAL_ENTRY
                      ? transactionType.JOURNAL_ENTRY
                      : transactionType.TRANSACTION
            let api =
                data.type === transactionType.JOURNAL_ENTRY
                    ? getJournalById
                    : getTransactionDetailsById

            if (type === transactionType.TRIP) {
                getTripDetails(data.id).then((result: any) => {
                    setSelectedTrip(result)
                    setLoadEdit(false)
                    setOpenModal(type)
                })
                .catch((error) => {
                    setLoadEdit(false)
                    showError(error?.statusText)
                    setIsLoading(false)
                })
            } else {
                api(accountId, businessId, data.id)
                .then((result: any) => {
                    setSelectedReport(result)
                    setLoadEdit(false)
                    setOpenModal(type)
                })
                .catch((error) => {
                    setLoadEdit(false)
                    showError(error?.statusText)
                    setIsLoading(false)
                })
            }
    }
    const refreshTransactions = () => {
        setIsLoading(true)
        if (!businessId) return
        getTransactionsReport(
            accountId,
            businessId,
            selectedAccount,
            ymdStartDate,
            ymdEndDate,
            periodText,
            time
        )
            .then((result: unknown) => {
                const reports = result as TransactionReportRoot
                setReportsData(reports)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error?.statusText)
                handleError(error)
                const oldReportData = {
                    ...reportsData,
                } as TransactionReportRoot
                if (oldReportData?.account && error.status === 400) {
                    oldReportData.account.transactions = []
                    setReportsData(oldReportData)
                }
            })
    }
    const handleSaveToPortal = (
        values: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const requestData = {
            start_date_ymd:
                ymdStartDate === EMPTY_PARAMS.START_DATE_YMD
                    ? ''
                    : ymdStartDate,
            end_date_ymd:
                ymdEndDate === EMPTY_PARAMS.END_DATE_YMD ? '' : ymdEndDate,
            period_text: periodText.replaceAll('+', ' '),
            time,
            comment: values.comment,
            account: selectedAccount,
        }
        saveTransactionReport(accountId, businessId, fileType, requestData)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    )
                    closeModal()
                    setIsSaveSnackbar(true)
                })
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error?.statusText)
            })
    }
    const formatDate = (date: number) => {
        const format = 'DD MMM yyyy'
        const dateInMilliSecs = date * 1000
        return convertUnixToGivenDateFormat(format, dateInMilliSecs)
    }
    const generateReportsTable = (items: Transactions[] | undefined) => {
        if (!items) return
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Date
                            </UiText>
                        </TableCell>
                        <TableCell align="right">
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Ref #
                            </UiText>
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Type
                            </UiText>
                        </TableCell>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Customer Name
                            </UiText>
                        </TableCell>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Category
                            </UiText>
                        </TableCell>
                        <TableCell align="right">
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Amount
                            </UiText>
                        </TableCell>
                        <TableCell align="right">
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Balance
                            </UiText>
                        </TableCell>
                        <TableCell align="right">{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((transaction: Transactions, index: number) => {
                        return (
                            <TableRow key={`${index}__`}>
                                <TableCell>
                                    {formatDate(transaction.date)}
                                </TableCell>
                                <TableCell align="right">
                                    <span>
                                        {transaction.number
                                            ? `# ${transaction.number}`
                                            : ''}
                                    </span>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={localClasses.descriptionValue}
                                >
                                    {transaction?.description ? (
                                        <InfoTooltip
                                            tooltipText={
                                                transaction?.description
                                            }
                                            customNode={
                                                <IconButton aria-label="info">
                                                    <InfoOutlined />
                                                </IconButton>
                                            }
                                        />
                                    ) : (
                                        <span
                                            className={localClasses.hideTooltip}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>{transaction.type}</TableCell>
                                <TableCell>
                                    {transaction.customer_name}
                                </TableCell>
                                <TableCell>{transaction.category}</TableCell>
                                <TableCell align="right">
                                    <span
                                        className={
                                            parseFloat(transaction.amount) < 0
                                                ? localClasses.priceWaste
                                                : ''
                                        }
                                    >
                                        {currencyFormatter.format(
                                            parseFloat(transaction.amount)
                                        )}
                                    </span>
                                </TableCell>
                                <TableCell align="right">
                                    <span
                                        className={
                                            parseFloat(transaction.balance) < 0
                                                ? localClasses.priceWaste
                                                : ''
                                        }
                                    >
                                        {currencyFormatter.format(
                                            parseFloat(transaction.balance)
                                        )}
                                    </span>
                                </TableCell>
                                {transaction.type !==
                                    transactionType.CLOSING_ENTRY && (
                                    <TableCell align="right">
                                        <IconButton
                                            disabled={loadEdit}
                                            onClick={() =>
                                                handleEditClick(transaction)
                                            }
                                        >
                                            <img alt={`Edit`} src={EditIcon} />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        )
    }

    const setExcludeSingleEntry = (data: any) => {
        businessId &&
            excludeTransaction(accountId, businessId, data.id, {
                excluded: true,
            })
                .then(() => {
                    setOpenModal(null)
                    setReportsData(undefined)
                    refreshTransactions()
                })
                .catch((error) => {
                    showError(error?.statusText)
                })
    }
    const deleteTransaction = (data: any) => {
        deleteTransactionById(accountId, businessId, data?.id)
            .then(() => {
                setOpenModal(null)
                setReportsData(undefined)
                refreshTransactions()
            })
            .catch((error) => {
                showError(error?.statusText)
            })
    }

    const deleteJournalEntry = (id: string) => {
        id &&
            deleteJournalById(accountId, businessId, id)
                .then(() => {
                    setOpenModal(null)
                    refreshTransactions()
                })
                .catch((error) => {
                    showError(error)
                })
    }
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false)
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath)
    }

    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText={'Transactions by Account'}
            />
            {uncategorizedTransactions && (
                <UncategorizedTransactionsAlert
                    transactionsCount={uncategorizedTransactions}
                />
            )}
            <div className={reportsClasses.reportsCommonHeader}>
                <div className="backLinkAndBtn">
                    <Link
                        data-cy="generate-new-report-btn"
                        to="/reports?name=transactions"
                    >
                        <LeftArrowIcon color={colorTheme.blue200} />
                        <span>Generate New Report</span>
                    </Link>
                    <SaveReports
                        businessName={reportsData?.business?.name ?? ''}
                        downloadReportPDFUrl={downloadTransactionReportPDF(
                            accountId,
                            businessId,
                            selectedAccount,
                            ymdStartDate,
                            ymdEndDate,
                            periodText,
                            time
                        )}
                        downloadReportCSVUrl={downloadTransactionReportCSV(
                            accountId,
                            businessId,
                            selectedAccount,
                            ymdStartDate,
                            ymdEndDate,
                            periodText,
                            time
                        )}
                        options={saveOptions}
                        handleSaveToPortal={handleSaveToPortal}
                        reportName={REPORTS_MENU_VIEWS.TRANSACTIONS_BY_ACCOUNT}
                        isDisabled={!reportsData?.account?.transactions?.length}
                    />
                </div>

                <div className="headerTextAndBusinessNameLogo">
                    <div>
                        <h2>Transactions by Account</h2>
                        <span>{`${
                            accountName || reportsData?.account?.title
                        } ${formattedPeriodText}`}</span>
                    </div>
                    <BusinessNameAndLogo
                        logoFromApi={logoImage}
                        accountId={accountId}
                        businessName={businessName!}
                    />
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : errorAlert?.show ? (
                <ErrorAlert message={errorAlert?.message} />
            ) : (
                <div className={reportsClasses.reportDetailsContent}>
                    {reportsData && (
                        <div className={localClasses.totalPaid}>
                            <span> Total </span>
                            <UiText weight="semi_bold_600">
                                <span
                                    className={
                                        parseFloat(
                                            reportsData?.account?.balance ?? '0'
                                        ) < 0
                                            ? localClasses.priceWaste
                                            : ''
                                    }
                                >
                                    {reportsData?.account?.balance &&
                                        currencyFormatter.format(
                                            parseFloat(
                                                reportsData?.account?.balance
                                            )
                                        )}{' '}
                                </span>
                            </UiText>
                        </div>
                    )}
                    {generateReportsTable(reportsData?.account.transactions)}
                </div>
            )}
            <NewTransactionModal
                open={openModal === transactionType.TRANSACTION}
                handleClose={() => setOpenModal(null)}
                isEdit={true}
                deleteData={{ deleteHandleOption: deleteTransaction }}
                selectedData={selectedReport}
                accountList={bankAccount}
                selectedAccount={{ account: selectedAccount }}
                setExcludeSingleEntry={setExcludeSingleEntry}
                refreshTransactions={refreshTransactions}
            />
            {selectedReport && (
                <NewJournalModal
                    open={openModal === transactionType.JOURNAL_ENTRY}
                    handleClose={() => setOpenModal(null)}
                    datasource={listData}
                    getJournalEntry={refreshTransactions}
                    setListData={setListData}
                    isEdit
                    deleteData={{
                        deleteHandleOption: deleteJournalEntry,
                    }}
                    selectedData={selectedReport}
                />
            )}
            <TripOverlayForm
                isOpen={openModal === transactionType.TRIP}
                trip={selectedTrip}
                handleClose={() => setOpenModal(null)}
                submitCallback={refreshTransactions}
                isNew={false}
            />
            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage="View Document"
                closeMessage="view"
            />
        </div>
    )
}
