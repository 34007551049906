import { Action, LOADING_PRODUCTS, SET_PRODUCTS } from '../actions/products';

export default function product(
    state = { loading: false, loaded: false, products: [] },
    action: Action
): any {
    switch (action.type) {
        case SET_PRODUCTS:
            return action.state;
        case LOADING_PRODUCTS:
            return { ...state, loading: true };
        default:
            return state;
    }
}
