import { useMediaQuery, useTheme } from '@material-ui/core'
import UiDialog from '../../../../common/ui/UiDialog'
import NewtekMerchantAccountForm from './NewtekMerchantAccountForm'

interface MerchantAccountModalProps {
    open: boolean
    handleClose: (param: boolean) => void
    openSuccessModal: () => void
    handleGetEmbedUrl: (result?: any, action?: any) => void;
}

const MerchantAccountModal = ({
    open,
    handleClose,
    openSuccessModal,
    handleGetEmbedUrl
}: MerchantAccountModalProps) => {
    const theme = useTheme()
    const isXsDevice = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <UiDialog
            open={open}
            handleClose={() => {
                handleClose(false);
                handleGetEmbedUrl('');
            }}
            title="Apply for Merchant Processing"
            size="sm"
            fullScreen={isXsDevice}
            contentStyles={isXsDevice ? {} : { maxHeight: '600px' }}
            disableEnforceFocus={true}
        >
            <NewtekMerchantAccountForm
                handleClose={handleClose}
                handleGetEmbedUrl={handleGetEmbedUrl}
            />
        </UiDialog>
    )
}

export default MerchantAccountModal
