import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import Products from '../bookkeeping/products-services/Products'
import ProductPreview from '../bookkeeping/products-services/ProductPreview'
import { ProductServicesProvider } from '../bookkeeping/products-services/ProductServicesProvider'
import InvoiceProvider from '../bookkeeping/invoices/InvoiceProvider';

export default function ProductServicesRoutes() {
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()

    return (
        <InvoiceProvider>
            <ProductServicesProvider>
                <Switch>
                    <Fragment>
                        <Hidden mdUp>
                            <Route path="/products" exact>
                                <Products />
                            </Route>
                            <Route path={`/products/:id`} exact>
                                <Container className={classes.container} maxWidth="lg">
                                    <ProductPreview />
                                </Container>
                            </Route>
                        </Hidden>
                        <Hidden smDown>
                            <Route path="/products">
                                <Container className={classes.container} maxWidth="lg">
                                    <Products />
                                </Container>
                            </Route>
                        </Hidden>
                    </Fragment>
                </Switch>
            </ProductServicesProvider>
        </InvoiceProvider>
    )
}