import {
    postResource, deleteResource, getRequestCancelTokenSrc
} from "../axiosService";
var cancelTokens: any;
export function scanReceiptFiles(account_id: string, business_id: string, data: any) {
    const cancelToken = getRequestCancelTokenSrc();
    cancelTokens = { ...cancelToken, 'fileScanApi': cancelToken };
    return postResource(
        `api/account/${account_id}/business/${business_id}/file_center/file_scan`, data, cancelToken.token
    );
}
export function createJournalEntiesFromReceipts(account_id: string, business_id: string, data: any) {
    return postResource(
        `api/account/${account_id}/business/${business_id}/receipt_journal_entry`, data
    );
}
export function bulkDeleteDocuments(account_id: string, business_id: string, data: any) {
    return deleteResource(
        `api/account/${account_id}/file_center/file/bulkDelete`, data
    );
}
export function cancelScanAPI() {
    if (cancelTokens && cancelTokens.hasOwnProperty('fileScanApi')) {
        cancelTokens['fileScanApi'].cancel();
    }
}