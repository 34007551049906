import React, { useCallback } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { format, getDate } from 'date-fns'
import { Event } from '../../../models'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import { ReactComponent as PeopleAltImage } from '../../../assets/icons-svg/PeopleAlt.svg'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

interface EventsTileProps {
    events: Event[]
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        eventsDeadlinesContainer: {
            display: 'grid',
            gridTemplateColumns: `${toRem16(52)} 1fr`,
        },
        eventsDetailsContainer: {
            border: colorTheme => `${toRem16(1)} solid ${colorTheme.grey300}`,
            borderRadius: toRem16(8),
            padding: '1rem',
            margin: '0 0 0.5rem 0',
            height: toRem16(76),
        },
        combinedContainer: {
            marginBottom: toRem16(12),
        },
        appointmentIconSection: {
            display: 'flex',
            background: colorTheme => colorTheme.beige100,
            width: 'fit-content',
            borderRadius: toRem16(24),
            alignItems: 'center',
            padding: `${toRem16(4)} 0.5rem`,
        },
        appointmentTime: {
            color: colorTheme => colorTheme.grey400,
            marginLeft: toRem16(4),
        },
        appointmentTitleText: {
            color: colorTheme => colorTheme.grey400,
            marginLeft: toRem16(4),
        },
        appointmentMonth: {
            margin: `0 0 ${toRem16(4)} 0`,
        },
        appointmentTitle: {
            margin: `${toRem16(4)} 0 0 ${toRem16(4)}`,
        },
    })
)

const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]
const SCHEDULED_TIME_FORMAT = 'hh:mm a'

const EVENT_TYPES = {
    APPOINTMENT: 'appointment',
}
export default function EventsTile(props: EventsTileProps) {
    const { events } = props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)

    const convertTimeStampToDate = (startDate: number): Date => {
        return new Date(startDate * 1000)
    }

    const getScheduledDay = (startDate: number): number => {
        const date = convertTimeStampToDate(startDate)
        return getDate(date)
    }
    const getScheduledTime = (startDate: number): string => {
        const date = convertTimeStampToDate(startDate)
        return format(date, SCHEDULED_TIME_FORMAT)
    }
    const renderAppointTypeContainer = (type: string) => {
        if (type === EVENT_TYPES.APPOINTMENT) {
            return (
                <div className={classes.appointmentIconSection}>
                    <PeopleAltImage />
                    <UiText
                        weight="medium_500"
                        variant="motorcycle_90"
                        className={classes.appointmentTitleText}
                    >
                        &nbsp;Appointment
                    </UiText>
                </div>
            )
        }
    }

    const getDateWiseKey = useCallback((startDate: number): string => {
        const date = convertTimeStampToDate(startDate)
        const day = date.getDate()
        const year = date.getFullYear()
        const month = date.getMonth()
        return `${day}-${MONTHS[month]}-${year}`
    }, [])

    const combinedDaywiseAppointments = useCallback(() => {
        let appointments: any = {}
        events.map((row: Event, index: number) => {
            const key = getDateWiseKey(row.start_date)
            if (appointments[key]) {
                appointments[key].push(row)
            } else {
                appointments[key] = [row]
            }
        })
        return appointments
    }, [events, getDateWiseKey])

    const AppointmentTile = ({
        row,
        index,
        month,
    }: {
        row: any
        index: number
        month: string
    }) => {
        return (
            <div className={classes.eventsDeadlinesContainer} key={index}>
                <div>
                    {index === 0 && (
                        <>
                            <div>
                            <UiText className={classes.appointmentMonth}>
                                {month}
                                
                            </UiText>
                            <UiText variant="suv_150" weight="semi_bold_600">
                                    {getScheduledDay(row.start_date)}
                                </UiText>
                        </div>

                        </>
                        
                    )}
                </div>
                <div className={classes.eventsDetailsContainer}>
                    {renderAppointTypeContainer(row.type)}
                    <UiText className={classes.appointmentTitle}>
                        {row.appointment_title}
                    </UiText>
                    <UiText
                        variant="motorcycle_90"
                        className={classes.appointmentTime}
                        textColor="textSecondary"
                    >
                        {getScheduledTime(row.start_date)}
                    </UiText>
                </div>
            </div>
        )
    }

    const Tiles = () => {
        const dayWiseAppointments = combinedDaywiseAppointments()
        const appointments = Object.keys(dayWiseAppointments)
        return appointments.map((combined, index) => {
            const Month = combined.split('-')[1]
            const lastIndex  = (appointments.length - 1) === index
            return (
                <div className={lastIndex ? '' : classes.combinedContainer} key={index}>
                    {dayWiseAppointments[combined].map(
                        (row: any, index: number) => (
                            <AppointmentTile
                                key={`${Month}-${index}`}
                                index={index}
                                row={row}
                                month={Month}
                            />
                        )
                    )}
                </div>
            )
        })
    }

    return <>{Tiles()}</>
}