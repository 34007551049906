export const SET_API_ERROR = 'SET_API_ERROR';

export interface errorData {
    errorCode: number;
    showError: boolean;
}
export interface ApiErrorAction {
    type: string;
    state: errorState;
}
export type errorState = errorData;


export const showError = (data: errorData): ApiErrorAction => ({
    type: SET_API_ERROR,
    state: data,
});

export const SET_ALERT = 'SET_ALERT';

export interface AlertData {
    alertType: 'success' | 'info' | 'warning' | 'error' | undefined;
    alertText: string;
}

export interface AlertAction {
    type: string;
    state: AlertData;
}

export const showAlert = (data: AlertData): AlertAction => ({
    type: SET_ALERT,
    state: data,
}); 




export const SET_INFO = 'SET_INFO';

export interface InfoData {
   infoData: string  | React.ReactNode
}

export interface InfoAction {
    type: string;
    state: InfoData;
}

export const showInfo = (data: InfoData): InfoAction => ({
    type: SET_INFO,
    state: data,
}); 

export type Action = ApiErrorAction | AlertAction | InfoAction;