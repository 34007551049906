import { TextField } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import React from 'react'

interface Props {
    showFloatingLabel: boolean
    label: string
    fieldName: string
    maxLength: number
}
const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;

export const VINInput = (props: Props) => {
    const { showFloatingLabel, label, fieldName, maxLength } = props

    const getVINInput = ({
        field,
        form,
        meta,
    }: FieldProps<string, any>) => {
        return (
            <TextField
                fullWidth
                label={showFloatingLabel && label}
                size='small'
                variant='outlined'
                inputProps={{
                    ...field,
                    maxLength,
                    onBlur: (event) => {
                        field?.onBlur && field.onBlur(event);
                        form?.setFieldValue &&
                            form.setFieldValue(
                                fieldName,
                                event.target.value?.trim()
                            );
                    },
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        const pattern = new RegExp(ALPHANUMERIC_REGEX);
                        if (pattern.test(event.target.value) || event.target.value === '') {
                            field?.onBlur && field.onBlur(event);
                            form?.setFieldValue &&
                                form.setFieldValue(
                                    fieldName,
                                    event.target.value?.trim()
                                );
                        }
                    },
                }}
                error={
                    (meta.touched || form.submitCount > 0) &&
                    (meta.error ? true : false)
                }
                helperText={
                    meta.touched || form.submitCount > 0
                        ? meta.error || ''
                        : ''
                }
            />
        )
    }

    return (
        <Field name={fieldName}>
            {(props: FieldProps<string, any>) =>
                getVINInput({ ...props })
            }
        </Field>
    )
}
