import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    createFile,
    getGeneralJournalCSVDownloadUrl,
    getGeneralJournalPDFDownloadUrl,
    getGeneralJournalReports,
    saveGeneralJournalCSVReportToPortal,
    saveGeneralJournalPDFReportToPortal,
} from '../../../../services/apiService/reports';
import { showError } from '../../../../services/formService';
import {
    currencyFormatter,
    getNumberfromString,
    isEmpty,
} from '../../../../utils/appUtil';
import Loader from '../../../common/Loader';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore';
import { DOCUMENT_DOWNLOAD_SUCCESS, EMPTY_PARAMS, saveOptions, REPORTS_MENU_VIEWS } from '../constants/reports.const';
import {
    Amount,
    DownloadModalFormData,
    DOWNLOAD_REPORT_TYPES,
    GeneralJournalReport,
    SaveJournal,
    SaveReportsToPortalData,
    Transaction,
} from '../models/reports-interface';
import { useReportsStyles } from '../styles/reports-styles';
import ErrorAlert from '../utils/Alerts/ErrorAlert';
import SaveReports from '../utils/SaveReports/SaveReports';
import {
    Box,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import { formatDateDDMMYY } from '../../../../utils/dateUtil';
import InfoTooltip from '../../../common/InfoTooltip';
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo';
import UncategorizedTransactionsAlert from '../utils/Alerts/UncategorizedTransactionsAlert';
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert';
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider';
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading';
import { toRem16 } from '../../../../styles/commonStyles';
import UiText from '../../../common/ui/UiText';
import { InfoOutlined } from '@material-ui/icons';
import { resetReportsData } from '../../../../store/actions/reportsData';
import { LeftArrowIcon } from '../Icons/LeftArrowIcon';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../styles/models/Colors.interface';

interface QueryParams {
    startDateYmd: string;
    endDateYmd: string;
    periodText: string;
    time: number;
}
const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    accountName: {
        maxWidth: '11rem',
        width: '11rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: '2rem',
        whiteSpace: 'nowrap',
    },
    creditAndDebitAmount: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: '2rem',
        whiteSpace: 'nowrap'
    },
    creditAndDebitAmountValuesCell: {
        padding: '0 !important'
    },
    totalRow: {
        '& td':{
            fontWeight: 'bold',
            borderBottom: 'none',
            borderTop: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey800}`,
        }
    },
    tableContaier: {
        '& .MuiTableCell-head':{
            minWidth: toRem16(100)
        },
        
    },
    refNoValue : {
        verticalAlign: 'text-top',
        padding: `${toRem16(21)} 0 0.6rem 0 !important`,
        '& button':{
            margin: '0 0 0 0.5rem',
            padding: 0
        },
        '& div':{
            display: 'flex',
        alignItems: 'center'
        }
    },
    alignTop: {
        verticalAlign: 'text-top'
    },
    emptyCell: {
        minWidth: '1rem',
        width: '1rem'
    },
    subtotalDebitValue: {
        padding: '0 !important'
    },
    subtotalCreditValue:{
        padding: '0 !important'
    },
    referenceTooltip:{
        verticalAlign: 'baseline'
    }
}));

const TOOLTIP_TEXT =
    'The General Journal Report shows all categorized transactions, journal entries, closing entries, and entries related to a trip in the mileage tracker for the selected period of time.';

export default function GeneralJournalDetailedReport() {
    const query: QueryParams = useParams() as unknown as QueryParams;
    const { periodText, time, startDateYmd, endDateYmd } = query;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const store = useStore().getState();
    const currentStore = useCurrentStore();
    const accountId = currentStore.currentAccountId;
    const businessName = currentStore.currentBusiness.name;
    const businessId = currentStore.currentBusinessId!;
    const [journalReports, setJournalReports] =
        useState<GeneralJournalReport>();
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState<string>('');
    const [csvDownloadUrl, setCsvDownloadUrl] = useState<string>('');
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('');
    const history = useHistory();
    const dispatch = useDispatch();
    const localClasses = styles(colorTheme);
    const [uncategorizedTransactions, setUncategorizedTransactions] =
        useState<number>();
    const [logoImage, setLogoImage] = useState<string | undefined>('');
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false);
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    );
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading]);
    
    useEffect(() => {
        const oldText = 'for the period from';
        const newText = 'From';
        const formmatedText = periodText
            .replaceAll('+', ' ')
            .replace(oldText, newText);
        setFormattedPeriodText(formmatedText);
    }, [periodText]);

    const fetchAPIParams = useCallback(() => {
        const startDateYmdParam =
            startDateYmd === EMPTY_PARAMS.START_DATE_YMD
                ? ''
                : startDateYmd;
        const endDateYmdParam =
            endDateYmd === EMPTY_PARAMS.END_DATE_YMD ? '' : endDateYmd;
        return {
            startDateYmdParam,
            endDateYmdParam,
        };
    },[endDateYmd, startDateYmd])
    
    useEffect(() => {
        const fetchMileageReports = () => {
            const { startDateYmdParam, endDateYmdParam } = fetchAPIParams();
            setIsLoading(true);
            getGeneralJournalReports(
                accountId,
                businessId,
                periodText,
                time,
                startDateYmdParam,
                endDateYmdParam
            )
                .then((result: unknown) => {
                    setIsLoading(false);
                    const data = result as GeneralJournalReport;
                    setJournalReports(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    showError(error.statusText);
                });
        };

        const reportsFromStore = store.reportsData.reports;
        const reports = isEmpty(reportsFromStore)
            ? fetchMileageReports()
            : reportsFromStore;
        setJournalReports(reports);
    }, [accountId, businessId, endDateYmd, fetchAPIParams, periodText, startDateYmd, store.reportsData.reports, time]);

    useEffect(() => {
        const logo = journalReports?.business.logo;
        setLogoImage(logo);
    }, [journalReports?.business.logo]);

    useEffect(() => {
        const {
            startDateYmdParam,
            endDateYmdParam
        } = fetchAPIParams();
        const pdfUrl = getGeneralJournalPDFDownloadUrl(
            accountId,
            businessId,
            periodText,
            startDateYmdParam,
            endDateYmdParam,
            time
        );
        setPdfDownloadUrl(pdfUrl);
    }, [accountId, businessId, endDateYmd, fetchAPIParams, periodText, startDateYmd, time]);
    useEffect(() => {
        const {
            startDateYmdParam,
            endDateYmdParam
        } = fetchAPIParams();

        const csvUrl = getGeneralJournalCSVDownloadUrl(
            accountId,
            businessId,
            periodText,
            startDateYmdParam,
            endDateYmdParam,
            time
        );
        setCsvDownloadUrl(csvUrl);
    }, [accountId, businessId, endDateYmd, fetchAPIParams, periodText, startDateYmd, time]);

    const getTransactionsCount = (message: string): number => {
        return getNumberfromString(message);
    };

    useEffect(() => {
        const warning = journalReports?.warning;
        if (!warning) return;
        const count = getTransactionsCount(warning);
        setUncategorizedTransactions(count);
    }, [journalReports?.warning]);

    const createFileAtLocation = (location: string, closeModal: () => void) => {
        createFile(location)
            .then((result: any) => {
                const { path, account_id } = result.data;
                setSavedDocumentPath(
                    `/documents/folder?folder=${encodeURIComponent(
                        path
                    )}&accountId=${account_id}`
                );
                closeModal();
                setIsSaveSnackbar(true);
            })
            .catch((error) => {
                showError(error?.statusText);
            });
    };
    const savePDFReport = (
        requestData: SaveReportsToPortalData,
        closeModal: () => void
    ) => {
        saveGeneralJournalPDFReportToPortal(accountId, businessId, requestData)
            .then((response: unknown) => {
                const data = response as SaveJournal;
                createFileAtLocation(data.location, closeModal);
            })
            .catch((error) => {
                showError(error?.statusText);
            });
    };
    const saveCSVReport = (
        requestData: SaveReportsToPortalData,
        closeModal: () => void
    ) => {
        saveGeneralJournalCSVReportToPortal(accountId, businessId, requestData)
            .then((response: unknown) => {
                const data = response as SaveJournal;
                createFileAtLocation(data.location, closeModal);
            })
            .catch((error) => {
                showError(error?.statusText);
            });
    };
    const handleSaveReportToPortal = (
        data: DownloadModalFormData,
        fileType: string,
        closeModal: () => void
    ) => {
        const {
            startDateYmdParam,
            endDateYmdParam
        } = fetchAPIParams();

        const requestData = {
            comment: data?.comment ?? '',
            start_date_ymd: startDateYmdParam,
            end_date_ymd: endDateYmdParam,
            period_text: periodText,
            time,
        };

        if (fileType === DOWNLOAD_REPORT_TYPES.PDF) {
            savePDFReport(requestData, closeModal);
            return;
        }
        saveCSVReport(requestData, closeModal);
    };
    const generateReportsTable = (
        journalReport: GeneralJournalReport,
        transactions: Transaction[]
    ) => {
        return (
            <Box mt={10}>
                <Table className= {`${reportsClasses.reportsTable} ${localClasses.tableContaier}`}>
                    <TableHead>
                        <TableRow>
                            <TableCell > <UiText weight="semi_bold_600" variant="motorcycle_90">Date</UiText></TableCell>
                            <TableCell ><UiText weight="semi_bold_600" variant="motorcycle_90">Type</UiText></TableCell>
                            <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Ref #</UiText></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell ><UiText weight="semi_bold_600" variant="motorcycle_90">Category</UiText></TableCell>
                            <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Debit</UiText></TableCell>
                            <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Credit</UiText></TableCell>
                            <TableCell align="right" className={localClasses.emptyCell}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map(
                            (transaction: Transaction, index: number) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell className={localClasses.alignTop}>
                                            {formatDateDDMMYY(transaction.date)}
                                        </TableCell>
                                        <TableCell className={localClasses.alignTop}>
                                            {transaction.type}
                                        </TableCell>
                                        <TableCell className={localClasses.refNoValue} align='right'>
                                            {
                                                transaction.number && 
                                                <> #{transaction.number} 
    
                                                 </>   
                                            }
                                           
                                           
                                        </TableCell>
                                        <TableCell align="left" className={localClasses.referenceTooltip}>
                                        {transaction.description && (
                                                    <InfoTooltip
                                                        tooltipText={
                                                            transaction.description
                                                        }
                                                        customNode={
                                                            <IconButton aria-label="delete">
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        }
                                                    />
                                                )}
                                        </TableCell>
                                        <TableCell className={
                                                    localClasses.creditAndDebitAmountValuesCell
                                                }>
                                            {transaction.amounts.map(
                                                (
                                                    amount: Amount,
                                                    key: number
                                                ) => {
                                                    return (
                                                        <div key={key}>
                                                            <p
                                                                className={
                                                                    localClasses.accountName
                                                                }
                                                            >
                                                                {amount.account}{' '}
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            )}

                                            <h4
                                                className={
                                                    localClasses.creditAndDebitAmount
                                                }
                                            >
                                                {' '}
                                                Total:
                                            </h4>
                                        </TableCell>

                                        <TableCell align="right" className={
                                                    localClasses.creditAndDebitAmountValuesCell
                                                }>
                                            {transaction.amounts.map(
                                                (
                                                    amount: Amount,
                                                    key: number
                                                ) => {
                                                    return (
                                                        <div key={key}>
                                                            <p
                                                                className={
                                                                    localClasses.creditAndDebitAmount
                                                                }
                                                            >
                                                                {amount.debit
                                                                    ? currencyFormatter.format(
                                                                          parseFloat(
                                                                              amount.debit
                                                                          )
                                                                      )
                                                                    : '-'}
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <h4
                                                className={
                                                    localClasses.creditAndDebitAmount
                                                }
                                            >
                                                {currencyFormatter.format(
                                                    parseFloat(
                                                        transaction.total_debit
                                                    )
                                                )}
                                            </h4>
                                        </TableCell>
                                        <TableCell align="right">
                                            {transaction.amounts.map(
                                                (
                                                    amount: Amount,
                                                    key: number
                                                ) => {
                                                    return (
                                                        <div key={key}>
                                                            <p
                                                                className={
                                                                    localClasses.creditAndDebitAmount
                                                                }
                                                            >
                                                                {amount.credit
                                                                    ? currencyFormatter.format(
                                                                          parseFloat(
                                                                              amount.credit
                                                                          )
                                                                      )
                                                                    : '-'}
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <h4
                                                className={
                                                    localClasses.creditAndDebitAmount
                                                }
                                            >
                                                {currencyFormatter.format(
                                                    parseFloat(
                                                        transaction.total_credit
                                                    )
                                                )}
                                            </h4>
                                        </TableCell>
                                        <TableCell align="right" className={localClasses.emptyCell}></TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                    {generateTotalRow(journalReport)}
                </Table>
            </Box>
        );
    };
    const generateTotalRow = (journalReport: GeneralJournalReport) => {
        return (
            <TableBody>
                <TableRow className={localClasses.totalRow}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Total:</TableCell>
                    <TableCell align="right" className={localClasses.subtotalDebitValue}>
                        {currencyFormatter.format(
                            parseFloat(journalReport.subtotal_debit)
                        )}
                    </TableCell>
                    <TableCell align="right" className={localClasses.subtotalCreditValue}>
                        {currencyFormatter.format(
                            parseFloat(journalReport.subtotal_credit)
                        )}
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false);
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath);
    };

    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText="General Journal"
            />
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {!journalReports ? (
                        <ErrorAlert />
                    ) : (
                        <>
                            {uncategorizedTransactions && (
                                <UncategorizedTransactionsAlert
                                    transactionsCount={
                                        uncategorizedTransactions
                                    }
                                />
                            )}
                            <div className={reportsClasses.reportsCommonHeader}>
                                <div className="backLinkAndBtn">
                                    <Link data-cy='generate-new-report-btn' to="/reports?name=generalJournal">
                                    <LeftArrowIcon color={colorTheme.blue200} />
                                        <span>Generate New Report</span>
                                    </Link>
                                    <SaveReports
                                        businessName={businessName!}
                                        downloadReportPDFUrl={pdfDownloadUrl}
                                        downloadReportCSVUrl={csvDownloadUrl}
                                        options={saveOptions}
                                        handleSaveToPortal={
                                            handleSaveReportToPortal
                                        }
                                        reportName={REPORTS_MENU_VIEWS.GENERAL_JOURNAL}
                                        isDisabled={!journalReports.transactions.length}
                                    />
                                </div>
                                <div className="headerTextAndBusinessNameLogo">
                                    <div>
                                        <h2>General Journal</h2>
                                        <span>{` ${formattedPeriodText}`}</span>
                                    </div>
                                    <BusinessNameAndLogo
                                        logoFromApi={logoImage}
                                        accountId={accountId}
                                        businessName={businessName!}
                                    />
                                </div>
                            </div>
                            <div
                                className={reportsClasses.reportDetailsContent}
                            >
                                {generateReportsTable(
                                    journalReports,
                                    journalReports.transactions
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage="View Document"
                closeMessage="view"
            />
        </div>
    );
}
