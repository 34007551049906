import { Button, makeStyles, Theme, useTheme } from '@material-ui/core'
import React, { Fragment } from 'react';
import UiDialog from './UiDialog'
import ArchiveIcon from '../../../assets/icons-svg/Archive-White.svg'
import { CSSProperties } from '@material-ui/styles'

import Loader from '../../../components/common/Loader'
import { useThemeContext } from '../whiteLabel/ColorThemeContext'
interface ConfirmationDialogProps {
    message: any
    open: boolean
    handleClose: (result?: any) => void
    confirmButtonText?: string
    cancelButtonText?: string
    confirmNode?: React.ReactNode
    title?: string
    hideTitleSection?: boolean
    confirmButtonColor?: 'default' | 'inherit' | 'primary' | 'secondary'
    confirmButtonIcon?: any
    showFormActionDivider?: boolean
    alignButtons?: 'start' | 'center' | 'end'
    size?: 'xs' | 'sm' | 'md' | 'lg'
    showLoader?: boolean
    customDialogStyles?: any
    customRootClass?: any
    confirmButtonId?: string,
    cancelButtonId?: string,
}

export default function UiConfirmationDialog({
    message,
    open,
    handleClose,
    confirmButtonText = 'Yes',
    cancelButtonText = 'No',
    confirmNode,
    title = '',
    hideTitleSection = true,
    confirmButtonColor = 'secondary',
    confirmButtonIcon,
    showFormActionDivider = false,
    alignButtons = 'start',
    size = 'xs',
    showLoader,
    customDialogStyles,
    customRootClass,
    confirmButtonId,
    cancelButtonId
}: ConfirmationDialogProps) {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => ({
        formActions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
        },
        bodySection: {
            ght: 100,
        },
        cancelButton: {
            marginRight: theme.spacing(2),
            color: `${colorTheme.black100} !important`,
        },
        confirmButton: {
            backgroundColor: `${colorTheme.grey400} !important`,
        },
        showLoader: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
        },
    }))()
    const close = (result: boolean) => handleClose(result)

    const getStyle = () => {
        const styleObj: CSSProperties = {
            width: '100%',
            textAlign: alignButtons,
        }
        if (showFormActionDivider) {
            styleObj.borderTop = `1px solid ${colorTheme.grey200}`
            styleObj.paddingTop = theme.spacing(3)
        }
        return styleObj
    }

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title={title}
            size={size}
            hideTitleSection={hideTitleSection}
            customDialogStyles={customDialogStyles}
            customRootClass={customRootClass}
        >
            <Fragment>
                <div className={classes.bodySection}>{message}</div>
                <div
                    className={
                        showLoader ? classes.showLoader : classes.formActions
                    }
                >
                    {showLoader ? (
                        <Loader />
                    ) : (
                        <div style={getStyle()}>
                            <Button
                                variant="outlined"
                                onClick={() => close(false)}
                                className={classes.cancelButton}
                                data-cy={cancelButtonId}
                            >
                                {cancelButtonText}
                            </Button>
                            {confirmNode ? (
                                confirmNode
                            ) : (
                                <Button
                                    classes={{
                                        containedSecondary:
                                            classes.confirmButton,
                                    }}
                                    variant="contained"
                                    startIcon={
                                        confirmButtonIcon || (
                                            <img
                                                alt={`Private`}
                                                src={ArchiveIcon}
                                            />
                                        )
                                    }
                                    onClick={() => close(true)}
                                    color={confirmButtonColor}
                                    data-cy={confirmButtonId}
                                >
                                    {confirmButtonText}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </Fragment>
        </UiDialog>
    )
}
