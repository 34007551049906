export type StateTax = {
    title: string
    amount: number
    type: string
}

export const calculateSum = (data: StateTax[] | null) => {
    return data?.reduce((sum: number, obj: StateTax) => {
        if (obj.type === 'Owes') {
            sum = sum - Number(obj.amount)
        }
        if (obj.type === 'Refund') {
            sum = sum + Number(obj.amount)
        }
        if (obj.type === 'NA') {
            sum = sum + 0
        }
        return Number(sum)
    }, 0)
}
