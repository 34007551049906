import React, { Fragment, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
    Theme,
    makeStyles,
    Hidden,
    IconButton,
    Button
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Location } from '../../../../models/locations'
import Loader from '../../../common/Loader'
import LocationDetails from './LocationDetails'
import LocationModal from '../modal/LocationModal'
import MobileHeader from '../../../common/MobileHeader'
import { commonStyles, mergeStyle } from '../../../../styles/commonStyles';
import ConfirmLocationModal from '../modal/ConfirmLocationModal'
import { locationDetail } from '../../../../services/apiService/locations'
import { handleDeleteLocation } from '../actions'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import UiButton from '../../../common/ui/UiButton'
import Icon from '../../../../components/common/Icon'
import useLocationsHook from '../locations.hook'
import UiText from '../../../common/ui/UiText';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';

const useStyles = makeStyles((theme: Theme) => ({
    marginButton: {
        marginRight: '1rem',
    },
    headerButtons: {
        color: useThemeContext().colorTheme.black100,
    },
    previewLocation: {
        marginTop: theme.spacing(1),
    },
    editMobileButton: {
        color: useThemeContext().blue200 + ' !important',
        marginTop: '0.313rem',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
    },
    containerHeight: {
        marginTop: '1.563rem',
        maxHeight: 'calc(100vh - 12.5rem)',
        overflow: "auto"
    },
    header: {
        marginLeft: '2.5rem'
    },
    headerAndActionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '2rem',
        margin: `0 0 1rem 0`,
        position: 'sticky',
        left: 0,
        top: 0,
        background: useThemeContext().white,
        '& .actionButtons':{
            display: 'flex'
        }
    },
}))

function LocationPreview({
    selectedLocation,
    loadLocations,
    setLocationUpdated,
    locations,
    setSelectedLocation,
}: {
    selectedLocation?: Location | undefined
    setSelectedLocation?: React.Dispatch<Location | undefined>
    loadLocations?: () => void
    setLocationUpdated?: React.Dispatch<boolean>
    locations?: Location[]
}) {
    const styles = useStyles()
    const common = commonStyles()
    const theme = useTheme()
    const history = useHistory()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    const params: { id: string } = useParams()
    const [loading, setLoading] = useState(false)
    const [currentLocation, setCurrentLocation] = useState<Location>()
    const { currentBusinessId, currentAccountId } = useCurrentStore()
    const [deleting, setDeleting] = useState(false)
    const { 
        formikRef, 
        openModal, 
        setOpenModal, 
        handleLocationModalClose,
        showConfirmModal,
        setShowConfirmModal,
        isFormTouched,
        discardChanges,
        setCurrentLocations,
        setDiscardChanges
    } = useLocationsHook();

    const handleConfirmModal = (result: boolean) => {
        if (result === true && !discardChanges) {
            setDeleting(true)
            handleDeleteLocation(
                (selectedLocation?.id || currentLocation?.id) as string,
                (isError) => {
                    setDeleting(false)
                    setShowConfirmModal(false)
                    history.push('/mileage/locations');
                    loadLocations?.()
                    const deletedLocationId = selectedLocation?.id || currentLocation?.id;
                    const remainingLocations = locations?.filter(location => location.id !== deletedLocationId);
                    if(!isError){
                        setCurrentLocation(remainingLocations?.[0])
                        setSelectedLocation?.(remainingLocations?.[0])
                    }
                }
            )
        } else if(result === true && discardChanges) {
            setShowConfirmModal(false)
            setOpenModal(false)
        }else{
            setShowConfirmModal(false)
        }
    }

    useEffect(() => {
        if (params?.id && currentBusinessId && currentAccountId) {
            setLoading(true)
            locationDetail(params?.id).then(
                (res: Location | unknown) => {
                    if (res) {
                        setLoading(false)
                        setCurrentLocation(res as Location)
                    }
                }
            )
        }
    }, [currentAccountId, currentBusinessId, params?.id, setCurrentLocation])

    const location = selectedLocation || currentLocation;
    const setLocation = setSelectedLocation || setCurrentLocation;

    return (
        <Fragment>
            {!location || loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <LocationModal
                        open={openModal}
                        handleClose={handleLocationModalClose}
                        location={location}
                        setSelectedLocation={setLocation}
                        loadLocations={loadLocations}
                        setLocationUpdated={setLocationUpdated}
                        formikRef={formikRef}
                        isFormTouched={isFormTouched}
                    />
                    <ConfirmLocationModal
                        deleting={deleting}
                        showConfirmModal={showConfirmModal}
                        location={location}
                        handleClose={handleConfirmModal}
                        discardChanges={discardChanges}
                    />
                    <MobileHeader showBottomDivider={true}>
                        <div className={mergeStyle(common.flex, common.spaceBetween)}>
                            <div className={mergeStyle(common.flex, common.gap8, common.alignCenter)}>
                            <IconButton
                                onClick={() => history.push('/mileage/locations')}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <UiText variant="hatchback_125" weight="medium_500" className={common.capitalize}>
                                    {location?.name}
                            </UiText>
                            </div>
                            
                            <div className={mergeStyle(common.flex, common.gap8)}>
                            <UiButton
                                btnType="secondary"
                                label="Delete"
                                handleClick={() => {
                                    setDiscardChanges(false)
                                    setShowConfirmModal(true)
                                }}
                                customClass={
                                    styles.editMobileButton
                                }
                            />
                            <UiButton
                                btnType="secondary"
                                label="Edit"
                                handleClick={() => {
                                    setOpenModal(true);
                                }}
                                customClass={
                                    styles.editMobileButton
                                }
                            />
                            </div>
                        </div>
                    </MobileHeader>
                    <div className={styles.previewLocation}>
                        {/* Hidden for Mobile Devices */}
                        
                        <Hidden smDown>
                            <div className={styles.headerAndActionButtons}>
                                <UiText variant="suv_150" weight="medium_500" className={common.capitalize}>
                                    {location?.name}
                                </UiText>
                                <div className='actionButtons'>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Icon icon="delete" size="1.125rem" />}
                                        onClick={() => {
                                            setDiscardChanges(false)
                                            setShowConfirmModal(true)
                                        }}
                                        className={
                                            `${styles.headerButtons} 
                                            ${styles.marginButton}`
                                        }
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Icon icon="edit" size="1.125rem" />}
                                        onClick={() => {
                                            setCurrentLocations(locations!)
                                            setOpenModal(true);
                                        }}
                                        className={styles.headerButtons}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </Hidden>
                        <div className={styles.containerHeight}>
                            <LocationDetails
                                location={location}
                            />
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default LocationPreview