import React from 'react'
import useDeviceSize from '../../../hooks/useDeviceSize'
import usePermission from '../../../hooks/usePermissions'
import TaxAdvisoryCallAd from '../Cards/TaxAdvisoryCallAd'
import TaxEngagementPlanMobileHeader from './TaxEngagementMobileHeader'
import { TaxEngagementPlan } from './TaxEngagementPlan'

const TaxEngagement = () => {
    const { hasTaxAdvisoryPermission } = usePermission()
    const { isMobileDevice } = useDeviceSize()

    return (
        <React.Fragment>
            {hasTaxAdvisoryPermission ? (
                <>
                    {isMobileDevice ? (
                        <TaxEngagementPlanMobileHeader />
                    ) : (
                        <TaxEngagementPlan />
                    )}
                </>
            ) : (
                <TaxAdvisoryCallAd />
            )}
        </React.Fragment>
    )
}

export default TaxEngagement
