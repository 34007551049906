import { createStyles, makeStyles, Theme } from '@material-ui/core';
import UiChip from '../../../../common/ui/UiChip';
import UiFormControlSelection from '../../../../common/ui/UiFormControlSelection';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons-svg/Calendar.svg';

import UiText from '../../../../common/ui/UiText';
import { toRem16 } from '../../../../../styles/commonStyles';
import { Chip } from '../../SelectAccountAndDates';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';

const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        dateChips: {
            margin: '1.2rem 0 3rem 0',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1rem',
            '& .MuiChip-root':{
                margin: 0,
            }
        },
        selectDates: {
            margin: '2.2rem 0 1rem 0',
        },
        dateFrom: {
            display: 'flex',
            alignItems: 'center',
        },
        simpleChip: {
            background: (colorTheme) => colorTheme.primaryWhite,
            border: (colorTheme) =>  `${toRem16(1)} solid ${colorTheme.grey200}`,
            '&:hover': {
                background: (colorTheme) => colorTheme.grey600,
            },
            '&:focus': {
                background: (colorTheme) => colorTheme.grey600,
            },
        },
        selectedChip: {
            background: (colorTheme) => colorTheme.grey600,
            border: (colorTheme) => `${toRem16(2)} solid ${colorTheme.grey500}`,
            '&:hover': {
                background: (colorTheme) => colorTheme.grey600,
            },
            '&:focus': {
                background: (colorTheme) => colorTheme.grey600,
            },
        },
        dateTo: {
            margin: '0 0.625rem 0 0.625rem',
        },
    })
);

interface Props {
    chips: Chip[];
    selectedChip: string;
    handleUpdateDates: (data: Chip) => void;
    isHideFromAndToDates?: boolean;
    disableFutureDate?: boolean
}
export default function SelectDates({
    chips,
    handleUpdateDates,
    selectedChip,
    isHideFromAndToDates,
    disableFutureDate = false,
}: Props) {
    const { colorTheme } = useThemeContext()
    const localClasses = useLocalStyles(colorTheme);

    return (
        <div>
            <div className={localClasses.selectDates}>
                <UiText weight='medium_500'>Select a period of time</UiText>
            </div>

           
                {!isHideFromAndToDates && (
                     <div className={`${localClasses.dateFrom} calendarSection`}>
                        <UiFormControlSelection
                            label='From'
                            type='date'
                            fieldName='from'
                            dateType='string'
                            showFloatingLabel={true}
                            disableFutureDate={disableFutureDate}
                            endIcon={<CalendarIcon />}
                        />
                        <div className={localClasses.dateTo}>{' - '}</div>
                        <UiFormControlSelection
                            label='To'
                            type='date'
                            fieldName='to'
                            dateType='string'
                            showFloatingLabel={true}
                            disableFutureDate={disableFutureDate}
                            endIcon={<CalendarIcon />}
                        />
                     </div>
                )}
           

            <div className={localClasses.dateChips}>
                {chips.map((chip: any, index: number) => {
                    return (
                        <UiChip
                            key={`${index}_`}
                            label={
                                <UiText variant='motorcycle_90'>
                                    {chip.for}
                                </UiText>
                            }
                            onClickCallout={() => {
                                handleUpdateDates(chip);
                            }}
                            customRootStyles={
                                selectedChip === chip.for
                                    ? localClasses.selectedChip
                                    : localClasses.simpleChip
                            }
                            cypressId={`${chip.for
                                .toLocaleLowerCase()
                                .replace(' ', '-')}-chip-btn`}
                        />
                    );
                })}
            </div>
        </div>
    );
}
