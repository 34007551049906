import { makeStyles, Theme } from '@material-ui/core'
import BgImage from '../assets/bg-images/bg.jpg'
import { ThemeColors } from './models/Colors.interface'
import { COLORS } from '../variables/colors'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        rootDiv: {
            display: 'flex',
            width: '100%',
            height: '100%',
            minHeight: 0,
            minWidth: 0,
            overflow: 'auto',
        },
        headerAndContent: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            flex: 1,
            minWidth: 0,
        },
        drawerAndHeaderContainer: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            minHeight: 0,
            minWidth: 0,
            flex: 1,
        },
        leftNavigationAndContent: {
            display: 'flex',
            flex: 1,
            minHeight: 0,
            height: '100%',
            position: 'relative',
            minWidth: 0,
        },
        loaderPageDiv: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            backgroundSize: 'cover',
            background: `linear-gradient(${COLORS.grey1400}, ${COLORS.grey1400} ),url(${BgImage}) no-repeat center center fixed`,
        },
        loaderOverlay: {
            display: 'flex',
            position: 'fixed',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 9999,
            background: '#00000080', // to do

            '& > div': {
                background: 'white',
                padding: theme.spacing(4),
                borderRadius: '8px',
            },
        },
        dashboardTextLink: {
            color: theme.palette.text.primary + '!important',
            cursor: 'pointer',
            textDecorationColor: theme.palette.text.primary + '!important',
        },
        nobr: {
            whiteSpace: 'nowrap',
        },
        alertNoteGrey: {
            backgroundColor: (colorTheme) =>
                colorTheme.grey1200 + ' !important',
            color: (colorTheme) => colorTheme.primaryBlack + ' !important',
        },
        fileTypeIconStyle: {
            verticalAlign: 'middle',
            marginRight: '10px',
        },
        linkPointer: {
            cursor: 'pointer',
        },
        linkText: {
            color: (colorTheme) => colorTheme.blue600,
            cursor: 'pointer',
        },
        pullRight: {
            float: 'right',
        },
        pullLeft: {
            float: 'left',
        },
        pageDesc: {
            fontSize: '1.25rem',
        },
    }
})
