import { CancelToken } from 'axios';
import { Notification, Product, Referral } from '../../models';
import { getResource, patchResource, postResource } from '../axiosService';

export function portalDetailsGET() {
    return getResource('api/user/current/portal');
}

export function getApiConfig<T>() {
    return getResource<T>('config');
}

export function getPermissionStatus() {
    return getResource(`api/user/current/check_first_business_permission`);
}

export function getProducts(account_id: string) {
    return getResource<Product[]>(`api/account/${account_id}/sales/product`);
}

export function getPaymentUrl(
    account_id: string,
    body: any,
    cancelToken?: CancelToken
) {
    return postResource(
        `/api/account/${account_id}/charge_over/get_payment_url`,
        body,
        cancelToken
    );
}

export function getNotificationsAndMessagesCount<T>(accountId: string) {
    return getResource<T>(`api/navigation/account/${accountId}/counts`);
}

export function getAllNotifications(accountId: string) {
    return getResource<Notification[]>(
        `api/account/${accountId}/communication/notification/all`
    );
}

export function markNotificationsAsRead(accountId: string, id: string[]) {
    return patchResource(
        `api/account/${accountId}/communication/notification`,
        {
            filters: {
                id,
            },
            is_read: true,
        }
    );
}

export function getCategories(account_id: string, business_id: string) {
    return getResource(
        `api/account/${account_id}/business/${business_id}/account`
    );
}

export function registerReferral(
    data: Referral
) {
    const api = `api/sfreferral/register`
    return postResource(api, data)
}

export function getProductBySfId(body: any) {
    return postResource(`/api/sf_price_products`, body);
}