import * as React from "react";
import {
  makeStyles,
  OutlinedInput,
  InputAdornment,
  Theme,
} from "@material-ui/core";
import { Cancel, Search } from "@material-ui/icons";

interface SearchBoxProps {
  searchTerm: string;
  placeHolder: string;
  fullWidth?: boolean;
  notched?: boolean;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  onKeyPressEvent: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  clearSearch: () => void;
  onChangeCallback?: (value: string) => void;
  cypressId?: string
}

const SearchBox = ({
  searchTerm,
  placeHolder,
  setInput,
  notched = false,
  fullWidth = true,
  clearSearch,
  onKeyPressEvent,
  onChangeCallback,
  cypressId
}: SearchBoxProps) => {
   const styles = makeStyles((theme: Theme) => ({
        searchInput: {
        height: "40px",
        width: "100%",
        },
    }))();
    return (
        <OutlinedInput
            id={cypressId}
            notched={notched}
            onChange={
                (event)=>{
                    setInput(event.target.value)
                    onChangeCallback && onChangeCallback(event.target.value)
                 }
             }
            value={searchTerm}
            fullWidth={fullWidth}
            classes={{
                root: styles.searchInput,
            }}
            placeholder={placeHolder}
            startAdornment={
                <InputAdornment position="start">
                <Search />
                </InputAdornment>
            }
            endAdornment={
                searchTerm ? (
                <InputAdornment
                    position="end"
                    onClick={clearSearch}
                >
                    <Cancel color="secondary" className="cursor-pointer" />
                </InputAdornment>
                ) : null
            }
            onKeyPress={(event) => onKeyPressEvent(event)}
        />
    )
};

export default SearchBox;
