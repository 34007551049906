import { Fragment, useState } from 'react';
import { Theme, makeStyles, Box, Typography, Hidden, Button, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import UiDialog from '../../common/ui/UiDialog'
import { ApplicationStore, AppData } from '../../../models'
import { Formik, Form, FormikHelpers } from 'formik'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import Loader from '../../common/Loader'
import * as Yup from 'yup'
import { formSubmitErrorHandler } from '../../../services/formService'
import CloseIcon from "@material-ui/icons/Close";
import ThumpsUpIcon from '../../../assets/icons-svg/ThumpsUp.svg';
import { registerReferral } from '../../../services/apiService/common'
import { toRem16 } from '../../../styles/commonStyles'

interface ReferralModalProps {
    open: boolean
    handleClose: (result?: boolean) => void
    user: string
    sf_id: string
}

function ReferralModal({
    open,
    handleClose,
    user,
    sf_id
}: ReferralModalProps, props: { appData: AppData }) {
    const useStyles = makeStyles((theme: Theme) => ({
        okayBtn: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        TextCenter: {
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
            },
        },
        buttonSubmit: {
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            padding: `${toRem16(10)} ${toRem16(0)}`,
            marginTop: 5,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        closeModalIcon: {
            marginTop: `${toRem16(-10)}`,
            marginLeft: `${toRem16(500)}`
        },
        title: {
            marginBottom: 24,
            fontWeight: 'bold'
        },
        modalContentContainer: {
            '& .MuiDialogTitle-root': {
                borderBottom: 'none',
                padding: `${toRem16(15)} ${toRem16(45)} ${toRem16(0)}`
            }
        },
        fieldContainer: {
            padding: '1rem 0rem',
        },
        loading: {
            height: `${toRem16(400)}`
        }
    }))
    const styles = useStyles()
    const [loading, setLoading] = useState(false)
    const [thanksMode, setThanksMode] = useState(false)
    const mask = [
        '(',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
    ];

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required')
    })

    const handleCloseReferralModal = () => {
        setThanksMode(false);
        handleClose(true)
    };

    const onSubmit = (data: any, form: FormikHelpers<any>) => {
        let formValues = {
            BusinessName    :  data.company_name,
            FirstName       :  data.first_name,
            LastName        :  data.last_name,
            Email           :  data.email,
            Phone           :  data.phone,
            ReferContactID  :  sf_id,
            ReferPortalUser :  user
        }
        setLoading(true);
        formSubmitErrorHandler(
            registerReferral(formValues).then(
                (res: any) => {
                    if (res.code === 200) {
                        form.setSubmitting(false)
                        setThanksMode(true);
                    }
                    setLoading(false);
                }
            ),
            () => {
                form.setSubmitting(false);
                setLoading(false);
            }
        )
    };

    return (
        <UiDialog
            open={open}
            handleClose={() => {
                handleClose(true);
            }}
            size="sm"
            customRootClass={styles.modalContentContainer}
            titleNode={
                <IconButton 
                            aria-label="close" 
                            onClick={() =>  handleCloseReferralModal()}
                            className={styles.closeModalIcon}
                        >
                    <CloseIcon />
                </IconButton>
            }
        >
        {
            (open && !thanksMode) && (
                <>
                <Typography
                    variant='h6'
                    className={styles.title}
                >
                    Refer a colleague and earn a $100 credit
                </Typography>
                <Typography
                    variant='body1'
                >
                    Submit the information below and we’ll contact your referral to discuss their tax savings options.
                </Typography>
                <br></br>
                <Typography
                    variant='body1'
                >
                    For every business you refer that signs up for business tax preparation, we’ll credit your account $100!*
                </Typography>
                <Formik
                    initialValues={{}}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    enableReinitialize={true}
                >
                    {(formik) => {
                        return (
                            <>
                                <Form>
                                    
                                    <Box className={styles.fieldContainer}>
                                        <UiFormControlSelection
                                           label='First name (Required)'
                                           type='text'
                                           fieldName='first_name'
                                           required={true}
                                           errorMessage='Required'
                                           showFloatingLabel={true}
                                        />
                                    </Box>
                                    <Box className={styles.fieldContainer}>
                                        
                                            <UiFormControlSelection
                                               label='Last Name (Required)'
                                               type='text'
                                               fieldName='last_name'
                                               required={true}
                                               errorMessage='Required'
                                               showFloatingLabel={true}
                                            />
                                    </Box>
                                    <Box className={styles.fieldContainer}>
                                        <UiFormControlSelection
                                            label='Company Name (Required)'
                                            type='text'
                                            fieldName='company_name'
                                            required={true}
                                            errorMessage='Required'
                                            showFloatingLabel={true}
                                        />
                                    </Box>    
                                    <Box className={styles.fieldContainer}>
                                        <UiFormControlSelection
                                            label='Email (Required)'
                                            type="text"
                                            fieldName='email'
                                            required={true}
                                            errorMessage={'Required'}
                                            showFloatingLabel={true}
                                        />
                                    </Box>
                                    <Box className={styles.fieldContainer}>
                                        <UiFormControlSelection
                                            label='Phone (Required)'
                                            type='mask'
                                            fieldName='phone'
                                            required={true}
                                            errorMessage='Required'
                                            showFloatingLabel={true}
                                            mask={mask}
                                            minLength={10}
                                            onChange={(e: any) => {
                                                if(e.target?.value?.length <= 2){
                                                    formik.setFieldValue('phone', e.target.value)
                                                    setTimeout(() => {
                                                        let input = document.getElementsByName("phone")[0];
                                                        input.focus();
                                                    }, 50);
                                                }
                                            }}
                                        />
                                    </Box>     

                                    <div>
                                        <Hidden xsDown>
                                            {loading ? (
                                                <div>
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <div className={styles.buttonSubmit}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={!formik.isValid}
                                                        onClick={() => {
                                                            formik.validateForm()
                                                        }}
                                                    >
                                                        Send the Referral
                                                    </Button>
                                                </div>
                                            )}
                                        </Hidden>
                                    </div>
                                </Form>
                            </>
                        )
                    }}
                </Formik>
                <Typography
                    variant='body2'
                >
                    *Excludes referrals who are refunded
                </Typography>
                </>
            )
        }
        { thanksMode && 
                <Fragment>
                    <div className={styles.TextCenter}>
                        <Fragment>
                            <img
                                alt={`Thanks`}
                                src={ThumpsUpIcon}
                            />
                            <Typography
                                className={styles.title}
                                variant='h6'
                            >
                                Thanks for your referral!
                            </Typography>
                            <Typography

                                variant='body1'
                            >
                                You will receive your $100 renewal credit once the business you referred purchases Business Tax Preparation, or an equivalent service, and is active for at least 30 days.
                            </Typography>
                        </Fragment>
                        <div className={styles.okayBtn}>
                            <Button
                                variant='contained'
                                onClick={() => handleCloseReferralModal()}
                                color="primary"
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                </Fragment>
            }
            
        </UiDialog>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    config: state.config
})

export default connect(mapStateToProps)(ReferralModal)