import UiModal from '../../../common/ui/UiModal'
import Form8879UploadFlow from '../tax-prep-statuses/Form8879UploadFlow'
import { renderFor } from '../constants/tax-prep.const'
import { useWidgetContext } from '../provider/WidgetProvider'
import { useState } from 'react'
import { ReviewDialog } from '../../../common/ReviewDialog'

type Form8879UploadModalProps = {
    open: boolean
    handleClose: () => void
    vtoType: renderFor
    taxYear: number
    taxPrepStatus: string
    taxApId: string | undefined
    setView?: (view: string) => void
    flow?: string
}

const Form8879UploadModal = ({
    open,
    handleClose,
    vtoType,
    taxYear,
    taxPrepStatus,
    taxApId,
    setView,
    flow,
}: Form8879UploadModalProps) => {
    const { notificationClickAction, onclickOfNotification } = useWidgetContext();
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false)
    const handleDismissModal = (showReviewModal : boolean) => {
        handleClose();
        onclickOfNotification({action: null});
        setShowReviewModal(showReviewModal)
    }
    return (
        <>
        <UiModal open={notificationClickAction.open === 'tax_form_uploaded' || open} handleClose={handleClose}>
            <Form8879UploadFlow
                handleClose={handleDismissModal}
                vtoType={vtoType}
                taxYear={taxYear}
                taxPrepStatus={taxPrepStatus}
                taxApId={taxApId}
                setView={setView}
                flow={flow}
            />
        </UiModal>
        {
            showReviewModal && <ReviewDialog />
        }
        </>
    )
}

export default Form8879UploadModal
