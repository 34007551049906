import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import usePermission from '../../../hooks/usePermissions'
import { toRem16 } from '../../../styles/commonStyles'
import { currencyFormatter } from '../../../utils/appUtil'
import UiText from '../../common/ui/UiText'
import { useTaxEngagementContext } from './TaxEngagementProvider'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: toRem16(54),
            background: (colorTheme) => colorTheme.cyan100,
            color: colorTheme => colorTheme.green600,
            margin: '0.5rem 0 1rem 0',
            padding: '0 2% 0 2%'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: `0 ${toRem16(4)} 0 ${toRem16(4)}`,
            '& .header-text': {
                alignSelf: 'flex-end'
            }
        },
    }
})

const EstimatedTaxSavingHeader = () => {
    const { hasTaxAdvisoryPermission } = usePermission()
    const { isDataLoading, taxEngagementData } = useTaxEngagementContext()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const isTaxSavingNull = taxEngagementData?.estimated_tax_savings === null

    return (
        <div>
            {hasTaxAdvisoryPermission && !isDataLoading && !isTaxSavingNull && (
                <div className={styles.container}>
                    <div className={styles.header}>
                        <UiText variant="car_100" weight="medium_500" className="header-text">
                            Estimated Tax Savings
                        </UiText>
                        <UiText variant="suv_150" weight="bold_700">
                            {taxEngagementData?.estimated_tax_savings &&
                                currencyFormatter.format(
                                    taxEngagementData?.estimated_tax_savings
                                )}
                        </UiText>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EstimatedTaxSavingHeader