import { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import Company from '../../payroll/company/Company'
import ADPCompany from '../../adp-payroll/company/Company'
import CompanyProvider from '../../adp-payroll/provider/CompanyProvider'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
        },
    }
})

const Routes = ({ useADPFlow }: { useADPFlow: boolean }) => {
    const classes = useStyles()
    return (
        <Switch>
            <Fragment>
                <Hidden mdUp>
                    <Route path="/payroll/company" exact>
                        <Container className={classes.container} maxWidth="lg">
                            {useADPFlow ? <ADPCompany /> : <Company />}
                        </Container>
                    </Route>
                </Hidden>
                <Hidden smDown>
                    <Route path="/payroll/company">
                        <Container className={classes.container} maxWidth="lg">
                            {useADPFlow ? <ADPCompany /> : <Company />}
                        </Container>
                    </Route>
                </Hidden>
            </Fragment>
        </Switch>
    )
}

const CompanyDetailsRoutes = () => {
    const { currentBusiness } = useCurrentStore()
    const useADPFlow = currentBusiness?.is_adp_flow ? true : (currentBusiness?.is_adp_company_created && currentBusiness?.is_gusto_company_exists)
    return (
        <>
            {useADPFlow ? (
                <CompanyProvider>
                    <Routes useADPFlow={useADPFlow} />
                </CompanyProvider>
            ) : (
                // GUSTO
                <Routes useADPFlow={useADPFlow as boolean} />
            )}
        </>
    )
}

export default CompanyDetailsRoutes