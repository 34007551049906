import React, { useState } from 'react'
import { showError } from '../../../../services/formService'
import { deleteVehicle } from '../../../../services/mileage-log/vehicleService'
import store from '../../../../store'
import { showAlert } from '../../../../store/actions/feedback'
import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog'
import UiText from '../../../common/ui/UiText'
import DeleteIcon from '../../../../assets/icons-svg/WhiteDelete.svg'
import { Button, Theme, createStyles, makeStyles } from '@material-ui/core'
import { ADD_EDIT_DELETE_SUCCESS_MSGS } from '../models/vehicle.model'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        header: {
            margin: '0 0 1rem 0',
        },
        infoText: {
            margin: '0 0 2rem 0',
        },
        deleteButton: {
            background: (colorTheme) => colorTheme.red200,
        },
        loader: {
            padding: '0 2rem 0 0',
        },
    })
)

interface Props {
    isOpen: boolean
    handleCloseModal: (isClose: boolean) => void
    vehicleId: string
    accountId: string
    refreshVehicles: () => void
    deleteVehicleCallBack: (deletedVehicleId: string) => void

}
export const DeleteVehicle = (props: Props) => {
    const { isOpen, handleCloseModal, vehicleId, accountId, refreshVehicles, deleteVehicleCallBack } =
        props
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)

    const onDeleteVehicle = () => {
        setIsDeleting(true)
        deleteVehicle(accountId, vehicleId)
            .then(() => {
                setIsDeleting(false)
                store.dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: ADD_EDIT_DELETE_SUCCESS_MSGS.DELETE,
                    })
                )
                handleCloseModal(true)
                refreshVehicles()
                deleteVehicleCallBack(vehicleId)
            })
            .catch((error) => {
                setIsDeleting(false)
                showError(error.statusText)
            })
    }
    const getActionButtons = (): React.ReactNode => {
        return (
            <>
                <Button
                    variant="contained"
                    className={classes.deleteButton}
                    startIcon={<img alt={`Delete`} src={DeleteIcon} />}
                    onClick={() => onDeleteVehicle()}
                    color="secondary"
                >
                    Delete
                </Button>
            </>
        )
    }
    return (
        <UiConfirmationDialog
            open={isOpen}
            showLoader={isDeleting}
            message={
                <>
                    <UiText
                        variant="hatchback_125"
                        weight="semi_bold_600"
                        className={classes.header}
                    >
                        Delete Vehicle?
                    </UiText>
                    <UiText className={classes.infoText}>
                        This cannot be undone.
                    </UiText>
                </>
            }
            handleClose={() => handleCloseModal(true)}
            confirmNode={getActionButtons()}
            cancelButtonText="Cancel"
        />
    )
}
