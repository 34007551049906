import React from 'react';
import { Button, makeStyles, Theme, Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import UiDialog from '../../common/ui/UiDialog';
import UiText from '../../common/ui/UiText';
import Loader from '../../common/Loader';
import { SegmentList } from '../../../models/chartOfAccountants';
import { useHistory } from 'react-router-dom';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    actionButton: {
        margin: '0 10px 10px 0',
        width: '170px',
    },
    deleteButton: {
        backgroundColor: (colorTheme) => colorTheme.red200,
        color: (colorTheme) => colorTheme.primaryWhite,
        margin: '0 10px 10px 0',
        width: '170px',
        '&:hover': {
            backgroundColor: (colorTheme) => colorTheme.red200,
            border: (colorTheme) => `1px solid ${colorTheme.red200}`,
        },
    },
    deleteContent: {
        borderRadius: '10px',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '10px',
        marginBottom: '10px',
    },
    buttonAlignment: {
        display: 'flex',
        marginTop: '32px',
    },
    deleteLoader: {
        minWidth: '180px',
    },
}));

interface Props {
    isOpen: boolean;
    handleClose: (value: boolean) => void;
    heading: string;
    deleteMessage: React.ReactNode | string;
    handleConfirm?: () => void;
    isSegment?: boolean;
    isDeleting?: boolean;
    selectedData?: SegmentList;
    isCategorized?: boolean;
}

const DeletePopup = ({
    isOpen,
    handleClose,
    heading,
    deleteMessage,
    handleConfirm,
    isSegment,
    isDeleting,
    selectedData,
    isCategorized,
}: Props) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const history = useHistory();
    const goToTransaction = () => {
        history.push('/transactions');
    };
    return (
        <UiDialog
            open={isOpen}
            handleClose={() => handleClose(false)}
            title='delete Segment'
            hideTitleSection
            size='xs'
        >
            {isCategorized ? (
                <>
                    <UiText>
                        If you want to delete this account, you will first need
                        to recategorize the transactions that are associated
                        with this account.
                    </UiText>
                    <div className={classes.buttonAlignment}>
                        <div>
                            <Button
                                variant='outlined'
                                color='secondary'
                                size='medium'
                                onClick={() => handleClose(false)}
                                className={classes.actionButton}
                                data-cy="cancel-recategorize-btn"
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant='contained'
                                color='primary'
                                size='medium'
                                onClick={goToTransaction}
                                data-cy="go-to-transc-btn"
                            >
                                Go to Transactions
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Box mb={3}>
                        <UiText variant='hatchback_125' weight='medium_500'>
                            {heading}
                        </UiText>
                    </Box>
                    {isSegment && (
                        <div className={classes.deleteContent}>
                            <div>
                                <UiText>{selectedData?.name}</UiText>
                            </div>
                            <div>
                                <UiText variant='motorcycle_90'>
                                    {selectedData?.coa_business_category_count}{' '}
                                    accounts
                                </UiText>
                            </div>
                        </div>
                    )}
                    <UiText>{deleteMessage}</UiText>
                    <div className={classes.buttonAlignment}>
                        <div>
                            <Button
                                variant='outlined'
                                color='secondary'
                                size='medium'
                                onClick={() => handleClose(false)}
                                className={classes.actionButton}
                                data-cy="cancel-delete-category-btn"
                            >
                                Cancel
                            </Button>
                        </div>
                        {isDeleting ? (
                            <div className={classes.deleteLoader}>
                                <Loader />
                            </div>
                        ) : (
                            <div>
                                <Button
                                    variant='contained'
                                    size='medium'
                                    startIcon={<Delete />}
                                    onClick={handleConfirm}
                                    className={classes.deleteButton}
                                    data-cy="delete-category-btn"
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </UiDialog>
    );
};

export default DeletePopup;
