import { Button } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import { useStyles } from './Styles'
import clsx from 'clsx'
import Header from './Header'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const NoThanksScreen = ({
    closeModal,
    moveToCollectInfoScreen,
    moveToIntroScreen,
}: {
    closeModal: () => void
    moveToCollectInfoScreen: () => void
    moveToIntroScreen: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div>
            <Header
                closeModal={closeModal}
                onBackButtonClick={moveToIntroScreen}
                headerText={'No Thanks'}
            />
            <div className={styles.contentLayout}>
                <UiText className={styles.contentSpacing}>
                    If 1-800Accountant prepares your return in the future, you
                    will need to manually provide your previous tax year
                    returns. (If you filed).
                </UiText>
                <div>
                    <Button
                        variant="contained"
                        onClick={closeModal}
                        color="primary"
                    >
                        Do Not Retrieve Past Tax Returns
                    </Button>
                </div>
                <Button
                    variant="outlined"
                    onClick={moveToCollectInfoScreen}
                    className={clsx(
                        styles.outlinedButton,
                        styles.contentSpacing
                    )}
                >
                    Check for Carryover Tax Benefits
                </Button>
            </div>
        </div>
    )
}

export default NoThanksScreen