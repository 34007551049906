import { useState } from 'react'
import { FieldArray } from 'formik'
import { Grid, Theme, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import AddIcon from '@material-ui/icons/Add'
import LinkButton from '../../../common/LinkButton'
import FinCenOwnerForm from '../../../common/FinCenOwnerForm'
import UiText from '../../../common/ui/UiText'
import FinCenContent from './FinCenConsentContent'

interface Iprop {
    formikRef: any
}

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    fieldContainer: {
        color: (colorTheme) => colorTheme.black100,
        marginBottom: theme.spacing(4),
        '&.calendar-container .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    ownerDetailsSection: {
        marginBottom: theme.spacing(3),
        color: (colorTheme) => colorTheme.black100,
    },
    mediumFieldContainer: {
        width: '60%',
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        },
    },
    consentSection: {
        marginTop: theme.spacing(4),
        borderTop: (colorTheme) => colorTheme.black100,
        '& > div': {
            paddingTop: '2.5rem',
            borderTop: "1px solid #12172433"
        },
    },
    consentContent: {
        marginTop: theme.spacing(2), 
        color: '#121724A6',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '1rem 1.5rem 1rem 1rem',
        height: '13rem',
        overflowY: 'auto',
        '& + div': {
            margin: '1.5rem 0',
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            },
        },
    },
    consentSectionTitle: {
        marginBottom: theme.spacing(4),
    },
    consentSectionsubTitle: {
        marginBottom: theme.spacing(4),
    },
    checkbox: {
        marginTop: theme.spacing(4),
    },
    desktopScreen: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    mobileScreen: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.5,
    }
}))

const FinCenDataCollectionForm: React.FC<Iprop> = ({formikRef}) => {
    const { colorTheme } = useThemeContext()
    const theme = useTheme()
    const classes = styles(colorTheme)
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(true)
    const { currentConfig } = useCurrentStore()
    const isXsScreen =  window.matchMedia('(max-width: 599px)').matches;

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement
        const bottom =
            Math.abs(
                target.scrollHeight - target.scrollTop - target.clientHeight
            ) < 1

        if (bottom) setIsCheckBoxDisabled(false)
    }

    return (
        <div>
            <div className={classes.fieldContainer}>
                <UiText variant={isSmallDevice ? 'hatchback_125' : 'suv_150'} weight='semi_bold_600'>Business Information</UiText>
            </div>
            <div className={classes.fieldContainer}>
                <UiFormControlSelection
                    key="name"
                    label="Full Legal Business Name (Required)"
                    type="text"
                    fieldName="name"
                    required={false}
                    errorMessage="Required"
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>

            <div
                className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
            >
                <UiFormControlSelection
                    key="ein"
                    label="EIN (Required)"
                    type="mask"
                    fieldName="ein"
                    mask={[
                        /[0-9,X]/,
                        /[0-9,X]/,
                        '-',
                        /[0-9,X]/,
                        /[0-9,X]/,
                        /[0-9,X]/,
                        /[0-9,X]/,
                        /[0-9,X]/,
                        /[0-9,X]/,
                        /[0-9,X]/,
                    ]}
                    minLength={9}
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>

            <div
                className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
            >
                <UiFormControlSelection
                    key="business_dba_name"
                    label="DBA (If Applicable)"
                    type="text"
                    fieldName="business_dba_name"
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>

            <div
                className={`${classes.fieldContainer}  ${classes.mediumFieldContainer} calendar-container`}
            >
                <UiFormControlSelection
                    key="form_date"
                    label="Date of Formation (Required)"
                    type="KeyboardDatePickerCustom"
                    fieldName="form_date"
                    dateType="string"
                    disableFutureDate={true}
                    showFloatingLabel={true}
                    dateFormat="MM/DD/YYYY"
                    placeholder='MM/DD/YYYY'
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>

            <div
                className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
            >
                <UiFormControlSelection
                    key="form_state"
                    label="State of Formation (Required)"
                    type="autocomplete"
                    optionsData={currentConfig.apiConfig.generic.states}
                    optionKey="name"
                    optionValue="id"
                    fieldName="form_state"
                    required={true}
                    errorMessage="Required"
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>
            <div className={classes.fieldContainer}>
                <UiFormControlSelection
                    key="address_address"
                    label="Street Address (Required)"
                    type="text"
                    fieldName={`address.address`}
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>
            <div className={classes.fieldContainer}>
                <UiFormControlSelection
                    key="address_city"
                    label="City  (Required)"
                    type="text"
                    fieldName={`address.city`}
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>
            <div
                className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
            >
                <UiFormControlSelection
                    key="address_state"
                    label="State (Required)"
                    type="autocomplete"
                    optionsData={currentConfig.apiConfig.generic.states}
                    optionKey="name"
                    optionValue="id"
                    fieldName="address.state"
                    required={true}
                    errorMessage="Required"
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>
            <div
                className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
            >
                <UiFormControlSelection
                    key="address_zip_code"
                    label="Zip (Required)"
                    type="mask"
                    fieldName={`address.zip_code`}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                    minLength={5}
                    showFloatingLabel={true}
                    size={isXsScreen ? 'medium' : 'small'}
                ></UiFormControlSelection>
            </div>
            <div className={classes.fieldContainer}>
                <FieldArray name="business_owners">
                    {({ push, remove, form }) => {
                        return form.values?.business_owners?.length > 0 ? (
                            <Grid>
                                <Grid
                                    className={classes.ownerDetailsSection}
                                    container
                                    justify="space-between"
                                    alignItems='center'
                                >
                                    <UiText
                                        variant={isSmallDevice ? 'hatchback_125' : 'suv_150'}
                                        weight="semi_bold_600"
                                    >
                                        Business Owner(s)
                                    </UiText>

                                    <LinkButton
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            push({
                                                tax_payer_name: '',
                                                dob_date: '',
                                                address: {
                                                    address: '',
                                                    city: '',
                                                    state: '',
                                                    country: 'US',
                                                    zip_code: '',
                                                },
                                                ssn: '',
                                                owner_uin: null,
                                                owner_uin_number: '',
                                                owner_uin_file_link: '',
                                                owner_uin_file_details: null,
                                                ownership: 0,
                                            })
                                        }}
                                    >
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="semi_bold_600"
                                        >
                                            New Owner
                                        </UiText>
                                    </LinkButton>
                                </Grid>
                                {form.values.business_owners.map(
                                    (owner: any, index: number) => {
                                        return (
                                            <FinCenOwnerForm
                                                index={index}
                                                fieldName={'business_owners'}
                                                removeOwner={(i: number) =>
                                                    remove(i)
                                                }
                                                key={`owner-${index}`}
                                                formikRef={formikRef}
                                            />
                                        )
                                    }
                                )}
                            </Grid>
                        ) : null
                    }}
                </FieldArray>
            </div>
            <div>
                <div className={classes.consentSection}>
                    <div>
                        <UiText
                            variant="suv_150"
                            weight="semi_bold_600"
                            className={classes.consentSectionTitle}
                        >
                            Consent for Disclosure of Tax Information.
                        </UiText>
                        <UiText
                            textColor="secondary"
                            className={`${classes.consentSectionsubTitle}
                                                ${classes.desktopScreen}`}
                        >
                            Read through the information to provide consent.
                            (must scroll to bottom)
                        </UiText>
                        <UiText
                            textColor="secondary"
                            className={`${classes.consentSectionsubTitle}
                                                ${classes.mobileScreen}`}
                        >
                            Read through the information to provide consent.
                        </UiText>

                        <div
                            className={classes.consentContent}
                            onScroll={handleScroll}
                        >
                            <FinCenContent />
                        </div>

                        <div>
                            <UiText textColor="secondary">
                                (You must scroll to the bottom)
                            </UiText>
                        </div>

                        <div>

                            <div
                                className={`${classes.fieldContainer} ${classes.checkbox} ${isCheckBoxDisabled ? classes.disabled : ''}`}
                            >
                                <UiFormControlSelection
                                    inlineLable="Yes, I agree"
                                    type="checkbox"
                                    fieldName="is_consent_disclosed"
                                />
                            </div>
                        </div>

                                        <div className={classes.fieldContainer}>
                                            <UiFormControlSelection
                                                label="Your full name (Required)"
                                                type="text"
                                                fieldName="consent_disclosed_by"
                                                required={false}
                                                errorMessage="Required"
                                                showFloatingLabel={true}
                                                size={isXsScreen ? 'medium' : 'small'}
                                            ></UiFormControlSelection>
                                        </div>

                        <div
                            className={`${classes.fieldContainer}  ${classes.mediumFieldContainer} calendar-container`}
                        >
                            <UiFormControlSelection
                                label="Date (Required)"
                                type="KeyboardDatePickerCustom"
                                fieldName="consent_disclosed_date"
                                dateType="string"
                                disableFutureDate={true}
                                showFloatingLabel={true}
                                dateFormat="MM/DD/YYYY"
                                placeholder="MM/DD/YYYY"
                                size={isXsScreen ? 'medium' : 'small'}
                            ></UiFormControlSelection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinCenDataCollectionForm
