import { Fragment, useState } from 'react';
import {
    Theme,
    makeStyles,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    styled,
} from '@material-ui/core'

import AppoinmentModal from '../../calender/AppoinmentModal'
import NewMessageModal from '../../message_center/NewMessageModal'
import { mergeStyle } from '../../../styles/commonStyles'
import { STATUS_COMPLETED, TAX_PREP_STATUSES_WIDGET_EXCLUDE_ICONS } from './constants/tax-prep.const'
import UiText from '../../common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

interface Props {
    name: string
    role: string
    profileImage?: string
    accountantId: string
    appoinmentTopic: string[] | null
    accountName?: string
    messageTopic?: string
    handleNewMessage?: (data: any) => void
    handleAppointment?: (data: any) => void
    status?: string
    statusIcons?: string
    appointmentSubTopic?: string[] | null
}

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: 0,
    },
    listRootCompleted: {
        borderRadius: '4px',
        backdropFilter: 'blur(10px)',
        zIndex: 2,
        background: (colorTheme) => colorTheme.white100,
    },
    navListItemGutters: {
        padding: 0,
    },
    actionBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 104,
        background: '#EFF3FD', // to do
        borderRadius: theme.spacing(0),
        marginTop: theme.spacing(3),
    },
    listText: {
        padding: 0,
        margin: 0,
        maxWidth: '200px',
    },
    textColorBlack: {
        color: (colorTheme) => colorTheme.black100,
    },
    listItemAction: {
        right: 0,
    },
    listItemActionCompleted: {
        right: '1rem',
    },
}))

function AssignedAccountant(props: Props) {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const {
        name,
        role,
        profileImage,
        accountantId,
        appoinmentTopic,
        accountName,
        messageTopic = 'Tax Accountant',
        handleNewMessage,
        handleAppointment,
        status,
        statusIcons,
        appointmentSubTopic
    } = props
    const [isAppoinmentModalOpen, setIsAppoinmentModalOpen] = useState(false)
    const [isNewMessageModalOpen, setIsNewMessageModalOpen] = useState(false)
    const member = {
        name,
        accountant_id: accountantId,
        task_name: role,
        account_name: accountName,
        message_center_topic: messageTopic,
        member_appointment_topic: appoinmentTopic,
        member_appointment_sub_topic: appointmentSubTopic
    }

    const ActionIcons = styled(IconButton)({
        padding: '0.5rem',
        margin: 0,
        '& img': {
            height: '24px',
            width: '24px',
        },
    })

    const tax_prep_status: string = statusIcons ?? '';

    return (
        <Fragment>
            <List
                className={mergeStyle(
                    classes.listRoot,
                    status === STATUS_COMPLETED && classes.listRootCompleted
                )}
            >
                <Fragment>
                    <ListItem
                        classes={{
                            gutters: `${
                                status !== STATUS_COMPLETED &&
                                classes.navListItemGutters
                            }`,
                        }}
                        alignItems="center"
                    >
                        <ListItemAvatar>
                            <Avatar alt={accountName} src={profileImage} />
                        </ListItemAvatar>
                        <ListItemText
                            className={`${classes.listText} ${
                                status === STATUS_COMPLETED &&
                                classes.textColorBlack
                            } `}
                            primary={
                                <UiText
                                    variant="motorcycle_90"
                                    weight="medium_500"
                                >
                                    {name}
                                </UiText>
                            }
                            secondary={
                                <>
                                    <UiText
                                        variant="motorcycle_90"
                                        className={`${
                                            status === STATUS_COMPLETED &&
                                            classes.textColorBlack
                                        } `}
                                    >
                                        {role}
                                    </UiText>
                                    {accountName && <div>{accountName}</div>}
                                </>
                            }
                        />
                         { !TAX_PREP_STATUSES_WIDGET_EXCLUDE_ICONS.includes(tax_prep_status) &&
                            <ListItemSecondaryAction
                                className={
                                    status === STATUS_COMPLETED
                                        ? classes.listItemActionCompleted
                                        : classes.listItemAction
                                }
                            >
                                <ActionIcons
                                    edge="end"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleNewMessage
                                            ? handleNewMessage(member)
                                            : setIsNewMessageModalOpen(true)
                                    }}
                                    disabled={!accountantId}
                                >
                                    <img
                                        alt={`Messages Icon`}
                                        src={
                                            require(`../../../assets/icons-svg/Messages.svg`)
                                                .default
                                        }
                                    />
                                </ActionIcons>
                                <ActionIcons
                                    edge="end"
                                    disabled={!accountantId}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleAppointment
                                            ? handleAppointment(member)
                                            : setIsAppoinmentModalOpen(true)
                                    }}
                                >
                                    <img
                                        alt={`Calendar Icon`}
                                        src={
                                            require(`../../../assets/icons-svg/Calendar.svg`)
                                                .default
                                        }
                                    />
                                </ActionIcons>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </Fragment>
            </List>
            <NewMessageModal
                open={isNewMessageModalOpen}
                handleClose={() => setIsNewMessageModalOpen(false)}
                topic={messageTopic}
            />
            <AppoinmentModal
                topics={appoinmentTopic}
                open={isAppoinmentModalOpen}
                handleClose={() => setIsAppoinmentModalOpen(false)}
                accountantId={accountantId}
            />
        </Fragment>
    )
}

export default AssignedAccountant
