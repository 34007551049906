import React, { useEffect, useState } from 'react'
import Loader from '../../../common/Loader'

interface Props {
    totalPagecount: number
    loadMore: (page: number) => void
}

export const InfiniteScroll = (props: Props) => {
    const { totalPagecount, loadMore } = props
    const [currentPageCount, setCurrentPageCount] = useState<number>(1)

    useEffect(() => {
        const target = document.querySelector('.load-more')!
        const intersectionObserver = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio <= 0) return
            const currentPage = currentPageCount + 1
            if (currentPage <= totalPagecount) {
                loadMore(currentPage)
            }
            setCurrentPageCount(currentPage)
        })
        target && intersectionObserver.observe(target)
        return () => {
            target && intersectionObserver.unobserve(target)
        }
    }, [currentPageCount, loadMore, totalPagecount])

    return (
        <>
            {currentPageCount < totalPagecount ? (
                <li className="load-more">
                    <Loader />
                </li>
            ) : null}
        </>
    )
}
