import { useContext, useEffect, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { toRem16 } from '../../../styles/commonStyles'
import { COLORS } from '../../../variables/colors'
import Loader from '../../common/Loader'
import UiButton from '../../common/ui/UiButton'
import UiText from '../../common/ui/UiText'
import { useVehicles } from './hooks/useVehicles'
import {
    Item,
    OdometerReading,
    FormData,
    VEHICLE_STATUSES,
    MILEAGE_YEAR_KEY,
} from './models/vehicle.model'
import { RenderVehicleDetails } from './components/RenderVehicleDetails'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { AddEditVehicle } from './components/AddEditVehicle'
import { isEmpty } from '../../../utils/appUtil'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { VehiclesList } from './components/VehiclesList'
import { EmptyState } from './components/EmptyState'
import { InfiniteScroll } from './components/InfiniteScrolling'
import { useHistory } from 'react-router-dom'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { PLAN_LEVEL_NAMES } from '../../sales/UpgradePortalAccess'


export const initialValues: FormData = {
    activeVehicle: false,
    defaultVehicle: false,
    make: '',
    model: '',
    year: '',
    licensePlate: '',
    vin: '',
    description: '',
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme) =>
    createStyles({
        vehiclesContainer: {
            display: 'flex',
            flex: 1,
            padding: '0 1rem',
            margin: `1rem ${toRem16(113.3)} 0 ${toRem16(113.3)}`,
            overflow: 'auto',
            minHeight: 0,
            [theme.breakpoints.down('xs')]: {
                margin: 0
            }
        },
        vehicleNavItems: {
            flex: 1,
            padding: 0,
            margin: 0,
            '& li': {
                listStyleType: 'none',
            },
        },
        vehicleNavItem: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1rem',
        },
        vehiclesListContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            borderRight: (colorTheme) =>  `${toRem16(1)} solid ${colorTheme.grey300}`
        },
        navItemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minHeight: 0,
            overflow: 'auto',
        },
        vehiclesCount: {
            margin: `${toRem16(24)} 0`,
        },

        vehicleDetailsContainer: {
            flex: 1,
            padding: '0 1.5rem',
            display: 'flex',
            flexDirection: 'column',
        },
        vehiclesListHeader: {
            position: 'sticky',
            top: 0,
            left: 0,
            background: COLORS.primaryWhite,
        },
        vehiclesListHeaderContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: 0,
            overflow: 'auto',
        },
        limitExceedsIndicator:{
            border: `${toRem16(1)} solid ${COLORS.grey200}`,
            borderRadius: toRem16(8),
            padding: '1rem',
            margin: '0 1.5rem 2rem 0',
            '& button':{
                margin: '1rem 0 0 0'
            }
        }
    })
)

export const Vehicles = () => { 
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    const [isRefreshVehicles, setIsRefreshVehicles] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isNoVehicleAdded, setIsNoVehicleAdded] = useState<boolean>(false)
    const [isFreeTrial, setIsFreeTrial] = useState<boolean>(false)
    const { isDataLoading, vehiclesDetails } = useVehicles(
        isRefreshVehicles,
        currentPage
    )
    const vehicleDetailsLength = vehiclesDetails?.items.length;
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [selectedVehicle, setSelectedVehicle] = useState<Item>()
    const [firstLoad, setFirstLoad] = useState(true);
    const [showAddEditVehicleModal, setShowAddEditVehicleModal] =
        useState<boolean>(false)
    const [mileageYears, setMileageYears] = useState<number[]>()
    const [initialFormValues, setInitialFormValues] =
        useState<FormData>(initialValues)
    const store = useCurrentStore()
    const { currentConfig, currentAccountId, currentAccount } = store
    const currentPlan: string = currentAccount.client_tier_permission
    const history = useHistory();
    
    useEffect(() => {
        setMileageYears(
            currentConfig.apiConfig.mileage_tracker.supported_years ?? []
        )
    }, [currentConfig])

    setActiveRouteHeading('Vehicles')
    useEffect(() => {
    }, [setActiveRouteHeading])

    useEffect(() => {
        setIsFreeTrial(currentPlan === PLAN_LEVEL_NAMES.FREE)
    }, [currentPlan])

    useEffect(() => {
        setIsNoVehicleAdded(!vehiclesDetails || isEmpty(vehiclesDetails.items))
    }, [vehiclesDetails])

    useEffect(()=>{
        if(firstLoad && vehiclesDetails?.items.length){
            setSelectedVehicle(vehiclesDetails.items[0]);
            setFirstLoad(false)
        }
    },[firstLoad, vehiclesDetails])


    const onAddVehicleModalClose = (isOpen: boolean) => {
        setShowAddEditVehicleModal(isOpen)
    }
    const refreshVehicles = () => {
        setCurrentPage(1)
        setIsRefreshVehicles(!isRefreshVehicles)
    }
    const getFormattedOdometerRatings = (
        odometerReadings: OdometerReading | null
    ) => {
        if (!odometerReadings || isEmpty(odometerReadings)) return null
        let mileageYearsData: { [key: string]: number } = {}
        Object.keys(odometerReadings).forEach((key) => {
            mileageYearsData[`${MILEAGE_YEAR_KEY}${key}`] =
                odometerReadings[key]
        })
        return mileageYearsData
    }

    const onEditVehicle = (vehicle: Item) => {
        setIsEditMode(true)
        resetFormValues()
        setInitialFormValues((prev: FormData) => ({
            ...prev,
            activeVehicle: vehicle.status === VEHICLE_STATUSES.ACTIVE ?? false,
            defaultVehicle: vehicle.is_default ?? false,
            make: vehicle.make,
            model: vehicle.model,
            year: vehicle.year?.toString() ?? '',
            licensePlate: vehicle.license_plate_number ?? '',
            vin: vehicle.vin ?? '',
            description: vehicle.description ?? '',
            ...getFormattedOdometerRatings(vehicle.odometer_readings),
        }))
        setShowAddEditVehicleModal(true)
    }
    const clearOdometerReadings = (formValues: FormData): FormData => {
        if (!formValues) return formValues
        const clearedFormObj = formValues
        for (const key in formValues) {
            if (key.startsWith(MILEAGE_YEAR_KEY)) {
                clearedFormObj[key] = ''
            }
        }
        return clearedFormObj
    }
    const resetFormValues = (): void => {
        setInitialFormValues((prev: FormData) => ({
            ...prev,
            ...clearOdometerReadings(prev),
            activeVehicle: false,
            defaultVehicle: false,
            make: '',
            model: '',
            year: '',
            licensePlate: '',
            vin: '',
            description: '',
        }))
    }
    const onAddVehicle = () => {
        setIsEditMode(false)
        resetFormValues()
        setShowAddEditVehicleModal(true)
    }
    const disableVehicleForFreePlan = (): boolean => {
        return isFreeTrial && (vehiclesDetails?.items || [])?.length >= 1
    }
    const loadMore = (currentPage: number) => {
        setCurrentPage(currentPage)
    }
    return (
        <>
            {isDataLoading && currentPage === 1 ? (
                <Loader />
            ) : (
                <>
                    {!currentPlan && null}
                    {isNoVehicleAdded ? (
                        <EmptyState
                            onAddVehicle={onAddVehicle}
                            isFreeTrial={isFreeTrial}
                        />
                    ) : (
                        <div className={classes.vehiclesContainer}>
                            <div className={classes.vehiclesListContainer}>
                                <div
                                    className={
                                        classes.vehiclesListHeaderContainer
                                    }
                                >
                                    <div className={classes.vehiclesListHeader}>
                                        {disableVehicleForFreePlan() && (
                                            <div className={classes.limitExceedsIndicator}>
                                                <UiText>You've reached the vehicle limit
                                                on your current plan.</UiText>
                                                <UiButton
                                                    handleClick={() =>{
                                                        history.push('/purchase/upgrade-portal-access')
                                                    }}
                                                    label="Upgrade Your Plan"
                                                    btnType="tertiary"
                                                />
                                            </div>
                                        )}
                                        <UiButton
                                            handleClick={onAddVehicle}
                                            label="Add a Vehicle"
                                            btnType="primary"
                                            icon={<Add />}
                                            disabled={disableVehicleForFreePlan()}
                                        />
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="regular_400"
                                            className={classes.vehiclesCount}
                                        >
                                            {vehicleDetailsLength +
                                                ' '}{' '}
                                                {
                                                    vehicleDetailsLength === 1 && `Vehicle`
                                                }
                                                {
                                                    vehicleDetailsLength &&  vehicleDetailsLength > 1 && `Vehicles`
                                                }
                                                
                                        </UiText>
                                    </div>
                                    <div className={classes.navItemsContainer}>
                                        <ul className={classes.vehicleNavItems}>
                                            <VehiclesList
                                                setSelectedVehicle={
                                                    setSelectedVehicle
                                                }
                                                vehicles={
                                                    vehiclesDetails?.items
                                                }
                                                selectedVehicle={selectedVehicle}
                                            />
                                            {vehiclesDetails?.pageCount &&
                                                vehiclesDetails.pageCount >
                                                    1 && (
                                                    <InfiniteScroll
                                                        totalPagecount={
                                                            vehiclesDetails.pageCount
                                                        }
                                                        loadMore={loadMore}
                                                    />
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vehicleDetailsContainer}>
                                {selectedVehicle ? (
                                    <RenderVehicleDetails
                                        selectedVehicle={selectedVehicle as Item}
                                        currentAccountId={currentAccountId}
                                        refreshVehicles={refreshVehicles}
                                        onEditVehicle={onEditVehicle}
                                        setSelectedVehicle={setSelectedVehicle}
                                        vehicles={vehiclesDetails?.items}
                                />
                                ) : (
                                    <UiText style={{margin:'auto'}}>
                                        Select a Vehicle to view the details
                                    </UiText>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
            {
                <AddEditVehicle
                    showModal={showAddEditVehicleModal}
                    mileageYears={mileageYears}
                    onModalClose={onAddVehicleModalClose}
                    refreshVehicles={refreshVehicles}
                    initialFormValues={initialFormValues}
                    isEditMode={isEditMode}
                    selectedVehicleId={selectedVehicle?.id}
                    callbackVehicle={(vehicle: Item) => {
                        setSelectedVehicle(vehicle)
                    }}
                />
            }
        </>
    )
}
