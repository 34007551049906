import { makeStyles, Theme, Button } from '@material-ui/core'
import React from 'react'
import TaxAdvisoryCallAdImage from '../../../assets/images/TaxAdvisoryAdImage.svg'
import SaveBusinessTaxImage from '../../../assets/images/SaveTaxesImage.svg'
import { toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import Icon from '../../../components/common/Icon'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { AdvisoryMeetingURLLinkForContact, AdvisoryMeetingURLLinkForLead } from '../TodoSection/Todo.const'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        adContainer: {
            flex: 1,
        },
        mainContainer: {
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: `${toRem16(8)}`,
        },
        textContainer: {
            padding: '1rem',
        },
        subTitleStyle: {
            marginTop: toRem16(12),
            marginBottom: toRem16(12),
        },
        secondaryText: {
            marginTop: toRem16(12),
            marginBottom: toRem16(12),
        },
        buttonStyle: {
            background: colorTheme => colorTheme.primary,
            color: colorTheme => colorTheme.primaryWhite,
            padding: toRem16(14),
            '&:hover': {
                background: colorTheme => colorTheme.primary,
                color: colorTheme => colorTheme.primaryWhite,
            },
        },
        advisoryCallImage: {
            maxWidth: '100%',
            verticalAlign: 'top',
            borderRadius: `0 0 ${toRem16(8)} ${toRem16(8)} `
        },
        taxSavingImageStyle: {
            maxWidth: '100%',
            borderRadius: `${toRem16(8)} ${toRem16(8)} 0 0`
        }
    }
})


enum COMPONENTS {
    BOOKKEEPING_SERVICE_AD='bookkeeping_service_ad',
    TAX_SAVE_SERVICE_AD='tax_save_service_ad'
}

const TextContainer = ({
    primaryText,
    secondaryText,
    buttonText,
    buttonClick
}: {
    primaryText: string
    secondaryText: string
    buttonText: string
    buttonClick: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.textContainer}>
            <UiText variant="hatchback_125" weight="medium_500">
                {primaryText}
            </UiText>
            <UiText className={styles.secondaryText} textColor="textSecondary">
                {secondaryText}
            </UiText>

            <Button
                startIcon={<Icon icon="calendar" svgColor={colorTheme.primaryWhite} />}
                className={styles.buttonStyle}
                onClick={buttonClick}
            >
                {buttonText}
            </Button>
        </div>
    )
}


const ComponentsToShow = [COMPONENTS.BOOKKEEPING_SERVICE_AD, COMPONENTS.TAX_SAVE_SERVICE_AD]

const TaxAdvisoryCallAd = () => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { currentUser } = useCurrentStore()
    const { isDesktop } = useDeviceSize()

    const renderComponent = Math.floor(Math.random() * 2)

    const meetingLink = currentUser.is_lead ? AdvisoryMeetingURLLinkForLead.replace('sfLeadId', currentUser.sf_lead_id) : 
        AdvisoryMeetingURLLinkForContact.replace('sfContactId', currentUser.sf_contact_id)

    return (
        <div className={styles.adContainer}>
            <div className={styles.mainContainer}>
                {ComponentsToShow[renderComponent] ===
                COMPONENTS.BOOKKEEPING_SERVICE_AD ? (
                    <>
                        <TextContainer
                            primaryText={
                                'Tax Advisory Services for Small Business'
                            }
                            secondaryText={
                                '1-800Accountant’s year-round tax advisory service proactively ensures compliance and a minimal tax liability.'
                            }
                            buttonText={'Schedule a Call to Learn More'}
                            buttonClick={() => {
                                window.open(meetingLink, '_blank');
                            }}
                        />
                        {isDesktop && <img src={TaxAdvisoryCallAdImage} alt="tax-advisory" className={styles.advisoryCallImage} />}
                    </>
                ) : (
                    <>
                        {isDesktop && <img src={SaveBusinessTaxImage} alt="tax-advisory" className={styles.taxSavingImageStyle}/>}
                        <TextContainer
                            primaryText={
                                'Find out exactly how much you could save on business taxes'
                            }
                            secondaryText={
                                'Discuss your unique business needs with a small business tax expert \
                            in a free 30-minute business tax consultation.'
                            }
                            buttonText={'Schedule a Call'}
                            buttonClick={() => {
                                window.open(meetingLink, '_blank');
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default TaxAdvisoryCallAd