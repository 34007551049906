import { Button, makeStyles } from '@material-ui/core'
import Icon from '../../../../components/common/Icon'
import UiText from '../../../common/ui/UiText'
import GeneralData from './GeneralData'
import Details from './Details'

import type { Trip } from '../../../../models'

const useStyles = makeStyles(() => ({
    scroll: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '75vh',
    },
    detailsContent: {
        overflowX: 'hidden',
    },
    tripLine: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    mr1: {
        marginRight: '1rem',
    },
    pb1: {
        paddingBottom: '1rem',
    },
}))

const TripDetails = ({
    trip,
    promptDelete,
    openEditOverlay,
    openCreateOverlay,
}: {
    trip: Trip
    promptDelete: () => void
    openEditOverlay: () => void
    openCreateOverlay?: () => void
}) => {
    const styles = useStyles()

    return (
        <div className={styles.detailsContent}>
            <div className={styles.tripLine}>
                <UiText variant="suv_150">Trip #{trip.number}</UiText>
                <div>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Icon icon="delete" size="1.125rem" />}
                        onClick={promptDelete}
                        className={styles.mr1}
                        data-cy="open-delete-confirm-modal"
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<Icon icon="edit" size="1.125rem" />}
                        onClick={openEditOverlay}
                        className={styles.mr1}
                        data-cy="open-edit-trip-modal"
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <div className="trip-details">
                <div className={styles.pb1}>
                    <Button
                        key="returnTrip"
                        variant="outlined"
                        color="secondary"
                        onClick={openCreateOverlay}
                    >
                        Make a Return Trip
                    </Button>
                </div>
                <div className={styles.scroll}>
                    <GeneralData trip={trip} />
                    <Details trip={trip} />
                </div>
            </div>
        </div>
    )
}

export default TripDetails
