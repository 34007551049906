import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { BANKING_PAGE_ERRORS } from '../constants/banking.const'
import { BankAccountGroup } from '../../../../models/bankAccountGroup'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { getBankAccountGroupList } from '../../../../services/apiService/bankAccount'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { hasPermission } from '../../../../services/aclService'
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../store/actions/feedback'
import { CUT_OFF_DATE } from '../constants/banking.const'

export const BankingDetailsContext = React.createContext(null)

export function useBankDetailsState(): any {
    const state = useContext(BankingDetailsContext)
    return state
}

export const BankDetailsProvider = ({ children }: any) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [bankGroups, setBankGroups] = useState<any[]>([])
    const [activeBankGroup, setActiveBankGroup] = useState<any>({})
    const [selectedInstitution, setSelectedInstitution] = useState<
        string | number
    >('')
    const theme = useTheme()
    const dispatch = useDispatch()
    const history = useHistory()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const [activeBankGroupAccounts, setActiveBankGroupAccounts] = useState<any>(
        {}
    )
    const [isFincity, setIsFincity] = useState(true)
    const [dialog, setDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>(
        BANKING_PAGE_ERRORS.GENERIC_ERROR
    )

    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)

    const { appData } = useCurrentStore()
    const {
        current_account_id: currentAccountId,
        current_business_id: currentBusinessId,
        current_account: currentAccount,
        personal_account: personalAccount,
        user
    } = appData

    const businessCreatedDate = currentAccount?.business?.created;
    const isPlaidBankAccount = currentAccount?.business?.is_plaid_bank_account;
    const leadSource = personalAccount?.extra_info?.lead_source
    const permission = hasPermission('BASIC_BOOKKEEPING', currentAccount)

    const getActiveGroup = (res: any[], selectedInstitution?: any) => {
        return res.find((group: any) => {
            return group.institution_id === selectedInstitution
        })
    }

    const checkForFincityAccount = (obj: any): boolean => {
        return obj.some((institution: any) =>
            institution.bank_accounts.some(
                (account: any) => account.type === 'aggregated'
            )
        )
    }

    const checkForPlaidAccount = (obj: any): boolean => {
        return obj.some((institution: any) =>
            institution.bank_accounts.some(
                (account: any) => account.type === 'plaid'
            )
        )
    }

    const checkOnBoardingDate = (timestamp: string): boolean => {
        const date = new Date(Number(timestamp) * 1000);
        const cutoffDate = new Date(CUT_OFF_DATE);
      
        return date >= cutoffDate ? true : false;
      };

    const loadBankAccounts = (selectGroup: boolean = false) => {
        setLoading(true)
        return getBankAccounts()
            .then((res: any) => {
                if (!res.length) {
                    newBankAccount()
                }
                const isFincity = checkForFincityAccount(res)
                const isPlaid = checkForPlaidAccount(res)

                if (isPlaid) {
                    setIsFincity(false)
                } else if (isPlaidBankAccount) {
                    setIsFincity(false)
                } else {
                    setIsFincity(
                        !isFincity &&
                            leadSource === 'ZenBusiness' &&
                            !permission &&
                            checkOnBoardingDate(businessCreatedDate)
                            ? false
                            : true
                    )
                }

                setBankGroups(res)

                if (selectGroup) {
                    showBanks(res[0])
                } else {
                    showBanks(getActiveGroup(res, selectedInstitution))
                }
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            err?.statusText ||
                            BANKING_PAGE_ERRORS.GENERIC_ERROR,
                    })
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setActiveRouteHeading('Banking')
        loadBankAccounts(true)
    }, [])

    const getBankAccounts = () => {
        return getBankAccountGroupList(currentAccountId, currentBusinessId)
    }

    const showBanks = (bankGroup: BankAccountGroup) => {
        if (bankGroup) {
            setSelectedInstitution(
                bankGroup.is_manual
                    ? bankGroup.bank_accounts[0].id
                    : bankGroup.institution_id
            )
            setActiveBankGroup(bankGroup)
            setActiveBankGroupAccounts(bankGroup.bank_accounts)
        }
    }

    const newBankAccount = () => {
        return history.push('/bank_accounts/new')
    }

    const providerValue: any = {
        loading,
        setLoading,
        bankGroups,
        setBankGroups,
        activeBankGroup,
        setActiveBankGroup,
        selectedInstitution,
        setSelectedInstitution,
        activeBankGroupAccounts,
        setActiveBankGroupAccounts,
        showBanks,
        newBankAccount,
        loadBankAccounts,
        errorMessage,
        setErrorMessage,
        isFincity,
        setIsFincity,
        dialog,
        setDialog,
    }

    return (
        <BankingDetailsContext.Provider value={providerValue}>
            {children}
        </BankingDetailsContext.Provider>
    )
}
