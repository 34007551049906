import { AppData, Count } from '../../models';
import { getNotificationsAndMessagesCount } from '../../services/apiService';

export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_NOTIFICATION_CLICK = 'SET_NOTIFICATION_CLICK';


export interface CountAction {
    type: string;
    state: Count;
}

export interface NotificationClickAction {
    type: string;
    state: any;
}

export type Action = CountAction;

export const setNotificationCount = (data: Count): CountAction => ({
    type: SET_NOTIFICATION_COUNT,
    state: data,
});

export const onclickOfNotification = (data: any): NotificationClickAction => ({
    type: SET_NOTIFICATION_CLICK,
    state: data
})

export function loadNotificationCount() {
    const getTaxFormUploaded =  sessionStorage.getItem('tax_form_uploaded') ? 'tax_form_uploaded' : null;
    return (dispatch: any, getState: any) => {
        const { appData }: { appData: AppData } = getState();
        getNotificationsAndMessagesCount<{
            'total-unread-count': { count: number };
            'total-notification-count': { count: number };
            'clickAction': {open: string}
        }>(appData.current_account_id).then((res) => {
            dispatch(setNotificationCount({...res, clickAction: { open: getTaxFormUploaded }}));
        });
    };
}
