import {
    Box,
    Typography,
    CircularProgress,
    Theme,
    Chip,
} from '@material-ui/core'
import {
    ArrowDropDown,
    ArrowRight,
    Folder as FolderIcon,
    FolderOpen,
} from '@material-ui/icons'
import { useEffect, useState } from 'react';
import { Folder, FolderTree } from '../../../models'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { ApplicationStore } from '../../../models'
import { buildTree } from '../../../services/folderService'
import { useHistory } from 'react-router-dom'
import { setInLocalStorage } from '../../../services/localStorageService'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
const FoldersTree = ({
    accountId,
    personalAccountId,
    folders,
    hideEmptyFolders,
}: {
    accountId: string
    personalAccountId: string
    folders: Folder[]
    hideEmptyFolders: boolean
}) => {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles((theme: Theme) => ({
        treeIconContainer: {
            width: 48,
            color: '#12172480', // to do
            '& svg': {
                fontSize: 24,
            },
        },
        treeItemContent: {
            height: 40,
        },
        treeItemLabel: {
            background: 'transparent !important',
            '&:hover': {
                background: 'transparent',
            },
        },
        notification: {
            height: '20px !important',
            color: `${colorTheme.primaryWhite} !important`,
            backgroundColor: theme.palette.primary.main + ' !important',
            fontWeight: 'bold',
            cursor: 'pointer !important',
        },
        notificationLabel: {
            padding: '3px 10px !important',
        },
    }))()

    const [businessPersonalFolderTree, setBusinessPersonalFolderTree] =
        useState<FolderTree>()
    const [businessExpanded, setBusinessExpanded] = useState<string[]>([])
    const history = useHistory()
    const handleOpenFolder = (folder: Folder) => {
        setInLocalStorage('selectedFolder', {
            path: folder.path,
            displayPath: folder.title,
        })
        history.push(
            `?folder=${encodeURIComponent(folder.path)}&accountId=${
                folder.portal_account_id
            }&tab="documents"`
        )
    }

    const getTreeItem = (folder: Folder & FolderTree, expanded: string[]) => {
        const treeItem = (
            <TreeItem
                classes={{
                    iconContainer: styles.treeIconContainer,
                    content: styles.treeItemContent,
                    label: styles.treeItemLabel,
                }}
                label={
                    <Box
                        display="flex"
                        alignItems="center"
                        onClick={() =>
                            (folder.is_uploadable || !folder.folders) && handleOpenFolder(folder)
                        }
                    >
                        <Typography variant="body2">{folder.title}</Typography>
                        {!expanded.includes(folder.path) && (
                            <Box mx={1}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    ({folder.files_count})
                                </Typography>
                            </Box>
                        )}

                        {!expanded.includes(folder.path) &&
                        folder.unread_files_count ? (
                            <Chip
                                label={folder.unread_files_count}
                                size="small"
                                classes={{
                                    root: styles.notification,
                                    labelSmall: styles.notificationLabel,
                                }}
                            />
                        ) : null}
                    </Box>
                }
                nodeId={folder.path}
                key={folder.path}
                icon={<FolderIcon color="inherit" />}
            ></TreeItem>
        )
        return hideEmptyFolders ? !!folder.files_count && treeItem : treeItem
    }

    useEffect(() => {
        const businessTree = buildTree(folders, accountId, 'business')
        const parmenentFolders =
            businessTree?.folders && businessTree?.folders['/permanent_files']
        setBusinessPersonalFolderTree(() => parmenentFolders)
    }, [folders, accountId, personalAccountId])
    return (
        <>
            {!folders.length ? (
                <Box width="100%" textAlign="center" my={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <TreeView
                    onNodeToggle={(e: any, nodes) => setBusinessExpanded(nodes)}
                    defaultCollapseIcon={
                        <Box
                            display="flex"
                            alignItems="center"
                            color={colorTheme.grey1700}
                        >
                            <ArrowDropDown color="inherit" fontSize="large" />
                            <FolderOpen color="inherit" fontSize="large" />
                        </Box>
                    }
                    defaultExpandIcon={
                        <Box
                            display="flex"
                            alignItems="center"
                            color={colorTheme.grey1800}
                        >
                            <ArrowRight color="inherit" fontSize="large" />
                            <FolderIcon color="inherit" fontSize="large" />
                        </Box>
                    }
                >
                    {businessPersonalFolderTree?.folders &&
                        Object.keys(businessPersonalFolderTree.folders)
                            .slice(0, 2)
                            .map(
                                (key) =>
                                    businessPersonalFolderTree.folders &&
                                    getTreeItem(
                                        businessPersonalFolderTree.folders[key],
                                        businessExpanded
                                    )
                            )}
                </TreeView>
            )}
        </>
    )
}

const mapStateToProps = ({ appData }: ApplicationStore) => ({
    accountId: appData.current_account_id,
    personalAccountId: appData.personal_account.id,
})
export default connect(mapStateToProps)(FoldersTree)
