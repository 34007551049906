import { useState } from 'react';
import { ContainedDeleteButton } from '../../../../common/DeleteButton';
import UiConfirmationDialog from '../../../../common/ui/UiConfirmationDialog';
import UiText from '../../../../common/ui/UiText';
import { commonStyles } from '../../../../../styles/commonStyles';
import { makeStyles } from '@material-ui/styles';
import Loader from '../../../../common/Loader';
import { handleDeleteInvoice } from '../../../invoices/actions';

interface InvoiceDeleteProps{
    showDeleteConfirmModal: boolean;
    invoiceNumber: string;
    setShowDeleteModal: (showDeleteModal : boolean) => void;
    onCurrentInvoiceDelete: (invoiceDeleted: boolean) => void;
    selectedInvoiceRefNo: string;
}
const useStyles = makeStyles(() => ({
    deleteLoader: {
        display: 'inline-flex',
        verticalAlign: 'middle',
    },
}))
export default function InvoiceDeleteConfirmModal(props: InvoiceDeleteProps){
    const {showDeleteConfirmModal, invoiceNumber, setShowDeleteModal, onCurrentInvoiceDelete, selectedInvoiceRefNo} = props;
    const common = commonStyles(); 
    const styles = useStyles();
    const [isDeleting, setIsDeleting] = useState(false);
    
    const deleteCurrentInvoice = () =>{
        if(!invoiceNumber){
            return;
        }
        setIsDeleting(true);
        handleDeleteInvoice(invoiceNumber, () => {
            setIsDeleting(false);
            setShowDeleteModal(false);
            onCurrentInvoiceDelete(true);
        })
    }
    return (
        <UiConfirmationDialog
                        open={showDeleteConfirmModal}
                        message={
                            <div>
                                <UiText weight="semi_bold_600">
                                    Delete invoice #{selectedInvoiceRefNo} ?
                                </UiText>
                                <UiText className={common.mt16}>
                                    The invoice will be deleted from your
                                    account.
                                </UiText>
                                <UiText className={common.mt16}>
                                    This can not be undone.
                                </UiText>
                            </div>
                        }
                        handleClose={() => {
                            setShowDeleteModal(false)
                        }}
                        confirmNode={
                            <>
                                {isDeleting ? (
                                    <div className={styles.deleteLoader}>
                                        <Loader size={30} />
                                    </div>
                                ) : (
                                    <ContainedDeleteButton
                                        variant="contained"
                                        showDeleteIcon
                                        onClick={deleteCurrentInvoice}
                                        data-cy="delete-invoice-btn"
                                    >
                                        Delete
                                    </ContainedDeleteButton>
                                )}
                            </>
                        }
                        cancelButtonText="Cancel"
                    />
    )
}
