import { useContext } from 'react';
import { createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { ColorThemeContext } from '../components/common/whiteLabel/ColorThemeContext';

export const ThemeCustom = () =>{
    const { colorTheme } = useContext(ColorThemeContext);
    const breakpoints = createBreakpoints({});
    
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: colorTheme.primaryGreen  
            },
            secondary: {
                main: colorTheme.secondary,
            },
            background: {
                default: colorTheme.primaryWhite,
            },
            error: {
                main: colorTheme.red400,
            },
        },
        spacing: [4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 72, 80, 96],
        typography: {
            fontFamily: [
                'Poppins',
                'Roboto',
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
        },
        overrides: {
            MuiLink: {
                root: {
                    color: colorTheme.blue200,
                },
            },
            MuiButton: {
                root: {
                    padding: '10px 8px',
                    height: 36,
                    color: colorTheme.primaryGreen,
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                },
                sizeLarge: {
                    height: 52,
                },
                outlined: {
                    padding: '10px 16px',
                    borderColor: colorTheme.grey700,
                },
                outlinedSecondary: {
                    borderColor: colorTheme.grey200,
                    fontWeight: 500,
                },
                contained: {
                    padding: '10px 16px',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                containedPrimary: {
                    color: colorTheme.primaryWhite,
                },
            },
            MuiDrawer: {
                paper: {
                    border: 'none',
                    boxShadow:
                        `0px 1px 1px ${colorTheme.grey1300}, 0px 2px 1px ${colorTheme.grey700}, 0px 1px 3px ${colorTheme.grey1400}`,
                    width: 240,
                    [breakpoints.up('xl')]: {
                        width: 256,
                    },
                },
                docked: {
                    width: 240,
                    [breakpoints.up('xl')]: {
                        width: 256,
                    },
                },
            },
            MuiAppBar: {
                root: {
                    height: 56,
                    boxShadow: 'none',
                    width: 'calc(100% - 240px)',
                    [breakpoints.up('xl')]: {
                        width: 'calc(100% - 256px)',
                    },
                    [breakpoints.down('md')]: {
                        height: 50,
                        width: '100%',
                    },
                },
            },
            MuiPopover: {
                root: {
                    zIndex: '1301 !important' as any,
                },
            },
            MuiTabs: {
                indicator: {
                    backgroundColor: colorTheme.primaryGreen,
                },
            },
            MuiTab: {
                root: {
                    textTransform: 'none',
                    minWidth: '72px !important',
                    fontSize: 16,
                    color: `${colorTheme.black200} !important`,
                    opacity: '1 !important',
                    marginRight: 24,
                    '&:hover': {
                        color: colorTheme.black200,
                        opacity: 1,
                        background: colorTheme.grey600,
                    },
                    '&$selected': {
                        color: colorTheme.black200,
                    },
                    '&:focus': {
                        color: colorTheme.black200,
                    },
                },
            },
            MuiTypography: {
                h4: {
                    [breakpoints.down(1441)]: {
                        fontSize: '1.5rem',
                    },
                },
                button: {
                    textTransform: 'none',
                    fontSize: '1rem',
                },
            },
            MuiDialog: {
                root: {
                    zIndex: '1301 !important' as any,
                },
            },
            MuiChip: {
                root: {
                    backgroundColor: colorTheme.grey1500,
                    '&:first-child': {
                        marginRight: 8,
                    },
                },
            },
        },
    });
    return theme;
}
