import React, { useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    Divider,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    makeStyles,
} from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import UiButton from '../../common/ui/UiButton'
import { COLORS } from '../../../variables/colors'
import { CentereAligned, Container, InlineFlex } from '../Wrapper'
import SearchBox from '../../common/SearchBox'
import PortalTabs from '../../common/PortalTabs'
import TabPanelHolder from '../../common/TabPanelHolder'
import { ContractorTaxYearModal } from '../ContractorTaxYearModal'
import {
    Contractor,
    Contractor_w_nine,
    Contractors,
} from '../models/contractor.model'
import { AddContractorModal } from '../AddContractorModal'
import {
    editContractor,
    file1099,
    getContractorList,
    getPdf,
    getViewPdfLink,
    updatePaidAmount,
} from '../apiServices/contractor.api'
import { useContractorContext } from '../ContractorProvider'
import Loader from '../../common/Loader'
import {
    CurrencyInputField,
    formatCurrency,
    removeNonNumericCharactors,
} from '../InlineCurrencyInput'
import Finance from '../../../assets/images/Finance.svg'
import { InfoOutlined } from '@material-ui/icons'
import {
    filterFiledAndNotFiledContractor,
    makeTaxYearDropdown,
} from '../utills/contractor.utils'
import { InformationBlock } from '../InformationBlock'
import { requestErrorHandler, showError } from '../../../services/formService'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../store/actions/feedback'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        mainImage: {
            margin:  '1.5rem 0',
            filter: 'grayscale(100%)'
        },
        textCustomStyle: {
            marginBottom: toRem16(16),
            textAlign: 'center',
        },
        centerText: {
            textAlign: 'center',
            marginBottom: toRem16(16),
        },
        filtered: {
            textAlign: 'center',
            margin: `${toRem16(80)} 0`,
        },
        stepCircle: {
            minWidth: toRem16(40),
            height: toRem16(40),
            backgroundColor: (colorTheme) => `${colorTheme.teal200}`,
            borderRadius: toRem16(40),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        dismiss: {
            color: (colorTheme) => `${colorTheme.black100}`,
            textDecoration: 'none',
        },
        uploadBtn: {
            color: (colorTheme) => `${colorTheme.blue200}`,
            textDecoration: 'none',
        },
        textArea: {
            '& .MuiOutlinedInput-input': {
                padding: toRem16(8),
            },
        },
        formControl: {
            margin: 0,
            width: '100%',
        },
        infoIcon: {
            textAlign: 'center',
        },
        dialog: {
            width: '50%',
            maxWidth: '50%',
            height: '80%',
            maxHeight: '80%',
        },
    }
})

enum statusTab {
    FILED = 'filed',
    NOT_FILED = 'not filed',
}

const CAN_NOT_FILE = ['waiting_w_nine']
const ROWS_PER_PAGE = 25;

const RenderTable = (props: any) => {
    const [page, setPage] = React.useState(0);

    const {
        contractorList,
        handleSelectAllClick,
        handleClick,
        numSelected,
        rowCount,
        selected,
        setSelected,
        handleUpdatePaidAmount,
        taxYear,
        handleFilterPage,
        searchInput,
        status,
        colorTheme,
        handleViewPdf,
        viewPdf,
        error,
        setError,
        pagination,
    } = props
    const classes = useStyles(colorTheme)
    const labelId = `enhanced-table-checkbox-${1}`
    const findPaidAmount = (w9s: Contractor_w_nine[], year: string) => {
        const index = w9s.findIndex((w9: Contractor_w_nine) => w9.year === year)
        if (index >= 0) {
            return `${w9s[index].paid_amount}`
        }
        return '0'
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage)
        setSelected([])
    }

    const indexOfLastUser = (page + 1) * rowCount;
    const indexOfFirstUser = indexOfLastUser - rowCount;
    const constractors = pagination ? contractorList.slice(indexOfFirstUser, indexOfLastUser) : contractorList;

    return contractorList.length ? (
        <>
            <Container
                margin="0 0 2rem 0"
                height={toRem16(450)}
                maxheight={toRem16(500)}
                overflow="auto"
            >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {status === statusTab.NOT_FILED && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={
                                            numSelected > 0 &&
                                            numSelected < rowCount
                                        }
                                        checked={
                                            rowCount > 0 &&
                                            numSelected === rowCount
                                        }
                                        onChange={(e) => handleSelectAllClick(e,page)}
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                            // @ts-ignore
                                            'data-cy': 'select-all'
                                        }}
                                        color="primary"
                                    />
                                </TableCell>
                            )}
                            <TableCell>Contractor Name</TableCell>
                            <TableCell align="right" />
                            <TableCell align="right">{`Amount paid in ${
                                taxYear ?? ''
                            }`}</TableCell>
                            {status === statusTab.FILED && (
                                <TableCell align="right">
                                    Form 1099-NEC
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractorList.length > 0 &&
                            constractors.map(
                                (item: Contractor, index: number) => {
                                    const isSelected = selected.findIndex(
                                        (contractor: any) =>
                                            item.id === contractor.id
                                    )
                                    return (
                                        <TableRow key={`${item.id}_`}>
                                            {status === statusTab.NOT_FILED && (
                                                <TableCell padding="checkbox">
                                                    {CAN_NOT_FILE.includes(
                                                        item
                                                            .contractor_w_nine[0]
                                                            .status ?? ''
                                                    ) ? (
                                                        <div
                                                            className={
                                                                classes.infoIcon
                                                            }
                                                        >
                                                            <InfoOutlined
                                                                style={{
                                                                    color: COLORS.grey400,
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Checkbox
                                                            checked={
                                                                isSelected >= 0
                                                            }
                                                            inputProps={{
                                                                'aria-labelledby':
                                                                    labelId,
                                                                // @ts-ignore
                                                                'data-cy': 'select'               
                                                            }}
                                                            color="primary"
                                                            onClick={() =>
                                                                handleClick(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </TableCell>
                                            )}
                                            <TableCell>
                                                {item.first_name +
                                                    ' ' +
                                                    item.last_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {CAN_NOT_FILE.includes(
                                                    item.contractor_w_nine[0]
                                                        .status ?? ''
                                                ) && (
                                                    <UiText textColor="textSecondary">
                                                        Waiting on W-9
                                                    </UiText>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {status ===
                                                statusTab.NOT_FILED ? (
                                                    <CurrencyInputField
                                                        name="currency"
                                                        handleOnBlur={
                                                            handleUpdatePaidAmount
                                                        }
                                                        onFocus={() =>
                                                            setError(
                                                                (
                                                                    prev: any
                                                                ) => ({
                                                                    ...prev,
                                                                    [item.email]:
                                                                        false,
                                                                })
                                                            )
                                                        }
                                                        size="small"
                                                        variant="outlined"
                                                        rowData={item}
                                                        initialValue={findPaidAmount(
                                                            item.contractor_w_nine,
                                                            taxYear
                                                        )}
                                                        isError={
                                                            error[item.email]
                                                        }
                                                        helperText="Amount is Required"
                                                    />
                                                ) : (
                                                    formatCurrency(
                                                        findPaidAmount(
                                                            item.contractor_w_nine,
                                                            taxYear
                                                        )
                                                    )
                                                )}
                                            </TableCell>
                                            {status === statusTab.FILED && (
                                                <TableCell align="right">
                                                    <Button
                                                        variant="text"
                                                        className={
                                                            classes.uploadBtn
                                                        }
                                                        onClick={() =>
                                                            handleViewPdf(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        {viewPdf.loading &&
                                                        viewPdf.id ===
                                                            item.id ? (
                                                            <Loader />
                                                        ) : (
                                                            'View'
                                                        )}
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                }
                            )}
                    </TableBody>
                </Table>
            </Container>
            {pagination && <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={contractorList.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowCount}
            />}
        </>
    ) : searchInput ? (
        <div className={classes.filtered}>
            <UiText>No contractor found</UiText>
        </div>
    ) : (
        handleFilterPage()
    )
}

interface ViewPdf {
    open: boolean
    loading: boolean
    pdf: string
    id: string
}

enum renderUI {
    NO_CONTRACTOR_FOR_THIS_TAX_YEAR = 'no contractor for this tax year',
    HAVE_SONE_CONTRACTOR = 'have some contractor',
}

const KEY = 'file1099'
const VALUE = 'dismissed'
const FIRST_INDEX = 0

export const File1099Necs = () => {
    const [isTaxModalOpen, setIsTaxModalOpen] = useState<boolean>(false)
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
    const [taxYear, setTaxYear] = React.useState<string>('')
    const [status, setStatus] = React.useState<statusTab>(statusTab.NOT_FILED)
    const [searchInput, setSearchInput] = useState('')
    const [selected, setSelected] = useState<any[]>([])
    const [yearWiseContractor, setYearWiseContractor] = useState<Contractors>()
    const [filedContractor, setFiledContractor] = useState<Contractor[]>([])
    const [notFiledContractor, setNotFiledContractor] = useState<Contractor[]>(
        []
    )
    const [isLoading, setIsloading] = useState<boolean>(false)
    const [filteredData, setFilteredData] = useState<any>({})
    const [isDismissed, setIsDismissed] = useState<string | null>(null)
    const [viewPdf, setViewPdf] = useState<ViewPdf>({
        open: false,
        loading: false,
        pdf: '',
        id: '',
    })
    const { currentBusinessId } = useContractorContext()
    const dispatch = useDispatch()
    const { colorTheme } = useThemeContext()
    const [error, setError] = useState({});

    const handleDismiss = () => {
        setIsDismissed(VALUE)
        localStorage.setItem(KEY, VALUE)
    }

    const getYearWiseContractor = (year: string) => {
        setIsloading(true)
        getContractorList({ year })
            .then((res: any) => {
                const result = res as Contractors
                setYearWiseContractor(result)
                const { filed, notFiled } = filterFiledAndNotFiledContractor(
                    result.items
                )
                setFilteredData({ filed, notFiled })
                setFiledContractor(filed)
                setNotFiledContractor(notFiled)
                setIsloading(false)
            })
            .catch((err) => {
                setIsloading(false)
            })
    }

    useEffect(() => {
        const prevYear = `${new Date().getFullYear() - 1}`
        setTaxYear(prevYear)
        getYearWiseContractor(prevYear)
        setIsDismissed(localStorage.getItem(KEY))
    }, [])

    const handleTaxYearChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setTaxYear(event.target.value as string)
        const filters: any = {}
        filters['year'] = event.target.value
        getYearWiseContractor(event.target.value as string)
        setSelected([])
    }

    const handleTabChange = (newValue: number) => {
        const status = newValue === 1 ? statusTab.FILED : statusTab.NOT_FILED
        setStatus(status)
    }

    const toLowerCase = (str: string) => {
        return str.toLocaleLowerCase()
    }

    const handleSearch = (name: string) => {
        setSearchInput(name)
        const list =
            status === statusTab.FILED ? filedContractor : notFiledContractor
        const getSetter =
            status === statusTab.FILED
                ? setFiledContractor
                : setNotFiledContractor

        if (name.length >= 2) {
            getSetter(
                list.filter((item) =>
                    toLowerCase(item.first_name + item.last_name).includes(
                        toLowerCase(name)
                    )
                )
            )
        } else {
            setFiledContractor(filteredData.filed)
            setNotFiledContractor(filteredData.notFiled)
        }
    }

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
        page: number
    ) => {

        const indexOfLastUser = (page + 1) * ROWS_PER_PAGE;
        const indexOfFirstUser = indexOfLastUser - ROWS_PER_PAGE;

        const data =
            status === statusTab.FILED ? filedContractor : notFiledContractor.slice(indexOfFirstUser, indexOfLastUser)

        if (!selected.length) {
            setSelected(
                data.filter(
                    (data) =>
                        !CAN_NOT_FILE.includes(
                            data.contractor_w_nine[0].status ?? ''
                        )
                )
            )
            return
        }
        setSelected([])
    }

    const handleClick = (contarctor: any) => {
        let newSelected: any[] = [...selected]
        const selectedIndex = selected.findIndex(
            (item: any) => item.id === contarctor.id
        )
        if (selectedIndex >= 0) {
            newSelected.splice(selectedIndex, 1)
        } else {
            newSelected = [...newSelected, contarctor]
        }
        setSelected(newSelected)
    }

    const handleUpdatePaidAmount = (
        value: string,
        cb: any,
        data: Contractor
    ) => {
        const updatedData = {
            amount: removeNonNumericCharactors(value),
        }

        updatePaidAmount(
            updatedData,
            data.contractor_w_nine[FIRST_INDEX].id as string
        )
            .then((res) => {
                cb(false)
                getYearWiseContractor(taxYear)
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: `Paid amount updated successfully`,
                    })
                )
            })
            .catch((err) => {
                cb(false)
                setError(prev => ({
                    ...prev,
                    [data.email]:true
                }));
            })
    }

    const handleCloseViewPdfModal = () => {
        setViewPdf({ open: false, loading: false, pdf: '', id: '' })
    }

    const convertBlobToUrl = (blob: any) => {
        const fileURL = URL.createObjectURL(blob)
        return fileURL
    }

    const handleViewPdf = (contractorId: string) => {
        setViewPdf({ open: false, loading: true, id: contractorId, pdf: '' })
        getViewPdfLink(contractorId, taxYear)
            .then((data: any) => {
                if (data) {
                    getPdf(data).then((res: any) => {
                        setViewPdf({
                            open: true,
                            loading: false,
                            id: contractorId,
                            pdf: `${convertBlobToUrl(res.data)}#toolbar=0`,
                        })
                    })
                } else {
                    showError('1099-NEC pdf is not available', 'info')
                    setViewPdf({ open: false, loading: false, pdf: '', id: '' })
                }
            })
            .catch((err) => {
                showError(err?.message)
                setViewPdf({ open: true, loading: false, pdf: '', id: '' })
            })
    }

    const handleFilterPage = () => {
        return (
            <>
                <div className={classes.filtered}>
                    <UiText variant="truck_175" weight="medium_500">
                        {status === statusTab.FILED
                            ? 'No 1099-NECs have been filed yet'
                            : 'Easily file your Form 1099-NECs'}
                    </UiText>
                    <UiText
                        variant="motorcycle_90"
                        className={classes.textCustomStyle}
                    >
                        {status === statusTab.FILED
                            ? 'When you file a 1099-NEC for a contractor it will be listed here'
                            : 'To file additional 1099-NECs you can add contractors you’ve already done business with, or add a new contractor'}
                    </UiText>
                    <div>
                        {status === statusTab.FILED ? (
                            <Button
                                variant="text"
                                className={classes.uploadBtn}
                                onClick={() => setStatus(statusTab.NOT_FILED)}
                            >
                                View Your Unfiled 1099-NECs
                            </Button>
                        ) : (
                            contractorActions('column')
                        )}
                    </div>
                </div>
            </>
        )
    }

    const contractorActions = (direction: 'row' | 'column') => {
        return (
            <InlineFlex
                gap={toRem16(16)}
                flexdirection={direction}
                alignitems="center"
                justifycontent={direction === 'row' ? 'flex-end' : 'center'}
            >
                {direction === 'column' && (
                    <div>
                        <UiButton
                            handleClick={() => setIsTaxModalOpen(true)}
                            label="Add Existing Contractors"
                            btnType="tertiary"
                        />
                    </div>
                )}
                <div>
                    <UiButton
                        handleClick={() => setIsAddModalOpen(true)}
                        label="Add a New Contractor"
                        btnType="secondary"
                    />
                </div>
                {direction === 'row' && (
                    <div>
                        <UiButton
                            handleClick={() => setIsTaxModalOpen(true)}
                            label="Add Contractors"
                            btnType="primary"
                        />
                    </div>
                )}
            </InlineFlex>
        )
    }

    const classes = useStyles(colorTheme)

    const TaxYearDropdown = () => {
        return (
            <Container maxwidth={toRem16(240)} margin="0">
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                >
                    <Select
                        labelId="tax_yar"
                        id="tax_yar_outlined"
                        value={taxYear}
                        onChange={handleTaxYearChange}
                        displayEmpty
                    >
                        <MenuItem disabled value="">
                            Tax Year
                        </MenuItem>
                        {makeTaxYearDropdown().map((item) => (
                            <MenuItem key={item.year} value={item.year}>
                                {`Tax Year ${item.year}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Container>
        )
    }

    const handleUiPage = (): renderUI => {
        if (yearWiseContractor?.items?.length) {
            return renderUI.HAVE_SONE_CONTRACTOR
        }
        return renderUI.NO_CONTRACTOR_FOR_THIS_TAX_YEAR
    }

    const NoContractorUi = () => {
        return (
            <>
                <CentereAligned>
                    <img
                        src={Finance}
                        alt="back to back"
                        className={classes.mainImage}
                    />
                </CentereAligned>
                <div className={classes.centerText}>
                    <UiText variant="truck_175" weight="medium_500" className={classes.textCustomStyle}>
                        No contractors have been added to this tax year
                    </UiText>
                    <UiText
                        variant="car_100"
                        className={classes.textCustomStyle}
                    >
                        To file 1099-NECs you can add contractors you’ve already
                        done business with, or add a new contractor
                    </UiText>
                </div>
                {contractorActions('column')}
            </>
        )
    }

    const handleFile1099 = () => {
        setIsloading(true)
        const ids = selected.map((data) => data.id)
        requestErrorHandler(
            file1099(taxYear, ids).then((res:any) => {
                getYearWiseContractor(taxYear)
                if(res.errors.length){
                    dispatch(
                        showAlert({
                            alertType: 'error',
                            alertText: res.errors[0].message,
                        })
                    )
                } else {
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `You’ve successfully filed ${ids.length} 1099-NECs with the IRS and relevant states. `,
                        })
                    )
                }
                setSelected([])
            }).catch((error)=>{
                if(error?.errors?.Amount){
                    const err:any = {};
                    selected.forEach((item:Contractor) => {
                     const w9 = item.contractor_w_nine.find((w9: Contractor_w_nine) => w9.year === taxYear);
                     !w9?.paid_amount && (err[item.email] = true);
                     })
                     setError(prev => ({
                         ...prev,
                         ...err,
                     }))
                }
                getYearWiseContractor(taxYear)
                showError(error?.statusText)
                setSelected([])
            }),
            false,
        )
    }

  

    const hanldePageToBeRendered = (page: renderUI) => {
        switch (page) {
            case renderUI.NO_CONTRACTOR_FOR_THIS_TAX_YEAR:
                return (
                    <>
                        <InformationBlock />
                        <TaxYearDropdown />
                        <NoContractorUi />
                    </>
                )
            case renderUI.HAVE_SONE_CONTRACTOR:
                return (
                    <>
                        {!isDismissed && (
                            <InformationBlock
                                showButton
                                buttonPosition="inline"
                                handleDismiss={handleDismiss}
                            />
                        )}
                        <InlineFlex
                            justifycontent="space-between"
                            marginbottom="2rem"
                        >
                            <TaxYearDropdown />
                            {contractorActions('row')}
                        </InlineFlex>
                        <InlineFlex justifycontent="space-between">
                            <div>
                                <PortalTabs
                                    disablePadding={true}
                                    onTabChange={handleTabChange}
                                    selectedValue={
                                        status === statusTab.NOT_FILED ? 0 : 1
                                    }
                                >
                                    <TabPanelHolder
                                        index={0}
                                        title={`Not Filed (${
                                            yearWiseContractor?.processedItems
                                                ?.notFiledCount ?? 0
                                        })`}
                                    />
                                    <TabPanelHolder
                                        index={1}
                                        title={`Filed (${
                                            yearWiseContractor?.processedItems
                                                ?.filedCount ?? 0
                                        })`}
                                    />
                                </PortalTabs>
                            </div>
                            <div>
                                <SearchBox
                                    searchTerm={searchInput}
                                    placeHolder={'Search'}
                                    setInput={setSearchInput}
                                    onChangeCallback={(value: string) => {
                                        handleSearch(value)
                                    }}
                                    clearSearch={() => {
                                        setSearchInput('')
                                        setFiledContractor(filteredData.filed)
                                        setNotFiledContractor(
                                            filteredData.notFiled
                                        )
                                        setSelected([])
                                    }}
                                    onKeyPressEvent={(event) => {
                                        if (event.key === 'Enter') {
                                            // do stuff
                                        }
                                    }}
                                />
                            </div>
                        </InlineFlex>
                            <RenderTable
                                contractorList={
                                    status === statusTab.FILED
                                        ? filedContractor
                                        : notFiledContractor
                                }
                                handleClick={handleClick}
                                handleSelectAllClick={handleSelectAllClick}
                                selected={selected}
                                setSelected={setSelected}
                                taxYear={taxYear}
                                numSelected={selected.length}
                                rowCount={ROWS_PER_PAGE}
                                handleUpdatePaidAmount={handleUpdatePaidAmount}
                                handleFilterPage={handleFilterPage}
                                searchInput={searchInput}
                                status={status}
                                colorTheme={colorTheme}
                                handleViewPdf={handleViewPdf}
                                viewPdf={viewPdf}
                                error={error}
                                setError={setError}
                                pagination={status === statusTab.FILED
                                    ? false
                                    : true}
                            />
                        {selected.length > 0 && (
                            <>
                                <Divider />
                                <InlineFlex
                                    justifycontent="space-between"
                                    padding="1rem 0"
                                >
                                    <UiText>{`${selected.length} 1099-NECs selected`}</UiText>
                                    <InlineFlex width="auto" gap={toRem16(16)}>
                                        <UiButton
                                            dataCy='file1099-btn'
                                            handleClick={handleFile1099}
                                            label={`File ${selected.length} 1099-NECs`}
                                            btnType="tertiary"
                                        />
                                    </InlineFlex>
                                </InlineFlex>
                            </>
                        )}
                    </>
                )
        }
    }

    return (
        <>
            {isLoading ? <Loader /> : hanldePageToBeRendered(handleUiPage())}
            {isTaxModalOpen && (
                <ContractorTaxYearModal
                    taxYear={taxYear}
                    isOpen={isTaxModalOpen}
                    handleClose={setIsTaxModalOpen}
                    reloadData={getYearWiseContractor}
                />
            )}
            <AddContractorModal
                isOpen={isAddModalOpen}
                setIsOpen={setIsAddModalOpen}
                reloadData={() => getYearWiseContractor(taxYear)}
            />
            <Dialog
                onClick={handleCloseViewPdfModal}
                onClose={handleCloseViewPdfModal}
                open={viewPdf.open}
                classes={{ paper: classes.dialog }}
            >
                {viewPdf.pdf && (
                    <object
                        data={viewPdf.pdf}
                        type={'application/pdf'}
                        width="100%"
                        height="100%"
                        aria-label={'1099 pdf'}
                    />
                )}
            </Dialog>
        </>
    )
}
