import { Fragment, useEffect, useState } from 'react';
import {
    Typography,
    Theme,
    makeStyles,
    Box,
    Divider,
    Button,
    Grid,
} from '@material-ui/core'
import UiDialog from '../../common/ui/UiDialog'
import ThumbsUp from '../../../assets/icons-svg/ThumpsUp.svg'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import { connect } from 'react-redux'
import { ApplicationStore } from '../../../models'
import { connectFinicityAccounts, preConnectFinicityAccounts } from '../../../services/apiService/bankAccount'
import { currencyFormatter } from '../../../utils/appUtil';
import Loader from '../../common/Loader'
import ConnectedAccountsDatePicker from './ConnectedAccountsDatePicker';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'


const styleClasses = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    marginItems: {
        marginTop: theme.spacing(2),
    },
    infoBox: {
        background: (colorTheme) =>`1px solid ${colorTheme.grey200}`,
        marginTop: '30px',
        marginLeft: theme.spacing(1),
        padding: theme.spacing('0', 1),
        borderRadius: '4px',
    },
    infoContainer: {
        position: `relative`,
        flexWrap: 'wrap',
    },
    dateBox: {
        marginTop: `30px`,
        width: `100%`,
    },
    infoTitles: {
        flexWrap: 'wrap',
    },
    boxplacement: {},
}))

const ConnectedAccounts = ({ openModal, handleClose, businessId }: any) => {
    const [bankAccounts, setBankAccounts] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const { colorTheme } = useThemeContext()
    const styles = commonStyles()
    const classes = styleClasses(colorTheme)
    
    const updateDateTo = (e: number, index: number, date: any) => {
        let bankAccountsCopy = { ...bankAccounts }
        bankAccountsCopy[e][index].date = date
        setBankAccounts(bankAccountsCopy)
    }

    useEffect(() => {
        if(openModal) {
            setLoading(true)
            preConnectFinicityAccounts(businessId)
                .then((res: any) => {
                    if (res?.account?.length) {
                        let group = res.account.reduce((bankGroup: any, bankAcccount: any) => {
                            bankGroup[bankAcccount.institutionId] = [...(bankGroup[bankAcccount.institutionId] || []), bankAcccount]
                            return bankGroup
                        }, {})

                        setBankAccounts(group)
                        setLoading(false)
                    }
                })
            .catch((err) => {
                setLoading(false)
                handleClose()
            })
        }
        
    }, [businessId, handleClose, openModal])

    const handleCloseInternally = () => {
        setLoading(true)
        connectFinicityAccounts(businessId, bankAccounts)
            .then((res: any) => {
                handleClose()
                setLoading(false)
            })
        .catch((err) => {
            handleClose()
            setLoading(false)
        })
    }

    return (
        <UiDialog
            open={openModal}
            handleClose={() => {
                handleClose()
            }}
            title=""
            size="sm"
            hideTitleSection={true}
            {...(loading
                ? {}
                : {
                      actions: (
                          <Button
                              color="primary"
                              variant="contained"
                              onClick={handleCloseInternally}
                          >
                              OKAY
                          </Button>
                      ),
                  })}
        >
            {loading ? (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ height: '100%' }}
                >
                    <Loader />
                </Grid>
            ) : (
                <div>
                    <div
                        className={mergeStyle(styles.flex, styles.spaceAround)}
                    >
                        <img src={ThumbsUp} alt="thumsup" />
                    </div>
                    <div
                        className={mergeStyle(styles.flex, styles.spaceAround, classes.infoTitles)}
                    >
                        <div>
                            <Typography variant="h5">
                                You successfully connected
                            </Typography>
                            <Typography
                                variant="h5"
                                className={mergeStyle(
                                    styles.flex,
                                    styles.spaceAround
                                )}
                            >
                                these bank accounts!
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h5" style={{ marginTop: '10px' }}>
                                Please choose a start date for each account.
                            </Typography>
                            <Typography variant="body2">
                                *You can go back as far as 24 months.
                            </Typography>
                        </div>
                    </div>
                    {/* Box design startes */}
                    {Object.keys(bankAccounts).map((e: any) => (
                        <Box className={classes.infoBox} mb={3}>
                            <div style={{ padding: '24px' }}>
                                {bankAccounts[e as any]?.map(
                                    (bank: any, index: any) => (
                                        <Fragment key={index}>
                                            <Box display="flex" className={classes.infoContainer}>
                                                <Box
                                                    style={{ width: '20px' }}
                                                ></Box>
                                                <Box
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <Box
                                                        className={mergeStyle(
                                                            classes.marginItems,
                                                            styles.capitalize
                                                        )}
                                                        color="secondary"
                                                    >
                                                        <Typography variant="body1">
                                                            {bank.name.toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.marginItems
                                                        }
                                                    >
                                                        <Typography variant="h5">
                                                        {currencyFormatter.format(
                                                            parseFloat(bank?.balance)
                                                        )}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.marginItems
                                                        }
                                                    >
                                                        {' '}
                                                        <Typography
                                                            variant="body2"
                                                            color="secondary"
                                                        >
                                                            {bank.number.replace(
                                                                /.(?=.{4})/g,
                                                                'X'
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={mergeStyle(
                                                            classes.marginItems,
                                                            styles.capitalize
                                                        )}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            variant="body2"
                                                            color="secondary"
                                                        >
                                                            {bank.type}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {!bank.connected && (
                                                    <Box className={classes.dateBox}>
                                                        <Typography
                                                            variant="body1"
                                                            style={{ float: 'left' }}
                                                        >
                                                            Start Date
                                                        </Typography>
                                                        <ConnectedAccountsDatePicker
                                                            bank={bank}
                                                            businessId={businessId}
                                                            updateDate={(date: any) => {
                                                                updateDateTo(e, index, date)
                                                            }}
                                                        />
                                                    </Box >
                                                )}
                                            </Box>
                                            {((bankAccounts[e as any].length - 1) !== index) && <Divider
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            ></Divider>}
                                        </Fragment>
                                    )
                                )}
                            </div>
                        </Box>
                    ))}
                </div>
            )}
        </UiDialog>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
})

export default connect(mapStateToProps)(ConnectedAccounts)