import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, List, makeStyles, Typography } from '@material-ui/core'

import { Contact } from '../../../models'
import Loader from '../../common/Loader'
import ContactItem from './ContactItem'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 300px)',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
}))

function ContactList({
    loading,
    loadMore,
    data,
    hasMore,
    selectedContact,
    setSelectedContact,
}: {
    loading: boolean
    loadMore: () => void
    data: Contact[]
    hasMore: boolean
    selectedContact: Contact | undefined
    setSelectedContact: React.Dispatch<Contact | undefined>
}) {
    const classes = useStyles()
    return (
        <div>
            <List className={classes.root} id="scrollableDiv">
                {loading ? (
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{ height: '100%' }}
                    >
                        <Loader />
                    </Grid>
                ) : (
                    <InfiniteScroll
                        dataLength={data?.length}
                        next={() => loadMore()}
                        hasMore={hasMore}
                        scrollableTarget={'scrollableDiv'}
                        loader={
                            <Grid
                                key={'loader'}
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{
                                    paddingBottom: '16px',
                                    paddingTop: '16px',
                                }}
                            >
                                <Loader />
                            </Grid>
                        }
                    >
                        {data.length > 0 ? (
                            data.map((contact: Contact, index: number) => (
                                <ContactItem
                                    key={contact?.id}
                                    contactId={contact?.id}
                                    contact={contact}
                                    selectedContact={selectedContact}
                                    setSelectedContact={setSelectedContact}
                                    lastItem={(data.length - 1) === index}
                                />
                            ))
                        ) : (
                            <Grid container justify="center">
                                <Typography variant="subtitle1">
                                    No Contacts were found
                                </Typography>
                            </Grid>
                        )}
                    </InfiniteScroll>
                )}
            </List>
        </div>
    )
}

export default ContactList