import { Grid, Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Fragment } from 'react';
import { useStyles } from '../../styles/styles';
import { currencyFormatter } from '../../utils/appUtil';
import LineChartBar from './LineChartBar';
import { useThemeContext } from './whiteLabel/ColorThemeContext';
import UiText from './ui/UiText';

interface LineCharProps {
    values: {
        title: string;
        amount: number;
        color: string;
    }[];
    summary: any;
    reduce?: number;
    handleAmountClick?: any;
}

function LineChartWidget(props: LineCharProps) {
    const classes = makeStyles((theme: Theme) => {
        return {
            progressbar: {
                marginTop: theme.spacing(3),
            },
            chargtLegend: {
                paddingTop: theme.spacing(0),
            },
            chartTitle: {},
            summary: {
                height: 66,
            },
        };
    })();
    let sum = props.values.reduce(
        (a, b) => a + (b.amount < 0 ? 0 : b.amount),
        0
    );

    let reformattedArray = props.values.map((v) => {
        return { ...v, width: v.amount <= 0 ? 0 : (v.amount * 100) / sum };
    });
    const { colorTheme } = useThemeContext()
    let commonClasses = useStyles(colorTheme);
    return (
        <Fragment>
            <Grid
                container
                direction='column'
                justify='flex-start'
                alignItems='stretch'
            >
                {props.summary ? (
                    <Grid
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='flex-start'
                        className={classes.summary}
                    >
                        {props.summary}
                    </Grid>
                ) : (
                    ''
                )}

                <Grid
                    container
                    direction='column'
                    justify='space-between'
                    alignItems='stretch'
                >
                    {reformattedArray
                        .slice(0, props.reduce || reformattedArray.length)
                        .map((v, i) => (
                            <div key={i}>
                                <Grid
                                    container
                                    direction='column'
                                    justify='center'
                                    alignItems='stretch'
                                    className={classes.progressbar}
                                >
                                    <LineChartBar
                                        value={v.width}
                                        color={v.color}
                                    />
                                </Grid>
                                <Grid
                                    key={i}
                                    container
                                    direction='row'
                                    justify='space-between'
                                    alignItems='center'
                                    className={classes.chargtLegend}
                                >
                                    <div>
                                        <Typography
                                            className={classes.chartTitle}
                                            variant='body1'
                                        >
                                            {v.title}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            component={Link}
                                            color='textPrimary'
                                            className={
                                                commonClasses.dashboardTextLink
                                            }
                                            onClick={() =>
                                                props.handleAmountClick &&
                                                props.handleAmountClick(v.title)
                                            }
                                            variant='body1'
                                        >
                                            <UiText variant='motorcycle_90' weight='semi_bold_600'>
                                                {currencyFormatter.format(v.amount)}
                                            </UiText>
                                        </Typography>
                                    </div>
                                </Grid>
                            </div>
                        ))}
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default LineChartWidget;
