import React, { ReactElement, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment';
import UiTextView from '../common/ui/UiTextView';
import { arraybufferToUri } from '../../utils/utility';
import Loader from '../common/Loader';
import OwnerCard from './OwnerCard';
import { ApplicationStore, Config, Business, BusinessOwner } from '../../models';
import { connect } from 'react-redux';
import { previewUrl } from '../../services/apiService/business';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

interface IProp {
    data: Business | null;
    toggleView?: () => void;
    onBusinessPage?: boolean;
    config: Config;
    accountId: string;
    accountantMode: boolean | undefined;
}

const BusinessSettingView: React.FC<IProp> = ({
    config: { apiConfig: config },
    data,
    toggleView,
    onBusinessPage = true,
    accountId,
    accountantMode
}): ReactElement => {
    const theme = useTheme()
    const { colorTheme }= useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            btnContainer: {
                backgroundColor: 'white',
                zIndex: 100,
                position:'sticky',
                top: 0,
                left: 0
            },
            subHeading: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            fieldContainer: {
                marginBottom: theme.spacing(2),
            },
            fieldTitle: {
                color: colorTheme.black200,
                opacity: '0.6',
            },
            businessLogo: {
                width: '60%',
                height: 'auto',
                marginBottom: theme.spacing(1),
                padding: theme.spacing(3),
                border: '1px solid',
                borderColor: colorTheme.grey2300,
                '& > svg': {
                    width: '5em',
                    height: '5em',
                },
            },
            caption: {
                color: colorTheme.black200,
                opacity: '0.6',
            },
            avatar: {
                backgroundColor: theme.palette.primary.main,
                color: colorTheme.primaryWhite,
            },
            btnDivider: {
                marginTop: theme.spacing(3),
            },
        };
    })();

    const [logoImage, setLogoImage] = useState<any>({
        image: '',
        isLoading: false,
    });

    useEffect(() => {
        if (data?.logo) {
            setLogoImage({ image: '', isLoading: true });
            previewUrl(accountId, data?.logo, 'logo')
                .then((response) => {
                    previewImage(response);
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }, [data, accountId]);

    const previewImage = (response: any) => {
        if (response?.status === 200) {
            const imageuri = arraybufferToUri(response);
            setLogoImage({ image: imageuri, isLoading: false });
        }
    };

    const formatOwners = (owners: BusinessOwner[] = []) => {
        return owners.map((owner, index) => {
            return (
                <OwnerCard
                    key={`owner-${index}`}
                    owner={owner}
                    lastItem={owners.length - 1 === index}
                    onBusinessPage={onBusinessPage}
                />
            );
        });
    };

    const getCountryName = (countryId: string): string | null => {
        const country = config.generic.countries.find(
            (item) => item.abbr === countryId
        );

        return country ? country.name : null;
    };

    return (
        <Box>
            {onBusinessPage && (
                <div className={classes.btnContainer}>
                    <Container>
                        <Button
                            variant='contained'
                            color='primary'
                            startIcon={<EditOutlinedIcon />}
                            onClick={toggleView}
                        >
                            Edit
                        </Button>
                    </Container>
                    <Divider className={classes.btnDivider} />
                </div>
            )}
            <Container disableGutters={onBusinessPage ? false : true} fixed>
                <Box py={onBusinessPage ? 2 : 0}>
                    {data ? (
                        <Grid item xs={12} md={onBusinessPage ? 6 : 12}>
                            <Grid>
                                {onBusinessPage && (
                                    <Typography
                                        variant='h6'
                                        className={classes.subHeading}
                                    >
                                        Details
                                    </Typography>
                                )}
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        Business Name
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        {data?.name}
                                    </Typography>
                                </Box>
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        EIN
                                    </Typography>
                                    <UiTextView type='ein' value={data?.ein} />
                                </Box>
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        Industry
                                    </Typography>
                                    <UiTextView
                                        type='config'
                                        configName='business.industries'
                                        configKey='id'
                                        labelKey='name'
                                        value={data?.industry}
                                    />
                                </Box>
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        Type
                                    </Typography>
                                    <UiTextView
                                        type='config'
                                        configName='business.types'
                                        configKey='id'
                                        labelKey='title'
                                        value={data?.business_type}
                                    />
                                </Box>
                            </Grid>
                            {onBusinessPage && (
                                <>
                                    <Grid
                                        container
                                        justify='space-between'
                                        style={{ margin: '2em 0' }}
                                    >
                                        <Typography variant='h6'>
                                            Logo
                                        </Typography>
                                    </Grid>
                                    <Grid container>
                                        <Grid
                                            item
                                            className={classes.fieldContainer}
                                            xs={12}
                                            md={4}
                                        >
                                            {logoImage.isLoading ? (
                                                <Loader justifyContent='flex-start' />
                                            ) : (
                                                <Avatar
                                                    variant='square'
                                                    src={logoImage.image}
                                                    className={
                                                        classes.businessLogo
                                                    }
                                                ></Avatar>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {onBusinessPage ? (
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            margin: theme.spacing(5, '0', 3),
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            className={classes.subHeading}
                                        >
                                            Formation Info
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box className={classes.fieldContainer}>
                                            <Typography
                                                variant='body2'
                                                className={classes.fieldTitle}
                                            >
                                                Formation Date
                                            </Typography>
                                            <Typography variant='subtitle1'>
                                                {data?.form_date
                                                    ? moment(
                                                          data.form_date
                                                      ).format('LL')
                                                    : 'n/a'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box className={classes.fieldContainer}>
                                            <Typography
                                                variant='body2'
                                                className={classes.fieldTitle}
                                            >
                                                Formation State
                                            </Typography>
                                            <UiTextView
                                                type='config'
                                                configName='generic.states'
                                                configKey='id'
                                                labelKey='name'
                                                value={data?.form_state}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Box className={classes.fieldContainer}>
                                        <Typography
                                            variant='body2'
                                            className={classes.fieldTitle}
                                        >
                                            Formation State
                                        </Typography>
                                        <UiTextView
                                            type='config'
                                            configName='generic.states'
                                            configKey='id'
                                            labelKey='name'
                                            value={data?.form_state}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            <Grid>
                                {onBusinessPage && (
                                    <Typography
                                        variant='h6'
                                        className={classes.subHeading}
                                        style={{
                                            margin: theme.spacing(5, '0', 3),
                                        }}
                                    >
                                        Address &amp; Phone Number
                                    </Typography>
                                )}
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        Address
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        {data.address?.address
                                            ? `${data.address.address}, `
                                            : ''}
                                        {data.address?.city
                                            ? `${data.address?.city}, `
                                            : ''}
                                        {data.address?.state
                                            ? `${data.address?.state}, `
                                            : ''}
                                        {data.address?.zip_code
                                            ? `${data.address?.zip_code}, `
                                            : ''}
                                        {data.address?.country
                                            ? `${getCountryName(
                                                  data.address?.country
                                              )}.`
                                            : ''}
                                        {!data.address?.address &&
                                            !data.address?.city &&
                                            !data.address?.state &&
                                            !data.address?.zip_code &&
                                            !data.address?.country &&
                                            'n/a'}
                                    </Typography>
                                </Box>
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        Phone Number
                                    </Typography>
                                    {data.is_us_phone && (<UiTextView
                                        type='phone'
                                        value={data.business_phone}
                                    />)}
                                    {!data.is_us_phone && (<Typography variant='subtitle1'>{data.business_phone}</Typography>)}
                                </Box>
                            </Grid>
                            {onBusinessPage && (
                                <Grid>
                                    <Typography
                                        variant='h6'
                                        className={classes.subHeading}
                                        style={{
                                            margin: theme.spacing(5, '0', 3),
                                        }}
                                    >
                                        Start of Fiscal Year
                                    </Typography>
                                    <Box className={classes.fieldContainer}>
                                        <Typography
                                            variant='body2'
                                            className={classes.fieldTitle}
                                        >
                                            Month
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                            {data?.accounting_settings
                                                ?.fiscal_year_first_month
                                                ? moment.months(
                                                      data?.accounting_settings
                                                          ?.fiscal_year_first_month -
                                                          1
                                                  )
                                                : ''}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.fieldContainer}>
                                        <Typography
                                            variant='caption'
                                            className={classes.caption}
                                        >
                                            1-800Accountant will automatically
                                            close out Income and Expense
                                            accounts and roll up net profit or
                                            loss into owner capital account on
                                            last month of fiscal year.
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid>
                                {onBusinessPage && (
                                    <Typography
                                        variant='h6'
                                        className={classes.subHeading}
                                        style={{
                                            margin: theme.spacing(5, '0', 3),
                                        }}
                                    >
                                        Ownership
                                    </Typography>
                                )}
                                <Box className={classes.fieldContainer}>
                                    <Typography
                                        variant='body2'
                                        className={classes.fieldTitle}
                                    >
                                        This business is owned by
                                    </Typography>
                                    <UiTextView
                                        type='config'
                                        configName='business.entity_types'
                                        configKey='name'
                                        labelKey='title'
                                        value={data?.business_info?.entity_type}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                {onBusinessPage && (
                                    <Typography
                                        variant='h6'
                                        className={classes.subHeading}
                                        style={{
                                            margin: theme.spacing(5, '0', 3),
                                        }}
                                    >
                                        Business Owners
                                    </Typography>
                                )}
                                {formatOwners(data?.business_owners)}
                            </Grid>
                            {onBusinessPage && (
                                <>
                                    <Grid
                                        container
                                        justify='space-between'
                                        style={{ margin: '2em 0' }}
                                    >
                                        <Typography variant='h6'>
                                            Current or Prior Year
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Box className={classes.fieldContainer}>
                                            <Typography
                                                variant='body2'
                                                className={classes.fieldTitle}
                                            >
                                                What bookkeeping software did
                                                you use?
                                            </Typography>
                                            <UiTextView
                                                type='config'
                                                configName='business.bookkeeping_software'
                                                configKey='name'
                                                labelKey='title'
                                                value={
                                                    data?.business_info
                                                        ?.bookkeeping_software
                                                }
                                            />
                                        </Box>
                                        <Box className={classes.fieldContainer}>
                                            <Typography
                                                variant='body2'
                                                className={classes.fieldTitle}
                                            >
                                                Was your business operational
                                                this year?
                                            </Typography>
                                            <UiTextView
                                                type='boolean'
                                                value={
                                                    data?.business_info
                                                        ?.was_operational
                                                }
                                            />
                                        </Box>
                                        {data?.business_type === 'S' ||
                                        data?.business_type === 'LLC_S' ? (
                                            <Box
                                                className={
                                                    classes.fieldContainer
                                                }
                                            >
                                                <Typography
                                                    variant='body2'
                                                    className={
                                                        classes.fieldTitle
                                                    }
                                                >
                                                    Did you receive your
                                                    S-Corporation election
                                                    acceptance letter?
                                                </Typography>
                                                <UiTextView
                                                    type='boolean'
                                                    value={
                                                        data?.business_info
                                                            ?.has_irs_s_corp_approval_letter
                                                    }
                                                />
                                            </Box>
                                        ) : null}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Box height='80vh'>
                            <Loader />
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
    accountId: state.appData.current_account_id,
    accountantMode: state.appData.user.accountant_mode
});

export default connect(mapStateToProps)(BusinessSettingView);
