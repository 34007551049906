import {
    Button,
    Chip,
    createStyles,
    FormHelperText,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfoTooltip from '../../common/InfoTooltip';
import UiChip from '../../common/ui/UiChip';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiFormField from '../../common/ui/UiFormField';
import { useReportsStyles } from './styles/reports-styles';
import { useHistory } from 'react-router-dom';
import { findSpaceRegex, DATE_TYPES } from './constants/reports.const';
import {
    getAllCOASegments,
    getBalanceSheetReport,
} from '../../../services/apiService/reports';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    ApiError,
    BalanceSheetReportRoot,
    ErrorAlertReport,
} from './models/reports-interface';
import store from '../../../store';
import { reportsDataActions } from '../../../store/actions/reportsData';
import Loader from '../../common/Loader';
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil';
import UiText from '../../common/ui/UiText';
import { showError } from '../../../services/formService';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { FormObserver } from './utils/Form/FormObserver';
import SelectCompareDataModal from './utils/modal/SelectCompareDataModal';
import { SegmentList } from '../../../models/chartOfAccountants';
import { ReactComponent as SearchIconImage } from '../../../assets/icons-svg/SearchIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons-svg/Calendar.svg';
import UiButton from '../../common/ui/UiButton';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface FormValues {
    date: number;
    isCompareSegments: boolean;
}
const initialFormValues = {
    date: moment.utc().hour(12).minute(0).second(0).unix(),
    isCompareSegments: false,
    startDate: 0,
    endDate: 0,
};
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        dateChips: {
            margin: '1rem 0',
            display: 'flex',
            gap: '1rem',
            '& .MuiChip-root':{
                margin: 0,
            },
            '& .MuiChip-clickable:hover':{
                backgroundColor: (colorTheme) => colorTheme.grey600,
            }
        },
        compareSegments: {
            margin: '2rem 0 1rem 0',
        },
        submitbtn: {
            margin: '2.2rem 0 0.5rem 0',
        },

        errorText: {
            color: (colorTheme) => colorTheme.red100,
        },
        errorBorder: {
            borderColor: (colorTheme) => colorTheme.red100,
            '&:hover': {
                borderColor: (colorTheme) => colorTheme.red100,
            },
        },
        selectPeriodText: {
            margin: '0 0 1rem 0',
        },
    })
);
const SEGMENTS_TITLE = 'Select Segments';
const BAL_SHEET_QUERY_PARAM = 'name=balanceSheet';
const TOOLTIP_TEXT =
    'The Balance Sheet lists all your company’s assets, liabilities, and equity at the selected point in time.';

export default function BalanceSheetReport() {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const localClasses = useLocalStyles(colorTheme);
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const storeData = useCurrentStore();
    const accountId = storeData.currentAccountId;
    const businessId = storeData.currentBusinessId!;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.TODAY
    );
    const history = useHistory();
    const businessName = storeData.currentBusiness?.name;

    const [isSelectSegmentsModalOpen, setIsSelectSegmentsModalOpen] =
        useState<boolean>(false);

    const [segments, setSegments] = useState<SegmentList[]>();
    const [compareValidation, setCompareValidation] = useState<string | null>(
        null
    );
    const [checkedSegments, setCheckedSegments] = useState<SegmentList[]>([]);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();

    useEffect(() => {
        history.push({
            pathname: history.location.pathname,
            search: BAL_SHEET_QUERY_PARAM,
        });
    }, [history]);

    useEffect(() => {
        setIsLoading(true);
        getAllCOASegments(accountId, businessId)
            .then((res: unknown) => {
                setIsLoading(false);
                const segmentValues = res as SegmentList[];
                setSegments(segmentValues);
            })
            .catch((error) => {
                setIsLoading(false);
                showError(error?.statusText);
            });
    }, [
        accountId,
        businessId,
        storeData.currentAccountId,
        storeData.currentBusinessId,
    ]);

    const getPeriodText = (date: number): string => {
        const periodTextFormat = 'DD MMM yyyy';
        const fromDateInMs = date * 1000;
        const convertedDate = convertUnixToGivenDateFormat(
            periodTextFormat,
            fromDateInMs
        );
        const periodText = `As of ${convertedDate}`;
        return periodText.replace(findSpaceRegex, '+');
    };

    const fetchAPIQueryParams = (date: number, isCompareSegments: boolean) => {
        const periodText = getPeriodText(date);
        const time = getCurrentTimeStamp();
        const endDateYmd = getDateInYmd(date);
        let classIds = '';
        if (isCompareSegments) {
            classIds = getCheckedSegmentIds();
        }
        return {
            accountId,
            businessId,
            periodText,
            time,
            endDateYmd,
            classIds,
        };
    };
    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const checkCompareSegments = (values: FormValues) => {
        if (!values.isCompareSegments) {
            resetCheckedValues();
        }
    };
    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        const isDateChanged = formikObject.touched?.date;

        if (isDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
        checkCompareSegments(values);
        setCompareValidation(null);
    };
    const navigateToBalanceSheetDetailedReports = (
        periodText: string,
        time: number,
        endDateYmd: string
    ) => {
        const detailedReportsUrl = `/reports/balance-sheet/${periodText}/${time}/${endDateYmd}/${initialValues.startDate}/${initialValues.endDate}`;
        history.push(detailedReportsUrl);
    };
    const dispatchReportsDataToStore = (data: BalanceSheetReportRoot) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };

    const getCheckedSegmentIds = () => {
        const segmentIds: string[] = [];
        checkedSegments.map((segment: SegmentList) => {
            return segmentIds.push(segment.id);
        });
        return segmentIds.join(',');
    };
    const navigateToComparisionReport = (
        periodText: string,
        time: number,
        endDateYmd: string
    ) => {
        const segmentIds = getCheckedSegmentIds();
        const detailedReportsUrl = `/reports/balance-sheet/${periodText}/${time}/${endDateYmd}/${initialValues.startDate}/${initialValues.endDate}?view=compare&segments=${segmentIds}`;
        history.push(detailedReportsUrl);
    };
    const navigateToReportSection = (
        isCompareSegments: boolean,
        reports: BalanceSheetReportRoot,
        periodText: string,
        time: number,
        endDateYmd: string
    ) => {
        dispatchReportsDataToStore(reports);
        if (isCompareSegments) {
            navigateToComparisionReport(periodText, time, endDateYmd);
            return;
        }
        navigateToBalanceSheetDetailedReports(periodText, time, endDateYmd);
    };
    const generateBalanceSheetReports = (
        date: number,
        isCompareSegments: boolean
    ) => {
        const {
            accountId,
            businessId,
            periodText,
            time,
            endDateYmd,
            classIds,
        } = fetchAPIQueryParams(date, isCompareSegments);
        setIsLoading(true);
        getBalanceSheetReport(
            accountId,
            businessId,
            periodText,
            time,
            endDateYmd,
            classIds
        )
            .then((result: unknown) => {
                setIsLoading(false);
                const data = result as BalanceSheetReportRoot;
                navigateToReportSection(
                    isCompareSegments,
                    data,
                    periodText,
                    time,
                    endDateYmd
                );
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };

    const onSubmit = (values: FormValues) => {
        const { date, isCompareSegments } = values;
        if (isCompareSegments && !checkedSegments.length) {
            setCompareValidation('Please select segments first');
            return;
        }
        generateBalanceSheetReports(date, isCompareSegments);
    };

    const getFormattedTime = (dateType: string) => {
        return getTimeParamsWithoutEST(dateType);
    };
    const updateBalanceSheetTime = (dateType: string): void => {
        setSelectedDateChip(dateType);
        const formattedDate = getFormattedTime(dateType);
        setinitialValues((prev) => ({
            ...prev,
            startDate: formattedDate.startDate ?? 0,
            endDate: formattedDate.endDate ?? 0,
            date: formattedDate?.endDate ?? 0,
        }));
    };
    const openSegmentSelectModal = () => {
        setIsSelectSegmentsModalOpen(true);
    };
    const closeSegmentSelectModal = () => {
        setIsSelectSegmentsModalOpen(false);
    };
    const getCurrentIndex = (
        checkedSegments: SegmentList[],
        segment: SegmentList
    ) => {
        const currentIndex = checkedSegments.indexOf(
            checkedSegments.filter((item: any) => item.id === segment.id)[0]
        );
        return currentIndex;
    };
    const addRemoveItemsBasedOnIndex = (
        currentIndex: number,
        newChecked: SegmentList[],
        segment: SegmentList
    ) => {
        if (currentIndex === -1) {
            newChecked.push(segment);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        return newChecked;
    };

    const handleToggleSegments = (segment: SegmentList) => {
        const currentIndex = getCurrentIndex(checkedSegments, segment);
        const newChecked = [...checkedSegments];
        const newSegmentsArray = addRemoveItemsBasedOnIndex(
            currentIndex,
            newChecked,
            segment
        );
        setCheckedSegments(newSegmentsArray);
        setCompareValidation(null);
    };
    const deleteSegment = (id: string) => {
        const segments = checkedSegments.filter((segment: SegmentList) => {
            return segment.id !== id;
        });
        setCheckedSegments(segments);
    };

    const resetCheckedValues = () => {
        setCheckedSegments([]);
    };

    const clearAllSegments = (event: any) =>{
        event.preventDefault();
        resetCheckedValues();
    }
    const isShowClearAllButton = () : boolean =>{
       return checkedSegments.length > 1;
    }

    return (
        <div className={reportsClasses.reportsViewParent}>
            <UiText className="businessName">{businessName}</UiText>
            <UiText
                variant="suv_150"
                weight="semi_bold_600"
                className={reportsClasses.pageTitle}
            >
                Balance Sheet
                <InfoTooltip tooltipText={TOOLTIP_TEXT}></InfoTooltip>
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values }) => {
                    return (
                        <Form>
                            <FormObserver onformChange={onformChangeCallback} />
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    <UiText
                                        weight="medium_500"
                                        className={
                                            localClasses.selectPeriodText
                                        }
                                    >
                                        Select a period of time
                                    </UiText>
                                    <div className="calendarSection">
                                        <UiFormControlSelection
                                            label="As of"
                                            type="date"
                                            fieldName="date"
                                            dateType="string"
                                            showFloatingLabel={true}
                                            disableFutureDate={true}
                                            endIcon={<CalendarIcon />}
                                        />
                                    </div>

                                    <div className={localClasses.dateChips}>
                                        <UiChip
                                            label={
                                                <UiText variant="motorcycle_90">
                                                    {DATE_TYPES.TODAY}
                                                </UiText>
                                            }
                                            data-cy='today-chip-btn'
                                            onClickCallout={() => {
                                                updateBalanceSheetTime(
                                                    DATE_TYPES.TODAY
                                                );
                                            }}
                                            customRootStyles={
                                                selectedDateChip ===
                                                DATE_TYPES.TODAY
                                                    ? 'selectedChip'
                                                    : 'customChip'
                                            }
                                            cypressId='today-chip-btn'
                                        />
                                        <UiChip
                                            label={
                                                <UiText variant="motorcycle_90">
                                                    {DATE_TYPES.LAST_YEAR}
                                                </UiText>
                                            }
                                            onClickCallout={() => {
                                                updateBalanceSheetTime(
                                                    DATE_TYPES.LAST_YEAR
                                                );
                                            }}
                                            customRootStyles={
                                                selectedDateChip ===
                                                DATE_TYPES.LAST_YEAR
                                                    ? 'selectedChip'
                                                    : 'customChip'
                                            }
                                            cypressId='last-year-chip-btn'
                                        />
                                    </div>

                                    <div
                                        className={localClasses.compareSegments}
                                    >
                                        <UiFormField
                                            type="checkbox"
                                            inlineLable="Compare Segments"
                                            label=""
                                            labelSize={false}
                                            fieldName="isCompareSegments"
                                            cypressId='compare-segments-checkbox'
                                        />
                                    </div>
                                    
                                    <div
                                        className={
                                            reportsClasses.multiSelectInput
                                        }
                                    >
                                        {
                                             isShowClearAllButton() &&  <UiButton
                                            handleClick={clearAllSegments}
                                            label='Clear All'
                                            btnType='primary'
                                            customClass={reportsClasses.clearAllSegmentsButton}
                                        />
                                        }
                                        <TextField
                                            id="outlined-basic"
                                            label="Select Segments"
                                            variant="outlined"
                                            size="small"
                                            data-cy='select-segment-input'
                                            disabled={!values.isCompareSegments}
                                            onClick={() => {
                                                if (!values.isCompareSegments)
                                                    return;
                                                openSegmentSelectModal();
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {<SearchIconImage />}
                                                    </InputAdornment>
                                                ),
                                                endAdornment:
                                                    checkedSegments.map(
                                                        (segment) => (
                                                            <Chip
                                                                key={segment.id}
                                                                tabIndex={-1}
                                                                label={
                                                                    segment.name
                                                                }
                                                                onDelete={() => {
                                                                    deleteSegment(
                                                                        segment.id
                                                                    );
                                                                }}
                                                            />
                                                        )
                                                    ),
                                            }}
                                        />
                                    </div>

                                    {compareValidation && (
                                        <FormHelperText
                                            className={localClasses.errorText}
                                        >
                                            {compareValidation}
                                        </FormHelperText>
                                    )}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        data-cy='generate-report-btn'
                                        className={localClasses.submitbtn}
                                    >
                                        Generate Report
                                    </Button>
                                </>
                            )}
                        </Form>
                    );
                }}
            </Formik>

            {segments && (
                <SelectCompareDataModal
                    listData={segments}
                    checkedData={checkedSegments}
                    handleToggle={handleToggleSegments}
                    isOpen={isSelectSegmentsModalOpen}
                    handleClose={closeSegmentSelectModal}
                    title={SEGMENTS_TITLE}
                    idKey="id"
                    valueKey="name"
                    searchKey="name"
                />
            )}
        </div>
    );
}
