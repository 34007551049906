import { Box, Button } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChartOfAccountantsData } from '../../../models/chartOfAccountants';
import { moveCatoryToResource } from '../../../services/apiService/chartOfAccountants';
import { showAlert } from '../../../store/actions/feedback';
import Loader from '../../common/Loader';
import UiDialog from '../../common/ui/UiDialog';
import UiText from '../../common/ui/UiText';
import { MoveSegmentBody } from './MoveAccount';

interface MoveAccountWarningType {
    isOpen: boolean;
    srcCategory: ChartOfAccountantsData;
    destCategory: ChartOfAccountantsData;
    handleClose: (close: boolean) => void;
    businessId: string;
    accountId: string;
    handleMoveAccountConfirm: (
        isDataChanged: boolean,
        updatedCategory: ChartOfAccountantsData
    ) => void;
}
export function MoveAccountConfirm(props: MoveAccountWarningType) {
    const {
        isOpen,
        srcCategory,
        destCategory,
        handleClose,
        businessId,
        accountId,
        handleMoveAccountConfirm,
    } = props;
    const srcCategoryName = srcCategory?.title;
    const destCategoryName = destCategory?.title;
    const srcCategoryId = srcCategory.id;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const dispatchErrorToStore = (error: { errors: { name: string } }) => {
        const message = error?.errors?.name;
        dispatch(
            showAlert({
                alertType: 'error',
                alertText: message ?? 'Something went wrong',
            })
        );
    };
    const submitMoveCategory = () => {
        const body: MoveSegmentBody = {
            coa_business_category_id: destCategory.id,
        };
        setIsLoading(true);
        moveCatoryToResource(accountId, businessId, srcCategoryId, body)
            .then((res: ChartOfAccountantsData) => {
                const updatedCategory = res;
                setIsLoading(false);
                handleMoveAccountConfirm(true, updatedCategory);
                handleClose(false);
            })
            .catch((error) => {
                setIsLoading(false);
                dispatchErrorToStore(error);
                handleClose(false);
            });
    };
    return (
        <UiDialog
            open={isOpen}
            handleClose={() => handleClose(false)}
            title='Move Account'
            hideTitleSection
            size='xs'
        >
            <UiText variant='hatchback_125' weight='semi_bold_600'>
                Convert to Level 4 Account?
            </UiText>
            <p>
                Moving {srcCategoryName && srcCategoryName} into{' '}
                {destCategoryName && destCategoryName} will convert it to a
                Level 4 Sub-Account.
                <br />
                Once converted it can only be moved between other level 3
                accounts.
                <br />
                This cannot be undone.
            </p>
            <Box mb={3} mt={4} display='flex' justifyContent='space-between'>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleClose(false)}
                    data-cy="move-cancel-confirm-btn"
                >
                    Cancel
                </Button>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Button
                        variant='outlined'
                        color='secondary'
                        onClick={submitMoveCategory}
                        data-cy="move-confirm-btn"
                    >
                        Convert
                    </Button>
                )}
            </Box>
        </UiDialog>
    );
}
