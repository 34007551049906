import { Box, Theme, makeStyles } from '@material-ui/core'
import UiDialog from '../../../common/ui/UiDialog'
import { commonStyles, mergeStyle } from '../../../../styles/commonStyles'
import UiButton from '../../../common/ui/UiButton'
import UiText from '../../../common/ui/UiText'
import Loader from '../../../common/Loader'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { useBankDetailsState } from '../provider/BankDetailsProvider'

interface PastDueNoticeModalProps {
    openModal: boolean
    closeModal: (params: any) => void
    handleProceedBtn: () => void
    selectedAccount: any
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: '40.25rem',
        },
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
            padding: '1rem 1rem 0px',
        },
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        textAlign: 'center',
        marginBottom: 24,
    },
    bankTitle: {
        color: (colorTheme) => colorTheme.red400
    }
}))

const ErrorDialog = ({
    openModal,
    closeModal,
    handleProceedBtn,
    selectedAccount,
}: PastDueNoticeModalProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const styles = commonStyles()

    const { setDialog, loading } = useBankDetailsState()

    const handleSuccess = () => {
        setDialog(false)
        handleProceedBtn()
    }

    return (
        <UiDialog
            open={openModal}
            handleClose={closeModal}
            title=""
            size="sm"
            customRootClass={classes.container}
            disableBackdropClick
            disableEscapeKeyDown
        >
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <UiText
                            variant="hatchback_125"
                            weight="bold_700"
                            className={classes.title}
                        >
                            {' '}
                            You may be redirected to your bank site, where you
                            might have been provided with the option to select
                            the account type you want to authenticate. Please
                            make sure to select the same account type, i.e.{' '}
                            <span className={classes.bankTitle}>{selectedAccount?.name}</span>, Selecting a different
                            account type may disconnect the previously selected
                            account type and lose the connection permanently.
                        </UiText>
                    </div>
                    <div
                        className={mergeStyle(
                            styles.flex,
                            styles.justifyCenter
                        )}
                    >
                        <UiButton
                            btnType="tertiary"
                            handleClick={() => closeModal(false)}
                            label="Cancel"
                        />
                        <Box marginLeft={2}>
                            <UiButton
                                btnType="tertiary"
                                handleClick={handleSuccess}
                                label="Proceed"
                            />
                        </Box>
                    </div>
                </>
            )}
        </UiDialog>
    )
}

export default ErrorDialog
