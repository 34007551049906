import InfoCard from '../Cards/InfoCard'
import UiText from '../../common/ui/UiText'
import Icon from '../../common/Icon'
import { makeStyles,Theme  } from '@material-ui/core'
import { toRem16 } from '../../../styles/commonStyles'
import { useHistory } from 'react-router-dom'
import { BookkeepingType } from '../../../models/dashboardModel'
import { updateDimissInfo } from '../AccountSetupItems/AccountSetupUtility'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const bizAccountNotLinkedMessage = `Log in to your bookkeeping software, review your income and 
                            \ expense items, and view the profit reports we generated.`

export const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        cardContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '2% 2% 2% 0',
        },
        container: {
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            marginTop: '1rem'
        },
        headerStyle: {
            display: 'grid',
            gridTemplateColumns: `${toRem16(72)} 1fr`,
        },
        iconStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginTop: '1rem',
        },
    }
})

const BookkeepingInfoCard = ({
    bookkeepingInfo,
    fetchAccountProgress,
}: {
    bookkeepingInfo: BookkeepingType
    fetchAccountProgress: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const history = useHistory()

    const showButton = bookkeepingInfo.show_button
    const { currentAccountId } = useCurrentStore()

    const dismissCard = (popup: string) => {
        updateDimissInfo(popup, currentAccountId, fetchAccountProgress)
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconStyle}>
                    <Icon icon="bookkeepingInfo" />
                </div>
                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <UiText variant="hatchback_125" weight="medium_500">
                            We’ve completed your {bookkeepingInfo.month}{' '}
                            bookkeeping
                        </UiText>
                    }
                    subtitle={
                        <UiText variant="motorcycle_90">
                            <>
                                {showButton ? (
                                    <>
                                        Review your income and expense items and
                                        view the profit reports we generated{' '}
                                    </>
                                ) : (
                                    bizAccountNotLinkedMessage
                                )}
                            </>
                        </UiText>
                    }
                    buttonText={showButton ? 'Review Bookkeeping' : ''}
                    buttonClick={() => {
                        history.push('/transactions')
                        dismissCard(bookkeepingInfo.pop_up)
                    }}
                />
            </div>
        </div>
    )
}

export default BookkeepingInfoCard