import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ERRORS } from '../../constants/reports.const';
import { ReactComponent as ErrorAlertIcon } from '../../../../../assets/icons-svg/AlertTriangle.svg';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';

const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        errorAlert: {
            background: (colorTheme) => colorTheme.red200,
            padding: '1rem',
            borderRadius: '0.3rem',
            display: 'flex',
            margin: '2.5rem 0',
            '& .alertContent':{
                margin: '0 0 0 0.5rem'
            }
        },
    })
);
interface Error {
    message?: string | undefined;
}
export default function ErrorAlert(props: Error) {
    const { message } = props;
    const { colorTheme } = useThemeContext()
    const displayError = message || ERRORS.GENERIC;
    const localClasses = useLocalStyles(colorTheme);
    return (
        <div className={localClasses.errorAlert}>
            <ErrorAlertIcon />
            <div className='alertContent'>{displayError}</div>
        </div>
    );
}
