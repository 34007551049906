import * as Yup from 'yup';
import { Config } from '../../../../models';
import { phoneNumberRegex, ssNRegex, zipCodeRegex } from '../../../../utils/maskUtil';

export const NAME_REQUIRED_MESSAGE = "Name is required"
export const FIRST_NAME_REQUIRED_MESSAGE = "First Name is required"
export const LAST_NAME_REQUIRED_MESSAGE = "Last Name is required"
export const CITY_REQUIRED_MESSAGE = "City is required"
export const ADDRESS_REQUIRED_MESSAGE = "Address is required"
export const STATE_REQUIRED_MESSAGE = "State is required"
export const ZIP_REQUIRED_MESSAGE = "Zip code is required"
export const PHONE_REQUIRED_MESSAGE = "Phone number is required"
export const SSN_REQUIRED_MESSAGE = "SSN is required"

export const spouseFormValidation = Yup.object({
    spouse_first_name: Yup.string().required(FIRST_NAME_REQUIRED_MESSAGE),
    spouse_last_name: Yup.string().required(LAST_NAME_REQUIRED_MESSAGE),
    spouse_city: Yup.string().required(CITY_REQUIRED_MESSAGE),
    spouse_address: Yup.string().required(ADDRESS_REQUIRED_MESSAGE),
    spouse_state: Yup.string().required(STATE_REQUIRED_MESSAGE),
    spouse_zip: Yup.string().required(ZIP_REQUIRED_MESSAGE),
    spouse_phone: Yup.string().required(PHONE_REQUIRED_MESSAGE),
    spouse_ssn: Yup.string().required(SSN_REQUIRED_MESSAGE),
})

export const ownerFormValidation = Yup.object({
    tax_payer_name: Yup.string().required(NAME_REQUIRED_MESSAGE),
    tax_payer_city: Yup.string().required(CITY_REQUIRED_MESSAGE),
    tax_payer_address: Yup.string().required(ADDRESS_REQUIRED_MESSAGE),
    tax_payer_state: Yup.string().required(STATE_REQUIRED_MESSAGE),
    tax_payer_zip: Yup.string().required(ZIP_REQUIRED_MESSAGE),
    tax_payer_phone: Yup.string().required(PHONE_REQUIRED_MESSAGE),
    tax_payer_ssn: Yup.string().required(SSN_REQUIRED_MESSAGE),
})

export const combinedFormValidation = ownerFormValidation.concat(spouseFormValidation);

export const spouseFormInitals = {
    spouse_first_name: '',
    spouse_last_name: '',
    spouse_address: '',
    spouse_ssn: '',
    spouse_city: '',
    spouse_phone: '',
    spouse_state: '',
    spouse_zip: '',
}


export const ownerInfoFormMapping = (config: Config) => [
    {   
        label: "Legal Name (Required)",
        type: "text",
        key:"tax_payer_name",
        errorMessage:"Required",
        showFloatingabel: true        
    },
    {
        label: "Social Security Number (Required)",
        type: "mask",
        key: "tax_payer_ssn",
        showFloatingLabel: true,
        regex: ssNRegex,
        minLength: 9,
        placeholder: "XXX-XX-XXXX"
    },
    {
        label: "Address (Required)",
        type: "text",
        key: "tax_payer_address",
        required: true,
        errorMessage:"Required",
        showFloatingLabel:true
    },
    {
        label: "City (Required)",
        type: "text",
        key: "tax_payer_city",
        required: true,
        errorMessage:"Required",
        showFloatingLabel:true
    },
    {
        type: "select",
        data: config.apiConfig.generic.states,
        optionKey: "name",
        optionValue: "abbr",
        placeholder: "State",
        label: "State",
        showFloatingLabel: true,
        key: "tax_payer_state",
        required: true
    },
    {   type: "mask",
        label: 'Zip Code (Required)',
        showFloatingLabel: true,
        key: "tax_payer_zip",
        regex: zipCodeRegex,
        minLength: 5,
        md: 5,

    },
    {   type: "mask",
        label: 'Phone (Required)',
        showFloatingLabel: true,
        key: "tax_payer_phone",
        regex: phoneNumberRegex,
        minLength: 10
    }
]

export const spouseInfoFormMappings = (config: Config) => [
    {
        label: "First Name (Required)",
        type: "text",
        key:"spouse_first_name",
        showFloatingabel: true        
    },
    {
        label: "Last Name (Required)",
        type: "text",
        key:"spouse_last_name",
        errorMessage:"Required",
        showFloatingabel: true        
    },
    {
        label: "Social Security Number (Required)",
        type: "mask",
        key: "spouse_ssn",
        showFloatingLabel: true,
        regex: ssNRegex,
        minLength: 9,
        placeholder: "XXX-XX-XXXX"
    },
    {
        label: "Address (Required)",
        type: "text",
        key: "spouse_address",
        required: true,
        errorMessage:"Required",
        showFloatingLabel:true
    },
    {
        label: "City (Required)",
        type: "text",
        key: "spouse_city",
        required: true,
        errorMessage:"Required",
        showFloatingLabel:true
    },
    {
        type: "select",
        data: config.apiConfig.generic.states,
        optionKey: "name",
        optionValue: "abbr",
        placeholder: "State",
        label: "State",
        showFloatingLabel: true,
        key: "spouse_state",
        required: true
    },
    {   type: "mask",
        label: 'Zip Code (Required)',
        showFloatingLabel: true,
        key: "spouse_zip",
        regex: zipCodeRegex,
        minLength: 5,
        md: 5,
    },
    {   type: "mask",
        label: 'Phone (Required)',
        showFloatingLabel: true,
        key: "spouse_phone",
        regex: phoneNumberRegex,
        minLength: 10
    }
]

