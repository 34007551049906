import { setAuthHeader, getResource } from './axiosService';
import {
    getFromLocalStorage,
    removeFromLocalStorage,
    setInLocalStorage,
} from './localStorageService';
import { getCookie, setCookie } from './cookieService'
import { 
    MYLO_AD_BLOCK,
    FOUND_AD_BLOCK,
    UENI_AD_BLOCK
} from '../components/dashboard/TodoSection/Todo.const'

const accessTokenKey = 'access_token';

const adOrder = [
    {
        'name' : MYLO_AD_BLOCK,
        'next' : FOUND_AD_BLOCK
    },
    {
        'name' : FOUND_AD_BLOCK,
        'next' : UENI_AD_BLOCK
    },
    {
        'name' : UENI_AD_BLOCK,
        'next' : MYLO_AD_BLOCK
    }
];

export const isLoggedIn = () => {
    return !!getFromLocalStorage(accessTokenKey);
};

export const storeAuthData = (auth: any) => {
    setInLocalStorage(accessTokenKey, auth.access_token);
    setInLocalStorage('auth', auth);
    setAuthHeader(auth.access_token);
};

export const getAuthData = () => {
    return getFromLocalStorage('auth') || {};
};

export const checkAuth = (back_uri: any) => {
    back_uri = back_uri || '';
    redirect(
        `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/oauth/v2/auth?` +
            'client_id=' +
            (window as any).ApiConfig.client_id +
            '&client_secret=' +
            (window as any).ApiConfig.client_secret +
            '&response_type=token' +
            '&redirect_uri=' +
            window.encodeURIComponent(
                window.location.href.replace(/#.*/, '#/') +
                    '?oauth' +
                    (back_uri ? '&back_uri=' + back_uri : '')
            )
    );
};

const redirect = (href: any) => {
    window.location.href = href;
};

const clearUserData = () => {
    removeFromLocalStorage(accessTokenKey);
    removeFromLocalStorage('auth');
    removeFromLocalStorage('current_account_id');
    removeFromLocalStorage('user');
    removeFromLocalStorage('accountant');
    removeFromLocalStorage('accounts');
    removeFromLocalStorage('current_business_id');
    removeFromLocalStorage('refresh_token');
    removeFromLocalStorage('pastDueModalFirstLoad');
    removeFromLocalStorage('catSettingNewTagDisplayName');
    removeFromLocalStorage('smart_category_confirm_modal_display');
    removeFromLocalStorage('dismissed_dashboard_blocks')
    removeFromLocalStorage('taxBenefitsModalFlag');
    removeFromLocalStorage('dataLoaded');
    setAuthHeader('');
};
export const clearAuthData = () => {
    // const accessToken = getFromLocalStorage(accessTokenKey);
     clearUserData();
     //logoutAuth(accessToken || '');
     //logoutAuth(accessToken || '');
 };
export const logout = () => {
    const accessToken = getFromLocalStorage(accessTokenKey);
    clearUserData();
    const adCookie = getCookie('adCookie');
    const findCookie = adOrder.find(function (item){
        return item.name === adCookie;
    });
    if(findCookie){
        setCookie('adCookie', findCookie.next, 10);
    }else if(!findCookie){
        setCookie('adCookie', MYLO_AD_BLOCK, 10);
    }
    logoutAuth(accessToken || '');
};

const logoutAuth = (accessToken: string) => {
    redirect(
        `${ process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/logout?` +
            'client_id=' +
            (window as any).ApiConfig.client_id +
            '&client_secret=' +
            (window as any).ApiConfig.client_secret +
            '&response_type=token' +
            '&access_token=' +
            accessToken +
            '&redirect_uri=' +
            window.encodeURIComponent(
                window.location.href.replace(/#.*/, '#/') + '?oauth'
            )
    );
};

export const refreshToken = () => {
    return new Promise((resolve) => {
        return getResource(
            `api/refresh_token?` +
                'client_id=' +
                (window as any).ApiConfig.client_id +
                '&client_secret=' +
                (window as any).ApiConfig.client_secret +
                ''
        ).then((response: any) => {
            storeAuthData({
                access_token: response.access_token,
                expires_in: response.expires_in,
                logged_in: new Date().getTime(),
            });
            setInLocalStorage('refresh_token', true);
            resolve(true);
        });
    });
};
