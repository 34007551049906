import { useState, useRef, useMemo } from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Typography,
    Paper,
    Divider,
    useTheme,
    InputAdornment,
    useMediaQuery,
    Theme,
} from '@material-ui/core'
import UiDialog from '../../../common/ui/UiDialog'
import {
    Formik,
    Form,
    FormikProps,
    FormikValues,
    FieldArray,
    Field,
    FieldProps,
} from 'formik'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'

import Loader from '../../../common/Loader'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { commonStyles, mergeStyle, toRem16 } from '../../../../styles/commonStyles'
import Icon from '../../../common/Icon'
import UiText from '../../../common/ui/UiText'
import { useInvoiceContext } from '../InvoiceProvider'
import Autocomplete, {
    AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'
import { BusinessDetails } from '../../../common/BusinessDetails'
import MenuDropdown from '../../../common/MenuDropdown'
import { handleCreateInvoice, handleUpdateInvoice } from '../actions'
import {
    paymentTermsOptions,
    paymentTermDays,
    paymentTerms,
    paymentTermsIndex,
} from '../InvoiceUtil'
import moment from 'moment'
import {
    InvoicePrice,
    validationSchema,
    NewInvoiceValues,
} from './InvoiceFormUtils'
import { InvoiceFormModalProps, ProductDetail } from '../../../../models'
import { OutlinedDeleteButton } from '../../../common/DeleteButton'
import ProductModal from '../../products-services/ProductModal'
import ContactModal from '../../contacts/modal/ContactModal'
import DiscardDraftModal from './DiscardDraftModal'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { currencyFormatter } from '../../../../utils/appUtil'
import { adjustToMiddayUTC } from '../../../../utils/dateUtil'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ReviewDialog } from '../../../common/ReviewDialog';

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    downloadButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`
    },
    pdfstyle: {
        marginLeft: '5px',
        marginRight: '10px',
    },
    autocompleteOption: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    deleteIcon: {
        '& .icon-div': {
            display: 'block',
        },
        '& .delete-icon': {
            display: 'none',
        },
        '&:hover': {
            '& .delete-icon': {
                display: 'block',
            },
            '& .icon-div': {
                display: 'none',
            },
        },
    },
    textMargin: {
        marginTop: '8px',
    },
    fieldMargin: {
        marginBottom: '12px',
    },
    buttonMargin: {
        marginRight: '4px',
    },
    calculationDivWidth: {
        width: '250px',
        height: '100px',
    },
    businessSection: {
        border: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        marginTop: '16px',
        padding: '8px',
        borderRadius: '4px',
    },
    amountMargin: {
        marginRight: '20px',
    },
    deleteIconStyle: {
        cursor: 'pointer',
        marginTop: '5px',
    },
    editIconRoot: {
        padding: '20px',
    },
    amountStyle: {
        marginRight: '5px',
        marginTop: '5px',
    },
    iconDiv: {
        width: '20px',
    },
    emptyDivWidth: {
        width: '74px',
    },
    buttonStyles: {
        height: `${toRem16(42)}`,
        marginTop: `${toRem16(8)}`
    }
}))

const EmptyInvoiceItem = {
    amount: '',
    offering: '',
    offering_data: {
        price: 0,
    },
    tax_rate: 0,
    offering_quantity: 1,
    price: 0,
}

const dueDateTimeStamp = (invoiceDueDate: number, selectedIndex: number) => {
    const momentVal = moment(invoiceDueDate * 1000)
        .add(paymentTermDays[selectedIndex], 'days')
        .valueOf()
    return Math.floor(momentVal / 1000)
}

const termChangeCallout = (
    formikValues: any,
    setFieldValue: any,
    selectedIndex: number
) => {
    setFieldValue('payment_terms', paymentTerms[selectedIndex])
    if (selectedIndex === 0) {
        setFieldValue('due_date', formikValues?.invoice_date)
    } else {
        const dateStamp = dueDateTimeStamp(
            formikValues?.invoice_date,
            selectedIndex
        )
        setFieldValue('due_date', dateStamp)
    }
}

const InvoiceFormModal = ({
    open,
    handleClose,
    isNew,
    modalTitle,
    reloadPage,
    invoiceDetails,
    setFetchCurrentInvoice,
    setShowDeleteConfirmation,
    onSuccessCallback,
    contactsData,
    productsData,
}: InvoiceFormModalProps) => {
    const { allContacts, allProducts, setRecallData, fetchInProgress } =
        useInvoiceContext()
    const theme = useTheme()
    const { currentAccount } = useCurrentStore()
    // isMobileDevice
    const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const common = commonStyles()
    const formikRef = useRef<FormikProps<FormikValues>>(null)
    const history = useHistory()
    const [submittingForm, setSubmittingForm] = useState(false)
    const [showProductModal, setShowProductModal] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)
    const [editableProduct, setEditableProduct] = useState(null)
    const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0)
    const [openDiscardChangeModal, setOpenDiscardChangeModal] = useState(false)
    const isMerchantApproved =
        !currentAccount || currentAccount.is_newtek_merchant_approved || currentAccount.is_payrix_merchant_approved

    const setFormFieldValue = (fieldName: string, value: any) => {
        formikRef?.current?.setFieldValue(fieldName, value)
    }

    const [reviewModalFlag, setReviewModalFlag] = useState(false);

    /**
     * @return invoice form initial value based on contacts or products data
     */
    const getFormValues = useMemo(() => {
        if (contactsData) {
            return {
                ...NewInvoiceValues,
                contact_data: contactsData,
                contact: contactsData?.id,
                payment_terms: contactsData?.payment_terms,
                due_date: dueDateTimeStamp(
                    NewInvoiceValues.invoice_date,
                    paymentTermsIndex[contactsData?.payment_terms]
                ),
            }
        }
        if (productsData) {
            const invoiceProducts = productsData.map((offering: any) => ({
                amount: '',
                offering: offering?.id,
                offering_data: offering,
                offering_quantity: 1,
                price: offering?.price,
                tax_rate: offering?.tax_rate,
            }))
            return {
                ...NewInvoiceValues,
                invoice_lines: [...invoiceProducts, EmptyInvoiceItem],   
            }
        } else {
            return NewInvoiceValues
        }
    }, [contactsData, productsData])

    let initialValues = {}

    if (isNew) {
        initialValues = {
            ...getFormValues,
            online_payment_enabled: isMerchantApproved,
        }
    } else {
        const invoiceLines = invoiceDetails.invoice_lines.map((data: any) => ({
            ...data,
            price: data.offering_data.price,
            tax_rate: data.offering_data.tax_rate,
        }))
        initialValues = {
            ...invoiceDetails,
            invoice_lines: [...invoiceLines, EmptyInvoiceItem],
        }
    }

    const checkIsTouched = () => {
        const formValues = formikRef?.current?.values
        if (JSON.stringify(initialValues) !== JSON.stringify(formValues)) {
            setOpenDiscardChangeModal(true)
        } else {
            handleClose()
        }
    }

    // get invoice product line wise data
    const getRowWiseData = (values: any, index: number, key: string) =>
        values?.invoice_lines?.[index]?.[key]

    const onSubmit = (data: any, formik: any) => {
        const utcInvoiceDate = moment(data?.invoice_date * 1000)
        const utcDueDate = moment(data?.due_date * 1000)
        const middayInvoiceTime = adjustToMiddayUTC(utcInvoiceDate)
        const middayDueTime = adjustToMiddayUTC(utcDueDate)

        let params = {
            ...data,
            ...(data?.payment_terms === '-'
                ? {
                      payment_terms: '',
                  }
                : {
                      payment_terms: data?.payment_terms,
                  }),
        }

        if (isNew) {
            params = {
                ...params,
                payment_status: 'unpaid',
                contact: data.contact_data?.id,
            }
        }
        const formattedParams = params.invoice_lines.map((data: any) => {
            const offeringData = data.offering_data
            const { category, ...others } = offeringData
            return {
                ...data,
                offering_data: {
                    ...others,
                    category: category?.id ?  category?.id : category,
                    price: data.price,
                    tax_rate: data.tax_rate,
                },
            }
        })

        params = {
            ...params,
            invoice_lines: formattedParams,
            invoice_date: middayInvoiceTime,
            due_date: middayDueTime,
        }

        setSubmittingForm(true)
        if (isNew) {
            handleCreateInvoice(
                params,
                (data) => {
                    handleClose()
                    reloadPage?.()
                    setSubmittingForm(false)
                    onSuccessCallback?.()
                    checkReviewWidget(data)
                },
                formik
            )
        } else {
            handleUpdateInvoice(
                invoiceDetails?.id,
                params,
                () => {
                    handleClose()
                    setFetchCurrentInvoice(true)
                    setSubmittingForm(false)
                },
                formik
            )
        }
    }

    const checkReviewWidget = (data: any) => {
        setReviewModalFlag(data.show_review_widget);
    };

    const preFillProductForm = (
        product: ProductDetail,
        itemIndex = selectedProductIndex
    ) => {
        setFormFieldValue(`invoice_lines.${itemIndex}.offering_data`, product ? product : '')
        setFormFieldValue(`invoice_lines.${itemIndex}.offering`, product?.id)
        setFormFieldValue(
            `invoice_lines.${itemIndex}.price`,
            product?.price || 0
        )
        setFormFieldValue(
            `invoice_lines.${itemIndex}.tax_rate`,
            product?.tax_rate || 0
        )
    }

    const InvoiceFormCalculations = (
        values: any,
        SubTotalPrice: any,
        TotalTaxPrice: any,
        TotalPrice: any
    ) => (
        <div className={mergeStyle(common.flex, common.flexEnd, common.mt16)}>
            <div
                className={mergeStyle(
                    common.flex,
                    common.spaceBetween,
                    classes.calculationDivWidth
                )}
            >
                <div>
                    {values?.use_taxes && (
                        <>
                            <UiText>Total before tax</UiText>
                            <UiText className={common.mt8}>Tax Due</UiText>
                        </>
                    )}
                    <UiText className={common.mt8}>Total Due</UiText>
                </div>

                <div className={common.textRight}>
                    {values?.use_taxes && (
                        <>
                            <UiText>
                                {currencyFormatter.format(
                                    parseFloat(SubTotalPrice)
                                )}
                            </UiText>
                            <UiText className={common.mt8}>
                                {currencyFormatter.format(
                                    parseFloat(TotalTaxPrice)
                                )}
                            </UiText>
                        </>
                    )}
                    <UiText
                        variant="suv_150"
                        weight="medium_500"
                        className={common.mt8}
                    >
                        {currencyFormatter.format(parseFloat(TotalPrice))}
                    </UiText>
                </div>
            </div>
        </div>
    )

    const AutoCompleteTextField = (
        params: AutocompleteRenderInputParams,
        props: FieldProps,
        errors?: any,
        fieldError?: any,
        selectedField?: string
    ) => {
        const isTouchedOrSubmitted =
            formikRef?.current?.touched[props.field.name] ||
            formikRef?.current?.submitCount
        const isHasFieldError =
            errors[props.field.name] || props?.meta?.error || fieldError
        const showError =
            !!(isTouchedOrSubmitted && isHasFieldError) && !props?.field.value

        return (
            <TextField
                {...params}
                size="small"
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                data-cy={`${selectedField}-input`}
                error={showError}
                helperText={showError ? isHasFieldError : ''}
            />
        )
    }

    const AutoCompleteRenderOption = (option: any) => (
        <div className={classes.autocompleteOption}>
            <div>{option?.title || option?.name}</div>
            <Typography variant="caption">{option?.type}</Typography>
        </div>
    )

    const menuLink = (
        { children, ...other }: any,
        selectedField: string,
        fieldIndex?: number
    ) => {
        return (
            <Paper {...other}>
                <Box borderBottom={`1px solid ${colorTheme.grey200}`}>
                    <Button
                        fullWidth
                        startIcon={<AddIcon />}
                        onMouseDown={(event) => {
                            event.preventDefault()
                        }}
                        onClick={(event) => {
                            event.preventDefault()
                            if (selectedField === 'contact_data') {
                                setShowContactModal(true)
                            } else {
                                setShowProductModal(true)
                                setSelectedProductIndex(fieldIndex as number)
                            }
                        }}
                    >
                        {`Create New ${
                            selectedField === 'contact_data'
                                ? 'Customer'
                                : 'Product'
                        }`}
                    </Button>
                </Box>
                {children}
            </Paper>
        )
    }

    const RenderNumberField = (
        minValue: number,
        fieldName: string,
        startIcon?: string,
        endIcon?: string
    ) => {
        return (
            <UiFormControlSelection
                type="number"
                min={minValue}
                fieldName={fieldName}
                onBlur={(e: any) => {
                    if (e?.target?.value) {
                        const numberVal = parseFloat(e?.target?.value)
                        formikRef?.current?.setFieldValue(
                            fieldName,
                            numberVal.toFixed(2)
                        )
                    }
                }}
                startIcon={startIcon}
                endIcon={endIcon}
                step={1}
            />
        )
    }

    const ContactAutocompleteDropdown = (
        options: any,
        selectedField: string,
        values: any,
        selectedValue?: any,
        errors?: any
    ) => {
        return (
            <Field name={selectedField}>
                {(props: FieldProps<any>) => {
                    return (
                        <Autocomplete
                            fullWidth
                            value={
                                values?.[selectedField]
                                    ? values?.[selectedField]
                                    : selectedValue
                            }
                            options={options as any}
                            placeholder="Select Product or Service"
                            getOptionLabel={(option: any) =>
                                option?.title || option?.name
                            }
                            renderInput={(
                                params: AutocompleteRenderInputParams
                            ) => {
                                const selectedFieldError =
                                    formikRef?.current?.errors[selectedField]

                                return AutoCompleteTextField(
                                    params,
                                    props,
                                    errors,
                                    selectedFieldError,
                                    selectedField
                                )
                            }}
                            onBlur={() => {
                                props?.form.setTouched({
                                    ...props.form.touched,
                                    [props.field.name]: true,
                                })
                            }}
                            renderOption={(option: any) =>
                                AutoCompleteRenderOption(option)
                            }
                            onChange={(event, item: any) => {
                                setFormFieldValue(
                                    'contact_data',
                                    item ? item : ''
                                )
                                setFormFieldValue(
                                    'contact',
                                    item ? item?.id : ''
                                )
                            }}
                            PaperComponent={(children) => {
                                return menuLink(children, selectedField)
                            }}
                        />
                    )
                }}
            </Field>
        )
    }

    const ProductAutocompleteOptions = (
        options: any,
        selectedField: string,
        values: any,
        selectedValue?: any,
        errors?: any,
        fieldIndex?: number,
        fieldError?: any,
        push?: any
    ) => {
        return (
            <Field name={selectedField}>
                {(props: FieldProps<any>) => {
                    return (
                        <Autocomplete
                            fullWidth
                            value={
                                values?.[selectedField]
                                    ? values?.[selectedField]
                                    : selectedValue
                            }
                            options={options as any}
                            placeholder="Select Product or Service"
                            getOptionLabel={(option: any) =>
                                option?.title || option?.name
                            }
                            renderInput={(
                                params: AutocompleteRenderInputParams
                            ) => {
                                const selectedFieldError =
                                    formikRef?.current?.errors[selectedField] ||
                                    fieldError
                                return AutoCompleteTextField(
                                    params,
                                    props,
                                    errors,
                                    selectedFieldError,
                                    selectedField
                                )
                            }}
                            onBlur={() => {
                                props?.form.setTouched({
                                    ...props.form.touched,
                                    [props.field.name]: true,
                                })
                            }}
                            renderOption={(option: any) =>
                                AutoCompleteRenderOption(option)
                            }
                            onChange={(event, item: any, reason: any) => {
                                const lastItem =
                                    values?.invoice_lines.length - 1 ===
                                    fieldIndex
                                if (lastItem && item != null) {
                                    push?.(EmptyInvoiceItem)
                                }
                                preFillProductForm(item, fieldIndex)
                            }}
                            PaperComponent={(children) => {
                                return menuLink(
                                    children,
                                    selectedField,
                                    fieldIndex
                                )
                            }}
                        />
                    )
                }}
            </Field>
        )
    }

    const changeDueDate = (time: any, formValues?: any) => {
        const dateTimeStamp = dueDateTimeStamp(
            time,
            paymentTermsIndex[formValues?.payment_terms]
        )
        setFormFieldValue('due_date', dateTimeStamp)
    }

    const renderDateUI = (label: string, fieldName: string) => (
        <UiFormControlSelection
            label={label}
            type="date"
            fieldName={fieldName}
            dateType="string"
            placeholder="DD/MM/YYYY"
            onDateChange={(dateTimeStamp: any) => {
                const formValues = formikRef?.current?.values
                const currentIndex =
                    paymentTermsIndex[formValues?.payment_terms]
                if (fieldName === 'invoice_date') {
                    if (currentIndex !== 0) {
                        changeDueDate(dateTimeStamp, formValues)
                    }
                } else {
                    setFormFieldValue('payment_terms', '')
                }
            }}
        />
    )

    const ButtonActions = (
        <Grid justify="flex-end" container className={classes.buttonStyles}>
            {submittingForm ? (
                <Loader />
            ) : (
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={common.mr8}
                        onClick={() => {
                            checkIsTouched()
                        }}
                        data-cy="cancel-invoice-changes"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const products = formikRef?.current?.values?.invoice_lines
                            
                            const filledProducts = products.filter((product: any) => {
                                if(product.offering_data?.title) {
                                    return product
                                } else {
                                    if(product.price !== 0 || product.price !== 0.0) {
                                        return product
                                    }
                                }
                             })

                             if(filledProducts.length === 0) {
                                // if all product lines are empty or product is unselected
                                const lastProduct = products.pop()
                                await formikRef?.current?.setFieldValue('invoice_lines', [lastProduct])
                             } else {
                                await formikRef?.current?.setFieldValue('invoice_lines', filledProducts)
                             }
                            formikRef?.current?.submitForm()
                        }}
                        data-cy="save-invoice-changes"
                    >
                        <>{isNew ? 'Save Changes' : 'Update'}</>
                    </Button>
                </>
            )}
        </Grid>
    )

    const BusinessEditSection = (
        <div className={mergeStyle(common.flex, common.spaceBetween)}>
            {BusinessDetails()}
            <IconButton
                classes={{ root: classes.editIconRoot }}
                onClick={() => {
                    history.push(`/settings/business`)
                }}
            >
                <Icon icon="edit" />
            </IconButton>
        </div>
    )

    const GridItem = ({ children }: any) => (
        <Grid item md={2} xs={12}>
            {children}
        </Grid>
    )

    const MessageToCustomer = () => (
        <Grid item md={7} xs={12}>
            <div className={common.mt32}>
                <UiFormControlSelection
                    label="Message to Customer (optional)"
                    type="textarea"
                    fieldName="message_to_customer"
                    showFloatingLabel={true}
                    rows={5}
                />
            </div>
        </Grid>
    )

    const EnableMerchantPayment = () => (
        <Grid item md={4} xs={12} className={common.mt32}>
            <UiFormControlSelection
                inlineLable="Enable Online Payment"
                type="checkbox"
                fieldName="online_payment_enabled"
            />
        </Grid>
    )

    const RenderTaxOption = () => (
        <div className={common.flex}>
            <UiText
                weight="medium_500"
                variant="hatchback_125"
                className={mergeStyle(common.mt16, common.mr16)}
            >
                Items
            </UiText>
            <div className={common.mt8}>
                <UiFormControlSelection
                    inlineLable="Show tax rate"
                    type="checkbox"
                    fieldName="use_taxes"
                />
            </div>
        </div>
    )

    const LineItemHeader = (values: any) => (
        <>
            <Grid container spacing={1} className={common.mtb16}>
                <Grid item md={values?.use_taxes ? 4 : 6} xs={12}>
                    Product
                </Grid>
                <GridItem>
                    <UiText textAlign="left">Quantity</UiText>
                </GridItem>
                <GridItem>
                    <UiText textAlign="left">Price</UiText>
                </GridItem>
                {values?.use_taxes && (
                    <GridItem>
                        <UiText textAlign="left"> Tax Rate</UiText>
                    </GridItem>
                )}
                <GridItem>
                    <UiText textAlign="right" className={classes.amountMargin}>
                        Amount
                    </UiText>
                </GridItem>
            </Grid>
            <Divider />
        </>
    )

    const EditProductButton = (values: any, index: number) => (
        <Button
            onClick={() => {
                setShowProductModal(true)
                setEditableProduct({
                    ...values.invoice_lines[index].offering_data,
                    id: values.invoice_lines[index].offering,
                })
                setSelectedProductIndex(index)
            }}
        >
            <Icon icon="edit" />
        </Button>
    )

    const ProductColumn = (
        values: any,
        index: number,
        offeringData: any,
        errors: any,
        productItemError: any,
        push: any
    ) => {
        return (
            <Grid
                item
                md={values?.use_taxes ? 4 : 6}
                xs={12}
                className={common.flex}
            >
                {ProductAutocompleteOptions(
                    allProducts,
                    `invoice_lines.${index}.offering`,
                    values,
                    offeringData,
                    errors,
                    index,
                    productItemError,
                    push
                )}
                {values.invoice_lines[index]?.offering_data?.title ? (
                    EditProductButton(values, index)
                ) : (
                    <div className={classes.emptyDivWidth}></div>
                )}
            </Grid>
        )
    }

    const DeleteRow = (values: any, remove: any, index: number) => (
        <>
            {index !== (values.invoice_lines.length - 1) ? (
                <>
                    <div
                        className={mergeStyle(
                            classes.deleteIconStyle,
                            'delete-icon'
                        )}
                        onClick={() => {
                            remove(index)
                        }}
                    >
                        <Icon icon="delete" svgColor={colorTheme.black200} />
                    </div>
                    <div
                        className={mergeStyle(classes.iconDiv, 'icon-div')}
                    ></div>
                </>
            ) : (
                <div className={classes.iconDiv}></div>
            )}
        </>
    )

    const LineItemCalculatedAmount = (
        priceQuantity: any,
        productPrice: any
    ) => {
        const linePrice = Math.round((priceQuantity * productPrice) * 100) / 100
        return (
        <div className={classes.amountStyle}>
            <UiText textAlign="right">
                {currencyFormatter.format(
                    parseFloat(linePrice.toFixed(2))
                )}
            </UiText>
        </div>
    )}

    return (
        <>
            {reviewModalFlag && <ReviewDialog/>}
            <ProductModal
                open={showProductModal}
                handleClose={() => {
                    setShowProductModal(false)
                }}
                isNew={!editableProduct}
                productCallback={(createdProduct: any) => {
                   
                    setRecallData(true)
                    setEditableProduct(null)
                    setShowProductModal(false)
                    preFillProductForm(createdProduct)
                    
                    const invoiceLines = formikRef?.current?.values?.invoice_lines;
                    const isLastIndex = (invoiceLines.length - 1) === selectedProductIndex;

                    if (isLastIndex) {
                        formikRef?.current?.setFieldValue("invoice_lines", [
                        ...invoiceLines,
                        EmptyInvoiceItem,
                        ]);
                    }
                }}
                showFormSaveOptions={!!editableProduct}
                product={editableProduct}
            />
            <ContactModal
                open={showContactModal}
                handleClose={() => setShowContactModal(false)}
                contactCallback={(response: any) => {
                    setRecallData(true)
                    if (!fetchInProgress) {
                        setRecallData(true)
                        setFormFieldValue('contact_data', response)
                    }
                }}
            />
            <DiscardDraftModal
                showConfirmModal={openDiscardChangeModal}
                handleParentModalClose={() => {
                    handleClose()
                }}
                handleModalClose={() => {
                    setOpenDiscardChangeModal(false)
                }}
            />
            <UiDialog
                open={open}
                handleClose={() => {
                    checkIsTouched()
                }}
                title={modalTitle}
                size="md"
                actions={ButtonActions}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                    innerRef={formikRef}
                >
                    {({
                        values,
                        setFieldValue,
                        errors,
                        touched,
                        setFieldError,
                        setErrors,
                    }) => {
                        let [SubTotalPrice, TotalTaxPrice, TotalPrice] =
                            InvoicePrice(
                                values?.invoice_lines,
                                values?.use_taxes
                            )
                        return (
                            <Form>
                                <div>
                                    <Grid
                                        container
                                        spacing={4}
                                        className={common.mt16}
                                    >
                                        <Grid item md={5} xs={12}>
                                            <div className={common.mt8}>
                                                <UiText
                                                    weight="medium_500"
                                                    className={
                                                        classes.fieldMargin
                                                    }
                                                >
                                                    Bill To (Required)
                                                </UiText>

                                                {ContactAutocompleteDropdown(
                                                    allContacts,
                                                    'contact_data',
                                                    values,
                                                    null,
                                                    errors
                                                )}
                                            </div>
                                        </Grid>

                                        <Grid item md={5} xs={12}>
                                            <div style={{ marginTop: '4px' }}>
                                                <UiText
                                                    weight="medium_500"
                                                    className={
                                                        classes.fieldMargin
                                                    }
                                                >
                                                    Bill From (Required)
                                                </UiText>

                                                {BusinessEditSection}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={4}
                                        className={common.mt16}
                                    >
                                        <Grid item md={5} xs={12}>
                                            <UiText
                                                className={classes.fieldMargin}
                                                weight="medium_500"
                                            >
                                                Issue Date
                                            </UiText>
                                            {renderDateUI(
                                                'Invoice Date',
                                                'invoice_date'
                                            )}
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <div>
                                                <div
                                                    className={mergeStyle(
                                                        common.flex,
                                                        common.spaceBetween,
                                                        common.mb10
                                                    )}
                                                >
                                                    <UiText weight="medium_500">
                                                        Due Date
                                                    </UiText>
                                                    <MenuDropdown
                                                        options={
                                                            paymentTermsOptions
                                                        }
                                                        selectedIndex={
                                                            paymentTermsIndex[
                                                                values
                                                                    ?.payment_terms
                                                            ]
                                                        }
                                                        setSelectedIndex={(
                                                            index: number
                                                        ) => {
                                                            termChangeCallout(
                                                                values,
                                                                setFieldValue,
                                                                index
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                {renderDateUI(
                                                    'Due Date',
                                                    'due_date'
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        {MessageToCustomer()}
                                        {isMerchantApproved &&
                                            EnableMerchantPayment()}
                                    </Grid>
                                    {RenderTaxOption()}
                                    {LineItemHeader(values)}
                                    <FieldArray name="invoice_lines">
                                        {({ push, remove }) => (
                                            <>
                                                {values.invoice_lines.map(
                                                    (
                                                        product: any,
                                                        index: any
                                                    ) => {
                                                        const priceQuantity =
                                                            getRowWiseData(
                                                                values,
                                                                index,
                                                                'offering_quantity'
                                                            )
                                                        const offeringData =
                                                            getRowWiseData(
                                                                values,
                                                                index,
                                                                'offering_data'
                                                            )
                                                        const productPrice =
                                                            getRowWiseData(
                                                                values,
                                                                index,
                                                                'price'
                                                            )

                                                        const invoiceLineError: any =
                                                            errors?.invoice_lines
                                                        const productItemError =
                                                            invoiceLineError?.[
                                                                index
                                                            ]?.offering_data
                                                                ?.title

                                                        return (
                                                            <>
                                                                <div
                                                                    key={`count-${index}`}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }
                                                                        className={mergeStyle(
                                                                            classes.deleteIcon,
                                                                            common.mt8
                                                                        )}
                                                                    >
                                                                        {ProductColumn(
                                                                            values,
                                                                            index,
                                                                            offeringData,
                                                                            errors,
                                                                            productItemError,
                                                                            push
                                                                        )}
                                                                        <GridItem>
                                                                            {RenderNumberField(
                                                                                0,
                                                                                `invoice_lines.${index}.offering_quantity`
                                                                            )}
                                                                        </GridItem>
                                                                        <GridItem>
                                                                            {RenderNumberField(
                                                                                0,
                                                                                `invoice_lines.${index}.price`,
                                                                                '$'
                                                                            )}
                                                                        </GridItem>

                                                                        {values?.use_taxes && (
                                                                            <GridItem>
                                                                                {RenderNumberField(
                                                                                    0,
                                                                                    `invoice_lines.${index}.tax_rate`,
                                                                                    '',
                                                                                    '%'
                                                                                )}
                                                                            </GridItem>
                                                                        )}
                                                                        <GridItem>
                                                                            <div
                                                                                className={mergeStyle(
                                                                                    common.flex,
                                                                                    common.flexEnd
                                                                                )}
                                                                            >
                                                                                {LineItemCalculatedAmount(
                                                                                    priceQuantity,
                                                                                    productPrice
                                                                                )}
                                                                                {DeleteRow(
                                                                                    values,
                                                                                    remove,
                                                                                    index
                                                                                )}
                                                                            </div>
                                                                        </GridItem>
                                                                    </Grid>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                    {InvoiceFormCalculations(
                                        values,
                                        SubTotalPrice,
                                        TotalTaxPrice,
                                        TotalPrice
                                    )}
                                    {invoiceDetails?.id && (
                                        <OutlinedDeleteButton
                                            className={common.mt16}
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth={isMobileDevice}
                                            onClick={() => {
                                                setShowDeleteConfirmation(true)
                                            }}
                                            showDeleteIcon
                                        >
                                            Delete invoice
                                        </OutlinedDeleteButton>
                                    )}
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </UiDialog>
        </>
    )
}

export default InvoiceFormModal
