import {
    Box,
    Button,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationStore } from '../../../models';
import UiDialog from '../../common/ui/UiDialog';
import UiFormField from '../../common/ui/UiFormField';
import { CalendarTodayOutlined } from '@material-ui/icons';
import moment from 'moment';
import Close from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { JournalQueryObject } from '../../../models/journalEntry-models';
import LinkButton from '../../common/LinkButton';

interface JournalFiltersProps {
    open: boolean;
    handleClose: (queryObject: JournalQueryObject | boolean) => void;
    journalFiltersConfig: {
        periods: {};
        amount: {
            operators: {
                eq: 'Equals';
                gt: 'Greater Than';
                lt: 'Less Than';
            };
        };
    };
    queryObject: JournalQueryObject;
    dataSource: any;
}

function JournalFilters({
    open,
    handleClose,
    journalFiltersConfig,
    queryObject,
    dataSource,
}: JournalFiltersProps) {
    const styles = makeStyles((theme: Theme) => ({
        autocomplete: {
            '& .MuiInputBase-fullWidth': {
                height: '40px',
                width: '100%',
                marginBottom: theme.spacing(1),
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                '& .MuiAutocomplete-input': {
                    padding: '0px',
                },
            },
        },
        textFieldRoot: {
            '& .MuiInputLabel-root': {
                top: '-7px',
            },
        },
    }))();

    const [categoryId, setCategoryId] = useState('');

    useEffect(() => {
        if (open) {
            if (!dataSource.categoryOptions) {
                let categoryOptions = Object.values(dataSource.accountRecord);
                categoryOptions.forEach((c: any) => {
                    if (c.parent) {
                        c.parent = categoryOptions.find(
                            (pc: any) => c.parent === pc.id
                        );
                    }
                });
                dataSource.categoryOptions = categoryOptions;
            }
            setCategoryId((val) => queryObject.category_id);
        }
    }, [open, dataSource, queryObject.category_id]);

    const onSubmit = (data: JournalQueryObject) => {
        let { amount, period, from_date, to_date, category_id, number } = data;

        if (period !== 'all' && period !== 'custom' && period !== 'quarter') {
            from_date = moment()
                .startOf(period as any)
                .unix();
            to_date = moment().endOf('day').unix();
        } else if(period === 'quarter'){
            from_date = moment().subtract(2, 'month').startOf('month').unix();
            to_date = moment().endOf('day').unix();
        }
        handleClose({
            from_date,
            to_date,
            period,
            amount,
            category_id,
            number,
        });
    };

    const setCategory = (item: any, formik: any) => {
        setCategoryId((val) => item?.id || '');
        formik.setFieldValue('category_id', item?.id || '');
    };

    const resteFilters = (formik: any) => {
        formik.setValues({
            category_id: '',
            from_date: moment().startOf('day').subtract(4, 'days').unix(),
            to_date: moment().endOf('day').unix(),
            period: 'all',
            amount: { value: '', operator: 'eq' },
            number: '',
        });
        setCategoryId('');
    };

    return (
        <UiDialog
            open={open}
            handleClose={() => handleClose(false)}
            hideTitleSection={true}
            title=''
            size='xs'
        >
            <Formik
                initialValues={queryObject}
                onSubmit={onSubmit}
                enableReinitialize
                validateOnMount={true}
            >
                {(formik) => {
                    return <>
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            mt={-2}
                            mb={2}
                            mr={-2}
                        >
                            <div>
                                <Typography
                                    variant='h6'
                                    style={{
                                        display: 'inline',
                                        marginRight: '8px',
                                    }}
                                >
                                    Filters
                                </Typography>
                                <LinkButton
                                    onClick={() => resteFilters(formik)}
                                >
                                    <Typography
                                        variant='body2'
                                        style={{ marginTop: '-4px' }}
                                    >
                                        Clear All
                                    </Typography>
                                </LinkButton>
                            </div>
                            <div>
                                <IconButton
                                    onClick={() => handleClose(false)}
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        </Box>
                        <Form>
                            <Grid container>
                                <Grid spacing={1} item xs={12} md={10}>
                                    <Typography
                                        variant='body2'
                                        gutterBottom
                                    >
                                        Category
                                    </Typography>
                                    <Autocomplete
                                        value={categoryId}
                                        onChange={(event, item) => {
                                            setCategory(item, formik);
                                        }}
                                        fullWidth={true}
                                        className={styles.autocomplete}
                                        options={dataSource?.categoryOptions.filter(
                                            (c: any) =>
                                                !c.is_hidden &&
                                                c.can_be_journal_entry_account
                                        )}
                                        getOptionLabel={(option: any) => {
                                            if (
                                                typeof option === 'string'
                                            ) {
                                                const data =
                                                    dataSource
                                                        ?.accountRecord[
                                                        option
                                                    ];
                                                return data
                                                    ? data?.title
                                                    : '';
                                            } else {
                                                return option.title;
                                            }
                                        }}
                                        renderOption={(option: any) => {
                                            let title;
                                            if (
                                                typeof option === 'string'
                                            ) {
                                                const data =
                                                    dataSource
                                                        ?.accountRecord[
                                                        option
                                                    ];
                                                title = data
                                                    ? data?.title
                                                    : '';
                                            } else {
                                                title = option.title;
                                            }
                                            return (
                                                <Fragment>
                                                    <div>
                                                        <Typography variant='body1'>
                                                            {title}
                                                        </Typography>

                                                        <Typography variant='caption'>
                                                            {
                                                                option
                                                                    .parent
                                                                    .title
                                                            }
                                                        </Typography>
                                                    </div>
                                                </Fragment>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    {...params}
                                                    label={false}
                                                    placeholder='Category'
                                                    variant='outlined'
                                                />
                                            </>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <UiFormField
                                        label='Date'
                                        fieldName='period'
                                        type='select'
                                        optionsData={Object.keys(
                                            journalFiltersConfig.periods
                                        ).map((key) => ({
                                            key,
                                            value: (journalFiltersConfig.periods as any)[
                                                key
                                            ],
                                        }))}
                                        optionKey='value'
                                        optionValue='key'
                                    />
                                </Grid>
                            </Grid>

                            {formik.values.period === 'custom' && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <UiFormField
                                            label={'Start'}
                                            type='date'
                                            fieldName='from_date'
                                            fastField={false}
                                            showFloatingLabel={true}
                                            labelSize={false}
                                            maxDate={moment(
                                                formik.values.to_date * 1000
                                            ).toDate()}
                                            endIcon={
                                                <CalendarTodayOutlined fontSize='small' />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <UiFormField
                                            fastField={false}
                                            label={'End'}
                                            type='date'
                                            fieldName='to_date'
                                            showFloatingLabel={true}
                                            labelSize={false}
                                            maxDate={moment().toDate()}
                                            minDate={moment(
                                                formik.values.from_date * 1000
                                            ).toDate()}
                                            endIcon={
                                                <CalendarTodayOutlined fontSize='small' />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={1}>
                                <Grid item xs={8} md={6}>
                                    <UiFormField
                                        label='Reference # (Equals)'
                                        fieldName='number'
                                        type='text'
                                        placeholder='Reference Number'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={9} md={6}>
                                    <UiFormField
                                        label='Amount ($)'
                                        fieldName='amount.operator'
                                        type='select'
                                        optionsData={Object.keys(
                                            journalFiltersConfig.amount
                                                .operators
                                        ).map((key) => ({
                                            key,
                                            value: (journalFiltersConfig
                                                .amount.operators as any)[
                                                key
                                            ],
                                        }))}
                                        optionKey='value'
                                        optionValue='key'
                                        containerBottomMargin={0}
                                    />
                                </Grid>
                                <Grid item xs={5} md={6}>
                                    <Hidden smDown>
                                        <Box pt={3} mt={2}></Box>
                                    </Hidden>
                                    <UiFormField
                                        label=''
                                        labelSize={false}
                                        fieldName='amount.value'
                                        type='number'
                                        placeholder='$ 0.00'
                                    />
                                </Grid>
                            </Grid>

                            <Box my={2} display='flex'>
                                <Button
                                    variant='outlined'
                                    type='button'
                                    onClick={() => handleClose(false)}
                                >
                                    Cancel
                                </Button>
                                <Box mx={2}>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        color='primary'
                                    >
                                        Apply Filters
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    </>;
                }}
            </Formik>
        </UiDialog>
    );
}

const mapStateToProps = ({ config }: ApplicationStore) => ({
    journalFiltersConfig: config.local.filters,
});
export default connect(mapStateToProps)(JournalFilters);
