import React, { useState, useContext, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '../../assets/icons-svg/Menu.svg';
import defaultLogo from '../../assets/logo/LogoMini.svg';
import AddIcon from '@material-ui/icons/Add';
import HistoryIcon from '@material-ui/icons/History';
import {
    makeStyles,
    Theme,
    IconButton,
    Hidden,
    Badge,
} from '@material-ui/core';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import { logout } from '../../services/authService';
import { connect } from 'react-redux';
import { AppData, ApplicationStore } from '../../models';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import ReferralModal from './referral/ReferralModal';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import { getPartnerLogo, isWhiteLabelClient } from '../common/whiteLabel/updateThemeAttibutes';

function AppToolbar({
    setDrawerOpen,
    appData,
    notification,
}: {
    setDrawerOpen: Function;
    appData: AppData;
    notification: any;
}) {
    const { colorTheme } = useThemeContext()
    const { activeRouteHeading, activeRouteHeadingIcon } = useContext(
        ActiveRoutingContext
    );
    const classes = makeStyles((theme: Theme) => {
        return {
            appBar: {
                [theme.breakpoints.up('md')]: {
                    boxShadow: 'none',
                },
                backgroundColor: colorTheme.primaryWhite,
                boxShadow: '0 1px 2px #F9F9F9', 
                color: theme.palette.text.primary,
                width: '100%',
                position: 'sticky',
                top: 0,
                left: 0,
                zIndex: 2
            },
            appBarTitle: {
                flexGrow: 1,
            },
            avatarButton: {
                backgroundColor: colorTheme.primaryWhite,
                height: 40,
                fontWeight: 'normal',
                fontSize: '1rem',
                border: `1px solid ${colorTheme.grey200}`, 
                textTransform: 'capitalize',
            },
            avatarButtonIcon: {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 8,
                paddingRight: 2,
            },
            listItemIconRoot: {
                minWidth: '44px',
            },
            menuButton: {
                marginRight: 0,
                [theme.breakpoints.up('md')]: {
                    marginRight: theme.spacing(2),
                    display: 'none',
                },
            },
            smToolbar: {
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
        };
    })();
    const [avatarEl, setAvatarEl] = useState<Element | null>(null);
    const handleClose = () => {
        setAvatarEl(null);
    };
    const [openReferralModal, setOpenReferralModal] = useState(false)
    const [logoPath, setLogoPath] = useState<string>('');
    const handleOpenReferralModal = () => {
        setOpenReferralModal(true);
    };
    const {user} = appData;
    const sf_contact_id = 'sf_contact_id';
    const sf_lead_id = 'sf_lead_id';
    const sf_id = user.hasOwnProperty(sf_contact_id) ? user.sf_contact_id : user.hasOwnProperty(sf_lead_id) ? user.sf_lead_id : null;
    
    useEffect(() =>{
        if(!user || !user.partner_details){
            setLogoPath(defaultLogo);
            return;
        }
        const partnerLogoUrl = getPartnerLogo(user.partner_details.partner_key ?? '');
        setLogoPath(partnerLogoUrl);
     },[user])
     
    return (
        <>
            <ReferralModal
                open={openReferralModal}
                handleClose={() => {
                    setOpenReferralModal(false)
                }}
                user={user?.id}
                sf_id={sf_id}
            />
        <AppBar className={classes.appBar}>
            <Hidden mdUp implementation="css">
                <Toolbar classes={{ root: classes.smToolbar }}>
                    <Badge
                        color="primary"
                        variant={notification?.count ? 'dot' : undefined}
                        onClick={() => setDrawerOpen(true)}
                    >
                        <img alt="Menu" src={MenuIcon} />
                    </Badge>
                    <Link to="/dashboard">
                        <img src={logoPath} alt="1800-Accountant logo" />
                    </Link>
                    <IconButton
                        className={classes.menuButton}
                        onClick={(e) => {
                            setAvatarEl(e.currentTarget);
                        }}
                    >
                        <img
                            alt={`User Icon`}
                            src={
                                require(`../../assets/icons-svg/Profile.svg`)
                                    .default
                            }
                        />
                    </IconButton>
                </Toolbar>
            </Hidden>
            <Hidden smDown implementation="css">
                <Toolbar>
                    <Typography variant="h6" className={classes.appBarTitle}>
                        {activeRouteHeading || 'Dashboard'}
                        {activeRouteHeadingIcon}
                    </Typography>

                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.avatarButton}
                        onClick={(e) => {
                            setAvatarEl(e.currentTarget);
                        }}
                        data-cy="open-user-popover-btn"
                    >
                        {appData.user.first_name} {appData.user.last_name}
                        <div className={classes.avatarButtonIcon}>
                            <img
                                alt={`User Icon`}
                                src={
                                    require(`../../assets/icons-svg/Profile.svg`)
                                        .default
                                }
                            />
                        </div>
                        {!!avatarEl ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </Button>
                </Toolbar>
            </Hidden>
            <Menu
                open={!!avatarEl}
                anchorEl={avatarEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                onClose={(e) => {
                    setAvatarEl(null);
                }}
                MenuListProps={{ disablePadding: true }}
                elevation={4}
            >
                <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/profile/details"
                    data-cy="go-to-detail-link"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <img
                            alt={`Payroll`}
                            src={
                                require(`../../assets/icons-svg/User.svg`)
                                    .default
                            }
                        />
                    </ListItemIcon>
                    <ListItemText primary="My Details" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleOpenReferralModal}>
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <SentimentSatisfiedAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Refer a Business" />
                </MenuItem>
                <MenuItem style={{ pointerEvents: 'none' }}>
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}/>
                    <Alert
                        icon={false}
                        severity="info"
                    >
                        <Typography>
                            Get a $100 renewal credit.
                        </Typography>
                        <Typography>
                            Terms apply.
                        </Typography>
                    </Alert>
                </MenuItem>
                {!appData.user.is_lead && (
                    <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to="/purchase/billing"
                        data-cy="goto-billing-btn"
                    >
                        <ListItemIcon
                            classes={{ root: classes.listItemIconRoot }}
                        >
                            <img
                                alt={`Payroll`}
                                src={
                                    require(`../../assets/icons-svg/Payroll.svg`)
                                        .default
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Billing" />
                    </MenuItem>
                )}
                <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/purchase/products"
                    data-cy="goto-products-btn"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Additional Products" />
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/purchase/upgrade-portal-access"
                    data-cy="goto-upgrade-portal-access-btn"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upgrade Portal Access" />
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/purchase/partner-products"
                    data-cy="goto-partner-products-btn"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Partner Products" />
                </MenuItem>
                {
                    !isWhiteLabelClient() && 
                    <>
                    <MenuItem
                        onClick={handleClose}
                        component="a"
                        href="/portal-classic/"
                        data-cy="goto-classic-portal-btn"
                    >
                        <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                            <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Switch back to Classic Mode" />
                    </MenuItem>
                    <Divider />
                  </>
            }
                
                <MenuItem
                    onClick={handleClose}
                    component="a"
                    href="/privacy-policy/"
                    target="_blank"
                    data-cy="goto-policy-btn"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <DescriptionOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    component="a"
                    href="/terms-of-services/"
                    target="_blank"
                    data-cy="goto-tos-btn"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <DescriptionOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terms of Service" />
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={(e) => {
                        logout(); 
                    }}
                    data-cy="sign-out-btn"
                >
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </MenuItem>
            </Menu>
        </AppBar>
        </>
    );
}
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    notification: state.count['total-notification-count'],
});
export default connect(mapStateToProps)(AppToolbar);
