import { makeStyles, Theme, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { COLORS } from '../../../variables/colors';

interface UiSectionProps {
    sectionHeading?: any;
    expanded: boolean;
    children: any;
    backgroundColor?: string;
}

const Accordion = withStyles({
    root: {
        border: `1px solid ${COLORS.grey200}`,
        boxShadow: 'none',
        background: COLORS.primaryWhite,
        borderRadius: '8px !important',
        margin: '16px 0 !important',
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: `1px solid ${COLORS.grey800}`,
        padding: '5px 16px',
        minHeight: '32px !important'
    },
    content: {
        margin: '0 0 !important'
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3)
    },
}))(MuiAccordionDetails);

export default function UiSection(props: UiSectionProps) {
    const { sectionHeading, expanded, children } = props;
    const styles = makeStyles({
        sectionHeading: {
            fontWeight: 500,
            width: '100%'
        },
        accordionRoot: {
            backgroundColor: props.backgroundColor || COLORS.primaryWhite,
            '&:first-child': {
                marginTop: '0 !important'
            },
            '&:last-child': {
                marginBottom: '0 !important'
            }
        }
    })();

    return (
        <Accordion classes={{ root: styles.accordionRoot }} expanded={expanded}>
            {
                sectionHeading && (
                    <AccordionSummary>
                        <Typography classes={{ root: styles.sectionHeading }} variant="subtitle1">{sectionHeading}</Typography>
                    </AccordionSummary>
                )
            }
            <AccordionDetails>
                <Typography component="div" className="w-100">
                    {children}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}
