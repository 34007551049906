import {
    Action,
    SET_ALERT,
    SET_API_ERROR,
    SET_INFO,
} from '../actions/feedback';

export default function feedback(state = {}, action: Action): any {
    switch (action.type) {
        case SET_API_ERROR:
            return { ...state, error: action.state };
        case SET_ALERT:
            return { ...state, alert: action.state };
        case SET_INFO:
            return { ...state, info: action.state };
        default:
            return state;
    }
}
