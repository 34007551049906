import {
    Grid,
    Hidden,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import { useState, useRef, Fragment } from 'react'
import { useADPCompanyContext } from '../provider/CompanyProvider'
import {
    ADPCompanyDetails,
    CompanyInfo,
    OnboardingStepInfo,
} from '../../../models/adp-payroll/company'
import SearchIcon from '@material-ui/icons/Search'

import FormHeaderText from '../../payroll/common/FormHeaderText'
import Loader from '../../common/Loader'
import UiFormField from '../../common/ui/UiFormField'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import NextActionButton from '../../payroll/common/NextActionButton'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { BusinessInfoFormSchema } from './ValidationSchema/BusinessInformationSchema'
import UiText from '../../common/ui/UiText'
import {
    markStepAsCompleted,
    updateBusinessInformation,
} from '../../../services/apiService/adp-payroll/company'
import { showAlert, showInfo } from '../../../store/actions/feedback'
import { useDispatch } from 'react-redux'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import UiButton from '../../common/ui/UiButton'
import { useStyles } from './CompanyCommonStyles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useCreationStyles = makeStyles((theme: Theme) => ({
    formStyle: {
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: "5rem"
        }
    },
    fieldMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    contentMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    questionStyle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}))

const INITIAL_DATA = {
    doing_business_as: '',
    business_name: '',
    ein: '',
    business_type: '',
    federal_filing_frequency: 'Quarterly',
    deposit_frequency: 'Monthly',
    industry: '',
    city: '',
    zip_code: '',
    street: '',
    state: '',
    country: 'US',
    street_2: ' ',
}

enum ADDRESS_COMPONENTS {
    STREET_NUMBER = 'street_number',
    ROUTE = 'route',
    LOCALITY = 'locality',
    STATE = 'administrative_area_level_1',
    COUNTRY = 'country',
    ZIP_CODE = 'postal_code',
    COUNTY = 'administrative_area_level_2',
}

const CompanyCreation = ({
    refreshSteps,
    goToPreviousSection
}: {
    refreshSteps: () => void
    goToPreviousSection: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const { currentConfig } = useCurrentStore()
    const styles = useCreationStyles()
    const classes = useStyles(colorTheme)
    const [loading, setLoading] = useState(false)
    const [formattedAddress, setFormattedAddress] = useState('')
    const configStates = currentConfig.apiConfig.generic.states
    const industries = Object.entries(
        currentConfig.apiConfig.payroll_org.company.adp_industry_titles,
    ).map(([name, id]) => ({ name, id }))
    const businessTypes = Object.entries(
        currentConfig.apiConfig.payroll_org.company.adp_business_types,
    ).map(([title, id]) => ({ title, id }))
    const {
        companyDetails,
        companyStepInfo,
        loadingStepData,
        setRefetchCompany,
        isCompanyOnboarding,
        isCompanyActive,
        formikRef,
    }: {
        companyDetails: ADPCompanyDetails
        companyStepInfo: OnboardingStepInfo
        loadingStepData: boolean
        setRefetchCompany: React.Dispatch<React.SetStateAction<boolean>>
        isCompanyOnboarding: boolean
        isCompanyActive: boolean
        formikRef: any
    } = useADPCompanyContext()
    const dispatch = useDispatch()
    const { isSmDevice, isXsDevice } = useDeviceSize()
    const autoCompleteRef = useRef<any>()

    const disableCompanyEdit = isCompanyOnboarding || isCompanyActive

    const companyCreated = companyStepInfo.adp_company_created && isCompanyActive;
    const isBusinessInfoCompleted =
        companyStepInfo.onboarding_steps_status.company_combined_data_collection
    let initialValues: CompanyInfo = { ...INITIAL_DATA }

    if (isBusinessInfoCompleted) {
        const businessDetails = companyDetails?.companyDetails as CompanyInfo
        initialValues = { ...businessDetails }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: (window as any).ApiConfig.google_maps_api_key,
        libraries: ['places'],
    })

    const onSubmit = (data: any, { setFieldError }: FormikHelpers<any>) => {
        setLoading(true)
        updateCombinedBusinessInfo({ ...data, country: 'US' }, setFieldError)
    }

    const getStateAbbr = (stateName: string): string | null => {
        const state = currentConfig.apiConfig.generic.states.find(
            (item) => item.name.toLowerCase() === stateName.toLowerCase(),
        )
        return state ? state.abbr : null
    }

    const updateCombinedBusinessInfo = (data: any, setFieldError?: any) => {
        data.companyCreated = companyCreated;
        updateBusinessInformation(data)
            .then((res) => {
                if (res) {
                    setLoading(false)
                    markCompanyCreateStepAsComplete()
                    refreshSteps()
                    setRefetchCompany(true)
                }
            })
            .catch((error) => {
                setLoading(false)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            error?.message ||
                            error?.statusText ||
                            'Something went wrong',
                    }),
                )
                if (error?.errors && setFieldError) {
                    Object.keys(error.errors).forEach((key) => {
                        setFieldError(key, error.errors[key][0])
                    })
                }
                if (error.errors?.length > 0) {
                    dispatch(
                        showInfo({
                            infoData: (
                                <Fragment>
                                    <UiText>
                                        {' '}
                                        Possible Correct address could be{' '}
                                    </UiText>
                                    <ul>
                                        {error.errors.map((address: any) => (
                                            <li key={address}>
                                                {address?.address1}{' '}
                                                {address?.address2},{' '}
                                                {address?.city},{' '}
                                                {address?.postalCode},{' '}
                                                {address?.stateCode}
                                            </li>
                                        ))}
                                    </ul>
                                </Fragment>
                            ),
                        }),
                    )
                }
            })
    }

    const markCompanyCreateStepAsComplete = () => {
        markStepAsCompleted({ step: 'company_combined_data_collection' }).then(
            (res) => {
                if (res) {
                    setLoading(false)
                    refreshSteps()
                }
            },
        )
    }

    return (
        <Grid
            style={{
                height: 'calc(100% - 32px)',
                overflowX: 'hidden',
                overflowY: loadingStepData ? 'hidden' : 'auto',
            }}
        >
            <FormHeaderText
                heading="Business Information"
                hideOnMobile={isSmDevice}
            />
            {loadingStepData ? (
                <Loader />
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={BusinessInfoFormSchema} // Provide the imported 'businessFormSchema' here
                    enableReinitialize
                    innerRef={formikRef}
                >
                    {(formik) => {
                        return (
                            <div className={styles.formStyle}>
                                <UiText
                                    variant="hatchback_125"
                                    weight="semi_bold_600"
                                    className={styles.contentMargin}
                                >
                                    Details
                                </UiText>
                                <Form>
                                    <div className={styles.fieldMargin}>
                                        <UiFormField
                                            fastField={false}
                                            type="text"
                                            labelSize={0}
                                            label={'Business Name (Required)'}
                                            showFloatingLabel={true}
                                            fieldName="business_name"
                                            helperText={
                                                'The legal name of your business. This is not your DBA.'
                                            }

                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormField
                                            fastField={false}
                                            type="text"
                                            labelSize={0}
                                            label={
                                                'Doing business as (DBA) (Optional)'
                                            }
                                            showFloatingLabel={true}
                                            fieldName="doing_business_as"
                                            helperText={
                                                'This is also known as a trade name, assumed name, or fictitious name. This cannot be adjusted after saving.'
                                            }
                                            disabled={companyCreated}
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormControlSelection
                                            type="mask"
                                            disabled={companyCreated}
                                            label={
                                                'Employer Identification Number (EIN) (Required)'
                                            }
                                            showFloatingLabel={true}
                                            fieldName="ein"
                                            mask={[
                                                /[0-9]/,
                                                /[0-9]/,
                                                '-',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                            minLength={9}
                                            helperText="An EIN must be 9 digits formatted XX-XXXXXXX."
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormControlSelection
                                            type="autocomplete"
                                            disabled={companyCreated}
                                            label={'Industry (Required)'}
                                            showFloatingLabel={true}
                                            fieldName="industry"
                                            optionsData={industries}
                                            optionKey="name"
                                            optionValue="name"
                                            cypressId={'business-industries'}
                                            helperText="This will ensure compliance with all regulations unique to your business industry."
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormControlSelection
                                            label="Business Type (Required)"
                                            type="select"
                                            disabled={companyCreated}
                                            optionsData={businessTypes}
                                            optionKey="title"
                                            optionValue="id"
                                            fieldName="business_type"
                                            required={true}
                                            errorMessage={
                                                'Business Type Required'
                                            }
                                            showFloatingLabel={true}
                                            helperText="The business type enables payroll and taxes to be paid and filed correctly."
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.questionStyle}>
                                        <UiText
                                            variant="moped_75"
                                            textColor="secondary"
                                        >
                                            (Required)
                                        </UiText>
                                        <UiText>
                                            Federal Tax Filing Frequency
                                        </UiText>
                                        <div className={styles.fieldMargin}>
                                            <UiFormControlSelection
                                                type="radio"
                                                disabled={companyCreated}
                                                label="Federal Filing Frequency"
                                                fieldName="federal_filing_frequency"
                                                radioOptionsData={[
                                                    {
                                                        label: 'Quarterly (Form 941)',
                                                        value: 'Quarterly',
                                                    },
                                                    {
                                                        label: 'Annually (Form 940)',
                                                        value: 'Annual',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.questionStyle}>
                                        <UiText
                                            variant="moped_75"
                                            textColor="secondary"
                                        >
                                            (Required)
                                        </UiText>
                                        <UiText>
                                            Federal Tax Deposit Frequency
                                        </UiText>
                                        <div className={styles.fieldMargin}>
                                            <UiFormControlSelection
                                                type="radio"
                                                disabled={companyCreated}
                                                label="Federal Filing Frequency"
                                                fieldName="deposit_frequency"
                                                radioOptionsData={[
                                                    {
                                                        label: 'Monthly',
                                                        value: 'Monthly',
                                                    },
                                                    {
                                                        label: 'Every other week',
                                                        value: 'SemiWeekly',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <UiText
                                        className={styles.contentMargin}
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                    >
                                        Business Filing Address
                                    </UiText>
                                    <div className={styles.contentMargin}>
                                        {isLoaded && (
                                            <StandaloneSearchBox
                                                onLoad={(ref: any) => {
                                                    autoCompleteRef.current =
                                                        ref
                                                }}
                                                onPlacesChanged={() => {
                                                    const places =
                                                        autoCompleteRef?.current?.getPlaces()
                                                    if (places.length) {
                                                        const address =
                                                            places[0]
                                                        setFormattedAddress(
                                                            address.formatted_address
                                                        )

                                                        const addressComponents =
                                                            address.address_components

                                                        const addressComponentsMap =
                                                            addressComponents.reduce(
                                                                (
                                                                    acc: any,
                                                                    component: any,
                                                                ) => {
                                                                    acc[
                                                                        component.types[0]
                                                                    ] =
                                                                        component.long_name
                                                                    return acc
                                                                },
                                                                {} as Record<
                                                                    string,
                                                                    string
                                                                >,
                                                            )
                                                        formik.setFieldValue(
                                                            'street',
                                                            `${
                                                                addressComponentsMap[
                                                                    ADDRESS_COMPONENTS
                                                                        .STREET_NUMBER
                                                                ]
                                                            } ${
                                                                addressComponentsMap[
                                                                    ADDRESS_COMPONENTS
                                                                        .ROUTE
                                                                ]
                                                            }`,
                                                        )
                                                        formik.setFieldValue(
                                                            'city',
                                                            addressComponentsMap[
                                                                ADDRESS_COMPONENTS
                                                                    .LOCALITY
                                                            ],
                                                        )
                                                        formik.setFieldValue(
                                                            'state',
                                                            getStateAbbr(
                                                                addressComponentsMap[
                                                                    ADDRESS_COMPONENTS
                                                                        .STATE
                                                                ],
                                                            ),
                                                        )
                                                        formik.setFieldValue(
                                                            'zip_code',
                                                            addressComponentsMap[
                                                                ADDRESS_COMPONENTS
                                                                    .ZIP_CODE
                                                            ],
                                                        )
                                                    }
                                                }}
                                            >
                                                <TextField
                                                    disabled={companyCreated}
                                                    placeholder={
                                                        'Search Location'
                                                    }
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    size={
                                                        isXsDevice
                                                            ? 'medium'
                                                            : 'small'
                                                    }
                                                    fullWidth
                                                    value={formattedAddress}
                                                    onChange={(e) =>
                                                        setFormattedAddress(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                    </div>

                                    <div className={styles.fieldMargin}>
                                        <UiFormField
                                            fastField={false}
                                            type="text"
                                            labelSize={0}
                                            label={'Street (Required)'}
                                            disabled={companyCreated}
                                            showFloatingLabel={true}
                                            fieldName="street"
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormField
                                            fastField={false}
                                            type="text"
                                            labelSize={0}
                                            disabled={companyCreated}
                                            label={'Street 2 (Optional)'}
                                            showFloatingLabel={true}
                                            fieldName="street_2"
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormField
                                            fastField={false}
                                            type="text"
                                            labelSize={0}
                                            disabled={companyCreated}
                                            label={'City (Required)'}
                                            showFloatingLabel={true}
                                            fieldName="city"
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormControlSelection
                                            type="autocomplete"
                                            optionsData={configStates}
                                            optionKey="name"
                                            optionValue="abbr"
                                            placeholder="State"
                                            label="State"
                                            disabled={companyCreated}
                                            showFloatingLabel={true}
                                            fieldName="state"
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <UiFormControlSelection
                                            type="mask"
                                            disabled={companyCreated}
                                            label={'Zip Code (Required)'}
                                            showFloatingLabel={true}
                                            fieldName="zip_code"
                                            mask={[
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                            minLength={5}
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                    </div>
                                    <div className={styles.fieldMargin}>
                                        <Hidden smDown>
                                            {!disableCompanyEdit && (
                                                <NextActionButton
                                                    loading={loading}
                                                    submitAction={
                                                        formik.submitForm
                                                    }
                                                    arrowIcon={true}
                                                />
                                            )}
                                            {companyCreated && <> {
                                                loading ? 
                                                        <Loader justifyContent='flex-start' size={30} /> : 
                                                        <UiButton
                                                            btnType="tertiary"
                                                            handleClick={formik.submitForm}
                                                            label="Update"
                                                        />
                                                    }
                                                </>
                                            }
                                        </Hidden>

                                        <Hidden mdUp>
                                            {!disableCompanyEdit && (
                                                <div
                                                    className={
                                                        classes.nextPrevContainer
                                                    }
                                                >
                                                    <NextActionButton
                                                        customClass={
                                                            classes.nxtBtn
                                                        }
                                                        loading={loading}
                                                        justifyLoader='center'
                                                        submitAction={formik.submitForm}
                                                        arrowIcon={true}
                                                    />
                                                </div>
                                            )}
                                            {companyCreated && <> {
                                                loading ? 
                                                        <Loader justifyContent='flex-start' size={30} /> : 
                                                        <>
                                                            <div
                                                                className={
                                                                    classes.nextPrevContainer
                                                                }
                                                            >
                                                            <UiButton
                                                                btnType="tertiary"
                                                                handleClick={formik.submitForm}
                                                                label="Update"
                                                                fullWidth
                                                                />
                                                            </div>
                                                        </>
                    
                                                    }  </>
                                                }
                                        </Hidden>
                                    </div>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            )}
        </Grid>
    )
}

export default CompanyCreation
