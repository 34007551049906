import { useState } from 'react';
import { Button, Typography, makeStyles, Theme } from '@material-ui/core'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import { deleteReconciliationById } from '../../../services/apiService/reconciliations'
import { ApplicationStore } from '../../../models'
import { connect } from 'react-redux'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    warningModal: {
        '& div:first-child': {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
}))

const DeleteLastReconciliation = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const [loading, setLoading] = useState(false)

    const deleteReconciliation = () => {
        setLoading(true)
        if (props?.data.length > 0) {
            let inProgressItem = props?.data.filter(
                (item: any) => !item.is_submitted
            )?.[0]
            let itemId = inProgressItem?.id
            deleteReconciliationById(props.accountId, props.businessId, itemId)
                .then((res) => {
                    setLoading(false)
                    props?.reloadReconciliationData()
                    props?.setShowNewModal(true)
                    props?.setOpenWarningDialog(false)
                })
                .catch((err) => {
                    setLoading(false)
                    props?.setOpenWarningDialog(false)
                })
        }
    }

    return (
        <UiConfirmationDialog
            open={props?.openWarningDialog}
            message={
                <>
                    <Typography
                        variant="body1"
                        className={classes.warningModal}
                        gutterBottom
                    >
                        <div>Start New Reconciliation?</div>
                        <div>
                            Your incomplete reconciliation and all progress made
                            on it will be deleted from your account.
                        </div>
                        <div>This cannot be undone.</div>
                    </Typography>
                </>
            }
            handleClose={() => {
                props?.setOpenWarningDialog(false)
            }}
            showLoader={loading}
            confirmNode={
                <Button
                    variant="contained"
                    onClick={deleteReconciliation}
                    color="primary"
                >
                    Start New
                </Button>
            }
            cancelButtonText="Cancel"
        />
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    accountId: state.appData.current_account_id,
    businessId: state.appData.current_business_id,
})

export default connect(mapStateToProps)(DeleteLastReconciliation)
