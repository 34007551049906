import { useState, useRef, useCallback, useEffect } from 'react'
import UiModal from '../../../common/ui/UiModal'
import { IconButton } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import UiText from '../../../common/ui/UiText'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { InformationBlock } from '../../../contractor/InformationBlock'
import UiButton from '../../../common/ui/UiButton'
import FinCenDataCollectionForm from './FinCenDataCollectionForm'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { formSubmitErrorHandler } from '../../../../services/formService'
import {
    getBusinessDetails,
    saveBusinessDetails,
} from '../../../../services/apiService'
import { loadPostAuthData } from '../../../../store/actions/appData'
import Loader from '../../../common/Loader'
import { connect, useDispatch } from 'react-redux'
import { ApplicationStore } from '../../../../models'
import { useWidgetContext } from '../../tax-prep/provider/WidgetProvider'
import FinCenSucessModal from './FinCenSucessModal'
import { hideDriftWidget, showDriftWidget } from '../../../../utils/utility'

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    modal: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: "100vw"
        },
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
       
    },
    modalContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '40rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        height: '75vh',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: `${window.innerHeight}px`,
            width: '100%',
            borderRadius: 0,
            overflow: 'auto',
        },
        '&:focus-visible': {
            outline: 'none',
        },
    },
    infoContainer: {
        width: '90%',
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1.375rem',
        boxSizing: 'border-box',
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '1rem',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        flex: 0,
        [theme.breakpoints.down('sm')]: {
            position: 'sticky',
            top: 0,
            zIndex: 1,
            padding: '1rem',
            '& .MuiIconButton-root': {
                display: 'none',
            }
        },
    },
    modalContent: {
        padding: '1.5rem',
        flex: 1,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
           width: '100%',
           boxSizing: 'border-box',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
         },
    },
    closeIconButton: {
        padding: 0,
    },
    buttonContainer: {
        padding: '0.7rem 1rem',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '1rem',
        flex: 0,
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column-reverse',
            boxSizing: 'border-box',
            '& .MuiButtonBase-root': {
                width: '100%',
            }
         },
    },
    scrollIconContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: (colorTheme) => colorTheme.primaryWhite,
        backgroundColor: (colorTheme) => colorTheme.primary,
        height: "3.5rem",
        width: "3.5rem",
        borderRadius: "50%",
        right: '19rem',
        bottom: '6rem',
        zIndex: 1,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
           display: 'none'
         },
    },
    loaderContainer: {
        position: 'absolute',
        width: '640px',
        height: '726px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0.5rem',
        zIndex: 1,
    },
    modalContentContainerWithAdminHeader : {
        [theme.breakpoints.down('sm')]: {
            top:'20px',
            height: `${window.innerHeight - 40}px`,
        }, 
    }
}))

let initialValues: any = {
    name: '',
    ein: '',
    business_dba_name: '',
    description: '',
    industry: '',
    business_type: '',
    form_date: null,
    form_state: '',
    address: {
        address: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
    },
    business_owners: [
        {
            tax_payer_name: '',
            dob_date: null,
            address: {
                address: '',
                city: '',
                state: '',
                country: 'US',
                zip_code: '',
            },
            ssn: '',
            owner_uin: '',
            owner_uin_number: '',
            owner_uin_file_link: '',
            owner_uin_file_details: null,
            ownership: 0,
        },
    ],
    business_phone: '',
    business_info: {
        was_operational: false,
        bookkeeping_software: '',
        entity_type: '',
    },
    is_consent_disclosed: false,
    consent_disclosed_by: '',
    consent_disclosed_date: null,
    phone_type_us: 'Yes',
    is_fincen:true,
}

const finCENFormValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    ein: Yup.string().required('EIN is required'),
    form_date: Yup.date().required('Form Date is required'),
    form_state: Yup.string().required('Form State is required'),
    address: Yup.object().shape({
        address: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip_code: Yup.string().required('Zip Code is required'),
    }),
    business_owners: Yup.array().of(
        Yup.object().shape({
            tax_payer_name: Yup.string().required(
                'Full Legal name is required'
            ),
            dob_date: Yup.string().required('Date of Birth is required'),
            address: Yup.object().shape({
                address: Yup.string().required('Street Address is required'),
                city: Yup.string().required('City is required'),
                country: Yup.string().required('Country is required'),
                state: Yup.string().when('country', {
                    is: 'US',
                    then: Yup.string().required('State is required'),
                    otherwise: Yup.string(),
                }),
                zip_code: Yup.string().required('Zip Code is required'),
            }),
            owner_uin: Yup.string().required('ID Type is required').nullable(),
            owner_uin_number: Yup.string().required('ID is required'),
            owner_uin_file_link: Yup.string().required(
                'Owner UIN File Link is required'
            ),
        })
    ),
    is_consent_disclosed: Yup.boolean()
        .oneOf([true], 'Consent is required')
        .required('Consent is required'),
    consent_disclosed_by: Yup.string().required('Your full name is required'),
    consent_disclosed_date: Yup.date().required('Date is required'),
})

const FinCenInfoModal = ({
    open,
    handleClose,
    accountId,
    businessId,
    loadPostAuthData,
    appData,
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const bottomRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null);
    const [showScrollIcon, setShowScrollIcon] = useState(true)
    const modalContentRef = useRef<HTMLDivElement>(null)
    const [businessDetails, setBusinessDetails] = useState<any | null>(null)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const { setApiSuccess } = useWidgetContext()
    const formikRef = useRef<any>();

    const { user } = appData;

    const getBusinessDetailsCallback = useCallback(() => {
        setIsLoading(true); 
        getBusinessDetails(accountId, businessId).then(
            (res: any) => {
                setBusinessDetails(res)
                setIsLoading(false); 
            }
        ).catch((err) => {
            setIsLoading(false); 
            setError("Error fetching business details. Please try again.")
        })
    }, [accountId, businessId])

    useEffect(() => {
        getBusinessDetailsCallback()
    }, [getBusinessDetailsCallback])

    useEffect(() => {
        if (open) {
          hideDriftWidget();
        } else {
          showDriftWidget();
        }
      }, [open])

    const handleFormSubmit = (values: any, actions: FormikHelpers<any>) => {
        const updatedBusinessOwners = values.business_owners.map(
            (owner: any) => {
                const { owner_uin_file_details, ...otherOwnerProps } = owner
                return otherOwnerProps
            }
        )
        const updatedValues = {
            ...values,
            business_owners: updatedBusinessOwners,
        }

        setIsLoading(true)
        values.is_us_phone = values.phone_type_us === 'Yes'
        formSubmitErrorHandler(
            saveBusinessDetails(updatedValues, accountId, businessId)
                .then((response: any) => {
                    setIsLoading(false)
                    loadPostAuthData(accountId).then(() => {
                        dispatch({ type: 'RESET_CATEGORIES' })
                    })
                    setApiSuccess(true)
                    handleClose()
                    setShowSuccessModal(true)
                })
                .catch((err) => {
                    setError(
                        'Error submitting business details. Please try again.'
                    )
                    setIsLoading(false)
                }),
            () => {
                setIsLoading(false)
            },
            actions.setFieldError
        )
    }

    const handleScrollIconClick = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' })
            setShowScrollIcon(false)
        }
    }

    const handleScroll = () => {
        if (modalContentRef.current) {
            const { scrollTop } = modalContentRef.current
            setShowScrollIcon(scrollTop === 0)
        }
    }

    //Initializing existing data
    if (businessDetails) {
        initialValues = {...initialValues, ...businessDetails}


        // TODO: Need to be removed after wade response
        initialValues.business_owners = initialValues.business_owners.map(
            (owner: any) => {
                return {
                    ...owner,
                    owner_uin: null,
                    owner_uin_number: '',
                    owner_uin_file_link: '',
                    owner_uin_file_details: null,
                }
            }
        )
    }

    return (
        <>
            <UiModal open={open} handleClose={handleClose}>
                <div className={classes.modal}>
                    {isLoading && (
                        <div className={classes.loaderContainer}>
                            <Loader />
                        </div>
                    )}

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                        validationSchema={finCENFormValidationSchema}
                        enableReinitialize={false}
                        validateOnBlur={true}
                        validateOnMount={true}
                        validateOnChange={true}
                        innerRef={formikRef}
                    >
                        {({ submitForm, isValid, dirty }) => {
                            return (
                                <Form>
                                    <div
                                        onClick={(e) => e.stopPropagation()}
                                        className={
                                            `${classes.modalContentContainer} ${user.accountant_mode ? classes.modalContentContainerWithAdminHeader : ''}`
                                            
                                        }
                                    >
                                        {showScrollIcon && !isLoading && (
                                            <div
                                                className={
                                                    classes.scrollIconContainer
                                                }
                                            >
                                                <ArrowDownwardIcon
                                                    onClick={
                                                        handleScrollIconClick
                                                    }
                                                />
                                            </div>
                                        )}

                                        <>
                                            <div
                                                className={classes.modalHeader}
                                            >
                                                <UiText
                                                    variant="hatchback_125"
                                                    weight="medium_500"
                                                >
                                                    Beneficial Ownership
                                                    Information Form
                                                </UiText>
                                                <IconButton
                                                    aria-label="close"
                                                    classes={{
                                                        root: classes.closeIconButton,
                                                    }}
                                                    onClick={handleClose}
                                                    data-cy="cross-modal-close-btn"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                            <div
                                                onScroll={handleScroll}
                                                ref={modalContentRef}
                                                className={classes.modalContent}
                                            >
                                                {error && (
                                                    <div className="">
                                                        <UiText
                                                            textColor="error"
                                                            variant="car_100"
                                                            weight="semi_bold_600"
                                                        >
                                                            {error}
                                                        </UiText>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        classes.infoContainer
                                                    }
                                                >
                                                    <InformationBlock
                                                        style={{
                                                            color: colorTheme.black100,
                                                        }}
                                                        message="Starting January 1, 2024, many companies will be required to report information to the U.S. government about who ultimately owns and controls them. Fines for not reporting this information will be steep. We're here to help."
                                                        maxWith={552}
                                                    />
                                                </div>

                                                <div>
                                                    <FinCenDataCollectionForm formikRef={formikRef} />
                                                </div>
                                                <div ref={bottomRef}></div>
                                            </div>
                                            <div
                                                className={classes.modalFooter}
                                            >
                                                <div
                                                    className={
                                                        classes.buttonContainer
                                                    }
                                                >
                                                    <UiButton
                                                        label="Cancel"
                                                        btnType="secondary"
                                                        handleClick={
                                                            handleClose
                                                        }
                                                    />
                                                    <UiButton
                                                        label="Submit"
                                                        btnType="tertiary"
                                                        handleClick={submitForm}
                                                        disabled={
                                                            !isValid || !dirty
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </UiModal>
            <FinCenSucessModal open={showSuccessModal} handleClose={() => setShowSuccessModal(false)}/>
        </>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    accountId: state.appData.current_account_id,
    businessId: state.appData.current_business_id,
    appData: state.appData,
})

export default connect(mapStateToProps, { loadPostAuthData })(FinCenInfoModal)
