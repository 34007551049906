import TodayIcon from '@material-ui/icons/Today'
export const payrollSignatoryFormFields: any = [
    {
        key: 'ssn',
        type: 'mask',
        placeholder: 'Social Security Number (Required)',
        label: 'Social Security Number (Required)',
        inputMask: [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        md: 12,
        xs: 12,
        required: true,
        showFloatingLabel: true,
        minLength: 9,
        cypressId: 'ssn-number'
    },
    {
        key: 'first_name',
        type: 'text',
        placeholder: 'First Name (Required)',
        label: 'First Name (Required)',
        md: 12,
        xs: 12,
        required: true,
        showFloatingLabel: true,
        cypressId: 'first-name'
    },
    {
        key: 'last_name',
        type: 'text',
        placeholder: 'Last Name (Required)',
        label: 'Last Name (Required)',
        md: 12,
        xs: 12,
        required: true,
        showFloatingLabel: true,
        cypressId: 'last-name'
    },
    {
        key: 'email',
        type: 'text',
        placeholder: 'Email (Required)',
        label: 'Email (Required)',
        md: 12,
        xs: 12,
        required: true,
        showFloatingLabel: true,
        cypressId: 'email'
    },
    {
        key: 'phone',
        type: 'mask',
        label: 'Phone (Required)',
        inputMask: [
            '(',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ')',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        placeholder: 'Phone (Required)',
        md: 6,
        xs: 12,
        showFloatingLabel: true,
        minLength: 10,
        required: true,
        cypressId: 'phone'
    },
    {
        key: 'title',
        type: 'text',
        placeholder: 'Title (Required)',
        label: 'Title (Required)',
        md: 12,
        xs: 12,
        required: true,
        showFloatingLabel: true,
        cypressId: 'title'
    },
    {
        md: 12,
        xs: 12,
        label: 'Birthday (Required)',
        required: true,
        type: 'KeyboardDatePicker',
        key: 'birthday',
        dateType: 'string',
        disableFutureDate: true,
        showFloatingLabel: true,
        errorMessage: 'Required',
        endIcon: <TodayIcon fontSize="small" />,
        placeholder: 'MM/DD/YYYY',
        cypressId: 'birthday'
    },
]

export const payrollSignatoryAddress = (apiConfig: any) => [
    {
        type: 'text',
        label: 'Street (Required)',
        showFloatingLabel: true,
        required: true,
        key: 'street_1',
        xs: 12,
        md: 12,
        cypressId: 'street_1'
    },
    {
        type: 'text',
        label: 'Street Line 2 (Optional)',
        showFloatingLabel: true,
        key: 'street_2',
        xs: 12,
        md: 12,
        cypressId: 'street_2'
    },
    {
        type: 'text',
        label: 'City (Required)',
        required: true,
        showFloatingLabel: true,
        key: 'city',
        xs: 12,
        md: 12,
        cypressId: 'city'
    },

    {
        type: 'select',
        data: apiConfig.generic.states,
        optionKey: 'name',
        optionValue: 'id',
        label: 'State (Required)',
        showFloatingLabel: true,
        key: 'state',
        xs: 12,
        md: 12,
        cypressId: 'state'
    },

    {
        type: 'mask',
        label: 'Zip Code (Required)',
        required: true,
        showFloatingLabel: true,
        key: 'zip',
        inputMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
        minLength: 5,
        xs: 12,
        md: 12,
        cypressId: 'zip-code'
    },
]
