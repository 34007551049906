import { getFromLocalStorage } from '../../../services/localStorageService';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { IMAGE_NAMES, IMG_BASE_URL } from './whiteLabel.const';

export const getPartnerLogo = (businessPartner: string) : string =>{
    return `${IMG_BASE_URL}/${businessPartner}/${IMAGE_NAMES.LOGO}`
}
const changeToPartnerFaviconIcon = (newIconPath: string) => {
    const oldFavicon = document.querySelector('link[rel="icon"]')
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.href = newIconPath
    if (oldFavicon) {
        document.head.removeChild(oldFavicon)
    }
    document.head.appendChild(newFavicon)
}

const updateScrollbarColor = (color: string) => {   
    const root = document.documentElement; 
    root.style.setProperty("--primary-green", color); 
}
const updatePrimaryButtonsColor = (color: string) => {
    const root = document.documentElement; 
    root.style.setProperty("--primary-color-text", color); 
  };

export const updateThemeAttribute = (partnerDetails : any) =>{
    const logo = getPartnerLogo(partnerDetails.partner_key)
    changeToPartnerFaviconIcon(logo);
    const customStyleInfo = partnerDetails?.custom_style_info as ThemeColors;
    updateScrollbarColor(customStyleInfo.primaryGreen)
    updatePrimaryButtonsColor(customStyleInfo.primaryButtonsColor);
}
export const isWhiteLabelClient = () : boolean =>{
    const user = getFromLocalStorage('user');
        if(!user || !user.partner_details) return false;
        return true;
}