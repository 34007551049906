import { IconButton } from '@material-ui/core'
import { ArrowBack, Close } from '@material-ui/icons'
import React from 'react'
import UiText from '../../../common/ui/UiText'
import { useHeaderStyles, useStyles } from './Styles'
import clsx from 'clsx'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const Header = ({
    headerText,
    closeModal,
    onBackButtonClick,
    hideTitleBorder = false,
    noBackgroundOpacity = false
}: {
    headerText?: string | React.ReactNode
    hideTitleBorder?: boolean
    onBackButtonClick?: () => void
    closeModal: () => void
    noBackgroundOpacity?: boolean
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const headerStyles = useHeaderStyles(colorTheme)
    return (
        <div
            className={
                hideTitleBorder
                    ? ''
                    : clsx(styles.spaceBetween, headerStyles.modalHeader, (noBackgroundOpacity ? headerStyles.stickyHeaderNoOpacity : headerStyles.stickyHeader))
            }
        >
            <div className={styles.flex}>
                {onBackButtonClick && (
                    <div className={headerStyles.backButton}>
                        <IconButton
                            aria-label="close"
                            onClick={onBackButtonClick}
                        >
                            <ArrowBack />
                        </IconButton>
                    </div>
                )}
                {headerText && (
                    <div className={headerStyles.headerTitle}>
                        <UiText variant="hatchback_125" weight="medium_500">
                            {headerText}
                        </UiText>
                    </div>
                )}
            </div>
            <div>
                <div className={headerStyles.closeButton}>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <Close />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default Header