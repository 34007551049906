import { getResource, putResource, postResource } from '../../axiosService'

const basURL = (businessId: string) =>
    `api/business/${businessId}/payroll_organizer/employee`

export function getEmployees(businessId: string, params: any) {
    return getResource(basURL(businessId), undefined, params)
}

export function getCurrentEmployee(businessId: string, employeeId: string) {
    return getResource(`${basURL(businessId)}/${employeeId}`, undefined)
}

export function createEmployee(businessId: string, data: any) {
    return postResource(basURL(businessId), data)
}

export function updateEmployeeData(businessId: string, data: any) {
    return putResource(basURL(businessId), data)
}

export function createJob(businessId: string, data: any) {
    return postResource(`${basURL(businessId)}/job`, data)
}

export function updateJob(businessId: string, data: any) {
    return putResource(`${basURL(businessId)}/job`, data)
}

export function updateJobCompensation(businessId: string, data: any) {
    return putResource(`${basURL(businessId)}/compensation`, data)
}

export function getEmployeeFedaralTax(businessId: string, employeeId: string) {
    return getResource(
        `${basURL(businessId)}/${employeeId}/federal_taxes`,
        undefined
    )
}

export function getEmployeePaymentMethod(
    businessId: string,
    employeeId: string
) {
    return getResource(
        `${basURL(businessId)}/${employeeId}/payment_method`,
        undefined
    )
}

export function getEmployeeBankAccount(businessId: string, employeeId: string) {
    return getResource(
        `${basURL(businessId)}/${employeeId}/bank_account`,
        undefined
    )
}

export function getEmployeeForms(businessId: string, employeeId: string) {
    return getResource(`${basURL(businessId)}/${employeeId}/forms`, undefined)
}

export function sendEmailRequestForInput(
    businessId: string,
    employeeId: string
) {
    return postResource(
        `${basURL(businessId)}/${employeeId}/request_info`,
        undefined
    )
}
