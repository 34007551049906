import * as Yup from 'yup'
import UiText from '../../common/ui/UiText'
import { SvgIcon } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import { Employee } from '../../../models/adp-payroll/employee'

export const formattedParams = (data: any) => {
    const formData: any = {
        legalName: {
            firstName: data?.firstName,
            middleName: data?.middleName,
            lastName: data?.lastName,
        },
        ...(data?.jobId
            ? {
                  jobID: data?.jobId,
              }
            : {
                  job: {
                      jobName: data?.jobName,
                  },
              }),
        startDate: data?.startDate,
        birthDate: data?.birthDate,
        personalEmailAddress: data?.personalEmailAddress,
        homeAddress: {
            address1: data?.address1,
            city: data?.city,
            stateCode: data?.stateCode,
            postalCode: data?.postalCode,
            countryCode: data?.countryCode,
        },
    }
    return formData
}

const ssnInfo = (
    <UiText variant="motorcycle_90" textColor="textSecondary">
        {' '}
        For security purposes your SSN has been hidden. You can update it by
        typing in the field.
    </UiText>
)

export const personalDetailsFieldMapping: any = [
    {
        key: 'firstName',
        type: 'text',
        placeholder: 'First Name (Required)',
        md: 10,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        showFloatingLabel: true,
    },
    {
        key: 'middleName',
        type: 'text',
        placeholder: 'Middle Initial (Optional)',
        md: 10,
        xs: 12,
        showFloatingLabel: true,
    },
    {
        key: 'lastName',
        type: 'text',
        placeholder: 'Last Name (Required)',
        md: 10,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        showFloatingLabel: true,
    },
    {
        key: 'email',
        type: 'text',
        icon: 'Email',
        placeholder: 'Email (Required)',
        md: 8,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        label: 'Email (Required)',
        showFloatingLabel: true,
    },
    {
        md: 8,
        xs: 12,
        key: 'birthDate',
        label: 'Date of Birth (Required)',
        required: true,
        type: 'KeyboardDatePicker',
        fieldName: 'personalEmailAddress',
        dateType: 'string',
        disableFutureDate: true,
        showFloatingLabel: true,
        errorMessage: 'Required',
        endIcon: <SvgIcon component={TodayIcon} color="action" />,
        placeholder: 'MM/DD/YYYY',
    },
    {
        md: 8,
        xs: 12,
        label: 'Social Security Number (Required)',
        type: 'mask',
        mask: [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        placeholder: 'Hidden',
        fieldSize: 6,
        minLength: 9,
        key: 'ssn',
        infoText: ssnInfo,
    },
]

export const JobDetailsFieldMapping: any = (locationData: any) => {
    return [
        {
            md: 8,
            xs: 12,
            key: 'hireDate',
            label: 'Date of Hire (Required)',
            required: true,
            type: 'KeyboardDatePicker',
            fieldName: 'hireDate',
            dateType: 'string',
            disableFutureDate: true,
            showFloatingLabel: true,
            placeholder: 'MM/DD/YYYY',
            errorMessage: 'Required',
            endIcon: <SvgIcon component={TodayIcon} color="action" />,
            infoText: (
                <UiText variant="motorcycle_90" textColor="textSecondary">
                    {' '}
                    This can be the date the employee was hired, <br /> or the
                    date they started this specific job.
                </UiText>
            ),
        },
        {
            key: 'jobTitle',
            type: 'text',
            placeholder: 'Job Title (Required)',
            label: 'Job Title (Required)',
            md: 10,
            xs: 12,
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
        },
        {
            type: 'select',
            placeholder: 'Location (Required)',
            label: 'Location (Required)',
            md: 10,
            xs: 12,
            key: 'jobLocation',
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
            data: locationData,
            optionKey: 'state',
            optionValue: 'id',
            infoText: (
                <UiText variant="motorcycle_90" textColor="textSecondary">
                    {' '}
                    The location where the employee will be working.
                </UiText>
            ),
        },
    ]
}

export const compensationFormMappings = (values: any) => {
    return [
        {
            md: 8,
            xs: 12,
            key: 'rate',
            label: 'Amount (Required)',
            type: 'currency',
            showFloatingLabel: true,
            required: true,
        },
        {
            key: 'payment_unit',
            type: 'select',
            placeholder: 'Frequency (Required)',
            label: 'Frequency (Required)',
            md: 10,
            xs: 12,
            required: true,
            data: ['Hour', 'Week', 'Month', 'Year', 'Paycheck'],
            showFloatingLabel: true,
        },
    ]
}

export const ownershipFormMapping = (values: any) => {
    return [
        {
            label: `Is the employee an owner of the company? (Required)`,
            fieldName: 'isOwner',
            type: 'radio',
        },
        {
            label: `Is the employee primary owner of the company? (Required)`,
            fieldName: 'isOwner',
            type: 'radio',
        },
        {
            key: 'email',
            type: 'number',
            md: 8,
            xs: 12,
            required: true,
            errorMessage: 'Required',
            label: 'What percentage of the company does the employee own? (Required)',
            endIcon: "%"
        },
    ]
}

export const formInitialValues = (employee: Employee | undefined) => {
    let initialValues = {
        firstName: employee?.legalName.firstName || '',
        middleName: employee?.legalName?.middleName || '',
        lastName: employee?.legalName?.lastName || '',
        email: employee?.personalEmailAddress || '',
        date_of_birth: employee?.birthDate || '',
        //ssn: employee?.ssn || "",
    }
    return initialValues
}

const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    personalEm: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    date_of_birth: Yup.string().required('Date of Birth is required'),
})


export const formatNumberWithCommas = (value:number) => {
    if(!value) return '0'
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}