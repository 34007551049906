import { useState } from 'react';
import { makeStyles } from '@material-ui/styles'
import { Form, Formik } from 'formik'
import { toRem16 } from '../../../styles/commonStyles'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import UiButton from '../../common/ui/UiButton'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import UiText from '../../common/ui/UiText'
import {
    defaultFormValues,
    FormPropertiesMapping,
} from './CreateAccountFormData'
import * as Yup from 'yup'
import { Business } from '../../../models'
import { showError } from '../../../services/formService'
import Loader from '../../common/Loader'
import { saveBusinessDetails } from '../../../services/apiService/business'
import { useDispatch } from 'react-redux'
import { loadPostAuthData } from '../../../store/actions/appData'

const initialValues = defaultFormValues
const classes = makeStyles(() => {
    return {
        accountForm: {
            padding: `${toRem16(31)} ${toRem16(48)} ${toRem16(48)} ${toRem16(
                48
            )}`,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            '& .MuiFormControl-root ': {
                margin: `0 0 ${toRem16(12)} 0`,
            },
        },
        accountFormContainer: {
            minHeight: 0,
            overflowY: 'auto',
            width: '100%',
        },
        formHeader: {
            textAlign: 'center',
            margin: `0 0 ${toRem16(31)} 0`,
        },
        createAccountButton: {
            margin: `${toRem16(30)} 0 0 0`,
        },
    }
})

interface CreateAccountProps {
    missingBusinessFields: string[]
    onBusinessDataSubmitted: () => void
}
interface CreateAccountFormData {
    first_name?: string
    last_name?: string
    name?: string
    business_type: string
    industry: string
    form_state: string
    ownership: number
}
const validationSchema = Yup.object({
    ownership: Yup.number()
        .min(100)
        .max(100),
});
export default function CreateAccountForm(props: CreateAccountProps) {
    const [isSubmittingData, setIsSubmittingData] = useState(false)
    const storeData = useCurrentStore();
    const dispatch = useDispatch();
    const accountId = storeData.currentAccountId
    const businessId = storeData.currentBusinessId
    const businessData = storeData.currentBusiness
    const styles = classes()
    const apiConfigData = storeData?.currentConfig?.apiConfig
    const formOptionsData = {
        businessTypes: apiConfigData.business.types,
        country: apiConfigData.generic.countries,
        industries: apiConfigData?.business.industries,
        states: apiConfigData.generic.states,
    }
    const { missingBusinessFields, onBusinessDataSubmitted } = props

    const isOwnerObjectPresent = (businessData: Business) => {
        return businessData?.business_owners && businessData?.business_owners[0]
    }
    const getProcessedDataForSubmit = (
        values: CreateAccountFormData,
        businessData: Business
    ): Business | undefined => {
        const {
            first_name,
            last_name,
            ownership,
            name,
            business_type,
            industry,
            form_state,
        } = values
        const businessDataCopy = JSON.parse(JSON.stringify(businessData))
        if (!businessDataCopy) return
        if (name) {
            businessDataCopy['name'] = name;
        }
        if (business_type) {
            businessDataCopy['business_type'] = business_type;
        }
        if (industry) {
            businessDataCopy['industry'] = industry;
        }
        if (form_state) {
            businessDataCopy['form_state'] = form_state;
        }
        if (!isOwnerObjectPresent(businessDataCopy)) {
            const ownerData = {
                ssn: '',
                address: {
                  address: '',
                  city: '',
                  country: '',
                  state: '',
                  county: '',
                  zip_code: '',
                },
              };
            businessDataCopy.business_owners = [ownerData]
        }
        
        if (first_name) businessDataCopy.business_owners[0].first_name = first_name;
        if (last_name) businessDataCopy.business_owners[0].last_name = last_name;
        if (ownership) businessDataCopy.business_owners[0].ownership = ownership;
        return businessDataCopy;
    }
    const onSubmit = (values: CreateAccountFormData) => {
        const data = getProcessedDataForSubmit(values, businessData)
        if (!data) return
        setIsSubmittingData(true)
        saveBusinessDetails(data, accountId, businessId as string)
            .then(() => {
                setIsSubmittingData(false);
                onBusinessDataSubmitted();
                dispatch(loadPostAuthData());
            })
            .catch((error) => {
                setIsSubmittingData(false)
                showError(error.message)
            })
    }
    const renderFormFields = (property: any, index: number) => {
        return (
            <div key={index}>
                <UiFormControlSelection
                    showFloatingLabel
                    placeholder={property?.placeholder}
                    type={property.type}
                    fieldName={property.key}
                    required={property?.required}
                    errorMessage={property?.errorMessage}
                    optionsData={property?.optionsData}
                    optionKey={property?.optionKey}
                    optionValue={property?.optionValue}
                    label={property?.label}
                    {...(property.type === 'mask'
                        ? { mask: property?.regex }
                        : {})}
                    {...(property?.minLength
                        ? { minLength: property?.minLength }
                        : {})}
                />
            </div>
        )
    }
    return (
        <div className={styles.accountFormContainer}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={validationSchema}
            >
                {({ submitForm }) => {
                    return (
                        <Form className={styles.accountForm}>
                            {isSubmittingData ? (
                                <Loader />
                            ) : (
                                <>
                                    <UiText
                                        variant="suv_150"
                                        weight="semi_bold_600"
                                        className={styles.formHeader}
                                    >
                                        Create an Account
                                    </UiText>
                                    {missingBusinessFields.map(
                                        (field: string, index: number) => {
                                            const property =
                                                FormPropertiesMapping(
                                                    formOptionsData,
                                                    field
                                                )
                                            return renderFormFields(
                                                property,
                                                index
                                            )
                                        }
                                    )}
                                    <UiButton
                                        btnType="tertiary"
                                        label="Create Account"
                                        customClass={styles.createAccountButton}
                                        handleClick={submitForm}
                                    />
                                </>
                            )}
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
