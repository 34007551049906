import { Form } from 'formik'
import UiText from '../../common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { Fragment } from 'react'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { RenderForm } from '../company/FirstEmployee/FormData'
import { Grid, Theme } from '@material-ui/core'
import { personalInfoFormMapping } from './EmployeeFormData'
import { makeStyles } from '@material-ui/styles'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { toRem16 } from '../../../styles/commonStyles'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { AddressAutocompletion } from '../AddressAutocompletion'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    flex: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
    },
    clear: {
        color: (colorTheme) => colorTheme.red100,
    },
    fieldMargin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    infoContainer: {
        display: 'flex',
        gap: toRem16(12),
        alignItems: 'center',
        padding: `${toRem16(8)} ${toRem16(16)}`,
        borderRadius: toRem16(1),
        background: (colorTheme) => colorTheme.blue300,
        marginBottom: toRem16(16),
    },
}))

const EditEmployeeForm = (props: any) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const { currentConfig } = useCurrentStore()
    const { paymentType, setPaymentType, values, setFieldValue } = props
    const styles = useStyles(colorTheme)

    return (
        <div className={styles.formStyle}>
            <Form>
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Personal Info
                </UiText>
                {personalInfoFormMapping(true).map((property: any) => (
                    <Fragment key={property.key}>
                        {RenderForm(property)}
                    </Fragment>
                ))}

                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Home Address
                </UiText>
                <Grid item md={10} xs={12}>
                    <div className={styles.fieldMargin}>
                        <AddressAutocompletion setFieldValue={setFieldValue}/>
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Street (Required)"
                            label="Street (Required)"
                            fieldName="street_1"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Street 2 (Optional)"
                            label="Street 2 (Optional)"
                            fieldName="street_2"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="City (Required)"
                            label="City (Required)"
                            fieldName="city"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="select"
                            optionsData={currentConfig.apiConfig.generic.states}
                            optionKey="name"
                            optionValue="id"
                            placeholder="State"
                            label="State"
                            showFloatingLabel={true}
                            fieldName="state"
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Zip Code (Required)"
                            label="Zip Code (Required)"
                            fieldName="zip"
                            showFloatingLabel={true}
                        />
                    </div>

                    <UiText
                        variant="hatchback_125"
                        weight="semi_bold_600"
                        className={styles.contentMargin}
                    >
                        Payment Method
                    </UiText>
                    <UiText>
                        Your paycheck will be deposited to the provided bank account.
                    </UiText>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Bank Name (Required)"
                            label="Bank Name (Required)"
                            fieldName="bank_name"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Routing Number (Required)"
                            label="Routing Number (Required)"
                            fieldName="routing_number"
                            showFloatingLabel={true}
                        />
                    </div>
                    <div className={styles.fieldMargin}>
                        <UiFormControlSelection
                            type="text"
                            placeholder="Account Number (Required)"
                            label="Account Number (Required)"
                            fieldName="account_number"
                            showFloatingLabel={true}
                        />
                    </div>
                </Grid>
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={styles.contentMargin}
                >
                    Ownership Status
                </UiText>
                <UiText className={styles.fieldSpacing}>
                    Is the employee an owner of the company? (Required)
                </UiText>
                <div className={styles.fieldMargin}>
                    <UiFormControlSelection
                        type="radio"
                        label="Is the employee an owner of the company? (Required)"
                        fieldName="isOwner"
                        radioOptionsData={[
                            {
                                label: 'Yes',
                                value: 'yes',
                            },
                            {
                                label: 'No',
                                value: 'no',
                            },
                        ]}
                    />
                </div>
                {values?.isOwner === 'yes' && (
                    <>
                        <UiText>
                            Is this employee the primary owner of the company?
                            (Required)
                        </UiText>
                        <div className={styles.fieldSpacing}>
                            <UiFormControlSelection
                                type="radio"
                                label="Is this employee the primary owner of the company? (Required)"
                                fieldName="primaryOwnerIndicator"
                                disabled={true}
                                radioOptionsData={[
                                    {
                                        label: 'Yes',
                                        value: 'yes',
                                    },
                                    {
                                        label: 'No',
                                        value: 'no',
                                    },
                                ]}
                            />
                        </div>
                        <UiText className={styles.fieldSpacing}>
                            What percentage of the company does the employee
                            own? (Required)
                        </UiText>
                        <div className={styles.fieldMargin}>
                            <UiFormControlSelection
                                fastField={false}
                                type="number"
                                fieldName={`ownership`}
                                placeholder="0"
                                endIcon="%"
                            />
                        </div>
                    </>
                )}
            </Form>
        </div>
    )
}

export default EditEmployeeForm
