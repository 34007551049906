import { makeStyles } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import SectionView from '../../../payroll/common/SectionView'
import Icon from '../../../common/Icon'
import {
    AssociateLegalAddress,
    Employee,
    MoneyDistribution,
} from '../../../../models/adp-payroll/employee'
import { commonStyles, mergeStyle } from '../../../../styles/commonStyles'
import EmptySectionView from '../../../payroll/common/EmptySectionView'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles(() => ({
    contentMargin: {
        marginTop: '1rem',
        marginLeft: '1rem',
    },
}))

const BankDetailsView = ({
    employee,
    setOpenRequestDialog,
}: {
    employee: Employee
    setOpenRequestDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const classes = useStyles()
    const styles = commonStyles()
    const employeeAddress: AssociateLegalAddress =
        employee?.associateLegalAddress
    const hasPaymentMethod = !!employee?.moneyDistributionInstructions
    const paymentDetails =
        employee?.moneyDistributionInstructions as MoneyDistribution
    const hasBankAddress =
        !!employee?.moneyDistributionInstructions?.accountNumber
    const isBirthdateAvailable = !!employee?.birthDate
    return (
        <div>
            <SectionView
                key={'direct_deposit'}
                headerText={'Payment Methods'}
                customNode={
                    <>
                        {hasPaymentMethod ? (
                            <>
                                {hasBankAddress ? (
                                    <div
                                        className={mergeStyle(
                                            styles.flex,
                                            styles.mt24
                                        )}
                                    >
                                        <Icon icon="bank" size="30px"></Icon>
                                        <div className={classes.contentMargin}>
                                            <UiText textColor="textSecondary">
                                                Bank Name:{' '}
                                                {
                                                    paymentDetails?.moneyDistributionInstructionName
                                                }
                                            </UiText>
                                            <UiText textColor="textSecondary">
                                                Account:{' '}
                                                {paymentDetails?.accountNumber}
                                            </UiText>
                                            <UiText textColor="textSecondary">
                                                Routing:{' '}
                                                {paymentDetails?.routingNumber}
                                            </UiText>
                                        </div>
                                    </div>
                                ) : (
                                    /** TODO - Need to add mailing address*/
                                    <div
                                        style={{
                                            height: '200px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <UiText>Check</UiText>
                                        <UiText textColor="textSecondary">
                                            A check will be given to you by your
                                            employer.
                                            <br />
                                            1-800 Accountant does not mail
                                            checks
                                        </UiText>
                                        <div
                                            className={mergeStyle(
                                                styles.flex,
                                                styles.mt24
                                            )}
                                        >
                                            {employeeAddress?.address1 && (
                                                <Icon
                                                    icon="locations"
                                                    size="20px"
                                                />
                                            )}
                                            <div className={styles.ml16}>
                                                <UiText>
                                                    {employeeAddress?.address1}{' '}
                                                    {employeeAddress?.address2}{' '}
                                                    <br />
                                                    {employeeAddress?.city}{' '}
                                                    {
                                                        employeeAddress?.postalCode
                                                    }{' '}
                                                    {employeeAddress?.stateCode}
                                                </UiText>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <EmptySectionView
                                showDivider={false}
                                primaryText={'No Payment Method Setup'}
                                secondaryText={''}
                                buttonText={'Request employee input'}
                                buttonIcon={<EmailIcon />}
                                onClickAction={() => {
                                    setOpenRequestDialog(true)
                                }}
                                disableButton={!isBirthdateAvailable}
                            />
                        )}
                    </>
                }
            />
        </div>
    )
}

export default BankDetailsView
