import { useEffect, useState } from 'react';
import { previewUrl } from '../../../../../services/apiService';
import { arraybufferToUri } from '../../../../../utils/utility';
import Loader from '../../../../common/Loader';
import { ReactComponent as BusinessLogoDefault } from '../../../../../assets/icons-svg/BusinessLogo.svg';
import { useReportsStyles } from '../../styles/reports-styles';
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext';

interface BusinessLogoProps {
    logoFromApi: string | undefined;
    accountId: string;
    businessName: string;
}
export default function BusinessNameAndLogo(props: BusinessLogoProps) {
    const { logoFromApi, accountId, businessName } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [logoImage, setLogoImage] = useState<string>('');
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);

    useEffect(() => {
        const setPreviewImage = (response: any) => {
            if (response?.status === 200) {
                const imageuri = arraybufferToUri(response);
                setLogoImage(imageuri);
            }
        };
        if (logoFromApi) {
            setIsLoading(true);
            previewUrl(accountId, logoFromApi, 'logo')
                .then((response) => {
                    setIsLoading(false);
                    setPreviewImage(response);
                })
                .catch((e) => {
                    setIsLoading(false);
                    console.error(e);
                });
        }
    }, [accountId, logoFromApi]);
    return (
        <div className={reportsClasses.businessLogoImageContainer}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div>{businessName}</div>
                    {logoImage ? (
                        <img
                            src={logoImage}
                            alt='Business logo'
                            className='businessLogo'
                        />
                    ) : (
                        <div className='businessLogo'>
                            <BusinessLogoDefault />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
