import {
    Box,
    Button,
    Divider,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getJournalById } from '../../../services/journalServices';
import Loader from '../../common/Loader';
import EditIcon from '../../../assets/icons-svg/Edit.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import moment from 'moment';
import { connect } from 'react-redux';
import NewJournalModal from './NewJournalModal';
import { AppData, ApplicationStore } from '../../../models/store';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import { deleteJournalById } from '../../../services/journalServices';
import { useJournalState } from './JournalProvider';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import Deleteicon from '../../../assets/icons/Deleteicon';
import NoteIcon from '../../../assets/icons-svg/Note.svg';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface JournalTreadProps {
    appData: AppData;
    selectMessage?: any;
    raw?: any;
    deleteData?: any;
    openEditModal?: (data: any) => void;
    getAccountdata?: () => void;
    getJournalEntry?: (keepSelectedData?: boolean) => void;
}

const useClasses = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    seconderyActionButtonOutline: {
        height: 36,
        //color: (colorTheme) => `${colorTheme.black100,
        color: (colorTheme) => `${colorTheme.black100} !important`
    },
    deleteIcon: {
        color: (colorTheme) => colorTheme.red400
    },
    buttonGap: {
        marginRight: theme.spacing(2),
    },
    listRoot: {
        width: '100%',
        height: 'calc(100vh - 328px)',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 258px)',
        },
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    messageBody: {
        marginTop: theme.spacing(1),
        whiteSpace: 'pre-line',
        wordWrap: 'break-word',
    },
    statusIcon: {
        position: 'relative',
        top: '3px',
        marginRight: theme.spacing(0),
    },
    fileIcon: {
        marginRight: theme.spacing(1),
    },
    removeIcon: {
        padding: 0,
        position: 'relative',
        top: '-7px',
    },
    subjectText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    sendButtonDIsabled: {
        opacity: 0.5,
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0.15px',
    },
    dateTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: (colorTheme) => colorTheme.grey400
    },
    date: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.15px',
        color: (colorTheme) => colorTheme.black100
    },
    total: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '32px',
        lineHeight: '40px',
        color: (colorTheme) => colorTheme.black100,
        [theme.breakpoints.down('sm')]: {
            padding: '12px 0px',
        },
    },
    deleteModal: {
        '& div:first-child': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
    table: {
        '& thead tr th': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: (colorTheme) => colorTheme.grey400,
            borderBottom: 'none',
        },
        '& tbody .normal-row th': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: (colorTheme) => colorTheme.black100,
        },
        '& tbody .last-row th': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: (colorTheme) => colorTheme.black100,
            borderBottom: 'none',
            '& .total': {
                '& div:first-child': {
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                    color: (colorTheme) => colorTheme.grey400,
                },
                '& div:last-child': {
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '20px',
                    letterSpacing: '0.15px',
                    color: (colorTheme) => colorTheme.black100,
                },
            },
        },
    },
    deleteEntry: {
        background: (colorTheme) => colorTheme.red400,
        borderRadius: '4px',
    },
    mobileView: {
        padding: theme.spacing(1, 0),
        '& .form-section': {
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            gridGap: '10px',
            padding: '12px 0px',
            '& .MuiBox-root': {
                background: '#EFF7FD',
            },
        },
        '& .header': {
            '& .title': {
                padding: '2px 0px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.4px',
                color: (colorTheme) => colorTheme.black100,
            },
            '& .title-info': {
                padding: '2px 0px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '0.15px',
                color: (colorTheme) => colorTheme.grey400,
            },
        },
    },
    container: {
        height: '100%',
        padding: '1rem 2.5rem 0.5rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },
    mobileHeader: {
        display: 'grid',
        gridTemplateColumns: '2rem auto 0.5rem',
        alignItems: 'center',
        width: '100%',
        gridGap: '10px',
    },
    parent: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 1),
        },
    },
    totalContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: (colorTheme) =>`1px solid ${colorTheme.grey200}`,
        padding: '0.5rem 0rem',
        '& .section-detail': {
            paddingLeft: theme.spacing(2),
            '& .title': {
                padding: '2px 0px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.25px',
                color: (colorTheme) => colorTheme.grey400,
            },
            '& .title-info': {
                padding: '2px 0px',
                textAlign: 'right',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '15px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                color: (colorTheme) => colorTheme.black100,
            },
        },
    },
}));

function JournalTread({ appData, getJournalEntry }: JournalTreadProps) {
    const { colorTheme } = useThemeContext()
    const classes = useClasses(colorTheme);
    const history = useHistory();
    const { id }: any = useParams();
    const [loading, setLoading] = useState(false);
    const [datasource, setdatasource] = useState({
        totalCredited: 0,
        totalDebited: 0,
        data: [],
    });
    const [openModal, setopenModal] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const {
        listData,
        selectedData,
        setSelectedData,
        setListData,
    } = useJournalState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getTitle = (data: any, type: string) => {
        let res = {
            debit: '',
            credit: '',
        };

        const record = listData?.accountRecord;
        if (type === 'credit' && record) {
            const credit = record[data?.account];
            if (credit?.title) res.credit = credit?.title;
        }
        if (type === 'debit' && record) {
            const debit = record[data?.account];
            if (debit?.title) res.debit = debit?.title;
        }
        return res;
    };

    const loadData = async () => {
        try {
            setLoading(true);
            const res = await getJournalById(
                appData.current_account_id,
                appData.current_business_id,
                id
            );
            setLoading(false);
            setSelectedData(res);
            return res;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };

    const calculateData = (data: any) => {
        let accountInfo = {
            totalCredited: 0,
            totalDebited: 0,
            data: [],
        };

        data?.credit?.forEach((item: any) => {
            //@ts-ignore
            accountInfo.data.unshift({ ...item, type: 'credit' });
            accountInfo.totalDebited += parseFloat(item.amount);
        });

        data?.debit?.forEach((item: any) => {
            //@ts-ignore
            accountInfo.data.unshift({ ...item, type: 'debit' });
            accountInfo.totalCredited += parseFloat(item.amount);
        });

        accountInfo.data.sort((a: any, b: any) => a.order - b.order);

        //@ts-ignore
        accountInfo.totalDebited = accountInfo.totalDebited.toLocaleString(
            undefined,
            { minimumFractionDigits: 2 }
        );
        //@ts-ignore
        accountInfo.totalCredited = accountInfo.totalCredited.toLocaleString(
            undefined,
            { minimumFractionDigits: 2 }
        );
        setdatasource(accountInfo);
    };

    const getTableData = useCallback(
        (data: any): void => {
            if (data) {
                calculateData(data);
            } else {
                loadData().then((res) => {
                    calculateData(res);
                });
            }
        },
        [selectedData] //eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        getTableData(selectedData);
    }, [getTableData, selectedData]);

    const deleteHandleOption = (id?: any) => {
        deleteJournalById(
            appData?.current_account_id,
            appData?.current_business_id,
            id
        )
            .then((res: any) => {
                setshowDeleteModal(false);
                if (getJournalEntry) {
                    setSelectedData(null);
                    getJournalEntry();
                } else {
                    history.push('/journal');
                }
            })
            .catch((err) => {
                setshowDeleteModal(false);
            });
    };

    const handleModalCLose = () => {
        setopenModal(false);
        setisEdit(false);
    };

    const openDeleteModal = (id?: string) => {
        setshowDeleteModal(true);
    };

    const openEditModal = (data: any) => {
        setisEdit(true);
        setopenModal(true);
        setSelectedData(data);
    };

    return (
        <Grid container direction='row' className={classes.container}>
            <NewJournalModal
                open={openModal}
                handleClose={() => handleModalCLose()}
                datasource={listData}
                getJournalEntry={getJournalEntry || (() => {})}
                setListData={setListData}
                isEdit={isEdit}
                deleteData={{
                    showDeleteModal,
                    deleteHandleOption: openDeleteModal,
                }}
                selectedData={selectedData}
            />
            <UiConfirmationDialog
                open={showDeleteModal}
                message={
                    <>
                        <Typography
                            variant='body1'
                            className={classes.deleteModal}
                            gutterBottom
                        >
                            <div>
                                Do you want to delete Journal Entry{' '}
                                {selectedData?.number}?
                            </div>
                            <div>
                                Do you want to delete the selected journal
                                entries?
                            </div>
                            <div>This cannot be undone.</div>
                        </Typography>
                    </>
                }
                handleClose={() => {
                    setshowDeleteModal(false);
                }}
                confirmNode={
                    <Button
                        className={classes.deleteEntry}
                        variant='contained'
                        startIcon={
                            <Deleteicon />
                            // <img alt={`Private`} src={WhiteDeleteIcon} />
                        }
                        onClick={() => {
                            deleteHandleOption(selectedData?.id);
                        }}
                        color='secondary'
                    >
                        Delete Entries
                    </Button>
                }
                cancelButtonText='Cancel'
            />

            {loading ? (
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                >
                    <Loader />
                </Grid>
            ) : (
                <Grid
                    container
                    direction='column'
                    justify='space-between'
                    alignItems='stretch'
                >
                    <div style={{ width: '100%' }}>
                        <Grid container alignItems='center'>
                            <Hidden mdUp>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    sm={12}
                                    container
                                    alignItems='center'
                                    direction='row'
                                    justify='space-between'
                                >
                                    {/* <Grid className={classes.mobileHeader}> */}
                                    <Grid
                                        style={{ display: 'flex' }}
                                        item
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <div>
                                            <IconButton
                                                component={Link}
                                                to='/journal'
                                                onClick={() =>
                                                    setSelectedData(null)
                                                }
                                            >
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </div>
                                        <div>
                                            <Typography
                                                variant='h6'
                                                className={classes.subjectText}
                                                noWrap
                                            >
                                                Journal Entry{' '}
                                                {`#${selectedData?.number}`}
                                            </Typography>
                                        </div>
                                    </Grid>

                                    <div>
                                        <IconButton
                                            aria-label='more'
                                            aria-controls='long-menu'
                                            aria-haspopup='true'
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id='long-menu'
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                <Button
                                                    className={
                                                        classes.deleteIcon
                                                    }
                                                    startIcon={<Deleteicon />}
                                                    onClick={() => {
                                                        setshowDeleteModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Delete Entry
                                                </Button>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    {/* </Grid> */}
                                </Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid
                                    item
                                    md={12}
                                    xs={2}
                                    sm={2}
                                    container
                                    alignItems='center'
                                    justify='space-between'
                                >
                                    <Box className={classes.title}>
                                        Journal Entry{' '} 
                                        {`#${selectedData?.number}`}
                                    </Box>
                                    <Box>
                                        <Button
                                            className={
                                                classes.seconderyActionButtonOutline
                                            }
                                            classes={{
                                                root: classes.buttonGap,
                                            }}
                                            variant='outlined'
                                            startIcon={<Deleteicon />}
                                            onClick={() => {
                                                setshowDeleteModal(true);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            className={
                                                classes.seconderyActionButtonOutline
                                            }
                                            variant='outlined'
                                            startIcon={
                                                <img
                                                    alt={`Edit`}
                                                    src={EditIcon}
                                                />
                                            }
                                            onClick={() => {
                                                //@ts-ignore
                                                openEditModal(selectedData);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Hidden mdUp>
                            <Divider variant='fullWidth'></Divider>
                        </Hidden>
                        <div className={classes.parent}>
                            <Box
                                padding='1rem 0rem'
                                display='flex'
                                justifyContent='space-between'
                            >
                                <Box>
                                    <div className={classes.dateTitle}>
                                        Date
                                    </div>
                                    <div className={classes.date}>
                                        {' '}
                                        {moment(
                                            selectedData?.date * 1000
                                        ).format('DD MMM YYYY')}
                                    </div>
                                </Box>
                                <Hidden mdUp>
                                    <Button
                                        className={
                                            classes.seconderyActionButtonOutline
                                        }
                                        variant='outlined'
                                        startIcon={
                                            <img alt={`Edit`} src={EditIcon} />
                                        }
                                        onClick={() => {
                                            //@ts-ignore
                                            openEditModal(selectedData);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Hidden>
                            </Box>
                            <Box
                                className={classes.total}
                            >{`$${selectedData?.amount}`}</Box>
                            <Box>
                                <Hidden implementation='js' smDown>
                                    <Table
                                        className={classes.table}
                                        aria-label='simple table'
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Category</TableCell>
                                                <TableCell>Debit ($)</TableCell>
                                                <TableCell align='right'>
                                                    Credit ($)
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datasource?.data?.map(
                                                (row: any, index) => (
                                                    <TableRow
                                                        className='normal-row'
                                                        key={index}
                                                    >
                                                        <TableCell
                                                            component='th'
                                                            scope='row'
                                                        >
                                                            {row?.type ===
                                                            'credit'
                                                                ? getTitle(
                                                                      row,
                                                                      row?.type
                                                                  ).credit
                                                                : getTitle(
                                                                      row,
                                                                      row?.type
                                                                  ).debit}
                                                        </TableCell>
                                                        {row?.type ===
                                                        'debit' ? (
                                                            <TableCell>{`$${row?.amount}`}</TableCell>
                                                        ) : (
                                                            <TableCell>
                                                                —
                                                            </TableCell>
                                                        )}
                                                        {row?.type ===
                                                        'credit' ? (
                                                            <TableCell align='right'>{`$${row?.amount}`}</TableCell>
                                                        ) : (
                                                            <TableCell align='right'>
                                                                —
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                )
                                            )}
                                            <TableRow className='last-row'>
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {''}
                                                </TableCell>
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                >
                                                    <div className='total'>
                                                        <div>Total Debit</div>
                                                        <div>{`$${datasource?.totalDebited}`}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                    align='right'
                                                >
                                                    <div className='total'>
                                                        <div>Total Credit</div>
                                                        <div>{`$${datasource?.totalCredited}`}</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Hidden>
                                <Hidden implementation='js' mdUp>
                                    <Box>
                                        {datasource?.data?.map(
                                            (row: any, index) => (
                                                <Box
                                                    key={index}
                                                    className={
                                                        classes.mobileView
                                                    }
                                                >
                                                    <Box className='header'>
                                                        <Box className='title'>
                                                            Category
                                                        </Box>
                                                        <Box className='title-info'>
                                                            {row?.type ===
                                                            'credit'
                                                                ? getTitle(
                                                                      row,
                                                                      row?.type
                                                                  ).credit
                                                                : getTitle(
                                                                      row,
                                                                      row?.type
                                                                  ).debit}
                                                        </Box>
                                                    </Box>
                                                    <Box className='form-section'>
                                                        <Box>
                                                            {row?.type ===
                                                            'debit' ? (
                                                                <UiFormControlSelection
                                                                    label='Debit'
                                                                    type='text'
                                                                    disabled
                                                                    value={`$${row?.amount}`}
                                                                    showFloatingLabel={
                                                                        true
                                                                    }
                                                                ></UiFormControlSelection>
                                                            ) : (
                                                                <UiFormControlSelection
                                                                    label='Debit'
                                                                    type='text'
                                                                    disabled
                                                                    value={`-`}
                                                                    showFloatingLabel={
                                                                        true
                                                                    }
                                                                ></UiFormControlSelection>
                                                            )}
                                                        </Box>
                                                        <Box>
                                                            {row?.type ===
                                                            'credit' ? (
                                                                <UiFormControlSelection
                                                                    label='Credit'
                                                                    type='text'
                                                                    disabled
                                                                    value={`$${row?.amount}`}
                                                                    showFloatingLabel={
                                                                        true
                                                                    }
                                                                ></UiFormControlSelection>
                                                            ) : (
                                                                <UiFormControlSelection
                                                                    label='Credit'
                                                                    type='text'
                                                                    disabled
                                                                    value={`-`}
                                                                    showFloatingLabel={
                                                                        true
                                                                    }
                                                                ></UiFormControlSelection>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        )}
                                        <Box className={classes.totalContainer}>
                                            <Box className='section-detail'>
                                                <Box className='title'>
                                                    Total Debit
                                                </Box>
                                                <Box className='title-info'>
                                                    {`$${datasource?.totalDebited}`}
                                                </Box>
                                            </Box>
                                            <Box className='section-detail'>
                                                <Box className='title'>
                                                    Total Credit
                                                </Box>
                                                <Box className='title-info'>
                                                    {`$${datasource?.totalCredited}`}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Hidden>
                                {selectedData?.description && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={1}>
                                            <img alt={`Note`} src={NoteIcon} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color='textSecondary' gutterBottom>
                                                Description
                                            </Typography>
                                            <Typography style={{whiteSpace: 'pre-line'}}>
                                                {selectedData.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {selectedData?.receipt_file_id &&
                                        <Grid item xs={12}>
                                            <Typography color='textSecondary' gutterBottom>
                                                Receipt File
                                            </Typography>
                                            <Box minHeight='300px' border='1px solid #f5f5f9' bgcolor='#f5f5f9'>
                                                <img
                                                    style={{ minWidth: 100, maxWidth: '100%' }}
                                                    src={`${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/api/account/${appData.current_account_id}/file_center/file/${selectedData.receipt_file_id}/view`}
                                                    alt='Preview'
                                                />
                                            </Box>
                                        </Grid>}
                            </Box>
                        </div>
                    </div>
                </Grid>
            )}
        </Grid>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(JournalTread);
