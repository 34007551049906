import { IBankAccount } from '../../models/bank-account-models';

export interface Action {
    type: string;
    state: any;
}
export const setBankAccDetails = {
    SET_BANK_ACC_DETAILS : 'SET_BANK_ACC_DETAILS',
}

export const setBankAccountsData = (action: string, data: IBankAccount[]): Action => ({
    type: action,
    state: data,
});
  