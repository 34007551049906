import { getResource, postResource } from '../axiosService';

export function getSlotsByTopic<T>(topic: string, accountant_id?: string, slot_override: string='30') {
    return getResource<T>(
        'api/communication/appointment/available_slots_by_topic',
        undefined,
        { topic, accountant_id, slot_override }
    )
    
}

export function addAppointment(data: any) {
    return postResource('/api/communication/appointment', data);
}

export function getAppoinmentTopics() {
    return getResource('api/communication/appointment/topics');
}

export function getUpcomingEvents<T>(account_id: string, params: any) {
    return getResource<T>(
        `/api/account/${account_id}/tax-calendar/event`,
        undefined,
        params
    );
}
