import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom'
import {
    Button,
    Hidden,
    Container,
    makeStyles,
    Theme,
    Grid,
    Divider,
    Paper,
    Typography,
    SnackbarContent,
    Checkbox,
    TextField,
    FormControlLabel,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add'
import MenuDropdown from '../../common/MenuDropdown'
// components
import ProductList from './ProductList'
import ProductPreview from './ProductPreview'
import ProductModal from './ProductModal'
import ActionButton from './ActionButton'
import ProductCategorizeModal from './ProductCategorizeModal'
import MobileHeader from '../../common/MobileHeader'
import ConfirmProductModal from './ConfirmProductModal'
import useProducts from './Products.hook'
import InvoiceFormModal from '../invoices/Modal/InvoiceFormModal';
import { useProductServiceContextState } from './ProductServicesProvider';
import SearchIcon from '@material-ui/icons/Search';
import { commonStyles, mergeStyle } from '../../../styles/commonStyles';
import CloseIcon from '@material-ui/icons/Close'
import UiText from '../../../components/common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

function Products() {

    const useStyles = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
            listContent: {
                paddingBottom: theme.spacing(3),
                width: 'inherit',
                height: 'calc(100vh - 120px)'
            },
            content: {
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.up(1601)]: {
                    justifyContent: 'flex-start',
                },
            },
            snackbar: {
                position: 'absolute',
                top: 0,
                zIndex: 1110,
                left: 0,
                width: '100%',
                '& > * + *': {
                    marginTop: theme.spacing(2),
                },
                '& .MuiSnackbarContent-message': {
                    padding: '8px 35px',
                    [theme.breakpoints.down('sm')]: {
                        padding: '8px 0px',
                    },
                },
                '& .MuiSnackbarContent-root': {
                    borderRadius: 0,
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    left: 0,
                },
            },
            snackbarContent: {
                background: colorTheme.secondary, 
            },
            selectAllBox: {
                marginLeft: '5px',
            },
            autocompleteOption: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            },
            iconButtonRoot: {
                padding: '4px' 
            },
            closeIconRoot: {
                fill: `${colorTheme.primaryWhite}`
            },
            messageContent: {
                marginLeft: '10px', 
                marginTop: '4px'
            }
        }
    })
    const { colorTheme } = useThemeContext()
    const classes = useStyles()
    const common = commonStyles()
    const history = useHistory()
    const { 
        path,
        openModal,
        setOpenModal,
        openCategorizeModal,
        setOpenCategorizeModal,
        showConfirmModal,
        searchTerm,
        productType,
        setProductType,
        selectedProduct,
        setSelectedProduct,
        productUpdated,
        setProductUpdated,
        selectAll,
        productsData,
        checkedProducts,
        loadProducts, 
        loadMore,
        handleContactModalClose,
        handleConfirmModal,
        handleClickType,
        handleCheck,
        handleCheckAll,
        dropdownOptions,
        checkedProdObj,
        openInvoiceModal,
        setOpenInvoiceModal,
        resetCheckedItems,
        setProductCategory,
        selectedProductCategory,
        setCheckedProducts
    } = useProducts()
    
    const { loading, items, hasMore } = productsData
    const { productCategories } = useProductServiceContextState()
    
    return (
        <Container className={classes.container} maxWidth="lg">
            {checkedProducts.length > 0 && (
                <div className={classes.snackbar}>
                    <SnackbarContent
                        classes={{
                            root: classes.snackbarContent,
                        }}
                        message={
                            <div className={common.flex}>
                                <div>
                                    <IconButton 
                                        edge="start" 
                                        classes={{ root: classes.iconButtonRoot}}
                                        onClick={()=> {
                                            setCheckedProducts([])
                                        }} 
                                        aria-label="close">
                                        <CloseIcon classes={{ root: classes.closeIconRoot}}/>
                                    </IconButton>
                                </div>
                                <div className={classes.messageContent}>
                                    <UiText variant="car_100" weight="medium_500">
                                        {`${checkedProducts.length} Selected`}
                                    </UiText>
                                </div>
                            </div>
                        }
                        action={
                            <ActionButton
                                handleClickType={handleClickType}
                                itemCount={checkedProducts.length}
                            />
                        }
                    />
                </div>
            )}
            <InvoiceFormModal
                open={openInvoiceModal}
                handleClose={()=> {
                    setOpenInvoiceModal(false)
                }}
                isNew={true}
                modalTitle={"New Invoice"}
                onSuccessCallback={()=> {
                    history.push('/invoices')
                }}
                productsData={checkedProdObj}
            />
            <ConfirmProductModal
                isEditable={checkedProducts.length > 0}
                showConfirmModal={showConfirmModal}
                product={undefined}
                handleClose={handleConfirmModal}
                bulkDelete={checkedProducts.length > 0}
                bulkDeleteCount={checkedProducts.length}
            />
            <ProductModal
                open={openModal}
                handleClose={handleContactModalClose}
                isNew={true}
                loadProducts={() => {
                    resetCheckedItems();
                    loadProducts({ text: searchTerm })
                }}
            />
            <ProductCategorizeModal
                open={openCategorizeModal}
                handleClose={() => setOpenCategorizeModal(false)}
                loadProducts={() => {
                    resetCheckedItems();
                    loadProducts({ text: searchTerm })
                }}
                productCount={checkedProducts.length}
                checkedProducts={checkedProducts}
            />
            <MobileHeader title={'Products/Services'} showBottomDivider={true} />
            <Grid container spacing={1}>
                <Grid
                    container
                    item
                    md={5}
                    sm={12}
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.content}
                >
                    <div className={classes.listContent}>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => setOpenModal(true)}
                            >
                                Add New
                            </Button>
                        </div>
                        <Autocomplete
                            id="category-select-options"
                            className={mergeStyle(common.mt16, common.mb16)}
                            fullWidth
                            value={selectedProductCategory}
                            options={productCategories as any}
                            placeholder="Select Product category"
                            getOptionLabel={(option: any) => option?.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    
                                />
                            )}
                            renderOption={(option: any) => {
                                return (
                                    <div className={classes.autocompleteOption}>
                                    <div>{option?.title}</div>
                                    <Typography variant="caption">
                                        {option?.parent_title}
                                    </Typography>
                                </div>
                                )
                            }}
                            onChange={(event, item: any) => {
                                resetCheckedItems()
                                setProductCategory(item ? item : null)
                                history.push('/products')
                                setSelectedProduct(undefined)
                            }}
                            PaperComponent={({ children, ...other }: any) => (
                                <Paper {...other}>
                                    {children}
                                </Paper>
                            )}
                        />
                        <div className={mergeStyle(common.flex, common.spaceBetween)}>
                            <FormControlLabel
                                className={classes.selectAllBox}
                                control={
                                    <Checkbox
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                            handleCheckAll(e.target.checked)
                                        }
                                        size="small"
                                        checked={selectAll}
                                        disabled={loading ||items.length === 0}
                                    />
                                }
                                label="Select all"
                            />
                            <div>
                                <MenuDropdown
                                    options={dropdownOptions}
                                    selectedIndex={productType}
                                    setSelectedIndex={(index: number)=> {
                                        resetCheckedItems()
                                        setProductType(index)
                                        history.push('/products')
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </div>                        
                        <ProductList
                            loading={loading}
                            data={items}
                            loadMore={loadMore}
                            hasMore={hasMore}
                            selectedProduct={selectedProduct}
                            setSelectedProduct={setSelectedProduct}
                            productUpdated={productUpdated}
                            handleCheck={handleCheck}
                            checkedProducts={checkedProducts}
                            productType={productType}
                        />
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid
                        sm={1}
                        item
                        container
                        direction="row"
                        justify="center"
                        
                    >
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={12} md={6} justify="center">
                        <Switch>
                            <Route exact path={path}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="subtitle1">
                                        Select a product to view the details
                                    </Typography>
                                </Grid>
                            </Route>
                            <Route exact path={`${path}/:id`}>
                                <ProductPreview
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                    loadProducts={() =>
                                        loadProducts({ text: searchTerm })
                                    }
                                    setProductUpdated={setProductUpdated}
                                />
                            </Route>
                        </Switch>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    )
}

export default Products