import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import UiDialog from '../common/ui/UiDialog';
import { connect } from 'react-redux';
import { ApplicationStore } from '../../models';
import { hasPermission } from '../../services/aclService';
import { useHistory } from 'react-router-dom';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
 
const today = new Date();
const finishNowBtnText = 'Finish now';
const remindLaterBtnText = 'Remind Me Later';
const expirationDate: any = '04/18/2022';
let cookie: any = 'personalTaxesReminderDate';
let type: any = '';

 function TaxSubmissionReminderModal(props: any) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => ({
        container: {
            '& .MuiDialogTitle-root': {
                borderBottom: 'none',
                padding: '15px 45px 0px'
            }
        },
        formActions: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        remindLaterBtn: {
            marginLeft: theme.spacing(2),
            color: `${colorTheme.black100} !important`,
            [theme.breakpoints.down('xs')]: {
                margin: theme.spacing(2, 0),
            },
        },
        TextCenter: {
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
            },
        },
    }))();
    
    const taxData = props.taxData;
    const history = useHistory();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    let vtoBusinessPermission = hasPermission('VTO_BUSINESS', props.appData.current_account);
    let vtoPersonalPermission = hasPermission('VTO_PERSONAL', props.appData.personal_account);
    let vtoPersonalYearSubmitted  = taxData?.personal && taxData?.personal?.tax_preparation_status !== 'none' && taxData?.personal?.tax_preparation_status !== 'Started';
    let vtoBusinessYearSubmitted = taxData?.business && taxData?.business?.tax_preparation_status !== 'none' && taxData?.business?.tax_preparation_status !== 'Started';
    const heading = 'Submit Your ' + type + ' Tax Details';
    const text = 'We noticed you haven’t submitted your ' + type.toLowerCase() +' tax details yet. The tax deadline is approaching quickly, and we need time to review your data and prepare your returns before the IRS deadline hits. Don’t delay.';
    const finishNowBtnLink: any = 'tax-organizer/' + type.toLowerCase();

    const handleModalClose = (result: any) => {
        if(result === true){
            setOpenInfoModal(false);
            history.push(finishNowBtnLink);
        }else if(result === false){
            let oneWeekDate: any = new Date();
            oneWeekDate = oneWeekDate.setDate(oneWeekDate.getDate() + 6);
            oneWeekDate = new Date(oneWeekDate);
            oneWeekDate = oneWeekDate.toLocaleString('en-US', { //est time
                timeZone: 'America/New_York'
            });
            oneWeekDate = new Date(oneWeekDate).toUTCString();
            document.cookie = cookie + "=" + oneWeekDate + "; expires=" + oneWeekDate + "; path=/";
            setOpenInfoModal(false);
        }
    };
    
    useEffect(() => {
        if(vtoBusinessPermission && !vtoBusinessYearSubmitted){
            type = 'Business';
            cookie = 'businessTaxesReminderDate' + props.appData.current_account_id;
        }else if(vtoPersonalPermission && !vtoPersonalYearSubmitted){
            type = 'Personal';
        }
        let personalTaxesReminderCookie: any = document.cookie.split(";");
        let taxesReminderCookieValue: any = false;
        personalTaxesReminderCookie.forEach(function (item: any) {
            let cookieName: any = item.split("=");
            cookieName.forEach(function (item: any){
                if(item.trim() === cookie) taxesReminderCookieValue = true; 
            });
        });

        if(today <= new Date(expirationDate) && type !== '' && !taxesReminderCookieValue){
            setOpenInfoModal(true);
        }
    }, [props.appData.current_account_id, vtoBusinessPermission, vtoBusinessYearSubmitted, vtoPersonalPermission, vtoPersonalYearSubmitted]);

    
      return (
                <UiDialog
                    open={openInfoModal}
                    handleClose={handleModalClose}
                    title=''
                    size='sm'
                    customRootClass={classes.container}
                >
                    <Fragment>
                        <div>
                            <Fragment>
                                <Typography
                                    className={classes.TextCenter}
                                    variant='h6'
                                    style={{ marginBottom: 24 }}
                                >
                                    {heading}
                                </Typography>
                                <Typography
                                    className={classes.TextCenter}
                                    variant='body1'
                                >
                                    {text}
                                </Typography>
                            </Fragment>
                        </div>

                        <div className={classes.formActions}>
                            <Button
                                variant='contained'
                                onClick={() => handleModalClose(true)}
                                color='primary'
                            >
                                {finishNowBtnText}
                            </Button>
                            <Button
                                variant='outlined'
                                onClick={() => handleModalClose(false)}
                                color='primary'
                                className={classes.remindLaterBtn}
                            >
                                {remindLaterBtnText}
                            </Button>
                        </div>
                    </Fragment>
                </UiDialog>
            );
};

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    config: state.config
});

export default connect(mapStateToProps)(TaxSubmissionReminderModal);