export default function maskUtil (input: string | number, mask: string) {
    if (!input) {
        return 'n/a';
    }
    const sections = mask.split('-');
    let tmp = 0;
    let result = '';
    let value = input + '';
    sections.forEach(section => {
        result += (result ? '-' : '') + value.substr(tmp, section.length);
        tmp += section.length;
    })
    return result || 'n/a';
}

export const inputMaskRegex = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
]

export const zipCodeRegex = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
export const phoneNumberRegex = [
    '(',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ')',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
]
export const contactZipCodeRegex = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

export const ssNRegex = [
    /[0-9,X]/,
    /[0-9,X]/,
    /[0-9,X]/,
    '-',
    /[0-9,X]/,
    /[0-9,X]/,
    '-',
    /[0-9,X]/,
    /[0-9,X]/,
    /[0-9,X]/,
    /[0-9,X]/,
]