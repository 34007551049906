import { Theme, makeStyles, IconButton } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { AppData, ApplicationStore, Config } from '../../models';
import { connect } from 'react-redux';
import { logout } from '../../services/authService';
import { COLORS } from '../../variables/colors';

function AdminLoginBanner(props: { appData: AppData; config: Config }) {
    const classes = makeStyles((theme: Theme) => {
        return {
            adminBanner: {
                position: 'sticky',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 9999,
                background: COLORS.primaryBlack,
                color: COLORS.primaryWhite,
                fontSize: '15px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '12px',
                },
            },
            username: {
                padding: theme.spacing(1, 3),
                fontWeight: 500,
            },
            actionBtn: {
                fontSize: '15px',
                color: COLORS.primaryWhite,
                lineHeight: '24px',
                fontWeight: 600,
                '& > span > span': {
                    marginLeft: theme.spacing(1),
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '10px',
                    padding: theme.spacing(1),
                },
            },
        };
    })();

    const { user, accountant } = props.appData;
    return user.accountant_mode ? (
        <div className={classes.adminBanner}>
            <div className={classes.username}>
                {accountant
                    ? `${accountant.first_name} ${accountant.last_name}`
                    : 'Accountant Login'}
            </div>
            <div>
                <IconButton
                    className={classes.actionBtn}
                    onClick={(e) => {
                        window.location.href = props.config.apiConfig.admin.url;
                    }}
                >
                    <SettingsOutlinedIcon />
                    <span>Admin App</span>
                </IconButton>
                <IconButton className={classes.actionBtn}  onClick={(e) => {
                        logout();
                     }}>
                    <img
                        alt={`Logout Icon`}
                        src={
                            require(`../../assets/icons-svg/logout.svg`).default
                        }
                    />
                    <span>Sign Out</span>
                </IconButton>
            </div>
        </div>
    ) : null;
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    config: state.config,
});

export default connect(mapStateToProps)(AdminLoginBanner);
