import React from 'react';
import UiModal from '../../../common/ui/UiModal'
import { Box, Theme, makeStyles, styled, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import UiText from '../../../common/ui/UiText'
import { useWidgetContext } from '../provider/WidgetProvider'
import TaxPrice from '../tax-prep-statuses/TaxPrice'
import { StateTax } from '../utils/states'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

type StatesModalProps = {
    open: boolean
    handleClose: () => void
}
const StateList = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    padding: '1rem 0',
    [theme.breakpoints.down('xs')]: {
        padding: '0.7rem 0',
    },
}))

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    modalContainer: {
        width: '30rem',
        backgroundColor: colorTheme => colorTheme.primaryWhite,
        borderRadius: '1rem',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            borderRadius: 0,
            overflow: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            width: '20.5rem',
        },
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1.375rem',
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
        boxSizing: 'border-box',
        borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
        backgroundColor: colorTheme => colorTheme.primaryWhite,
    },
    closeIconButton: {
        padding: 0,
    },
    stateInfoContainer: {
        padding: '1rem 1.5rem',
    },
    stateTaxList: {
        borderBottom: colorTheme => `2px solid ${colorTheme.grey200}`,
    },
    positiveIndicator: {
        color: colorTheme => colorTheme.black100,
    },
    negativeIndicator: {
        color: colorTheme => colorTheme.red200,
    },
}))

const StatesModal = ({ open, handleClose }: StatesModalProps) => {
    const { taxYear, stateTax, taxPrepData } = useWidgetContext()
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    
    const business = taxPrepData?.business

    const closeStateModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        handleClose()
    }

    return (
        <UiModal open={open} handleClose={closeStateModal}>
            <div className={classes.modalContainer}>
                <div className={classes.modalHeader}>
                    <UiText variant="car_100" weight="medium_500">
                        {taxYear} State Tax Details
                    </UiText>
                    <IconButton
                        aria-label="close"
                        classes={{ root: classes.closeIconButton }}
                        onClick={closeStateModal}
                        data-cy="cross-modal-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.stateInfoContainer}>
                    <div className={classes.stateTaxList}>
                        {business?.state_taxes?.map(
                            ({ title, amount, type }: StateTax) => {
                                return (
                                    <StateList key={title}>
                                        <UiText>{title}</UiText>
                                        <TaxPrice type={type} amount={amount} />
                                    </StateList>
                                )
                            }
                        )}
                    </div>
                    <div className="statesTaxSummation">
                        <StateList>
                            <UiText variant="hatchback_125">Total</UiText>
                            <TaxPrice amount={stateTax} />
                        </StateList>
                    </div>
                </div>
            </div>
        </UiModal>
    )
}

export default StatesModal
