import { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    createStyles,
    FormHelperText,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import InfoTooltip from '../../common/InfoTooltip';
import UiChip from '../../common/ui/UiChip';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiFormField from '../../common/ui/UiFormField';
import { useReportsStyles } from './styles/reports-styles';
import { useHistory } from 'react-router-dom';
import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
} from './constants/reports.const';
import {
    getAllCOASegments,
    getIncomeStatementReport,
} from '../../../services/apiService/reports';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    ApiError,
    IncomeStatementReportRoot,
    ErrorAlertReport,
} from './models/reports-interface';
import store from '../../../store';
import { reportsDataActions } from '../../../store/actions/reportsData';
import Loader from '../../common/Loader';
import {
    getTimeZoneOffset,
    getCurrentTimeStamp,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil';
import { showError } from '../../../services/formService';
import UiText from '../../common/ui/UiText';
import { commonStyles, mergeStyle } from '../../../styles/commonStyles';
import SelectCompareDataModal from './utils/modal/SelectCompareDataModal';
import { SegmentList } from '../../../models/chartOfAccountants';
import { ReactComponent as SearchIconImage } from '../../../assets/icons-svg/SearchIcon.svg';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { FormObserver } from './utils/Form/FormObserver';
import { ReactComponent as CalendarIcon } from '../../../assets/icons-svg/Calendar.svg';
import UiButton from '../../common/ui/UiButton';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface FormValues {
    from: number;
    to: number;
    isCompareSegments: boolean;
}
const initialFormValues = {
    from: moment.utc().hour(12).minute(0).second(0).unix(),
    to: moment.utc().hour(12).minute(0).second(0).unix(),
    isCompareSegments: false,
};

const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        dateChips: {
            margin: '1rem 0',
            '& .MuiChip-root':{
                margin: '0 1rem 0 0'
            },
            '& .MuiChip-clickable:hover':{
                backgroundColor: (colorTheme) => colorTheme.grey600,
            }
        },
        compareSegments: {
            margin: '1.5rem 0 1rem 0',
        },
        submitbtn: {
            margin: '2.2rem 0 0.5rem 0',
        },
        input: {
            '&:hover': {
                borderColor: (colorTheme) => colorTheme.black100,
            },
        },
        errorText: {
            color: (colorTheme) => colorTheme.red100,
        },
        errorBorder: {
            borderColor: (colorTheme) => colorTheme.red100,
            '&:hover': {
                borderColor: (colorTheme) => colorTheme.red100,
            },
        },
    })
);
const TOOLTIP_TEXT =
    'The Income Statement shows your company’s expenses and revenue of the selected period of time.';
const SEGMENTS_TITLE = 'Select Segments';

export default function IncomeStatementReport() {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const localClasses = useLocalStyles(colorTheme);
    const commonClass = commonStyles();
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const storeData = useCurrentStore();
    const accountId = storeData.currentAccountId;
    const businessId = storeData.currentBusinessId!;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSelectSegmentsModalOpen, setIsSelectSegmentsModalOpen] =
        useState<boolean>(false);
    const [segments, setSegments] = useState<SegmentList[]>();
    const [checkedSegments, setCheckedSegments] = useState<SegmentList[]>([]);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const [compareValidation, setCompareValidation] = useState<string | null>(
        null
    );

    const getCurrentIndex = (
        checkedSegments: SegmentList[],
        segment: SegmentList
    ) => {
        const currentIndex = checkedSegments.indexOf(
            checkedSegments.filter((item: any) => item.id === segment.id)[0]
        );
        return currentIndex;
    };
    const addRemoveItemsBasedOnIndex = (
        currentIndex: number,
        newChecked: SegmentList[],
        segment: SegmentList
    ) => {
        if (currentIndex === -1) {
            newChecked.push(segment);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        return newChecked;
    };

    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };

    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };

    const getCheckedSegmentIds = () => {
        const segmentIds: string[] = [];
        checkedSegments.map((segment: SegmentList) => {
            return segmentIds.push(segment.id);
        });
        return segmentIds.join(',');
    };

    const handleToggleSegments = (segment: SegmentList) => {
        const currentIndex = getCurrentIndex(checkedSegments, segment);
        const newChecked = [...checkedSegments];
        const newSegmentsArray = addRemoveItemsBasedOnIndex(
            currentIndex,
            newChecked,
            segment
        );
        setCheckedSegments(newSegmentsArray);
        setCompareValidation(null);
    };
    const deleteSegment = (id: string) => {
        const segments = checkedSegments.filter((segment: SegmentList) => {
            return segment.id !== id;
        });
        setCheckedSegments(segments);
    };
    const resetCheckedValues = () => {
        setCheckedSegments([]);
    };

    const compareSegmentsCheck = (values: FormValues) => {
        if (!values.isCompareSegments) {
            resetCheckedValues();
        }
    };
    const openSegmentSelectModal = () => {
        setIsSelectSegmentsModalOpen(true);
    };
    const closeSegmentSelectModal = () => {
        setIsSelectSegmentsModalOpen(false);
    };

    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const history = useHistory();
    const businessName = storeData.currentBusiness?.name;

    const fetchAPIQueryParams = (from: number, to: number) => {
        const start_date_ymd = moment(from * 1000).format(DATE_FORMATS.ymdDate);
        const end_date_ymd = moment(to * 1000).format(DATE_FORMATS.ymdDate);
        const periodText = (
            'From ' +
            moment(from * 1000).format(DATE_FORMATS.periodText) +
            ' to ' +
            moment(to * 1000).format(DATE_FORMATS.periodText)
        ).replace(findSpaceRegex, '+');
        const time = getCurrentTimeStamp();
        const timezoneOffset = getTimeZoneOffset();
        return {
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
            timezoneOffset,
        };
    };

    useEffect(() => {
        setIsLoading(true);
        getAllCOASegments(accountId, businessId)
            .then((res: unknown) => {
                setIsLoading(false);
                const segmentValues = res as SegmentList[];
                setSegments(segmentValues);
            })
            .catch((error) => {
                setIsLoading(false);
                showError(error?.statusText)
            });
    }, [
        accountId,
        businessId,
        storeData.currentAccountId,
        storeData.currentBusinessId,
    ]);

    const navigateToIncomeStatementDetailedReports = (
        isCompareSegments: boolean,
        startDate: number,
        endDate: number,
        start_date_ymd: string,
        end_date_ymd: string,
        time: number
    ) => {
        const segmentIds = getCheckedSegmentIds();
        let pageUrl = `/reports/income-statement/${startDate}/${endDate}/${start_date_ymd}/${end_date_ymd}/${time}`;
        if (isCompareSegments) {
            pageUrl = `/reports/income-statement/${startDate}/${endDate}/${start_date_ymd}/${end_date_ymd}/${time}?view=compare&segments=${segmentIds}`;
        }
        history.push(pageUrl);
    };
    const dispatchReportsDataToStore = (data: IncomeStatementReportRoot) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };

    const generateIncomeStatementReports = (
        from: number,
        to: number,
        isCompareSegments: boolean
    ) => {
        const { start_date_ymd, end_date_ymd, periodText, time } =
            fetchAPIQueryParams(from, to);
        if (!businessId) return;
        setIsLoading(true);
        getIncomeStatementReport(
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
            getCheckedSegmentIds()
        )
            .then((result: unknown) => {
                setIsLoading(false);
                const data = result as IncomeStatementReportRoot;
                dispatchReportsDataToStore(data);
                navigateToIncomeStatementDetailedReports(
                    isCompareSegments,
                    from,
                    to,
                    start_date_ymd,
                    end_date_ymd,
                    time
                );
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };
    const onSubmit = (values: FormValues) => {
        const { from, to, isCompareSegments } = values;
        if (isCompareSegments && !checkedSegments.length) {
            setCompareValidation('Please select segments first');
            return;
        }
        generateIncomeStatementReports(from, to, values.isCompareSegments);
    };
    useEffect(() => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            true
        );
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    }, []);
    const updateDates = (dateType: string): void => {
        setSelectedDateChip(dateType);
        const { startDate, endDate } = getTimeParamsWithoutEST(dateType, true);
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        const fromDateChanged = formikObject.touched?.from;
        const toDateChanged = formikObject.touched?.to;

        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
        setCompareValidation(null);
        compareSegmentsCheck(values);
    };
    const isShowClearAllButton = () : boolean =>{
        return checkedSegments.length > 1;
     }
     const clearAllSegments = (event: any) =>{
        event.preventDefault();
        resetCheckedValues();
    }
    return (
        <div className={reportsClasses.reportsViewParent}>
            <UiText className="businessName">{businessName}</UiText>
            <UiText
                variant="suv_150"
                weight="semi_bold_600"
                className={reportsClasses.pageTitle}
            >
                Income Statement
                <InfoTooltip tooltipText={TOOLTIP_TEXT}></InfoTooltip>
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}
            <UiText weight="medium_500" style={{ marginBottom: '0.625rem' }}>
                Select a period of time
            </UiText>

            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ values }) => {
                    return (
                        <Form>
                            <FormObserver onformChange={onformChangeCallback} />
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    <div
                                        className={mergeStyle(
                                            commonClass.flex,
                                            commonClass.alignCenter,
                                            'calendarSection'
                                        )}
                                    >
                                        <UiFormControlSelection
                                            label="From"
                                            type="date"
                                            fieldName="from"
                                            dateType="string"
                                            showFloatingLabel={true}
                                            disableFutureDate={true}
                                            endIcon={<CalendarIcon />}
                                        />
                                        <div className={commonClass.mx10}>
                                            {' - '}
                                        </div>
                                        <UiFormControlSelection
                                            label="To"
                                            type="date"
                                            fieldName="to"
                                            dateType="string"
                                            showFloatingLabel={true}
                                            disableFutureDate={true}
                                            endIcon={<CalendarIcon />}
                                        />
                                    </div>
                                    <div className={localClasses.dateChips}>
                                        <UiChip
                                            label={
                                                <UiText variant="motorcycle_90">
                                                    {DATE_TYPES.THIS_YEAR}
                                                </UiText>
                                            }
                                            onClickCallout={() => {
                                                updateDates(
                                                    DATE_TYPES.THIS_YEAR
                                                );
                                            }}
                                            customRootStyles={
                                                selectedDateChip ===
                                                DATE_TYPES.THIS_YEAR
                                                    ? 'selectedChip'
                                                    : 'customChip'
                                            }
                                            cypressId='this-year-chip-btn'
                                        />
                                        <UiChip
                                            label={
                                                <UiText variant="motorcycle_90">
                                                    {DATE_TYPES.LAST_QUARTER}
                                                </UiText>
                                            }
                                            onClickCallout={() => {
                                                updateDates(
                                                    DATE_TYPES.LAST_QUARTER
                                                );
                                            }}
                                            customRootStyles={
                                                selectedDateChip ===
                                                DATE_TYPES.LAST_QUARTER
                                                    ? 'selectedChip ml8'
                                                    : 'customChip ml8'
                                            }
                                            cypressId='last-quater-chip-btn'
                                        />

                                        <UiChip
                                            label={
                                                <UiText variant="motorcycle_90">
                                                    {DATE_TYPES.LAST_YEAR}
                                                </UiText>
                                            }
                                            onClickCallout={() => {
                                                updateDates(
                                                    DATE_TYPES.LAST_YEAR
                                                );
                                            }}
                                            customRootStyles={
                                                selectedDateChip ===
                                                DATE_TYPES.LAST_YEAR
                                                    ? 'selectedChip'
                                                    : 'customChip'
                                            }
                                            cypressId='last-year-chip-btn'
                                        />
                                    </div>
                                    <div
                                        className={localClasses.compareSegments}
                                    >
                                        <UiFormField
                                            type="checkbox"
                                            inlineLable="Compare Segments"
                                            label=""
                                            labelSize={false}
                                            fieldName="isCompareSegments"
                                            cypressId='compare-segments-checkbox'
                                        />
                                    </div>
                                   
                                    <div
                                        className={
                                            reportsClasses.multiSelectInput
                                        }
                                    >
                                        {
                                             isShowClearAllButton() &&  <UiButton
                                            handleClick={clearAllSegments}
                                            label='Clear All'
                                            btnType='primary'
                                            customClass={reportsClasses.clearAllSegmentsButton}
                                        />
                                        }
                                        <TextField 
                                            id="outlined-basic"
                                            label="Select Segments"
                                            variant="outlined"
                                            size="small"
                                            data-cy='select-segment-input'
                                            disabled={!values.isCompareSegments}
                                            onClick={() => {
                                                if (!values.isCompareSegments)
                                                    return;
                                                openSegmentSelectModal();
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {<SearchIconImage />}
                                                    </InputAdornment>
                                                ),
                                                endAdornment:
                                                    checkedSegments.map(
                                                        (segment) => (
                                                            <Chip
                                                                key={segment.id}
                                                                tabIndex={-1}
                                                                label={
                                                                    segment.name
                                                                }
                                                                onDelete={() => {
                                                                    deleteSegment(
                                                                        segment.id
                                                                    );
                                                                }}
                                                            />
                                                        )
                                                    ),
                                            }}
                                        />
                                    </div>
                                    {compareValidation && (
                                        <FormHelperText
                                            className={localClasses.errorText}
                                        >
                                            {compareValidation}
                                        </FormHelperText>
                                    )}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        className={localClasses.submitbtn}
                                        data-cy='generate-report-btn'
                                    >
                                        Generate Report
                                    </Button>
                                </>
                            )}
                        </Form>
                    );
                }}
            </Formik>

            {segments && (
                <SelectCompareDataModal
                    listData={segments}
                    checkedData={checkedSegments}
                    handleToggle={handleToggleSegments}
                    isOpen={isSelectSegmentsModalOpen}
                    handleClose={closeSegmentSelectModal}
                    title={SEGMENTS_TITLE}
                    idKey="id"
                    valueKey="name"
                    searchKey="name"
                />
            )}
        </div>
    );
}
