import React from 'react';
import Container from '@material-ui/core/Container';
import {
  makeStyles,
  Typography,
  Theme,
} from "@material-ui/core";
import notFoundIcon from '../../../assets/icons-svg/NotFound.svg'
import PopularPageContent from '../../common/PopularPageContent'

const ErrorPage: React.FC = () => {
  const classes = makeStyles((theme: Theme) => {
    return {
      headerStyle: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(6)
      },
      svgClass: {
        height: '80px',
        width: '80px',
        marginTop: theme.spacing(7)
      },
      subHeader: {
        fontSize: '24px',
        fontWeight: 'normal'
      }
    }
  })();
  return (
    <Container fixed>
      <img
        alt={`404 error`}
        src={notFoundIcon}
        className={classes.svgClass}
      />
      <Typography variant='h3' className={classes.headerStyle}>404 - Page not found</Typography>
      <Typography variant="h6" gutterBottom className={classes.subHeader}>Don't worry, we'll help you get to where you need to go</Typography>
      <PopularPageContent align='flex-start'/>
    </Container>
  )
}


export default ErrorPage