import {
    Box,
    Button,
    Container,
    Divider,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Business, AppData, ApplicationStore, Config } from '../../models';
import BusinessEditForm from '../common/BuinsessEditForm';
import Loader from '../common/Loader';
import {
    ActiveRoutingContext,
    ActiveTourStepContext,
} from '../routing/Providers/ActiveRoutingProvider';
import { loadPostAuthData } from '../../store/actions/appData';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
    setInLocalStorage,
} from '../../services/localStorageService';
import { initPermissionCheck } from '../../utils/appUtil';
import { createBusinessAccount, createBusiness } from '../../services/apiService';
import { formSubmitErrorHandler } from '../../services/formService';
import UpgradePlanBusiness from './UpgradePlanBusiness';

let initialValues: Business = {
    name: '',
    ein: '',
    description: '',
    industry: '',
    business_type: '',
    form_date: null,
    form_state: '',
    address: {
        address: '',
        city: '',
        state: '',
        zip_code: '',
        country: 'US',
    },
    business_owners: [
        {
            first_name: '',
            last_name: '',
            address: {
                address: '',
                city: '',
                state: '',
                country: 'US',
                zip_code: '',
            },
            ssn: '',
        },
    ],
    business_phone: '',
    business_info: {
        was_operational: false,
        bookkeeping_software: '',
        entity_type: '',
    },
    phone_type_us: 'Yes'
};


const BusinessOnboarding: React.FC<{
    loadPostAuthData: any;
    config: Config;
    appData: AppData;
    newAccount?: boolean;
    accountantMode?: boolean | undefined;
    accessLevelPlatform?: boolean | undefined;
}> = ({
    appData,
    loadPostAuthData,
    newAccount = false,
    accountantMode,
    accessLevelPlatform
}): ReactElement => {
    const classes = makeStyles((theme: Theme) => {
        return {
            btnContainer: {
                position: 'sticky',
                top: 0,
                left: 0,
                backgroundColor: 'white',
                zIndex: 100,
            },
            btnDivider: {
                marginTop: '1em',
            },
            toggleBtn: {
                margin: '0px 10px 0px 0',
            },
        };
    })();

    const history = useHistory();
    if (!newAccount && appData.current_business_id) {
        history.push('/dashboard');
    }

    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);

    const { setActiveTourStep } = useContext(ActiveTourStepContext);

    useEffect(() => {
        setActiveRouteHeading('Enter Business Details');
    }, [setActiveRouteHeading]);

    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    initialValues.business_owners = [
        {
            first_name: appData.user.first_name,
            last_name: appData.user.last_name,
            address: {
                address: '',
                city: '',
                state: '',
                country: 'US',
                zip_code: '',
            },
            ssn: '',
        },
    ];

    const handleFormSubmit = (
        values: Business,
        actions: FormikHelpers<Business>
    ) => {
        setIsFormSubmitting(true);
        const submitApi = newAccount ? createBusinessAccount : createBusiness;
        values.is_us_phone = values.phone_type_us === 'Yes' ? true : false;
        formSubmitErrorHandler(
            submitApi(values, appData.current_account_id).then(
                (response: any) => {
                    loadPostAuthData(response.portal_account).then(
                        (res: any) => {
                            if (newAccount) {
                                history.push('/purchase/products');
                            } else {
                                setInLocalStorage('permission_check', {
                                    count: 10,
                                });
                                initPermissionCheck(10);
                                setActiveTourStep(0);
                                history.push('/dashboard');
                            }
                            setIsFormSubmitting(false);
                        }
                    );
                }
            ),
            () => {
                setIsFormSubmitting(false);
            },
            actions.setFieldError
        );
    };

    return (!accessLevelPlatform && !accountantMode) ? (
        <UpgradePlanBusiness/>
    ) : (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleFormSubmit}
        >
            {({ submitForm, handleSubmit, setFieldValue }) => {
                return (
                    <Box>
                        <div className={classes.btnContainer}>
                            <Container>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={submitForm}
                                    className={classes.toggleBtn}
                                    disabled={isFormSubmitting}
                                >
                                    Proceed to Portal
                                </Button>
                                {isFormSubmitting ? <Loader></Loader> : null}
                            </Container>
                            <Divider className={classes.btnDivider} />
                        </div>
                        <Container fixed>
                            <Form onSubmit={handleSubmit}>
                                <BusinessEditForm setField={setFieldValue} />
                            </Form>
                        </Container>
                    </Box>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
    appData: state.appData,
    accountantMode: state.appData.user.accountant_mode,
    accessLevelPlatform: state.auth.accessLevel.platform
});
export default connect(mapStateToProps, { loadPostAuthData })(
    BusinessOnboarding
);
