import { connect } from 'react-redux'
import { ApplicationStore, Business } from '../../../../models'
import UiDialog from '../../../common/ui/UiDialog'
import BusinessSettingEdit from '../../../settings/BusinessSettingEdit'

interface BusinessDetailsEditPopupProps {
    open: boolean;
    handleClose: (result?: any) => void;
    business: Business
}

function BusinessDetailsEditPopup({ open, handleClose, business }: BusinessDetailsEditPopupProps) {

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title="Business Settings"
            size="sm"
        >
            <BusinessSettingEdit data={business} toggleView={handleClose} onBusinessSettingPage={false} />
        </UiDialog>
    )
}
const mapStateToProps = ({ appData, config }: ApplicationStore) => ({
    business: appData.current_business
})
export default connect(mapStateToProps)(BusinessDetailsEditPopup);