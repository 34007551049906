import React, { Fragment, useEffect } from 'react'
import { Grid, List, makeStyles, ListItem } from '@material-ui/core'

import { Location } from '../../../models/locations'
import Loader from '../../common/Loader'
import LocationItem from './LocationItem'
import { InfiniteScrollObserver } from './InfiniteScrollObserver'
import { ListLoader } from '../../dashboard/Cards/CommonComponents'
import { useIntersectionObserver } from '../../common/hooks/useIntersectionObserver'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: 'calc(100vh - 15.75rem)',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
}))

function LocationList({
    loading,
    loadMore,
    data,
    hasMore,
    selectedLocation,
    setSelectedLocation,
    pageCount,
    currentPage,
}: {
    loading: boolean
    loadMore: () => void
    data: Location[]
    hasMore: boolean
    selectedLocation?: Location
    setSelectedLocation: React.Dispatch<Location | undefined>
    pageCount: number
    currentPage: number
}) {
    const classes = useStyles()
    const [ref, entry] = useIntersectionObserver({})
    const isScrollEnd = !!entry?.isIntersecting

    useEffect(() => {
        if (isScrollEnd) {
            hasMore && loadMore()
        }
    }, [hasMore, isScrollEnd, loadMore])

    return (
        <List className={classes.root} id="scrollableDiv">
            {data.length > 0 &&
                data.map((location: Location, index: number) => (
                    <Fragment key={location?.id}>
                        <LocationItem
                            key={location?.id}
                            locationId={location?.id}
                            location={location}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            lastItem={data.length - 1 === index}
                        />
                        {data.length - 1 === index && <div ref={ref}></div>}
                    </Fragment>
                ))}
            {loading && <ListLoader />}
        </List>
    )
}

export default LocationList
