import { useState, useEffect } from 'react';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { ProductDetail } from '../../../models';
import { ContainedDeleteButton } from '../../common/DeleteButton';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface confirmProductModalProps {
    isEditable?: boolean | undefined;
    showConfirmModal: boolean;
    handleClose: (result?: any) => void;
    product?: ProductDetail;
    bulkDelete?: boolean;
    bulkDeleteCount?: number;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    dialogHeader: {
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: theme.spacing(4),
        lineHeight: '28px',
    },
    infoText: {
        marginBottom: theme.spacing(4),
    },
    deleteButton: {
        background: (colorTheme) => colorTheme.red400,
    }
}));

const bulkDeleteInfoText = `The products and services will be \n deleted from your account. 
                            Existing \n invoices that uses this product listing will not be affected`;
const ConfirmProductModal = ({
    isEditable,
    showConfirmModal,
    handleClose,
    product,
    bulkDelete,
    bulkDeleteCount,
}: confirmProductModalProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    let headerText = bulkDelete
        ? `Delete ${bulkDeleteCount} Products/Services`
        : isEditable
        ? 'Delete product'
        : 'Discard Changes?';
    let infoText = bulkDelete
        ? bulkDeleteInfoText
        : isEditable
        ? `Do you want to delete the product ${product?.title}?.`
        : `You have unsaved changes.Do you want to discard them?`;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (showConfirmModal) {
            setLoading(false);
        }
    }, [showConfirmModal]);

    return (
        <UiConfirmationDialog
            open={showConfirmModal}
            message={
                <>
                    <Typography variant="body1" gutterBottom>
                        <div className={classes.dialogHeader}>{headerText}</div>
                        <div className={classes.infoText}>{infoText}</div>
                        <div>This cannot be undone.</div>
                    </Typography>
                </>
            }
            handleClose={(result) => {
                handleClose();
            }}
            showLoader={loading}
            confirmNode={
                <ContainedDeleteButton
                    className={classes.deleteButton}
                    variant="contained"
                    showDeleteIcon
                    onClick={() => {
                        setLoading(true);
                        handleClose(true);
                    }}
                    color="secondary"
                    data-cy={`${isEditable || bulkDelete ? 'delete' : 'discard'}-confirm-button`}
                >
                    {isEditable || bulkDelete ? 'Delete' : 'Discard'}
                </ContainedDeleteButton>
            }
            cancelButtonText="Cancel"
        />
    );
};

export default ConfirmProductModal;
