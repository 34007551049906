import { Grid, makeStyles, TextField, Paper, Box } from '@material-ui/core'
import { Formik, Form } from 'formik'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'
import {
    timeOptions,
    milesOptions,
    refOptions,
    defaultQueryObject,
    activityOptions,
} from './TripUtil'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
    startOfDayTimeStamp,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil'
import type { TripQueryObject } from '../../../models/index'
import { embeddedLocationTempIds } from './Form/TripFormUtils'

type FilterFormProps = {
    onSubmit: (data: any) => void
    vehicles: any
    locations: any
    purposes: any
    formikRef: any
    queryObject: any
    resetKey?: number
}

type FieldLabelProps = {
    label?: string
    showResetButton: boolean
    resetCallout: () => void
    position?: 'start' | 'end'
}

type FieldAutocompleteProps = {
    name?: string
    options: any
    selectedField: string
    values?: any
    formikRef: any
    key?: number
}

type MinDateUIProps = {
    label: string
    parentField: string
    fieldName: string
    maxDateValue: any
}

type MaxDateUIProps = {
    label: string
    parentField: string
    fieldName: string
    minDateValue: any
}

const useStyles = makeStyles((theme) => {
    return {
        flex: {
            display: 'flex',
        },
        sectionStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.25rem',
            marginBottom: '0.25rem',
            height: '2.25rem',
        },
        sectionEndStyle: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '0.25rem',
            marginBottom: '0.25rem',
            height: '2.25rem',
        },
        labelStyle: {
            marginTop: '0.375rem',
        },
        autocompleteOption: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        defaultMenuItem: {
            color: theme.palette.text.disabled,
        },
        ml0_5: {
            marginLeft: '0.5rem',
        },
    }
})

const FieldHeader = ({
    label,
    showResetButton,
    resetCallout,
    position = 'start',
}: FieldLabelProps) => {
    const classes = useStyles()
    return (
        <div
            className={
                position === 'start'
                    ? classes.sectionStyle
                    : classes.sectionEndStyle
            }
        >
            {label && <UiText className={classes.labelStyle}>{label}</UiText>}
            {showResetButton && (
                <UiButton
                    btnType="hyperlink"
                    label="Reset"
                    handleClick={() => {
                        resetCallout()
                    }}
                />
            )}
        </div>
    )
}

const FieldAutocomplete = ({
    name,
    options,
    selectedField,
    values,
    formikRef,
    key,
}: FieldAutocompleteProps) => {
    const classes = useStyles()

    const getOptionLabel = (option: any) => {
        if (!option) {
            return ''
        }
        if (option.license_plate_number) {
            return `${option.make} ${option.model} (${option.license_plate_number})`
        } else if (selectedField === 'vehicle') {
            return `${option.make} ${option.model}`
        }
        return option.title ?? option.name
    }

    type optArg = Record<string, any>
    
    return (
        <Autocomplete
            fullWidth
            key={key}
            value={values?.[selectedField]}
            options={options.filter((option:any) => option.id !== '' && !Object.values(embeddedLocationTempIds).includes(option.id))}
            getOptionSelected={(option: optArg, value: optArg) => {
                return option.id === value.id
            }}
            getOptionLabel={(option: any) => getOptionLabel(option)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    InputProps={params.InputProps}
                    label={name}
                />
            )}
            renderOption={(option: any) => (
                <div className={classes.autocompleteOption}>
                    <div className={classes.flex}>
                        <span className={classes.ml0_5}>
                            {getOptionLabel(option)}
                        </span>
                    </div>
                </div>
            )}
            onChange={(e, item: any) => {
                formikRef?.current?.setFieldValue(selectedField, item ?? '')
            }}
            PaperComponent={({ children, ...other }: any) => {
                return <Paper {...other}>{children}</Paper>
            }}
        />
    )
}

const MinDateUI = ({
    label,
    parentField,
    fieldName,
    maxDateValue,
}: MinDateUIProps) => {
    const MaxDate = new Date(maxDateValue * 1000)
    return (
        <UiFormControlSelection
            label={label}
            type="date"
            fieldName={fieldName}
            placeholder="MM/DD/YYYY"
            maxDate={MaxDate}
            fastField={false}
            disableFutureDate={parentField === 'period'}
        />
    )
}

const MaxDateUI = ({
    label,
    parentField,
    fieldName,
    minDateValue,
}: MaxDateUIProps) => {
    const MinDate = new Date(minDateValue * 1000)
    return (
        <UiFormControlSelection
            label={label}
            type="date"
            fieldName={fieldName}
            placeholder="MM/DD/YYYY"
            disableFutureDate={parentField === 'period'}
            minDate={MinDate}
            fastField={false}
        />
    )
}

const FilterForm = ({
    onSubmit,
    vehicles,
    locations,
    purposes,
    formikRef,
    queryObject,
    resetKey,
}: FilterFormProps) => {
    const classes = useStyles()

    const resetFieldValues = (fieldName: string) => {
        formikRef?.current?.setFieldValue(
            fieldName,
            defaultQueryObject?.[fieldName as keyof TripQueryObject],
        )
    }

    const boxMaxWidth = 400
    return (
        <div>
            <Formik
                initialValues={queryObject}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ values, setFieldValue }) => {
                    return (
                        <Form>
                            <Box mb={2}>
                                <FieldAutocomplete
                                    name="Vehicle"
                                    options={vehicles}
                                    selectedField="vehicle"
                                    values={values}
                                    formikRef={formikRef}
                                    key={resetKey}
                                />
                            </Box>
                            <Box mb={2}>
                                <FieldAutocomplete
                                    name="Location"
                                    options={locations}
                                    selectedField="location"
                                    values={values}
                                    formikRef={formikRef}
                                    key={resetKey}
                                />
                            </Box>
                            <Box mb={1} width={boxMaxWidth}>
                                <UiFormControlSelection
                                    showFloatingLabel={true}
                                    label="Activity"
                                    className={
                                        !values?.activity
                                            ? classes.defaultMenuItem
                                            : ''
                                    }
                                    defaultValue={'-1'}
                                    type="select"
                                    fieldName="activity"
                                    optionsData={activityOptions}
                                    showPlaceholderAsOption={false}
                                />
                            </Box>
                            <Box mb={2} width={boxMaxWidth}>
                                <FieldAutocomplete
                                    name="Purpose"
                                    options={purposes}
                                    selectedField="purpose"
                                    values={values}
                                    formikRef={formikRef}
                                    key={resetKey}
                                />
                            </Box>
                            <Box width={boxMaxWidth}>
                                <UiFormControlSelection
                                    type="select"
                                    showFloatingLabel={true}
                                    label="Date"
                                    optionsData={timeOptions}
                                    fieldName="period"
                                    onChange={(event: any) => {
                                        if (event?.target.value === 'Custom') {
                                            setFieldValue(
                                                'dateFrom',
                                                startOfDayTimeStamp,
                                            )
                                            setFieldValue(
                                                'dateTo',
                                                startOfDayTimeStamp,
                                            )
                                        } else {
                                            const { startDate, endDate } =
                                                getTimeParamsWithoutEST(
                                                    event?.target.value,
                                                    true,
                                                )
                                            setFieldValue('dateFrom', startDate)
                                            setFieldValue('dateTo', endDate)
                                        }
                                    }}
                                    showPlaceholderAsOption={false}
                                />
                                {values?.period === 'Custom' && (
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.labelStyle}
                                    >
                                        <Grid item md={6} xs={6}>
                                            <MinDateUI
                                                label="FromDate"
                                                parentField="period"
                                                fieldName="dateFrom"
                                                maxDateValue={values?.dateTo}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <MaxDateUI
                                                label="ToDate"
                                                parentField="period"
                                                fieldName="dateTo"
                                                minDateValue={values?.dateFrom}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                            <div>
                                <FieldHeader
                                    label="Distance"
                                    showResetButton={
                                        values.milesType !==
                                            defaultQueryObject.milesType ||
                                        !!defaultQueryObject.miles
                                    }
                                    resetCallout={() => {
                                        resetFieldValues('miles')
                                        resetFieldValues('milesType')
                                    }}
                                />
                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="select"
                                            optionsData={milesOptions}
                                            fieldName="milesType"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="number"
                                            placeholder="Miles"
                                            fieldName="miles"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <FieldHeader
                                    label="Reference Number"
                                    showResetButton={
                                        values.refNumberType !==
                                            defaultQueryObject.refNumberType ||
                                        !!defaultQueryObject.refNumber
                                    }
                                    resetCallout={() => {
                                        resetFieldValues('refNumber')
                                        resetFieldValues('refNumberType')
                                    }}
                                />
                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="select"
                                            optionsData={refOptions}
                                            fieldName="refNumberType"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="number"
                                            placeholder="Ref #"
                                            fieldName="refNumber"
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    formikRef?.current?.setFieldValue(
                                                        'refNumber',
                                                        parseInt(
                                                            event.target.value,
                                                        ),
                                                    )
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default FilterForm
