import React, { useEffect, useRef, useState } from 'react'
import { Container, InlineFlex } from '../Wrapper'
import { toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import { CollapsibleSection } from '../CollapsibleSection'
import { ContractorForm } from '../ContractorForm'
import {
    Button,
    List,
    ListItemText,
    Theme,
    makeStyles,
} from '@material-ui/core'
import ListElement from '../../common/ListElement'
import { ReactComponent as Checked } from '../../../assets/icons-svg/check-circle-xsmall.svg'
import { ReactComponent as Error } from '../../../assets/icons-svg/RedWarnig.svg'
import UiButton from '../../common/ui/UiButton'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import { Form, Formik } from 'formik'
import {
    discardImport,
    getContractorList,
    importComplete,
    removeContractor,
    updateForNeedReview,
} from '../apiServices/contractor.api'
import { showAlert } from '../../../store/actions/feedback'
import { useDispatch } from 'react-redux'
import {
    importInitialValues,
    useContractorContext,
} from '../ContractorProvider'
import {
    findErrosForEmptyFiled,
    handleImportMode,
} from '../utills/contractor.utils'
import { ERRORS, IMPORT_STEPS } from '../constants/contractor.const'
import { Contractor, Contractors } from '../models/contractor.model'
import Loader from '../../common/Loader'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { FormObserver } from '../../bookkeeping/reports/utils/Form/FormObserver'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        removeButton: {
            border: (colorTheme) => `1px solid ${colorTheme.red200}`,
            color: (colorTheme) => `${colorTheme.black100}`,
            marginRight: toRem16(24),
        },
        root: {
            width: '100%',
            maxWidth: 360,
        },
        count: {
            margin: '1rem 0',
        },
        container: {
            '& .MuiPaper-root': {
                maxWidth: `${toRem16(450)} !important`,
            },
        },
        secondaryText: {
            marginTop: '1.5rem',
        },
        scrollDiv: {
            width: '100%',
            overflowY: 'auto',
            maxHeight: toRem16(340),
        },
        gap: {
            marginRight: toRem16(24),
        },
        green:{
            ['& > path']:{
                fill: "#274F54"
            }
        }
    }
})

enum buttonAction {
    REMOVE = 'remove',
    DISCARD = 'discard',
}

interface Review {
    contractor: Contractor
    missingFields: any
}

const findNeedreviewContractor = (data: Contractors) => {
    const reviewRequired: Array<Review> = []
    const reviewNotRequired: Array<Review> = []
    const firstIndex: number = 0

    data.items.forEach((contractor: Contractor) => {
        const missingFields = findErrosForEmptyFiled(contractor)
        if (contractor?.need_review) {
            reviewRequired.push({
                contractor,
                missingFields,
            } as Review)
        } else {
            reviewNotRequired.push({ contractor, missingFields: {} } as Review)
        }
    })
    const firstContractor: Review = reviewRequired.length
        ? reviewRequired[firstIndex]
        : reviewNotRequired[firstIndex]
    return {
        reviewRequired,
        reviewNotRequired,
        firstContractor,
    }
}

export const validationSchema = Yup.object({
    first_name: Yup.string().required(ERRORS.FIRST_NAME),
    last_name: Yup.string().required(ERRORS.LAST_NAME),
    email: Yup.string()
        .email(ERRORS.VALID_EMAIL)
        .max(255)
        .required(ERRORS.EMAIL),
    phone: Yup.string().required(ERRORS.PHONE),
    type: Yup.string(),
    business_name: Yup.string().when('type', {
        is: 'business',
        then: Yup.string().required(ERRORS.BUSSINESS),
        otherwise: Yup.string().optional(),
      }),
    contractor_w_nine: Yup.array().of(
        Yup.object().shape({
          year: Yup.string()
            .length(4, ERRORS.TAX)
            .required(ERRORS.TAX),
        })
    ),
    tax_identification_type: Yup.string(),
    tax_identification_number: Yup.string(),
    street: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipcode: Yup.string(),
})

export const YerifyYourData: React.FC<any> = (): any => {
    const { currentBusinessId, importData, setImportData } =
        useContractorContext()
    const formRef = useRef(null)
    const history = useHistory()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const [selected, setSelected] = useState<Review>()
    const [openDialog, setOpenDialog] = useState<buttonAction | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [goodToGo, setGoodToGo] = useState<Review[]>()
    const [needReview, setNeedReview] = useState<Review[]>()
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
    const [formObserverValue, setFormObserverValue] = useState<any>({})
    const isSelectedContractorReviewed = selected?.contractor?.need_review === false;

    const dispatch = useDispatch()

    let submitData: any

    const fetchImportedContractors = () => {
        setIsLoading(true)
        getContractorList({ is_import_complete: false })
            .then((res: unknown) => {
                const { reviewRequired, reviewNotRequired, firstContractor } =
                    findNeedreviewContractor(res as Contractors)
                setSelected(firstContractor)
                setNeedReview(reviewRequired)
                setGoodToGo(reviewNotRequired)
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                const message = err?.message
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: message ?? 'Something went wrong',
                    })
                )
            })
    }

    useEffect(() => {
        fetchImportedContractors()
    }, [importData.completed_step])

    const handleListItemClick = (data: Review) => {
        setSelected(data)
    }

    const onformChangeCallback = (values: any) => {
        setFormObserverValue(values)
    }

    const handleRemoveAction = () => {
        let apiCallMethod
        if (openDialog === buttonAction.DISCARD) {
            apiCallMethod = discardImport
        } else {
            apiCallMethod = removeContractor
        }

        setIsModalLoading(true)
        apiCallMethod(
            openDialog === buttonAction.DISCARD
                ? importData.id.id
                : selected?.contractor?.id!
        )
            .then(() => {
                setOpenDialog(null)
                fetchImportedContractors()
                setIsModalLoading(false)
                openDialog === buttonAction.DISCARD &&
                    setImportData(importInitialValues)
            })
            .catch((err) => {
                const message = err?.message
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: message ?? 'Something went wrong',
                    })
                )
                setIsModalLoading(false)
            })
    }

    const handleNextContractor = (values: Contractor) => {
        const selectedIndex =
            needReview?.findIndex(
                (data: Review) =>
                    data.contractor.id === selected?.contractor?.id
            ) ?? 0
        if (Number(selectedIndex + 1) < Number(needReview?.length)) {
            const nextContractor =
                needReview?.[(selectedIndex + 1) % Number(needReview?.length)]
            nextContractor && setSelected(nextContractor)
        }

        if(isSelectedContractorReviewed) return;

        setIsLoading(true)
        updateForNeedReview(currentBusinessId!, {
            ...values,
            tax_identification_number:
                `${values?.tax_identification_number ?? ''}`.replaceAll('-', ''),
            need_review: false,
        })
            .then((data) => {
                setIsLoading(false)
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: 'Updated successfully',
                    })
                )
                fetchImportedContractors()
            })
            .catch((err) => {
                setIsLoading(false)
                let message = err.statusText
                if(err.errors?.phone){
                    message = err.errors?.phone
                }
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: message ?? 'Something went wrong',
                    })
                )
            })
    }

    const sanitizeAndValidate = (value:any, validLength:number) =>
    String(value).replaceAll(/[^a-z0-9]+/gi, '').length === validLength
        ? String(value).replaceAll(/[^a-z0-9]+/gi, '')
        : ''

    const getSanitizedValues = (values: Contractor) => {
        const phone = sanitizeAndValidate(values?.phone, 10)
        const tax_identification_number = sanitizeAndValidate(
            values?.tax_identification_number,
            9
        )
        const zip = sanitizeAndValidate(values?.zip, 5)

        return {
            ...values,
            phone,
            tax_identification_number,
            zip,
        }
    }

    selected&&console.log(getSanitizedValues(selected.contractor),selected.contractor)

    const handleImportComplete = () => {
        const contractorIds = goodToGo?.map(
            (data: Review) => data.contractor.id
        )
        setIsLoading(true)
        importComplete({ ids: contractorIds })
            .then(() => {
                setIsLoading(false)
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: `${contractorIds?.length} contractors were successfully imported.`,
                    })
                )
                setImportData(importInitialValues)
                history.push('/contractors/manage')
            })
            .catch((err) => {
                setIsLoading(false)
                const message = err?.message
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: message ?? 'Something went wrong',
                    })
                )
            })
    }

    const action = () => {
        return (
            <InlineFlex gap="1rem" justifycontent="flex-end">
                    <UiButton
                        handleClick={() => setOpenDialog(buttonAction.DISCARD)}
                        btnType="secondary"
                        label="Discard Contractors"
                    />
                <UiButton
                    btnType="tertiary"
                    handleClick={(e) =>
                        needReview?.length
                            ? submitData(e)
                            : handleImportComplete()
                    }
                    disabled={
                        (!goodToGo?.length && !needReview?.length) || isLoading
                    }
                    label={
                        needReview?.length
                            ?  isSelectedContractorReviewed ? 'Next Contractor' : 'Save Changes'
                            : `Save ${goodToGo?.length} Contractors`
                    }
                />
            </InlineFlex>
        )
    }
    const allFieldDisabled = !selected?.contractor.need_review
    return (
        <>
            <CollapsibleSection
                header="Verify Data"
                subHeader="If any optional field is left blank, you can send a W-9 to the contractor. This will allow the contractor to provide the remaining info. You can check the status of a W-9 at any time from the “Manage W-9s” section of the portal."
                mode={handleImportMode(
                    IMPORT_STEPS.FOUR,
                    importData?.completed_step
                )}
                preExpanded={importData?.completed_step === IMPORT_STEPS.THREE}
                stepNumber={IMPORT_STEPS.FOUR}
                footer={action()}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <UiText variant="suv_150">
                            {`${
                                Number(goodToGo?.length) +
                                Number(needReview?.length)
                            } Contractors Imported`}
                        </UiText>
                        <InlineFlex gap={toRem16(24)}>
                            <Container
                                maxheight={toRem16(360)}
                                overflow="auto"
                                width="50%"
                                margin="none"
                                padding="0 1rem 0 0"
                            >
                                {needReview && needReview?.length > 0 && (
                                    <div>
                                        <div className="contractor-review-count">
                                            <UiText
                                                variant="motorcycle_90"
                                                weight="bold_700"
                                                className={classes.count}
                                            >
                                                {`${needReview.length} contractors need review`}
                                            </UiText>
                                        </div>
                                        <div className={classes.root}>
                                            <List
                                                component="nav"
                                                aria-label="main mailbox folders"
                                            >
                                                {needReview?.map(
                                                    (data: Review) => {
                                                        const isSelected = selected?.contractor?.id === data.contractor.id;
                                                        return (
                                                            <ListElement
                                                                isItemSelected={isSelected}
                                                                handleItemClick={() => {
                                                                    handleListItemClick(
                                                                        data
                                                                    )
                                                                }}
                                                                primaryIcon={
                                                                        <Error className={isSelected ? classes.green : ''}/>
                                                                }
                                                                primaryNode={
                                                                    <ListItemText
                                                                        className="contractor-review-item"
                                                                        primary={
                                                                            data
                                                                                .contractor
                                                                                .first_name +
                                                                            ' ' +
                                                                            data
                                                                                .contractor
                                                                                .last_name
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        )
                                                    }
                                                )}
                                            </List>
                                        </div>
                                    </div>
                                )}
                                {!!goodToGo?.length && <div>
                                    <div className='contractor-verified-count'>
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="bold_700"
                                            className={classes.count}
                                        >
                                            {`${goodToGo?.length} contractors are good to go`}
                                        </UiText>
                                    </div>
                                    <div className={classes.root}>
                                        <List
                                            component="nav"
                                            aria-label="main mailbox folders"
                                        >
                                            {goodToGo?.map((data: Review) => {
                                                return (
                                                    <ListElement
                                                        key={data.contractor.id}
                                                        isItemSelected={
                                                            selected?.contractor
                                                                ?.id ===
                                                            data.contractor.id
                                                        }
                                                        handleItemClick={() => {
                                                            handleListItemClick(
                                                                data
                                                            )
                                                        }}
                                                        primaryIcon={<Checked />}
                                                        primaryNode={
                                                            <ListItemText
                                                                className="contractor-verified-item"
                                                                primary={
                                                                    data.contractor
                                                                        .first_name +
                                                                    ' ' +
                                                                    data.contractor
                                                                        .last_name
                                                                }
                                                            />
                                                        }
                                                    />
                                                )
                                            })}
                                        </List>
                                    </div>
                                </div>}
                            </Container>
                            <Container>
                                <InlineFlex
                                    gap={toRem16(24)}
                                    justifycontent="space-between"
                                >
                                    <UiText variant="suv_150">
                                        {selected?.contractor &&
                                            `${selected?.contractor.first_name} ${selected?.contractor?.last_name}`}
                                    </UiText>
                                    <Button
                                        className={classes.removeButton}
                                        onClick={() =>
                                            setOpenDialog(buttonAction.REMOVE)
                                        }
                                        disabled={!selected?.contractor.id}
                                    >
                                        <UiText weight="medium_500">
                                            Remove Contractor
                                        </UiText>
                                    </Button>
                                </InlineFlex>
                                {selected ? (
                                    <Container
                                        maxheight={toRem16(340)}
                                        minheight="auto"
                                        overflow="auto"
                                    >
                                        <Formik
                                            initialValues={{
                                                ...getSanitizedValues(selected.contractor),
                                            }}
                                            enableReinitialize={true}
                                            onSubmit={handleNextContractor}
                                            validateOnMount={true}
                                            validationSchema={validationSchema}
                                        >
                                            {({
                                                handleSubmit,
                                                setFieldValue,
                                                values: formValues,
                                                setTouched,
                                                errors,
                                            }) => {
                                                submitData = handleSubmit
                                                return (
                                                    <Form>
                                                        <FormObserver
                                                            onformChange={
                                                                onformChangeCallback
                                                            }
                                                        />
                                                        <ContractorForm
                                                            formikRef={formRef}
                                                            values={formValues}
                                                            setTouched={
                                                                setTouched
                                                            }
                                                            alreadyTouched={
                                                                selected.missingFields
                                                            }
                                                            showInlineError
                                                            formObserverValue={
                                                                formObserverValue
                                                            }
                                                            isEditContarctor={!!selected.contractor.email}
                                                            isAllFieldDisabled={allFieldDisabled}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    </Container>
                                ) : (
                                    ''
                                )}
                            </Container>
                        </InlineFlex>
                    </div>
                )}
            </CollapsibleSection>
            <UiConfirmationDialog
                open={Boolean(openDialog)}
                customRootClass={classes.container}
                alignButtons="center"
                message={
                    <div style={{ textAlign: 'center' }}>
                        <UiText weight="bold_700" variant="suv_150">
                            {openDialog === buttonAction.DISCARD
                                ? 'Discard Contractors?'
                                : 'Remove Contractor?'}
                        </UiText>
                        <div className={classes.secondaryText}>
                            <UiText>
                                {openDialog === buttonAction.DISCARD
                                    ? 'These contractors will not be imported. This cannot be undone.'
                                    : 'This contractor will not be included in the import.This cannot be undone.'}
                            </UiText>
                        </div>
                        {isModalLoading && <Loader />}
                    </div>
                }
                handleClose={() => {
                    setOpenDialog(null)
                }}
                confirmNode={
                    <UiButton
                        handleClick={handleRemoveAction}
                        btnType="tertiary"
                        label={
                            openDialog === buttonAction.DISCARD
                                ? 'Discard Contractors'
                                : 'Remove Contractor'
                        }
                    />
                }
                cancelButtonText="Keep Editing"
            />
        </>
    )
}
