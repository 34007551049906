export interface OnboardingStepInfo {
    current_step: string;
    onboarding_steps_status: OnboardingStepsStatus;
    adp_company_created: boolean;
    is_adp: boolean;
    adp_onboarding_status: string
}

export interface OnboardingStepsStatus {
    company_combined_data_collection: boolean;
    payroll_frequency_addition: boolean;
    bank_account_addition: boolean;
    first_employee: boolean;
    tos_acceptance: boolean;
    company_created?: boolean
}

export interface ADPCompanyStepInfo {
    loadingStepInfo: boolean;
    loadedStepInfo: boolean;
    companyOnboardingStepInfo: OnboardingStepInfo;
}


export interface CompanyInfo {
  business_name: string;
  doing_business_as: string;
  ein: string;
  business_type: string;
  federal_filing_frequency: string;
  deposit_frequency: string;
  industry: string;
  street: string;
  street_2?: string | undefined;
  city: string;
  state: string;
  zip_code: string;
  country: string;
}

export interface JobLocation {
  street_1: string;
  street_2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface FirstEmployee {
  first_name: string;
  middle_initial: string;
  last_name: string;
  email: string;
  phone: string;
  gender: string;
  dob: number; // Assuming it's a Unix timestamp
  ssn: string;
  job_type: string;
  hire_date: number; // Assuming it's a Unix timestamp
  job_title: string;
  job_location: JobLocation;
  amount: number;
  frequency: string;
  is_employee_owner: boolean;
  is_primary_owner: boolean;
  ownership_percentage: number;
  is_owner_an_employee: boolean;
}

export interface PaySchedule {
  frequency: string;
  first_payment_date?: number | string; // Consider using Date type if possible
  first_work_period_start_date?: number | string; // Consider using Date type if possible
  first_work_period_end_date?: number | string; // Consider using Date type if possible
}

export interface TosAgreement {
  legalConfirmation: boolean;
  signature: string;
}

export interface BankDetails {
  routing_number: string;
  account_number: string;
  account_type: string;
}

export interface ADPCompanyDetails {
  companyDetails: CompanyInfo;
  firstEmployee: FirstEmployee;
  paySchedule: PaySchedule;
  tosAgreement: TosAgreement;
  bankDetails: BankDetails;
}


export interface Company {
    name: string;
    trade_name: string;
    ein: string;
    first_name: string;
    last_name: string;
    email: string;
    pgone: string;
}

export interface CompanyAddress {
    street: string;
    street_line_2: string;
    city: string;
    state: string;
    zip_code: string;
    id: string;
    version: string;
    phone_number: string;
    is_mailing_address: boolean;
    is_filing_address: boolean;
}

export interface PayrollFrequency {
    id?: string;
    frequency: string;
    anchor_pay_date: string;
    anchor_end_of_pay_period: string;
    day_1?: number | null;
    day_2?: number | null;
    version?: string;
    selected_payday?: string;
}
export interface IndustryClassification {
  code: string;
  shortName: string;
  scheme: {
    code: string;
    shortName: string;
  };
}

interface MonetaryAccountClassification {
  code: string;
  shortName: string;
  scheme: {
    code: string;
    shortName: string;
  };
}

export interface MonetaryAccount {
  routingNumberProtectedToken: {
    plainText: string;
    maskedText: string;
  };
  accountNumberProtectedToken: {
    plainText: string;
    maskedText: string;
  };
  bankName: string;
  monetaryAccountClassification: MonetaryAccountClassification;
  monetaryAccountID: string;
}

export interface GovernmentRegistration {
  filingFrequencyClassification?: {
    code: string;
    shortName: string;
    scheme: {
      code: string;
      shortName: string;
    };
  };
  depositFrequencyClassification?: {
    code: string;
    shortName: string;
    scheme: {
      code: string;
      shortName: string;
    };
  };
}

export interface Location {
  locationID: string;
  legalAddress: {
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    stateCode: string;
    countryCode: string;
    primaryLocationIndicator: boolean;
  };
  headQuarterIndicator: boolean;
}

export interface PayrollSchedule {
  payrollScheduleName: string;
  firstPayrollProcessingPeriod: {
    periodStartDate: string;
    periodEndDate: string;
    paymentDate: string;
    scheduleFrequencyClassification: {
      code: string;
      shortName: string;
      scheme: {
        code: string;
        shortName: string;
      };
    };
  };
}

export interface WorkAssignment {
  startDate: string;
  locations: Location[];
  agreementClassification: {
    code: string;
    shortName: string;
    scheme: {
      code: string;
      shortName: string;
    };
  };
  compensations: {
    earnings: {
      compensationAmount: number;
    }[];
  };
  employmentTypeCode: string;
}

export interface Associate {
  associateID: string;
  legalName: {
    firstName: string;
    lastName: string;
  };
  associateLegalAddress: {
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    stateCode: string;
    countryCode: string;
  };
  associateTypeClassification: {
    code: string;
    shortName: string;
    scheme: {
      code: string;
      shortName: string;
    };
  };
  associateStatusClassification: {
    code: string;
    shortName: string;
    scheme: {
      code: string;
      shortName: string;
    };
  };
  associateMailingAddress?: any; // Adjust this type if mailing address is provided
  personalPhoneFormattedNumber: string;
  personalEmailAddress: string;
  genderClassification: {
    code: string;
    shortName: string;
    scheme: {
      code: string;
      shortName: string;
    };
  };
  workAssignments: {
    workAssignments: WorkAssignment[];
  };
  governmentRegistrations: {
    governmentRegistrations: GovernmentRegistration[];
  };
}

export interface ADPCompanyDetails {
  clientName: string;
  doingBusinessAsName: string;
  federalEmployerID: string;
  legalFormCode: string;
  industryClassificationCode: string;
  industryClassification: IndustryClassification;
  monetaryAccount: MonetaryAccount;
  governmentRegistrations: {
    governmentRegistrations: GovernmentRegistration[];
  };
  locations: {
    locations: Location[];
  };
  payrollProcessingPeriods: {
    payrollProcessingPeriods: {
      payrollSchedule: PayrollSchedule;
      scheduleFrequencyClassification: {
        code: string;
        shortName: string;
        scheme: {
          code: string;
          shortName: string;
        };
      };
      onDemandPayrollScheduleIndicator?: boolean;
      payrollPeriod: string;
      periodStartDate: string;
      periodEndDate: string;
      paymentDate: string;
    }[];
  };
  associates: {
    associates: Associate[];
  };
}

export enum SETUP_TYPE {
    OLD ='old',
    NEW = 'new'
}