import { useEffect } from 'react'
import Lottie from 'react-lottie'
import { AccountBalanceOutlined } from '@material-ui/icons'
import {
    Button,
    Hidden,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import Icon from '../../common/Icon'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'
import { PAGE_TYPE } from '../constants/adp-payroll.const'
import Monocle from '../../../assets/animations/Monocle.json'
import UiSnackbarAlert from '../../common/ui/UiSnackbarAlert'
import { useADPBankingState } from '../provider/BankingInfoProvider'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { useADPCompanyContext } from '../provider/CompanyProvider'
import NextActionButton from '../../payroll/common/NextActionButton'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { toRem16 } from '../../../styles/commonStyles'
import { useStyles } from './CompanyCommonStyles'
import { markStepAsCompleted } from '../../../services/apiService/adp-payroll/company'

const useBankInfoStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    iconContent: {
        display: 'flex',
        marginTop: theme.spacing(4),
        marginBottom: '1rem',
        color: (colorTheme) => colorTheme.grey400,
    },
    secureText: {
        marginLeft: '0.5rem',
    },
    scrollHeight: {
        maxHeight: '30vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    sectionHeader: {
        marginTop: '1rem',
        marginBottom: '2.5rem',
        '& p:nth-child(2)': {
            color: (colorTheme) => colorTheme.black100,
        },
    },
    buttonContainer: {
        marginBottom: theme.spacing(4),
        '& div': {
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            color: (colorTheme) => colorTheme.grey400,
        },
    },
    bankBtn: {
        height: toRem16(42),
        background: (colorTheme) => colorTheme.primary,
        padding: '0.563rem 1rem',
        color: (colorTheme) => colorTheme.primaryWhite,
        '&:hover': {
            background: (colorTheme) => colorTheme.primary,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
        '&:disabled': {
            opacity: 0.25,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
    },
    bankInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '22.5rem',
    },
    bankAccountContainer: {
        display: 'flex',
        alignItems: 'center',
        color: (colorTheme) => colorTheme.grey400,
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        borderRadius: '0.5rem',
        padding: '0 1.5rem 0 1rem',
        width: '100%',
        boxSizing: 'border-box',
        '& .bankName': {
            color: (colorTheme) => colorTheme.black100,
        },
    },
    bankAccount: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '14px 0 8px 0',

        '& div:first-child': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#12172414',
            borderRadius: '50%',
        },
        '& div:nth-child(2)': {
            marginLeft: '1rem',
        },
    },
    iconContainer: {
        borderRadius: '3.125rem',
        borderColor: (colorTheme) => colorTheme.grey200,
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        alignItems: 'center',
    },
    changeBankBtn: {
        padding: '0.5rem',
        color: (colorTheme) => colorTheme.blue200,
        marginTop: '1.5rem',
        marginBottom: '1rem',
    },
    bankVerificationSection: {
        '& div:nth-child(1)': {
            margin: '1.5rem 0!important',
        },
        '& .depositText': {
            marginBottom: '1.5rem',
        },
    },
    mainContainer: {
        position: 'relative',
        '& .loaderIcon': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: (colorTheme) => colorTheme.primaryWhite,
            zIndex: 100,
        },
    },
    innerContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '2rem',
        },
    }
}))

const BankingSectionHeader = ({
    title,
    subtitle,
}: {
    title: string
    subtitle: string
}) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useBankInfoStyles(colorTheme)
    const { isSmDevice } = useDeviceSize()

    return (
        <div className={classes.sectionHeader}>
            {!isSmDevice && (
                <UiText variant="suv_150" weight="semi_bold_600">
                    {title}
                </UiText>
            )}
            <UiText variant="car_100" weight="regular_400">
                <span
                    dangerouslySetInnerHTML={{
                        __html: subtitle,
                    }}
                ></span>
            </UiText>
        </div>
    )
}

const ChangeBankAccount = () => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useBankInfoStyles(colorTheme)

    const { linkToken, triggerBankAccountChange } = useADPBankingState()

    return (
        <div>
            {linkToken && (
                <Button
                    variant="text"
                    className={classes.changeBankBtn}
                    onClick={() => triggerBankAccountChange()}
                >
                    Change Bank Account
                </Button>
            )}
        </div>
    )
}

const BankConnectInitialSection = () => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useBankInfoStyles(colorTheme)

    const { openPlaidLink, disableBankConnectButton, isProcessorToken, updateProcessorTokenWithHandling } = useADPBankingState()
    return (
        <div className="">
            <BankingSectionHeader
                title={'Bank Information'}
                subtitle={
                    'Connect the bank account you want to pay your employees from.'
                }
            />

            <div className={classes.buttonContainer}>
                {isProcessorToken ? (
                    <UiButton
                        btnType="tertiary"
                        handleClick={() => updateProcessorTokenWithHandling()}
                        label="Reconnect Bank Account"
                    />
                ) : (
                    <UiButton
                        btnType="tertiary"
                        handleClick={() => openPlaidLink()}
                        disabled={disableBankConnectButton}
                        label="Connect Via Plaid"
                    />
                )}

                <div>
                    <Icon
                        size={'15px'}
                        icon="lock"
                        strokeColor={colorTheme.secondary}
                    ></Icon>
                    <Typography
                        style={{ marginLeft: '0.4rem' }}
                        variant="body2"
                    >
                        Your data is kept secure and private.
                    </Typography>
                </div>
            </div>
        </div>
    )
}

const BankVerificationSection = () => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useBankInfoStyles(colorTheme)
    const {
        bankInfo,
        openUpdateMode,
        updateTokenReady,
        disableBankVerifyButton,
        fetchUpdateModeToken,
    } = useADPBankingState()

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Monocle,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    useEffect(() => {
        fetchUpdateModeToken()
    }, [])

    return (
        <div className={classes.bankVerificationSection}>
            <div>
                <Lottie options={defaultOptions} height={80} width={80} />
            </div>
            <BankingSectionHeader
                title={'Enter the 3-letter code'}
                subtitle={`Look for a <b>$0.01 deposit</b> in your account ending in ••••${bankInfo?.mask}. Your code is the first 3 letters after the # symbol.`}
            />
            <div>
                <Button
                    onClick={() => openUpdateMode()}
                    className={classes.bankBtn}
                    disabled={!updateTokenReady || disableBankVerifyButton}
                >
                    <UiText weight="semi_bold_600">Verify Bank Account</UiText>
                </Button>
            </div>
            <ChangeBankAccount />
        </div>
    )
}

const BankConnectedSection = () => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useBankInfoStyles(colorTheme)
    const { bankInfo } = useADPBankingState()

    return (
        <div className="">
            <BankingSectionHeader
                title={'Bank Information'}
                subtitle={
                    'Connect the bank account you want to pay your employees from.'
                }
            />

            <div className={classes.bankInfoContainer}>
                <div className={classes.bankAccountContainer}>
                    <div className={classes.bankAccount}>
                        <div>
                            <div className={classes.iconContainer}>
                                <AccountBalanceOutlined />
                            </div>
                        </div>
                        <div>
                            {bankInfo?.bank_name && (
                                <UiText
                                    className="bankName"
                                    variant="motorcycle_90"
                                >
                                    {bankInfo?.bank_name}
                                </UiText>
                            )}
                            <UiText variant="motorcycle_90">
                                Account: {bankInfo?.account_number}
                            </UiText>
                            <UiText variant="motorcycle_90">
                                Routing: {bankInfo?.routing_number}
                            </UiText>
                            <UiText variant="motorcycle_90">
                                {bankInfo?.account_type}
                            </UiText>
                        </div>
                    </div>
                </div>
            </div>
            <ChangeBankAccount />
        </div>
    )
}

const BankInformation = ({
    refreshSteps,
    goBack,
}: {
    refreshSteps: () => void
    goBack: () => void
}) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useBankInfoStyles(colorTheme)
    const styles = useStyles(colorTheme)
    const { page, loadToken, loadBankInfo, bankInfo, isError, setIsError, errorMsg } =
        useADPBankingState()
    const { isCompanyOnboarding, isCompanyActive, companyStepInfo } = useADPCompanyContext()
    const disableCompanyEdit = isCompanyOnboarding || isCompanyActive

    const handleNextStep = async () => {
        if (
            (bankInfo?.verification_status === "automatically_verified" || 
            bankInfo?.verification_status === "manually_verified") && 
            bankInfo?.processor_token_linked && 
            companyStepInfo?.onboarding_steps_status['bank_account_addition'] === false
        ) {
            await markStepAsCompleted({ step: 'bank_account_addition' });
        }
        refreshSteps();
    }

    const componentMapping = {
        [PAGE_TYPE.INITIAL_SECTION]: <BankConnectInitialSection />,
        [PAGE_TYPE.COMPLETED_SECTION]: <BankConnectedSection />,
        [PAGE_TYPE.VERIFICATION_PENDING_SECTION]: <BankVerificationSection />,
        default: <></>,
    }

    return (
        <div className={classes.mainContainer}>
            {(loadToken || loadBankInfo) && (
                <div className="loaderIcon">
                    <Loader />
                </div>
            )}
            <div className={classes.innerContainer}>
                {componentMapping[page] || componentMapping.default}
                <Hidden smDown>
                    <div>
                        {!disableCompanyEdit && (
                            <NextActionButton
                                loading={false}
                                submitAction={() => {
                                    handleNextStep()
                                }}
                                arrowIcon={true}
                            />
                        )}
                    </div>
                </Hidden>
                <Hidden mdUp>
                    {!disableCompanyEdit && (
                        <div className={styles.nextPrevContainer}>
                            <NextActionButton
                                customClass={styles.nxtBtn}
                                loading={false}
                                submitAction={() => {
                                    handleNextStep()
                                }}
                                arrowIcon={true}
                            />
                            <UiButton
                                btnType="hyperlink"
                                label="Previous"
                                fullWidth
                                customClass={styles.nxtBtn}
                                handleClick={goBack}
                            />
                        </div>
                    )}
                </Hidden>
            </div>

            <UiSnackbarAlert
                open={isError}
                message={errorMsg}
                handleClose={() => setIsError(false)}
                actionButtonColor={'primary'}
                severity="error"
            />
        </div>
    )
}

export default BankInformation
