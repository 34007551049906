import axios, { CancelToken } from 'axios'
import {
    deleteResource,
    getResource,
    patchResource,
    postResource,
    requestHandler,
} from '../../../services/axiosService'
import store from '../../../store'
import { API, CONTRACTOR_TOKEN } from '../constants/contractor.const'
import { Contractor, ImportContractor } from '../models/contractor.model'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id
    return {
        businessId,
    }
}

const getContractorToken = (): string => {
    return `${sessionStorage.getItem(CONTRACTOR_TOKEN)}`
}

export function getContractorInfo(contractorId: string) {
    const { businessId } = getStoreData()
    const URL = `${API.CONTRACTOR.replace('{id}', businessId)}/${contractorId}`
    return getResource<Contractor>(URL)
}

export function addContractor(businessId: string, param: any) {
    return postResource(API.CONTRACTOR.replace('{id}', businessId), param)
}

export function editContractor(businessId: string, param: any) {
    return patchResource(API.CONTRACTOR.replace('{id}', businessId), param)
}

export function updateForNeedReview(businessId: string, param: any) {
    return patchResource(API.NEED_REVIEW.replace('{id}', businessId), param)
}

export function removeContractor(contractorId: string) {
    const { businessId } = getStoreData()
    const url = `${API.CONTRACTOR.replace('{id}', businessId)}/${contractorId}`
    return deleteResource(url)
}

export function removeMultipleContractor(ids: any) {
    const { businessId } = getStoreData()
    const url = `${API.CONTRACTOR.replace('{id}', businessId)}?ids=${ids}`
    return deleteResource(url)
}

export function getContractorList(filters?: any) {
    const { businessId } = getStoreData()
    const url = `${API.CONTRACTORS_LIST.replace('{id}', businessId)}`
    const params = filters || {}
    return getResource(url, undefined, params)
}

export function contractorImportData(businessId: string) {
    const url = `${API.IMPORTED_DATA.replace('{id}', businessId)}`
    return getResource<ImportContractor>(url)
}

export function downloadTemplate(businessId: string) {
    return `${
        window.location.origin
    }/cbapi/app.php/${API.DOWNLOAD_TEMPLATE.replace('{id}', businessId)}`
}

export function uploadContractorFile(businessId: string, file: File[]) {
    const url = `${API.UPLOAD_FILE.replace('{id}', businessId)}`
    const formData = new FormData()
    formData.append('file', file[0])
    return postResource(url, formData)
}

export function importContractor(businessId: string, param: any) {
    return postResource(
        API.IMPORT_CONTRACTOR.replace('{id}', businessId),
        param
    )
}

export function importComplete(param: any) {
    const { businessId } = getStoreData()
    return patchResource(API.IMPORT_COMPLETE.replace('{id}', businessId), param)
}

export function discardImport(importId: string) {
    const { businessId } = getStoreData()
    return deleteResource(
        `${API.DISCARD_IMPORT}/${importId}`.replace('{id}', businessId)
    )
}

export function sendW9Email(year: string, contractorId: string) {
    const { businessId } = getStoreData()
    return postResource(
        `${API.SEND_W9_EMAIL}`
            .replace('{id}', businessId)
            .replace('{contractor_id}', contractorId),
        { year }
    )
}

export function addContractorIntoYear(year: string, ids: Array<string>) {
    const { businessId } = getStoreData()
    return postResource(
        `${API.ADD_CONTRACTOR_INTO_YEAR}`
            .replace('{id}', businessId)
            .replace('{year}', year),
        { ids }
    )
}

export function file1099(year: string, ids: Array<string>) {
    const { businessId } = getStoreData()
    return postResource(
        `${API.FILE_1099}`.replace('{id}', businessId).replace('{year}', year),
        { ids }
    )
}

const makeHeaders = () => {
    return {
        Authorization: `Bearer ${getContractorToken()}`,
        'Content-Type': 'application/json',
    }
}

export const customAxiosRequest = <T>(
    method: string,
    subUrl: any,
    body?: any,
    cancelToken?: CancelToken,
    params?: any,
    headers?: any
) => {
    return requestHandler<T>(
        method,
        subUrl,
        body ? body : undefined,
        cancelToken,
        params,
        headers
    )
}

export function getLimitedAccessData() {
    return customAxiosRequest(
        'get',
        API.LIMITED_ACCESS,
        undefined,
        undefined,
        undefined,
        makeHeaders()
    )
}

export function updateLimitedAccesData(data: Contractor) {
    return customAxiosRequest(
        'patch',
        API.LIMITED_ACCESS,
        data,
        undefined,
        undefined,
        makeHeaders()
    )
}

export function getViewPdfLink(contractorId: string, year: string) {
    const { businessId } = getStoreData()
    const url = API.GET_VIEW_PDF_LINK.replace('{id}', businessId)
        .replace('{contractorId}', contractorId)
        .replace('{year}', year)
    return getResource(url, undefined, undefined, false, 'text')
}

export function getPdf(url: string) {
    return axios.get(url, {
        responseType: 'blob',
    })
}

export function updatePaidAmount(param: any, w9Id: string) {
    const { businessId } = getStoreData()
    return patchResource(API.UPDATE_PAID_AMOUNT.replace('{id}', businessId).replace('{w9_id}', w9Id), param)
}
