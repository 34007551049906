import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    useMemo,
} from 'react'
import * as Yup from 'yup'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import UiText from '../../common/ui/UiText'
import FormHeaderText from './FormHeaderText'
import { CalendarTodayOutlined, WarningTwoTone } from '@material-ui/icons'
import NextActionButton from './NextActionButton'
import { AppData, ApplicationStore, CompanyStepInfo } from '../../../models'
import { connect } from 'react-redux'
import {
    createPayrollFrequency,
    getPayrollFrequency,
} from '../../../services/apiService'
import { PayrollFrequency } from '../../../models/payroll/company'
import Loader from '../../common/Loader'
import { formSubmitErrorHandler } from '../../../services/formService'
import moment from 'moment'
import UiAlert from '../../../components/common/ui/UiAlert'
import { loadPostAuthData } from '../../../store/actions/appData'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

interface PayrollFrequencyFormProps {
    appData: AppData
    companyStepInfo: CompanyStepInfo
    refreshSteps: () => void
    parentLoading: (loading: boolean) => void
    loadPostAuthData: () => Promise<any>
}

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        disableSection: {
            color: (colorTheme) => colorTheme.grey1900,
            cursor: 'default',
            pointerEvents: 'none',
            opacity: '0.5',
        },
        muiPaper: {
            '& .MuiMenu-Paper': {
                maxHeight: '100px',
            },
        },
    }
})

const yesterday = moment().subtract(1, 'days')
const afterOneWeek = moment().add(7, 'days')
const allowedDatesTillSevenDays = Array(7)
    .fill(yesterday)
    .map(() => yesterday.add(1, 'd').format('MM-DD-YYYY'))

const formatDate = (value: any, format: string = 'MM/DD/YYYY') =>
    value.format(format)
const timeStamp = (date: string) => moment(moment(date).unix() * 1000)

// returns the upcoming dates based on the selected day of a month
const upcomingDates = (selectedDay: number) => {
    const currentDate =
        moment().format('MM') + `/${selectedDay}/` + moment().format('YYYY')

    let currentMonth, nextMonth, thirdMonth, fourthMonth
    if (parseInt(afterOneWeek.format('MM')) > parseInt(moment().format('MM'))) {
        currentMonth = timeStamp(currentDate).add(2, 'M')
        nextMonth = timeStamp(currentDate).add(2, 'M')
        thirdMonth = timeStamp(currentDate).add(3, 'M')
        fourthMonth = timeStamp(currentDate).add(4, 'M')
    } else {
        currentMonth = timeStamp(currentDate)
        nextMonth = timeStamp(currentDate).add(1, 'M')
        thirdMonth = timeStamp(currentDate).add(2, 'M')
        fourthMonth = timeStamp(currentDate).add(3, 'M')
    }
    return [currentMonth, nextMonth, thirdMonth, fourthMonth]
}
// returns array that is used in schedule start date select option
const scheduleDateOptions = (dates: any) => {
    const options = dates.map((date: any) => ({
        label: formatDate(date, 'll'),
        value: formatDate(date),
    }))
    return options
}

const alternateText = (infoText: string) => (
    <UiText
        style={{ marginTop: '5px' }}
        variant="moped_75"
        textColor="textSecondary"
    >
        {' '}
        {infoText}
    </UiText>
)

const renderPayrollValues = (label: string, value: any, infoText?: string) => (
    <Box my={2} mt={4}>
        <UiText textColor="textSecondary">{label}</UiText>
        <UiText>{value || 'NA'}</UiText>
        {infoText && alternateText(infoText)}
    </Box>
)

const PreSaveWarning = () => {
    const { colorTheme } = useThemeContext()
    return (
        <Box
            my={2}
            py={'14px'}
            px={'16px'}
            bgcolor={colorTheme.red200}
            borderRadius={'4px'}
        >
            <Box display="flex">
                <Box mr={1} color={colorTheme.red200}>
                    <WarningTwoTone />
                </Box>
                <Box>
                    <UiText>
                        Payroll frequency details can’t be edited after
                        proceeding to the next step.
                    </UiText>
                </Box>
            </Box>
        </Box>
    )
}

const resetFormData = ({
    setFieldValue,
}: Pick<FormikHelpers<any>, 'setFieldValue'>) => {
    setFieldValue('day_1', null)
    setFieldValue('day_2', null)
    setFieldValue('anchor_pay_date', '')
    setFieldValue('anchor_end_of_pay_period', '')
    setFieldValue('selected_payday', '')
}

const PostSaveWarning = () => {
    const { colorTheme } = useThemeContext()
    return (
        <Box my={2}>
            <UiAlert
                icon="info"
                severity="info"
                customContent={
                    <UiText>
                        If you need to change to your Payroll Frequency, contact
                        your Payroll Specialist:{' '}
                        <b
                            style={{
                                color: colorTheme.blue200, // why inline style? - remove this
                            }}
                        >
                            1-(800) 222-6868
                        </b>
                    </UiText>
                }
            />
        </Box>
    )
}

const PayrollFrequencyForm = forwardRef(
    (
        {
            appData,
            companyStepInfo,
            refreshSteps,
            parentLoading,
            loadPostAuthData,
        }: PayrollFrequencyFormProps,
        ref
    ) => {
        const [initialValues, setInitialValues] = useState<any>({
            frequency: 'Monthly',
            anchor_pay_date: '',
            anchor_end_of_pay_period: null,
            selected_payday: '',
            day_1: null,
            day_2: null,
        })

        const [submittingForm, setSubmittingForm] = useState(false)
        const payrollFrequencyCreated =
            companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status
                .payroll_frequency_addition
        const [loading, setLoading] = useState(payrollFrequencyCreated)
        const formRef = useRef<any>()
        const [skipNext, setSkipNext] = useState(false)
        const { colorTheme } = useThemeContext()
        const classes = useStyles(colorTheme)
        const [payStartDate, setPayStartDate] = useState('')

        const availableDates = useMemo(() => {
            let val = 0
            const dates = Array(moment().daysInMonth())
                .fill(0)
                .map((e) => (val = val + 1))
            return dates
        }, [])

        useEffect(() => {
            if (payrollFrequencyCreated) {
                setLoading(true)
                getPayrollFrequency(appData.current_business_id)
                    .then((data) => {
                        setInitialValues(data)
                        setLoading(false)
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            }
        }, [appData.current_business_id, payrollFrequencyCreated])

        useEffect(() => {
            parentLoading(submittingForm)
            return () => {
                parentLoading(false)
            }
        }, [submittingForm])

        useImperativeHandle(
            ref,
            () => ({
                isFromDirty() {
                    return formRef.current?.dirty
                },

                submit() {
                    if (formRef.current.isValid) {
                        setSkipNext(true)
                    }

                    return formRef.current.submitForm
                },
            }),
            [formRef]
        )

        useEffect(() => {
            let anchorEndPayPeriod = formatDate(
                timeStamp(payStartDate).subtract(7, 'days')
            )

            formRef?.current?.setFieldValue(
                'anchor_end_of_pay_period',
                anchorEndPayPeriod
            )
        }, [payStartDate])

        const onSubmit = (
            data: PayrollFrequency,
            formHelpers: FormikHelpers<any>
        ) => {
            setSubmittingForm(true)
            if (payrollFrequencyCreated) {
                refreshSteps()
            } else {
                formSubmitErrorHandler(
                    createPayrollFrequency(appData.current_business_id, {
                        ...data,
                        anchor_pay_date: moment(data.anchor_pay_date).format(
                            'MM/DD/YYYY'
                        ),
                        anchor_end_of_pay_period: moment(
                            data.anchor_end_of_pay_period
                        ).format('MM/DD/YYYY'),
                    }).then((val) => {
                        if (skipNext) {
                            setSkipNext(false)
                            formHelpers.resetForm(val)
                        } else {
                            loadPostAuthData().then(() => {
                                refreshSteps()
                            })
                        }
                        setSubmittingForm(false)
                    }),
                    () => {
                        setSubmittingForm(false)
                        setSkipNext(false)
                    },
                    formHelpers.setFieldError
                )
            }
        }
        const validationSchema = Yup.object({
            frequency: Yup.string().required('Frequency is required'),
            anchor_pay_date: Yup.string().required(
                'First Period Start Date is required'
            ),
            anchor_end_of_pay_period: Yup.string().required(
                'First Period End Date is required'
            ),
            day_1: Yup.number().when('frequency', {
                is: (frequency: string, test: any) =>
                    ['Monthly', 'Twice per month'].includes(frequency),
                then: Yup.number().nullable(),
                otherwise: Yup.number().nullable(),
            }),
            selected_payday: Yup.string(),
        }).test('selectedPayDayError', (value, { createError }) => {
            let { frequency, day_1, selected_payday } = value
            let isMonthlyPayDayisEmpty = !day_1
            const isPayDayisEmpty = !selected_payday
            return frequency === 'Twice per month' && isPayDayisEmpty
                ? createError({
                      message: 'Payday is Required',
                      path: 'selected_payday',
                  })
                : isMonthlyPayDayisEmpty && frequency === 'Monthly'
                ? createError({
                      message: 'Monthly payday is Required',
                      path: 'day_1',
                  })
                : true
        })

        const payrollData = (
            <>
                {renderPayrollValues(
                    'Payroll Frequency (Required)',
                    initialValues.frequency
                )}
                {initialValues.frequency === 'Monthly' &&
                    renderPayrollValues('Monthly Payday', initialValues.day_1)}

                {initialValues.frequency === 'Twice per month' &&
                    renderPayrollValues(
                        'Paydays',
                        initialValues.day_1 === 1
                            ? '1st and 15th days of the month.'
                            : '15th and last day of the month.'
                    )}

                {renderPayrollValues(
                    'Schedule Start Date (Required)',
                    initialValues.anchor_pay_date,
                    'This will be the first day your employees are paid on this schedule.'
                )}

                {initialValues?.anchor_end_of_pay_period &&
                    renderPayrollValues(
                        'First Period End Date',
                        initialValues.anchor_end_of_pay_period,
                        'The First Period End Date is always one week before the Schedule Start Date.'
                    )}
            </>
        )

        const shouldDisableDatesInPeriod = (
            date: any,
            type: string | undefined
        ) => {
            const parsedDate = parseInt(date.format('D'))
            const formatedDate = moment(date.format('MM-DD-YYYY'))
            const lastDay = moment(`${date.format('MM')}`).daysInMonth()
            const firstPeriodCheck = [1, 15].includes(parsedDate)
            const secondPeriodCheck = [15, lastDay].includes(parsedDate)

            if (type === 'firstAndFifth') {
                return !(afterOneWeek < formatedDate && firstPeriodCheck)
            } else {
                return !(afterOneWeek < formatedDate && secondPeriodCheck)
            }
        }

        return (
            <Grid
                style={{
                    height: 'calc(100% - 132px)',
                    overflowX: 'hidden',
                    overflowY:
                        loading || companyStepInfo.loadingStepInfo
                            ? 'hidden'
                            : 'auto',
                }}
            >
                <FormHeaderText heading="Payroll Schedule" />
                <UiText>
                    This determines how often your employees are paid.
                </UiText>
                {loading || companyStepInfo.loadingStepInfo ? (
                    <Loader />
                ) : (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                        innerRef={formRef}
                    >
                        {({ values, submitForm, setFieldValue, errors }) => {
                            setPayStartDate(values?.anchor_pay_date)
                            const isMonthly = values?.frequency === 'Monthly'
                            const twicePerMonth =
                                values?.frequency === 'Twice per month'

                            let potentialDates: any = []
                            if (values?.day_1) {
                                let currentDate = parseInt(
                                    moment().format('DD')
                                )
                                const [
                                    currentMonth,
                                    nextMonth,
                                    thirdMonth,
                                    fourthMonth,
                                ] = upcomingDates(values?.day_1)

                                potentialDates =
                                    currentDate + 7 < values?.day_1
                                        ? scheduleDateOptions([
                                              currentMonth,
                                              nextMonth,
                                              thirdMonth,
                                          ])
                                        : scheduleDateOptions([
                                              nextMonth,
                                              thirdMonth,
                                              fourthMonth,
                                          ])
                            }

                            return (
                                <Form>
                                    {payrollFrequencyCreated ? (
                                        <Box>
                                            {payrollData}
                                            {PostSaveWarning}
                                            <Box mt={4}>
                                                <NextActionButton
                                                    loading={submittingForm}
                                                    submitAction={refreshSteps}
                                                />
                                            </Box>
                                        </Box>
                                    ) : (
                                        <>
                                            <Grid md={7}>
                                                <Box my={2} mt={4}>
                                                    <UiFormControlSelection
                                                        type="select"
                                                        label={
                                                            'Frequency (Required)'
                                                        }
                                                        showFloatingLabel={true}
                                                        fieldName="frequency"
                                                        optionsData={[
                                                            'Every week',
                                                            'Every other week',
                                                            'Twice per month',
                                                            'Monthly',
                                                        ]}
                                                        onChange={(e) => {
                                                            resetFormData({
                                                                setFieldValue,
                                                            })
                                                        }}
                                                    />
                                                </Box>
                                                {/* { Monthly Payday Selection} */}
                                                {isMonthly && (
                                                    <UiFormControlSelection
                                                        type="select"
                                                        label={
                                                            'Monthly Payday (Required)'
                                                        }
                                                        showFloatingLabel={true}
                                                        fieldName="day_1"
                                                        optionsData={
                                                            availableDates
                                                        }
                                                    />
                                                )}
                                                {/* { Twice Per Month Payday Selection} */}
                                                {twicePerMonth && (
                                                    <div>
                                                        <UiText weight="medium_500">
                                                            {' '}
                                                            Paydays{' '}
                                                        </UiText>
                                                        <UiFormControlSelection
                                                            type="radio"
                                                            fieldName="selected_payday"
                                                            fastField={false}
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value ===
                                                                    'firstAndFifth'
                                                                ) {
                                                                    setFieldValue(
                                                                        'day_1',
                                                                        1
                                                                    )
                                                                    setFieldValue(
                                                                        'day_2',
                                                                        15
                                                                    )
                                                                }
                                                                if (
                                                                    e.target
                                                                        .value ===
                                                                    'fifthAndLast'
                                                                ) {
                                                                    setFieldValue(
                                                                        'day_1',
                                                                        15
                                                                    )
                                                                    setFieldValue(
                                                                        'day_2',
                                                                        31
                                                                    )
                                                                }
                                                                setFieldValue(
                                                                    'anchor_pay_date',
                                                                    ''
                                                                )
                                                            }}
                                                            radioOptionsData={[
                                                                {
                                                                    label: '1st and 15th of the month.',
                                                                    value: 'firstAndFifth',
                                                                },
                                                                {
                                                                    label: '15th and the last day of the month',
                                                                    value: 'fifthAndLast',
                                                                },
                                                            ]}
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        (isMonthly ||
                                                            twicePerMonth) &&
                                                        values?.day_1 === null
                                                            ? classes.disableSection
                                                            : ''
                                                    }
                                                >
                                                    <Box my={2} mt={4}>
                                                        {isMonthly ? (
                                                            <UiFormControlSelection
                                                                type="select"
                                                                label={
                                                                    'Schedule Start Date (Required)'
                                                                }
                                                                showFloatingLabel={
                                                                    true
                                                                }
                                                                fieldName="anchor_pay_date"
                                                                fastField={
                                                                    false
                                                                }
                                                                optionKey="label"
                                                                optionValue="value"
                                                                optionsData={
                                                                    potentialDates
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const selectedDay =
                                                                        e
                                                                            ?.target
                                                                            ?.value
                                                                    setPayStartDate(
                                                                        selectedDay
                                                                    )
                                                                }}
                                                            />
                                                        ) : (
                                                            <UiFormControlSelection
                                                                dateType="string"
                                                                disableFutureDate={
                                                                    false
                                                                }
                                                                showFloatingLabel={
                                                                    true
                                                                }
                                                                type="stringDate"
                                                                fastField={
                                                                    false
                                                                }
                                                                label={
                                                                    'Schedule Start Date (Required)'
                                                                }
                                                                fieldName="anchor_pay_date"
                                                                endIcon={
                                                                    <CalendarTodayOutlined />
                                                                }
                                                                placeholder="MM/DD/YYYY"
                                                                dateFormat="MM/DD/YYYY"
                                                                disablePastDate
                                                                shouldDisableDate={(
                                                                    date: any
                                                                ) => {
                                                                    if (
                                                                        values?.frequency ===
                                                                        'Twice per month'
                                                                    ) {
                                                                        return shouldDisableDatesInPeriod(
                                                                            date,
                                                                            values?.selected_payday
                                                                        )
                                                                    } else {
                                                                        if (
                                                                            [
                                                                                'Every week',
                                                                                'Every other week',
                                                                            ].includes(
                                                                                values?.frequency
                                                                            )
                                                                        ) {
                                                                            return allowedDatesTillSevenDays.includes(
                                                                                date.format(
                                                                                    'MM-DD-YYYY'
                                                                                )
                                                                            )
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                        {alternateText(
                                                            'This will be the first day your employees are paid on this schedule.'
                                                        )}
                                                    </Box>
                                                    <Box my={2} mt={4}>
                                                        <UiText
                                                            variant="motorcycle_90"
                                                            textColor="textSecondary"
                                                        >
                                                            <span>
                                                                First Period End
                                                                Date
                                                            </span>
                                                        </UiText>
                                                        <Box mt={1}>
                                                            {isNaN(
                                                                Date.parse(
                                                                    values?.anchor_end_of_pay_period
                                                                )
                                                            ) ? (
                                                                <>&mdash;</>
                                                            ) : (
                                                                values?.anchor_end_of_pay_period
                                                            )}
                                                        </Box>
                                                        {alternateText(
                                                            'The First Period End Date is always one week before the Schedule Start Date.'
                                                        )}
                                                    </Box>
                                                </div>
                                            </Grid>
                                            {PreSaveWarning}
                                            <Box my={2} mt={4}>
                                                <NextActionButton
                                                    loading={submittingForm}
                                                    submitAction={submitForm}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </Form>
                            )
                        }}
                    </Formik>
                )}
            </Grid>
        )
    }
)
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
})
export default connect(mapStateToProps, { loadPostAuthData }, null, {
    forwardRef: true,
})(PayrollFrequencyForm)
