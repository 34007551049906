import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
    createFile,
    downloadReportIntoPDF,
    getIncomeStatementReport,
    saveIncomeReportToPortal,
} from '../../../../services/apiService/reports';
import { getNumberfromString, isEmpty } from '../../../../utils/appUtil';
import Loader from '../../../common/Loader';
import UiText from '../../../common/ui/UiText';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore';
import {
    IncomeStatementReportRoot,
} from '../models/reports-interface';
import { useReportsStyles } from '../styles/reports-styles';
import moment from 'moment';
import {
    findSpaceRegex,
    DATE_FORMATS,
    saveOptions,
    DOCUMENT_DOWNLOAD_SUCCESS,
    REPORTS_MENU_VIEWS
} from '../constants/reports.const';
import { showError } from '../../../../services/formService';
import UncategorizedTransactionsAlert from '../utils/Alerts/UncategorizedTransactionsAlert';
import SaveReports from '../utils/SaveReports/SaveReports';
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert';
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo';
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading';
import ComparisionReport, {
    reportTypes,
} from '../utils/BalanceSheetAndIncomeStatementReports/ComparisionReport';
import { GenerateIncomeReportsData } from '../utils/BalanceSheetAndIncomeStatementReports/ReportsListData';
import { getSegmentsData } from '../utils/BalanceSheetAndIncomeStatementReports/Comparison';
import { resetReportsData } from '../../../../store/actions/reportsData';
import { LeftArrowIcon } from '../Icons/LeftArrowIcon';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider';

interface QueryParams {
    startDate: number;
    endDate: number;
    start_date_ymd: string;
    end_date_ymd: string;
    time: number;
}

const LEVEL_1_ROWS = ['revenue', 'expenses'];
const TOOLTIP_TEXT =
    'The Income Statement shows your company’s expenses and revenue of the selected period of time.';

export default function IncomeStatementDetailedReport() {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const store = useStore().getState();
    const history = useHistory();
    const dispatch = useDispatch();
    const [reportsData, setReportsData] = useState<IncomeStatementReportRoot>();
    const query: QueryParams = useParams() as unknown as QueryParams;
    const storeData = useCurrentStore();
    const accountId = storeData.currentAccountId;
    const businessId = storeData.currentBusinessId!;
    const businessName = storeData.currentBusiness.name;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { startDate, endDate, start_date_ymd, end_date_ymd, time } = query;
    const [uncategorizedTransactions, setUncategorizedTransactions] =
        useState<number>();
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false);
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    );
    const [showComparisionReport, isShowComparisionReport] =
        useState<boolean>(false);
    const [logoImage, setLogoImage] = useState<string | undefined>('');
    const [segmentsIDAndName, setSegmentsIDAndName] = useState();
    const queryParam = new URLSearchParams(useLocation().search);
    const isCompareQuery = queryParam.get('view');
    const segmentIds = queryParam.get('segments')!;   
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading]);

    useEffect(() => {
        if (isCompareQuery) {
            isShowComparisionReport(true);
        }
    }, [isCompareQuery]);

    useEffect(() =>{
        if(!isCompareQuery || !reportsData){
            return;
        }
        const selectedSegments = reportsData?.selected_classes;
        const segments = getSegmentsData(selectedSegments);
        setSegmentsIDAndName(segments);
    },[isCompareQuery, reportsData])

    const getPeriodText = useCallback(() => {
        const periodText = (
            'From ' +
            moment(startDate * 1000).format(DATE_FORMATS.periodText) +
            ' to ' +
            moment(endDate * 1000).format(DATE_FORMATS.periodText)
        ).replace(findSpaceRegex, '+');
        return periodText;
    },[endDate, startDate]);
    
    useEffect(() => {
        const fetchIncomeStatementReports = () => {
            setIsLoading(true);
            getIncomeStatementReport(
                accountId,
                businessId,
                start_date_ymd,
                end_date_ymd,
                getPeriodText(),
                time,
                segmentIds
            )
                .then((result: unknown) => {
                    setIsLoading(false);
                    setReportsData(result as IncomeStatementReportRoot);
                    return result as IncomeStatementReportRoot;
                })
                .catch((error) => {
                    setIsLoading(false);
                    showError(error?.statusText);
                });
        };
        const reportsFromStore = store.reportsData.reports;
        const result = isEmpty(reportsFromStore)
            ? fetchIncomeStatementReports()
            : reportsFromStore;
        setReportsData(result);
    }, [endDate, time, storeData.currentAccountId, storeData.currentBusinessId, store, segmentIds, accountId, businessId, start_date_ymd, end_date_ymd, getPeriodText]);

    useEffect(() => {
        const logo = reportsData?.business?.logo;
        logo && setLogoImage(logo);
    }, [reportsData?.business?.logo]);

    const getTransactionsCount = (message: string): number => {
        return getNumberfromString(message);
    };

    useEffect(() => {
        const warning = reportsData?.warning;
        if (!warning) return;
        const count = getTransactionsCount(warning);
        setUncategorizedTransactions(count);
    }, [reportsData?.warning]);

    const handleSaveToPortal = (
        data: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const requestData = {
            start_date_ymd,
            end_date_ymd,
            period_text: getPeriodText(),
            time,
            comment: data.comment,
        };
        saveIncomeReportToPortal(accountId, businessId, requestData)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data;
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    );
                    closeModal();
                    setIsSaveSnackbar(true);
                });
            })
            .catch((error) => {
                showError(error?.statusText);
            });
    };

    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false);
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath);
    };
    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText={'Income Statement'}
            />
            {uncategorizedTransactions && (
                <UncategorizedTransactionsAlert
                    transactionsCount={uncategorizedTransactions}
                />
            )}
            <div className={reportsClasses.reportsCommonHeader}>
                <div className="backLinkAndBtn">
                    <Link data-cy='generate-new-report-btn' to="/reports?name=incomeStatement">
                        <LeftArrowIcon color={colorTheme.blue200} />
                        <span>Generate New Report</span>
                    </Link>
                    {
                        !isCompareQuery && 
                        <SaveReports
                        businessName={businessName ?? ''}
                        downloadReportPDFUrl={downloadReportIntoPDF(
                            accountId,
                            businessId,
                            start_date_ymd,
                            end_date_ymd,
                            getPeriodText(),
                            time
                        )}
                        options={saveOptions.slice(0, 2)}
                        handleSaveToPortal={handleSaveToPortal}
                        reportName={REPORTS_MENU_VIEWS.INCOME_STATEMENT}
                    />
                    }
                   
                </div>
                <div className="headerTextAndBusinessNameLogo">
                    <div>
                        <UiText variant="suv_150" weight="semi_bold_600">
                            Income Statement
                        </UiText>
                        <UiText>{getPeriodText().replaceAll('+', ' ')}</UiText>
                    </div>
                    <BusinessNameAndLogo
                        logoFromApi={logoImage}
                        accountId={accountId}
                        businessName={businessName!}
                    />
                </div>
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {!showComparisionReport ? (
                        <GenerateIncomeReportsData
                            reportsData={reportsData!}
                            reportType={reportTypes.INCOME_STATEMENT}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    ) : (
                        <ComparisionReport
                            reportsData={reportsData}
                            LEVEL_1_ROWS={LEVEL_1_ROWS}
                            segmentsIDAndName={segmentsIDAndName}
                            reportType={reportTypes.INCOME_STATEMENT}
                        />
                    )}
                </>
            )}
            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage="View Document"
                closeMessage="view" 
            />
        </div>
    );
}
