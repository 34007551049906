import { Box, Button, IconButton, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { isValidDocumentMime } from '../../../../utils/documentUtil'
import { useDispatch } from 'react-redux'
import { showInfo } from '../../../../store/actions/feedback'
import { InfoOutlined } from '@material-ui/icons'
import { uploadFilesToFolder } from '../../../../services/apiService'
import Icon from '../../../common/Icon'
import CloseIcon from '@material-ui/icons/Close'
import ThankYouPage from './ThankYouPage'
import FormErrorAlert from './Form8879ErrorAlert'

import {
    MIME_IMAGES,
    MIME_TYPES,
    MAX_FILE_SIZE,
} from '../constants/tax-prep.const'
import Loader from '../../../common/Loader'
import UiText from '../../../common/ui/UiText'
import {
    SUPPORTED_1040_MIME_TYPE,
    ALLOWED_1040_DOCUMENT_TYPES,
} from '../constants/tax-prep.const'
import { useWidgetContext } from '../provider/WidgetProvider'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { toRem16 } from '../../../../styles/commonStyles'
import { Folder } from '../../../../models'
import { dismissBlock } from '../../AccountSetupItems/AccountSetupUtility'
import { UPLOAD_1040_INFO_CARD } from '../../TodoSection/Todo.const'
import { getPrevYearOptions } from '../utils'

interface Form1040UploadFlowProps {
    handleClose: () => void
    title?: string
    setView?: (view: string) => void
    taxPrepStatus?: string
    flow?: string
    selectedYear: string
    folders: Folder[]
    accountId: string
    setRefetch1040Info: (val: Boolean) => void;
}

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    content: {
        padding: '1.25rem 3rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 1rem',
        },
    },
    fileName: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        '& span': {
            padding: '0 0 0 0.5rem',
            fontSize: toRem16(14),
        },
    },
    eFileButton: {
        padding: '0.563rem 1rem',
        background: (colorTheme) => colorTheme.primary,
        color: (colorTheme) => colorTheme.primaryWhite,
        boxSizing: 'border-box',
        borderRadius: '4px',
        '&:disabled': {
            opacity: 0.25,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
        '&:hover': {
            background: (colorTheme) => colorTheme.primary,
        },
    },
    deleteIcon: {
        color: (colorTheme) => colorTheme.red200,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    fileContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginBottom: '2rem',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        borderRadius: '0.5rem',
        padding: '2px 1rem',
    },
    dragZoneContainer: {
        borderRadius: '0.5rem',
        color: (colorTheme) => colorTheme.black100,
    },
    uploadBtn: {
        background: (colorTheme) => colorTheme.tertiary,
        color: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '4px',
        padding: '0.8rem',
        '&:hover': {
            background: (colorTheme) => colorTheme.tertiary,
        },
    },
    modalContentContainer: {
        width: '40rem',
        padding: '0.5rem 0',
        height: toRem16(688),
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '8px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            borderRadius: 0,
            overflow: 'auto',
        },
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.5rem 1.375rem',
        boxSizing: 'border-box',
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
    },
    closeIconButton: {
        padding: 0,
        fontSize: '1.2rem',
    },
    dropZoneContainer: {
        marginBottom: '2rem',
    },
    uploadHeaderContainer: {
        marginLeft: '0.5rem',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    uploadHeaderText: {
        marginLeft: '0.5rem',
    },
}))

const allowedMimeTypes = SUPPORTED_1040_MIME_TYPE
const allowedDocumentTypes = ALLOWED_1040_DOCUMENT_TYPES
export interface TaxUpload {
    show_review_widget?: boolean
}

interface FileLink {
    size: number
    is_preview_available: boolean
    mime_type: string
    id: string
    filename: string
    portal_account_id: string
    deleted: boolean
    upload_date: number
    type: string
    is_read_by_user: boolean
    is_read_by_accountant: boolean
    path: string
    is_owned_by_accountant: boolean
}

interface DocumentUpload {
    file_links: FileLink[]
    show_review_widget: boolean
}
export default function Form1040UploadFlow(props: Form1040UploadFlowProps) {
    const { handleClose, setView, flow, selectedYear, folders, accountId, setRefetch1040Info } =
        props
    const { colorTheme } = useThemeContext()
    const dispatch = useDispatch()
    const { taxYear } = useWidgetContext()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [files, setFiles] = useState<File[] | null>(null)
    const { isError, setIsError } = useWidgetContext()
    const [is400Error, setIs400Error] = useState<boolean>(false)
    const [error400String, setError400String] = useState<string>('')

    const styles = useStyle(colorTheme)

    const handleModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        const prevYear = getPrevYearOptions()[2];
        if (Number(selectedYear) === prevYear) {
            setRefetch1040Info(true)
        }
        setFiles(null)
        setUploadSuccess(false)
        handleClose()
    }

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )
    const isFileSizeSupported = (files: File[]): boolean => {
        const fileSize = files[0].size / 1024 / 1024
        return fileSize <= MAX_FILE_SIZE
    }

    const handleFileUnsupported = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <UiText>File(s) has unsupported format.</UiText>
                        <UiText>
                            Please upload only{' '}
                            <b>{allowedDocumentTypes.join(', ')}</b>
                        </UiText>
                    </div>
                ),
            })
        )
    }

    const handleFileUnsupportedSize = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <UiText>File size is too large.</UiText>
                        <UiText>Please upload file size less than 15 MB</UiText>
                    </div>
                ),
            })
        )
    }

    const uploadFiles = async (files: File[]) => {
        setIsLoading(true)

        const folder = folders.find(
            (folder) =>
                folder.title === 'Tax Return' &&
                folder.path.includes(`ty${selectedYear}`)
        )

        if (!folder) return setIsLoading(false)

        uploadFilesToFolder(accountId, folder.path, 'personal', files, selectedYear)
            .then((res: unknown) => {
                const data = res as DocumentUpload[]
                setIsLoading(false)
                setUploadSuccess(true)
               
            })
            .catch((error) => {
                if (error.status === 400) {
                    setError400String(error.statusText)
                    setIs400Error(true)
                }
                setIsLoading(false)
                setIsError(true)
            })
    }
    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }

        if (isFileTypeUnsupported(files)) {
            handleFileUnsupported()
            return
        }

        if (!isFileSizeSupported(files)) {
            handleFileUnsupportedSize()
            return
        }

        setFiles(files)
    }

    const getUploadedDocImageType = (mimeType: string) => {
        if (
            mimeType === MIME_TYPES.IMG_JPEG ||
            mimeType === MIME_TYPES.IMG_PNG
        ) {
            return MIME_IMAGES.ATTACHMENT_IMG
        }
        if (mimeType === MIME_TYPES.APP_PDF) {
            return MIME_IMAGES.PDF
        }
        return MIME_IMAGES.GENERIC
    }
    const getImageBasedOnMimeType = (mimeType: string) => {
        const imageType = getUploadedDocImageType(mimeType)
        return (
            <img
                src={
                    require(`../../../../assets/icons-svg/${imageType}.svg`)
                        .default
                }
                alt="document"
            />
        )
    }
    const renderSelectedFiles = () => {
        if (!files) return
        return files.map((file: File) => {
            return (
                <div className={styles.fileContainer}>
                    <div className={styles.fileName}>
                        {getImageBasedOnMimeType(file.type)}{' '}
                        <span>{file.name}</span>
                    </div>
                    <IconButton onClick={() => setFiles(null)}>
                        <Icon icon="delete" svgColor={colorTheme.grey400} />
                    </IconButton>
                </div>
            )
        })
    }

    const uploadDocument = () => {
        if (!files) {
            return
        }
        uploadFiles(files)
    }

    const acceptedFormat = ['application/pdf']

    return (
        <>
            {uploadSuccess ? (
                <ThankYouPage
                    title="Thank you for uploading your previous year’s personal tax return (1040)"
                    description="We’ll start processing your tax data."
                    handleClose={handleModalClose}
                    textCenter
                />
            ) : (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={styles.modalContentContainer}
                >
                    <div className={styles.modalHeader}>
                        <div className={styles.uploadHeaderContainer}>
                            {flow !== 'upload' && (
                                <Box
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setView && setView('landing')
                                    }}
                                >
                                    {' '}
                                    <Icon
                                        icon="leftArrow"
                                        svgColor={colorTheme.grey400}
                                    />
                                </Box>
                            )}

                            <UiText
                                className={styles.uploadHeaderText}
                                variant="car_100"
                                weight="medium_500"
                            >
                                Upload Your Previous Year’s Personal Tax Return
                                (1040)
                            </UiText>
                        </div>

                        <IconButton
                            aria-label="close"
                            classes={{ root: styles.closeIconButton }}
                            onClick={handleModalClose}
                            data-cy="cross-modal-close-btn"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={styles.content}>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                mt={2}
                                mb={4}
                            >
                                {files ? (
                                    renderSelectedFiles()
                                ) : (
                                    <div className={styles.dropZoneContainer}>
                                        <Dropzone
                                            multiple={false}
                                            onDrop={handleUploadFile}
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                            }) => {
                                                return (
                                                    <Box
                                                        {...getRootProps({})}
                                                        border={`2px dashed ${colorTheme.grey200}`}
                                                        textAlign="center"
                                                        p={4}
                                                        className={
                                                            styles.dragZoneContainer
                                                        }
                                                    >
                                                        <UiText
                                                            weight="regular_400"
                                                            variant="suv_150"
                                                            textColor="inherit"
                                                        >
                                                            Drop a file here to
                                                            upload
                                                        </UiText>
                                                        <Box my={3}>
                                                            <input
                                                                {...getInputProps()}
                                                                name="files[]"
                                                                accept={acceptedFormat.toString()}
                                                            />
                                                        </Box>
                                                        <Box my={3}>
                                                            <Button
                                                                className={
                                                                    styles.uploadBtn
                                                                }
                                                                startIcon={
                                                                    <Icon
                                                                        icon="upload"
                                                                        svgColor={
                                                                            colorTheme.primaryWhite
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <UiText
                                                                    variant="motorcycle_90"
                                                                    weight="semi_bold_600"
                                                                >
                                                                    Upload
                                                                </UiText>
                                                            </Button>
                                                        </Box>
                                                        <Box mt={4}>
                                                            <UiText
                                                                variant="motorcycle_90"
                                                                textColor="secondary"
                                                                weight="regular_400"
                                                            >
                                                                Supported file
                                                                type: .pdf
                                                            </UiText>
                                                            <UiText
                                                                variant="motorcycle_90"
                                                                textColor="secondary"
                                                                weight="regular_400"
                                                            >
                                                                15 MB Max File
                                                                Size
                                                            </UiText>
                                                        </Box>
                                                    </Box>
                                                )
                                            }}
                                        </Dropzone>
                                    </div>
                                )}

                                <Button
                                    onClick={uploadDocument}
                                    disabled={!files}
                                    className={styles.eFileButton}
                                >
                                    <UiText
                                        variant="car_100"
                                        weight="semi_bold_600"
                                    >
                                        Upload Form 1040
                                    </UiText>
                                </Button>
                                {isError && (
                                    <Box mt={4}>
                                        <FormErrorAlert
                                            is400Error={is400Error}
                                            error400String={error400String}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
