import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    Grid,
    Hidden,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    ListItem,
    ListItemText,
    Typography,
    ListItemAvatar,
    useTheme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '../../../assets/icons-svg/Edit.svg';
import DeleteIcon from '../../../assets/icons-svg/Delete.svg';
import WhiteDeleteIcon from '../../../assets/icons-svg/WhiteDelete.svg';
import TagIcon from '../../../assets/icons-svg/Tag.svg';
import Exclude from '../../../assets/icons-svg/ExcludeDark.svg';
import Loader from '../../common/Loader';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import { setLoader } from '../../../store/actions/loading';
import { getDotColors } from '../../../styles/ReconciliationDotColors';
import { AppData, ApplicationStore } from '../../../models/store';
import { initCategories } from '../../../store/actions/categories';
import AccountSelector from './AccountSelector';
import {
    IMostLikelyCategory,
    ITransaction,
} from '../../../models/transaction-models';
import { IBankAccount } from '../../../models/bank-account-models';
import { loadPostAuthData } from '../../../store/actions/appData';
import {
    deleteTransactionById,
    getMostLikelyCategories,
    getTransaction,
    updateTransaction,
    categorizeTransaction
} from '../../../services/transactionsService';
import { formSubmitErrorHandler } from '../../../services/formService';
import { saveBusinessDetails } from '../../../services/apiService';
import { patchResource } from '../../../services/axiosService';
import { getBankAccounts } from '../../../services/bankAccountService';

import MassCategorizationRules from './MassCategorizationRules';
import { isSplitValid } from '../../../utils/transactionUtil';
import { currencyFormatter } from '../../../utils/appUtil';
import CategorySelector from './CategorySelector';
import NewTransactionModal from './NewTransactionModal';
import AutoCategorizationModal from './AutoCategorizationModal';
import { showAlert } from '../../../store/actions/feedback';
import Deleteicon from '../../../assets/icons/Deleteicon';
import { ReviewDialog } from '../../common/ReviewDialog';
import { useStore } from 'react-redux';
import SettingsModal from './SettingsModal';
import UiText from '../../common/ui/UiText';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface TransactionThreadProps {
    appData: AppData;
    loadPostAuthData: any;
    currentData?: ITransaction | null;
    raw?: any;
    openEditModal?: (data: any) => void;
    onUpdate?: () => void;
    setSelectedAccount?: any;
    accountList?: IBankAccount[];
    handleReloadCurrentState?: () => void;
    setSelectedData?: any;
    triggerAutoCategorization?: any;
}

const useClasses = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    seconderyActionButtonOutline: {
        height: 36,
        color: (colorTheme) => `${colorTheme.black100} !important`,
    },
    buttonGap: {
        marginRight: theme.spacing(2),
    },
    listRoot: {
        width: '100%',
        height: 'calc(100vh - 328px)',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 258px)',
        },
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    messageBody: {
        marginTop: theme.spacing(1),
        whiteSpace: 'pre-line',
        wordWrap: 'break-word',
    },
    statusIcon: {
        position: 'relative',
        top: '3px',
        marginRight: theme.spacing(0),
    },
    fileIcon: {
        marginRight: theme.spacing(1),
    },
    removeIcon: {
        padding: 0,
        position: 'relative',
        top: '-7px',
    },
    subjectText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    sendButtonDIsabled: {
        opacity: 0.5,
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '0.15px',
        color: (colorTheme) => colorTheme.black100,
    },
    dateTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: (colorTheme) => colorTheme.grey400,
    },
    date: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.15px',
        color: (colorTheme) => colorTheme.black100,
    },
    subjectContainer: {
        margin: theme.spacing(5, '0', 2),
    },
    categorySelection: {
        padding: theme.spacing(4, '0', 2),
    },
    deleteModal: {
        '& div:first-child': {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
    deleteEntry: {
        background: (colorTheme) => colorTheme.red400,
        borderRadius: '4px',
    },
    likelyText: {
        width: '100px',
        '& > span': {
            display: 'inline-block',
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            background: 'green',
            marginRight: theme.spacing(2),
            ...getDotColors(),
        },
    },
    mostLikelyChip: {
        marginBottom: theme.spacing(3),
    },

    enableMassCategrizationHeader: {
        position: 'relative',
        paddingBottom: '2rem !important',
    },
    crossButton: {
        position: 'absolute',
        right: '-20px',
        top: '0',
    },
    chipBg: {
        background: (colorTheme) => colorTheme.grey100,
        color: (colorTheme) => colorTheme.primaryBlack
    },
    transactionTitle: {
        'font-weight': 500,
        'font-size': '18px',
        'line-height': '45px',
    },
    deleteIcon: {
        color: (colorTheme) => colorTheme.red400
    },
    btnLink : {
        margin: '0px',
        height: 'auto',
        paddingTop: '0px',
        paddingBottom: '0px',
        background: 'none'
    },
    vAlignBt: {
        verticalAlign: 'bottom'
    }
}));


function TransactionThread({
    appData,
    loadPostAuthData,
    currentData,
    raw,
    onUpdate,
    setSelectedAccount,
    accountList,
    handleReloadCurrentState,
    setSelectedData,
    triggerAutoCategorization
}: TransactionThreadProps) {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = useClasses(colorTheme);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const [loadingTransactionData, setLoadingTransactionData] = useState(false);
    const [transactionData, setransactionData] = useState<ITransaction | null>(
        null
    );
    const [activeAcc, setActiveAcc] = useState<IBankAccount[]>([]);
    const [mostLikeyCategories, setMostLikeyCategories] = useState<
        IMostLikelyCategory[]
    >([]);
    const [
        loadingMostLiklyCategories,
        setLoadingMostLiklyCategories,
    ] = useState(false);
    const [openEnableMassCat, setOpenEnableMassCat] = useState(false);
    const [
        openAutoCategorizationModal,
        setopenAutoCategorizationModal,
    ] = useState(false);
    const [
        likelyCategory,
        setLikelyCategory,
    ] = useState<IMostLikelyCategory | null>(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [dontAskAgainChk, setDontAskAgainChk] = useState(false);
    const [openRuleSetting, setOpenRuleSetting] = useState(false);
    const [loading3DotMenu, setLoading3DotMenu] = useState(false);
    const [bankAccounts, setbankAccounts] = useState<IBankAccount[]>([]);
    const [openTransactionModal, setOpenTransactionModal] = useState(false);
    const [
        excludeSingleEntry,
        setExcludeSingleEntry,
    ] = useState<null | ITransaction>(null);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [
        deleteSingleEntry,
        setdeleteSingleEntry,
    ] = useState<null | ITransaction>(null);
    const [threadHeight, setThreadHeight] = useState('');
    const [refState, setRefState] = useState<any>();

    const [anchorElDot, setAnchorElDot] = useState<any>(null);
    const [reviewModalFlag, setReviewModalFlag] = useState(false);
    const handleCloseDotMenu = () => {
        setAnchorElDot(null);
    };

    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
    const openDot = Boolean(anchorElDot);

    const noTransactionCategory = !transactionData?.category,
        hasSplittedTransaction = !(transactionData?.split?.length === 0),
        showCategorizeTransaction = noTransactionCategory
            ? hasSplittedTransaction
                ? false
                : true
            : false,
        showSplitTransactionOption = noTransactionCategory
            ? hasSplittedTransaction
                ? false
                : true
            : false;
    
    const store = useStore().getState();
    
    const updateMostLikely = useCallback(
        (data: ITransaction | null) => {
            let transactionParams = {};
            transactionParams = {
                account_type: activeAcc?.[0]?.account_type,
                amount: data?.amount,
                bank_account_name: activeAcc?.[0]?.name,
                customer_name: data?.customer_name,
                description: data?.description,
                id: data?.id,
            };

            if (transactionParams) {
                setLoadingMostLiklyCategories(true);
                setMostLikeyCategories([]);
                getMostLikelyCategories(
                    appData.current_account_id,
                    appData.current_business_id,
                    transactionParams
                )
                    .then((response) => {
                        setMostLikeyCategories(response[data?.id as string]);
                        setLoadingMostLiklyCategories(false);
                    })
                    .catch(() => {
                        setLoadingMostLiklyCategories(false);
                    });
            }
        },
        [activeAcc, appData.current_account_id, appData.current_business_id]
    );
    const checkReviewModal = (data: any) => {
        setReviewModalFlag(data.show_review_widget);
    };
    const fetchTransactionDetails = useCallback(
        (transactionId: string) => {
            setLoadingTransactionData(true);
            getTransaction(
                appData.current_account_id,
                appData.current_business_id,
                transactionId
            )
                .then((res) => {
                    setransactionData(res);
                    updateMostLikely(res);
                    setLoadingTransactionData(false);
                })
                .catch((e) => {
                    setLoadingTransactionData(false);
                });
        },
        [
            appData.current_account_id,
            appData.current_business_id,
            setLoader,
            updateMostLikely,
        ]
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    const reloadDetails = () => {
        if (onUpdate) {
            onUpdate();
        } else {
            history.push({
                pathname: location.pathname,
                search: query.toString(),
            });
        }
    };

    const handleMassCategorizationRules = (ruleName: string, event: any) => {
        const data: any = {};
        if (typeof event === 'boolean') {
            data[ruleName] = event;
        } else {
            data[ruleName] = event.currentTarget.checked;
        }
        handleClose();
        if (
            ruleName === 'auto_categorization_alert' ||
            ruleName === 'category_confirmation_reminder'
        ) {
            setLoading3DotMenu(true);
            patchResource('api/user/current', data)
                .then(() => {
                    loadPostAuthData().then(() => {});
                })
                .finally(() => setLoading3DotMenu(false));
        } else if (ruleName === 'mass_categorization_rules') {
            setOpenRuleSetting(true);
        } else {
            setLoading3DotMenu(true);
            saveBusinessDetails(
                data,
                appData.current_account_id,
                appData.current_business_id
            )
                .then(() => {
                    loadPostAuthData().then(() => {});
                })
                .finally(() => setLoading3DotMenu(false));
        }
    };

    const updateLikelyCategory = (category: IMostLikelyCategory) => {
        if (setOpenConfirmation) {
            setOpenConfirmation(false);
        }
        if (transactionData) {
            updateTransaction(
                appData.current_account_id,
                appData.current_business_id,
                transactionData?.id as string,
                {
                    category: category ? category.id : '',
                }
            ).then(() => {
                handleReloadCurrentState?.();
                fetchTransactionDetails(transactionData?.id as string);
                if (dontAskAgainChk) {
                    handleMassCategorizationRules(
                        'category_confirmation_reminder',
                        false
                    );
                }
                if (appData.user.auto_categorization_alert) {
                    setOpenEnableMassCat(true);
                }
            }).catch((err) => {
                const error_obj = err.errors;
                const error = error_obj? error_obj[Object.keys(error_obj)[0]] : err.statusText;
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: error
                    })
                );
            });
        }
    };

    const getSettingsElement = () =>{
        return (<IconButton
                aria-label='settings'
                size='small'
                color='default'
                    onClick={() => {
                        setSettingsModalOpen(true);
                    }}
                >
                <SettingsOutlinedIcon />
            </IconButton>);
    }
    const getlikelyCategoryCards = () => {
        if (mostLikeyCategories?.length > 0) {
            return mostLikeyCategories.map((category, index) => {
                return (
                    <Box className={classes.mostLikelyChip}>
                        <Grid
                            container
                            justify='space-between'
                            alignItems='center'
                        >
                            <Chip
                                label={category.name}
                                variant='outlined'
                                onClick={() =>
                                    handleMostLikelyCategoryClick(category)
                                }
                            />
                            {category.percent ? (
                                <Grid
                                    container
                                    alignItems='center'
                                    className={classes.likelyText}
                                >
                                    <span
                                        className={`dot-color-${index}`}
                                    ></span>
                                    <Typography variant='body2'>
                                        {category.percent}% likely
                                    </Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Box>
                );
            });
        } else {
            return <></>;
        }
    };

    const handleMostLikelyCategoryClick = (category: IMostLikelyCategory) => {
        setLikelyCategory(category);
        if (appData.user.category_confirmation_reminder) {
            setOpenConfirmation(true);
        } else {
            updateLikelyCategory(category);
        }
    };

    const enableAutoCategorization = () => {
        setOpenEnableMassCat(false);
        setopenAutoCategorizationModal(true);
    };

    const renderChip = (key: string, label: string | undefined) => {
        return (
            <Chip
                key={key}
                label={label}
                color='secondary'
                className={classes.chipBg}
                style={{ margin: theme.spacing('0', 2, 2, '0') }}
            />
        );
    };

    const getCategoryLabel = (transactionData:ITransaction) => {
        return transactionData.auto_categorization_type === 1 || transactionData.auto_categorization_type === 3 ? 'Automatically categorized.':'Rule based categorized.';
    }
    const getCategory = () => {
        let categoryElement = null;
        let categoryName = '';
        
        if(!transactionData?.category_name){
            categoryName = store?.category?.categories.find(
                (item: any) => item.id === transactionData?.category
            )?.title;
        }else{
            categoryName = transactionData?.category_name;
        }
        if (transactionData?.category) {
            categoryElement = renderChip(
                'category',
                categoryName || ''
            );
        } else if (hasSplittedTransaction) {
            categoryElement = transactionData?.split?.map((cat, index) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {renderChip(`cat-${index}`, cat.category_name)}
                        <div>
                            {currencyFormatter.format(
                                parseFloat(cat.amount || '')
                            )}
                        </div>
                    </div>
                );
            });
        }

        return categoryElement ? (
            <>
                <Typography
                    variant='h6'
                    style={{ marginBottom: theme.spacing(3) }}
                >
                    Category 
                   {getSettingsElement()}
                </Typography> 
                
                {categoryElement}
               
                {transactionData?.auto_categorization_type ?  
                    <UiText>
                            <img
                                alt={`Tag`}
                                className={classes.vAlignBt}
                                src={TagIcon}
                            /> 
                            {getCategoryLabel(transactionData)}
                            <Button
                                    className={classes.btnLink}
                                    variant='text'
                                    onClick={()=>{setOpenRuleSetting(true)}}
                                >
                                    View Custom Rule
                            </Button>
                    </UiText> : ''
                }
            </>
        ) : null;
    };

    const openDeleteModal = (transaction: ITransaction) => {
        if(transaction.reconciliation) {
            dispatch (
                showAlert ({
                    alertType: 'error',
                    alertText: `It's not possible to delete transaction with reconciled status. Please undo reconciliation in order to delete it.`,
                })
            );
        }
        else {
            if (transaction) setdeleteSingleEntry(transaction);
            setshowDeleteModal(true);
        }
    };

    const handleSingleExclude = () => {
        if (excludeSingleEntry) {
            updateTransaction(
                appData.current_account_id,
                appData.current_business_id,
                excludeSingleEntry.id as string,
                { excluded: true }
            )
                .then(() => {
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Transactions #${excludeSingleEntry.number} excluded`,
                        })
                    );
                    setSelectedData?.(null);
                    if (handleReloadCurrentState) {
                        handleReloadCurrentState();
                    } else {
                        history.push('/transactions');
                    }
                })
                .finally(() => {
                    setExcludeSingleEntry(null);
                });
        }
    };

    const deleteHandleOption = (id?: any) => {
        if (id) {
            deleteTransactionById(
                appData.current_account_id,
                appData.current_business_id,
                id
            )
                .then(() => {
                    setshowDeleteModal(false);
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Transactions #${deleteSingleEntry?.number} deleted`,
                        })
                    );

                    setSelectedData?.(null);
                    setOpenTransactionModal(false);

                    if (handleReloadCurrentState) {
                        handleReloadCurrentState();
                    } else {
                        history.push('/transactions');
                    }
                })
                .finally(() => {
                    setdeleteSingleEntry(null);
                    setshowDeleteModal(false);
                });
        }
    };

    const autoCategrizationConfirmationModal = () => {
        return (
            <UiConfirmationDialog
                open={openEnableMassCat}
                showFormActionDivider={true}
                alignButtons='end'
                size='sm'
                message={
                    <>
                        <Typography
                            variant='body1'
                            className={classes.deleteModal}
                            gutterBottom
                        >
                            <div
                                className={
                                    classes.enableMassCategrizationHeader
                                }
                            >
                                Enable Auto-Categorizing?
                                <IconButton
                                    aria-label='close'
                                    color='inherit'
                                    className={classes.crossButton}
                                    onClick={() => setOpenEnableMassCat(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div>
                                Would you like to automatically categorize
                                similar incoming transactions as{' '}
                                <strong>{likelyCategory?.name}</strong>?
                            </div>
                            <div>
                                You can change your decision at any time in the
                                Category Menu.
                            </div>
                        </Typography>
                    </>
                }
                handleClose={() => {
                    setOpenEnableMassCat(false);
                    reloadDetails();
                }}
                confirmNode={
                    <Button
                        variant='contained'
                        onClick={enableAutoCategorization}
                        color='primary'
                    >
                        Enable
                    </Button>
                }
                cancelButtonText='Cancel'
            />
        );
    };

    const autoCategorizationModal = () => {
        return openAutoCategorizationModal ? (
            <AutoCategorizationModal
                selectedTransaction={transactionData}
                category={likelyCategory as IMostLikelyCategory}
                handleClose={() => {
                    setopenAutoCategorizationModal(false);
                }}
                handleSuccess={() => {
                    setopenAutoCategorizationModal(false);
                }}
            />
        ) : null;
    };

    const likelycategoryUpdateConfirm = () => {
        return (
            <UiConfirmationDialog
                open={openConfirmation}
                showFormActionDivider={true}
                alignButtons='end'
                size='sm'
                message={
                    <>
                        <Typography
                            variant='body1'
                            className={classes.deleteModal}
                            gutterBottom
                        >
                            <div></div>
                            <div>
                                Would you like to categorize this transaction as{' '}
                                <strong>{likelyCategory?.name}</strong>?
                            </div>
                            <FormControlLabel
                                label='Do not show this reminder in the future'
                                control={
                                    <Checkbox
                                        checked={dontAskAgainChk}
                                        color='primary'
                                        onChange={(event: any) =>
                                            setDontAskAgainChk(
                                                event.currentTarget.checked
                                            )
                                        }
                                        inputProps={{
                                            'aria-label':
                                                'Confirmation Reminders checkbox',
                                        }}
                                    />
                                }
                            />
                        </Typography>
                    </>
                }
                handleClose={() => {
                    setLikelyCategory(null);
                    setOpenConfirmation(false);
                    setDontAskAgainChk(false);
                }}
                confirmNode={
                    <Button
                        // className={classes.deleteEntry}
                        variant='contained'
                        // disabled={deleteLoading}

                        onClick={() =>
                            updateLikelyCategory(
                                likelyCategory as IMostLikelyCategory
                            )
                        }
                        color='primary'
                    >
                        Yes
                    </Button>
                }
                cancelButtonText='No'
            />
        );
    };

    const handleFormSubmit = (
        formValues: any,
        { setStatus, setFieldError, setSubmitting }: any
    ) => {
        if (formValues) {
            if (formValues.split && formValues.split.length) {
                if (
                    formValues.split.length &&
                    !isSplitValid(formValues.split, formValues)
                ) {
                    setStatus({
                        error:
                            'Transaction Split Amount should be equal Original Amount',
                    });
                    setSubmitting(false);
                    return;
                }
                if (formValues.split.length) {
                    formValues.split.pop();

                    if (formValues.split.length === 1) {
                        formValues.category = formValues.split[0].category;
                        formValues.split = [];
                    }
                }
                splitCurrentTransactions(formValues, {
                    setStatus,
                    setFieldError,
                    setSubmitting,
                });
            } else {
                categorizeCurrentTransaction(formValues?.category, {
                    setFieldError,
                    setSubmitting,
                });
            }
        }
    };

    // categorize transaction
    const categorizeCurrentTransaction = (category: string, form: any) => {
        let params = {
            category: category,
        };

        formSubmitErrorHandler(
            categorizeTransaction(
                appData.current_account_id as string,
                appData.current_business_id,
                transactionData?.id || '',
                params
            ).then((res: any) => {
                if (res) {
                    form.setSubmitting(false);
                    checkReviewModal(res);
                    fetchTransactionDetails(transactionData?.id as string);
                    handleReloadCurrentState?.();
                    dispatch(
                        showAlert({
                            alertText: `Transaction Categorized`,
                            alertType: 'success',
                        })
                    );
                }
            }).catch((res) => {
                // Display the first field error as an alert 
                const error = res.errors[Object.keys(res.errors)[0]][0] ?? 
                res.statusText;
                form.setSubmitting(false);
                dispatch(
                    showAlert({
                        alertText: error,
                        alertType: 'error',
                    })
                );
            })
        );
    };

    const splitCurrentTransactions = (formikValues: any, form: any) => {
        let splitValues = formikValues.split.map(
            (value: any, index: number) => ({
                amount: value?.amount,
                category: value?.category,
                order: index,
            })
        );
        let formData = { ...transactionData, split: splitValues };
        formSubmitErrorHandler(
            updateTransaction(
                appData.current_account_id,
                appData.current_business_id,
                transactionData?.id as string,
                formData
            ).then((res: any) => {
                if (res) {
                    form.setSubmitting(false);
                    fetchTransactionDetails(transactionData?.id as string);
                    handleReloadCurrentState?.();
                    dispatch(
                        showAlert({
                            alertText: `Transaction splitted`,
                            alertType: 'success',
                        })
                    );
                }
            }),
            () => {
                form.setSubmitting(false);
            },
            form.setFieldError
        );
    };

    const splitTransactionValidations = Yup.array().of(
        Yup.object({
            category: Yup.string().required('Category is required!'),
            amount: Yup.number().required('Amount is required!'),
        })
    );

    useEffect(() => {
        dispatch(initCategories(true));
    }, [dispatch]);

    useEffect(() => {
        getBankAccounts().then((accounts) => {
            const active = accounts?.list.filter(
                (acc) => acc.account === transactionData?.account
            );
            setbankAccounts(accounts ? accounts.list : []);
            if (active) {
                setActiveAcc(active);
            }
        });
    }, [transactionData?.account, transactionData?.id]);

    useEffect(() => {
        if (!currentData) {
            const transactionId = location.pathname.split('/')[2];
            if (transactionId) {
                fetchTransactionDetails(transactionId);
            }
        } else {
            setransactionData(currentData);
            updateMostLikely(currentData);
        }
    }, [currentData, currentData?.id]);

    useEffect(() => {
        if (refState) {
            setThreadHeight(`calc(100vh - ${refState?.offsetHeight + (appData.user.accountant_mode ? 144 : 130)}px)`);
        }
    }, [refState, refState?.offsetHeight, transactionData]);

    const checkAndExclude = (selectedData: ITransaction) => {
        if(selectedData.reconciliation) {
            setExcludeSingleEntry(null);
            dispatch (
                showAlert ({
                    alertType: 'error',
                    alertText: `It's not possible to exclude transaction with reconciled status. Please undo reconciliation in order to exclude it.`,
                })
            );
        }
        else {
            setExcludeSingleEntry(selectedData);
        }
        
    }

    return (
        <Grid
            container
            direction='row'
            style={{ height: '100%', padding: '1rem 0 0.5rem 0.5rem' }}
        >
            {reviewModalFlag && <ReviewDialog/>}
            {loadingTransactionData ? (
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                >
                    <Loader />
                </Grid>
            ) : (
                <Grid
                    container
                    direction='column'
                    justify='space-between'
                    alignItems='stretch'
                >
                    <div style={{ width: '100%' }}>
                        <div ref={(ref: any) => setRefState(ref)}>
                            <Grid container alignItems='center'>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    sm={12}
                                    container
                                    alignItems='center'
                                    direction='row'
                                >
                                    <Hidden mdUp>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            sm={12}
                                            container
                                            alignItems='center'
                                            direction='row'
                                            justify='space-between'
                                        >
                                            <Grid
                                                style={{ display: 'flex' }}
                                                item
                                                direction='row'
                                                alignItems='center'
                                            >
                                                <div>
                                                    <IconButton
                                                        onClick={() => {
                                                            history.goBack();
                                                        }}
                                                        style={{
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <ArrowBackIcon />
                                                    </IconButton>
                                                </div>
                                                <div>
                                                    <Typography
                                                        variant='h6'
                                                        className={
                                                            classes.subjectText
                                                        }
                                                        noWrap
                                                    >
                                                        Transactions #
                                                        {
                                                            transactionData?.number
                                                        }
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div>
                                                    <IconButton
                                                        aria-label='more'
                                                        aria-controls='long-menu-edt'
                                                        aria-haspopup='true'
                                                        onClick={(event) => {
                                                            anchorElDot
                                                                ? setAnchorElDot(
                                                                      null
                                                                  )
                                                                : setAnchorElDot(
                                                                      event.currentTarget
                                                                  );
                                                        }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id='long-menu-edt'
                                                        anchorEl={anchorElDot}
                                                        keepMounted
                                                        open={openDot}
                                                        onClose={
                                                            handleCloseDotMenu
                                                        }
                                                    >
                                                        {!transactionData?.excluded ? (
                                                            <MenuItem
                                                                onClick={
                                                                    handleCloseDotMenu
                                                                }
                                                            >
                                                                <Button
                                                                    className={
                                                                        classes.seconderyActionButtonOutline
                                                                    }
                                                                    startIcon={
                                                                        <img
                                                                            alt={`Edit`}
                                                                            src={
                                                                                EditIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        //@ts-ignore
                                                                        setOpenTransactionModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </MenuItem>
                                                        ) : null}
                                                        <Divider />
                                                        {!(transactionData?.is_aggregated || transactionData?.is_plaid) &&
                                                        transactionData?.id ? (
                                                            <MenuItem
                                                                onClick={
                                                                    handleCloseDotMenu
                                                                }
                                                            >
                                                                <Button
                                                                    className={
                                                                        classes.deleteIcon
                                                                    }
                                                                    startIcon={
                                                                        <Deleteicon />
                                                                    }
                                                                    onClick={() => {
                                                                        openDeleteModal(
                                                                            transactionData
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </MenuItem>
                                                        ) : null}
                                                        {!transactionData?.excluded &&
                                                        (transactionData?.is_aggregated || transactionData?.is_plaid)  ? (
                                                            <MenuItem
                                                                onClick={
                                                                    handleCloseDotMenu
                                                                }
                                                            >
                                                                <Button
                                                                    className={
                                                                        classes.seconderyActionButtonOutline
                                                                    }
                                                                    classes={{
                                                                        root:
                                                                            classes.buttonGap,
                                                                    }}
                                                                    startIcon={
                                                                        <img
                                                                            alt={`Exclude`}
                                                                            src={
                                                                                Exclude
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        checkAndExclude(transactionData);
                                                                    }}
                                                                >
                                                                    Exclude
                                                                </Button>
                                                            </MenuItem>
                                                        ) : null}
                                                    </Menu>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={2}
                                    sm={2}
                                    container
                                    alignItems='center'
                                    justify='space-between'
                                >
                                    <Hidden smDown>
                                        <Box className={classes.title}>
                                            Transaction{' '}
                                            {`#${transactionData?.number}`}
                                        </Box>
                                        <Box>
                                            {!(transactionData?.is_aggregated || transactionData?.is_plaid) &&
                                            transactionData?.id ? (
                                                <Button
                                                    className={
                                                        classes.seconderyActionButtonOutline
                                                    }
                                                    classes={{
                                                        root: classes.buttonGap,
                                                    }}
                                                    variant='outlined'
                                                    startIcon={
                                                        <img
                                                            alt={`Delete`}
                                                            src={DeleteIcon}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        openDeleteModal(
                                                            transactionData
                                                        );
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            ) : null}
                                            {!transactionData?.excluded &&
                                            (transactionData?.is_aggregated || transactionData?.is_plaid) ? (
                                                <Button
                                                    className={
                                                        classes.seconderyActionButtonOutline
                                                    }
                                                    classes={{
                                                        root: classes.buttonGap,
                                                    }}
                                                    variant='outlined'
                                                    startIcon={
                                                        <img
                                                            alt={`Exclude`}
                                                            src={Exclude}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        checkAndExclude(transactionData);
                                                    }}
                                                >
                                                    Exclude
                                                </Button>
                                            ) : null}
                                            {!transactionData?.excluded ? (
                                                <Button
                                                    className={
                                                        classes.seconderyActionButtonOutline
                                                    }
                                                    variant='outlined'
                                                    startIcon={
                                                        <img
                                                            alt={`Edit`}
                                                            src={EditIcon}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        setOpenTransactionModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            ) : null}
                                        </Box>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Hidden mdUp>
                                <Divider variant='fullWidth'></Divider>
                            </Hidden>

                            <Box padding='1rem 0rem'>
                                <div className={classes.dateTitle}>Date</div>
                                <div className={classes.date}>
                                    {' '}
                                    {transactionData &&
                                        moment(
                                            (transactionData?.date as number) *
                                                1000
                                        ).format('DD MMM YYYY')}
                                </div>
                            </Box>
                            <AccountSelector
                                currentAccountId={transactionData?.account}
                                disabled={true}
                                updateCurrentAccount={false}
                                setSelectedAccount={setSelectedAccount}
                                accountList={accountList || bankAccounts}
                            />
                            <Grid
                                container
                                alignItems='center'
                                justify='space-between'
                                className={classes.subjectContainer}
                            >
                                <Grid item>
                                    <Typography variant='body1'>
                                        {transactionData?.customer_name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='h4'>
                                        {currencyFormatter.format(
                                            parseFloat(
                                                transactionData?.amount || '0'
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </div>
                        <div
                            style={{
                                overflow: 'auto',
                                height: threadHeight,
                                overflowX: 'hidden',
                            }}
                        >
                            <Box className={classes.categorySelection}>
                                {getCategory()}
                                {!transactionData?.excluded &&
                                !hasSplittedTransaction ? (
                                    loadingMostLiklyCategories ? (
                                        <Loader />
                                    ) : mostLikeyCategories?.length > 0 ? (
                                        <>
                                            <Typography
                                                variant='h6'
                                                style={{
                                                    marginTop: theme.spacing(2),
                                                }}
                                            >
                                                Select a Category
                                                {getSettingsElement()}
                                            </Typography>
                                            <Box my={2}>
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                >
                                                    <div
                                                        style={{
                                                            padding: '12px 0',
                                                        }}
                                                    >
                                                        <Typography variant='subtitle1'>
                                                            {
                                                                mostLikeyCategories?.length
                                                            }{' '}
                                                            Most likely
                                                            categories
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Box>
                                            <Box>
                                                {getlikelyCategoryCards()}
                                            </Box>
                                        </>
                                    ) : null
                                ) : null}
                                {transactionData &&
                                    (showSplitTransactionOption ||
                                        showCategorizeTransaction) && (
                                        <Formik
                                            initialValues={{
                                                ...transactionData,
                                                category: '',
                                            }}
                                            onSubmit={handleFormSubmit}
                                            validationSchema={
                                                splitTransactionValidations
                                            }
                                            enableReinitialize
                                        >
                                            {({ status, setStatus }) => {
                                                return (
                                                    <Form>
                                                        {status?.error && (
                                                            <Alert
                                                                className='alert'
                                                                severity='warning'
                                                                onClose={() => {
                                                                    setStatus({
                                                                        error:
                                                                            '',
                                                                    });
                                                                }}
                                                            >
                                                                {status?.error}
                                                            </Alert>
                                                        )}
                                                        <CategorySelector
                                                            accountId={
                                                                appData.current_account_id
                                                            }
                                                            businessId={
                                                                appData.current_business_id
                                                            }
                                                            renderedOutsideForm={
                                                                true
                                                            }
                                                        />
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    )}
                                <Box>
                                    <Box my={2}>
                                        <Divider variant='fullWidth' />
                                    </Box>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <img
                                                alt={'description'}
                                                src={
                                                    require(`../../../assets/icons-svg/InfoText.svg`)
                                                        .default
                                                }
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    component='div'
                                                    variant='subtitle1'
                                                    color='textSecondary'
                                                >
                                                    Description
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    component='span'
                                                    variant='body2'
                                                    color='textPrimary'
                                                >
                                                    {
                                                        transactionData?.description
                                                    }
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Box>
                            </Box>
                        </div>
                    </div>

                    {/* Modals */}
                    <div>
                        {autoCategrizationConfirmationModal()}
                        {autoCategorizationModal()}
                        {likelycategoryUpdateConfirm()}
                        <NewTransactionModal
                            open={openTransactionModal}
                            handleClose={() => setOpenTransactionModal(false)}
                            isEdit={true}
                            deleteData={{
                                showDeleteModal,
                                deleteHandleOption: openDeleteModal,
                            }}
                            selectedData={transactionData}
                            accountList={bankAccounts}
                            selectedAccount={{
                                account: transactionData?.account,
                            }}
                            refreshTransactions={handleReloadCurrentState}
                            setUpdatedTransaction={(tran: ITransaction) => {
                                fetchTransactionDetails(
                                    transactionData?.id as string
                                );
                                checkReviewModal(tran);
                                handleReloadCurrentState?.();
                            }}
                            setExcludeSingleEntry={setExcludeSingleEntry}
                        />
                        <MassCategorizationRules
                            isOpen={openRuleSetting}
                            handleClose={() => {
                                setOpenRuleSetting(false);
                            }}
                        />
                        <UiConfirmationDialog
                            open={showDeleteModal}
                            message={
                                <>
                                    <Typography
                                        variant='body1'
                                        className={classes.deleteModal}
                                        gutterBottom
                                    >
                                        <div>
                                            Delete Transaction
                                            {` #${deleteSingleEntry?.number}`}
                                        </div>
                                        <div>
                                            Do you want to delete the selected
                                            Transaction?
                                        </div>
                                        <div>This cannot be undone.</div>
                                    </Typography>
                                </>
                            }
                            handleClose={() => {
                                setshowDeleteModal(false);
                            }}
                            confirmNode={
                                <Button
                                    className={classes.deleteEntry}
                                    variant='contained'
                                    // disabled={deleteLoading}
                                    startIcon={
                                        <img
                                            alt={`Private`}
                                            src={WhiteDeleteIcon}
                                        />
                                    }
                                    onClick={() => {
                                        deleteHandleOption(
                                            deleteSingleEntry?.id
                                        );
                                    }}
                                    color='secondary'
                                >
                                    Delete
                                </Button>
                            }
                            cancelButtonText='Cancel'
                        />
                        <UiConfirmationDialog
                            open={!!excludeSingleEntry}
                            message={
                                <>
                                    <Typography
                                        variant='body1'
                                        className={classes.deleteModal}
                                        gutterBottom
                                    >
                                        <div>
                                            {!!excludeSingleEntry
                                                ? `Exclude transaction #${excludeSingleEntry.number} ?`
                                                : null}
                                        </div>
                                        <div>
                                            Excluded transactions do not show up
                                            on the dashboard or in any generated
                                            reports.
                                        </div>
                                        <div>
                                            You can change this at any time
                                            through the “Excluded” tab in
                                            Transactions.
                                        </div>
                                    </Typography>
                                </>
                            }
                            handleClose={() => {
                                setExcludeSingleEntry(null);
                            }}
                            confirmNode={
                                <Button
                                    className={classes.deleteEntry}
                                    variant='contained'
                                    startIcon={
                                        <img src={Exclude} alt='excludeicon' />
                                    }
                                    onClick={() => {
                                        handleSingleExclude();
                                    }}
                                    color='secondary'
                                >
                                    Exclude
                                </Button>
                            }
                            cancelButtonText='Cancel'
                        />
                    </div>
                </Grid>
            )}
             {settingsModalOpen && <SettingsModal
                        open={settingsModalOpen}
                        triggerAutoCategorization={triggerAutoCategorization}
                        handleClose={()=>{
                            setSettingsModalOpen(false);
                        }}
                />
            }
        </Grid>
        
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps, { loadPostAuthData })(
    TransactionThread
);
