import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Hidden,
    makeStyles,
    Theme,
    Grid,
    Divider,
    Box,
} from '@material-ui/core'
import Icon from '../../../components/common/Icon'
import UiText from '../../common/ui/UiText'
import MobileHeader from '../../common/MobileHeader'
// components
import PurposesList from './PurposesList'
import PurposeModal from '../purposes/modal/PurposeModal'
// api
import usePurposesHook from './purposes.hook'
import UiButton from '../../common/ui/UiButton'
import { toRem16 } from '../../../styles/commonStyles'
import { getTrips } from '../../../services/apiService/trips'
import { jsonToRql } from '../../../utils/rql'
import { PaginatedTripsResponse } from '../../../models/trips'
import TripList from '../trips/TripList'
import { Purpose } from '../../../models/purposes'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { DiscardChangesPurposeModal } from './modal/DiscardChangesPurposeModal'
import Loader from '../../common/Loader'
import useDeviceSize from '../../../hooks/useDeviceSize'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            minHeight: '100%',
            display: 'flex',
        },
        titleMargin: {
            margin: '1rem 0rem 0rem 0rem',
        },
        purposesContainer: {
            display: 'flex',
            flex: 1,
            padding: '0 1.5rem',
            margin: `0rem 5rem 0rem 5rem`,
            overflow: 'auto',
            minHeight: 0,
            [theme.breakpoints.down('sm')]: {
                margin : 0,
                padding: `0 ${toRem16(12)} 0 ${toRem16(12)}`
            }
        },
        purposesListContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            borderRight: `${toRem16(1)} solid ${useThemeContext().grey300}`,
        },
        navItemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minHeight: 0,
            overflow: 'auto',
            marginBottom: '50px'
        },
        purposeDetailsContainer: {
            flex: 1,
            padding: '0 1.5rem',
            display: 'flex',
            flexDirection: 'column',
        },
        purposesListHeader: {
            position: 'sticky',
            top: 0,
            left: 0,
            background: useThemeContext().white,
        },
        purposesListHeaderContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: 0,
            overflow: 'auto',
            margin: `0rem 2rem 0rem 1rem`,
            [theme.breakpoints.down('sm')]: {
                margin : 0,
            }
        },
        tripCount: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3),
            display: 'flex',
        },
        mileageIconMargin: {
            margin: '0rem 1.5rem 0rem 1.5rem',
        },
    }
})

interface LoadingStates {
    loading: boolean
    hasMore: boolean
}

const Purposes = () => {
    const classes = useStyles()
    const history = useHistory()
    const [discardDialog, setDiscardDialog] = useState<boolean>(false)
    const { isMobileDevice } = useDeviceSize()
    const [isNewPurposeBeingCreated, setIsNewPurposeBeingCreated] = useState(false);


    const {
        openModal,
        setOpenModal,
        selectedPurpose,
        setSelectedPurpose,
        purposesData,
        formikRef,
        loadPurposes,
        loading,
        firstLoad,
        justCreatedPurpose,
        setJustCreatedPurpose,
    } = usePurposesHook()

    const [tripsData, setTripsData] = useState<
        PaginatedTripsResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 25,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    })

    const handleFormClose = () => {
        formikRef.current?.dirty ? setDiscardDialog(true) : setOpenModal(false)
    }

    const loadMore = (id?: string, pageNo?: number) => {
        if (!selectedPurpose) return
        const rql = jsonToRql({
            purpose: id || selectedPurpose.id,
        })
        setTripsData((prev: PaginatedTripsResponse & LoadingStates) => ({
            ...prev,
            loading: true,
        }))

        getTrips({
            perPage: 25,
            page: pageNo!,
            rql,
        }).then((response: PaginatedTripsResponse | unknown) => {
            if (response) {
                const responsePayload = response as PaginatedTripsResponse
                const { itemsCount, page, perPage, pageCount, items } =
                    responsePayload
                setTripsData(
                    (prev: PaginatedTripsResponse & LoadingStates) => ({
                        ...prev,
                        items: prev.items.concat(items),
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    })
                )
            }
        })
    }

    const fetchTrips = useCallback(
        (id?: string) => {
            if (!selectedPurpose) return
            const rql = jsonToRql({
                purpose: id || selectedPurpose.id,
            })
            setTripsData((prev: PaginatedTripsResponse & LoadingStates) => ({
                ...prev,
                loading: true,
            }))

            getTrips({
                perPage: 25,
                page: 1,
                rql,
            }).then((response: PaginatedTripsResponse | unknown) => {
                if (response) {
                    const responsePayload = response as PaginatedTripsResponse
                    const { itemsCount, page, perPage, pageCount, items } =
                        responsePayload
                    setTripsData(
                        (prev: PaginatedTripsResponse & LoadingStates) => ({
                            ...prev,
                            items: items,
                            page: page,
                            pageCount: pageCount,
                            perPage: perPage,
                            itemsCount: itemsCount,
                            loading: false,
                            hasMore: page < pageCount,
                        })
                    )
                }
            })
        },
        [selectedPurpose]
    )

    const getDefaultPurpose = useCallback(() => {
        let purpose = undefined
        if (firstLoad) {
            const defaultPurpose = purposesData.filter(
                (item: Purpose) => item.is_default_mt_purpose
            )
            if (defaultPurpose.length === 1) {
                purpose = defaultPurpose[0]
            } else if (defaultPurpose.length === 0) {
                purpose = purposesData[0]
            }
        } else {
            purpose = justCreatedPurpose
        }

        // if (purpose?.id) {
        //     setSelectedPurpose(purpose)
        //     if(isMobileDevice){
        //         history.push(`/mileage/purposes/${purpose?.id}/trips`)
        //     }
        // }
    }, [
        firstLoad,
        history,
        justCreatedPurpose,
        purposesData,
        setSelectedPurpose,
    ])

    useEffect(() => {
        if (purposesData?.length) {
            getDefaultPurpose()
        }
    }, [getDefaultPurpose, purposesData])

    useEffect(() => {
        fetchTrips()
    }, [fetchTrips])


    useEffect(()=>{
        if(!openModal){
            setIsNewPurposeBeingCreated(false)
        }
    },[openModal])

    if (loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Loader />
            </Grid>
        )
    }

    return (
        <>
            <PurposeModal
                open={openModal}
                handleClose={handleFormClose}
                loadPurposes={() => loadPurposes()}
                formikRef={formikRef}
                purposeCallback={(purpose: Purpose) => {
                    setJustCreatedPurpose(purpose)
                    setSelectedPurpose(purpose)
                    setOpenModal(false)
                }}
                purpose={isNewPurposeBeingCreated ? undefined : selectedPurpose}
            />
            <DiscardChangesPurposeModal
                open={discardDialog}
                handleModalAction={setDiscardDialog}
                handleModalFormAction={setOpenModal}
            />
            <div className={classes.purposesContainer}>
                <div className={classes.purposesListContainer}>
                    <div className={classes.purposesListHeaderContainer}>
                        <div className={classes.purposesListHeader}>
                            <MobileHeader
                                title={'Purposes'}
                                showBottomDivider={true}
                            />
                            <Box my={2}>
                                <UiButton
                                    btnType="primary"
                                    label="New Purpose"
                                    handleClick={() => {
                                        setIsNewPurposeBeingCreated(true)
                                        setOpenModal(true)
                                    }}
                                    icon={<Icon icon="add" svgColor="white" />}
                                />
                            </Box>
                            <Box my={3}>
                                <UiText>
                                    {purposesData?.length} Saved Purposes
                                </UiText>
                            </Box>
                        </div>
                        <div className={classes.navItemsContainer}>
                            {purposesData?.length > 0 && (
                                <PurposesList
                                    loading={loading}
                                    data={purposesData ?? []}
                                    selectedPurpose={selectedPurpose}
                                    setSelectedPurpose={setSelectedPurpose}
                                    loadPurposes={loadPurposes}
                                    purposeCallback={(
                                        purpose: Purpose | null
                                    ) => {
                                        setJustCreatedPurpose(purpose!)
                                    }}
                                    setOpenModal={setOpenModal}
                                    fetchPurposeTrip={fetchTrips}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Hidden smDown>
                <Divider orientation="vertical" flexItem />
                <div className={classes.purposeDetailsContainer}>
                    {tripsData.loading ? <Loader/> :
                        <Grid item xs={12} md={12} justify="center">
                            {!selectedPurpose ? (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    className={classes.container}
                                >
                                    <UiText>
                                        Select a purpose to view the details
                                    </UiText>
                                </Grid>
                            ) : (
                                <>
                                    <UiText
                                        variant="hatchback_125"
                                        weight="medium_500"
                                        className={classes.titleMargin}
                                    >
                                        {selectedPurpose?.name}
                                    </UiText>
                                    <div className={classes.tripCount}>
                                        <Icon
                                            icon="mileage"
                                            customClass={
                                                classes.mileageIconMargin
                                            }
                                        />
                                        <UiText>
                                            {tripsData.items?.length < 2
                                                ? tripsData.items?.length +
                                                  ' Recorded Trip'
                                                : tripsData.items?.length +
                                                  ' Recorded Trips'}
                                        </UiText>
                                    </div>
                                    <Divider variant="inset" />
                                    <Grid item xs={12} md={12} justify="center">
                                            <TripList
                                                items={tripsData.items}
                                                itemsCount={
                                                    tripsData.itemsCount
                                                }
                                                isLoading={tripsData.loading}
                                                page={tripsData.page}
                                                pageCount={tripsData.pageCount}
                                                loadMore={loadMore}
                                                hasMore={tripsData.hasMore}
                                                selectedTrip={undefined}
                                                setSelectedTrip={() => {}}
                                                calledFromPurposes={true}
                                                selectedPurpose={selectedPurpose}
                                            />
                                    </Grid>
                                </>
                            )}
                        </Grid>}
                </div>
                </Hidden>
            </div>
        </>
    )
}

export default Purposes
