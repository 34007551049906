import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Background from '../../assets/bg-images/bg.svg'
import { toRem16 } from '../../styles/commonStyles'
import defaultLogo from '../../assets/logo/Logo.svg'
import CreateAccountForm from './forms/CreateAccountForm'
import { useStore } from 'react-redux'
import { ApplicationStore } from '../../models/store'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { Theme } from '@material-ui/core'
import { getPartnerLogo } from '../common/whiteLabel/updateThemeAttibutes'

const classes = makeStyles<Theme, ThemeColors>(() => {
    return {
        app: {
            display: 'flex',
            flex: 1,
            minHeight: 0,
            minWidth: 0,
        },
        createAccountParentContainer: {
            background: `url(${Background})`,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        createAccountContainer: {
            display: 'flex',
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            borderRadius: '1rem',
            minWidth: toRem16(400),
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        logoContainer: {
            display: 'flex',
            padding: `${toRem16(20)} 0`,
            borderBottom: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey200}`,
            width: '100%',
            justifyContent: 'center',
        },
    }
})
interface CreateAccountProps {
    missingBusinessFields: string[];
    onBusinessDataSubmitted : () => void;
}
export default function CreateAccount(props: CreateAccountProps) {
    const { colorTheme } = useThemeContext()
    const styles = classes(colorTheme)
    const { missingBusinessFields, onBusinessDataSubmitted } = props;
    const appStore: ApplicationStore = useStore().getState();
    const { user } = appStore.appData;
    const [logoPath, setLogoPath] = useState<string>('');
    
    useEffect(() =>{
        if(!user || !user.partner_details){
            setLogoPath(defaultLogo)
            return;
        }
        const partnerLogoUrl = getPartnerLogo(user.partner_details.partner_key ?? '');
        setLogoPath(partnerLogoUrl);
     },[logoPath, user])

    return (
        <div className={styles.app}>
            <div className={styles.createAccountParentContainer}>
                <div className={styles.createAccountContainer}>
                    <div className={styles.logoContainer}>
                        <img
                            src={logoPath}
                            alt="1800-Accountant logo"
                            height="40"
                        />
                    </div>
                    <CreateAccountForm
                        missingBusinessFields={missingBusinessFields}
                        onBusinessDataSubmitted = {onBusinessDataSubmitted}
                    />
                </div>
            </div>
        </div>
    )
}
