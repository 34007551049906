import { Action, SET_INIT_DATA } from '../actions/appData'

export default function appData(
    state = { user: { financial_pod: { tax_advisor: { accountant_id: '' } } } },
    action: Action
): any {
    switch (action.type) {
        case SET_INIT_DATA:
            return action.state
        default:
            return state
    }
}