import { Grid, Button, Box, makeStyles } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import AddIcon from '@material-ui/icons/Add'
import AppStore from '../../../assets/images/AppStore.svg'
import PlayStore from '../../../assets/images/PlayStore.svg'

const useStyles = makeStyles({
    mt2: {
        marginTop: '2rem',
    },
    mr2: {
        marginRight: '2rem',
    },
    textBox: {
        maxWidth: '40rem',
    },
})

const EmptyScreen = ({ openOverlay }: { openOverlay: () => void }) => {
    const classes = useStyles()
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignContent={'center'}
            justifyContent={'center'}
            flexWrap={'wrap'}
            minHeight={'80vh'}
        >
            <div className={classes.textBox}>
                <UiText variant="truck_175" textAlign="center">
                    Easily calculate your tax mileage deductions by tracking
                    your trips here
                </UiText>
            </div>
            <div className={classes.mt2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={openOverlay}
                >
                    Add Your First Trip
                </Button>
            </div>
            <div className={classes.mt2}>
                <UiText variant="car_100" textAlign="center">
                    Track your trips on the road with our mobile app.
                </UiText>
            </div>
            <div className={classes.mt2}>
                <a
                    href={
                        'https://apps.apple.com/us/app/1-800accountant/id1622560452'
                    }
                    rel="noreferrer noopener"
                    target="_blank"
                    className={classes.mr2}
                >
                    <img src={AppStore} alt="appStore" />
                </a>
                <a
                    href={
                        'https://play.google.com/store/apps/details?id=com.accountant1800.app'
                    }
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <img src={PlayStore} alt="playStore" />
                </a>
            </div>
        </Box>
    )
}

export default EmptyScreen
