import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    makeStyles,
    Theme,
    Box,
    Checkbox,
    ListItemIcon,
    Tooltip,
    IconButton,
    Button,
    Grid,
    Link,
    ListItemSecondaryAction,
    useMediaQuery,
    Hidden,
    useTheme,
} from '@material-ui/core';

import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { ITransaction } from '../../../models/transaction-models';
import { IBankAccount } from '../../../models/bank-account-models';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Illustration from '../../../assets/icons-svg/Illustration.svg';
import AddIcon from '@material-ui/icons/Add';
import { currencyFormatter } from '../../../utils/appUtil';
import { mergeStyle } from '../../../styles/commonStyles';
import { useStore } from 'react-redux';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface Props {
    items: ITransaction[];
    personalAccountId: string;
    searchResult?: boolean;
    selectedData?: any | null;
    onSelect: (data: any, id: string) => void;
    raw: any;
    selectedChekboxData: any;
    selectedBank?: IBankAccount | null;
    handleCheck: (checked: boolean, id: string) => void;
    checkedTransactions: string[];
    handleModalOpen: () => void;
    refState: any;
    isAdminLogin?: boolean;
    categories: any[];
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    amountText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: '-6px',
        },
        fontSize: '15px !important',
    },
    categoryText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 'normal',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '15px !important',

        [theme.breakpoints.down('sm')]: {
            width: '100px',
            position: 'relative',
            top: '6px',
        },
        [theme.breakpoints.down('md')]: {
            width: '130px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '250px',
        },
    },
    cardIcon: {
        width: '38px',
        left: '1px',
        opacity: '0.8',
        top: '7px',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    newMessageButton: {
        height: '36px',
        marginTop: theme.spacing(4),
    },
    emptyText: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
    },
    refeshButton: {
        fontSize: theme.spacing(3),
    },
    searchInput: {
        marginTop: theme.spacing(3),
        height: '40px',
        width: '100%',
    },
    archivedStatus: {
        marginRight: theme.spacing(3),
    },
    statusIcon: {
        position: 'relative',
        top: '3px',
        marginRight: theme.spacing(0),
    },
    selectedMessage: {
        background: (colorTheme) => `${colorTheme.green500} !important`,
    },
    messageBody: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    attachmentIconStyle: {
        fontSize: '15px',
        top: theme.spacing(0),
        position: 'relative',
    },
    subjectText: {
        maxWidth: 'calc(100% - 80px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    textMarkers: {
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        padding: theme.spacing('0', 1),
        borderRadius: '4px',
    },
    listItemDetail: {
        cursor: 'pointer',
        '& .MuiListItemText-primary': {
            maxWidth: '275px',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '175px',
            },
        },
    },
    categoryBox: {
        maxWidth: '30%',
    },
    infoIconRoot: {
        padding: '7px',
    },
    currencyColor: {
        color: (colorTheme) => colorTheme.blue600,
    },
    duplicateTransactionMarker: {
        border: (colorTheme) => `1px solid ${colorTheme.yellow100}`,
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        padding: theme.spacing('0', 1),
        borderRadius: '4px',
        backgroundColor: (colorTheme) => colorTheme.yellow100,
        fontWeight: 'bold',
        color: (colorTheme) => colorTheme.primaryBlack,
    },
    duplicateTransactionTooltip: {
        maxWidth: '230px',
        background: (colorTheme) => colorTheme.secondary,
        borderRadius: '4px ',
        padding: '9px 11px ',
        width: '230px',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        boxShadow: (colorTheme) => `0px 8px 10px ${colorTheme.grey1300}, 0px 3px 14px ${colorTheme.grey700}, 0px 5px 5px ${colorTheme.grey1400}`,
    },
}));

function TransactionsList(props: Props) {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const [dyHeigh, setDyHeight] = useState('calc(100vh - 610px)');
    const { checkedTransactions, handleCheck } = props;
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const store = useStore().getState();
    // useEffect(() => {
    //     if (props.refState) {
    //         setDyHeight(
    //             `calc(100vh - ${
    //                 props.refState.offsetHeight + (isSmallDevice ? 166 : 154)
    //             }px)`
    //         );
    //     }
    // }, [props.refState, checkedTransactions.length, isSmallDevice]);

    const getCategories = (transaction: ITransaction, businessCategories: any) => {
        let splittedCategories = transaction?.split;
        
        let categoryName = null;
        if(!transaction?.category_name){
            categoryName = businessCategories.find((item: any) => item.id === transaction?.category)?.title;
        }else{
            categoryName = transaction?.category_name;
        }
        return splittedCategories && splittedCategories.length > 0
            ? splittedCategories.map((cat) => cat.category_name).join(', ')
            : categoryName || 'Uncategorized';
    };

    const Transactions = (data: any, id?: string) => {
        return (
            <List
                component='div'
                // style={{ height: dyHeigh }}
                className={classes.listRoot}
            >
                {data.length === 0 ? (
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                        style={{ minHeight: '100%' }}
                    >
                        <img src={Illustration} alt='Illustration'></img>
                        <Typography
                            variant='subtitle1'
                            className={classes.emptyText}
                        >
                            {props.selectedBank?.type === 'manual' ||
                            props.selectedBank?.type === 'manual_group' ? (
                                props.raw?.params?.type === 'uncategorized' &&
                                props.selectedBank?.entries_count &&
                                props.selectedBank?.entries_count > 0 ? (
                                    'Congratulations! You have categorized all of your transactions.'
                                ) : (
                                    'No transactions have been added yet to this manual account.'
                                )
                            ) : (
                                props.raw?.params?.type === 'uncategorized' && 
                                !props.selectedBank?.need_activate &&
                                props.selectedBank?.entries_count &&
                                props.selectedBank?.entries_count > 0 ? (
                                    'Congratulations! You have categorized all of your transactions.'
                                ) : (
                                    <>
                                    No transactions have been synced yet from
                                    this connected bank account.
                                    <br />
                                    <span
                                        style={{
                                            position: 'relative',
                                            top: '24px',
                                        }}
                                    >
                                        <Link href='tel:1-800-222-6868'>
                                            Contact support
                                        </Link>{' '}
                                        if this problem persists.
                                    </span>
                                </>
                                )
                            )}
                        </Typography>
                        {props.selectedBank?.type === 'manual' ? (
                            <Button
                                className={classes.newMessageButton}
                                variant='contained'
                                color='primary'
                                startIcon={<AddIcon />}
                                onClick={props.handleModalOpen}
                            >
                                Transaction
                            </Button>
                        ) : (
                            ''
                        )}
                    </Grid>
                ) : (
                    <Box>
                        {data.map((element: ITransaction, index: number) => (
                            <Fragment key={index}>
                                <ListItem
                                    button
                                    classes={{
                                        gutters: classes.navListItemGutters,
                                        selected: classes.selectedMessage,
                                    }}
                                    alignItems='flex-start'
                                    selected={
                                        props.selectedData?.id === element.id
                                    }
                                    onClick={() => {
                                        props.onSelect(
                                            element,
                                            element.id as string
                                        );
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {props.selectedBank?.type !==
                                        'manual_group' && (
                                        <ListItemIcon>
                                            {element.can_use_bulk ? (
                                                <Checkbox
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) =>
                                                        handleCheck(
                                                            e.target.checked,
                                                            element.id as string
                                                        )
                                                    }
                                                    size='small'
                                                    inputProps={{
                                                        'aria-label':
                                                            'checkbox with small size',
                                                    }}
                                                    checked={checkedTransactions.includes(
                                                        element.id as string
                                                    )}
                                                />
                                            ) : (
                                                <Tooltip
                                                    title={
                                                        element.cannot_use_bulk_reason as string
                                                    }
                                                    arrow
                                                >
                                                    <IconButton
                                                        classes={{
                                                            root:
                                                                classes.infoIconRoot,
                                                        }}
                                                        aria-label='Info'
                                                        disableRipple={true}
                                                        disableFocusRipple={
                                                            true
                                                        }
                                                    >
                                                        <InfoOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        className={classes.listItemDetail}
                                        primary={
                                            <Fragment>
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    <Typography
                                                        noWrap
                                                        key={index}
                                                    >
                                                        {element.customer_name ||
                                                            'Unknown ' +
                                                                (+(element.amount as string) >
                                                                0
                                                                    ? 'Customer'
                                                                    : 'Payee')}
                                                    </Typography>
                                                </div>
                                            </Fragment>
                                        }
                                        secondary={
                                            <>
                                                <Box
                                                    component='span'
                                                    className={
                                                        classes.messageBody
                                                    }
                                                >
                                                    <Hidden smDown>
                                                        {moment(
                                                            (element.date as number) *
                                                                1000
                                                        ).format(
                                                            'DD MMM YYYY'
                                                        ) +
                                                            ' • ' +
                                                            `XXXX${element.bank_account?.slice(
                                                                -4
                                                            )}`}
                                                    </Hidden>
                                                    <Hidden mdUp>
                                                        {moment(
                                                            (element.date as number) *
                                                                1000
                                                        ).format('DD MMM YYYY')}
                                                    </Hidden>

                                                    {element.reconciliation ? (
                                                        <span
                                                            className={
                                                                classes.textMarkers
                                                            }
                                                        >
                                                            R
                                                        </span>
                                                    ) : null}
                                                    {element.is_transfer ? (
                                                        <span
                                                            className={
                                                                classes.textMarkers
                                                            }
                                                        >
                                                            T
                                                        </span>
                                                    ) : null}
                                                    {element.is_shadow ? ( 
                                                        <Tooltip
                                                       
                                                            enterTouchDelay ={0}
                                                            arrow
                                                            classes={{ tooltip: classes.duplicateTransactionTooltip }}
                                                            title={'Potential duplicate transaction'}
                                                        >
                                                            <span
                                                                className={
                                                                    classes.duplicateTransactionMarker
                                                                }
                                                            >
                                                                D
                                                            </span>
                                                        </Tooltip>                                                      
                                                    ) : null}
                                                </Box>
                                                <Hidden mdUp>
                                                    <div>
                                                        {`XXXX${element.bank_account?.slice(
                                                            -4
                                                        )}`}
                                                    </div>
                                                </Hidden>
                                            </>
                                        }
                                    ></ListItemText>
                                    <ListItemSecondaryAction
                                        onClick={() => {
                                            props.onSelect(
                                                element,
                                                element.id as string
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ListItemText
                                            secondary={
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            parseFloat(
                                                                element.amount ||
                                                                    '0'
                                                            ) > 0 ? mergeStyle(classes.amountText, classes.currencyColor) : classes.amountText
                                                        }
                                                        variant='caption'
                                                    >
                                                        {currencyFormatter.format(
                                                            parseFloat(
                                                                element.amount ||
                                                                    '0'
                                                            )
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        className={
                                                            classes.categoryText
                                                        }
                                                        variant='caption'
                                                    >
                                                        {getCategories(element, props.categories)}
                                                    </Typography>
                                                </div>
                                            }
                                        ></ListItemText>
                                    </ListItemSecondaryAction>
                                </ListItem>

                                {index === data.length - 1 ? (
                                    ''
                                ) : (
                                    <Divider variant='inset' />
                                )}
                            </Fragment>
                        ))}
                    </Box>
                )}
            </List>
        );
    };

    return <>{props.items ? Transactions(props.items) : null}</>;
}

export default TransactionsList;
