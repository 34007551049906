import {
    Grid,
    Hidden,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    List,
    makeStyles,
    Theme,
    IconButton,
    useTheme,
    useMediaQuery,
} from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { OnboardingStepsStatus } from '../../../models/adp-payroll/company'
import { commonStyles, mergeStyle, toRem16 } from '../../../styles/commonStyles'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import UiAlert from '../../common/ui/UiAlert'
import IconList from '../../common/IconList'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import CompanyCreation from './CompanyCreation'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { UnsavedSectionWarning } from '../../payroll/company/UnsavedSectionWarning'
import { useADPCompanyContext } from '../provider/CompanyProvider'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { OnboardingStepInfo } from '../../../models/adp-payroll/company'
import PayrollSchedule from './PayrollSchedule'
import FirstEmployee from './FirstEmployee/FirstEmployee'
import BankInformation from './BankInformation'
import Agreements from './Agreements/Agreements'
import Icon from '../../common/Icon'
import { ADPBankingInfoProvider } from '../provider/BankingInfoProvider'
import {
    AGREEMENTS,
    BANK_ACCOUNT_ADDITION,
    COMPANY_COMBINED_DATA_COLLECTION,
    FINISH_ONBOARDING,
    FIRST_EMPLOYEE,
    PAYROLL_FREQUENCY_ADDITION,
} from './company.const'
import SetupConfirmation from '../../payroll/company/SetupConfirmation'
import FinishOnboarding from '../../payroll/company/FinishOnabording'
import UiButton from '../../common/ui/UiButton'
import { getOnboardingStepInfo } from '../../../services/apiService/adp-payroll/company'
import { loadPostAuthData } from '../../../store/actions/appData'
import { useDispatch } from 'react-redux'
import SectionMobileHeader from './Mobile/SectionMobileHeader'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    selectedStep: {
        background: (colorTheme) => `${colorTheme.cyan100} !important`,
        borderRadius: theme.spacing(0),
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
    },
    incompleteNotice: {
        backgroundColor: (colorTheme) => colorTheme.beige100,
        borderRadius: theme.spacing(1),
    },
    arrowIcon: {
        marginRight: theme.spacing(2),
        color: (colorTheme) => colorTheme.blue200,
        minWidth: 0,
    },
    alertRootWidth: {
        maxWidth: '100%',
        marginTop: '1rem',
    },
    alertMessage: {
        width: '100%',
    },
    avatarStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        height: toRem16(40),
        width: toRem16(40),
        backgroundColor: (themeColors) => themeColors.teal200,
    },
    containerStyle: {
        height: '100%',
        overflow: 'auto',
    },
    gridHeight: {
        height: '100%',
    },
    completeIconStyle: {
        position: 'absolute',
        bottom: toRem16(4),
        left: toRem16(40),
    },
}))

const CompanyDetail = () => {
    const commonClasses = commonStyles()
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { currentConfig } = useCurrentStore()
    const companyDetailsSteps = currentConfig.local.adp_company_details_steps
    const dispatch = useDispatch()
    const {
        companyStepInfo,
        setRecallStepsData,
        isCompanyActive,
        isCompanyOnboarding,
        isAllStepsCompleted,
        formikRef,
    }: {
        companyStepInfo: OnboardingStepInfo
        setRecallStepsData: React.Dispatch<React.SetStateAction<boolean>>
        isCompanyActive: boolean
        isCompanyOnboarding: boolean
        isAllStepsCompleted: boolean
        formikRef: any
    } = useADPCompanyContext()

    const [currentStep, setCurrentStep] = useState<any>(
        'company_combined_data_collection'
    )
    const [recallData, setRecallData] = useState(false)
    const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState('')
    const [nextStep, setNextStep] = useState<any>('')
    const [openPayrollPopup, setOpenPayrollPopup] = useState(false)
    const [openOnboarding, setOpenOnboarding] = useState(false)
    const [hideCompleteNotice, setHideCompleteNotice] = useState(false)
    const companyOnboardingCurrentStep = companyStepInfo.current_step
    const statusSteps: OnboardingStepsStatus =
        companyStepInfo?.onboarding_steps_status
    const completedSteps = Object.keys(statusSteps).filter(
        (step: any) => statusSteps[step as keyof OnboardingStepsStatus]
    )
    const isStepsCompleted =
        completedSteps.length === Object.keys(statusSteps).length
    const isOnboardingAccepted = companyStepInfo?.adp_company_created

    const disableCompanyEdit = isCompanyOnboarding || isCompanyActive

    // mobile view
    const theme = useTheme()
    const [viewMenu, setViewMenu] = useState(true)
    const isSmDevice = useMediaQuery(theme.breakpoints.down('sm'))

    const showSection = isSmDevice ? !viewMenu : true
    const showMenu = isSmDevice ? viewMenu : true

    const fetchStepsData = useCallback(() => {
        setRecallData(true)
        getOnboardingStepInfo().then((res: OnboardingStepInfo) => {
            if (res) {
                setRecallData(false)
                if (res.adp_onboarding_status === 'Onboarding') {
                    setOpenOnboarding(true)
                }
                if (res.adp_onboarding_status === 'Active') {
                    setOpenPayrollPopup(true)
                    dispatch(loadPostAuthData())
                }
            }
        })
    }, [dispatch])

    useEffect(() => {
        isStepsCompleted || disableCompanyEdit
            ? setCurrentStep(AGREEMENTS)
            : setCurrentStep(companyOnboardingCurrentStep)
    }, [companyOnboardingCurrentStep, disableCompanyEdit, isStepsCompleted])

    const getStepMethod = (step: keyof OnboardingStepsStatus) =>
        companyStepInfo.onboarding_steps_status[step]
            ? goToNextSection
            : refreshSteps

    const goToPreviousSection = (bypassCheck?: any) => {
        if (formikRef.current?.dirty && bypassCheck !== true) {
            setShowUnsavedWarningModal('true')
        } else {
            setCurrentStep(companyDetailsSteps[currentStepIndex() - 1].id)
        }
    }

    const currentSection = () => {
        switch (currentStep as keyof OnboardingStepsStatus) {
            case COMPANY_COMBINED_DATA_COLLECTION:
                return (
                    <CompanyCreation
                        refreshSteps={getStepMethod(
                            COMPANY_COMBINED_DATA_COLLECTION
                        )}
                        goToPreviousSection={goToPreviousSection}
                    />
                )
            case PAYROLL_FREQUENCY_ADDITION:
                return (
                    <PayrollSchedule
                        refreshSteps={getStepMethod(PAYROLL_FREQUENCY_ADDITION)}
                        goBack={goToPreviousSection}
                    />
                )
            case BANK_ACCOUNT_ADDITION:
                return (
                    <ADPBankingInfoProvider
                        refreshSteps={getStepMethod(BANK_ACCOUNT_ADDITION)}
                    >
                        <BankInformation
                            refreshSteps={getStepMethod(BANK_ACCOUNT_ADDITION)}
                            goBack={goToPreviousSection}
                        />
                    </ADPBankingInfoProvider>
                )
            case FIRST_EMPLOYEE:
                return (
                    <FirstEmployee
                        refreshSteps={getStepMethod(FIRST_EMPLOYEE)}
                        goBack={goToPreviousSection}
                    />
                )
            case AGREEMENTS || FINISH_ONBOARDING:
                return (
                    <Agreements
                        refreshSteps={getStepMethod(AGREEMENTS)}
                        openPayrollPopup={() => {
                            setOpenPayrollPopup(true)
                        }}
                        refetchCompanyStatus={() => {
                            fetchStepsData()
                        }}
                        goBack={goToPreviousSection}
                    />
                )
        }
    }

    const currentStepIndex = () =>
        companyDetailsSteps.findIndex((value) => value.id === currentStep)

    const refreshSteps = () => {
        setRecallStepsData(true)
    }

    const goToNextSection = () => {
        setCurrentStep(companyDetailsSteps[currentStepIndex() + 1].id)
    }

    const handleOnSectionClick = (step: keyof OnboardingStepsStatus) => {
        if (step === currentStep) {
            isSmDevice && setViewMenu(false)
            return
        }
        if (formikRef?.current?.dirty) {
            setNextStep(step)
            setShowUnsavedWarningModal('true')
        } else {
            isSmDevice && setViewMenu(false)
            setCurrentStep(step)
        }
    }

    const handleUnsavedWanringModalAction = (
        action: 'close' | 'keep' | 'discard' | 'save'
    ) => {
        switch (action) {
            case 'close':
                setShowUnsavedWarningModal('')
                break
            case 'keep':
                setShowUnsavedWarningModal('')
                break
            case 'discard':
                nextStep ? setCurrentStep(nextStep) : goToPreviousSection(true) 
                setShowUnsavedWarningModal('')
                break
            case 'save':
                setShowUnsavedWarningModal('')
                formikRef.current?.submitForm()
                break
        }
    }

    const incompleteNotices = (
        <ListItem
            alignItems="flex-start"
            className={classes.incompleteNotice}
            data-cy="setup-complete-alert"
        >
            <ListItemIcon>{IconList['help']}</ListItemIcon>
            <ListItemText
                primary={
                    <UiText
                        weight="semi_bold_600"
                        style={{ letterSpacing: '0.5px' }}
                    >
                        Payroll setup is incomplete
                    </UiText>
                }
                secondary={
                    <Typography variant="body2" style={{ fontWeight: 400 }}>
                        In order for payroll to work, you must finish setting it
                        up by completing all required fields.
                    </Typography>
                }
            />
        </ListItem>
    )

    const completeNotice = (
        <UiAlert
            variant="outlined"
            severity="success"
            icon="checkCircleSmall"
            customStyles={{
                root: classes.alertRootWidth,
                message: classes.alertMessage,
            }}
            data-cy="setup-incomplete-alert"
            customContent={
                <>
                    <div
                        className={mergeStyle(
                            commonClasses.flex,
                            commonClasses.spaceBetween
                        )}
                    >
                        <UiText variant="car_100" weight="bold_700">
                            Payroll Setup is complete!
                        </UiText>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setHideCompleteNotice(true)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                    <UiText variant="motorcycle_90">
                        You can adjust the information below at any time
                    </UiText>
                </>
            }
        />
    )

    const previousSectionLink = (name: string) => (
        <ListItem
            classes={{
                root: commonClasses.cursorPointer,
                gutters: commonClasses.listItemZeroGutters,
            }}
            onClick={goToPreviousSection}
        >
            <ListItemIcon className={classes.arrowIcon}>
                <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <UiText
                        weight="semi_bold_600"
                        style={{
                            letterSpacing: '0.5px',
                            color: colorTheme.blue200,
                        }}
                    >
                        Previous Section
                    </UiText>
                }
                secondary={<UiText>{name}</UiText>}
            />
        </ListItem>
    )

    const RenderIcon = ({
        stepKey,
    }: {
        stepKey: keyof OnboardingStepsStatus
    }) => {
        let icon

        switch (stepKey) {
            case COMPANY_COMBINED_DATA_COLLECTION:
                icon = IconList['purpose']
                break
            case PAYROLL_FREQUENCY_ADDITION:
                icon = IconList['calendar']
                break
            case BANK_ACCOUNT_ADDITION:
                icon = IconList['bank']
                break
            case FIRST_EMPLOYEE:
                icon = IconList['user']
                break
            case AGREEMENTS:
                icon = IconList['journalEntry']
                break
        }

        return (
            <ListItemIcon>
                <div className={classes.avatarStyle}>{icon}</div>
            </ListItemIcon>
        )
    }

    return (
        <Grid container direction="row" className={classes.gridHeight}>
            {showMenu && (
                <Grid
                    item
                    md={5}
                    sm={12}
                    justify="flex-start"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    direction="column"
                    wrap="nowrap"
                    className={classes.containerStyle}
                >
                    {isOnboardingAccepted
                        ? hideCompleteNotice
                            ? null
                            : completeNotice
                        : incompleteNotices}
                    <div className={commonClasses.mt16}>
                        <Typography variant="body1">
                            {
                                Object.keys(
                                    companyStepInfo.onboarding_steps_status as OnboardingStepsStatus
                                ).filter(
                                    (key: string) =>
                                        // restrict the finish_onboarding step as it is not a section
                                        key !== 'finish_onboarding' &&
                                        companyStepInfo.onboarding_steps_status[
                                            key as keyof OnboardingStepsStatus
                                        ] === true
                                ).length
                            }{' '}
                            of 5 complete
                        </Typography>
                    </div>

                    <List
                        className={mergeStyle(
                            commonClasses.mt24,
                            commonClasses.fullWidth
                        )}
                    >
                        {companyDetailsSteps.map((step) => {
                            const isStepCompleted =
                                companyStepInfo?.onboarding_steps_status[
                                    step.id as keyof OnboardingStepsStatus
                                ]

                            return (
                                <ListItem
                                    key={step.id as keyof OnboardingStepsStatus}
                                    button
                                    classes={{
                                        selected: classes.selectedStep,
                                    }}
                                    selected={step.id === currentStep}
                                    style={{ height: '64px' }}
                                    onClick={() =>
                                        handleOnSectionClick(
                                            step.id as keyof OnboardingStepsStatus
                                        )
                                    }
                                    disabled={
                                        !isStepCompleted &&
                                        step.id !== currentStep &&
                                        step.id !== companyStepInfo.current_step
                                    }
                                    data-cy={
                                        isStepCompleted
                                            ? `${step.id}-completed`
                                            : `${step.id}-uncompleted`
                                    }
                                >
                                    <RenderIcon
                                        stepKey={
                                            step.id as keyof OnboardingStepsStatus
                                        }
                                    />
                                    <div className={classes.completeIconStyle}>
                                        {isStepCompleted && (
                                            <Icon
                                                icon="CompleteIconPrimary"
                                                svgColor={colorTheme.secondary}
                                            />
                                        )}
                                    </div>
                                    <ListItemText
                                        primary={
                                            <UiText
                                                variant="car_100"
                                                weight="medium_500"
                                            >
                                                {step.title}
                                            </UiText>
                                        }
                                    ></ListItemText>
                                </ListItem>
                            )
                        })}
                    </List>
                    {!isCompanyActive && isAllStepsCompleted && (
                        <UiAlert
                            variant="outlined"
                            severity="success"
                            icon="checkCircleSmall"
                            customStyles={{
                                root: classes.alertRootWidth,
                                message: classes.alertMessage,
                            }}
                            data-cy="setup-incomplete-alert"
                            customContent={
                                <>
                                    <div
                                        className={mergeStyle(
                                            commonClasses.flex,
                                            commonClasses.spaceBetween
                                        )}
                                    >
                                        <UiText variant="motorcycle_90">
                                            Company is not yet active in ADP
                                            platform. Please wait for the
                                            onboarding process to be completed!!
                                        </UiText>
                                    </div>
                                    <div style={{ marginTop: '1rem' }}>
                                        {recallData ? (
                                            <Loader />
                                        ) : (
                                            <UiButton
                                                btnType="tertiary"
                                                label="Check Status"
                                                handleClick={() => {
                                                    fetchStepsData()
                                                }}
                                            />
                                        )}
                                    </div>
                                </>
                            }
                        />
                    )}
                </Grid>
            )}

            <Hidden smDown implementation="js">
                <Grid
                    sm={1}
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.gridHeight}
                    />
                </Grid>
            </Hidden>
            {/**
             * this section only needs to be displayed on desktop view always and for mobile view
             * this section will be displayed when the viewMenu is set to false
             */}

            {showSection && (
                <Grid
                    item
                    md={6}
                    wrap="nowrap"
                    className={classes.gridHeight}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    {isSmDevice ? (
                        <SectionMobileHeader
                            menuHeader={
                                companyDetailsSteps.find(
                                    (step) => step.id === currentStep
                                )?.title as string
                            }
                            viewMenu={() => setViewMenu(true)}
                        />
                    ) : (
                        <Grid>
                            {currentStep !== COMPANY_COMBINED_DATA_COLLECTION
                                ? previousSectionLink(
                                      companyDetailsSteps[
                                          currentStepIndex() - 1
                                      ]?.title
                                  )
                                : null}
                        </Grid>
                    )}
                    {currentSection()}
                </Grid>
            )}

            <UnsavedSectionWarning
                open={showUnsavedWarningModal ? true : false}
                handleModalAction={handleUnsavedWanringModalAction}
                size="xs"
            />
            <SetupConfirmation
                open={openPayrollPopup}
                handleClose={() => {
                    setOpenPayrollPopup(false)
                    refreshSteps()
                }}
            />
            <FinishOnboarding
                open={openOnboarding}
                handleClose={() => {
                    setOpenOnboarding(false)
                    refreshSteps()
                }}
            />
        </Grid>
    )
}

export default CompanyDetail
