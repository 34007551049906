import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { HomeWorkOutlined, LocationOnOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { ApplicationStore } from '../../../models';
import UiSection from '../../common/ui/UiSection'

function RegisteredAgentSection({ agentDetails, state }: {
    agentDetails: {
        name: string;
        street: string;
        city: string;
        zip: string;
        county: string;
        suite: string;
    },
    state: string;
}) {
    const { name, street, city, zip, county, suite } = agentDetails

    return (
        <div>
            <UiSection
                sectionHeading={'Registered Agent'}
                expanded={true}
            >
                <List disablePadding style={{ marginLeft: -16 }}>
                    <ListItem alignItems="flex-start" dense>
                        <ListItemIcon>
                            <HomeWorkOutlined />
                        </ListItemIcon>
                        <ListItemText
                            secondary={name}
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start" dense>
                        <ListItemIcon>
                            <LocationOnOutlined />
                        </ListItemIcon>
                        <ListItemText
                            secondary={`${street || ''} ${suite || ''} ${city || ''} ${county || ''}, ${state || ''} ${zip || ''}`}
                        />
                    </ListItem>
                </List>
            </UiSection>
        </div>
    )
}

const mapStateToProps = ({ entityManagement }: ApplicationStore) => ({
    agentDetails: entityManagement.entityTab && entityManagement.entityTab.agentDetails,
    state: entityManagement.banner.formationState
});
export default connect(mapStateToProps)(RegisteredAgentSection);