import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
    ERRORS,
    EMPTY_PARAMS,
} from './constants/reports.const';
import { getGeneralLedgerReports } from '../../../services/apiService/reports';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    ApiError,
    BalanceSheetReportRoot,
    ErrorAlertReport,
} from './models/reports-interface';
import { reportsDataActions } from '../../../store/actions/reportsData';
import { getCurrentTimeStamp } from '../../../utils/dateUtil';
import { useReportsStyles } from './styles/reports-styles';
import InfoTooltip from '../../common/InfoTooltip';
import Loader from '../../common/Loader';
import { getTimeParamsWithoutEST } from '../../../utils/dateUtil';
import UiText from '../../common/ui/UiText';
import SelectDates from './utils/Date/SelectDates';
import { showError } from '../../../services/formService';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { FormObserver } from './utils/Form/FormObserver';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const chips = [
    {
        for: DATE_TYPES.THIS_MONTH,
        id: 1,
    },
    {
        for: DATE_TYPES.THREE_MONTHS,
        id: 2,
    },
    {
        for: DATE_TYPES.THIS_YEAR,
        id: 3,
    },
    {
        for: DATE_TYPES.ALL_TIME,
        id: 4,
    },
];
const TOOLTIP_TEXT = 'The General Ledger Report shows a complete record of all transactions on your account over the selected period of time.';
const initialFormValues = {
    from: moment.utc().hour(12).minute(0).second(0).unix(),
    to: moment.utc().hour(12).minute(0).second(0).unix(),
};

export default function GeneralLedger() {
    const history = useHistory();
    const storeData = useCurrentStore();
    const dispatch = useDispatch();
    const accountId = storeData.currentAccountId;
    const businessId = storeData.currentBusinessId!;
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const businessName = storeData.currentBusiness?.name;
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const [isHideFromDateAndToDate, setIsHideFromDateAndToDate] = useState<boolean>(false);

    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };

    useEffect(() => {
        updateDates({ for: selectedDateChip, id: 3 });
    }, []);

    const updateStartAndEndDates = (dateType: { for: string; id: any }) => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            dateType.for,
            true
        );
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    }

    const updateDates = (dateType: { for: string; id: any }): void => {
        setSelectedDateChip(dateType.for);
        updateStartAndEndDates(dateType);
        checkIfFromAndToDatesAreToBeHidden(dateType.for);
    };

    const fetchAPIQueryParams = (from: number, to: number) => {
        const start_date_ymd = from === 0 ? EMPTY_PARAMS.START_DATE_YMD : moment(from * 1000).format(DATE_FORMATS.ymdDate);
        const end_date_ymd = to === 0 ? EMPTY_PARAMS.END_DATE_YMD : moment(to * 1000).format(DATE_FORMATS.ymdDate);
        let periodText = (
            'for the period from ' +
            moment(from * 1000).format(DATE_FORMATS.periodText) +
            ' to ' +
            moment(to * 1000).format(DATE_FORMATS.periodText)
        ).replace(findSpaceRegex, '+');
        if(isHideFromDateAndToDate) {
            periodText = 'All Time'
        }
        const time = getCurrentTimeStamp();
        return {
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
        };
    };

    const checkIfFromAndToDatesAreToBeHidden = (dateType: string)=>{
        if(dateType === DATE_TYPES.ALL_TIME) {
            setIsHideFromDateAndToDate(true);
            return;
        }
        setIsHideFromDateAndToDate(false);
    }

    const navigateToGeneralLedgeDetailedReports = (
        start_date_ymd: string,
        end_date_ymd: string,
        periodText: string,
        time: number
    ) => {
        const detailedReportsUrl = `/reports/general-ledger/${start_date_ymd}/${end_date_ymd}/${periodText}/${time}`;
        history.push(detailedReportsUrl);
    };
    const dispatchReportsDataToStore = (data: any) => {
        dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };

    const generateGeneralLedgerReports = (data: any) => {
        const { start_date_ymd, end_date_ymd, periodText, time } =
            fetchAPIQueryParams(data.from, data.to);
        setIsLoading(true);
        getGeneralLedgerReports(
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd
        )
            .then((result: unknown) => {
                const responseData = result as BalanceSheetReportRoot;
                dispatchReportsDataToStore(responseData);
                navigateToGeneralLedgeDetailedReports(
                    start_date_ymd,
                    end_date_ymd,
                    periodText,
                    time
                );
                setIsLoading(false);
            })
            .catch((error: any) => {
                setIsLoading(false);
                handleError(error);
            });
    };

    const validationSchema = Yup.object({
        from: Yup.number().required(ERRORS.DATE),
        to: Yup.number().required(ERRORS.DATE),
    });

    const resetDateChips = () => {
        setSelectedDateChip('');
    };

    const onformChangeCallback = (values: any, formikObject : any) => {
        const fromDateChanged = formikObject.touched?.from;
        const toDateChanged = formikObject.touched?.to;
        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };

    const onSubmit = (values: any) => {
        generateGeneralLedgerReports(values);
    };

    return (
        <div className={reportsClasses.reportsViewParent}>
            <UiText className='businessName'>{businessName}</UiText>
            <UiText
                variant='suv_150'
                weight='semi_bold_600'
                className={reportsClasses.pageTitle}
            >
                General Ledger
                <InfoTooltip
                    tooltipText={
                        TOOLTIP_TEXT
                    }
                />
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                <Form>
                    <FormObserver onformChange={onformChangeCallback} />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <SelectDates
                                chips={chips}
                                handleUpdateDates={updateDates}
                                selectedChip={selectedDateChip}
                                isHideFromAndToDates={isHideFromDateAndToDate}
                                disableFutureDate
                            />
                            <Button
                                variant='contained'
                                type='submit'
                                color='primary'
                                data-cy='generate-report-btn'
                            >
                                Generate Report
                            </Button>
                        </>
                    )}
                </Form>
            </Formik>
        </div>
    );
}
