import moment from 'moment-timezone'

export const getCookie = (cookieName: string) => {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()

        if (cookie.indexOf(cookieName + '=') === 0) {
            return cookie.substring(cookieName.length + 1, cookie.length)
        }
    }

    return null
}

export const setCookie = (
    cookieName: string,
    cookieValue: string | number,
    expirationDays: number | null,
    expiryDate?: string
) => {
    let expires = ''
    if (expirationDays) {
        const expirationDate = moment()
            .tz('America/New_York')
            .add(expirationDays, 'days')
            .toDate()
        expires = 'expires=' + expirationDate.toUTCString()
    } else {
        const expirationDate = moment(expiryDate)
            .tz('America/New_York')
            .toDate()
        expires = 'expires=' + expirationDate.toUTCString()
    }

    // Set the cookie
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/'
}
