import { makeStyles, createStyles, Theme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import useDeviceSize from '../../hooks/useDeviceSize'
import { ApplicationStore } from '../../models/store'
import { loadAppointments } from '../../store/actions/appointmentEvents'
import { toRem16 } from '../../styles/commonStyles'
import Loader from '../common/Loader'
import UiText from '../common/ui/UiText'
import EventsTile from './Cards/EventsTile'
import NoDeadLineView from './emptyViews/NoDeadlineView'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'


const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        eventsDeadlinesContainer: {
            margin: `${toRem16(32)} 0`,
        },
        eventsDeadlinesContent: {
            border: colorTheme => `${toRem16(1)} solid ${colorTheme.grey300}`,
            borderRadius: toRem16(4),
            padding: `${toRem16(20)} ${toRem16(24)}`,
        },
        eventsDeadlinesHeader: {
            margin: '0 0 0.5rem 0',
        },
    })
)
interface EventsProps {
    heading?: string
    viewText?: string
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    loading: boolean
    events: any
    accountId: string
}
function UpcomingEventsAndDeadlines(props: EventsProps) {
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    const { loading, events, accountId } = props
    const dispatch = useDispatch()
    const { isMobileDevice } = useDeviceSize()

    useEffect(() => {
        dispatch(loadAppointments())
    }, [dispatch, accountId])

    return (
        <div className={classes.eventsDeadlinesContainer}>
            <UiText
                variant={isMobileDevice ? "hatchback_125" : "suv_150"}
                weight="semi_bold_600"
                className={classes.eventsDeadlinesHeader}
            >
                Upcoming Events & Deadlines
            </UiText>
            <div className={classes.eventsDeadlinesContent}>
                {loading ? (
                    <Loader />
                ) : events && events.length > 0 ? (
                    <EventsTile events={events} />
                ) : (
                    <NoDeadLineView />
                )}
            </div>
        </div>
    )
}
const mapStateToProps = ({ appointmentEvents, appData }: ApplicationStore) => ({
    loading: appointmentEvents.loading,
    events: appointmentEvents.events,
    accountId: appData.current_account_id
});
export const UpcomingEventdDeadlines = connect(mapStateToProps)(React.memo(UpcomingEventsAndDeadlines));