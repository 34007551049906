import { FormikValues } from 'formik';
import { _timestamp } from '../../../utils/dateUtil';
import moment from 'moment';
import { getDateRange, startOfDayTimeStamp, endOfDayTimeStamp } from '../../../utils/dateUtil';
import { invoiceTypeMapping } from './InvoiceUtil';
export const timeOptions = [
    'All Time',
    'This week',
    'This month',
    'Three months',
    'This year',
    'Custom',
];
export const refOptions = ['Contains', 'Equals'];
export const amountOptions = ['Greater Than', 'Less Than', 'Equals'];
export const amountMapping: any = {
    'Greater Than': 'minAmount',
    'Less Than': 'maxAmount',
    Equals: 'amount',
};
export const displayableChipKeys = [
    'term',
    'refNumber',
    'amount',
    'due_period',
    'invoice_period',
    'contact',
    'offering',
];

export const RefMapping: any = {
    Contains: 'numberLike',
    Equals: 'number',
};

export const formatFilterParams = (data: any) => {
    return {
        ...(data?.paymentStatus !== 'All Invoices'
            ? { paymentStatus: invoiceTypeMapping[data?.paymentStatus] }
            : null),
        ...(data?.contact ? { contact: data?.contact?.id } : null),
        ...(data?.offering ? { offering: data?.offering?.id } : null),
        ...(data?.amount
            ? { [amountMapping?.[data.amountType]]: data?.amount }
            : null),
        ...(data?.refNumber
            ? { [RefMapping?.[data.refType]]: data?.refNumber }
            : null),
        ...(data?.due_period !== 'All Time' ? { ...dateFilterValues("due_date", data?.due_period, data) } : null), 
        ...(data?.invoice_period !== 'All Time' ? { ...dateFilterValues("invoice_period", data?.invoice_period, data) } : null),
        ...(data?.term !== '' ? { term: data?.term } : null),    
    };
};

export const symbolMapping: any = {
    'Greater Than': '>',
    'Less Than': '<',
    Equals: '=',
};

export const formDefaultValues: any = {
    term: '',
    paymentStatus: 'All Invoices',
    contact: '',
    offering: '',
    due_period: 'All Time',
    invoice_period: 'All Time',
    refType: 'Contains',
    refNumber: '',
    amountType: 'Greater Than',
    amount: '',
    minDueDate: startOfDayTimeStamp,
    maxDueDate: endOfDayTimeStamp,
    minInvoiceDate: startOfDayTimeStamp,
    maxInvoiceDate: endOfDayTimeStamp,
};

const dateFilterValues: any = (dateType: string, period: string, data: any) => {
    const dates = dateType === 'due_date' ? getDateRange(period) : getDateRange(period, true)
    if(['This week','This month', 'Three months','This year'].includes(period)) {
        return dateType === 'due_date' ? {
            minDueDate: dates?.startDate,
            maxDueDate: dates?.endDate
         } : { 
            minInvoiceDate: dates?.startDate,
            maxInvoiceDate: dates?.endDate
        }
    } else {
        return dateType === 'due_date' ? {
            minDueDate: data?.minDueDate,
            maxDueDate: data?.maxDueDate
        } : { 
            minInvoiceDate: data?.minInvoiceDate,
            maxInvoiceDate: data?.maxInvoiceDate
        }
    }
}


export const formattedChipData = (
    key: string,
    data: { [field: string]: any }
) => {
    switch (key) {
        case 'refNumber':
            return {
                label: `Ref ${data['refType']} ${data[key]}`,
                key: key,
            };
        case 'amount':
            return {
                label: `Amount ${symbolMapping[data['amountType']]} $${
                    data[key]
                } `,
                key: key,
            };
        case 'due_period':
            return {
                label: `Due Date: ${data['due_period']} `,
                key: key,
            };
        case 'invoice_period':
            return {
                label: `Invoice Date: ${data['invoice_period']}`,
                key: key,
            };
        case 'contact':
            return {
                label: `Contact: ${data?.['contact']?.name} `,
                key: key,
            };
        case 'offering':
            return {
                label: `Product/Sevices: ${data?.['offering']?.title} `,
                key: key,
            };
        case 'term':
            return {
                label: `Term: ${data.term} `,
                key: key,
            };    
        default:
            return {
                label: key,
                key: key,
            };
    }
};

export const getFilterChips = (formData: FormikValues) => {
    let chips: { label: string; key: string }[] = [];
    Object.keys(formData).forEach((key) => {
        if (formData[key] !== formDefaultValues?.[key]) {
            if (displayableChipKeys.includes(key)) {
                const chipData = formattedChipData(key, formData);
                chips.push(chipData);
            }
        }
    });
    return chips;
};
