import UiText from './ui/UiText'
import { useCurrentStore } from './hooks/useCurrentStore'

export const BusinessDetails = () => {
    const { currentBusiness, currentConfig } = useCurrentStore()

    const getCountryName = (countryId: string): string | null => {
        const country = currentConfig.apiConfig.generic.countries.find(
            (item) => item.abbr === countryId
        )

        return country ? country.name : null
    }

    return (
        <div>
            <UiText weight="medium_500">
                {currentBusiness?.name} <br />
            </UiText>
            <UiText textColor="textSecondary">
                {currentBusiness.address?.address
                    ? `${currentBusiness.address.address}, `
                    : ''}
                {currentBusiness.address?.city
                    ? `${currentBusiness.address?.city}, `
                    : ''}
                {currentBusiness.address?.state
                    ? `${currentBusiness.address?.state}, `
                    : ''}
                {currentBusiness.address?.zip_code
                    ? `${currentBusiness.address?.zip_code}, `
                    : ''}
                {currentBusiness.address?.country
                    ? `${getCountryName(currentBusiness.address?.country)}.`
                    : ''}
            </UiText>
        </div>
    )
}
