import React from 'react';
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core'
import { COLORS } from '../../../../variables/colors'
import CloseIcon from '@material-ui/icons/Close'
import ThumbUpAltTwoToneIcon from '@material-ui/icons/ThumbUpAltTwoTone'
import UiText from '../../../common/ui/UiText'
import UiButton from '../../../common/ui/UiButton'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

interface ThankYouPageProps {
    handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void
    title?: string
    description?: string
    textCenter?: boolean
}

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    closeIconButton: {
        padding: 0,
        fontSize: '1.2rem',
    },
    successContainer: {
        width: '40rem',
        height: '22.5rem',
        background: (colorTheme) => colorTheme.primaryWhite,
        padding: '2rem 3rem',
        boxSizing: 'border-box',
        borderRadius: '0.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem 1rem',
        },
    },
    closeActionBtn: {
        padding: '0.56rem 3.75rem',
        marginTop: '1.5rem',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    thumbIcon: {
        fontSize: '4rem',
        color: (colorTheme) => colorTheme.tertiary,
    },
    thankYouPageContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerText: {
        margin: '1rem 0',
        color: (colorTheme) => colorTheme.black100,
    },
}))

const ThankYouPage = ({title = 'Thank you for completing your Form 8879', description = 'We will let you know when your taxes have beensubmitted.', handleClose, textCenter = false }: ThankYouPageProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme)
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={styles.successContainer}
        >
            <div className={styles.iconContainer}>
                <IconButton
                    onClick={handleClose}
                    aria-label="close"
                    classes={{ root: styles.closeIconButton }}
                    data-cy="cross-modal-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={styles.thankYouPageContent}>
                <ThumbUpAltTwoToneIcon className={styles.thumbIcon} />
                <Box mt={5}>
                    <UiText textAlign={textCenter ? 'center' : 'left'} variant="suv_150" weight="bold_700">
                        {title}
                    </UiText>
                    <UiText
                        textAlign={textCenter ? 'center' : 'left'}
                        className={styles.containerText}
                        variant="hatchback_125"
                        weight="regular_400"
                    >
                        {description}
                    </UiText>
                </Box>
                <UiButton
                    handleClick={handleClose}
                    customClass={styles.closeActionBtn}
                    btnType="tertiary"
                    label="Okay"
                />
            </div>
        </div>
    )
}

export default ThankYouPage
