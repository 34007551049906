import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppData, ApplicationStore, CompanyStepInfo } from '../../../models';
import { loadCompanyStepInfo, loadingCompanyStepInfo } from '../../../store/actions/company';
import { getESign, getOnboardingStepInfo } from '../../../services/apiService/payroll/company'
import Loader from '../../common/Loader';
import ProcessPayroll from '../ProcessPayroll'
import CompanyDetail from './CompanyDetail';
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

interface CompanySetupProps {
    appData: AppData;
    companyStepInfo: CompanyStepInfo;
}

function CompanySetup({ companyStepInfo }: CompanySetupProps) {
    const dispatch = useDispatch();
    const { currentBusinessId } = useCurrentStore()
    const [showCompanyDetail, setShowCompanyDetail] = useState(false);

    useEffect(() => {
        dispatch(loadingCompanyStepInfo());
        getOnboardingStepInfo(currentBusinessId as string).then((res: any) => {
            if(res) {
                if(res.current_step === 'company_creation') {
                    dispatch(loadCompanyStepInfo());
                } else {
                    /**
                     * If the E-sign document was signed already from gusto. 
                     * with the get document API call, we will internally mark this E-sign 
                     * step as  completed.
                    */
                    getESign(currentBusinessId as string).then((result) => {
                        if (result) {
                            dispatch(loadCompanyStepInfo());
                        }
                    })
                }
            }
        })
        return () => {
            dispatch({type: 'RESET_STEP_INFO'});
        }
    }, [dispatch, currentBusinessId]);

    return !companyStepInfo.loadedStepInfo ? (
        <Loader />
    ) : !showCompanyDetail &&
      companyStepInfo.companyOnboardingStepInfo.current_step ===
          'company_creation' ? (
        <ProcessPayroll finishSetup={() => setShowCompanyDetail(true)} />
    ) : (
        <CompanyDetail />
    );
}
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
});
export default connect(mapStateToProps)(CompanySetup);
