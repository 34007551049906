import Icon from '../../common/Icon'
import UiText from '../../common/ui/UiText'
import InfoCard from '../Cards/InfoCard'
import { useStyles } from './BookKeepingInfoCard'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const Upload1040InfoCard = ({
    onButtonClick,
}: {
    onButtonClick: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconStyle}>
                    <Icon icon="companyCircle" />
                </div>
                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <UiText variant="hatchback_125" weight="medium_500">
                            Upload a previous personal tax return (Form 1040)
                        </UiText>
                    }
                    subtitle={
                        <UiText variant="motorcycle_90">
                            Clients who upload their return save an average of
                            13% more on their taxes and spend 38 minutes less
                            per year on tax preparation.
                        </UiText>
                    }
                    buttonText={'Upload Form 1040'}
                    buttonClick={onButtonClick}
                />
            </div>
        </div>
    )
}

export default Upload1040InfoCard
