import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getProductCategories } from '../../../services/apiService/products'
import { ProductCategory, formattedCategoryType } from '../../../models'

export const ProductServiceContext = createContext(null)

export function useProductServiceContextState(): any {
    const state = useContext(ProductServiceContext)
    return state
}

export const ProductServicesProvider = ({ children }: any) => {
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([])
    const [formattedProdCategoryObj, setFormattedProdCategoryObj] = useState<formattedCategoryType>()
    const [refetchData, setRefetchData] = useState<boolean>(false)
    const [fetchInProgress, setFetchInProgress] = useState<boolean>(false)


    const fetchCategories = useCallback(()=> {
        getProductCategories().then((result: any) => {
            if (result) {
                let formattedCategory: formattedCategoryType = {}

                result.forEach((category: any) => {
                    formattedCategory = { ...formattedCategory, [category.id]: category.title }
                    return formattedCategory
                })
                setProductCategories(result)
                setFormattedProdCategoryObj(formattedCategory)
                setFetchInProgress(false)
                setRefetchData(false)
            }
        })
    },[])

    useEffect(()=> {
        fetchCategories()
    },[fetchCategories])

    useEffect(()=> {
        if(refetchData) {
            setFetchInProgress(true)
            fetchCategories() 
        }
    },[refetchData, fetchCategories])

    const providerValue: any = {
        productCategories,
        setProductCategories,
        formattedProdCategoryObj,
        setFormattedProdCategoryObj,
        refetchData,
        setRefetchData,
        fetchInProgress,
        setFetchInProgress
    }

    return (
        <ProductServiceContext.Provider value={providerValue}>
            {children}
        </ProductServiceContext.Provider>
    )
}

