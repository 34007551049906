import React from 'react';
import { Button, Typography, makeStyles, Theme} from '@material-ui/core'
import UiText from '../../common/ui/UiText';
import { toRem16 } from '../../../styles/commonStyles';
import Icon from '../../common/Icon';
import useDeviceSize from '../../../hooks/useDeviceSize';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';


const useStyles = makeStyles<Theme, ThemeColors>(()=> {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            marginBottom: '1rem',
        },
        imageStyle: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            borderRadius: '0px 0.5rem 0.5rem 0px;'
        },
        buttonStyle: {
            marginTop: toRem16(8)
        },
        dismissBtn: {
            marginTop: '1rem', 
            color: colorTheme => colorTheme.blue200
        },
        mainTextContent: {
            padding: '0 4% 0 4%', 
            marginTop: '1rem', 
            marginBottom: '0.125rem'
        },
        divStyle: {
            display: 'grid',
            gridTemplateColumns: '1fr 15rem'
        },
        divWithoutImage: {
            display: 'grid',
            gridTemplateColumns: `1fr ${toRem16(8)}`
        },
        subTitleStyle: {
            fontSize: '14px',
            fontWeight: 500,
            marginTop: toRem16(12),
            marginBottom: toRem16(12),
        }
    }
})

interface AdvertisementCardProps {
    title?: string | React.ReactNode,
    subtitle?: string | React.ReactNode,
    buttonText?: string,
    customTitle?: string | React.ReactNode,
    buttonClick?: () => void
    onDismissClick?: () => void,
    buttonIcon?: any
    cardImage?: any

}

const AdvertisementCard = ({
    title,
    subtitle,
    customTitle,
    buttonText,
    buttonClick,
    buttonIcon,
    cardImage,
    onDismissClick
}: AdvertisementCardProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()

    return (
        <div className={styles.container}>
            <div className={ isMobileDevice ? '' : (cardImage ? styles.divStyle :  styles.divWithoutImage)}>
                <div className={styles.mainTextContent}>
                    {customTitle}
                    {title && <UiText variant='hatchback_125' weight='medium_500' >{title}</UiText>}
                    {subtitle && <Typography 
                        color='textSecondary' 
                        className={styles.subTitleStyle}>
                            {subtitle}
                    </Typography>}
                    <Button 
                        color="primary" 
                        variant='contained' 
                        startIcon={buttonIcon}
                        className={styles.buttonStyle}
                        onClick={buttonClick}
                    >
                        {buttonText}
                    </Button>
                    <div>
                        <Button 
                            startIcon={<Icon icon='clock' strokeColor={colorTheme.blue200}/>} 
                            variant="text"
                            className={styles.dismissBtn}
                            onClick={onDismissClick}
                            >
                            Dismiss
                        </Button>
                    </div>
                </div>
                { !isMobileDevice && cardImage && <img src={cardImage} className={styles.imageStyle} alt="cardImage"></img>}
            </div>
        </div>
        
    )
}

export default AdvertisementCard