import React from 'react'
import { dismissBlock } from '../AccountSetupItems/AccountSetupUtility'
import { UENI_AD_BLOCK } from '../TodoSection/Todo.const'
import UeniAdImage from '../../../assets/images/UeniAdImage.png'
import { Button, makeStyles, Theme} from '@material-ui/core'
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import Icon from '../../common/Icon';

const useStyles = makeStyles<Theme, ThemeColors>(()=> {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            border: colorTheme => `0.063rem solid ${colorTheme.grey200}`,
            borderRadius: '0.5rem',
            marginBottom: '1rem',
        },
        imageStyle: {
            width: '100%',
            display: 'block',
            borderRadius: '0.5rem 0.5rem 0rem 0rem;',
            cursor: 'pointer'
            
        },
        dismissBtn: {
            marginTop: '1rem', 
            color: colorTheme => colorTheme.blue200
        },
        mainTextContent: {
            padding: '0 0 0 1.4rem', 
            marginBottom: '0.125rem'
        },
        divStyle: {
            justifyContent: 'center', /* centers items horizontally */
            alignItems: 'center' /* centers items vertically */

        }
    }
})

const UeniAd = ({
    reload,
}: {
    reload: () => void
}) => {
    const pageLink = `https://ueni.sjv.io/1800accountant`
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    return (
        <div className={styles.container}>
            <div className={styles.divStyle}>
                <img  
                    onClick={() => {
                            window.open(pageLink, '_blank');
                            }} 
                    src={UeniAdImage} 
                    className={styles.imageStyle} 
                    alt="cardImage">
                </img>
                <div className={styles.mainTextContent}>
                
                    <div>
                        <Button 
                            startIcon={<Icon icon='clock' strokeColor={colorTheme.blue200}/>} 
                            variant="text"
                            className={styles.dismissBtn}
                            onClick={() => {
                                reload()
                                dismissBlock(UENI_AD_BLOCK)
                            }}
                            >
                            Dismiss
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UeniAd