import { Button, makeStyles, Theme } from '@material-ui/core'
import { useState } from 'react'
import { COLORS } from '../../../../variables/colors'
import Icon from '../../../common/Icon'
import UiText from '../../../common/ui/UiText'
import SectionView from '../../../payroll/common/SectionView'
import { contractorInfo } from './DetailsUtility'
import { AddContractorModal } from '../../AddContractorModal'
import { Contractor } from '../../models/contractor.model'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    headerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    hyperTextColor: {
        color: (colorTheme) => `${colorTheme.blue200}`,
    },
    sectionName: {
        marginTop: '0.5rem',
    },
}))

const PersonalInformation = ({
    contractorData,
    reloadData,
    setSelectedContractor,
}: {
    contractorData: Contractor
    reloadData: () => void
    setSelectedContractor?: (data: any) => void
}) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const sectionInfo = contractorInfo(contractorData)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <div>
            <SectionView
                key={'Personal Details '}
                headerNode={
                    <div className={classes.headerStyle}>
                        <UiText
                            variant="hatchback_125"
                            weight="medium_500"
                            className={classes.sectionName}
                        >
                            Information
                        </UiText>
                        <Button
                            startIcon={
                                <Icon icon="edit" svgColor={COLORS.blue200} />
                            }
                            variant="text"
                            onClick={() => setIsOpen(true)}
                            className={classes.hyperTextColor}
                        >
                            {' '}
                            Edit
                        </Button>
                    </div>
                }
                disableSection={false}
                sectionInfoData={sectionInfo}
            />
            <AddContractorModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isEdit
                initialData={contractorData}
                reloadData={reloadData}
                setSelectedContractor={setSelectedContractor}
            />
        </div>
    )
}

export default PersonalInformation
