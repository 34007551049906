import React, { Fragment } from 'react'
import { makeStyles, Divider, Tooltip, Theme } from '@material-ui/core'
import UiText from '../../../components/common/ui/UiText'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import { currencyFormatter } from '../../../utils/appUtil'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useClasses = makeStyles<Theme, ThemeColors>(() => ({
    withoutTaxColumn: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '20px 5px',
    },
    withTaxColoumn: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gap: '20px 5px',
    },
    priceInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '24px',
        width: '250px',
    },
    iconStyle: {
        fill: (colorTheme) => colorTheme.secondary,
        marginTop: "3px",
        marginLeft: "4px",
        height: "16px",
        cursor: 'pointer'
    }
}))
const ProductDetailsTable = ({
    products,
    showTaxColumn,
    invoice,
}: {
    products: any
    showTaxColumn: boolean
    invoice: any
}) => {
    const { colorTheme } = useThemeContext()
    const classes = useClasses(colorTheme)
    const styles = commonStyles()
    return (
        <div className={styles.mt16}>
            <div
                className={
                    showTaxColumn
                        ? mergeStyle(classes.withTaxColoumn, styles.mb16)
                        : mergeStyle(classes.withoutTaxColumn, styles.mb16)
                }
            >
                <UiText className={styles.textLeft}> Item </UiText>
                <UiText className={styles.textRight}>QTY</UiText>
                <UiText className={styles.textRight}>Price</UiText>
                {showTaxColumn && (
                    <UiText className={styles.textRight}>Tax Rate</UiText>
                )}
                <UiText className={styles.textRight}>Total</UiText>
            </div>
            <Divider />
            <div className={styles.mt16}>
                {products.map((product: any, index: number) => (
                    <Fragment key={product?.id}>
                        <div
                            className={
                                showTaxColumn
                                    ? mergeStyle(
                                          classes.withTaxColoumn,
                                          styles.mtb16
                                      )
                                    : mergeStyle(
                                          classes.withoutTaxColumn,
                                          styles.mtb16
                                      )
                            }
                        >
                            <UiText className={mergeStyle(styles.textLeft, styles.flex)}>
                                {product?.offering_data?.title}
                                {product?.offering_data?.description && <div>
                                    <Tooltip title={product?.offering_data?.description} placement="top" arrow>
                                        <InfoOutlinedIcon 
                                            fontSize="small" 
                                            classes={{
                                                root: classes.iconStyle
                                            }}
                                        />
                                    </Tooltip>
                                </div>}
                                
                            </UiText>
                            <UiText className={styles.textRight}>
                                {product?.offering_quantity}
                            </UiText>
                            <UiText className={styles.textRight}>
                                ${product?.offering_data?.price}
                            </UiText>
                            {showTaxColumn && (
                                <UiText className={styles.textRight}>
                                    {product?.offering_data?.tax_rate}%
                                </UiText>
                            )}
                            <UiText className={styles.textRight}>
                                ${product.amount}
                            </UiText>
                        </div>
                        <Divider />
                    </Fragment>
                ))}
            </div>
            <div className={mergeStyle(styles.flex, styles.flexEnd)}>
                <div className={classes.priceInfo}>
                    <div>
                        {showTaxColumn && (
                            <>
                                <UiText className={mergeStyle(styles.mt16)}>
                                    Total before tax
                                </UiText>
                                <UiText className={mergeStyle(styles.mt8)}>
                                    Tax due
                                </UiText>
                            </>
                        )}
                        <UiText
                            variant="hatchback_125"
                            className={mergeStyle(styles.mt16)}
                        >
                            Total Due
                        </UiText>
                    </div>
                    <div>
                        {showTaxColumn && (
                            <>
                                <UiText
                                    className={mergeStyle(
                                        styles.textRight,
                                        styles.mt16
                                    )}
                                >
                                    ${invoice.subtotal}
                                </UiText>
                                <UiText
                                    className={mergeStyle(
                                        styles.textRight,
                                        styles.mt8
                                    )}
                                >
                                    ${invoice.tax_total}
                                </UiText>
                            </>
                        )}

                        <UiText
                            variant="hatchback_125"
                            weight="medium_500"
                            className={mergeStyle(
                                styles.textRight,
                                styles.mt16
                            )}
                        >
                            {currencyFormatter.format(invoice?.total)}
                        </UiText>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailsTable
