import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import { LandingPage } from '../contractor/file-1099-necs/LandingPage'
import ManageContractors from '../contractor/Contractor/ManageContractors'
import { ContractorProvider } from '../contractor/ContractorProvider'
import ContractorDetails from '../contractor/Contractor/ContractorDetails/ContractorDetails'

export const ContractorRoutes: React.FC = () => {
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()

    const Wrapper: React.FC<any> = ({ children }) => (
        <Container className={classes.container} maxWidth="lg">
            {children}
        </Container>
    )

    return (
        <Switch>
            <ContractorProvider>
                <>
                    <Hidden mdUp>
                        <Route path="/contractors/file-1099-nec" exact>
                            <Wrapper>
                                <LandingPage />
                            </Wrapper>
                        </Route>
                        <Route path="/contractors/manage" exact>
                            <Wrapper>
                                <ManageContractors />
                            </Wrapper>
                        </Route>
                        <Route path="/contractors/manage/:id" exact>
                            <Wrapper>
                                <ContractorDetails />
                            </Wrapper>
                        </Route>
                    </Hidden>
                    <Hidden smDown>
                        <Route path="/contractors/file-1099-nec" exact>
                            <Wrapper>
                                <LandingPage />
                            </Wrapper>
                        </Route>
                        <Route path="/contractors/manage" exact>
                            <Wrapper>
                                <ManageContractors />
                            </Wrapper>
                        </Route>
                    </Hidden>
                </>
            </ContractorProvider>
        </Switch>
    )
}
