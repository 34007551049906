import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

type UiModalProps = {
    open: boolean
    handleClose: (e: React.MouseEvent<HTMLButtonElement>) => void
    children: React.ReactNode
}

export default function UiModal({ open, handleClose, children }: UiModalProps) {
    const classes = useStyles()
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <>{children}</>
        </Modal>
    )
}
