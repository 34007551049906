import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AmountField from '../transactions/AmountField'
import { showAlert } from '../../../store/actions/feedback'
import { ApplicationStore, Config } from '../../../models'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import AddressForm from '../../common/AddressForm'
import Loader from '../../common/Loader'
import { BankAccount } from '../../../models/bankAccount'
import { formSubmitErrorHandler } from '../../../services/formService'
import {
    createBankAccount,
    updateBankAccount,
} from '../../../services/apiService/bankAccount'
import { loadCategories } from '../../../store/actions/categories'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

let defaultValues: any = {
    id: '',
    number: 0,
    name: '',
    account: '',
    account_type: '',
    type: '',
    balance: 0,
    excluded_balance: 0,
    entries_count: 0,
    historical_loaded: false,
    is_key: false,
    currency: 'USD',
    need_activate: true,
    bank_details: {
        name: '',
        website: '',
        description: '',
        phone: '',
        address: {
            address: '',
            city: '',
            country: 'US',
            county: '',
            state: '',
            zip_code: '',
        },
    },
}

const NewBankForm: React.FC<{
    config: Config
    accountId: string
    businessId: string
    isNew: boolean
    bankData: any
    handleClose: (fetchApi: any) => void
}> = ({
    config: { apiConfig: config },
    businessId,
    accountId,
    isNew,
    bankData,
    handleClose
}): ReactElement => {
    const dispatch = useDispatch()
    const timeout: any = useRef()
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            subHeading: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            fieldContainer: {
                marginBottom: theme.spacing(4),
            },
            fieldTitle: {
                color: colorTheme.black200,
                opacity: '0.6',
            },
            createAccountButton: {
                width: '100%',
                marginTop: '22px',
                height: '36px',
            },
            businessLogo: {
                width: '60%',
                height: 'auto',
                marginBottom: theme.spacing(1),
                padding: theme.spacing(3),
                border: '1px solid',
                borderColor: colorTheme.grey2300,
            },
            caption: {
                color: colorTheme.black200,
                opacity: '0.6',
            },
            avatar: {
                backgroundColor: theme.palette.primary.main,
                color: colorTheme.primaryWhite,
            },
            deleteBtn: {
                fontSize: '1em',
                color: theme.palette.error.main,
            },
            deleteRedBtn: {
                fontSize: '1em',
                background: theme.palette.error.main,
                color: colorTheme.primaryWhite,
            },
            stepContainer: {
                minHeight: 'auto',
            },
            blockHeight: {
                height: '350px',
                width: '100%',
            },
        }
    })()

    const [loading, setLoading] = useState(false)
    const [steps, setAddSteps] = useState([1])
    const history = useHistory()

    let initialValues = isNew ? defaultValues : {...bankData, currency: 'USD'}

    const handleFormSubmit = (values: BankAccount, { setFieldError }: any) => {
        setLoading(true)
        let apiCall = isNew
            ? createBankAccount(accountId, businessId, values)
            : updateBankAccount(accountId, businessId, bankData.id, values)
        formSubmitErrorHandler(
            apiCall.then((response: any) => {
                if (response) {
                    setLoading(false)
                    if(isNew) {
                        history.push('/bank_accounts');
                    }
                    handleClose(true)
                    dispatch(
                        showAlert({
                            alertText: `${
                                isNew
                                    ? 'Bank account created successfully'
                                    : 'Bank account updated successfully'
                            }`,
                            alertType: 'success',
                        })
                    )
                    dispatch(loadCategories());
                }
            }),
            () => {
                setLoading(false)
            },
            setFieldError
        )
    }

    const validationSchema = Yup.object({
        name: Yup.string().required('Account name is required'),
        account_type: Yup.string().required('Account type is required'),
    })

    useEffect(() => {
        if (steps.includes(2) || steps.includes(3)) {
            timeout.current = setTimeout(() => {
                document
                    ?.getElementById(`step-${steps[steps.length - 1]}`)
                    ?.scrollIntoView({ behavior: 'smooth' })
            }, 100)
        }
    }, [steps])

    useEffect(() => {
        return () => {
            clearInterval(timeout.current)
        }
    }, [])

    return <>
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnMount={true}
        >
            {({
                submitForm,
                handleSubmit,
                submitCount,
                values,
                isValid,
            }) => (
                <div>
                    {/* STEP 1 - Account details */}
                    {steps.includes(1) && (
                        <Grid container spacing={1}>
                            <Typography
                                variant="h6"
                                className={classes.subHeading}
                            >
                                Account Details
                            </Typography>

                            <Grid
                                item
                                md={12}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Account Name (Required)"
                                    type="text"
                                    fieldName="name"
                                    required={true}
                                    errorMessage="Required"
                                    showFloatingLabel={true}
                                    cypressId="acc-name"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Account Type (Required)"
                                    type="select"
                                    optionsData={
                                        config.bank_account.account_types
                                    }
                                    optionKey="name"
                                    optionValue="id"
                                    fieldName="account_type"
                                    required={true}
                                    errorMessage="Required"
                                    data-cy="acc-type"
                                ></UiFormControlSelection>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Account Number (Optional)"
                                    type="text"
                                    fieldName="number"
                                    cypressId="bank-acc-number"
                                    showFloatingLabel={true}
                                ></UiFormControlSelection>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <Field
                                    component={AmountField}
                                    name={`balance`}
                                    fieldname={`balance`}
                                    value={values.balance}
                                    disabled={!isNew}
                                ></Field>
                                <Typography variant="caption">
                                    As of Today
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Currency (Required)"
                                    type="text"
                                    disabled={true}
                                    fieldName="currency"
                                    showFloatingLabel={true}
                                    defaultValue="USD"
                                ></UiFormControlSelection>
                            </Grid>
                            {!steps.includes(2) && (
                                <div className={classes.blockHeight}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!isValid}
                                        onClick={() => {
                                            setAddSteps(steps.concat(2))
                                        }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            )}
                        </Grid>
                    )}

                    {/* STEP 2 - Bank details */}
                    {steps.includes(2) && (
                        <Grid
                            container
                            spacing={1}
                            style={{ marginTop: '20px' }}
                        >
                            <Typography
                                variant="h6"
                                className={classes.subHeading}
                            >
                                Bank Details
                            </Typography>

                            <Grid
                                item
                                md={12}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Bank Name"
                                    type="text"
                                    fieldName="bank_details.name"
                                    errorMessage="Required"
                                    showFloatingLabel={true}
                                    cypressId="bank-name"
                                ></UiFormControlSelection>
                            </Grid>

                            <Grid
                                item
                                md={7}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Url (Optional)"
                                    type="text"
                                    fieldName="bank_details.website"
                                    showFloatingLabel={true}
                                    cypressId="bank-web"
                                ></UiFormControlSelection>
                            </Grid>
                            <Grid
                                item
                                md={5}
                                xs={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Phone Number"
                                    type="mask"
                                    fieldName="bank_details.phone"
                                    mask={[
                                        '(',
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                        ')',
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                        '-',
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                        /[0-9]/,
                                    ]}
                                    showFloatingLabel={true}
                                    minLength={10}
                                ></UiFormControlSelection>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.fieldContainer}
                            >
                                <UiFormControlSelection
                                    label="Bank Description"
                                    type="text"
                                    fieldName="bank_details.description"
                                    errorMessage="Required"
                                    showFloatingLabel={true}
                                    cypressId="bank-desc"
                                ></UiFormControlSelection>
                            </Grid>
                            {!steps.includes(3) && (
                                <div style={{ height: '350px' }}>
                                    <Button
                                        id="step-2"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            setAddSteps(steps.concat(3))
                                        }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            )}
                        </Grid>
                    )}

                    {/* STEP 3 - Bank Address details */}
                    {steps.includes(3) && (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    className={classes.subHeading}
                                >
                                    Bank Address
                                </Typography>
                                <AddressForm
                                    fieldName="bank_details.address"
                                    showCountry={true}
                                ></AddressForm>
                            </Grid>
                            <div className={classes.blockHeight}>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <Button
                                        id="step-3"
                                        className={
                                            classes.createAccountButton
                                        }
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={
                                            submitCount > 0 && !isValid
                                        }
                                        data-cy={`${isNew ? 'create' : 'update'}-acc-btn`}
                                    >
                                        {isNew
                                            ? 'Create Account'
                                            : 'Update account'}
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    )}
                </div>
            )}
        </Formik>
    </>;
}
const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
    accountId: state.appData.current_account_id,
    businessId: state.appData.current_business_id,
})
export default connect(mapStateToProps)(NewBankForm)
