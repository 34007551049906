import { useState } from 'react';
import {
    makeStyles,
    Theme,
    Collapse,
    IconButton,
    Typography,
    Link,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import AdvertisingModal from './AdvertisingModal'
import MerchantAccountModal from './MerchantAccount/MerchantAccountModal'
import RegistrationConfirmedModal from './MerchantAccount/RegisterationConfirmedModal'
import NewtekModal from './MerchantAccount/NewtekModal'
import { getFromLocalStorage } from '../../../../services/localStorageService'
import { ApplicationStore, AppData } from '../../../../models'
import {
    getCurrentNewtekMerchantUrl
} from '../../../../services/apiService/business'

const useStyles = makeStyles((theme: Theme) => ({
    alertStyles: {
        margin: '10px 0px 10px 0px',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    alertRootStyle: {
        borderRadius: '8px'
    }
}))
const BannerComponent = ({ appData }: { appData: AppData }) => {
    const styles = useStyles()
    const [open, setOpen] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [openBusinessForm, setOpenBusinessForm] = useState(false)
    const [openRegConfirmedModal, setOpenRegConfirmedModal] = useState(false)
    const [embedUrl, setEmbedUrl] = useState('')
    const [embedNewtelModal, setEmbedNewtekModal] = useState(false)
    const [newtekMerchantApproved, setNewtekMerchantApproved] = useState(false)
    const newtekMerchantAlreadyExists = 'A Merchant already exists.';
    let merchantBannerValue = getFromLocalStorage(
        'hide_merchant_account_banner'
    )

    const showLocalStorageBanner = () => {
        let currentAcc = appData.current_account
        if (currentAcc) {
            let isAppliedForMerchantForm =
                !currentAcc.business ||
                currentAcc.has_applied_for_payrix_merchant ||
                currentAcc.has_applied_for_newtek_merchant
            return isAppliedForMerchantForm ? false : !merchantBannerValue
        }
    }

    const getUrl = () => {
        getCurrentNewtekMerchantUrl(appData.current_account_id, appData.current_business_id)
        .then((res: any) => {
            if(res.message && res.message === newtekMerchantAlreadyExists){
                setNewtekMerchantApproved(true)
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    return (
        <div>
            <AdvertisingModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                hideAlert={() => {
                    setOpen(false)
                }}
                openBusinsessModal={() => {
                    setOpenBusinessForm(true)
                }}
            />
            <MerchantAccountModal
                open={openBusinessForm}
                handleClose={(type: boolean) => setOpenBusinessForm(type)}
                openSuccessModal={() => setOpenRegConfirmedModal(true)}
                handleGetEmbedUrl={(url: any) => {
                    if(url && url != ''){
                        window.open(url)
                    }
                }}
                
            />
            <RegistrationConfirmedModal
                open={openRegConfirmedModal}
                handleClose={() => {
                    setOpenRegConfirmedModal(false)
                }}
            />
            <NewtekModal
                open={embedNewtelModal}
                handleClose={() => {
                    getUrl()
                    setEmbedNewtekModal(false)
                }}
                url={embedUrl}
            />
            {(showLocalStorageBanner() && !newtekMerchantApproved) && (
                <div className={styles.alertStyles}>
                    <Collapse in={open}>
                        <Alert
                            classes={{ root: styles.alertRootStyle}}
                            icon={false}
                            severity="warning"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        onClick={() => {
                                            setOpen(true)
                                        }}
                                    />
                                </IconButton>
                            }
                        >
                            <Typography>
                                Get your invoices paid faster with merchant
                                processing.{' '}
                                <Link
                                    className={styles.cursorPointer}
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                >
                                    Learn More
                                </Link>
                            </Typography>
                        </Alert>
                    </Collapse>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
})

export default connect(mapStateToProps)(BannerComponent)
