import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

function WidgetBox(props: any) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            widgetRoot: {
                border: `1px solid ${colorTheme.grey200}`,
                borderRadius: '8px',
                width: '100%',
                position: 'relative',
                minHeight: '25rem'
            },
            widgetHeader: {
                borderBottom: `1px solid ${colorTheme.grey200}`,
                padding: theme.spacing(2, 4)
            },
            widgetBody: {
                padding: theme.spacing(3, 4, 4),
                height: props.height,
                [theme.breakpoints.down('sm')]: {
                    height: 'fit-content'
                    
                }
            },
        };
    })();

    return (
        <Fragment>
            <div className={classes.widgetRoot}>
                <div className={classes.widgetHeader}>
                    <Grid
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                    >
                        <Typography variant="body1">{props.heading}</Typography>
                        {props.actionControl}
                    </Grid>
                </div>
                <div className={classes.widgetBody}>{props.children}</div>
            </div>
        </Fragment>
    );
}

export default WidgetBox;
