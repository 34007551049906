import {
    Action,
    LOADING_CATEGORIES,
    SET_CATEGORIES,
    RESET_CATEGORIES,
} from '../actions/categories';

let defaultState = { loading: false, loaded: false, categories: [] };

export default function category(state = defaultState, action: Action): any {
    switch (action.type) {
        case SET_CATEGORIES:
            return action.state;
        case LOADING_CATEGORIES:
            return { ...state, loading: true };
        case RESET_CATEGORIES:
            return defaultState;
        default:
            return state;
    }
}
