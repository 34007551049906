import { getFromLocalStorage } from './localStorageService';
import store from '../store';

let isCanViewCachedVal: any = {};

export function isCanView(route: any) {

    if (isCanViewCachedVal[route.acl] === undefined) {
        isCanViewCachedVal[route.acl] = isCanViewCompute(route);
    }

    return isCanViewCachedVal[route.acl];
}

export function isCanViewCompute(route: any) {
    if (route && route.acl) {
        if (route.acl === '*') {
            return true;
        } else if (typeof route.acl === 'string') {
            // Format 'A|B^C' ==> (A || B ) & !C
            var acl = route.acl;
            let { auth } = store.getState();
            var userRoles = auth.roles;
            //check all exclusions first
            if (acl.indexOf('^') !== -1) {
                var not_allow = acl.substr(acl.indexOf('^')).split('^');
                acl = acl.substr(0, acl.indexOf('^'));
                for (var n = 0; n < not_allow.length; n++) {
                    if (userRoles.indexOf(not_allow[n]) !== -1) {
                        return false;
                    }
                }
            }

            var allow = acl.split('|');
            for (var i = 0; i < allow.length; i++) {
                if (userRoles.indexOf(allow[i]) !== -1) {
                    return true;
                }
            }
            return false;
        }
    } else {
        console.log('Warning: acl roles not set for route', route);
        return true;
    }
}

export function clearCache() {
    isCanViewCachedVal = {};
}

let permissions_data: any = {
    ALL_BOOKKEEPING: 'ALL_BOOKKEEPING',
    EXTENDED_STORAGE: 'EXTENDED_STORAGE',

    VTO2014_PERSONAL: 'VTO2014_PERSONAL',
    VTO2014_BUSINESS: 'VTO2014_BUSINESS',

    VTO2015_PERSONAL: 'VTO2015_PERSONAL',
    VTO2015_BUSINESS: 'VTO2015_BUSINESS',

    VTO2016_PERSONAL: 'VTO2016_PERSONAL',
    VTO2016_BUSINESS: 'VTO2016_BUSINESS',

    BASIC_BOOKKEEPING: 'BASIC_BOOKKEEPING',

    VTO2017_PERSONAL: 'VTO2017_PERSONAL',
    VTO2017_BUSINESS: 'VTO2017_BUSINESS',
    SCHEDULE_E_2017_QUANTITY: 'SCHEDULE_E_2017_QUANTITY',

    VTO2018_PERSONAL: 'VTO2018_PERSONAL',
    VTO2018_BUSINESS: 'VTO2018_BUSINESS',
    SCHEDULE_E_2018_QUANTITY: 'SCHEDULE_E_2018_QUANTITY',
    SCHEDULE_C_2018_QUANTITY: 'SCHEDULE_C_2018_QUANTITY',

    VTO2019_PERSONAL: 'VTO2019_PERSONAL',
    VTO2019_BUSINESS: 'VTO2019_BUSINESS',
    SCHEDULE_E_2019_QUANTITY: 'SCHEDULE_E_2019_QUANTITY',
    SCHEDULE_C_2019_QUANTITY: 'SCHEDULE_C_2019_QUANTITY',

    VTO2020_PERSONAL: 'VTO2020_PERSONAL',
    VTO2020_BUSINESS: 'VTO2020_BUSINESS',
    SCHEDULE_E_2020_QUANTITY: 'SCHEDULE_E_2020_QUANTITY',
    SCHEDULE_C_2020_QUANTITY: 'SCHEDULE_C_2020_QUANTITY',

    VTO2021_PERSONAL: 'VTO2021_PERSONAL',
    VTO2021_BUSINESS: 'VTO2021_BUSINESS',
    SCHEDULE_E_2021_QUANTITY: 'SCHEDULE_E_2021_QUANTITY',
    SCHEDULE_C_2021_QUANTITY: 'SCHEDULE_C_2021_QUANTITY',

    TAX_EXTENSION2014_PERSONAL: 'TAXEXTENSION2014_PERSONAL',
    TAX_EXTENSION2014_BUSINESS: 'TAXEXTENSION2014_BUSINESS',
    TAX_EXTENSION2015_PERSONAL: 'TAXEXTENSION2015_PERSONAL',
    TAX_EXTENSION2015_BUSINESS: 'TAXEXTENSION2015_BUSINESS',
    TAX_EXTENSION2016_PERSONAL: 'TAXEXTENSION2016_PERSONAL',
    TAX_EXTENSION2016_BUSINESS: 'TAXEXTENSION2016_BUSINESS',
    TAX_EXTENSION2017_PERSONAL: 'TAXEXTENSION2017_PERSONAL',
    TAX_EXTENSION2017_BUSINESS: 'TAXEXTENSION2017_BUSINESS',
    TAX_EXTENSION2018_PERSONAL: 'TAXEXTENSION2018_PERSONAL',
    TAX_EXTENSION2018_BUSINESS: 'TAXEXTENSION2018_BUSINESS',
    TAX_EXTENSION2019_PERSONAL: 'TAXEXTENSION2019_PERSONAL',
    TAX_EXTENSION2019_BUSINESS: 'TAXEXTENSION2019_BUSINESS',
    TAX_EXTENSION2020_PERSONAL: 'TAXEXTENSION_PERSONAL',
    TAX_EXTENSION2020_BUSINESS: 'TAXEXTENSION_BUSINESS',
    ESTIMATED_TAXES: 'ESTIMATED_TAXES',
    FRANCHISE_TAX: 'FRANCHISE_TAX',
    ENTITY_FORMATION: 'ENTITY_FORMATION',
    ENTITY_MANAGEMENT: 'ENTITY_MANAGEMENT',
    TAX_ADVISORY: 'TAX_ADVISORY',
    EIN: 'EIN',
    ANNUAL_REPORT: 'ANNUAL_REPORT',
    PAYROLL: 'PAYROLL',
    VTO_BUSINESS: 'VTO_BUSINESS',
    VTO_PERSONAL: 'VTO_PERSONAL',
    STATE_TAX_FILING: 'STATE_TAX_FILING'
};

function getUserAccessRights(account: any) {
    let { appData } = store.getState();
    var currentAccount = account ? account : appData.current_account;
    return currentAccount ? currentAccount.access_rights : [];
}

export function getPermission(name: string, account: any) {
    return (
        getUserAccessRights(account).find(
            (ar: any) => ar.permission === permissions_data[name]
        ) || {}
    );
}

export function hasPermission(name: string, account: any) {
    var permission = getUserAccessRights(account).find(
        (ar: any) => ar.permission === permissions_data[name]
    );
    if (!permission) {
        //console.log('Warning: permission not found ', name);
        return false;
    } else {
        return permission.enabled;
    }
}

export function isLead() {
    var user = getFromLocalStorage('user');
    return user && user.is_lead;
}

export function isOnPage(page: string) {
    return window.location.hash.indexOf(`#${page}`) === 0;
}



export function isLinkVisible(pageObj: any) {
    let { appData: { current_business_id, current_business, user, accountant } } = store.getState();

    if(pageObj?.name === 'Payroll' || ['/payroll/company','/payroll/employees'].includes(pageObj.page)) {
        return (user?.accountant_mode || current_business?.enable_payroll_left_nav)
    }

    if ((!(user?.accountant_mode && accountant) && pageObj.admin_only) ||
        (pageObj.page === '/draft-returns' && !current_business_id)) {
        return false;
    }

    if (pageObj.page === '/tax-organizer/tax-document-import') {
        return isOnPage(pageObj.page);
    }

    // - OEHA-20649 / OEHA-20718 / OEHA-21756 /OEHA-21857
    if (pageObj.page === '/tax-extensions/business' || pageObj.page === '/tax-extensions/personal') {
        return false
    }

    if (pageObj.page === '/reports/squarespace') {
        return !!(current_business && current_business.squarespace_info && current_business.squarespace_info.website_id);
    }

    if (pageObj.page === '/draft-returns') {
        return !isLead() && user.show_draft_returns_nav && !(current_business.business_type === 'C' || current_business.business_type === 'NON_PROFIT' || current_business.business_type === 'LLC_C');
    }

    if (pageObj.page === '/entity-management' && !current_business.id) {
        return false;
    }

    return true;
}
