import React, { RefObject } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import UiFormControlSelection from '../../../../common/ui/UiFormControlSelection'
import UiText from '../../../../common/ui/UiText'
import UiButton from '../../../../common/ui/UiButton'
import UiDialog from '../../../../common/ui/UiDialog'
import Loader from '../../../../common/Loader'
import { FormData } from '../../models/vehicle.model'
import { toRem16 } from '../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { VINInput } from './input-template/VINInput'

type SubmitFormActionType<Values> = (
    values: Values,
    formikHelpers: FormikHelpers<Values>
) => void
interface FormProps {
    initialFormValues: FormData
    handleFormSubmit: (form: FormData) => void
    validationSchema: any
    mileageYears: number[] | undefined
    showModal: boolean
    onModalClose: (isOpen: boolean) => void
    isSubmittingInfo: boolean
    formRef: RefObject<FormikProps<FormData>>
    isEditMode: boolean
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        formRoot: {
            '& .MuiFormControlLabel-root': {
                display: 'flex',
                flex: 1,
            },
        },
        checkboxInputs: {
            '& .MuiFormControlLabel-root': {
                display: 'flex',
                flex: 1,
            },
        },
        defaultVehicleText: {
            margin: '0 0 0 2rem',
        },
        inputFields: {
            margin: `${toRem16(24)} 0 0 0`,
            '& .MuiTextField-root': {
                maxWidth: '65%',    
                display: 'flex',
                flex: 1,
                margin: `0 0 ${toRem16(24)} 0`,
            },
        },
        optionalText: {
            color: (colorTheme) => colorTheme.grey400
        },
        descriptionInput: {
            maxWidth: '100% !important',
        },
        odometerReadingsContainer: {
            margin: `${toRem16(40)} 0 0 0`,
            '& .MuiTextField-root': {
                maxWidth: '65%',
                display: 'flex',
                flex: 1,
                margin: `0 0 ${toRem16(24)} 0`,
            },
        },
        odometerReadingsHeader: {
            margin: `0 0 ${toRem16(24)} 0`,
            display: 'inline-flex',
        },
        loader: {
            padding: '0 2rem 0 0',
        },
        actionBtnSave: {
            margin: '0 0 0 1rem !important',
        },
        dialogContainer: {
            '& .MuiDialogActions-root': {
                minHeight: toRem16(45),
            },
        },
    })
)

export const AddEditVehicleModal = (props: FormProps) => {
    const {
        initialFormValues,
        handleFormSubmit,
        validationSchema,
        mileageYears,
        showModal,
        onModalClose,
        isSubmittingInfo,
        formRef,
        isEditMode,
    } = props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    let submitFormAction: SubmitFormActionType<FormData>
    
    const onFormSubmit = (form: FormData) => {
        handleFormSubmit(form)
    }

    const getActionButtons = (): React.ReactNode => {
        return (
            <>
                {isSubmittingInfo ? (
                    <Loader
                        justifyContent="flex-end"
                        customClass={classes.loader}
                    />
                ) : (
                    <>
                        <UiButton
                            handleClick={() => {
                                onModalClose(false)
                            }}
                            label="Cancel"
                            btnType="secondary"
                        />
                        <UiButton
                            handleClick={(e) => submitFormAction(e, e)}
                            label={isEditMode ? 'Save Changes' : 'Save Vehicle'}
                            btnType="primary"
                            customClass={classes.actionBtnSave}
                        />
                    </>
                )}
            </>
        )
    }
    return (
        <UiDialog
            open={showModal}
            handleClose={() => {
                onModalClose(false)
            }}
            title={`${isEditMode ? 'Edit Vehicle' : 'New Vehicle'}`}
            size="sm"
            actions={getActionButtons()}
            customRootClass={classes.dialogContainer}
        >
            <Formik
                initialValues={initialFormValues}
                onSubmit={onFormSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
                className={classes.formRoot}
                innerRef={formRef}
            >
                {({ handleSubmit }) => {
                    submitFormAction =
                        handleSubmit as unknown as SubmitFormActionType<FormData>
                    return (
                        <>
                            <div className={classes.checkboxInputs}>
                                <UiFormControlSelection
                                    inlineLable="Active"
                                    type="checkbox"
                                    fieldName="activeVehicle"
                                />
                                <div>
                                    <UiFormControlSelection
                                        inlineLable="Default Vehicle"
                                        type="checkbox"
                                        fieldName="defaultVehicle"
                                    />

                                    <UiText
                                        weight="regular_400"
                                        variant="moped_75"
                                        className={classes.defaultVehicleText}
                                    >
                                        New trips will automatically select this vehicle.
                                    </UiText>
                                </div>
                            </div>
                            <div className={classes.inputFields}>
                                <UiFormControlSelection
                                    label={'Make (Required)'}
                                    type="text"
                                    fieldName={'make'}
                                    required={true}
                                    errorMessage={'Required'}
                                    showFloatingLabel={true}
                                />
                                <UiFormControlSelection
                                    label={'Model (Required)'}
                                    type="text"
                                    fieldName={'model'}
                                    required={true}
                                    errorMessage={'Required'}
                                    showFloatingLabel={true}
                                />
                                <UiFormControlSelection
                                    label={'Year (Required)'}
                                    type="number"
                                    fieldName={'year'}
                                    required={true}
                                    errorMessage={'Required'}
                                    showFloatingLabel={true}
                                />
                                <UiFormControlSelection
                                    label={'License Plate (Optional)'}
                                    type="text"
                                    fieldName={'licensePlate'}
                                    required={false}
                                    showFloatingLabel={true}
                                />
                                <VINInput 
                                label={'VIN (Optional)'}
                                fieldName={'vin'}
                                showFloatingLabel={true}
                                maxLength={17} />

                                <UiFormControlSelection
                                    label={'Description (Optional)'}
                                    type="textarea"
                                    fieldName={'description'}
                                    required={false}
                                    showFloatingLabel={true}
                                    customClass={classes.descriptionInput}
                                />
                            </div>

                            {mileageYears && (
                                <div
                                    className={
                                        classes.odometerReadingsContainer
                                    }
                                >
                                    <UiText
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                        className={
                                            classes.odometerReadingsHeader
                                        }
                                    >
                                        Odometer Readings &nbsp;{' '}
                                        <UiText
                                            className={classes.optionalText}
                                        >
                                            (Optional)
                                        </UiText>
                                    </UiText>
                                    {mileageYears &&
                                        mileageYears.map(
                                            (year: number) => {
                                                return (
                                                    <UiFormControlSelection
                                                        key={year}
                                                        label={`Mileage Year ${year}`}
                                                        type="number"
                                                        fieldName={`mileageYear${year}`}
                                                        showFloatingLabel={true}
                                                        required={false}
                                                    />
                                                )
                                            }
                                        )}
                                </div>
                            )}
                        </>
                    )
                }}
            </Formik>
        </UiDialog>
    )
}
