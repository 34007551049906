import { useState } from 'react';
import store from '../../../store'
import { showAlert } from '../../../store/actions/feedback'
import type { TripQueryObject, TripVehicle } from '../../../models/trips'
import { Purpose } from '../../../models/purposes';
import { Location } from '../../../models/locations';

export const timeOptions = [
    'All Time',
    'This Week',
    'This Month',
    'Three Months',
    'This Year',
    'Custom',
]

export const milesOptions = ['Greater Than', 'Less Than', 'Equals']

export const refOptions = ['Contains', 'Equals']

export const activityOptions: string[] = [
    'Business',
    'Personal',
    'Medical',
    'Moving',
    'Charity',
]

export const defaultQueryObject: TripQueryObject = {
    term: '',
    vehicle: '',
    location: '',
    activity: '',
    purpose: '',
    milesType: 'Greater Than',
    miles: '',
    period: '' as const,
    dateFrom: '',
    dateTo: '',
    refNumberType: 'Contains',
    refNumber: '',
}

export const displayableChipKeys = [
    'term',
    'refNumber',
    'vehicle',
    'location',
    'period',
    'activity',
    'purpose',
    'miles',
]

export const symbolMapping = {
    'Greater Than': '>',
    'Less Than': '<',
    Equals: '=',
}

export const formattedChipData = (
    key: keyof TripQueryObject,
    data: { [field: string]: any }
) => {
    switch (key) {
        case 'refNumber':
            return {
                label: `Reference Number ${data.refNumberType} ${data.refNumber}`,
                key: key,
            }
        case 'vehicle':
            let label = `Vehicle: ${data.vehicle.make} ${data.vehicle.model} ${data.vehicle.year}`
            if (data.vehicle.license_plate_number) {
                label.concat(` (${data.vehicle.license_plate_number})`)
            }
            return {
                label,
                key: key,
            }
        case 'location':
            return {
                label: `Location: ${data.location.name}`,
                key: key,
            }
        case 'period':
            return {
                label: `Period: ${data.period}`,
                key: key,
            }
        case 'activity':
            return {
                label: `Activity: ${data.activity}`,
                key: key,
            }
        case 'purpose':
            return {
                label: `Purpose: ${data.purpose.name}`,
                key: key,
            }
        case 'term':
            return {
                label: `Search by: ${data.term}`,
                key: key,
            }
        case 'miles':
            const milesType = data.milesType as keyof typeof symbolMapping
            return {
                label: `Miles ${symbolMapping[milesType]} ${data.miles}`,
                key: key,
            }
        default:
            return {
                label: key,
                key: key,
            }
    }
}

export const resetParams = (key: keyof TripQueryObject) => {
    switch (key) {
        case 'period':
            return {
                period: defaultQueryObject.period,
                dateFrom: defaultQueryObject.dateFrom,
                dateTo: defaultQueryObject.dateTo,
            }
        case 'miles':
            return {
                milesType: defaultQueryObject.milesType,
                miles: defaultQueryObject.miles,
            }
        case 'refNumber':
            return {
                refNumberType: defaultQueryObject.refNumberType,
                refNumber: defaultQueryObject.refNumber,
            }
        default:
            return { [key]: '' }
    }
}

export const resetParams2 = (key: keyof TripQueryObject) => {
    return defaultQueryObject[key] || { [key]: '' }
}

export const getFilterChips = (formData: TripQueryObject) => {
    let chips: { label: string; key: keyof TripQueryObject }[] = []
    Object.keys(formData).forEach((key) => {
        const typedKey = key as keyof TripQueryObject
        if (
            formData[typedKey] &&
            formData[typedKey] !== defaultQueryObject?.[typedKey]
        ) {
            if (displayableChipKeys.includes(typedKey)) {
                const chipData = formattedChipData(typedKey, formData)
                chips.push(chipData)
            }
        }
    })
    return chips
}

export type MilesTypesMappingType = {
    [key: string]: string
}

export type Chip = {
    label: string
    key: keyof TripQueryObject
}

export const MilesTypesMapping: MilesTypesMappingType = {
    'Greater Than': 'gt',
    'Less Than': 'lt',
    Equals: 'eq',
}

export const snackbar = (type: 'success' | 'error', text: string) => {
    store.dispatch(
        showAlert({
            alertText: text,
            alertType: type,
        })
    )
}
export const AutoCompleteFieldCreatedValues = () => {
    const [createdStartLocation,setCreatedStartLocationState] = useState<Location|undefined>(undefined);
    const [createdDestination,setCreatedDestinationState] = useState<Location|undefined>(undefined);
    const [createdVehicle,setCreatedVehicleState] = useState<TripVehicle|undefined>(undefined);
    const [createdPurpose,setCreatedPurposeState] = useState<Purpose|undefined>(undefined);

    // Create setter functions that mimic the useState setter functionality
    const setCreatedStartLocation = (newLocation: Location | undefined) => {
        setCreatedStartLocationState(newLocation)
    };

    const setCreatedDestination = (newDestination: Location | undefined) => {
        setCreatedDestinationState(newDestination)

    };

    const setCreatedVehicle = (newVehicle: TripVehicle | undefined) => {
        setCreatedVehicleState(newVehicle)

    };

    const setCreatedPurpose = (newPurpose: Purpose | undefined) => {
        setCreatedPurposeState(newPurpose)
    };

    return {
        createdStartLocation: createdStartLocation,
        createdDestination: createdDestination,
        createdVehicle: createdVehicle,
        createdPurpose: createdPurpose,
        setCreatedStartLocation,
        setCreatedDestination,
        setCreatedVehicle,
        setCreatedPurpose
    }
}

export type AutoCompleteFieldCreatedValuesType = ReturnType<typeof AutoCompleteFieldCreatedValues>



export function fixedTo2Decimal(num:number) {
    return num % 1 === 0 ? num.toString() : num.toFixed(2);
}