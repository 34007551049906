import { useContext, useEffect } from 'react';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import { getFromLocalStorage } from '../../services/localStorageService';

export default function UpdatePaymentMethod() {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);

    useEffect(() => {
        setActiveRouteHeading('Update Payment Method');
    });

    const iframe = () => {
        const url = getFromLocalStorage("paymentMethodUpdaterUrl")
        return {
            __html: `<iframe src="${url}" width="100%" height="100%"></iframe>`,
        };
    };
    return (
        <div
            style={{ height: '100%', overflow: 'hidden' }}
            dangerouslySetInnerHTML={iframe() as any}
        ></div>
    );
}
