import { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid, Box, Button, Hidden, IconButton } from '@material-ui/core'
import {
    Add,
    InsertDriveFileSharp,
    ClearSharp,
    InsertPhoto,
    PictureAsPdf,
    InfoOutlined,
} from '@material-ui/icons'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useStore } from 'react-redux'
import Loader from '../../../components/common/Loader'
import {
    deleteFile,
    searchDocuments,
    uploadFilesToFolder,
} from '../../../services/apiService/document'
import { formSubmitErrorHandler } from '../../../services/formService'
import { documentFilter } from '../../../services/uploadService'
import UiText from '../../common/ui/UiText'
import UiErrorInfoDialog from '../../common/ui/UiErrorInfoDialog'
import FormHeaderText from './FormHeaderText'
import NextActionButton from './NextActionButton'
import { SearchDocumentApiParams } from '../../../models'
import { markStepAsCompleted } from '../../../services/apiService/payroll/company'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { showInfo } from '../../../store/actions/feedback';

const MAX_FILE_SIZE = 15728640
const acceptedFormat = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
]

function DocumentUploadForm({ refreshSteps, nextStep }: any) {
    const { colorTheme } = useThemeContext()
    const [fileList, setFileList] = useState<any[]>([])
    const store = useStore().getState()
    const [loading, setLoading] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [errors, setErrors] = useState<any>([])
    const currentBusinessId = store.appData.current_business_id
    const currentAccountId = store.appData.current_account_id
    const documentUploadStepCompleted =
        store.company.companyOnboardingStepInfo.onboarding_steps_status
            .document_upload
    const maxFileSize = store.config.apiConfig.file_center.max_file_size
    const dispatch = useDispatch();

    useEffect(() => {
        let params = {
            type: 'business',
            path: '/payroll/payroll_documents',
            perPage: 30,
        }
        if (documentUploadStepCompleted) {
            setLoading(true)
            searchDocuments(
                currentAccountId,
                params as SearchDocumentApiParams
            ).then((result): any => {
                setLoading(false)
                setFileList(result.items as any)
            })
        }
    }, [currentAccountId, documentUploadStepCompleted])

    const markUploadStepAsComplete = () => {
        formSubmitErrorHandler(
            markStepAsCompleted(currentBusinessId, {
                step: 'document_upload',
            }).then((res) => {
                if(res) {
                    refreshSteps()
                }
            }),
            () => {
                refreshSteps()
                setUploading(false)
            },
            () => {
                setUploading(false)
            }
        )
    }

    const postDocStepActions = () => {
        if (documentUploadStepCompleted) {
            refreshSteps()
            setUploading(false)
        } else {
            markUploadStepAsComplete()
        }
    }

    const sizeLimitMessage = (file: any)=> {
        dispatch(
            showInfo({
                infoData: (
                    <Fragment>
                        <Box textAlign='center' width='100%'>
                            <InfoOutlined fontSize='large' />
                        </Box>
                        <div>File {file.name} is too large.</div>
                        <div>
                            Please downsize the file. If you use a scanner please rescan the image at a lesser dpi.
                        </div>
                    </Fragment>
                ),
            })
        )
    }

    const limitCheck = (files: any) => {
        for(var file of files){
            if(file.size > maxFileSize){
                sizeLimitMessage(file)
                return true
            }
        }
        return false
    }

    const uploadForm = () => {
        setUploading(true)
        let nonUploadedFiles = fileList.filter((file) => !file?.id)

        if(limitCheck(nonUploadedFiles)){
            return
        }

        if (nonUploadedFiles.length > 0) {
            formSubmitErrorHandler(
                uploadFilesToFolder(
                    currentAccountId,
                    '/payroll/payroll_documents',
                    'business',
                    nonUploadedFiles
                ).then((response) => {
                    if (response) {
                        postDocStepActions()
                    }
                }),
                () => {
                    setUploading(false)
                },
                () => {}
            )
        } else {
            postDocStepActions()
        }
    }

    const handleRemove = (fileObject: any, fileId: any) => {
        let filteredUploadedFiles: any
        if (fileId) {
            filteredUploadedFiles = fileList.filter(
                (file) => file?.id !== fileObject?.id
            )
            // delete uploaded file
            deleteFile(currentAccountId, fileId).then(() => {})
        } else {
            filteredUploadedFiles = fileList.filter(
                (file) => file.name !== fileObject.name
            )
        }
        setFileList([...filteredUploadedFiles])
    }

    const filteredFiles = (acceptedFiles: any) => {
        let preFiles: any = []
        let fileErrors: any = []
        acceptedFiles.forEach((file: File, index: number) => {
            let check = documentFilter(file, MAX_FILE_SIZE)
            if (check === true) {
                preFiles.push(file)
            } else {
                fileErrors.push({ message: check })
            }
        })
        setErrors(fileErrors)
        return preFiles
    }

    // upload files from button
    const uploadFiles = (event: any) => {
        let files: any[] = filteredFiles([...event?.target?.files])
        setFileList([...fileList, ...files])
    }

    const onDrop = useCallback(
        (acceptedFiles) => {
            let files = filteredFiles(acceptedFiles)
            let allFiles = fileList.concat(files)
            setFileList(allFiles)
        },
        [fileList]
    )
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    })

    const renderFileFormat = (fileFormat: string) => {
        if (fileFormat === 'application/pdf') {
            return (
                <Box mr={1} color={colorTheme.red500}>
                    <PictureAsPdf />
                </Box>
            )
        }
        if (fileFormat === 'application/doc') {
            <Box mr={1} color={colorTheme.teal300}>
                <InsertDriveFileSharp />
            </Box>
        }
        return (
            <Box mr={1} color={colorTheme.orange400}>
                <InsertPhoto />
            </Box>
        )
    }
    return (
        <Grid
            style={{
                height: 'calc(100% - 108px)',
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
        >
            {loading ? (
                <Loader />
            ) : (
                <>
                    {errors.length > 0 &&
                        errors.map((e: any, index: number) => (
                            <UiErrorInfoDialog
                                key={index}
                                open={true}
                                message={e.message}
                                handleClose={() => {
                                    let arr = [...errors]
                                    arr.splice(index, 1)
                                    setErrors(arr)
                                }}
                            />
                        ))}
                    <FormHeaderText
                        heading="Document Upload"
                        formType="(Optional)"
                    />
                    <UiText>
                        Upload any relevant documents here. They can be viewed
                        here or in the Document Center.
                    </UiText>
                    <Box display="flex" alignItems="center" my={4}>
                        <label htmlFor="contained-button-file">
                            <input
                                id="contained-button-file"
                                multiple
                                type="file"
                                name="file"
                                hidden
                                accept={acceptedFormat.toString()}
                                onChange={uploadFiles}
                            />
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                component="span"
                                style={{ color: colorTheme.black100 }}
                            >
                                Upload Documents
                            </Button>
                        </label>
                        <Box ml={2}>
                            <UiText variant="moped_75">
                                15 MB Max File Size
                            </UiText>
                        </Box>
                    </Box>
                    {fileList.length === 0 ? (
                        <>
                            <Hidden smDown>
                                <Box
                                    {...getRootProps({})}
                                    border={`2px dashed ${colorTheme.grey200}`}
                                    textAlign="center"
                                    p={4}
                                    width="90%"
                                >
                                    <UiText
                                        weight="medium_500"
                                        variant="hatchback_125"
                                    >
                                        Drop files here to upload
                                    </UiText>
                                    <Box my={3}>
                                        <input
                                            {...getInputProps()}
                                            name="files[]"
                                            accept={acceptedFormat.toString()}
                                        />
                                    </Box>
                                    <Box my={3}>
                                        <Button
                                            disabled={false}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Select Files
                                        </Button>
                                    </Box>
                                    <Box my={3}>
                                        <UiText textColor="secondary">
                                            Supported file types: .pdf, .png,
                                            .jpeg, .jpg
                                        </UiText>
                                    </Box>
                                </Box>
                            </Hidden>
                            <Box mt={4}>
                                <UiText textColor="secondary">
                                    No documents uploaded
                                </UiText>
                            </Box>
                        </>
                    ) : (
                        <Box>
                            {fileList.map((file) => (
                                <Box
                                    key={file.name}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box display="flex">
                                        {renderFileFormat(file?.mime_type)}
                                        <UiText>
                                            {file.name || file?.filename}
                                        </UiText>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                                handleRemove(file, file?.id)
                                            }
                                        >
                                            <ClearSharp />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            )}
            <Box my={2} mt={2}>
                <NextActionButton
                    loading={uploading}
                    submitAction={uploadForm}
                />
            </Box>
        </Grid>
    )
}

export default DocumentUploadForm
