import React from 'react';
import SectionView from '../../common/SectionView'
import EmptySectionView from '../../common/EmptySectionView'
import EmailIcon from '@material-ui/icons/Email'

interface EmployeeAddressProps {
    address?: any
    setOpenRequestDialog: React.Dispatch<React.SetStateAction<boolean>>
    disableRequestInputButton?: boolean | undefined
}

const Address = ({ address, setOpenRequestDialog, disableRequestInputButton }: EmployeeAddressProps) => {
    let { city, state, street_1, street_2, zip } = address
    return (
        <div>
            {address?.state ? (
                <div style={{height: '200px'}}>
                    <SectionView
                        key={'employee_address'}
                        headerText={'Employee Address'}
                        customNode={
                            <>
                                {street_1} {street_2} <br />
                                {city}  {state}  {zip}
                            </>
                        }
                    />
                </div>
            ) : (
                <EmptySectionView
                    headerText={'Employee Address'}
                    primaryText={'No Address Found'}
                    buttonText={'Request employee input'}
                    buttonIcon={<EmailIcon />}
                    disableButton={disableRequestInputButton}
                    onClickAction={() => {
                        setOpenRequestDialog(true)
                    }}
                />
            )}
        </div>
    )
}

export default Address
