import { makeStyles, Theme } from '@material-ui/core';
import { toRem16 } from '../../../../styles/commonStyles';
import { ThemeColors } from '../../../../styles/models/Colors.interface';

export const useReportsStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        activeRouteHeading: {
            position: 'absolute',
            top: 14,
            left: 20,
            zIndex: 2,
            background: (colorTheme) => colorTheme.primaryWhite,
            '& .headingText': {
                display: 'flex',
                alignItems:'center'
            },
        },
        clearAllSegmentsButton: {
            position: 'absolute',
            top: '50%',
            right: toRem16(1),
            transform: 'translateY(-50%)',
            background: 'transparent',
            color: (colorTheme) => colorTheme.blue600,
            zIndex: 1,
            '&:hover':{
                cursor: 'pointer',
                background: 'transparent',
                color: (colorTheme) => colorTheme.blue600,
            },
            '& .MuiTypography-button':{
                fontSize: toRem16(14),
            }
        },
        reportsViewParent: {
            '& input': {
                padding: `${toRem16(10.5)} ${toRem16(14)}`,
            },
            '& .businessName': {
                color: (colorTheme) => colorTheme.grey400,
            },
            '& .selectedChip': {
                background: (colorTheme) => colorTheme.grey600,
                border: (colorTheme) =>  `${toRem16(2)} solid ${colorTheme.grey500}`,
            },
            '& .customChip': {
                background: (colorTheme) => colorTheme.primaryWhite,
                border: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey200}`,
            },
            '& .ml8': {
                marginRight: '0.5rem',
            },
            '& .calendarSection': {
                '& svg': {
                    width: `${toRem16(24)}`,
                    height: `${toRem16(24)}`,
                },
            },
        },
        pageTitle: {
            marginBottom: toRem16(40),
            display: 'flex',
            alignItems: 'center',
        },
        reportDetailsContainer: {
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
            height: '100%',
            overflow: 'auto',
            minWidth: 0,
            '& .borderBottom': {
                borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            },
        },
        reportDetailsContent: {
            margin: '0 1.5rem 6rem 1.5rem',
            
        },

        downloadDropdownOptions: {
            '& a': {
                textDecoration: 'none',
                color: (colorTheme) => colorTheme.black100,
            },
        },
        reportsTable: {
            '& .MuiTableCell-root': {
                padding: '0.6rem 0.6rem 0.6rem 0',
            },
            '& .MuiTableCell-head': {
                padding: '0 0 0.5rem 0',
                borderBottom: (colorTheme) => `1px solid ${colorTheme.grey400}`,
            },
            '& thead':{
                position: 'sticky',
                top:0,
                left:0,
                zIndex: 1,
                background: (colorTheme) => colorTheme.primaryWhite,
            }
        },

        reportsCommonHeader: {
            margin: '0 1.5rem',
            '& a': {
                display: 'flex',
                alignItems: 'center',
                color: (colorTheme) => colorTheme.blue200,
                textDecoration: 'none',
                margin: '0 0 0 0.5rem',
                '& span': {
                    margin: '0 0 0 0.5rem',
                },
            },
            '& .headerTextAndBusinessNameLogo': {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '1.5rem 0',

                '& h2': {
                    fontSize: toRem16(24),
                    margin: '0',
                },
            },
            '& .backLinkAndBtn': {
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                margin: `${toRem16(20)} 0 ${toRem16(34)} 0`,
            },
        },
        dateSelectors: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .dateSeparator': {
                margin: '0 1rem',
            },
        },
        timePeriodChips: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            gap: '1rem',
            margin: '1.2rem 0 3rem 0',
            '& .MuiChip-root':{
                margin: 0,
            },
            '& .MuiChip-clickable:hover':{
                backgroundColor: (colorTheme) => colorTheme.grey600,
                cursor: 'pointer'
            }
        },
        businessLogoImageContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .businessLogo': {
                margin: '0 0 0 1rem',
                height: toRem16(48),
            },
            '& img': {
                width: toRem16(48),
                height: toRem16(48),
            },
        },
        categoryChip: {
            display: 'flex',
            margin: '1rem 0',
            '& p': {
                backgroundColor: (colorTheme) => colorTheme.grey100,
                borderRadius: '1.7rem',
                marginRight: '1rem',
                padding: '0.5rem',
            },
        },
        multiSelectInput: {
            position: 'relative',
            '& .Mui-disabled': {
                '& svg': {
                    '& path': {
                        fill: (colorTheme: ThemeColors) => colorTheme.grey100,
                    },
                },
            },
            '& .MuiInputBase-adornedStart': {
                display: 'flex',
                flexFlow: 'wrap',
                minHeight: '2.5rem',
                padding: `${toRem16(10)} 5rem ${toRem16(10)} ${toRem16(14)}`,
            },
            '& input': {
                padding: 0,
                height: 0,
            },
            '& .MuiFormControl-root': {
                width: '100%',
            },
            '& .chipsContainer': {
                margin: '0 0 1.5rem 0',
            },
            '& .MuiChip-root': {
                margin: '0 0 0 2rem',
            },
            '& .MuiInputAdornment-positionStart': {
                position: 'absolute',
            },
        },
    };
});
