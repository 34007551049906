import React from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import useDeviceSize from '../../../hooks/useDeviceSize'
import MobileCardView from './MobileView/MobileCardView'
import StyledInfoCard from './StyledInfoCard'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        imageStyle: {
            width: toRem16(190),
            borderRadius: toRem16(8),
        },
        buttonStyle: {
            margin: `${toRem16(8)} 0 ${toRem16(8)} 0`,
        },
        mainTextContent: {
            paddingLeft: '0.5rem',
            marginBottom: '0.125rem',
        },
        divStyle: {
            marginTop: '0.5rem',
            display: 'grid',
            gridTemplateColumns: `1fr ${toRem16(200)}`,
        },
        divWithoutImage: {
            display: 'grid',
            gridTemplateColumns: '1fr 0.5rem',
        },
        textMargin: {
            marginTop: toRem16(6),
        },
        dismissBtn: {
            color: colorTheme => colorTheme.blue200,
            marginTop: toRem16(8),
            marginLeft: toRem16(8),
        },
    }
})

export interface InfoCardProps {
    title?: string | React.ReactNode
    subtitle?: string | React.ReactNode
    buttonText?: string | React.ReactNode
    customTitle?: string | React.ReactNode
    buttonClick?: () => void
    buttonIcon?: any
    cardImage?: any
    rightButton?: React.ReactNode
    containerStyle?: any
    btnPrimary?: boolean
    btnContained?: boolean
    onDismissClick?: () => void
    borderView?: boolean
    disableClick?: boolean
}

const InfoCard = ({
    title,
    subtitle,
    customTitle,
    buttonText,
    buttonClick,
    buttonIcon,
    cardImage,
    rightButton,
    containerStyle,
    btnPrimary = true,
    btnContained = true,
    onDismissClick,
    borderView = false,
    disableClick,
}: InfoCardProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()

    if (borderView) {
        return (
            <StyledInfoCard
                dismissAction={() => {
                    onDismissClick?.()
                }}
                rightImage={cardImage}
                content={
                    <>
                        {customTitle}
                        {subtitle && (
                            <UiText
                                textColor="textSecondary"
                                className={styles.textMargin}
                            >
                                {subtitle}
                            </UiText>
                        )}
                        {buttonText && (
                            <Button
                                color={btnPrimary ? 'primary' : 'secondary'}
                                variant={
                                    btnContained ? 'contained' : 'outlined'
                                }
                                startIcon={buttonIcon}
                                className={styles.buttonStyle}
                                onClick={buttonClick}
                            >
                                {buttonText}
                            </Button>
                        )}
                        {rightButton}
                    </>
                }
            />
        )
    }

    if (isMobileDevice) {
        return (
            <MobileCardView
                customTitle={customTitle}
                subtitle={subtitle}
                buttonText={buttonText}
                buttonClick={buttonClick}
                buttonIcon={buttonIcon}
                cardImage={cardImage}
                onDismissClick={onDismissClick}
                borderView={false}
                rightButton={rightButton}
                disableClick={disableClick}
            />
        )
    }

    return (
        <div className={containerStyle ? containerStyle : styles.container}>
            <div
                className={cardImage ? styles.divStyle : styles.divWithoutImage}
            >
                <div className={styles.mainTextContent}>
                    {customTitle}
                    {title && (
                        <UiText variant="suv_150" weight="medium_500">
                            {title}
                        </UiText>
                    )}
                    {subtitle && (
                        <UiText
                            textColor="textSecondary"
                            className={styles.textMargin}
                        >
                            {subtitle}
                        </UiText>
                    )}
                    {buttonText && (
                        <Button
                            color={btnPrimary ? 'primary' : 'secondary'}
                            variant={btnContained ? 'contained' : 'outlined'}
                            startIcon={buttonIcon}
                            className={styles.buttonStyle}
                            onClick={buttonClick}
                            disabled={disableClick}
                        >
                            {buttonText}
                        </Button>
                    )}
                    {rightButton}
                    {onDismissClick && (
                        <Button
                            variant="text"
                            className={styles.dismissBtn}
                            onClick={() => {
                                onDismissClick?.()
                            }}
                        >
                            Dismiss
                        </Button>
                    )}
                </div>
                <div>
                    {cardImage && (
                        <img
                            src={cardImage}
                            className={styles.imageStyle}
                            alt="cardImage"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default InfoCard