import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

function WidgetBoxhiddenSM(props: any) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => {
        return {
            widgetRoot: {
                border: `1px solid ${colorTheme.grey200}`,
                borderRadius: '8px',
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                    border: 'none',
                },
            },
            wdgetHeader: {
                borderBottom: `1px solid ${colorTheme.grey200}`,
                padding: theme.spacing(2, 4),
                [theme.breakpoints.down('sm')]: {
                    border: 'none',
                    padding: theme.spacing(2, 0, 2),
                },
            },
            wdgeBody: {
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
            },
        };
    })();

    return (
        <Fragment>
            <div className={classes.wdgetHeader}>
                <Grid
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                >
                    {props.actionControl}
                </Grid>
            </div>
            <div className={classes.wdgeBody}>{props.children}</div>
        </Fragment>
    );
}

export default WidgetBoxhiddenSM;