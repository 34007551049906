import { AppData, Category } from '../../models';
import { getCategories } from '../../services/apiService';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES';
export const RESET_CATEGORIES = 'RESET_CATEGORIES';

export interface CategoriesAction {
    type: string;
    state?: Category;
}

export type Action = CategoriesAction;

const loadingCategories = (): Action => ({
    type: LOADING_CATEGORIES,
});
const setCategories = (data: Category): Action => ({
    type: SET_CATEGORIES,
    state: data,
});

export function loadCategories() {
    return (dispatch: any, getState: any) => {
        dispatch(loadingCategories());
        const { appData }: { appData: AppData } = getState();
        getCategories(
            appData.current_account_id,
            appData.current_business_id
        ).then((res: any) => {
            dispatch(
                setCategories({ loading: false, loaded: true, categories: res })
            );
        });
    };
}

export function initCategories(shouldRefresh: boolean = false) {
    return (dispatch: any, getState: any) => {
        const { category }: { category: Category } = getState();
        if ((!category.loaded && !category.loading) || shouldRefresh) {
            dispatch(loadCategories());
        }
    };
}
