import React, { useState } from 'react'
import {
    Avatar,
    ListItem,
    makeStyles,
    Theme,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
} from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import UiChip from '../../common/ui/UiChip'
import { COLORS } from '../../../variables/colors'
import { Contractor } from '../models/contractor.model'
import { Block, MoreVert } from '@material-ui/icons'
import { ReactComponent as Delete } from '../../../assets/icons-svg/deleteTrash.svg'
import { editContractor, removeContractor } from '../apiServices/contractor.api'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import ConfirmContractorModal from './ContractorDetails/ConfirmContractorModal'
import { requestErrorHandler } from '../../../services/formService'
import { useContractorContext } from '../ContractorProvider'
import { toRem16 } from '../../../styles/commonStyles'

export enum ACTION_TYPE {
    DELETE = 'DELETE',
    DEACTIVATE = 'DEACTIVATE',
}
const useStyles = makeStyles((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },

    inline: {
        display: 'inline',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    selectedMessage: {
        background: `${COLORS.cyan100} !important`,
    },
    itemAvatar: {
        backgroundColor: theme.palette.primary.main,
        margin: '0 0.5rem 0.5rem 0.5rem',
    },
    secondaryText: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        marginLeft: '0.5rem',
    },
    labelStyle: {
        marginTop: '0.5rem',
    },
    buttonSpacing: {
        marginLeft: '0.5rem',
    },
    alignCenter:{
        alignSelf:"center"
    },
    divider:{
        marginRight: toRem16(32)
    }
}))

interface ContractorItemProps {
    onSelect: () => void
    selectedItem: boolean
    contractor: Contractor
    setSelectedContractor: (data: any) => void
}

const ContractorItem = ({
    onSelect,
    selectedItem,
    contractor,
    setSelectedContractor,
}: ContractorItemProps) => {
    const { currentBusinessId } = useCurrentStore()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const open = Boolean(anchorEl)
    const { getContractors } = useContractorContext()
    const [action, setAction] = useState(ACTION_TYPE.DELETE)
    const isDeletable = action === ACTION_TYPE.DELETE
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)

    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget)
    // }

    // const handleClose = () => {
    //     setAnchorEl(null)
    // }
    const classes = useStyles()
    // const isInCompleted = contractor?.status || true

    const handleDelete = () => {
        setLoading(true)
        requestErrorHandler(
            removeContractor(contractor.id!).then(() => {
                setLoading(false)
                setIsConfirmationOpen(false)
            })
        )
    }

    const markAsInActive = () => {
        setLoading(true)
        editContractor(currentBusinessId!, { ...contractor, is_active: false })
            .then(() => {
                setLoading(false)
                setIsConfirmationOpen(false)
                getContractors({ page: 1, perPage: 100 })
                setSelectedContractor(null)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const handleAction = (type: ACTION_TYPE) => {
        switch (type) {
            case ACTION_TYPE.DELETE:
                handleDelete()
                break
            case ACTION_TYPE.DEACTIVATE:
                markAsInActive()
                break
            default:
                break
        }
    }
    return (
        <>
            <ConfirmContractorModal
                showConfirmModal={isConfirmationOpen}
                handleClose={() => {
                    setIsConfirmationOpen(false)
                }}
                contractor={contractor}
                isDeletable={isDeletable}
                handleAction={handleAction}
                loading={loading}
            />
            <ListItem
                button
                classes={{
                    gutters: classes.navListItemGutters,
                    selected: classes.selectedMessage,
                    root: 'contractor-item',
                }}
                alignItems="flex-start"
                selected={selectedItem}
                onClick={onSelect}
            >
                <ListItemAvatar>
                    <Avatar
                        classes={{
                            colorDefault: classes.itemAvatar,
                        }}
                    >
                        {contractor?.first_name?.charAt(0)?.toUpperCase() ||
                            'A'}
                    </Avatar>
                </ListItemAvatar>

                <ListItemText
                    className={classes.alignCenter}          
                    primary={
                        <div className={classes.buttonSpacing}>
                            <UiText>
                                {contractor?.first_name} {contractor?.last_name}
                            </UiText>
                            {contractor?.is_complete === false && (
                                <div className={classes.labelStyle}>
                                    <UiChip
                                        label={
                                            <UiText
                                                variant="motorcycle_90"
                                                weight="medium_500"
                                                textColor="secondary"
                                            >
                                                Incomplete
                                            </UiText>
                                        } 
                                    />
                                </div>
                            )}
                        </div>
                    }
                    secondary={<div className={classes.secondaryText}></div>}
                />
                {/* <ListItemSecondaryAction>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVert />
                    </IconButton>
                </ListItemSecondaryAction> */}
            </ListItem>
            <Divider className={classes.divider} variant="inset" component="li" />
            {/* <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose()
                        setIsConfirmationOpen(true)
                        setAction(ACTION_TYPE.DEACTIVATE)
                    }}
                >
                    <ListItemIcon style={{ minWidth: '2rem' }}>
                        <Block fontSize="small" />
                    </ListItemIcon>
                    <UiText>Mark as Inactive</UiText>
                </MenuItem>
                <MenuItem // to do
                    onClick={() => {
                        handleClose()
                        setOpenConfirmationModal(true)
                        setAction(ACTION_TYPE.DELETE)
                    }}
                >
                    <ListItemIcon style={{ minWidth: '2rem' }}>
                        <Delete
                            style={{
                                fill: COLORS.red200,
                            }}
                        />
                    </ListItemIcon>
                    <UiText textColor="error">Delete</UiText>
                </MenuItem>
            </Menu> */}
        </>
    )
}

export default ContractorItem
