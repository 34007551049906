import React, { Fragment, useState } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { FormikHelpers, Formik, Form } from 'formik'
import { Form8821 } from '../../../../models'
import Icon from '../../../common/Icon'
import { useStyles } from './Styles'
import UiText from '../../../common/ui/UiText'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import Header from './Header'
import { Add } from '@material-ui/icons'
import { MODAL_SCREEN } from './CarryoverTaxInfoModal'
import { saveForm8821Data } from '../../../../services/apiService'
import { formSubmitErrorHandler } from '../../../../services/formService'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../store/actions/feedback'
import {
    combinedFormValidation,
    ownerFormValidation,
    ownerInfoFormMapping,
    spouseFormInitals,
    spouseInfoFormMappings,
} from './FormUtility'
import Loader from '../../../common/Loader'
import clsx from 'clsx'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { inputMaskRegex } from '../../../../utils/maskUtil'

const TaxProcessingInfo = ({
    signatureValue,
}: {
    signatureValue: string | undefined
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div>
            <UiText>
                By proceeding, you are electronically signing IRS Form 8821
                which will authorize 1-800Accountant to retrieve your past tax
                returns (Form 1040) for tax years 2018-2022.
            </UiText>
            <TextField
                variant="outlined"
                value={signatureValue}
                className={clsx(styles.contentSpacing, styles.signatureField)}
                
            />
            <UiText>The IRS takes 4-6 weeks to process this form.</UiText>
        </div>
    )
}

const InfoBanner = () => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.taxInfoBanner}>
            <Icon
                icon="InfoIcon"
                svgColor={colorTheme.blue200}
                customClass={'icon'}
            />
            <UiText variant="motorcycle_90">
                This information is required in order for 1-800Accountant to
                check your Carryover Tax Benefits eligibility.
            </UiText>
        </div>
    )
}

const CollectInfoForm = ({
    closeModal,
    setCurrentScreen,
}: {
    closeModal: () => void
    setCurrentScreen: React.Dispatch<React.SetStateAction<MODAL_SCREEN>>
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const dispatch = useDispatch()
    const [signatureValue, setSignatureValue] = useState('')
    const [addSpouseForm, setAddSpouseForm] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const {
        currentConfig,
        currentBusiness,
        currentAccountId,
        currentBusinessId,
    } = useCurrentStore()

    const businessOwner = currentBusiness?.business_owners?.[0]

    let initialValues: Form8821 = {
        tax_payer_name:
            businessOwner?.first_name + ' ' + businessOwner?.last_name ?? '',
        tax_payer_address: businessOwner?.address?.address,
        tax_payer_city: businessOwner?.address?.city,
        tax_payer_state: businessOwner?.address?.state ?? '',
        tax_payer_zip: businessOwner?.address?.zip_code,
        tax_payer_phone: currentBusiness?.business_phone,
        tax_payer_ssn: '',
    }

    const RenderFormFields = (property: any) => {
        return (
            <UiFormControlSelection
                showFloatingLabel
                placeholder={property?.placeholder}
                type={property.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                optionsData={property?.data}
                optionKey={property?.optionKey}
                optionValue={property?.optionValue}
                label={property?.label}
                fastField={property?.fastField} 
                {...(property.type === 'KeyboardDatePicker'
                    ? { disableFutureDate: true, 
                        dateType: 'string', 
                        dateFormat: 'MM/DD/YYYY',
                        nonIsoDate: true }
                    : {})}
                {...(property?.minLength
                    ? { minLength: property?.minLength }
                    : {})}
                {...(property.type === 'mask'
                    ? { mask: property?.regex ? property?.regex : inputMaskRegex, fieldSize: 6 }
                    : {})}
                {...(property.endIcon ? { endIcon: property.endIcon } : {})}
                onKeyUp={
                    property.key === 'tax_payer_name' ? 
                        (e: React.ChangeEvent <HTMLTextAreaElement | HTMLInputElement>) => {
                            if (e?.target?.value) {
                                setSignatureValue(e?.target?.value?.toUpperCase())
                            }
                        } : () => {}
                }
            />
        )
    }

    const onSubmit = (formik: any, form: FormikHelpers<Form8821>) => {
        setLoading(true)
        formSubmitErrorHandler(
            saveForm8821Data(
                currentAccountId,
                currentBusinessId as string,
                formik
            ).then(
                (res: any) => {
                    if (res) {
                        setLoading(false)
                        form.setSubmitting(false)
                        setCurrentScreen(MODAL_SCREEN.THANKS_SCREEN)
                    }
                },
                (err) => {
                    setLoading(false)
                    const error = err.statusText
                    form.setSubmitting(false)
                    dispatch(
                        showAlert({
                            alertText: error,
                            alertType: 'error',
                        })
                    )
                }
            ),
            () => {
                form.setSubmitting(false)
            }
        )
    }
    const addSpouseInfo = (
        formValues: Form8821,
        setValues: (
            values: React.SetStateAction<Form8821>,
            shouldValidate?: boolean | undefined
        ) => void
    ) => {
        const values: Form8821 = { ...formValues, ...spouseFormInitals }
        setValues(values)
        setAddSpouseForm(true)
    }

    const removeSpouseInfo = (
        formValues: any,
        setValues: (
            values: React.SetStateAction<Form8821>,
            shouldValidate?: boolean | undefined
        ) => void
    ) => {
        let values: Form8821 = { ...formValues }

        Object.keys(spouseFormInitals).forEach((key: string) => {
            delete values[key as keyof Form8821]
        })
        setAddSpouseForm(false)
        setValues(values)
    }

    return (
        <div>
            <Header
                headerText={'Verify Your Information'}
                closeModal={closeModal}
                onBackButtonClick={() => {
                    setCurrentScreen(MODAL_SCREEN.INTRODUCTION)
                }}
                noBackgroundOpacity
            />
            {loading ? (
                <div className={styles.loaderStyle}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.contentLayout}>
                    <InfoBanner />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={
                            addSpouseForm
                                ? combinedFormValidation
                                : ownerFormValidation
                        }
                        enableReinitialize
                    >
                        {({ values, submitForm, setValues }) => {
                            return (
                                <Form>
                                    <Grid>
                                        {ownerInfoFormMapping(
                                            currentConfig
                                        ).map((element: any) => {
                                            return (
                                                <Grid
                                                    item
                                                    md={element?.md ?? 12}
                                                    key={element.key}
                                                >
                                                    <div
                                                        className={
                                                            styles.fieldContainer
                                                        }
                                                    >
                                                        {RenderFormFields(
                                                            element
                                                        )}
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    {addSpouseForm ? (
                                        <Fragment>
                                            <div
                                                className={
                                                    styles.deleteSpouseInfo
                                                }
                                            >
                                                <UiText
                                                    variant="motorcycle_90"
                                                    weight="semi_bold_600"
                                                    className="content"
                                                >
                                                    Spouse Information
                                                </UiText>
                                                <div
                                                    className={'delete-info'}
                                                    onClick={() => {
                                                        removeSpouseInfo(
                                                            values,
                                                            setValues
                                                        )
                                                    }}
                                                >
                                                    <Icon
                                                        icon="delete"
                                                        svgColor={
                                                            colorTheme.red200
                                                        }
                                                    />
                                                    <UiText className="remove-text">
                                                        Remove
                                                    </UiText>
                                                </div>
                                            </div>

                                            <br />
                                            {spouseInfoFormMappings(
                                                currentConfig
                                            ).map((element: any) => {
                                                return (
                                                    <Grid
                                                        item
                                                        md={element?.md ?? 12}
                                                        key={element.key}
                                                    >
                                                        <div
                                                            className={
                                                                styles.fieldContainer
                                                            }
                                                        >
                                                            {RenderFormFields(
                                                                element
                                                            )}
                                                        </div>
                                                    </Grid>
                                                )
                                            })}
                                        </Fragment>
                                    ) : (
                                        <div>
                                            <Button
                                                startIcon={<Add />}
                                                className={styles.addButton}
                                                onClick={() => {
                                                    addSpouseInfo(
                                                        values,
                                                        setValues
                                                    )
                                                }}
                                            >
                                                Add Spouse Info
                                            </Button>
                                        </div>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={styles.contentSpacing}
                                        onClick={submitForm}
                                    >
                                        Retrieve Past Tax Returns
                                    </Button>
                                    <TaxProcessingInfo
                                        signatureValue={signatureValue === '' ? initialValues?.tax_payer_name?.toUpperCase() : signatureValue}
                                    />
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            )}
        </div>
    )
}

export default CollectInfoForm