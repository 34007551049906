import store from '../../store'
import {
    getResource,
    patchResource,
    deleteResource,
    postResource,
} from '../axiosService'
export interface bulkCategoryUpdateParams {
    category: string,
    filters: {id: string[]}
}
export interface bulkProductDeleteParams {
    deleted: boolean,
    filters: {id: string[]}
}

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    const productBaseURL = `api/account/${accountId}/business/${businessId}/offering`
    const categoryBaseURL = `api/account/${accountId}/business/${businessId}/category`
    return {
        accountId,
        businessId,
        productBaseURL,
        categoryBaseURL,
    }
}

export function getAllProducts() {
    const { accountId, businessId } = getStoreData()
    return getResource(
        `api/account/${accountId}/business/${businessId}/offerings`,
        undefined
    )
}
export function getProductCategories() {
    const { categoryBaseURL } = getStoreData()
    return getResource(
        categoryBaseURL,
        undefined
    )
}

export function createCategory(params: any) {
    const { categoryBaseURL } = getStoreData()
    return postResource(
        categoryBaseURL,
        params
    )
}
export function getProductServices(
    params: any
) {
    const { productBaseURL } = getStoreData()
    return getResource(
        productBaseURL,
        undefined,
        params
    )
}

export function bulkProductsAction(params: bulkProductDeleteParams | bulkCategoryUpdateParams) {
    const { productBaseURL } = getStoreData()
    return patchResource(
        productBaseURL,
        params
    )
}

export function productDetail(
    productId: string
) {
    const { productBaseURL } = getStoreData()
    return getResource(
        `${productBaseURL}/${productId}`
    )
}

export function createProduct(
    params: any
) {
    const { productBaseURL } = getStoreData()
    return postResource(
        productBaseURL,
        params
    )
}

export function updateProduct(
    productId: string,
    params: any
) {
    const { productBaseURL } = getStoreData()
    return patchResource(
        `${productBaseURL}/${productId}`,
        params
    )
}

export function deleteProduct(
    productId: string
) {
    const { productBaseURL } = getStoreData()
    return deleteResource(
        `${productBaseURL}/${productId}`
    )
}