import { Fragment, useEffect, useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Hidden } from '@material-ui/core'
import { ActiveRoutingContext } from './Providers/ActiveRoutingProvider'
import Purposes from '../mileage-log/purposes/Purposes'
import PurposeTripList from '../mileage-log/purposes/PurposeTripList'
import { useFiltersContext } from '../mileage-log/trips/Provider/FiltersProvider'
import { defaultQueryObject } from '../mileage-log/trips/TripUtil'

const PurposesRoutes = () => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const {setQueryObject} = useFiltersContext();

    useEffect(() => {
        setActiveRouteHeading('Purposes')
    }, [setActiveRouteHeading])


    useEffect(()=>{
        setQueryObject(defaultQueryObject)
    },[])

    return (
        <Switch>
            <Fragment>
                <Hidden mdUp>
                    <Route path="/mileage/purposes" exact>
                        <Purposes />
                    </Route>
                    <Route path={`/mileage/purposes/:id/trips`} exact>
                        <PurposeTripList />
                    </Route>
                </Hidden>
                <Hidden smDown>
                    <Route path="/mileage/purposes">
                        <Purposes />
                    </Route>
                </Hidden>
            </Fragment>
        </Switch>
    )
}

export default PurposesRoutes
