import { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Loader from '../../../common/Loader'
import PlaidSuccessPage from './PlaidSuccessPage'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import PayrollMigrationLandingPage from './PayrollMigrationLandingPage'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ERROR_MESSAGES, PAGE_TYPE } from './constants/payroll-migration.const'
import { submitTOS } from '../../../../services/apiService/adp-payroll/company'
import { usePayrollMigrationState } from '../../Providers/PayrollMigrationProvider'
import BankVerificationSection from './BankVerificationSection'
import Navbar from './Navbar'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    layout: {
        width: '100%',
        height: '100%',
        marginBottom: '2rem',
        position: 'relative',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            marginBottom: '2rem',
            position: 'relative',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '-ms-overflow-style': 'none' /* IE and Edge */,
            'scrollbar-width': 'none' /* Firefox */,
        },
    },
   
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: "1rem 1rem 1rem 1rem",
            width: '100%',
            boxSizing: 'border-box',
            flex: 1,
        },
    },
    tosFlowData: {
        width: '40rem',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
            width: '100%',
        },
    },
    loader: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        position: 'fixed',
        zIndex: 10,
    },
    scrollIconContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: (colorTheme) => colorTheme.primaryWhite,
        backgroundColor: (colorTheme) => colorTheme.primary,
        height: '3.5rem',
        width: '3.5rem',
        borderRadius: '50%',
        bottom: '11rem',
        zIndex: 1,
        cursor: 'pointer',
    },
}))

let initialValues: any = {
    agreementOne: false,
    agreementTwo: false,
    agreementThree: false,
    agreementFour: false,
    agreementFive: false,
    agreementSix: false,
    agreementSeven: false,
    signature: '',
}

const payrollValidationSchema = Yup.object().shape({
    agreementOne: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    agreementTwo: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    agreementThree: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    agreementFour: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    agreementFive: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    agreementSix: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    agreementSeven: Yup.boolean().oneOf(
        [true],
        'Must Accept Terms and Conditions'
    ),
    signature: Yup.string().required('Your signature is required to proceed'),
})

export type PageType = 'initialPage' | 'SuccessPage' | 'verificationPage' | ''

const TOSLandingPage = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const [pageMargin, setPageMargin] = useState<number>(5)
    const bottomRef = useRef<HTMLDivElement>(null)
    const [showScrollIcon, setShowScrollIcon] = useState(true)
    const [isContentOverflow, setIsContentOverflow] = useState(false);
    const modalContentRef = useRef<HTMLDivElement>(null)

    const {
        page,
        loadToken,
        loadingCompanyDetails,
        isError,
        errorMsg,
        setIsError,
        isAgreementAccepted,
        errorHandler,
        openPlaidLink,
        setSubmittingCompanyData,
        bankInfo,
        setIsAgreementAccepted,
        updateMode,
        showReconnectPage,
        loader
    } = usePayrollMigrationState()
    const { currentBusinessId } = useCurrentStore()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    if (isAgreementAccepted) {
        initialValues = {
            agreementOne: true,
            agreementTwo: true,
            agreementThree: true,
            agreementFour: true,
            agreementFive: true,
            agreementSix: true,
            agreementSeven: true,
            signature: 'signature',
        }
    } else {
        initialValues = {
            agreementOne: false,
            agreementTwo: false,
            agreementThree: false,
            agreementFour: false,
            agreementFive: false,
            agreementSix: false,
            agreementSeven: false,
            signature: '',
        }
    }

    useEffect(() => {
        if (page === PAGE_TYPE.INITIAL_PAGE) {
            setPageMargin(smDevice ? 0 : 2.5)
        }
    }, [page])

    useEffect(() => {
        //@ts-ignore
        if (modalContentRef.current && modalContentRef.current?.scrollHeight >= window.innerHeight) {
            setIsContentOverflow(true);
          }
      }, []);

    const handleScrollIconClick = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' })
            setShowScrollIcon(false)
        }
    }

    const handleScroll = () => {
        if (modalContentRef.current) {
            const { scrollTop } = modalContentRef.current
            setShowScrollIcon(scrollTop === 0)
        }
    }

    const componentMapping = {
        [PAGE_TYPE.INITIAL_PAGE]: <PayrollMigrationLandingPage />,
        [PAGE_TYPE.SUCCESS_PAGE]: <PlaidSuccessPage />,
        [PAGE_TYPE.VERIFICATION_PAGE]: <BankVerificationSection />,
        default: <></>,
    }

    const handleDataSubmit = async (values: any) => {
        setSubmittingCompanyData(true);
        try {
            const shouldOpenPlaidLink =
                isAgreementAccepted &&
                ((bankInfo && Object.keys(bankInfo).length === 0) || showReconnectPage || updateMode)

            if (shouldOpenPlaidLink) {
                openPlaidLink()
                return
            }

            const { signature, ...agreements } = values;
            const allAgreementsAccepted = Object.values(agreements).every(value => value === true);

            const payload = {
                legalConfirmation: allAgreementsAccepted,
                signature,
            };

            await submitTOS(currentBusinessId, payload);
            setIsAgreementAccepted(true);

            if (showReconnectPage) {
                openPlaidLink()
            }
        } catch (error) {
            errorHandler(ERROR_MESSAGES.SUBMIT_AGREEMENT_FAIL);
        } finally {
            setSubmittingCompanyData(false)
        }
    }

    return (
        <div
            onScroll={handleScroll}
            ref={modalContentRef}
            className={classes.layout}
        >
            {(loader || loadToken || loadingCompanyDetails) && (
                <div className={classes.loader}>
                    <Loader />
                </div>
            )}
          
            <Navbar />  
            <Formik
                initialValues={initialValues}
                onSubmit={handleDataSubmit}
                validationSchema={payrollValidationSchema}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {({ isValid, dirty, values }) => {
                    return (
                        <Form>
                            <div className={classes.content}>
                                {showScrollIcon &&
                                    !loadToken &&
                                    (page === PAGE_TYPE.INITIAL_PAGE ||
                                        (page === PAGE_TYPE.SSN_VERIFY_PAGE &&
                                            isContentOverflow)) && (
                                        <div
                                            className={
                                                classes.scrollIconContainer
                                            }
                                            style={page === PAGE_TYPE.INITIAL_PAGE ? { bottom: '11rem' } : (page === PAGE_TYPE.SSN_VERIFY_PAGE ? { bottom: '3rem' } : {})}
                                        >
                                            <ArrowDownwardIcon
                                                onClick={handleScrollIconClick}
                                            />
                                        </div>
                                    )}
                                {
                                    <div
                                        style={{
                                            marginTop: `${
                                                smDevice ? 0.5 : pageMargin
                                            }rem`,
                                        }}
                                        className={classes.tosFlowData}
                                    >
                                        {componentMapping[page] ||
                                            componentMapping.default}
                                        <div ref={bottomRef}></div>
                                    </div>
                                }
                            </div>
                        </Form>
                    )
                }}
            </Formik>

            <UiSnackbarAlert
                open={isError}
                message={errorMsg}
                handleClose={() => setIsError(false)}
                actionButtonColor={'primary'}
                severity="error"
            />
        </div>
    )
}

export default TOSLandingPage
