import { useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
    createFile,
    getBalanceSheetPDFDownloadUrl,
    getBalanceSheetReport,
    saveBalanceSheetPDFReportToPortal,
} from '../../../../services/apiService/reports';
import { showError } from '../../../../services/formService';
import {
    getNumberfromString,
    isEmpty,
} from '../../../../utils/appUtil';
import Loader from '../../../common/Loader';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore';
import {
    BalanceSheetReportRoot,
    DownloadModalFormData,
    SaveJournal,
    SaveReportsToPortalData,
} from '../models/reports-interface';
import { useReportsStyles } from '../styles/reports-styles';
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo';
import ErrorAlert from '../utils/Alerts/ErrorAlert';
import SaveReports from '../utils/SaveReports/SaveReports';
import UncategorizedTransactionsAlert from '../utils/Alerts/UncategorizedTransactionsAlert';
import { DOCUMENT_DOWNLOAD_SUCCESS, saveOptions, REPORTS_MENU_VIEWS } from '../constants/reports.const';
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert';
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading';
import { ReportsListData } from '../utils/BalanceSheetAndIncomeStatementReports/ReportsListData';
import ComparisionReport, { reportTypes } from '../utils/BalanceSheetAndIncomeStatementReports/ComparisionReport';
import { getSegmentsData } from '../utils/BalanceSheetAndIncomeStatementReports/Comparison';
import { resetReportsData } from '../../../../store/actions/reportsData';
import { LeftArrowIcon } from '../Icons/LeftArrowIcon';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider';

interface QueryParams {
    periodText: string;
    time: number;
    endDateYmd: string;
    startDate: number;
    endDate: number;
}
const LEVEL_1_ROWS = ['assets', 'liabilities', 'capital'];
export const REPORT_ITEM_ASSETS = 'Assets';
const TOOLTIP_TEXT =
    'The Balance Sheet lists all your company’s assets, liabilities, and equity at the selected point in time.';

export default function BalanceSheetDetailedReport() {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const store = useStore().getState();
    const [reportsData, setReportsData] = useState<BalanceSheetReportRoot>();
    const query: QueryParams = useParams() as unknown as QueryParams;
    const storeData = useCurrentStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { periodText, time, endDateYmd, startDate, endDate } = query;

    const queryParam = new URLSearchParams(useLocation().search);
    const isCompareQuery = queryParam.get('view');
    const segmentIds = queryParam.get('segments') ?? '';
    const accountId = storeData.currentAccountId;
    const businessId = storeData.currentBusinessId!;
    const businessName = storeData.currentBusiness.name;
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('');
    const [logoImage, setLogoImage] = useState<string | undefined>('');
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState<string>('');
    const [uncategorizedTransactions, setUncategorizedTransactions] =
        useState<number>();
    const [showComparisionReport, isShowComparisionReport] =
        useState<boolean>(false);
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false);
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    );
    const [segmentsIDAndName, setSegmentsIDAndName] = useState();
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    },[setActiveRouteHeading]);
    
    const fetchBalanceSheetReports = (
        periodText: string,
        time: number,
        endDateYmd: string
    ) => {
        setIsLoading(true);
        getBalanceSheetReport(
            accountId,
            businessId,
            periodText,
            time,
            endDateYmd,
            segmentIds
        )
            .then((result: unknown) => {
                setIsLoading(false);
                setReportsData(result as BalanceSheetReportRoot);
            })
            .catch((error) => {
                setIsLoading(false);
                showError(error?.statusText);
            });
    };
    useEffect(() => {
        const reportsFromStore = store.reportsData.reports;
        const result = isEmpty(reportsFromStore)
            ? fetchBalanceSheetReports(periodText, time, endDateYmd)
            : reportsFromStore;
        setReportsData(result);
    }, []);

    useEffect(() =>{
        if(!isCompareQuery || !reportsData){
            return;
        }
        const selectedSegments = reportsData?.selected_classes;
        const segments = getSegmentsData(selectedSegments);
        setSegmentsIDAndName(segments);
    },[isCompareQuery, reportsData])

    useEffect(() => {
        if (isCompareQuery) {
            isShowComparisionReport(true);
        }
    }, [isCompareQuery]);

    useEffect(() => {
        const formmatedText = periodText.replaceAll('+', ' ');
        setFormattedPeriodText(formmatedText);
    }, [periodText]);

    const getTransactionsCount = (message: string): number => {
        return getNumberfromString(message);
    };

    useEffect(() => {
        const warning = reportsData?.warning;
        if (!warning) return;
        const count = getTransactionsCount(warning);
        setUncategorizedTransactions(count);
    }, [reportsData?.warning]);

    useEffect(() => {
        const logo = reportsData?.business.logo;
        logo && setLogoImage(logo);
    }, [reportsData?.business?.logo]);

    useEffect(() => {
        const pdfUrl = getBalanceSheetPDFDownloadUrl(
            accountId,
            businessId,
            periodText,
            endDateYmd,
            time
        );
        setPdfDownloadUrl(pdfUrl);
    }, [accountId, businessId, endDateYmd, periodText, time]);

    const createFileAtLocation = (location: string, closeModal: () => void) => {
        createFile(location)
            .then((result: any) => {
                const { path, account_id } = result.data;
                setSavedDocumentPath(
                    `/documents/folder?folder=${encodeURIComponent(
                        path
                    )}&accountId=${account_id}`
                );
                closeModal();
                setIsSaveSnackbar(true);
            })
            .catch((error) => {
                setIsLoading(false);
                showError(error?.statusText);
            });
    };
    const savePDFReport = (
        requestData: SaveReportsToPortalData,
        closeModal: () => void
    ) => {
        saveBalanceSheetPDFReportToPortal(accountId, businessId, requestData)
            .then((response: unknown) => {
                const data = response as SaveJournal;
                createFileAtLocation(data.location, closeModal);
            })
            .catch((error) => {
                showError(error?.statusText);
            });
    };
    const handleSaveReportToPortal = (
        data: DownloadModalFormData,
        fileType: string,
        closeModal: () => void
    ) => {
        const requestData = {
            comment: data?.comment ?? '',
            end_date_ymd: endDateYmd,
            period_text: periodText,
            time,
        };
        savePDFReport(requestData, closeModal);
    };
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false);
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath);
    };

    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <>
                <ActiveRouterHeadingSection
                    tooltipText={TOOLTIP_TEXT}
                    headingText="Balance Sheet"
                />
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {!reportsData ? (
                            <ErrorAlert />
                        ) : (
                            <>
                                {uncategorizedTransactions && (
                                    <UncategorizedTransactionsAlert
                                        transactionsCount={
                                            uncategorizedTransactions
                                        }
                                    />
                                )}
                                <div
                                    className={
                                        reportsClasses.reportsCommonHeader
                                    }
                                >
                                    <div className="backLinkAndBtn">
                                        <Link data-cy='generate-new-report-btn' to="/reports?name=balanceSheet">
                                        <LeftArrowIcon color={colorTheme.blue200} />
                                            <span>Generate New Report</span>
                                        </Link>
                                        {!isCompareQuery && (
                                            <SaveReports
                                                businessName={businessName!}
                                                downloadReportPDFUrl={
                                                    pdfDownloadUrl
                                                }
                                                options={saveOptions.slice(
                                                    0,
                                                    2
                                                )}
                                                handleSaveToPortal={
                                                    handleSaveReportToPortal
                                                }
                                                reportName={REPORTS_MENU_VIEWS.BALANCE_SHEET}
                                            />
                                        )}
                                    </div>
                                    <div className="headerTextAndBusinessNameLogo">
                                        <div>
                                            <h2>Balance Sheet</h2>
                                            <span>{formattedPeriodText}</span>
                                        </div>
                                        <BusinessNameAndLogo
                                            logoFromApi={logoImage}
                                            accountId={accountId}
                                            businessName={businessName!}
                                        />
                                    </div>
                                </div>
                                {showComparisionReport ? (
                                    <ComparisionReport
                                        reportsData={reportsData}
                                        LEVEL_1_ROWS={LEVEL_1_ROWS}
                                        segmentsIDAndName = {segmentsIDAndName}
                                        reportType={reportTypes.BALANCE_SHEET}
                                    />
                                ) : (
                                    <ReportsListData
                                        reportsData={reportsData}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </>
            {isSaveSnackbar && (
                <UiSnackbarAlert
                    open={isSaveSnackbar}
                    message={DOCUMENT_DOWNLOAD_SUCCESS}
                    handleClose={handleGoToDocument}
                    actionButtonColor={'primary'}
                    actionButtonMessage="View Document"
                    closeMessage="view"
                />
            )}
        </div>
    );
}
