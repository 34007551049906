import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react'
import { Contractors, ImportContractor, PaginatedContractorResponse, LoadingStates } from './models/contractor.model'
import { useCurrentStore } from '../common/hooks/useCurrentStore'
import { AppData, Config } from '../../models'
import { getContractorList } from './apiServices/contractor.api'
import { requestErrorHandler } from '../../services/formService'
import { useRouteMatch } from 'react-router-dom'

interface ContractorState {
    contractors: Contractors
    setContractors: (data: Contractors) => void
    appData: AppData
    currentConfig: Config
    currentAccountId: string
    currentBusinessId: string | undefined
    isLoading: boolean
    setIsLoading: (data: boolean) => void
    fetchContractors: () => void
    importData: ImportContractor
    setImportData: (data: ImportContractor) => void
    path: any
    searchTerm: string
    setSearchTerm: any
    contractorsData: PaginatedContractorResponse & LoadingStates
    setContractorsData: any
    getContractors: any
    loadContractors: any
    searchContractors: any
    loadMore: any
    items: any
}

const ContractorContext = createContext<ContractorState | null>(null)

export const importInitialValues: ImportContractor = {
    id: { id: '' },
    business: { id: '' },
    completed_step: 0,
    column_preferences: [],
}

export function ContractorProvider({ children }: { children: ReactNode }) {
    const { appData, currentConfig, currentAccountId, currentBusinessId } =
        useCurrentStore()
    const [contractors, setContractors] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [importData, setImportData] =
        useState<ImportContractor>(importInitialValues)
    let { path } = useRouteMatch()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [contractorsData, setContractorsData] = useState<
        PaginatedContractorResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 100,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    })

    const fetchContractors = useCallback(() => {
        setIsLoading(true)
        requestErrorHandler(
            getContractorList(currentBusinessId!).then((data: any) => {
                const contractors = data as Contractors
                setContractors(contractors)
                setIsLoading(false)
            }),
            true,
            () => {
                setIsLoading(false)
            }
        )
    }, [currentBusinessId])




    const getContractors = useCallback((params) => {
        requestErrorHandler(
            getContractorList(params).then((response: any) => {
                if (response) {
                    const { itemsCount, items } = response
                    setContractorsData({
                        ...contractorsData,
                        items: [...items] || [],
                        itemsCount: itemsCount,
                        loading: false,
                    })
                }
            })
        )
    }, [])

    const { items } = contractorsData

    // load contractors
    const loadContractors = useCallback(
        (params?: any) => {
            setContractorsData((prev: any) => ({
                ...prev,
                loading: true,
            }))
            getContractors({
                page: 1,
                perPage: 100,
                ...params,
            })
        },
        [getContractors]
    )

    const searchContractors = useCallback(() => {
        const searchParams = {
            email: searchTerm,
        }
        loadContractors(searchTerm ? { ...searchParams } : {})
    }, [loadContractors, searchTerm])

    const loadMore = () => {
        const { page, loading } = contractorsData
        if (!loading) {
            setContractorsData((prev: any) => ({
                ...prev,
                loading: true,
            }))
            getContractors({
                page: page + 1,
                perPage: 100,
            })
        }
    }



    const initialValues: ContractorState = {
        contractors,
        setContractors,
        appData,
        currentConfig,
        currentAccountId,
        currentBusinessId,
        isLoading,
        setIsLoading,
        fetchContractors,
        importData,
        setImportData,
        path,
        searchTerm,
        setSearchTerm,
        contractorsData,
        setContractorsData,
        getContractors,
        loadContractors,
        searchContractors,
        loadMore,
        items,
    }

    return (
        <ContractorContext.Provider value={initialValues}>
            {children}
        </ContractorContext.Provider>
    )
}

export function useContractorContext(): ContractorState {
    const state = useContext(ContractorContext)

    if (!state) {
        throw new Error('useWidgetContext must be used within WidgetContext')
    }

    return state
}
