import {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react'
import {
    getAllLocations,
    getAllPurposes,
    getAllVehicles,
} from '../../../../services/apiService/trips'

const TripsContext = createContext(null)

export function useTripsContext(): any {
    const state = useContext(TripsContext)
    return state
}

const TripsProvider = ({ children }: any) => {
    const [allVehicles, setAllVehicles] = useState<any>([])
    const [allPurposes, setAllPurposes] = useState<any>([])
    const [allLocations, setAllLocations] = useState<any>([])
    const [recallData, setRecallData] = useState<boolean>()
    const [fetchInProgress, setFetchInProgress]= useState<boolean>()

    const fetchRequiredData = useCallback(() => {
        setFetchInProgress(true)
        Promise.all([getAllLocations(), getAllVehicles(), getAllPurposes()])
            .then((res: any) => {
                const [locations, vehicles, purposes] = res
                setAllLocations(locations)
                setAllPurposes(purposes)
                setAllVehicles(vehicles)
                setFetchInProgress(false)
                setRecallData(false)
            })
            .catch((err) => {
                setFetchInProgress(false)
                setRecallData(false)
            })
    }, [])

    useEffect(() => {
        fetchRequiredData()
    }, [fetchRequiredData])

    useEffect(() => {
        if (recallData) {
            fetchRequiredData()
        }
    }, [fetchRequiredData, recallData])

    const providerValue: any = {
        allVehicles,
        allLocations,
        allPurposes,
        fetchInProgress,
        setFetchInProgress,
        setRecallData,
        setAllPurposes,
        setAllVehicles,
        setAllLocations
    }

    return (
        <TripsContext.Provider value={providerValue}>
            {children}
        </TripsContext.Provider>
    )
}

export default TripsProvider
