import {
    Chip,
    Button,
    Grid,
    Hidden,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core'
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { AppData, ApplicationStore } from '../../../models'
import { getReconciliation, getReconciliations } from '../../../services/apiService'
import Loader from '../../common/Loader'
import ReportDoc from '../../../assets/icons-svg/Report-Doc.svg'
import arrowUnpublish from '../../../assets/icons-svg/arrowUnpublish.svg'
import deleteTrash from '../../../assets/icons-svg/deleteTrash.svg'
import editPen from '../../../assets/icons-svg/editPen.svg'
import { currencyFormatter, getMessageTime } from '../../../utils/appUtil'
import { dateFormatMDY } from '../../../utils/dateUtil'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import ComponentMobileHeader from '../../common/ComponentMobileHeader'
import DeleteReconciliation from './DeleteReconciliation'
import UnpublishDialog from './UnpublishDialog'
import ReconciliationModal from './ReconciliationModal'
import { initCategories } from '../../../store/actions/categories'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    seconderyActionButtonOutline: {
        height: 36,
        color: (colorTheme) => `${colorTheme.black100} !important`,
    },
    buttonGap: {
        marginRight: theme.spacing(2),
    },
    listRoot: {
        width: '100%',
        height: 'calc(100vh - 328px)',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 258px)',
        },
    },
    itemSpace: {
        marginTop: '20px',
    },
    progressStatus: {
        padding: '16px 16px',
        height: '40px',
        background: (colorTheme) => colorTheme.grey100,
        borderRadius: '8px',
        margin: '20px 0px',
    },
    progressMainText: {
        fontWeight: 'bold',
        fontSize: '15px',
        lineHeight: '20px',
        letterSpacing: '0.15px',
        color: (colorTheme) => colorTheme.black100,
    },
    progessMoreText: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: (colorTheme) => colorTheme.grey400,
    },
}))

interface ReconciliationDetailsProps {
    appData: AppData
    selectedData?: any
    setSelectedData?: any
    reloadCurrentState?: any
    hasOneItem?: any
    category?: any, 
    allBankAccounts?: any
}

function ReconciliationDetails({
    appData,
    selectedData,
    setSelectedData,
    reloadCurrentState,
    hasOneItem,
    category,
    allBankAccounts
}: ReconciliationDetailsProps) {
    const dispatch = useDispatch()
    const { colorTheme } = useThemeContext()
    const styles = commonStyles()
    const classes = useStyles(colorTheme)
    const history = useHistory()
    let { id }: any = useParams()
    let location: any = useLocation()
    let query = new URLSearchParams(useLocation().search)
    let accountQuery = query.get('account')
    const [reconciliation, setReconciliation] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false)
    const [openReconcileModal, setOpenReconcileModal] = useState<boolean>(false)
    const [lastItemId, setLastItemId] = useState<string | undefined>()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    let showUnpublishAction =
        (hasOneItem || location?.state?.onlyOneItem || (lastItemId === reconciliation?.id)) &&
        reconciliation?.is_submitted

    const buttonActions: any = useMemo(() => {
        return reconciliation?.is_submitted
            ? showUnpublishAction
                ? [
                      {
                          title: 'Unpublish',
                          iconType: 'image',
                          iconAlt: 'unpublish',
                          action: 'unpublish',
                          showDivider: true,
                          icon: arrowUnpublish,
                          class: styles.mr16,
                      },
                      {
                          title: 'Report',
                          iconType: 'image',
                          iconAlt: 'report',
                          action: 'report',
                          showDivider: false,
                          icon: ReportDoc,
                      },
                  ]
                : [
                      {
                          title: 'Report',
                          iconType: 'image',
                          iconAlt: 'report',
                          action: 'report',
                          showDivider: false,
                          icon: ReportDoc,
                      },
                  ]
            : [
                  {
                      title: 'Delete',
                      iconType: 'image',
                      iconAlt: 'delete',
                      action: 'delete',
                      showDivider: true,
                      icon: deleteTrash,
                      class: styles.mr16,
                  },
                  {
                      title: 'Edit',
                      iconType: 'image',
                      iconAlt: 'edit',
                      action: 'edit',
                      showDivider: false,
                      icon: editPen,
                  },
              ]
    }, [reconciliation?.is_submitted, showUnpublishAction, styles.mr16])

    useEffect(() => {
        if(smDevice) {
            dispatch(initCategories())
        }
    }, [dispatch, smDevice])

    useEffect(() => {
        const loadReconciliation = () => {
            getReconciliation(
                appData.current_account_id,
                appData.current_business_id,
                id
            ).then((r: any) => {
                setReconciliation(r)
                setSelectedData?.(r)
                setLoading(false)
            })
        }
        if (selectedData) {
            setReconciliation(selectedData)
        } else {
            setLoading(true)
            getReconciliations(appData.current_account_id, appData.current_business_id, {
                account: accountQuery,
                page: 1,
                per_page: 10,
            }).then((res: any) => {
                if(res) {
                    setLastItemId(res?.items?.[0]?.id)
                }
            })
            loadReconciliation()
        }
    }, [accountQuery, 
        appData.current_account_id, 
        appData.current_business_id, 
        id, 
        selectedData, 
        setSelectedData])


    const handleModalClose = (result: any) => {
        setOpenReconcileModal(false)
    }

    const redirect = (id: string) => {
        history.push(
            `/reconcile/${id}/reconcile?account=${reconciliation.account}`
        )
    }

    const reconcileData = (reconciliation: any) => {
        return [
            {
                grid: 6,
                label: 'Start Date',
                node: <>{dateFormatMDY(reconciliation.start_date * 1000)}</>,
            },
            {
                grid: 6,
                label: 'Start Balance',
                node: (
                    <>
                        {currencyFormatter.format(reconciliation.start_balance)}
                    </>
                ),
            },
            {
                grid: 6,
                label: 'End Date',
                node: <>{dateFormatMDY(reconciliation.end_date * 1000)}</>,
            },
            {
                grid: 6,
                label: 'End Balance',
                node: (
                    <>{currencyFormatter.format(reconciliation.end_balance)}</>
                ),
            },
            {
                grid: 12,
                label: 'Reconciled Amount',
                node: <>{currencyFormatter.format(reconciliation.balance)}</>,
            },
            {
                grid: 12,
                label: 'Transactions Reconciled',
                node: <>{reconciliation.transaction_count}</>,
            },
            {
                grid: 12,
                label: 'Description',
                node: <>{reconciliation.description}</>,
            },
        ]
    }

    const handleButtonClick = (type: string) => {
        const reconciliationId = reconciliation?.id;
        switch (type) {
            case 'delete':
                setOpenDeleteModal(true)
                break
            case 'edit':
                setOpenReconcileModal(true)
                break
            case 'unpublish':
                setOpenUnpublishDialog(true)
                break
            case 'report':
                if(reconciliationId){
                    history.push(`/reports/reconcile/${reconciliationId}`)
                }
                break
            default:
                break
        }
    }

    return (
        <Grid container direction="row" style={{ height: '100%' }}>
            <ReconciliationModal
                open={openReconcileModal}
                isNew={false}
                handleClose={(result: any) => handleModalClose(result)}
                reconciliation={reconciliation}
                category={category}
            />
            <DeleteReconciliation
                openDeleteModal={openDeleteModal}
                reloadCurrentState={reloadCurrentState}
                setOpenDeleteModal={setOpenDeleteModal}
                accountId={appData.current_account_id}
                businessId={appData.current_business_id}
                reconciliationId={reconciliation?.id}
            />
            <UnpublishDialog
                openUnpublishDialog={openUnpublishDialog}
                reloadCurrentState={reloadCurrentState}
                setOpenUnpublishDialog={setOpenUnpublishDialog}
                setReconciliation={setReconciliation}
                accountId={appData.current_account_id}
                businessId={appData.current_business_id}
                reconciliationId={reconciliation?.id}
            />
            {!reconciliation || loading ? (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Loader />
                </Grid>
            ) : (
                <Grid container direction="column" alignItems="stretch">
                    <div>
                        <ComponentMobileHeader
                            title={
                                reconciliation.is_submitted ? (
                                    'Reconciliations'
                                ) : (
                                    <>
                                        {getMessageTime(
                                            reconciliation.start_date * 1000
                                        )}{' '}
                                        -{' '}
                                        {getMessageTime(
                                            reconciliation.end_date * 1000
                                        )}
                                    </>
                                )
                            }
                            handleBackAction={() => {
                                history.push(
                                    `/reconcile?account=${accountQuery}`
                                )
                            }}
                            showBottomDivider
                            menuItemActions={buttonActions}
                            handleItemClickType={handleButtonClick}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <Grid container alignItems="center">
                            <Hidden smDown>
                                <Grid
                                    item
                                    md={6}
                                    xs={10}
                                    sm={10}
                                    container
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Typography variant="h6">
                                        {dateFormatMDY(
                                            reconciliation.start_date * 1000
                                        )}{' '}
                                        -{' '}
                                        {dateFormatMDY(
                                            reconciliation.end_date * 1000
                                        )}
                                    </Typography>
                                </Grid>
                            </Hidden>
                            {/* Reconciliation Details Buttons in Desktop */}
                            <Hidden smDown>
                                <Grid
                                    item
                                    md={6}
                                    xs={2}
                                    sm={2}
                                    container
                                    justify="flex-end"
                                >
                                    <div>
                                        {buttonActions.map((button: any) => (
                                            <Button
                                                key={button.title}
                                                variant="outlined"
                                                startIcon={
                                                    <img
                                                        alt={button.iconAlt}
                                                        src={button.icon}
                                                    />
                                                }
                                                className={button.class}
                                                onClick={() => {
                                                    handleButtonClick(
                                                        button.action
                                                    )
                                                }}
                                            >
                                                {button.title}
                                            </Button>
                                        ))}
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Grid container alignItems="center" direction="row">
                            {reconciliation.is_submitted ? (
                                <div style={{ margin: '20px 0px' }}>
                                    <Chip
                                        label={
                                            <>
                                                Submitted{' '}
                                                {dateFormatMDY(
                                                    reconciliation.updated *
                                                        1000
                                                )}
                                            </>
                                        }
                                    />
                                </div>
                            ) : (
                                <div
                                    className={mergeStyle(
                                        styles.flex,
                                        styles.spaceBetween,
                                        styles.fullWidth,
                                        classes.progressStatus
                                    )}
                                >
                                    <div>
                                        <div
                                            className={classes.progressMainText}
                                        >
                                            In Progress
                                        </div>
                                        <div
                                            className={classes.progessMoreText}
                                        >
                                            {dateFormatMDY(
                                                reconciliation.created * 1000
                                            )}
                                        </div>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            redirect(reconciliation.id)
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            )}
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid
                                item
                                xs={12}
                                container
                                alignItems="center"
                                direction="row"
                            >
                                <Typography variant="h6">Details</Typography>
                            </Grid>
                            {reconcileData(reconciliation).map((item: any) => (
                                <Grid
                                    key={item.label}
                                    item
                                    xs={item.grid}
                                    container
                                    className={classes.itemSpace}
                                    direction="column"
                                >
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {item.label}
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                    >
                                        {item.node}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Grid>
            )}
        </Grid>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
})
export default connect(mapStateToProps)(ReconciliationDetails)
