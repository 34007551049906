import React, { Fragment } from 'react';
import {
    Hidden,
    makeStyles,
    Theme,
    Box,
    Typography,
    Divider,
} from '@material-ui/core'

/**
 * Usecase - Showing Header in Mobile Devices
 */

const useClass = makeStyles((theme: Theme) => ({
    mobileHeader: {
        boxSizing:"border-box",
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
    },
    divider: {
        width: '100%',
        marginBottom: '1rem',
    },
}))

type mobileHeaderProps = {
    children?: React.ReactNode
    title?: string | React.ReactNode
    showBottomDivider?: boolean
}

const MobileHeader = (props: mobileHeaderProps) => {
    const classes = useClass()
    const { children, title, showBottomDivider } = props
    return (
        <Hidden mdUp>
            <Fragment>
                {children ? (
                    children
                ) : (
                    <Box className={classes.mobileHeader}>
                        <Typography variant="h6">{title}</Typography>
                    </Box>
                )}
                {showBottomDivider && (
                    <div className={classes.divider}>
                        <Divider variant="fullWidth"></Divider>
                    </div>
                )}
            </Fragment>
        </Hidden>
    )
}

export default MobileHeader;