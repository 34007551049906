import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { toRem16 } from '../../../../../styles/commonStyles';
import { currencyFormatter } from '../../../../../utils/appUtil';
import UiText from '../../../../common/ui/UiText';
import {
    Assets,
    Capital,
    Liabilities,
    Revenue,
    Expenses,
} from '../../models/reports-interface';
import { reportTypes } from './ComparisionReport';
import { getClassWiseBalance } from './Comparison';
import { REPORT_ITEM_ASSETS } from '../../generated-reports/BalanceSheetDetailedReport';
import { ThemeColors } from '../../../../../styles/models/Colors.interface';

interface GenerateColumnProps {
    reportsData: any;
    LEVEL_1_ROWS: Array<string>;
    reportType?: string;
}
interface GenerateReportsTableProps {
    items: Assets | Liabilities | Capital | Revenue | Expenses;
    segmentId: string;
    reportType?: string;
}
interface GenerateTotalSectionProps {
    data: any;
    total: string;
}
interface GenerateTotalsColumnProps {
    items: any;
    LEVEL_1_ROWS: Array<string>;
    reportsData: any;
    reportType?: reportTypes;
}

export const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        segmentColumns: {
            display: 'flex',
            flex: '1',
            minWidth: 0,
            overflowX: 'scroll',
            margin: '0 1.5rem 1rem 1.5rem',
            '& .segmentColumn': {
                minWidth: toRem16(160),
                textAlign: 'right',
                height: 'max-content',
            },
            '& .segmentColumn:nth-child(2n+1)': {
                background: (colorTheme) => colorTheme.blue500,
            },
            '& .comparisionReport': {
                '& .segmentName': {
                    height: toRem16(48),
                    display: 'flex',
                    alignItems: 'baseline',
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    boxShadow: '0px 4px 3px 1px rgb(0 0 0 / 14%)',
                    backdropFilter: 'blur( 20px )',
                    '& p': {
                        maxWidth: '8rem',
                        width: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        padding: '1rem 1rem 0 0',
                    },
                },

                '& .priceWaste': {
                    color: (colorTheme) => colorTheme.red100
                },
                '& p': {
                    marginLeft: 'auto',
                    padding: '0 1rem 0 0 ',
                    maxWidth: '8rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                },
                '& .level4Item': {
                    padding: 0,
                    marginLeft: 'auto',
                },
                '& .totalLiabilities': {
                    padding: '0.6rem 0 0 0',
                    height: '2.5rem',
                    borderTopColor: (colorTheme) => colorTheme.grey400,
                    borderTopStyle: 'solid',
                    borderTopWidth: '0.25rem',
                },

                '& .level1TitleHeightedBlock': {
                    height: '3.5rem',
                    margin: '3rem 0 0 0',
                    '& .level1Title': {
                        borderBottom: 0,
                        margin: 0,
                    },
                },
                '& .level2TitleHeightedBlock': {
                    height: '2.9rem',
                    margin: '0.5rem 0 0 0',
                    '& .l2Title': {
                        borderBottom: 0,
                    },
                },

                '& .level2Total': {
                    height: '2.9rem',
                    padding: '0.3rem 0 0 2rem',
                    '& p': {
                        padding: '0.6rem 1rem 0 0',
                    },
                },
                '& .level1TotalBalanceSheet':{
                    height: '2.5rem',
                    padding: '0.6rem 0 0 0',
                    borderTop: (colorTheme) => `${toRem16(2)} solid ${colorTheme.grey200}`,
                },
                '& .level1TotalIncomeStatement':{
                    height: '2.5rem',
                    padding: '0.6rem 0 0 0',
                    borderTop: (colorTheme) => `${toRem16(2)} solid ${colorTheme.grey200}`,
                },
                '& .level1TotalBalanceSheetAssets':{
                    height: '2.5rem',
                    padding: '0.6rem 0 0 0',
                    borderTop: (colorTheme) =>  `${toRem16(4)} solid ${colorTheme.grey400}`,
                },
                '& .level3Items': {
                    padding: '0',
                    height: '2.9rem',
                    borderBottom: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
                    display: 'flex',
                    alignItems: 'center',
                },
                '& .level2HeaderNoChild': {
                    height: '2.5rem',
                    padding: '0.5rem 0 0 0',
                },
            },
            '& .baseColumnWithLabels': {
                borderRight: '1px solid',
                borderRightColor: (colorTheme) =>  colorTheme.grey200,
                minWidth: toRem16(256),
                position: 'sticky',
                top: 0,
                left: 0,
                zIndex: 2,
                background: (colorTheme) =>  colorTheme.primaryWhite,
                height: 'max-content',
                '& .segmentName': {
                    background: (colorTheme) =>  colorTheme.primaryWhite,
                },
                '& .level3Items': {
                    '& p': {
                        padding: '0',
                    },
                    margin: '0 0 0 2rem'
                },
                '& .level2Total': {
                    '& p': {
                        padding: '1rem 0 0 2rem',
                    },
                },
                '& .totalLiabilities': {
                    padding: '0.6rem 0 0 0 ',
                },
                '& .level2HeaderNoChild': {
                    '& p':{
                        padding: '0 0 0 1rem'
                    }
                },
                '& p': {
                    marginLeft: '0 !important',
                    maxWidth: '15rem !important',
                    width: 'auto !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                },
                '& .level2Title': {
                    padding: '0 0 0 1rem !important',
                },
            },
        },
    })
);

export const GenerateTotalSection = (props: GenerateTotalSectionProps) => {
    const { data, total } = props;
    if (!data) return null;
    return (
        <div className="totalLiabilities">
            <UiText
                weight="bold_700"
                variant="motorcycle_90"
                className={parseFloat(total) < 0 ? 'priceWaste' : ''}
            >
                {currencyFormatter.format(parseFloat(total))}
            </UiText>
        </div>
    );
};
const getClassNameForLevel1Total = (itemName: string, reportType : string | undefined) =>{
    if(!reportType){
        return 'level1TotalBalanceSheet';
    }
    if(itemName === REPORT_ITEM_ASSETS){
        return 'level1TotalBalanceSheetAssets';
    }
    if( reportType === reportTypes.INCOME_STATEMENT){
        return 'level1TotalIncomeStatement';
    }
    return 'level1TotalBalanceSheet';
}
export const GenerateTotalsColumn = (props: GenerateTotalsColumnProps) => {
    const { items, LEVEL_1_ROWS, reportsData, reportType } = props;
    
    if (!reportsData) {
        return null;
    }
    const total =
        reportType === reportTypes.INCOME_STATEMENT
            ? items?.total_net_income
            : items?.total_liabilities_and_capital ?? '';
    return (
        <div className="segmentColumn comparisionReport">
            <div className="segmentName">
                <UiText weight="semi_bold_600" variant="motorcycle_90">
                    Totals
                </UiText>
            </div>
            {LEVEL_1_ROWS.map((report: string, index: number) => {
                return (
                    <div key={index}>
                        {reportsData[report]?.children.length > 0 && (
                            <>
                                <div className="level1TitleHeightedBlock"></div>
                                {reportsData[report].children.map(
                                    (
                                        l2Items:
                                            | Assets
                                            | Liabilities
                                            | Capital
                                            | Revenue
                                            | Expenses,
                                        index: number
                                    ) => {
                                        return (
                                            <div key={index}>
                                                {l2Items.children.length > 0 ? (
                                                    
                                                    <div className="level2Items">
                                                        <div className="level2TitleHeightedBlock">
                                                            {' '}
                                                        </div>
                                                        {l2Items.children.map(
                                                            (
                                                                l3Items:
                                                                    | Assets
                                                                    | Liabilities
                                                                    | Capital
                                                                    | Revenue
                                                                    | Expenses,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="level3Items"
                                                                    >
                                                                        {l3Items.account_id && (
                                                                            <UiText
                                                                                weight="bold_700"
                                                                                variant="motorcycle_90"
                                                                                className={
                                                                                    parseFloat(
                                                                                        l3Items.balance
                                                                                    ) <
                                                                                    0
                                                                                        ? 'priceWaste level3ItemTitle'
                                                                                        : 'level3ItemTitle'
                                                                                }
                                                                            >
                                                                                {currencyFormatter.format(
                                                                                    parseFloat(
                                                                                        l3Items.balance
                                                                                    )
                                                                                )}
                                                                            </UiText>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div className="level2Total">
                                                            <UiText
                                                                weight="bold_700"
                                                                variant="motorcycle_90"
                                                                className={
                                                                    parseFloat(
                                                                        l2Items.balance
                                                                    ) < 0
                                                                        ? 'priceWaste'
                                                                        : ''
                                                                }
                                                            >
                                                                {' '}
                                                                {currencyFormatter.format(
                                                                    parseFloat(
                                                                        l2Items.balance
                                                                    )
                                                                )}
                                                            </UiText>
                                                        </div>
                                                    </div>
                                                    
                                                      
                                                ) : (
                                                    <div className="level2HeaderNoChild">
                                                        <UiText
                                                            className={
                                                                parseFloat(
                                                                    l2Items.balance
                                                                ) < 0
                                                                    ? 'priceWaste'
                                                                    : ''
                                                            }
                                                            weight="bold_700"
                                                            variant="motorcycle_90"
                                                        >
                                                            {currencyFormatter.format(
                                                                    parseFloat(
                                                                        l2Items.balance
                                                                    )
                                                                )}
                                                          
                                                        </UiText>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                                <div className={getClassNameForLevel1Total(reportsData[report].title, reportType)}>
                                  <UiText  className={
                                                                parseFloat(
                                                                    reportsData[report]?.balance
                                                                ) < 0
                                                                    ? 'priceWaste'
                                                                    : ''
                                                            } weight="bold_700"
                                                            variant="motorcycle_90"> 
                                                            {currencyFormatter.format(
                                                                    parseFloat(
                                                                        reportsData[report]?.balance
                                                                    )
                                                                )}
                                                            
                                                            </UiText>
                                </div>

                            </>
                        )}
                    </div>
                );
            })}
            <GenerateTotalSection data={items} total={total} />
        </div>
    );
};
export const GenerateBaseColumn = (props: GenerateColumnProps) => {
    const { reportsData, LEVEL_1_ROWS, reportType } = props;
   
    return (
        <div className="comparisionReport baseColumnWithLabels">
            <div className="segmentName"></div>

            {LEVEL_1_ROWS.map((report: string, index) => {
                return (
                    <div key={index}>
                        {reportsData[report]?.children.length > 0 && (
                            <div className="level1TitleHeightedBlock">
                                <UiText
                                    weight="medium_500"
                                    className="level1Title"
                                    variant="suv_150"
                                >
                                    {reportsData[report]?.title}
                                </UiText>
                            </div>
                        )}

                        {reportsData[report].children.length > 0 ? (
                            <>
                                {reportsData[report].children.map(
                                    (
                                        l2Items:
                                            | Assets
                                            | Liabilities
                                            | Capital
                                            | Revenue
                                            | Expenses,
                                        index: number
                                    ) => {
                                        return (
                                            <div key={index}>
                                                {l2Items.children.length > 0 ? (
                                                    <div className="level2Items">
                                                        <div className="level2TitleHeightedBlock">
                                                            <UiText
                                                                variant="hatchback_125"
                                                                weight="medium_500"
                                                                className="level2Title"
                                                            >
                                                                {l2Items.title}
                                                            </UiText>
                                                        </div>

                                                        {l2Items.children.map(
                                                            (
                                                                l3Items:
                                                                    | Assets
                                                                    | Liabilities
                                                                    | Capital
                                                                    | Revenue
                                                                    | Expenses,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="level3Items"
                                                                    >
                                                                        {l3Items.account_id && (
                                                                            <UiText
                                                                                weight="regular_400"
                                                                                variant="motorcycle_90"
                                                                                className="level3ItemTitle"
                                                                            >
                                                                                {
                                                                                    l3Items.title
                                                                                }
                                                                            </UiText>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div className="level2Total">
                                                            <UiText
                                                                weight="medium_500"
                                                                variant="motorcycle_90"
                                                            >
                                                                Total{' '}
                                                                {l2Items.title}
                                                            </UiText>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="level2HeaderNoChild">
                                                        <UiText
                                                            weight="medium_500"
                                                        >
                                                            {l2Items.title}
                                                        </UiText>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}{' '}
                                <div className={getClassNameForLevel1Total(reportsData[report]?.title, reportType)}>
                                <UiText
                                                            weight="semi_bold_600"
                                                            variant="motorcycle_90"
                                                            
                                                        >
                                                           Total {reportsData[report]?.title}
                                                        </UiText>
                                  
                                </div>

                            </>
                        ) : null}
                    </div>
                );
            })}
            {reportType === reportTypes.INCOME_STATEMENT ? (
                <div className="totalLiabilities">
                    <UiText weight="semi_bold_600" variant="motorcycle_90">
                        Net Income{' - '}
                        {parseFloat(reportsData.total_net_income) > 0
                            ? 'Profit'
                            : 'Loss'}
                    </UiText>
                </div>
            ) : (
                <div className="totalLiabilities">
                    <UiText weight="semi_bold_600" variant="motorcycle_90">
                        Total {reportsData.liabilities?.title} and{' '}
                        {reportsData.capital?.title}
                    </UiText>
                </div>
            )}
        </div>
    );
};

export const GenerateReportsTable = (props: GenerateReportsTableProps) => {
    const { items, segmentId, reportType } = props;
    if (!items || !segmentId) {
        return null;
    }
    
   
    const generateClassWiseBalanceNode = (items: any, segmentId : string) =>{
        const balance = getClassWiseBalance(items, segmentId);
        if(!balance){
            return;
        }
        const classNameValue = balance && parseFloat(balance) < 0 ? 'priceWaste' : '';
        return (
                 <UiText
                weight="regular_400"
                variant="motorcycle_90"
                className={classNameValue}
            >
                {currencyFormatter.format(
                    parseFloat(
                        balance
                    )
                )}
            </UiText>
        )
    }   
    
    return (
        <>
            {items.children.length > 0 ? (
                <>
                    <div className="level1TitleHeightedBlock"></div>

                    {items.children.map((l2Items: any, index: number) => {
                        return (
                            <div key={index}>
                                {l2Items.children.length > 0 ? (
                                    <div className="level2Items">
                                        <div className="level2TitleHeightedBlock"></div>

                                        {l2Items.children.map(
                                            (l3Items: any, index: number) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="level3Items"
                                                    >
                                                        {l3Items.account_id && (
                                                            <>
                                                                {l3Items.children.map(
                                                                    (
                                                                        l4Item: any,
                                                                        index: number
                                                                    ) => {
                                                                        return (
                                                                           <>
                                                                                {l4Item.class_id ===
                                                                                    segmentId && (
                                                                                        <div key={
                                                                                            index
                                                                                        }
                                                                                       
                                                                                        className="level4Item"
                                                                                    >
                                                                                    <div
                                                                                        className={
                                                                                            parseFloat(
                                                                                                l4Item.balance
                                                                                            ) <
                                                                                            0
                                                                                                ? 'priceWaste'
                                                                                                : ''
                                                                                        }
                                                                                    >
                                                                                        <UiText
                                                                                            weight="regular_400"
                                                                                            variant="motorcycle_90"
                                                                                        >
                                                                                            {currencyFormatter.format(
                                                                                                parseFloat(
                                                                                                    l4Item.balance
                                                                                                )
                                                                                            )}
                                                                                        </UiText>
                                                                                    </div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                        <div className='level2Total'>
                                          {
                                              generateClassWiseBalanceNode(l2Items, segmentId)
                                          }
                                        </div>
                                    </div>
                                ) : (
                                    <div className="level2HeaderNoChild">
                                             {
                                                generateClassWiseBalanceNode(l2Items, segmentId)
                                            }
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div className={getClassNameForLevel1Total(items.title, reportType)}>
                        {
                            
                            generateClassWiseBalanceNode(items, segmentId)
                        }   
                        </div>
                </>
            ) : null}
        </>
    );
};
