import React, { useState } from 'react';
import { Button, makeStyles, Theme, Divider } from '@material-ui/core'
import UiChip from '../../common/ui/UiChip'
import {
    getInvoiceStatus,
    statusIcon,
    IsOverDueInvoicePaid,
    paymentTermsKeys,
} from './InvoiceUtil'
import { longDateFormat } from '../../../utils/dateUtil'
import Icon from '../../../components/common/Icon'
import ProductDetailsTable from './ProductDetailsTable'
import UiText from '../../common/ui/UiText'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import {
    invoicePdfURL,
    updatePaymentStatus,
} from '../../../services/apiService/invoice'
import { BusinessDetails } from '../../../components/common/BusinessDetails'
import { BusinessLogo } from '../../../components/common/BusinessLogo'
import EmailInvoiceModal from './EmailInvoiceModal'
import UiTextView from '../../../components/common/ui/UiTextView'
import { currencyFormatter } from '../../../utils/appUtil';
import {
    OutlinedDeleteButton
} from '../../common/DeleteButton'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    itemText: {
        marginLeft: '0px',
    },
    paidChipColor: {
        backgroundColor: (colorTheme) => colorTheme.teal200,
    },
    overDueChipColor: {
        backgroundColor: (colorTheme) => colorTheme.yellow100
    },
    billInfo: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '16px',
        marginTop: '32px !important',
    },
    listContainer: {
        height: 'calc(100vh - 400px)',
        overflow: 'auto',
        overflowX: 'hidden',
        marginTop: '20px',
    },
    detailsContent: {
        overflow: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 200px)',
        '& .invoice-details > *': {
            marginTop: '16px',
        },
    },
    detailContainer: {
        width: '75%'
    },
    logoContainer: {
        width: '25%'
    },
    marginLeft: {
        marginLeft: '12px',
    },
    buttonMargin: {
        marginLeft: '5px',
    },
    paymentLine: {
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: '8px', 
        marginTop: '8px'
    },
    messageFieldStyle: {
        display: 'flex', 
        marginTop: '32px !important',
        marginBottom: '32px'
    },
    listMargin: {
        paddingRight: "16px",
        marginTop: "14px",
    },
    chipLabelStyle: {
        marginLeft: '5px',
        'font-weight': '500'
    },
    messageIconStyle: {
        display: "block !important",
    }
}))

const actionButtons: {
    label: string
    icon: string
    action: string
}[] = [
    {
        label: 'Send',
        icon: 'sms',
        action: 'send_email',
    },
    {
        label: 'PDF',
        icon: 'download',
        action: 'download',
    },
]

const markPaidButton = {
    label: 'Mark Paid',
    icon: 'tick',
    action: 'mark_paid',
}
const markUnpaidButton = {
    label: 'Mark Unpaid',
    icon: 'undo',
    action: 'mark_unpaid',
}
const InvoiceDetails = ({
    invoice,
    setLoading,
    setCurrentInvoice,
    setSelectedInvoice,
    reloadPage,
    setFetchCurrentInvoice,
    setShowDeleteModal,
    setOpenModal
}: {
    invoice: any
    setCurrentInvoice: React.Dispatch<React.SetStateAction<any>>
    setSelectedInvoice?: React.Dispatch<React.SetStateAction<any>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    reloadPage?: () => void
    setFetchCurrentInvoice: React.Dispatch<React.SetStateAction<boolean>>
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const common = commonStyles()
    const [openEmailModal, setOpenEmailModal] = useState(false)
    //const [openStatusChangeModal, setOpenStatusChangeModal] = useState(false)

    const invoiceStatus = React.useMemo(() => {
        return getInvoiceStatus(invoice)
    }, [invoice])

    const isDueInvoicePaid = React.useMemo(() => {
        return IsOverDueInvoicePaid(invoice)
    }, [invoice])
    

    const PaidOverDueStatus = isDueInvoicePaid ? " (Overdue)" : ""
    const PaymentTerm = paymentTermsKeys[invoice.payment_terms]
    const invoicePaymentDate =
    invoiceStatus === 'Paid' ? invoice?.payment_date : undefined
    const emailSentdate  = invoice.is_email_sent ?  invoice.email_sent_at : undefined
    const buttonList = ['Unpaid', 'Overdue'].includes(invoiceStatus)
        ? actionButtons.concat(markPaidButton)
        : actionButtons.concat(markUnpaidButton)

    const { address, city, state, country, zip_code } =
        invoice?.contact_data?.address || {}

    const downloadInvoice = () => {
        const link = document.createElement('a')
        link.setAttribute('id', 'download-link')
        link.target = '_blank'
        link.href = `${invoicePdfURL(invoice.id)}`
        link.click()
        let downloadLink = document.getElementById('download-link')
        downloadLink && downloadLink?.parentNode?.removeChild(downloadLink)
    }

    const updateStatus = (status: string) => {
        setLoading(true)
        updatePaymentStatus(invoice.id, status).then((response) => {
            if (response) {
                setCurrentInvoice(response)
                setSelectedInvoice?.(response)
                setLoading(false)
                reloadPage?.()
            }
        })
    }

    const RenderPaymentInfo = (label: string, value: string) => (
        <div className={styles.paymentLine}>
            <UiText textColor="textSecondary">{label}</UiText> &nbsp;
            <UiText>{value}</UiText>
        </div>
    )

    const sendEmailInvoice = () => {
        setOpenEmailModal(true)
    }

    const buttonAction = (action: string) => {
        switch (action) {
            case 'download':
                downloadInvoice()
                break
            case 'send_email':
                sendEmailInvoice()
                break
            case 'mark_paid':
                updateStatus('paid')
                break
            case 'mark_unpaid':
                updateStatus('unpaid')
                break
        }
    }

    return (
        <div className={styles.detailsContent}>
            <EmailInvoiceModal
                open={openEmailModal}
                invoice={invoice}
                handleClose={() => {
                    setOpenEmailModal(false)
                }}
                callback={() => {
                    setFetchCurrentInvoice(true)
                    reloadPage?.()
                }}
            />
            <div className={styles.paymentLine}>
                <UiText variant="suv_150">Invoice #{invoice.number}</UiText>
                <div>
                    <OutlinedDeleteButton
                        variant="outlined"
                        color="secondary"
                        showDeleteIcon
                        onClick={() => {
                            setShowDeleteModal(true)
                        }}
                        className={common.mr16}
                        data-cy="open-delete-confirm-modal"
                        >
                        Delete
                    </OutlinedDeleteButton>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={
                            <Icon icon="edit" size="18px"/>
                        }
                        onClick={() => {
                            setOpenModal(true)
                        }}
                        className={common.mr8}
                        data-cy="open-edit-invoice-modal"
                    >
                        Edit
                    </Button>
                </div>
           
            </div>
            <div className={mergeStyle("invoice-details", styles.listMargin)}>
                
                {/* Invoice Status Icon */}
                <div>
                    {buttonList.map((btn: any) => (
                        <Button
                            key={btn.label}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                buttonAction(btn.action)
                            }}
                            startIcon={<Icon icon={btn.icon} />}
                            classes={{
                                root:
                                    btn.label !== 'Send'
                                        ? styles.buttonMargin
                                        : '',
                            }}
                        >
                            {btn.label}
                        </Button>
                    ))}
                </div>
                <div className={mergeStyle(common.flex, common.spaceBetween)}>
                    <div className={styles.detailContainer}>
                        <div className={mergeStyle(common.flex, common.spaceBetween)}>
                            <div>
                                <UiChip
                                    label={invoiceStatus}
                                    renderIcon={true}
                                    iconName={statusIcon[invoiceStatus]}
                                    customRootStyles={
                                        invoiceStatus === 'Paid' ? styles.paidChipColor : 
                                        invoiceStatus === 'Overdue' ? styles.overDueChipColor : 
                                        ''
                                    }
                                    labelTextStyle={
                                        styles.chipLabelStyle
                                    }
                                />
                            </div>
                            <UiText variant="suv_150" weight="medium_500">{currencyFormatter.format(invoice?.total)}</UiText>
                        </div>

                        {/* Invoice Dates */}
                        <div>
                            {invoicePaymentDate && <>
                                {RenderPaymentInfo("Date Paid", `${longDateFormat(invoicePaymentDate as number)}${PaidOverDueStatus}`) }
                                <Divider />
                            </>}
                            {emailSentdate && <>
                                {RenderPaymentInfo("Date Sent", `${longDateFormat(emailSentdate as number)}`)}
                                <Divider />
                            </>}
                            {RenderPaymentInfo("Due Date", `${longDateFormat(invoice.due_date)} (${PaymentTerm})`)}
                            <Divider />
                            {RenderPaymentInfo("Date Issued", `${longDateFormat(invoice.invoice_date)}`)}
                        </div>
                    </div>
                    <div className={styles.logoContainer}><BusinessLogo /></div>

                </div>
                
                {/* Billing Informations */}
                <div className={styles.billInfo}>
                    <div>
                        <UiText>Bill To</UiText>
                        <div className={common.mt16}>
                            <UiText weight="medium_500">
                                {invoice?.contact_data?.name}
                            </UiText>
                            <UiText textColor="textSecondary">
                                {address} <br /> 
                                {city} {state} {zip_code} {country}{' '}
                                <br />
                                {invoice?.contact_data?.phone && <UiTextView type='phone' value={invoice?.contact_data?.phone} />}
                            </UiText>
                        </div>
                    </div>
                    <div>
                        <UiText>Bill From</UiText>
                        <div className={common.mt16}><BusinessDetails /></div>
                    </div>
                </div>
                <UiText variant="suv_150">Items</UiText>
                <ProductDetailsTable
                    products={invoice.invoice_lines}
                    showTaxColumn={invoice.use_taxes}
                    invoice={invoice}
                />
                <div className={styles.messageFieldStyle}>
                    <Icon icon="text" customClass={styles.messageIconStyle}/>
                    <div className={styles.marginLeft}>
                        <UiText variant="motorcycle_90">Message</UiText>
                        <UiText className={common.preWhiteSpace}>{invoice.message_to_customer ?? "-"}</UiText>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceDetails
