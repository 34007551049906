import React from 'react';
import {
    Container,
    Typography,
    withStyles,
    WithStyles,
    Theme,
    Grid,
    Link,
} from '@material-ui/core';
import LinkButton from '../../common/LinkButton';
import Recycle from '../../../assets/icons-svg/Recycle.svg';
import Logo from '../../../assets/logo/Logo.svg';
import { COLORS } from '../../../variables/colors';
interface BoundaryState {
    hasError: boolean;
}
interface BoundaryProps {
    children: JSX.Element;
}

const styles = (theme: Theme) => ({
    containerHeader: {
        marginTop: theme.spacing(7),
    },
    headerStyle: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(6),
    },
    svgClass: {
        height: '80px',
        width: '80px',
        marginTop: theme.spacing(7),
    },
    sub: {
        fontSize: '24px',
        fontWeigth: 'normal',
    },
    contactNumber: {
        fontWeight: 500,
        fontSize: ' 16px',
        color: `${COLORS.blue600} !important`,
    },
    logoRoot: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        height: '64px',
    },
});

interface BoundaryProps extends WithStyles<typeof styles> {}
class ErrorBoundary extends React.Component<BoundaryProps, BoundaryState> {
    constructor(props: BoundaryProps) {
        super(props);
        this.state = { hasError: false };
        this.reload = this.reload.bind(this);
    }

    static getDerivedStateFromError(error: Error): BoundaryState {
        // updated to true for fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        //catch error and log here
    }

    reload() {
        window.location.reload();
    }

    render() {
        const { classes } = this.props;
        if (this.state.hasError) {
            return (
                <>
                    <div className={classes.logoRoot}>
                        <img
                            src={Logo}
                            alt='1800-Accountant logo'
                            height='40'
                        />
                    </div>
                    <Container className={classes.containerHeader}>
                        <Grid
                            container
                            direction='column'
                            justify='flex-start'
                            alignItems='flex-start'
                        >
                            <div className={classes.svgClass}>
                                <img
                                    src={Recycle}
                                    alt={`error`}
                                    height='48px'
                                    width='54px'
                                />
                            </div>
                            <div>
                                <Typography
                                    variant='h3'
                                    className={classes.headerStyle}
                                >
                                    Something went wrong
                                </Typography>
                                <Typography
                                    variant='h6'
                                    className={classes.sub}
                                    gutterBottom
                                >
                                    {' '}
                                    Sorry for the inconvenience, please try to
                                    reload the page
                                </Typography>
                                <div className='link-section'>
                                    <LinkButton onClick={this.reload}>
                                        Refresh the page
                                    </LinkButton>
                                </div>
                            </div>
                            <div style={{ marginTop: '40px' }}>
                                <Typography variant='overline' gutterBottom>
                                    Questions? Call us at{' '}
                                    <Link
                                        className={classes.contactNumber}
                                        href='tel:1-800-222-6868'
                                    >
                                        1-800-222-6868
                                    </Link>
                                </Typography>
                            </div>
                        </Grid>
                    </Container>
                </>
            );
        }
        return this.props.children;
    }
}

export default withStyles(styles, {})(ErrorBoundary);
