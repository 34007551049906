import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
    createStyles,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    withStyles,
} from '@material-ui/core';
import { useDispatch, useStore } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    createFile,
    getJournalEntriesReport,
    getJournalEntriesReportCSVUrl,
    getJournalEntriesReportPDFUrl,
    saveJournalEntriesDocuments,
} from '../../../../services/apiService/reports';
import { showAlert } from '../../../../store/actions/feedback';
import { currencyFormatter, isEmpty } from '../../../../utils/appUtil';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore';
import {
    JETransaction,
    JETransactionAccount,
    JournalEntriesReportRoot,
} from '../models/reports-interface';
import { useReportsStyles } from '../styles/reports-styles';
import Loader from '../../../common/Loader';
import { convertUnixToGivenDateFormat } from '../../../../utils/dateUtil';
import UiText from '../../../common/ui/UiText';
import { toRem16 } from '../../../../styles/commonStyles';
import NewJournalModal from '../../journal/NewJournalModal';
import { useJournalState } from '../../journal/JournalProvider';
import {
    deleteJournalById,
    getAccount,
    getJournalById,
} from '../../../../services/journalServices';
import InfoTooltip from '../../../common/InfoTooltip';
import { InfoOutlined } from '@material-ui/icons';
import SaveReports from '../utils/SaveReports/SaveReports';
import { DOCUMENT_DOWNLOAD_SUCCESS, EMPTY_PARAMS, saveOptions, REPORTS_MENU_VIEWS } from '../constants/reports.const';
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert';
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo';
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading';
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider';
import { ReactComponent as EditPen } from '../../../../assets/icons-svg/editPen.svg';
import { resetReportsData } from '../../../../store/actions/reportsData';
import { LeftArrowIcon } from '../Icons/LeftArrowIcon';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../styles/models/Colors.interface';
import { COLORS } from '../../../../variables/colors';

interface QueryParams {
    ymdStartDate: string;
    ymdEndDate: string;
    periodText: string;
    time: number;
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        dataTable: {
            margin: '5rem 1.5rem 6rem 1.5rem',
            '& thead':{
                position: 'sticky',
                top:0,
                left:0,
                zIndex: 1,
                background: (colorTheme) => colorTheme.primaryWhite,
            }
        },
        edit: {
            '&:hover': {
                color: (colorTheme) => colorTheme.red100,
            },
        },
        totalRow: {
            '& td':{
                fontWeight: 'bold',
                borderBottom: 'none',
                borderTop: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey800}`,
            }
        },
        hideTooltip: {
            display: 'block',
            width: '3rem',
            height: 'auto'
        },
        refNoValue:{
            '& span':{
                margin: '0 -1.4rem 0 0'
            },
            padding: '0 3rem 0 0 !important'
        },
        refNoHeader: {
            padding: '0 3rem 0 0 !important'
        }
    })
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: 'none',
        },
    })
)(TableCell);

const SmallTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '8rem',
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: `1px solid ${COLORS.grey2000}`,
        },
    })
)(TableRow);

const TOOLTIP_TEXT =
    'The Journal Entries Report shows all manually created journal entries for the selected period of time.';

export default function JournalEntriesDetailedReport() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reportsData, setReportsData] = useState<JournalEntriesReportRoot>();
    const store = useStore().getState();
    const currentStore = useCurrentStore();
    const accountId = currentStore.currentAccountId;
    const businessId = currentStore.currentBusinessId!;
    const businessName = currentStore.currentBusiness.name;
    const { listData, setListData } = useJournalState();
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const [selectedReport, setSelectedReport] = useState<any>();
    const query: QueryParams = useParams() as unknown as QueryParams;
    const { ymdStartDate, ymdEndDate, periodText, time } = query;
    const dispatch = useDispatch();
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loadEdit, setLoadEdit] = useState<boolean>(false);
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false);
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    );
    const [logoImage, setLogoImage] = useState<string | undefined>('');
   
    const localClasses = useLocalStyles(colorTheme);
    const history = useHistory();
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading]);
    const showError = useCallback(
        (error: { statusText: string }) => {
            const errorMessage = error.statusText ?? 'Something went wrong';
            dispatch(
                showAlert({
                    alertType: 'error',
                    alertText: errorMessage,
                })
            );
        },
        [dispatch]
    );
    useEffect(() => {
        const initText = 'for the period f';
        const replaceByText = 'F';
        const formmatedText = periodText
            .replaceAll('+', ' ')
            .replace(initText, replaceByText);
        setFormattedPeriodText(formmatedText);
    }, [periodText]);
    useEffect(() => {
        const fetchJournalEntriesReports = () => {
            setIsLoading(true);
            if (!businessId) return;
            getJournalEntriesReport(
                accountId,
                businessId,
                ymdStartDate,
                ymdEndDate,
                periodText,
                time
            )
                .then((result: unknown) => {
                    setReportsData(result as JournalEntriesReportRoot);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    showError(error);
                });
        };
        const reportsFromStore = store.reportsData.reports;
        const reports = isEmpty(reportsFromStore)
            ? fetchJournalEntriesReports()
            : reportsFromStore;
        setReportsData(reports as JournalEntriesReportRoot);
    }, [
        accountId,
        businessId,
        periodText,
        query,
        showError,
        store.reportsData.reports,
        time,
        ymdEndDate,
        ymdStartDate,
    ]);

    const handleEditClick = (data: any) => {
        setLoadEdit(true);
        setIsLoading(true);
        getJournalById(accountId, businessId, data.id)
            .then((result: any) => {
                setSelectedReport(result);
                setOpenModal(true);
                setLoadEdit(false);
                setIsLoading(false);
            })
            .catch((error: any) => {
                setIsLoading(false);
                setLoadEdit(false);
                setIsLoading(false);
                showError(error);
            });
    };
    useEffect(() => {
        const logo = reportsData?.business?.logo;
        logo && setLogoImage(logo);
    }, [reportsData?.business?.logo]);

    const getAccountdata = useCallback((): void => {
        setListData((prev: any) => ({ ...prev, loadingAccountRecord: true }));
        getAccount(accountId, businessId)
            .then((res: any) => {
                const parentCategories = {};
                const cache = {};
                res.forEach((caty: any) => {
                    if (caty.can_have_children) {
                        //@ts-ignore
                        parentCategories[caty?.id] = caty;
                    }
                    //@ts-ignore
                    cache[caty?.id] = caty;
                });
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: cache,
                    accountParentList: parentCategories,
                    loadingAccountRecord: false,
                }));
            })
            .catch((err) => {
                setListData((prev: any) => ({ ...prev, loading: false }));
            });
    }, []);

    useEffect(() => {
        getAccountdata();
    }, []);

    const refreshJournalEntries = () => {
        setIsLoading(true);
        if (!businessId) return;
        getJournalEntriesReport(
            accountId,
            businessId,
            ymdStartDate,
            ymdEndDate,
            periodText,
            time
        )
            .then((result: unknown) => {
                setReportsData(result as JournalEntriesReportRoot);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                showError(error);
            });
    };
    const formatDate = (date: number) => {
        const format = 'DD MMM yyyy';
        const dateInMilliSecs = date * 1000;
        return convertUnixToGivenDateFormat(format, dateInMilliSecs);
    };
    const generateReportsTable = (items: JETransaction[] | undefined) => {
        if (!items) return;
        return (
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <SmallTableCell><UiText weight="semi_bold_600" variant="motorcycle_90">Date</UiText></SmallTableCell>
                        <SmallTableCell align='right' className={localClasses.refNoHeader}><UiText weight="semi_bold_600" variant="motorcycle_90">Ref #</UiText></SmallTableCell>
                        <TableCell><UiText weight="semi_bold_600" variant="motorcycle_90">Category</UiText></TableCell>
                        <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Debit</UiText></TableCell>
                        <TableCell align="right"><UiText weight="semi_bold_600" variant="motorcycle_90">Credit</UiText></TableCell>
                        <TableCell>{''}</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {items.map((transaction: JETransaction, i: number) => {
                        return (
                            <Fragment key={`${i}__1`}>
                                {transaction?.amounts.map(
                                    (
                                        statement: JETransactionAccount,
                                        j: number
                                    ) => {
                                        if (statement.debit) {
                                            return (
                                                <Fragment key={`${j}__2`}>
                                                    <TableRow>
                                                        {j === 0 ? (
                                                            <>
                                                            <StyledTableCell>
                                                                {formatDate(
                                                                    transaction.date
                                                                )}
                                                            </StyledTableCell>
                                                            </>
                                                        ) : (
                                                            <StyledTableCell />
                                                        )}
                                                        {j === 0 ? (
                                                            <>
                                                            <StyledTableCell align='right' className={localClasses.refNoValue}>
                                                                <>
                                                                    #{
                                                                        transaction.number
                                                                    }
                                                                    {transaction?.description ? (
                                                                        <InfoTooltip
                                                                            tooltipText={
                                                                                transaction?.description
                                                                            }
                                                                            customNode={
                                                                                <IconButton aria-label="info">
                                                                                    <InfoOutlined />
                                                                                </IconButton>
                                                                            }
                                                                        />
                                                                    ): (
                                                                        <span className={localClasses.hideTooltip} />
                                                                    )}
                                                                </>
                                                            </StyledTableCell>
                                                            </>
                                                        ) : (
                                                            <StyledTableCell>  </StyledTableCell>
                                                        )}
                                                        <StyledTableCell>
                                                            {statement.account}
                                                        </StyledTableCell>
                                                        
                                                        <StyledTableCell align="right">
                                                            {statement.debit
                                                                ? currencyFormatter.format(
                                                                      parseFloat(
                                                                          statement.debit
                                                                      )
                                                                  )
                                                                : '-'}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">-</StyledTableCell>
                                                        {j === 0 ? (
                                                            <StyledTableCell align="right">
                                                                <IconButton
                                                                    disabled={
                                                                        loadEdit
                                                                    }
                                                                    onClick={() =>
                                                                        handleEditClick(
                                                                            transaction
                                                                        )
                                                                    }
                                                                >
                                                                    <EditPen
                                                                        className={
                                                                            localClasses.edit
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </StyledTableCell>
                                                        ) : (
                                                            <StyledTableCell />
                                                        )}
                                                    </TableRow>
                                                </Fragment>
                                            );
                                        }
                                        return (
                                            <Fragment key={`${j}__2`}>
                                                <TableRow>
                                                    {j === 0 ? (
                                                        <StyledTableCell>
                                                            {formatDate(
                                                                transaction.date
                                                            )}
                                                        </StyledTableCell>
                                                    ) : (
                                                        <StyledTableCell />
                                                    )}
                                                    {j === 0 ? (
                                                        <StyledTableCell>
                                                            {transaction.number}
                                                            <InfoTooltip
                                                                tooltipText={
                                                                    transaction?.description
                                                                }
                                                                customNode={
                                                                    <IconButton aria-label="delete">
                                                                        <InfoOutlined />
                                                                    </IconButton>
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                    ) : (
                                                        <StyledTableCell />
                                                    )}
                                                    <StyledTableCell>
                                                        {statement.account}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">-</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {statement.credit
                                                            ? currencyFormatter.format(
                                                                    parseFloat(
                                                                        statement.credit
                                                                    )
                                                                )
                                                            : '-'}
                                                    </StyledTableCell>
                                                    {j === 0 ? (
                                                        <StyledTableCell>
                                                            <IconButton
                                                                disabled={
                                                                    loadEdit
                                                                }
                                                                onClick={() =>
                                                                    handleEditClick(
                                                                        transaction
                                                                    )
                                                                }
                                                            >
                                                                <EditPen />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    ) : (
                                                        <StyledTableCell />
                                                    )}
                                                </TableRow>
                                            </Fragment>
                                        );
                                    }
                                )}
                                <StyledTableRow>
                                    <StyledTableCell />
                                    <StyledTableCell />
                                    <StyledTableCell>
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="semi_bold_600"
                                        >
                                            Total:
                                        </UiText>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="semi_bold_600"
                                        >
                                            {currencyFormatter.format(
                                                parseFloat(
                                                    transaction?.total_debit ??
                                                        '0.0'
                                                )
                                            )}
                                        </UiText>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="semi_bold_600"
                                        >
                                            {currencyFormatter.format(
                                                parseFloat(
                                                    transaction.total_credit
                                                )
                                            )}
                                        </UiText>
                                    </StyledTableCell>
                                    <StyledTableCell />
                                </StyledTableRow>
                            </Fragment>
                        );
                    })}
                </TableBody>
                {generateTotalRow(reportsData)}
            </Table>
        );
    };
    const generateTotalRow = (report: JournalEntriesReportRoot| undefined) => {
        return report && (
            <TableBody>
                <TableRow className={localClasses.totalRow}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Total:</TableCell>
                    <TableCell align='right'>
                        {currencyFormatter.format(
                            parseFloat(report.subtotal_debit)
                        )}
                    </TableCell>
                    <TableCell align='right'>
                        {currencyFormatter.format(
                            parseFloat(report.subtotal_credit)
                        )}
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableBody>
        );
    };
    const deleteTransaction = () => {
        selectedReport?.id && deleteJournalById(accountId, businessId, selectedReport?.id)
            .then(() => {
                setOpenModal(false);
                setReportsData(undefined);
                refreshJournalEntries();
            })
            .catch((error) => {
                showError(error);
            });
    };
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false);
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath);
    };
    const handleSaveToPortal = (
        values: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const data = {
            comment: values.comment,
            start_date_ymd: ymdStartDate === EMPTY_PARAMS.START_DATE_YMD ? '' : ymdStartDate,
            end_date_ymd:  ymdEndDate === EMPTY_PARAMS.END_DATE_YMD ? '' : ymdEndDate,
            period_text: periodText,
            time,
        };
        saveJournalEntriesDocuments(accountId, businessId, data, fileType)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data;
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    );
                    closeModal();
                    setIsSaveSnackbar(true);
                });
            })
            .catch((error) => {
                showError(error);
            });
    };
    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText="Journal Entries"
            />
            <div className={reportsClasses.reportsCommonHeader}>
                <div className="backLinkAndBtn">
                    <Link data-cy='generate-new-report-btn' to="/reports?name=journalEntries">
                    <LeftArrowIcon color={colorTheme.blue200} />
                        <span>Generate New Report</span>
                    </Link>
                    <SaveReports
                        businessName={businessName!}
                        options={saveOptions}
                        downloadReportPDFUrl={getJournalEntriesReportPDFUrl(
                            accountId,
                            businessId,
                            ymdStartDate,
                            ymdEndDate,
                            periodText,
                            time
                        )}
                        downloadReportCSVUrl={getJournalEntriesReportCSVUrl(
                            accountId,
                            businessId,
                            ymdStartDate,
                            ymdEndDate,
                            periodText,
                            time
                        )}
                        handleSaveToPortal={handleSaveToPortal}
                        reportName={REPORTS_MENU_VIEWS.JOURNAL_ENTRIES}
                        isDisabled={!reportsData?.transactions.length}
                    />
                </div>

                <div className="headerTextAndBusinessNameLogo">
                    <div>
                        <h2>Journal Entries Report</h2>
                        <span>{` ${formattedPeriodText}`}</span>
                    </div>
                    <BusinessNameAndLogo
                        logoFromApi={logoImage}
                        accountId={accountId}
                        businessName={businessName!}
                    />
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={localClasses.dataTable}>
                        {generateReportsTable(reportsData?.transactions)}
                    </div>
                    {!loadEdit && (
                        <NewJournalModal
                            open={openModal}
                            handleClose={() => setOpenModal(false)}
                            datasource={listData}
                            getJournalEntry={refreshJournalEntries}
                            setListData={setListData}
                            isEdit
                            deleteData={{
                                deleteHandleOption: deleteTransaction,
                            }}
                            selectedData={selectedReport}
                        />
                    )}
                    <UiSnackbarAlert
                        open={isSaveSnackbar}
                        message={DOCUMENT_DOWNLOAD_SUCCESS}
                        handleClose={handleGoToDocument}
                        actionButtonColor={'primary'}
                        actionButtonMessage="View Document"
                        closeMessage="view"
                    />
                </>
            )}
        </div>
    );
}
