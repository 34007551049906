import { useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import {
    createFile,
    getMileageLogReports,
    getMileageReportCSVUrl,
    getMileageReportPDFUrl,
    saveMilageReportToPortal,
} from '../../../../services/apiService/reports'
import { currencyFormatter } from '../../../../utils/appUtil'
import Loader from '../../../common/Loader'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import {
    ACTIVITIES,
    DOCUMENT_DOWNLOAD_SUCCESS,
    EMPTY_PARAMS,
    saveOptions,
    SHOW_ODOMETER,
    REPORTS_MENU_VIEWS
} from '../constants/reports.const'
import { MileageLogReport, Trip } from '../models/reports-interface'
import { useReportsStyles } from '../styles/reports-styles'
import ErrorAlert from '../utils/Alerts/ErrorAlert'

import SaveReports from '../utils/SaveReports/SaveReports'
import {
    Box,
    createStyles,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import EditIcon from '../../../../assets/icons-svg/Edit.svg'
import { formatDateDDMMYY } from '../../../../utils/dateUtil'
import { showError } from '../../../../services/formService'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo'
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider'
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading'
import CustomMileageLogTooltip from '../utils/Tooltip/CustomMileageLogTooltip'
import { resetReportsData } from '../../../../store/actions/reportsData'
import { LeftArrowIcon } from '../Icons/LeftArrowIcon'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import TripOverlayForm from '../../../mileage-log/trips/Form/TripOverlayForm';
import { getTripDetails } from '../../../../services/apiService/trips';
import { Trip as TripDetailType } from '../../../../models';
import { fixedTo2Decimal } from '../../../mileage-log/trips/TripUtil';

interface QueryParams {
    vehicleIds: string
    activity: string
    startDateYmd: string
    endDateYmd: string
    periodText: string
    time: number
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        totalMiles: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (colorTheme) => colorTheme.grey200,
            padding: '0 0 0.5rem 0',
        },
        endingBalance: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '0.5rem 0',
        },
        reconciledBalance: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 0',
        },
        tripLocationMarker: {
            display: 'flex',
            '& .tooltip': {
                margin: '0 0 0 1rem',
            },
        },
        tripNoCell: {
            padding: '0 !important',
        },
        parkingAndTolls: {
            padding: '0 !important',
        },
        deductions: {
            padding: '0 !important',
        },
    })
)
const TOOLTIP_TEXT =
    'The Mileage Log Report shows the miles you traveled on each qualifying trip as well as the total number of miles during the selected period of time.'

export function MileageLogDetailedReport() {
    const query: QueryParams = useParams() as unknown as QueryParams
    const store = useStore().getState()
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const businessName = currentStore.currentBusiness.name
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme)
    const [mileageLogReports, setMileageLogReports] =
        useState<MileageLogReport>()
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState<string>('')
    const [csvDownloadUrl, setCsvDownloadUrl] = useState<string>('')
    const { vehicleIds, activity, startDateYmd, endDateYmd, periodText, time } =
        query
    
    const localClasses = useLocalStyles(colorTheme)
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('')
    const [logoImage, setLogoImage] = useState<string | undefined>('')
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false)
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    )
    const [selectedTrip, setSelectedTrip] = useState<TripDetailType>()
    const [loadingTrip, setLoadingTrip] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const dispatch = useDispatch();


    const getTrip = (tripId : string) => {
        if(loadingTrip) return;
        setLoadingTrip(true)
        getTripDetails(tripId)
            .then((response: unknown) => {
                const trip = response as TripDetailType
                setSelectedTrip(trip);
                setLoadingTrip(false);
                setShowEditModal(true);
            })
            .catch(() => {
                setLoadingTrip(false)
                setShowEditModal(false);
                showError(
                    'Could not load trip details. Please try again later.'
                )
            })
    }

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading])
    const history = useHistory()


    const fetchAPIParams = () => {
        const vehicleIDsParam =
            vehicleIds === EMPTY_PARAMS.VEHICLE_IDS ? '' : vehicleIds;
        return {
            vehicleIDsParam,
            startDateYmd,
            endDateYmd,
        };
    };

    const fetchMileageReports = () => {
        const { vehicleIDsParam, startDateYmd, endDateYmd } = fetchAPIParams();

        setIsLoading(true);
        getMileageLogReports(
            accountId,
            activity,
            periodText,
            SHOW_ODOMETER,
            startDateYmd,
            endDateYmd,
            time,
            vehicleIDsParam
        )
            .then((result: unknown) => {
                setIsLoading(false);
                const data = result as MileageLogReport;
                setMileageLogReports(data);
            })
            .catch((error) => {
                setIsLoading(false);
                showError(error.statusText);
            });
    };

    useEffect(() => {
        fetchMileageReports();
    }, [
        accountId,
        activity,
        endDateYmd,
        periodText,
        startDateYmd,
        store.reportsData.reports,
        time,
        vehicleIds,
    ]);

    useEffect(() => {
        const vehicleIDsParam =
            vehicleIds === EMPTY_PARAMS.VEHICLE_IDS ? '' : vehicleIds
        const pdfUrl = getMileageReportPDFUrl(
            accountId,
            SHOW_ODOMETER,
            activity,
            vehicleIDsParam,
            startDateYmd,
            endDateYmd,
            periodText,
            time
        )
        setPdfDownloadUrl(pdfUrl)
    }, [
        accountId,
        activity,
        endDateYmd,
        periodText,
        startDateYmd,
        time,
        vehicleIds,
    ])

    useEffect(() => {
        const vehicleIDsParam =
            vehicleIds === EMPTY_PARAMS.VEHICLE_IDS ? '' : vehicleIds
        const csvUrl = getMileageReportCSVUrl(
            accountId,
            SHOW_ODOMETER,
            activity,
            vehicleIDsParam,
            startDateYmd,
            endDateYmd,
            periodText,
            time
        )
        setCsvDownloadUrl(csvUrl)
    }, [
        accountId,
        activity,
        endDateYmd,
        periodText,
        startDateYmd,
        time,
        vehicleIds,
    ])

    useEffect(() => {
        const oldText = 'for the period from'
        const newText = 'From'
        const formmatedText = periodText
            .replaceAll('+', ' ')
            .replace(oldText, newText)
        setFormattedPeriodText(formmatedText)
    }, [periodText])

    const handleSaveReportsToPortal = (
        value: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const vehicleIDsParam =
            vehicleIds === EMPTY_PARAMS.VEHICLE_IDS ? '' : vehicleIds
        const requestData = {
            activity: activity,
            comment: value.comment,
            start_date_ymd: startDateYmd,
            end_date_ymd: endDateYmd,
            period_text: periodText,
            show_odometer: true,
            time,
            vehicle_ids: vehicleIDsParam,
        }
        saveMilageReportToPortal(accountId, fileType, requestData)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    )
                    closeModal()
                    setIsSaveSnackbar(true)
                })
            })
            .catch((error) => {
                showError(error?.statusText)
            })
    }

    const navigateToClassicPortalMileageEdit = (tripId: string) => {
        const url = `/mileage/trips/${tripId}`
        history.push(url)
    }

    const generateReportsTable = (trips: Trip[]) => {
        if (!trips) return null
        return (
            <Box mt={10}>
                <Table className={`${reportsClasses.reportsTable}`}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <UiText
                                    weight="semi_bold_600"
                                    variant="motorcycle_90"
                                >
                                    Date
                                </UiText>
                            </TableCell>
                            <TableCell align="right">
                                <UiText
                                    weight="semi_bold_600"
                                    variant="motorcycle_90"
                                >
                                    Trip #
                                </UiText>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell>
                                <UiText
                                    weight="semi_bold_600"
                                    variant="motorcycle_90"
                                >
                                    Purpose
                                </UiText>
                            </TableCell>
                            <TableCell>
                                <UiText
                                    weight="semi_bold_600"
                                    variant="motorcycle_90"
                                >
                                    Vehicle
                                </UiText>
                            </TableCell>
                            <TableCell>
                                <UiText
                                    weight="semi_bold_600"
                                    variant="motorcycle_90"
                                >
                                    Distance
                                </UiText>
                            </TableCell>
                            {activity === ACTIVITIES.BUSINESS && (
                                <TableCell align="right">
                                    <UiText
                                        weight="semi_bold_600"
                                        variant="motorcycle_90"
                                    >
                                        Parking & Tolls
                                    </UiText>
                                </TableCell>
                            )}
                            <TableCell align="right">
                                <UiText
                                    weight="semi_bold_600"
                                    variant="motorcycle_90"
                                >
                                    Deductions
                                </UiText>
                            </TableCell>
                            <TableCell align="right">{''}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trips.map((trip: Trip, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {formatDateDDMMYY(trip.date)}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        className={localClasses.tripNoCell}
                                    >
                                        <div>#{trip.number}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div
                                            className={
                                                localClasses.tripLocationMarker
                                            }
                                        >
                                            <span className="tooltip">
                                                <CustomMileageLogTooltip
                                                    startLocation={
                                                        trip.location_from_name
                                                    }
                                                    endLocation={
                                                        trip.location_to_name
                                                    }
                                                />
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell>{trip.purpose_name}</TableCell>
                                    <TableCell>
                                        {trip.vehicle_name}
                                        {trip.show_odometer && (
                                            <div>
                                                {trip.odometer_start} -{' '}
                                                {trip.odometer_end}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>{fixedTo2Decimal(trip.miles)} mi</TableCell>
                                    {activity === ACTIVITIES.BUSINESS && (
                                        <TableCell
                                            align="right"
                                            className={
                                                localClasses.parkingAndTolls
                                            }
                                        >
                                            {currencyFormatter.format(
                                                parseFloat(trip.expenses)
                                            )}
                                        </TableCell>
                                    )}
                                    <TableCell
                                        align="right"
                                        className={localClasses.deductions}
                                    >
                                        {currencyFormatter.format(
                                            parseFloat(trip.tax_deductions)
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton disabled={!!loadingTrip} onClick={() => getTrip(trip.id)}>
                                            <img alt={`Edit`} src={EditIcon} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Box>
        )
    }
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false)
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath)
    }
    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText={'Mileage Log'}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {!mileageLogReports ? (
                        <ErrorAlert />
                    ) : (
                        <>
                            <div className={reportsClasses.reportsCommonHeader}>
                                <div className="backLinkAndBtn">
                                    <Link data-cy='generate-new-report-btn' to="/reports?name=mileageLog">
                                    <LeftArrowIcon color={colorTheme.blue200} />
                                        <span>Generate New Report</span>
                                    </Link>
                                    <SaveReports
                                        businessName={businessName!}
                                        downloadReportPDFUrl={pdfDownloadUrl}
                                        downloadReportCSVUrl={csvDownloadUrl}
                                        options={saveOptions}
                                        handleSaveToPortal={
                                            handleSaveReportsToPortal
                                        }
                                        reportName={REPORTS_MENU_VIEWS.MILEAGE_LOG}
                                        isDisabled={!mileageLogReports.trips.length}
                                    />
                                </div>
                                <div className="headerTextAndBusinessNameLogo">
                                    <div>
                                        <h2>Mileage Log</h2>
                                        <span>{formattedPeriodText}</span>
                                    </div>
                                    <BusinessNameAndLogo
                                        logoFromApi={logoImage}
                                        accountId={accountId}
                                        businessName={businessName!}
                                    />
                                </div>
                            </div>
                            <div
                                className={reportsClasses.reportDetailsContent}
                            >
                                <Box mt={5} mb={7}>
                                    <div className={localClasses.totalMiles}>
                                        <span>Total Miles </span>
                                        <UiText weight="semi_bold_600">
                                            {fixedTo2Decimal(mileageLogReports.total.mileage)} mi
                                        </UiText>
                                    </div>

                                    {activity === ACTIVITIES.BUSINESS && (
                                        <div
                                            className={
                                                localClasses.endingBalance
                                            }
                                        >
                                            <span> Total Parking & Tolls </span>
                                            <UiText weight="semi_bold_600">
                                                {currencyFormatter.format(
                                                    parseFloat(
                                                        mileageLogReports.total
                                                            .expenses
                                                    )
                                                )}
                                            </UiText>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            localClasses.reconciledBalance
                                        }
                                    >
                                        <span> Total Deduction </span>
                                        <UiText weight="semi_bold_600">
                                            {currencyFormatter.format(
                                                parseFloat(
                                                    mileageLogReports.total
                                                        .deductions
                                                )
                                            )}
                                        </UiText>
                                    </div>
                                </Box>
                                {generateReportsTable(mileageLogReports.trips)}
                            </div>
                        </>
                    )}
                </div>
            )}
            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage="View Document"
                closeMessage="view"
            />
            <TripOverlayForm
                isOpen={showEditModal}
                trip={selectedTrip}
                handleClose={() => setShowEditModal(false)}
                submitCallback={fetchMileageReports}
                isNew={false}
            />
        </div>
    )
}
