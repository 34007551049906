import { Badge, Box, CircularProgress, Typography } from '@material-ui/core';
import {
    UploadedFile,
    PaginatedResponse,
    FolderTree,
} from '../../models';
import NoFilesFound from '../../assets/images/NoFilesFound.png';
import { makeStyles } from '@material-ui/styles';
import { COLORS } from '../../variables/colors';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

export default function DocumentList({
    files,
    handleSelectFile,
    folderView,
    accountId,
    businessFolderTree,
    personalFolderTree,
}: {
    files: PaginatedResponse<UploadedFile> | undefined;
    handleSelectFile: (file: UploadedFile) => void;
    folderView: boolean;
    accountId: string;
    businessFolderTree: FolderTree | undefined;
    personalFolderTree: FolderTree | undefined;
}) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        link: {
            color: colorTheme.black200,
            '&:hover': {
                color: colorTheme.black200,
                textDecoration: 'underline',
            },
            wordBreak: 'break-word',
        },
        breadcrumb: {
            textTransform: 'capitalize',
        },
        content: {
            overflowY: 'auto',
            height: 'calc(100vh - 350px)'
        }
    })();

    const getIconType = (mime: string) => {
        switch (mime) {
            case 'image/jpeg':
                return 'Image-Attachment';
            case 'image/png':
                return 'Image-Attachment';
            case 'application/pdf':
                return 'PDF';
            default:
                return 'Generic';
        }
    };

    const getFile = (file: UploadedFile, index: number) => (
        <Box
            key={index}
            display='flex'
            alignItems='center'
            className='cursor-pointer'
        >
            <Box
                width='30px'
                height='40px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                mr={1}
            >
                <Badge
                    color='primary'
                    variant='dot'
                    overlap='circle'
                    invisible={file.is_read_by_user}
                >
                    <img
                        src={
                            require(`../../assets/icons-svg/${getIconType(
                                file.mime_type
                            )}.svg`).default
                        }
                        alt='document'
                    />
                </Badge>
            </Box>
            <Typography
                variant='body2'
                className={styles.link}
                component='div'
                onClick={() => handleSelectFile(file)}
            >
                {file.filename}
            </Typography>
        </Box>
    );

    const getBreadCrumbsByPath = (path: string) => {
        const pathArray = path.split('/');
        const parentType = pathArray.shift();
        const getPathToDisplayArray = (folderTree: FolderTree) => {
            const finalFolder = pathArray.reduce(
                (
                    latestFolder: FolderTree,
                    item: string,
                    index: number,
                    array: string[]
                ): FolderTree => {
                    const path = array.slice(0, index + 1).join('/');
                    return (latestFolder?.folders as any)[`/${path}`];
                },
                folderTree
            );

            return finalFolder.pathToDisplay;
        };
        if (parentType === 'business') {
            return getPathToDisplayArray(businessFolderTree as FolderTree);
        } else {
            return getPathToDisplayArray(personalFolderTree as FolderTree);
        }
    };

    const getFilesGroupedByFolder = () => {
        const grouped = files?.items.reduce(
            (group: { [path: string]: UploadedFile[] }, file) => {
                const parentType =
                    file.portal_account_id === accountId
                        ? 'business'
                        : 'private';
                const filePathToDisplay = `${parentType}${file.path}`;
                if (Array.isArray(group[filePathToDisplay])) {
                    group[filePathToDisplay].push(file);
                } else {
                    group[filePathToDisplay] = [file];
                }
                return group;
            },
            {}
        ) as { [path: string]: UploadedFile[] };

        /**
         * Converted to array, to iterate it in UI
         */
        return (
            businessFolderTree &&
            personalFolderTree &&
            Object.keys(grouped).map((key) => ({
                breadcrumb: getBreadCrumbsByPath(key),
                files: grouped[key],
            }))
        );
    };

    return (
        <div style={{marginLeft: '16px'}} >
            {files ? (
                files.items?.length ? (
                    <>
                        <Typography
                            variant='subtitle2'
                            color='textSecondary'
                            gutterBottom
                        >
                            {files.items.length} Documents
                        </Typography>
                        <Box mt={3} className={styles.content}>
                            {folderView
                                ? files.items.map((file, index) =>
                                      getFile(file, index)
                                  )
                                : getFilesGroupedByFolder()?.map(
                                      (group, index) => (
                                          <Box key={index} mt={4}>
                                              <Typography
                                                  variant='subtitle2'
                                                  color='textSecondary'
                                                  gutterBottom
                                                  className={styles.breadcrumb}
                                              >
                                                  {group.breadcrumb}
                                              </Typography>
                                              <Box mt={1}>
                                                  {group.files.map(
                                                      (
                                                          file: UploadedFile,
                                                          fileIndex: number
                                                      ) =>
                                                          getFile(
                                                              file,
                                                              fileIndex
                                                          )
                                                  )}
                                              </Box>
                                          </Box>
                                      )
                                  )}
                        </Box>
                    </>
                ) : (
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                    >
                        <img src={NoFilesFound} alt='Not found' />
                        <Typography variant='h6' color='textSecondary'>
                            No matching results
                        </Typography>
                        <Typography variant='subtitle1' color='textSecondary'>
                            {folderView
                                ? 'No documents were found'
                                : 'Try adjusting your search and/or filters'}
                        </Typography>
                    </Box>
                )
            ) : (
                <Box textAlign='center'>
                    <CircularProgress size={24} />
                </Box>
            )}
        </div>
    );
}
