import { Button } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getGeneralJournalReports } from '../../../services/apiService/reports';
import { showError } from '../../../services/formService';
import store from '../../../store';
import { reportsDataActions } from '../../../store/actions/reportsData';
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil';
import InfoTooltip from '../../common/InfoTooltip';
import Loader from '../../common/Loader';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
    EMPTY_PARAMS,
} from './constants/reports.const';
import {
    ApiError,
    ErrorAlertReport,
    GeneralJournalReport,
} from './models/reports-interface';
import { useReportsStyles } from './styles/reports-styles';
import SelectDates from './utils/Date/SelectDates';
import ErrorAlert from './utils/Alerts/ErrorAlert';
import { FormObserver } from './utils/Form/FormObserver';
import UiText from '../../common/ui/UiText';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const initialFormValues = {
    from: moment.utc().hour(12).minute(0).second(0).unix(),
    to: moment.utc().hour(12).minute(0).second(0).unix(),
};
const dateChips = [
    {
        for: DATE_TYPES.THIS_MONTH,
        id: 1,
    },
    {
        for: DATE_TYPES.THREE_MONTHS,
        id: 2,
    },
    {
        for: DATE_TYPES.THIS_YEAR,
        id: 3,
    },
    {
        for: DATE_TYPES.ALL_TIME,
        id: 4,
    },
];
const GET_DATE_TILL_TODAY = true;
const TOOLTIP_TEXT = 'The General Journal Report shows all categorized transactions, journal entries, closing entries, and entries related to a trip in the mileage tracker for the selected period of time.';
interface FormValues {
    from: number;
    to: number;
}
export default function GeneralJournal() {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const storeData = useCurrentStore();
    const accountId = storeData.currentAccountId;
    const businessId = storeData.currentBusinessId!;
    const businessName = storeData.currentBusiness?.name;
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDateChip, setSelectedDateChip] = useState<string>(
        DATE_TYPES.THIS_YEAR
    );
    const [isHideFromDateAndToDate, setIsHideFromDateAndToDate] = useState<boolean>(false);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const history = useHistory();

    useEffect(() => {
        const { startDate, endDate } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            GET_DATE_TILL_TODAY
        );
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    }, []);

    const updateStartAndEndDates = (dateType: { for: string; id: any }) => {
        const { startDate, endDate } = getTimeParamsWithoutEST(dateType.for, GET_DATE_TILL_TODAY);
        setinitialValues((prev) => ({
            ...prev,
            from: startDate ?? 0,
            to: endDate ?? 0,
        }));
    };
    const checkIfFromAndToDatesAreToBeHidden = (dateType: string)=>{
        if(dateType === DATE_TYPES.ALL_TIME){
            setIsHideFromDateAndToDate(true);
            return;
        }
        setIsHideFromDateAndToDate(false);
    }
    const updateDates = (dateType: { for: string; id: any }): void => {
        setSelectedDateChip(dateType.for);
        updateStartAndEndDates(dateType);
        checkIfFromAndToDatesAreToBeHidden(dateType.for);
    };

    const getFormattedDate = (dateInSecs: number, periodTextFormat: string) => {
        const dateInMs = dateInSecs * 1000;
        const date = convertUnixToGivenDateFormat(periodTextFormat, dateInMs);
        return date;
    };

    const getPeriodTextWithFromAndToDate = (
        from: number,
        to: number
    ): string => {
        const periodTextFormat = DATE_FORMATS.periodText;
        const fromDate = getFormattedDate(from, periodTextFormat);
        const toDate = getFormattedDate(to, periodTextFormat);
        const periodText = `for the period from ${fromDate} to ${toDate}`;
        return periodText.replace(findSpaceRegex, '+');
    };
    const getPeriodText = (fromDate: number, toDate: number) => {
        const periodTextAllTime = DATE_TYPES.ALL_TIME;
        const periodText =
            !fromDate || !toDate
                ? periodTextAllTime.replace(findSpaceRegex, '+')
                : getPeriodTextWithFromAndToDate(fromDate, toDate);
        return periodText;
    };
    const fetchAPIQueryParams = (fromDate: number, toDate: number) => {
        const periodText = getPeriodText(fromDate, toDate);
        const time = getCurrentTimeStamp();
        const startDateYmd = !fromDate ? null : getDateInYmd(fromDate);
        const endDateYmd = !toDate ? null : getDateInYmd(toDate);
        return {
            accountId,
            businessId,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        };
    };
    const handleError = (error: ApiError) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '',
        });
    };
    const resetDateChips = () => {
        setSelectedDateChip('');
    };
    const onformChangeCallback = (values: FormValues, formikObject: any) => {
        const fromDateChanged = formikObject.touched?.from;
        const toDateChanged = formikObject.touched?.to;
        if (fromDateChanged || toDateChanged) {
            resetDateChips();
        }
        resetErrorAlert();
    };
    const dispatchReportsDataToStore = (data: GeneralJournalReport) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };
    const navigateToDetailedJournalReport = (
        periodText: string,
        time: number,
        startDateYmd: string | null,
        endDateYmd: string | null
    ) => {
        const startDate = startDateYmd || EMPTY_PARAMS.START_DATE_YMD;
        const endDate = endDateYmd || EMPTY_PARAMS.END_DATE_YMD;
        const journalReportUrl = `/reports/general-journal/${periodText}/${time}/${startDate}/${endDate}`;
        history.push(journalReportUrl);
    };
    const getReportsDataAndNavigate = (fromDate: number, toDate: number) => {
        const {
            accountId,
            businessId,
            periodText,
            time,
            startDateYmd,
            endDateYmd,
        } = fetchAPIQueryParams(fromDate, toDate);
        setIsLoading(true);
        getGeneralJournalReports(
            accountId,
            businessId,
            periodText,
            time,
            startDateYmd,
            endDateYmd
        )
            .then((res: unknown) => {
                setIsLoading(false);
                const reports = res as GeneralJournalReport;
                dispatchReportsDataToStore(reports);
                navigateToDetailedJournalReport(
                    periodText,
                    time,
                    startDateYmd,
                    endDateYmd
                );
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };
    const onSubmit = (values: FormValues) => {
        const { from, to } = values;
        getReportsDataAndNavigate(from, to);
    };

    return (
        <div className={reportsClasses.reportsViewParent}>
            <span className='businessName'>{businessName}</span>
            <UiText
                variant="suv_150"
                weight="semi_bold_600"
                className={reportsClasses.pageTitle}
            >
                General Journal
                <InfoTooltip tooltipText={TOOLTIP_TEXT}/>
            </UiText>
            {errorAlert?.isShow && <ErrorAlert message={errorAlert?.message} />}
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                <Form>
                    <FormObserver onformChange={onformChangeCallback} />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <SelectDates
                                chips={dateChips}
                                handleUpdateDates={updateDates}
                                selectedChip={selectedDateChip}
                                isHideFromAndToDates={isHideFromDateAndToDate}
                                disableFutureDate
                            />

                            <Button
                                variant='contained'
                                type='submit'
                                color='primary'
                                data-cy='generate-report-btn'
                            >
                                Generate Report
                            </Button>
                        </>
                    )}
                </Form>
            </Formik>
        </div>
    );
}
