import React from 'react'
import MdashCheck from '../../../common/MdashCheck'
import UiText from '../../../common/ui/UiText'
import { Contractor } from '../../models/contractor.model'
import UiTextView from '../../../common/ui/UiTextView'

export const contractorInfo = (
    contractor: Contractor
): {
    label: string | React.ReactNode
    value: string | React.ReactNode
}[] => {
    const address = `${contractor?.street} ${contractor?.city} ${contractor?.state} ${contractor?.zip}`
    const checkMessage = 'Needs Contractor Input'
    return [
        {
            label: 'Legal Name',
            value: (
                <UiText>
                    {contractor?.first_name}
                    &nbsp;
                    {contractor?.last_name}
                </UiText>
            ),
        },
        {
            label: 'Email',
            value: (
                <MdashCheck customCheck={!!contractor?.email}>
                    {contractor?.email}
                </MdashCheck>
            ),
        },
        {
            label: 'Phone',
            value: (
                <MdashCheck customCheck={!!contractor?.phone}>
                    <UiTextView
                        type="phone-us"
                        value={String(contractor?.phone)}
                    />
                </MdashCheck>
            ),
        },
        {
            label: 'Contractor Type',
            value: (
                <MdashCheck customCheck={!!contractor?.type} checkMessage={checkMessage}>
                    {contractor?.type}
                </MdashCheck>
            ),
        },
        {
            label: 'Business Name',
            value: (
                <MdashCheck customCheck={!!contractor?.business_name} checkMessage={checkMessage}>
                    {contractor?.business_name}
                </MdashCheck>
            ),
        },
        {
            label: 'Tax Year',
            value: (
                <MdashCheck
                    customCheck={!!contractor?.contractor_w_nine.length}
                >
                    {contractor?.contractor_w_nine.map(
                        (years: any, index: number) => {
                            return `${years.year}${
                                index ===
                                contractor?.contractor_w_nine.length - 1
                                    ? ''
                                    : ', '
                            }`
                        }
                    )}
                </MdashCheck>
            ),
        },
        {
            label: 'Tax ID Type',
            value: (
                <MdashCheck customCheck={!!contractor?.tax_identification_type} checkMessage={checkMessage}>
                    {contractor?.tax_identification_type}
                </MdashCheck>
            ),
        },
        {
            label: `${
                contractor?.tax_identification_type
                    ? contractor?.tax_identification_type
                    : 'TIN'
            }`.toLocaleUpperCase(),
            value: (
                <MdashCheck
                    customCheck={!!contractor?.tax_identification_number}
                    checkMessage={checkMessage}
                >
                    {contractor?.tax_identification_number}
                </MdashCheck>
            ),
        },
        {
            label: 'Mailing Address',
            value: (
                <MdashCheck customCheck={!!contractor?.city} checkMessage={checkMessage}>
                    {address}
                </MdashCheck>
            ),
        },
    ]
}
