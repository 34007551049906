import UiText from '../../../common/ui/UiText'

export const paymentFlsaMapping: {
    "Month": string,
    'Paycheck': string
} = {
    'Month': 'Salaried Nonexempt',
    'Paycheck': 'Owner'
}

export const ownershipPaymentError = {
    message: "For Owner, Payment frequency should be `Paycheck`",
    path: "payment_unit",
};
export const payCheckUnitError = {
    message: "Payment unit must be one of Hour, Week, Month, or Year",
    path: "payment_unit",
};
export const FlsaStatusInfoText = (
    <>
        <UiText variant="motorcycle_90">
            <span style={{ fontWeight: 500 }}>Exempt employees</span> are paid a
            fixed salary each pay period <br /> and cannot earn overtime.
        </UiText>
        <UiText variant="motorcycle_90">
            <span style={{ fontWeight: 500 }}> Salaried employees</span> are
            paid a fixed salary each pay period.
        </UiText>
        <UiText variant="motorcycle_90">
            <span style={{ fontWeight: 500 }}> Non-exempt employees</span> are
            paid for the hours they work <br /> and any overtime.
        </UiText>
        <UiText variant="motorcycle_90">
            <span style={{ fontWeight: 500 }}> Owners are employees</span> that
            own at least 20% of the company.
        </UiText>
    </>
)

const paymentUnitInfo = (
    <UiText variant="motorcycle_90">
        {' '}
        The frequency the compensation amount is paid{' '}
    </UiText>
)

const flsaStatusOptions = (isMonthlyPayment: boolean) => [
    {
        label: 'Exempt',
        status: 'Exempt',
        disabled: isMonthlyPayment
    }, 
    {
        label: 'Salaried Nonexempt',
        status: 'Salaried Nonexempt'  ,
        disabled: false 
    }, 
    {
        label: 'Nonexempt',
        status: 'Nonexempt',
        disabled: isMonthlyPayment
    }, 
    {
        label: 'Owner',
        status: 'Owner',
        disabled: false
    }
]

export const compensationFormMappings = (values: any) =>  {
    const isMonthlyPayment = values.payment_unit === 'Month'
    const flsaData = flsaStatusOptions(isMonthlyPayment)
    return [
        {
            md: 8,
            xs: 12,
            key: 'rate',
            label: 'Amount (Required)',
            type: 'currency',
            showFloatingLabel: true,
            required: true,
        },
        {
            key: 'payment_unit',
            type: 'select',
            placeholder: 'Frequency (Required)',
            label: 'Frequency (Required)',
            md: 10,
            xs: 12,
            required: true,
            data: ['Hour', 'Week', 'Month', 'Year', 'Paycheck'],
            showFloatingLabel: true,
            infoText: paymentUnitInfo,
        },
        {
            key: 'flsa_status',
            type: 'select',
            placeholder: 'FLSA Status (Required)',
            label: 'FLSA Status (Required)',
            md: 10,
            xs: 12,
            required: true,
            optionKey: 'label',
            optionValue: 'status',
            data: flsaData,
            showFloatingLabel: true,
            fastField: false, 
            infoText: FlsaStatusInfoText,
        },
    ]
}

export const compensationInitials = (compensation: any, job: any) => {
    const checkPaymentUnit =  job?.title === 'Owner' ? 'Paycheck' : 'Hour' 
    const checkFlsaDefault =  compensation?.payment_unit === "Monthly" ? 'Salaried Nonexempt' : 'Nonexempt'
    let initial = {
        rate: compensation?.rate || '',
        payment_unit: compensation?.payment_unit
                ? compensation?.payment_unit
                : checkPaymentUnit,
        flsa_status: compensation?.flsa_status
                ? compensation?.flsa_status
                : job?.title === 'Owner'
                ? 'Owner'
                : checkFlsaDefault
    }
    return initial
}
