import InfoTooltip from '../../../common/InfoTooltip';
import UiText from '../../../common/ui/UiText';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { useReportsStyles } from '../styles/reports-styles';

interface HeadingProps{
    tooltipText: string;
    headingText: string;
}
export const ActiveRouterHeadingSection = (props: HeadingProps) => {
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const {tooltipText, headingText} = props;

    return (
        <div className={reportsClasses.activeRouteHeading}>
            <UiText variant="suv_150" weight="semi_bold_600" className="headingText">
                {headingText}
                <InfoTooltip tooltipText={tooltipText} />
            </UiText>
        </div>
    );
};