import { Button, Theme, createStyles, makeStyles } from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'
import UiDialog from '../../../common/ui/UiDialog'
import UiText from '../../../common/ui/UiText'
import {
    UNSAVED_MODAL_ACTIONS,
    UNSAVED_MODAL_ACTIONS_TYPES,
} from '../models/vehicle.model'
import { toRem16 } from '../../../../styles/commonStyles'
import Icon from '../../../common/Icon'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

interface Props {
    open: boolean
    handleModalAction: (action: UNSAVED_MODAL_ACTIONS) => void
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        actionButtons: {
            '& .discardBtn':{
                margin: '0 0 0 1rem',
                color: (colorTheme) => colorTheme.black100,
            }
        },
        customDialogContainer:{
            borderRadius: 0,
            '& > div':{
                padding: `${toRem16(18)} ${toRem16(24)}`
            }
        }
    })
)
export const UnsavedChangesDialogue = (props: Props) => {
    const { open, handleModalAction } = props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    return (
        <UiDialog
            open={open}
            handleClose={() => {}}
            title=""
            size="sm"
            hideTitleSection={true}
            customDialogStyles={
                { paper: classes.customDialogContainer }
            }
        >
            <div className ={classes.container}>
                <UiText weight="semi_bold_600" variant="motorcycle_90">
                    Discard unsaved changes?
                </UiText>
                <div className={classes.actionButtons}>
                <UiButton
                    handleClick={() => {
                        handleModalAction(UNSAVED_MODAL_ACTIONS_TYPES.KEEP)
                    }}
                    label="Keep Editing"
                    btnType="tertiary"
                />

                <Button
                    variant="outlined"
                    startIcon={<Icon icon="delete" size="1.125rem" />}
                    className="discardBtn"
                    onClick={() => {
                        handleModalAction(UNSAVED_MODAL_ACTIONS_TYPES.DISCARD)
                    }}
                >
                    Discard
                </Button>
                </div>
            </div>
        </UiDialog>
    )
}
