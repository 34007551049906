import { Fragment } from 'react';
import { Button, Theme, Typography, makeStyles } from '@material-ui/core'
import UiDialog from '../../components/common/ui/UiDialog'
import { commonStyles, mergeStyle } from '../../styles/commonStyles'
interface PastDueNoticeModalProps {
    openModal: boolean
    closeModal: (params: any) => void ,
    handleAction: (params: any) => void
}

const useStyles =  makeStyles((theme: Theme) => ({
    container: {
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
            padding: '15px 45px 0px'
        }
    },
    formActions: {
        padding: '10px 0px',
        marginTop: 10,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }
}))

const PastDueNoticeModal = ({
    openModal,
    closeModal,
    handleAction,
}: PastDueNoticeModalProps) => {
    const classes = useStyles()
    const styles = commonStyles()
    return (
        <UiDialog
            open={openModal}
            handleClose={closeModal}
            title=''
            size='sm'
            customRootClass={classes.container}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <div>
                <Typography
                    className={styles.textCenter}
                    variant='h6'
                    style={{ marginBottom: 24 }}
                >
                    Past Due Notice
                </Typography>
                <Typography
                    className={styles.textCenter}
                    variant='body1'
                >
                    Our records indicate your payment method is expired or 
                    invalid. In order to schedule an appointment with one 
                    of our accountants, you need to update this information.
                </Typography>
            </div>
            <div className={mergeStyle(styles.flex, styles.justifyCenter, classes.formActions)}>
                <Button
                    variant='contained'
                    onClick={() => handleAction(true)}
                    color='primary'
                >
                    Resolve Now
                </Button>
            </div>
        </UiDialog>
    )
}

export default PastDueNoticeModal