import { useState, useEffect } from 'react'
import {
    Switch,
    Route,
    HashRouter,
    useLocation,
    Redirect,
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { useStyles } from '../../styles/styles'
import LoaderPage from '../common/LoaderPage'
import { getInitData } from '../../services/accountService'
import { getAuthData, isLoggedIn } from '../../services/authService'
import AuthInit from '../auth/AuthInit'
import { connect } from 'react-redux'

import { setInitData } from '../../store/actions/appData'
import { prepareUserRoles, setAuthData } from '../../store/actions/auth'
import { setAuthHeader } from '../../services/axiosService'
import { loadConfig } from '../../store/actions/config'
import PaymentRedirect from '../sales/PaymentRedirect'
import { getFromLocalStorage } from '../../services/localStorageService'
import { initPermissionCheck } from '../../utils/appUtil'
import CreateAccount from '../business/CreateAccount'
import { useCollectBusinessInfo } from '../business/hooks/useCollectBusinessInfo'
import CreateNewBusiness from '../dashboard/business-onboarding/CreateNewBusiness'
import { OnBoardingProvider } from '../dashboard/business-onboarding/OnBoardingProvider'
import { PayrollMigrationProvider } from './Providers/PayrollMigrationProvider'
import TOSLandingPage from './Payroll/payroll-migration/TOSLandingPage'
import ActiveRoutingProvider from './Providers/ActiveRoutingProvider'
import { ContractorLimitedPage } from '../contractor/ContractorLimitedPage'
import { ThemeCustom } from '../../styles/ThemeCustom';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import { updateThemeAttribute } from '../common/whiteLabel/updateThemeAttibutes';
import TOSBusinessOptPage from './Payroll/payroll-migration/TOSBusinessOptPage'


function Routing<Props>(props: Props) {
    return (
        <HashRouter basename="/">
            <Switch>
                <Route exact path="/contractor-limited-access">
                    <ContractorLimitedPage />
                </Route>
                <Route exact path="/">
                    <AuthInit />
                </Route>
                <Route exact path="/redirect">
                    <PaymentRedirect />
                </Route>

                <Route component={Protected.bind(null, props)} />
            </Switch>
        </HashRouter>
    )
}

function Protected(props: any) {
    const { colorTheme, updateColorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const customTheme = ThemeCustom();
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [hasMissingFields, setHasMissingFields] = useState(false)
    const isNewBusinessRoute = location.pathname === '/profile/business/new'
    const isTOSFlow = location.pathname === '/payroll-migration'
    const { missingBusinessFields } = useCollectBusinessInfo()
    const adp_tos_redirect = new URLSearchParams(location.search).get('is_adp_tos_redirect')

    const onBusinessDataSubmitted = () => {
        setHasMissingFields(false)
    }

    useEffect(() => {
        props.loadConfig().then(() => {
            if (isLoggedIn()) {
                props.setAuthData(getAuthData())
                setAuthHeader()
                props.setInitData(getInitData())
                props.prepareUserRoles()

                const permission_check = getFromLocalStorage('permission_check')
                if (permission_check) {
                    initPermissionCheck(permission_check.count)
                }
            }
            setLoading(false)
        })
    }, [props])

    useEffect(() => {
        if (missingBusinessFields && missingBusinessFields.length > 0) {
            setHasMissingFields(true)
        }
    }, [missingBusinessFields])

    useEffect(() =>{
        const user = getFromLocalStorage('user');
        if(!user || !user.partner_details) return;
        const partnerDetails = user.partner_details;
        updateColorTheme(partnerDetails?.custom_style_info);
        updateThemeAttribute(partnerDetails);
    },[])

    const renderComponent = () => {

        if (adp_tos_redirect || isTOSFlow) {
            let Component = null

            if (adp_tos_redirect) {
                Component = TOSBusinessOptPage
            } else if (isTOSFlow) {
                Component = TOSLandingPage
            }

            return (
                <PayrollMigrationProvider>
                    {Component && <Component />}
                </PayrollMigrationProvider>
            )
        }
        

        if (hasMissingFields) {
            return (
                <CreateAccount
                    missingBusinessFields={missingBusinessFields as string[]}
                    onBusinessDataSubmitted={onBusinessDataSubmitted}
                />
            );
        } else if (isNewBusinessRoute) {
            return (
                <OnBoardingProvider>
                    <CreateNewBusiness />
                </OnBoardingProvider>
            );
        } else {
            return <ActiveRoutingProvider />;
        }
    };

    if (loading) {
        return <LoaderPage />
    }

    return isLoggedIn() ? (
        <ThemeProvider theme={customTheme}>
            <div className={classes.rootDiv}>
                {renderComponent()}
            </div>
        </ThemeProvider>
    ) : (
        <Redirect
            to={{ pathname: '/', search: `back_uri=${location?.pathname}` }}
        />
    )
}

export default connect(null, {
    setInitData,
    setAuthData,
    loadConfig,
    prepareUserRoles,
})(Routing)