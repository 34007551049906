import { createContext, useContext, useState, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

interface StateValue {
    selectedData: any;
    listData: any;
    setListData: (theme: any) => void;
    setSelectedData: (data: any) => void;
}

const JournalStateContext = createContext<StateValue | null>(null);

export function useJournalState(): StateValue {
    const state = useContext(JournalStateContext);

    if (!state) {
        throw new Error(
            'useJournalState must be used within JournalStateProvider'
        );
    }

    return state;
}

export function JournalStateProvider({ children }: Props) {
    const [selectedData, setSelectedData] = useState(null);
    const [listData, setListData] = useState({
        data: [],
        loading: true,
        loadingAccountRecord: true,
        page: 1,
        searchText: '',
        totalItem: 0,
        perPage: 10,
        accountRecord: {},
        accountParentList: {},
    });
    
   

    const providerValue = {
        selectedData,
        listData,
        setListData,
        setSelectedData
    };

    return (
        <JournalStateContext.Provider value={providerValue}>
            {children}
        </JournalStateContext.Provider>
    );
}
