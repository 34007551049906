import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useStore } from "react-redux";
import UiDialog from "../../../components/common/ui/UiDialog";
import UiText from "../../../components/common/ui/UiText";
import UiButton from "../../../components/common/ui/UiButton";
import Loader from "../../../components/common/Loader";
import { commonStyles, mergeStyle } from "../../../styles/commonStyles";
import SetupConfirmation from "./SetupConfirmation";
import { finishOnboarding } from "../../../services/apiService/payroll/company";
import {  formSubmitErrorHandler }  from '../../../services/formService'
import { ReviewDialog } from '../../common/ReviewDialog';

const useStyles = makeStyles(() => ({
    dialogContainer: {
        "& .MuiPaper-root": {
            width: "640px",
            height: "306px",
            maxWidth: "700px",
            maxHeight: "400px",
        },
        "& .MuiDialogContent-root": {
            overflow: "hidden",
        },
    },
}));
export interface Onboarding{
    show_review_widget? : boolean
}
const CompletePayrollPopup = ({ open, handleClose, refreshSteps }: any) => {
    const styles = commonStyles();
    const classes = useStyles();
    const store = useStore()?.getState();
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
    const currentBusinessId = store.appData.current_business_id;
    const submitPayroll = () => {
        setLoading(true);
        formSubmitErrorHandler(
            finishOnboarding(currentBusinessId)
            .then((res : unknown) => {
                const data = res as Onboarding
                if (data) {
                    setShowReviewModal(data.show_review_widget ?? false)
                    setLoading(false);
                    setShowConfirmation(true);
                }
            }), () => {
                setLoading(false);
            })
    };
    return (
        <div>
            <SetupConfirmation
                open={showConfirmation}
                handleClose={() => {
                    setShowConfirmation(false);
                    handleClose();
                    refreshSteps();
                }}
            />
            {
                showReviewModal && <ReviewDialog />
            }
            <UiDialog
                open={open}
                handleClose={handleClose}
                hideTitleSection={true}
                size="sm"
                customRootClass={classes.dialogContainer}
                disableBackdropClick={loading}
            >
                <div
                    style={{
                        textAlign: "center",
                        height: "300px",
                    }}
                    data-cy="submit-payroll-popup"
                >
                    <UiText
                        variant="van_225"
                        weight="bold_700"
                        className={styles.mt24}
                    >
                        Complete Payroll Setup
                    </UiText>
                    <UiText variant="car_100" className={styles.mt24}>
                        Submit your information to finish your account setup
                    </UiText>
                    <div className={styles.mt24}>
                        {loading ? (
                            <Loader />
                        ) : (
                            <UiButton
                                btnType="tertiary"
                                handleClick={submitPayroll}
                                label="Submit Payroll"
                            />
                        )}
                    </div>
                    <UiText
                        textColor="secondary"
                        className={mergeStyle(styles.mt24, styles.mb16)}
                    >
                        You can return to this information and edit it as needed
                        at any time
                    </UiText>
                </div>
            </UiDialog>
        </div>
    );
};

export default CompletePayrollPopup;
