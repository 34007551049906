import React from 'react';
import { Contact } from '../../../../models'
import { phoneNumberRegex, contactZipCodeRegex } from '../../../../utils/maskUtil'
export const defaultValues = {
    name: '',
    type: 'customer',
    email: '',
    mobile: '',
    phone: '',
    fax: '',
    website: '',
    payment_terms: 'net30',
    company: '',
    address: {
        address: '',
        city: '',
        state: '',
        zip_code: '',
        country: 'US',
    },
    additional_info: '',
}
export const propertyIconMapping = (selectOptions: any) => [
    {
        key: 'name',
        type: 'text',
        icon: 'User',
        placeholder: 'Contact Name',
        md: 12,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        label: 'Name (Required)',
    },
    {
        key: 'type',
        type: 'toggleGroup',
        optionsData: [
            { text: 'Customer', value: 'customer', width: '104px' },
            { text: 'Vendor', value: 'vendor', width: '104px' },
        ],
        md: 6,
        xs: 12,
    },
    {
        key: 'email',
        type: 'text',
        icon: 'Email',
        placeholder: 'Email',
        md: 6,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        label: 'Email (Required)',
    },
    {
        key: 'mobile',
        type: 'mask',
        icon: 'Mobile',
        placeholder: 'Mobile',
        md: 12,
        xs: 12,
        label: 'Mobile',
        minLength: 10,
        regex: phoneNumberRegex
    },
    {
        key: 'phone',
        type: 'mask',
        icon: 'Call',
        placeholder: 'Phone',
        md: 12,
        xs: 12,
        label: 'Phone',
        minLength: 10,
        regex: phoneNumberRegex
    },
    {
        key: 'fax',
        type: 'mask',
        icon: 'Fax',
        placeholder: 'Fax',
        md: 12,
        xs: 12,
        label: 'Fax',
        minLength: 10,
        regex: phoneNumberRegex
    },
    {
        key: 'payment_terms',
        type: 'toggleGroup',
        label: 'Default Payment Terms',
        optionsData: [
            { text: '—', value: '', width: '68px' },
            { text: 'Net 10', value: 'net10', width: '68px' },
            { text: 'Net 15', value: 'net15', width: '68px' },
            { text: 'Net 30', value: 'net30', width: '68px' },
        ],
        md: 8,
        xs: 11,
    },
    {
        key: 'address',
        type: 'text',
        icon: 'Location',
        label: 'Address',
        addressDetails: [
            { key: 'showIcon', label: 'Address' },
            {
                key: 'address.address',
                type: 'text',
                placeholder: 'Address',
                md: 12,
                xs: 12,
                label: 'Street',
            },
            {
                key: 'address.city',
                type: 'text',
                placeholder: 'City',
                md: 12,
                xs: 12,
                label: 'City',
            },
            {
                key: 'address.state',
                type: 'select',
                placeholder: 'State',
                md: 6,
                xs: 12,
                label: 'State',
                optionKey: 'name',
                optionValue: 'id',
                optionsData: selectOptions.state,
            },
            {
                key: 'address.zip_code',
                type: 'mask',
                placeholder: 'Zip Code',
                md: 6,
                xs: 12,
                label: 'Zipcode',
                regex: contactZipCodeRegex,
                minLength: 5,
            },
            {
                key: 'address.country',
                type: 'select',
                placeholder: 'Country',
                md: 8,
                xs: 12,
                label: 'Country',
                optionKey: 'name',
                optionValue: 'id',
                optionsData: selectOptions.country
            },
        ],
    },
    {
        key: 'website',
        type: 'text',
        icon: 'Website',
        placeholder: 'Website',
        md: 12,
        xs: 12,
    },
    {
        key: 'company',
        type: 'text',
        icon: 'Company',
        placeholder: 'Company',
        md: 12,
        xs: 12,
    },
    {
        key: 'additional_info',
        type: 'textarea',
        icon: 'Text',
        placeholder: 'Additional Info',
        md: 12,
        xs: 12,
    },
]
export interface ContactFormProps {
    contact?: any
    action?: string
    handleClose?: any
    setSelectedContact?: React.Dispatch<Contact | undefined>
    loadContacts?: () => void
    formikRef?: any
    states: OptionsData[]
    countries: OptionsData[]
    setContactUpdated?: React.Dispatch<boolean>
    contactCallback?: any
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}
export interface StateCountryProps {
    [key: string]: OptionsData[]
}
export type OptionsData = {
    abbr: string
    name: string
}