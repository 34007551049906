import React, { useState } from 'react'
import {
    Button,
    makeStyles,
    LinearProgress,
    Divider,
    Collapse,
    Theme
} from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import UiAlert from '../../common/ui/UiAlert'
import { ReactComponent as Work } from '../../../assets/icons-svg/Work.svg'
import { ReactComponent as EmptyCircle } from '../../../assets/icons-svg/EmptyCircle.svg'
import ConnectBankAccountCards from '../Cards/ConnectBankAccountCard'
import MerchantProcessingCard from '../Cards/MerchantProcessingCard'
import AdvisoryCall from '../Cards/AdvisoryCall'
import DiscoveryCall from '../Cards/DiscoveryCall'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import {
    ATTEND_DISCOVERY_CALL,
    ATTEND_ADVISORY_CALL,
    MERCHANT_ACCOUNT_SETUP,
    CONNECT_BANK_ACCOUNT,
    TAX_ADVISORY,
} from './Todo.const'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AppoinmentModal from '../../calender/AppoinmentModal'
import {
    ADVISORY_CALL_TOPIC,
    DISCOVERY_CALL_TOPIC,
} from '../../guided-discovery/constants'
import { 
    isBankSetupCompletedOrDismissed, 
    isMerchantSetupCompletedORDismissed 
} from '../AccountSetupItems/AccountSetupUtility'
import { AccountProgress, AccountStep } from '../../../models/dashboardModel'
import { hasPermission } from '../../../services/aclService'
import Icon from '../../common/Icon'
import clsx from 'clsx';
import useDeviceSize from '../../../hooks/useDeviceSize'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            margin: '1rem 0 1rem 0',
        },
        alertStyle: {
            '& .MuiAlert-message': {
                width: '100%',
            },
        },
        btnColor: {
            color: colorTheme => colorTheme.black100,
            fontWeight: 600,
        },
        headerText: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        alertTextStyle: {
            marginTop: '0.4rem',
        },
        iconStyle: {
            display: "flex",
            justifyContent: "center",
        },
        alertContent: {
            width: '100%',
            color: colorTheme => colorTheme.black100,
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '1rem'
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '1rem'
            }
        },
        mt8: {
            marginTop: toRem16(8),
        },
        progressStyle: {
            width: '50%',
            marginTop: toRem16(8),
            borderRadius: toRem16(8),
            height: toRem16(8),
            '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: colorTheme => colorTheme.black400
            }

        },
        headerStyle: {
            display: 'grid',
            gridTemplateColumns: `${toRem16(64)} 1fr`,
            marginTop: '1rem',
        },
        textCenter: {
            textAlign: 'center',
        },
        primaryIconStyle: {
            display: 'flex',
            justifyContent: 'center',
        },
        itemsListContent: {
            display: 'grid',
            gridTemplateColumns: '64px 1fr',
        },
        stepIconStyle: {
            textAlign: 'center',
            marginTop: '1rem',
            position: 'relative',
        },
        verticalLine: {
            top: `${toRem16(25)}`,
            left: `${toRem16(31)}`,
            height: '90%',
            position: 'absolute',
            borderLeft: colorTheme => `${toRem16(2)} solid ${colorTheme.grey200}`,
        },
        merchantCardContainer: {
            margin: '0.5rem 0.125rem 1rem 0'
        },
        bankCardContainer: {
            margin: '0.5rem 0.125rem 0.5rem 0'
        },
        appointmentContainer: {
            margin: '1rem 0.125rem 0.5rem 0'
        },
        textStyle: {
            height: '40px',
            marginLeft: '0.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        headerContent: {
            marginLeft: '0.5rem'
        },
        dividerStyle: {
            marginRight: '1rem'
        },
        workIconStyle: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
        },
        cursorPointer: {
            cursor: "pointer"
        },
        topMargin: {
            marginTop: '1rem'
        }
    }
})

export const TodoSection = ({ 
    accountProgress, 
    fetchAccountProgress, 
    dismissPopupInfo,
    setupCompletionPercent,
    setLoading,
    todoSteps,
    remainingToDos
}: {
    accountProgress: AccountProgress | undefined,
    fetchAccountProgress: () => void
    dismissPopupInfo: string[]
    setupCompletionPercent: number
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    todoSteps: AccountStep[],
    remainingToDos: number
}) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()
    const { appData } = useCurrentStore()
    const [openSteps, setOpenSteps] = useState(true)
    const [appointmentTopic, setAppointmentTopic] = useState<any>([])
    const [openAppointmentModal, setOpenAppointmentModal] = useState(false)
    const [showAlert, setShowAlert] = useState(true)

    const appointmentAccountantId =
        appData.user?.financial_pod?.tax_advisor?.accountant_id
    
    const handleModalClose = () => {
        setOpenAppointmentModal(false)
        fetchAccountProgress?.()
    }

    const isStepCompleted = (step: any) => {
        const stepId = step.id
        switch (stepId) {
            case ATTEND_DISCOVERY_CALL:
                return accountProgress?.completed_discovery_appointment
            case ATTEND_ADVISORY_CALL:
                return accountProgress?.completed_advisory_appointment
            case CONNECT_BANK_ACCOUNT:
                return isBankSetupCompletedOrDismissed(accountProgress as AccountProgress, dismissPopupInfo)
            case MERCHANT_ACCOUNT_SETUP:
                return isMerchantSetupCompletedORDismissed(accountProgress as AccountProgress, dismissPopupInfo)
            default:
                return false
        }
    }

    const RenderBlock = ({ step }: any) => {
        const stepId = step.id

        switch (stepId) {
            case ATTEND_DISCOVERY_CALL:
                return (
                    <div className={classes.appointmentContainer}>
                        <DiscoveryCall
                            primaryText={step.primary_text}
                            secondaryText={step.secondary_text}
                            completeButtonText={step.incomplete_button_text}
                            scheduleAppointment={() => {
                                setAppointmentTopic([DISCOVERY_CALL_TOPIC])
                                setOpenAppointmentModal(true)
                            }}
                            accountProgress={accountProgress}
                            fetchAccountProgress={fetchAccountProgress}
                        />
                    </div>
                )
            case ATTEND_ADVISORY_CALL:
                return (
                    <div className={classes.appointmentContainer}>
                        <AdvisoryCall
                            primaryText={step.primary_text}
                            secondaryText={step.secondary_text}
                            completeButtonText={step.complete_button_text}
                            accountProgressInfo={accountProgress}
                            inCompleteButtonText={step.incomplete_button_text}
                            fetchAccountProgress={fetchAccountProgress}
                            scheduleAppointment={() => {
                                setAppointmentTopic([ADVISORY_CALL_TOPIC])
                                setOpenAppointmentModal(true)
                            }}
                        />
                    </div>
                   
                )
            case CONNECT_BANK_ACCOUNT:
                return (
                    <div className={classes.bankCardContainer}>
                        <ConnectBankAccountCards
                            primaryText={step.primary_text}
                            secondaryText={step.secondary_text}
                            completeButtonText={step.incomplete_button_text}
                            accountProgress={accountProgress}
                            dismissPopupInfo={dismissPopupInfo}
                            fetchAccountProgress={fetchAccountProgress}
                            setLoading={setLoading}
                        />
                    </div>
                )
            case MERCHANT_ACCOUNT_SETUP:
                return (
                    <div className={classes.merchantCardContainer}>
                        <MerchantProcessingCard
                            primaryText={step.primary_text}
                            secondaryText={step.secondary_text}
                            completeButtonText={step.incomplete_button_text}
                            accountProgress={accountProgress}
                            dismissPopupInfo={dismissPopupInfo}
                            fetchAccountProgress={fetchAccountProgress}
                            setLoading={setLoading}
                        />
                    </div>
                    
                )
            default:
                return null
        }
    }

    return (
        <div>
            {appointmentTopic.length && appointmentAccountantId ? (
                <AppoinmentModal
                    topics={appointmentTopic}
                    open={openAppointmentModal}
                    handleClose={handleModalClose}
                    accountantId={appointmentAccountantId}
                />
            ) : (
                ''
            )}
            <UiText
                variant={isMobileDevice ? "hatchback_125" : "suv_150"}
                weight="semi_bold_600"
                className={classes.headerText}
            >
                Your To-Do ({remainingToDos})
            </UiText>
            <Collapse in={showAlert}>
                <UiAlert
                    icon="InfoIcon"
                    iconColor={colorTheme.blue200}
                    severity="info"
                    customStyles={{ root: classes.alertStyle }}
                    customContent={
                        <div className={classes.alertContent}>
                            <UiText className={classes.alertTextStyle}>
                                See the progress we’re making for your business at a
                                glance.
                            </UiText>
                            <Button variant="text" className={classes.btnColor} onClick={()=> {
                               setShowAlert(false)  
                            }}>
                                Okay, Got it
                            </Button>
                        </div>
                    }
                />
            </Collapse>
            <div className={classes.container}>
                <div className={classes.headerStyle}>
                    <div className={classes.workIconStyle}>
                        <Work />
                    </div>
                    <div className={classes.headerContent}>
                        <UiText variant="hatchback_125" weight="medium_500">
                            Finish Your Account
                        </UiText>
                        <UiText
                            textColor="textSecondary"
                            className={classes.mt8}
                        >
                            {setupCompletionPercent}% complete
                        </UiText>
                        <LinearProgress
                            variant="determinate"
                            value={setupCompletionPercent}
                            className={classes.progressStyle}
                        />
                    </div>
                </div>
                <div className={clsx(classes.itemsListContent, classes.cursorPointer, classes.topMargin)} 
                    onClick={() => {
                        setOpenSteps(!openSteps)
                    }}
                >
                    <div
                        className={classes.workIconStyle}
                    >
                        {openSteps ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </div>
                    <UiText className={classes.textStyle}>
                        {openSteps ? 'Hide Steps' : 'Open Steps'}
                    </UiText>
                </div>
                <Collapse in={openSteps} timeout="auto">
                    <div className={classes.itemsListContent}>
                        <div className={classes.iconStyle}>
                            <Icon 
                                icon="CompleteIconPrimary" 
                                customClass={classes.primaryIconStyle}
                                svgColor={colorTheme.primary}
                            />
                        </div>
                        <UiText className={classes.textStyle}>
                            Sign in to your 1-800Accountant Portal.
                        </UiText>
                    </div>
                    {todoSteps.map((step: any) => {
                        return (
                            <div
                                className={classes.itemsListContent}
                                key={step.id}
                            >
                                <div className={classes.stepIconStyle}>
                                    {isStepCompleted(step) ? (
                                        <Icon 
                                            icon="CompleteIconPrimary" 
                                            customClass={classes.primaryIconStyle}  
                                            svgColor={colorTheme.primary}
                                        />
                                    ) : (
                                        <EmptyCircle />
                                    )}
                                    {step.id === ATTEND_DISCOVERY_CALL && (
                                        <div
                                            className={classes.verticalLine}
                                        ></div>
                                    )}
                                </div>
                                <div>
                                    <Divider className={classes.dividerStyle}/>
                                    <RenderBlock step={step} />
                                </div>
                            </div>
                        )
                    })}
                </Collapse>
            </div>
        </div>
    )
}