import { Fragment } from 'react';
import { Theme, makeStyles } from '@material-ui/core'
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
} from '@material-ui/core'
import Icon from '../../../common/Icon'
import IconList from '../../../common/IconList'
import UiText from '../../../common/ui/UiText'
import UiTextView from '../../../common/ui/UiTextView'
import { commonStyles } from '../../../../styles/commonStyles'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const propertyIconMapping = [
    { key: 'payment_terms', icon: 'dollar', label: 'Payment Terms' },
    { key: 'mobile', icon: 'mobile', label: 'Mobile' },
    { key: 'phone', icon: 'call', label: 'Phone' },
    { key: 'email', icon: 'email', label: 'Email' },
    { key: 'fax', icon: 'fax', label: 'Fax' },
    { key: 'address', icon: 'location', label: 'Address' },
    { key: 'website', icon: 'website', label: 'Website' },
    { key: 'company', icon: 'company', label: 'Company' },
    { key: 'additional_info', icon: 'text', label: 'Additional Info' },
]

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    itemHeight: {
        height: '20px',
        marginBottom: '8px',
    },
    blueLink: {
        color: (colorTheme) => `${colorTheme.blue600} !important`,
    },
}))

export default function ContactDetails({ contact }: { contact: any }) {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const styles = commonStyles()
    const { address, city, state, country, zip_code } = contact?.address || {}

    const RenderListItem = (property: any) => {
        const itemVal = contact?.[`${property?.key}`]
            ? contact?.[`${property?.key}`]
            : '-'
        return (
            <>
                {['phone', 'mobile'].includes(property?.key) ? (
                    <UiTextView type="phone" value={contact.phone} />
                ) : (
                    itemVal
                )}
            </>
        )
    }

    return (
        <List>
            {propertyIconMapping.map((property) => (
                <Fragment key={property.key}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar className={styles.mt16}>
                            <Icon
                                icon={property.icon as keyof typeof IconList}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <UiText textColor="textSecondary">
                                    {property?.label}
                                </UiText>
                            }
                            secondary={
                                <div className={classes.itemHeight}>
                                    {!['address', 'additional_info'].includes(
                                        property?.key
                                    ) ? (
                                        <UiText
                                            textColor="textPrimary"
                                            className={
                                                property?.label === 'Website'
                                                    ? classes.blueLink
                                                    : ''
                                            }
                                        >
                                            {RenderListItem(property)}
                                        </UiText>
                                    ) : (
                                        <div>
                                            {property?.key === 'address' && (
                                                <UiText textColor="textPrimary">
                                                    {address} {city} {state}{' '}
                                                    {zip_code}
                                                    {country}{' '}
                                                </UiText>
                                            )}
                                            {property?.key ===
                                                'additional_info' && (
                                                <div
                                                    className={
                                                        styles.preWhiteSpace
                                                    }
                                                >
                                                    <UiText textColor="textPrimary">
                                                        {' '}
                                                        {
                                                            contact.additional_info
                                                        }
                                                    </UiText>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <Divider
                                        variant="fullWidth"
                                        className={styles.mt8}
                                    />
                                </div>
                            }
                        />
                    </ListItem>
                </Fragment>
            ))}
        </List>
    )
}
