import { Form, Formik } from 'formik'
import { useStyles } from '../CompanyCommonStyles'
import UiText from '../../../common/ui/UiText'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import {
    compensationFormMappings,
    employeeInfoValidationSchema,
    getInitialValues,
    jobDetailFormMapping,
    personalDetailFormMapping,
    RenderForm,
} from './FormData'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { Fragment, useState } from 'react'
import { useADPCompanyContext } from '../../provider/CompanyProvider'
import { createFirstEmployee } from '../../../../services/apiService/adp-payroll/company'
import NextActionButton from '../../../payroll/common/NextActionButton'
import moment from 'moment'
import { ADPCompanyDetails } from '../../../../models/adp-payroll/company'
import { showAlert } from '../../../../store/actions/feedback'
import { useDispatch } from 'react-redux'
import FormHeaderText from '../../../payroll/common/FormHeaderText'
import useDeviceSize from '../../../../hooks/useDeviceSize'
import { Grid, Hidden } from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'

const FirstEmployee = ({
    refreshSteps,
    goBack,
}: {
    refreshSteps: () => void
    goBack: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const {
        companyDetails,
        setRefetchCompany,
        isCompanyOnboarding,
        isCompanyActive,
        formikRef,
    }: {
        companyDetails: ADPCompanyDetails
        setRefetchCompany: React.Dispatch<React.SetStateAction<boolean>>
        isCompanyOnboarding: boolean
        isCompanyActive: boolean
        formikRef: any
    } = useADPCompanyContext()

    const disableCompanyEdit = isCompanyOnboarding || isCompanyActive
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let initialValues = getInitialValues(companyDetails)
    const { isSmDevice, isXsDevice } = useDeviceSize()
 

    const onSubmit = (values: any, { setFieldError }: any) => {
        setLoading(true)
        let address = {
            city: companyDetails?.companyDetails.city,
            zip: companyDetails?.companyDetails.zip_code,
            street_1: companyDetails?.companyDetails?.street,
            street_2: companyDetails?.companyDetails?.street_2,
            state: companyDetails?.companyDetails?.state,
            country: 'US',
        }

        let params = {
            ...values,
            dob: values?.dob,
            hire_date: values?.hire_date,
            job_location: address,
            is_employee_owner: true,
            is_primary_owner: true,
        }
        createFirstEmployee(params)
            .then((res) => {
                if (res) {
                    setLoading(false)
                    refreshSteps()
                    setRefetchCompany(true)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText:
                            error?.message ||
                            error?.statusText ||
                            'Something went wrong',
                    })
                )
                if (error?.errors && setFieldError) {
                    Object.keys(error.errors).forEach((key) => {
                        setFieldError(key, error.errors[key][0])
                    })
                }
            })
    }


    const JobDetails = ({}) => <>{jobDetailFormMapping(companyDetails, formikRef?.current?.setFieldValue).map(
        (property: any) => (
            <Fragment key={property.key}>
                {RenderForm({...property, size: isXsDevice ? "medium" : "small"})}
            </Fragment>
        )
    )}</>

    const CompensationDetails = ({}) => 
        <>{compensationFormMappings(formikRef?.current?.values).map(
            (property: any) => (
                <Fragment key={property.key}>
                    {RenderForm({...property, size: isXsDevice ? "medium" : "small"})}
                </Fragment>
            )
        )}</>

    return (
        <div>
            <FormHeaderText
                heading="First Employee"
                hideOnMobile={isSmDevice}
            />
            <UiText className={isSmDevice ? styles.fieldMargin : ''}>
                Let’s add your first employee. This can be yourself.
            </UiText>
            <div className={styles.containerStyle}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={employeeInfoValidationSchema}
                    enableReinitialize
                    innerRef={formikRef}
                >
                    {({ submitForm, values, errors, setFieldValue }) => {
                        return (
                            <div className={styles.formStyle}>
                                <Form>
                                    <UiText
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                        className={styles.sectionHeader}
                                    >
                                        Personal Info
                                    </UiText>
                                    {personalDetailFormMapping.map(
                                        (property: any) => (
                                            <Fragment key={property.key}>
                                                {RenderForm({...property, size: isXsDevice ? "medium" : "small"})}
                                            </Fragment>
                                        )
                                    )}
                                    <UiText
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                        className={styles.sectionHeader}
                                    >
                                        Employment
                                    </UiText>
                                    <UiText variant="moped_75">
                                        (Required)
                                    </UiText>
                                    <UiText>
                                        Is this owner also an employee?
                                    </UiText>
                                    <div className={styles.fieldMargin}>
                                        <UiFormControlSelection
                                            type="radio"
                                            label="Is this owner also an employee?"
                                            fieldName="is_owner_an_employee"
                                            radioOptionsData={[
                                                {
                                                    label: 'Yes',
                                                    value: true,
                                                },
                                                {
                                                    label: 'No',
                                                    value: false,
                                                },
                                            ]}
                                            onChange={(e) => {
                                                if(e.target.value === 'true') {
                                                    setFieldValue(
                                                        'is_owner_an_employee',
                                                        true
                                                    )
                                                } else {
                                                    const fields = ['job_type', 'hire_date', 'job_title','job_location','amount', 'frequency']
                                                    fields.forEach(field => {
                                                        setFieldValue(field, '')
                                                    })
                                                    setFieldValue(
                                                        'is_owner_an_employee',
                                                        false
                                                    )
                                                }
                                                
                                                
                                            }}
                                        />
                                    </div>
                                    { values?.is_owner_an_employee && <div>
                                        <UiText
                                            variant="hatchback_125"
                                            weight="semi_bold_600"
                                            className={styles.sectionHeader}
                                        >
                                            Job Details
                                        </UiText>
                                        <JobDetails />
                                        <UiText
                                            variant="hatchback_125"
                                            weight="semi_bold_600"
                                            className={styles.sectionHeader}
                                        >
                                            Compensation
                                        </UiText>
                                        <CompensationDetails />
                                    </div>}
                                    <UiText
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                        className={styles.sectionHeader}
                                    >
                                        Ownership Status
                                    </UiText>

                                    <div className={styles.fieldMargin}>
                                        <UiText>
                                            What percentage of the company does
                                            the employee own? (Required)
                                        </UiText>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={4}>
                                                <div
                                                    className={
                                                        styles.fieldMargin
                                                    }
                                                >
                                                    <UiFormControlSelection
                                                        fastField={false}
                                                        type="number"
                                                        fieldName={`ownership_percentage`}
                                                        endIcon={'%'}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Hidden smDown>
                                        {!disableCompanyEdit && (
                                            <NextActionButton
                                                loading={loading}
                                                submitAction={submitForm}
                                                arrowIcon={true}
                                            />
                                        )}
                                    </Hidden>
                                    <Hidden mdUp>
                                        <div className={styles.nextPrevContainer}>
                                            {!disableCompanyEdit && (
                                                <NextActionButton
                                                    loading={loading}
                                                    justifyLoader='center'
                                                    submitAction={submitForm}
                                                    arrowIcon={true}
                                                    fullWidth={true}
                                                    customClass={styles.nxtBtn}
                                                />
                                            )}
                                             <UiButton
                                                btnType="hyperlink"
                                                label="Previous"
                                                fullWidth
                                                customClass={
                                                    styles.nxtBtn
                                                }
                                                handleClick={goBack}            
                                            />
                                        </div>
                                        
                                        
                                    </Hidden>
                                </Form>
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default FirstEmployee
