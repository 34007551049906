import { Fragment, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import UiDialog from '../../../../common/ui/UiDialog';
import UiText from '../../../../common/ui/UiText';
import { ArrowBack, Search } from '@material-ui/icons';
import { commonStyles } from '../../../../../styles/commonStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            '& .MuiPaper-root': {
                height: 816,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    margin: '0px',
                    top: '0px',
                    '& .MuiDialogTitle-root': {
                        display: 'none',
                    },
                },
            },
        },
        dialogDetails: {
            '& .MuiDialogContent-root': {
                height: '550px',
                padding: 0,
            },
            '& .MuiPaper-root': {
                '& .MuiDialogTitle-root': {
                    padding: '12px 16px',
                },
            },
        },
        inputBase: {
            width: '100%',
            flex: 1,
            padding: '10px 0 10px 0',
        },
        listRoot: {
            padding: 0,
        },
        listDivider: {
            marginLeft: '8%',
        },
        listIcon: {
            minWidth: 'auto',
        },
    })
);

interface SelectCompareDataModalProps {
    listData: any[];
    checkedData: any[];
    handleToggle: (data: any) => void;
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    idKey?: string;
    valueKey?: string;
    valueKeySecondary?: string;
    searchKey?: string;
    searchKeySecondary?: string;
}

const SelectCompareDataModal = ({
    listData, 
    checkedData,
    handleToggle,
    isOpen,
    handleClose,
    title,
    idKey = 'id',
    valueKey = 'name',
    searchKey = 'name',
    valueKeySecondary,
    searchKeySecondary,
}: SelectCompareDataModalProps) => {
    const classes = useStyles();
    const common = commonStyles();
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const [searchedList, setSearchedList] = useState<any>([]);

    useEffect(() => {
        if (!listData) return;
        setSearchedList(listData);
    }, [listData]);
    const filterDataUsingPrimarySearchKey = (searchToken: string) => {
        return listData.filter((data: any) =>
            data[searchKey].toLowerCase().includes(searchToken.toLowerCase())
        );
    };
    const filterDataUsingSecondarySearchKey = (searchToken: string) => {
        if(!searchKeySecondary) return;
        return listData.filter((data: any) =>
            data[searchKeySecondary]
                .toLowerCase()
                .includes(searchToken.toLowerCase())
        );
    };
    const handleSearch = (searchToken: string) => {
        let filteredValue;
        filteredValue = filterDataUsingPrimarySearchKey(searchToken);
        if (!filteredValue.length && searchKeySecondary) {
            filteredValue = filterDataUsingSecondarySearchKey(searchToken);
        }
        setSearchedList(filteredValue);
    };

    return (
        <UiDialog
            open={isOpen}
            handleClose={handleClose}
            disableBackdropClick={true}
            title={isXsDown ? undefined : title}
            size='sm'
            fullScreen={isXsDown ? true : false}
            customRootClass={classes.dialogDetails}
            actions={
                !isXsDown && (
                    <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        type='submit'
                        style={{ width: 'auto' }}
                        onClick={handleClose}
                    >
                        Done
                    </Button>
                )
            }
            titleNode={
                isXsDown ? (
                    <>
                        <div className={common.flex}>
                            <IconButton onClick={handleClose}>
                                <ArrowBack />
                            </IconButton>
                            <UiText
                                style={{
                                    marginTop: '10px',
                                    marginLeft: '5px',
                                }}
                                variant='hatchback_125'
                                weight='medium_500'
                            >
                                {'Select Segment'}
                            </UiText>{' '}
                        </div>
                        <Divider />
                    </>
                ) : null
            }
        >
            <>
                <div className={common.flex}>
                    <IconButton type='submit' aria-label='search'>
                        <Search />
                    </IconButton>
                    <InputBase
                        className={classes.inputBase}
                        placeholder={title}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <Divider />
                <div>
                    <List className={classes.listRoot}>
                        {searchedList.map((item: any) => {
                            return (
                               
                                <Fragment key={item[idKey]}>
                                    <ListItem
                                        role={undefined}
                                        dense
                                        button
                                        onClick={() => handleToggle(item)}
                                    >
                                        <ListItemIcon
                                            className={classes.listIcon}
                                        >
                                            <Checkbox
                                                edge='start'
                                                checked={
                                                    checkedData.filter(
                                                        (data) =>
                                                            data[idKey] ===
                                                            item[idKey]
                                                    ).length === 1
                                                }
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby':
                                                        item[valueKey],
                                                }}
                                            />
                                        </ListItemIcon>
                                        <UiText>
                                            {item[valueKey]}{' '}
                                            {valueKeySecondary &&
                                                item[valueKeySecondary]}
                                        </UiText>
                                    </ListItem>
                                    <div>
                                        <Divider
                                            className={classes.listDivider}
                                        />
                                    </div>
                                </Fragment>
                                
                            );
                        })}
                    </List>
                </div>
            </>
        </UiDialog>
    );
};

export default SelectCompareDataModal;
