import { Grid, Theme, makeStyles } from '@material-ui/core'
import UiText from '../../common/ui/UiText'

const useStyles = makeStyles((theme: Theme) => ({
    emptyStateWrapper: {
        padding: 8,
    },
    emptyTextWrapper: {
        marginTop: theme.spacing(4),
        width: '100%',
    },
}))

interface Props {
    src: string
    alt?: string
    text: React.ReactNode | string
}
function EmptyState(props: Props) {
    const classes = useStyles()
    const { src, alt, text } = props
    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.emptyStateWrapper}
        >
            <div>
                <img alt={alt ?? 'image'} src={src} />
            </div>
            <div className={classes.emptyTextWrapper}>
                <UiText textAlign="center">{text}</UiText>
            </div>
        </Grid>
    )
}

export default EmptyState
