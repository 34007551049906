import { Box, Button, makeStyles, Snackbar, Theme } from '@material-ui/core'
import { Fragment, useState } from 'react';
import Dropzone from 'react-dropzone'
import { isValidDocumentMime } from '../../../../utils/documentUtil'
import UiDialog from '../../../common/ui/UiDialog'
import { useDispatch, useStore } from 'react-redux'
import { showInfo } from '../../../../store/actions/feedback'
import { InfoOutlined } from '@material-ui/icons'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { taxDocumentUpload } from '../../../../services/apiService'
import { showError } from '../../../../services/formService'
import { VtoType } from '../models/tax-prep.interface'
import {
    DOCUMENTS_UPLOAD,
    MESSAGES,
    MIME_IMAGES,
    MIME_TYPES,
    renderFor,
} from '../constants/tax-prep.const'
import Loader from '../../../common/Loader'
import UiText from '../../../common/ui/UiText'
import { Alert } from '@material-ui/lab'
import { useWidgetContext } from '../provider/WidgetProvider'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

interface DocumentUpload {
    size: number
    is_preview_available: boolean
    mime_type: string
    id: string
    filename: string
    portal_account_id: string
    deleted: boolean
    upload_date: number
    type: string
    is_read_by_user: boolean
    is_read_by_accountant: boolean
    path: string
    is_owned_by_accountant: boolean
}
interface DocumentUploadProps {
    open: boolean
    handleClose: (fileUploaded?: string) => void
    title: string
    vtoType: renderFor
    taxYear: number
    vtoId: number
    taxPrepStatus: string
    taxApId: string | undefined
}
const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    footer: {
        display: 'flex',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        marginLeft: 'auto',
        padding: '0.7rem 1rem 0.2rem 0',
        justifyContent: 'flex-end',
    },
    container: {
        '& .MuiDialogContent-root': {
            padding: '8px 0px',
            height: '600px',
        },
    },
    content: {
        padding: '20px 24px',
    },
    fileName: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        '& span': {
            padding: '0 0 0 0.5rem',
        },
    },
    snackbarRoot: {
        bottom: '0px',
        'justify-content': 'center',
        position: 'relative',
    },
    snackbarContentDiv: {
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
    },
    actionStyles: {
        display: 'flex',
        justifyContent: 'center',
    },
    actionButton: {
        color: (colorTheme) => colorTheme.primaryWhite,
        borderLeft: (colorTheme) =>  `1px solid ${colorTheme.primaryWhite}`,
        borderRadius: 0,
    },
    dropzone: {
        padding: '1.5rem',
        border: (colorTheme) =>  `2px dashed ${colorTheme.grey200}`,
        width: '90%',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
}))
export default function DocumentsUploadModal(props: DocumentUploadProps) {
    const { open, handleClose, title, vtoType, taxYear, taxApId } = props
    const store = useStore().getState()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const { notificationClickAction, onclickOfNotification } =
        useWidgetContext()

    const allowedMimeTypes =
        store.config.apiConfig.file_center.allowed_mime_types
    const allowedDocumentTypes =
        store.config.apiConfig.file_center.allowed_extensions
    const maxFileSize = store.config.apiConfig.file_center.max_file_size
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme)
    const [allUploadedFiles, setAllUploadedFiles] = useState<any>()

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )
    const handleFileUnsupported = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <div>File(s) has unsupported format.</div>
                        <div>
                            Please upload only{' '}
                            <b>{allowedDocumentTypes.join(', ')}</b>
                        </div>
                    </div>
                ),
            })
        )
    }

    const getFormattedVtoType = (vtoType: string): VtoType => {
        if (vtoType === renderFor.BUSINESS) {
            return DOCUMENTS_UPLOAD.BUSINESS
        }
        return DOCUMENTS_UPLOAD.PERSONAL
    }
    const getUniqueFiles = (files: unknown): DocumentUpload[] => {
        const uploadedFiles = files as DocumentUpload[]
        const uniqueFiles = uploadedFiles.filter(
            (file, index, filesArray) =>
                filesArray.findIndex(
                    (newFile) => newFile.filename === file.filename
                ) === index
        )
        return uniqueFiles
    }
    const isFilesAlreadyUploaded = (allUploadedFiles: any[]) => {
        return allUploadedFiles && allUploadedFiles.length > 0
    }
    const pushNewlyAddedFiles = (newUploadedFiles: DocumentUpload[]): void => {
        newUploadedFiles.forEach((files) => {
            setAllUploadedFiles((oldArray: any) => [...oldArray, files])
        })
    }
    const setNewlyUploadedFiles = (files: File[]) => {
        const singleDimensionArrayOfFiles = files.flat()
        const uniqueFiles = getUniqueFiles(singleDimensionArrayOfFiles)
        if (isFilesAlreadyUploaded(allUploadedFiles)) {
            pushNewlyAddedFiles(uniqueFiles)
        } else {
            setAllUploadedFiles([...uniqueFiles])
        }
        setOpenSnackbar(true)
    }

    const sizeLimitMessage = (file: File)=> {
        dispatch(
            showInfo({
                infoData: (
                    <Fragment>
                        <Box textAlign='center' width='100%'>
                            <InfoOutlined fontSize='large' />
                        </Box>
                        <div>File {file.name} is too large.</div>
                        <div>
                            Please downsize the file. If you use a scanner please rescan the image at a lesser dpi.
                        </div>
                    </Fragment>
                ),
            })
        )
    }

    const limitCheck = (files: File[]) => {
        for(var file of files){
            if(file.size > maxFileSize){
                sizeLimitMessage(file)
                return true
            }
        }
        return false
    }

    const uploadFiles = async (files: File[]) => {
        const year = taxYear.toString()
        const type = getFormattedVtoType(vtoType)
        if(limitCheck(files)){
            return
        }
        setIsLoading(true)
        if (!taxApId) {
            return
        }
        taxDocumentUpload(type, year, accountId, taxApId, files)
            .then((res: any) => {
                setNewlyUploadedFiles(res)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error.statusText)
            })
    }
    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }

        if (isFileTypeUnsupported(files)) {
            handleFileUnsupported()
            return
        }
        uploadFiles(files)
    }

    const handleFinishUpload = () => {
        setAllUploadedFiles([])
        handleClose()
    }
    const handleCloseModal = (uploadMessage?: string) => {
        setAllUploadedFiles([])

        if (uploadMessage === MESSAGES.UPLOAD_SUCCESS) {
            handleClose(MESSAGES.UPLOAD_SUCCESS)
            return
        }
        handleClose()
        onclickOfNotification({ action: null })
    }
    const getUploadedDocImageType = (mimeType: string) => {
        if (
            mimeType === MIME_TYPES.IMG_JPEG ||
            mimeType === MIME_TYPES.IMG_PNG
        ) {
            return MIME_IMAGES.ATTACHMENT_IMG
        }
        if (mimeType === MIME_TYPES.APP_PDF) {
            return MIME_IMAGES.PDF
        }
        return MIME_IMAGES.GENERIC
    }
    const getImageBasedOnMimeType = (mimeType: string) => {
        const imageType = getUploadedDocImageType(mimeType)
        return (
            <img
                src={
                    require(`../../../../assets/icons-svg/${imageType}.svg`)
                        .default
                }
                alt="document"
            />
        )
    }
    const renderUploadedFiles = () => {
        if (!allUploadedFiles) return
        return allUploadedFiles.map((file: DocumentUpload) => {
            return (
                <div className={styles.fileName}>
                    {getImageBasedOnMimeType(file.mime_type)}{' '}
                    <span>{file.filename}</span>
                </div>
            )
        })
    }

    const acceptedFormat = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
    ]

    return (
        <UiDialog
            open={notificationClickAction.open === 'tax_form_uploaded' || open}
            handleClose={handleCloseModal}
            title={title}
            size="sm"
            customRootClass={styles.container}
            customDialogActionStyle={styles.actionStyles}
            actions={
                <div className={styles.snackbarContentDiv}>
                    <Snackbar
                        classes={{
                            root: styles.snackbarRoot,
                        }}
                        open={openSnackbar}
                        onClose={() => {
                            setOpenSnackbar(false)
                        }}
                        autoHideDuration={3000}
                    >
                        <Alert
                            severity="success"
                            variant="filled"
                            action={
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => {
                                        setOpenSnackbar(false)
                                    }}
                                    className={styles.actionButton}
                                >
                                    Okay
                                </Button>
                            }
                        >
                            {' '}
                            Upload Successful
                        </Alert>
                    </Snackbar>
                </div>
            }
        >
            <div className={styles.content}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Box mt={2} mb={4}>
                        <Dropzone onDrop={handleUploadFile}>
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <Box
                                        {...getRootProps({})}
                                        className={styles.dropzone}
                                        textAlign="center"
                                    >
                                        <UiText
                                            weight="medium_500"
                                            variant="hatchback_125"
                                        >
                                            Drop files here to upload
                                        </UiText>
                                        <Box my={3}>
                                            <input
                                                {...getInputProps()}
                                                name="files[]"
                                                accept={acceptedFormat.toString()}
                                            />
                                        </Box>
                                        <Box my={3}>
                                            <Button
                                                disabled={false}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Select Files
                                            </Button>
                                        </Box>
                                        <Box my={3}>
                                            <UiText textColor="secondary">
                                                Supported file types: .pdf,
                                                .png, .jpeg, .jpg
                                            </UiText>
                                        </Box>
                                    </Box>
                                )
                            }}
                        </Dropzone>
                    </Box>
                )}
                {renderUploadedFiles()}
            </div>
        </UiDialog>
    )
}
