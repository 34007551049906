import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { toRem16 } from '../../../../styles/commonStyles'
import IconList from '../../../common/IconList'
import UiText from '../../../common/ui/UiText'
import { Item } from '../models/vehicle.model'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

interface Props {
    setSelectedVehicle: (vehicle: Item) => void
    vehicles: Item[] | undefined
    selectedVehicle: Item | undefined
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        vehiclesList: {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        vehicleModelContainer: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem 0',
            margin: '0 1rem 0 0',
            '& svg': {
                minWidth: toRem16(26),
            },
        },
        vehicleModelDetails: {
            margin: 0,
        },
        yearAndNumber: {
            color: (colorTheme) => colorTheme.grey400,
        },
        defaultVehicle: {
            color: (colorTheme) => colorTheme.grey400,
        },
        selectedVehicle: {
            background: (colorTheme) => colorTheme.cyan100,
            borderRadius: '0.5rem',
            margin: `0 ${toRem16(24)} 0 0`,
            display: 'flex',
            '& .vehicleModelContainer':{
                borderBottom: '0 !important'
            }
        },
        vehicleNavItem: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 1rem 0 0',
            '&:not(:last-child) .vehicleModelContainer': {
                borderBottom: (colorTheme) => `1px solid ${colorTheme.grey300}`,
            }
        },
        vehicleIcon: {
            margin: '0 1rem',
        }
    })
)
export const VehiclesList = (props: Props) => {
    const { setSelectedVehicle, vehicles, selectedVehicle } = props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)


    const capitalizeFirstLetter = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const handleVehicleClick = (vehicle: Item) => {
        setSelectedVehicle(vehicle)
    }
    return (
        <>
            {vehicles?.map((vehicle: Item) => {
                const isSelected = vehicle.id === selectedVehicle?.id
                const listItemClasses = `${classes.vehicleNavItem} ${
                    isSelected ? classes.selectedVehicle : ''
                }`
                return (
                    <li
                        onClick={() => handleVehicleClick(vehicle)}
                        className={listItemClasses}
                        key={vehicle.id}
                    >
                        <div className={classes.vehiclesList}>
                            <div className={classes.vehicleIcon}>{IconList['vehicle']}</div>

                            <div className={`${classes.vehicleModelContainer} vehicleModelContainer`}>
                                <div className={classes.vehicleModelDetails}>
                                    <UiText
                                        variant="car_100"
                                        weight="regular_400"
                                    >
                                        {vehicle.make}
                                        {' ' + vehicle.model}
                                    </UiText>
                                    <UiText
                                        variant="motorcycle_90"
                                        weight="regular_400"
                                        className={classes.yearAndNumber}
                                    >
                                        {vehicle.year}
                                        {vehicle.license_plate_number && (
                                            <>
                                                &#x2022;{' '}
                                                {vehicle.license_plate_number}
                                            </>
                                        )}
                                    </UiText>
                                </div>
                                <div>
                                    <UiText>
                                        {capitalizeFirstLetter(vehicle.status)}
                                    </UiText>
                                    <UiText className={classes.defaultVehicle}>
                                        {vehicle.is_default && 'Default'}
                                    </UiText>
                                </div>
                            </div>
                        </div>
                    </li>
                )
            })}
        </>
    )
}
