export const LOADING_STATE = 'LOADING_STATE'

export interface LoadingAction {
    type: string
    state: boolean
}

export type Action = LoadingAction;

export const showLoading = (): LoadingAction => ({
    type: LOADING_STATE,
    state: true
})

export const hideLoading = (): LoadingAction => ({
    type: LOADING_STATE,
    state: false
})

export function setLoader(loaderState: boolean) {
    return (dispatch: any) => {
        dispatch(loaderState ? showLoading() : hideLoading());
    };
}