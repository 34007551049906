import React, { useContext } from 'react'
import { useTaxEngagementPlan } from '../hooks/useTaxEngagementPlan'
import { TaskData } from './TaxEngagementPlan'

interface TaxEngagementValue {
    taxEngagementData: TaskData | undefined
    isDataLoading: boolean
    totalTasksCompletedCount: number
    totalTasksCount: number
    isShowDismissPopup: boolean
    taskCompletionPercentage: number
    getTaxEngagementData: () => void
}

const TaxEngagementContext = React.createContext<TaxEngagementValue | null>(
    null
)

export function useTaxEngagementContext(): TaxEngagementValue {
    const state = useContext(TaxEngagementContext)
    if (!state) {
        throw new Error(
            'useTaxEngagementContext must be used within TaxEngagement Provider'
        )
    }
    return state
}

const TaxEngagementProvider = ({ children }: any) => {
    const {
        taxEngagementData,
        isDataLoading,
        totalTasksCompletedCount,
        totalTasksCount,
        isShowDismissPopup,
        taskCompletionPercentage,
        getTaxEngagementData,
    } = useTaxEngagementPlan()

    const taxEngagementValues: TaxEngagementValue = {
        taxEngagementData,
        isDataLoading,
        totalTasksCompletedCount,
        totalTasksCount,
        isShowDismissPopup,
        taskCompletionPercentage,
        getTaxEngagementData,
    }

    return (
        <TaxEngagementContext.Provider value={taxEngagementValues}>
            {children}
        </TaxEngagementContext.Provider>
    )
}

export default TaxEngagementProvider
