import store from '../store';
import { Fragment } from 'react';
import { Typography } from '@material-ui/core';

let _documentAllowMime: any;
let _documentAllowTypes: any;
let _maxDocumentFileSize: any;

const init = () => {
    let { config } = store.getState();
    _documentAllowMime = config.apiConfig.file_center.allowed_mime_types;
    _documentAllowTypes = config.apiConfig.file_center.allowed_extensions;
    _maxDocumentFileSize = config.apiConfig.file_center.max_file_size;
};

const _isValidDocumentMime = (item: File) => {
    var allowTypesSubst = _documentAllowTypes.map((t: any) => {
        return t.substr(-4);
    });
    return item.type && item.type.substr(0, 5) !== 'like/'
        ? _documentAllowMime.indexOf(item.type) !== -1
        : allowTypesSubst.indexOf(item.name.substr(-4)) !== -1;
};

export function documentFilter(item: File, maxFileSize: (number | undefined) = undefined) {
    if (!_maxDocumentFileSize) {
        init();
    }

    var result = true;
    var modal;
    const MAX_FILE_SIZE =  maxFileSize ? maxFileSize : _maxDocumentFileSize 
    if (item.size === 0) {
        modal = (
            <Fragment>
                <Typography variant='body1' gutterBottom>
                    File <b>{item.name}</b> is empty.
                </Typography>
                <Typography variant='body1'>
                    Please check and try uploading again
                </Typography>
            </Fragment>
        );

        result = false;
    } else if (item.size > MAX_FILE_SIZE) {
        //64MB
        modal = (
            <Fragment>
                <Typography variant='body1' gutterBottom>
                    File <b>{item.name}</b> is too large.
                </Typography>
                <Typography variant='body1'>
                    Please downsize the file. If you use a scanner please rescan
                    the image at a lesser dpi.
                </Typography>
            </Fragment>
        );
        result = false;
    } else if (!_isValidDocumentMime(item)) {
        modal = (
            <Fragment>
                <Typography variant='body1' gutterBottom>
                    File <b>{item.name}</b> has unsupported format.
                </Typography>
                <Typography variant='body1'>
                    Please upload only {_documentAllowTypes.join(', ')}
                </Typography>
            </Fragment>
        );
        result = false;
    }

    return result ? result : modal;
}
