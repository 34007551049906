import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    makeStyles,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    createStyles,
    Typography,
    LinearProgress,
    Hidden,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import UiDialog from '../../common/ui/UiDialog';
import { ApplicationStore, Config } from '../../../models/store';
import { cancelScanAPI, scanReceiptFiles } from '../../../services/apiService/receiptsServices';
import Loader from '../../common/Loader';
import VerifyScanResult from './VerifyScanResult';
import Dropzone from 'react-dropzone';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import { useStyles } from "../../../styles/styles";
import UiSnackbarAlert from '../../common/ui/UiSnackbarAlert';
import { categoryListProps,  maxFileSizeUpload, maxNumberFilesUpload,resizeReceiptImage, minFileSizeToCompress } from './ScanUtils';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';


interface ScanProcessProps {
    open: boolean;
    handleClose: (result?: any, action?: any) => void;
    config: Config;
    accountId: string;
    businessId: string;
    filesToScan: any[];
    listData: categoryListProps,
    setListData: any
}
interface ReceiptsScanResultProps {
    receiptsScanResult: any;
}

const allowedFileTypesToScan = ['image/jpeg', 'image/png']
const allowedFileTypesToPreview = ['image/jpeg', 'image/png'];

function ScanProcess({
    open,
    handleClose,
    config,
    accountId,
    businessId,
    filesToScan,
    listData,
    setListData
}: ScanProcessProps) {
    const { colorTheme } = useThemeContext()
    const scanStatus = {
        NOT_STARTED: 0,
        IN_PROGRESS: 1,
        COMPLETED: 2,
    };
    const useLocalStyles = makeStyles((theme: Theme) =>
        createStyles({
            marginVertical: {
                marginTop: 20,
                marginBottom: 20,
            },
            marginRight: {
                marginRight: theme.spacing(1)
            },
            marginBottom: {
                marginBottom: 20,
            },
            reviewOrderButton: {
                '&:not(:disabled)': {
                    backgroundColor: colorTheme.orange300,
                    color: colorTheme.primaryWhite,
                },
            },
            dividerStyle: {
                marginTop: theme.spacing(1),
                marginLeft: '-12px',
                marginRight: '-12px',
                borderBottom: '1px solid ' + colorTheme.grey200,
                borderRadius: '8px',
                marginBottom: theme.spacing(1),
                '&:hover': {
                    backgroundColor: colorTheme.cyan100,
                    borderColor: colorTheme.cyan100
                },
            },
            uiDialogBody: {
                minHeight: '40vh',
            },
            link: {
                cursor: 'pointer'
            },
            receiptImgPreview: {
                maxWidth: '100%',
            }
        })
    );
    const theme = useTheme();
    var fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useLocalStyles();
    
    const globalClasses = useStyles(colorTheme);
    const [fileList, setFileList] = useState<any[]>([]);
    const [maxFileCount, setMaxFileCount] = useState<number>(0);
    const [showAlert, setShowAlert] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [scanResults, setScanResult] = useState<ReceiptsScanResultProps>({
        receiptsScanResult: {},
    });
    // 0 - not started , 1-> in progress, 2 - Completed
    const [scanProcessStatus, setScanProcessStatus] = useState(
        scanStatus.NOT_STARTED
    );
    const [scanFormSubmitted, setScanFormSubmitted] = useState(false);
    const [itemIndToRemove, setItemIndToRemove] = useState<number>(-1);


    const [fileViewDialog, setFileViewDialog] = useState<any>('');
    const [validFileCount, setValidFileCount] = useState<number>(0);
    const processingTimePerFile = 15; // in seconds

    const calculateValidFileCount = useCallback(() => {
        let validCount = fileList.filter(file => allowedFileTypesToScan.includes(file.type)).length;
        setValidFileCount(validCount);
    }, [fileList]);



    useEffect(() => {
        setScanFormSubmitted(false);
        setFileList(filesToScan);
        setMaxFileCount(maxNumberFilesUpload - filesToScan.length);
        calculateValidFileCount();
        setShowAlert(false);
        setAlertMessage('');
    }, [calculateValidFileCount, filesToScan]);


    const showFilePreview = (file: any) => {
        let reader = new FileReader();
        reader.onload = function (event: any) {
            file.imgSrc = event.target.result;
            setFileViewDialog(file);
        };
        reader.readAsDataURL(file);
    };
    const getIconImageForFileType = (fileType: string) => {
        switch (fileType) {
            case 'image/jpeg':
            case 'image/png':
                return <ImageIcon style={{ color: '#F3831A' }} />; 
            case 'application/pdf': 
                return <PictureAsPdfIcon style={{ color: '#F34747' }} />;
            default:
                return <InsertDriveFileIcon style={{ color: '#087B9A' }} />;
        }
    };
    const handleLoaderClose = () => {
        cancelScanAPI();
        setScanFormSubmitted(false);
        if (scanProcessStatus !== scanStatus.NOT_STARTED) {
            setScanProcessStatus(scanStatus.NOT_STARTED);
        }
    };

    const handleModalClose = () => {
        setFileViewDialog('');
    }
    const handleRemoveConfirmDialogClose = (res: any) => {
        setItemIndToRemove(-1);
        if (res === true) {
            let prev = fileList;
            prev.splice(itemIndToRemove, 1);
            setMaxFileCount(maxNumberFilesUpload - prev.length);
            setFileList([...prev]);
            calculateValidFileCount();
        }
    }

    const onSubmit = () => {
        setScanProcessStatus(scanStatus.IN_PROGRESS);
        setScanFormSubmitted(true);
        if (!validFileCount) {
            setScanFormSubmitted(false);
            return;
        }
        let dataToSubmit = new FormData();

        fileList.forEach((file: any, ind: number) => {
            if (file && allowedFileTypesToScan.includes(file.type)) {
                dataToSubmit.append(`receipt_files[]`, file);
            }
        });

        setScanProcessStatus(scanStatus.IN_PROGRESS);
        scanReceiptFiles(accountId, businessId, dataToSubmit)
            .then((res: any) => {
                //check if predicted category exist in list
                res.forEach((t: any, ind: number) => {
                    let isAvailable = Object.values(listData?.accountRecord).filter((cate: any, i: any) =>
                        cate.id === t.category?.id
                    );
                    if (!isAvailable.length) {
                        res[ind].category = '';
                    }
                }
                );
                const tempResult = {
                    receiptsScanResult: res,
                };
                setScanResult(tempResult);
                setScanProcessStatus(scanStatus.COMPLETED);
                setScanFormSubmitted(false);
            })
            .catch((err: any) => {
                if (err) {
                    setShowAlert(true);
                    setAlertMessage(err.statusText);
                }
                setScanProcessStatus(scanStatus.NOT_STARTED);
                setScanFormSubmitted(false);
            });

    };
    const handleFileUpload = async (
        files: any[],
        rejectedFile: any[],
        event: any
    ) => {
        setShowLoader(true);
        await Promise.all(
            files.map((image: any) => {
                if(image.size>minFileSizeToCompress) {
                    return resizeReceiptImage(image);
                }
                return image;
            })
        ).then((uploadReceiptImages) => {
            setShowLoader(false);
            let prev = fileList;
            let isExist = false;
            uploadReceiptImages.forEach((file, ind) => {
                isExist = prev.find(obj => obj.name === file.name);
                if (!isExist) {
                    //file not exists.
                    prev.push(file);
                }

            });

            setMaxFileCount(maxNumberFilesUpload - prev.length);
            setFileList([...prev]);
            calculateValidFileCount();
            if (rejectedFile.length > 0) {
                let rejectedFileNames: string[] = [];
                rejectedFile.forEach((r, i) => {
                    rejectedFileNames.push(r.file.name);
                });
                setShowAlert(true);
                setAlertMessage('Rejected ' + (rejectedFileNames.join(',')) + ' due to invalid file size/type/count.');
            }
        });
    };

    const removeFileFromList = (ind: number) => {
        setItemIndToRemove(ind);
    }
    //file preview
    if (fileViewDialog) {
        return (
            <UiDialog
                key='file_preview'
                fullScreen={fullScreen}
                open={fileViewDialog ? true : false}
                handleClose={handleModalClose}
                title={fileViewDialog.name}
                size='sm'
                appBarTitle={fileViewDialog.name}
            >

                <Box className={classes.uiDialogBody}>
                    <Grid
                        container
                        direction='column'
                        alignItems='center'
                        justify='center'
                    >
                        <Grid item xs={12}>
                            {allowedFileTypesToPreview.includes(
                                fileViewDialog.type
                            ) ? (
                                <Box py={2}>

                                    <img
                                        className={classes.receiptImgPreview}
                                        src={fileViewDialog.imgSrc}
                                        alt={fileViewDialog.name}
                                    />
                                </Box>
                            ) : ''}
                        </Grid>
                    </Grid>
                </Box>
            </UiDialog>
        );
    }
    // scan in progress loader
    else if (scanProcessStatus === scanStatus.IN_PROGRESS) {
        return (
            <UiDialog
                key='scan_process_loader'
                fullScreen={fullScreen}
                open={open}
                handleClose={handleLoaderClose}
                title={`Scan ${validFileCount} Receipts`}
                size='sm'
            >
                <Box
                    className={`${classes.uiDialogBody} ${classes.marginVertical}`}
                >
                    <Grid
                        container
                        spacing={1}
                        direction={fullScreen ? 'column' : 'row'}
                        alignItems='center'
                        justify='center'
                        style={{ minHeight: fullScreen ? '80vh' : '40vh' }}
                    >
                        <Grid item xs={12}>
                            <Box my={2}>
                                <Typography variant='h5' align='center'>
                                    Scanning Receipts
                                </Typography>
                            </Box>
                            <Box my={2}>
                                <Typography align='center'>
                                    Total Estimated Time: {validFileCount * processingTimePerFile} Seconds
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container xs={12}>
                            <Box py={4} width={1}>
                                <LinearProgress />
                            </Box>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction='row'
                            alignItems='center'
                            justify='center'
                        >
                            <Button
                                className={classes.marginVertical}
                                variant='outlined'
                                color='secondary'
                                onClick={handleLoaderClose}
                            >
                                Cancel Scan
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </UiDialog>
        );
    }
    //scan completed, verify the scan result
    else if (scanProcessStatus === scanStatus.COMPLETED && scanResults) {
        return (
            <VerifyScanResult
                open={true}
                handleClose={(result: any) => {
                    setScanProcessStatus(scanStatus.NOT_STARTED);
                    if (result && result.success >= -1) {
                        handleClose(result);
                    }
                }}
                filesToScan={filesToScan}
                scanResult={scanResults.receiptsScanResult}
                allowedFileTypesToPreview={allowedFileTypesToPreview}
                getFileType={getIconImageForFileType}
                datasource={listData}
                setListData={setListData}
            />

        );
    }
    //default state - Initial stage - file list display
    else {
        return (
            <>
                <UiSnackbarAlert
                    open={alertMessage?true:false}
                    message={alertMessage}
                    handleClose={() => { setAlertMessage('') }}
                    actionButtonColor={'primary'}
                />
                <UiDialog
                    key='scan_files'
                    fullScreen={fullScreen}
                    open={open}
                    handleClose={handleClose}
                    title={`Scan ${validFileCount} Receipts`}
                    size='sm'
                    appBarSaveText='Scan'
                    onAppBarSave={onSubmit}
                >
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>

                                <Box my={2}>
                                    <Alert severity='error' className={globalClasses.alertNoteGrey}>
                                        Scanning a receipts from a connected bank
                                        account or credit card will create
                                        a duplicate journal entry for the
                                        transaction in your financial statements.
                                    </Alert>
                                </Box>
                            </Grid>
                        </Grid>
                        <List>
                            <Grid item xs={12}>
                                <ListItem key={`file`}>
                                    <ListItemText>
                                    {showLoader? (
                            <Loader />
                                ) : (
                                        <Dropzone
                                            disabled={!maxFileCount}
                                            noDrag={true}
                                            onDrop={handleFileUpload}
                                            accept='image/png,image/jpeg'
                                            maxSize={maxFileSizeUpload}
                                            maxFiles={maxFileCount}
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragAccept,
                                                isDragReject,
                                            }) => {
                                                return (
                                                    <Box
                                                        {...getRootProps({
                                                            //className: `dropzone ${additionalClass}`
                                                        })}
                                                    >
                                                        <Box my={3}>
                                                            <input
                                                                {...getInputProps()}
                                                                name='files[]'
                                                            />
                                                        </Box>
                                                        <Box my={3}>
                                                            <Button
                                                                disabled= {!maxFileCount}
                                                                variant='outlined'
                                                                color='secondary'
                                                                startIcon={
                                                                    <AddIcon />
                                                                }
                                                                data-cy="add-documents-button"
                                                            >
                                                                Add Documents
                                                            </Button>
                                                        </Box>
                                                        <Box my={3}>
                                                            <Typography
                                                                color='textSecondary'
                                                                variant='body2'
                                                            >
                                                                Supported file types: .png, .jpeg, .jpg<br />
                                                                Maximum size per file: {maxFileSizeUpload/(1024*1024)}MB<br/>
                                                                {maxFileCount>0? `${maxFileCount}  more file(s) can be uploaded.`: `Maximum file upload count reached.` }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </Dropzone>)}
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        </List>
                    </Box>

                    <Box>
                        <List>
                            {fileList.map((fileItem: any, ind: number) => {
                                return (

                                    <ListItem key={ind} className={classes.dividerStyle}>
                                        <ListItemAvatar>
                                            <span
                                                className={
                                                    globalClasses.fileTypeIconStyle
                                                }
                                            >
                                                {getIconImageForFileType(
                                                    fileItem.type
                                                )}
                                            </span>

                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Box>
                                                <Grid container>
                                                    <Grid style={{ overflow: "hidden", textOverflow: "ellipsis", width: '11rem' }} item xs={9} className={allowedFileTypesToPreview.includes(
                                                        fileItem.type
                                                    ) ? classes.link : ''}>
                                                        {allowedFileTypesToPreview.includes(
                                                            fileItem.type
                                                        ) ?
                                                            <span
                                                                onClick={() => {
                                                                    showFilePreview(
                                                                        fileItem
                                                                    );
                                                                }}
                                                            >
                                                                {fileItem.name}
                                                            </span>
                                                            : <span>
                                                                {fileItem.name}
                                                            </span>}
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Grid container
                                                            spacing={1}
                                                            direction='row'
                                                            alignItems='center'
                                                            justify='flex-end'>
                                                            <Button
                                                                variant='text'
                                                                onClick={() => { removeFileFromList(ind) }}
                                                            >
                                                                <CloseIcon />

                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>


                                );
                            })}
                        </List>
                    </Box>
                    <Hidden smDown>
                        <Box>
                            <Grid container
                                spacing={1}
                                direction='row'
                                alignItems='center'
                                justify='flex-end'>

                                <Box>
                                    <Button
                                        className={`${classes.marginVertical} ${classes.marginRight}`}
                                        type='reset'
                                        variant='outlined'
                                        color='secondary'
                                        data-cy="close-upload-button"
                                        onClick={() =>
                                            handleClose({ success: -3, error: 0 })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    {scanFormSubmitted ? (
                                        <div className={classes.marginVertical}>
                                            <Loader />
                                        </div>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className={classes.marginVertical}
                                            type='button'
                                            disabled={validFileCount ? false : true}
                                            onClick={() => {
                                                onSubmit();
                                            }}
                                            data-cy="scan-upload-button"
                                        >
                                            Scan {validFileCount} upload
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        </Box>
                    </Hidden>
                </UiDialog>
                <UiConfirmationDialog
                    open={itemIndToRemove > -1}
                    message={
                        <>
                            <Typography
                                variant='body1'
                                gutterBottom
                            >
                                Do you want to remove this  file from scan process?
                            </Typography>
                        </>
                    }
                    confirmButtonColor="primary"
                    handleClose={handleRemoveConfirmDialogClose}
                />
            </>
        );
    }
}
const mapStateToProps = ({ config, appData }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
});
export default connect(mapStateToProps)(ScanProcess);
