import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    makeStyles,
    Theme,
    Box,
    Checkbox,
    ListItemIcon,
    FormControlLabel,
    Button,
} from '@material-ui/core';

import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import {
    CategoryRule,
    IMostLikelyCategory,
    ITransaction,
} from '../../../models/transaction-models';
import CurrencyText from '../../common/currencyText';
import UiDialog from '../../common/ui/UiDialog';
import { useLocation } from 'react-router';
import {
    addCategoryRule,
    assignCategory,
    getTransactionsWIthCategoryRule,
} from '../../../services/transactionsService';
import { AppData, ApplicationStore } from '../../../models';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';
import { showError } from '../../../services/formService';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface Props {
    appData: AppData;
    category: IMostLikelyCategory;
    selectedTransaction: ITransaction | null;
    handleClose: () => void;
    handleSuccess?: () => void;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        height: 'calc(100vh - 482px)',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    timeText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 'normal',
    },
    cardIcon: {
        width: '38px',
        left: '1px',
        opacity: '0.8',
        top: '7px',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    newMessageButton: {
        height: '36px',
    },
    refeshButton: {
        fontSize: theme.spacing(3),
    },
    searchInput: {
        marginTop: theme.spacing(3),
        height: '40px',
        width: '100%',
    },
    archivedStatus: {
        marginRight: theme.spacing(3),
    },
    statusIcon: {
        position: 'relative',
        top: '3px',
        marginRight: theme.spacing(0),
    },
    selectedMessage: {
        background: (colorTheme) => `${colorTheme.green500} !important`,
    },
    messageBody: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    attachmentIconStyle: {
        fontSize: '15px',
        top: theme.spacing(0),
        position: 'relative',
    },
    subjectText: {
        maxWidth: 'calc(100% - 80px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    textMarkers: {
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        padding: theme.spacing('0', 1),
        borderRadius: '4px',
    },
    headline: {
        '& > p': {
            lineHeight: '3em',
        },
    },
    footerAction: {
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: theme.spacing(3, '0'),
        marginTop: theme.spacing(2),
        textAlign: 'end',
    },
    cancelBtn: {
        marginRight: theme.spacing(2),
    },
}));

function AutoCategorizationModal(props: Props) {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const [transactionList, setTransactionList] = useState<ITransaction[]>([]);
    const [selectedChekboxData, setselectedChekboxData] = useState<string[]>(
        []
    );
    const [isLoading, setIsLoading] = useState(false);
    const query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        getTransactions();
    }, []);

    const getCheckedvalue = (id: string) => {
        return selectedChekboxData.includes(id);
    };

    const handleModalClose = () => {
        props.handleClose();
    };

    const getTransactions = () => {
        const {
            category,
            amount,
            customer_name,
            description,
            id
        } = props.selectedTransaction as ITransaction;
        const category_rule: CategoryRule = {
            account: category as string,
            amount: amount as string,
            customer_name: customer_name as string,
            description: description as string,
            transaction_id: id as string
        };
        setIsLoading(true);

        if(!category_rule.account) {
            showError("Category is missing");
            setIsLoading(false);
            props.handleClose();
            return;
        }

        addCategoryRule(
            props.appData.current_account_id,
            props.appData.current_business_id,
            category_rule
        )
            .then((response) => {
                return getTransactionsWIthCategoryRule(
                    props.appData.current_account_id,
                    props.appData.current_business_id,
                    response?.id || '',
                    props.selectedTransaction?.account? 'account='+props.selectedTransaction?.account: ''
                );
            })
            .then((response) => {
                if(response.items.length>0) {
                    setTransactionList(response.items);
                }
                else {
                    props.handleClose();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const toggleChkBox = (id: string) => {
        const index = selectedChekboxData.indexOf(id);
        if (index === -1) {
            setselectedChekboxData([...selectedChekboxData, id]);
        } else {
            selectedChekboxData.splice(index, 1);
            setselectedChekboxData([...selectedChekboxData]);
        }
    };

    const toggleSelectAll = (event: any) => {
        const chkd = event.currentTarget.checked;
        if (!chkd) {
            setselectedChekboxData([]);
        } else {
            setselectedChekboxData(
                transactionList.map((item) => item.id as string)
            );
        }
    };

    const categorizeSelected = () => {
        if (selectedChekboxData.length > 0) {
            setIsLoading(true);
            assignCategory(
                props.appData.current_account_id,
                props.appData.current_business_id,
                {
                    category: props.category.id,
                    filters: {
                        id: selectedChekboxData,
                    },
                }
            )
                .then(() => {
                    props.handleSuccess && props.handleSuccess();
                })
                .catch((e:any)=>{
                    showError(e?.statusText);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const Messages = (data: any) => {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    {data.length === 0 ? (
                        <Box textAlign='center'>
                            <Typography
                                variant='subtitle1'
                                color='textSecondary'
                            >
                                No Transactions Were Found
                            </Typography>
                        </Box>
                    ) : (
                        <Box>
                            {data.map(
                                (element: ITransaction, index: number) => (
                                    <Fragment key={index}>
                                        <ListItem
                                            // component={Link}
                                            // to={`/transactions/${element.id}`}
                                            button
                                            classes={{
                                                gutters:
                                                    classes.navListItemGutters,
                                            }}
                                            alignItems='flex-start'
                                        >
                                            <ListItemIcon
                                                style={{ marginTop: '0' }}
                                            >
                                                <Checkbox
                                                    onChange={(
                                                        e: React.ChangeEvent
                                                    ) =>
                                                        toggleChkBox(
                                                            element.id as string
                                                        )
                                                    }
                                                    size='small'
                                                    inputProps={{
                                                        'aria-label':
                                                            'checkbox with small size',
                                                    }}
                                                    checked={getCheckedvalue(
                                                        element?.id as string
                                                    )}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Fragment>
                                                        <div
                                                            style={{
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis',
                                                            }}
                                                        >
                                                            <Typography
                                                                noWrap
                                                                key={index}
                                                            >
                                                                {element.customer_name ||
                                                                    'Unknown ' +
                                                                        (+(element.amount as string) >
                                                                        0
                                                                            ? 'Customer'
                                                                            : 'Payee')}
                                                            </Typography>
                                                        </div>
                                                    </Fragment>
                                                }
                                                secondary={
                                                    <Box
                                                        component='span'
                                                        className={
                                                            classes.messageBody
                                                        }
                                                    >
                                                        {moment(
                                                            (element.date as number) *
                                                                1000
                                                        ).format('DD MMM YYYY')}
                                                        {element.reconciliation ? (
                                                            <span
                                                                className={
                                                                    classes.textMarkers
                                                                }
                                                            >
                                                                R
                                                            </span>
                                                        ) : null}
                                                        {element.is_transfer ? (
                                                            <span
                                                                className={
                                                                    classes.textMarkers
                                                                }
                                                            >
                                                                T
                                                            </span>
                                                        ) : null}
                                                    </Box>
                                                }
                                            />
                                            <Typography
                                                className={classes.timeText}
                                                variant='caption'
                                            >
                                                <CurrencyText
                                                    value={
                                                        +(element.amount as string)
                                                    }
                                                />
                                            </Typography>
                                        </ListItem>
                                        {index === data.length - 1 ? (
                                            ''
                                        ) : (
                                            <Divider variant='inset' />
                                        )}
                                    </Fragment>
                                )
                            )}
                        </Box>
                    )}
                </List>
            </Fragment>
        );
    };

    return (
        <UiDialog
            open={true}
            handleClose={handleModalClose}
            title='Categorize Similar Transactions?'
            size='sm'
        >
            <>
                {isLoading ? (
                    <Loader />
                ) : transactionList.length > 0 ? (
                    <>
                        <Box width='100%' className={classes.headline}>
                            <Typography>
                                {' '}
                                We found{' '}
                                <strong>{transactionList.length}</strong>{' '}
                                similar uncategorized transactions.
                            </Typography>
                            <Typography>
                                {' '}
                                Categorize them as{' '}
                                <strong>{props.category.name}</strong>
                            </Typography>
                            <FormControlLabel
                                label='Select All'
                                control={
                                    <Checkbox
                                        checked={
                                            transactionList.length ===
                                            selectedChekboxData.length
                                        }
                                        onChange={toggleSelectAll}
                                    />
                                }
                            />
                        </Box>
                        <Box width='100%'>
                            {transactionList.length > 0
                                ? Messages(transactionList)
                                : null}
                        </Box>
                        <Box className={classes.footerAction}>
                            <Button
                                variant='outlined'
                                onClick={handleModalClose}
                                className={classes.cancelBtn}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => categorizeSelected()}
                                color='primary'
                            >
                                Categorize{' '}
                                {selectedChekboxData.length > 0
                                    ? `(${selectedChekboxData.length})`
                                    : ''}
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Box width='100%' className={classes.headline}>
                        <Typography>
                            No Similar Transactions Were Found
                        </Typography>
                    </Box>
                )}
            </>
        </UiDialog>
    );
}

// export default AutoCategorizationModal;
const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(AutoCategorizationModal);
