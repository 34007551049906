import { JournalEntrys,JournalEntryByID } from '../models/journalEntry-models';
import {
    deleteResource,
    getResource,
    patchResource,
    postResource    
} from "./axiosService";

export function getAccount(account_id: string, business_id: string) {
    return getResource(
        `api/account/${account_id}/business/${business_id}/account`
    );
}
export function addJournalEntry(data: any, account_id: string, business_id: string) {
    return postResource(
        `api/account/${account_id}/business/${business_id}/journal_entry`,data
    );
}
export function addCategory(data:any, account_id: string, business_id: string) {
    return postResource(
        `api/account/${account_id}/business/${business_id}/account`, data
    );
}
export function patchJournalEntry(data: any, account_id: string, business_id: string) {
    return patchResource(
        `api/account/${account_id}/business/${business_id}/journal_entry/${data.id}`,data
    );
}

export function deleteMultipleJournals(account_id: string, business_id: string,data:any) {
    return patchResource(
        `api/account/${account_id}/business/${business_id}/journal_entry`,
        data
    );
}

export function getJournalById(account_id: string, business_id: string,journalId:string) {
    return getResource<JournalEntryByID>(
        `api/account/${account_id}/business/${business_id}/journal_entry/${journalId}`,
        undefined
    );
}

export function deleteJournalById(account_id: string, business_id: string,journalId:string) {
    return deleteResource(
        `api/account/${account_id}/business/${business_id}/journal_entry/${journalId}`
    );
}

export function getJournal(account_id: string, business_id: string,params: any) {
    return getResource<JournalEntrys>(
        `api/account/${account_id}/business/${business_id}/journal_entry`,
        undefined,
        params
    )
}