import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Typography, Button, makeStyles, Theme } from '@material-ui/core'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import Deleteicon from '../../../assets/icons/Deleteicon'
import { deleteReconciliationById } from '../../../services/apiService'
import { showAlert } from '../../../store/actions/feedback'
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    deleteEntry: {
        background: (colorTheme) => colorTheme.red400,
        borderRadius: '4px',
    },
    deleteModal: {
        '& div:first-child': {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
}))

const DeleteReconciliation = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const deleteReconciliation = () => {
        setLoading(true)
        deleteReconciliationById(
            props.accountId,
            props.businessId,
            props.reconciliationId
        )
            .then((res) => {
                setLoading(false)
                props?.reloadCurrentState()
                props?.setOpenDeleteModal(false)
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: `Reconciliation data has been deleted successfully`,
                    })
                )
            })
            .catch((err) => {
                setLoading(false)
                props?.setOpenDeleteModal(false)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: `Something went wrong`,
                    })
                )
            })
    }

    return (
        <UiConfirmationDialog
            open={props?.openDeleteModal}
            message={
                <>
                    <Typography
                        variant="body1"
                        className={classes.deleteModal}
                        gutterBottom
                    >
                        <div>Delete Reconciliation?</div>
                        <div>
                            Any progress you made on this reconciliation will be
                            lost.
                        </div>
                        <div>This cannot be undone.</div>
                    </Typography>
                </>
            }
            showLoader={loading}
            handleClose={() => {
                props?.setOpenDeleteModal(false)
            }}
            confirmNode={
                <Button
                    className={classes.deleteEntry}
                    variant="contained"
                    startIcon={<Deleteicon />}
                    onClick={deleteReconciliation}
                    color="secondary"
                >
                    Delete
                </Button>
            }
            cancelButtonText="Cancel"
        />
    )
}

export default DeleteReconciliation
