import React from 'react';
import EmptyView from './EmptyView';
import NoTodoList from '../../../assets/images/NoTodoList.svg'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(()=> {
    return {
        container: {
            margin: '1rem 0 1rem 0'
        }
    }
})
const NoActivity = () => {
    const classes =  useStyles()
    return (
        <div className={classes.container}>
            <EmptyView 
                title="You’ve finished everything on your to-do list. Way to go!"
                subtitle="If there is anything we need you to do, we’ll add it here."
                image={NoTodoList}
                borderedContainer={true}
            />
        </div>
    )
}

export default NoActivity