import {
    Box,
    Grid,
    Link,
    makeStyles,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import LineChartWidget from '../../common/LineChartWidget'
import WidgetBox from '../../common/WidgetBox'
import MenuDropdown from '../../common/MenuDropdown'
import WidgetSkeletonLoader from '../../common/WidgetSkeletonLoader'
import { getBalanceSheetReport } from '../../../services/apiService'
import { connect } from 'react-redux'
import {
    currencyFormatter,
    isTrialEnded,
    percentFormatter,
} from '../../../utils/appUtil'
import { useHistory } from 'react-router-dom'
import { useStyles } from '../../../styles/styles'
import { ApplicationStore } from '../../../models'
import {
    convertUnixToGivenDateFormat,
    getCurrentTimeStamp,
    getDateInYmd,
    getTimeParamsWithoutEST,
} from '../../../utils/dateUtil'
import { BalanceSheetReportRoot } from '../../bookkeeping/reports/models/reports-interface'
import { COLORS } from '../../../variables/colors'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { toRem16 } from '../../../styles/commonStyles'
import {
    BALANCE_DATE_DROPDOWN_OPTIONS,
    findSpaceRegex,
    periodTextFormat,
} from './constants'
import EmptyState from './EmptyState'
import BalanceSheetEmpty from '../../../assets/icons-svg/BalanceSheetEmpty.svg'

enum CHART_TITLES {
    LIABILITIES = 'Liabilities',
    EQUITY = 'Equity',
}

function BalanceSheet(props: any) {
    const { colorTheme } = useThemeContext()
    const commonClasses = useStyles(colorTheme)
    const classes = makeStyles((theme: Theme) => ({
        progressbar: {
            margin: '-0.0625rem',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            '&> div:first-child': {
                borderTopLeftRadius: toRem16(5),
                borderBottomLeftRadius: toRem16(5),
            },
            '&> div:last-child': {
                borderTopRightRadius: toRem16(5),
                borderBottomRightRadius: toRem16(5),
            },
        },
        progressBarItem: {
            height: toRem16(10),
            borderRadius: 'inherit',
            opacity: 0.8,
            '&:hover': {
                opacity: '1 !important',
            },
        },
        debtRatioContainer: {
            border: `${toRem16(1)} solid ${COLORS.grey200}`,
            borderRadius: theme.spacing(0),
            padding: theme.spacing(3),
            minHeight: 92,
            minWidth: 165,
            position: 'relative',
            top: 10,
            height: 'fit-content',
            width: 'fit-content',
            [theme.breakpoints.down('sm')]: {
                top: 0,
            },
        },
    }))()

    const [loading, setLoading] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [values, setValues] = useState<any>()
    const [lineChartValues, setLineChartValues] = useState<any>([])
    const [summaryChartValue, setSummaryChartValue] = useState<any>()
    const history = useHistory()
    const businessID = props.appData.current_business_id
    const accountID = props.appData.current_account_id

    const getPeriodText = (date: number): string => {
        const fromDateInMs = date * 1000
        const convertedDate = convertUnixToGivenDateFormat(
            periodTextFormat,
            fromDateInMs
        )
        const periodText = `As of ${convertedDate}`
        return periodText.replace(findSpaceRegex, '+')
    }
    const getDateAndTimeParams = useCallback(() => {
        const currentTimeStamp = getCurrentTimeStamp()
        const selectedDateType = BALANCE_DATE_DROPDOWN_OPTIONS[selectedIndex]
        const { startDate, endDate }: any =
            getTimeParamsWithoutEST(selectedDateType)
        const endDateYmd = getDateInYmd(endDate)
        const periodText = getPeriodText(endDate)
        return {
            periodText,
            currentTimeStamp,
            endDateYmd,
            startDate,
            endDate,
        }
    }, [selectedIndex])

    useEffect(() => {
        if (isTrialEnded()) {
            setValues(undefined)
            setLoading(false)
            return
        }
        const getTotalAssets = (data: BalanceSheetReportRoot) => {
            const balance = Number.parseFloat(data.assets.balance)
            return balance <= 0 ? 0 : balance
        }
        const getTotalLiabilitiesAndCapital = (
            data: BalanceSheetReportRoot
        ) => {
            const liabilitiesAndCapital = Number.parseFloat(
                data.total_liabilities_and_capital
            )
            if (liabilitiesAndCapital <= 0) {
                return 0
            }
            return liabilitiesAndCapital
        }
        const setChartValuesSummary = (
            totalAssets: number,
            totalLiabilitiesAndCapital: number,
            sum: number
        ) => {
            setSummaryChartValue({
                totalAssetsWidth: sum ? (totalAssets * 100) / sum : 0,
                totalLiabilitiesAndCapitalWidth: sum
                    ? (totalLiabilitiesAndCapital * 100) / sum
                    : 0,
            })
        }
        const setLineChartWidthAttributes = (data: BalanceSheetReportRoot) => {
            const totalAssets = getTotalAssets(data)
            const totalLiabilitiesAndCapital =
                getTotalLiabilitiesAndCapital(data)
            const sum = totalAssets + totalLiabilitiesAndCapital
            setChartValuesSummary(totalAssets, totalLiabilitiesAndCapital, sum)
        }
        const setValuesIntoLineChart = (data: BalanceSheetReportRoot) => {
            const liabailitiesBalance = data.liabilities.balance
            const capitalBalance = data.capital.balance
            setLineChartValues([
                {
                    title: CHART_TITLES.LIABILITIES,
                    amount: Number.parseFloat(liabailitiesBalance),
                    color: COLORS.teal300,
                },
                {
                    title: CHART_TITLES.EQUITY,
                    amount: Number.parseFloat(capitalBalance),
                    color: COLORS.test1,
                },
            ])
        }
        setLoading(true)
        const { periodText, currentTimeStamp, endDateYmd } =
            getDateAndTimeParams()
        const classId = ''
        getBalanceSheetReport(
            accountID,
            businessID,
            periodText,
            currentTimeStamp,
            endDateYmd,
            classId
        )
            .then((result: unknown) => {
                const data = result as BalanceSheetReportRoot
                setValues(data)
                setValuesIntoLineChart(data)
                setLineChartWidthAttributes(data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setValues(undefined)
            })
    }, [accountID, businessID, getDateAndTimeParams])

    const getRedirectionUrl = (
        periodText: string,
        time: number,
        startDate: number,
        endDate: number,
        endDateYmd: string
    ) => {
        return `/reports/balance-sheet/${periodText}/${time}/${endDateYmd}/${
            startDate ?? endDate
        }/${endDate ?? startDate}`
    }
    const redirectToBalanceSheetReports = () => {
        const { periodText, currentTimeStamp, startDate, endDate, endDateYmd } =
            getDateAndTimeParams()
        const redirectionUrl = getRedirectionUrl(
            periodText,
            currentTimeStamp,
            startDate,
            endDate,
            endDateYmd
        )
        history.push(redirectionUrl)
    }

    function ChartSummary() {
        return (
            <div>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.progressbar}
                >
                    <Grid
                        style={{
                            width: `calc(${summaryChartValue.totalAssetsWidth}% - 1px)`,
                        }}
                        item
                    >
                        <Tooltip
                            title={`${summaryChartValue.totalAssetsWidth}%`}
                        >
                            <Box
                                bgcolor={COLORS.green900}
                                className={classes.progressBarItem}
                            />
                        </Tooltip>
                    </Grid>
                    {!(
                        !summaryChartValue.totalAssetsWidth ||
                        !summaryChartValue.totalLiabilitiesAndCapitalWidth
                    ) && (
                        <Box
                            bgcolor={COLORS.grey200}
                            width={toRem16(2)}
                            height={toRem16(24)}
                        />
                    )}
                    <Grid
                        style={{
                            width: `calc(${summaryChartValue.totalLiabilitiesAndCapitalWidth}% - 1px)`,
                        }}
                        item
                    >
                        <Tooltip
                            title={`${summaryChartValue.totalLiabilitiesAndCapitalWidth}%`}
                        >
                            <Box
                                bgcolor={COLORS.test2}
                                className={classes.progressBarItem}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Fragment>
                        <div>
                            <Typography
                                component={Link}
                                color="textPrimary"
                                className={commonClasses.dashboardTextLink}
                                onClick={() => redirectToBalanceSheetReports()}
                                variant="h4"
                            >
                                {currencyFormatter.format(
                                    values.assets.balance
                                )}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                Total Assets
                            </Typography>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Typography
                                component={Link}
                                color="textPrimary"
                                className={commonClasses.dashboardTextLink}
                                onClick={() => redirectToBalanceSheetReports()}
                                variant="h4"
                                align="right"
                            >
                                {currencyFormatter.format(
                                    values.total_liabilities_and_capital
                                )}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                Total Liabilities & Equity
                            </Typography>
                        </div>
                    </Fragment>
                </Grid>
            </div>
        )
    }

    return (
        <WidgetBox
            heading="Balance Sheet"
            actionControl={
                props.trialEnded ? (
                    ''
                ) : (
                    <MenuDropdown
                        options={BALANCE_DATE_DROPDOWN_OPTIONS}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        disabled={loading}
                    />
                )
            }
        >
            {loading ? (
                <WidgetSkeletonLoader
                    justifyContent="flex-start"
                    alignItems="flex-start"
                />
            ) : values ? (
                <Fragment>
                    <Grid
                        style={{ marginBottom: toRem16(12) }}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                        className={classes.debtRatioContainer}
                    >
                        <Typography
                            component={Link}
                            color="textPrimary"
                            className={commonClasses.dashboardTextLink}
                            onClick={() => redirectToBalanceSheetReports()}
                            variant="h4"
                        >
                            {percentFormatter.format(
                                values.debt_to_assets_ratio
                            )}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            Debt-to-Assets Ratio
                        </Typography>
                    </Grid>
                    <ChartSummary />
                    <Grid style={{ marginTop: toRem16(8) }} container>
                        <LineChartWidget
                            values={lineChartValues}
                            summary={''}
                            handleAmountClick={redirectToBalanceSheetReports}
                        />
                    </Grid>
                </Fragment>
            ) : (
                <EmptyState
                    alt='balance sheet empty image'
                    src={BalanceSheetEmpty}
                    text={
                        <span>
                            Know your business's worth at a given time.{' '}
                            <br></br>
                            We'll highlight your assets, liabilities, and owner
                            equity.
                        </span>
                    }
                />
            )}
        </WidgetBox>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    trialEnded: state.auth.isTrialEnded,
})
export default connect(mapStateToProps)(BalanceSheet)
