import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import BankGroup from '../bookkeeping/banking/BankGroup'
import BankAccountPlaid from '../bookkeeping/banking/banking-plaid/BankGroup'
import BankingInfoPage from '../bookkeeping/banking/BankingInfoPage'
import { useBankDetailsState } from '../bookkeeping/banking/provider/BankDetailsProvider'

export const BankingRoutes: React.FC = () => {
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()
    const { isFincity } = useBankDetailsState()

    return (
            <Switch>
                <Fragment>
                    <Hidden mdUp>
                        <Route path="/bank_accounts" exact>
                            <Container
                                className={classes.container}
                                maxWidth="lg"
                            >
                                <BankingInfoPage />
                            </Container>
                        </Route>
                        <Route path={`/bank_accounts/:id`} exact>
                            <Container
                                className={classes.container}
                                maxWidth="lg"
                            >
                                {!isFincity ? (
                                    <BankAccountPlaid />
                                ) : (
                                    <BankGroup />
                                )}
                            </Container>
                        </Route>
                    </Hidden>
                    <Hidden smDown>
                        <Route path="/bank_accounts">
                            <Container
                                className={classes.container}
                                maxWidth="lg"
                            >
                                <BankingInfoPage />
                            </Container>
                        </Route>
                    </Hidden>
                </Fragment>
            </Switch>
    )
}
