import { Fragment, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Button,
    makeStyles,
    Theme,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { useStore } from 'react-redux';
import { COLORS } from '../../../variables/colors';
import UiText from '../../common/ui/UiText';
import UiAlert from '../../common/ui/UiAlert';
import FormHeaderText from './FormHeaderText';
import {
    getESign,
    makeESign,
    getPayrollSignatory,
} from '../../../services/apiService/payroll/company';
import Loader from '../../common/Loader';
import IconList from '../../common/IconList';
import { formSubmitErrorHandler } from '../../../services/formService';
import { mergeStyle } from '../../../styles/commonStyles';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    marginText: {
        marginTop: '8px',
        marginBottom: '8px',
        color: colorTheme => colorTheme.primaryBlack,
    },
    disableSection: {
        color: '#00000042', //  to do - le me - mumma, i am done
        cursor: 'default',
        pointerEvents: 'none',
    },
    leftMargin: {
        marginLeft: '4px',
    },
    formBox: {
        marginRight: '12px',
        height: '50px',
    },
    gridStyle: {
        height: 'calc(100% - 108px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    }
}));

function ElectronicSignatureForm({ refreshSteps }: any) {
    const { colorTheme } = useThemeContext()
    const style = useStyles(colorTheme);
    const store = useStore().getState();
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [currentForm, setCurrentForm] = useState<string>('');
    const [formResponse, setFormResponse] = useState<any>(null);
    const [isFormSigning, SetFormSigning] = useState<any>({
        US_8655: false,
        company_direct_deposit: false,
    });
    const [signatureText, setSignatureText] = useState<string>('');
    const [aggreedToEsign, setAggreedToEsign] = useState<boolean>(false);

    const currentBusinessId = store.appData.current_business_id;
    const isBankAccountAdded =
        store.company.companyOnboardingStepInfo.onboarding_steps_status
            .bank_account_addition;
    const isEsignCompleted =
        store.company.companyOnboardingStepInfo.onboarding_steps_status
            .e_signature_addition;

    const onSubmit = (formString: string) => {
        let params = {
            agreed_to_use_electronic_signature: true,
            signature_text: signatureText,
            forms_data: [
                {
                    ...formResponse?.forms_data?.[formString],
                },
            ],
        };

        formSubmitErrorHandler(
            makeESign(currentBusinessId, params).then((apiResponse) => {
                if (apiResponse) {
                    let currentRes = formResponse;
                    let currFormResonse = currentRes.forms_data?.[formString];
                    let mergedResponse = {
                        ...currFormResonse,
                        ...apiResponse?.[0],
                    };
                    setAggreedToEsign(true)

                    currentRes.forms_data = {
                        ...currentRes.forms_data,
                        [formString]: mergedResponse,
                    };
                    setFormResponse(currentRes);
                    SetFormSigning((prev: any) => ({
                        ...prev,
                        [formString]: false,
                    }));
                    refreshSteps();
                }
            }),
            () => {
                SetFormSigning((prev: any) => ({
                    ...prev,
                    [currentForm]: false,
                }));
            },
            () => {
                SetFormSigning((prev: any) => ({
                    ...prev,
                    [currentForm]: false,
                }));
            }
        );
    };

    useEffect(() => {
        setLoading(true);
        getPayrollSignatory(currentBusinessId).then((payroll) => {
            if (payroll) {
                setSignatureText(payroll.first_name + ' ' + payroll.last_name);
            }
        });
    }, [currentBusinessId]);

    useEffect(() => {
        getESign(currentBusinessId).then((result) => {
            if (result) {
                setFormResponse(result);
                setAggreedToEsign(result?.agreed_to_use_electronic_signature);
                setChecked(result?.agreed_to_use_electronic_signature)
            }
            setLoading(false);
        });
    }, [currentBusinessId, isEsignCompleted]);

    return (
        <Grid
            className={style.gridStyle}
        >
            <FormHeaderText
                heading="Electronic Signatures"
                formType="(Required)"
            />
            {!isBankAccountAdded && (
                <div className={style.marginText}>
                    <UiAlert
                        customContent={
                            <div className={style.marginText}>
                                <UiText
                                    variant="hatchback_125"
                                    weight="semi_bold_600"
                                >
                                    Missing Information
                                </UiText>
                                <UiText
                                    variant="car_100"
                                    className={style.marginText}
                                >
                                    it looks like we are missing a few details
                                    about your company. <br /> Once you complete
                                    those sections you can sign these documents.{' '}
                                    <br />
                                </UiText>
                                <UiText variant="car_100">
                                    - Banking Information
                                </UiText>
                            </div>
                        }
                    />
                </div>
            )}
            {loading ? (
                <Loader />
            ) : (
                <>
                    {!(formResponse && formResponse.forms_data) ? (
                        <>No Form found available to sign</>
                    ) : (
                        <div
                            className={
                                isBankAccountAdded
                                    ? mergeStyle(style.leftMargin)
                                    : mergeStyle(
                                          style.leftMargin,
                                          style.disableSection
                                      )
                            }
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => {
                                            setChecked(event.target.checked);
                                        }}
                                        disabled={aggreedToEsign}
                                        name="agreee"
                                        color="primary"
                                    />
                                }
                                label="Agree to use an Electronic Signature (Required)"
                            />
                            <Box my={2}>
                                <UiText variant="car_100" textColor="secondary">
                                    Signature
                                </UiText>
                                <UiText
                                    variant="hatchback_125"
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '5px',
                                    }}
                                >
                                    {signatureText}
                                </UiText>
                                <UiText variant="motorcycle_90">
                                    The digital signature is the same as the
                                    name of the Payroll Signatory.
                                </UiText>
                            </Box>

                            {Object.keys(formResponse.forms_data).map(
                                (fieldName) => {
                                    const data =
                                        formResponse.forms_data[fieldName];
                                    return (
                                        <Fragment>
                                            <Box mt={7} display="flex">
                                                <UiText
                                                    weight="medium_500"
                                                    variant="hatchback_125"
                                                    style={{
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    {data.title} (Required)
                                                </UiText>
                                            </Box>
                                            <UiText variant="motorcycle_90">
                                                {data.description}
                                            </UiText>
                                            <Box mt={4}>
                                                <Button
                                                    variant="outlined"
                                                    style={{
                                                        color: colorTheme.black100,
                                                    }}
                                                    target="_blank"
                                                    component="a"
                                                    href={
                                                        data?.document_pdf_url
                                                    }
                                                    data-cy={`${fieldName}-view-doc-button`}
                                                >
                                                    View Document
                                                </Button>
                                            </Box>
                                            <Box mt={4}>
                                                {!data?.requires_signing ? (
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        color={colorTheme.tertiary}
                                                    >
                                                        {
                                                            IconList[
                                                                'checkCircleSmall'
                                                            ]
                                                        }
                                                        <UiText
                                                            weight="medium_500"
                                                            variant="motorcycle_90"
                                                            style={{
                                                                marginLeft:
                                                                    '5px',
                                                            }}
                                                            data-cy={`${fieldName}-form-signed-text`}
                                                        >
                                                            Digitally Signed
                                                        </UiText>
                                                    </Box>
                                                ) : (
                                                    <>
                                                        {isFormSigning[
                                                            data?.name
                                                        ] ? (
                                                            <Loader
                                                                justifyContent="flex-start"
                                                                size={30}
                                                            />
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={
                                                                    !checked
                                                                }
                                                                data-cy={`${fieldName}-form-sign-button`}
                                                                onClick={() => {
                                                                    setCurrentForm(
                                                                        data.name
                                                                    );
                                                                    SetFormSigning(
                                                                        (
                                                                            prev: any
                                                                        ) => ({
                                                                            ...prev,
                                                                            [data?.name]:
                                                                                true,
                                                                        })
                                                                    );
                                                                    onSubmit(data.name)
                                                                }}
                                                            >
                                                                Digitally Sign
                                                            </Button>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        </Fragment>
                                    );
                                }
                            )}
                        </div>
                    )}
                </>
            )}
        </Grid>
    );
}

export default ElectronicSignatureForm;
