import { Box, InputLabel } from '@material-ui/core'
import React from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import FormikAutocomplete from './utils/FormikAutoComplete'
import {
    ONBOARDING_FORM_FIELDS,
    ONBOARDING_PAGE_INFO,
} from './constant/business-onboarding.const'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { FormikContextType, useFormikContext } from 'formik'
import OwnerContainer from './OwnerContainer'
import UiButton from '../../common/ui/UiButton'
import {
    AutoSelectDropDownContainerProps,
    OnBoardingFormType,
} from './models/onboarding.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    inputLabel: {
        marginBottom: '1.2rem',
        color: (colorTheme) => colorTheme.black100,
    },
    radioButtonContainer: {
        margin: '2rem 0 1.5rem 0',
        color: (colorTheme) => colorTheme.black200,
        '& div .Mui-checked': { 
            color: (colorTheme) => colorTheme.primaryGreen,
        },
    },
    radioButtonLabel: {
        padding: '0.5rem 0',
    },
    subInputText: {
        marginTop: '0.2rem',
        width: '88%',
        marginLeft: '1rem',
    },
    formFields: {
        margin: '2rem 0',
        width: '26rem',
        [theme.breakpoints.down('xs')]: {
            width: '20rem',
        },
    },
    radioContainerTitle: {
        color: (colorTheme) => colorTheme.black200,
    },
}))

const AutoSelectDropDownContainer = ({
    label,
    fieldType,
    optionsData,
    placeholder,
}: AutoSelectDropDownContainerProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    return (
        <div>
            <InputLabel className={classes.inputLabel} htmlFor={fieldType}>
                <UiText variant="hatchback_125">{label}</UiText>
            </InputLabel>

            <FormikAutocomplete
                type={fieldType}
                placeholder={placeholder}
                optionsData={optionsData}
            />
        </div>
    )
}

const RadioButtonContainer = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const { currentUser } = useCurrentStore()

    const {
        setFieldValue,
        setFieldTouched,
        setFieldError,
    }: FormikContextType<OnBoardingFormType> = useFormikContext()

    const handleRadioButtonCheck = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const selectedValue = e.target.value
        if (selectedValue === 'Yes') {
            setFieldTouched('business_owners', false)
            setFieldValue(`business_owners`, [
                {
                    first_name: currentUser?.first_name,
                    last_name: currentUser?.last_name,
                    ownership: 100,
                },
            ])
        } else {
            setFieldValue(`business_owners[1]`, {
                first_name: '',
                last_name: '',
                ownership: 0,
            })
            setFieldError('business_owners[1]', undefined)
            setFieldTouched('business_owners', false)
        }
    }

    return (
        <div className={classes.radioButtonContainer}>
            <UiText
                className={classes.radioContainerTitle}
                variant="hatchback_125"
            >
                Are you the only owner of the business?
            </UiText>
            <UiFormControlSelection
                type="radio"
                labelClass="align-items-start"
                fieldName="only_owner"
                onChange={(e) => handleRadioButtonCheck(e)}
                radioOptionsData={[
                    {
                        label: (
                            <div className={classes.radioButtonLabel}>
                                <div>Yes</div>
                            </div>
                        ),
                        value: 'Yes',
                    },
                    {
                        label: (
                            <div className={classes.radioButtonLabel}>No</div>
                        ),
                        value: 'No',
                    },
                ]}
            />
        </div>
    )
}

const OnBoardingForm = () => {
    const { apiConfig } = useCurrentStore().currentConfig
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    const { values, submitForm }: FormikContextType<OnBoardingFormType> =
        useFormikContext()

    return (
        <div>
            <Box className={classes.formFields}>
                <AutoSelectDropDownContainer
                    label={ONBOARDING_FORM_FIELDS.BUSINESS_TYPE.LABEL}
                    fieldType={ONBOARDING_FORM_FIELDS.BUSINESS_TYPE.FIELD_TYPE}
                    placeholder={
                        ONBOARDING_FORM_FIELDS.BUSINESS_TYPE.PLACEHOLDER
                    }
                    optionsData={apiConfig.business.types}
                />
            </Box>

            <Box className={classes.formFields}>
                <AutoSelectDropDownContainer
                    label={ONBOARDING_FORM_FIELDS.INDUSTRY.LABEL}
                    fieldType={ONBOARDING_FORM_FIELDS.INDUSTRY.FIELD_TYPE}
                    placeholder={ONBOARDING_FORM_FIELDS.INDUSTRY.PLACEHOLDER}
                    optionsData={apiConfig.business.industries}
                />
                <div className={classes.subInputText}>
                    <UiText variant="moped_75" textColor="textSecondary">
                        {ONBOARDING_PAGE_INFO.INDUSTRY_SUBINPUT_TEXT}
                    </UiText>
                </div>
            </Box>

            <Box className={classes.formFields}>
                <AutoSelectDropDownContainer
                    label={ONBOARDING_FORM_FIELDS.STATE.LABEL}
                    fieldType={ONBOARDING_FORM_FIELDS.STATE.FIELD_TYPE}
                    placeholder={ONBOARDING_FORM_FIELDS.STATE.PLACEHOLDER}
                    optionsData={apiConfig.generic.states}
                />
            </Box>

            <RadioButtonContainer />

            {values?.only_owner === 'No' && <OwnerContainer />}

            <Box mt={4}>
                <UiButton
                    handleClick={() => submitForm()}
                    label="Go to my Account"
                    btnType="tertiary"
                />
            </Box>
        </div>
    )
}

export default OnBoardingForm
