export const base64ToImage = (base64String: any) => {
    return `data:image/png;base64,${base64String}`
}

export const isUrl = (str: string | undefined) => {
    return isString(str) ? /^https?:\/\//.test(str) : false;
}

export const isString = (value: any): value is string =>  {
    return typeof value === 'string';
}
