import React, { useState } from 'react'
import { Button, makeStyles, Theme } from '@material-ui/core'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import { discardImport } from '../apiServices/contractor.api'
import {
    importInitialValues,
    useContractorContext,
} from '../ContractorProvider'
import { requestErrorHandler } from '../../../services/formService'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    container: {
        '& .MuiPaper-root': {
            maxWidth: `${toRem16(450)} !important`,
        },
    },
    content: {
        textAlign: 'center',
    },
    secondaryText: {
        textAlign: 'center',
        marginTop: '1.5rem',
    },
    formControl: {
        margin: 0,
        width: '100%',
    },
    message: {
        marginTop: toRem16(24),
    },
    discard: {
        backgroundColor: colorTheme => `${colorTheme.red200}`,
        '&:hover': {
            backgroundColor: colorTheme => `${colorTheme.red100}`,
        },
    },
}))

interface DiscardModalProps {
    openDialog: boolean
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}
const DiscardModal = ({ openDialog, setOpenDialog }: DiscardModalProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { importData, setImportData } = useContractorContext()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleDiscard = () => {
        setIsLoading(true)
        requestErrorHandler(
            discardImport(importData?.id.id ?? '').then((data: any) => {
                setOpenDialog(false)
                setIsLoading(false)
                setImportData(importInitialValues)
            }),
            true,
            () => {
                setOpenDialog(false)
                setIsLoading(false)
            }
        )
    }

    return (
        <UiConfirmationDialog
            open={openDialog}
            customRootClass={classes.container}
            message={
                <div className={classes.content}>
                    <UiText weight="semi_bold_600" variant="hatchback_125">
                        You have unsaved changes
                    </UiText>
                    <div className={classes.secondaryText}>
                        <UiText variant="motorcycle_90">
                            In order to continue you'll need to discard all
                            changes to the contractors.
                        </UiText>
                        <UiText
                            variant="motorcycle_90"
                            className={classes.message}
                        >
                            This cannot be undone.
                        </UiText>
                    </div>
                </div>
            }
            handleClose={() => {
                setOpenDialog(false)
            }}
            showLoader={isLoading}
            confirmNode={
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.discard}
                    onClick={handleDiscard}
                    disabled={isLoading}
                >
                    {' '}
                    Discard Changes
                </Button>
            }
            cancelButtonText="Keep Editing"
            alignButtons="center"
        />
    )
}

export default DiscardModal
