import { Button, Menu, MenuItem } from '@material-ui/core';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
    DownloadModalFormData,
    DOWNLOAD_REPORT_OPTION_TYPES,
    DOWNLOAD_REPORT_TYPES,
} from '../../models/reports-interface';
import SaveDocumentsOnPortalModal from '../modal/SaveDocumentsOnPortalModal';

type Option = {
    label: string;
    type: DOWNLOAD_REPORT_OPTION_TYPES;
    fileType: DOWNLOAD_REPORT_TYPES;
};

interface Reports {
    businessName: string;
    downloadReportPDFUrl: string;
    downloadReportCSVUrl?: string;
    handleSaveToPortal: (data: DownloadModalFormData, fileType: string, closeModal: () => void) => void;
    reportName: string;
    options: Option[];
    isDisabled?: boolean;
}

export default function SaveReports(props: Reports) {
    const [downloadMenu, setDownloadMenu] = useState<Element | null>(null);
    const [isOpenSaveModal, setIsOpenSaveModal] = useState<boolean>(false);
    const [saveModalFileType, setSaveModalFileType] =
        useState<DOWNLOAD_REPORT_TYPES>(DOWNLOAD_REPORT_TYPES.PDF);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        businessName,
        downloadReportPDFUrl,
        downloadReportCSVUrl,
        options,
        reportName,
        handleSaveToPortal,
        isDisabled,
    } = props;
    const downloadReport = (type: string) => {
        const downloadUrl =
            type === DOWNLOAD_REPORT_TYPES.PDF
                ? downloadReportPDFUrl
                : downloadReportCSVUrl;
        const link = document.createElement('a');
        link.setAttribute('id', 'download-link');
        link.target = '_blank';
        link.href = downloadUrl ?? '';
        link.click();
        const downloadLink = document.getElementById('download-link');
        downloadLink?.parentNode?.removeChild(downloadLink);
    };

    const handleMenuClose = (type: string) => {
        setDownloadMenu(null);
        downloadReport(type);
    };
    const handleSaveToDocuments = (type: string) => {
        setSaveModalFileType(type as DOWNLOAD_REPORT_TYPES);
        setIsOpenSaveModal(true);
        setDownloadMenu(null);
    };
    const handleModalClose = () => {
        setIsLoading(false);
        setIsOpenSaveModal(false);
    };

    const SaveToPortal = (data: DownloadModalFormData, fileType: string) => {
        setIsLoading(true);
        handleSaveToPortal(data, fileType, handleModalClose);
    }

    return (
        <>
            <Button
                variant='contained'
                color='primary'
                data-cy='save-report-btn'
                onClick={(e) => {
                    setDownloadMenu(e.currentTarget);
                }}
                disabled={isDisabled}
            >
                Save Report
                {!!downloadMenu ? (
                    <KeyboardArrowUpIcon />
                ) : (
                    <KeyboardArrowDownIcon />
                )}
            </Button>
            <Menu
                open={!!downloadMenu}
                anchorEl={downloadMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                onClose={(e) => {
                    setDownloadMenu(null);
                }}
                MenuListProps={{ disablePadding: true }}
                elevation={4}
            >
                {options.map((option: Option, index: number) => (
                    <MenuItem
                        onClick={() => {
                            option.type === 'save'
                                ? handleSaveToDocuments(option.fileType)
                                : handleMenuClose(option.fileType);
                        }}
                        key={`menu_${index}`}
                        data-cy={`${option.label
                            .toLocaleLowerCase()
                            .replaceAll(' ', '-')
                            .replace('.', '')}_btn`}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
            <SaveDocumentsOnPortalModal
                isOpen={isOpenSaveModal}
                handleClose={handleModalClose}
                handleFormSubmit={SaveToPortal}
                businessName={businessName}
                fileType={saveModalFileType}
                isLoading={isLoading}
                reportName={reportName}
            />
        </>
    );
}
