import { AppData, AppointmentEventsState, Event } from '../../models';
import { getUpcomingEvents } from '../../services/apiService';
import moment from 'moment';

export const SET_APPOINTMENT_EVENTS = 'SET_APPOINTMENT_EVENTS';

export interface EventAction {
    type: string;
    state: AppointmentEventsState
}

export type Action = EventAction;

export const setAppointmentEvents = (loading: boolean, events: Event[]): EventAction => ({
    type: SET_APPOINTMENT_EVENTS,
    state: {
        loading,
        events
    }
});

export function loadAppointments() {
    return (dispatch: any, getState: any) => {
        dispatch(setAppointmentEvents(true, []));
        const { appData }: { appData: AppData } = getState();
        getUpcomingEvents<Event[]>(appData.current_account_id, {rql: `start%5Fdate=ge=${moment().unix()}`}).then((res) => {
            dispatch(setAppointmentEvents(false, res));
        });
    };
}
