import { COLORS } from '../../../variables/colors'

/**
 * Balance sheet
 */
export enum DATE_OPTIONS {
    TODAY = 'Today',
    LAST_YEAR = 'Last Year',
    THIS_YEAR = 'This Year',
    LAST_QUARTER = 'Last Quarter',
    THREE_MONTHS = 'Three Months',
    ALL_TIME = 'All Time',
}
export const BALANCE_DATE_DROPDOWN_OPTIONS = [
    DATE_OPTIONS.TODAY,
    DATE_OPTIONS.LAST_YEAR,
]
export const periodTextFormat = 'DD MMM yyyy'
export const findSpaceRegex = /\s+/g

/**
 * Expanses
 */

export const EXPANCE_DATE_DROPDOWN_OPTIONS = [
    DATE_OPTIONS.THIS_YEAR,
    DATE_OPTIONS.LAST_QUARTER,
    DATE_OPTIONS.LAST_YEAR,
]

export const expansesColors = [COLORS.green900, COLORS.test2, COLORS.teal300]

/**
 * Invoices
 */

export const INVOICES_DATE_DROPDOWN_OPTIONS = [
    DATE_OPTIONS.LAST_QUARTER,
    DATE_OPTIONS.THIS_YEAR,
    DATE_OPTIONS.ALL_TIME,
]
export const DATE_FILTER = 'issue'

export enum INVOICES_CHART_TITLES {
    UNPAID = 'Unpaid',
    PAID = 'Paid',
    LATE = 'Late',
}

/**
 * Income statement
 */

export enum INCOME_STATEMENT_CHART_TITLES {
    REVENUE = 'Revenue',
    EXPENSES = 'Expenses',
}

export const INCOME_STATEMENT_DATE_DROPDOWN_OPTIONS = [
    DATE_OPTIONS.THIS_YEAR,
    DATE_OPTIONS.LAST_QUARTER,
    DATE_OPTIONS.LAST_YEAR,
]
