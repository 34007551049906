import {
    makeStyles,
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grow, Tooltip } from '@material-ui/core';
import { percentFormatter } from '../../utils/appUtil';

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
            opacity: 0.8,
            '&:hover': {
                opacity: '1 !important',
            },
        },
        bar: {
            borderRadius: 5,
            backgroundColor: 'red',
        },
    })
)(LinearProgress);

interface LineChartBarProps {
    value: any;
    color: string;
}

export default function LineChartBar({ value, color }: LineChartBarProps) {
    const classes = makeStyles({
        root: {
            flexGrow: 1,
        },
        bar: {
            borderRadius: 5,
            backgroundColor: color,
        },
    })();

    return (
        <Grow
            in
            style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: 1000 } : {})}
        >
            <div className={classes.root}>
                <Tooltip title={percentFormatter.format(value / 100)}>
                    <BorderLinearProgress
                        variant='determinate'
                        style={{ color: 'red !important' }}
                        value={value}
                        classes={{
                            bar: classes.bar,
                        }}
                    />
                </Tooltip>
            </div>
        </Grow>
    );
}
