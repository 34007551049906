import { Button, List, ListItem, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationStore } from '../../../../models';
import { getEinData } from '../../../../services/apiService';
import UiSection from '../../../common/ui/UiSection'
import ObtainEINPopup from './ObtainEINPopup';
import ScheduleTaxSavingsAnalysisPopup from './ScheduleTaxSavingsAnalysisPopup';

function RecommendedSteps({ history, businessEin, accountId }: { history: any; businessEin: string; accountId: string; }) {

    const styles = makeStyles({
        listHeading: {
            fontWeight: 500
        },
        listItem: {
            paddingBottom: 10,
            paddingTop: 10,
            '&:first-child': {
                paddingTop: 0,
            },
            '&:last-child': {
                paddingBottom: 0,
            }
        }
    })();

    const [einModalState, setEinModalState] = useState(false);
    const [einAlreadyApplied, setEinAlreadyApplied] = useState(true); // kept true to hide ein step initially 
    const [taxSavingsModalState, setTaxSavingsModalState] = useState(false);

    const handleEinModal = (state: { modalState: boolean; setEIN?: boolean | undefined } = { modalState: false }) => {
        if (state && state.setEIN) {
            history.push('/settings/business?edit=true');
        }
        setEinModalState(state.modalState);
    }

    const handleTaxSavingsModal = (state: boolean) => {
        setTaxSavingsModalState(state);
    }

    useEffect(() => {
        getEinData(accountId).then(res => {
            setEinAlreadyApplied(!!res);
        })
    }, [accountId])

    return (
        <UiSection expanded={true} sectionHeading="Recommended Steps">
            <List disablePadding>
                <ListItem disableGutters className={styles.listItem} divider={!businessEin && !einAlreadyApplied}>
                    <div>
                        <div className="d-flex justify-content-between">
                            <div>
                                <Typography variant="subtitle1" component="div" className={styles.listHeading} gutterBottom>
                                    Free Tax Savings Analysis
                                </Typography>
                                <Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
                                    Maximizing tax deductions puts more money in your pocket. In fact, our clients received double the average federal refund last year. We’ll show you the savings you qualify for.
                                </Typography>
                            </div>
                            <div>
                                <Button variant="outlined" color="secondary" onClick={() => handleTaxSavingsModal(true)}>Schedule</Button>
                            </div>
                        </div>
                    </div>
                </ListItem>
                {
                    !businessEin && !einAlreadyApplied && (
                        <ListItem disableGutters className={styles.listItem}>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div>

                                        <Typography variant="subtitle1" component="div" className={styles.listHeading} gutterBottom>
                                            Obtain Your Employer Identification Number
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" style={{ paddingRight: 10 }}>
                                            An Employer Identification Number is needed for various compliance requirements such as but not limited to: filing your business tax return, opening a business bank account, and hiring employees.
                                        </Typography>
                                    </div>
                                    <div>
                                        <Button variant="outlined" color="secondary" onClick={() => handleEinModal({ modalState: true })}>Resolve</Button>
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    )
                }
                {/* <ListItem disableGutters className={styles.listItem}>
                    <div>
                        <div className="d-flex justify-content-between">
                            <div>
                                <Typography variant="subtitle1" component="div" className={styles.listHeading} gutterBottom>
                                    Finish Draft Tax Return
                                </Typography>
                                <Typography variant="body2" color="textSecondary" style={{paddingRight: 10}}>
                                    By connecting to your corporate data, we have begun to work on your Tax Return. At the end of the year, simply confirm your information is up to date, and provide us with your income and expenses.
                                    <br />
                                    Our team of CPAs and EAs will prepare your return for you. All our returns go through a 7 step qualify assurance process.
                                </Typography>
                            </div>
                            <div>
                                <Button variant="outlined" color="secondary">View Return</Button>
                            </div>
                        </div>
                        <Button variant="text">Learn More</Button>
                    </div>
                </ListItem> */}
            </List>
            <ObtainEINPopup open={einModalState} handleClose={handleEinModal} />
            <ScheduleTaxSavingsAnalysisPopup open={taxSavingsModalState} handleClose={handleTaxSavingsModal} />
        </UiSection>
    )
}

const mapStateToProps = ({ appData }: ApplicationStore) => ({
    businessEin: appData.current_business.ein || '',
    accountId: appData.current_account_id
})
export default withRouter(connect(mapStateToProps)(RecommendedSteps));