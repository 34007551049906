import React from 'react'
import { Location } from '../../../../models/locations'
import { contactZipCodeRegex } from '../../../../utils/maskUtil'
export const defaultValues = {
    save_to_list: 'No',
    name        : '',
    description : '',
    address     : {
                    country     : 'US',
                    address     : '2 Lincoln Memorial Circle Northwest',
                    city        : 'Washington',
                    county      : '',
                    state       : 'DC',
                    zip_code    : '20004'
    }
}
export const propertyMappingGeneral: LocationPropFieldsInterface[] =  [
    {
        key             : 'save_to_list',
        type            : 'radio',
        label           : 'Name (Required)',
        radioOptionsData: [
            {
                label: (
                    'Only change the location for this trip'
                ),
                value: 'No',
            },
            {
                label: (
                    'Update the location for all trips'
                ),
                value: 'Yes',
            },
        ],
        required        : false
    },
    {
        key             : 'name',
        type            : 'text',
        placeholder     : 'Location Name',
        md              : 12,
        xs              : 12,
        required        : true,
        errorMessage    : 'Required',
        label           : 'Name (Required)',
    },
    {
        key             : 'description',
        type            : 'text',
        placeholder     : 'Description (Optional)',
        md              : 12,
        xs              : 12,
        required        : false,
        errorMessage    : 'Required',
        label           : 'Description (Optional)',
    }
]

export function propertyMappingManuallyEnter(selectOptions: StateCountryProps){
        const data: LocationPropFieldsInterface[] = [
        {
            key         : 'address.country',
            type        : 'select',
            placeholder : 'Country',
            md          : 12,
            xs          : 12,
            required    : false,
            label       : 'Country',
            optionKey   : 'name',
            optionValue : 'id',
            optionsData : selectOptions.country,
        },
        {
            key         : 'address.address',
            type        : 'text',
            placeholder : 'Address',
            md          : 12,
            xs          : 12,
            required    : false,
            label       : 'Address',
        },
        {
            key         : 'address.city',
            type        : 'text',
            placeholder : 'City',
            md          : 12,
            xs          : 12,
            required    : false,
            label       : 'City',
        },
        {
            key         : 'address.county',
            type        : 'text',
            placeholder : 'County',
            md          : 12,
            xs          : 12,
            required    : false,
            label       : 'County',
        },
        {
            key         : 'address.state',
            type        : 'select',
            placeholder : 'State',
            md          : 6,
            xs          : 12,
            label       : 'State',
            optionKey   : 'name',
            optionValue : 'id',
            optionsData : selectOptions.state,
        },
        {
            key         : 'address.zip_code',
            type        : 'mask',
            placeholder : 'Zip Code',
            md          : 6,
            xs          : 12,
            label       : 'Zip Code',
            regex       : contactZipCodeRegex,
            minLength   : 5,
        }
    ]
    return data;
}
export interface LocationFormProps {
    location?               : any
    action?                 : string
    handleClose?            : any
    setSelectedLocation?    : React.Dispatch<Location | undefined>
    loadLocations?          : () => void
    formikRef?              : any
    setLocationUpdated?     : React.Dispatch<boolean>
    locationCallback?       : any
    setLoading?             : React.Dispatch<React.SetStateAction<boolean>>
    isFormTouched           : any
    discardChanges?         : boolean
    onlyForOneTrip?         : boolean
}
export interface StateCountryProps {
    [key: string]: OptionsData[]
}
export type OptionsData = {
    abbr: string
    name: string
}

export type InputType = "select" | "text" | "mask" | "radio";
export interface LocationPropFieldsInterface {
    key?              : string,
    type              : InputType,
    placeholder?      : string,
    md?               : number,
    xs?               : number,
    required?         : boolean,
    errorMessage?     : string,
    label?            : string,
    optionKey?        : string,
    optionValue?      : string,
    optionsData?      : OptionsData[]|null,
    regex?            : Array<RegExp>|null,
    minLength?        : number,
    radioOptionsData? : { value: any; label: any }[]
}

export interface LocationPayload {
    name            : string,
    description?    : string,
    address         : LocationPayloadAddress
}

export interface LocationPayloadAddress {
    country?    : string,
    address?    : string,
    city?       : string,
    county?     : string,
    state?      : string,
    zip_code?   : string,
    latitude?   : number,
    longitude?  : number,
}