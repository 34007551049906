import React, { useState } from 'react'
import {
    makeStyles,
    Theme
} from '@material-ui/core'
import { getCurrentNewtekMerchantUrl } from '../../../services/apiService/business'
import AdvertisingModal from '../../bookkeeping/invoices/Banner/AdvertisingModal'
import MerchantAccountModal from '../../bookkeeping/invoices/Banner/MerchantAccount/MerchantAccountModal'
import RegistrationConfirmedModal from '../../bookkeeping/invoices/Banner/MerchantAccount/RegisterationConfirmedModal'
import NewtekModal from '../../bookkeeping/invoices/Banner/MerchantAccount/NewtekModal'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { toRem16 } from '../../../styles/commonStyles'


const useStyles = makeStyles((theme: Theme) => ({
    alertStyles: {
        margin: `${toRem16(10)} 0px ${toRem16(10)} 0px`,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    alertRootStyle: {
        borderRadius: '8px'
    }
}))
const MerchantProcessingForm = ({ 
    openModal,
    setOpenModal
}: { 
    setOpenModal: any
    openModal: boolean
}) => {
    const styles = useStyles()
    const [open, setOpen] = useState(true)
    const [openBusinessForm, setOpenBusinessForm] = useState(false)
    const [openRegConfirmedModal, setOpenRegConfirmedModal] = useState(false)
    const [embedUrl, setEmbedUrl] = useState('')
    const [embedNewtelModal, setEmbedNewtekModal] = useState(false)
    const [newtekMerchantApproved, setNewtekMerchantApproved] = useState(false)
    const newtekMerchantAlreadyExists = 'A Merchant already exists.';
    
    const { 
        currentAccountId, 
        currentBusinessId 
    } = useCurrentStore()


    const getUrl = () => {
        getCurrentNewtekMerchantUrl(currentAccountId, currentBusinessId as string)
        .then((res: any) => {
            if(res.message && res.message === newtekMerchantAlreadyExists){
                setNewtekMerchantApproved(true)
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    return (
        <div>
            <AdvertisingModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                hideAlert={() => {
                    setOpen(false)
                }}
                openBusinsessModal={() => {
                    setOpenBusinessForm(true)
                }}
            />
            <MerchantAccountModal
                open={openBusinessForm}
                handleClose={(type: boolean) => setOpenBusinessForm(type)}
                openSuccessModal={() => setOpenRegConfirmedModal(true)}
                handleGetEmbedUrl={(url: any) => {
                    if(url){    
                        window.open(url)
                    }
                }}
                
            />
            <RegistrationConfirmedModal
                open={openRegConfirmedModal}
                handleClose={() => {
                    setOpenRegConfirmedModal(false)
                }}
            />
            <NewtekModal
                open={embedNewtelModal}
                handleClose={() => {
                    getUrl()
                    setEmbedNewtekModal(false)
                }}
                url={embedUrl}
            />
        </div>
    )
}


export default MerchantProcessingForm
