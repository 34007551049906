import { Typography } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationStore, Config } from "../../../models";

interface Iprop {
  config: Config | any;
  type: "config" | "boolean" | "phone" | "phone-us" | "ein" | "ssn";
  configName?: string;
  configKey?: string | any;
  value: string | boolean | undefined;
  labelKey?: string | any;
  hideSSN?: boolean;
  variant?:
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline"
  | "srOnly"
  | "inherit";
}

const UiTextView: React.FC<Iprop> = ({
  config: { apiConfig: config },
  type,
  configName,
  value,
  configKey,
  labelKey,
  variant = "subtitle1",
  hideSSN
}) => {
  const getValue = () => {
    switch (type) {
      case "boolean":
        return typeof value === "undefined" ? "n/a" : value ? "Yes" : "No";
      case "phone":
        if (!value) {
          return "n/a";
        }
        return value
          .toString()
          .split("")
          .reduce((text, char, index) => {
            switch (index) {
              case 2:
                return text + char + "-";
              case 5:
                return text + char + "-";
              default:
                return text + char;
            }
          }, "");
          case "phone-us":
            if (!value) {
              return "n/a";
            }
            return value
              .toString()
              .split("")
              .reduce((text, char, index) => {
                switch (index) {
                  case 0:
                    return "("+ text + char;
                  case 2:
                    return text + char + ") ";
                  case 5:
                    return text + char + "-";
                  default:
                    return text + char;
                }
              }, "");
      case "ssn":
        if (!value) {
          return "n/a";
        }
        return value
          .toString()
          .split("")
          .reduce((text, char, index) => {
            if (hideSSN && index < 6) { char = 'X' }
            switch (index) {
              case 2:
                return text + char + "-";
              case 4:
                return text + char + "-";
              default:
                return text + char;
            }
          }, "");
      case "ein":
        if (!value) {
          return "n/a";
        }
        return value
          .toString()
          .split("")
          .reduce(
            (text, char, index) =>
              index === 1 ? text + char + "-" : text + char,
            ""
          );
      case "config":
        const cfgArr = configName?.split(".");
        // let cfg = '';
        cfgArr?.forEach((k: string) => {
          config = config[k];
        });
        if (config && configKey) {
          return (
            (config.find((item: any) => item[configKey] === value) &&
              config.find((item: any) => item[configKey] === value)[
              labelKey
              ]) ||
            "n/a"
          );
        }
        return "n/a";
    }
  };

  return <Typography variant={variant}>{getValue()}</Typography>;
};

const mapStateToProps = (state: ApplicationStore) => ({
  config: state.config,
});
export default connect(mapStateToProps)(UiTextView);
