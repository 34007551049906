import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { hasPermission } from '../../../../services/aclService'
import {
    BANK_SETUP_AD_BLOCK,
    BASIC_BOOKKEEPING_PERMISSION,
    INVOICE_INFO_CARD,
    MERCHANT_SETUP_AD_BLOCK,
    TRANSACTION_INFO_CARD,
    CARRYOVER_TAX_BENEFITS_CARD,
    UPLOAD_1040_INFO_CARD,
    LAST_1040_POPUP_DATE,
} from '../Todo.const'
import { 
    isBlockDismissed,
    isBankSetupCompletedOrDismissed, 
    isMerchantSetupCompletedORDismissed
} from '../../AccountSetupItems/AccountSetupUtility'
import { useDashboardContext } from '../../Provider/DashboardProvider'
import type { AccountProgress } from '../../../../models/dashboardModel'
import { useWidgetContext } from '../../tax-prep/provider/WidgetProvider'
import { getAccountProgress, getFolders, searchDocuments, getPersonalVtoDetails } from '../../../../services/apiService'
import { VTO_SUBMITTED_STATUS } from '../../tax-prep/constants/tax-prep.const'
import { getPrevYearOptions } from '../../tax-prep/utils'
import { getFromLocalStorage } from '../../../../services/localStorageService'

export const useTodoBlockInfo = () => {
    const { currentAccount, currentBusinessId, currentAccountId, personalAccount } = useCurrentStore()
    const [accountProgress, setAccountProgress] = useState<any>(null)
    const [prevYearTaxDocs, setPrevYearTaxDocs] = useState<any>({});
    const [submittedPrevYearVto, setSubmittedPrevYearVto] = useState<any>(false);
    const [refetch1040Info, setRefetch1040Info] = useState(false);
    const [documentCount, setDocumentCount] = useState(0);

    const { invoiceData, dismissPopupInfo } =
        useDashboardContext()
    const bookkeepingInfo = accountProgress?.bookkeeping_popups
    const hasBookkeepingPermissions = hasPermission(
        BASIC_BOOKKEEPING_PERMISSION,
        currentAccount
    )

    const isBankMarkedCompleted = isBankSetupCompletedOrDismissed(accountProgress as AccountProgress, dismissPopupInfo)
    const isMerchantCompleted = isMerchantSetupCompletedORDismissed(accountProgress as AccountProgress, dismissPopupInfo)
    const { taxPrepData: {show_fincen_data_collection_form: showFincenCard} } = useWidgetContext()
    const prevYear = getPrevYearOptions()[2];
    const plaidVerificationStatus = accountProgress?.show_payroll_bank_verification_card
    const showPayrollMigrationInfoCard = accountProgress?.show_payroll_bank_migration_card
    

    const bankAccountConnected =
        accountProgress?.connected_bank_account ||
        accountProgress?.bank_account_created
    const showBankSetupCard = bankAccountConnected
        ? false
        : !isBlockDismissed(BANK_SETUP_AD_BLOCK)

    const hasInvoice = !!invoiceData?.late_count
    const showInvoiceCard = hasInvoice && !isBlockDismissed(INVOICE_INFO_CARD)

    const merchantConnected = accountProgress?.merchant_account_setup
    const showMerchantCard = merchantConnected
        ? false
        : !isBlockDismissed(MERCHANT_SETUP_AD_BLOCK)

    const noUncategorizedTransaction =
        accountProgress?.uncategorized_transaction_count === 0
    const showTransactionCard =
        !noUncategorizedTransaction && !isBlockDismissed(TRANSACTION_INFO_CARD)

    const showBookkeepingCard =
        !!bookkeepingInfo?.length && hasBookkeepingPermissions

    // this conditions initially hide card and show it after api calls if required
    const show1040Popup =
        has2WeeksPassed() &&
        !isBlockDismissed(UPLOAD_1040_INFO_CARD) &&
        !(personalAccount.upload_1040_consent === false) &&
        documentCount === 0 && !submittedPrevYearVto;

    // this conditions initially show card and hide it after api calls if required
    const show1040Card =
        isBlockDismissed(UPLOAD_1040_INFO_CARD) ||
        personalAccount.upload_1040_consent === false ||
        documentCount > 0 ||
        submittedPrevYearVto
            ? false
            : true    

    const bookKeepingTodoCount = useCallback(() => {
        return showBookkeepingCard
            ? bookkeepingInfo?.filter(
                  (item: any) => !dismissPopupInfo.includes(item.pop_up)
              ).length || 0
            : 0
    }, [bookkeepingInfo, dismissPopupInfo, showBookkeepingCard])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [folders, vto] = await Promise.all([
                    getFolders(currentAccountId),
                    getPersonalVtoDetails(currentAccountId, prevYear)
                ]);
    
                const folder = folders.find(
                    (folder) =>
                        folder.title === 'Tax Return' &&
                        folder.path.includes(`ty${prevYear}`)
                );
    
                    const docs = await searchDocuments(currentAccountId, { path: folder?.path });
                    setPrevYearTaxDocs(docs);
    
                setSubmittedPrevYearVto(vto[0]?.status === VTO_SUBMITTED_STATUS);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
    
        fetchData();
    }, [currentAccountId, prevYear, refetch1040Info]);

    useEffect(() => {
        if(prevYearTaxDocs?.items?.length > 0) {
            const personalItemsCount = prevYearTaxDocs.items.filter((item: any) => item.type === 'personal').length;
            setDocumentCount(personalItemsCount);
        }
    }, [prevYearTaxDocs]);

    useEffect(() => {
        let isMounted = true;
        const fetchAccountProgress = async () => {
            const res = await getAccountProgress({ business_id: currentBusinessId });
            if (isMounted) {  
                setAccountProgress(res);
            }
        };
        fetchAccountProgress();
        return () => {  // add this block
            isMounted = false;
        };
    }, [currentBusinessId]);
    /**
     * TODO - need to integrate with API
     */
    const showCarryoverTaxBenefitCard = !accountProgress?.carryover_tax_benefits && !isBlockDismissed(CARRYOVER_TAX_BENEFITS_CARD)

    const getTodoCount = useCallback(() => {
        const cardTodosCount = [
            isBankMarkedCompleted && showBankSetupCard,
            isMerchantCompleted && showMerchantCard,
            showTransactionCard,
            showInvoiceCard,
            showCarryoverTaxBenefitCard,
            showFincenCard,
            showPayrollMigrationInfoCard,
            plaidVerificationStatus,
            show1040Card
        ].filter((item) => !!item).length
        return bookKeepingTodoCount() + cardTodosCount
    }, [
        bookKeepingTodoCount,
        showBankSetupCard,
        showInvoiceCard,
        showMerchantCard,
        showTransactionCard,
        showCarryoverTaxBenefitCard,
        isBankMarkedCompleted,
        isMerchantCompleted,
        showFincenCard,
        showPayrollMigrationInfoCard,
        plaidVerificationStatus,
        show1040Card
    ])

    function has2WeeksPassed() {
        const lastPopupTimestamp = getFromLocalStorage(
            `${currentAccountId}_${LAST_1040_POPUP_DATE}`
        )
        if (!lastPopupTimestamp) return true
        const lastPopupDate = moment.unix(lastPopupTimestamp)
        const todayDate = moment()
        const differenceInDays = todayDate.diff(lastPopupDate, 'days')
        const isMoreThanTwoWeeks = differenceInDays > 14
        return isMoreThanTwoWeeks
    }

    const count = getTodoCount()
    const isAllTodoCompleted = count === 0

    return {
        count,
        isAllTodoCompleted,
        showBankSetupCard,
        showInvoiceCard,
        showMerchantCard,
        showTransactionCard,
        showCarryoverTaxBenefitCard,
        hasBookkeepingPermissions,
        bookkeepingInfo,
        showPayrollMigrationInfoCard,
        plaidVerificationStatus,
        show1040Card,
        setRefetch1040Info,
        show1040Popup
    }
}
