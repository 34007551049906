import EmailIcon from '@material-ui/icons/Email'
import SectionView from '../../../payroll/common/SectionView'
import EmptySectionView from '../../../payroll/common/EmptySectionView'
import { AssociateLegalAddress } from '../../../../models/adp-payroll/employee'

const AddressDetailsView = ({
    address,
    setOpenRequestDialog
}: {
    address?: AssociateLegalAddress
    setOpenRequestDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <div>
            {address?.address1 ? (
                <div style={{ height: '200px' }}>
                    <SectionView
                        key={'employee_address'}
                        headerText={'Address'}
                        customNode={
                            <>
                                {address.address1} {address.address2} <br />
                                {address.city} {address.stateCode}{' '}
                                {address.postalCode}
                            </>
                        }
                    />
                </div>
            ) : (
                <EmptySectionView
                    headerText={'Employee Address'}
                    primaryText={'No Address Found'}
                    buttonText={'Request employee input'}
                    buttonIcon={<EmailIcon />}
                    disableButton={false}
                    onClickAction={() => {
                        setOpenRequestDialog(true)
                    }}
                />
            )}
        </div>
    )
}

export default AddressDetailsView
