import { useMediaQuery, useTheme } from '@material-ui/core'
import UiDialog from '../../../../common/ui/UiDialog'

interface NewtekModalProps {
    open: boolean
    handleClose: (param: boolean) => void
    url: any
}

const NewtekModal = ({
    open,
    handleClose,
    url
}: NewtekModalProps) => {
    const theme = useTheme()
    const isXsDevice = useMediaQuery(theme.breakpoints.down('xs'))

    const returnIframe = () => {
        return {
            __html: `<iframe src="${url}" style="width: 100%;" height="1000" frameBorder="0"></iframe>`,
        };
    };

    return (
        <UiDialog
            open={open}
            handleClose={() => {
                handleClose(true);
            }}
            title="Apply for Merchant Processing"
            size="lg"
            fullScreen={isXsDevice}
            contentStyles={isXsDevice ? {} : { maxHeight: '600px' }}
            disableEnforceFocus={true}
        >
            <div dangerouslySetInnerHTML={returnIframe()}></div>

        </UiDialog>
    )
}

export default NewtekModal
