import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Grid,
    Button,
    makeStyles,
    Theme,
    TextField,
    InputAdornment,
    Box,
    Paper,
    Typography,
    Hidden,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { ApplicationStore } from '../../../models';
import Loader from '../../../components/common/Loader';
import UiDialog from '../../../components/common/ui/UiDialog';
import CreateCategory from '../journal/CreateCategory';
import { formSubmitErrorHandler } from '../../../services/formService';
import { addCategory } from '../../../services/journalServices';
import { showAlert } from '../../../store/actions/feedback';
import { loadCategories } from '../../../store/actions/categories';
import { categorizeTransactions } from '../../../services/transactionsService';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface TransactionCategorizeModalProps {
    open: boolean;
    handleClose: () => void;
    loadParentState: () => void;
    checkedCount: number;
    checkedItems: string[];
    accountId?: string;
    businessId?: string;
    setCheckedTransactions?: React.Dispatch<string[]>;
    handleReloadCurrentState: () => void;
    category?: any;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    formContainer: {
        height: '150px',
    },
    footerAction: {
        right: '0',
        width: '100%',
        bottom: '0',
        display: 'flex',
        padding: '0.5rem',
        position: 'absolute',
        background: (colorTheme) => colorTheme.primaryWhite,
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        'justify-content': 'flex-end',
        'z-index': 1,
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
    textFieldRoot: {
        '& .MuiInputLabel-root': {
            top: '-7px',
        },
    },
    autocompleteRoot: {
        padding: '0px 0px !important',
    },
}));

function TransactionCategorizeModal({
    open,
    handleClose,
    loadParentState,
    checkedCount,
    checkedItems,
    accountId,
    businessId,
    category,
    setCheckedTransactions,
    handleReloadCurrentState,
}: TransactionCategorizeModalProps) {
    const { colorTheme } = useThemeContext()
    const classNames = useStyles(colorTheme);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);
    const [listData, setListData] = useState<any>({});
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [categoryFormSubmitted, setCategoryFormSubmitted] = useState(false);

    const getAccountdata = useCallback(() => {
        setLoading(category?.loading);
        if (category) {
            const parentCategories = {};
            const cache = {};
            [...category.categories].forEach((caty: any) => {
                if (caty.can_have_children) {
                    //@ts-ignore
                    parentCategories[caty?.id] = { ...caty };
                }
                //@ts-ignore
                cache[caty?.id] = { ...caty };
            });
            let categoryOptions = Object.values({ ...cache });
            categoryOptions.forEach((c: any) => {
                if (c.parent) {
                    c.parent = categoryOptions.find(
                        (pc: any) => c.parent === pc.id
                    );
                }
            });
            setListData((prev: any) => ({
                ...prev,
                accountRecord: cache,
                accountParentList: parentCategories,
                categoryOptions: categoryOptions,
            }));
            setLoading(category?.loading);
        }
    }, [category]);

    useEffect(() => {
        getAccountdata();
    }, [getAccountdata, category]);

    const formikRef = useRef<FormikProps<FormikValues>>(null);

    const onSubmit = (data: any) => {
        let params = {
            category: selectedCategory?.id,
            filters: { id: checkedItems },
        };

        formSubmitErrorHandler(
            categorizeTransactions(
                accountId as string,
                businessId as string,
                params
            ).then((res: any) => {
                if (res) {
                    formikRef?.current?.setSubmitting(false);
                    handleClose();
                    setCheckedTransactions?.([]);
                    handleReloadCurrentState();
                    dispatch(
                        showAlert({
                            alertText: `${checkedCount} transaction categorized`,
                            alertType: 'success',
                        })
                    );
                }
            }),
            () => {
                formikRef?.current?.setSubmitting(false);
            },
            formikRef?.current?.setFieldError
        );
    };

    const initialValues = {
        category: null,
    };

    const validationSchema = Yup.object({
        category: Yup.string().required('Select a category'),
    });

    const menuLink = ({ children, ...other }: any) => {
        return (
            <Paper {...other}>
                <Box borderBottom={`1px solid ${colorTheme.grey200}`}>
                    <Button
                        fullWidth
                        startIcon={<AddIcon />}
                        onMouseDown={(event) => {
                            event.preventDefault();
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            setOpenCategoryModal(true);
                        }}
                    >
                        Create New Category
                    </Button>
                </Box>
                {children}
            </Paper>
        );
    };

    const onCategorySubmit = (data: any, { setFieldError }: any) => {
        setCategoryFormSubmitted(true);
        formSubmitErrorHandler(
            addCategory(data, accountId as string, businessId as string).then(
                (res: any) => {
                    setCategoryFormSubmitted(false);
                    const newData = { [res?.id]: res };
                    res.parent = listData.accountRecord[res.parent];
                    setListData((prev: any) => ({
                        ...prev,
                        accountRecord: { ...prev?.accountRecord, ...newData },
                        categoryOptions: prev.categoryOptions.concat(res),
                    }));
                    dispatch(loadCategories());
                    setOpenCategoryModal(false);
                }
            ),
            () => {
                setCategoryFormSubmitted(false);
            },
            setFieldError
        );
    };

    return (
        <div className='productCategory'>
            <UiDialog
                open={openCategoryModal}
                handleClose={() => {
                    setOpenCategoryModal(false);
                }}
                title='Add New Category'
                size='sm'
            >
                <CreateCategory
                    categoryFormSubmitted={categoryFormSubmitted}
                    accountParentList={listData?.categoryOptions?.filter(
                        (c: any) => c.can_have_children
                    )}
                    onCategorySubmit={onCategorySubmit}
                    handleCategoryClose={() => {
                        setOpenCategoryModal(false);
                    }}
                />
            </UiDialog>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={`Categorize ${checkedItems.length} transactions`}
                size='sm'
            >
                <div className={classNames.formContainer}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                            innerRef={formikRef}
                        >
                            {({ submitForm, isSubmitting, isValid }) => (
                                <Form>
                                    <Box my={2}>
                                        <Autocomplete
                                            fullWidth
                                            classes={{
                                                root:
                                                    classNames.autocompleteRoot,
                                            }}
                                            value={selectedCategory}
                                            options={listData?.categoryOptions?.filter(
                                                (c: any) =>
                                                    !c.is_hidden &&
                                                    c.can_be_transaction_to
                                            )}
                                            getOptionLabel={(option: any) => {
                                                if (
                                                    typeof option === 'string'
                                                ) {
                                                    const data =
                                                        listData?.accountRecord[
                                                            option
                                                        ];
                                                    return data
                                                        ? data?.title
                                                        : '';
                                                } else {
                                                    return option.title;
                                                }
                                            }}
                                            renderOption={(option) => {
                                                let title;
                                                if (
                                                    typeof option === 'string'
                                                ) {
                                                    const data =
                                                        listData?.accountRecord[
                                                            option
                                                        ];
                                                    title = data
                                                        ? data?.title
                                                        : '';
                                                } else {
                                                    title = option.title;
                                                }
                                                return (
                                                    <React.Fragment>
                                                        <div>
                                                            <Typography variant='body1'>
                                                                {title}
                                                            </Typography>

                                                            <Typography variant='caption'>
                                                                {
                                                                    option
                                                                        .parent
                                                                        .title
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <>
                                                    <Hidden
                                                        implementation='js'
                                                        smDown
                                                    >
                                                        <TextField
                                                            {...params}
                                                            placeholder='Category'
                                                            variant='outlined'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <InputAdornment position='start'>
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Hidden>
                                                    <Hidden
                                                        implementation='js'
                                                        mdUp
                                                    >
                                                        <TextField
                                                            {...params}
                                                            classes={{
                                                                root:
                                                                    classNames.textFieldRoot,
                                                            }}
                                                            variant='outlined'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <InputAdornment position='start'>
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Hidden>
                                                </>
                                            )}
                                            onChange={(event, item: any) => {
                                                formikRef?.current?.setFieldValue(
                                                    'category',
                                                    item?.id
                                                );
                                                setSelectedCategory(item);
                                            }}
                                            PaperComponent={(child: any) => {
                                                return menuLink(child);
                                            }}
                                        />
                                    </Box>
                                    <div className={classNames.footerAction}>
                                        {isSubmitting ? (
                                            <Loader />
                                        ) : (
                                            <Grid justify='flex-end' container>
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    onClick={handleClose}
                                                    className={
                                                        classNames.cancelButton
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    disabled={!isValid}
                                                    onClick={submitForm}
                                                >
                                                    Save Changes
                                                </Button>
                                            </Grid>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </UiDialog>
        </div>
    );
}

const mapStateToProps = ({ config, appData, category }: ApplicationStore) => ({
    config,
    category,
    businessId: appData.current_business_id,
    accountId: appData.current_account_id,
});

export default connect(mapStateToProps)(TransactionCategorizeModal);
