import { makeStyles, createStyles, Avatar, Theme } from '@material-ui/core';
import React, { Fragment } from 'react'
import { Attachments } from '../../../models/notification';
import { toRem16 } from '../../../styles/commonStyles';
import LinkButton from '../../common/LinkButton';
import UiText from '../../common/ui/UiText';
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useHistory } from 'react-router-dom';
import { Content } from './ActivityFeed.model';
import { getMessageTime } from '../../../utils/appUtil';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface MessageFeed {
    message: Content 
}
const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        linkButton: {
            minWidth: 0,
            display: 'flex',
        },
        messageTimeStamp: {
            color: colorTheme => colorTheme.black200,
        },
        messageCenterItemContent: {
            borderBottom: colorTheme => `${toRem16(1)} solid ${colorTheme.grey200}`,
            width: '100%',
            padding: '1rem 0',
        },
        messageCenterListItem: {
            display: 'flex',
            gap: '1rem',
        },
        accountantIconContainer: {
            padding: '2rem 0 0 0',
        },
        reconciliationIcon: {
            margin: '2rem 0 0 0'
        },
        messageSubject: {
            display: 'flex', 
            justifyContent: 'space-between'
        }
    })
)
export const MessageFeed = (props: MessageFeed) => {
    const { colorTheme } = useThemeContext()
    const { message } = props;
    const history = useHistory();
    const classes = useLocalStyles(colorTheme);
    const { lastUnreadMessage } = message;
    const accountant = lastUnreadMessage?.accountant
    const firstName = accountant?.first_name
    const lastName = accountant?.last_name
    const photoLink = accountant?.photo_link
    return (
        <li className={classes.messageCenterListItem}>
            <div className={classes.accountantIconContainer}>
                <Avatar alt={firstName} src={photoLink} />
            </div>

            <div className={classes.messageCenterItemContent}>
                <UiText
                    variant="moped_75"
                    weight="regular_400"
                    className={classes.messageTimeStamp}
                >
                    {getMessageTime(message.lastUnreadMessage.date * 1000)}
                </UiText>
                <div className={classes.messageSubject}>
                    <UiText variant="car_100" weight="medium_500">
                        {message.subject}
                    </UiText>
                    <div>
                        {lastUnreadMessage.attachments.map(
                            (attachment: Attachments, index: number) => (
                                <Fragment key={index}>
                                    <AttachFileIcon />
                                </Fragment>
                            )
                        )}
                    </div>
                </div>
                <UiText variant="car_100" weight="regular_400">
                    {firstName} {lastName}
                    {lastUnreadMessage.body &&
                        ': ' + lastUnreadMessage.body}
                    {!lastUnreadMessage.body &&
                    lastUnreadMessage.attachments.length === 0
                        ? 'This file has been removed.'
                        : ''}
                </UiText>
                <div>
                    {lastUnreadMessage.attachments.map(
                        (attachment: Attachments, index: number) => (
                            <Fragment key={index}>
                                {attachment.file_name}
                            </Fragment>
                        )
                    )}
                </div>
                <LinkButton
                    className={classes.linkButton}
                    onClick={() => {
                        history.push(`/message_center/${message.id}`)
                    }}
                >
                    View Conversation
                </LinkButton>
            </div>
        </li>
    )
}