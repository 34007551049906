import React, { useState } from 'react'
import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog'
import UiText from '../../../common/ui/UiText'
import DeleteIcon from '../../../../assets/icons-svg/WhiteDelete.svg'
import { Button, Theme, createStyles, makeStyles } from '@material-ui/core'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { handleDeletePurpose } from '../actions';

const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        header: {
            margin: '0rem 0rem 1rem 0rem',
        },
        infoText: {
            margin: '0rem 0rem 2rem 0rem',
        },
        deleteButton: {
            background: (colorTheme) => colorTheme.red200,
        },
        loader: {
            padding: '0rem 2rem 0rem 0rem',
        },
    })
)

interface Props {
    isOpen: boolean
    handleCloseModal: (isClose: boolean, isError?:boolean) => void
    purposeId: string
    loadPurposes: () => void
}
export const DeletePurposeModal = (props: Props) => {
    const { isOpen, handleCloseModal, purposeId, loadPurposes } =
        props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    const [submitting, setSubmitting] = useState(false)
    const onDeletePurpose = (purposeId: string) => {
        setSubmitting(true)
        
        handleDeletePurpose(purposeId, (isError) => {
            setSubmitting(false)
            handleCloseModal(true, isError);
            loadPurposes?.();
        });
    };

    const getActionButtons = (): React.ReactNode => {
        return (
            <>
                <Button
                    variant="contained"
                    className={classes.deleteButton}
                    startIcon={<img alt={`Delete`} src={DeleteIcon} />}
                    onClick={() => onDeletePurpose(purposeId)}
                    color="secondary"
                >
                    Delete
                </Button>
            </>
        )
    }
    return (
        <UiConfirmationDialog
            open={isOpen}
            showLoader={submitting}
            message={
                <>
                <UiText
                    variant="hatchback_125"
                    weight="semi_bold_600"
                    className={classes.header}
                >
                    Delete Purpose?
                </UiText>
                <UiText className={classes.infoText}>
                    This cannot be undone.
                </UiText>
            </>
                    
            }
            handleClose={() => handleCloseModal(true)}
            confirmNode={getActionButtons()}
            cancelButtonText="Cancel"
        />
    )
}
