export const ONBOARDING_PAGE_INFO = {
    WELCOME_MESSAGE: 'Welcome to 1-800Accountant!',
    PAGE_DESCRIPTION:
        'We have a few more questions about your business that will allow us to optimize your experience.',
    FORM_FIELD_INFO: 'All fields are required',
    INDUSTRY_SUBINPUT_TEXT:
        'This will determine your business’s chart of accounts. You will not be able to change this later.',
    OWNER_PAGE_ALERT_TEXT:
        ' The combined ownership percentage from all owners needs to equal 100%.',
}

export const ONBOARDING_FORM_FIELDS = {
    BUSINESS_TYPE: {
        LABEL: 'What is the entity type of your business?',
        FIELD_TYPE: 'business_type',
        PLACEHOLDER: 'Select an Entity Type',
    },
    INDUSTRY: {
        LABEL: 'What industry is your business in?',
        FIELD_TYPE: 'industry',
        PLACEHOLDER: 'Select an Industry',
    },
    STATE: {
        LABEL: 'What state was your business created in?',
        FIELD_TYPE: 'form_state',
        PLACEHOLDER: 'Select a State',
    },
}

export const ONBOARDING_FORM_MISSING_FIELDS = {
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    COMPANY: 'company',
}
