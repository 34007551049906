import Loader from '../../common/Loader';
import UiButton from '../../common/ui/UiButton';
import {
    ArrowForward,
} from '@material-ui/icons';
interface NextActionButtonProps {
    loading: boolean;
    submitAction: () => void;
    disabled?: boolean;
    arrowIcon?: boolean;
    fullWidth?: boolean;
    customClass?: string;
    justifyLoader?: 'flex-start' | 'center'
}

export default function NextActionButton(props: NextActionButtonProps) {
    const { fullWidth = false} = props
    return props.loading ? (
        <Loader justifyContent={props.justifyLoader || 'flex-start'} size={30} />
    ) : (
        <UiButton
            customClass={props.customClass || ''}
            btnType='tertiary'
            handleClick={props.submitAction}
            disabled={props.disabled}
            label='Next'
            fullWidth={fullWidth}
            endIcon={ props?.arrowIcon ? <ArrowForward /> : null}
        />
    );
}
