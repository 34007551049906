import UiText from "../../../common/ui/UiText";
import { SvgIcon } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";

const ssnInfo = (
    <UiText variant="motorcycle_90" textColor="textSecondary">
        {" "}
        For security purposes your SSN has been hidden. You can update it by
        typing in the field.
    </UiText>
);
export const personalDetailFormMapping: any = [
    {
        key: "first_name",
        type: "text",
        placeholder: "First Name (Required)",
        md: 10,
        xs: 12,
        required: true,
        errorMessage: "Required",
        showFloatingLabel: true,
    },
    {
        key: "middle_initial",
        type: "text",
        placeholder: "Middle Initial",
        md: 10,
        xs: 12,
        showFloatingLabel: true,
    },
    {
        key: "last_name",
        type: "text",
        placeholder: "Last Name (Required)",
        md: 10,
        xs: 12,
        required: true,
        errorMessage: "Required",
        showFloatingLabel: true,
    },
    {
        key: "email",
        type: "text",
        icon: "Email",
        placeholder: "Email (Required)",
        md: 8,
        xs: 12,
        required: true,
        errorMessage: "Required",
        label: "Email (Required)",
        showFloatingLabel: true,
    },
    {
        md: 8,
        xs: 12,
        key: "date_of_birth",
        label: "Date of Birth (Required)",
        required: true,
        type: "KeyboardDatePicker",
        fieldName: "date_of_birth",
        dateType: "string",
        disableFutureDate: true,
        showFloatingLabel: true,
        errorMessage: "Required",
        endIcon: <SvgIcon component={TodayIcon} color="action" />,
        placeholder: "MM/DD/YYYY",
    },
    {
        md: 8,
        xs: 12,
        label: "Social Security Number (Required)",
        type: "mask",
        mask: [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            "-",
            /[0-9]/,
            /[0-9]/,
            "-",
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        placeholder: "Hidden",
        fieldSize: 6,
        minLength: 9,
        key: "ssn",
        infoText: ssnInfo,
    },
];

export const personalDetailValues = (employee: any): any => {
    let initialValues = {
        first_name: employee?.first_name || "",
        middle_initial: employee?.middle_initial || "",
        last_name: employee?.last_name || "",
        email: employee?.email || "",
        date_of_birth: employee?.date_of_birth || "",
        ssn: employee?.ssn || "",
    };
    return initialValues;
};
