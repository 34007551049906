import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import { Grid, List, makeStyles, Typography } from '@material-ui/core'

import { ProductDetail } from '../../../models'
import Loader from '../../common/Loader'
import ProductItem from './ProductItem'
import {  useProductServiceContextState } from  './ProductServicesProvider'
import { NO_PRODUCTS_SERVICES_TEXT, NO_PRODUCTS_TEXT, NO_SERVICES_TEXT } from './constants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 300px)',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    emptyTextDiv: {
        whiteSpace: 'pre-wrap',
        textAlign: 'center'
    }
}))

const dropdownOptions: string[] = [
    'All Products & Services',
    'Products',
    'Services',
]

function ProductList({
    loading,
    loadMore,
    data,
    hasMore,
    selectedProduct,
    setSelectedProduct,
    productUpdated,
    handleCheck,
    checkedProducts,
    productType
}: {
    loading: boolean
    loadMore: () => void
    data: ProductDetail[]
    hasMore: boolean
    selectedProduct: ProductDetail | undefined
    setSelectedProduct: React.Dispatch<ProductDetail | undefined>
    productUpdated: boolean
    handleCheck: (checked: boolean, id: string) => void
    checkedProducts: string[]
    productType: number
}) {
    const classes = useStyles()
    const {
        formattedProdCategoryObj,
        setRefetchData
    } = useProductServiceContextState()
    
    useEffect(() => {
        if (productUpdated) {
            setRefetchData(true)
        }
    }, [productUpdated, setRefetchData])

    const renderInCenter = (children: React.ReactNode) => (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ height: '100%' }}
        >
            <div className={classes.emptyTextDiv}>
                {children}
            </div>
        </Grid>
    )

    return (
        <div>
            <List className={classes.root} id="scrollableDiv">
                {loading ? (
                    renderInCenter(<Loader />)
                ) : data.length === 0 ? (
                    renderInCenter(
                        <Typography variant="subtitle1">
                            {dropdownOptions[productType] === 'All Products & Services' && NO_PRODUCTS_SERVICES_TEXT }
                            {dropdownOptions[productType] === 'Products' && NO_PRODUCTS_TEXT }
                            {dropdownOptions[productType] === 'Services' && NO_SERVICES_TEXT }
                        </Typography>
                    )
                ) : (
                    <InfiniteScroll
                        dataLength={data?.length}
                        next={() => loadMore()}
                        hasMore={hasMore}
                        scrollableTarget={'scrollableDiv'}
                        loader={
                            <Grid
                                key={'loader'}
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{
                                    paddingBottom: '16px',
                                    paddingTop: '16px',
                                }}
                            >
                                <Loader />
                            </Grid>
                        }
                    >
                        {data.length > 0 && data.map((product: ProductDetail, index: number) => (
                            <ProductItem
                                key={product?.id}
                                productId={product?.id}
                                product={product}
                                selectedProduct={selectedProduct}
                                setSelectedProduct={setSelectedProduct}
                                categories={formattedProdCategoryObj}
                                handleCheck={handleCheck}
                                checkedProducts={checkedProducts}
                                lastItem={index === (data.length - 1)}
                            />
                        ))}
                    </InfiniteScroll>
                )}
            </List>
        </div>
    )
}

export default ProductList