import { Fragment, useContext, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ApplicationStore } from '../../../models'
import { useSelector } from 'react-redux'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import { ActiveRoutingContext } from '../Providers/ActiveRoutingProvider'
import { EmployeeProvider } from '../../payroll/employees/EmployeeProvider'
import Employees from '../../payroll/employees/Employees'
import EmployeeDetails from '../../payroll/employees/EmployeeDetails'
import AdpEmployees from '../../adp-payroll/employees/AdpEmployees'
import { ADPEmployeesProvider } from '../../adp-payroll/employees/ADPEmployeesProvider'
import ADPEmployeeDetails from '../../adp-payroll/employees/ADPEmployeeDetails'
import usePermissions from '../../../hooks/usePermissions'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
        },
    }
})

const EmployeesRoutes = () => {
    const classes = useStyles()
    const { hasPayrollPermission } = usePermissions()
     const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    let currentBusiness = useSelector(
        (state: ApplicationStore) => state.appData.current_business
    )
    // ADP check flow
    const useADPFlow = currentBusiness.is_adp_flow ? true : (currentBusiness.is_adp_company_created && currentBusiness.is_gusto_company_exists)

    useEffect(() => {
        setActiveRouteHeading('Employees')
    }, [setActiveRouteHeading])

    // Redirect to payroll company setup if user does not have permission or company is not created
    if (useADPFlow && (!hasPayrollPermission || !currentBusiness.is_adp_company_created)) {
        return <Redirect to="/payroll/company" />
    }

    // Redirect to payroll company setup if user does not have permission or company is not created
    if (!useADPFlow && (!hasPayrollPermission || !currentBusiness.has_payroll_company_created)) {
        return <Redirect to="/payroll/company" />
    }

    const EmployeesSection = () => (
        <Route path="/payroll/employees" exact>
            <Container className={classes.container} maxWidth="lg">
                {useADPFlow ? <AdpEmployees /> : <Employees />}
            </Container>
        </Route>
    )

    const EmployeeDetailsSection = () => (
        <Route path={`/payroll/employees/:id`} exact>
            <Container className={classes.container} maxWidth="lg">
                {useADPFlow ? <ADPEmployeeDetails /> : <EmployeeDetails />}
            </Container>
        </Route>
    )

    const CommonRoutes = () => {
        return (
            <Switch>
                <Fragment>
                    <Hidden mdUp>
                        <EmployeesSection />
                        <EmployeeDetailsSection />
                    </Hidden>
                    <Hidden smDown>
                        <EmployeesSection />
                        <EmployeeDetailsSection />
                    </Hidden>
                </Fragment>
            </Switch>
        )
    }


    return (
        <Fragment>
            {useADPFlow ? (
                <ADPEmployeesProvider>
                    <CommonRoutes />
                </ADPEmployeesProvider>
            ) : (
                <EmployeeProvider>
                    <CommonRoutes />
                </EmployeeProvider>
            )}
        </Fragment>
    )
}

export default EmployeesRoutes
