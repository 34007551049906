import { Message } from '../../models';
import {
    getResource,
    postResource,
    patchResource,
    deleteResource,
} from '../axiosService';

export function getMessages(account_id: string, params: any) {
    return getResource(
        `api/message-center/account/${account_id}/conversation`,
        undefined,
        params
    );
}

export function getUnreadMessages(accountId: string) {
    return getResource<Message[]>(
        `/api/message-center/account/${accountId}/conversation/unread`
    );
}

export function getMessagesCenterTopics() {
    return getResource('api/message-center/topic');
}

export function createMessage(account_id: string, body: any) {
    return postResource(
        `api/message-center/account/${account_id}/conversation`,
        body
    );
}

export function getMessage(account_id: string, conversation_id: string) {
    return getResource(
        `api/message-center/account/${account_id}/conversation/${conversation_id}`,
        undefined
    );
}

export function replyToMessage(
    account_id: string,
    conersation_id: string,
    body: any
) {
    return postResource(
        `api/message-center/account/${account_id}/conversation/${conersation_id}/messages`,
        body,
        undefined
    );
}

export function markReadMessage(
    account_id: string,
    conersation_id: string,
    id: any
) {
    return patchResource(
        `api/message-center/account/${account_id}/conversation/${conersation_id}/messages`,
        { filters: id, is_read: true },
        undefined
    );
}

export function archiveMessage(account_id: string, conersation_id: string) {
    return patchResource(
        `api/message-center/account/${account_id}/conversation/${conersation_id}`,
        { status: 'archived' },
        undefined
    );
}

export function removeAttachment(
    conersation_id: string,
    message_id: string,
    attachment_id: string
) {
    return deleteResource(
        `api/message-center/conversation/${conersation_id}/message/${message_id}/attachment/${attachment_id}`
    );
}
