export const contractor = {}

export const API = {
    CONTRACTOR: 'api/business/{id}/contractor',
    CONTRACTORS_LIST: 'api/business/{id}/contractors',
    DOWNLOAD_TEMPLATE: 'api/business/{id}/contractor_import/download',
    IMPORTED_DATA: 'api/business/{id}/contractor_import',
    UPLOAD_FILE: '/api/business/{id}/contractor_import/upload',
    IMPORT_CONTRACTOR: 'api/business/{id}/contractor_import/import',
    IMPORT_COMPLETE: 'api/business/{id}/contractor_import/complete_import',
    DISCARD_IMPORT: 'api/business/{id}/contractor_import',
    NEED_REVIEW: 'api/business/{id}/contractor_import/update_contractor',
    SEND_W9_EMAIL: 'api/business/{id}/contractor/{contractor_id}/send_w_nine',
    ADD_CONTRACTOR_INTO_YEAR: 'api/business/{id}/contractor/year/{year}',
    FILE_1099: 'api/business/{id}/contractor/file_1099/{year}',
    LIMITED_ACCESS: 'contractor_api/contractor',
    GET_VIEW_PDF_LINK: '/api/business/{id}/contractor/{contractorId}/view_1099/{year}',
    UPDATE_PAID_AMOUNT: '/api/business/{id}/contractorW9/{w9_id}'
}

export const STEPS = [
    'The first step to issuing a 1099-NEC is to send W-9s to the contractors you worked with throughout the year.',
    'A W-9 is a legally mandated IRS information request form that your contractors must fill out.',
    'You can file a 1099-NEC with the IRS once you have all the necessary information from the contractor.',
]

export const ERRORS = {
    FIRST_NAME: 'First name is required',
    LAST_NAME: 'Last name is required',
    VALID_EMAIL: 'Must be a valid email',
    EMAIL: 'Email is required',
    PHONE: 'Phone is required',
    TAX: 'Tax year is required',
    TIN: 'Should be exactly 9 charachters',
    BUSSINESS: 'Bussines name is required',
    TYPE: 'Type is required',
    IDENTIFICATION: 'Identification number is required',
    STREET: 'Street is required',
    CITY: 'City is required',
    STATE: 'State is required',
    ZIPCODE: 'Zip code is required'
}

export const IMPORT_STEPS = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
}

export enum importSectionMode {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    COMPLETED = 'completed',
}
export enum pages {
    MAIN = 'main',
    IMPORT_W_9 = 'importw9',
    EMPTY_STATE = 'empty state',
}

export const CONTRACTOR_TOKEN = 'contractorToken'

export const IMPORT_STEP3_DATA = [
    {
        key: 'Last Name',
        value: 'last_name',
    },
    {
        key: 'First Name',
        value: 'first_name',
    },
    {
        key: 'Phone',
        value: 'phone',
    },
    {
        key: 'Contractor Type',
        value: 'type',
    },
    {
        key: 'Business Name',
        value: 'business_name',
    },
    {
        key: 'Email Address',
        value: 'email',
    },
    {
        key: 'Tax Year',
        value: 'year',
    },
    {
        key: 'Tax Identification Type',
        value: 'tax_identification_type',
    },
    {
        key: 'Tax Identification Number',
        value: 'tax_identification_number',
    },
    {
        key: 'Street Address',
        value: 'street',
    },
    {
        key: 'City',
        value: 'city',
    },
    {
        key: 'State',
        value: 'state',
    },
    {
        key: 'ZIP or Foreign Postal Code',
        value: 'zip',
    }
]
