import UiText from '../../../common/ui/UiText'
import { Grid, GridSize, SvgIcon } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import { RenderFormFields } from '../../../payroll/employees/Forms/FormUtils'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { useStyles } from '../CompanyCommonStyles'
import moment from 'moment'
import * as Yup from 'yup'
import { ADPCompanyDetails } from '../../../../models/adp-payroll/company'

const ssnInfo = (
    <UiText variant="motorcycle_90" textColor="textSecondary">
        {' '}
        For security purposes your SSN has been hidden. You can update it by
        typing in the field.
    </UiText>
)
export const personalDetailFormMapping: any = [
    {
        key: 'first_name',
        type: 'text',
        placeholder: 'First Name (Required)',
        md: 10,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        showFloatingLabel: true,
    },
    {
        key: 'middle_initial',
        type: 'text',
        placeholder: 'Middle Initial (Optional)',
        md: 10,
        xs: 12,
        showFloatingLabel: true,
    },
    {
        key: 'last_name',
        type: 'text',
        placeholder: 'Last Name (Required)',
        md: 10,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        showFloatingLabel: true,
    },
    {
        key: 'email',
        type: 'text',
        icon: 'Email',
        placeholder: 'Email (Required)',
        md: 8,
        xs: 12,
        required: true,
        errorMessage: 'Required',
        label: 'Email (Required)',
        showFloatingLabel: true,
    },
    {
        md: 8,
        xs: 12,
        key: 'dob',
        label: 'Date of Birth (Required)',
        required: true,
        type: 'KeyboardDatePicker',
        fieldName: 'date_of_birth',
        dateType: 'string',
        disableFutureDate: true,
        showFloatingLabel: true,
        endIcon: <SvgIcon component={TodayIcon} color="action" />,
        placeholder: 'MM/DD/YYYY',
    },
    {
        md: 8,
        xs: 12,
        key: 'phone',
        label: 'Phone Number(Required)',
        required: true,
        type: 'mask',
        fieldName: 'phone',
        regex: [
            '(',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            ')',
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        minLength: 10,
    },
    {
        md: 8,
        xs: 12,
        label: 'Social Security Number (Required)',
        type: 'mask',
        mask: [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
        ],
        placeholder: 'Hidden',
        fieldSize: 6,
        minLength: 9,
        key: 'ssn',
        infoText: ssnInfo,
    },
    {
        key: 'gender',
        type: 'radio',
        label: 'Gender (Required)',
        md: 10,
        xs: 12,
        required: true,
        radioOptionsData: [
            {
                label: 'Male',
                value: 'Male',
            },
            {
                label: 'Female',
                value: 'Female',
            },
        ],
    },
]

const formatAddress = (address: any) => {
    const components = [
        address?.street,
        address?.street_2,
        address?.city,
        address?.state,
        address?.zip_code,
    ]
    // Filter out empty values
    const nonEmptyComponents = components.filter((component) => component)

    // Join the non-empty components with comma
    const formattedAddress = nonEmptyComponents.join(', ')

    return formattedAddress
}

export const jobDetailFormMapping: any = (
    companyDetails: any,
    setFieldValue: any
) => {
    let locationData = [formatAddress(companyDetails?.companyDetails)]
    return [
        {
            type: 'select',
            placeholder: 'Job Type (Required)',
            label: 'Job Type (Required)',
            md: 10,
            xs: 12,
            key: 'job_type',
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
            data: [
                { name: '1st Party Contract', id: '1stPartyContract' },
                { name: 'Employee', id: 'Employee' },
            ],
            optionKey: 'name',
            optionValue: 'id',
            onChange: () => {
                setFieldValue('frequency', '')
            },
        },
        {
            md: 8,
            xs: 12,
            key: 'hire_date',
            label: 'Date of Hire (Required)',
            required: true,
            type: 'KeyboardDatePicker',
            fieldName: 'hire_date',
            dateType: 'string',
            disableFutureDate: true,
            showFloatingLabel: true,
            placeholder: 'MM/DD/YYYY',
            errorMessage: 'Required',
            endIcon: <SvgIcon component={TodayIcon} color="action" />,
            infoText: (
                <UiText variant="motorcycle_90" textColor="textSecondary">
                    {' '}
                    This can be the date the employee was hired, <br /> or the
                    date they started this specific job.
                </UiText>
            ),
        },
        {
            key: 'job_title',
            type: 'text',
            placeholder: 'Job Title (Required)',
            label: 'Job Title (Required)',
            md: 10,
            xs: 12,
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
        },
        {
            type: 'select',
            placeholder: 'Location (Required)',
            label: 'Location (Required)',
            md: 10,
            xs: 12,
            key: 'job_location',
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
            data: locationData,
            infoText: (
                <UiText variant="motorcycle_90" textColor="textSecondary">
                    {' '}
                    The location where the employee will be working.
                </UiText>
            ),
        },
    ]
}

const compensationData = (jobType: string, frequency: string) => {
    if (jobType === 'Employee') {
        return [
            {
                code: 'Hourly',
                shortName: 'Hourly',
            },
            {
                code: 'PerPay',
                shortName: 'Per Pay Period',
            },
            {
                code: 'PerPayOvertime',
                shortName: 'Per Pay Period with OT',
            },
            {
                code: 'SalaryNoOvertime',
                shortName: 'Annual salary',
            },
            {
                code: 'SalaryOvertimeEligible',
                shortName: 'Annual salary with OT',
            },
        ]
    } else {
        return [
            {
                code: 'FlatAmount',
                shortName: 'Flat Amount',
            },
            {
                code: 'Hourly',
                shortName: 'Hourly',
            },
        ]
    }
}

export const compensationFormMappings = (values: any) => {
    return [
        {
            md: 8,
            xs: 12,
            key: 'amount',
            label: 'Amount (Required)',
            type: 'currency',
            showFloatingLabel: true,
            required: true,
        },
        {
            key: 'frequency',
            type: 'select',
            placeholder: 'Frequency (Required)',
            label: 'Frequency (Required)',
            md: 10,
            xs: 12,
            required: true,
            data: compensationData(values?.job_type, values?.frequency),
            showFloatingLabel: true,
            infoText: paymentUnitInfo,
            optionKey: 'shortName',
            optionValue: 'code',
            fastField: false,
        },
    ]
}

export const ownershipPaymentError = {
    message: 'For Owner, Payment frequency should be `Paycheck`',
    path: 'payment_unit',
}
export const payCheckUnitError = {
    message: 'Payment unit must be one of Hour, Week, Month, or Year',
    path: 'payment_unit',
}

const paymentUnitInfo = (
    <UiText variant="motorcycle_90">
        {' '}
        The frequency the compensation amount is paid{' '}
    </UiText>
)

const conditionalValidation = (attribute: string, schema: any) => {
    return Yup.mixed().when(attribute, {
        is: true,
        then: schema,
        otherwise: Yup.mixed().notRequired(),
    })
}

export const getInitialValues: any = (companyDetails?: ADPCompanyDetails) => {
    const employeeData = companyDetails?.firstEmployee
    let locationData = formatAddress(companyDetails?.companyDetails)
    return {
        first_name: employeeData?.first_name || '',
        middle_initial: employeeData?.middle_initial || '',
        last_name: employeeData?.last_name || '',
        email: employeeData?.email || '',
        dob: employeeData?.dob ?? '',
        phone: employeeData?.phone || '',
        ssn: '',
        is_owner_an_employee: employeeData?.is_owner_an_employee || false,
        job_type: employeeData?.job_type,
        job_title: employeeData?.job_title || '',
        job_location: `${locationData}`,
        amount: employeeData?.amount || 0.0,
        gender: employeeData?.gender || 'Male',
        hire_date: employeeData?.hire_date
            ?? '',
        frequency: employeeData?.frequency ?? null,
        ownership_percentage: employeeData?.ownership_percentage || 0,
    }
}

const HIRE_DATE_VALIDATION = Yup.string()
    .required('Hire date is required')
    .test(
        'is-after-birth',
        'Employee must be at least 14 years old at the time of hiring',
        function (value: any) {
            const birthDate = new Date(this.parent.dob)
            const hireDate = new Date(value)
            return birthDate.getFullYear() <= hireDate.getFullYear() - 14
        }
    )
const COMPENSATION_AMOUNT_VALIDATION = Yup.number()
    .positive('Amount should be greater than 0')
    .required('Amount is required')
    .typeError('Amount must be a number')

export const employeeInfoValidationSchema = () => {
    return Yup.object({
        first_name: Yup.string().required('First Name is required'),
        middle_initial: Yup.string(),
        last_name: Yup.string().required('Last Name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        ssn: Yup.string().required('SSN is required'),
        dob: Yup.string().required('Date of Birth is required'),
        phone: Yup.string()
            .required('Phone number is required')
            .min(10, 'Phone number should be at least 10 digits'),
        hire_date: conditionalValidation(
            'is_owner_an_employee',
            HIRE_DATE_VALIDATION
        ),
        job_title: conditionalValidation(
            'is_owner_an_employee',
            Yup.string().required('Job title is required')
        ),
        job_location: conditionalValidation(
            'is_owner_an_employee',
            Yup.string().required('Job location is required')
        ),
        amount: conditionalValidation(
            'is_owner_an_employee',
            COMPENSATION_AMOUNT_VALIDATION
        ),
        gender: Yup.string().required('Gender is required'),
        frequency: conditionalValidation(
            'is_owner_an_employee',
            Yup.string().required('Compensation frequency is required')
        ),
        is_owner_an_employee: Yup.boolean().required(
            'Ownership status is required'
        ),
        ownership_percentage: Yup.number()
            .min(1, 'Ownership percentage must be greater than or equal to 0')
            .max(
                100,
                'Ownership percentage must be should be less or equal than 100'
            )
            .required('Ownership percentage is required')
            .test(
                'is-decimal',
                'Only numbers with up to two decimal places are allowed',
                (value: any) => {
                    if (isNaN(value)) return false
                    return value === parseFloat(value.toFixed(2))
                }
            ),
    })
}

export const RenderForm = (property: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <Grid container spacing={1}>
            <Grid
                item
                xs={property?.xs as GridSize}
                md={property?.md as GridSize}
            >
                <div className={styles.fieldSpacing}>
                    <RenderFormFields property={property} />
                    {property?.infoText}
                </div>
            </Grid>
        </Grid>
    )
}
