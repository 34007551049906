import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api'
import Reports from '../bookkeeping/reports';
import IncomeStatementReport from '../bookkeeping/reports/IncomeStatementReport';
import BalanceSheetDetailedReport from '../bookkeeping/reports/generated-reports/BalanceSheetDetailedReport';
import IncomeStatementDetailedReport from '../bookkeeping/reports/generated-reports/IncomeStatementDetailedReport';
import InvoiceDetailedReport from '../bookkeeping/reports/generated-reports/InvoiceDetailedReport';
import ReconciliationDetailedReport from '../bookkeeping/reports/generated-reports/ReconciliationDetailedReport';
import { ReconciliationReportForAccount } from '../bookkeeping/reports/generated-reports/ReconciliationReportForAccount';
import { MileageLogDetailedReport } from '../bookkeeping/reports/generated-reports/MileageLogDetailedReport';
import TransactionDetailedReport from '../bookkeeping/reports/generated-reports/TransactionDetailedReport';
import JournalEntriesDetailedReport from '../bookkeeping/reports/generated-reports/JournalEntriesDetailedReport';
import { JournalStateProvider } from '../bookkeeping/journal/JournalProvider';
import GeneralJournalDetailedReport from '../bookkeeping/reports/generated-reports/GeneralJournalDetailedReport';
import GeneralLedgerDetailedReport from '../bookkeeping/reports/generated-reports/GeneralLedgerDetailedReport';
import { ProductServicesProvider } from '../bookkeeping/products-services/ProductServicesProvider';
import InvoiceProvider from '../bookkeeping/invoices/InvoiceProvider';
import TripsProvider from '../mileage-log/trips/Provider/TripsProvider';

export default function ReportTypesRoutes() {
    return (
        <Switch>
            <Route path="/reports" component={Reports} exact />
            <Route
                path="/reports/journal-entries/:ymdStartDate/:ymdEndDate/:periodText/:time/"
                render={() => (
                    <JournalStateProvider>
                        <JournalEntriesDetailedReport />
                    </JournalStateProvider>
                )}
                exact
            />
            <Route
                path="/reports/income-statement"
                component={IncomeStatementReport}
                exact
            />
            <Route
                path="/reports/income-statement/:startDate/:endDate/:start_date_ymd/:end_date_ymd/:time"
                component={IncomeStatementDetailedReport}
                exact
            />
            <Route
                path="/reports/balance-sheet/:periodText/:time/:endDateYmd/:startDate/:endDate"
                component={BalanceSheetDetailedReport}
            />

            <Route
                path="/reports/invoice/:periodText/:time/:dateFilter/:startDateYmd/:endDateYmd"
                render={() => (
                    <ProductServicesProvider>
                        <InvoiceProvider>
                            <InvoiceDetailedReport />
                        </InvoiceProvider>
                    </ProductServicesProvider>
                )}
                exact
            />

            <Route
                path="/reports/reconciliations/:accountIds/:periodText/:time/:startDateYmd/:endDateYmd"
                component={ReconciliationDetailedReport}
                exact
            />
            <Route
                path="/reports/transactions-by-account/:selectedAccount/:ymdStartDate/:ymdEndDate/:periodText/:time"
                exact
                render={() => (
                    <TripsProvider>
                    <JournalStateProvider>
                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey={
                            (window as any).ApiConfig.google_maps_api_key
                        }
                        libraries={['places']}
                        loadingElement=" "
                    > 
                        <TransactionDetailedReport />
                    </LoadScript>
                    </JournalStateProvider>
                    </TripsProvider>
                )}
            />
            <Route path="/reports/reconcile/:reconciliationId" component={ReconciliationReportForAccount}
                exact/>
            <Route
                path="/reports/mileage/:vehicleIds/:activity/:startDateYmd/:endDateYmd/:periodText/:time"
                render={() => (
                    <TripsProvider>
                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey={
                            (window as any).ApiConfig.google_maps_api_key
                        }
                        libraries={['places']}
                        loadingElement=" "
                    > 
                        <MileageLogDetailedReport/>
                    </LoadScript>
                    </TripsProvider>
                )}
                exact
            />
            <Route
                path="/reports/general-journal/:periodText/:time/:startDateYmd/:endDateYmd"
                component={GeneralJournalDetailedReport}
                exact
            />

            <Route
                path="/reports/general-ledger/:ymdStartDate/:ymdEndDate/:periodText/:time"
                exact
                render={() => (
                    <TripsProvider>
                    <JournalStateProvider>
                    <LoadScript
                        id="script-loader"
                        googleMapsApiKey={
                            (window as any).ApiConfig.google_maps_api_key
                        }
                        libraries={['places']}
                        loadingElement=" "
                    > 
                        <GeneralLedgerDetailedReport />
                    </LoadScript>
                    </JournalStateProvider>
                    </TripsProvider>
                )}
            />
            <Redirect to="/dashboard" />
        </Switch>
    );
}
