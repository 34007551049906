import { Action, SET_CONFIG } from "../actions/config";

export default function appData(state = {
  local: { account_steps: [] },
}, action: Action): any {
  switch (action.type) {
    case SET_CONFIG:
      return action.state;
    default:
      return state;
  }
}
