import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import {
    Button,
    Theme,
    makeStyles,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Invoice } from '../../../models'
import Loader from '../../common/Loader'
import InvoiceDetails from './InvoiceDetails'
import MobileHeader from '../../common/MobileHeader'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import { COLORS } from '../../../variables/colors'
import { invoiceDetail } from '../../../services/apiService/invoice'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import {
    ContainedDeleteButton,
} from '../../common/DeleteButton'
import UiText from '../../common/ui/UiText'
import { handleDeleteInvoice } from './actions'
import InvoiceFormModal from './Modal/InvoiceFormModal'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    editButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
    },
    marginButton: {
        marginRight: '16px',
    },
    previewContact: {
        marginTop: theme.spacing(4),
    },
    editMobileButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
        marginTop: '5px',
        paddingTop: 4,
        paddingBottom: 4,
    },
    deleteLoader: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        marginBottom: '2px',
    },
}))

function InvoicePreview({
    selectedInvoice,
    setSelectedInvoice,
    reloadPage,
}: {
    selectedInvoice?: Invoice | undefined
    setSelectedInvoice?: React.Dispatch<React.SetStateAction<any>>
    reloadPage?: () => void
}) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const common = commonStyles()
    const theme = useTheme()
    const history = useHistory()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    const params: { id: string } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentInvoice, setCurrentInvoice] = useState<Invoice>()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const invoiceDetails = selectedInvoice || currentInvoice
    const [isDeleting, setIsDeleting] = useState(false)
    const [fetchCurrentInvoice, setFetchCurrentInvoice] = useState(false)

    const deleteCurrentInvoice = () => {
        setOpenModal(false)
        setIsDeleting(true)
        handleDeleteInvoice(invoiceDetails?.id as string, () => {
            setIsDeleting(false)
            setShowDeleteModal(false)
            history.push('/invoices')
            reloadPage?.()
            setSelectedInvoice?.(undefined)
            setCurrentInvoice(undefined)
        })
    }

    const getCurrentInvoice = useCallback(() => {
        setLoading(true)
        invoiceDetail(params?.id).then((response: any) => {
            setCurrentInvoice(response)
            setSelectedInvoice?.(response)
            setLoading(false)
            setFetchCurrentInvoice(false)
            reloadPage?.()
        })
    }, [params?.id, reloadPage, setSelectedInvoice])

    useEffect(() => {
        if (fetchCurrentInvoice) {
            getCurrentInvoice()
        }
    }, [fetchCurrentInvoice, getCurrentInvoice])

    useEffect(() => {
        if (!selectedInvoice && params?.id) {
            getCurrentInvoice()
        }
    }, [getCurrentInvoice, params?.id, selectedInvoice])

    return (
        <div>
            {!(selectedInvoice || currentInvoice) || loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MobileHeader showBottomDivider={isXsDown}>
                        <div
                            className={mergeStyle(
                                common.flex,
                                common.spaceBetween
                            )}
                        >
                            <IconButton
                                onClick={() => history.push('/invoices')}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Button
                                variant="text"
                                classes={{ root: styles.editMobileButton }}
                                onClick={() => setOpenModal(true)}
                            >
                                Edit
                            </Button>
                        </div>
                    </MobileHeader>
                    <InvoiceFormModal
                        open={openModal}
                        handleClose={() => setOpenModal(false)}
                        isNew={false}
                        modalTitle={`Edit Invoice #${invoiceDetails?.number}`}
                        invoiceDetails={invoiceDetails}
                        setFetchCurrentInvoice={setFetchCurrentInvoice}
                        setShowDeleteConfirmation={setShowDeleteModal}
                    />
                    <UiConfirmationDialog
                        open={showDeleteModal}
                        message={
                            <div>
                                <UiText weight="semi_bold_600">
                                    Delete invoice #{invoiceDetails?.number} ?
                                </UiText>
                                <UiText className={common.mt16}>
                                    The invoice will be deleted from your
                                    account.
                                </UiText>
                                <UiText className={common.mt16}>
                                    This can not be undone.
                                </UiText>
                            </div>
                        }
                        handleClose={() => {
                            setShowDeleteModal(false)
                        }}
                        confirmNode={
                            <>
                                {isDeleting ? (
                                    <div className={styles.deleteLoader}>
                                        <Loader size={30} />
                                    </div>
                                ) : (
                                    <ContainedDeleteButton
                                        variant="contained"
                                        showDeleteIcon
                                        onClick={deleteCurrentInvoice}
                                        data-cy="delete-invoice-btn"
                                    >
                                        Delete
                                    </ContainedDeleteButton>
                                )}
                            </>
                        }
                        cancelButtonText="Cancel"
                    />
                    <div className={styles.previewContact}>
                        <InvoiceDetails 
                            invoice={invoiceDetails}
                            setCurrentInvoice={setCurrentInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            setLoading={setLoading}
                            reloadPage={reloadPage}
                            setFetchCurrentInvoice={setFetchCurrentInvoice}
                            setShowDeleteModal={setShowDeleteModal}
                            setOpenModal={setOpenModal}
                        />
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default InvoicePreview
