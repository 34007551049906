import {
    makeStyles,
    Theme,
    Typography,
    Button,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import UiDialog from '../../../../common/ui/UiDialog'
import ThumbsUp from '../../../../../assets/icons-svg/ThumpsUp.svg'
import { commonStyles, mergeStyle } from '../../../../../styles/commonStyles'
const styleClasses = makeStyles((theme: Theme) => ({
    marginItems: {
        marginTop: theme.spacing(4),
    },
}))
const RegistrationConfirmedModal = ({ open, handleClose }: any) => {
    const styles = commonStyles()
    const classes = styleClasses()
    const theme = useTheme()
    const isXsDevice = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <div>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title=""
                size="sm"
                hideTitleSection={true}
                contentStyles={isXsDevice ? {} : { minHeight: '400px' }}
            >
                <div
                    className={mergeStyle(
                        styles.flex,
                        styles.spaceAround,
                        classes.marginItems
                    )}
                >
                    <img src={ThumbsUp} alt="thumsup" />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Typography
                        variant="h5"
                        style={{ fontWeight: 'bold' }}
                        className={mergeStyle(
                            styles.flex,
                            styles.spaceAround,
                            classes.marginItems
                        )}
                    >
                        Congratulations!
                    </Typography>
                    <Typography variant="h6" className={classes.marginItems}>
                        You successfully applied for Online Invoicing
                    </Typography>
                    <Typography variant="body2" className={classes.marginItems}>
                        You will receive an email within two business days
                        regarding your <br /> approval status
                    </Typography>
                </div>
                <div
                    className={mergeStyle(
                        styles.flex,
                        styles.spaceAround,
                        classes.marginItems
                    )}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleClose}
                    >
                        Okay{' '}
                    </Button>
                </div>
            </UiDialog>
        </div>
    )
}

export default RegistrationConfirmedModal
