import React, { useEffect, useState, useCallback } from 'react';
import { Typography } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import SectionView from '../../common/SectionView'
import EmptySectionView from '../../common/EmptySectionView'
import UiText from '../../../common/ui/UiText'
import { EmployeeComponentProps } from '../../../../models/payroll/employee'
import Loader from '../../../common/Loader'
import Icon from '../../../common/Icon'
import {
    getEmployeePaymentMethod,
    getEmployeeBankAccount,
} from '../../../../services/apiService/payroll/employee'
import { commonStyles, mergeStyle } from '../../../../styles/commonStyles'
interface PaymentMethodProps extends EmployeeComponentProps {
    employeeId?: string
    businessId?: string
    setOpenRequestDialog: React.Dispatch<React.SetStateAction<boolean>>
    disableRequestInputButton?: boolean | undefined
    employeeAddress: any
}

const PaymentMethod = ({
    employeeId,
    businessId,
    setOpenRequestDialog,
    disableRequestInputButton,
    employeeAddress
}: PaymentMethodProps) => {
    const [loading, setLoading] = useState(false)
    const [accountType, setAccountType] = useState<string>()
    const [accountDetails, setAccountDetails] = useState<any>()
    const styles = commonStyles()

    const fetchBankDetails = useCallback(
        (type) => {
            if (type === 'Direct Deposit') {
                getEmployeeBankAccount(
                    businessId as string,
                    employeeId as string
                )
                    .then((response: any) => {
                        if (response) {
                            setAccountDetails(response)
                            setLoading(false)
                        }
                    })
                    .catch((error: any) => {
                        setLoading(false)
                    })
            } else {
                setLoading(false)
            }
        },
        [businessId, employeeId]
    )

    useEffect(() => {
        setLoading(true)
        getEmployeePaymentMethod(businessId as string, employeeId as string)
            .then((response: any) => {
                if (response) {
                    setAccountType(response.type)
                    fetchBankDetails(response.type)
                } else {
                    setLoading(false)
                }
            })
            .catch((err: any) => {
                setLoading(false)
            })
    }, [businessId, employeeId, fetchBankDetails])

    return (
        <div>
            <SectionView
                key={'direct_deposit'}
                headerText={'Payment Methods'}
                customNode={
                    <>
                        {loading ? (
                            <div style={{ height: '200px' }}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {accountType ? (
                                    <>
                                        {accountType === 'Direct Deposit' ? (
                                            <div>
                                                <Typography variant="body1">
                                                    Direct Deposit
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    This employee’s paycheck
                                                    will be deposited to the
                                                    provided bank account:
                                                </Typography>
                                                <div
                                                    className={mergeStyle(
                                                        styles.flex,
                                                        styles.mt24
                                                    )}
                                                >
                                                    <Icon
                                                        icon="bank"
                                                        size="30px"
                                                    ></Icon>
                                                    <div
                                                        className={styles.ml16}
                                                    >
                                                        <UiText>
                                                            {accountDetails?.[0]?.name ||
                                                                'Bank Name'}
                                                        </UiText>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Account:{' '}
                                                            {
                                                                accountDetails?.[0]?.account_number
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Routing:{' '}
                                                            {
                                                                accountDetails?.[0]?.routing_number
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Type:{' '}
                                                            {accountDetails?.[0]?.account_type ||
                                                                'Checking'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            /** TODO - Need to add mailing address*/
                                            <div
                                                style={{
                                                    height: '200px',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <Typography variant="body1">
                                                    Check
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    A check will be given to you
                                                    by your employer.
                                                    <br />
                                                    1-800 Accountant does not
                                                    mail checks
                                                </Typography>
                                                <div
                                                    className={mergeStyle(
                                                        styles.flex,
                                                        styles.mt24
                                                    )}
                                                >
                                                   {employeeAddress?.street_1 && <Icon icon="locations" size="20px" />}
                                                    <div className={styles.ml16}>
                                                        <UiText>
                                                            {employeeAddress?.street_1} {employeeAddress?.street_2} <br/>
                                                            {employeeAddress?.city} {employeeAddress?.zip}
                                                        </UiText>
                                                    </div>
                                                </div>    
                                                
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <EmptySectionView
                                        showDivider={false}
                                        primaryText={'No Payment Method Setup'}
                                        secondaryText={''}
                                        buttonText={'Request employee input'}
                                        buttonIcon={<EmailIcon />}
                                        disableButton={disableRequestInputButton}
                                        onClickAction={() => {
                                            setOpenRequestDialog(true)
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </>
                }
            />
        </div>
    )
}

export default PaymentMethod
