import {
    Avatar,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { Fragment } from "react";
import * as React from "react";
import CircularProgressWithLabel from "../../common/CircularProgressWithLabel";
import LinkButton from '../../common/LinkButton';
import PortalTabs from "../../common/PortalTabs";
import TabPanelHolder from "../../common/TabPanelHolder";
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

function ToDoContent(props: { width: string; children: React.ReactNode }) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(1),
            marginTop: theme.spacing(1),
            width: props.width,
        },
        title: {
            padding: theme.spacing(2),
            top: theme.spacing(0),
            position: "relative",
            display: "inline",
        },
        listRoot: {
            width: "100%",
            maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: "inline",
        },
        actionButton: {
            //backgroundColor: theme.palette.grey[100],
            background: colorTheme.grey2100,
            marginTop: theme.spacing(2),
            height: "36px",
            display: "flex",
        },
        timeText: {
            whiteSpace: "nowrap",
            paddingTop: "6px",
        },
        cardIcon: {
            width: "38px",
            left: "1px",
            opacity: "0.8",
            top: "7px",
        },
        dateStyle: {
            opacity: 0.65,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        navListItemGutters: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    }))();

    function Completed() {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    <Typography variant="body2" className={classes.dateStyle}>
                        Completed Sep 29, 2020
                    </Typography>
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <img
                                alt={`Check-Circle Icon`}
                                src={require(`../../../assets/icons-svg/check-circle.svg`)}
                                className={classes.cardIcon}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary="2020 Taxes Complete"
                            secondary={
                                <Fragment>
                                    <span>
                                        You finished your submission for taxes.
                                        Click to view our progress.
                                    </span>

                                    <LinkButton>
                                        View 2020 Tax Return
                                    </LinkButton>
                                </Fragment>
                            }
                        />
                        <Typography
                            className={classes.timeText}
                            variant="caption"
                        >
                            12:34 PM
                        </Typography>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <Typography variant="body2" className={classes.dateStyle}>
                        Completed Sep 28, 2020
                    </Typography>
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <img
                                alt={`Bookkeeping Icon`}
                                src={require(`../../../assets/icons-svg/Credit-Card.svg`)}
                                className={classes.cardIcon}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Billing Info Updated"
                            secondary="You finished your submission for taxes. Click to view our progress."
                        />
                        <Typography
                            className={classes.timeText}
                            variant="caption"
                        >
                            12:34 PM
                        </Typography>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <img
                                alt={`Document Icon`}
                                src={require(`../../../assets/icons-svg/Document.svg`)}
                                className={classes.cardIcon}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Document Uploaded"
                            secondary={
                                <Fragment>
                                    <span>
                                        You successfully uploaded
                                        document-name.doc.
                                    </span>

                                    <LinkButton>
                                        View Document
                                    </LinkButton>
                                </Fragment>
                            }
                        />
                        <Typography
                            className={classes.timeText}
                            variant="caption"
                        >
                            12:34 PM
                        </Typography>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </List>
            </Fragment>
        );
    }

    function DueSoon() {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    <Typography variant="body2" className={classes.dateStyle}>
                        Due Sep 29, 2020
                    </Typography>
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <CircularProgressWithLabel value={44} />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Finish Tax Submission"
                            secondary={
                                <Fragment>
                                    <span>
                                        You’re so close! Finish your 2020 Tax
                                        documentation and be worry free.
                                    </span>

                                    <Button
                                        className={classes.actionButton}
                                        variant="contained"
                                    >
                                        Finish 2020 Taxes
                                    </Button>
                                </Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <Typography variant="body2" className={classes.dateStyle}>
                        Due Sep 28, 2020
                    </Typography>
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <img
                                alt={`Bookkeeping Icon`}
                                src={require(`../../../assets/icons-svg/Credit-Card.svg`)}
                                className={classes.cardIcon}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Billing Info Out-of-Date"
                            secondary={
                                <Fragment>
                                    <span>
                                        Your info is out of date. Update to
                                        continue your subscription.
                                    </span>

                                    <Button
                                        className={classes.actionButton}
                                        variant="contained"
                                    >
                                        Update Billing Info
                                    </Button>
                                </Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem
                        classes={{
                            gutters: classes.navListItemGutters,
                        }}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <Avatar
                                alt="Remy Sharp"
                                src="https://material-ui.com/static/images/avatar/3.jpg"
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Document Request"
                            secondary={
                                <Fragment>
                                    <span>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            Christopher C
                                        </Typography>
                                        {
                                            " needs your 2019 Tax Return documents"
                                        }
                                    </span>

                                    <Button
                                        className={classes.actionButton}
                                        variant="contained"
                                    >
                                        Upload Document
                                    </Button>
                                </Fragment>
                            }
                        />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                </List>
            </Fragment>
        );
    }

    return (
        <div className={classes.root}>
            {props.children}
            <Typography className={classes.title} variant="h5">
                To-Do
            </Typography>
            <PortalTabs>
                <TabPanelHolder index={0} title="Due Soon">
                    <DueSoon />
                </TabPanelHolder>
                <TabPanelHolder index={1} title="Completed">
                    <Completed />
                </TabPanelHolder>
            </PortalTabs>
        </div>
    );
}

export default ToDoContent;
