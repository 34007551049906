import { AccountProgress } from '../../../models/dashboardModel'
import { updateDismissPopup } from '../../../services/apiService'
import { showError } from '../../../services/formService'
import {
    getFromLocalStorage,
    setInLocalStorage,
} from '../../../services/localStorageService'
import {
    BANK_ACCOUNT_CREATED,
    CONNECT_BANK_ACCOUNT,
    DISMISSED_BLOCK_STORAGE_KEY,
    MERCHANT_ACCOUNT_SETUP,
    TODO_CONNECT_BANK,
    TODO_MERCHANT_SETUP,
} from '../TodoSection/Todo.const'
import store from '../../../store/index'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

export const isBankSetupCompletedOrDismissed = (
    accountProgress: AccountProgress,
    dismissPopupInfo: string[]
) => {
    const isBankSetupDismissed = dismissPopupInfo?.includes(TODO_CONNECT_BANK)
    return accountProgress?.[CONNECT_BANK_ACCOUNT] || 
        isBankSetupDismissed || 
        accountProgress?.[BANK_ACCOUNT_CREATED]
}

export const isMerchantSetupCompletedORDismissed = (
    accountProgress: AccountProgress,
    dismissPopupInfo: string[]
) => {
    const isMerchantPopupDismissed =
        dismissPopupInfo?.includes(TODO_MERCHANT_SETUP)
    return (
        accountProgress?.[MERCHANT_ACCOUNT_SETUP] || isMerchantPopupDismissed
    )
}
/**
 * Dismiss a block and store in local storage. 
 */

export const dismissBlock = (key: string) => {
    const { appData } = store.getState();
    const currentAccountId = appData.current_account_id
    const dismissedBlocks = getFromLocalStorage(DISMISSED_BLOCK_STORAGE_KEY)
    setInLocalStorage(DISMISSED_BLOCK_STORAGE_KEY, [...dismissedBlocks, `${currentAccountId}_${key}`])
}

// export const dismissBlock = (key: string) => {
//     return new Promise((resolve, reject) => {
//         const dismissedBlocks = getFromLocalStorage(DISMISSED_BLOCK_STORAGE_KEY)
//         resolve(
//             setInLocalStorage(DISMISSED_BLOCK_STORAGE_KEY, [...dismissedBlocks, key])
//         )
//     })
    
// }


/**
 * Check in local storage if the block was already dismissed ?
 */
export const isBlockDismissed = (key: string) => {
    const { appData } = store.getState();
    const currentAccountId = appData.current_account_id
    const dismissedBlocks = getFromLocalStorage(DISMISSED_BLOCK_STORAGE_KEY)
    return dismissedBlocks.includes(`${currentAccountId}_${key}`)
}


export const updateDimissInfo = (block: string, currentAccountId: string, callback: ()=>void) => {
    let data = {
        pop_up: block
    }
    updateDismissPopup(currentAccountId as string, data)
        .then(() => {
            callback()
        })
        .catch((error) => {
            showError(error)
        })
}

export const GetCurrentUserContactId = () =>{
    const { currentUser } = useCurrentStore()
    const contactId = currentUser?.is_lead ? currentUser?.sf_lead_id : currentUser?.sf_contact_id
    return contactId
}
