import { toRem16 } from '../../../../styles/commonStyles'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    modalRoot: {
        '& .MuiPaper-root': {
            maxHeight: toRem16(640),
            '& .MuiDialogContent-root': {
                padding: 0,
            },
        },
    },
    TextCenter: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    taxInfoBanner: {
        display: 'flex',
        padding: '1rem',
        background: colorTheme => colorTheme.blue300,
        borderRadius: theme.spacing(0),
        margin: '1rem 0 1.5rem 0',
        '& .icon': {
            marginRight: '1rem',
        },
    },
    contentGrid: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 3),
        },
    },
    fieldContainer: {
        marginBottom: theme.spacing(4),
    },
    removeTextPointer: {
        cursor: 'pointer',
    },
    boldStyle: {
        'font-weight': 'bold',
        'font-size': `${toRem16(15)}`,
    },
    addSpouseTextPointer: {
        color: colorTheme =>  `${colorTheme.blue200} !important`,
        cursor: 'pointer',
        textDecorationColor: colorTheme =>  `${colorTheme.blue200} !important`,
    },
    okayBtn: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        color: colorTheme =>  `${colorTheme.white100} !important`,
        backgroundColor: colorTheme => `${colorTheme.primary} !important`,
    },
    signatureField: {
        '& .MuiOutlinedInput-input': {
            height: '.5rem',
            width: toRem16(330),
            fontFamily: 'Fasthand',
            fontSize: '1.5rem'
        }
    },
    flex: {
        display: 'flex',
    },
    titleText: {
        marginTop: `${toRem16(15)}`,
        marginLeft: `${toRem16(5)}`,
        width: `${toRem16(275)}`,
    },
    loaderStyle: {
       height: `${toRem16(400)}`
    },
    introScreenContent: {
        padding: theme.spacing(7),
    },
    introScreenHeader: {
        maxHeight: '.5rem'
    },
    contentSpacing: {
        margin: '1rem 0 1rem 0',
    },
    contentLayout: {
        padding: theme.spacing(7),
        paddingTop: '0px',
    },
    outlinedButton: {
        color: colorTheme => `${colorTheme.black200}`,
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    addButton: {
        color: colorTheme => `${colorTheme.blue200}`,
        border: 'none',
    },
    deleteSpouseInfo: {
        display: 'flex',
        '& .content': {
            textAlign: 'center',
        },
        '& .delete-info': {
            display: 'flex',
            marginLeft: toRem16(12),
            cursor: 'pointer',
            '& .remove-text': {
                marginLeft: toRem16(8),
                color: colorTheme => `${colorTheme.red200}`,
            },
        },
    },
}))

export const useHeaderStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        closeButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '0.5rem 0.5rem 0.5rem 0',
        },
        stickyHeader: {
            top: '0px',
            position: 'sticky',
            zIndex: 1000,
            background: colorTheme => colorTheme.white100
        },
        stickyHeaderNoOpacity: {
            top: '0px',
            position: 'sticky',
            zIndex: 1000,
            background: colorTheme => colorTheme.primaryWhite
        },
        modalHeader: {
            borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
            marginTop: '0.rem',
        },
        headerTitle: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        backButton: {
            margin: '0.5rem',
        },
        
    }
})