import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface UiSnackbarAlertPropsType {
    open: boolean;
    handleClose: any;
    message: string;
    actionButtonMessage?: string;
    actionButtonColor?: 'primary' | 'secondary';
    closeMessage?: string;
    severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
}

export default function UiSnackbarAlert({
    open,
    handleClose,
    message,
    actionButtonMessage,
    actionButtonColor,
    closeMessage,
    severity,
}: UiSnackbarAlertPropsType) {
    const close = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        handleClose(
            reason === 'close' || reason === 'timeout' ? '' : closeMessage
        );
    };

    return (
        <>
            {severity ? (
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={open}
                        autoHideDuration={4000}
                        onClose={close}
                    >
                        <Alert onClose={close} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar>
                </div>
            ) : (
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={open}
                        autoHideDuration={6000}
                        onClose={close}
                        message={message}
                        action={
                            <React.Fragment>
                                <Button
                                    color={actionButtonColor || 'primary'}
                                    size="small"
                                    onClick={close}
                                >
                                    {actionButtonMessage || 'Dismiss'}
                                </Button>
                            </React.Fragment>
                        }
                    />
                </div>
            )}
        </>
    );
}
