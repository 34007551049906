import { useMediaQuery, Theme } from '@material-ui/core'
import NewBankForm from './NewBankForm'
import UiDialog from '../../common/ui/UiDialog'
import Loader from '../../common/Loader'
const ManualAccountModal = ({
    openModal,
    handleClose,
    isNew = true,
    bankData,
    loadingData = false
}: any) => {
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    return (
        <div>
            <UiDialog
                open={openModal}
                handleClose={() => {handleClose(false)}}
                title={
                    isNew ? 'Create a Manual Bank Account' : 'Edit Bank Account'
                }
                size="sm"
                contentStyles={smDevice ? {} : { maxHeight: '600px' }}
                fullScreen={smDevice}
            >
                { loadingData ? <div style={{height: '600px'}}>
                    <Loader/>
                </div> : <NewBankForm isNew={isNew} bankData={bankData} handleClose={handleClose}/>}
            </UiDialog>
        </div>
    )
}

export default ManualAccountModal