import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    createFile,
    downloadReconciliationReportIntoPDF,
    getReconciliationReports,
    saveReconciliationReportToPortal,
} from '../../../../services/apiService/reports';
import { showError } from '../../../../services/formService';
import { currencyFormatter, isEmpty } from '../../../../utils/appUtil';
import { useCurrentStore } from '../../../common/hooks/useCurrentStore';
import { DOCUMENT_DOWNLOAD_SUCCESS, EMPTY_PARAMS, saveOptions, REPORTS_MENU_VIEWS } from '../constants/reports.const';
import {
    Account,
    Reconciliation,
    ReconciliationResponse,
} from '../models/reports-interface';
import { useReportsStyles } from '../styles/reports-styles';
import {
    createStyles,
    makeStyles,
    Theme,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import Loader from '../../../common/Loader';
import UiText from '../../../common/ui/UiText';
import { formatDateDDMMYY } from '../../../../utils/dateUtil';
import DocumentIcon from '../../../../assets/icons-svg/DocumentIcon.svg';
import SaveReports from '../utils/SaveReports/SaveReports';
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert';
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo';
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider';
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading';
import { resetReportsData } from '../../../../store/actions/reportsData';
import { LeftArrowIcon } from '../Icons/LeftArrowIcon';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../styles/models/Colors.interface';
interface QueryParams {
    accountIds: string;
    periodText: string;
    time: number;
    startDateYmd: string;
    endDateYmd: string;
}

const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        accountName: {
            margin: '1rem 0 2rem 0',
        },
        startBalanceHeader: {
            padding: '0 10rem 0 0 !important'
        },
        endBalanceHeader: {
            padding: '0 10rem 0 0 !important'
        },
        startBalanceValueCell: {
            padding: '0 10rem 0 0 !important'
        },
        endBalanceValueCell: {
            padding: '0 10rem 0 0 !important'
        },
    })
);
const TOOLTIP_TEXT =
    'The Reconciliations Report shows all reconciliations for the specified accounts during the selected period of time.';
    
export default function ReconciliationDetailedReport() {
    const query: QueryParams = useParams() as unknown as QueryParams;
    const { accountIds, periodText, time, startDateYmd, endDateYmd } = query;
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('');
    const currentStore = useCurrentStore();
    const accountId = currentStore.currentAccountId;
    const businessId = currentStore.currentBusinessId!;
    const businessName = currentStore.currentBusiness.name;
    const [reconciledTransactionsReport, setReconciledTransactionsReport] =
        useState<ReconciliationResponse>();
    const store = useStore().getState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme);
    const history = useHistory();
    const dispatch = useDispatch();
    const localClasses = useLocalStyles(colorTheme);
    const [logoImage, setLogoImage] = useState<string | undefined>('');
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false);
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    );
    const [downloadPDFUrl, setDownloadPDFUrl] = useState<string>('');
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);

    useEffect(() => {
        setActiveRouteHeading(' ');
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading]);
    useEffect(() => {
        const oldText = 'for the period from';
        const newText = 'From';
        const formmatedText = periodText
            .replaceAll('+', ' ')
            .replace(oldText, newText);
        setFormattedPeriodText(formmatedText);
    }, [periodText]);
    
    const getFormattedDateAndAccountIdParams = useCallback(() =>{
        const accountIdsParam =  accountIds === EMPTY_PARAMS.BANKS_ACC_IDS ? '' : accountIds;
        const startDateYmdParam =
                startDateYmd === EMPTY_PARAMS.START_DATE_YMD
                    ? ''
                    : startDateYmd;
            const endDateYmdParam =
                endDateYmd === EMPTY_PARAMS.END_DATE_YMD ? '' : endDateYmd;
                return {
                    accountIdsParam,
                    startDateYmdParam,
                    endDateYmdParam
                }
    },[accountIds, endDateYmd, startDateYmd])
    
    useEffect(() => {
        const fetchAPIParams = () => {
            const {accountIdsParam, startDateYmdParam, endDateYmdParam} = getFormattedDateAndAccountIdParams();
            return {
                accountIdsParam, startDateYmdParam, endDateYmdParam
            }
        };
        const fetchReconciliationReports = () => {
            const { accountIdsParam, startDateYmdParam, endDateYmdParam } =
                fetchAPIParams();

            getReconciliationReports(
                accountId,
                businessId,
                accountIdsParam,
                periodText,
                time,
                startDateYmdParam,
                endDateYmdParam
            )
                .then((result: unknown) => {
                    setIsLoading(false);
                    const reports = result as ReconciliationResponse;
                    setReconciledTransactionsReport(reports);
                })
                .catch((error) => {
                    setIsLoading(false);
                    showError(error.statusText);
                });
        };
        const reportsFromStore = store.reportsData.reports;
        const reports = isEmpty(reportsFromStore)
            ? fetchReconciliationReports()
            : reportsFromStore;
        setReconciledTransactionsReport(reports);
    }, [accountId, accountIds, businessId, endDateYmd, getFormattedDateAndAccountIdParams, periodText, startDateYmd, store.reportsData.reports, time]);
    
    const handleSaveToPortal = (
        data: any,
        fileType: string,
        closeModal: () => void
    ) => {
       const {
        accountIdsParam,
        startDateYmdParam,
        endDateYmdParam
       } = getFormattedDateAndAccountIdParams();
        const requestData = {
            comment: data.comment,
            period_text: periodText,
            start_date_ymd: startDateYmdParam,
            end_date_ymd: endDateYmdParam,
            time,
            account_ids: accountIdsParam,
        };

        saveReconciliationReportToPortal(accountId, businessId, requestData)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data;
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    );
                    closeModal();
                    setIsSaveSnackbar(true);
                });
            })
            .catch((error) => {
                showError(error?.statusText);
            });
    };
    const redirectToReconciliationBankReport = (
        reconciliationId: string
    ): string => {
        if (!reconciliationId) return '';
        return `/reports/reconcile/${reconciliationId}?fromReports=true`;
    };
    const generateReportsTable = (reports: Reconciliation[] | undefined) => {
        if (!reports) return;
        const reconciliationReports = reports;
        return (
            <Table className={reportsClasses.reportsTable}>
                <TableHead>
                    <TableRow>
                        <TableCell><UiText weight="semi_bold_600" variant="motorcycle_90">Reconciled On</UiText></TableCell>
                        <TableCell><UiText weight="semi_bold_600" variant="motorcycle_90">Start Date</UiText></TableCell>
                        <TableCell>{''}</TableCell>
                        <TableCell align="right" className={localClasses.startBalanceHeader}><UiText weight="semi_bold_600" variant="motorcycle_90">Start Balance</UiText></TableCell>
                        <TableCell><UiText weight="semi_bold_600" variant="motorcycle_90">End Date</UiText></TableCell>
                        <TableCell>{''}</TableCell>
                        <TableCell align="right" className={localClasses.endBalanceHeader}><UiText weight="semi_bold_600" variant="motorcycle_90">End Balance</UiText></TableCell>
                        <TableCell>{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reconciliationReports.map(
                        (report: Reconciliation, index: number) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {formatDateDDMMYY(report.submit_date)}
                                    </TableCell>
                                    <TableCell>
                                        {formatDateDDMMYY(report.start_date)}
                                    </TableCell>
                                    <TableCell>{''}</TableCell>
                                    <TableCell align="right" className={localClasses.startBalanceValueCell}>
                                        {currencyFormatter.format(
                                            parseFloat(report.start_balance)
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {formatDateDDMMYY(report.end_date)}
                                    </TableCell>
                                    <TableCell>{''}</TableCell>
                                    <TableCell align="right" className={localClasses.endBalanceValueCell}>
                                        {currencyFormatter.format(
                                            parseFloat(report.end_balance)
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={redirectToReconciliationBankReport(
                                                report.id
                                            )}
                                        >
                                            <img alt={`Edit`} src={DocumentIcon} />
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    )}
                </TableBody>
            </Table>
        );
    };
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false);
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath);
    };
    useEffect(() =>{
        const { accountIdsParam, startDateYmdParam, endDateYmdParam } =
        getFormattedDateAndAccountIdParams();
        const url =downloadReconciliationReportIntoPDF(
            accountId,
            businessId,
            startDateYmdParam,
            endDateYmdParam,
            periodText,
            time,
            accountIdsParam
        )
        setDownloadPDFUrl(url);
    },[accountId, businessId, getFormattedDateAndAccountIdParams, periodText, time])

    return (
        <div className={reportsClasses.reportDetailsContainer}>
                <ActiveRouterHeadingSection tooltipText={TOOLTIP_TEXT} headingText={'Reconciliations'}/> 
                <div className={reportsClasses.reportsCommonHeader}>
                    <div className="backLinkAndBtn">
                        <Link data-cy='generate-new-report-btn' to="/reports?name=reconciliations">
                        <LeftArrowIcon color={colorTheme.blue200} />
                            <span>Generate New Report</span>
                        </Link>
                        <SaveReports
                            businessName={
                                reconciledTransactionsReport?.business.name ??
                                ''
                            }
                            downloadReportPDFUrl = {downloadPDFUrl}
                            options={saveOptions.slice(0, 2)}
                            handleSaveToPortal={handleSaveToPortal}
                            reportName={REPORTS_MENU_VIEWS.RECONCILIATIONS}
                            isDisabled={!reconciledTransactionsReport?.accounts.length}
                        />
                    </div>
                    <div className="headerTextAndBusinessNameLogo">
                        <div>
                            <h2>Reconciliations</h2>
                            <span>{formattedPeriodText}</span>
                        </div>
                        <BusinessNameAndLogo
                            logoFromApi={logoImage}
                            accountId={accountId}
                            businessName={businessName!}
                        />
                    </div>
                </div>
            
            {isLoading ? (
                <Loader />
            ) : (
                <div className={reportsClasses.reportDetailsContent}>
                    {reconciledTransactionsReport?.accounts.map(
                        (account: Account, index: number) => {
                            return (
                                <div key={index}>
                                    <UiText
                                        weight="medium_500"
                                        variant="hatchback_125"
                                        typographyVariant="h3"
                                        className={localClasses.accountName}
                                    >
                                        {account.title}
                                    </UiText>
                                    {generateReportsTable(
                                        account.reconciliations
                                    )}
                                </div>
                            );
                        }
                    )}
                </div>
            )}
            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage="View Document"
                closeMessage="view"
            />
        </div>
    );
}
