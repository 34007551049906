import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Box,
    Button,
    createStyles,
    Divider,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    makeStyles,
    OutlinedInput,
    SnackbarContent,
    TablePagination,
    Theme,
    Tooltip,
    Typography,
    Checkbox,
    Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import Loader from '../../common/Loader';
import WhiteDeleteIcon from '../../../assets/icons-svg/WhiteDelete.svg';
import {
    deleteMultipleJournals,
    getAccount,
    getJournal,
    deleteJournalById,
} from '../../../services/journalServices';
import { ApplicationStore } from '../../../models/store';
import { useHistory, useLocation } from 'react-router-dom';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import JournalList from './JournalList';
import NewJournalModal from './NewJournalModal';
import JournalTread from './JournalTread';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import { useJournalState } from './JournalProvider';
import { FilterList, Search, Cancel } from '@material-ui/icons';
import { JournalQueryObject } from '../../../models/journalEntry-models';
import moment from 'moment';
import JournalFilter from './JournalFilter';
import { jsonToRql, rqlToJson } from '../../../utils/rql';
import { currencyFormatter } from '../../../utils/appUtil';
import InfoTooltip from '../../common/InfoTooltip'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const filterDefaultValues: JournalQueryObject = {
    category_id: '',
    from_date: moment().endOf('day').unix(),
    to_date: moment().endOf('day').unix(),
    period: 'all',
    amount: { value: '', operator: 'eq' },
    number: '',
};

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        snackbar: {
            position: 'absolute',
            top: '0rem',
            zIndex: 1110,
            left: '240px',
            width: 'calc(100% - 240px)',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            '& .MuiSnackbarContent-message': {
                padding: '8px 35px',
            },
            '& .MuiSnackbarContent-root': {
                borderRadius: 0,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                left: 0,
            },
        },
        content: {
            display: 'flex',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                padding: 0,
            },
            padding: theme.spacing(1),
            '& .MuiContainer-root': {
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                },
            },
        },
        listRoot: {
            maxWidth: '464px',
            paddingBottom: theme.spacing(3),
            width: '100%',
        },
        newMessageButton: {
            height: '36px',
        },
        refeshButton: {
            fontSize: theme.spacing(3),
        },
        searchInput: {
            height: '40px',
            width: '100%',
            '&.Mui-focused fieldset': {
                border: '1px solid black !important',
            },
        },
        close: {
            padding: theme.spacing(0.5),
            position: 'absolute',
            left: '0px',
            top: 0,
            [theme.breakpoints.down('sm')]: {
                top: 0,
            },
        },
        buttonGap: {
            marginRight: theme.spacing(2),
        },
        selectButton: {
            color: (colorTheme) => colorTheme.primaryWhite,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '15px',
            lineHeight: '16px',
            letterSpacing: '0.75px',
            borderColor: (colorTheme) => colorTheme.primaryWhite,
        },
        dividerStyle: {
            marginTop: theme.spacing(4),
        },
        mobileHeader: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
        },
        customWidth: {
            maxWidth: 256,
            background: (colorTheme) => colorTheme.secondary,
            borderRadius: '4px',
            padding: '12px 16px',
            width: '288px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
        },
        divider: {
            width: '100%',
            marginBottom: '1rem',
        },
        deleteEntry: {
            background: (colorTheme) => colorTheme.red400,
            borderRadius: '4px',
        },
        pagination: {
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 2),
            },
        },
        deleteIcon: {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        search: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 2),
            },
        },
        deleteModal: {
            '& div:first-child': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '28px',
                letterSpacing: '0.15px',
                color: (colorTheme) => colorTheme.black100,
                padding: '0.5rem 0rem',
            },
            '& div': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                color: (colorTheme) => colorTheme.black100,
                padding: '0.3rem 0rem',
            },
        },
        filterButton: {
            marginLeft: 15,
            height: 40,
        },
    })
);

const Journal = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const [selectedChekboxData, setSelectedChekboxData] = useState({});
    const [selectAll, setselectAll] = useState(false);
    const [openModal, setopenModal] = useState<boolean>(false);
    const history = useHistory();
    const {
        listData,
        setListData,
        selectedData,
        setSelectedData,
    } = useJournalState();
    const [showSnackbar, setshowSnackbar] = useState({
        show: false,
        data: {},
    });

    const [deleteSingleEntry, setdeleteSingleEntry] = useState<null | string>(
        null
    );
    const [isEdit, setisEdit] = useState(false);
    // alert state
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const {
        setActiveRouteHeading,
        setActiveRouteHeadingIcon,
        activeRouteHeadingIcon,
    } = useContext(ActiveRoutingContext);

    // filters
    const [match_text, setTerm] = useState('');
    const [queryObject, setQueryObject] = useState<JournalQueryObject>(
        filterDefaultValues
    );
    const [journalFilterPopupState, setJournalFilterPopupState] = useState(
        false
    );
    const query = new URLSearchParams(useLocation().search);
    const searchQuery = query.get('search');
    const pageQuery = query.get('page');
    const [chipsToDisplay, setChipsToDisplay] = useState<
        {
            label: string;
            searchKey:
                | 'period'
                | 'amount'
                | 'match_text'
                | 'number'
                | 'category_id';
        }[]
    >([]);

    const handleModalCLose = () => {
        setopenModal(false);
        setisEdit(false);
    };

    const getJournalData = useCallback(
        (query?: any, keepSelectedData?: boolean): void => {
            setListData((prev: any) => ({
                ...prev,
                loading: true,
                page: query.page,
            }));
            getJournal(
                props.appData.current_account_id,
                props.appData.current_business_id,
                query
            )
                .then((res: any) => {
                    setListData((prev: any) => ({
                        ...prev,
                        loading: false,
                        data: res?.items,
                        totalItem: res?.itemsCount,
                    }));
                    if (!keepSelectedData) {
                        setSelectedData((prev: any) => res?.items[0] || null);
                    } else if (res?.items?.length === 1) {
                        setSelectedData((prev: any) => res?.items[0]);
                    }
                })
                .catch((err) => {
                    setListData((prev: any) => ({ ...prev, loading: false }));
                });
        },
        [] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const getAccountdata = useCallback((): void => {
        setListData((prev: any) => ({ ...prev, loadingAccountRecord: true }));
        getAccount(
            props.appData.current_account_id,
            props.appData.current_business_id
        )
            .then((res: any) => {
                const parentCategories = {};
                const cache = {};
                res.forEach((caty: any) => {
                    if (caty.can_have_children) {
                        //@ts-ignore
                        parentCategories[caty?.id] = caty;
                    }
                    //@ts-ignore
                    cache[caty?.id] = caty;
                });
                setListData((prev: any) => ({
                    ...prev,
                    accountRecord: cache,
                    accountParentList: parentCategories,
                    loadingAccountRecord: false,
                }));
            })
            .catch((err) => {
                setListData((prev: any) => ({ ...prev, loading: false }));
            });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAccountdata();
    }, [getAccountdata]);

    useEffect(() => {
        if (searchQuery) {
            const searchJson = rqlToJson<
                JournalQueryObject & {
                    search: string;
                    match_text: string;
                    page: any;
                }
            >(
                searchQuery + (pageQuery ? `&page=${pageQuery}` : ''),
                {
                    search: searchQuery,
                    match_text: '',
                    from_date: filterDefaultValues.from_date,
                    to_date: filterDefaultValues.to_date,
                    amount: { value: '', operator: 'eq' },
                    period: 'all',
                    number: '',
                    category_id: '',
                    page: 1,
                },
                ['number']
            );
            const {
                match_text,
                from_date,
                to_date,
                period,
                amount,
                number,
                category_id,
                page,
            } = searchJson;

            setQueryObject((prev) => ({
                from_date: from_date,
                to_date: to_date,
                period,
                amount,
                category_id,
                number,
            }));

            setTerm((prev) => match_text);
            getJournalData({
                page,
                perPage: listData.perPage,
                match_text,
                ...buildQueryObject({
                    from_date,
                    to_date,
                    period,
                    amount,
                    number,
                    category_id,
                }),
            });
        } else if (pageQuery) {
            getJournalData({ page: pageQuery, perPage: listData.perPage });
        } else {
            setTerm(() => '');
            setChipsToDisplay([]);
            getJournalData({ page: 1,  perPage: listData.perPage });
            setQueryObject(filterDefaultValues);
        }
    }, [getJournalData, searchQuery, pageQuery]);

    useEffect(() => {
        const searchJson = rqlToJson<
            JournalQueryObject & {
                search: string;
                match_text: string;
            }
        >(
            searchQuery || '',
            {
                search: searchQuery || '',
                match_text: '',
                from_date: 0,
                to_date: 0,
                amount: { value: '', operator: 'eq' },
                period: 'all',
                number: '',
                category_id: '',
            },
            ['number']
        );
        handleChipsData(searchJson, listData.accountRecord);
    }, [searchQuery, listData.accountRecord]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setActiveRouteHeading('Journal Entries');
        setActiveRouteHeadingIcon(
            <InfoTooltip tooltipText="A journal entry is a record that describes a financial transaction, 
            such as the reclassification of expenses, or recording a non-cash expense like the depreciation of assets."/>
        );
        return () => {
            setActiveRouteHeadingIcon(null);
        };
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const handleChipsData = useCallback(
        (
            searchJson: JournalQueryObject & {
                match_text: string;
            },
            caregories
        ) => {
            setChipsToDisplay(
                Object.keys(searchJson).reduce(
                    (
                        chips: {
                            label: string;
                            searchKey:
                                | 'period'
                                | 'amount'
                                | 'match_text'
                                | 'number'
                                | 'category_id';
                        }[],
                        key
                    ) => {
                        switch (key) {
                            case 'period':
                                // If custom period is selected the date range else period
                                if (searchJson.period === 'custom') {
                                    chips.push({
                                        label: `Date: ${moment(
                                            searchJson.from_date * 1000
                                        ).format('DD MMM, YYYY')} - ${moment(
                                            searchJson.to_date * 1000
                                        ).format('DD MMM, YYYY')}`,
                                        searchKey: 'period',
                                    });
                                } else {
                                    searchJson.period !== 'all' &&
                                        chips.push({
                                            label: `Date: ${
                                                props.journalFiltersConfig
                                                    .periods[searchJson.period]
                                            }`,
                                            searchKey: 'period',
                                        });
                                }
                                return chips;
                            case 'number':
                                searchJson.number !== '' &&
                                    chips.push({
                                        label: `Ref# equals: ${searchJson.number}`,
                                        searchKey: 'number',
                                    });
                                return chips;
                            case 'amount':
                                searchJson.amount.value !== '' &&
                                    chips.push({
                                        label: `Amount ${
                                            props.journalFiltersConfig.amount
                                                .shortOperators[
                                                searchJson.amount.operator
                                            ]
                                        } ${currencyFormatter.format(
                                            parseFloat(searchJson.amount.value)
                                        )}`,
                                        searchKey: 'amount',
                                    });
                                return chips;
                            case 'match_text':
                                searchJson.match_text &&
                                    chips.push({
                                        label: `Search by: ${searchJson.match_text}`,
                                        searchKey: 'match_text',
                                    });
                                return chips;
                            case 'category_id':
                                searchJson.category_id &&
                                    chips.push({
                                        label: `Category: ${
                                            caregories?.[searchJson.category_id]
                                                ?.title || ''
                                        }`,
                                        searchKey: 'category_id',
                                    });
                                return chips;
                        }
                        return chips;
                    },
                    []
                )
            );
        },
        [props.journalFiltersConfig.periods, listData.accountRecord] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleModalOpen = () => {
        setopenModal(true);
    };

    const handleApplyJournalFilter = (
        result: JournalQueryObject | boolean,
        page = 1,
        searchTerm = match_text
    ) => {
        if (result && typeof result === 'object') {
            setQueryObject(result);
            const {
                from_date,
                to_date,
                amount,
                period,
                number,
                category_id,
            } = result;
            const rql = jsonToRql({
                ...(period === 'all'
                    ? null
                    : { from_date: from_date, to_date: to_date }),
                match_text: searchTerm,
                period: period === 'all' ? null : period,
                amount: amount.value !== '' ? amount : null,
                number,
                category_id,
            });
            if (rql) {
                history.push(
                    `/journal?search=${encodeURIComponent(rql)}&page=${page}`
                );
            } else {
                history.push(`/journal?page=${page}`);
            }
        }
        setJournalFilterPopupState(false);
    };

    const handleReloadCurrentState = (
        keepSelectedData?: boolean,
        perPage = listData.perPage
    ) => {
        if (searchQuery) {
            getJournalData(
                {
                    page: listData.page,
                    perPage: perPage,
                    match_text,
                    ...buildQueryObject(queryObject),
                },
                keepSelectedData
            );
        } else if (pageQuery) {
            getJournalData(
                { page: pageQuery, perPage: perPage },
                keepSelectedData
            );
        } else {
            getJournalData({ page: 1, perPage: perPage }, keepSelectedData);
        }
    };

    const handleDeleteFilter = (
        searchKey:
            | 'period'
            | 'amount'
            | 'match_text'
            | 'number'
            | 'category_id',
        index: number
    ) => {
        chipsToDisplay.splice(index, 1);
        setChipsToDisplay(chipsToDisplay);

        let jsonToConvert: JournalQueryObject;
        let matchText = match_text;

        if (searchKey === 'period') {
            jsonToConvert = {
                ...queryObject,
                period: filterDefaultValues.period,
                from_date: filterDefaultValues.from_date,
                to_date: filterDefaultValues.to_date,
            };
        } else if (searchKey === 'match_text') {
            jsonToConvert = { ...queryObject };
            matchText = '';
            setTerm('');
        } else {
            jsonToConvert = {
                ...queryObject,
                [searchKey]:
                    filterDefaultValues[searchKey as keyof JournalQueryObject],
            };
        }

        handleApplyJournalFilter(jsonToConvert, 1, matchText);
    };

    const onSelectHandle = (selected: any) => {
        setSelectedData(selected);
    };

    const checkBoxHandle = (e: any, selected: any, id: string) => {
        let updatedData = selectedChekboxData;
        //@ts-ignore
        if (!selectedChekboxData[id]) {
            const newData = { [id]: selected };
            updatedData = { ...updatedData, ...newData };
            setSelectedChekboxData(updatedData);
        } else {
            // @ts-ignore
            delete updatedData[id];
            setSelectedChekboxData(updatedData);
        }
        setshowSnackbar({ show: true, data: updatedData });
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        handleApplyJournalFilter(queryObject, newPage + 1);
        setshowSnackbar({ show: false, data: {} });
        setSelectedChekboxData((prev) => ({}));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setListData((prev: any) => ({
            ...prev,
            perPage: parseInt(event.target.value, 10),
        }));
        if (listData.page > 1) {
            handleApplyJournalFilter(queryObject);
        } else {
            handleReloadCurrentState(undefined, event.target.value);
        }
    };

    const handleClose = (
        event: React.SyntheticEvent | MouseEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setshowSnackbar({ show: false, data: {} });
        setSelectedChekboxData((prev) => ({}));
    };

    const allSelectHandle = (selectAll = true) => {
        if (selectAll) {
            let cache = {};
            listData.data.forEach((item: any) => {
                //@ts-ignore
                cache[item?.id] = item;
            });
            setshowSnackbar((prev) => ({ ...prev, data: cache }));
            setSelectedChekboxData(cache);
            setselectAll(true);
        } else {
            setshowSnackbar((prev) => ({ ...prev, data: {} }));
            setSelectedChekboxData({});
            setselectAll(false);
        }
    };

    const deleteHandle = () => {
        const postData = {
            deleted: true,
            filters: { id: Object.keys(selectedChekboxData) },
        };
        deleteMultipleJournals(
            props.appData.current_account_id,
            props.appData.current_business_id,
            postData
        ).then((res) => {
            setshowDeleteModal(false);
            setshowSnackbar({ show: false, data: {} });
            setSelectedChekboxData((prev) => ({}));
            setSelectedData(null);
            handleReloadCurrentState();
        });
    };

    const openDeleteModal = (id?: string) => {
        if (id) setdeleteSingleEntry(id);
        setshowDeleteModal(true);
    };

    const deleteHandleOption = (id?: any) => {
        if (id) {
            deleteJournalById(
                props.appData.current_account_id,
                props.appData.current_business_id,
                id
            )
                .then((res) => {
                    setshowDeleteModal(false);
                    history.push('/journal');
                    setisEdit(false);
                    setopenModal(false);
                })
                .catch((err) => {
                    setshowDeleteModal(false);
                });
        } else {
            deleteHandle();
        }
    };

    const openEditModal = (data: any) => {
        setisEdit(true);
        setopenModal(true);
        setSelectedData(data);
    };

    const buildQueryObject = (query: JournalQueryObject) => {
        let { amount, period, from_date, to_date, ...queryObj } = query as any;
        switch (query.amount.operator) {
            case 'eq':
                queryObj.amount = query.amount.value;
                break;
            case 'gt':
                queryObj.min_amount = query.amount.value;
                break;
            case 'lt':
                queryObj.max_amount = query.amount.value;
                break;
        }

        return {
            ...(period === 'all' ? (null as any) : { from_date, to_date }),
            ...queryObj,
        };
    };

    const action = (
        <>
            <Box>
                <IconButton
                    aria-label='close'
                    color='inherit'
                    className={classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Hidden smDown>
                    <Button
                        startIcon={<CheckIcon />}
                        variant='outlined'
                        classes={{
                            root: classes.buttonGap,
                        }}
                        className={classes.selectButton}
                        onClick={() => allSelectHandle()}
                    >
                        Select All
                    </Button>
                    <Button
                        className={classes.selectButton}
                        variant='outlined'
                        startIcon={
                            <img alt={`Private`} src={WhiteDeleteIcon} />
                        }
                        onClick={() => openDeleteModal()}
                    >
                        Delete
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        aria-label='delete'
                        color='inherit'
                        className={classes.deleteIcon}
                        onClick={() => openDeleteModal()}
                    >
                        <img alt={`Private`} src={WhiteDeleteIcon} />
                    </IconButton>
                </Hidden>
            </Box>
        </>
    );

    const FilterControls = (
        <Grid container>
            <Grid
                item
                container
                direction='row'
                justify='space-between'
                alignItems='center'
                xs={12}
                className={classes.search}
            >
                <Hidden only={false ? ['xs', 'sm'] : []}>
                    <Button
                        className={classes.newMessageButton}
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={handleModalOpen}
                    >
                        Journal Entry
                    </Button>
                </Hidden>
            </Grid>
            <Grid item xs={12} className={classes.search}>
                <Box display='flex'>
                    <OutlinedInput
                        notched={false}
                        onChange={(event) => setTerm(event.target.value)}
                        value={match_text}
                        fullWidth
                        classes={{
                            root: classes.searchInput,
                        }}
                        placeholder='Search'
                        startAdornment={
                            <InputAdornment position='start'>
                                <Search />
                            </InputAdornment>
                        }
                        endAdornment={
                            match_text ? (
                                <InputAdornment
                                    position='end'
                                    onClick={() => {
                                        setTerm('');
                                    }}
                                >
                                    <Cancel
                                        color='secondary'
                                        className='cursor-pointer'
                                    />
                                </InputAdornment>
                            ) : null
                        }
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleApplyJournalFilter(
                                    {
                                        ...queryObject,
                                    },
                                    1,
                                    match_text
                                );
                            }
                        }}
                    />
                    <Hidden smDown>
                        <Button
                            startIcon={<FilterList />}
                            variant='outlined'
                            color='secondary'
                            className={classes.filterButton}
                            onClick={() => setJournalFilterPopupState(true)}
                        >
                            Filters
                        </Button>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color='secondary'
                            className={classes.filterButton}
                            onClick={() => setJournalFilterPopupState(true)}
                        >
                            <FilterList />
                        </IconButton>
                    </Hidden>
                </Box>
                {chipsToDisplay?.length ? (
                    <Box mt={2}>
                        {chipsToDisplay.map((chip, index) => (
                            <Chip
                                key={index}
                                label={chip.label}
                                style={{
                                    marginRight: '8px',
                                    marginBottom: '8px',
                                }}
                                onDelete={() =>
                                    handleDeleteFilter(chip.searchKey, index)
                                }
                            />
                        ))}
                    </Box>
                ) : null}
            </Grid>
            <JournalFilter
                open={journalFilterPopupState}
                handleClose={handleApplyJournalFilter}
                queryObject={queryObject}
                dataSource={listData}
            />
            <Grid item xs={12}>
                <Hidden smDown>
                    <Divider
                        className={classes.dividerStyle}
                        variant='fullWidth'
                    ></Divider>
                </Hidden>
            </Grid>
        </Grid>
    );

    return (
            <Grid container direction="row" style={{ height: '100%' }}>
                <UiConfirmationDialog
                    open={showDeleteModal}
                    message={
                        <>
                            <Typography
                                variant='body1'
                                className={classes.deleteModal}
                                gutterBottom
                            >
                                <div>
                                    Delete{' '}
                                    {Object.keys(selectedChekboxData).length}{' '}
                                    Journal Entries
                                </div>
                                <div>
                                    Do you want to delete the selected journal
                                    entries?
                                </div>
                                <div>This cannot be undone.</div>
                            </Typography>
                        </>
                    }
                    handleClose={() => {
                        setshowDeleteModal(false);
                    }}
                    confirmNode={
                        <Button
                            className={classes.deleteEntry}
                            variant='contained'
                            startIcon={
                                <img alt={`Private`} src={WhiteDeleteIcon} />
                            }
                            onClick={() => {
                                deleteHandleOption(deleteSingleEntry);
                            }}
                            color='secondary'
                        >
                            Delete Entries
                        </Button>
                    }
                    cancelButtonText='Cancel'
                />

                <NewJournalModal
                    open={openModal}
                    handleClose={() => handleModalCLose()}
                    datasource={listData}
                    getJournalEntry={handleReloadCurrentState}
                    setListData={setListData}
                    isEdit={isEdit}
                    deleteData={{
                        showDeleteModal,
                        deleteHandleOption: openDeleteModal,
                    }}
                    selectedData={selectedData}
                />
                <Hidden implementation='js' mdUp>
                    <Box className={classes.mobileHeader}>
                        <Typography variant='h6'>Journal Entries</Typography>
                        {activeRouteHeadingIcon}
                    </Box>
                    <div className={classes.divider}>
                        <Divider variant='fullWidth'></Divider>
                    </div>
                </Hidden>
                <Grid
                    item
                    md={5}
                    sm={12}
                    justify='flex-start'
                    alignItems='flex-start'
                    className={
                        classes.content
                    }
                >
                    <Grid
                        container
                        item
                        alignItems='flex-start'
                        className={classes.listRoot}
                        style={listData.loading || listData.loadingAccountRecord? { height: '100%' } : {}}
                    >
                        {Object.keys(selectedChekboxData).length < 2 &&
                            FilterControls}
                        {listData.loading || listData.loadingAccountRecord ? (
                            <Grid
                                container
                                direction='column'
                                justify='center'
                                alignItems='center'
                                style={{ flex: 1 }}
                            >
                                <Loader />
                            </Grid>
                        ) : (
                            <>
                                <Hidden mdUp>
                                    {Object.keys(selectedChekboxData).length >
                                        0 && (
                                        <Grid
                                            container
                                            direction='column'
                                            justify='flex-start'
                                            alignItems='flex-start'
                                            style={{
                                                padding: '10px',
                                                borderBottom:
                                                    '1px solid rgba(18, 23, 36, 0.2)', // to do
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                allSelectHandle();
                                                            } else {
                                                                allSelectHandle(
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                        name='select-all'
                                                        size='small'
                                                        checked={selectAll}
                                                    />
                                                }
                                                label='Select All'
                                            />
                                        </Grid>
                                    )}
                                </Hidden>

                                <TablePagination
                                    component='div'
                                    labelRowsPerPage='Show:'
                                    count={listData.totalItem}
                                    page={listData.page - 1}
                                    className={classes.pagination}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={listData.perPage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                                <JournalList
                                    items={listData.data}
                                    personalAccountId={
                                        props.appData.personal_account.id
                                    }
                                    showSnackbar={showSnackbar}
                                    onSelect={onSelectHandle}
                                    selectedData={selectedData}
                                    selectedChekboxData={selectedChekboxData}
                                    checkBoxHandle={checkBoxHandle}
                                    raw={listData}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>

                <Hidden smDown implementation='js'>
                    <Grid
                        sm={1}
                        item
                        container
                        direction='row'
                        justify='center'
                        alignItems='stretch'
                    >
                        <Divider orientation='vertical' flexItem />
                    </Grid>
                    <Grid item sm={6}>
                        {selectedData ? (
                            <JournalTread
                                raw={listData.accountRecord}
                                getAccountdata={getAccountdata}
                                selectMessage={selectedData}
                                deleteData={{
                                    showDeleteModal,
                                    deleteHandleOption: openDeleteModal,
                                }}
                                openEditModal={openEditModal}
                                getJournalEntry={handleReloadCurrentState}
                            />
                        ) : (
                            <Grid
                                container
                                direction='column'
                                justify='center'
                                alignItems='center'
                                style={{ height: '100%' }}
                            >
                                <Typography variant='subtitle1'>
                                    Select a Journal to see the thread
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Hidden>
            </Grid>

    );
};

const mapStateToProps = ({ appData, config }: ApplicationStore) => ({
    appData: appData,
    journalFiltersConfig: config.local.filters,
});
export default connect(mapStateToProps)(Journal);