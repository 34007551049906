import { useHistory } from 'react-router-dom'
import TOSFlowHeader from './TOSFlowHeader'
import UiText from '../../../common/ui/UiText'
import LinkButton from '../../../common/LinkButton'
import thumbsUp from '../../../../assets/animations/ThumbsUp.json'
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import BusinessSelectSection from './BusinessSelectSection'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    businessDetailsContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '3rem',
        '& > div': {
            textAlign: 'center',
        },
    },
    successContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerContainer: {
        marginBottom: '1.5rem',
    },
    title: {
        marginBottom: '2rem',
    },
    pageTitle: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.5rem',
        },
    },    
    dashboardRedirectButton: {
        textAlign: 'center',
        marginTop: '1.5rem',
        '& button': {
            padding: '0.053rem 0'
        }
    }
}))

const PlaidSuccessPage = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const history = useHistory()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    return (
        <div className={classes.successContainer}>
            <div className={classes.footerContainer}>
                <TOSFlowHeader
                    icon={thumbsUp}
                    headerText="Your payroll is good to go!"
                >
                    <>
                        <UiText className={classes.pageTitle}>
                            We’ll let you know if we need {smDevice && <br />} anything else from you.
                        </UiText>
                        <UiText>You can close this window.</UiText>
                    </>
                </TOSFlowHeader>
                <div className={classes.dashboardRedirectButton}>
                    <LinkButton onClick={() => history.push('/dashboard')}>
                        <UiText weight="semi_bold_600">
                            Go to my Dashboard
                        </UiText>
                    </LinkButton>
                </div>
            </div>

            <div className={classes.businessDetailsContainer}>
                <div>            
                    <BusinessSelectSection successPage={true} />
                </div>
            </div>
        </div>
    )
}

export default PlaidSuccessPage
