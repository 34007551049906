import { CurrentUser, PartnerDetails } from '../models/account';
import { getAuthData } from './authService';
import { getFromLocalStorage, setInLocalStorage } from './localStorageService';

export const getInitData = () => {
  let data: any = {};
  data.accounts = getFromLocalStorage('accounts') || [];
  data.user = getFromLocalStorage('user') || {};
  data.accountant = getFromLocalStorage('accountant') || null;
  data.current_account_id = getFromLocalStorage('current_account_id') || '';
  data.current_business_id = getFromLocalStorage('current_business_id') || '';
  data.personal_account = data.accounts.find(
    (acc: any) => acc.account_type === 'personal'
  );
  data.current_account = data.accounts.find(
    (acc: any) => acc.id === data.current_account_id
  );
  data.current_business = data.current_account?.business;

  return data;
};

const checkPartnerDetailsAndRedirect = (partnerDetails : PartnerDetails | null) =>{
  if(!partnerDetails || !partnerDetails?.domain_url) return;
  const currentUrl = window.location.hostname;
  const partnerDomainUrl = partnerDetails?.domain_url
  if(currentUrl === partnerDomainUrl) return;
  const authDetails = getAuthData();
  const accessToken = authDetails.access_token;
  const expiresIn = authDetails.expires_in;
  const partnerURL = "https://" + partnerDomainUrl +  `/portal/#/?oauth&state=undefined&access_token=${accessToken}&expires_in=${expiresIn}`;
  window.location.href = partnerURL;
}
export const setUserData = (user: CurrentUser) => {
  setInLocalStorage('user', user);
  checkPartnerDetailsAndRedirect(user.partner_details);
};

export const setAccountantData = (accountant: any) => {
  setInLocalStorage('accountant', accountant);
};

export const setAccountsData = (accounts: [], currentAccountId: string = getFromLocalStorage('current_account_id')) => {
  let account_list: any = [];
  let accountWithBusiness;
  accounts.forEach((acc_data: any) => {
    let account = acc_data.account;
    account.business = acc_data.business;
    account.access_rights = acc_data.access_rights;
    account.has_archived_business = acc_data.has_archived_business;
    account.mc_only = acc_data.mc_only || false;
    account_list.push(account);
  });
  if (currentAccountId) {
    accountWithBusiness = account_list.find(
      (account: any) =>
        account.account_type === 'business' &&
        account.id === currentAccountId
    );
  }
  if (!accountWithBusiness) {
    accountWithBusiness = account_list.find(
      (account: any) => account.account_type === 'business' && account.business
    );
  }
  const current_account = accountWithBusiness || account_list[1];
  const current_business = current_account.business;
  setInLocalStorage('accounts', account_list);
  setInLocalStorage('current_account_id', current_account.id);
  setInLocalStorage('current_business_id', current_business?.id || '');
};

// currently not in use
export const setCurrent = (account: any) => {
  // const prevAccountId = getFromLocalStorage('current_account_id');
  // AccountService.data.current_id = typeof account !== 'object' ? account : account.id;

  setInLocalStorage(
    'current_account_id',
    typeof account !== 'object' ? account : account.id
  );
  setInLocalStorage('current_business_id', account.business?.id);
  // $rootScope.$broadcast('ACCOUNT_CHANGE');

  // AccountService._applyBinds('current_id');
  // $storage.set('opened_folders', {});
  setInLocalStorage('opened_folders', {});

  // if (
  //   prevAccountId !== AccountService.data.current_id &&
  //   !!getFromLocalStorage('access_token')
  // ) {
  //   CacheService.clear();
  //   if (
  //     $location.path() === (redirectTo ? redirectTo : '/dashboard') &&
  //     !redirectOptions
  //   ) {
  //     $state.reload();
  //   } else {
  //     $location
  //       .path(redirectTo ? redirectTo : '/dashboard')
  //       .search(redirectOptions || {});
  //   }
  // } else if (forceRedirect) {
  //   if (
  //     $location.path() === (redirectTo ? redirectTo : '/dashboard') &&
  //     !redirectOptions
  //   ) {
  //     $state.reload();
  //   } else {
  //     $location
  //       .path(redirectTo ? redirectTo : '/dashboard')
  //       .search(redirectOptions || {});
  //   }
  // } else if (redirectTo) {
  //   $location
  //     .path(redirectTo ? redirectTo : '/dashboard')
  //     .search(redirectOptions || {});
  // }
};
