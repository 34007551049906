import { EmpData } from '../../../models/payroll/employee'
export const employeeAlerts = {
    missingJobAndCompensations: {
        header: 'Missing Job & Compensations',
        description:
            'Before you can request input from an employee you must first add a job and its compensation.',
    },
    missingCompensations: {
        header: 'Missing Compensations',
        description:
            'Before you can request input from an employee, you must first add the compensation for the job.',
    },
    missingEmployeeInformation: {
        header: 'Missing Employee Information',
        description:
            'Send an Employee Input Request in order to allow the employee to complete their portion of the payroll setup process.',
    },
    waitingOnEmployee: {
        header: 'Missing Employee Email & Birthdate',
        description:
            "Before you can request input please update the employee's email & birthdate",
    },
    linkExpired: {
        header: 'Employee Link has Expired',
        description:
            'The employee link expired before the employee completed their portion of the payroll setup process. You will need to send a new link.',
    },
}

export const employeeStatusCheck = (employee: EmpData | undefined) => {
    const hasEmployeeJob = employee?.jobs?.length
    let isJobAndCompensationMissing = !hasEmployeeJob,
        isCompensationsMissing =
            hasEmployeeJob && !employee?.jobs?.[0]?.compensations?.length,
        isMissedEmployeeInformation = !employee?.onboarded,
        isBirthdateMissing = !employee?.date_of_birth

    return [
        isBirthdateMissing,
        isJobAndCompensationMissing,
        isCompensationsMissing,
        isMissedEmployeeInformation,
    ]
}

export const getAlertInfoText = (employee: EmpData | undefined) => {
    let [
        isBirthdateMissing,
        isJobAndCompensationMissing,
        isCompensationsMissing,
        isMissedEmployeeInformation,
    ] = employeeStatusCheck(employee)
    if (isBirthdateMissing) {
        return employeeAlerts.waitingOnEmployee
    }
    if (isJobAndCompensationMissing)
        return employeeAlerts.missingJobAndCompensations
    if (isCompensationsMissing) return employeeAlerts.missingCompensations
    if (isMissedEmployeeInformation)
        return employeeAlerts.missingEmployeeInformation
}
