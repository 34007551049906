import {
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const customStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    plusMinusController: {
        position: 'relative',
        height: '46px !important',
        width: '46px',
        cursor: 'pointer',
    },
    triangleBg: {
        height: '100%',
        width: '100%',
        display: 'block',
        position: 'relative',
        opacity: '0.16',
        top: '-7%',
        transformOrigin: '41% 53%',
        '&.negative': {
            transform: 'rotate(180deg)',
        },

        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            top: '15%',
            width: '72%',
            height: '72%',
            background: (colorTheme) => `linear-gradient(to bottom right, ${colorTheme.green400} 50%, transparent 50%)`,
            borderRadius: '15%',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            left: '-34%',
            top: '-11.5%',
            width: '93.5%',
            height: '80%',
            background: (colorTheme) => `linear-gradient(to top,${colorTheme.green400}  10%,transparent 15%)`,
            borderRadius: '10%',
            transform: 'rotate(-45deg)',
            zIndex: '0',
        },
    },
    plusIcon: {
        position: 'absolute',
        top: '5%',
        left: '12%',
    },
    minusIcon: {
        position: 'absolute',
        bottom: '10%',
        right: '34%',
    },
    testBox: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        background: (colorTheme) => colorTheme.primaryBlack,
    },
}));

interface IAmountField {
    fieldname: string;
    value?: any | undefined;
    form?: any;
    disabled?: boolean;
}

export default function AmountField(props: IAmountField) {
    const { colorTheme } = useThemeContext()
    const classes = customStyle(colorTheme);

    const [isNeg, setIsNeg] = useState(false);
    const [displayValue, setDisplayValue] = useState<string | number>(0);

    const { values: formValues, setFieldValue } = useFormikContext<any>();

    const byString = (o: any, s: any) => {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    };

    useEffect(
        () => {
            let field = byString(formValues, props.fieldname);
            if (field && +field > 0) {
                setIsNeg(false);
                setDisplayValue(field);
            } else if (field && +field < 0) {
                setIsNeg(true);
                setDisplayValue(+field * -1);
            } else {
                setDisplayValue('0');
            }
        },
        props.value !== undefined ? [props.value] : []
    );

    const toggleNegative = () => {
        setFieldValue(
            props.fieldname,
            byString(formValues, props.fieldname) * -1
        );
        setIsNeg(!isNeg);
    };

    const changeHandler = (event: any) => {
        props.form?.setTouched({
            ...props.form.touched,
            [props.fieldname]: true,
        });
        let val = +event.currentTarget.value;
        if (val > 0 && isNeg) {
            val = val * -1;
        }
        if (val < 0) {
            setIsNeg(true);
        } else {
            setIsNeg(false);
        }
        setFieldValue(props.fieldname, val);
        if (val == 0) {
            setDisplayValue('0');
        }
    };

    //const fieldError
    let showError = false;
    if (props.form) {
        const fieldError = getIn(props.form.errors, props.fieldname);
        showError = getIn(props.form.touched, props.fieldname) && !!fieldError;
    }

    return (
        <Grid container>
            <Grid
                item
                className={classes.plusMinusController}
                onClick={toggleNegative}
                xs={2}
            >
                <span
                    className={`${classes.triangleBg} ${
                        isNeg ? 'negative' : ''
                    }`}
                ></span>
                <Typography variant='subtitle1' className={classes.plusIcon}>
                    +
                </Typography>
                <Typography variant='subtitle1' className={classes.minusIcon}>
                    -
                </Typography>
            </Grid>
            <Grid item xs>
                <UiFormControlSelection
                    type='currency'
                    value={displayValue}
                    onBlur={changeHandler}
                    fastField={false}
                    onChange={(e) => setDisplayValue(e.currentTarget.value)}
                    error={showError}
                    helperText={
                        showError ? props.form?.errors[props.fieldname] : ''
                    }
                    disabled={props.disabled}
                    cypressId="amount-field"
                />
            </Grid>
        </Grid>
    );
}
