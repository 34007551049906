import {
    Grid,
    Hidden,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    List,
    makeStyles,
    Theme,
    IconButton,
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    ApplicationStore,
    CompanyDetailsStep,
    CompanyStepInfo,
} from '../../../models';
import { OnboardingStepsStatus } from '../../../models/payroll/company';
import { commonStyles, mergeStyle } from '../../../styles/commonStyles';
import { UnsavedSectionWarning } from './UnsavedSectionWarning';
import Loader from '../../common/Loader';
import UiText from '../../common/ui/UiText';
import UiAlert from '../../common/ui/UiAlert';
import IconList from '../../common/IconList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { loadCompanyStepInfo } from '../../../store/actions/company';
import BusinessInformationForm from '../common/BusinessInformationForm';
import BusinessAddressForm from '../common/BusinessAddressForm';
import PayrollSignatoryForm from '../common/PayrollSignatoryForm';
import PayrollFrequencyForm from '../common/PayrollFrequencyForm';
import TermsOfServices from '../common/TermsOfServices';
import FederalTaxInformationForm from '../common/FederalTaxInformationForm';
import BusinessIndustryForm from '../common/BusinessIndustryForm';
import BankingInformationForm from '../banking-info/BankingInformationForm'
import DocumentUploadForm from '../common/DocumentUploadForm';
import ElectronicSignatureForm from '../common/ElectronicSignatureForm';
import CompletePayrollPopup from './CompletePayrollPopup';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { BankingInfoProvider } from '../banking-info/provider/BankingInfoProvider';

interface CompanyDetailsProps {
    companyDetailsSteps: CompanyDetailsStep[];
    companyStepInfo: CompanyStepInfo;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    selectedStep: {
        background: (colorTheme) => `${colorTheme.cyan100} !important`,
        borderRadius: theme.spacing(0),
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
    },
    incompleteNotice: {
        backgroundColor: (colorTheme) => colorTheme.beige100,
        borderRadius: theme.spacing(1),
    },
    arrowIcon: {
        marginRight: theme.spacing(2),
        color: (colorTheme) => colorTheme.blue200,
        minWidth: 0,
    },
    alertRootWidth: {
        width: '90%',
    },
    alertMessage: {
        width: '100%',
    },
}));

function CompanyDetail({
    companyDetailsSteps,
    companyStepInfo,
}: CompanyDetailsProps) {
    const commonClasses = commonStyles();
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const [currentStep, setCurrentStep] = useState<any>('company_creation');
    const dispatch = useDispatch();
    const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState('');
    const [openPayrollPopup, setOpenPayrollPopup] = useState(false);
    const companyOnboardingCurrentStep =
        companyStepInfo.companyOnboardingStepInfo?.current_step;
    const isStepsCompleted =
        companyOnboardingCurrentStep === 'finish_onboarding';
    const isOnboardingAccepted =
        companyOnboardingCurrentStep === 'finish_onboarding_accepted';
    const [formSubmitProgress, setFormSubmitProgress] = useState(false);

    useEffect(() => {
        isOnboardingAccepted
            ? setCurrentStep('e_signature_addition')
            : setCurrentStep(companyOnboardingCurrentStep);
    }, [
        companyOnboardingCurrentStep,
        companyStepInfo.companyOnboardingStepInfo,
        isOnboardingAccepted,
    ]);

    useEffect(() => {
        // open submit popup after completing all 10 steps
        setOpenPayrollPopup(isStepsCompleted);
    }, [isStepsCompleted]);

    const getStepMethod = (step: keyof OnboardingStepsStatus) =>
        companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status[step]
            ? goToNextSection
            : refreshSteps;

    const childFormRef = useRef<any>();
    const getSection = () => {
        switch (currentStep) {
            case 'company_creation':
                return (
                    <BusinessInformationForm
                        refreshSteps={getStepMethod('company_creation')}
                        ref={childFormRef}
                        parentLoading={setFormSubmitProgress}
                    />
                );
            case 'tos_acceptance':
                return (
                    <TermsOfServices
                        refreshSteps={getStepMethod('tos_acceptance')}
                    />
                );
            case 'address_addition':
                return (
                    <BusinessAddressForm
                        refreshSteps={getStepMethod('address_addition')}
                        ref={childFormRef}
                        parentLoading={setFormSubmitProgress}
                    />
                );
            case 'payroll_frequency_addition':
                return (
                    <PayrollFrequencyForm
                        refreshSteps={getStepMethod(
                            'payroll_frequency_addition'
                        )}
                        ref={childFormRef}
                        parentLoading={setFormSubmitProgress}
                    />
                );           
            case 'federal_tax_info_addition':
                return (
                    <FederalTaxInformationForm
                        refreshSteps={getStepMethod(
                            'federal_tax_info_addition'
                        )}
                        ref={childFormRef}
                        parentLoading={setFormSubmitProgress}
                    />
                );
            case 'business_industry_addition':
                return (
                    <BusinessIndustryForm
                        refreshSteps={getStepMethod(
                            'business_industry_addition'
                        )}
                        ref={childFormRef}
                        parentLoading={setFormSubmitProgress}
                    />
                );
            case 'bank_account_addition':
                return (
                    <BankingInfoProvider>
                        <BankingInformationForm
                            refreshSteps={refreshSteps}
                            nextStep={goToNextSection}
                        />
                    </BankingInfoProvider>
                   
                );
            case 'payroll_signatory_addition':
                return (
                    <PayrollSignatoryForm
                        refreshSteps={getStepMethod(
                            'payroll_signatory_addition'
                        )}
                        ref={childFormRef}
                        parentLoading={setFormSubmitProgress}
                    />
                );
            case 'document_upload':
                return (
                    <DocumentUploadForm
                        refreshSteps={getStepMethod('document_upload')}
                        nextStep={goToNextSection}
                    />
                );
            case 'e_signature_addition':
                return (
                    <ElectronicSignatureForm
                        refreshSteps={getStepMethod('e_signature_addition')}
                    />
                );
        }
    };

    const currentStpeIndex = () =>
        companyDetailsSteps.findIndex((value) => value.id === currentStep);

    const refreshSteps = () => {
        dispatch(loadCompanyStepInfo());
    };

    const goToNextSection = () => {
        setCurrentStep(companyDetailsSteps[currentStpeIndex() + 1].id);
    };

    const goToPreviousSection = () => {
        if (childFormRef.current?.isFromDirty()) {
            setShowUnsavedWarningModal('true');
        } else {
            setCurrentStep(companyDetailsSteps[currentStpeIndex() - 1].id);
        }
    };

    const handleOnSectionClick = (step: keyof OnboardingStepsStatus) => {
        if (step === currentStep) {
            return;
        }
        if (childFormRef.current?.isFromDirty()) {
            setShowUnsavedWarningModal(step);
        } else {
            setCurrentStep(step);
        }
    };

    const handleUnsavedWanringModalAction = (
        action: 'close' | 'keep' | 'discard' | 'save'
    ) => {
        switch (action) {
            case 'close':
                setShowUnsavedWarningModal('');
                break;
            case 'keep':
                setShowUnsavedWarningModal('');
                break;
            case 'discard':
                if (showUnsavedWarningModal === 'true') {
                    setCurrentStep(
                        companyDetailsSteps[currentStpeIndex() - 1].id
                    );
                } else {
                    setCurrentStep(showUnsavedWarningModal);
                }

                setShowUnsavedWarningModal('');
                break;
            case 'save':
                setShowUnsavedWarningModal('');
                childFormRef.current?.submit()();
                break;
        }
    };

    const incompleteNotics = (
        <ListItem alignItems='flex-start' className={classes.incompleteNotice} data-cy="setup-complete-alert">
            <ListItemIcon>{IconList['help']}</ListItemIcon>
            <ListItemText
                primary={
                    <UiText
                        weight='semi_bold_600'
                        style={{ letterSpacing: '0.5px' }}
                    >
                        Payroll setup is incomplete
                    </UiText>
                }
                secondary={
                    <Typography variant='body2' style={{ fontWeight: 400 }}>
                        In order for payroll to work, you must finish setting it
                        up by completing all required fields.
                    </Typography>
                }
            />
        </ListItem>
    );

    const completeNotice = (
        <UiAlert
            variant='outlined'
            severity='success'
            icon='checkCircleSmall'
            customStyles={{
                root: classes.alertRootWidth,
                message: classes.alertMessage,
            }}
            data-cy="setup-incomplete-alert"
            customContent={
                <>
                    <div
                        className={mergeStyle(
                            commonClasses.flex,
                            commonClasses.spaceBetween
                        )}
                        
                    >
                        <UiText variant='car_100' weight='bold_700'>
                            Payroll Setup is complete!
                        </UiText>
                        <IconButton
                            aria-label='close'
                            color='inherit'
                            size='small'
                            onClick={() => {}}
                        >
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    </div>
                    <UiText variant='motorcycle_90'>
                        You can adjust the information below at any time
                    </UiText>
                </>
            }
        />
    );

    const previousSectionLink = (name: string) => (
        <ListItem
            classes={{
                root: commonClasses.cursorPointer,
                gutters: commonClasses.listItemZeroGutters,
            }}
            onClick={goToPreviousSection}
            disabled={formSubmitProgress}
        >
            <ListItemIcon className={classes.arrowIcon}>
                <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText
                primary={
                    <UiText
                        weight='semi_bold_600'
                        style={{
                            letterSpacing: '0.5px',
                            color: colorTheme.blue200,
                        }}
                    >
                        Previous Section
                    </UiText>
                }
                secondary={<UiText>{name}</UiText>}
            />
        </ListItem>
    );

    return (
        <Grid
            container
            direction='row'
            style={{
                height: '100%',
            }}
        >
            {true ? (
                <>
                    <Grid
                        container
                        item
                        md={5}
                        sm={12}
                        justify='flex-start'
                        alignItems='flex-start'
                        alignContent='flex-start'
                        direction='column'
                        wrap='nowrap'
                        style={{ height: '100%', overflow: 'auto' }}
                    >
                        {isOnboardingAccepted
                            ? completeNotice
                            : incompleteNotics}
                        <div className={commonClasses.mt16}>
                            <Typography variant='body1'>
                                {
                                    Object.keys(
                                        companyStepInfo
                                            .companyOnboardingStepInfo
                                            .onboarding_steps_status as OnboardingStepsStatus
                                    ).filter(
                                        (key: string) =>
                                            // restrict the finish_onboarding step as it is not a section 
                                            key !== 'finish_onboarding' &&
                                            companyStepInfo
                                                .companyOnboardingStepInfo
                                                .onboarding_steps_status[
                                                key as keyof OnboardingStepsStatus
                                            ] === true
                                    ).length
                                }{' '}
                                of 10 complete
                            </Typography>
                        </div>

                        <List
                            className={mergeStyle(
                                commonClasses.mt24,
                                commonClasses.fullWidth
                            )}
                        >
                            {companyDetailsSteps.map((step) => {

                                const isStepCompleted = companyStepInfo.companyOnboardingStepInfo
                                        ?.onboarding_steps_status[step.id as keyof OnboardingStepsStatus]
                               
                                return (
                                    <ListItem
                                        key={step.id as keyof OnboardingStepsStatus}
                                        button
                                        classes={{ selected: classes.selectedStep }}
                                        selected={step.id === currentStep}
                                        style={{ height: '56px' }}
                                        onClick={() =>
                                            handleOnSectionClick(
                                                step.id as keyof OnboardingStepsStatus
                                            )
                                        }
                                        disabled={
                                            formSubmitProgress ||
                                            (!isStepCompleted &&
                                                step.id !== currentStep &&
                                                step.id !==
                                                    companyStepInfo
                                                        .companyOnboardingStepInfo
                                                        .current_step)
                                        }
                                        data-cy={isStepCompleted ? `${step.id}-completed` : `${step.id}-uncompleted`}
                                        
                                    >
                                        <ListItemIcon>
                                            {
                                                IconList[
                                                    isStepCompleted ? 
                                                    'checkCircleSmall' : 
                                                    'leadingCircle']
                                            }
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant='body1'
                                                    style={{
                                                        fontWeight: 'inherit',
                                                    }}
                                                >
                                                    {step.title}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>

                    <Hidden smDown implementation='js'>
                        <Grid
                            sm={1}
                            item
                            container
                            direction='row'
                            justify='center'
                            alignItems='stretch'
                        >
                            <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                    height: '100%',
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            wrap='nowrap'
                            style={{ height: '100%' }}
                            container
                            direction='column'
                            justify='flex-start'
                            alignItems='stretch'
                        >
                            <Grid>
                                {currentStep !== 'company_creation'
                                    ? previousSectionLink(
                                          companyDetailsSteps[
                                              currentStpeIndex() - 1
                                          ]?.title
                                      )
                                    : null}
                            </Grid>

                            {getSection()}
                        </Grid>
                    </Hidden>
                </>
            ) : (
                <Loader />
            )}
            <UnsavedSectionWarning
                open={showUnsavedWarningModal ? true : false}
                handleModalAction={handleUnsavedWanringModalAction}
            />
            <CompletePayrollPopup
                open={openPayrollPopup}
                handleClose={() => {
                    setOpenPayrollPopup(false);
                }}
                refreshSteps={refreshSteps}
            />
        </Grid>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    companyDetailsSteps: state.config.local.company_details_steps,
    companyStepInfo: state.company,
});
export default connect(mapStateToProps)(CompanyDetail);
