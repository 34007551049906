import { useState } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, Theme, Hidden, Divider } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { logout } from '../../../services/authService'
import Menu from '@material-ui/core/Menu'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { toRem16 } from '../../../styles/commonStyles'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    avatarButton: {
        height: 40,
        fontWeight: 'normal',
        fontSize: '1rem',
        border: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey300}`,
        textTransform: 'capitalize'
    },
    avatarButtonIcon: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8,
        paddingRight: 2,
    },
    listItemIconRoot: {
        minWidth: '2.75rem',
    },
}))

const MenuBar = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    const { currentUser } = useCurrentStore()
    const [avatarEl, setAvatarEl] = useState<Element | null>(null)

    const handleClose = () => {
        setAvatarEl(null)
    }

    return (
        <div>
            <Hidden smDown implementation="css">
                <Toolbar>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.avatarButton}
                        onClick={(e) => {
                            setAvatarEl(e.currentTarget)
                        }}
                    >
                        {currentUser?.first_name} {currentUser?.last_name}
                        <div className={classes.avatarButtonIcon}>
                            <img
                                alt={`User Icon`}
                                src={
                                    require(`../../../assets/icons-svg/Profile.svg`)
                                        .default
                                }
                            />
                        </div>
                        {!!avatarEl ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </Button>
                </Toolbar>
                <Menu
                    open={!!avatarEl}
                    anchorEl={avatarEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    onClose={() => {
                        setAvatarEl(null)
                    }}
                    MenuListProps={{ disablePadding: true }}
                    elevation={4}
                >
                    <MenuItem
                        onClick={handleClose}
                        component="a"
                        href="/privacy-policy/"
                        target="_blank"
                        data-cy="goto-policy-btn"
                    >
                        <ListItemIcon
                            classes={{ root: classes.listItemIconRoot }}
                        >
                            <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Privacy Policy" />
                    </MenuItem>
                    <MenuItem
                        onClick={handleClose}
                        component="a"
                        href="/terms-of-services/"
                        target="_blank"
                        data-cy="goto-tos-btn"
                    >
                        <ListItemIcon
                            classes={{ root: classes.listItemIconRoot }}
                        >
                            <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Terms of Service" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={(e) => {
                            logout()
                        }}
                        data-cy="sign-out-btn"
                    >
                        <ListItemIcon
                            classes={{ root: classes.listItemIconRoot }}
                        >
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </MenuItem>
                </Menu>
            </Hidden>
        </div>
    )
}

export default MenuBar