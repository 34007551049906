import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import {
    Button,
    Theme,
    makeStyles,
    Grid,
    Hidden,
    IconButton,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { ProductDetail } from '../../../models'
import Loader from '../../common/Loader'
import ProductDetails from './ProductDetails'
import ProductModal from './ProductModal'
import MobileHeader from '../../common/MobileHeader'
import ConfirmProductModal from './ConfirmProductModal'
import { deleteProductAction } from './actions'
import { productDetail } from '../../../services/apiService/products'
import { mergeStyle, commonStyles } from '../../../styles/commonStyles'
import { OutlinedDeleteButton } from '../../common/DeleteButton'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    editButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
    },
    marginButton: {
        marginRight: theme.spacing(1),
    },
    mobileHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    editMobileButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
        marginTop: '5px',
        paddingTop: 4,
        paddingBottom: 4,
    },
    containerHeight: {
        marginTop: '25px',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        overflowX: "hidden"
    }
}))

function ProductPreview({
    selectedProduct,
    setSelectedProduct,
    loadProducts,
    setProductUpdated,
    checkedProdObj
}: {
    selectedProduct?: ProductDetail | undefined
    setSelectedProduct?: React.Dispatch<ProductDetail | undefined>
    loadProducts?: () => void
    setProductUpdated?: React.Dispatch<boolean>
    checkedProdObj?: any[]
}) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const common = commonStyles()
    const history = useHistory()
    const params: { id: string } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [currentProduct, setCurrentProduct] = useState<ProductDetail>()

    const handleConfirmModal = (result: any) => {
        if (result) {
            // i.e when discard/delete clicked
            deleteProductAction(
                (currentProduct?.id || selectedProduct?.id) as string,
                () => {
                    setShowConfirmModal(false)
                    history.push('/products')
                    loadProducts?.()
                    setCurrentProduct(undefined)
                    setSelectedProduct?.(undefined)
                }
            )
        } else {
            setShowConfirmModal(false)
        }
    }

    useEffect(() => {
        if (!selectedProduct && params?.id) {
            setLoading(true)
            productDetail(params?.id).then((res: any) => {
                if (res) {
                    setLoading(false)
                    setCurrentProduct(res)
                    setSelectedProduct?.(res)
                }
            })
        }
    }, [params?.id, selectedProduct, setSelectedProduct])

    return (
        <Fragment>
            {!(selectedProduct || currentProduct) || loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <ProductModal
                        open={openModal}
                        handleClose={() => setOpenModal(false)}
                        isNew={false}
                        product={selectedProduct || currentProduct}
                        setSelectedProduct={
                            setSelectedProduct || setCurrentProduct
                        }
                        loadProducts={loadProducts}
                        setProductUpdated={setProductUpdated}
                    />
                    <ConfirmProductModal
                        isEditable={true}
                        showConfirmModal={showConfirmModal}
                        product={currentProduct || selectedProduct}
                        handleClose={handleConfirmModal}
                    />
                    <MobileHeader showBottomDivider={true}>
                        <div className={mergeStyle(common.flex, common.spaceBetween)}>
                            <IconButton
                                onClick={() => history.push('/products')}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Button
                                variant="text"
                                classes={{ root: styles.editMobileButton }}
                                onClick={() => setOpenModal(true)}
                            >
                                Edit
                            </Button>
                        </div>
                    </MobileHeader>
                    
                        {/* Hidden for Mobile Devices */}
                        <Hidden smDown>
                            <Grid container direction="row" justify="flex-end">
                                <OutlinedDeleteButton
                                    variant="outlined"
                                    color="secondary"
                                    showDeleteIcon
                                    onClick={() => {
                                        setShowConfirmModal(true)
                                    }}
                                    className={styles.marginButton}
                                >
                                    Delete
                                </OutlinedDeleteButton>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                    className={styles.editButton}
                                >
                                    Edit
                                </Button>
                            </Grid>
                        </Hidden>
                        <div className={styles.containerHeight}>
                            <ProductDetails
                                product={selectedProduct || currentProduct}
                                setOpenEditModal={setOpenModal}
                            />
                        </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default ProductPreview;