import store from '../../store'
import {
    getResource,
    postResource,
    patchResource,
    deleteResource
} from '../axiosService'
import { Purpose, PurposeSetDefault } from '../../models/purposes'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    const purposesBaseURL = `api/account/${accountId}/mileage_tracker/purposes`
    const purposeBaseURL = `api/account/${accountId}/mileage_tracker/purpose`
    return {
        purposesBaseURL,
        purposeBaseURL
    }
}

export function getPurposes(params: Purpose) {
    const { purposesBaseURL } = getStoreData()
    return getResource(
        purposesBaseURL,
        undefined,
        params
    )
}

export function purposeDetail(
    purposeId: string
) {
    const { purposesBaseURL } = getStoreData()
    return getResource(
        `${purposesBaseURL}/${purposeId}`
    )
}

export function createPurpose(
    params: Purpose
) {
    const { purposeBaseURL } = getStoreData()
    return postResource(
        `${purposeBaseURL}`,
        params
    )
}

export function updatePurpose(
    purposeId: string,
    params: Purpose
) {
    const { purposeBaseURL } = getStoreData()
    return patchResource(
        `${purposeBaseURL}/${purposeId}`,
        params
    )
}

export function deletePurpose(
    purposeId: string
) {
    const { purposeBaseURL } = getStoreData()
    return deleteResource(
        `${purposeBaseURL}/${purposeId}`
    )
}
