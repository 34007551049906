import { IconButton, makeStyles } from '@material-ui/core'
import UiDialog from '../../../common/ui/UiDialog'
import CloseIcon from '@material-ui/icons/Close'
import ThumbsUp from '../../../../assets/icons-svg/thumbs-up.svg'
import UiText from '../../../common/ui/UiText'
import UiButton from '../../../common/ui/UiButton'

const styles = makeStyles((theme) => ({
    successContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        ['& button']: {
            marginBottom: '2rem',
        },
    },
    root: {
        '& .MuiDialogTitle-root': {
            borderBottom: 'none',
        },
    },
    closeIcon: {
        marginLeft: 'auto',
    },
}))

function FinCenSucessModal({
    open,
    handleClose,
}: {
    open: boolean
    handleClose: () => void
}) {
    const classes = styles()
    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            size="sm"
            customRootClass={classes.root}
            titleNode={
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.closeIcon}
                >
                    <CloseIcon />
                </IconButton>
            }
        >
            <div className={classes.successContainer}>
                <img src={ThumbsUp} alt="thumsup" />

                <UiText variant="suv_150" weight="bold_700">
                    Success!
                </UiText>
                <UiText textAlign="center" variant="car_100">
                    We’ve received your Beneficial Ownership Information.
                </UiText>

                <UiButton
                    btnType="hyperlink"
                    label="Close"
                    handleClick={handleClose}
                />
            </div>
        </UiDialog>
    )
}

export default FinCenSucessModal
