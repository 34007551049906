import { useState, useEffect } from 'react';
import { useInterval } from './useInterval';

const DEFAULT_DELAY = 1000;

/**
 * How do we know if we meeting has ended ???
 * @param expiryTimestamp
 */
const isExpiryTimeValid = (expiryTimestamp: number) => {
  const isValidTime = (new Date(expiryTimestamp)).getTime() > 0;
  const currentTime = new Date().getTime()
  const expiryTime = new Date(expiryTimestamp * 1000).getTime()

  return isValidTime && (currentTime < expiryTime)
}

const  getTimeFromSeconds = (secs: number) => {
  const totalSeconds = Math.ceil(secs);
  const days = Math.floor(totalSeconds / (60 * 60 * 24));
  const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    seconds,
    minutes,
    hours,
    days,
  };
}

const  getRemainingTimeInSeconds = (expiry: number) => {
  const now = new Date().getTime();
  const expiryTime = new Date(expiry* 1000).getTime()
  const remainingTime = expiryTime - now;
  if (remainingTime > 0) {
    const val = remainingTime / 1000;
    return Math.round(val)
  }
  return 0;
}

const getDelayFromExpiryTimestamp = (expiryTimestamp: number) => {
  if (!isExpiryTimeValid(expiryTimestamp)) {
    return null;
  }

  const seconds = getRemainingTimeInSeconds(expiryTimestamp);
  const extraMilliSeconds = Math.floor((seconds - Math.floor(seconds)) * 1000);
  return extraMilliSeconds > 0 ? extraMilliSeconds : DEFAULT_DELAY;
}

export const useTimer = ({ expiry, onTimeout }: {
  expiry: number | undefined,
  onTimeout: any
} ) => {
  const [expiryTimestamp, setExpiryTimestamp] = useState(0);
  const [seconds, setSeconds] = useState(getRemainingTimeInSeconds(expiryTimestamp))
  const [isTimerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    if(expiry) {
      setExpiryTimestamp(expiry)
      setTimerRunning(true)
    }
  },[expiry])

  const handleExpire = () => {
    onTimeout()
    setTimerRunning(false)
  }

  function start() {
    setSeconds(getRemainingTimeInSeconds(expiryTimestamp));
  }

  // if valid time then expire the
  useInterval(() => {
    const secondsValue = getRemainingTimeInSeconds(expiryTimestamp);
    setSeconds(secondsValue);
    if (secondsValue <= 0) {
      handleExpire();
    }
  }, isTimerRunning ? getDelayFromExpiryTimestamp(expiryTimestamp) : null);

  return {
    start, isTimerRunning, ...getTimeFromSeconds(seconds)
  };
}