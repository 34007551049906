import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core'
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
} from '@material-ui/core'
import Icon from '../../../common/Icon'
import IconList from '../../../common/IconList'
import UiText from '../../../common/ui/UiText'
import { commonStyles } from '../../../../styles/commonStyles'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { 
    GoogleMap,
    Marker
} from '@react-google-maps/api'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';

const propertyIconMapping = [
    { key: 'address', icon: 'location', label: 'Address' },
    { key: 'description', icon: 'note', label: 'Description' }
]

const useStyles = makeStyles(() => ({
    itemHeight: {
        maxHeight: '1.25rem',
        marginBottom: '0.5rem',
    },
    blueLink: {
        color: `${useThemeContext().blue400} !important`,
    },
    listRoot: {
        marginLeft: '-15px'
    },
}))

export default function LocationDetails({ location }: { location: any }) {
    const { currentConfig } = useCurrentStore()
    const classes = useStyles()
    const styles = commonStyles()
    const { 
        address, 
        city, 
        state, 
        country, 
        zip_code, 
        latitude,
        longitude,
        county
    } = location?.address || {}
    const countries = currentConfig.apiConfig.generic.countries;

    const RenderListItem = (property: any) => {
        const itemVal = location?.[`${property?.key}`]
            ? location?.[`${property?.key}`]
            : '-'
        return (
            <>
                {itemVal}
            </>
        )
    }

    const mapContainerStyle = {
        height: "23rem",
        width: "100%"
      };

    const center = {
        lat: 38.8892686,
        lng: -77.050176
    };

    const getCountryName = (countryId: string): string | null => {
        const country = countries.find(
            (item) => item.abbr === countryId
        )!;
        return country?.name;
    };

    return (
        <>
            <List className={classes.listRoot}>
                {propertyIconMapping.map((property) => (
                    <Fragment key={property.key}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Icon
                                    icon={property.icon as keyof typeof IconList}
                                    customClass={`${styles.mt16} ${styles.ml2}`}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <UiText textColor="textSecondary">
                                        {property?.label}
                                    </UiText>
                                }
                                secondary={
                                    <div className={classes.itemHeight}>
                                        {!['address'].includes(
                                            property?.key
                                        ) ? (
                                            <UiText
                                                textColor="textPrimary"
                                            >
                                                {RenderListItem(property)}
                                            </UiText>
                                        ) : (
                                            <div>
                                                {property?.key === 'address' && (
                                                    <UiText textColor="textPrimary">
                                                        {address ? address + ', ' : ''} 
                                                        {city ? city + ', ' : ''}
                                                        {county ? county + ', ' : ''}
                                                        {state ? state + ', ' : ''}
                                                        {zip_code ? zip_code + ', ' : ''}
                                                        {getCountryName(country)}
                                                    </UiText>
                                                )}
                                            </div>
                                        )}
                                        <Divider
                                            variant="fullWidth"
                                            className={styles.mt8}
                                        />
                                    </div>
                                }
                            />
                        </ListItem>
                    </Fragment>
                ))}
            </List>
            {(latitude !== center.lat && longitude !== center.lng && latitude !== undefined && longitude !== undefined) && (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={{
                        lat: latitude,
                        lng: longitude
                    }}
                    zoom={15}
                >
                    <Marker
                        position={{
                            lat: latitude,
                            lng: longitude
                        }}
                    />
                    <></>
                </GoogleMap>
            )}
        </>
    )
}