import MomentUtils from '@date-io/moment';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    makeStyles,
    Radio,
    RadioGroup,
    Theme,
    Typography,
} from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { commonStyles } from '../../../styles/commonStyles';
import { ThemeColors } from '../../../styles/models/Colors.interface';

import UiDialog from '../../common/ui/UiDialog';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        circularProgress: {
            color: (colorTheme) =>colorTheme.primary,
            marginBottom: theme.spacing(11),
            marginTop: theme.spacing(11),
        },
        createModalFooter: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: (colorTheme) =>`1px solid ${colorTheme.primary}`,
            padding: '0.5rem 0rem 0rem 0rem',
            marginLeft: '-24px',
            marginRight: '-24px',
            '& button:nth-child(1)': {
                marginRight: '10px',
                width: 57,
            },
            '& button:nth-child(2)': {
                marginRight: '10px',
                width: 'fit-content',
            },
        },
        checkCircle: {
            height: 160,
            width: 160,
            marginBottom: theme.spacing(11),
            marginTop: theme.spacing(11),
        },
    };
});

function ImportTransactionsModal(props: {
    open: boolean;
    handleClose: () => void;
    step: string;
    importTransaction: (date: any) => void;
    importedTransactions: any;
}) {
    const currrentDate = moment();

    const backDateTwoYears = moment(currrentDate).subtract(2, 'years');
    const [importDate, setImportDate] = useState(
        moment(currrentDate).subtract(1, 'month')
    );
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme);
    const commonClasses = commonStyles();

    const [importMode, setImportMode] = useState<'static' | 'dynamic'>(
        'static'
    );
    const history = useHistory();

    const completeContent = () => (
        <Grid
            container
            direction='column'
            style={{ height: '100%', textAlign: 'center' }}
            justify='flex-start'
            alignItems='center'
        >
            <Grid>
                <img
                    alt={`Check-Circle Icon`}
                    src={
                        require(`../../../assets/icons-svg/check-circle.svg`)
                            .default
                    }
                    className={classes.checkCircle}
                />
            </Grid>
            <Grid>
                <Typography variant='h6'>
                    Successfully Imported {props.importedTransactions}{' '}
                    Transactions
                </Typography>

                <div className={commonClasses.mt24}>
                    <Button
                        variant='outlined'
                        color='secondary'
                        className={commonClasses.mr16}
                        onClick={() => {
                            props.handleClose();
                            history.push('/transactions');
                        }}
                    >
                        View Transactions
                    </Button>
                    <Button
                        variant='outlined'
                        color='secondary'
                        onClick={props.handleClose}
                    >
                        Close
                    </Button>
                </div>
            </Grid>
        </Grid>
    );

    const inProgressContent = () => (
        <Grid
            container
            direction='column'
            style={{ height: '100%', textAlign: 'center' }}
            justify='flex-start'
            alignItems='center'
        >
            <Grid>
                <CircularProgress
                    size={160}
                    thickness={2.5}
                    variant='indeterminate'
                    className={classes.circularProgress}
                />
            </Grid>
            <Grid>
                <Typography variant='h6'>
                    Importing transactions from{' '}
                    {importMode === 'static'
                        ? backDateTwoYears.format('DD MMM YYYY')
                        : importDate.format('DD MMM YYYY')}
                </Typography>
                <Typography variant='subtitle1' className={commonClasses.mt16}>
                    Leave this window open.
                </Typography>
                <Typography variant='subtitle1'>
                    This can take up to two minutes.
                </Typography>
                <Button
                    variant='outlined'
                    color='secondary'
                    className={commonClasses.mt24}
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );

    const startContent = () => (
        <Grid
            container
            direction='column'
            style={{ height: '100%' }}
            justify='space-between'
        >
            <Grid
                container
                direction='column'
                justify='flex-start'
                alignItems='flex-start'
            >
                <Typography variant='subtitle1' className={commonClasses.mt16}>
                    You can import transactions from up to 24 months ago.
                </Typography>
                <FormControl component='fieldset'>
                    <RadioGroup
                        aria-label='gender'
                        name='gender1'
                        className={commonClasses.mt16}
                        value={importMode}
                        onChange={(event: any) =>
                            setImportMode(event.target.value)
                        }
                    >
                        <FormControlLabel
                            value='static'
                            control={<Radio color='primary' />}
                            label={
                                <Typography variant='subtitle1'>
                                    All transactions since{' '}
                                    {backDateTwoYears.format('DD MMM YYYY')}
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value='dynamic'
                            control={<Radio color='primary' />}
                            className={commonClasses.mt16}
                            label={
                                <Grid
                                    container
                                    direction='row'
                                    justify='center'
                                    alignItems='center'
                                >
                                    <Grid>
                                        <Typography
                                            component='span'
                                            variant='subtitle1'
                                            className={commonClasses.mr16}
                                        >
                                            Transactions since{'   '}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                        >
                                            <DatePicker
                                                variant={'inline'}
                                                inputVariant='outlined'
                                                format={'DD MMM YYYY'}
                                                margin='none'
                                                disableFuture={true}
                                                disablePast={false}
                                                minDate={moment(
                                                    backDateTwoYears
                                                ).add(1, 'day')}
                                                size='small'
                                                fullWidth
                                                value={importDate}
                                                autoOk={true}
                                                onChange={(value: any) =>
                                                    setImportDate(value)
                                                }
                                                label={''}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <CalendarTodayOutlined fontSize='small' />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            }
                        ></FormControlLabel>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid>
                <Box className={classes.createModalFooter}>
                    <Button
                        variant='outlined'
                        color='secondary'
                        fullWidth
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        type='submit'
                        onClick={() =>
                            props.importTransaction(
                                importMode === 'static'
                                    ? backDateTwoYears.unix()
                                    : importDate.unix()
                            )
                        }
                    >
                        Import Transactions
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );

    const getContent = () => {
        switch (props.step) {
            case 'start':
                return startContent();
            case 'in-progress':
                return inProgressContent();
            case 'complete':
                return completeContent();
        }
    };

    return (
        <UiDialog
            open={props.open}
            title='Import Transactions'
            handleClose={props.handleClose}
            size='sm'
        >
            <div style={{ height: '640px' }}>{getContent()}</div>
        </UiDialog>
    );
}

export default ImportTransactionsModal;
