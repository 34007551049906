import React from 'react';
import {
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";

interface contentProps {
  contentHeader: string | React.ReactNode;
  contentSecondaryText: string | React.ReactNode;
  clickEvent?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  buttonText: string;
  disableButton?: boolean | false;
}

export default function  ListItemContent({contentHeader, contentSecondaryText, clickEvent, buttonText, disableButton  }: contentProps) {
  const styles = makeStyles({
    listHeading: {
        fontWeight: 500,
    }
})();
  

  

  return (
    <div>
      <div className="d-flex justify-content-between">
          <div>
              <Typography
                  variant="subtitle1"
                  component="div"
                  className={styles.listHeading}
                  gutterBottom
              >
                  {contentHeader}
              </Typography>
              <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingRight: 10 }}
              >
                  {contentSecondaryText}
              </Typography>
          </div>
          <div>
              <Button
                  disabled={disableButton}
                  variant="outlined"
                  color={"secondary"}
                  onClick={clickEvent}
              >
                  {buttonText}
              </Button>
          </div>
      </div>
    </div>
  )
}