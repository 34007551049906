import { useState, useEffect } from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core'
import UiDialog from '../../common/ui/UiDialog'
import { commonStyles } from '../../../styles/commonStyles'
import Loader from '../../../components/common/Loader'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        headerColor: {
            color: (colorTheme) => colorTheme.black200,
        },
    }
})

const UnsaveChangeDialog = ({
    open,
    handleClose,
    saveReconciliation,
    discardChanges,
    hideDiscardChangesButton
}: any) => {
    const { colorTheme } = useThemeContext()
    const styles = commonStyles(colorTheme)
    const classes = useStyles(colorTheme)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!open) {
            setLoading(false)
        }
    }, [open])
    
    return (
        <UiDialog
            open={open}
            handleClose={()=> {
                setLoading(false)
                handleClose()
            }}
            hideTitleSection={true}
            title=""
            size="xs"
        >
            <div className={classes.headerColor}>You have unsaved changes</div>
            <div className={styles.mt16}>
                {' '}
                Would you like to save your progress
            </div>
            <div> before you leave this page ?</div>

            {loading ? <Box my={4}><Loader/></Box> : 
                <>
                    <div className={styles.mt16}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={()=> { 
                                setLoading(true);
                                saveReconciliation()
                            }}
                        >
                            Save Changes
                        </Button>
                    </div>
                    {!hideDiscardChangesButton && <div className={styles.mt16}>
                        <Button variant="outlined" onClick={discardChanges}>
                            Discard Changes
                        </Button>
                    </div>}
                    <div className={styles.mt16}>
                        <Button variant="text" color="primary" onClick={handleClose}>
                            Keep Editing
                        </Button>
                    </div>
                </>
            }
        </UiDialog>
    )
}

export default UnsaveChangeDialog
