import moment from 'moment'
import {
    Employee,
    WorkAssignment,
} from '../../../../models/adp-payroll/employee'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import SectionView from '../../../payroll/common/SectionView'
import Icon from '../../../common/Icon'
import MdashCheck from '../../../common/MdashCheck'
import EmptySectionView from '../../../payroll/common/EmptySectionView'
import EmailIcon from '@material-ui/icons/Email'
import { formatNumberWithCommas } from '../NewEmployeeFormUtils'
import { frequencyOptions } from '../EmployeeFormData'

const JobDetailsView = ({
    selectedEmployee,
    setOpenModal,
}: {
    selectedEmployee?: Employee | undefined
    setOpenModal?: any
}) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    return (
        <div>
            {selectedEmployee?.workAssignments?.length ? (
                <>
                    {selectedEmployee?.workAssignments?.map(
                        (currentJob: WorkAssignment, index: number) => (
                            <SectionView
                                key={`job_detail-${index}`}
                                headerText={'Job Details'}
                                // buttonIcon={
                                //     <Icon
                                //         icon="edit"
                                //         svgColor={colorTheme.blue200}
                                //     />
                                // }
                                // buttonText={'Edit'}
                                sectionInfoData={[
                                    {
                                        label: 'Date Hired',
                                        value: (
                                            <MdashCheck
                                                check={
                                                    !!selectedEmployee
                                                        ?.workAssignments[0]
                                                        ?.startDate
                                                }
                                            >
                                                {moment(
                                                    selectedEmployee
                                                        ?.workAssignments[0]
                                                        ?.startDate
                                                ).format('MMM D, YYYY')}
                                            </MdashCheck>
                                        ),
                                    },
                                    {
                                        label: 'Title',
                                        value: <> {currentJob?.jobName} </>,
                                    },
                                    {
                                        label: 'Location',
                                        value: (
                                            <>
                                                {
                                                    currentJob?.locations?.[0]
                                                        .locationName
                                                }{' '}
                                                <br />
                                            </>
                                        ),
                                    },
                                    {
                                        label: 'Compensation',
                                        value: (
                                            <>
                                                {' '}
                                                {`$${formatNumberWithCommas(currentJob?.compensations?.earnings?.[0]?.compensationAmount) ?? '0'}`}{' '}
                                            </>
                                        ),
                                    },
                                    {
                                        label: 'Frequency',
                                        value: (
                                            <>
                                                {' '}
                                                {
                                                    frequencyOptions.find(option => option.code === currentJob?.employmentTypeCode)?.shortName 
                                                }{' '}
                                            </>
                                        ),
                                    },
                                ]}
                                onClickAction={() => {
                                    setOpenModal(true)
                                }}
                            />
                        )
                    )}
                </>
            ) : (
                <EmptySectionView
                    headerText={'Job Details'}
                    primaryText={'No Job Found'}
                    buttonText={'Request employee input'}
                    buttonIcon={<EmailIcon />}
                    disableButton={false}
                    onClickAction={() => {}}
                />
            )}
        </div>
    )
}

export default JobDetailsView
