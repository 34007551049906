import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';

import {
    findSpaceRegex,
    DATE_FORMATS,
    DATE_TYPES,
    EMPTY_PARAMS,
} from './constants/reports.const';
import { getTransactionsReport } from '../../../services/apiService/reports';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import { ApiError, BalanceSheetReportRoot, ErrorAlertReport } from './models/reports-interface';
import store from '../../../store';
import { reportsDataActions } from '../../../store/actions/reportsData';
import { getCurrentTimeStamp, getTimeParamsWithoutEST } from '../../../utils/dateUtil';
import SelectAccountAndDates from './SelectAccountAndDates';
import { initCategories } from '../../../store/actions/categories';
import { ApplicationStore } from '../../../models';
import { showError } from '../../../services/formService';

const chips = [
    {
        for: DATE_TYPES.THIS_MONTH,
        id: 1,
    },
    {
        for: DATE_TYPES.THREE_MONTHS,
        id: 2,
    },
    {
        for: DATE_TYPES.THIS_YEAR,
        id: 3,
    },
    {
        for: DATE_TYPES.ALL_TIME,
        id: 4,
    },
];
const TOOLTIP_TEXT = 'The Transactions by Account Report lists all transactions you made for the selected period of time that were categorized in the specified account from your chart of accounts.';
function TransactionsByAccount({ category }: any) {
    const history = useHistory();
    const storeData = useCurrentStore();
    const dispatch = useDispatch();
    const businessName = storeData.currentBusiness?.name;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountList, setAccountList] = useState<any>([]);
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>();
    const queryParam = new URLSearchParams(useLocation().search);
    const accountId = queryParam.get('account');
    const startDate = queryParam.get('startDate');
    const endDate = queryParam.get('endDate');
    const [preselectAccount, setPreselectAccount] = useState<any>();

    useEffect(() => {
        setIsLoading(category?.loading);
        if (category) {
            const parentCategories = {};
            const cache = {};
            [...category.categories].forEach((caty: any) => {
                if (caty.can_have_children) {
                    //@ts-ignore
                    parentCategories[caty?.id] = { ...caty };
                }
                //@ts-ignore
                cache[caty?.id] = { ...caty };
            });
            let categoryOptions = Object.values({ ...cache });
            categoryOptions.forEach((c: any) => {
                if (c.parent) {
                    c.parent = categoryOptions.find(
                        (pc: any) => c.parent === pc.id
                    );
                }
            });
            setAccountList(categoryOptions);
            setIsLoading(category?.loading);
        }
    }, [category]);
    useEffect(() =>{
        const preselectAccount = category?.categories?.filter((account: any) =>{
            return account.id === accountId;
        })
        setPreselectAccount(preselectAccount[0]);
    },[accountId, accountList, category?.categories])
    
    useEffect(() => {
        dispatch(initCategories(true));
    }, [dispatch]);

    const isThisYearSelected = useMemo(() => {
        const { startDate: start, endDate: end } = getTimeParamsWithoutEST(
            DATE_TYPES.THIS_YEAR,
            true
        );
        if(startDate && endDate){
            return Number.parseInt(startDate ?? '0') === start && Number.parseInt(endDate ?? '0') === end
        }
        return null
    }, [startDate, endDate]);

    const fetchAPIQueryParams = (from: number, to: number) => {
        const start_date_ymd = from === 0 ? EMPTY_PARAMS.START_DATE_YMD : moment(from * 1000).format(DATE_FORMATS.ymdDate);
        const end_date_ymd = to === 0 ? EMPTY_PARAMS.END_DATE_YMD : moment(to * 1000).format(DATE_FORMATS.ymdDate);
        let periodText = (
            'for the period from ' +
            moment(from * 1000).format(DATE_FORMATS.periodText) +
            ' to ' +
            moment(to * 1000).format(DATE_FORMATS.periodText)
        ).replace(findSpaceRegex, '+');
        if(!from && !to){
            periodText = 'For All Time'
        }
        const time = getCurrentTimeStamp();
        const accountId = storeData.currentAccountId;
        const businessId = storeData.currentBusinessId;
        return {
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
        };
    };

    const navigateToTransactionDetailedReports = (
        selectedAccount: string,
        start_date_ymd: string,
        end_date_ymd: string,
        periodText: string,
        time: number,
    ) => {
        const detailedReportsUrl = `reports/transactions-by-account/${selectedAccount}/${start_date_ymd}/${end_date_ymd}/${periodText}/${time}`;
        history.push(detailedReportsUrl);
    };
    const dispatchReportsDataToStore = (data: BalanceSheetReportRoot) => {
        store.dispatch({
            type: reportsDataActions.SET_REPORTS_DATA,
            state: data,
        });
    };
    
    const handleError = (error: ApiError) =>{
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            });
            return;
        }
        showError(error?.statusText);
    }
    const generateTransactionsReports = (data: any) => {
        const {
            accountId,
            businessId,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time,
        } = fetchAPIQueryParams(data.from, data.to);
        if (!businessId) return;
        setIsLoading(true);
        getTransactionsReport(
            accountId,
            businessId,
            data?.account?.id,
            start_date_ymd,
            end_date_ymd,
            periodText,
            time
        )
            .then((result: unknown) => {
                const responseData = result as BalanceSheetReportRoot;
                dispatchReportsDataToStore(responseData);
                navigateToTransactionDetailedReports(
                    data?.account?.id,
                    start_date_ymd,
                    end_date_ymd,
                    periodText,
                    time
                );
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleError(error);
            });
    };
    const resetErrorAlert = () => {
        setErrorAlert({
            isShow: false,
            message: '', 
        });
    };
    return (
        <div>
            <SelectAccountAndDates
                pageTitle='Transactions by Account'
                tooltipText={TOOLTIP_TEXT}
                chips={chips}
                businessName={businessName ?? ''}
                generateReport={generateTransactionsReports}
                isLoading={isLoading}
                accountList={accountList}
                errorAlert={errorAlert}
                resetErrorAlert={resetErrorAlert}
                preSelectAccount={preselectAccount}
                setPreselectAccount={setPreselectAccount}
                disableFutureDate
                preSelectedDates={{startDate, endDate}}
                isThisYearSelected={isThisYearSelected}
            />
        </div>
    );
}

const mapStateToProps = ({ category }: ApplicationStore) => ({
    category,
});

export default connect(mapStateToProps)(TransactionsByAccount);
