import React from 'react'
import {
    Button,
    List,
    ListItem,
    makeStyles,
    Theme,
    Typography,
    Icon,
    Hidden,
    useMediaQuery,
} from '@material-ui/core'

import { commonStyles, mergeStyle } from '../../styles/commonStyles'
import { Link } from 'react-router-dom'

const styles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        height: '100%',
    },
    title: {
        fontSize: '34px',
        lineHeight: '40px',
        textAlign: 'center',
    },
    smTitle: {
        marginTop: '20px',
        marginLeft: '20px',
    },
    marginContent: {
        marginTop: '40px',
    },
    title2: {
        fontSize: '20px',
    },
    listRoot: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '16px',
    },
    listItem: {
        width: '100%',
        marginLeft: '11px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    info: {
        width: '100%',
        fontSize: theme.breakpoints.down('sm') ? '12px' : '18px',
        textAlign: 'center',
    },
    info2: {
        width: '100%',
        marginTop: '12px',
        textAlign: 'center',
    },
    separator: {
        width: '100%',
        textAlign: 'center',
        margin: '50px',
    },
    checkImg: {
        width: '22px',
    },
}))

function GettingStarted(props: any) {
    const classes = styles()
    const commonStyle = commonStyles()

    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    const checkIcon = (
        <Icon>
            <img
                alt={`link`}
                className={classes.checkImg}
                src={require(`../../assets/icons-svg/checkIcon.svg`).default}
            />
        </Icon>
    )

    return (
        <div className={classes.content} data-cy={'getting-started-screen'}>
            <Hidden smUp>
                <span className={classes.smTitle}>Banking</span>
            </Hidden>
            <Typography
                variant={smDevice ? 'subtitle1' : 'h2'}
                className={smDevice ? classes.smTitle : classes.title}
            >
                Get Payroll with 1-800Accountant
            </Typography>

            <div
                className={
                    smDevice
                        ? ''
                        : mergeStyle(
                              commonStyle.flex,
                              commonStyle.justifyCenter
                          )
                }
            >
                <List className={classes.listRoot}>
                    <ListItem>
                        {checkIcon}
                        <Typography
                            variant="body2"
                            className={classes.listItem}
                        >
                            Tailored with the right features for your needs
                        </Typography>
                    </ListItem>
                    <ListItem>
                        {checkIcon}
                        <Typography
                            variant="body2"
                            className={classes.listItem}
                        >
                            Pay your employees in just a few clicks
                        </Typography>
                    </ListItem>
                    <ListItem>
                        {checkIcon}
                        <Typography
                            variant="body2"
                            className={classes.listItem}
                        >
                            We file your payroll taxes automatically
                        </Typography>
                    </ListItem>
                </List>
            </div>
            <div
                className={mergeStyle(
                    commonStyle.flex,
                    commonStyle.justifyCenter
                )}
            ></div>
            <div
                className={mergeStyle(
                    commonStyle.flex,
                    commonStyle.justifyCenter
                )}
            >
                <Link
                    to={'/purchase/products'}
                    className={commonStyle.textDecorationNone}
                >
                    <Button color="primary" variant="contained">
                        Get Payroll
                    </Button>
                </Link>
            </div>
            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.info2}
            >
                Payroll is handled through Gusto
            </Typography>
        </div>
    )
}

export default GettingStarted
