import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import {
    ArrowDownwardOutlined,
    Check,
    CheckCircleOutline,
} from '@material-ui/icons';

import UiText from '../../common/ui/UiText';
import FormHeaderText from './FormHeaderText';
import { AppData, ApplicationStore, CompanyStepInfo } from '../../../models';
import Loader from '../../common/Loader';
import { connect } from 'react-redux';
import {
    getTermsOfService,
    accceptTos
} from '../../../services/apiService';
import NextActionButton from './NextActionButton';
import { showAlert } from '../../../store/actions/feedback';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const useStyle = makeStyles<Theme, ThemeColors>(() => ({
    accepted: {
        marginLeft: '10px',
    },
    scroll: {
        position: 'fixed',
        bottom: '15%',
        left: '75%',
        transform: `translate(-50%, 0)`,
    },
    scrollToBottom: {
        width: '56px',
        height: '56px',
        color: colorTheme => colorTheme.primaryWhite,
        backgroundColor: colorTheme => colorTheme.primary,
        '&:hover': {
            backgroundColor: colorTheme => colorTheme.primary,
        },
    },
}));

interface TermsOfServicesProps {
    appData: AppData;
    companyStepInfo: CompanyStepInfo;
    refreshSteps: () => void;
    showAlert: any;
}

function TermsOfServices({
    appData,
    companyStepInfo,
    refreshSteps,
    showAlert,
}: TermsOfServicesProps) {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme);

    const [submittingForm, setSubmittingForm] = useState(false);
    const tosAccepted =
        companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status
            .tos_acceptance;
    const [isChecked, setIsChecked] = useState(tosAccepted);
    const [loading, setLoading] = useState(true);
    const [tos, setTos] = useState('');
    const scrollTo = useRef<HTMLDivElement>(null);
    const [hideScrollToBottom, setHideScrollToBottom] = useState(false);

    useEffect(() => {
        getTermsOfService(appData.current_business_id).then((res: any) => {
            setTos(res.tos_text);
            setLoading(false);
        });
    }, [appData.current_business_id]);

    const acceptTos = () => {
        if (tosAccepted) {
            refreshSteps();
        } else {
            setSubmittingForm(true);
            accceptTos(appData.current_business_id)
                .then(() => {
                    refreshSteps();
                })
                .catch(() => {
                    setSubmittingForm(false);
                    showAlert({
                        alertText:
                            'Sorry, some error occurred. Please try again.',
                        alertType: 'error',
                    });
                });
        }
    };

    const onScroll = (e: any) => {
        const bottom =
            (e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight) || (e.target.scrollHeight - e.target.scrollTop -
            e.target.clientHeight < 10);
        if (bottom) {
            setHideScrollToBottom(true);
        }
    };

    return (
        <Grid
            style={{
                height: 'calc(100% - 108px)',
                position: 'relative',
                overflow:
                    loading || companyStepInfo.loadingStepInfo
                        ? 'hidden'
                        : 'auto',
            }}
            onScroll={onScroll}
        >
            <FormHeaderText heading='Terms of Service' />

            {loading || companyStepInfo.loadingStepInfo ? (
                <Loader />
            ) : (
                <>
                    <UiText>
                        This determines how often your employees are paid.
                    </UiText>
                    <Box mt={1}>
                        <UiText variant='hatchback_125'>
                            Using 1800Accountant’s payroll features requires
                            that you read and accept Gusto’s terms of service.
                        </UiText>
                    </Box>
                    <Box my={4}>
                        <UiText weight='medium_500' variant='truck_175'>
                            Gusto’s Terms of Service
                        </UiText>
                    </Box>
                    <Box>
                        <UiText>
                            <div
                                dangerouslySetInnerHTML={{ __html: tos }}
                            ></div>
                        </UiText>
                    </Box>
                    {!hideScrollToBottom && (
                        <Box className={classes.scroll}>
                            <IconButton
                                className={classes.scrollToBottom}
                                aria-label='scroll-down'
                                color='secondary'
                                onClick={() => {
                                    scrollTo?.current?.scrollIntoView({
                                        behavior: 'smooth',
                                    });
                                }}
                            >
                                <ArrowDownwardOutlined />
                            </IconButton>
                        </Box>
                    )}

                    <Box my={4}>
                        {isChecked ? (
                            <Box display='flex' color={colorTheme.tertiary}>
                                <CheckCircleOutline />
                                <UiText
                                    weight='semi_bold_600'
                                    className={classes.accepted}
                                >
                                    Terms and Conditions Accepted
                                </UiText>
                            </Box>
                        ) : (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => setIsChecked(!isChecked)}
                                startIcon={<Check />}
                            >
                                Accept Terms and Conditions
                            </Button>
                        )}
                    </Box>
                    <div ref={scrollTo}></div>
                    <Box my={2} mt={4}>
                        <NextActionButton
                            loading={submittingForm}
                            submitAction={acceptTos}
                            disabled={!isChecked}
                        />
                    </Box>
                </>
            )}
        </Grid>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
});
export default connect(mapStateToProps, { showAlert })(
    TermsOfServices
);
