import React from 'react';
import {
    Divider,
    makeStyles,
    Theme,
    Checkbox,
    ListItem,
    ListItemIcon
} from '@material-ui/core'
import { useThemeContext } from './whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../styles/models/Colors.interface';

interface ListItemProps {
    handleItemClick?: () => void;
    isItemSelected?: boolean | undefined;
    renderCheckbox?: boolean
    primaryIcon?: React.ReactNode;
    primaryNode?: React.ReactNode;
    secondaryIcon?: React.ReactNode;
    secondaryNode?: React.ReactNode;
    handleCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    listItemIconRootStyle?: any
    checkedItem?: boolean | undefined
    listItemGutterStyle?: any
    lastItem?: boolean
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        selectedItem: {
            background: (colorTheme) => `${colorTheme.green500} !important`,
            borderRadius: '5px'
        },
        ListDetail: {
            display: 'flex',
            width: '100%',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            '& .MuiListItemText-primary': {
                maxWidth: '275px',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                },
            },
        },
        navListItemGutters: {
            paddingLeft: '0px 16px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0),
            },
        },
        itemRootStyles: {
            '&:hover': {
                background: (colorTheme) => colorTheme.grey600,
            },
        }
    }
})

const ListElement = ({
    handleItemClick,
    isItemSelected,
    renderCheckbox,
    handleCheck,
    checkedItem,
    primaryIcon,
    primaryNode,
    secondaryIcon,
    secondaryNode,
    listItemIconRootStyle,
    listItemGutterStyle,
    lastItem
}: ListItemProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <>
            <ListItem
                classes={{
                    root: styles.itemRootStyles,
                    gutters: listItemGutterStyle ? listItemGutterStyle : styles.navListItemGutters ,
                    selected: styles.selectedItem
                }}
                alignItems='flex-start'
                selected={isItemSelected}
            >
                {(primaryIcon || renderCheckbox) && <ListItemIcon 
                    classes={{
                        root: listItemIconRootStyle
                    }}>
                    {primaryIcon && primaryIcon}
                    {renderCheckbox && 
                        <Checkbox
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheck?.(e)
                            }}
                            size="small"
                            checked={checkedItem}
                        />
                    }
                </ListItemIcon>}
                <div
                    onClick={handleItemClick}
                    className={styles.ListDetail}
                >
                    { primaryNode && primaryNode }
                    { secondaryNode && secondaryNode }
                    { secondaryIcon && secondaryIcon } 
                </div>
            </ListItem>
            {!lastItem && <Divider />}
        </>
    )
}

export default ListElement;