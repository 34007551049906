import { Item, VehicleDetailsAPI, Vehicles } from '../../components/mileage-log/vehicles/models/vehicle.model';
import { deleteResource, getResource, postResource, patchResource } from '../axiosService';
import { API_PATHS } from '../constants/api.const';

export const getAllVehicleDetails = (id : string, page: string, perPage: string) : Promise<Vehicles> =>{
    const url = `${API_PATHS.VEHICLES.ALL}`.replace(':id', id).replace(':page', page).replace(':perPage', perPage)
    return getResource(url);
}
export const postVehicle = (accountId : string, body: VehicleDetailsAPI) : Promise<Item> =>{
    const url =  `${API_PATHS.VEHICLES.ADD}`.replace(':accountId', accountId)
    return postResource(url, body)
}
export const deleteVehicle = (accountId: string, vehicleId: string) : Promise<unknown> =>{
    const url = `${API_PATHS.VEHICLES.DELETE}`.replace(':accountId', accountId).replace(':vehicleId',vehicleId)
    return deleteResource(url);
}
export const editVehicle = (accountId: string, vehicleId: string, body: VehicleDetailsAPI) : Promise<unknown> =>{
    const url = `${API_PATHS.VEHICLES.EDIT}`.replace(':accountId', accountId).replace(':vehicleId',vehicleId)
    return patchResource(url, body);
}
