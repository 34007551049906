import { Alert } from '@material-ui/lab'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import UiText from '../../common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { Box, Grid, Hidden, makeStyles, Theme } from '@material-ui/core'
import { CalendarTodayOutlined } from '@material-ui/icons'
import moment from 'moment'
import { Form, Formik } from 'formik'
import NextActionButton from '../../payroll/common/NextActionButton'
import { useState } from 'react'
import { createPayrollSchedule } from '../../../services/apiService/adp-payroll/company'
import * as Yup from 'yup'
import { ADPCompanyDetails } from '../../../models/adp-payroll/company'
import { useADPCompanyContext } from '../provider/CompanyProvider'
import { OnboardingStepInfo } from '../../../models/adp-payroll/company'
import { requestErrorHandler } from '../../../services/formService'
import { toRem16 } from '../../../styles/commonStyles'
import FormHeaderText from '../../payroll/common/FormHeaderText'
import useDeviceSize from '../../../hooks/useDeviceSize'
import UiButton from '../../common/ui/UiButton'
import { useStyles } from './CompanyCommonStyles'
import { getDate } from 'date-fns'

const usePayrollStyles = makeStyles((theme: Theme) => ({
    containerStyle: {
        marginTop: '1rem',
        maxHeight: 'calc(100svh - 20rem)',
        overflowY: 'auto',
        marginBottom: '3rem',
        paddingRight: '1rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem'
        }
    },
    headerStyle: {
        marginTop: '1rem',
    },
    questionSection: {
        margin: '1.5rem 0 0 0',
    },
    questionLabel: {
        margin: `0 0 1rem 0`,
    },
    alternateTextStyle: {
        paddingLeft: '0.8rem',
        marginTop: toRem16(6),
    },
    warningContainer: {
        marginTop: '1rem',
    },
}))

const AlternateText = ({ infoText }: { infoText: string }) => {
    const { colorTheme } = useThemeContext()
    const classes = usePayrollStyles(colorTheme)
    return (
        <UiText
            className={classes.alternateTextStyle}
            variant="moped_75"
            textColor="textSecondary"
        >
            {' '}
            {infoText}
        </UiText>
    )
}


const PreSaveWarning = () => {
    return (
        <UiText>
            Payroll frequency details can’t be edited after proceeding to the
            next step.
        </UiText>
    )
}

const WeeklyFirstPay = () => {
    return (
        <AlternateText
            infoText={`Select a date in the future that falls on a weekday. 
        Whatever day of the week the date you select 
        falls on will be the day of the week all 
        subsequent payments will be made.`}
        />
    )
}

const MonthlyFirstPay = () => {
    return (
        <AlternateText
            infoText={`Select a date in the future. Whatever day of the \
        month you select will become the day of the month that all \ 
        subsequent payments will be made. If you select the 28th, 
        29th, 30th or 31st of the month, all subsequent payments will
        be made on the last day of each month.`}
        />
    )
}

const BiWeeklyFirstPay = () => {
    return (
        <AlternateText
            infoText={`Select a date in the future that falls on a weekday.
            Whatever day of the week the date you select falls on will 
            be the day of the week all subsequent payments will be made.`}
        />
    )
}

const validDateTest = () => ({
    name: 'is-valid-date',
    test: (value:any) => {
      if (!value) return false;
      return moment(value, 'YYYY-MM-DD', false).isValid();
    },
    message: 'Invalid date format',
})

const validationSchema = Yup.object({
    frequency: Yup.string().required('Frequency is required'),
    first_payment_date: Yup.string()
        .required('First payment date is required'),
    first_work_period_start_date: Yup.string()
        .required('First work period start date is required')
        .test(validDateTest()),
    first_work_period_end_date: Yup.string()
        .required('First work period end date is required')
        .test(validDateTest()),
})

const INITIAL_DATA = {
    frequency: '',
    first_payment_date: '',
    first_work_period_start_date: '',
    first_work_period_end_date: '',
}
const PayrollSchedule = ({
    refreshSteps,
    goBack,
}: {
    refreshSteps: () => void
    goBack: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const classes = usePayrollStyles(colorTheme)
    const styles = useStyles(colorTheme)
    const {
        companyDetails,
        companyStepInfo,
        setRefetchCompany,
        isCompanyOnboarding,
        isCompanyActive,
        formikRef,
    }: {
        companyDetails: ADPCompanyDetails
        companyStepInfo: OnboardingStepInfo
        setRefetchCompany: React.Dispatch<React.SetStateAction<boolean>>
        isCompanyOnboarding: boolean
        isCompanyActive: boolean
        formikRef: any
    } = useADPCompanyContext()
    const [loading, setLoading] = useState(false)
    const disableCompanyEdit = isCompanyOnboarding || isCompanyActive

    const isFifteenth = (date: any) => getDate(date) === 15;

    const isPayrollStepCompleted =
        companyStepInfo?.onboarding_steps_status.payroll_frequency_addition

    let initialValues = INITIAL_DATA

    if (isPayrollStepCompleted) {
        const payrollDetails = companyDetails?.paySchedule
        initialValues = {
            ...payrollDetails,
            first_payment_date: payrollDetails?.first_payment_date as string,
            first_work_period_start_date: payrollDetails?.first_work_period_start_date as string,
            first_work_period_end_date: payrollDetails?.first_work_period_end_date as string,
            ...(payrollDetails?.frequency === 'SemiMonthly' ? {
                first_payment_date: isFifteenth(new Date(payrollDetails?.first_payment_date as string)) ? 'firstAndFifth' : 'fifthAndLast',
            } : {

            }), 
        }
    }

    const onSubmit = (values: any) => {
        let formattedDate = ''
        setLoading(true)
        const currentDate = moment()
        if (values?.first_payment_date === 'firstAndFifth') {
            currentDate.date(15)
            formattedDate = currentDate.format('MM/DD/YYYY')
        }
        if (values?.first_payment_date === 'fifthAndLast') {
            currentDate.endOf('month')
            formattedDate = currentDate.format('MM/DD/YYYY')
        }

        let params = {
            ...values,
            ...(values?.frequency === 'SemiMonthly'
                ? { first_payment_date: formattedDate }
                : {
                      first_payment_date: values?.first_payment_date,
                  }),
            first_work_period_start_date: values?.first_work_period_start_date,
            first_work_period_end_date: values?.first_work_period_end_date,
        }

        requestErrorHandler(
            createPayrollSchedule(params).then((res) => {
                if (res) {
                    setLoading(false)
                    refreshSteps()
                    setRefetchCompany(true)
                }
            }),
            true,
            () => {
                setLoading(false)
            },
        )
    }

    const { isSmDevice, isXsDevice } = useDeviceSize()

    return (
        <div>
            <FormHeaderText
                heading="Payroll Schedule"
                hideOnMobile={isSmDevice}
            />
            <UiText className={isSmDevice ? classes.headerStyle : ''}>
                {' '}
                This determines how often your employees are paid
            </UiText>
            <div className={classes.containerStyle}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    innerRef={formikRef}
                >
                    {({ values, submitForm, setFieldValue }) => {
                        const isMonthly = values?.frequency === 'Monthly'
                        const twicePerMonth =
                            values?.frequency === 'SemiMonthly'
                        const isBiWeekly = values?.frequency === 'BiWeekly'
                        const isWeekly = values?.frequency === 'Weekly'

                        return (
                            <Form>
                                <div className={classes.questionSection}>
                                    <UiText
                                        variant="moped_75"
                                        textColor="secondary"
                                    >
                                        (Required)
                                    </UiText>
                                    <UiText className={classes.questionLabel}>
                                        {' '}
                                        How often do you pay your employees ?
                                    </UiText>
                                    <UiFormControlSelection
                                        type="radio"
                                        fieldName="frequency"
                                        radioOptionsData={[
                                            {
                                                label: 'Every Week',
                                                value: 'Weekly',
                                            },
                                            {
                                                label: 'Every other week',
                                                value: 'BiWeekly',
                                            },
                                            {
                                                label: 'Twice per month',
                                                value: 'SemiMonthly',
                                            },
                                            {
                                                label: 'Monthly',
                                                value: 'Monthly',
                                            },
                                        ]}
                                    />
                                </div>
                                {!twicePerMonth && (
                                    <div className={classes.questionSection}>
                                        <UiText
                                            variant="moped_75"
                                            textColor="secondary"
                                        >
                                            (Required)
                                        </UiText>
                                        <UiText
                                            className={classes.questionLabel}
                                        >
                                            When is first date you will pay your
                                            employees?
                                        </UiText>
                                        <Grid item md={10}>
                                            <UiFormControlSelection
                                                label={
                                                    'When is first date you will pay your employees?'
                                                }
                                                type="KeyboardDatePicker"
                                                fieldName={'first_payment_date'}
                                                dateType="string"
                                                placeholder="MM/DD/YYYY"
                                                dateFormat="MM/DD/YYYY"
                                                size={
                                                    isXsDevice
                                                        ? 'medium'
                                                        : 'small'
                                                }
                                            />
                                            {isWeekly && WeeklyFirstPay()}
                                            {isMonthly && MonthlyFirstPay()}
                                            {isBiWeekly && BiWeeklyFirstPay()}
                                        </Grid>
                                    </div>
                                )}

                                {/* { Twice Per Month Payday Selection} */}
                                {twicePerMonth && (
                                    <div className={classes.questionSection}>
                                        <UiText
                                            variant="moped_75"
                                            textColor="secondary"
                                        >
                                            (Required)
                                        </UiText>
                                        <UiText> Paydays </UiText>
                                        <UiFormControlSelection
                                            type="radio"
                                            fieldName="first_payment_date"
                                            fastField={false}
                                            onChange={(e) => {}}
                                            radioOptionsData={[
                                                {
                                                    label: '1st and 15th of the month.',
                                                    value: 'firstAndFifth',
                                                },
                                                {
                                                    label: '15th and the last day of the month',
                                                    value: 'fifthAndLast',
                                                },
                                            ]}
                                        />
                                    </div>
                                )}

                                <div className={classes.questionSection}>
                                    <UiText
                                        variant="moped_75"
                                        textColor="secondary"
                                    >
                                        (Required)
                                    </UiText>
                                    <UiText className={classes.questionLabel}>
                                        When is the start of the first pay
                                        period ?
                                    </UiText>
                                    <Grid item md={10}>
                                        <UiFormControlSelection
                                            dateType="string"
                                            disableFutureDate={false}
                                            showFloatingLabel={true}
                                            type="KeyboardDatePicker"
                                            label={
                                                'When is the start of the first pay period '
                                            }
                                            fieldName="first_work_period_start_date"
                                            endIcon={<CalendarTodayOutlined />}
                                            placeholder="MM/DD/YYYY"
                                            dateFormat="MM/DD/YYYY"
                                            size={
                                                isXsDevice ? 'medium' : 'small'
                                            }
                                        />
                                        <AlternateText
                                            infoText={
                                                'This will be the first day your employees are paid on this schedule.'
                                            }
                                        />
                                    </Grid>
                                    <div className={classes.questionSection}>
                                        <UiText
                                            variant="moped_75"
                                            textColor="secondary"
                                        >
                                            (Required)
                                        </UiText>
                                        <UiText
                                            className={classes.questionLabel}
                                        >
                                            When is the end of the first pay
                                            period ?
                                        </UiText>
                                        <Grid item md={10}>
                                            <UiFormControlSelection
                                                dateType="string"
                                                disableFutureDate={false}
                                                showFloatingLabel={true}
                                                type="KeyboardDatePicker"
                                                label={
                                                    'When is the end of the first pay period'
                                                }
                                                fieldName="first_work_period_end_date"
                                                endIcon={
                                                    <CalendarTodayOutlined />
                                                }
                                                placeholder="MM/DD/YYYY"
                                                dateFormat="MM/DD/YYYY"
                                                size={
                                                    isXsDevice
                                                        ? 'medium'
                                                        : 'small'
                                                }
                                            />
                                            <AlternateText
                                                infoText={
                                                    'The First Period End Date is always one week before the Schedule Start Date.'
                                                }
                                            />
                                        </Grid>
                                    </div>
                                </div>
                                {PreSaveWarning}
                                <Hidden smDown>
                                    {!disableCompanyEdit && (
                                        <Box my={2} mt={4} mb={4}>
                                            <NextActionButton
                                                loading={loading}
                                                submitAction={submitForm}
                                                arrowIcon={true}
                                            />
                                        </Box>
                                    )}
                                </Hidden>
                                <Hidden mdUp>
                                    {!disableCompanyEdit && (
                                        <div
                                            className={
                                                styles.nextPrevContainer
                                            }
                                        >
                                            <NextActionButton
                                                customClass={styles.nxtBtn}
                                                loading={loading}
                                                justifyLoader='center'
                                                submitAction={submitForm}
                                                arrowIcon={true}
                                            />
                                            <UiButton
                                                btnType="hyperlink"
                                                label="Previous"
                                                fullWidth
                                                customClass={styles.nxtBtn}
                                                handleClick={goBack}
                                            />
                                        </div>
                                    )}
                                </Hidden>
                            </Form>
                        )
                    }}
                </Formik>
                <Alert
                    className={classes.warningContainer}
                    icon={false}
                    severity="error"
                >
                    <UiText>
                        Payroll frequency details can’t be edited after
                        proceeding to the next step.
                    </UiText>
                </Alert>
            </div>
        </div>
    )
}

export default PayrollSchedule
