import React from 'react';
import { useHistory } from 'react-router-dom'
import {
    makeStyles,
    Theme,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core'
import { Invoice } from '../../../models'
import ListElement from '../../common/ListElement'
import { longDateFormat } from '../../../utils/dateUtil'
import { getInvoiceStatus, statusIcon } from './InvoiceUtil'
import Icon from '../../common/Icon'
import { currencyFormatter } from '../../../utils/appUtil'
import { mergeStyle } from '../../../styles/commonStyles'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        emptyDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        rightItemText: {
            textTransform: 'capitalize',
            textAlign: 'right',
        },
        listItemIconRootStyle: {
            minWidth: '40px',
        },
        listItemIcon: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '6px',
        },
        listRootStyles: {
            minWidth: '30px',
            marginLeft: '8px',

        },
        invoiceStatusIcon: {
            height: '40px',
            width: '40px',
            display: 'inline-flex',
            justifyContent: 'center',
            borderRadius: '50%',
        },
        defaultStatusBackground: {
            backgroundColor: (colorTheme) => colorTheme.grey100,
        },
        paidChipColor: {
            backgroundColor: (colorTheme) => colorTheme.teal200,
        },
        overDueChipColor: {
            backgroundColor: (colorTheme) => colorTheme.yellow100,
        },
    }
})

export default function InvoiceItem({
    invoice,
    invoiceId,
    selectedInvoice,
    setSelectedInvoice,
    lastItem,
}: {
    invoiceId: string
    invoice: Invoice
    selectedInvoice: Invoice | undefined
    setSelectedInvoice: React.Dispatch<
        React.SetStateAction<Invoice | undefined>
    >
    lastItem: boolean
}) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const history = useHistory()
    const invoiceStatus = getInvoiceStatus(invoice)

    const defaultStyle = mergeStyle(
        styles.invoiceStatusIcon,
        styles.defaultStatusBackground
    )
    const paidStyle = mergeStyle(styles.invoiceStatusIcon, styles.paidChipColor)
    const overdueStyle = mergeStyle(
        styles.invoiceStatusIcon,
        styles.overDueChipColor
    )

    const invoiceStatusStyle =
        invoiceStatus === 'Paid'
            ? paidStyle
            : invoiceStatus === 'Overdue'
            ? overdueStyle
            : defaultStyle

    return (
        <div>
            <ListElement
                handleItemClick={() => {
                    setSelectedInvoice(invoice)
                    history.push(`/invoices/${invoiceId}`)
                }}
                isItemSelected={invoiceId === selectedInvoice?.id}
                primaryNode={
                    <ListItemText
                        primary={invoice?.contact_data?.name}
                        secondary={
                            <>
                                <div>#{invoice.number}</div>
                                <div>{invoiceStatus}</div>
                            </>
                        }
                    />
                }
                secondaryNode={
                    <ListItemText
                        className={styles.rightItemText}
                        primary={
                            <span>
                                {currencyFormatter.format(invoice?.total)}
                            </span>
                        }
                        secondary={
                            <>
                                <div>
                                    Due {longDateFormat(invoice.due_date)}
                                </div>
                                <div>
                                    Issued{' '}
                                    {longDateFormat(invoice.invoice_date)}
                                </div>
                            </>
                        }
                    />
                }
                secondaryIcon={
                    <ListItemIcon
                        className={styles.listItemIcon}
                        classes={{ root: styles.listRootStyles }}
                    >
                        <div className={invoiceId === selectedInvoice?.id ? defaultStyle : invoiceStatusStyle}>
                            <Icon
                                icon={statusIcon[invoiceStatus]}
                                size={'20px'}
                            />
                        </div>
                    </ListItemIcon>
                }
                lastItem={lastItem}
            />
        </div>
    )
}
