import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toRem16 } from '../../../styles/commonStyles'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import { useTaxEngagementPlan } from '../hooks/useTaxEngagementPlan'
import CircularProgressWithLabel from '../libs/CircularProgressWithLabel'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        container: {
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            minHeight: toRem16(120),
            width: '100%',
        },
        headerText: {
            margin: '1rem 0 0 1rem',
        },
        progressContainer: {
            display: 'flex',
            margin: '1rem 1rem 0.5rem 1rem',
        },
        progressStep: {
            marginLeft: '1rem',
            '& .header': {
                color: colorTheme => colorTheme.grey400,
            }
        },
        loaderStyle: {
            height: toRem16(120),
        },
        buttonStyle: {
            margin: '0 0 1rem 1rem',
            background: colorTheme => colorTheme.primary,
            color: colorTheme => colorTheme.white100,
            '&:hover': {
                background: colorTheme => colorTheme.orange100,
                color: colorTheme => colorTheme.white100,
            },
        },
    }
})

const TaxEngagementPlanMobileHeader = () => {
    const history = useHistory()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)

    const {
        isDataLoading,
        totalTasksCompletedCount,
        totalTasksCount,
        taskCompletionPercentage,
    } = useTaxEngagementPlan()

    return (
        <div className={classes.container}>
            {isDataLoading ? (
                <div className={classes.loaderStyle}>
                    <Loader />
                </div>
            ) : (
                <>
                    <UiText
                        className={classes.headerText}
                        variant="hatchback_125"
                        weight="medium_500"
                    >
                        {' '}
                        Your Tax Engagement Plan{' '}
                    </UiText>
                    <div className={classes.progressContainer}>
                        <CircularProgressWithLabel
                            value={taskCompletionPercentage}
                            color={colorTheme.primary}
                            bgColor={colorTheme.grey200}
                            size={60}
                        />
                        <div className={classes.progressStep}>
                            <UiText
                                weight="semi_bold_600"
                                className="header"
                            >
                                What we’re doing for you
                            </UiText>
                            <UiText variant="hatchback_125" weight="semi_bold_600">
                                {totalTasksCompletedCount} of {totalTasksCount}{' '}
                                Complete
                            </UiText>
                        </div>
                    </div>
                    <Button
                        className={classes.buttonStyle}
                        onClick={() => {
                            history.push('/dashboard/tax-plan')
                        }}
                    >
                        View Your Plan
                    </Button>
                </>
            )}
        </div>
    )
}

export default TaxEngagementPlanMobileHeader