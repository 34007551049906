import { Action, SET_LOCAL_CONFIG } from '../actions/featureData'
import features from '../../config/features.json';

export default function featureConfig(state = features, action: Action): any {
    switch (action.type) {
        case SET_LOCAL_CONFIG:
            return action.state
        default:
            return state
    }
}
