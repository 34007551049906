import { Button, Theme, makeStyles } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import React, { useEffect, useState, useCallback } from 'react'
import UiText from '../../../common/ui/UiText'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import RequestInformationDialog from './RequestInformation'
import PersonalInformation from './PersonalInformation'
import { useParams } from 'react-router-dom'
import {
    editContractor,
    getContractorInfo,
} from '../../apiServices/contractor.api'
import Loader from '../../../common/Loader'
import {
    requestErrorHandler,
    showError,
} from '../../../../services/formService'
import { Contractor } from '../../models/contractor.model'
import { Container, InlineFlex } from '../../Wrapper'
import { toRem16 } from '../../../../styles/commonStyles'
import InfoI from '../../../../assets/icons-svg/InfoI.svg'
import QuestionMarkIcon from '../../../../assets/icons-svg/question-mark.svg'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { useContractorContext } from '../../ContractorProvider'
import moment from 'moment'

interface ContactorDetailsProps {
    selectedContractor?: any
    setSelectedContractor?: React.Dispatch<React.SetStateAction<any>>
    reloadState?: () => void
}

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    container: {
        marginTop: '1.5rem',
    },
    margin: {
        marginTop: '1.5rem',
    },
    infoSection: {
        marginTop: '2rem',
    },
    resend: {
        color: (colorTheme) => `${colorTheme.blue200}`,
    },
    emailSendText: {
        color: (colorTheme) => `${colorTheme.grey400}`,
    }
}))

const SecondaryInfo = (props: any) => {
    const {
        message,
        handleYes,
        action,
        isLoading,
        dismissedNote,
        setDismissedNote,
        selectedContractor,
        icon,
    } = props
    const handleDismiss = () => {
        const oldData: any = { ...dismissedNote }
        oldData[`${selectedContractor.id}`] = 'dismissed'
        setDismissedNote(oldData)
    }
    const Styles = makeStyles<Theme, ThemeColors>(() => ({
        buttons: {
            marginTop: toRem16(12),
            display: 'flex',
            gap: toRem16(12),
        },
    }))
    const { colorTheme } = useThemeContext()
    const classes = Styles(colorTheme)
    return (
        <Container
            padding={toRem16(16)}
            backgroundcolor={'#EFE8DA'}
            borderradius={toRem16(4)}
            margin="0 0 2rem 0"
            minheight="auto"
        >
            <InlineFlex gap={toRem16(8)} alignitems="center">
                <img src={icon || InfoI} alt="circular check icon" />
                <UiText>
                    {message ||
                        'The contractor has not submitted their information yet.'}
                </UiText>
            </InlineFlex>
            {action && (
                <div className={classes.buttons}>
                    {!isLoading ? (
                        <>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleYes}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleDismiss}
                            >
                                No
                            </Button>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            )}
        </Container>
    )
}

const ContractorDetails = ({
    selectedContractor,
    setSelectedContractor,
    reloadState,
}: ContactorDetailsProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const params: { id: string } = useParams()
    const [openRequestDialog, setOpenRequestDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [contractorInfo, setContractorInfo] = useState<
        Contractor | undefined
    >()
    const { currentBusinessId } = useCurrentStore()
    const [isAdding, setIsAdding] = useState<boolean>(false)
    const { loadContractors, contractorsData } = useContractorContext()
    const [dismissedNote, setDismissedNote] = useState<any>({})

    const fetchDetails = useCallback(() => {
        setLoading(true)
        const contractorId = params?.id || selectedContractor?.id
        requestErrorHandler(
            getContractorInfo(contractorId as string).then(
                (contractorInfo: Contractor) => {
                    if (contractorInfo) {
                        setContractorInfo(contractorInfo)
                        setLoading(false)
                    }
                }
            ),
            true,
            () => {
                setLoading(false)
            }
        )
    }, [params?.id, selectedContractor?.id])

    useEffect(() => {
        if (!selectedContractor) {
            fetchDetails()
        }
    }, [fetchDetails, params.id, selectedContractor])

    if (loading) {
        return <Loader />
    }

    const isEmailAlreadySent = () => {
        if (
            selectedContractor.contractor_w_nine[0]?.status === 'waiting_w_nine'
        ) {
            return true
        }
        return false
    }
    
    const previousYear = new Date().getFullYear() - 1
    const handleYes = () => {
        const years = selectedContractor.contractor_w_nine
        years.push({ year: previousYear })
        setIsAdding(true)
        editContractor(currentBusinessId!, {
            ...selectedContractor,
            contractor_w_nine: years,
        })
            .then((res: any) => {
                showError('Added successfully', 'success')
                setIsAdding(false)
                loadContractors()
                setSelectedContractor?.(res)
            })
            .catch(() => {
                showError('Action failed, please try again', 'error')
                setIsAdding(false)
            })
    }

    const isAlreadyAddedYear = () => {
        const index = selectedContractor.contractor_w_nine.findIndex(
            (data: any) => data.year === `${previousYear}`
        )
        return index >= 0 ? false : true
    }

    const getLocalTime = (utcTime: string) => {
        const utcMoment = moment.utc(utcTime);
        const localMoment = utcMoment.local();
        return localMoment.format('DD MMM, YYYY • HH:mm')
    }

    return (
        <div className={classes.container}>
            <RequestInformationDialog
                contractorId={selectedContractor?.id}
                setOpenDialog={setOpenRequestDialog}
                openDialog={openRequestDialog}
                selectedContractor={selectedContractor}
                setSelectedContractor={setSelectedContractor}
                contractors={contractorsData}
            />
            {selectedContractor?.is_complete === false && (
                <SecondaryInfo icon={QuestionMarkIcon} message="Before you can file this contractor’s 1099 you need to collect more information" />
            )}
            {isEmailAlreadySent() && <SecondaryInfo />}
            {isAlreadyAddedYear() &&
                selectedContractor?.id &&
                dismissedNote[`${selectedContractor?.id}`] !== 'dismissed' && (
                    <SecondaryInfo
                        message={`Did you pay this contractor more than $600 in Tax Year ${previousYear}`}
                        handleYes={handleYes}
                        action
                        isLoading={isAdding}
                        selectedContractor={selectedContractor}
                        dismissedNote={dismissedNote}
                        setDismissedNote={setDismissedNote}
                    />
                )}
            <UiText variant="suv_150">
                {selectedContractor?.first_name} {selectedContractor?.last_name}
            </UiText>
            {isEmailAlreadySent() && (
                <UiText className={classes.emailSendText} variant="motorcycle_90" weight="medium_500">
                    {`A W-9 was sent by email on ${getLocalTime(
                        selectedContractor.contractor_w_nine[0]?.email_sent_date
                    )}`}
                </UiText>
            )}
            {isEmailAlreadySent() ? (
                <Button
                    startIcon={<ReplayIcon />}
                    variant="text"
                    onClick={() => {
                        setOpenRequestDialog(true)
                    }}
                    className={classes.resend}
                >
                    <UiText weight="medium_500">Send Again</UiText>
                </Button>
            ) : (
                <Button
                    startIcon={<MailOutlineIcon />}
                    variant="outlined"
                    color="secondary"
                    className={classes.margin}
                    onClick={() => {
                        setOpenRequestDialog(true)
                    }}
                >
                    Send a W-9 to the contractor
                </Button>
            )}
            <div className={classes.infoSection}>
                <PersonalInformation
                    contractorData={selectedContractor || contractorInfo}
                    setSelectedContractor={setSelectedContractor}
                    reloadData={() => {
                        fetchDetails()
                        reloadState?.()
                    }}
                />
            </div>
        </div>
    )
}

export default ContractorDetails
