import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import {
    EmpData,
    EmployeeJobDetails,
} from '../../../../models/payroll/employee'
import EmptySectionView from '../../common/EmptySectionView'
import SectionView from '../../common/SectionView'
import CompensationDetails from '../views/CompensationDetails'
import moment from 'moment'
import Icon from '../../../common/Icon'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

interface JobDetailsProps {
    selectedEmployee?: EmpData | undefined
    currentAddress?: any
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    setCurrentForm: React.Dispatch<React.SetStateAction<string>>
    setSelectedJob: React.Dispatch<React.SetStateAction<any>>
    setSelectedCompensations: React.Dispatch<React.SetStateAction<any>>
    formSubmitCallback?: () => void
}

const JobDetails = ({
    selectedEmployee,
    currentAddress,
    setOpenModal,
    setCurrentForm,
    setSelectedJob,
    setSelectedCompensations,
    formSubmitCallback,
}: JobDetailsProps) => {
    const { colorTheme }: {colorTheme: ThemeColors } = useThemeContext()
    return (
        <div>
            {selectedEmployee?.jobs?.length ? (
                <>
                    {selectedEmployee?.jobs?.map(
                        (currentJob: EmployeeJobDetails) => (
                            <SectionView
                                key={'job_detail'}
                                headerText={'Job Details'}
                                buttonIcon={<Icon icon="edit" svgColor={colorTheme.blue200}/>}
                                buttonText={'Edit'}
                                sectionInfoData={[
                                    {
                                        label: 'Date Hired',
                                        value: (
                                            <>
                                                {moment(
                                                    currentJob?.hire_date
                                                ).format('MMM D, YYYY')}
                                            </>
                                        ),
                                    },
                                    {
                                        label: 'Title',
                                        value: <> {currentJob?.title} </>,
                                    },
                                    {
                                        label: 'Location',
                                        value: (
                                            <>
                                                {currentAddress?.street} <br />
                                                {
                                                    currentAddress?.street_line_2
                                                }{' '}
                                                {currentAddress?.city} <br />
                                                {currentAddress?.state} <br />
                                                {currentAddress?.zip_code} <br />
                                                {currentAddress?.phone_number}
                                            </>
                                        ),
                                    },
                                ]}
                                onClickAction={() => {
                                    setOpenModal(true)
                                    setCurrentForm('JobDetails')
                                    setSelectedJob(currentJob)
                                }}
                                customComponent={
                                    <CompensationDetails
                                        employee={selectedEmployee}
                                        currentJob={currentJob}
                                        compensation={
                                            currentJob?.compensations?.[0]
                                        }
                                        setSelectedCompensations={
                                            setSelectedCompensations
                                        }
                                        formSubmitCallback={formSubmitCallback}
                                        setCurrentForm={setCurrentForm}
                                        buttonIcon={<Icon icon="edit" svgColor={colorTheme.blue200}/>}
                                    />
                                }
                            />
                        )
                    )}
                </>
            ) : (
                <EmptySectionView
                    headerText={'Job Details'}
                    primaryText={'No Job Created Yet'}
                    secondaryText={''}
                    buttonText={'Add Job'}
                    buttonIcon={<AddIcon />}
                    onClickAction={() => {
                        setOpenModal(true)
                        setCurrentForm('JobDetails')
                        setSelectedJob(null)
                    }}
                />
            )}
        </div>
    )
}

export default JobDetails
