import React, { Fragment } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    makeStyles,
    Theme,
    Box,
    Grid,
    Divider,
    Avatar,
    useMediaQuery,
    ListItemAvatar,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../common/Loader'
import UiChip from '../../common/ui/UiChip'
import UiText from '../../common/ui/UiText'
import { EmpData } from '../../../models/payroll/employee'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        width: '100%',
        maxHeight: 'calc(100vh - 300px)',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    selectedMessage: {
        background: (colorTheme) =>`${colorTheme.green500} !important`,
    },
    itemAvatar: {
        backgroundColor: (colorTheme) => colorTheme.primaryGreen,
        marginBottom: '8px',
        marginLeft: '8px',
        marginRight: '8px',
    },
    secondaryText: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        marginLeft: '8px',
    },
    labelStyle: {
        marginTop: '10px',
    },
}))

function EmployeeListView(props: {
    items: EmpData[]
    loadMore: () => void
    fetchMore: boolean
    personalAccountId?: string
    searchResult?: boolean
    selectedData?: EmpData
    setSelectedData: React.Dispatch<EmpData>
}) {
    const history = useHistory()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)

    return (
        <List className={classes.listRoot} id={'scrollableDiv'}>
            <div>
                <InfiniteScroll
                    dataLength={props?.items.length}
                    next={() => {
                        props.loadMore()
                    }}
                    hasMore={props?.fetchMore}
                    scrollableTarget={'scrollableDiv'}
                    loader={
                        <Grid
                            key={'loader'}
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{
                                paddingBottom: '16px',
                                paddingTop: '16px',
                            }}
                        >
                            <Loader />
                        </Grid>
                    }
                >
                    {props?.items.map((emp: EmpData, index: number) => (
                        <Fragment key={emp?.id}>
                            <ListItem
                                button
                                classes={{
                                    gutters: classes.navListItemGutters,
                                    selected: classes.selectedMessage,
                                }}
                                alignItems="flex-start"
                                selected={props.selectedData?.id === emp?.id}
                                onClick={() => {
                                    props?.setSelectedData(emp)
                                    if (smDevice) {
                                        history.push(
                                            `/payroll/employees/${emp.id}`
                                        )
                                    }
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        classes={{
                                            colorDefault: classes.itemAvatar,
                                        }}
                                    >
                                        {emp.first_name.charAt(0).toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                    primaryTypographyProps={{
                                        style: {
                                            fontWeight: true
                                                ? 'bold'
                                                : 'normal',
                                        },
                                    }}
                                    primary={
                                        <Fragment>
                                            <UiText
                                                style={{ marginLeft: '8px' }}
                                            >
                                                {emp?.first_name}{' '}
                                                {emp?.last_name}
                                            </UiText>
                                            {emp?.terminated && (
                                                <div
                                                    className={
                                                        classes.labelStyle
                                                    }
                                                >
                                                    <UiChip
                                                        label={
                                                            <Typography variant="body2">
                                                                Terminated
                                                            </Typography>
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Fragment>
                                    }
                                    secondary={
                                        <Box
                                            component="span"
                                            className={classes.secondaryText}
                                        >
                                            {emp?.jobs
                                                ?.map((e: any) => e.title)
                                                .join(',')}
                                        </Box>
                                    }
                                />
                            </ListItem>
                            {index === props.items.length - 1 ? (
                                ''
                            ) : (
                                <Divider variant="inset" />
                            )}
                        </Fragment>
                    ))}
                </InfiniteScroll>
            </div>
        </List>
    )
}

export default EmployeeListView
