import React from 'react'
import { useEffect, useState } from 'react'
import UiDialog from './ui/UiDialog'
import StarIcon from '../../assets/icons-svg/Star.svg'
import { makeStyles, Theme } from '@material-ui/core'
import { updateRating } from '../../services/apiService'
import Loader from './Loader'
import { UserReviewResponse } from '../../models/review.model'
import { ApplicationStore } from '../../models'
import { useStore } from 'react-redux'
import { toRem16 } from '../../styles/commonStyles'
import { showError } from '../../services/formService'
import UiText from './ui/UiText'

const styles = makeStyles((theme: Theme) => ({
    ratingSectionButton: {
        backgroundColor: theme.palette.grey[400],
        margin: '0 0.5rem 0 0',
        maxWidth: toRem16(45),
        maxHeight: toRem16(45),
        '& img': {
            maxWidth: toRem16(45),
            maxHeight: toRem16(45),
        },
    },
    ratingSection: {
        margin: '1rem 0 0 0',
        display: 'flex',
        '& .starColor0': {
            backgroundColor: '#dcdce6', // to do - will be updating color variables once clarified by Phil
        },
        '& .starColor1': {
            backgroundColor: '#ff3721',
        },
        '& .starColor2': {
            backgroundColor: '#ff8621',
        },
        '& .starColor3': {
            backgroundColor: '#ffce00',
        },
        '& .starColor4': {
            backgroundColor: '#73cf11',
        },
        '& .starColor5': {
            backgroundColor: '#00b67a',
        },
    },
}))
export const REVIEW_FOR_TRUST_PILOT = 'Trustpilot'
export const REVIEW_SOURCE = '1800'
const RATINGS = [1, 2, 3, 4, 5]

export const ReviewDialog = () => {
    const [rating, setRating] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [showThanks, setShowThanks] = useState(false)
    const [showTrustPilot, setShowTrustPilot] = useState(false)
    const [loading, setLoading] = useState(false)
    const [embedUrl, setEmbedUrl] = useState('')
    const store: ApplicationStore = useStore().getState()
    const classes = styles()

    useEffect(() => {
        const accountantMode = store.appData.user.accountant_mode
        if (!accountantMode) {
            setShowModal(true)
        }
    }, [store])

    const handleReviewRating = (
        rating: number,
        reviewFor: string,
        uniqueLink: string
    ) => {
        if (rating < 4) {
            setShowThanks(true)
            return
        }
        let popup = window.open('', 'Review Us')
        popup?.document.write('loading ...')
        if (reviewFor === REVIEW_FOR_TRUST_PILOT) {
            setEmbedUrl(uniqueLink)
            setShowTrustPilot(true)
            popup?.close()
            return
        }
        if (popup) {
            popup.location.href = uniqueLink
            setShowThanks(true)
            return 
        }
        setShowThanks(true)
    }
    const onRatingClick = (rating: number) => {
        setLoading(true)
        updateRating(REVIEW_SOURCE, rating)
            .then((response: UserReviewResponse) => {
                setLoading(false)
                handleReviewRating(
                    response.review_rating,
                    response.review_for,
                    response.unique_link
                )
            })
            .catch((error) => {
                setLoading(false)
                showError(error)
                handleClose()
            })
    }
    const handleClose = () => {
        setShowModal(false)
    }
    const handleCloseTrustPilot = () => {
        setShowTrustPilot(false)
        setShowModal(false)
    }
    const returnIframe = () => {
        return {
            __html: `<iframe src="${embedUrl}" style="width: 100%;" height="1000" frameBorder="0"></iframe>`,
        }
    }
    const showTrustPilotModal = (): React.ReactElement => {
        return (
            <UiDialog
                open={showTrustPilot}
                title=""
                handleClose={handleCloseTrustPilot}
                hideTitleSection={true}
                size="md"
                contentStyles={{ padding: '0 !important' }}
            >
                <div dangerouslySetInnerHTML={returnIframe()}></div>
            </UiDialog>
        )
    }
    const getDialogueContent = (): React.ReactElement => {
        if (showThanks) {
            return <UiText>Thank you. We value your feedback.</UiText>
        }
        if (loading) {
            return <Loader />
        }
        return (
            <>
                <UiText>Are you enjoying the 1800Accountant Portal?</UiText>
                <div className={`${classes.ratingSection}`}>
                    {RATINGS.map((star: number) => {
                        return (
                            <div
                                key={star}
                                role="button"
                                className={`${classes.ratingSectionButton} ${
                                    rating >= star
                                        ? 'starColor' + rating
                                        : 'starColor0'
                                }`}
                                onMouseEnter={() => setRating(star)}
                                onMouseLeave={() => setRating(0)}
                                onClick={() => onRatingClick(star)}
                            >
                                <img src={StarIcon} alt={`${star} star`} />
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
    
    return (
        <>
            {showTrustPilot && showTrustPilotModal()}
            <UiDialog
                open={showModal}
                title="Review Us"
                handleClose={handleClose}
                size="xs"
                contentStyles={{ margin: `${toRem16(12)} 0` }}
            >
                {getDialogueContent()}
            </UiDialog>
        </>
    )
}
