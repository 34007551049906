import {
    Box,
    IconButton,
    Typography,
    makeStyles,
    CircularProgress,
    Button,
} from '@material-ui/core';
import {
    Folder as FolderIcon,
    Close,
    LockOutlined,
    ArrowBack,
} from '@material-ui/icons';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Folder, FolderTree } from '../../models';
import { updateDocument } from '../../services/apiService';
import { buildTree } from '../../services/folderService';
import { showAlert, showError } from '../../store/actions/feedback';
import UiDialog from '../common/ui/UiDialog';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

export default function DocumentMove({
    openMove,
    handleMoveClose,
    accountId,
    personalAccountId,
    fileId,
    folders,
}: {
    openMove: boolean;
    handleMoveClose: (result: boolean) => void;
    accountId: string;
    personalAccountId: string;
    fileId: string;
    folders: Folder[];
}) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        closeIconButton: { padding: 0 },
        backIconButton: { padding: 0, marginRight: 8 },
    })();

    const [businessFolderTree, setBusinessFolderTree] = useState<FolderTree>();
    const [personalFolderTree, setPersonalFolderTree] = useState<FolderTree>();
    const [parent, setParent] = useState<Folder & FolderTree>();
    const [parents, setParents] = useState<(Folder & FolderTree)[]>([]);
    const [movingDocument, setMovingDocument] = useState(false);
    const dispatch = useDispatch();

    const handleAfterClose = (result: boolean) => {
        handleMoveClose(result);
        setParents([]);
        setParent(undefined);
        setMovingDocument(false);
    };

    const getFoldersToDisplay = (folders: {
        [key: string]: Folder & FolderTree;
    }) => {
        return Object.keys(folders).map((key) => (
            <Box
                display='flex'
                alignItems='center'
                color={`${colorTheme.grey800}`}
                className='cursor-pointer'
                my={2}
                key={`${folders[key].portal_account_id}-${key}`}
                onClick={() => handleFolderClick(folders[key])}
            >
                <FolderIcon color='inherit' />
                <Box color={`${colorTheme.black100}`} ml={1}>
                    <Typography variant='subtitle2' color='inherit'>
                        {folders[key].title}
                    </Typography>
                </Box>
            </Box>
        ));
    };

    const handleMove = () => {
        setMovingDocument(true);
        const { path, portal_account_id, type } = parent as Folder & FolderTree;
        updateDocument(accountId, fileId, { path, portal_account_id, type })
            .then(() => {
                handleAfterClose(true);
                dispatch(
                    showAlert({
                        alertText: 'Document Moved Successfully!',
                        alertType: 'success',
                    })
                );
            })
            .catch((err) => {
                dispatch(showError({ errorCode: err.status, showError: true }));
                setMovingDocument(false);
            });
    };

    const handleFolderClick = (folder: Folder & FolderTree) => {
        setParents((parents) => parents?.concat(folder));
        setParent(folder);
    };

    const handleBackButton = () => {
        if (parent?.level) {
            parents.pop();
            const newParent = parents.slice(-1)[0];
            setParents([...parents]);
            setParent(newParent);
        } else {
            setParent(undefined);
            setParents([]);
        }
    };

    useEffect(() => {
        const businessTree = buildTree(folders, accountId, 'business');
        const personalTree = buildTree(folders, personalAccountId, 'personal');
        setBusinessFolderTree(() => businessTree);
        setPersonalFolderTree(() => personalTree);
    }, [accountId, folders, personalAccountId]);

    return (
        <UiDialog
            open={openMove}
            handleClose={handleAfterClose}
            title=''
            hideTitleSection={true}
            size='xs'
            contentStyles={{ padding: '0 !important' }}
            actions={
                movingDocument ? (
                    <CircularProgress size={22} />
                ) : (
                    <Fragment>
                        <Button
                            variant='outlined'
                            color='secondary'
                            onClick={() => handleAfterClose(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleMove}
                            disabled={!parent || !!parent.folders}
                        >
                            Move Here
                        </Button>
                    </Fragment>
                )
            }
        >
            <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                borderBottom={`1px solid ${colorTheme.grey200}`}
                px={4}
                py={3}
            >
                {parent ? (
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <IconButton
                            classes={{ root: styles.backIconButton }}
                            onClick={() => handleBackButton()}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant='h6'>{parent.title}</Typography>
                    </Box>
                ) : (
                    <Typography variant='h6'>Select Destination</Typography>
                )}
                <IconButton
                    aria-label='close'
                    classes={{ root: styles.closeIconButton }}
                    onClick={() => handleAfterClose(false)}
                >
                    <Close />
                </IconButton>
            </Box>

            {!folders.length ? (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    my={5}
                >
                    <CircularProgress size={22} />
                </Box>
            ) : parent ? (
                <Box px={4} py={3}>
                    {parent.folders ? (
                        getFoldersToDisplay(parent.folders)
                    ) : (
                        <Box textAlign='center' my={5}>
                            No Sub-Folders
                        </Box>
                    )}
                </Box>
            ) : (
                <Box px={4} py={3}>
                    <Box>
                        <Typography variant='h6' color='secondary'>
                            Business
                        </Typography>

                        {businessFolderTree?.folders &&
                            getFoldersToDisplay(businessFolderTree.folders)}
                    </Box>

                    <Box mt={4}>
                        <Box display='flex' alignItems='center'>
                            <Typography variant='h6' color='secondary'>
                                Private
                            </Typography>
                            <Box ml={2}>
                                <LockOutlined fontSize='small' />
                            </Box>
                        </Box>
                        <Typography variant='caption'>
                            Documents in this section are only visible to you
                        </Typography>

                        {personalFolderTree?.folders &&
                            getFoldersToDisplay(personalFolderTree.folders)}
                    </Box>
                </Box>
            )}
        </UiDialog>
    );
}
