import React, { Fragment, useEffect } from 'react'
import { Grid, List, makeStyles } from '@material-ui/core'
import { Trip } from '../../../models'
import TripItem from './TripItem'
import UiText from '../../../components/common/ui/UiText'
import { useIntersectionObserver } from '../../common/hooks/useIntersectionObserver'
import { ListLoader } from '../../dashboard/Cards/CommonComponents'
import { Purpose } from '../../../models/purposes'

type TripListProps = {
    items: Trip[]
    itemsCount: number
    isLoading: boolean
    page: number
    pageCount: number
    loadMore: (id?: string, page?: number) => void
    selectedTrip: Trip | undefined
    setSelectedTrip: React.Dispatch<React.SetStateAction<Trip | undefined>>
    calledFromPurposes?: boolean
    hasMore?: boolean
    selectedPurpose?:Purpose
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 235px)',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listLoader: {
        paddingBottom: '1rem',
        paddingTop: '1rem',
    },
    loaderStyle: {
        height: '100%',
        padding: '1rem 0 1rem 0',
    },
    tripCount: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
    },
    scroll: {
        maxHeight: '55vh',
        overflow: 'auto',
    },
}))

const TripList = ({
    items,
    itemsCount,
    isLoading,
    page,
    pageCount,
    loadMore,
    selectedTrip,
    setSelectedTrip,
    calledFromPurposes,
    hasMore,
    selectedPurpose,
}: TripListProps) => {
    const classes = useStyles()
    const [ref, entry] = useIntersectionObserver({})
    const isScrollEnd = !!entry?.isIntersecting

    const tripsCountString =
        itemsCount === 1 ? itemsCount + ' Trip' : itemsCount + ' Trips'

    useEffect(() => {
        if (isScrollEnd && page < pageCount) {
            hasMore && loadMore(undefined, page + 1)
        }
    }, [hasMore, isScrollEnd, loadMore, page, pageCount])

    return (
        <Grid item xs={12} justify="space-between">
            {!calledFromPurposes && (
                <div className={classes.tripCount}>
                    <UiText>{tripsCountString}</UiText>
                </div>
            )}
            <List className={classes.root} id="scrollableDiv">
                {items?.map((trip: Trip, index: number) => (
                    <Fragment key={trip?.id}>
                        <TripItem
                            key={trip?.id}
                            tripId={trip?.id}
                            trip={trip}
                            selectedTrip={selectedTrip}
                            setSelectedTrip={setSelectedTrip}
                            lastItem={items.length - 1 === index}
                            calledFromPurposes={calledFromPurposes}
                            selectedPurpose={selectedPurpose}
                        />
                    </Fragment>
                ))}
                {isLoading && <ListLoader />}
                <div ref={ref} id="wanttosee"></div>
            </List>
        </Grid>
    )
}

export default TripList
