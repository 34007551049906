import { Container, Grid, IconButton, makeStyles, Theme, Typography, Tabs, Tab } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationStore, EntityManagementState } from '../../models';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import { NotificationsNone, CalendarToday, PlaceOutlined } from '@material-ui/icons';
import EntityTab from './entity-tab/EntityTab';
import ProductsTab from './products-tab/ProductsTab'
import Loader from '../common/Loader';
import { loadEntityManagamentData } from '../../store/actions/entityManagement';
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import Documents from '../documents/Documents'
import { ThemeColors } from '../../styles/models/Colors.interface';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`panel-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

function EntityManagement({ accountId, businessId, config, entityManagement, loadEntityManagamentData }: {
    accountId: string; businessId: string; config: any; entityManagement: EntityManagementState, loadEntityManagamentData: any
}) {
    const { colorTheme } = useThemeContext()
    const [value, setValue] = useState(0);
    const query = new URLSearchParams(useLocation().search)
    const currentTab = query.get('tab')
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    
    const [entityManagementData, setEntityManagementData] = useState<EntityManagementState | null>();

    useEffect(() => {
        setActiveRouteHeading('Entity Management');
        loadEntityManagamentData(accountId, businessId).catch((err: any) => {
            window.location.href = '#/dashboard';
        });
    }, [setActiveRouteHeading, loadEntityManagamentData, accountId, businessId]);
    useEffect(() => {
        setEntityManagementData(entityManagement.banner ? entityManagement : null)
    }, [entityManagement])


    useEffect(() => {
      if(currentTab) {
        setValue(1)
      }
    },[currentTab])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const styles = makeStyles((theme: Theme) => ({
        bannerContainer: {
            borderBottom: `1px solid ${colorTheme.grey200}`
        },
        bannerItemsContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        tabPanelsContainer: {
            paddingTop: theme.spacing(4),
            height: '100%'
        },
        bannerItemHeading: {
            fontSize: 12,
            color: colorTheme.grey400,
            marginBottom: 4
        },
        businessNameHeading: {
            display: 'flex',
            justifyContent: 'space-between'
        }
    }))();

    return (
        entityManagementData
            ? <div>
                <div className={styles.bannerContainer}>
                    <Container>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography variant="h5" className={styles.businessNameHeading}>
                                    {entityManagementData.banner.businessName}
                                    {/* <Button variant="outlined" endIcon={<Icon>add</Icon>}>
                                        New Entity
                                    </Button> */}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md="auto"
                                xs={12}
                            >
                                <div className={styles.bannerItemsContainer}>
                                    <IconButton aria-label="status">
                                        <NotificationsNone />
                                    </IconButton>
                                    <div>
                                        <div className={styles.bannerItemHeading}>Current Status</div>
                                        <div>{entityManagementData.banner.apStatus || '---'}</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md="auto"
                                xs={12}
                            >
                                <div className={styles.bannerItemsContainer} style={{ margin: '0 20px' }}>
                                    <IconButton aria-label="status">
                                        <CalendarToday />
                                    </IconButton>
                                    <div>
                                        <div className={styles.bannerItemHeading}>Estimated Completion</div>
                                        <div>{moment(entityManagementData.banner.estimatedCompletion * 1000).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md="auto"
                                xs={12}
                            >
                                <div className={styles.bannerItemsContainer}>
                                    <IconButton aria-label="status">
                                        <PlaceOutlined />
                                    </IconButton>
                                    <div>
                                        <div className={styles.bannerItemHeading}>Formation State</div>
                                        <div>{config.apiConfig.generic.states.find((state: any) => state.abbr === entityManagementData.banner.formationState).name}</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Tabs value={value} onChange={handleChange}>
                                    <Tab disableRipple label="Entity" id="panel-0" />
                                    <Tab disableRipple label="Documents" id="panel-1" />
                                    <Tab disableRipple label="All Products" id="panel-2" />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container classes={{ root: styles.tabPanelsContainer }}>
                    <TabPanel value={value} index={0}>
                        <EntityTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Documents />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ProductsTab/> 
                    </TabPanel>
                </Container>
            </div>
            : <Loader />
    )
}

const mapStateToProps = ({ appData, config, entityManagement }: ApplicationStore) => ({
    accountId: appData.personal_account.id,
    businessId: appData.current_business_id,
    config,
    entityManagement
});
export default connect(mapStateToProps, { loadEntityManagamentData })(EntityManagement);