import { useState } from 'react'
import { Grid, Button, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Conversation from '../../../assets/images/Conversation.png'
import Loader from '../../common/Loader'
import { commonStyles, mergeStyle, toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'

const useStyles = makeStyles(() => {
    return {
        marginContent: {
            marginTop: '40px',
            marginBottom: '40px',
        },
        contentWidth: {
            width: toRem16(750),
        },
    }
})

const PayrollConnectionInfo = () => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const history = useHistory()
    const styles = commonStyles()
    const classes = useStyles()

    return (
        <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
                height: '100%',
            }}
            data-cy={'payroll-connect-to-specialist'}
        >
            {!imageLoaded && <Loader />}
            <img
                src={Conversation}
                alt="conversations"
                onLoad={() => {
                    setImageLoaded(true)
                }}
            />
            {imageLoaded && (
                <div>
                    <div
                        className={mergeStyle(
                            styles.mt16,
                            styles.textCenter,
                            classes.contentWidth
                        )}
                    >
                        <UiText
                            className={styles.mb16}
                            variant="suv_150"
                            weight="bold_700"
                        >
                            We’ll be in touch
                        </UiText>
                        <UiText variant="car_100">
                            We’re excited to get your business’s payroll up and
                            running! There are a few extra things we’ll need to
                            take care of first. Your Payroll Specialist will
                            contact you through the Message Center and help you
                            every step of the way.
                        </UiText>
                    </div>
                    <div
                        className={mergeStyle(
                            classes.marginContent,
                            styles.textCenter
                        )}
                    >
                        <UiButton
                            btnType="tertiary"
                            handleClick={() => {
                                history.push('/message_center')
                            }}
                            label="Go to Message Center"
                            data-cy={'go-to-message-center'}
                        />
                    </div>
                </div>
            )}
        </Grid>
    )
}

export default PayrollConnectionInfo
