import { Button, makeStyles, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { Product } from '../../models';
import { ThemeColors } from '../../styles/models/Colors.interface';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import ChargeoverIframeModal from './ChargeoverIframeModal';

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    purchaseButton: {
        backgroundColor: (colorTheme) => colorTheme.red500,
        color: (colorTheme) => colorTheme.primaryWhite
    },
}));
export default function ProductPurchase({
    product,
    fullWidth = false,
    utmContentParam
}: {
    product: Product;
    fullWidth?: boolean;
    utmContentParam: string
}) {
    const { colorTheme } = useThemeContext()
    const [openChargeoverModal, setOpenChargeoverModal] = useState(false);
    const classes = useStyles(colorTheme);
    return (
        <>
            <Button
                variant='contained'
                className={classes.purchaseButton}
                fullWidth={fullWidth}
                onClick={() => setOpenChargeoverModal(true)}
            >
                Purchase | ${product?.price_book.first_charge_amount}
            </Button>
            <ChargeoverIframeModal
                open={openChargeoverModal}
                productIds={[product.product_id]}
                handleClose={() => setOpenChargeoverModal(false)}
                utmContent={utmContentParam}
            />
        </>
    );
}
