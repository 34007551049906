import {
    createStyles,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
    createFile,
    getCsvDownloadUrl,
    getInvoiceReports,
    getPdfDownloadUrl,
    saveInvoiceDocument,
    updateInvoicePaymentStatus,
} from '../../../../services/apiService/reports'
import { currencyFormatter, isEmpty } from '../../../../utils/appUtil'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import {
    ErrorAlertReport,
    InvoiceItem,
    InvoiceReportRoot,
    InvoiceUpdate,
} from '../models/reports-interface'
import { useReportsStyles } from '../styles/reports-styles'
import Loader from '../../../common/Loader'
import {
    DOCUMENT_DOWNLOAD_SUCCESS,
    EMPTY_PARAMS,
    REPORT_MENU_OPTIONS,
    REPORT_STATUSES,
    saveOptions,
    VIEW_INVOIDES_BY_API_PARAMS,
    REPORTS_MENU_VIEWS
} from '../constants/reports.const'
import ActionIcon from '../../../../assets/icons-svg/Action.svg'
import UiChip from '../../../common/ui/UiChip'
import { formatDateDDMMYY } from '../../../../utils/dateUtil'
import UiText from '../../../common/ui/UiText'
import { showError } from '../../../../services/formService'
import SaveReports from '../utils/SaveReports/SaveReports'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import BusinessNameAndLogo from '../utils/BusinessLogo/BusinessNameAndLogo'
import InvoiceFormModal from '../../invoices/Modal/InvoiceFormModal'
import { contactDetail } from '../../../../services/apiService/contacts'
import { Contact, Invoice } from '../../../../models'
import ContactModal from '../../contacts/modal/ContactModal'
import { invoiceDetail } from '../../../../services/apiService/invoice'
import { toRem16 } from '../../../../styles/commonStyles'
import { ActiveRouterHeadingSection } from '../utils/ActiveRouteHeading'
import InvoiceDeleteConfirmModal from '../utils/modal/InvoiceDeleteConfirmModal'
import ErrorAlert from '../utils/Alerts/ErrorAlert'
import { resetReportsData } from '../../../../store/actions/reportsData'
import { LeftArrowIcon } from '../Icons/LeftArrowIcon'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ActiveRoutingContext } from '../../../routing/Providers/ActiveRoutingProvider'

interface QueryParams {
    periodText: string
    time: number
    dateFilter: string
    startDateYmd: string | null
    endDateYmd: string | null
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        totalPaid: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '0 0 0.5rem 0',
            '& .totalValue': {},
        },
        totalUnpaid: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5rem 0 0 0',
            margin: '0 0 5rem 0',
            '& p': {
                color: (colorTheme) => colorTheme.red100,
            },
        },

        actionButtonCell: {
            textAlign: 'center',
            '& :hover': {
                cursor: 'pointer',
            },
        },
        amountDue: {
            maxWidth: toRem16(20),
        },
        customer: {
            minWidth: toRem16(220),
        },
        amountDueValueCell: {
            padding: '0 !important',
        },
        refNoHeader: {
            padding: '0 4rem 0 0 !important',
        },
        refNoValueCell: {
            padding: '0 4rem 0 0 !important',
        },
    })
)
const TOOLTIP_TEXT =
    'The Invoices Report shows all your company’s invoices based on the issue or due date during the selected period of time.'

export default function InvoiceDetailedReport() {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [reportsData, setReportsData] = useState<InvoiceReportRoot>()
    const store = useStore().getState()
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const businessId = currentStore.currentBusinessId!
    const businessName = currentStore.currentBusiness.name
    const { colorTheme } = useThemeContext()
    const reportsClasses = useReportsStyles(colorTheme)
    const [anchorElMenu, setAnchorElMenu] = React.useState(null)
    const [selectedReport, setSelectedReport] = useState<InvoiceItem>()
    const query: QueryParams = useParams() as unknown as QueryParams
    const { periodText, time, dateFilter, startDateYmd, endDateYmd } = query
    const [formattedPeriodText, setFormattedPeriodText] = useState<string>('')
    const [isSaveSnackbar, setIsSaveSnackbar] = useState<boolean>(false)
    const [savedDocumentPath, setSavedDocumentPath] = useState<string | null>(
        null
    )
    
    const localClasses = useLocalStyles(colorTheme)
    const history = useHistory()
    const dispatch = useDispatch();
    const [logoImage, setLogoImage] = useState<string | undefined>('')
    const [isOpenEditInvoiceModal, setIsOpenEditInvoiceModal] =
        useState<boolean>(false)
    const [isOpeneditContactModal, setIsOpeneditContactModal] =
        useState<boolean>(false)
    const [currentContact, setCurrentContact] = useState<Contact>()
    const [invoiceDetails, setInvoiceDetails] = useState<Invoice>()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [selectedContactIDExists, setSelectedContactIDExists] =
        useState<boolean>(true)
    const [selectedInvoiceID, setSelectedInvoiceID] = useState('')
    const [selectedInvoiceRefNo, setSelectedInvoiceRefNo] = useState('')
    const [isDeletingInvoice, setIsDeletingInvoice] = useState(false)
    const [showInvoiceDeleteConfirmModal, setShowInvoiceDeleteConfirmModal] =
        useState(false)
    const [errorAlert, setErrorAlert] = useState<ErrorAlertReport>()

    useEffect(() => {
        setActiveRouteHeading(' ')
        return () => {
            dispatch(resetReportsData());
        }
    }, [setActiveRouteHeading])
    useEffect(() => {
        const textToReplace = 'for the period'
        const dateFilterText =
            dateFilter === VIEW_INVOIDES_BY_API_PARAMS.ISSUE ? 'Issue' : 'Due'
        const periodTextFormatted = periodText
            .replaceAll('+', ' ')
            .replace(textToReplace, '')
        const formmatedText = dateFilterText + ' date ' + periodTextFormatted
        setFormattedPeriodText(formmatedText)
    }, [dateFilter, periodText])

    const getFormattedDateFilter = useCallback(() => {
        return dateFilter.toLocaleLowerCase()
    }, [dateFilter])

    const fetchAPIParams = useCallback(() => {
        const startDateYmdParam =
            startDateYmd === EMPTY_PARAMS.START_DATE_YMD ? '' : startDateYmd
        const endDateYmdParam =
            endDateYmd === EMPTY_PARAMS.END_DATE_YMD ? '' : endDateYmd
        return {
            startDateYmdParam,
            endDateYmdParam,
        }
    }, [endDateYmd, startDateYmd])

    const onInvoiceError = (error: any) => {
        if (error.status === 400) {
            setErrorAlert({
                isShow: true,
                message: error.statusText ?? '',
            })
        }
        showError(error?.statusText)
    }
    const fetchInvoiceReports = useCallback(() => {
        const dateFilter = getFormattedDateFilter()
        const { startDateYmdParam, endDateYmdParam } = fetchAPIParams()
        setIsLoading(true)
        if (!businessId) return
        getInvoiceReports(
            accountId,
            businessId,
            dateFilter,
            periodText,
            time,
            startDateYmdParam,
            endDateYmdParam
        )
            .then((result: unknown) => {
                const reports = result as InvoiceReportRoot
                setReportsData(reports)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                onInvoiceError(error)
            })
    }, [
        accountId,
        businessId,
        fetchAPIParams,
        getFormattedDateFilter,
        periodText,
        time,
    ])

    useEffect(() => {
        const reportsFromStore = store.reportsData.reports
        const reports = isEmpty(reportsFromStore)
            ? fetchInvoiceReports()
            : reportsFromStore
        setReportsData(reports)
    }, [
        accountId,
        businessId,
        dateFilter,
        endDateYmd,
        fetchInvoiceReports,
        periodText,
        startDateYmd,
        store.reportsData.reports,
        time,
    ])
    useEffect(() => {
        const logo = reportsData?.business?.logo
        logo && setLogoImage(logo)
    }, [reportsData?.business?.logo])

    const checkIfContactExists = (item: InvoiceItem) => {
        if (!item.contact_id) {
            setSelectedContactIDExists(false)
            return
        }
        setSelectedContactIDExists(true)
    }
    const handleActionClick = (
        event: { currentTarget: React.SetStateAction<null> },
        item: InvoiceItem
    ) => {
        setAnchorElMenu(event.currentTarget)
        setSelectedReport(item)
        checkIfContactExists(item)
    }
    const handleMenuClose = () => {
        setAnchorElMenu(null)
    }

    const generateReportsTable = (items: InvoiceItem[] | undefined) => {
        if (!items) return
        return (
            <Table className={reportsClasses.reportsTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Issue Date
                            </UiText>
                        </TableCell>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Due Date
                            </UiText>
                        </TableCell>
                        <TableCell
                            align="right"
                            className={localClasses.refNoHeader}
                        >
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Ref #
                            </UiText>
                        </TableCell>
                        <TableCell className={localClasses.customer}>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Customer
                            </UiText>
                        </TableCell>
                        <TableCell>
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Status
                            </UiText>
                        </TableCell>
                        <TableCell
                            className={localClasses.amountDue}
                            align="right"
                        >
                            <UiText
                                weight="semi_bold_600"
                                variant="motorcycle_90"
                            >
                                Amount Due
                            </UiText>
                        </TableCell>
                        <TableCell>{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row: InvoiceItem, index: number) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    {formatDateDDMMYY(row.issue_date)}
                                </TableCell>
                                <TableCell>
                                    {formatDateDDMMYY(row.due_date)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={localClasses.refNoValueCell}
                                >
                                    #{row.number}
                                </TableCell>
                                <TableCell>{row.contact_name}</TableCell>
                                <TableCell>
                                    <UiChip label={row.status} key={index} />
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={localClasses.amountDueValueCell}
                                >
                                    {currencyFormatter.format(
                                        parseFloat(row.amount_due)
                                    )}
                                </TableCell>
                                <TableCell
                                    onClick={(event: any) => {
                                        handleActionClick(event, row)
                                    }}
                                    className={localClasses.actionButtonCell}
                                >
                                    <IconButton>
                                        <img alt={`Action`} src={ActionIcon} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        )
    }
    const getPaidOrUnPaidStatus = (): string => {
        if (selectedReport?.status === REPORT_STATUSES.PAID) {
            return REPORT_MENU_OPTIONS.MARK_AS_UNPAID
        }
        return REPORT_MENU_OPTIONS.MARK_AS_PAID
    }

    const updatePaymentStatus = (
        accountId: string,
        businessId: string,
        invoiceId: string,
        body: InvoiceUpdate
    ) => {
        updateInvoicePaymentStatus(accountId, businessId, invoiceId, body)
            .then((res: unknown) => {
                setIsLoading(false)
                reloadPage()
            })
            .catch((error) => {
                setIsLoading(false)
                showError(error?.statusText)
            })
    }
    const getPaymentStatusForAPIUpdate = (status: string) => {
        if (
            status === REPORT_MENU_OPTIONS.MARK_AS_PAID ||
            status === REPORT_STATUSES.PAID
        ) {
            return REPORT_STATUSES.UNPAID.toLocaleLowerCase()
        }
        return REPORT_STATUSES.PAID.toLocaleLowerCase()
    }
    const markAsPaidUnPaid = () => {
        handleMenuClose()
        const invoiceId = selectedReport?.id
        const status = selectedReport?.status
        if (!status) {
            return
        }
        const paymentStatusToUpdate = getPaymentStatusForAPIUpdate(status)
        const body = {
            payment_status: paymentStatusToUpdate,
        }
        if (!businessId || !invoiceId) return
        setIsLoading(true)
        updatePaymentStatus(accountId, businessId, invoiceId, body)
    }

    const getContactDetails = (contactId: string) => {
        setIsLoading(true)
        contactDetail(contactId)
            .then((res) => {
                setIsLoading(false)
                const response = res as unknown as Contact
                setCurrentContact(response)
                setIsOpeneditContactModal(true)
            })
            .catch((error) => {
                showError(error?.statusText)
            })
    }
    const handleContactEdit = () => {
        handleMenuClose()
        const contactId = selectedReport?.contact_id
        if (!contactId) {
            return
        }
        getContactDetails(contactId)
    }

    const getInvoiceDetails = (id: string) => {
        setIsLoading(true)
        invoiceDetail(id)
            .then((response: unknown) => {
                setIsLoading(false)
                const data = response as Invoice
                setInvoiceDetails(data)
                setIsOpenEditInvoiceModal(true)
            })
            .catch((error) => {
                showError(error?.statusText)
            })
    }
    const handleInvoiceEdit = () => {
        handleMenuClose()
        const id = selectedReport?.id
        const refNo = selectedReport?.number ?? ''
        if (!id) {
            return
        }
        setSelectedInvoiceID(id)
        setSelectedInvoiceRefNo(refNo)
        getInvoiceDetails(id)
    }
    const generateInvoiceMenu = () => {
        const markAsPaidOrUnpaidOpt = getPaidOrUnPaidStatus()
        return (
            <Menu
                id="invoice-action-menu"
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={markAsPaidUnPaid}>
                    {markAsPaidOrUnpaidOpt}
                </MenuItem>
                <MenuItem onClick={handleInvoiceEdit}>
                    {REPORT_MENU_OPTIONS.EDIT_INVOICE}
                </MenuItem>
                <MenuItem
                    onClick={handleContactEdit}
                    disabled={!selectedContactIDExists}
                >
                    {REPORT_MENU_OPTIONS.EDIT_RECIPIENT}
                </MenuItem>
            </Menu>
        )
    }

    const getPdfUrl = () => {
        const { startDateYmdParam, endDateYmdParam } = fetchAPIParams()

        return getPdfDownloadUrl(
            accountId,
            businessId,
            dateFilter,
            periodText,
            time,
            startDateYmdParam,
            endDateYmdParam
        )
    }
    const getCsvUrl = () => {
        const { startDateYmdParam, endDateYmdParam } = fetchAPIParams()
        return getCsvDownloadUrl(
            accountId,
            businessId,
            dateFilter,
            periodText,
            time,
            startDateYmdParam,
            endDateYmdParam
        )
    }

    const handleSaveToPortal = (
        values: any,
        fileType: string,
        closeModal: () => void
    ) => {
        const { startDateYmdParam, endDateYmdParam } = fetchAPIParams()
        const data = {
            comment: values.comment,
            date_filter: dateFilter.toLocaleLowerCase(),
            start_date_ymd: startDateYmdParam,
            end_date_ymd: endDateYmdParam,
            period_text: periodText,
            time,
        }
        saveInvoiceDocument(accountId, businessId, data, fileType)
            .then((response: any) => {
                createFile(response.location).then((result: any) => {
                    const { path, account_id } = result.data
                    setSavedDocumentPath(
                        `/documents/folder?folder=${encodeURIComponent(
                            path
                        )}&accountId=${account_id}`
                    )
                    closeModal()
                    setIsSaveSnackbar(true)
                })
            })
            .catch((error) => {
                showError(error?.statusText)
            })
    }
    const handleGoToDocument = (reason: string) => {
        setIsSaveSnackbar(false)
        reason === 'view' &&
            savedDocumentPath &&
            history.push(savedDocumentPath)
    }
    const handleEditInvoiceModalClose = () => {
        setIsOpenEditInvoiceModal(false)
        setSelectedInvoiceID('')
        setSelectedInvoiceRefNo('')
    }
    const reloadPage = () => {
        fetchInvoiceReports()
    }
    const deleteCurrentInvoice = () => {
        setShowInvoiceDeleteConfirmModal(true)
    }
    const onDeleteModalClose = () => {
        setShowInvoiceDeleteConfirmModal(false)
    }
    const onCurrentInvoiceDelete = () => {
        setIsOpenEditInvoiceModal(false);
        setReportsData(undefined);
        reloadPage();
    }
    return (
        <div className={reportsClasses.reportDetailsContainer}>
            <ActiveRouterHeadingSection
                tooltipText={TOOLTIP_TEXT}
                headingText={'Invoices'}
            />
            <div className={reportsClasses.reportsCommonHeader}>
                <div className="backLinkAndBtn">
                    <Link
                        data-cy="generate-new-report-btn"
                        to="/reports?name=invoices"
                    >
                        <LeftArrowIcon color={colorTheme.blue200} />
                        <span>Generate New Report</span>
                    </Link>
                    <SaveReports
                        businessName={reportsData?.business.name ?? ''}
                        downloadReportPDFUrl={getPdfUrl()}
                        downloadReportCSVUrl={getCsvUrl()}
                        options={saveOptions}
                        handleSaveToPortal={handleSaveToPortal}
                        reportName={REPORTS_MENU_VIEWS.INVOICES}
                        isDisabled={!reportsData?.items.length}
                    />
                </div>
                <div className="headerTextAndBusinessNameLogo">
                    <div>
                        <h2>Invoices Report</h2>
                        <span>{formattedPeriodText}</span>
                    </div>

                    <BusinessNameAndLogo
                        logoFromApi={logoImage}
                        accountId={accountId}
                        businessName={businessName!}
                    />
                </div>
            </div>
            {errorAlert?.isShow ? (
                <ErrorAlert message={errorAlert?.message} />
            ) : (
                <>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className={reportsClasses.reportDetailsContent}>
                            <>
                                <div className={localClasses.totalPaid}>
                                    <span> Total Paid </span>
                                    <UiText weight="semi_bold_600">
                                        {reportsData?.total_paid &&
                                            currencyFormatter.format(
                                                parseFloat(
                                                    reportsData?.total_paid
                                                )
                                            )}{' '}
                                    </UiText>
                                </div>
                                <div className={localClasses.totalUnpaid}>
                                    <span> Total Unpaid </span>

                                    <UiText weight="semi_bold_600">
                                        {reportsData?.total_unpaid &&
                                            currencyFormatter.format(
                                                parseFloat(
                                                    reportsData?.total_unpaid
                                                )
                                            )}{' '}
                                    </UiText>
                                </div>
                            </>

                            {generateReportsTable(reportsData?.items)}
                            {generateInvoiceMenu()}
                        </div>
                    )}
                </>
            )}

            <UiSnackbarAlert
                open={isSaveSnackbar}
                message={DOCUMENT_DOWNLOAD_SUCCESS}
                handleClose={handleGoToDocument}
                actionButtonColor={'primary'}
                actionButtonMessage="View Document"
                closeMessage="view"
            />
            {isOpeneditContactModal && (
                <ContactModal
                    open={isOpeneditContactModal}
                    handleClose={() => setIsOpeneditContactModal(false)}
                    contact={currentContact}
                    setSelectedContact={setCurrentContact}
                    setContactUpdated={reloadPage}
                />
            )}
            {isOpenEditInvoiceModal && (
                <InvoiceFormModal
                    open={isOpenEditInvoiceModal}
                    handleClose={handleEditInvoiceModalClose}
                    isNew={false}
                    modalTitle="Edit Invoice"
                    invoiceDetails={invoiceDetails}
                    setFetchCurrentInvoice={reloadPage}
                    setShowDeleteConfirmation={deleteCurrentInvoice}
                />
            )}
            {showInvoiceDeleteConfirmModal && (
                <InvoiceDeleteConfirmModal
                    showDeleteConfirmModal={showInvoiceDeleteConfirmModal}
                    invoiceNumber={selectedInvoiceID}
                    setShowDeleteModal={onDeleteModalClose}
                    onCurrentInvoiceDelete={onCurrentInvoiceDelete}
                    selectedInvoiceRefNo={selectedInvoiceRefNo}
                />
            )}
        </div>
    )
}
