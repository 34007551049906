import { SvgIcon } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'
import UiText from '../../../common/ui/UiText'

export const jobDetailFormMapping: any = (locationData: any) => {
    return [
        {
            md: 8,
            xs: 12,
            key: 'hire_date',
            label: 'Date of Hire (Required)',
            required: true,
            type: 'KeyboardDatePicker',
            fieldName: 'hire_date',
            dateType: 'string',
            disableFutureDate: true,
            showFloatingLabel: true,
            placeholder: 'MM/DD/YYYY',
            errorMessage: 'Required',
            endIcon:  <SvgIcon component={TodayIcon} color="action" />,
            infoText: (
                <UiText variant="motorcycle_90" textColor="textSecondary">
                    {' '}
                    This can be the date the employee was hired, <br /> or the date they started this specific job.
                </UiText>
            ),
        },
        {
            key: 'title',
            type: 'text',
            placeholder: 'Job Title (Required)',
            label: 'Job Title (Required)',
            md: 10,
            xs: 12,
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
        },
        {
            type: 'select',
            placeholder: 'Location (Required)',
            label: 'Location (Required)',
            md: 10,
            xs: 12,
            key: 'location_id',
            required: true,
            errorMessage: 'Required',
            showFloatingLabel: true,
            data: locationData,
            optionKey: 'state',
            optionValue: 'id',
            infoText: (
                <UiText variant="motorcycle_90" textColor="textSecondary">
                    {' '}
                    The location where the employee will be working.
                </UiText>
            ),
        },
    ]
}

