export const BANKING_PAGE_ERRORS = {
    GENERIC_ERROR: 'Something went wrong. Please try again later.',
    BANK_ACCOUNT_EXISTS: 'Bank account already exists.',
}

export enum PLAID_ERROR_CODES {
    ITEM_LOGIN_REQUIRED = 'ITEM_LOGIN_REQUIRED',
    ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
    ITEM_NOT_SUPPORTED = 'ITEM_NOT_SUPPORTED',
    NO_ACCOUNTS = 'NO_ACCOUNTS',
}

export const PLAID_ERROR_CODES_MAPPING: Record<PLAID_ERROR_CODES, string> = {
    [PLAID_ERROR_CODES.ITEM_LOGIN_REQUIRED]:
        'User login is required to update this information.',
    [PLAID_ERROR_CODES.ITEM_NOT_SUPPORTED]:
        'This account is currently not supported',
    [PLAID_ERROR_CODES.ITEM_NOT_FOUND]: 'The Item you requested cannot be found',
    [PLAID_ERROR_CODES.NO_ACCOUNTS]: 'No valid accounts were found for this item',
}

export enum ConnectionType {
    Plaid = 'Plaid',
    Finicity = 'Finicity',
}

export const REDIRECT_URL = {
    UAT: 'https://uat.1800accountant.com',
}

export const QUERY_SUBSTRING =
    'cbapi/app.php/plaid/bank_account/handle_redirect?'

export const CUT_OFF_DATE = '2023-10-25' 
