import { Fragment } from 'react';
import {
    Button,
    Box,
    Container,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography,
    List,
    ListItem,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import InfiniteScroll from 'react-infinite-scroll-component'
import { currencyFormatter } from '../../../utils/appUtil'
import { dateFormatMDY } from '../../../utils/dateUtil'
import Loader from '../../common/Loader'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageMarginTop: {
        marginTop: '30px',
    },
    newReconciliationButton: {
        height: '36px',
        marginTop: '30px',
    },
    listRoot: {
        width: '100%',
        height: 'calc(100vh - 261px)',
        overflow: 'auto',
        backgroundColor: 'theme.palette.background.paper',
    },
    timeText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 'normal',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    selectedMessage: {
        background: (colorTheme) => `${colorTheme.green500} !important`
    },
    messageBody: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    reconciliationDivider: {
        marginLeft: '24px',
        marginRight: '24px',
    },
    inProgressText: {
        background: (colorTheme) => `${colorTheme.grey1500}`,
        borderRadius: '32px',
        padding: '4px 12px 4px 12px',
        textAlign: 'center',
        width: '103px',
    },
    reconciliationContainer: {
        padding: '0 16px',
        margin: '10px 0',
        width: '100%',
    },
}))

function ReconciliationsListView(props: {
    emptyState: boolean
    items: any[]
    loadMore: any
    path: string
    handleNewModal: any
    setSelectedData: any
    selectedData?: any
}) {
    const { colorTheme } = useThemeContext()
    const theme = useTheme()
    const classes = useStyles(colorTheme)
    const history = useHistory()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const newModal = () => {
        props.handleNewModal()
    }
    const query = new URLSearchParams(useLocation().search)
    let accountQuery = query.get('account')

    const ReconciliationList = (data: any) => {
        return (
            <Fragment>
                <List className={classes.listRoot} id={'scrollableDiv'}>
                    <InfiniteScroll
                        dataLength={data.items.length}
                        next={() => props.loadMore()}
                        hasMore={false}
                        scrollableTarget={'scrollableDiv'}
                        loader={
                            <Grid
                                key={'loader'}
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{
                                    paddingBottom: '16px',
                                    paddingTop: '16px',
                                }}
                            >
                                <Loader />
                            </Grid>
                        }
                    >
                        {data.items.map((i: any, index: number) => (
                            <Fragment key={index}>
                                <ListItem
                                    button
                                    classes={{
                                        gutters: classes.navListItemGutters,
                                        selected: classes.selectedMessage,
                                    }}
                                    alignItems="flex-start"
                                    selected={props.selectedData?.id === i.id}
                                    onClick={() => {
                                        props.setSelectedData(i)
                                        if(isSmallDevice) {
                                            history.push({
                                                pathname: `/reconcile/${i.id}`,
                                                search: `account=${accountQuery?.toString()}`,
                                                state: {
                                                    onlyOneItem:
                                                        data.items?.[0].id === i?.id,
                                                },
                                            })
                                        }
                                        
                                    }}
                                >
                                    <div
                                        className={
                                            classes.reconciliationContainer
                                        }
                                    >
                                        {!i.is_submitted && (
                                            <Typography
                                                className={
                                                    classes.inProgressText
                                                }
                                                variant="body2"
                                            >
                                                In Progress
                                            </Typography>
                                        )}
                                        <div>
                                            <Typography variant="subtitle1">
                                                {dateFormatMDY(
                                                    i.start_date * 1000
                                                )}{' '}
                                                -{' '}
                                                {dateFormatMDY(
                                                    i.end_date * 1000
                                                )}
                                                <span
                                                    style={{ float: 'right' }}
                                                >
                                                    {currencyFormatter.format(
                                                        i.end_balance
                                                    )}
                                                </span>
                                            </Typography>
                                        </div>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {i.is_submitted ? (
                                                <span>
                                                    Reconciled{' '}
                                                    {dateFormatMDY(
                                                        i.updated * 1000
                                                    )}{' '}
                                                </span>
                                            ) : (
                                                <span>
                                                    Started{' '}
                                                    {dateFormatMDY(
                                                        i.created * 1000
                                                    )}{' '}
                                                </span>
                                            )}
                                        </Typography>
                                    </div>
                                </ListItem>
                                {index === data.items.length - 1 ? (
                                    ''
                                ) : (
                                    <Divider
                                        className={
                                            classes.reconciliationDivider
                                        }
                                        variant="fullWidth"
                                    />
                                )}
                            </Fragment>
                        ))}
                    </InfiniteScroll>
                </List>
            </Fragment>
        )
    }

    return (
        <>
            {props.emptyState ? (
                <Container>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        justifyItems="center"
                    >
                        <div className={classes.pageMarginTop}>
                            <img
                                alt={`NoReconciliations`}
                                src={
                                    require(`../../../assets/icons-svg/NoReconciliations.svg`)
                                        .default
                                }
                            />
                        </div>
                        <div>
                            <Typography variant="body1" align="center">
                                This account has not been reconciled yet.
                            </Typography>
                        </div>
                        <Button
                            className={classes.newReconciliationButton}
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={newModal}
                        >
                            Reconcile
                        </Button>
                    </Box>
                </Container>
            ) : (
                ReconciliationList(props.items)
            )}
        </>
    )
}

export default ReconciliationsListView
