import React from 'react'
import InfoCard from '../Cards/InfoCard'
import Icon from '../../common/Icon'
import UiText from '../../common/ui/UiText'
import { toRem16 } from '../../../styles/commonStyles'
import { makeStyles, Theme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AccountProgress, FinancialYearDetails } from '../../../models/dashboardModel'
import CurrencyText from '../../common/currencyText'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        cardContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '2% 2% 2% 0',
        },
        container: {
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            margin: '1rem 0 1rem 0',
        },
        headerStyle: {
            display: 'grid',
            gridTemplateColumns: `${toRem16(72)} 1fr`,
        },
        iconStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginTop: '1rem',
        },
    }
})

export const TransactionCard = ({
    accountProgress,
    updateDimissInfo,
}: {
    accountProgress: AccountProgress | undefined
    updateDimissInfo: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const history = useHistory()
    const financialYearDetail = accountProgress?.financial_year_details as FinancialYearDetails
    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconStyle}>
                    <Icon
                        icon="bookkeepingInfo"
                        rectColor={colorTheme.yellow100}
                        svgColor={colorTheme.yellow700}
                    />
                </div>
                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <>
                            <UiText textColor="textSecondary">
                                Transactions
                            </UiText>
                            <UiText>
                                You have{' '}
                                <b>
                                    {
                                        <CurrencyText
                                            value={
                                                accountProgress?.uncategorized_transaction_amount ||
                                                (0 as number)
                                            }
                                            showPlus={false}
                                        />
                                    }
                                </b>
                                &nbsp; in{' '}
                                {
                                    accountProgress?.uncategorized_transaction_count
                                }{' '}
                                uncategorized transactions.
                            </UiText>
                        </>
                    }
                    buttonText={'View Transactions'}
                    buttonClick={() => {
                        updateDimissInfo()
                        history.push({
                            pathname: '/transactions',
                            state: {
                                from: 'dashboard',
                                filter: {
                                    type: 'uncategorized',
                                    start_date: financialYearDetail.start_date,
                                    end_date: financialYearDetail.end_date
                                },
                            },
                        })
                    }}
                />
            </div>
        </div>
    )
}