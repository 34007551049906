import { CircularProgress } from '@material-ui/core';
import { useStyles } from '../../styles/styles';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

export default function LoaderOverlay() {
  const { colorTheme } = useThemeContext()
  const classes = useStyles(colorTheme);
  return (
    <div className={classes.loaderOverlay}>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
}
