/**
 * Render an MDash or the content, depending on props.check
 * Useful - if wanted to show Mdash if null or undefined or falsy value
 * */

import React from 'react';
interface MdashProps {
  customClass?: any
  check?: boolean
  customCheck?: boolean
  children: React.ReactNode
  checkMessage?: string
}

const MdashCheck = ({customClass, check, customCheck, children, checkMessage}: MdashProps) => {
  return (
    <span className={customClass}>
      {!!customCheck || !!check ? <span>{children}</span> : checkMessage ? checkMessage :
        <span> &mdash; </span>
      }
    </span>
  )
}

export default MdashCheck