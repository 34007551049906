import {
    Button,
    createStyles,
    makeStyles,
    Theme,
    IconButton,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import Icon from '../../common/Icon'
import IconList from '../../common/IconList'
import CircularProgressWithLabel from '../libs/CircularProgressWithLabel'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { updateDismissPopup } from '../../../services/apiService/dashboard'
import { showError } from '../../../services/formService'
import { ReactComponent as AlertTriangle } from '../../../assets/icons-svg/AlertTriangle.svg'
import Loader from '../../common/Loader'
import useDeviceSize from '../../../hooks/useDeviceSize'
import MobileHeader from '../../common/MobileHeader'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TaxEngagementInfo from './TaxEngagementInfo'
import AppoinmentModal from '../../calender/AppoinmentModal'
import { DISCOVERY_CALL_TOPIC } from '../../guided-discovery/constants'
import { useDashboardContext } from '../Provider/DashboardProvider'
import { useTaxEngagementContext } from './TaxEngagementProvider'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

export const DISMISS_POPUP_NAME = 'tax_engagement_plan'

export const TASK_STATUSES = {
    PENDING: 'pending',
    COMPLETE: 'complete',
}

export interface DismissPopupData {
    pop_up: string
}
export interface Task {
    title: string
    status: string
    warning: string
    is_connected: boolean
    seq: number
    type: string
}
export interface DismissPopupsData {
    pop_ups: string[]
}
export interface TaskData {
    tasks: Task[]
    current_financial_year: number
    estimated_tax_savings: number
    is_gd_session_exist: boolean
}
const progressInfoSectionDetails = {
    isShowInfoSection: true,
    isDimissDataUpdating: false,
}
const useLocalStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        loaderContainer: {
            height: '100%'
        },
        engagementPlanContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100%'
        },
        engagementPlanContent: {
            borderRadius: toRem16(8),
            background: colorTheme => colorTheme.cyan100,
            padding: '1rem',
        },
        estimatedTaxSavingsContainer: {
            borderRadius: '0.5rem',
            background: colorTheme => colorTheme.cyan100,
            padding: toRem16(20),
            margin: '1rem 0',
            '& .savingsAmount': {
                color: colorTheme => colorTheme.green600,
            },
        },
        tasksList: {
            background: colorTheme => colorTheme.primaryWhite,
            borderRadius: '0.5rem',
            margin: '1rem 0 0 0',
            '& .header': {
                color: colorTheme => colorTheme.grey400,
            },
            [theme.breakpoints.up('sm')]: {
                padding: '1rem'
            }
        },
        downloadButton: {
            border: colorTheme => `${toRem16(1)} solid ${colorTheme.grey300}`,
            color: colorTheme => colorTheme.black100,
            margin: `${toRem16(20)} 0 0 0`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        downloadButtonText: {
            color: colorTheme => colorTheme.white100,
        },
        progressInfoSection: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: colorTheme => colorTheme.blue300,
            borderRadius: `${toRem16(4)}`,
            padding: `1rem`,
            '& .dismissible-section': {
                [theme.breakpoints.up('sm')]: {
                    display: 'flex',
                }
            }
        },
        progressInfoIcon: {},
        progressInfoText: {
            margin: '0 1rem',
        },
        progressBarSection: {
            display: 'flex',
            margin: `${toRem16(18)} 0`,
        },
        progressBarTextSection: {
            margin: '0 0 0 1rem',
        },
        tasksListItem: {
            display: 'flex',
            margin: '0 0 1rem 0',
            alignItems: 'flex-start',
        },
        tasksListItemText: {
            padding: `0 0 ${toRem16(8)} 0`,
            width: '100%',
            color: colorTheme => colorTheme.grey400,
        },
        tasksListItemContainer: {
            borderBottom: colorTheme => `${toRem16(1)} solid ${colorTheme.grey200}`,
            margin: '0 0 0 1rem',
        },
        lastItemIndex: {
            margin: '0 0 0 1rem',
        },
        tasksListItemsDataContainer: {
            position: 'relative',
            margin: '0 0 0 1rem',
        },
        verticalLine: {
            borderLeft: colorTheme => `${toRem16(2)} solid ${colorTheme.grey800}`,
            height: '1.3rem',
            position: 'absolute',
            top: toRem16(-24),
            left: toRem16(10),
        },
        warningContainer: {
            background: colorTheme => colorTheme.red300,
            padding: `${toRem16(14)} ${toRem16(16)}`,
            borderRadius: toRem16(4),
            display: 'flex',
            gap: toRem16(13.5),
        },
        dismissButton: {
            margin: '0 1rem 0 1rem',
            [theme.breakpoints.up('sm')]: {
                alignSelf: 'center',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '& p': {
                color: colorTheme => colorTheme.black100,
            },
        },
        linkText: {
            color: colorTheme => colorTheme.blue200,
            textDecoration: 'none',
            marginLeft: toRem16(4),
        },
        mobileHeaderContainer: {
            display: 'flex',
            position: 'sticky',
            top: 0,
            height: toRem16(60),
            zIndex: 1000,
            background: colorTheme => colorTheme.white100,
            borderBottom: colorTheme => `1px solid ${colorTheme.grey200}`,
        },
        mobileHeaderText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    })
)

export const TaxEngagementPlan = () => {
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)
    const history = useHistory()
    const {
        taxEngagementData,
        isDataLoading,
        totalTasksCompletedCount,
        totalTasksCount,
        isShowDismissPopup,
        taskCompletionPercentage,
        getTaxEngagementData
    } = useTaxEngagementContext()

    const { fetchAccountProgress } = useDashboardContext()

    const store = useCurrentStore()
    const { isMobileDevice } = useDeviceSize()
    const { currentAccountId, currentBusinessId, appData } = store
    const [progressInfoSectionParams, setProgressInfoSectionParams] = useState(
        progressInfoSectionDetails
    )
    const [openAppointmentModal, setOpenAppointmentModal] = useState(false)
    const appointmentAccountantId = appData.user.financial_pod.tax_advisor.accountant_id

    useEffect(() => {
        setProgressInfoSectionParams((prev) => ({
            ...prev,
            isShowInfoSection: isShowDismissPopup,
        }))
    }, [isShowDismissPopup])
    const isTaxStatusCompleted = (status: string): boolean => {
        return status === TASK_STATUSES.COMPLETE
    }
    const isDataConnected = (data: Task): boolean => {
        return data.is_connected
    }
    const dismissProgressInfoSection = () => {
        const body = {
            pop_up:
                DISMISS_POPUP_NAME +
                '_' +
                taxEngagementData?.current_financial_year,
        }
        setProgressInfoSectionParams((prev) => ({
            ...prev,
            isDimissDataUpdating: true,
        }))
        updateDismissPopup(currentAccountId as string, body)
            .then(() => {
                setProgressInfoSectionParams((prev) => ({
                    isShowInfoSection: false,
                    isDimissDataUpdating: false,
                }))
            })
            .catch((error) => {
                setProgressInfoSectionParams((prev) => ({
                    ...prev,
                    isDimissDataUpdating: false,
                }))
                showError(error)
            })
    }
    const displayEngagementPlanTitle = (title: string) => {
        const exceptionCaseTitle = 'Finalize your Engagement Plan'
        if (title !== exceptionCaseTitle) {
            return (
                <UiText className={classes.tasksListItemText}>{title}</UiText>
            )
        }
        return (
            <UiText className={classes.tasksListItemText}>
                Finalize your
                <a
                    className={classes.linkText}
                    href={`#/documents/folder?folder=%2Fpermanent_files%2Fstatement_of_work&accountId=${currentBusinessId}`}
                >
                    Engagement Plan
                </a>
            </UiText>
        )
    }

    const handleModalClose = () => {
        setOpenAppointmentModal(false)
        getTaxEngagementData()
        fetchAccountProgress()
       
    }

    const scheduleAppointment = () => {
        setOpenAppointmentModal(true)
    }

    return (
        <>
            {[DISCOVERY_CALL_TOPIC].length && appointmentAccountantId ? (
                <AppoinmentModal
                    topics={[DISCOVERY_CALL_TOPIC]}
                    open={openAppointmentModal}
                    handleClose={handleModalClose}
                    accountantId={appointmentAccountantId}
                />
            ) : (
                ''
            )}
            <MobileHeader>
                <div className={classes.mobileHeaderContainer}>
                    <IconButton onClick={() => history.goBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <div className={classes.mobileHeaderText}>
                        <UiText variant="hatchback_125">
                            Your Tax Engagement Plan
                        </UiText>
                    </div>
                </div>
            </MobileHeader>
            <div className={classes.engagementPlanContainer}>
                <div
                    className={
                        isMobileDevice ? classes.loaderContainer : classes.engagementPlanContent
                    }
                >
                    {!isMobileDevice && (
                        <UiText variant="suv_150" weight="semi_bold_600">
                            Your Tax Engagement Plan
                        </UiText>
                    )}
                    {isDataLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <div
                                className={classes.estimatedTaxSavingsContainer}
                            >
                                <TaxEngagementInfo
                                    taxEngagementData={taxEngagementData}
                                    scheduleAppointment={scheduleAppointment}
                                />
                            </div>

                            <div className={classes.tasksList}>
                                {progressInfoSectionParams.isShowInfoSection &&
                                    progressInfoSectionParams.isDimissDataUpdating && (
                                        <Loader />
                                    )}
                                {progressInfoSectionParams.isShowInfoSection &&
                                    !progressInfoSectionParams.isDimissDataUpdating && (
                                        <div
                                            className={
                                                classes.progressInfoSection
                                            }
                                        >
                                            <Icon
                                                icon="InfoIcon"
                                                customClass={
                                                    classes.progressInfoIcon
                                                }
                                                svgColor={colorTheme.blue200}
                                            />
                                            <div className='dismissible-section'>
                                                <UiText
                                                    className={
                                                        classes.progressInfoText
                                                    }
                                                    variant="motorcycle_90"
                                                >
                                                    See the progress we’re making
                                                    for your business at a glance.
                                                </UiText>
                                                <Button
                                                    onClick={() => {
                                                        dismissProgressInfoSection()
                                                    }}
                                                    className={classes.dismissButton}
                                                >
                                                    <UiText
                                                        variant="motorcycle_90"
                                                        weight="semi_bold_600"
                                                    >
                                                        Okay, Got it
                                                    </UiText>
                                                </Button>
                                            </div>
                                        </div>
                                    )}

                                <div className={classes.progressBarSection}>
                                    <CircularProgressWithLabel
                                        value={taskCompletionPercentage}
                                        color={colorTheme.primary}
                                        bgColor={colorTheme.grey200}
                                        size={60}
                                    />
                                    <div
                                        className={
                                            classes.progressBarTextSection
                                        }
                                    >
                                        <UiText
                                            variant={isMobileDevice ? 'car_100' : 'hatchback_125'}
                                            weight="semi_bold_600"
                                            className="header"
                                        >
                                            What we’re doing for you
                                        </UiText>
                                        <UiText
                                            variant={isMobileDevice ? 'hatchback_125' : 'suv_150'}
                                            weight="semi_bold_600"
                                        >
                                            {totalTasksCompletedCount} of{' '}
                                            {totalTasksCount} Complete
                                        </UiText>
                                    </div>
                                </div>
                                {taxEngagementData?.tasks?.map((data, index) => {
                                    const lastIndex = index === (taxEngagementData?.tasks?.length - 1)
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                classes.tasksListItemsDataContainer
                                            }
                                        >
                                            {
                                                isDataConnected(data) && (
                                                    <div
                                                        className={
                                                            classes.verticalLine
                                                        }
                                                    ></div>
                                                )
                                            }
                                            <div
                                                className={
                                                    classes.tasksListItem
                                                }
                                            >
                                                {isTaxStatusCompleted(
                                                    data.status
                                                ) && (
                                                    <Icon
                                                        icon={
                                                            'CompleteIconPrimary' as keyof typeof IconList
                                                        }
                                                        svgColor={colorTheme.primary}
                                                    />
                                                )}
                                                {!isTaxStatusCompleted(
                                                    data.status
                                                ) && (
                                                    <Icon
                                                        icon={
                                                            'EmptyCircle' as keyof typeof IconList
                                                        }
                                                    />
                                                )}
                                                <div
                                                    className={
                                                        lastIndex ? 
                                                            classes.lastItemIndex : 
                                                            classes.tasksListItemContainer
                                                    }
                                                >
                                                    {displayEngagementPlanTitle(
                                                        data.title
                                                    )}
                                                    {data.warning && (
                                                        <div
                                                            className={
                                                                classes.warningContainer
                                                            }
                                                        >
                                                            <div>
                                                                <AlertTriangle />
                                                            </div>

                                                            <UiText
                                                                variant="motorcycle_90"
                                                                weight="regular_400"
                                                            >
                                                                {data.warning}
                                                            </UiText>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}