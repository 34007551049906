import { Theme, makeStyles, List, Grid, useMediaQuery } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import ContractorItem from './ContractorItem'
import { Contractor, ContractorsData } from '../models/contractor.model'
import { useIntersectionObserver } from '../../common/hooks/useIntersectionObserver'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
    listRoot: {
        width: '100%',
        maxHeight: '80vh',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    loaderStyle: {
        padding: '1rem 0 1rem 0',
    },
    infoText: {
        margin: '1rem 0 1rem 0',
    },
}))

// TODO remove any type
interface ContractorListProps {
    contractorsData: ContractorsData
    loadItems: () => void
    hasMoreItems: boolean
    selectedContractor: Contractor | undefined 
    setSelectedContractor: React.Dispatch<React.SetStateAction<Contractor | undefined>> 
    pageLoading: boolean
}
const ContractorList = ({
    contractorsData,
    loadItems,
    hasMoreItems,
    selectedContractor,
    setSelectedContractor,
    pageLoading,
}: ContractorListProps) => {
    const classes = useStyles()
    const [ref, entry] = useIntersectionObserver({})
    const isScrollEnd = !!entry?.isIntersecting
    const history = useHistory()

    const LoaderGrid = () => {
        return (
            <Grid
                key={'loader'}
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.loaderStyle}
                >
                <Loader />
            </Grid>
        )
    }

    useEffect(()=>{
        if(isScrollEnd) {
            hasMoreItems && loadItems()
        }
    },[isScrollEnd, hasMoreItems, loadItems])

    const dataItems = contractorsData?.items ?? []

    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    return (
        <div>
            <List className={classes.listRoot} id={'scrollableDiv'}>
                <div>
                    <div id="contractor-count">
                        <UiText className={classes.infoText}>
                            {contractorsData?.itemsCount ?? 0} Contractors
                        </UiText>
                    </div>
                    {dataItems?.map((contractor: Contractor, index: number) => (
                        <Fragment key={contractor?.id}>
                            <ContractorItem
                                onSelect={() => {
                                    if (smDevice) {
                                        history.push(
                                            `/contractors/manage/${contractor?.id}`
                                        )
                                    } else {
                                        setSelectedContractor(contractor)
                                    }
                                }}
                                selectedItem={
                                    selectedContractor?.id === contractor?.id
                                }
                                contractor={contractor}
                                setSelectedContractor={setSelectedContractor}
                            />
                            {dataItems.length - 1 === index && (
                                <div ref={ref}></div>
                            )}
                        </Fragment>
                    ))}
                    {pageLoading && <LoaderGrid />}
                </div>
            </List>
        </div>
    )
}

export default ContractorList
