import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
    ListItemText,
    IconButton,
    Popper,
    Paper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
    ListItem,
    Divider,
    makeStyles,
    Theme,
    Grid
} from '@material-ui/core'
import { Purpose } from '../../../models/purposes'
import { trimText } from '../../../utils/utility'
import { mergeStyle, commonStyles, toRem16 } from '../../../styles/commonStyles'
import { MoreVert } from '@material-ui/icons/'
import { handleUpdatePurpose, handleDeletePurpose } from './actions';
import Loader from '../../common/Loader';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import UiText from '../../common/ui/UiText';
import Icon from '../../../components/common/Icon'
import IconList from '../../common/IconList'
import { DeletePurposeModal } from './modal/DeletePurposeModal'
import useDeviceSize from '../../../hooks/useDeviceSize'

const useStyles = makeStyles((theme: Theme) => {
    return {
        selectedItem: {
            background: useThemeContext().selectedItemBg + ' !important',
            borderRadius: '0.313rem'
        },
        ListDetail: {
            display: 'flex',
            width: '100%',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            '& .MuiListItemText-primary': {
                maxWidth: '25rem',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                },
            },
        },
        loaderMargin: {    
            margin: '0rem 0rem 0rem 1rem'
        },
        itemRootStyles: {
            '&:hover': {
                background: useThemeContext().grey100
            },
        },
        navListItemGutters: {
            paddingLeft: '0rem 1rem',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0),
            },
        },
        loaderBox: {
            minWidth: '4rem'
        },
        iconPadding: {
            padding: '0rem 1rem 0rem 0rem'
        },
        starIconPadding: {
            padding: `0rem ${toRem16(12)} 0rem 0rem`
        },
        purposeNameInline: {
            display: 'inline'
        }
    }
})

enum ACTIONS {
    EDIT        = 'edit',
    REMOVE      = 'remove',
    SET_DEFAULT = 'set_default'
}


export default function PurposeItem({
    purpose,
    selectedPurpose,
    setSelectedPurpose,
    lastItem,
    loadPurposes,
    purposeCallback,
    setOpenModal,
    deletePurposeCallBack,
}: {
    purpose: Purpose
    selectedPurpose: Purpose | undefined
    setSelectedPurpose: React.Dispatch<Purpose | undefined>
    lastItem: boolean,
    loadPurposes: () => void,
    purposeCallback?: (purpose: Purpose | null) => void,
    setOpenModal: React.Dispatch<boolean>
    deletePurposeCallBack: () => void
}) {
    const styles = commonStyles()
    const history = useHistory()
    const anchorRef = useRef<any>()
    const [open, setOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const { colorTheme } = useThemeContext()
    const { isMobileDevice } = useDeviceSize()
    const handleClose = (event?: React.MouseEvent<Document>) => {
        if (anchorRef.current && anchorRef.current.contains(event?.target)) {
            return
        }
        setOpen(false)
    }
    const [deletePurposeModal, setDeletePurposeModal] = useState(false)
    const classes = useStyles()
    const mDash = () => <span className={mergeStyle(styles.flex, styles.flexEnd)}>—</span>
    const setPurposeAsDefault = (purposeId: string) => {
        setSubmitting(true)
        const data = {
            is_default_mt_purpose : true
        }
        handleUpdatePurpose(purposeId, data, (response) => {
            setSubmitting(false)
            handleClose();
            purposeCallback?.(response);
            loadPurposes?.();
        });
    };

    const deletePurpose = () => {
        setDeletePurposeModal(true)
        setOpen(false)
    };

    const editPurpose = (purpose: Purpose) => {
        setSelectedPurpose(purpose)
        setOpenModal(true)
        setOpen(false)
    }

    const DOT_OPTIONS = [
        {
            title: 'Set as Default',
            action: 'set_default',
            visible: !purpose.is_default_mt_purpose,
            icon: 'starIcon'
        },
        {
            title: 'Edit',
            action: 'edit',
            visible: !purpose.is_default,
            icon: 'edit',
            divider: true
        },
        {
            title: 'Delete',
            action: 'remove',
            visible: !purpose.is_default,
            icon: 'delete',
            color: colorTheme.red100,
            textColor: 'error'
        }
    ]
    
    return (
        <>
            <ListItem
                 classes={{
                    root: classes.itemRootStyles,
                    gutters: classes.navListItemGutters ,
                    selected: classes.selectedItem
                }}
                alignItems='flex-start'
                selected={purpose.id === selectedPurpose?.id}
            >
                <div
                    onClick={() => {
                        setSelectedPurpose(purpose)
                        if(isMobileDevice){
                            history.push(`/mileage/purposes/${purpose.id}/trips`)
                        }
                    }}
                    className={classes.ListDetail}
                >
                    <ListItemText
                        primary={
                            <>
                                <UiText 
                                    weight={purpose.is_default_mt_purpose ? 'semi_bold_600' : 'regular_400'}
                                    className={classes.purposeNameInline}
                                >
                                    { purpose?.name ? purpose?.name + ' ' : mDash() }
                                </UiText>
                                {
                                    purpose.is_default_mt_purpose &&
                                    <UiText 
                                        className={classes.purposeNameInline}
                                        textColor='secondary'
                                    >
                                        (Default)
                                    </UiText>
                                }
                            </>
                        }
                        secondary={
                            purpose?.trip_count !== undefined &&
                            `${purpose.trip_count} Trip` + (purpose.trip_count > 1 ? 's' : '')
                        }
                    />
                </div>
                {
                    !(purpose.is_default_mt_purpose && purpose.is_default) &&
                        <IconButton
                            id={`purpose-${purpose.id}`}
                            ref={anchorRef}
                            onClick={() => {
                                setOpen(!open)
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                }
            </ListItem>
            {!lastItem && <Divider />}

           <DeletePurposeModal
             isOpen={deletePurposeModal}
             handleCloseModal={(value, isError) => {
                setDeletePurposeModal(false)
                if(!isError){
                    deletePurposeCallBack()
                }
            }}
             purposeId={purpose.id}
             loadPurposes={loadPurposes}
           />
             
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement={'top-end'}
                transition
                disablePortal
                style={{ zIndex: 1 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'end top'
                                    : 'end bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener
                                onClickAway={handleClose}
                            >
                                <MenuList
                                    autoFocusItem={open}
                                >
                                    { 
                                        submitting ?
                                        <div className={classes.loaderBox}>
                                            <Loader />
                                        </div>
                                        :
                                            DOT_OPTIONS.map(
                                            (
                                                option: any,
                                                index: number
                                            ) => (
                                                
                                                    option.visible &&
                                                        <Grid
                                                            key={index}
                                                        >
                                                            <MenuItem
                                                                onClick={() => {
                                                                    switch (option.action) {
                                                                        case ACTIONS.EDIT:
                                                                            return editPurpose(purpose);
                                                                        case ACTIONS.REMOVE:
                                                                            return deletePurpose();
                                                                        case ACTIONS.SET_DEFAULT:
                                                                            return setPurposeAsDefault(purpose.id);
                                                                    }
                                                                }}
                                                            > 
                                                                <Icon 
                                                                    customClass={option.icon === 'starIcon' ? classes.starIconPadding : classes.iconPadding}
                                                                    icon={option.icon as keyof typeof IconList}
                                                                    svgColor={option.color ?? undefined}
                                                                />
                                                                <UiText 
                                                                    textColor={option.textColor ?? undefined}
                                                                >
                                                                    { option.title }
                                                                </UiText>
                                                            </MenuItem>
                                                            {
                                                                option.divider && <Divider />
                                                            }
                                                        </Grid>
                                                        
                                            )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}