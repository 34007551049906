import { useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    makeStyles,
    Theme,
    Hidden,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Tooltip,
} from '@material-ui/core'
import { ReconcileBalanceInfo, dataItem } from './ReconciliationBalanceInfo'
import { currencyFormatter } from '../../../utils/appUtil'
import { commonStyles } from '../../../styles/commonStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Loader from '../../common/Loader'
import { longDateFormat } from '../../../utils/dateUtil'
import UiButton from '../../common/ui/UiButton'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    textsb24: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: 0.18,
    },
    summary: {
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '8px 16px 8px 16px',
    },
    accordionRoot: {
        border: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        margin: '15px !important',
        minHeight: '0px',
    },
    accordionSummaryRoot: {
        display: 'block !important',
    },
    diffColor: {
        color: (colorTheme) => colorTheme.red600
    },
    disableButton: {  // to do
        color: 'rgba(0, 0, 0, 0.26)',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.26)',
        },
    },
}))

const ReconcileDetailHeader = ({
    transactions,
    reconciliation,
    setOpenReconcileModal,
    saveReconciliation,
    reconcileErrorMsg,
    submitReconciliation,
    savingChanges
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const styles = commonStyles()
    const [accordionExpanded, setAccordionExpanded] = useState(false)

    const reconcileTransactionCount = () => {
        return transactions.filter((t: any) => t.reconcile === true).length
    }
    const reconcileBalance = () => {
        let total = 0
        transactions.forEach(function (t: any) {
            if (t.reconcile) {
                total = Number(total.toFixed(2)) + t.balance_multiplier * parseFloat(t.amount)
            }
        })
        return total
    }
    
    const reconcileTotal = () => {
        return parseFloat(reconciliation.start_balance) + reconcileBalance()
    }

    const reconcileDifference = () => {
        return currencyFormatter.format(
            reconciliation.end_balance - reconcileTotal()
        )
    }
    return (
        <Grid container>
            <Hidden smDown>
                <Grid
                    container
                    xs={9}
                    direction="row"
                    className={classes.summary}
                >
                    <Grid container xs={9}>
                        <Typography className={classes.textsb24}>
                            Summary -{' '}
                            {longDateFormat(reconciliation.start_date)} -{' '}
                            {longDateFormat(reconciliation.end_date)}
                        </Typography>
                    </Grid>
                    <Grid container xs={3} justify="flex-end">
                        <Button
                            onClick={() => {
                                setOpenReconcileModal((prev: any) => ({
                                    ...prev,
                                    type: 'edit',
                                    open: true,
                                }))
                            }}
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            {dataItem(
                                'Beginning Balance',
                                <>
                                    {currencyFormatter.format(
                                        reconciliation.start_balance
                                    )}
                                </>
                            )}
                            {dataItem(
                                'Ending Balance',
                                <>
                                    {currencyFormatter.format(
                                        reconciliation.end_balance
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <ReconcileBalanceInfo
                                reconciliation={reconciliation}
                                transactions={transactions}
                                reconcileDifference={reconcileDifference}
                                reconcileTransactionCount={
                                    reconcileTransactionCount
                                }
                                reconcileBalance={reconcileBalance}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container xs={3} justify="flex-end">
                    {savingChanges ? (
                        <Loader />
                    ) : (
                        <UiButton  btnType="secondary"
                        label="Save Changes"
                        customClass={styles.mr16}
                        handleClick={() => {
                            saveReconciliation()
                        }} />   
                    )}
                    
                    {reconcileErrorMsg ? (
                        <Tooltip title={reconcileErrorMsg} placement="top">
                            <Button
                                variant="outlined"
                                className={classes.disableButton}
                            >
                                Submit
                            </Button>
                        </Tooltip>
                    ) : (
                        /*<Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                submitReconciliation()
                            }}
                            disabled={!!reconcileErrorMsg || transactions.length === 0}
                        >
                            Submit
                        </Button>*/
                        <UiButton btnType="primary"
                        label="Submit"
                        disabled={!!reconcileErrorMsg}
                        handleClick={() => {
                            submitReconciliation()
                        }} />   
                    )}
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <div style={{ width: '100%' }}>
                    <Accordion
                        classes={{
                            root: classes.accordionRoot,
                            expanded: classes.accordionRoot,
                        }}
                        onChange={(e: any, expanded: boolean) => {
                            setAccordionExpanded(expanded)
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            classes={{
                                content: classes.accordionSummaryRoot,
                            }}
                        >
                            {accordionExpanded ? (
                                'Details'
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{ display: 'flex' }}
                                    >
                                        Difference
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        className={classes.diffColor}
                                    >
                                        {reconcileDifference()}
                                    </Typography>
                                </div>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginLeft: '15px',
                                    }}
                                >
                                    <Typography>
                                        {longDateFormat(
                                            reconciliation.start_date
                                        )}{' '}
                                        -{' '}
                                        {longDateFormat(
                                            reconciliation.end_date
                                        )}
                                    </Typography>
                                    <Button
                                        onClick={() => {
                                            setOpenReconcileModal(
                                                (prev: any) => ({
                                                    ...prev,
                                                    type: 'edit',
                                                    open: true,
                                                })
                                            )
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                                <div
                                    style={{
                                        marginLeft: '15px',
                                        marginRight: '15px',
                                    }}
                                >
                                    {dataItem(
                                        'Beginning Balance',
                                        <>
                                            {currencyFormatter.format(
                                                reconciliation.start_balance
                                            )}
                                        </>
                                    )}
                                    {dataItem(
                                        'Ending Balance',
                                        <>
                                            {currencyFormatter.format(
                                                reconciliation.end_balance
                                            )}
                                        </>
                                    )}
                                </div>

                                <Divider className={styles.mb16} />
                                <ReconcileBalanceInfo
                                    reconciliation={reconciliation}
                                    transactions={transactions}
                                    reconcileDifference={reconcileDifference}
                                    reconcileTransactionCount={
                                        reconcileTransactionCount
                                    }
                                    reconcileBalance={reconcileBalance}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Hidden>
        </Grid>
    )
}

export default ReconcileDetailHeader
