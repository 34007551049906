export const defaultFormValues = {
    first_name: '',
    last_name: '',
    name: '',
    business_type: '',
    industry: '',
    form_state: '',
    ownership: 100,
}
export const FormPropertiesMapping = (optionsData: any, fieldName: string) => {
    switch (fieldName) {
        case 'first_name': 
            return {
                key: 'first_name',
                type: 'text',
                placeholder: 'First Name (Required)',
                required: true,
                errorMessage: 'Required',
                label: 'First Name (Required)',
            }
        case 'last_name':
            return {
                key: 'last_name',
                type: 'text',
                placeholder: 'Last Name (Required)',
                required: true,
                errorMessage: 'Required',
                label: 'Last Name (Required)',
            }
        case 'name':
            return {
                key: 'name',
                type: 'text',
                placeholder: 'Business Name (Required)',
                required: true,
                errorMessage: 'Required',
                label: 'Business Name (Required)',
            }
        case 'country':
            return {
                key: 'country',
                type: 'select',
                placeholder: 'Country',
                label: 'Country',
                optionKey: 'name',
                optionValue: 'id',
                optionsData: optionsData.country,
                required: true,
                errorMessage: 'Required',
            }
        case 'industry':
            return {
                key: 'industry',
                type: 'select',
                placeholder: 'Industry (Required)',
                label: 'Industry (Required)',
                optionKey: 'name',
                optionValue: 'id',
                optionsData: optionsData.industries,
                required: true,
                errorMessage: 'Required',
            }
        case 'form_state':
            return {
                key: 'form_state',
                type: 'select',
                placeholder: 'Formation State (Required)',
                label: 'Formation State (Required)',
                optionKey: 'name',
                optionValue: 'id',
                optionsData: optionsData.states,
                required: true,
                errorMessage: 'Required',
            }
        case 'ownership':
            return {
                key: 'ownership',
                type: 'text',
                placeholder: 'Ownership Percentage (Required)',
                label: 'Ownership Percentage (Required)',
                errorMessage: 'Required',
                required: true,
            }
        case 'business_type':
            return {
                key: 'business_type',
                type: 'select',
                placeholder: 'Type (Required)',
                label: 'Type (Required)',
                optionKey: 'title',
                optionValue: 'id',
                optionsData: optionsData.businessTypes,
                required: true,
                errorMessage: 'Required',
            }
        default:
            return null
    }
}