import { Button, makeStyles, Theme, Tooltip } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import { downloadTaxSavingPdf } from '../../../services/apiService'
import { formSubmitErrorHandler } from '../../../services/formService'
import { toRem16 } from '../../../styles/commonStyles'
import { currencyFormatter } from '../../../utils/appUtil'
import AppoinmentModal from '../../calender/AppoinmentModal'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import Icon from '../../common/Icon'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import { DISCOVERY_CALL_TOPIC } from '../../guided-discovery/constants'
import { useDashboardContext } from '../Provider/DashboardProvider'
import { TaskData } from './TaxEngagementPlan'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const NON_PROFIT = 'NON_PROFIT'
const PDF_NAME = 'Tax Saving'
const BLOB_TYPE = 'application/pdf'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        downloadButton: {
            border: colorTheme => `${toRem16(1)} solid ${colorTheme.grey200}`,
            color: colorTheme => `${colorTheme.black100}`,
        },
        appointmentTimeDetail: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: toRem16(6),
        },
        appointmentInfo: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        flex: {
            display: 'flex',
        },
        discoveryCallBtn: {
            color: colorTheme => `${colorTheme.black100}`,
        },
        link: {
            color: colorTheme => `${colorTheme.blue200}`,
            cursor: 'pointer',
        },
        popupContent: {
            height: toRem16(70),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1rem',
        },
        subHeader: {
            margin: '1rem 0 1rem 0',
        },
        disabledButton: {
            color: colorTheme => colorTheme.grey500,
            '&:hover': {
                color: colorTheme => colorTheme.grey500,
            },
        },
    }
})

const formatDate = (date: number) => {
    return (
        <>
            {moment(date * 1000).format('MMMM DD YYYY') +
                ' • ' +
                moment(date * 1000).format('hh:mm A')}
        </>
    )
}

const TaxEngagementInfo = ({
    taxEngagementData,
    scheduleAppointment,
}: {
    taxEngagementData: TaskData | undefined
    scheduleAppointment: () => void
}) => {
    const [openAppointmentModal, setOpenAppointmentModal] = useState(false)
    const [downloadingPdf, setDownloadingStatus] = useState(false)
    const {
        currentBusiness,
        currentAccountId,
        currentBusinessId,
        currentUser,
    } = useCurrentStore()
    const appointmentAccountantId =
        currentUser.financial_pod.tax_advisor.accountant_id

    const { accountProgress } = useDashboardContext()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    const isTaxSavingNull = !taxEngagementData?.estimated_tax_savings
    const isNonProfitBusiness = currentBusiness?.business_type === NON_PROFIT
    const hasDiscoveryScheduled =
        accountProgress?.scheduled_discovery_appointment &&
        accountProgress?.discovery_appointment_details?.start_date
    const meetingStartDate =
        accountProgress?.discovery_appointment_details?.start_date

    const isGDCompleted = accountProgress?.completed_discovery_appointment ?? false

    const downloadPdf = () => {
        setDownloadingStatus(true)
        formSubmitErrorHandler(
            downloadTaxSavingPdf(currentAccountId, currentBusinessId as string)
                .then(
                    (response: Blob) =>
                        new Blob([response], {
                            type: BLOB_TYPE,
                        })
                )
                .then((res: any) => {
                    downloadTaxSavingBlobPDf(res)
                    setDownloadingStatus(false)
                }),
            () => {
                setDownloadingStatus(false)
            }
        )
    }

    const downloadTaxSavingBlobPDf = (blob: Blob) => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.setAttribute('id', 'download-pdf-link')
        a.target = '_blank'
        a.href = url
        a.download = PDF_NAME
        a.click()
        a.remove()
        window.URL.revokeObjectURL(a.href)
    }

    const headerText = () => {
        if (isNonProfitBusiness) {
            return {
                header: 'Your business is tax exempt',
                subHeader: ``,
            }
        }
        if (isTaxSavingNull) {
            return {
                header: 'Not Available Yet',
                subHeader: `Meet with your Senior Tax Advisor to get your estimated tax savings`,
            }
        }
        return {
            header: `${
                taxEngagementData?.estimated_tax_savings &&
                currencyFormatter.format(
                    taxEngagementData?.estimated_tax_savings
                )
            }`,
            subHeader: ``,
        }
    }

    const { header, subHeader } = headerText()

    const ScheduleDiscoveryButton = () => {
        return (
            <Button
                variant="outlined"
                color="primary"
                startIcon={<Icon icon="calendar" svgColor={colorTheme.black100} />}
                className={styles.discoveryCallBtn}
                onClick={scheduleAppointment}
            >
                <UiText variant={'motorcycle_90'} weight="semi_bold_600">
                    {' '}
                    Schedule Your Discovery Call
                </UiText>
            </Button>
        )
    }

    const RescheduleDiscAppointment = () => {
        return (
            <div className={styles.appointmentInfo}>
                <div className={styles.flex}>
                    <Icon icon="calendar" svgColor={colorTheme.black100} />
                    <UiText
                        variant={'motorcycle_90'}
                        weight="semi_bold_600"
                        className={styles.appointmentTimeDetail}
                    >
                        {formatDate(meetingStartDate as number)}
                    </UiText>
                </div>
                <Button
                    onClick={scheduleAppointment}
                    className={styles.downloadButton}
                >
                    <UiText variant={'motorcycle_90'} weight="semi_bold_600">
                        {' '}
                        Reschedule
                    </UiText>
                </Button>
            </div>
        )
    }

    const DownloadButton = (disabled = false) => {
        return isGDCompleted ? (
            <Button
                className={
                    disabled ? styles.disabledButton : styles.downloadButton
                }
                startIcon={
                    <Icon
                        icon="download"
                        svgColor={disabled ? colorTheme.grey500 : colorTheme.black100}
                    />
                }
                onClick={
                    disabled
                        ? undefined
                        : () => {
                              downloadPdf()
                          }
                }
            >
                <UiText variant={'motorcycle_90'} weight="medium_500">
                    {' '}
                    View the tax savings we discussed
                </UiText>
            </Button>
        ) : (
            <UiText>
                Will be updated after you meet with your Tax Advisor
            </UiText>
        )
    }

    const ButtonComponent = () => {
        const gdSessionExists = taxEngagementData?.is_gd_session_exist
        if (isNonProfitBusiness) {
            return <></> // not completed
        }
        if (isTaxSavingNull) {
            return (
                <>
                    {hasDiscoveryScheduled ? (
                        <RescheduleDiscAppointment />
                    ) : (
                        <ScheduleDiscoveryButton />
                    )}
                </>
            )
        }
        return (
            <>
                {downloadingPdf ? (
                    <Loader />
                ) : gdSessionExists ? (
                    <>{DownloadButton(false)}</>
                ) : (
                    <Tooltip
                        title="GD call hasn't been scheduled yet"
                        placement="top"
                    >
                        {DownloadButton(true)}
                    </Tooltip>
                )}
            </>
        )
    }

    return (
        <div>
            {appointmentAccountantId ? (
                <AppoinmentModal
                    topics={[DISCOVERY_CALL_TOPIC]}
                    open={openAppointmentModal}
                    handleClose={() => {
                        setOpenAppointmentModal(false)
                    }}
                    accountantId={appointmentAccountantId}
                />
            ) : (
                ''
            )}
            <UiText variant="car_100" weight="medium_500">
                {`Your ${isGDCompleted ? 'Estimated' : 'Potential'} Tax Savings`}
            </UiText>
            <UiText variant="suv_150" weight="bold_700">
                {header}
            </UiText>
            <UiText variant="motorcycle_90" className={styles.subHeader}>
                {subHeader}
            </UiText>
            <ButtonComponent />
        </div>
    )
}

export default TaxEngagementInfo