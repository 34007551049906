import React from 'react'
import UiText from '../../../common/ui/UiText'
import { makeStyles, Theme } from '@material-ui/core'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    marginError: {
        margin: theme.spacing(2),
        color: (colorTheme) => colorTheme.red200,
    },
}))

const ShowError = ({ errors }: { errors: any }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <>
            <div>
                <UiText>Please correct the following errors</UiText>
            </div>

            {errors.map((err: any) => (
                <div className={styles.marginError}>
                    <div>{err.message}</div>
                </div>
            ))}
        </>
    )
}

export const errorMapping = () => {
    const errorMap: any = {}
    errorMap['legalName.firstName'] = 'first_name'
    errorMap['legalName.lastName'] = 'last_name'
    errorMap['employmentTypeCode'] = 'frequency'
    errorMap['personalEmailAddress'] = 'email'
    return errorMap
}

export default ShowError
