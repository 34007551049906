import { Box, Grid, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import LineChartWidget from '../common/LineChartWidget';
import MenuDropdown from '../common/MenuDropdown';
import WidgetSkeletonLoader from '../common/WidgetSkeletonLoader';
import { getIncomeStatement } from '../../services/apiService';
import { ApplicationStore } from '../../models';
import { connect } from 'react-redux';
import { currencyFormatter, getTimeParams } from '../../utils/appUtil';
import UiDialog from '../common/ui/UiDialog';
import { COLORS } from '../../variables/colors';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import { useHistory } from 'react-router-dom'
import { getCurrentTimeStamp, getDateInYmd, getTimeParamsWithoutEST } from '../../utils/dateUtil';
import { EXPANCE_DATE_DROPDOWN_OPTIONS } from './ReportWidgets/constants';
import { useStyles } from '../../styles/styles';


function ExpensesModal(props: any) {
    const classes = makeStyles((theme: Theme) => ({
        emptyStateWrapper: {
            padding: 8,
        },
        emptyTextWrapper: {
            marginTop: theme.spacing(4),
        },
        emptySubTextWrapper: {
            marginTop: theme.spacing(2),
            width: 350,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        modalBody: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
    }))();

    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(props?.preSelectedIndex ?? 0);
    const [values, setValues] = useState<any>();
    const [lineChartValues, setLineChartValues] = useState<any>([]);
    const history = useHistory()
    const { colorTheme } = useThemeContext()
    let commonClasses = useStyles(colorTheme);

    const getDateAndTimeParams = useCallback(() => {
        const selectedDateType = EXPANCE_DATE_DROPDOWN_OPTIONS[selectedIndex]
        const getDateTillToday = true
        const { startDate, endDate } = getTimeParamsWithoutEST(
            selectedDateType,
            getDateTillToday
        )
        const startDateYmd = getDateInYmd(startDate!)
        const endDateYmd = getDateInYmd(endDate!)
        const currentTimeStamp = getCurrentTimeStamp()
        return {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        }
    }, [selectedIndex])

    const handleAmountClick = () => {
        const {
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp,
        } = getDateAndTimeParams()
        if (!startDate || !endDate) {
            return
        }
        const redirectionUrl = props.getRedirectionUrl(
            startDate,
            endDate,
            startDateYmd,
            endDateYmd,
            currentTimeStamp
        )
        history.push(redirectionUrl)
    }

    const colors = useMemo(
        () => [
            COLORS.test3, // needs to be removed 
            COLORS.test2,
            COLORS.teal300,
            COLORS.test1,
            COLORS.green900,
            COLORS.test7,
            COLORS.test6,
            COLORS.red100,
            COLORS.orange400,
            COLORS.test5,
        ],
        []
    );

    useEffect(() => {
        setLoading(true);
        getIncomeStatement(
            props.appData.current_account_id,
            props.appData.current_business_id,
            getTimeParams(EXPANCE_DATE_DROPDOWN_OPTIONS[selectedIndex], true)
        )
            .then((res: any) => {
                setValues(res);
                res.expenses.children[0].children.sort(
                    (a: any, b: any) =>
                        Number.parseFloat(b.balance) -
                        Number.parseFloat(a.balance)
                );
                setLineChartValues(
                    res.expenses.children[0].children.map(
                        (val: any, index: number) => {
                            return {
                                ...val,
                                amount: Number.parseFloat(val.balance),
                                color: colors[('' + index).slice(-1) as any],
                            };
                        }
                    )
                );
                setLoading(false);
            })
            .catch(() => {
                setValues(undefined);
                setLoading(false);
            });
    }, [
        props.appData.current_account_id,
        props.appData.current_business_id,
        EXPANCE_DATE_DROPDOWN_OPTIONS,
        selectedIndex,
        colors,
    ]);

    const emtpyState = (
        <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
            className={classes.emptyStateWrapper}
        >
            <div>
                <img
                    alt={`ExpensesEmpty`}
                    src={
                        require(`../../assets/icons-svg/ExpensesEmpty.svg`)
                            .default
                    }
                />
            </div>
            <div className={classes.emptyTextWrapper}>
                <Typography variant='body1' align='center'>
                    Categorizing transactions allows you to have a clear picture
                    of where your money is going.
                </Typography>
            </div>
        </Grid>
    );

    function ChartSummary() {
        const { colorTheme } = useThemeContext()
        return (
            <Fragment>
                <div>
                    <Typography
                        component={Link}
                        variant="h4"
                        className={commonClasses.dashboardTextLink}
                        onClick={handleAmountClick}
                    >
                        {currencyFormatter.format(values.expenses.balance)}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        Total Spent
                    </Typography>
                </div>
                <div
                    style={{
                        border: `1px solid ${colorTheme.grey200}`,
                        borderRadius: '4px',
                        padding: '6px 8px',
                        position: 'relative',
                    }}
                >
                    <Typography
                        component={Link}
                        className={commonClasses.dashboardTextLink}
                        onClick={() => {
                            history.push(
                                '/transactions?search=type=uncategorized'
                            )
                        }}
                        variant='button'
                    >
                        {currencyFormatter.format(values.uncategorized_amount)}
                    </Typography>
                    <Typography color='textSecondary' variant='body2'>
                        Uncategorized
                    </Typography>
                </div>
            </Fragment>
        );
    }

    return (
        <UiDialog
            open={props.open}
            handleClose={props.handleClose}
            title='Expenses'
            size='sm'
        >
            <div className={classes.modalBody}>
                <MenuDropdown
                    options={EXPANCE_DATE_DROPDOWN_OPTIONS}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    disabled={loading}
                    variant='outlined'
                />
                <Box marginTop={'24px'} minHeight={'221px'}>
                    {loading ? (
                        <WidgetSkeletonLoader
                            justifyContent='flex-start'
                            alignItems='flex-start'
                        />
                    ) : values ? (
                        <LineChartWidget
                            values={lineChartValues}
                            summary={<ChartSummary />}
                            handleAmountClick={handleAmountClick}
                        />
                    ) : (
                        emtpyState
                    )}
                </Box>
            </div>
        </UiDialog>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(ExpensesModal);
