import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    makeStyles,
    Theme,
    Grid,
    Divider,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import Icon from '../../../components/common/Icon'
import UiText from '../../common/ui/UiText'
import usePurposesHook from './purposes.hook'
import { getTrips } from '../../../services/apiService/trips'
import { jsonToRql } from '../../../utils/rql'
import { PaginatedTripsResponse } from '../../../models/trips'
import TripList from '../trips/TripList'
import Loader from '../../common/Loader'
import { getPurposes } from '../../../services/apiService/purposes'
import { toRem16 } from '../../../styles/commonStyles'
import MobileHeader from '../../common/MobileHeader'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            minHeight: '100%',
            display: 'flex',
        },
        titleMargin: {
            margin: '1rem 0rem 0rem 0rem',
        },
        purposeDetailsContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: `0 ${toRem16(12)} 0 ${toRem16(12)}`,
            boxSizing: 'border-box',
        },
        tripCount: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(3),
            display: 'flex',
        },
        mileageIconMargin: {
            margin: '0rem 1.5rem 0rem 1.5rem',
        },
        mrAuto: {
            marginRight: 'auto',
        },
    }
})

interface LoadingStates {
    loading: boolean
    hasMore: boolean
}

const PurposeTripList = () => {
    const classes = useStyles()
    const history = useHistory()
    const params: { id: string } = useParams()
    const theme = useTheme()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

    const { selectedPurpose, loading, setSelectedPurpose } = usePurposesHook()

    const [tripsData, setTripsData] = useState<
        PaginatedTripsResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 25,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    })

    const loadMore = (id?: string, pageNo?: number) => {
        const rql = jsonToRql({
            purpose: params.id,
        })
        setTripsData((prev: PaginatedTripsResponse & LoadingStates) => ({
            ...prev,
            loading: true,
        }))

        getTrips({
            perPage: 25,
            page: pageNo!,
            rql,
        }).then((response: PaginatedTripsResponse | unknown) => {
            if (response) {
                const responsePayload = response as PaginatedTripsResponse
                const { itemsCount, page, perPage, pageCount, items } =
                    responsePayload
                setTripsData(
                    (prev: PaginatedTripsResponse & LoadingStates) => ({
                        ...prev,
                        items: prev.items.concat(items),
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    })
                )
            }
        })
    }

    const fetchTrips = (id: string) => {
        const rql = jsonToRql({
            purpose: id,
        })
        setTripsData((prev: PaginatedTripsResponse & LoadingStates) => ({
            ...prev,
            loading: true,
        }))

        getTrips({
            perPage: 25,
            page: 1,
            rql,
        }).then((response: PaginatedTripsResponse | unknown) => {
            if (response) {
                const responsePayload = response as PaginatedTripsResponse
                const { itemsCount, page, perPage, pageCount, items } =
                    responsePayload
                setTripsData(
                    (prev: PaginatedTripsResponse & LoadingStates) => ({
                        ...prev,
                        items: items,
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    })
                )
            }
        })
    }

    const fetchPurpose = (id: string) => {
        // @ts-ignore
        getPurposes({ id }).then((data: any) => {
            const purpose = data.find((purpose: any) => purpose.id === id)
            setSelectedPurpose(purpose)
        })
    }

    useEffect(() => {
        if (params.id) {
            fetchTrips(params.id)
            fetchPurpose(params.id)
        }
    }, [params.id])

    if (loading) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Loader />
            </Grid>
        )
    }

    return (
        <>
            <MobileHeader showBottomDivider={isXsDown}>
                <IconButton
                    className={classes.mrAuto}
                    onClick={() => history.push('/mileage/purposes')}
                >
                    <ArrowBackIcon />
                </IconButton>
            </MobileHeader>
            <div className={classes.purposeDetailsContainer}>
                {tripsData.loading ? (
                    <Loader />
                ) : (
                    <Grid item xs={12} md={12} justify="center">
                        <>
                            <UiText
                                variant="hatchback_125"
                                weight="medium_500"
                                className={classes.titleMargin}
                            >
                                {selectedPurpose?.name}
                            </UiText>
                            <div className={classes.tripCount}>
                                <Icon
                                    icon="mileage"
                                    customClass={classes.mileageIconMargin}
                                />
                                <UiText>
                                    {tripsData.items?.length < 2
                                        ? tripsData.items?.length +
                                          ' Recorded Trip'
                                        : tripsData.items?.length +
                                          ' Recorded Trips'}
                                </UiText>
                            </div>
                            <Divider variant="inset" />
                            <Grid item xs={12} md={12} justify="center">
                                <TripList
                                    items={tripsData.items}
                                    itemsCount={tripsData.itemsCount}
                                    isLoading={tripsData.loading}
                                    page={tripsData.page}
                                    pageCount={tripsData.pageCount}
                                    loadMore={loadMore}
                                    hasMore={tripsData.hasMore}
                                    selectedTrip={undefined}
                                    setSelectedTrip={() => {}}
                                    calledFromPurposes={true}
                                    selectedPurpose={selectedPurpose}
                                />
                            </Grid>
                        </>
                    </Grid>
                )}
            </div>
        </>
    )
}

export default PurposeTripList
