import React, { useEffect, useState } from 'react'
import { Box, Button, Theme, Typography, makeStyles } from '@material-ui/core'
import { COLORS } from '../../../variables/colors'
import Dropzone from 'react-dropzone'
import { InlineFlex } from '../Wrapper'
import UiButton from '../../common/ui/UiButton'
import { toRem16 } from '../../../styles/commonStyles'
import { ReactComponent as CSV } from '../../../assets/icons-svg/Csv.svg'
import { ReactComponent as Delete } from '../../../assets/icons-svg/deleteTrash.svg'
import { CollapsibleSection } from '../CollapsibleSection'
import { useContractorContext } from '../ContractorProvider'
import { handleImportMode } from '../utills/contractor.utils'
import { uploadContractorFile } from '../apiServices/contractor.api'
import { ImportContractor } from '../models/contractor.model'
import { IMPORT_STEPS } from '../constants/contractor.const'
import DiscardModal from './DiscardModal'
import Loader from '../../common/Loader'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        skip: {
            color: COLORS.blue200,
        },
        dropzone: {
            width: '50%',
            border: (colorTheme) => `2px dashed ${colorTheme.grey200}`,
            margin: '0 auto',
        },
        fileContainer: {
            textAlign: 'center',
            width: '80%',
            margin: '3rem auto',
        },
        remove: {
            color: (colorTheme) => `${colorTheme.red200}`,
        },
        next: {
            marginRight: toRem16(24),
        },
    }
})

export const ImportYourW9Step2: React.FC<any> = (): any => {
    const { currentBusinessId, importData, setImportData } =
        useContractorContext()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openDiscard, setOpenDiscard] = useState<boolean>(false)

    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }
        setUploadedFiles(files)
    }

    useEffect(() => {
        importData.file_name &&
            setUploadedFiles([{ name: `${importData.file_name}` } as File])
        return () => {
            setUploadedFiles([])
        }
    }, [importData])

    const handleRemoveUploadedFine = () => {
        if (uploadedFiles.length) {
            if (importData.id.id) {
                setOpenDiscard(true)
                return
            }
        }
        setUploadedFiles([])
    }

    const handleNext = () => {
        if (uploadedFiles.length) {
            setIsLoading(true)
            uploadContractorFile(currentBusinessId!, uploadedFiles)
                .then((res: unknown) => {
                    setIsLoading(false)
                    setImportData(res as ImportContractor)
                })
                .catch((err) => {
                    setIsLoading(false)
                })
        }
    }

    const isNewlyUploaded = (): boolean => {
        return Boolean(uploadedFiles.length && uploadedFiles[0].size)
    }

    return (
        <CollapsibleSection
            header="Upload a Spreadsheet"
            subHeader="We’ll import your contractor data."
            mode={handleImportMode(
                IMPORT_STEPS.TWO,
                importData?.completed_step
            )}
            preExpanded={importData?.completed_step === IMPORT_STEPS.ONE}
            stepNumber={IMPORT_STEPS.TWO}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {uploadedFiles.length ? (
                        uploadedFiles.map((file: any, index: number) => {
                            return (
                                index === 0 && (
                                    <div
                                        key={file.name}
                                        className={styles.fileContainer}
                                    >
                                        <InlineFlex justifycontent="space-between">
                                            <InlineFlex
                                                gap={toRem16(14)}
                                                alignitems="center"
                                            >
                                                <CSV />
                                                <div>{file.name}</div>
                                            </InlineFlex>
                                            <Button
                                                startIcon={
                                                    <Delete
                                                        style={{
                                                            fill: COLORS.red200,
                                                        }}
                                                    />
                                                }
                                                className={styles.remove}
                                                onClick={
                                                    handleRemoveUploadedFine
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </InlineFlex>
                                    </div>
                                )
                            )
                        })
                    ) : (
                        <Dropzone onDrop={handleUploadFile} accept="text/csv">
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <Box
                                        className={styles.dropzone}
                                        {...getRootProps({})}
                                    >
                                        <Box my={3}>
                                            <Typography align="center">
                                                Drop files here to upload
                                            </Typography>
                                        </Box>
                                        <Box my={3}>
                                            <input
                                                id="upload-button-file"
                                                {...getInputProps()}
                                            />
                                        </Box>
                                        <Box
                                            my={3}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                data-cy="upload-files-button"
                                            >
                                                Select Files{' '}
                                            </Button>
                                        </Box>
                                        <Box my={3}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                                align="center"
                                            >
                                                Supported file type: .csv
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            }}
                        </Dropzone>
                    )}
                    <InlineFlex justifycontent="flex-end">
                        <UiButton
                            btnType="tertiary"
                            label="Next"
                            handleClick={handleNext}
                            customClass={styles.next}
                            disabled={isLoading || !isNewlyUploaded()}
                        />
                    </InlineFlex>
                    <DiscardModal
                        openDialog={openDiscard}
                        setOpenDialog={setOpenDiscard}
                    />
                </>
            )}
        </CollapsibleSection>
    )
}
