import { useState } from 'react';
import {
    Grid,
    Radio,
    Theme,
    makeStyles,
    Typography,
    FormControlLabel,
    Button,
} from '@material-ui/core';
import { commonStyles, mergeStyle } from '../../styles/commonStyles';
import UiText from '../common/ui/UiText';
import PayrollConnectInfo from './PayrollConnectInfo';
import { COLORS } from '../../variables/colors';
import { ThemeColors } from '../../styles/models/Colors.interface';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import { useCurrentStore } from '../common/hooks/useCurrentStore';

const radioGroupLabel = [
    {
        label: 'I am starting up my business’s payroll for the first time',
        value: 'new',
        cyelement: 'new-payroll'
    },
    {
        label: 'I’ve already run payroll for my business this calendar year',
        value: 'old',
        cyelement: 'already-running-payroll'
    },
];

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    centerContent: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        height: '100%',
    },
    header: {
        lineHeight: '40px',
        textAlign: 'center',
        fontSize: theme.breakpoints.down('xs') ? '40px' : '',
    },

    marginContent: {
        marginTop: '40px',
        marginBottom: '40px',
    },
    greenCheckbox: {
        color: colorTheme => `${colorTheme.tertiary} !important`,
        '&$checked': {
            color: colorTheme => `${colorTheme.tertiary} !important`,
        },
    },
    checkboxBorder: {
        border: '1px solid',
        borderRadius: '4px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        paddingRight: '16px',
        marginBottom: '20px',
        cusor: 'pointer',
    },
    greenBorderColor: {
        borderColor: colorTheme => colorTheme.tertiary,
    },
    greyBorderColor: {
        borderColor: colorTheme => colorTheme.grey200,
    },
}));
const ProcessPayroll = ({ finishSetup }: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const styles = commonStyles();
    const [showConversationPage, setShowConversationPage] = useState(false);
    const [activeRadioButton, setActiveRadioButton] = useState<string>('new');
    const { currentBusiness } = useCurrentStore()
    const activeClass = mergeStyle(
        classes.checkboxBorder,
        classes.greenBorderColor
    );
    const nonActiveClass = mergeStyle(
        classes.checkboxBorder,
        classes.greyBorderColor
    );

    return (
        <div>
            {showConversationPage ? (
                <PayrollConnectInfo />
            ) : (
                <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.marginContent}
                    style={{
                        height: '100%',
                    }}
                >
                    <div>
                        <Typography variant="h2" className={classes.header}>
                            You’re almost there!
                        </Typography>
                        <div className={classes.marginContent}>
                            <UiText>
                                We need a little more information to set up your
                                business’s payroll.
                            </UiText>
                        </div>
                        <div className={classes.marginContent}>
                            {radioGroupLabel.map((radioButton) => (
                                <div
                                    className={
                                        radioButton.value === activeRadioButton
                                            ? activeClass
                                            : nonActiveClass
                                    }
                                    onClick={() => {
                                        setActiveRadioButton(radioButton.value);
                                    }}
                                    data-cy={radioButton?.cyelement}
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={
                                                    radioButton.value ===
                                                    activeRadioButton
                                                }
                                                onChange={() => {
                                                    setActiveRadioButton(
                                                        radioButton.value
                                                    );
                                                }}
                                                value={radioButton.value}
                                                classes={{
                                                    root: classes.greenCheckbox,
                                                }}
                                            />
                                        }
                                        label={radioButton.label}
                                    />
                                </div>
                            ))}
                        </div>
                        <div
                            className={mergeStyle(
                                classes.marginContent,
                                styles.textCenter
                            )}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    activeRadioButton === 'new'
                                        ? finishSetup()
                                        : setShowConversationPage(true);
                                }}
                            >
                                Next
                            </Button>
                            <div className={styles.mt16}>
                                <UiText textColor="textSecondary">
                                    Payroll is handled through Gusto
                                </UiText>
                            </div>
                        </div>
                    </div>
                </Grid>
            )}
        </div>
    );
};

export default ProcessPayroll;
