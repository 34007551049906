import { applyMiddleware, CombinedState, createStore, compose } from 'redux';
import reducers from './reducers';
import ReduxThunk from 'redux-thunk';
import logger from './logger/logger';
import { ApplicationStore } from '../models';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore<CombinedState<ApplicationStore>, any, any, any>(
    reducers,
    composeEnhancers(applyMiddleware(ReduxThunk, logger))
);
