import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Contact } from '../../../../models';
import { ContainedDeleteButton } from '../../../common/DeleteButton';
import { ThemeColors } from '../../../../styles/models/Colors.interface';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
interface confirmContactModalProps {
    showConfirmModal: boolean;
    handleClose: (result?: any) => void;
    contact?: Contact;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    dialogHeader: {
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: theme.spacing(4),
        lineHeight: '28px',
    },
    infoText: {
        marginBottom: theme.spacing(4),
    },
    deleteButton: {
        background: (colorTheme) => colorTheme.red400,
    },
}));

const ConfirmContactModal = ({
    showConfirmModal,
    handleClose,
    contact,
}: confirmContactModalProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const infoText = contact
        ? `${contact?.name} will be deleted from your account.`
        : `Progress made to this contact have not been saved and will be lost`;
    const headerText = contact ? 'Delete Contact' : 'Discard Draft?';

    return (
        <UiConfirmationDialog
            open={showConfirmModal}
            message={
                <Typography variant="body1" gutterBottom>
                    <div className={classes.dialogHeader}>{headerText}</div>
                    <div className={classes.infoText}>{infoText}</div>
                    <div>This cannot be undone.</div>
                </Typography>
            }
            handleClose={handleClose}
            confirmNode={
                <ContainedDeleteButton
                    className={classes.deleteButton}
                    variant="contained"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => {
                        handleClose(true);
                    }}
                    color="secondary"
                    showDeleteIcon
                    id={`${contact ? 'delete' : 'discard'}-confirm-button`}
                >
                    {contact ? 'Delete' : 'Discard'}
                </ContainedDeleteButton>
            }
            cancelButtonText="Cancel"
        />
    );
};

export default ConfirmContactModal;
