import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core';

import { useCallback, useEffect, useState } from 'react';
import UiDialog from '../../common/ui/UiDialog';
import { AppData, ApplicationStore } from '../../../models';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';
import { CategoryRule } from '../../../models/transaction-models';
import {
    deleteCategoryRule,
    getCategoryRule,
} from '../../../services/transactionsService';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Deleteicon from '../../../assets/icons/Deleteicon';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface Props {
    appData: AppData;
    isOpen: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    ruleItem: {
        minHeight: '40px',
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    bodyText: {
        margin: theme.spacing(3, '0'),
        padding: theme.spacing('0', '0', 4),
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    deleteEntry: {
        background: (colorTheme) => colorTheme.red400,
        borderRadius: '4px',
        color: (colorTheme) => colorTheme.primaryWhite,
    },
    crossButton: {
        position: 'absolute',
        right: '-20px',
        top: '0',
    },
    cancelButton: {
        marginRight: theme.spacing(3),
    },
}));

function MassCategorizationRules(props: Props) {
    const theme = useTheme();
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryRules, setCategoryRules] = useState<Record<
        string,
        CategoryRule[]
    > | null>(null);
    const [deleteRuleId, setDeleteRuleId] = useState<string | null>(null);

    const handleModalClose = () => {
        props.handleClose();
    };

    useEffect(() => {
        if (!props.isOpen) {
            setCategoryRules(null);
        } else {
            fetchRules();
        }
    }, [props.isOpen]);

    const fetchRules = useCallback(() => {
        setIsLoading(true);
        getCategoryRule(
            props.appData.current_account_id,
            props.appData.current_business_id
        )
            .then((response) => {
                setCategoryRules(response);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const deleteRule = () => {
        if (deleteRuleId) {
            setIsLoading(true);
            deleteCategoryRule(
                props.appData.current_account_id,
                props.appData.current_business_id,
                deleteRuleId
            )
                .then(() => {
                    setDeleteRuleId(null);
                    fetchRules();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const viewRuleList = () => {
        if (!categoryRules) {
            return;
        } else {
            const ruleKeys = Object.keys(categoryRules);

            return ruleKeys.map((category, index) => (
                <div
                    key={`cat-${index}`}
                    style={{ marginTop: theme.spacing(3) }}
                >
                    <Typography variant='caption'>{category}</Typography>
                    {categoryRules[category].map((rule, index) =>
                        listItem(rule, index)
                    )}
                </div>
            ));
        }
    };

    const listItem = (rule: CategoryRule, index: number) => {
        return (
            <Grid
                container
                key={`rule-${index}`}
                alignItems='center'
                className={classes.ruleItem}
            >
                <Grid item xs={11}>
                    <Typography>{`${rule.customer_name}-${rule.description}`}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        onClick={() => {
                            setDeleteRuleId(rule?.id || null);
                        }}
                    >
                        <DeleteOutlineIcon color='error' />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <UiDialog
                open={props.isOpen}
                handleClose={handleModalClose}
                title='Mass-Categorization Rules'
                size='sm'
            >
                <div>
                    {isLoading ? (
                        <Grid
                            container
                            direction='column'
                            justify='center'
                            alignItems='center'
                        >
                            <Loader />
                        </Grid>
                    ) : !Array.isArray(categoryRules) ? (
                        viewRuleList()
                    ) : (
                        <div>No Mass-Categorization Rules Found</div>
                    )}
                </div>
            </UiDialog>
            <UiDialog
                open={!!deleteRuleId}
                handleClose={() => {
                    setDeleteRuleId(null);
                }}
                title='Do you want to delete this categorization rule?'
                size='sm'
            >
                <div className={classes.bodyText}>
                    New transactions to your accounts will no longer be
                    automatically categorized with this rule. This can not be
                    undone.
                </div>
                <Box textAlign='end'>
                    <Button
                        variant='outlined'
                        className={classes.cancelButton}
                        onClick={() => {
                            setDeleteRuleId(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.deleteEntry}
                        startIcon={<Deleteicon />}
                        onClick={deleteRule}
                    >
                        Delete
                    </Button>
                </Box>
            </UiDialog>
        </>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});
export default connect(mapStateToProps)(MassCategorizationRules);
