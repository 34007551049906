import React, { Fragment } from 'react'
import { Button, IconButton } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import { useStyles } from './Styles'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import Header from './Header'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const IntroScreen = ({
    moveToCollectInfoScreen,
    moveToNoThanksScreen,
    closeModal,
}: {
    moveToCollectInfoScreen: () => void
    moveToNoThanksScreen: () => void
    closeModal: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <Fragment>
            <div className={styles.introScreenHeader}>
                <Header hideTitleBorder closeModal={closeModal} />
            </div>
            <div className={styles.introScreenContent}>
                <Fragment>
                    <UiText variant="truck_175" weight="medium_500">
                        You may qualify for <br /> Carryover Tax Benefits
                    </UiText>
                    <UiText className={styles.contentSpacing}>
                        We can check your eligibility for you by reviewing your
                        personal tax returns (1040) for tax years 2018-2022.
                    </UiText>
                </Fragment>
                <div>
                    <Button
                        variant="contained"
                        onClick={moveToCollectInfoScreen}
                        color="primary"
                    >
                        Check for Carryover Tax Benefits
                    </Button>
                </div>
                <Button
                    variant="outlined"
                    onClick={moveToNoThanksScreen}
                    className={clsx(
                        styles.outlinedButton,
                        styles.contentSpacing
                    )}
                >
                    No Thanks
                </Button>
            </div>
        </Fragment>
    )
}

export default IntroScreen