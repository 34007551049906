import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    makeStyles,
    Theme,
    Box,
    Checkbox,
    ListItemIcon,
    useMediaQuery,
} from '@material-ui/core';

import React, { Fragment } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

interface Props {
    items: any[];
    personalAccountId: string;
    searchResult?: boolean;
    selectedData?: any | null;
    onSelect: (data: any, id: string) => void;
    checkBoxHandle: (e: any, data: any, id: string) => void;
    raw: any;
    selectedChekboxData: any;
    showSnackbar?: any;
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    title: {
        padding: theme.spacing(2),
        top: theme.spacing(0),
        position: 'relative',
        display: 'inline',
    },
    listRoot: {
        display: 'grid',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2),
        },
        overflow: 'auto',
        '& .MuiDivider-root': {
            marginLeft: '0',
        },
        '& .list-container': {
            '& .MuiDivider-root': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '58px',
                },
            },
        },
    },
    inline: {
        display: 'inline',
    },
    timeText: {
        whiteSpace: 'nowrap',
        paddingTop: '6px',
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 'normal',
    },
    cardIcon: {
        width: '38px',
        left: '1px',
        opacity: '0.8',
        top: '7px',
    },
    navListItemGutters: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    newMessageButton: {
        height: '36px',
    },
    refeshButton: {
        fontSize: theme.spacing(3),
    },
    searchInput: {
        marginTop: theme.spacing(3),
        height: '40px',
        width: '100%',
    },
    archivedStatus: {
        marginRight: theme.spacing(3),
    },
    statusIcon: {
        position: 'relative',
        top: '3px',
        marginRight: theme.spacing(0),
    },
    selectedMessage: {
        background:(colorTheme) => `${colorTheme.green500} !important`
    },
    messageBody: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    attachmentIconStyle: {
        fontSize: '15px',
        top: theme.spacing(0),
        position: 'relative',
    },
    subjectText: {
        maxWidth: 'calc(100% - 80px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    ListDetail: {
        display: 'flex',
        width: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '300px',
        },
        '& .MuiListItemText-primary': {
            maxWidth: '275px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '175px',
            },
        },
    },
}));

function JournalList(props: Props) {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    
    const history = useHistory();
    const getTitle = (data: any) => {
        let res = {
            debit: '',
            credit: '',
            title: '',
        };
        data?.debit?.forEach((element: any, index: number) => {
            const debit = props?.raw?.accountRecord[element?.account];
            res.debit =
                res.debit === ''
                    ? `${res.debit}${debit?.title}`
                    : `${res.debit}, ${debit?.title}`;
            res.title = res.title + debit?.title + ', ';
        });
        data?.credit?.forEach((element: any, index: number) => {
            const credit = props?.raw?.accountRecord[element?.account];
            res.credit =
                res.credit === ''
                    ? `${res.credit}${credit?.title}`
                    : `${res.credit}, ${credit?.title}`;

            if (index === data?.credit?.length - 1) {
                res.title = res.title + credit?.title;
            } else {
                res.title = res.title + credit?.title + ', ';
            }
        });
        return res;
    };
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const getCheckedvalue = (id: string) => {
        const ischecked = Boolean(props.selectedChekboxData[id]);
        return ischecked;
    };

    const gotoLocation = (location: string) => {
        history.push(location);
    };
    const Messages = (data: any, id?: string) => {
        return (
            <Fragment>
                <List className={classes.listRoot}>
                    {data.length === 0 ? (
                        <Box textAlign='center'>
                            <Typography
                                variant='subtitle1'
                                color='textSecondary'
                            >
                                No Journal Were Found
                            </Typography>
                        </Box>
                    ) : (
                        <Box className='list-container'>
                            {data.map((element: any, index: number) => (
                                <Fragment key={index}>
                                    <ListItem
                                        classes={{
                                            gutters: classes.navListItemGutters,
                                            selected: classes.selectedMessage,
                                        }}
                                        alignItems='flex-start'
                                        selected={
                                            props.selectedData?.id ===
                                            element.id && !smDevice
                                        }
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                onChange={(
                                                    e: React.ChangeEvent
                                                ) =>
                                                    props.checkBoxHandle(
                                                        e,
                                                        element,
                                                        element.id
                                                    )
                                                }
                                                size='small'
                                                checked={getCheckedvalue(
                                                    element?.id
                                                )}
                                            />
                                        </ListItemIcon>
                                        <div
                                            onClick={() => {
                                                props.onSelect(
                                                    element,
                                                    element.id
                                                );
                                                if (smDevice) {
                                                    gotoLocation(
                                                        `/journal/${element.id}`
                                                    );
                                                }
                                            }}
                                            className={classes.ListDetail}
                                        >
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    style: {
                                                        fontWeight: element.unread_messages_count
                                                            ? 'bold'
                                                            : 'normal',
                                                    },
                                                }}
                                                primary={
                                                    <Fragment>
                                                        <Typography noWrap>
                                                            {
                                                                getTitle(
                                                                    element
                                                                ).title
                                                            }
                                                        </Typography>
                                                    </Fragment>
                                                }
                                                secondary={
                                                    <Box
                                                        component='span'
                                                        className={
                                                            classes.messageBody
                                                        }
                                                    >
                                                        {moment(
                                                            element.date * 1000
                                                        ).format(
                                                            'DD MMM YYYY h:mm A'
                                                        )}
                                                    </Box>
                                                }
                                            />
                                            <Typography
                                                className={classes.timeText}
                                                variant='caption'
                                            >
                                                {`$${element.amount}`}
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    {index === data.length - 1 ? (
                                        ''
                                    ) : (
                                        <Divider variant='inset' />
                                    )}
                                </Fragment>
                            ))}
                        </Box>
                    )}
                </List>
            </Fragment>
        );
    };

    return <>{Messages(props.items)}</>;
}

export default JournalList;
