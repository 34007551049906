import React from 'react'
import { Grid, List, makeStyles } from '@material-ui/core'
import { Purpose } from '../../../models/purposes'
import Loader from '../../common/Loader'
import PurposeItem from './PurposeItem'

interface PurposeModalProps {
    loading: boolean
    data: Purpose[]
    selectedPurpose?: Purpose
    setSelectedPurpose: React.Dispatch<Purpose | undefined>,
    loadPurposes: () => void,
    purposeCallback?: (purpose: Purpose | null) => void,
    setOpenModal: React.Dispatch<boolean>
    fetchPurposeTrip: (id: string) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listLoader : {
        minHeight: '100%'
    }
}))

function PurposesList({
    loading,
    data,
    selectedPurpose,
    setSelectedPurpose,
    loadPurposes,
    purposeCallback,
    setOpenModal
}: PurposeModalProps) {
    const classes = useStyles()


    const deletePurposeCallBack = () => {
        if(data[0]){
            setSelectedPurpose(data[0]);
        }else {
            setSelectedPurpose(undefined);
        }
    }

    return (
        <List className={classes.root} id="scrollableDiv">
            {loading ? (
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.listLoader}
                >
                    <Loader />
                </Grid>
            ) : (
                <>
                {data.length > 0 && (
                    data.map((purpose: Purpose, index: number) => (
                        <PurposeItem
                            key={purpose.id}
                            purpose={purpose}
                            selectedPurpose={selectedPurpose}
                            setSelectedPurpose={setSelectedPurpose}
                            lastItem={(data.length - 1) === index}
                            loadPurposes={loadPurposes}
                            purposeCallback={purposeCallback}
                            setOpenModal={setOpenModal}
                            deletePurposeCallBack={deletePurposeCallBack}
                        />
                    ))
                )}
                </>
            )}
        </List>
    )
}

export default PurposesList