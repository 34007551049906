import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, makeStyles, Theme } from '@material-ui/core'
import UiDialog from "../common/ui/UiDialog";
import UiText from '../common/ui/UiText';
import Conversation from '../../assets/images/Conversation.png'
import { commonStyles } from '../../styles/commonStyles'
import { showError } from '../../services/formService'
import { ApplicationStore, GDSessionDetails } from '../../models'
import moment from 'moment'
import { ThemeColors } from '../../styles/models/Colors.interface';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
interface JoinAdvisorMeetingPopupProps {
    open: boolean
    handleClose: () => void
    callDetails?: undefined | {
        gd_session_details: GDSessionDetails
    }
}

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    dialogContainer: {
        "& .MuiPaper-root": {
            width: "640px",
            maxWidth: "700px",
        },
        "& .MuiDialogContent-root": {
            overflow: "hidden",
        },
        "& .MuiPaper-rounded": {
            borderRadius: "24px",
        }
    },
    resumeButton: {
        color: colorTheme => colorTheme.blue200
    },
    imageHeight: {
        height: '200px'
    }
}));

const DG_TIMEZONE = 'gd_timezone';

const JoinAdvisorMeetingPopup = ({
    open,
    handleClose,
    callDetails
}: JoinAdvisorMeetingPopupProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const styles = commonStyles()   
    const currentBusiness = useSelector((state: ApplicationStore) => state?.appData?.current_business)

    const owners = currentBusiness?.business_owners
    const firstOwner = owners?.[0]

    const getPrimaryOwner = useCallback((primaryOwnerId: string) => {
        return owners?.find(owner => owner.sf_business_owner_id === primaryOwnerId)
    },[owners])

    const getOwnerName = () => {
        const primaryOwnerId = currentBusiness?.primary_business_owner_sf_id
        if(primaryOwnerId) {
            const primaryOwner = getPrimaryOwner(primaryOwnerId)
            return `${primaryOwner?.first_name}`
        }
        return `${firstOwner?.first_name}`
    }

    const sessionDetails = callDetails?.gd_session_details
    const meetingURL = `${process.env.REACT_APP_DEV_SERVER_URL}presenter-web/#/client?token=${sessionDetails?.client_token}&session_id=${sessionDetails?.session_id}`
    
    const handleJoinMeeting = () => {
        const hasMeetingEnded = sessionDetails && sessionDetails?.expires_at as number < moment().unix()
        hasMeetingEnded ? handleMeetingEnding() : launchGdCall();
    }

    const launchGdCall = () => {
        localStorage.setItem(DG_TIMEZONE, `${new Date().getTimezoneOffset()}`);
        window.open(meetingURL, '_blank');
    }

    const handleMeetingEnding = () => {
        showError("The meeting has ended")
        handleClose()
    }

    
    return (
            <UiDialog
                open={open}
                handleClose={handleClose}
                hideTitleSection={true}
                size="sm"
                customRootClass={classes.dialogContainer}
            >
                <div className={styles.textCenter}>
                    <div className={styles.mtb16}>
                        <img src={Conversation} alt="changes" className={classes.imageHeight}/>
                    </div>   
                    <div className={styles.mtb16}>
                        <UiText variant="hatchback_125" weight="medium_500">{`Hi ${getOwnerName()},`}</UiText>
                        <UiText variant="hatchback_125" weight="medium_500">
                            It's time for your meeting with your tax advisor!
                        </UiText>
                    </div>

                    <Button color="primary" variant='contained' onClick={handleJoinMeeting}>
                        Join Meeting
                    </Button>
                    <div className={styles.mtb16}> 
                        <UiText
                            textColor="secondary"
                        >
                            (Using a web cam is not required)
                        </UiText>
                    </div>
                </div>
            </UiDialog>
    )
}

export default JoinAdvisorMeetingPopup;