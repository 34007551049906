import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { inputMaskRegex } from '../../../../utils/maskUtil'
import UiText from '../../../common/ui/UiText'
import { commonStyles } from '../../../../styles/commonStyles'
export const RenderFormFields = ({ property }: any) => {
    const styles = commonStyles()
    return (
        <>
            {property?.type === 'radio' && (
                <UiText className={styles.mb16}>{property?.label}</UiText>
            )}
            <UiFormControlSelection
                showFloatingLabel
                placeholder={property?.placeholder}
                type={property.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                optionsData={property?.data}
                optionKey={property?.optionKey}
                optionValue={property?.optionValue}
                label={property?.label}
                fastField={property?.fastField}
                size={property.size}
                {...(property.type === 'KeyboardDatePicker'
                    ? {
                          disableFutureDate: true,
                          dateType: 'string',
                          dateFormat: 'MM/DD/YYYY',
                          nonIsoDate: true,
                      }
                    : {})}
                {...(property?.minLength
                    ? { minLength: property?.minLength }
                    : {})}
                {...(property.type === 'mask'
                    ? {
                          mask: property?.regex
                              ? property?.regex
                              : inputMaskRegex,
                          fieldSize: 6,
                      }
                    : {})}
                {...(property.endIcon ? { endIcon: property.endIcon } : {})}
                {...(property.type === 'radio'
                    ? {
                          radioOptionsData: property?.radioOptionsData,
                      }
                    : {})}
                {...(property.onChange 
                ? {
                        onChange: property.onChange 
                    }
                : {})}
            />
        </>
    )
}
