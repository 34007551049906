import { Config } from '../../../models/store'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import Resizer from 'react-image-file-resizer';
export const maxNumberFilesUpload = 5;
export const maxFileSizeUpload = 10 * (1024 * 1024); //10 MB (binary value)
export const minFileSizeToCompress = 4 * (1024 * 1024); //4 MB (binary value)
export const maxCompressedImageWidth = 1200;
export const maxCompressedImageHeight = 960;
export const useLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        dividerStyle: {
            marginTop: theme.spacing(3),
            marginLeft: '-12px',
            marginRight: '-12px',
        },
        fieldContainer: {
            marginBottom: theme.spacing(4),
        },
        skippedFormEntry: {
            opacity: 0.6,
        },
        marginRight: {
            marginRight: theme.spacing(1),
        },
        receiptImgPreview: {
            maxWidth: '100%',
        },
        autocomplete: {
            '& .MuiInputBase-fullWidth': {
                height: '40px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                '& .MuiAutocomplete-input': {
                    padding: '0px',
                },
            },
        },
        textFieldRoot: {
            '& .MuiInputLabel-root': {
                top: '-7px',
            },
        },
    })
)

export interface verifyScanProps {
    open: boolean
    handleClose: (result?: any, action?: any) => void
    config: Config
    accountId: string
    businessId: string
    filesToScan: any[]
    scanResult: any[]
    allowedFileTypesToPreview: string[]
    getFileType: any
    datasource: any
    setListData: (data: any) => void
}

export interface JournalEntryDebitProps {
    amount: number
    account: string
    date: number
    isSkipped: boolean
    category?: any
}

export interface JournalEntryOptionsProps {
    moveToDoc: string[]
}

export interface JournalEntryInputProps {
    debit: JournalEntryDebitProps[]
    moveToDoc?: boolean[]
}

export interface categoryListProps {
    data: any,
    categoryOptions: any,
    accountRecord: any,
    loading: boolean
}

export const resizeReceiptImage = (file: File) =>
    new Promise((resolve) => {
        let ext = file.name.split('.').pop();
        if (ext?.toLocaleLowerCase() === 'jpg') {
            ext = 'JPEG';
        }
        Resizer.imageFileResizer(
            file,
            maxCompressedImageWidth,
            maxCompressedImageHeight,
            ext ? ext.toUpperCase() : 'JPEG',
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            'file'
        );
    });
