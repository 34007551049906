import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { ListItemIcon, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import Icon from '../../../common/Icon'
import Loader from '../../../common/Loader'
import UiText from '../../../common/ui/UiText'
import IconList from '../../../common/IconList'
import { toRem16 } from '../../../../styles/commonStyles'
import UiSnackbarAlert from '../../../common/ui/UiSnackbarAlert'
import { loadPostAuthData } from '../../../../store/actions/appData'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { usePayrollMigrationState } from '../../Providers/PayrollMigrationProvider'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    businessItem: {
        position: 'relative',
        width: '30rem',
        height: '4.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '1rem 1.5rem 1rem 1rem',
        marginBottom: '1rem',
        borderRadius: '0.5rem',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },

        '& > div:first-child': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',  
        },
       
    },
    businessList: {
        overflowX: 'hidden',
        overFlowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            boxSizing: 'border-box',
            width: '100vw',
            padding: '0 1rem',
        },
    },
    businessItemContainer: {
        position: 'relative',
        width: 'fit-content',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    avatarStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        height: toRem16(40),
        width: toRem16(40),
        backgroundColor: (themeColors) => themeColors.teal200,
    },
    loaderClass: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: (colorTheme) => colorTheme.grey200,
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        textAlign: 'center',
        marginBottom: '2.25rem',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.5rem',
        },
    },
}))

const BusinessSelectSection = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
   
    const [isError, setIsError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingBusinessId, setLoadingBusinessId] = useState<number | null>(
        null
    )
    const {businessList, isBusinessListLoading} = usePayrollMigrationState()
    const dispatch = useDispatch()
    const history = useHistory()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    const businessMenuClickHandler = (account: any) => {
        setIsLoading(true)
        props
            .loadPostAuthData(account.portal_account)
            .then(() => {
                dispatch({ type: 'RESET_CATEGORIES' }) 
                const uniqueKey = new Date().getTime();
                const baseUrl = window.location.href.split('#')[0];
                window.location.href = `${baseUrl}#/payroll-migration?forceReload=${uniqueKey}`;
            })
            .catch((error: any) => {
                setIsError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

   

    if (isBusinessListLoading) {
        return (
            <div className={classes.loader}>
                <Loader />
            </div>
        )
    }

    return (
        <>
            {businessList.length !== 0 &&
                !isBusinessListLoading &&
                !props?.successPage && (
                    <UiText
                        className={classes.title}
                        variant="suv_150"
                        weight="bold_700"
                    >
                        Select a Business Payroll account to update
                    </UiText>
                )}
            {businessList.length !== 0 &&
                !isBusinessListLoading &&
                props?.successPage && (
                    <UiText
                        className={classes.title}
                        variant="hatchback_125"
                        weight="bold_700"
                    >
                        Update another business {smDevice && <br />} payroll bank account
                    </UiText>
                )}
            <div className={classes.businessList}>
                {businessList.length === 0 ? (
                    <></>
                ) : (
                    businessList.map((business: any, index: number) => (
                        <div
                            key={index}
                            className={classes.businessItemContainer}
                        >
                            {isLoading && loadingBusinessId === business.id && (
                                <div className={classes.loaderClass}>
                                    <Loader />
                                </div>
                            )}

                            <div
                                key={index}
                                className={classes.businessItem}
                                onClick={() => {
                                    setLoadingBusinessId(business?.id)
                                    businessMenuClickHandler(business)
                                }}
                            >
                                <div>
                                    <ListItemIcon>
                                        <div className={classes.avatarStyle}>
                                            {IconList['purpose']}
                                        </div>
                                    </ListItemIcon>
                                    <UiText
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                    >
                                        {business?.name.length > 41 ? `${business?.name.substring(0, 41)}...` : business?.name}
                                    </UiText>
                                </div>
                                <Icon icon="rightArrow" svgColor="#121724" />
                            </div>
                        </div>
                    ))
                )}
            </div>
            <UiSnackbarAlert
                open={isError}
                message={'Failed to fetch business list. Please try again.'}
                handleClose={() => setIsError(false)}
                actionButtonColor={'primary'}
                severity="error"
            />
        </>
    )
}

export default connect(null, { loadPostAuthData })(
    BusinessSelectSection
)
