import React from 'react'
import Loader from '../../common/Loader'
import { TodoSection } from '../TodoSection/TodoSection'
import {
    COMPLETION_PERCENTAGE,
    DOWNLOAD_APP_AD_BLOCK,
    TAX_ADVISORY,
} from '../TodoSection/Todo.const'
import {
    isBlockDismissed,
} from './AccountSetupUtility'
import { useTodoBlockInfo } from '../TodoSection/hooks/useTodoBlockInfo'
import TodoAdBlocks from '../TodoSection/TodoAdBlocks'
import { Theme, makeStyles } from '@material-ui/core'
import { toRem16 } from '../../../styles/commonStyles'
import DownloadAppAd from '../Cards/DownloadAppAd'
import { useDashboardContext } from '../Provider/DashboardProvider'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { hasPermission } from '../../../services/aclService'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

import type { AccountStep } from '../../../models/dashboardModel'


const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            minHeight: toRem16(600),
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            marginTop: toRem16(8),
        },
    }
})

const AccountSetupItems = () => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { 
        loading,
        setLoading,
        invoiceData,
        accountProgress,
        dismissPopupInfo,
        completedStepCount,
        setupCompletionPercent,
        hasLoginFromMobile,
        fetchAccountProgress,
    } = useDashboardContext()

    const { personalAccount, currentConfig } = useCurrentStore()
    const accountSteps = currentConfig.local.account_steps
    const freeAccountSteps = accountSteps.filter((step: AccountStep)=> !step.tax_advisory_required)
    
    const todoSteps: AccountStep[] = hasPermission(TAX_ADVISORY, personalAccount) ? accountSteps : freeAccountSteps

    // one extra step for default sign-in to portal
    const remainingSetupItems: number = (todoSteps.length + 1) - completedStepCount
    
    const shouldShowTodoSection = setupCompletionPercent !== COMPLETION_PERCENTAGE;
    const shouldShowToDoTitle = !shouldShowTodoSection;

    const { count: remainingAccountToDos } = useTodoBlockInfo();

    const totalRemainingToDos = remainingAccountToDos + remainingSetupItems;

    if (loading) {
        return (
            <div className={styles.loaderContainer}>
                <Loader />
            </div>
        )
    }

    return (
        <div>
                {shouldShowTodoSection && <TodoSection
                    accountProgress={accountProgress}
                    dismissPopupInfo={dismissPopupInfo}
                    fetchAccountProgress={fetchAccountProgress}
                    setupCompletionPercent={setupCompletionPercent}
                    setLoading={setLoading}
                    todoSteps={todoSteps}
                    remainingToDos={totalRemainingToDos}
                />}
                <TodoAdBlocks
                    accountProgress={accountProgress}
                    dismissPopupInfo={dismissPopupInfo}
                    fetchAccountProgress={fetchAccountProgress}
                    invoiceData={invoiceData}
                    showToDoTitle={shouldShowToDoTitle}
                    remainingToDos={totalRemainingToDos}
                />
            {!hasLoginFromMobile && !isBlockDismissed(DOWNLOAD_APP_AD_BLOCK) && (
                <DownloadAppAd
                    reload={() => {
                        fetchAccountProgress()
                    }}
                />
            )}
        </div>
    )
}

export default AccountSetupItems