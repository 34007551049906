import React from 'react'
import { Purpose } from '../../../../models/purposes'
export const defaultValues = {
    is_default :  false,
    is_default_mt_purpose : false,
    name : ""
}
export const propertyMappingGeneral: PurposePropFieldsInterface[] =  [
    {
        key             : 'name',
        type            : 'text',
        placeholder     : 'Purpose Name',
        md              : 12,
        xs              : 12,
        required        : true,
        errorMessage    : 'Required',
        label           : 'Name (Required)',
    },
    {
        key             : 'is_default_mt_purpose',
        type            : 'checkbox',
        required        : false,
        label           : 'Set as default',
    }
]
export interface PurposeFormProps {
    purpose?                : any
    action?                 : string
    handleClose?            : any
    setSelectedPurpose?     : React.Dispatch<Purpose | undefined>
    loadPurposes?           : () => void
    formikRef?              : any
    purposeCallback?        : (purpose: Purpose) => void
    setLoading?             : React.Dispatch<React.SetStateAction<boolean>>
    handleFormClose?        : () => void
}

export type InputType = "checkbox" | "text";
export interface PurposePropFieldsInterface {
    key             : string,
    type            : InputType,
    placeholder?    : string,
    md?             : number,
    xs?             : number,
    required?       : boolean,
    errorMessage?   : string,
    label?          : string
}

export interface PurposePayload {
    is_default              : boolean,
    is_default_mt_purpose   : boolean,
    name                    : string
}