import UiConfirmationDialog from '../../../common/ui/UiConfirmationDialog';
import { makeStyles, Theme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ContainedDeleteButton } from '../../../common/DeleteButton';
import UiText from '../../../common/ui/UiText'
import { commonStyles } from '../../../../styles/commonStyles';

interface DiscardDraftModalProps {
    showConfirmModal: boolean;
    handleParentModalClose: () => void;
    handleModalClose: () => void;
}

const DiscardDraftModal = ({
    showConfirmModal,
    handleParentModalClose,
    handleModalClose
}: DiscardDraftModalProps) => {
    const common = commonStyles()
    return (
        <UiConfirmationDialog
            open={showConfirmModal}
            message={
                <div>
                    <UiText variant="hatchback_125" weight="semi_bold_600">Discard draft ?</UiText>
                    <UiText className={common.mt16}>Do you want to discard this unsaved invoice ?</UiText>
                    <UiText className={common.mt16}>This cannot be undone.</UiText>
                </div>
            }
            handleClose={handleModalClose}
            confirmNode={
                <ContainedDeleteButton
                    variant="contained"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => {
                        handleModalClose()
                        handleParentModalClose()
                    }}
                    color="secondary"
                    showDeleteIcon
                >
                    Discard
                </ContainedDeleteButton>
            }
            cancelButtonText="Keep Editing"
        />
    );
};

export default DiscardDraftModal;
