import React, {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Chip,
    createStyles,
    Divider,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    makeStyles,
    OutlinedInput,
    SnackbarContent,
    Theme,
    Typography,
    useMediaQuery,
    TablePagination,
    FormControlLabel,
    Checkbox,
    FormControl,
    Select,
    MenuItem,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Search, Cancel } from '@material-ui/icons';
import Loader from '../../common/Loader';
import WhiteDeleteIcon from '../../../assets/icons-svg/WhiteDelete.svg';
import Exclude from '../../../assets/icons-svg/Exclude.svg';
import { showAlert } from '../../../store/actions/feedback';
import {
    AppData,
    ApplicationStore,
    FilterAmontOperators,
    FilterConfig,
    FilterPeriods,
} from '../../../models/store';
import { useLocation, useHistory } from 'react-router-dom';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'
import TransactionThread from './TransactionThread';
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog';
import UiDialog from '../../common/ui/UiDialog';
import PortalTabs from '../../common/PortalTabs';
import TabPanelHolder from '../../common/TabPanelHolder';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import AccountSelector from './AccountSelector';
import { getFromLocalStorage, setInLocalStorage } from '../../../services/localStorageService'
import {
    getBankAccounts,
    BankAccounts,
    reconnectBankAccount,
    resetBankAccounts,
} from '../../../services/bankAccountService';
import { IBankAccount } from '../../../models/bank-account-models';
import {
    ITransaction,
    TransactionQueryObject,
} from '../../../models/transaction-models';
import {
    deleteTransactionById,
    transactionPage,
    categorizeTransactions,
    updateTransaction,
    syncTransactions as syncFinicityTransactions,
    syncPlaidTransactions,
    applyAutoCategorization,
    getAutoCategorizationProcessStatus

} from '../../../services/transactionsService';
import TransactionsList from './TransactionsList';
import { PageService } from '../../../services/pageService';
import NewTransactionModal from './NewTransactionModal';
import moment from 'moment';
import TransactionFilter from './TransactionFilter';
import { jsonToRql, rqlToJson } from '../../../utils/rql';
import { currencyFormatter } from '../../../utils/appUtil';
import TransactionCategorizeModal from './TransactionCategorizeModal';
import SnackbarActions from './SnackbarActions';
import EmptyState from '../../../assets/icons-svg/Empty State Icon.svg';
import LinkButton from '../../common/LinkButton';
import { initCategories } from '../../../store/actions/categories';
import UiButton from '../../common/ui/UiButton';
import { saveBusinessDetails } from '../../../services/apiService/business';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import SettingsModal from './SettingsModal';
import UiText from '../../common/ui/UiText';
import { commonStyles, toRem16 } from '../../../styles/commonStyles';
import { loadPostAuthData } from '../../../store/actions/appData';
import { showError } from '../../../services/formService';
import { ConnectionType } from '../banking/constants/banking.const';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useBankDetailsState } from '../banking/provider/BankDetailsProvider';

const filterDefaultValues: TransactionQueryObject = {
    type: '',
    from_date: moment().endOf('day').unix(),
    to_date: moment().endOf('day').unix(),
    period: 'all',
    amount: { value: '', operator: 'eq' },
    number: '',
};

interface CustomizedRouteState {
    filter: {
        type: string
        start_date: string
        end_date: string
    }
    from: string
}

let reloadAfterCategorized: boolean = false;

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
            padding: '1rem 1rem 1rem 1rem',
        },
        listRoot: {
            paddingBottom: theme.spacing(3),
            width: 'inherit',
            height: '100%',
            flexDirection: 'column',
        },
        newMessageButton: {
            height: '36px',
            marginRight: theme.spacing(3),
        },
        refeshButton: {
            fontSize: theme.spacing(3),
        },
        searchInput: {
            marginTop: theme.spacing(3),
            height: '40px',
            width: '100%',
            '&.Mui-focused fieldset': {
                border: '1px solid black !important',
            },
            [theme.breakpoints.down('sm')]: {
                width: '95%',
            },
        },
        close: {
            padding: theme.spacing(0.5),
            position: 'absolute',
            left: '0px',
        },
        buttonGap: {
            marginRight: theme.spacing(2),
        },
        dividerStyle: {
            marginTop: theme.spacing(3),
            marginLeft: '-12px',
            marginRight: '-12px',
        },
        customWidth: {
            maxWidth: 256,
        },
        deleteEntry: {
            background: (colorTheme) => colorTheme.red400,
            borderRadius: '4px',
        },
        pagination: {
            overflow: 'hidden',
        },
        pageToolbar: {
            padding: 0,
        },
        deleteModal: {
            '& div:first-child': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '20px',
                lineHeight: '28px',
                letterSpacing: '0.15px',
                color: (colorTheme) => colorTheme.black100,
                padding: '0.5rem 0rem',
            },
            '& div': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                color: (colorTheme) => colorTheme.black100,
                padding: '0.3rem 0rem',
            },
        },
        accountContainer: {
            margin: theme.spacing(5, '0', 3),
        },
        accountIconContainer: {
            paddingTop: theme.spacing(0),
            marginRight: theme.spacing(2),
            color: (colorTheme) => colorTheme.grey1000,
            '& > svg': {
                fontSize: '44px',
            },
        },
        accountName: {
            color: (colorTheme) => colorTheme.grey1000,
        },
        filterBtn: {
            height: '40px',
            color: (colorTheme) => colorTheme.grey1000,
            [theme.breakpoints.down('sm')]: {
                minWidth: 0,
                '& > span.MuiButton-label': {
                    justifyContent: 'flex-start',
                    '& > span > svg': {
                        fontSize: '24px!important',
                    },
                },
            },
        },
        sectionContainer: {
            flexWrap: 'nowrap',
            height: '100%',
        },
        sectionPanels: {
            flexBasis: '47.5%',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
            },
        },
        sectionDivider: {
            maxWidth: '5%',
        },
        snackbar: {
            position: 'absolute',
            top: '0',
            zIndex: 10000,
            left: '0',
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            '& .MuiSnackbarContent-message': {
                padding: '8px 35px',
                [theme.breakpoints.down('sm')]: {
                    padding: '8px 35px',
                },
            },
            '& .MuiSnackbarContent-root': {
                borderRadius: 0,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                left: 0,
            },
        },
        adminSnackbar: {
            top: '3rem',
        },
        snackbarContent: {
            background: 'rgba(41, 49, 71, 1)', 
        },
        buttonStyle: {
            color: (colorTheme) => colorTheme.primaryWhite,
        },
        marginTop24: {
            marginTop: theme.spacing(4),
        },
        marginTop16: {
            marginTop: theme.spacing(3),
        },
        emptyText: {
            marginTop: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                width: '50%',
            },
        },
        mobileHeader: {
            padding: theme.spacing(0, 2),
        },
        bold: {
            fontWeight: 'bold'
        },
        featureChip : {
            backgroundColor: (colorTheme) => colorTheme.cyan100,
            fontWeight: 'bold',
            borderRadius: '4px'
        },
        sectionWrap: {
            width: '100%',
            paddingTop: 0,
            paddingBottom: theme.spacing(2),
            marginTop: 0,
            textAlign: 'center'
        },
        smartCatSmallBox : {
            border: '1px solid',
            borderColor: (colorTheme) => colorTheme.grey200,
            borderRadius: '0.25rem',
            padding: theme.spacing(4),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        smartCatSettingModal: {
            '& .MuiPaper-root ': {
                padding: theme.spacing(4), 
            },
            '& .MuiDialogTitle-root': {
                margin: '0px',
                paddingTop: '0.75rem',
                paddingBottom: '0px',
                border: 'none'
            }
        },
        smartCategoryButtonWrapper: {
            width: '100%',
            textAlign: 'center'
        },
        rejectBtn: {
            marginTop: theme.spacing(4),
            textAlign: 'center'
        },
        smartDesc1: {
            width: '100%',
            textAlign: 'center',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        autoCategoryStatus: {
            border: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey200}`,
            padding: theme.spacing(2)
        },
        seconderyActionButtonOutline: {
            borderColor: (colorTheme) => `${colorTheme.primaryBlack} !important`,
        },
        syncButton:{
            background: 'transparent',
            marginLeft: 'auto',
            color: (colorTheme) => colorTheme.grey500,
            '&:hover':{
                background: 'transparent',
            }
        }
    })
);

const Transactions = (props: {
    appData: AppData;
    filtersConfig: FilterConfig;
    transactionFilterTypes: any;
    category: any;
    loadPostAuthData:any
}) => {
    const theme = useTheme()
    const smartModalFlagName = 'smart_category_confirm_modal_display';
    const isAdminLogin = props?.appData?.user?.accountant_mode
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const commonClass = commonStyles();
    const dispatch = useDispatch();
    const location = useLocation()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [allBankAccounts, setAllBAnkAccounts] = useState<IBankAccount[]>([]);
    const [selectedAcc, setSelectedAcc] = useState<IBankAccount | null>(null);
    const [bankListObj, setBankListObj] = useState<BankAccounts | null>(null);
    const [transactionSync, setTransactionSync] = useState<boolean>(false);
    const [loadingBankData, setLoadingBankData] = useState<boolean>(true);
    const [selectedData, setSelectedData] = useState<ITransaction | null>(null);
    const [selectedChekboxData, setSelectedChekboxData] = useState({});
    const [openModal, setopenModal] = useState<boolean>(false);
    const [openAutoCategoryConfirmModal, setOpenAutoCategoryConfirmModal] = useState<boolean>(false);
    const [autoCategoryStatus, setAutoCategoryStatus] = useState<boolean>(false);
    const [smartCatModalLoading, setSmartCatModalLoading] = useState<boolean>(false);
    const [smartCatSettingsModalOpen, setSmartCatSettingsModalOpen] = useState<number>(0);
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
    const [excludedTransactions, setExcludedTransactions] =
        useState<boolean>(false);
    const { currentBusiness, currentAccountId, currentBusinessId } =  useCurrentStore();
    const { isFincity}  = useBankDetailsState()
    const connectionType = !isFincity
        ? ConnectionType.Plaid
        : ConnectionType.Finicity
    const history = useHistory();
    const [listData, setListData] = useState<PageService<ITransaction> | null>(
        null
    );
    const [deleteSingleEntry, setdeleteSingleEntry] =
        useState<null | ITransaction>(null);
    const [isEdit, setisEdit] = useState(false);
    let autoCategorizeTimeout: any;
    // alert state
    const [checkedTransactions, setCheckedTransactions] = useState<string[]>(
        []
    );
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const { setActiveRouteHeading, setSnackBarNode } = useContext(
        ActiveRoutingContext
    );
    const [openCategorizeModal, setOpenCategorizeModal] = useState(false);
    const [showExcludeModal, setShowExcludeModal] = useState(false);
    const [excludeSingleEntry, setExcludeSingleEntry] =
        useState<null | ITransaction>(null);
    const [excludeConfirmed, setExcludeConfirmed] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    //filters

    const [match_text, setTerm] = useState('');
    const [queryObject, setQueryObject] =
        useState<TransactionQueryObject>(filterDefaultValues);
    const [transactionPopupState, setTransactionPopupState] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    const searchQuery = query.get('search');
    const pageQuery = query.get('page');
    const accountQuery = query.get('account');

    const [chipsToDisplay, setChipsToDisplay] = useState<
        {
            label: string;
            searchKey: 'period' | 'amount' | 'match_text' | 'number' | 'type';
        }[]
    >([]);
    const [refState, setRefState] = useState<any>();
    let canUseBulkActions = listData?.items?.filter(
        (item: any) => selectedAcc?.type !== 'manual_group' && item.can_use_bulk
    );

    const handleModalCLose = () => {
        setopenModal(false);
        setisEdit(false);
    };

    useEffect(() => {
        setActiveRouteHeading('Transactions');
        getBankAccData();
        triggerStatuscheck();
        let smartCatModalStatus = getFromLocalStorage(smartModalFlagName);
        smartCatModalStatus = smartCatModalStatus === ''? 1: smartCatModalStatus;
        setInLocalStorage(smartModalFlagName, smartCatModalStatus);
        if((smartCatModalStatus > 0 && smartCatModalStatus <=2 ) && (!currentBusiness?.enable_auto_categorization && currentBusiness?.enable_smart_categorization)) {
            setSmartCatSettingsModalOpen(smartCatModalStatus);
        }
    }, [excludedTransactions]);

    useEffect(() => {
        dispatch(initCategories(true));
    }, [dispatch]);

    useEffect(() => {
        return () => {
            resetBankAccounts();
        };
    }, []);

    useEffect(() => {
        // This is being used in dashboard view transaction card
        const state = location.state as CustomizedRouteState
        if(state?.from === 'dashboard') {
            const filter = state?.filter
            const startDate = moment(filter?.start_date).startOf('day').unix()
            const endDate = moment(filter?.end_date).endOf('day').unix()
            handleApplyFilter(
                {
                    ...queryObject,
                    type: filter?.type as string,
                    from_date: startDate,
                    to_date: endDate,
                    period: 'custom'
                },
                1,
                ''
            );
        }
    },[])
    const excludedTabHandler = (e: number) => {
        const isExcluded = e ? true : false;
        setExcludedTransactions(isExcluded);
    };

    const getBankAccData = useCallback(() => {
        getBankAccounts(excludedTransactions).then((list) => {
            setLoadingBankData(false);
            setBankListObj(list);
            setAllBAnkAccounts(list ? list.list : []);
        });
    }, [excludedTransactions]);

    const accountChangeHandler = (account?: IBankAccount) => {
        setSelectedAcc(account ? account : null);
        if (listData && listData.page > 1) {
            handleApplyFilter(queryObject);
        }
    };

    const handleSettingsModalClose = () => {
        setSmartCatSettingsModalOpen(getFromLocalStorage(smartModalFlagName));
        setSettingsModalOpen(false);
    }

    const handleSmartCatModalClose = (level:number) => {
        setInLocalStorage(smartModalFlagName, level);
        setSmartCatSettingsModalOpen(level);
    }

    const turnOnSmartCategorization = () => {
       setSmartCatModalLoading(true);
        const businessData = {...currentBusiness, enable_auto_categorization:true};
            saveBusinessDetails(businessData, currentAccountId, currentBusinessId as string )
            .then((res)=>{
                props.loadPostAuthData(currentAccountId).then(()=>{
                    setInLocalStorage(smartModalFlagName, 3);
                    setSmartCatSettingsModalOpen(3);
                    setSmartCatModalLoading(false);
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Smart Categorization settings updated.`,
                        })
                    );
                });
            }).catch((e)=>{
                setSmartCatModalLoading(false);
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: `Unable to update the smart categorization settings. Please try again later`,
                    })
                );
         });
    }

    const getTransactionData = useCallback(
        (
            query: any,
            account: IBankAccount,
            keepSelectedTransaction?: Boolean
        ): void => {
            setCheckedTransactions([]);
            const pageAction = transactionPage(
                props.appData.current_account_id,
                props.appData.current_business_id,
                account?.account,
                true,
                excludedTransactions
            );
            let { page, perPage, ...params } = query;

            setListData(
                (prev: any) =>
                    ({
                        ...prev,
                        loading: true,
                        page,
                    } as PageService<ITransaction>)
            );
            pageAction
                .forceLoad(query.page, query.perPage, params)
                .then((data: PageService<ITransaction>) => {
                    setListData(
                        (prev: any) =>
                            ({
                                ...data,
                                loading: false,
                            } as PageService<ITransaction>)
                    );
                })
                .catch((err) => {
                    setListData((prev: any) => ({ ...prev, loading: false }));
                });
        },
        [] //eslint-disable-line react-hooks/exhaustive-deps
    );

    // visibility of of Add transaction and Sync buttons
     const showSyncButton = () => {
         if (
             !excludedTransactions &&
             (selectedAcc?.type === 'aggregated' ||
                 selectedAcc?.type === 'plaid')
         ) {
             return selectedAcc.connected
         }
         return (
             !excludedTransactions &&
             selectedAcc?.type === 'aggregated_group' &&
             bankListObj?.connectedCount &&
             bankListObj?.connectedCount > 0
         );
    };

    const showAddTransactionButton = () => {
        return (
            !excludedTransactions &&
            (selectedAcc?.type === 'aggregated' ||
                selectedAcc?.type === 'manual' ||
                selectedAcc?.type === 'plaid' ||
                selectedAcc?.type === 'personal-funds')
        )
    };

    const showReconnectButton = useCallback(
        (providerName: string) => {
            return (
                selectedAcc?.type === 'aggregated' &&
                selectedAcc?.provider === providerName &&
                !selectedAcc?.connected
            );
        },
        [selectedAcc]
    );

    // Sync functionality
    const syncTransactions = () => {
        setTransactionSync(true);
        const syncTransactionApi: (
            accountId: string,
            businessId: string,
            bankAccountId: string | null,
            bankAccounts: any
        ) => Promise<any> = !isFincity ? syncPlaidTransactions : syncFinicityTransactions;
        return syncTransactionApi(
            props.appData.current_account_id,
            props.appData.current_business_id,
            selectedAcc?.type === 'aggregated' || selectedAcc?.type === 'plaid'
                ? selectedAcc?.id
                : null,
            selectedAcc?.type === 'aggregated' || selectedAcc?.type === 'plaid'
                ? (selectedAcc as IBankAccount)
                : (bankListObj?.list as IBankAccount[])
        )
            .then((res) => {
                if(res && res.enable_auto_categorization) {
                    setOpenAutoCategoryConfirmModal(true);
                }
                else {
                    setLoadingBankData(true);
                    handleReloadCurrentState();
                }
            })
            .catch((e)=>{
                setTransactionSync(false);
                showError(e?.display_message || e?.error_message || e?.message);
            })
            .finally(() => {
                setTransactionSync(false);
                triggerStatuscheck();
            });
    };

    const reconnectAccount = () => {
        setLoadingBankData(true);
        reconnectBankAccount(
            props.appData.current_account_id,
            props.appData.current_business_id,
            selectedAcc?.id || ''
        )
            .then(() => {
                return bankListObj?.reload();
            })
            .then(() => {
                getBankAccounts(excludedTransactions).then((list) => {
                    setLoadingBankData(false);
                    setBankListObj(list);
                    setAllBAnkAccounts(list ? list.list : []);
                    const selAcc = list?.list.find(
                        (acc) => acc.id === selectedAcc?.id
                    );
                    if (selAcc) {
                        setSelectedAcc(selAcc);
                    }
                });
            })
            .finally(() => setLoadingBankData(false));
    };

    const handleModalOpen = () => {
        setisEdit(false);
        setopenModal(true);
    };

    useEffect(() => {
        if (selectedAcc) {
            if (searchQuery) {
                const searchJson = rqlToJson<
                    TransactionQueryObject & {
                        search: string;
                        match_text: string;
                        page: any;
                    }
                >(
                    decodeURIComponent(searchQuery) +
                        (pageQuery ? `&page=${pageQuery}` : ''),
                    {
                        search: searchQuery,
                        match_text: '',
                        type: '',
                        from_date: filterDefaultValues.from_date,
                        to_date: filterDefaultValues.to_date,
                        amount: { value: '', operator: 'eq' },
                        period: 'all',
                        number: '',
                        page: 1,
                    },
                    ['number']
                );
                const {
                    match_text,
                    from_date,
                    to_date,
                    period,
                    amount,
                    number,
                    type,
                    page,
                } = searchJson;

                setQueryObject((prev: any) => ({
                    from_date: from_date,
                    to_date: to_date,
                    period,
                    amount,
                    type,
                    number,
                }));

                setTerm((prev) => match_text);
                getTransactionData(
                    {
                        page,
                        perPage: listData?.perPage || 100,
                        match_text,
                        ...buildQueryObject({
                            from_date,
                            to_date,
                            period,
                            amount,
                            number,
                            type,
                        }),
                    },
                    selectedAcc
                );
            } else if (pageQuery) {
                getTransactionData(
                    { page: pageQuery, perPage: listData?.perPage || 100 },
                    selectedAcc
                );
            } else {
                setTerm(() => '');
                setChipsToDisplay([]);
                getTransactionData(
                    { page: 1, perPage: listData?.perPage || 100 },
                    selectedAcc
                );
                setQueryObject(filterDefaultValues);
            }
        }
    }, [getTransactionData, searchQuery, pageQuery, selectedAcc]);

    const buildQueryObject = (query: TransactionQueryObject) => {
        let { amount, period, from_date, to_date, ...queryObj } = query as any;
        switch (query.amount.operator) {
            case 'eq':
                queryObj.amount = query.amount.value;
                break;
            case 'gt':
                queryObj.min_amount = query.amount.value;
                break;
            case 'lt':
                queryObj.max_amount = query.amount.value;
                break;
        }

        return {
            ...(period === 'all' ? (null as any) : { from_date, to_date }),
            ...queryObj,
        };
    };

    const applyAutoMassCateorization = () => {
        setOpenAutoCategoryConfirmModal(false);
        setAutoCategoryStatus(true);
        reloadAfterCategorized = true;
        applyAutoCategorization(
            currentAccountId,
            currentBusinessId as string
            ).then((res)=>{
                triggerStatuscheck();
            })
    }

    const cancelAutoCategorization = () => {
        setOpenAutoCategoryConfirmModal(false);
        setLoadingBankData(true);
        handleReloadCurrentState();
    }

    const triggerStatuscheck = () => {
        autoCategorizeTimeout = setInterval(checkAutoCategorizationProcessStatus, 10000);
    }

    const checkAutoCategorizationProcessStatus = () => {
        getAutoCategorizationProcessStatus(props.appData.current_account_id,
            props.appData.current_business_id).then((res:any)=>{
                if(res.status) {
                    setAutoCategoryStatus(true);
                    reloadAfterCategorized = true;
                }
                else {
                    clearTimeout(autoCategorizeTimeout);
                    if(reloadAfterCategorized) {
                        reloadAfterCategorized = false;
                        handleReloadCurrentState();
                    }
                    setAutoCategoryStatus(false);
                }
        })
    }
    const handleApplyFilter = (
        result: TransactionQueryObject | boolean,
        page = 1,
        searchTerm = match_text
    ) => {
        if (result && typeof result === 'object') {
            setQueryObject(result);
            const { from_date, to_date, amount, period, number, type } = result;
            const rql = jsonToRql({
                ...(period === 'all'
                    ? null
                    : { from_date: from_date, to_date: to_date }),
                match_text: searchTerm,
                period: period === 'all' ? null : period,
                amount: amount.value !== '' ? amount : null,
                number,
                type,
            });

            if (rql) {
                query.set('search', encodeURIComponent(rql));
            } else {
                query.delete('search');
            }

            query.set('page', page.toString());
            history.push({
                pathname: history.location.pathname,
                search: query.toString(),
            });
        }
        setTransactionPopupState(false);
    };

    const handleReloadCurrentState = (
        keepSelectedData?: boolean,
        perPage: any = listData?.perPage
    ) => {
        resetBankAccounts();
        getBankAccData();
        perPage = perPage ? perPage : listData?.perPage;
        if (searchQuery) {
            getTransactionData(
                {
                    page: listData?.page as number,
                    perPage: perPage,
                    match_text,
                    ...buildQueryObject(queryObject),
                },
                selectedAcc as IBankAccount,
                keepSelectedData
            );
        } else if (pageQuery) {
            getTransactionData(
                { page: pageQuery, perPage: perPage },
                selectedAcc as IBankAccount,
                keepSelectedData
            );
        } else {
            getTransactionData(
                { page: 1, perPage: perPage },
                selectedAcc as IBankAccount,
                keepSelectedData
            );
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setCheckedTransactions([]);
        handleApplyFilter(queryObject, newPage + 1);

        // IMP: Need to hande post bulk action implementation
        // setshowSnackbar({ show: false, data: {} });
        // setSelectedChekboxData((prev) => ({}));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setCheckedTransactions([]);
        setListData((prev: any) => ({
            ...prev,
            perPage: parseInt(event.target.value, 10),
        }));
        if (listData && listData.page > 1) {
            handleApplyFilter(queryObject);
        } else {
            handleReloadCurrentState(undefined, event.target.value);
        }
    };
    useEffect(() => {
        const searchJson = rqlToJson<
            TransactionQueryObject & {
                search: string;
                match_text: string;
            }
        >(
            decodeURIComponent(searchQuery || ''),
            {
                search: searchQuery || '',
                match_text: '',
                from_date: 0,
                to_date: 0,
                amount: { value: '', operator: 'eq' },
                period: 'all',
                number: '',
                type: '',
            },
            ['number']
        );
        handleChipsData(searchJson);
    }, [searchQuery]); //eslint-disable-line react-hooks/exhaustive-deps

    const handleChipsData = useCallback(
        (
            searchJson: TransactionQueryObject & {
                match_text: string;
            }
        ) => {
            setChipsToDisplay(
                Object.keys(searchJson).reduce(
                    (
                        chips: {
                            label: string;
                            searchKey:
                                | 'period'
                                | 'amount'
                                | 'match_text'
                                | 'number'
                                | 'type';
                        }[],
                        key
                    ) => {
                        switch (key) {
                            case 'period':
                                // If custom period is selected the date range else period
                                if (searchJson.period === 'custom') {
                                    chips.push({
                                        label: `Date: ${moment(
                                            searchJson.from_date * 1000
                                        ).format('DD MMM, YYYY')} - ${moment(
                                            searchJson.to_date * 1000
                                        ).format('DD MMM, YYYY')}`,
                                        searchKey: 'period',
                                    });
                                } else {
                                    searchJson.period !== 'all' &&
                                        chips.push({
                                            label: `Date: ${
                                                props.filtersConfig.periods[
                                                    searchJson.period as keyof FilterPeriods
                                                ]
                                            }`,
                                            searchKey: 'period',
                                        });
                                }
                                return chips;
                            case 'number':
                                searchJson.number !== '' &&
                                    chips.push({
                                        label: `Ref# equals: ${searchJson.number}`,
                                        searchKey: 'number',
                                    });
                                return chips;
                            case 'amount':
                                searchJson.amount.value !== '' &&
                                    chips.push({
                                        label: `Amount ${
                                            props.filtersConfig.amount
                                                .shortOperators[
                                                searchJson.amount
                                                    .operator as keyof FilterAmontOperators
                                            ]
                                        } ${currencyFormatter.format(
                                            parseFloat(searchJson.amount.value)
                                        )}`,
                                        searchKey: 'amount',
                                    });
                                return chips;
                            case 'match_text':
                                searchJson.match_text &&
                                    chips.push({
                                        label: `Search by: ${searchJson.match_text}`,
                                        searchKey: 'match_text',
                                    });
                                return chips;
                            case 'type':
                                searchJson.type &&
                                    chips.push({
                                        label: `Status: ${
                                            props.transactionFilterTypes.find(
                                                (t: any) =>
                                                    t.type === searchJson.type
                                            )?.title || ''
                                        }`,
                                        searchKey: 'type',
                                    });
                                return chips;
                        }
                        return chips;
                    },
                    []
                )
            );
        },
        [props.filtersConfig.periods] //eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleDeleteFilter = (
        searchKey: 'period' | 'amount' | 'match_text' | 'number' | 'type',
        index: number
    ) => {
        chipsToDisplay.splice(index, 1);
        setChipsToDisplay(chipsToDisplay);

        let jsonToConvert: TransactionQueryObject;
        let matchText = match_text;

        if (searchKey === 'period') {
            jsonToConvert = {
                ...queryObject,
                period: filterDefaultValues.period,
                from_date: filterDefaultValues.from_date,
                to_date: filterDefaultValues.to_date,
            };
        } else if (searchKey === 'match_text') {
            jsonToConvert = { ...queryObject };
            matchText = '';
            setTerm('');
        } else {
            jsonToConvert = {
                ...queryObject,
                [searchKey]:
                    filterDefaultValues[
                        searchKey as keyof TransactionQueryObject
                    ],
            };
        }

        handleApplyFilter(jsonToConvert, 1, matchText);
    };
    const FilterControls = (
        <Grid container>
            <Grid container>
                <PortalTabs onTabChange={excludedTabHandler}>
                    <TabPanelHolder index={0} title='Active'></TabPanelHolder>
                    {/* <TabPanelHolder index={1} title='Excluded'></TabPanelHolder> */}
                </PortalTabs>
            </Grid>
            {autoCategoryStatus && <Grid item container direction='row' alignItems='center' justify='center' xs={12} className={classes.autoCategoryStatus}>
                <UiText textAlign='center'>Auto categorization is in progress</UiText>
            </Grid>}
            <Grid xs={12} container className={classes.accountContainer}>
                <AccountSelector
                    excluded={excludedTransactions}
                    accountList={allBankAccounts}
                    onChange={(account) => {
                        if (account) {
                            if (query.get('account') !== account?.id) {
                                query.set('account', account?.id);
                                history.replace({
                                    pathname: history.location.pathname,
                                    search: query.toString(),
                                });
                                accountChangeHandler(account);
                            }
                        }
                    }}
                    disabled={false}
                    currentAccountId={accountQuery}
                    setSelectedAccount={setSelectedAcc}
                />
            </Grid>

            <Grid item container direction='row' alignItems='center' xs={12}>
                {showAddTransactionButton() ? (
                    <Button
                        className={classes.newMessageButton}
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={handleModalOpen}
                    >
                        Transaction
                    </Button>
                ) : null}
                {showSyncButton() && !selectedAcc?.disconnected ? (
                    <>
                        <Button
                            className={classes.syncButton}
                            variant='contained'
                            color='secondary'
                            disabled={transactionSync}
                            startIcon={<RefreshIcon />}
                            onClick={syncTransactions}
                        >
                            Sync
                        </Button>
                        <UiDialog
                            open={openAutoCategoryConfirmModal}
                            title = {'Auto Categorization'}
                            handleClose={cancelAutoCategorization}
                            size='sm'
                        >
                            <div className={classes.sectionWrap}>
                                <UiText className={commonClass.mb10}>Would you like to apply auto categorization on uncategorized transactions?</UiText>
                                <div className={commonClass.textCenter}>
                                            <UiButton
                                                handleClick={applyAutoMassCateorization}
                                                label='Yes'
                                                btnType='tertiary'
                                                customClass={classes.buttonGap}
                                            />
                                            <Button
                                                    variant='text'
                                                    onClick={cancelAutoCategorization}
                                                >
                                                    No
                                            </Button>
                                        </div>
                            </div>
                        </UiDialog>
                    </>
                ) : null}
                {showReconnectButton('finicity') ? (
                    <Button
                        className={classes.newMessageButton}
                        variant='contained'
                        color='secondary'
                        startIcon={<SyncRoundedIcon />}
                        onClick={reconnectAccount}
                    >
                        Reconnect
                    </Button>
                ) : null}
                {showReconnectButton('intuit') ? (
                    <Button
                        className={classes.newMessageButton}
                        variant='contained'
                        color='secondary'
                        startIcon={<SyncRoundedIcon />}
                        href='#/bank_accounts/{{bank_account.id}}/reconnect'
                    >
                        Reconnect
                    </Button>
                ) : null}
            </Grid>
            <Grid
                item
                xs={12}
                container
                justify='space-between'
                alignItems='flex-end'
            >
                <Grid xs={11} md>
                    <OutlinedInput
                        notched={false}
                        onChange={(event) => setTerm(event.target.value)}
                        value={match_text}
                        fullWidth
                        classes={{
                            root: classes.searchInput,
                        }}
                        placeholder='Search'
                        startAdornment={
                            <InputAdornment position='start'>
                                <Search />
                            </InputAdornment>
                        }
                        endAdornment={
                            match_text ? (
                                <InputAdornment
                                    position='end'
                                    onClick={() => {
                                        setTerm('');
                                    }}
                                >
                                    <Cancel
                                        color='secondary'
                                        className='cursor-pointer'
                                    />
                                </InputAdornment>
                            ) : null
                        }
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleApplyFilter(
                                    {
                                        ...queryObject,
                                    },
                                    1,
                                    match_text
                                );
                            }
                        }}
                    />
                </Grid>
                <Grid xs={1} md={3} style={{ textAlign: 'end' }}>
                    <Hidden smDown>
                        <Button
                            variant='outlined'
                            startIcon={<FilterListRoundedIcon />}
                            className={classes.filterBtn}
                            onClick={() => setTransactionPopupState(true)}
                        >
                            Filters
                        </Button>
                    </Hidden>
                    <Hidden mdUp>
                        <Button
                            variant='text'
                            startIcon={<FilterListRoundedIcon />}
                            className={classes.filterBtn}
                            onClick={() => setTransactionPopupState(true)}
                        ></Button>
                    </Hidden>
                </Grid>
                <Grid xs={12}>
                    {chipsToDisplay?.length ? (
                        <Box mt={2}>
                            {chipsToDisplay.map((chip, index) => (
                                <Chip
                                    key={index}
                                    label={chip.label}
                                    style={{
                                        marginRight: '8px',
                                        marginBottom: '8px',
                                    }}
                                    onDelete={() =>
                                        handleDeleteFilter(
                                            chip.searchKey,
                                            index
                                        )
                                    }
                                />
                            ))}
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
            <TransactionFilter
                open={transactionPopupState}
                handleClose={handleApplyFilter}
                queryObject={queryObject}
            />

            {
                // @ts-ignore: Object is possibly 'null'.
                !listData?.loading && listData?.totalItem > 0 ? (
                    <Grid item xs={12}>
                        <Hidden mdUp>
                            <Divider
                                className={classes.dividerStyle}
                                variant='fullWidth'
                            ></Divider>
                        </Hidden>
                    </Grid>
                ) : (
                    ''
                )
            }
        </Grid>
    );

    const onSelectHandle = (selected: any) => {
        setCheckedTransactions([]);
        setSelectedData(selected);
        if (isSmallDevice) {
            history.push(`/transactions/${selected.id}`);
        }
    };

    const handleClose = (
        event: React.SyntheticEvent | MouseEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarNode(null);
        setSelectedChekboxData((prev) => ({}));
    };

    const allSelectHandle = (checked: boolean) => {
        let itemId = canUseBulkActions
            ? canUseBulkActions.map((item: ITransaction) => item.id as string)
            : [];
        if (checked) {
            setCheckedTransactions(itemId);
            setSelectAll(true);
        } else {
            setCheckedTransactions([]);
            setSelectAll(false);
        }
    };

    const deleteHandle = () => {
        const postData = {
            delete: true,
            filters: { id: Object.keys(selectedChekboxData) },
        };
    };

    const openDeleteModal = (transaction: ITransaction) => {
        if (transaction) setdeleteSingleEntry(transaction);
        setshowDeleteModal(true);
    };

    const deleteHandleOption = (id?: any) => {
        if (id) {
            deleteTransactionById(
                props.appData.current_account_id,
                props.appData.current_business_id,
                id
            )
                .then(() => {
                    setshowDeleteModal(false);
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Transactions #${deleteSingleEntry?.number} deleted`,
                        })
                    );
                    handleReloadCurrentState();
                    setisEdit(false);
                    setSelectedData(null);
                    setopenModal(false);
                })
                .finally(() => {
                    setdeleteSingleEntry(null);
                    setshowDeleteModal(false);
                });
        } else {
            deleteHandle();
        }
    };

    const handleCheck = (checked: boolean, transactionId: string) => {
        if (checked) {
            setCheckedTransactions((prev) => [...prev, transactionId]);
        } else {
            let filteredProducts = checkedTransactions.filter(
                (id) => id !== transactionId
            );
            setCheckedTransactions(filteredProducts);
        }
    };

    const handleClickType = (action: string) => {
        switch (action) {
            case 'categorize':
                setOpenCategorizeModal(true);
                break;
            case 'exclude':
                setShowExcludeModal(true);
                break;
            default:
                break;
        }
    };

    const action = (
        <>
            <IconButton
                aria-label='close'
                color='inherit'
                className={classes.close}
                onClick={() => {
                    setCheckedTransactions([]);
                }}
            >
                <CloseIcon />
            </IconButton>
            <Hidden mdUp>
                <SnackbarActions
                    handleClickType={handleClickType}
                    isManualAccount={selectedAcc?.type === 'manual'}
                />
            </Hidden>
            <Hidden smDown>
                <div style={{ display: 'flex' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<LabelOutlinedIcon />}
                        style={{ marginRight: '20px' }}
                        onClick={() => {
                            setOpenCategorizeModal(true);
                        }}
                    >
                        Categorize
                    </Button>
                    {selectedAcc?.type !== 'manual' && (
                        <Button
                            classes={{ root: classes.buttonStyle }}
                            startIcon={<img src={Exclude} alt='excludeicon' />}
                            onClick={() => {
                                setShowExcludeModal(true);
                            }}
                        >
                            Exclude
                        </Button>
                    )}
                </div>
            </Hidden>
        </>
    );

    const openEditModal = (data: ITransaction) => {
        const selAcc = allBankAccounts
            .reverse()
            .find((acc) => acc.account === data.account);
        setSelectedAcc(selAcc ? selAcc : null);
        setisEdit(true);
        setopenModal(true);
        setSelectedData(data);
    };

    const handleExcludeTransaction = () => {
        let params = {
            excluded: true,
            filters: { id: checkedTransactions },
        };
        setExcludeConfirmed(true);
        categorizeTransactions(
            props.appData.current_account_id,
            props.appData.current_business_id,
            params
        )
            .then((res: any) => {
                if (res) {
                    handleReloadCurrentState();
                    setExcludeConfirmed(false);
                    setShowExcludeModal(false);
                    setCheckedTransactions([]);
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `${checkedTransactions.length} transactions excluded`,
                        })
                    );
                }
            })
            .catch((err: any) => {
                handleReloadCurrentState();
                setExcludeConfirmed(false);
                setShowExcludeModal(false);
                setCheckedTransactions([]);
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: `Something went wrong`,
                    })
                );
            });
    };

    const handleSingleExclude = () => {
        if (excludeSingleEntry) {
            updateTransaction(
                props.appData.current_account_id,
                props.appData.current_business_id,
                excludeSingleEntry.id as string,
                {
                    excluded: true,
                }
            )
                .then(() => {
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Transactions #${excludeSingleEntry.number} excluded`,
                        })
                    );
                    handleReloadCurrentState();
                })
                .finally(() => {
                    setExcludeSingleEntry(null);
                });
        }
    };
    useLayoutEffect(() => {
        let localRefState = { ...refState };
        setRefState((refState: any) => localRefState);
        function updateSize() {
            let localRefState = { ...refState };
            setRefState((refState: any) => localRefState);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const emptyState = (
        <Grid
            container
            direction='column'
            justify='flex-start'
            alignItems='center'
            style={{ textAlign: 'center' }}
        >
            <img
                src={EmptyState}
                className={classes.marginTop16}
                alt='EmptyState'
            ></img>
            <Typography className={classes.marginTop24} variant='h5'>
                Automatically sync your transactions
            </Typography>
            <Typography className={classes.emptyText} variant='body1'>
                By securely connecting your business bank accounts you can save
                time and automatically sync your transactions.
            </Typography>
            <Button
                onClick={() => history.push('/bank_accounts/new')}
                className={classes.marginTop24}
                variant='contained'
                color='primary'
                startIcon={<AddIcon />}
            >
                Connect my Bank
            </Button>
            <Typography
                className={classes.marginTop16}
                variant='body2'
                color='textSecondary'
            >
                Accounts are securely connected through {connectionType}.
            </Typography>
            <Typography className={classes.marginTop16} variant='body2'>
                or
            </Typography>
            <Typography className={classes.marginTop16} variant='h6'>
                Manually add Transactions
            </Typography>
            <LinkButton
                onClick={() => history.push('/bank_accounts/new')}
                className={classes.marginTop24}
            >
                Create a Manual Account
            </LinkButton>

            <Typography
                className={classes.emptyText}
                variant='body2'
                color='textSecondary'
            >
                Manual accounts do not connect directly to the bank and will
                require manual entry of all transactions.
            </Typography>
        </Grid>
    );

    return (
        <>
            {loadingBankData ? (
                <Loader />
            ) : (
                <>
                    <Hidden implementation='js' mdUp>
                        <Box className={classes.mobileHeader}>
                            <Typography variant='h6'>Transactions</Typography>
                        </Box>
                    </Hidden>
                    
                    

                    {bankListObj?.aggregatedCount ||
                    bankListObj?.connectedCount ||
                    bankListObj?.manualCount ? (
                        <Grid
                            container
                            direction='row'
                            className={classes.sectionContainer}
                        >
                            <UiConfirmationDialog
                                open={showDeleteModal}
                                message={
                                    <>
                                        <Typography
                                            variant='body1'
                                            className={classes.deleteModal}
                                            gutterBottom
                                        >
                                            <div>
                                                Delete Transaction
                                                {` #${deleteSingleEntry?.number}`}
                                            </div>
                                            <div>
                                                Do you want to delete the
                                                selected Transaction?
                                            </div>
                                            <div>This cannot be undone.</div>
                                        </Typography>
                                    </>
                                }
                                handleClose={() => {
                                    setshowDeleteModal(false);
                                }}
                                confirmNode={
                                    <Button
                                        className={classes.deleteEntry}
                                        variant='contained'
                                        // disabled={deleteLoading}
                                        startIcon={
                                            <img
                                                alt={`Private`}
                                                src={WhiteDeleteIcon}
                                            />
                                        }
                                        onClick={() => {
                                            deleteHandleOption(
                                                deleteSingleEntry?.id
                                            );
                                        }}
                                        color='secondary'
                                    >
                                        Delete
                                    </Button>
                                }
                                cancelButtonText='Cancel'
                            />
                            <NewTransactionModal
                                open={openModal}
                                handleClose={() => handleModalCLose()}
                                isEdit={isEdit}
                                deleteData={{
                                    showDeleteModal,
                                    deleteHandleOption: openDeleteModal,
                                }}
                                selectedData={selectedData}
                                accountList={allBankAccounts}
                                selectedAccount={selectedAcc}
                            />
                            <Grid
                                container
                                item
                                className={`${classes.content} ${classes.sectionPanels}`}
                            >
                                {/* exclude transaction confirmmodal */}
                                <UiConfirmationDialog
                                    open={
                                        showExcludeModal || !!excludeSingleEntry
                                    }
                                    message={
                                        <>
                                            <Typography
                                                variant='body1'
                                                className={classes.deleteModal}
                                                gutterBottom
                                            >
                                                <div>
                                                    {showExcludeModal
                                                        ? `Exclude ${checkedTransactions.length} Transactions ?`
                                                        : null}
                                                    {!!excludeSingleEntry
                                                        ? `Exclude transaction #${excludeSingleEntry.number} ?`
                                                        : null}
                                                </div>
                                                <div>
                                                    Excluded transactions do not
                                                    show up on the dashboard or
                                                    in any generated reports.
                                                </div>
                                                <div>
                                                    You can change this at any
                                                    time through the “Excluded”
                                                    tab in Transactions.
                                                </div>
                                            </Typography>
                                        </>
                                    }
                                    handleClose={() => {
                                        setShowExcludeModal(false);
                                        setExcludeSingleEntry(null);
                                    }}
                                    showLoader={excludeConfirmed}
                                    confirmNode={
                                        <Button
                                            className={classes.deleteEntry}
                                            variant='contained'
                                            startIcon={
                                                <img
                                                    src={Exclude}
                                                    alt='excludeicon'
                                                />
                                            }
                                            onClick={() => {
                                                excludeSingleEntry
                                                    ? handleSingleExclude()
                                                    : handleExcludeTransaction();
                                            }}
                                            color='secondary'
                                        >
                                            Exclude
                                        </Button>
                                    }
                                    cancelButtonText='Cancel'
                                />
                                {checkedTransactions.length > 1 && (
                                    <div
                                        className={
                                            classes.snackbar
                                        }
                                    >
                                        <SnackbarContent
                                            classes={{
                                                root: classes.snackbarContent,
                                            }}
                                            message={
                                                isSmallDevice
                                                    ? `${checkedTransactions.length}`
                                                    : `${checkedTransactions.length} Selected`
                                            }
                                            action={action}
                                        />
                                    </div>
                                )}
                                <TransactionCategorizeModal
                                    open={openCategorizeModal}
                                    handleClose={() =>
                                        setOpenCategorizeModal(false)
                                    }
                                    loadParentState={() => {}}
                                    checkedCount={checkedTransactions.length}
                                    checkedItems={checkedTransactions}
                                    setCheckedTransactions={
                                        setCheckedTransactions
                                    }
                                    handleReloadCurrentState={
                                        handleReloadCurrentState
                                    }
                                />
                                <NewTransactionModal
                                    open={openModal}
                                    handleClose={() => handleModalCLose()}
                                    isEdit={isEdit}
                                    deleteData={{
                                        showDeleteModal,
                                        deleteHandleOption: openDeleteModal,
                                    }}
                                    selectedData={selectedData}
                                    accountList={allBankAccounts}
                                    selectedAccount={selectedAcc}
                                    refreshTransactions={
                                        handleReloadCurrentState
                                    }
                                />
                                {settingsModalOpen &&
                                <SettingsModal
                                    open = {settingsModalOpen}
                                    triggerAutoCategorization={applyAutoMassCateorization}
                                    handleClose={handleSettingsModalClose}
                                /> }
                                {smartCatSettingsModalOpen===2? 
                                
                                (<>
                                    {smartCatModalLoading? <Loader/>: (
                                
                                    <div className={classes.smartCatSmallBox}>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Chip
                                                    className={classes.featureChip}
                                                    label={<UiText weight="semi_bold_600">New Feature</UiText>}
                                                    variant='default'
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Grid container
                                                        spacing={1}
                                                        direction='row'
                                                        alignItems='center'
                                                        justify='flex-end'>
                                                    <IconButton
                                                        aria-label='close'
                                                        color='inherit'
                                                        onClick={()=>{
                                                                handleSmartCatModalClose(3)
                                                            }
                                                        }
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    
                                        <div className={commonClass.mt8}>
                                            <UiText variant='car_100' weight='semi_bold_600'>Smart-Categorize</UiText>
                                            <UiText className={commonClass.mt8}>
                                                Save time by allowing the 1-800Accountant portal to automatically categorize transactions.
                                            </UiText>
                                        </div>
                                        <div className={commonClass.mt16}>
                                            <UiButton
                                                handleClick={turnOnSmartCategorization}
                                                label='Turn on Smart-Categorize'
                                                btnType='tertiary'
                                                customClass={classes.buttonGap}
                                            />                            
                                            <Button
                                                    className={classes.seconderyActionButtonOutline}
                                                    variant='outlined'
                                                    onClick={()=>{setSettingsModalOpen(true)}}
                                                >
                                                    Settings
                                            </Button>
                                        </div>
                                    </div>
                                    )}
                                </>)
                                : ''}
                                <Grid
                                    container
                                    item
                                    alignContent='flex-start'
                                    className={classes.listRoot}
                                    wrap='nowrap'
                                >
                                    <div ref={(ref: any) => setRefState(ref)}>
                                        {checkedTransactions.length <= 1 &&
                                            FilterControls}

                                            {isAdminLogin && checkedTransactions.length >=
                                                2 && (
                                                <Grid
                                                    container
                                                    direction='column'
                                                    justify='flex-start'
                                                    alignItems='flex-start'
                                                    style={{
                                                        padding: '10px',
                                                        marginLeft: '10px',
                                                        borderBottom:`1px solid ${colorTheme.grey200}`,
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    allSelectHandle(
                                                                        e.target
                                                                            .checked
                                                                    );
                                                                }}
                                                                name='select-all'
                                                                size='small'
                                                                checked={
                                                                    selectAll
                                                                }
                                                            />
                                                        }
                                                        label='Select All'
                                                    />
                                                </Grid>
                                            )}
                                        <Grid container direction='row' alignItems='baseline' justify='space-between'>
                                            <Grid
                                                alignItems='center'
                                                style={{
                                                    minHeight: '52px',
                                                    display: 'flex',
                                                    marginRight: '8px',
                                                }}
                                                item
                                            >
                                                <FormControl component='div'>
                                                    <Select
                                                        displayEmpty
                                                        value={queryObject.type}
                                                        defaultValue=''
                                                        onChange={(e) => {
                                                            setQueryObject(
                                                                (
                                                                    prev: any
                                                                ) => ({
                                                                    ...prev,
                                                                    type: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            );
                                                            handleApplyFilter(
                                                                {
                                                                    ...queryObject,
                                                                    type: e
                                                                        .target
                                                                        .value as string,
                                                                },
                                                                1,
                                                                match_text
                                                            );
                                                        }}
                                                    >
                                                        {props.transactionFilterTypes.map(
                                                            (
                                                                option: any,
                                                                index: number
                                                            ) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={
                                                                        option.type
                                                                    }
                                                                >
                                                                    {
                                                                        option.title
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {listData && listData.itemsCount ? (
                                                <TablePagination
                                                    component='div'
                                                    labelRowsPerPage='Show:'
                                                    count={
                                                        listData?.itemsCount as number
                                                    }
                                                    classes={{
                                                        toolbar:
                                                            classes.pageToolbar,
                                                    }}
                                                    page={
                                                        (listData?.page -
                                                            1) as number
                                                    }
                                                    className={
                                                        classes.pagination
                                                    }
                                                    onChangePage={
                                                        handleChangePage
                                                    }
                                                    rowsPerPage={
                                                        listData?.perPage as number
                                                    }
                                                    onChangeRowsPerPage={
                                                        handleChangeRowsPerPage
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>{' '}   
                                    </div>
                                    {(!listData || listData?.loading || props.category?.loading) ? (
                                        <Grid
                                            container
                                            direction='column'
                                            justify='center'
                                            alignItems='center'
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <Loader />
                                        </Grid>
                                    ) : (
                                        <Grid
                                            container
                                            direction='column'
                                            justify='center'
                                            alignItems='center'
                                            style={{
                                               overflow: 'auto'
                                            }}
                                        >

                                            <TransactionsList
                                                items={
                                                    listData?.items as ITransaction[]
                                                }
                                                personalAccountId={
                                                    props.appData
                                                        .personal_account.id
                                                }
                                                onSelect={onSelectHandle}
                                                selectedData={selectedData}
                                                selectedChekboxData={
                                                    selectedChekboxData
                                                }
                                                handleCheck={handleCheck}
                                                checkedTransactions={
                                                    checkedTransactions
                                                }
                                                raw={listData}
                                                selectedBank={selectedAcc}
                                                handleModalOpen={
                                                    handleModalOpen
                                                }
                                                refState={refState}
                                                isAdminLogin={isAdminLogin}
                                                categories={props.category?.categories}
                                            />
                                    
                                               
                                            <UiDialog
                                                    open={smartCatSettingsModalOpen===1}
                                                    title = {''}
                                                    handleClose={()=>{handleSmartCatModalClose(2)}}
                                                    size='sm'
                                                    customRootClass={classes.smartCatSettingModal}
                                                >
                                                    {smartCatModalLoading? <Loader/>: 
                                                    <>  
                                                        <div className={classes.sectionWrap}>
                                                            <Chip
                                                                className={classes.featureChip}
                                                                label={'New Feature'}
                                                                variant='default'
                                                            />
                                                        </div>
                                                        <div className={classes.sectionWrap}>
                                                            <UiText variant='truck_175' weight='bold_700'>Smart-Categorize</UiText>
                                                            <UiText className={classes.marginTop24}>
                                                            Save time by allowing the 1-800Accountant portal to automatically categorize transactions.
                                                            </UiText>
                                                        </div>

                                                        
                                                        <div className={classes.smartCategoryButtonWrapper}>
                                                            <UiButton
                                                                handleClick={turnOnSmartCategorization}
                                                                label='Turn on Smart-Categorize'
                                                                btnType='tertiary'
                                                            />
                                                        </div>
                                                        <div className={classes.rejectBtn}>
                                                            <LinkButton
                                                                    variant='text'
                                                                    onClick={()=>{handleSmartCatModalClose(2)}}
                                                                >
                                                                    Ask Me Later
                                                            </LinkButton>
                                                        </div>
                                                        <div className={classes.smartDesc1}>
                                                            <UiText textColor='textSecondary' variant='motorcycle_90'>
                                                                Transactions will automatically be categorized when a suggested category has a high likelihood of accuracy. When a transaction would be categorized by a custom rule, that will take priority over Smart-Categorize.
                                                            </UiText>
                                                        </div>
                                                    </>
                                                }
                                            
                                            </UiDialog>                                            
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Hidden smDown implementation='js'>
                                <Grid
                                    item
                                    container
                                    direction='row'
                                    justify='center'
                                    alignItems='stretch'
                                    className={classes.sectionDivider}
                                >
                                    <Divider orientation='vertical' flexItem />
                                </Grid>
                                <Grid item className={classes.sectionPanels}>
                                    {selectedData ? (
                                        <TransactionThread
                                            currentData={selectedData}
                                            openEditModal={openEditModal}
                                            setSelectedAccount={setSelectedAcc}
                                            accountList={allBankAccounts}
                                            triggerAutoCategorization={applyAutoMassCateorization}
                                            handleReloadCurrentState={
                                                handleReloadCurrentState
                                            }
                                            setSelectedData={setSelectedData}
                                        />
                                    ) : (
                                        <Grid
                                            container
                                            direction='column'
                                            justify='center'
                                            alignItems='center'
                                            style={{ height: '100%' }}
                                        >
                                            <UiText variant='motorcycle_90'>
                                                Select a Transaction to see the
                                                thread
                                            </UiText>
                                        </Grid>
                                    )}
                                </Grid>
                            </Hidden>
                        </Grid>
                    ) : (
                        emptyState
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    filtersConfig: state.config.local.filters,
    transactionFilterTypes: state.config.apiConfig.transaction.filter_types,
    category: state.category,
});
export default connect(mapStateToProps, { loadPostAuthData })(Transactions);
