import moment from 'moment'
import { importSectionMode } from '../constants/contractor.const'
import { Contractor } from '../models/contractor.model'

export const handleImportMode = (step: number, currentStep: number) => {
    if (step === currentStep + 1) {
        return importSectionMode.ACTIVE
    } else if (currentStep + 1 > step) {
        return importSectionMode.COMPLETED
    }
    return importSectionMode.INACTIVE
}

export const findErrosForEmptyFiled = (contractor: any) => {
    const errors: any = { email: true, phone:true, contractor_w_nine:[{year:true}] }
    Object.keys(contractor).forEach((item) => {
        if (!contractor[`${item}`]) {
            errors[item] = true
        }
    })
    return errors
}

type taxYear = {
    year: string
    disabled?: boolean
}

export const makeTaxYearDropdown = (): Array<taxYear> => {
    const startYear = 2022
    const prevYear = new Date().getFullYear() - 1;
    const years = []
    for (let i = startYear; i <= prevYear; i++) {
        years.push({ year: `${i}`, disabled: false })
    }
    return years
}

/*
    From 1 February 2023 - 31 January 2024
    {{YYYY_1}} = 2023
    {{YYYY_2}} = 2024
*/
export const showYears = (): number => {
    const startDate = moment(`${new Date().getFullYear()}-02-01`)
    const endDate = moment(`${new Date().getFullYear() + 1}-01-31`)
    const currentDate = moment()

    if (currentDate.isBetween(startDate, endDate, undefined, '[]')) {
        return Number(endDate.format('yyyy'))
    }
    return Number(startDate.format('yyyy'))
}

export const filterFiledAndNotFiledContractor = (contractor: Contractor[]) => {
    const firstIndex = 0
    const filed: Contractor[] = []
    const notFiled: Contractor[] = []
    contractor?.forEach((contractor: Contractor) => {
      if (
            contractor.contractor_w_nine[firstIndex].status === 'filed'
        ) {
            filed.push(contractor)
        } else if (
            (contractor.is_complete) || (!contractor.is_complete && contractor.contractor_w_nine[firstIndex].status === 'waiting_w_nine')
        ) {
            notFiled.push(contractor)
        }
    })
    return {
        filed,
        notFiled,
    }
}
