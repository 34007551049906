export interface Vehicles {
    page:       number;
    perPage:    number;
    pageCount:  number;
    itemsCount: number;
    items:      Item[];
}

export type OdometerReading = {
    [key: string]: number;
};
export interface Item {
    id:                string;
    make:              string;
    model:             string;
    year:              number;
    status:            string;
    is_default:        boolean;
    odometer_readings: OdometerReading
    vin?:              string;
    license_plate_number?: string;
    description?: string;
}

export interface FormData {
    activeVehicle: boolean
    defaultVehicle: boolean
    make: string
    model: string
    year: string
    licensePlate: string
    vin: string
    description: string
    [key: string]: any,
    mileageYears? : { [key: string]: number }
}

export interface VehicleDetailsAPI {
    make: string
    model: string
    year: string
    license_plate_number: string
    vin: string
    status: string
    is_default: boolean
    description: string
    odometer_readings?: { [key: string]: number }
}
export enum VEHICLE_STATUSES {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}
export const MILEAGE_YEAR_KEY = 'mileageYear';
export enum UNSAVED_MODAL_ACTIONS_TYPES  {
    KEEP = 'keep',
    DISCARD = 'discard'
}
export type UNSAVED_MODAL_ACTIONS = UNSAVED_MODAL_ACTIONS_TYPES
export const FORM_ERRORS = {
    YEAR : {
        VALID : 'Please enter a valid year.',
        REQUIRED: 'Year is required.',
        MIN: 'Year must be greater than or equal to 1930.',
        MAX: 'Year must be less than or equal to the current year.'
    },
    VIN: {
        VALID: 'VIN length must be 11 or 17 characters long.'
    },
    MAKE: {
        REQUIRED: 'Make is required.'
    },
    MODEL: {
        REQUIRED: 'Model is required.'
    },
    ODOMETER_READING: {
        POSITIVE: 'Odometer Readings should be greater than zero.',
        WHOLE_NUMBER: 'Odometer Readings should be a valid number.'
    }
}
export const VIN_REGEX = /^(.{11}|.{17})$/
export enum ADD_EDIT_DELETE_SUCCESS_MSGS{
    ADD = 'New Vehicle Added!',
    EDIT = 'Vehicle Details Updated!',
    DELETE = 'Vehicle Deleted'
}
export const TOTAL_VEHICLES_PER_PAGE = '10'