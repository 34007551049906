import { useContext, useEffect } from 'react';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';

export default function PartnerProducts() {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    const partnerProductsUrl = 'https://1800accountant.com/partners-marketplace-iframe';

    useEffect(() => {
        setActiveRouteHeading('Partner Products');
    });

    const iframe = () => {
        return {
            __html: `<iframe src="${partnerProductsUrl}" width="100%" height="100%"></iframe>`,
        };
    };
    return (
        <div
            style={{ height: '100%', overflow: 'hidden' }}
            dangerouslySetInnerHTML={iframe() as any}
        ></div>
    );
}
