import { Divider, makeStyles } from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'
import UiText from '../../../common/ui/UiText'

const useStyles = makeStyles(() => {
    return {
        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0.5rem 0 0.5rem 0',
        },
    }
})

const SectionMobileHeader = ({
    menuHeader,
    viewMenu,
}: {
    menuHeader: string
    viewMenu: () => void
}) => {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.content}>
                <UiText variant='hatchback_125' weight='medium_500'>{menuHeader}</UiText>
                <UiButton btnType='hyperlink' label="Menu" handleClick={()=>{
                    viewMenu()
                }}/>
            </div>
            <Divider />
        </div>
    )
}

export default SectionMobileHeader
