import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme } from '@material-ui/core'
import Invoices from '../bookkeeping/invoices/Invoices'
import InvoicePreview from '../bookkeeping/invoices/InvoicePreview'
import InvoiceProvider from '../bookkeeping/invoices/InvoiceProvider'
import { ProductServicesProvider } from '../bookkeeping/products-services/ProductServicesProvider'
export default function InvoiceRoutes() {
    const classes = makeStyles((theme: Theme) => {
        return {
            container: {
                height: '100%',
            },
        }
    })()

    return (
        <ProductServicesProvider>
            <InvoiceProvider>
                <Switch>
                    <Fragment>
                        <Hidden mdUp>
                            <Route path="/invoices" exact>
                                <Invoices />
                            </Route>
                            <Route path={`/invoices/:id`} exact>
                                <Container className={classes.container} maxWidth="lg">
                                    <InvoicePreview />
                                </Container>
                            </Route>
                        </Hidden>
                        <Hidden smDown>
                            <Route path="/invoices">
                                <Container className={classes.container} maxWidth="lg">
                                    <Invoices />
                                </Container>
                            </Route>
                        </Hidden>
                    </Fragment>
                </Switch>
            </InvoiceProvider>
        </ProductServicesProvider>
    )
}