import {
    Button,
    List,
    ListItem,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationStore } from '../../../../models';
import UiSection from '../../../common/ui/UiSection';
import SubmitAnnualReportPopup from './SubmitAnnualReportPopup';
import moment from 'moment';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';

interface RequiredStepsProps {
    annualReportDescription: string;
    annualReportDueDate: number;
    formationState: string;
}

function RequiredSteps({
    annualReportDescription,
    annualReportDueDate,
    formationState,
}: RequiredStepsProps) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        listHeading: {
            fontWeight: 500,
        },
        listItem: {
            paddingBottom: 10,
            paddingTop: 10,
            '&:first-child': {
                paddingTop: 0,
            },
            '&:last-child': {
                paddingBottom: 0,
            },
        },
        primaryColor: {
            color: colorTheme.primaryGreen,
        },
        orangeColor: {
            color: colorTheme.orange300,
        },
        redColor: {
            color: 'red',
        },
        iconMargin: {
            marginRight: 5,
        },
    })();
    const [openAnnualReportPopup, setOpenAnnualReportPopup] = useState(false);
    const handleSubmitAnnualPopupClose = (result: any) => {
        setOpenAnnualReportPopup(false);
    };
    const annualReportDate: any = annualReportDueDate && {
        date: moment(annualReportDueDate * 1000).format('MMM DD, YYYY'),
        diff: moment(new Date(annualReportDueDate * 1000)).diff(
            moment(),
            'days'
        ),
    };
    const statesNotAllowedForAnnualReport = ['AZ', 'MO', 'NM', 'SC', 'OH'];

    return (
        <UiSection expanded={true} sectionHeading='Required Steps'>
            <List disablePadding>
                {/* <ListItem disableGutters className={styles.listItem} divider>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Typography variant="subtitle1" component="div" className={styles.listHeading} gutterBottom>
                                Setup a Registered Agent
                                <Typography color="primary" className={`d-flex align-items-center ${styles.listHeading}`}>
                                    <CalendarToday fontSize="small" className={styles.iconMargin} /> Due Today
                                </Typography>
                            </Typography>
                            <Button variant="outlined" size="small">Resolve</Button>
                        </div>
                        <div>
                            A registered agent can be appointed to accept service of process and official mail on your business' behalf. This is a requirement in the State of California.
                        </div>
                        <Button variant="text">Learn More</Button>
                    </div>
                </ListItem>
                <ListItem disableGutters className={styles.listItem} divider>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Typography variant="subtitle1" component="div" className={styles.listHeading} gutterBottom>
                                Submit Initial Report
                                <Typography color="primary" component="div" className={`d-flex align-items-center ${styles.listHeading}`}>
                                    <CalendarToday fontSize="small" className={styles.iconMargin} /> Due Dec 12, 2020
                                    <Typography color="textSecondary">(in 9 days)</Typography>
                                </Typography>
                            </Typography>
                            <Button variant="outlined" size="small">Resolve</Button>
                        </div>
                        <div>
                            California has an initial report that is due within 90 days of the entity being formed. Failure to file can result in revocation of your entity status.
                        </div>
                    </div>
                </ListItem> */}
                {statesNotAllowedForAnnualReport.includes(formationState) ? (
                    <ListItem disableGutters className={styles.listItem}>
                        <Typography
                            variant='body1'
                            align='center'
                            style={{ width: '100%' }}
                        >
                            No Required Steps
                        </Typography>
                    </ListItem>
                ) : (
                    <ListItem disableGutters className={styles.listItem}>
                        <div className='w-100'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <Typography
                                        variant='subtitle1'
                                        component='div'
                                        className={styles.listHeading}
                                        gutterBottom
                                    >
                                        Submit Annual Report
                                        {/* <Typography color="textSecondary" className={`d-flex align-items-center ${styles.listHeading}`}>
                                                <Info fontSize="small" className={styles.iconMargin} /> Pending State Approval
                                            </Typography> */}
                                        {annualReportDueDate && (
                                            <div className='d-flex align-items-center'>
                                                <Typography
                                                    component='span'
                                                    className={`d-flex align-items-center ${styles.listHeading}`}
                                                    classes={{
                                                        root:
                                                            annualReportDate.diff ===
                                                            0
                                                                ? styles.orangeColor
                                                                : annualReportDate.diff >
                                                                  0
                                                                ? styles.primaryColor
                                                                : styles.redColor,
                                                    }}
                                                >
                                                    <CalendarToday
                                                        fontSize='small'
                                                        className={
                                                            styles.iconMargin
                                                        }
                                                    />{' '}
                                                    Due{' '}
                                                    {annualReportDate.diff === 0
                                                        ? 'Today'
                                                        : annualReportDate.date}
                                                </Typography>
                                                &nbsp;
                                                <Typography color='textSecondary'>
                                                    {annualReportDate.diff < 0
                                                        ? '(Overdue)'
                                                        : annualReportDate.diff >
                                                          0
                                                        ? `(in ${
                                                              annualReportDate.diff
                                                          } day${
                                                              annualReportDate.diff !==
                                                              1
                                                                  ? 's'
                                                                  : ''
                                                          })`
                                                        : ''}
                                                </Typography>
                                            </div>
                                        )}
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='textSecondary'
                                        style={{ paddingRight: 10 }}
                                    >
                                        {annualReportDescription}
                                    </Typography>
                                </div>
                                <div>
                                    <Button
                                        disabled={!annualReportDueDate}
                                        variant='outlined'
                                        color='secondary'
                                        onClick={() =>
                                            setOpenAnnualReportPopup(true)
                                        }
                                    >
                                        {annualReportDueDate
                                            ? 'Resolve'
                                            : 'Pending Formation'}
                                    </Button>
                                </div>
                            </div>

                            {/* <Button variant="text">Learn More</Button> */}
                        </div>
                    </ListItem>
                )}
            </List>
            <SubmitAnnualReportPopup
                open={openAnnualReportPopup}
                handleClose={(result: any) =>
                    handleSubmitAnnualPopupClose(result)
                }
            />
        </UiSection>
    );
}

const mapStateToProps = ({ entityManagement }: ApplicationStore) => ({
    annualReportDescription:
        entityManagement.entityTab &&
        entityManagement.entityTab.annualReportDescription,
    annualReportDueDate:
        entityManagement.entityTab &&
        entityManagement.entityTab.annualReportDueDate,
    formationState: entityManagement.banner.formationState,
});

export default connect(mapStateToProps)(RequiredSteps);
