import Lottie from 'react-lottie'
import { ListItemIcon, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import IconList from '../../../common/IconList'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { toRem16 } from '../../../../styles/commonStyles'
import { usePayrollMigrationState } from '../../Providers/PayrollMigrationProvider'
import { PAGE_TYPE } from './constants/payroll-migration.const'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    tosFlowHeaderSection: {
        marginTop: '1.5rem',
        color: (colorTheme) => colorTheme.black100,
        textAlign: 'center',
        '& div': {
            margin: '1rem 0 0 0',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
    currentBusiness: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        marginBottom: '1.5rem',
    },
    avatarStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        height: toRem16(40),
        width: toRem16(40),
        backgroundColor: (themeColors) => themeColors.teal200,
    },
}))

type TOSFlowHeaderProp = {
    icon: any
    headerText: string
    children: React.ReactNode
}

const TOSFlowHeader = ({ icon, headerText, children }: TOSFlowHeaderProp) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const { currentBusiness } = useCurrentStore()
    const { page } = usePayrollMigrationState()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    let styles = {};
    if (smDevice) {
        styles = { textAlign: (page === PAGE_TYPE.VERIFICATION_PAGE || page === PAGE_TYPE.SUCCESS_PAGE)  ? 'center' : 'left' };
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: icon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }
    return (
        <>
            {page === PAGE_TYPE.INITIAL_PAGE && (
                <div className={classes.currentBusiness}>
                    <ListItemIcon>
                        <div className={classes.avatarStyle}>
                            {IconList['purpose']}
                        </div>
                    </ListItemIcon>
                    <UiText variant='hatchback_125' weight='semi_bold_600'>
                        {currentBusiness?.name}
                    </UiText>
                </div>
            )}
            <div>
                <Lottie options={defaultOptions} height={80} width={80} />
            </div>
            <div className={classes.tosFlowHeaderSection} style={{ ...styles }} >
                <UiText variant="suv_150" weight={page === PAGE_TYPE.VERIFICATION_PAGE ? "semi_bold_600": "bold_700"}>
                    {headerText}
                </UiText>
                <div>{children}</div>
            </div>
        </>
    )
}

export default TOSFlowHeader
