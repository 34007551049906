import { useEffect, useState } from 'react';
import { Button, Theme, useMediaQuery } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { ApplicationStore, Config } from '../../../../models'
import UiDialog from '../../../common/ui/UiDialog'
import UiFormField from '../../../common/ui/UiFormField'
import * as Yup from "yup";
import { makeStyles } from '@material-ui/styles'
import { addAppointment, getSlotsByTopic } from '../../../../services/apiService'
import Loader from '../../../common/Loader'
import UiErrorInfoDialog from '../../../common/ui/UiErrorInfoDialog'
import { dayStartTimestamp, _timestamp } from '../../../../utils/dateUtil'
import moment from 'moment'
import { showAlert } from '../../../../store/actions/feedback'
import { COLORS } from '../../../../variables/colors'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

interface ScheduleTaxSavingsAnalysisPopupProps {
    open: boolean;
    handleClose: (result?: any) => void;
    config: Config,
    accountId: string;
    businessId: string;
}

function ScheduleTaxSavingsAnalysisPopup({ config, open, handleClose, accountId, businessId }: ScheduleTaxSavingsAnalysisPopupProps) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        marginVertical: {
            marginTop: 20,
            marginBottom: 20
        },
        marginBottom: {
            marginBottom: 20
        },
        reviewOrderButton: {
            '&:not(:disabled)': {
                backgroundColor: colorTheme.orange300,
                color: colorTheme.primaryWhite,
            }
        }
    })();

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({
        topic: 'Tax Savings Analysis',
        appointmentTopic: 'Free Tax Savings Analysis',
        date: null,
        start_time: null,
        description: ''
    });
    const validationSchema = Yup.object({
        topic: Yup.string().required('Topic is required'),
        appointmentTopic: Yup.string().required('Topic is required'),
        date: Yup.number().required('Date and time is required'),
        start_time: Yup.number().required('Date and time is requird'),
        description: Yup.string()
    });

    const [showError, setShowError] = useState(false);
    const [allMappedTimeSlots, setAllMappedTimeSlots] = useState<{ date: number; timeSlots: { time: string; timestamp: number }[]; }[]>([]);
    const [allEnabledSlots, setAllEnabledSlots] = useState<number[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const smDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        getSlotsByTopic<{ schedling_warning: any, slots: { "Tax Savings Analysis": number[] } }>('Tax Savings Analysis')
            .then(res => {
                const slots = res.slots['Tax Savings Analysis'];
                if (!slots.length) {
                    setShowError(true);
                } else {
                    const mappedTimeSlots: { date: number; timeSlots: { time: string; timestamp: number }[]; }[] = [];
                    const allEnabledSlotsTmp = slots.reduce((result: number[], current) => {
                        const dayStart: any = dayStartTimestamp(current * 1000);
                        const foundIndex = result.indexOf(dayStart);
                        if (foundIndex === -1) {
                            result.push(dayStart);
                            mappedTimeSlots.push({
                                date: dayStart,
                                timeSlots: [{
                                    time: moment(current * 1000).format('hh:mm A'),
                                    timestamp: current
                                }]
                            });
                        } else {
                            mappedTimeSlots[foundIndex].timeSlots.push({
                                time: moment(current * 1000).format('hh:mm A'),
                                timestamp: current
                            });
                        }
                        return result;
                    }, []);


                    setAllEnabledSlots(allEnabledSlotsTmp);
                    setAllMappedTimeSlots(mappedTimeSlots);
                    setInitialValues((initialValues: any) => ({
                        ...initialValues,
                        date: allEnabledSlotsTmp[0],
                        start_time: mappedTimeSlots[0].timeSlots[0].timestamp
                    }));
                    setLoadingData(false);
                }
            }).catch(err => { })
    }, []);

    const shouldDisableDateFunction = (datePickerDate: any) => {
        let date = new Date(datePickerDate);
        return !allEnabledSlots.includes(_timestamp(date));
    };

    const onSubmit = (data: any) => {
        setFormSubmitted(true);
        addAppointment(data).then((res: any) => {
            showAlert({ alertText: 'Appointment Scheduled', alertType: 'success' });
            handleClose(false);
        }).catch(err => {
            setFormSubmitted(false);
        })
    };

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title="Schedule an Appointment"
            size="sm"
        >
            {
                loadingData
                    ? <div className={styles.marginVertical}><Loader /></div>
                    : <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                        validateOnMount={true}
                    >
                        {
                            formik => {
                                return (
                                    <Form>
                                        <UiFormField
                                            type="text"
                                            placeholder="Select a Topic (Required)"
                                            label="Select a Topic (Required)"
                                            fieldName="appointmentTopic"
                                            disabled={true}
                                        />
                                        <UiFormField
                                            type="date"
                                            datePickerVariant={smDevice ? 'dialog' : 'static'}
                                            label="Date & Time (Required)"
                                            disableDatePickerToolbar={true}
                                            fieldName="date"
                                            provideDayStartDate={true}
                                            shouldDisableDateFunction={shouldDisableDateFunction}
                                        />
                                        <UiFormField
                                            type="select"
                                            placeholder="Select Time"
                                            label=""
                                            labelSize={false}
                                            fieldName="start_time"
                                            optionsData={allMappedTimeSlots.find(timeSlot =>
                                                timeSlot.date === formik.values.date
                                            )?.timeSlots || []}
                                            optionKey="time"
                                            optionValue="timestamp"
                                            fastField={false}
                                        />
                                        <UiFormField
                                            type="textarea"
                                            placeholder="Write a description (Optional)"
                                            label=""
                                            labelSize={false}
                                            fieldName="description"
                                        />
                                        {
                                            formSubmitted
                                                ? <div className={styles.marginVertical}>
                                                    <Loader />
                                                </div>
                                                : <Button
                                                    variant="contained"
                                                    fullWidth
                                                    color="primary"
                                                    className={styles.marginVertical}
                                                    type="submit"
                                                    disabled={!formik.isValid}
                                                    onClick={() => {
                                                        formik.validateForm();
                                                    }}
                                                >
                                                    Schedule Appointment
                                        </Button>
                                        }
                                    </Form>
                                )
                            }
                        }
                    </Formik >
            }
            <UiErrorInfoDialog
                message="No slots are avalable."
                open={showError}
                handleClose={() => { setShowError(false); handleClose() }}
            />
        </UiDialog >
    )
}

const mapStateToProps = ({ config, appData }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id
})
export default connect(mapStateToProps)(ScheduleTaxSavingsAnalysisPopup);