import { 
    Theme,
    Typography, 
    Tooltip, 
    useTheme, 
    useMediaQuery,
    makeStyles
} from '@material-ui/core'
import infoIcon from '../../../assets/icons-svg/infoExclamation.svg'
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { currencyFormatter } from '../../../utils/appUtil'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';


const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        textColor: {
            color: (colorTheme) => colorTheme.red200,
        },
    };
});

export const dataItem = (title: any, data: any) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
                variant="body2"
                gutterBottom
                style={{ display: 'flex' }}
            >
                {title}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {data}
            </Typography>
        </div>
    )
}

export const InfoTooltip = (text: string) => (
    <Tooltip title={text} placement="top" arrow>
        <div style={{ marginTop: '2px', marginLeft: '6px' }}>
            <img alt="remove" src={infoIcon} />
        </div>
    </Tooltip>
)

export const ReconcileBalanceInfo = ({
    transactions,
    reconciliation,
    reconcileDifference,
    reconcileTransactionCount,
    reconcileBalance,
}: any) => {
    const theme = useTheme()
    const { colorTheme } = useThemeContext()
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const styles = useStyles(colorTheme)

    return (
        <div
            style={
                isSmallDevice
                    ? { paddingRight: '16px', paddingLeft: '16px' }
                    : { paddingLeft: '16px' }
            }
        >
            {dataItem(
                <>
                    {' '}
                    Difference
                    {InfoTooltip(
                        'The difference between the beginning and ending balance must be zero in order to complete the reconciliation.'
                    )}
                </>,
                <span className={styles.textColor}>{reconcileDifference()}</span>
            )}

            {dataItem(
                'Reconciled Transactions',
                <>
                    {reconcileTransactionCount()} of {transactions.length}
                </>
            )}

            {dataItem(
                <>
                    Reconciled Amount
                    {InfoTooltip(
                        'The balance of all transactions that are checked as matching in the bank statement and business portal.'
                    )}
                </>,
                <>{currencyFormatter.format(reconcileBalance())}</>
            )}
        </div>
    )
}
