import {
    Grid,
    makeStyles,
    TextField,
    InputAdornment,
    Paper,
    Typography,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import UiText from '../../common/ui/UiText'
import UiButton from '../../common/ui/UiButton'
import {
    timeOptions,
    amountOptions,
    refOptions,
    formDefaultValues,
} from './FilterFormUtils'
import { dropdownOptions } from './InvoiceUtil'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import { startOfDayTimeStamp } from '../../../utils/dateUtil'
const useStyles = makeStyles(() => {
    return {
        sectionStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '4px',
            marginBottom: '4px',
            height: '36px',
        },
        labelStyle: {
            marginTop: '6px',
        },
        autocompleteOpton: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
    }
})

type FilterFormProps = {
    onSubmit: (data: any) => void
    contacts: any
    products: any
    formikRef: any
    queryObject: any
}

const FilterForm = ({
    onSubmit,
    products,
    contacts,
    formikRef,
    queryObject,
}: FilterFormProps) => {
    const classes = useStyles()

    const resetFieldValues = (fieldName: string) => {
        formikRef?.current?.setFieldValue(
            fieldName,
            formDefaultValues?.[fieldName]
        )
    }

    const FieldHeader = (
        label: string,
        showResetButton: boolean,
        resetCallout: () => void
    ) => {
        return (
            <div className={classes.sectionStyle}>
                <UiText className={classes.labelStyle}>{label}</UiText>
                {showResetButton && (
                    <UiButton
                        btnType="hyperlink"
                        label="Reset"
                        handleClick={() => {
                            resetCallout()
                        }}
                    />
                )}
            </div>
        )
    }

    const RenderAutocomplete = (
        options: any,
        selectedField: string,
        values: any
    ) => (
        <Autocomplete
            fullWidth
            value={values?.[selectedField]}
            options={options as any}
            getOptionLabel={(option: any) => option?.title || option?.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={(option: any) => (
                <div className={classes.autocompleteOpton}>
                    <div>{option?.title || option?.name}</div>
                    <Typography variant="caption">{option?.type}</Typography>
                </div>
            )}
            onChange={(event, item: any) => {
                formikRef?.current?.setFieldValue(
                    selectedField,
                    item ? item : ''
                )
            }}
            PaperComponent={({ children, ...other }: any) => {
                return <Paper {...other}>{children}</Paper>
            }}
        />
    )

    const renderMinDateUI = (
        label: string,
        parentField: string,
        fieldName: string, 
        maxDateValue: any) => {
        const MaxDate = new Date(maxDateValue * 1000)
        return (
            <UiFormControlSelection
                label={label}
                type="date"
                fieldName={fieldName}
                placeholder="MM/DD/YYYY"
                maxDate={MaxDate}
                fastField={false}
                disableFutureDate={parentField === 'invoice_period'}
            />
        )
    }

    const renderMaxDateUI = (
        label: string, 
        parentField: string,
        fieldName: string, 
        minDateValue: any, 
    ) => {
        const MinDate = new Date(minDateValue * 1000)
        return (
            <UiFormControlSelection
                label={label}
                type="date"
                fieldName={fieldName}
                placeholder="MM/DD/YYYY"
                disableFutureDate={parentField === 'invoice_period'}
                minDate={MinDate}
                fastField={false}
            />
        )
    }

    return (
        <div>
            <Formik
                initialValues={queryObject}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ values, setFieldValue }) => {
                    return (
                        <Form>
                            <div>
                                {FieldHeader(
                                    'Status',
                                    values?.paymentStatus !==
                                        formDefaultValues?.paymentStatus,
                                    () => {
                                        resetFieldValues('paymentStatus')
                                    }
                                )}

                                <UiFormControlSelection
                                    type="select"
                                    fieldName="paymentStatus"
                                    optionsData={dropdownOptions}
                                />
                            </div>
                            <div>
                                {FieldHeader(
                                    'Customer',
                                    values?.contact !==
                                        formDefaultValues?.contact,
                                    () => {
                                        resetFieldValues('contact')
                                    }
                                )}
                                {RenderAutocomplete(
                                    contacts,
                                    'contact',
                                    values
                                )}
                            </div>

                            <div>
                                {FieldHeader(
                                    'Products',
                                    values?.offering !==
                                        formDefaultValues?.offering,
                                    () => {
                                        resetFieldValues('offering')
                                    }
                                )}
                                {RenderAutocomplete(
                                    products,
                                    'offering',
                                    values
                                )}
                            </div>
                            <div>
                                {FieldHeader(
                                    'Due Date',
                                    values?.due_period !==
                                        formDefaultValues?.due_period,
                                    () => {
                                        resetFieldValues('due_period')
                                        resetFieldValues('minDueDate')
                                        resetFieldValues('maxDueDate')
                                    }
                                )}

                                <UiFormControlSelection
                                    type="select"
                                    optionsData={timeOptions}
                                    fieldName="due_period"
                                    onChange= {(event: any) => {
                                        if(event?.target.value === 'Custom') {
                                            setFieldValue('maxDueDate', startOfDayTimeStamp);
                                            setFieldValue('minDueDate', startOfDayTimeStamp);
                                        } else {
                                            setFieldValue('maxDueDate', 0);
                                            setFieldValue('minDueDate', 0);
                                        }
                                    }}
                                />
                                {values?.due_period === 'Custom' && (
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.labelStyle}
                                    >
                                        <Grid item md={6} xs={6}>
                                            {renderMinDateUI(
                                                'FromDate',
                                                'due_period',
                                                'minDueDate',
                                                values?.maxDueDate
                                            )}
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            {renderMaxDateUI(
                                                'ToDate',
                                                'due_period',
                                                'maxDueDate',
                                                values?.minDueDate
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                            <div>
                                {FieldHeader(
                                    'Issue Date',
                                    values?.invoice_period !==
                                        formDefaultValues?.invoice_period,
                                    () => {
                                        resetFieldValues('invoice_period')
                                        resetFieldValues('minInvoiceDate')
                                        resetFieldValues('maxInvoiceDate')
                                    }
                                )}
                                <UiFormControlSelection
                                    type="select"
                                    optionsData={timeOptions}
                                    fieldName="invoice_period"
                                    onChange= {(event: any) => {
                                        if(event?.target.value === 'Custom') {
                                            setFieldValue('minInvoiceDate', startOfDayTimeStamp);
                                            setFieldValue('maxInvoiceDate', startOfDayTimeStamp);
                                        } else {
                                            setFieldValue('minInvoiceDate', 0);
                                            setFieldValue('maxInvoiceDate', 0);
                                        }
                                    }}
                                />
                                {values?.invoice_period === 'Custom' && (
                                    <Grid
                                        container
                                        spacing={1}
                                        className={classes.labelStyle}
                                    >
                                        <Grid item md={6} xs={6}>
                                            {renderMinDateUI(
                                                'FromDate',
                                                'invoice_period',
                                                'minInvoiceDate',
                                                values?.maxInvoiceDate,
                                            )}
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            {renderMaxDateUI(
                                                'ToDate',
                                                'invoice_period',
                                                'maxInvoiceDate',
                                                values?.minInvoiceDate,
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                            <div>
                                {FieldHeader(
                                    'Ref #',
                                    values?.refType !==
                                        formDefaultValues?.refType ||
                                        values?.refNumber !==
                                            formDefaultValues?.refNumber,
                                    () => {
                                        resetFieldValues('refNumber')
                                        resetFieldValues('refType')
                                    }
                                )}

                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="select"
                                            optionsData={refOptions}
                                            fieldName="refType"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="number"
                                            placeholder={'0'}
                                            fieldName="refNumber"
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    formikRef?.current?.setFieldValue(
                                                        'refNumber',
                                                        parseInt(event.target.value)
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                {FieldHeader(
                                    'Amount',
                                    values?.amountType !==
                                        formDefaultValues?.amountType ||
                                        values?.amount !==
                                            formDefaultValues?.amount,
                                    () => {
                                        resetFieldValues('amount')
                                        resetFieldValues('amountType')
                                    }
                                )}

                                <Grid container spacing={1}>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="select"
                                            optionsData={amountOptions}
                                            fieldName="amountType"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <UiFormControlSelection
                                            type="number"
                                            placeholder={'0.0'}
                                            startIcon={'$'}
                                            fieldName="amount"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default FilterForm
