import { Box, Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Company } from '../../../models/payroll/company';
import {
    AppData,
    ApplicationStore,
    CompanyStepInfo,
} from '../../../models/store';
import {
    createCompany,
    getCompany,
    updateCompany,
} from '../../../services/apiService';
import { formSubmitErrorHandler } from '../../../services/formService';
import Loader from '../../common/Loader';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiFormField from '../../common/ui/UiFormField';
import FormHeaderText from './FormHeaderText';
import NextActionButton from './NextActionButton';

interface BusinessInformationFormProps {
    appData: AppData;
    companyStepInfo: CompanyStepInfo;
    refreshSteps: () => void;
    parentLoading: (loading: boolean) => void;
}

const BusinessInformationForm = forwardRef(
    (
        {
            appData,
            companyStepInfo,
            refreshSteps,
            parentLoading,
        }: BusinessInformationFormProps,
        ref
    ) => {
        const [initialValues, setInitialValues] = useState<any>({
            ein: '',
            phone: '',
        });
        const [loading, setLoading] = useState(true);
        const [submittingForm, setSubmittingForm] = useState(false);
        const companyCreated =
            companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status
                .company_creation;
        const formRef = useRef<any>();
        const [skipNext, setSkipNext] = useState(false);

        useEffect(() => {
            getCompany(appData.current_business_id)
                .then((data) => {
                    setInitialValues(data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }, []);

        useEffect(() => {
            parentLoading(submittingForm);
            return () => {
                parentLoading(false);
            };
        }, [submittingForm]);

        useImperativeHandle(
            ref,
            () => ({
                isFromDirty() {
                    return formRef.current?.dirty;
                },

                submit() {
                    if (formRef.current.isValid) {
                        setSkipNext(true);
                    }

                    return formRef.current.submitForm;
                },
            }),
            [formRef]
        );

        const onSubmit = (data: Company, formHelpers: FormikHelpers<any>) => {
            setSubmittingForm(true);
            formSubmitErrorHandler(
                (companyCreated ? updateCompany : createCompany)(
                    appData.current_business_id,
                    data
                ).then((val) => {
                    if (skipNext) {
                        setSkipNext(false);
                        setInitialValues(val);
                        formHelpers.resetForm(val);
                    } else {
                        refreshSteps();
                    }

                    setSubmittingForm(false);
                }),
                () => {
                    setSubmittingForm(false);
                    setSkipNext(false);
                },
                formHelpers.setFieldError
            );
        };
        const validationSchema = Yup.object({
            name: Yup.string()
                .min(2, 'Company Name should be at least 2 characters')
                .required('Business name is required'),
            trade_name: Yup.string(),
            ein: Yup.string().required(
                'Employee Identification Number is required'
            )
        });

        return (
            <Grid
                style={{
                    height: 'calc(100% - 32px)',
                    overflowX: 'hidden',
                    overflowY:
                        loading || companyStepInfo.loadingStepInfo
                            ? 'hidden'
                            : 'auto',
                }}
            >
                <FormHeaderText
                    heading='Business Information'
                    formType='(Required)'
                />
                {loading || companyStepInfo.loadingStepInfo ? (
                    <Loader />
                ) : (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                        innerRef={formRef}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <Box my={2} mt={4}>
                                        <UiFormField
                                            fastField={false}
                                            type='text'
                                            label={'Business Name (Required)'}
                                            showFloatingLabel={true}
                                            fieldName='name'
                                            helperText={
                                                'The legal name of your business. This is not your DBA.'
                                            }
                                        />
                                    </Box>
                                    <Box my={2} mt={4}>
                                        <UiFormField
                                            fastField={false}
                                            type='text'
                                            labelSize={0}
                                            label={
                                                'Doing business as (DBA) (Optional)'
                                            }
                                            showFloatingLabel={true}
                                            fieldName='trade_name'
                                            helperText={
                                                'This is also known as a trade name, assumed name, or fictitious name. This cannot be adjusted after saving.'
                                            }
                                            disabled={companyCreated}
                                        />
                                    </Box>
                                    <Box my={2} mt={4}>
                                        <UiFormControlSelection
                                            type='mask'
                                            label={
                                                'Employer Identification Number (EIN) (Required)'
                                            }
                                            showFloatingLabel={true}
                                            fieldName='ein'
                                            mask={[
                                                /[0-9]/,
                                                /[0-9]/,
                                                '-',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                            minLength={9}
                                        />
                                    </Box>
                                    <Box my={2} mt={2}>
                                        <NextActionButton
                                            loading={submittingForm}
                                            submitAction={formik.submitForm}
                                        />
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Grid>
        );
    }
);

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
    BusinessInformationForm
);
