import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { showError } from '../../../services/formService'
import { toRem16 } from '../../../styles/commonStyles'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import Loader from '../../common/Loader'
import UiText from '../../common/ui/UiText'
import { getReconciliation } from '../../../services/apiService/reconciliations'
import { ReconciliationDetails } from '../../bookkeeping/reports/models/reports-interface'
import { useAllActivityFeedNotifications } from '../hooks/useAllActivityFeedNotifications'
import { NoActivityView } from '../emptyViews/NoActivityView'
import { MessageFeed } from './MessageFeed'
import { ReconciliationFeed } from './ReconciliationFeed'
import {
    ALLOWED_NOTIFICATION_CONTENT_TYPE,
    NotificationData,
    NOTIFICATION_TYPES,
} from './ActivityFeed.model'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        activityFeedSection: {
            border: (colorTheme) => `${toRem16(1)} solid ${colorTheme.grey200}`,
            padding: `${toRem16(20)} ${toRem16(20)}`,
            borderRadius: toRem16(8),
        },
        activityFeedContainer: {
            margin: `${toRem16(32)} 0`,
        },
        activityFeedSectionHeader: {
            margin: `0 0 ${toRem16(8)} 0`,
        },
        activityFeedList: {
            padding: 0,
            margin: 0,
            '& li': {
                listStyleType: 'none',
            },
        },
    })
)
export const ActivityFeed = () => {
    const { colorTheme } = useThemeContext()
    const storeData = useCurrentStore()
    const { currentAccountId, currentBusinessId } = storeData
    const { isDataLoading, notificationsData } =
        useAllActivityFeedNotifications()
    const [isReconciliationsDataLoading, setIsReconciliationsDataLoading] =
        useState(false)
    const classes = useLocalStyles(colorTheme)
    const history = useHistory()
    const { isMobileDevice } = useDeviceSize()

    const getReconciliationDetails = (reconciliationId: string) => {
        setIsReconciliationsDataLoading(true)
        getReconciliation(
            currentAccountId,
            currentBusinessId as string,
            reconciliationId
        )
            .then((res: unknown) => {
                const reconciliationDetails = res as ReconciliationDetails
                setIsReconciliationsDataLoading(false)
                history.push(
                    `/reconcile/${reconciliationId}/reconcile?account=${reconciliationDetails.account}`
                )
            })
            .catch((error) => {
                setIsReconciliationsDataLoading(false)
                showError(error?.statusText)
            })
    }
    const isNotificationTypeReconciliation = (
        notification: NotificationData
    ): boolean => {
        return (
            notification.type === NOTIFICATION_TYPES.ALERT &&
            notification.content.type === ALLOWED_NOTIFICATION_CONTENT_TYPE
        )
    }
    const isNotificationTypeUnReadConvo = (
        notification: NotificationData
    ): boolean => {
        return notification.type === NOTIFICATION_TYPES.UNREAD_CONVERSATION
    }
    const isAcitivitesTypeValid = (
        notificationsData: NotificationData[]
    ): boolean => {
        const notifications = notificationsData.filter(
            (notification: NotificationData) => {
                return isNotificationTypeReconciliation(notification) || isNotificationTypeUnReadConvo(notification);
            }
        )
        return notifications.length > 0
    }
    const isNoActivity = (
        notificationsData: NotificationData[] | undefined
    ): boolean => {
        if (!notificationsData) return true
        return !isAcitivitesTypeValid(notificationsData)
    }
    const displayActivityFeed = () => {
        if (isNoActivity(notificationsData)) return <NoActivityView />
        return notificationsData?.map(
            (notification: NotificationData, index: number) => {
                const { content } = notification
                return (
                    <div key={index}>
                        {isNotificationTypeUnReadConvo(notification) && (
                            <MessageFeed message={content} />
                        )}
                        {isNotificationTypeReconciliation(notification) && (
                            <ReconciliationFeed
                                content={content}
                                onViewReconciliation={getReconciliationDetails}
                            />
                        )}
                    </div>
                )
            }
        )
    }

    return (
        <div className={classes.activityFeedContainer}>
            <UiText
                variant={isMobileDevice ? "hatchback_125" : "suv_150"}
                weight="semi_bold_600"
                className={classes.activityFeedSectionHeader}
            >
                Activity Feed
            </UiText>
            <div className={classes.activityFeedSection}>
                {isDataLoading || isReconciliationsDataLoading ? (
                    <Loader />
                ) : (
                    <ul className={classes.activityFeedList}>
                        {displayActivityFeed()}
                    </ul>
                )}
            </div>
        </div>
    )
}