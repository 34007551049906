import { Fragment, useState } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Theme,
    Grid,
    GridSize,
    makeStyles,
    Button,
    Hidden,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
// icons
import { ApplicationStore, Contact } from '../../../../models';
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection';
import Loader from '../../../common/Loader';
import ModalMobileHeader from '../../../common/ModalMobileHeader';
import ConfirmContactModal from '../modal/ConfirmContactModal';
import {
    defaultValues,
    propertyIconMapping,
    ContactFormProps,
    StateCountryProps,
} from './ContactFormUtils';
import {
    handleUpdateContact,
    handleCreateContact,
    handleDeleteContact,
} from '../actions';
import { OutlinedDeleteButton } from '../../../common/DeleteButton';
import { mergeStyle } from '../../../../styles/commonStyles';
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        display: 'flex',
        marginTop: '16px',
    },
    iconClass: {
        width: '20px',
        height: '20px',
        marginRight: '16px',
        marginTop: '8px',
    },
    footerAction: {
        right: '0',
        width: '100%',
        bottom: '0',
        display: 'flex',
        height: '45px',
        padding: '0.5rem',
        position: 'absolute',
        background: (colorTheme) => colorTheme.primaryWhite,
        borderTop: (colorTheme) =>`1px solid ${colorTheme.grey200}`,
        'justify-content': 'flex-end',
        'z-index': 1,
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
    formContainer: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(4),
    },
    lastItemContainer: {
        height: '150px',
    },
    deleteButtonContainer: {
        marginTop: theme.spacing(2),
        height: '120px',
    },
}));

const hidableFields = ['State', 'City'];

function ContactForm({
    contact,
    handleClose,
    setSelectedContact,
    loadContacts,
    formikRef,
    states,
    countries,
    setContactUpdated,
    contactCallback,
    setLoading,
}: ContactFormProps) {
    const formOptionsData: StateCountryProps = {
        country: countries,
        state: states,
    };
    const { colorTheme } = useThemeContext()
    const classNames = useStyles(colorTheme);
    const theme = useTheme();
    const history = useHistory();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const location = useLocation();
    let initialValues = defaultValues;
    
    if (contact) {
        initialValues = contact;
    }

    const emptyDiv = <span className={classNames.iconClass}></span>;
    const renderIcon = (icon: any) => {
        return (
            <img
                alt={`${icon}`}
                src={
                    require(`../../../../assets/icons-svg/${icon}.svg`).default
                }
                className={classNames.iconClass}
            />
        );
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Enter a valid email address'),
    });

    const onSubmit = (data: any, actions: any) => {
        if (contact) {
            handleUpdateContact(contact.id, data, actions, (res: Contact) => {
                setSelectedContact && setSelectedContact(res as Contact);
                handleClose();
                loadContacts?.();
                setContactUpdated?.(true);
            });
        } else {
            handleCreateContact(data, actions, (response) => {
                contactCallback?.(response);
                handleClose(true);
                loadContacts?.();
            });
        }
    };

    const renderFormFields = (property: any) => {
        return (
            <UiFormControlSelection
                showFloatingLabel
                placeholder={property?.placeholder}
                type={property.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                optionsData={property?.optionsData}
                optionKey={property?.optionKey}
                optionValue={property?.optionValue}
                label={property?.label}
                {...(property.type === 'mask' ? { mask: property?.regex } : {})}
                {...(property?.minLength
                    ? { minLength: property?.minLength }
                    : {})}
            />
        );
    };
    /**
     * Handle FORM Modal closing
     * @param touched | FormikProps
     */
    const handleFormClose = (touched: any) => {
        if (contact) {
            handleClose(); // on edit no check touch check added direct close
        } else {
            // if form is touched then confirmation modal opens
            Object.keys(touched).length > 0
                ? setOpenConfirmationModal(true)
                : handleClose(true);
        }
    };

    /**
     * Confirmation Pop up Handling
     * This modal only appears on creating New Contact / Delete Contact
     */
    const handleConfirmModal = (result: any) => {
        // close the modal // result === true when user clicks right side options
        setOpenConfirmationModal(false);
        if (result === true) {
            if (contact) {
                // on Edit
                const currentPath = location.pathname;
                const isContactsPage = currentPath.includes('/contacts');
                setLoading?.(true);
                handleDeleteContact(contact.id, () => {
                    setOpenConfirmationModal(false);
                    if(isContactsPage){
                        history.push('/contacts');
                    }
                    loadContacts?.();
                    setSelectedContact?.(undefined);
                    handleClose();
                    setLoading?.(false);
                });
            } else {
                // else close the mainContactModal
                setOpenConfirmationModal(false);
                handleClose(true);
            }
        }
    };

    const GridItem = (property?: any, data: any = null) => (
        <Grid item xs={property?.xs as GridSize} md={property?.md as GridSize}>
            <div
                className={
                    property?.key === 'additional_info'
                        ? mergeStyle(
                              classNames.container,
                              classNames.lastItemContainer
                          )
                        : classNames.container
                }
            >
                {property.icon ? renderIcon(property.icon) : emptyDiv}
                {data ? data : renderFormFields(property)}
            </div>
        </Grid>
    );
    return (
        <>
            <ConfirmContactModal
                contact={contact}
                showConfirmModal={openConfirmationModal}
                handleClose={handleConfirmModal}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ submitForm, touched, isSubmitting, values }) => {
                    const isNonUS = values?.address?.country !== 'US';
                    return (
                        <Form>
                            <ModalMobileHeader
                                title={contact ? 'Edit Contact' : 'New Contact'}
                                buttonText={'save'}
                                loading={isSubmitting}
                                handleClose={() => {
                                    handleFormClose(touched);
                                }}
                                handleButtonAction={() => submitForm()}
                            />
                            <div
                                className={
                                    isSmDown ? classNames.formContainer : ''
                                }
                            >
                                {propertyIconMapping(formOptionsData).map(
                                    (property: any) => (
                                        <Fragment key={property.key}>
                                            <Grid container spacing={1}>
                                                {property.key !== 'address' ? (
                                                    <>{GridItem(property)}</>
                                                ) : (
                                                    <>
                                                        {property?.addressDetails &&
                                                            property.addressDetails.map(
                                                                (
                                                                    detail: any,
                                                                    index: number
                                                                ) => (
                                                                    <Fragment
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {index ===
                                                                        0
                                                                            ? GridItem(
                                                                                  property,
                                                                                  detail.label
                                                                              )
                                                                            : isNonUS &&
                                                                              hidableFields.includes(
                                                                                  detail.label
                                                                              )
                                                                            ? null
                                                                            : GridItem(
                                                                                  detail
                                                                              )}
                                                                    </Fragment>
                                                                )
                                                            )}
                                                    </>
                                                )}
                                            </Grid>
                                        </Fragment>
                                    )
                                )}
                            </div>
                            {contact?.id && (
                                <div
                                    className={classNames.deleteButtonContainer}
                                >
                                    <OutlinedDeleteButton
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth={isSmDown}
                                        onClick={() => {
                                            setOpenConfirmationModal(true);
                                        }}
                                        showDeleteIcon
                                    >
                                        Delete Contact
                                    </OutlinedDeleteButton>
                                </div>
                            )}
                            <Hidden xsDown>
                                <div className={classNames.footerAction}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid justify="flex-end" container>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => {
                                                    handleFormClose(touched);
                                                }}
                                                className={
                                                    classNames.cancelButton
                                                }
                                                data-cy="cancel-contact-changes"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={submitForm}
                                                data-cy="save-contact-changes"
                                            >
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    )}
                                </div>
                            </Hidden>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    countries: state.config.apiConfig.generic.countries,
    states: state.config.apiConfig.generic.states,
});
export default connect(mapStateToProps)(ContactForm);
