import { AppData, CompanyStepInfo } from '../../models';
import { getOnboardingStepInfo } from '../../services/apiService';

export const SET_COMPANY_STEP_INFO = 'SET_COMPANY_STEP_INFO';
export const LOADING_COMPANY_STEP_INFO = 'LOADING_COMPANY_STEP_INFO';
export const RESET_STEP_INFO = 'RESET_STEP_INFO';

export interface CompanyAction {
    type: string;
    state?: CompanyStepInfo;
}

export type Action = CompanyAction;

export const loadingCompanyStepInfo = (): Action => ({
    type: LOADING_COMPANY_STEP_INFO,
});
const setCompanyStepInfo = (data: CompanyStepInfo): Action => ({
    type: SET_COMPANY_STEP_INFO,
    state: data,
});

export function loadCompanyStepInfo() {
    return (dispatch: any, getState: any) => {
        dispatch(loadingCompanyStepInfo());
        const { appData }: { appData: AppData } = getState();
        getOnboardingStepInfo(appData.current_business_id).then((res) => {
            dispatch(
                setCompanyStepInfo({
                    loadingStepInfo: false,
                    loadedStepInfo: true,
                    companyOnboardingStepInfo: res,
                })
            );
        });
    };
}
