import React, { useState, useRef } from 'react'
import { Grid, Button, makeStyles, Theme } from '@material-ui/core'
import UiDialog from '../../../components/common/ui/UiDialog'
import { Formik, Form, FieldArray, FormikProps, FormikValues } from 'formik'
import * as Yup from 'yup'
import Loader from '../../common/Loader'
import { useDispatch } from 'react-redux'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import AddIcon from '@material-ui/icons/Add'
import { commonStyles } from '../../../styles/commonStyles'
import ClearIcon from '@material-ui/icons/Clear'
import { sendEmailInvoice } from '../../../services/apiService/invoice'
import { showAlert } from '../../../store/actions/feedback'
import { invoicePdfURL } from '../../../services/apiService/invoice'
import Icon from '../../../components/common/Icon'
import UiText from '../../common/ui/UiText'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    downloadButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
    },
    pdfstyle: {
        marginLeft: '5px',
        marginRight: '10px',
    },
    fieldMargin: {
        marginBottom: '10px'
    },
    desktopHeight: {
        height: '37.5rem'
    }

}))

const validationSchema = Yup.object({
    from: Yup.string()
        .email('Must be a valid email address')
        .required('This is a required field'),
    to: Yup.array().of(
        Yup.string()
            .email('Must be a valid email address')
            .required('Receipients is required')
    ),
    subject: Yup.string().required('Email subject is required'),
    body: Yup.string(),
})

const EmailInvoiceModal = ({ open, handleClose, invoice, callback }: any) => {
    const { colorTheme } = useThemeContext()
    const common = commonStyles()
    const styles = useStyles(colorTheme)
    const dispatch = useDispatch()
    const { currentBusiness, currentUser } = useCurrentStore()
    const [submitting, setSubmitting] = useState(false)
    const formikRef = useRef<FormikProps<FormikValues>>(null)

    let initialValues = {
        from: currentUser.username,
        to: [invoice?.contact_data?.email],
        subject: `Invoice #${invoice?.number} from ${currentBusiness.name}`,
        body: '',
    }

    const downloadInvoice = () => {
        const link = document.createElement('a')
        link.setAttribute('id', 'download-link')
        link.target = '_blank'
        link.href = `${invoicePdfURL(invoice.id)}`
        link.click()
        let downloadLink = document.getElementById('download-link')
        downloadLink && downloadLink?.parentNode?.removeChild(downloadLink)
    }

    const onSubmit = (data: any) => {
        setSubmitting(true)
        sendEmailInvoice(invoice.id, data).then((response) => {
            if (response) {
                setSubmitting(false)
                handleClose()
                dispatch(
                    showAlert({
                        alertType: 'success',
                        alertText: 'Email is sent successfully.',
                    })
                )
                callback?.()
            }
        }).catch((err) => {
            setSubmitting(false)
            handleClose()
            dispatch(
                showAlert({
                    alertType: 'error',
                    alertText: err?.statusText,
                })
            )
        });
    }

    return (
        <div>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title="Email Invoice"
                size="sm"
                customDialogStyles={
                    { paper: styles.desktopHeight }
                }
                actions={
                    <>
                        {formikRef?.current?.isSubmitting ? (
                            <Loader />
                        ) : (
                            <Grid justify="flex-end" container>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginRight: '4px' }}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        formikRef?.current?.submitForm()
                                    }}
                                >
                                    Send
                                </Button>
                            </Grid>
                        )}
                    </>
                }
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                    innerRef={formikRef}
                >
                    {({ values }) => {
                        return (
                            <Form>
                                <div className={common.mt16}>
                                    <UiText className={styles.fieldMargin}> From (Required)</UiText>
                                    <UiFormControlSelection
                                        label="From (required)"
                                        type="text"
                                        fieldName="from"
                                        showFloatingLabel={false}
                                    />
                                </div>
                                <div className={common.mt16}>
                                    <UiText className={styles.fieldMargin}> To (Required)</UiText>
                                    <FieldArray name="to">
                                        {({ push, remove }) => (
                                            <>
                                                {values.to.map(
                                                    (
                                                        receipient: any,
                                                        index: any
                                                    ) => {
                                                        return (
                                                            <div
                                                                className={
                                                                    common.mt16
                                                                }
                                                                key={`count-${index}`}
                                                            >
                                                                <Grid container>
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        xs={values.to.length > 1 ? 11: 12}
                                                                        md={values.to.length > 1 ? 7 : 8}
                                                                    >
                                                                        <UiFormControlSelection
                                                                            label="To (required)"
                                                                            type="text"
                                                                            fieldName={`to.${index}`}
                                                                            showFloatingLabel={
                                                                                false
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    {values.to.length > 1 &&  (
                                                                        <Grid
                                                                            item
                                                                            xs={1}
                                                                            md={1}
                                                                            alignItems="center"
                                                                        >
                                                                            <Button
                                                                                color="primary"
                                                                                aria-label="upload picture"
                                                                                classes={{
                                                                                    root: styles.downloadButton,
                                                                                }}
                                                                                onClick={() => {
                                                                                    remove(
                                                                                        index
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </Button>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </div>
                                                        )
                                                    }
                                                )}
                                                <div className={common.mt16}>
                                                    <Button
                                                        variant="text"
                                                        type="button"
                                                        classes={{
                                                            root: styles.downloadButton,
                                                        }}
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            push('')
                                                        }}
                                                    >
                                                        Add Recipient Email
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>
                                </div>

                                <div className={common.mt16}>
                                    <UiFormControlSelection
                                        label="Subject (required)"
                                        type="text"
                                        fieldName="subject"
                                        showFloatingLabel={true}
                                    />
                                </div>
                                <div className={common.mtb16}>
                                    <UiFormControlSelection
                                        label="Message (optional)"
                                        type="textarea"
                                        fieldName="body"
                                        showFloatingLabel={true}
                                        rows={5}
                                    />
                                </div>
                                <div className={common.mtb16}>
                                    <UiText className={common.mb16}>
                                        {' '}
                                        Invoice
                                    </UiText>
                                    <div className={common.flex}>
                                        <div className={styles.pdfstyle}>
                                            <Icon icon="pdf" />
                                        </div>
                                        <UiText>Invoice.pdf</UiText>
                                    </div>
                                    <Button
                                        variant="text"
                                        classes={{
                                            root: styles.downloadButton,
                                        }}
                                        onClick={downloadInvoice}
                                        startIcon={
                                            <Icon
                                                icon={'download'}
                                                svgColor={colorTheme.blue200}
                                            />
                                        }
                                    >
                                        Download PDF
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </UiDialog>
        </div>
    )
}

export default EmailInvoiceModal
