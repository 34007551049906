import { Button, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { toRem16 } from '../../../styles/commonStyles'
import UiText from '../../common/ui/UiText'
import MobileAdImage from '../../../assets/images/MobileAdImage.png'
import AppStore from '../../../assets/images/AppStore.svg'
import PlayStore from '../../../assets/images/PlayStore.svg'
import Icon from '../../common/Icon'
import { DOWNLOAD_APP_AD_BLOCK } from '../TodoSection/Todo.const'
import { dismissBlock } from '../AccountSetupItems/AccountSetupUtility'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            marginTop: '1rem',
        },
        buttonStyle: {
            margin: '2%',
            color: colorTheme => colorTheme.blue200,
        },
        mainTextContent: {
            paddingLeft: '8px',
            marginBottom: '0.125rem',
        },
        divStyle: {
            display: 'grid',
            gridTemplateColumns: `1fr ${toRem16(240)}`,
        },
        content: {
            marginTop: '2%',
            paddingLeft: '2%',
        },
        imageStyle: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            borderRadius: '0px 0.5rem 0.5rem 0px;',
        },
        logoMargin: {
            marginLeft: '1rem',
        },
    }
})

const DownloadAppAd = ({ reload }: { reload: () => void }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    const AdContent = () => {
        return (
            <div className={styles.content}>
                <UiText weight="medium_500" className={styles.content}>
                    Simplify tracking your business miles with your <br />
                    phone’s GPS.
                </UiText>
                <div className={styles.content}>
                    <a
                        href={
                            'https://apps.apple.com/us/app/1-800accountant/id1622560452'
                        }
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <img src={AppStore} alt="appStore" />
                    </a>
                    <a
                        href={
                            'https://play.google.com/store/apps/details?id=com.accountant1800.app'
                        }
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <img
                            src={PlayStore}
                            alt="playStore"
                            className={styles.logoMargin}
                        />
                    </a>
                </div>
                <Button
                    startIcon={
                        <Icon
                            icon="clock"
                            strokeColor={colorTheme.blue200}
                        />
                    }
                    variant="text"
                    className={styles.buttonStyle}
                    onClick={() => {
                        reload()
                        dismissBlock(DOWNLOAD_APP_AD_BLOCK)
                    }}
                >
                    Dismiss
                </Button>
            </div>
        )
    }

    const MobileView = () => {
        return (
            <div className={styles.container}>
                <img
                    src={MobileAdImage}
                    alt="MobileAdImage"
                    className={styles.imageStyle}
                />
                <AdContent />
            </div>
        )
    }

    const DesktopView = () => {
        return (
            <div className={styles.container}>
                <div className={styles.divStyle}>
                    <AdContent />
                    <img
                        src={MobileAdImage}
                        alt="MobileAdImage"
                        className={styles.imageStyle}
                    />
                </div>
            </div>
        )
    }

    const { isMobileDevice } = useDeviceSize()

    return <>{isMobileDevice ? <MobileView /> : <DesktopView />}</>
}

export default DownloadAppAd