import { useState } from 'react';
import { makeStyles, Button, Theme, Typography } from '@material-ui/core'
import EmployeeFormModal from '../EmployeeFormModal'
import UiText from '../../../common/ui/UiText'
import MdashCheck from '../../../common/MdashCheck'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useStyle = makeStyles<Theme, ThemeColors>(() => ({
    borderContent: {
        border: (colorTheme) => `1px solid ${colorTheme.grey700}`,
        padding: '8px',
        borderRadius: '4px',
    },
}))

const CompensationDetails = ({
    employee,
    compensation,
    setSelectedCompensations,
    formSubmitCallback,
    setCurrentForm,
    currentJob,
    buttonIcon
}: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme)
    const [openModal, setOpenModal] = useState(false)
    return (
        <div className={styles.borderContent}>
            <EmployeeFormModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                employeeData={employee}
                job={currentJob}
                compensation={compensation}
                currentForm={'CompensationDetails'}
                formSubmitCallback={formSubmitCallback}
            />
            <Typography variant="body2" color="textSecondary">
                Compensation
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    {compensation ? (
                        <div>
                            <UiText variant="motorcycle_90">
                                <MdashCheck check={compensation.rate}>
                                    {compensation.rate}
                                </MdashCheck>{' '}
                                /{' '}
                                <MdashCheck check={compensation.payment_unit}>
                                    {compensation.payment_unit}
                                </MdashCheck>{' '}
                                <br />
                                <MdashCheck check={compensation.flsa_status}>
                                    {compensation.flsa_status}
                                </MdashCheck>
                            </UiText>{' '}
                        </div>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No Compensation added
                        </Typography>
                    )}
                </div>
                <Button
                    startIcon={buttonIcon}
                    variant="text"
                    onClick={() => {
                        setOpenModal(true)
                        setSelectedCompensations(compensation)
                        setCurrentForm('CompensationDetails')
                    }}
                >
                    {' '}
                </Button>
            </div>
        </div>
    )
}

export default CompensationDetails
