import { useLocation } from 'react-router-dom';
import Loader from '../common/Loader';

export default function PaymentRedirect() {
    let query = new URLSearchParams(useLocation().search);
    window.parent.postMessage({ result: query.get('result') }, window.origin);

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                position: 'absolute',
            }}
        >
            <Loader />
        </div>
    );
}
