import {
    Box,
    Button,
    Grid,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import UiFormControlSelection from './ui/UiFormControlSelection'
import { useFormikContext } from 'formik'
import { useCurrentStore } from './hooks/useCurrentStore'
import Dropzone from 'react-dropzone'
import UiText from './ui/UiText'
import { isValidDocumentMime } from '../../utils/documentUtil'
import {
    ALLOWED_DOCUMENT_TYPES,
    MIME_IMAGES,
    MIME_TYPES,
    SUPPORTED_MIME_TYPE,
} from '../dashboard/tax-prep/constants/tax-prep.const'
import Deleteicon from '../../assets/icons-svg/DeleteNew.svg'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from './whiteLabel/ColorThemeContext'
import { deleteFile, finCenDocumentUpload } from '../../services/apiService'
import Loader from './Loader'
import {
    FileId,
} from '../dashboard/TodoSection/FinCenInfoModal/FinCenData.types'
import Icon from './Icon'
import { ApplicationStore, BusinessOwner, Config } from '../../models'
import { connect } from 'react-redux'

interface Iprop {
    removeOwner: Function
    fieldName: string
    index: number
    config: Config
    formikRef:any
}

const customStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        ownerBox: {
            border: '1px solid',
            borderRadius: '0.5rem',
            padding: '1rem',
            borderColor: (colorTheme) => colorTheme.grey300,
            marginBottom: theme.spacing(3),
            color: (colorTheme) => colorTheme.black100,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
        },
        fieldContainer: {
            marginBottom: theme.spacing(4),
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: 0,
            },
        },
        mediumFieldContainer: {
            width: '60%',
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            },
        },
        ownerContainerHeader: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                gap: '0.8rem',
                marginBottom: theme.spacing(4),
            },
            ['& > p']:{
                lineHeight: "36px"
            }
        },
        ownerDocumentTitle: {
            marginBottom: theme.spacing(1),
        },
        ownerDocumentSubHeading: {
            marginBottom: theme.spacing(3),
        },
        dropzone: {
            display: 'flex',
            padding: '1rem 0.7rem',
            alignItems: 'center',
            border: (colorTheme) => `2px dashed ${colorTheme.grey200}`,
            width: '100%',
            [theme.breakpoints.up('sm')]:{
                width: '95%'
            },
            borderRadius: '0.5rem',
            [theme.breakpoints.down('xs')]: {
                border: 'none !important',
                display: 'flex',
                flexDirection: 'column',
                padding: '0',
                '& .mobileViewHidden': {
                    display: 'none',
                },
                '& .MuiButton-root': {
                    width: '100%',
                    marginBottom: '1rem',
                }
            },
        },
        fileContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem 0 1rem 1rem ',
            borderRadius: '0.5rem',
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            marginBottom: '1rem'
        },
        uploadedFileContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                gap: '0.8rem',
            },
        },
        file: {
            height: '1.25rem',
            width: '1.25rem',
            borderRadius: '50%',
            margin: 'auto 0',
        },
        uploadInfo: {
            display: 'flex',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01em',
            color: (colorTheme) => colorTheme.grey400,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                '& .mobileViewHidden': {
                    display: 'none',
                }
            }
        },
        document: {
            display: 'flex',
            alignItems: 'center',
            gap: '1.5rem',
            width: '100%',
        },
        deleteIcon: {
            color: (colorTheme) => colorTheme.red400,
        },
        dotClass: {
            margin: '0 0.5rem',
        },
        loaderContainer: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: (colorTheme) => colorTheme.white100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.5rem',
            zIndex: 1,
        },
        uploadContainer: {
            position: 'relative',
        },
        fileUploadContainer: {
            position: 'relative',
        },
        btnContainer: {
            display: 'flex',
            gap: '1rem',
            [theme.breakpoints.down('xs')]: {
                width:'100%'
            },
        },
        cancelButton: {
            color: (colorTheme) => colorTheme.blue200,
            border: 'none',
            outline: 'none',
            background: 'none',
            padding: 0,
            font: 'inherit',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                flex:'1'
            },
        },
        deleteEntry: {
            color: (colorTheme) => colorTheme.primaryWhite,
            background: (colorTheme) => colorTheme.red400,
            cursor: 'pointer',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: (colorTheme) => colorTheme.red400,
                color: (colorTheme) => colorTheme.primaryWhite,
                transform: 'initial',
            },
            [theme.breakpoints.down('xs')]: {
                flex:'1'
            },
        },
        customBtnStyle: {
            marginRight: '1rem',
        },
        fullwidth: {
            width: '100%'
        },
        flexWrap: {
            flexWrap:'wrap'
        }
    }
})

const allowedMimeTypes = SUPPORTED_MIME_TYPE
const allowedDocumentTypes = ALLOWED_DOCUMENT_TYPES

const FinCenOwnerForm: React.FC<Iprop> = ({
    fieldName,
    index,
    removeOwner,
    config,
    formikRef,
}) => {
    const { colorTheme } = useThemeContext()
    const classes = customStyle(colorTheme)
    const theme  = useTheme()
    const isLargerThanSmScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const { currentAccountId, currentConfig, currentBusinessId } = useCurrentStore()
    const [isDocumentLoading, setIsDocumentLoading] = useState(false)
    const [deleteOwner, setDeleteOwner] = useState(false);
    const [deleteOwnerDocument, setDeleteOwnerDocument] = useState(false);
    const [stateFieldDisabled, setStateFieldDisabled] = useState(false)
    const [isownerLoading, setIsOwnerLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { values, setFieldValue, setFieldError, validateForm, setFieldTouched } = useFormikContext<any>()
    const isXsScreen =  window.matchMedia('(max-width: 599px)').matches;

    const getUploadedDocImageType = (mimeType: string) => {
        if (
            mimeType === MIME_TYPES.IMG_JPEG ||
            mimeType === MIME_TYPES.IMG_PNG ||
            mimeType === MIME_TYPES.IMG_JPG
        ) {
            return MIME_IMAGES.ATTACHMENT_IMG
        }
        if (mimeType === MIME_TYPES.APP_PDF) {
            return 'PDFIcon'
        }
        return MIME_IMAGES.GENERIC
    }

    const getImageBasedOnMimeType = (mimeType: string) => {
        const imageType = getUploadedDocImageType(mimeType)
        return (
            <img
                src={require(`../../assets/icons-svg/${imageType}.svg`).default}
                alt="document"
            />
        )
    }

    const handleDocumentDelete = (isOwnerRemove?: boolean): void => {
        setDeleteOwnerDocument(false)
        setIsDocumentLoading(true)
        const fileId: FileId =
            values.business_owners[index]?.owner_uin_file_details?.id

        if (isOwnerRemove && !fileId) {
            removeOwner(index)
            setDeleteOwner(false)
            setIsOwnerLoading(false)
            setIsDocumentLoading(false)
            return
        }

        if (!fileId) {
            setIsDocumentLoading(false)
            setIsOwnerLoading(false)
            return
        }
        if(isOwnerRemove) {
            setIsOwnerLoading(true)
        }   

        deleteFile(currentAccountId, fileId)
            .then((res) => {
                if (isOwnerRemove) {               
                    removeOwner(index)
                    setDeleteOwner(false)
                }else {
                    setFieldValue(`${fieldName}[${index}].owner_uin_file_link`, '')
                    setFieldValue(`${fieldName}[${index}].owner_uin_file_details`, null)  
                }
            })
            .catch((err) => {
                setErrorMessage('File delete failed. Please try again.')
            })
            .finally(() => {
                setIsDocumentLoading(false)
                setIsOwnerLoading(false)
            })
    }

    const isUseOwnership = () => {
        const businessType = config?.apiConfig.business.types.find(
          (item) => item.abbr === values?.business_type
        );
        return values.business_type && businessType?.ownership_is_percent;
      };

    const validateOwnership = (value: any) => {
        const businessOwners =
          formikRef?.current?.values?.business_owners || values.business_owners;
        const error = "Total ownership percantage should be 100%";
        if (isUseOwnership() && value === "") {
          return "Required";
        }
        const totalOwnership = businessOwners
          ? businessOwners.reduce(
              (acc: number, owner: BusinessOwner) =>
                acc + (owner?.ownership ? owner?.ownership : 0),
              0
            )
          : [];
        if (isUseOwnership() && totalOwnership !== 100) {
          return error;
        }
        return "";
    };

    function formatFileSize(sizeInBytes: number): string {
        const sizeInKb = Math.round(sizeInBytes / 1024)
        return `${sizeInKb} kb`
    }

    const renderUploadedFiles = (fileDetails: any) => {
        if (!fileDetails) return
        return (
            <div className={classes.fileContainer}>
                {deleteOwnerDocument && (
                    <div className={`${classes.uploadedFileContainer} ${classes.flexWrap}`}>
                        <UiText variant={isLargerThanSmScreen ? "hatchback_125" : "car_100"} weight={isLargerThanSmScreen ? "bold_700" : "semi_bold_600"}>
                            Delete this file?
                        </UiText>
                        <div
                            className={`${classes.btnContainer} ${classes.customBtnStyle}`}
                        >
                            <Button
                                className={classes.cancelButton}
                                onClick={() => setDeleteOwnerDocument(false)}
                            >
                                <UiText
                                    variant="motorcycle_90"
                                    weight="semi_bold_600"
                                >
                                    Keep it
                                </UiText>
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.deleteEntry}
                                startIcon={
                                    <Icon
                                        icon="delete"
                                        svgColor={colorTheme.primaryWhite}
                                    />
                                }
                                onClick={() => handleDocumentDelete()}
                            >
                                <UiText
                                    variant="motorcycle_90"
                                    weight="semi_bold_600"
                                >
                                    Delete
                                </UiText>
                            </Button>
                        </div>
                    </div>
                )}

                {!deleteOwnerDocument && (
                    <div className={classes.uploadedFileContainer}>
                        <div className={classes.document}>
                            <div className={classes.file}>
                                {getImageBasedOnMimeType(
                                    fileDetails?.owner_uin_file_details
                                        ?.mime_type
                                )}{' '}
                            </div>

                            <div className={classes.metaData}>
                                <UiText variant="car_100">
                                    {
                                        fileDetails?.owner_uin_file_details
                                            ?.filename
                                    }
                                </UiText>
                                <span className={`${classes.uploadInfo}`}>
                                    <UiText>
                                        Uploaded{' '}
                                        {formatDate(
                                            fileDetails?.owner_uin_file_details
                                                ?.upload_date
                                        )}
                                    </UiText>{' '}
                                    <span
                                        className={`${classes.dotClass} mobileViewHidden`}
                                        style={{ fontSize: '14px' }}
                                    >
                                        •
                                    </span>{' '}
                                    <UiText>
                                        {formatFileSize(
                                            fileDetails?.owner_uin_file_details
                                                ?.size
                                        )}
                                    </UiText>
                                </span>
                            </div>
                        </div>

                        <div>
                            <Button
                                variant="text"
                                className={classes.deleteIcon}
                                startIcon={
                                    <img alt={`Delete`} src={Deleteicon} />
                                }
                                onClick={() =>
                                    setDeleteOwnerDocument(true)
                                }
                            ></Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )

    const uploadFile = async (file: File[]) => {
        setIsDocumentLoading(true)
        finCenDocumentUpload(file, currentAccountId, currentBusinessId)
            .then((res: any) => {
                const fileData = res[0][0]
                setFieldValue(`${fieldName}[${index}].owner_uin_file_link`, fileData.id)
                setFieldValue(`${fieldName}[${index}].owner_uin_file_details`, fileData)
                setErrorMessage(null)
            })
            .catch((error) => {
                setErrorMessage('File upload failed. Please try again.')
            })
            .finally(() => {
                setIsDocumentLoading(false)
            })
    }

    const checkFileSize = (files: File[]) => {
        const fileDetails = files[0]

        const fileSizeMB = fileDetails.size / (1024 * 1024) // convert size to MB
        const fileType = fileDetails.type

        if (fileType === 'application/pdf' && fileSizeMB > 4) {
            setErrorMessage('File size is more than 4MB')
            return false
        }

        if (fileType.startsWith('image/') && fileSizeMB > 10) {
            setErrorMessage('Image size is more than 10MB')
            return false
        }

        setErrorMessage(null)
        return true
    }

    const handleUploadFile = (files: File[]): void => {
        if (!files.length) {
            return
        }

        if (isFileTypeUnsupported(files)) {
            setErrorMessage('File(s) has unsupported format')
            return
        }

        if (!checkFileSize(files)) {
            return
        }

        uploadFile(files)
    }

    const formatDate = (date: number) => {
        const uploadDate = new Date(date * 1000)
        return uploadDate.toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        })
    }

    const acceptedFormat = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
    ]


    return (
        <div className={classes.ownerBox}>
            {isownerLoading && (
                <div className={classes.loaderContainer}>
                    <Loader />
                </div>
            )}
            {!deleteOwner && (
                <Grid container className={classes.ownerContainerHeader}>
                    <UiText variant={isLargerThanSmScreen ? "hatchback_125" : "car_100"} weight={isLargerThanSmScreen ? "bold_700" : "semi_bold_600"}>
                        Owner {index + 1}
                    </UiText>
                    {(values.business_owners?.length || 0) > 1 ? (
                        <Button
                            className={classes.deleteIcon}
                            startIcon={<img alt="Delete owner" src={Deleteicon} />}
                            onClick={() => setDeleteOwner(true)}
                        >
                            Delete
                        </Button>
                    ) : null}
                </Grid>
            )}
            {deleteOwner && (
                <Grid
                    container
                    justify="space-between"
                    className={`${classes.ownerContainerHeader} ${classes.ownerDeleteConfirmationModal}`}
                >
                    <UiText variant={isLargerThanSmScreen ? "hatchback_125" : "car_100"} weight={isLargerThanSmScreen ? "bold_700" : "semi_bold_600"}>
                        Delete Owner {index + 1}?
                    </UiText>
                    <div className={classes.btnContainer}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => setDeleteOwner(false)}
                        >
                            <UiText
                                variant="motorcycle_90"
                                weight="semi_bold_600"
                            >
                                Cancel
                            </UiText>
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.deleteEntry}
                            startIcon={
                                <Icon
                                    icon="delete"
                                    svgColor={colorTheme.primaryWhite}
                                />
                            }
                            onClick={() => handleDocumentDelete(true)}
                        >
                            <UiText
                                variant="motorcycle_90"
                                weight="semi_bold_600"
                            >
                                Delete
                            </UiText>
                        </Button>
                    </div>
                </Grid>
            )}
            <div>
                <div className={classes.fieldContainer}>
                    <UiFormControlSelection
                        required={true}
                        label="Full Legal Name (Required)"
                        type="text"
                        fieldName={`${fieldName}[${index}].tax_payer_name`}
                        showFloatingLabel={true}
                        size={isXsScreen ? 'medium' : 'small'}
                    />
                </div>

            {isUseOwnership() ? (
                <div
                    className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                >
                    <UiFormControlSelection
                    fieldName={`${fieldName}[${index}].ownership`}
                    type="number"
                    label="Ownership Percentage (Required)"
                    showFloatingLabel={true}
                    validationFunction={validateOwnership}
                    size={isXsScreen ? 'medium' : 'small'}
                    />
                </div>
            ) : (
                <div
                    className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                >
                    <UiFormControlSelection
                    fieldName={`${fieldName}[${index}].ownership`}
                    type="number"
                    label="Number of Shares"
                    showFloatingLabel={true}
                    min={1}
                    step={1}
                    size={isXsScreen ? 'medium' : 'small'}
                    />
                </div>
            )}

                <div
                    className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                >
                    <UiFormControlSelection
                        key={`${fieldName}-${index}-dob_date`}
                        label="Date of Birth (Required)"
                        type="KeyboardDatePickerCustom"
                        fieldName={`${fieldName}[${index}].dob_date`}
                        dateType="string"
                        disableFutureDate={true}
                        showFloatingLabel={true}
                        dateFormat="MM/DD/YYYY"
                        placeholder='MM/DD/YYYY'
                        size={isXsScreen ? 'medium' : 'small'}
                    ></UiFormControlSelection>
                </div>

                <div className={classes.fieldContainer}>
                    <UiFormControlSelection
                        key={`${fieldName}[${index}].address.address`}
                        label="Street Address (Required)"
                        type="text"
                        fieldName={`${fieldName}[${index}].address.address`}
                        showFloatingLabel={true}
                        size={isXsScreen ? 'medium' : 'small'}
                    ></UiFormControlSelection>
                </div>
                <div className={classes.fieldContainer}>
                    <UiFormControlSelection
                        key={`${fieldName}[${index}].address.city`}
                        label="City (Required)"
                        type="text"
                        fieldName={`${fieldName}[${index}].address.city`}
                        showFloatingLabel={true}
                        size={isXsScreen ? 'medium' : 'small'}
                    ></UiFormControlSelection>
                </div>
                <div
                    className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                >
                    <UiFormControlSelection
                        key={`${fieldName}-${index}-country`}
                        label="Country (Required)"
                        type="autocomplete"
                        optionsData={currentConfig.apiConfig.generic.countries}
                        optionKey="name"
                        optionValue="iso_abbr"
                        fieldName={`${fieldName}[${index}].address.country`}
                        onChange={(item: any) => {
                            const selectedCountryCode = item?.iso_abbr
                            if (selectedCountryCode !== 'US') {
                                setFieldValue(
                                    `${fieldName}[${index}].address.state`,
                                    ''
                                )
                                setFieldError(
                                    `${fieldName}[${index}].address.state`,
                                    ''
                                )
                                setFieldTouched(
                                    `${fieldName}[${index}].address.state`,
                                    true
                                )
                            } else {
                                setFieldError(
                                    `${fieldName}[${index}].address.state`,
                                    'State is required'
                                )
                                setFieldTouched(
                                    `${fieldName}[${index}].address.state`,
                                    true
                                )
                            }

                            setTimeout(() => {
                                validateForm()
                            }, 200)
                        }}
                        showFloatingLabel={true}
                        size={isXsScreen ? 'medium' : 'small'}
                    ></UiFormControlSelection>
                </div>
                <div
                    className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                >
                    <UiFormControlSelection
                        key={`${fieldName}-${index}-state`}
                        label="State (Required)"
                        type="autocomplete"
                        optionsData={currentConfig.apiConfig.generic.states}
                        optionKey="name"
                        optionValue="id"
                        fieldName={`${fieldName}[${index}].address.state`}
                        disabled={
                            values.business_owners[index]?.address?.country !==
                            'US'
                        }
                        showFloatingLabel={true}
                        size={isXsScreen ? 'medium' : 'small'}
                    ></UiFormControlSelection>
                </div>
                <div
                    className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                >
                    <UiFormControlSelection
                        key={`${fieldName}-${index}-zip_code`}
                        label="Zip (Required)"
                        type="mask"
                        fieldName={`${fieldName}[${index}].address.zip_code`}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                        minLength={5}
                        showFloatingLabel={true}
                        size={isXsScreen ? 'medium' : 'small'}
                    ></UiFormControlSelection>
                </div>

                <div className="businessOwnerIdentification">
                    <div className="businessOwnerIdentificationHeader">
                        <UiText
                            className={classes.ownerDocumentTitle}
                            variant="car_100"
                            weight="bold_700"
                        >
                            Business Owner Identification (Required)
                        </UiText>
                        <UiText
                            className={classes.ownerDocumentSubHeading}
                            variant="motorcycle_90"
                        >
                            In order to properly file for FinCEN, you will need
                            to upload a picture of your identification.
                        </UiText>
                    </div>
                    <div>
                        <div
                            className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                        >
                            <UiFormControlSelection
                                key="owner_uin"
                                label="ID Type (Required)"
                                type="select"
                                optionsData={currentConfig.apiConfig.business.business_owner_uin_types}
                                optionKey="title"
                                optionValue="name"
                                fieldName={`${fieldName}[${index}].owner_uin`}
                                showFloatingLabel={true}
                                size={isXsScreen ? 'medium' : 'small'}
                            ></UiFormControlSelection>
                        </div>
                        <div
                            className={`${classes.fieldContainer}  ${classes.mediumFieldContainer}`}
                        >
                            <UiFormControlSelection
                                key={`${fieldName}[${index}].owner_uin_number`}
                                label="ID # (Required)"
                                type="text"
                                fieldName={`${fieldName}[${index}].owner_uin_number`}
                                showFloatingLabel={true}
                                size={isXsScreen ? 'medium' : 'small'}
                            ></UiFormControlSelection>
                        </div>

                        <Box className={classes.fileUploadContainer}>
                            {isDocumentLoading && (
                                <div className={classes.loaderContainer}>
                                    <Loader />
                                </div>
                            )}

                            {!values.business_owners[index]
                                ?.owner_uin_file_link && (
                                <Box
                                    className={classes.uploadContainer}
                                    mt={2}
                                    mb={4}
                                >
                                    <Dropzone onDrop={handleUploadFile}>
                                        {({ getRootProps, getInputProps }) => {
                                            return (
                                                <Box
                                                    {...getRootProps({})}
                                                    className={classes.dropzone}
                                                    textAlign="center"
                                                >
                                                    <Box>
                                                        <input
                                                            {...getInputProps()}
                                                            name="files[]"
                                                            accept={acceptedFormat.toString()}
                                                            key={index}
                                                        />
                                                    </Box>
                                                    <Box className={isLargerThanSmScreen ? ''  : classes.fullwidth}>
                                                        <Button
                                                            disabled={false}
                                                            variant={isLargerThanSmScreen ? 'contained' : 'outlined'}
                                                            startIcon={
                                                                <Icon
                                                                    icon="upload"
                                                                    svgColor={isLargerThanSmScreen ? colorTheme.primaryWhite : colorTheme.grey400 }
                                                                />
                                                            }
                                                            color={isLargerThanSmScreen ? "primary" : "secondary" }
                                                        >
                                                            Upload File
                                                        </Button>
                                                    </Box>
                                                    <Box
                                                        className="mobileViewHidden"
                                                        mx={3}
                                                    >
                                                        Or
                                                    </Box>
                                                    <Box>
                                                        <UiText
                                                            className="mobileViewHidden"
                                                            variant="car_100"
                                                        >
                                                            Drag & drop file
                                                            here to upload
                                                        </UiText>
                                                        <UiText
                                                            variant="motorcycle_90"
                                                            textColor="secondary"
                                                        >
                                                            Supported file
                                                            types: .pdf, .png,
                                                            .jpeg, .jpg
                                                        </UiText>
                                                    </Box>
                                                </Box>
                                            )
                                        }}
                                    </Dropzone>
                                    {errorMessage && (
                                        <Box mt={3}>
                                            <UiText textColor="error">
                                                {errorMessage}
                                            </UiText>
                                        </Box>
                                    )}
                                </Box>
                            )}

                            {values.business_owners[index]
                                ?.owner_uin_file_link &&
                                renderUploadedFiles(
                                    values.business_owners[index]
                                )}
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
  });

export default connect(mapStateToProps)(FinCenOwnerForm);
  
