import Icon from '../../common/Icon'
import UiText from '../../common/ui/UiText'
import InfoCard from '../Cards/InfoCard'
import { useStyles } from './BookKeepingInfoCard'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'

const FinCenInfoCard = ({ onButtonClick }: { onButtonClick: () => void }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}>
                <div className={styles.iconStyle}>
                    <Icon icon="bankingIcon" />
                </div>
                <InfoCard
                    containerStyle={styles.cardContainer}
                    customTitle={
                        <UiText variant="hatchback_125" weight="medium_500">
                            Complete your Beneficial Ownership Information Form
                        </UiText>
                    }
                    subtitle={
                        <UiText variant="motorcycle_90">
                            Starting January 1, 2024, many companies will be
                            required to report information to the U.S.
                            government about who ultimately owns and controls
                            them. Penalties for not reporting this information
                            will be steep. We're here to help.
                        </UiText>
                    }
                    buttonText={'Complete the Form'}
                    buttonClick={onButtonClick}
                />
            </div>
        </div>
    )
}

export default FinCenInfoCard
