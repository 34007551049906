import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    Button,
    Theme,
    makeStyles,
    Box,
    Chip,
    Grid,
    Hidden,
    IconButton,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Contact, ApplicationStore } from '../../../../models'
import Loader from '../../../common/Loader'
import ContactDetails from './ContactDetails'
import ContactModal from '../modal/ContactModal'
import MobileHeader from '../../../common/MobileHeader'
import { commonStyles, mergeStyle } from '../../../../styles/commonStyles';
import ConfirmContactModal from '../modal/ConfirmContactModal'
import { contactDetail } from '../../../../services/apiService/contacts'
import { handleDeleteContact } from '../actions'
import { OutlinedDeleteButton } from '../../../common/DeleteButton'
import InvoiceFormModal from '../../invoices/Modal/InvoiceFormModal'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    contactName: {
        fontSize: '24px',
    },
    editButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
    },
    marginButton: {
        marginRight: '4px',
    },
    previewContact: {
        marginTop: theme.spacing(1),
    },
    editMobileButton: {
        color: (colorTheme) => `${colorTheme.blue200} !important`,
        marginTop: '5px',
        paddingTop: 4,
        paddingBottom: 4,
    },
    containerHeight: {
        marginTop: '25px',
        maxHeight: 'calc(100vh - 200px)',
        overflow: "auto"
    }
}))

function ContactPreview({
    selectedContact,
    setSelectedContact,
    loadContacts,
    businessId,
    accountId,
    setContactUpdated,
}: {
    selectedContact?: Contact | undefined
    setSelectedContact?: React.Dispatch<Contact | undefined>
    loadContacts?: () => void
    businessId: string
    accountId: string
    setContactUpdated?: React.Dispatch<boolean>
}) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const common = commonStyles()
    const theme = useTheme()
    const history = useHistory()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    const params: { id: string } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [currentContact, setCurrentContact] = useState<Contact>()
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false)

    const handleConfirmModal = (result: any) => {
        if (result) {
            // i.e when discard/delete clicked
            handleDeleteContact(
                (currentContact?.id || selectedContact?.id) as string,
                () => {
                    setShowConfirmModal(false)
                    history.push('/contacts')
                    loadContacts?.()
                    setCurrentContact(undefined)
                    setSelectedContact?.(undefined)
                }
            )
        } else {
            setShowConfirmModal(false)
        }
    }

    useEffect(() => {
        if (params?.id && businessId && accountId) {
            setLoading(true)
            contactDetail(params?.id).then(
                (res: any) => {
                    if (res) {
                        setLoading(false)
                        setCurrentContact(res)
                    }
                }
            )
        }
    }, [accountId, businessId, params?.id, setSelectedContact])

    return (
        <Fragment>
            {!(selectedContact || currentContact) || loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <InvoiceFormModal
                        open={openInvoiceModal}
                        handleClose={()=> {
                            setOpenInvoiceModal(false)
                        }}
                        isNew={true}
                        modalTitle={"New Invoice"}
                        onSuccessCallback={()=> {
                            history.push('/invoices')
                        }}
                        contactsData={selectedContact || currentContact}
                    />
                    <ContactModal
                        open={openModal}
                        handleClose={() => setOpenModal(false)}
                        contact={selectedContact || currentContact}
                        setSelectedContact={
                            setSelectedContact || setCurrentContact
                        }
                        loadContacts={loadContacts}
                        setContactUpdated={setContactUpdated}
                    />
                    <ConfirmContactModal
                        showConfirmModal={showConfirmModal}
                        contact={currentContact || selectedContact}
                        handleClose={handleConfirmModal}
                    />
                    <MobileHeader showBottomDivider={true}>
                        <div className={mergeStyle(common.flex, common.spaceBetween)}>
                            <IconButton
                                onClick={() => history.push('/contacts')}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Button
                                variant="text"
                                classes={{ root: styles.editMobileButton }}
                                onClick={() => setOpenModal(true)}
                            >
                                Edit
                            </Button>
                        </div>
                    </MobileHeader>
                    <div className={styles.previewContact}>
                        {/* Hidden for Mobile Devices */}
                        <Hidden smDown>
                            <Grid container direction="row" justify="flex-end">
                                <OutlinedDeleteButton
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        setShowConfirmModal(true)
                                    }}
                                    showDeleteIcon
                                    className={styles.marginButton}
                                    id="contact_delete"
                                >
                                    Delete
                                </OutlinedDeleteButton>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                    className={styles.editButton}
                                >
                                    Edit
                                </Button>
                            </Grid>
                        </Hidden>
                        <div className={styles.containerHeight}>
                            <Box mx={2}>
                                <div className={styles.contactName}>
                                    {selectedContact?.name || currentContact?.name}
                                </div>
                            </Box>
                            <Box mx={2} my={2}>
                                <Button
                                    fullWidth={isXsDown}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setOpenInvoiceModal(true)}
                                >
                                    New Invoice
                                </Button>
                            </Box>
                            <Box mx={2}>
                                <Chip
                                    label={
                                        selectedContact?.type ||
                                        currentContact?.type
                                    }
                                    className={common.capitalize}
                                />
                            </Box>
                            <ContactDetails
                                contact={selectedContact || currentContact}
                            />
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    businessId: state.appData.current_business_id,
    accountId: state.appData.current_account_id,
})
export default connect(mapStateToProps)(ContactPreview)