import { Grid, makeStyles, Theme } from '@material-ui/core'
import UiChip from '../../../common/ui/UiChip'
import { currencyFormatter, capitalizeFirstLetter } from '../../../../utils/appUtil'
import { longDateFormat } from '../../../../utils/dateUtil'
import UiText from '../../../common/ui/UiText'

import type { Trip } from '../../../../models'
import { fixedTo2Decimal } from '../TripUtil'

type PropsType = {
    trip: Trip
}

const useStyles = makeStyles((theme: Theme) => ({
    chipWrapper: {
        marginBottom: theme.spacing(2),
    },
    chipLabelStyle: {
        textTransform: 'capitalize',
    },
    mileageRate: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),

    },
    secondaryText: {
        color: theme.palette.text.secondary,
    },
    costData: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    mt1: {
        marginTop: '1rem',
    } 
}))

const GeneralData = ({ trip }: PropsType) => {
    const styles = useStyles()

    const mileageDeduction = trip.miles * trip.mileage_rate
    const tripDate = new Date(trip.date * 1000)
    const taxYear = tripDate.getFullYear()
    const isBusinessActivity = trip.activity === 'business'

    return (
        <>
            <UiText variant="car_100">
                {longDateFormat(trip.date)}
            </UiText>
            <UiText variant="van_225" weight='medium_500' className={styles.mt1}>{fixedTo2Decimal(trip.miles)} mi</UiText>
            <div className={styles.costData}>
                <Grid container className={styles.mt1}>
                    <div className={styles.chipWrapper}>
                        <UiChip
                            label={trip.activity}
                            renderIcon={false}
                            labelTextStyle={styles.chipLabelStyle}
                        />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <UiText weight="medium_500">
                                {currencyFormatter.format(mileageDeduction)}
                            </UiText>
                            <UiText textColor="textSecondary">
                                Mileage Deduction
                            </UiText>
                        </Grid>
                        <Grid item xs={4}>
                            <UiText weight="medium_500">
                                {trip.parking > 0 && isBusinessActivity
                                    ? currencyFormatter.format(trip.parking)
                                    : '-'}
                            </UiText>
                            <UiText textColor="textSecondary">Parking</UiText>
                        </Grid>
                        <Grid item xs={4}>
                            <UiText weight="medium_500">
                                {trip.tolls > 0 && isBusinessActivity
                                    ? currencyFormatter.format(trip.tolls)
                                    : '-'}
                            </UiText>
                            <UiText textColor="textSecondary">Tolls</UiText>
                        </Grid>
                    </Grid>
                    <div className={styles.mileageRate}>
                        <UiText variant="motorcycle_90">
                            {currencyFormatter.format(trip.mileage_rate)}
                            /Mile&nbsp;
                            <span className={styles.secondaryText}>
                                (IRS rate for {capitalizeFirstLetter(trip.activity)} Travel in&nbsp;
                                {taxYear})
                            </span>
                        </UiText>
                    </div>
                </Grid>
            </div>
        </>
    )
}

export default GeneralData
