import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import CarIcon from '../../../../assets/icons-svg/CarIcon.svg'
import { toRem16 } from '../../../../styles/commonStyles'
import UiButton from '../../../common/ui/UiButton'
import UiText from '../../../common/ui/UiText'
import { Link } from 'react-router-dom'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'

const useLocalStyles = makeStyles<Theme, ThemeColors>(() =>
    createStyles({
        container: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
        },
        content: {
            maxWidth: toRem16(640),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        textContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: `${toRem16(40)} 0 0 0`
        },
        addVehicleButton: {
            margin: `${toRem16(40)} 0 ${toRem16(32)} 0`,
        },
        upgradePlanLink: {
            color: (colorTheme) => colorTheme.blue200,
            textDecoration: 'none',
        },
        infoText: {
            display: 'flex',
            '& a': {
                margin: '0 0 0 0.5rem',
            },
        },
    })
)
interface Props {
    onAddVehicle: () => void
    isFreeTrial: boolean
}
export const EmptyState = (props: Props) => {
    const { onAddVehicle, isFreeTrial } = props
    const { colorTheme } = useThemeContext()
    const classes = useLocalStyles(colorTheme)

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <img src={CarIcon} alt="Car Icon" />
                <div className={classes.textContent}>
                    <UiText variant="truck_175" weight="medium_500">
                        Track your business miles
                    </UiText>
                    <UiText>You haven’t added a vehicle yet.</UiText>
                    <UiButton
                        handleClick={onAddVehicle}
                        label="Add a Vehicle"
                        btnType="primary"
                        icon={<Add />}
                        customClass={classes.addVehicleButton}
                    />
                    {isFreeTrial && (
                        <UiText className={classes.infoText}>
                            Need to track trips for more than one vehicle?{' '}
                            <Link
                                to="/purchase/upgrade-portal-access"
                                className={classes.upgradePlanLink}
                            >
                                <UiText weight="medium_500">
                                    Upgrade your Plan
                                </UiText>
                            </Link>
                        </UiText>
                    )}
                </div>
            </div>
        </div>
    )
}
