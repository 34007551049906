import { useEffect } from 'react';
import { useFormikContext } from 'formik';

interface Form {
    onformChange: (values: any, formikObject: any) => void;
}
export function FormObserver(props: Form) {
    const { values } = useFormikContext();
    const formikObject = useFormikContext();
    const { onformChange } = props;
    useEffect(() => {
        onformChange(values, formikObject);
    }, [values]);
    return null;
}
