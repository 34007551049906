import { Switch, Route, useRouteMatch } from 'react-router-dom'
import {
    Button,
    Hidden,
    Container,
    makeStyles,
    Theme,
    Grid,
    Divider,
    Box,
    Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MenuDropdown from '../../common/MenuDropdown'
import SearchBox from '../../common/SearchBox'
import MobileHeader from '../../common/MobileHeader'
// components
import ContactList from './ContactList'
import ContactPreview from './preview/ContactPreview'
import ContactModal from '../contacts/modal/ContactModal'
import ConfirmContactModal from '../contacts/modal/ConfirmContactModal'
// api
import useContactsHook from './contacts.hook'
import { commonStyles, mergeStyle  } from '../../../styles/commonStyles';

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
        },
        listContent: {
            paddingBottom: theme.spacing(3),
            width: 'inherit',
            height: '100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up(1601)]: {
                justifyContent: 'flex-start',
            },
        },
    }
})


function Contacts() {
    const classes = useStyles()
    let { path } = useRouteMatch()
    
    const {
        dropdownOptions,
        openModal,
        setOpenModal,
        showConfirmModal,
        searchTerm,
        setSearchTerm,
        contactType,
        setContactType,
        selectedContact,
        setSelectedContact,
        setContactUpdated,
        contactsData,
        formikRef,
        handleContactModalClose,
        handleConfirmModal,
        loadMore,
        loadContacts
    } = useContactsHook();

    const { loading, items, hasMore } = contactsData
    const common = commonStyles()

    return (
        <Container className={classes.container} maxWidth="lg">
            <ConfirmContactModal
                showConfirmModal={showConfirmModal}
                contact={undefined}
                handleClose={handleConfirmModal}
            />
            <ContactModal
                open={openModal}
                handleClose={handleContactModalClose}
                loadContacts={() => loadContacts({ text: searchTerm })}
                formikRef={formikRef}
            />
            <MobileHeader title={'Contacts'} showBottomDivider={true} />
            <Grid container spacing={1}>
                <Grid
                    container
                    item
                    md={5}
                    sm={12}
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.content}
                >
                    <div className={classes.listContent}>
                        <Box my={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => setOpenModal(true)}
                            >
                                New Contact
                            </Button>
                        </Box>
                        <Box my={2}>
                            <SearchBox
                                searchTerm={searchTerm}
                                setInput={setSearchTerm}
                                placeHolder={'Search'}
                                notched={false}
                                fullWidth={true}
                                clearSearch={() => {
                                    setSearchTerm('')
                                    loadContacts()
                                }}
                                onKeyPressEvent={(event) => {
                                    if (event.key === 'Enter') {
                                        loadContacts({ text: searchTerm })
                                    }
                                }}
                            />
                        </Box>
                        {!loading && (
                            <div className={mergeStyle(common.mt16, common.mb16)}>
                                <MenuDropdown
                                    options={dropdownOptions}
                                    selectedIndex={contactType}
                                    setSelectedIndex={setContactType}
                                    disabled={loading}
                                />
                            </div>
                        )}
                        <ContactList
                            loading={loading}
                            data={items}
                            loadMore={loadMore}
                            hasMore={hasMore}
                            selectedContact={selectedContact}
                            setSelectedContact={setSelectedContact}
                        />
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid
                        sm={1}
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                    >
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={12} md={6} justify="center">
                        <Switch>
                            <Route exact path={path}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="subtitle1">
                                        Select a contact to view the details
                                    </Typography>
                                </Grid>
                            </Route>
                            <Route path={`/contacts/:id`} exact>
                                <ContactPreview
                                    selectedContact={selectedContact}
                                    setSelectedContact={setSelectedContact}
                                    loadContacts={() =>
                                        loadContacts({ text: searchTerm })
                                    }
                                    setContactUpdated={setContactUpdated}
                                />  
                            </Route>
                        </Switch>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    )
}

export default Contacts