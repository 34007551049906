import React, { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core'
import { Formik, FormikHelpers } from 'formik'
import { connect, } from 'react-redux'
import * as Yup from 'yup'
import { ApplicationStore, Business } from '../../../../../models'
import UiFormControlSelection from '../../../../common/ui/UiFormControlSelection'
import Loader from '../../../../common/Loader'
import { formSubmitErrorHandler } from '../../../../../services/formService'
import {
    getCurrentNewtekMerchantUrl,
    getNewtekMerchantUrl,
    getBusinessDetails
} from '../../../../../services/apiService/business'
import { mergeStyle } from '../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    fieldSpacing: {
        marginBottom: theme.spacing(4),
    },
    blockHeight: {
        height: '350px',
        width: '100%',
    },
    contentGap: {
        marginTop: '32px',
    },
    checkBoxMargin: {
        marginTop: '16px',
    },
    alertIconColor: {
        color: 'black !important',
    },
    alertBackground: {
        backgroundColor: (colorTheme) => colorTheme.grey1500
    },
    paymentButton: {
        marginBottom: '48px',
    },
    loading: {
        height: '400px'
    }
}))

const NewtekMerchantAccountForm = ({
    config,
    accountId,
    businessId,
    handleClose,
    handleGetEmbedUrl
}: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const [loading, setLoading] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [business, setBusiness] = useState<Business>()

    useEffect(() => {
        getApplicationUrl();
    }, []);

    useEffect(() => {
        if (businessId) {
            setLoading(true)
            getBusinessDetails(accountId, businessId).then((response: any) => {
                if (response) {
                    setLoading(false)
                    setBusiness(response)
                }
            })
        }
    }, [accountId, businessId])

    const getApplicationUrl = () => {
        setLoading(true);
        getCurrentMerchantUrl()
        .then((res: any) => {
            if (res.code === 200) {
                handleGetEmbedUrl(res.redirect_url)
                handleClose()
            }
            if (res.code === 204) {
                setOpenForm(true)
            }
            setLoading(false);
        })
        .catch((err) => {
            handleClose()
        });
    };

    const getCurrentMerchantUrl = () => {
        return getCurrentNewtekMerchantUrl(accountId, businessId);
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required')
    })

    const handleFormSubmit = (data: any, form: FormikHelpers<any>) => {
        let formValues = {
            ...data,
            contact_first: business?.business_owners?.[0]
                ? business?.business_owners?.[0]?.first_name
                : '',
            contact_last: business?.business_owners?.[0]
                ? business?.business_owners?.[0]?.last_name
                : '',
            organization_legal_name: business ? business?.name : '',
            organization_state: business?.address?.state
                ? business?.address?.state
                : config.apiConfig.generic?.states?.[0]?.id,
            organization_zip: business?.address?.zip_code ? business?.address?.zip_code : '',
            organization_phone: business?.business_phone ? business?.business_phone : ''
        }
        setLoading(true);
        formSubmitErrorHandler(
            getNewtekMerchantUrl(accountId, businessId, formValues).then(
                (res: any) => {
                    if (res.code === 200) {
                        form.setSubmitting(false)
                        handleGetEmbedUrl(res.redirect_url)
                        handleClose()
                    }
                    setLoading(false);
                }
            ),
            () => {
                form.setSubmitting(false);
                setLoading(false);
            },
            form.setFieldError
        )
    }

    let initialValues = {};

    return (
        <>
        {
            loading && (
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={mergeStyle(
                            styles.loading
                        )}
                    >
                        <Loader />
                    </Grid>
            )
        }
        {
            openForm && (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                    validateOnMount={true}
                >
                    {({
                        submitForm,
                        setFieldValue
                    }) => {
                        return (
                            <div id="step-1">
                                <Typography variant="caption">
                                Please provide an email, each business email needs to be unique
                                </Typography>
                                <br />
                                <br />
                                <Grid container spacing={1}>
                                <UiFormControlSelection
                                    label={'Email'}
                                    type="text"
                                    fieldName={'email'}
                                    required={true}
                                    errorMessage={'Required'}
                                    showFloatingLabel={true}
                                    onKeyUp={(e: React.ChangeEvent <HTMLTextAreaElement | HTMLInputElement>) => {
                                        if (e?.target?.value) {
                                            setFieldValue('email', e?.target?.value?.toLowerCase())
                                        }
                                    }}
                                />
                                </Grid>
                                <br />
                                <Button
                                        className={mergeStyle(
                                            styles.checkBoxMargin,
                                            styles.paymentButton
                                        )}
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '100%' }}
                                        onClick={() => {
                                            submitForm()
                                        }}
                                    >
                                        Next
                                    </Button>
                            </div>
                        )
                    }}
                </Formik>
            )
        }
        </>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    config: state.config,
    accountId: state.appData.current_account_id,
    businessId: state.appData.current_business_id,
})

export default connect(mapStateToProps)(NewtekMerchantAccountForm)
