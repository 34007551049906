import { useHistory } from 'react-router-dom'
import { Button, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import UiText from '../../../common/ui/UiText'
import defaultLogo from '../../../../assets/logo/Logo.svg'
import MenuBar from '../../../dashboard/business-onboarding/MenuBar'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { logout } from '../../../../services/authService'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    navbar: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingRight: '0.5rem',
        paddingLeft: '3rem',
        boxSizing: 'border-box',
        marginBottom: '1rem',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0',
            padding: '0.75rem 1rem 0.5rem 1rem',
            borderBottom: (colorTheme) =>`1px solid ${colorTheme.grey200}`,
            '& button': {
                color: (colorTheme) => colorTheme.blue200,
            }
        },
    },
    logoRoot: {
        height: '4rem',
        marginTop: '1rem',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            height: 'fit-content',
            marginTop: '0',
            '& img': {
                height: '2rem',
            }
        },
    },
}))

const Navbar = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    const history = useHistory()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    return (
        <div className={classes.navbar}>
            <div onClick={() => history.push('/')} className={classes.logoRoot}>
                <img src={defaultLogo} alt="1800-Accountant logo" height="40" />
            </div>
            {smDevice ? (
                <div>
                    <Button onClick={() => logout()} variant="text">
                        <UiText variant="motorcycle_90" weight="semi_bold_600">
                            Sign Out
                        </UiText>
                    </Button>
                </div>
            ) : (
                <div className="menu">
                    <MenuBar />
                </div>
            )}
        </div>
    )
}

export default Navbar
