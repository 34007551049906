import { useCallback, useEffect, useState } from 'react';
import { Business, BusinessOwner } from '../../../models'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'

const MISSING_BUSINESS_FIELD_NAMES = {
    BUSINESS_NAME: 'name',
    BUSINESS_TYPE: 'business_type',
    BUSINESS_INDUSTRY: 'industry',
    FORMATION_STATE: 'form_state',
    BUSINESS_OWNERS: 'business_owners',
}
const BUSINESS_OWNER_FIELD_NAMES = {
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    OWNERSHIP: 'ownership',
}
const BUSINESS_TYPES = {
    C_CORP : 'C',
    S_CORP : 'S'
}
const BUSINESS_OWNER_FIELDS = [
    BUSINESS_OWNER_FIELD_NAMES.FIRST_NAME,
    BUSINESS_OWNER_FIELD_NAMES.LAST_NAME,
    BUSINESS_OWNER_FIELD_NAMES.OWNERSHIP,
]
const BUSINESS_FIELDS_TO_CHECK = [
    MISSING_BUSINESS_FIELD_NAMES.BUSINESS_NAME,
    MISSING_BUSINESS_FIELD_NAMES.BUSINESS_TYPE,
    MISSING_BUSINESS_FIELD_NAMES.BUSINESS_INDUSTRY,
    MISSING_BUSINESS_FIELD_NAMES.FORMATION_STATE,
    MISSING_BUSINESS_FIELD_NAMES.BUSINESS_OWNERS,
]
const EXCLUDE_OWNERSHIP_FOR_BUSINESS = [BUSINESS_TYPES.C_CORP, BUSINESS_TYPES.S_CORP];

export const useCollectBusinessInfo = () => {
    const store = useCurrentStore()
    const [missingBusinessFields, setMissingBusinessFields] =
        useState<string[]>()

    
    const getEmptyPrimaryOwnerFields = (primaryOwnerData : BusinessOwner, businessDetails: Business) =>{
        const emptyFields = [];
        const {first_name, last_name, ownership} = primaryOwnerData;
        if(!first_name){
            emptyFields.push(BUSINESS_OWNER_FIELD_NAMES.FIRST_NAME);
        }
        if(!last_name){
            emptyFields.push(BUSINESS_OWNER_FIELD_NAMES.LAST_NAME);
        }
        if(businessDetails.business_type && !ownership && !EXCLUDE_OWNERSHIP_FOR_BUSINESS.includes(businessDetails.business_type)){
            emptyFields.push(BUSINESS_OWNER_FIELD_NAMES.OWNERSHIP);
        }
        return emptyFields;
    }
    const checkIfOwnerDetailsIsEmpty = (value: BusinessOwner[], businessDetails: any) => {
        if (Array.isArray(value) && value.length === 0) {
            return BUSINESS_OWNER_FIELDS;
        }
        const primaryOwnerData : BusinessOwner = value[0];
        const missingFields = getEmptyPrimaryOwnerFields(primaryOwnerData, businessDetails);
        return missingFields;
    }
    const getMissingBusinessFields = useCallback(
        (businessDetails: any, fieldName: string,) => {
                const value = (businessDetails as any)[fieldName]
                if (
                    fieldName === MISSING_BUSINESS_FIELD_NAMES.BUSINESS_OWNERS
                ) {
                    const fields = checkIfOwnerDetailsIsEmpty(value, businessDetails);
                    return fields;
                }
                if (!value) {
                    return fieldName;
                }
        },
        []
    )
   
    useEffect(() => {
        const isBusinessIdPresent = store.currentBusinessId
        if(!isBusinessIdPresent) return; 
        const businessDetails = store.currentBusiness; 
        if (!businessDetails) return;
        let missingFields: string[] = []
        BUSINESS_FIELDS_TO_CHECK.forEach((field: string) => {
            const missingField = getMissingBusinessFields(
                businessDetails,
                field,
            )
            if (missingField && !Array.isArray(missingField)) {
                missingFields.push(missingField);
            }else if(missingField && missingField.length >0 ){
                 const values = missingFields.concat(missingField);
                 missingFields = [];
                 missingFields = values;
            }
        })
        setMissingBusinessFields(missingFields)
    }, [
        getMissingBusinessFields,
        store.currentBusiness,
        store.currentBusinessId,
    ])
    return { missingBusinessFields }
}
