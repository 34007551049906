import { Button, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import useDeviceSize from '../../../hooks/useDeviceSize';
import { toRem16 } from '../../../styles/commonStyles';
import Icon from '../../common/Icon';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme)=> {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            border: colorTheme => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(8),
            margin: `1rem 0 1rem 0`,
        },
        buttonStyle: {
            margin: `${toRem16(8)} 0 ${toRem16(8)} 0`, 
            color: colorTheme => colorTheme.blue200,
            paddingLeft: '3%'
        },
        divStyle: {
            display: 'grid',
            gridTemplateColumns: `1fr ${toRem16(240)}`,
        },
        divGap: {
            marginTop: toRem16(8),
            paddingLeft: '2%'
        },
        imageStyle: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            borderRadius: '0px 0.5rem 0.5rem 0px;',
            [theme.breakpoints.down('sm')]: {
                borderRadius: '0.5rem 0.5rem 0px 0px;'
            },
        },
    }
})


const StyledInfoCard = ({
    rightImage,
    dismissAction,
    content
}: {
    rightImage: any,
    dismissAction: ()=> void,
    content: React.ReactNode
}) => {
    const  { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()
    return (
        <div className={styles.container}>
            <div className={isMobileDevice ? '' : styles.divStyle}>
                {isMobileDevice && <img src={rightImage} alt="imageCard"  className={styles.imageStyle}/>}
                <div className={styles.divGap}>
                    <div className={styles.divGap}>
                        {content}
                    </div>
                    <Button 
                        startIcon={<Icon icon='clock' strokeColor={colorTheme.blue200}/>} 
                        variant="text"
                        className={styles.buttonStyle}
                        onClick={dismissAction}
                        >
                        Dismiss
                    </Button>
                </div>
                {!isMobileDevice && <img src={rightImage} alt="imageCard"  className={styles.imageStyle}/>}
            </div>
        </div>
    )
}

export default StyledInfoCard