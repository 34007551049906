import { Theme, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { connect } from 'react-redux'
import UiDialog from '../../common/ui/UiDialog'
import ReconciliationForm from './ReconciliationForm'
import { ApplicationStore } from '../../../models'
interface ReconciliationModalProps {
    open: boolean
    isNew: boolean
    handleClose: (result?: any) => void
    reconciliation: any
    category: any
    accountantMode: boolean
}

function ReconciliationModal({
    open,
    isNew,
    handleClose,
    category,
    reconciliation,
    accountantMode,
}: ReconciliationModalProps) {
    const useStyles = makeStyles((theme: Theme) => ({
        container: {
            '& .MuiPaper-root': {
                height: 816,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    margin: '0px',
                    top: accountantMode ? '40px' : '0px',
                    '& .MuiDialogTitle-root': {
                        display: 'none',
                    },
                },
                '& .MuiDialogContent-root': {
                    [theme.breakpoints.down('sm')]: {
                        padding: '20px 20px',
                        marginTop: '60px',
                    },
                    '& .top-section': {
                        '& .alert': {
                            height: '36px',
                            width: 'inherit',
                            marginBottom: theme.spacing(2),
                        },
                        '& .delete-section': {
                            display: 'flex',
                        },
                        [theme.breakpoints.down('sm')]: {
                            height: '100%',
                            maxHeight: '100%',
                            padding: '0px',
                        },
                    },
                },
            },
        },
    }))
    const styles = useStyles()
    const theme = useTheme()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={isNew ? 'New Reconciliation' : 'Edit Reconciliation'}
                size="sm"
                customRootClass={isXsDown ? styles.container : {}}
            >
                <ReconciliationForm
                    reconciliation={isNew ? category : reconciliation}
                    handleClose={handleClose}
                    isNew={isNew}
                    category={isNew ? [] : category}
                />
            </UiDialog>
        </>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    accountantMode: state.appData.user.accountant_mode,
})

export default connect(mapStateToProps)(ReconciliationModal)
