import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { currencyFormatter } from '../../../../utils/appUtil'
import UiText from '../../../common/ui/UiText'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'

type TaxPriceProps = {
    type?: string
    amount: string | number | null
}

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    positiveIndicator: {
        color: (colorTheme) => colorTheme.black100,
    },
    negativeIndicator: {
        color: (colorTheme) => colorTheme.red200,
    },
}))

const TaxPrice = ({ type, amount }: TaxPriceProps) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    if (amount === null || amount === undefined) return null
    let isNegative
    if (!type) {
        isNegative = amount < 0
        amount = Number(amount.toString().replace('-', ''))
    } else {
        isNegative = type === 'Owes'
    }

    return (
        <UiText
            className={
                isNegative
                    ? classes.negativeIndicator
                    : classes.positiveIndicator
            }
            weight="semi_bold_600"
        >
            {type !== 'NA' && (!isNegative && amount !== 0 ? '+' : '')}

            {type === 'NA' ? 'N/A' : currencyFormatter.format(Number(amount))}
        </UiText>
    )
}

export default TaxPrice
