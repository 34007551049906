import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePlaidLink } from 'react-plaid-link'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { QUERY_SUBSTRING } from '../../bookkeeping/banking/constants/banking.const'
import { REDIRECT_URL } from '../../payroll/banking-info/constants/banking-info.const'
import { fetchTOSDetails, getPayrollBusiness, updateProcessorToken } from '../../../services/apiService/adp-payroll/company'
import { exchangeToken, fetchUpdateToken, getBankAccountForADP, getLinkToken } from '../../../services/apiService'
import { ERROR_MESSAGES, PAGE_TYPE, PLAID_STATUS } from '../Payroll/payroll-migration/constants/payroll-migration.const'

export const BankingInfoContext = React.createContext(null)

export function usePayrollMigrationState(): any {
    const state = useContext(BankingInfoContext)
    return state
}

const payrollMigrationStatus = true;

export const PayrollMigrationProvider = ({ children }: any) => {
    const [linkToken, setLinkToken] = useState<any>(null)
    const [loadToken, setLoadToken] = useState<any>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [bankInfo, setBankInfo] = useState<any>(null)
    const [page, setPage] = useState<string>("")
    const [publicToken, setPublicToken] = useState<string | null>(null) 
    const [updateLinkToken, setUpdateLinkToken] = useState<any>(null)
    const [updateMode, setUpdateMode] = useState<boolean>(false)
    const [showReconnectPage, setShowReconnectPage] = useState<boolean>(false) 
    const [metadata, setMetaData] = useState<any>(null)
    const [isError, setIsError] = useState<boolean>(false)
    const [loadingCompanyDetails ,setLoadingCompanyDetails] = useState<boolean>(false)
    const [submittingCompanyData, setSubmittingCompanyData] = useState<boolean>(false)
    const [businessList, setBusinessList] = useState<any[]>([])
    const [isBusinessListLoading, setIsBusinessListLoading] =
        useState<boolean>(false)
    const [companyDetails, setCompanyDetails] = useState<any>(null)
    const [processorToken, setProcessorToken] = useState<any>(false)
    const [disableBankConnectButton, setDisableBankConnectButton] = useState<boolean>(false)
    const [disableBankVerifyButton, setDisableBankVerifyButton] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<any>(null)
    const [dialog, setDialog] = useState<boolean>(false)
    const [accountEditStatus, setAccountEditStatus] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { currentBusinessId, currentBusiness } = useCurrentStore()
    const [isAgreementAccepted, setIsAgreementAccepted] = useState<boolean>(false)
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=')
    const history = useHistory()

    const onSuccess = (public_token: string | null, metadata: any) => {
         setPublicToken(public_token)
         setMetaData(metadata)
    }

    const resetState = () => {
        setPublicToken(null)
        setMetaData(null) 
    }

    const resetOnRender = () => {
        setUpdateLinkToken(null)
        setLinkToken(null)
        setBankInfo(null)
        setProcessorToken(false)
    }

    const config: any = {
        token: linkToken,
        onSuccess,
    }

    if (isOAuthRedirect) {
        const baseUrl = window.location.origin
        const url = window.location.href
        const queryString = url.split('?')[1]
        const redirectURL = url.includes('localhost')
            ? `${REDIRECT_URL.UAT}/${QUERY_SUBSTRING}${queryString}`
            : `${baseUrl}/${QUERY_SUBSTRING}${queryString}`
        config.receivedRedirectUri = redirectURL
    }

    const { open: openUpdateMode, ready: updateTokenReady } = usePlaidLink({
        token: updateLinkToken,
        onSuccess: async (publicToken, metaData) => {
            try {
                setLoadToken(true)
                await fetchBankInfo()
            } catch (err) {
                errorHandler(ERROR_MESSAGES.GET_BANK_DETAILS_ERROR)
            }
        },
    })

    const { open: openPlaidLink, ready } = usePlaidLink({
        ...config,
    })

    const renderPage = () => {
        try {
            const pageMapping = {
                pending_manual_verification: PAGE_TYPE.VERIFICATION_PAGE,
                default: PAGE_TYPE.INITIAL_PAGE,
            }

            const shouldShowInitialPage =
               (showReconnectPage ||
                !isAgreementAccepted ||
                !bankInfo ||
                Object.keys(bankInfo).length === 0) && !loadingCompanyDetails

            if (shouldShowInitialPage) {
                setPage(PAGE_TYPE.INITIAL_PAGE)
                return
            }
            
            if(processorToken) {
                setPage(PAGE_TYPE.SUCCESS_PAGE)
                return
            }

            //@ts-ignore
            const pageType = pageMapping[bankInfo?.verification_status] || PAGE_TYPE.INITIAL_PAGE
            setPage(pageType)
        } catch (error) {
            setPage(PAGE_TYPE.INITIAL_PAGE)
        } finally {
            setLoader(false)
        }
    }

    const fetchLinkToken = () => {
        const token = localStorage.getItem('linkToken')
        if (token && isOAuthRedirect) {
            setLinkToken(token)
            return
        }
        setLoadToken(true)
        currentBusinessId &&
            getLinkToken(currentBusinessId, payrollMigrationStatus)
                .then((res) => {
                    setLinkToken(res.link_token)
                    localStorage.setItem('linkToken', res.link_token)
                })
                .catch((err) => {
                    errorHandler('Failed to fetch link token, please try again')
                    setDisableBankConnectButton(true)
                })
                .finally(() => {
                    setLoadToken(false)
                })
    }

    const fetchUpdateModeToken = async () => {
        setLoadToken(true)
        if (currentBusinessId) {
            try {
                const res = await fetchUpdateToken(currentBusinessId)
                setUpdateLinkToken(res.link_token)
                await fetchLinkToken(); 
            } catch (err) {
                setDisableBankVerifyButton(true)
            } finally {
                setLoadToken(false)
            }
        }
    }

    const checkValidationAndSetBankInfo = (res: any) => {
        setLoader(true)
        const { item_id, verification_status, status } = res || {};
    
        const isItemIdInvalid = !item_id || item_id === '';
        const isVerificationStatusInvalid = verification_status !== 'pending_manual_verification' && verification_status !== 'automatically_verified' && verification_status !== 'manually_verified';
        const isStatusInvalid = status !== 'authorized';
    
        if (isItemIdInvalid || isVerificationStatusInvalid || isStatusInvalid) {
            setShowReconnectPage(true);
        }else {
            setShowReconnectPage(false);
        }
    
        setBankInfo(res);
    }

    const fetchBankInfo = async () => {
        setLoadToken(true)
        if (currentBusinessId) {
            try {
                const res = await getBankAccountForADP(currentBusinessId)
               
                checkValidationAndSetBankInfo(res)
            } catch (err: any) {
                if (
                    PLAID_STATUS.includes(err?.errorStatusCode) ||
                    err?.status === 401 ||
                    err?.statusText.includes(ERROR_MESSAGES.ITEM_ERROR) ||
                    err?.statusText.includes(ERROR_MESSAGES.ITEM_LOGIN_REQUIRED)
                ) {
                    errorHandler(ERROR_MESSAGES.BANK_RECONNECT_REQUIRED)
                    setUpdateMode(true)
                } else {
                    errorHandler(ERROR_MESSAGES.GET_BANK_DETAILS_ERROR)
                }
                setShowReconnectPage(true);
            } finally {
                setLoadToken(false)
            }
        }
    }

    const finishOnboarding = async () => {
        setLoadToken(true)
        try {
            if(bankInfo?.verification_status === 'manually_verified' || bankInfo?.verification_status === 'automatically_verified') {
                await updateProcessorToken(currentBusinessId)
                setProcessorToken(true)
                await fetchPayrollBusiness()
            }
           
        } catch (error) {
            errorHandler(ERROR_MESSAGES.FINISH_ONBOARDING_ERROR)
        } finally {
            resetState()
        }
    }

    const fetchPayrollBusiness = async () => {
        setIsBusinessListLoading(false)
        try {
            const response = await getPayrollBusiness()
            //@ts-ignore
            const businessList = response?.payroll_businesses || []
            if (businessList.length === 0 && !PAGE_TYPE.SUCCESS_PAGE) {
                history.push('/dashboard')
                return
            }

            const businessAccounts = businessList.filter(
                (account: any) => !account.is_plaid_bank_account
            )
            if (!PAGE_TYPE.SUCCESS_PAGE && businessAccounts.length === 0) {
                history.push(`/dashboard`)
                return
            }

            if (!PAGE_TYPE.SUCCESS_PAGE && businessAccounts.length === 1) {
                history.push(`/payroll-migration`)
                return
            }
            setBusinessList(businessAccounts)
        } catch (error) {
            errorHandler(ERROR_MESSAGES.FETCH_BUSINESS_LIST_FAIL)
            setTimeout(() => {
                history.push('/dashboard')
            }, 7000)
        } finally {
            setLoadToken(false)
            setIsBusinessListLoading(false)
        }
    }

    const fetchCompanyDetails = async () => {
        setLoadingCompanyDetails(true);
        try {
            if (currentBusinessId) {
                const res: any = await fetchTOSDetails(currentBusinessId);
                if (res?.is_tos_flow === false) {
                    history.push('/dashboard')
                }
                if(res?.is_processor_token_linked === true) {
                    setProcessorToken(true)
                }else {
                    setProcessorToken(false)
                }
                setCompanyDetails(res);
                if (res?.legal_confirmation_details?.is_accepted === true) {
                    setIsAgreementAccepted(true);
                }else {
                    setIsAgreementAccepted(false);
                }
            }          
        } catch (err) {
            errorHandler(ERROR_MESSAGES.FETCH_COMPANY_DETAILS_FAIL);
            setDisableBankConnectButton(true);
        } finally {
            setLoadingCompanyDetails(false);
        }
    };


    useEffect(() => {
        resetState()
        resetOnRender()
        fetchLinkToken()
        fetchBankInfo()
    }, [currentBusinessId])

    useEffect(() => {
        if (isOAuthRedirect && ready) {
            openPlaidLink()
        }
    }, [ready, openPlaidLink, isOAuthRedirect])

    useEffect(() => {
        const exchangeTokenAndSetBankInfo = async () => {
            if (publicToken && metadata?.account_id) {
                setLoadToken(true)
                const payload = {
                    business_id: currentBusinessId,
                    meta_data: metadata,
                }
                try {
                    const res = await exchangeToken(payload)
                    setProcessorToken(false)
                    if (
                        bankInfo &&
                        (res?.verification_status === 'manually_verified' ||
                            res?.verification_status ===
                                'automatically_verified')
                    ) {
                        finishOnboarding()
                    }
                    checkValidationAndSetBankInfo(res)
                    localStorage.removeItem('linkToken')
                } finally {
                    setLoadToken(false)
                }
            }
        }
        exchangeTokenAndSetBankInfo()
    }, [publicToken, metadata, currentBusinessId])

    useEffect(() => {
        fetchPayrollBusiness()
    }, [])

    useEffect(() => {
        if (
            bankInfo && !processorToken && isAgreementAccepted &&
            (bankInfo?.verification_status === 'manually_verified' ||
                bankInfo?.verification_status === 'automatically_verified')
        ) {
            finishOnboarding()
        }
    }, [bankInfo, isAgreementAccepted, currentBusinessId, processorToken])

    useEffect(() => {
        fetchCompanyDetails()
    }, [currentBusinessId])

    useEffect(() => {
        renderPage()
    }, [companyDetails, bankInfo, isAgreementAccepted, processorToken])
   
    const errorHandler = (err: any) => {
        if(typeof err === 'string') {
            setErrorMsg(err) 
        }else {
            setErrorMsg(err?.statusText || ERROR_MESSAGES.GENERIC_ERROR)
        }
        setIsError(true)
    }

    const providerValue: any = {
        linkToken,
        setLoadToken,
        bankInfo,
        setBankInfo,
        publicToken,
        setPublicToken,
        alert,
        metadata,
        setMetaData,
        isError,
        loadToken,
        dialog,
        setDialog,
        isModalOpen,
        setIsModalOpen,
        isOAuthRedirect,
        openPlaidLink,
        page,
        setPage,
        accountEditStatus,
        setAccountEditStatus,
        errorMsg,
        setErrorMsg,
        setIsError,
        disableBankConnectButton,
        errorHandler,
        setDisableBankConnectButton,
        companyDetails,
        isAgreementAccepted,
        loadingCompanyDetails,
        submittingCompanyData,
        setSubmittingCompanyData,
        fetchUpdateModeToken,
        openUpdateMode,
        updateTokenReady,
        disableBankVerifyButton,
        setIsAgreementAccepted,
        renderPage,
        updateMode,
        showReconnectPage,
        businessList,
        isBusinessListLoading,
        loader,
        fetchPayrollBusiness
    }

    return (
        <BankingInfoContext.Provider value={providerValue}>
            {children}
        </BankingInfoContext.Provider>
    )
}
