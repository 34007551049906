import React from 'react';

const CurrencyText: React.FC<{
    value: number;
    currencySign?: string;
    showPlus?: boolean;
}> = ({ value, currencySign = '$', showPlus = true }) => {

    const isNegative = value < 0;

    return (<span>{isNegative ? `-$${value * -1}` : `${showPlus ? '+$' : '$'}${value}`}</span>);
};
export default CurrencyText;
