import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';
import { useThemeContext } from './whiteLabel/ColorThemeContext';

export default function LinkButton(props: ButtonProps) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles({
        linkBtn: {
            color: colorTheme.blue200,
            fontSize: 16,
            padding: '0 0px',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    })();
    return (
        <Button
            variant='text'
            disableRipple
            classes={{ root: styles.linkBtn }}
            {...props}
        >
            {props.children}
        </Button>
    );
}
