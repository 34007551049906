import { useState } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Theme, Chip } from "@material-ui/core";
import {
    ArrowDropDown,
    ArrowRight,
    Folder as FolderIcon,
    FolderOpen,
} from "@material-ui/icons";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/styles";
import {
    Folder,
    FolderTree,
    UploadedFile,
    PaginatedResponse,
} from "../../models";
import DocumentList from "./DocumentList";
import { setInLocalStorage } from "../../services/localStorageService";
import PermanentDocumentList from "../entity-management/documents-tab/PermanentDocumentList";
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

export default function FoldersAndListView({
    folder,
    hideEmptyFolders,
    setUploadableFolderTree,
    files,
    folderView,
    handleSelectFile,
    accountId,
    businessFolderTree,
    personalFolderTree,
    isEntityManagementTab,
    folderQuery,
}: {
    folder: FolderTree | undefined;
    hideEmptyFolders: boolean;
    files: PaginatedResponse<UploadedFile> | undefined;
    handleSelectFile: (file: UploadedFile) => void;
    folderView: boolean;
    accountId: string;
    businessFolderTree: FolderTree | undefined;
    personalFolderTree: FolderTree | undefined;
    setUploadableFolderTree: React.Dispatch<FolderTree | undefined>;
    isEntityManagementTab?: boolean;
    folderQuery?: string | null;
}) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles((theme: Theme) => ({
        treeIconContainer: {
            width: 48,
            color: colorTheme.grey1700,
            "& svg": {
                fontSize: 24,
            },
        },
        treeItemContent: {
            height: 40,
        },
        treeItemLabel: {
            background: "transparent !important",
            "&:hover": {
                background: "transparent",
            },
        },
        notification: {
            height: "20px !important",
            color: `${colorTheme.primaryWhite} !important`,
            backgroundColor: theme.palette.primary.main + " !important",
            fontWeight: "bold",
            cursor: "pointer !important",
        },
        notificationLabel: {
            padding: "3px 10px !important",
        },
    }))();

    const [businessExpanded, setBusinessExpanded] = useState<string[]>([]);
    const history = useHistory();

    const handleOpenFolder = (folder: Folder & FolderTree) => {
        setInLocalStorage("selectedFolder", {
            path: folder.path,
            displayPath: folder.pathToDisplay,
        });
        setUploadableFolderTree(folder.is_uploadable ? folder : undefined);
        history.push(
            `?folder=${encodeURIComponent(folder.path)}&accountId=${
                folder.portal_account_id
            }`
        );
    };

    const getTreeItem = (folder: Folder & FolderTree, expanded: string[]) => {
        const treeItem = (
            <TreeItem
                classes={{
                    iconContainer: styles.treeIconContainer,
                    content: styles.treeItemContent,
                    label: styles.treeItemLabel,
                }}
                label={
                    <Box
                        display="flex"
                        alignItems="center"
                        onClick={() =>
                            (folder.is_uploadable || !folder.folders) &&
                            handleOpenFolder(folder)
                        }
                    >
                        <Typography variant="body2">{folder.title}</Typography>
                        {!expanded.includes(folder.path) && (
                            <Box mx={1}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    ({folder.files_count})
                                </Typography>
                            </Box>
                        )}

                        {!expanded.includes(folder.path) &&
                        folder.unread_files_count ? (
                            <Chip
                                label={folder.unread_files_count}
                                size="small"
                                classes={{
                                    root: styles.notification,
                                    labelSmall: styles.notificationLabel,
                                }}
                            />
                        ) : null}
                    </Box>
                }
                nodeId={folder.path}
                key={folder.path}
                icon={<FolderIcon color="inherit" />}
            ></TreeItem>
        );
        return hideEmptyFolders ? !!folder.files_count && treeItem : treeItem;
    };

    return (
        <div>
            <TreeView
                onNodeToggle={(e: any, nodes) => setBusinessExpanded(nodes)}
                defaultCollapseIcon={
                    <Box
                        display="flex"
                        alignItems="center"
                        color={colorTheme.grey1700}
                    >
                        <ArrowDropDown color="inherit" fontSize="large" />
                        <FolderOpen color="inherit" fontSize="large" />
                    </Box>
                }
                defaultExpandIcon={
                    <Box
                        display="flex"
                        alignItems="center"
                        color={colorTheme.grey1800}
                    >
                        <ArrowRight color="inherit" fontSize="large" />
                        <FolderIcon color="inherit" fontSize="large" />
                    </Box>
                }
            >
                {folder?.folders &&
                    Object.keys(folder.folders).map(
                        (key) =>
                            folder.folders &&
                            getTreeItem(folder.folders[key], businessExpanded)
                    )}
            </TreeView>
            {isEntityManagementTab ? (
                <PermanentDocumentList
                    files={files}
                    handleSelectFile={handleSelectFile}
                    folderView={folderQuery ? true : false}
                    accountId={accountId}
                />
            ) : (
                <DocumentList
                    files={files}
                    handleSelectFile={handleSelectFile}
                    folderView={true}
                    accountId={accountId}
                    businessFolderTree={businessFolderTree}
                    personalFolderTree={personalFolderTree}
                />
            )}
        </div>
    );
}
