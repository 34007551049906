import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveRoutingContext  } from '../../routing/Providers/ActiveRoutingProvider'

export default function TaxProjections() {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext);
    let query = new URLSearchParams(useLocation().search);

    useEffect(() => {
        setActiveRouteHeading('Tax Projections');
    });

    const iframe = () => {
        return {
            __html: `<iframe src="${window.origin
                }/portal-embed/#/tax-projections?${query || ''
                }" width="100%" height="100%"></iframe>`,
        };
    };
    return (
        <div
            style={{ height: '100%', overflow: 'hidden' }}
            dangerouslySetInnerHTML={iframe() as any}
        ></div>
    );
}
