import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
    Dispatch,
    SetStateAction,
} from 'react'
import { defaultQueryObject } from '../TripUtil'
import { TripQueryObject } from '../../../../models'

interface FiltersContextValue {
    queryObject: TripQueryObject
    setQueryObject: Dispatch<SetStateAction<TripQueryObject>>
}

const FiltersContext = createContext<FiltersContextValue | undefined>(undefined)

export function useFiltersContext(): FiltersContextValue {
    const context = useContext(FiltersContext)
    if (!context) {
        throw new Error(
            'useFiltersContext must be used within a FiltersProvider'
        )
    }
    return context
}

interface FiltersProviderProps {
    children: ReactNode
}

const FiltersProvider: React.FC<FiltersProviderProps> = ({ children }) => {
    const [queryObject, setQueryObject] =
        useState<TripQueryObject>(defaultQueryObject)

    const providerValue: FiltersContextValue = {
        queryObject,
        setQueryObject,
    }

    return (
        <FiltersContext.Provider value={providerValue}>
            {children}
        </FiltersContext.Provider>
    )
}

export default FiltersProvider
