import {
    Box,
    Button,
    Divider,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    makeStyles,
    OutlinedInput,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    getMessages,
    getMessagesCenterTopics,
} from '../../services/apiService';
import { ApplicationStore } from '../../models';
import Loader from '../common/Loader';
import MessageCenterListView from './MessageListView';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import NewMessageModal from './NewMessageModal';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import MessageThread from './MessageThread';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import { showInfo } from '../../store/actions/feedback';
import InfoTooltip from '../../components/common/InfoTooltip'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';
import UiText from '../common/ui/UiText';
import UpgradePlanMessageCenter from './UpgradePlanMessageCenter'
import { useCurrentStore } from '../common/hooks/useCurrentStore'

const perPage = window.innerHeight >= 1100 ? 20 : 10;

function MessageCenter(props: any) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => ({
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up(1601)]: {
                justifyContent: 'flex-start',
            },
        },
        listRoot: {
            maxWidth: '464px',
            paddingBottom: theme.spacing(3),
            width: 'inherit',
            height: '100%',
        },
        newMessageButton: {
            height: '36px',
        },
        refeshButton: {
            fontSize: theme.spacing(3),
        },
        searchInput: {
            marginTop: theme.spacing(3),
            height: '40px',
            width: '100%',
            '&.Mui-focused fieldset': {
                border: `1px solid ${colorTheme.primaryBlack} !important`,
            },
        },
        startButtonDisabled: {
            color: '#00000042 !important', // to do 
            boxShadow: 'none !important',
            backgroundColor: `${colorTheme.grey700} !important`,
            cursor: 'not-allowed !important',
            '&:hover': {
                color: `${colorTheme.primaryWhite} !important`,
                backgroundColor: `${colorTheme.grey700} !important`,
                borderColor: `${colorTheme.green600} !important`,
            },
        },
        dividerStyle: {
            marginTop: theme.spacing(3),
            marginLeft: '-12px',
            marginRight: '-12px',
        },
    }))();

    // common data
    const { setActiveRouteHeading, 
            setActiveRouteHeadingIcon,
            activeRouteHeadingIcon } = useContext(ActiveRoutingContext);
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayWithoutTab, setDisplayWithoutTab] = useState(false);
    const [text, setText] = useState('');
    const [textCached, setTextCached] = useState('');
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [open, setOpen] = useState(false);
    const [emptyState, setEmptyState] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    // combined data
    const [hasMore, setHasMore] = useState(false);
    const [messageData, setMessageData] = useState<any>([]);
    const [currentPage, satCurrentPage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);

    // active data
    const [hasMoreActive, setHasMoreActive] = useState(false);
    const [messageDataActive, setMessageDataActive] = useState<any>([]);
    const [currentPageActive, satCurrentPageActive] = useState(1);

    // archived data
    const [hasMoreArchived, setHasMoreArchived] = useState(false);
    const [messageDataArchived, setMessageDataArchived] = useState<any>([]);
    const [currentPageArchived, satCurrentPageArchived] = useState(1);
    const { accessLevel, currentUser } = useCurrentStore();

    const setActiveState = (res: any, concat: boolean) => {
        const { items, page, pageCount } = res;
        satCurrentPageActive(page);
        setHasMoreActive(page < pageCount);
        setMessageDataActive(concat ? messageDataActive.concat(items) : items);
    };

    const setArchivedState = (res: any, concat: boolean) => {
        const { items, page, pageCount } = res;
        satCurrentPageArchived(page);
        setHasMoreArchived(page < pageCount);
        setMessageDataArchived(
            concat ? messageDataArchived.concat(items) : items
        );
    };

    const getMessagesWithFilter = (
        page: number,
        text?: string,
        archived?: boolean
    ) => {
        return getMessages(props.appData.current_account_id, {
            page,
            perPage,
            text,
            archived,
        });
    };

    useEffect(() => {
        setActiveRouteHeading('Messages');
        setActiveRouteHeadingIcon(
            <InfoTooltip tooltipText="Be advised that our office hours are 9:30 AM to 6:30 PM Eastern Time.
            Accountant and team members will typically respond within 1-2 business days."/>
        );
        Promise.all([
            getMessagesWithFilter(1, undefined, false),
            getMessagesCenterTopics(),
            getMessagesWithFilter(1, undefined, true),
        ])
            .then((res: any) => {
                setTopics(res[1]);
                setActiveState(res[0], false);
                setArchivedState(res[2], false);
                setEmptyState(
                    res[0].items.length === 0 && res[2].items.length === 0
                );
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const setCombinedDataState = (res: any, concat: boolean) => {
        const { items, page, pageCount, itemsCount } = res;
        setTotalItemsCount(itemsCount);
        satCurrentPage(page);
        setHasMore(page < pageCount);
        setMessageData(concat ? messageData.concat(items) : items);
    };

    const getMessagesPage = (archived?: any) => {
        switch (archived) {
            case false:
                getMessagesWithFilter(
                    currentPageActive + 1,
                    undefined,
                    false
                ).then((res: any) => {
                    setActiveState(res, true);
                });
                break;

            case true:
                getMessagesWithFilter(
                    currentPageArchived + 1,
                    undefined,
                    true
                ).then((res: any) => {
                    setArchivedState(res, true);
                });
                break;

            default:
                getMessagesWithFilter(currentPage + 1, text, undefined).then(
                    (res: any) => {
                        setCombinedDataState(res, true);
                    }
                );
                break;
        }
    };

    const getMessagesWithSearchText = () => {
        setLoadingMessages(true);
        setTextCached(text);

        switch (text) {
            case '':
                setDisplayWithoutTab(false);
                Promise.all([
                    getMessagesWithFilter(1, undefined, false),
                    getMessagesWithFilter(1, undefined, true),
                ])
                    .then((res: any) => {
                        setActiveState(res[0], false);
                        setArchivedState(res[1], false);
                        setLoadingMessages(false);
                    })
                    .catch((err) => {
                        setLoadingMessages(false);
                    });
                break;

            default:
                setDisplayWithoutTab(true);
                getMessagesWithFilter(1, text).then((res: any) => {
                    setCombinedDataState(res, false);
                    setLoadingMessages(false);
                });
                break;
        }
    };

    const handleRefreshClick = () => {
        setText(textCached);
        setLoadingMessages(true);
        switch (textCached) {
            case '':
                refreshMessagesWithoutSearch();
                break;
            default:
                setLoadingMessages(true);
                getMessagesWithFilter(1, textCached).then((res: any) => {
                    setCombinedDataState(res, false);
                    setLoadingMessages(false);
                });
                break;
        }
    };

    const refreshMessagesWithoutSearch = () => {
        setLoadingMessages(true);
        Promise.all([
            getMessagesWithFilter(1, undefined, false),
            getMessagesWithFilter(1, undefined, true),
        ])
            .then((res: any) => {
                setActiveState(res[0], false);
                setArchivedState(res[1], false);
                setLoadingMessages(false);
            })
            .catch((err) => {
                setLoadingMessages(false);
            });
    };

    const history = useHistory();
    const handleModalCLose = (result: any) => {
        if (result === 'fail') {
            return;
        } else if (result.id) {
            setTextCached('');
            setText('');
            setDisplayWithoutTab(false);
            setEmptyState(false);
            refreshMessagesWithoutSearch();
            history.push(`/message_center/${result.id}`);
        }
        setOpen(false);
    };

    const handleModalOpen = () => {
        if (props.appData.user.accountant_mode) {
            return;
        }
        if (topics.length) {
            setOpen(true);
        } else {
            props.showInfo({
                infoData: (
                    <Fragment>
                        <Typography variant='body1' gutterBottom>
                            You don't have dedicated Business Consultants
                            assigned.
                        </Typography>
                        <Typography variant='body1'>
                            Please call{' '}
                            <a href='tel:+18002226868'>1-800-222-6868</a> to
                            find out why.
                        </Typography>
                    </Fragment>
                ),
            });
        }
    };

    const selectMessage = (id: any) => {
        setSelectedId(id);
        if (displayWithoutTab) {
            if (messageData.find((m: any) => m.id === id)) {
                let message = messageData.find((m: any) => m.id === id);
                if (message?.unread_messages_count) {
                    message.unread_messages_count = 0;
                    setMessageData(messageData);
                }
            }
        } else {
            let message = messageDataActive.find((m: any) => m.id === id);
            if (message?.unread_messages_count) {
                message.unread_messages_count = 0;
                setMessageDataActive(messageDataActive);
            }

            let messageArchived = messageDataArchived.find(
                (m: any) => m.id === id
            );
            if (messageArchived?.unread_messages_count) {
                messageArchived.unread_messages_count = 0;
                setMessageDataArchived(messageDataArchived);
            }
        }
    };

    const updateMessageList = (message: any) => {
        if (displayWithoutTab) {
            if (messageData.find((m: any) => m.id === message.id)) {
                let data = messageData.filter((m: any) => m.id !== message.id);
                data.splice(0, 0, message);
                setMessageData(data);
            }
        } else {
            if (messageDataActive.find((m: any) => m.id === message.id)) {
                let data = messageDataActive.filter(
                    (m: any) => m.id !== message.id
                );
                data.splice(0, 0, message);
                setMessageDataActive(data);
            }
        }
    };

    const archiveToActiveRefresh = () => {
        setTextCached('');
        setText('');
        setDisplayWithoutTab(false);
        refreshMessagesWithoutSearch();
    };

    const MessageControls = (
        <Grid container>
            <Hidden implementation='js' mdUp>
                <Box my={2}><Typography variant='h6'>Messages</Typography></Box>
                {activeRouteHeadingIcon}
                <Divider variant='fullWidth' />
            </Hidden>
               
            
            <Grid
                item
                container
                direction='row'
                justify='space-between'
                alignItems='center'
                xs={12}
            >
                <Hidden only={displayWithoutTab ? ['xs', 'sm'] : []}>
                    <Button
                        className={classes.newMessageButton}
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={handleModalOpen}
                        classes={{
                            root: topics.length
                                ? ''
                                : classes.startButtonDisabled,
                        }}
                        disabled={!props.accessLevel.platform || props.appData.user.accountant_mode}
                    >
                        New Message
                    </Button>

                    <Hidden smDown>
                        <Button
                            className={classes.refeshButton}
                            color='inherit'
                            startIcon={<RefreshIcon fontSize='large' />}
                            onClick={handleRefreshClick}
                        >
                            Refresh
                        </Button>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color='inherit'
                            onClick={handleRefreshClick}
                        >
                            <RefreshIcon fontSize='default' />
                        </IconButton>
                    </Hidden>
                </Hidden>
            </Grid>
            <Grid item xs={12}>
                <OutlinedInput
                    notched={false}
                    onChange={(event) => setText(event.target.value)}
                    value={text}
                    type='search'
                    fullWidth
                    classes={{
                        root: classes.searchInput,
                    }}
                    placeholder='Search'
                    startAdornment={
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            getMessagesWithSearchText();
                        }
                    }}
                />
            </Grid>
            {!loadingMessages && displayWithoutTab && totalItemsCount > 0 ? (
                <Grid item xs={12}>
                    <Hidden mdUp>
                        <Divider
                            className={classes.dividerStyle}
                            variant='fullWidth'
                        ></Divider>
                    </Hidden>
                    <Typography
                        style={{ marginTop: '16px' }}
                        variant='subtitle1'
                        color='textSecondary'
                    >
                        {totalItemsCount} Search Result
                        {totalItemsCount > 1 ? 's' : ''}
                    </Typography>
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    );

    let { path } = useRouteMatch();

    return (
        <Grid container direction='row' style={{ height: '100%' }}>
            <NewMessageModal
                open={open}
                handleClose={(result: any) => handleModalCLose(result)}
                topics={topics}
            />
            {loading ? (
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                >
                    <Loader />
                </Grid>
            ) : emptyState ? (
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                >
                    {
                        (
                            !accessLevel.platform && 
                            !currentUser.accountant_mode
                        ) ? (
                            <UpgradePlanMessageCenter/>
                        ) : (
                            <Fragment>
                                <UiText
                                    textColor='textSecondary'
                                >
                                    Have questions for your fulfillment representative?
                                    Click below to start a conversation.
                                </UiText>
                                <Button
                                    className={classes.newMessageButton}
                                    variant='contained'
                                    color='primary'
                                    onClick={handleModalOpen}
                                    classes={{
                                        root: topics.length
                                            ? ''
                                            : classes.startButtonDisabled,
                                    }}
                                    disabled={!props.accessLevel.platform || props.appData.user.accountant_mode}
                                >
                                    START CONVERSATION
                                </Button>
                            </Fragment>
                        )
                    }
                </Grid>
            ) : (
                <Fragment>
                    <Grid
                        container
                        item
                        md={5}
                        sm={12}
                        justify='flex-start'
                        alignItems='flex-start'
                        className={classes.content}
                    >
                        <Grid
                            container
                            item
                            alignItems='flex-start'
                            className={classes.listRoot}
                        >
                            {MessageControls}
                            {loadingMessages ? (
                                <Grid
                                    container
                                    direction='column'
                                    justify='center'
                                    alignItems='center'
                                    style={{ flex: 1 }}
                                >
                                    <Loader />
                                </Grid>
                            ) : (
                                <MessageCenterListView
                                    items={messageData}
                                    hasMore={hasMore}
                                    loadMore={getMessagesPage}
                                    personalAccountId={
                                        props.appData.personal_account.id
                                    }
                                    searchResult={displayWithoutTab}
                                    activeData={{
                                        items: messageDataActive,
                                        hasMore: hasMoreActive,
                                    }}
                                    archivedData={{
                                        items: messageDataArchived,
                                        hasMore: hasMoreArchived,
                                    }}
                                    selectedId={selectedId}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Hidden smDown implementation='js'>
                        <Grid
                            sm={1}
                            item
                            container
                            direction='row'
                            justify='center'
                            alignItems='stretch'
                        >
                            <Divider orientation='vertical' flexItem />
                        </Grid>

                        <Grid item sm={6}>
                            <Switch>
                                <Route exact path={path}>
                                    <Grid
                                        container
                                        direction='column'
                                        justify='center'
                                        alignItems='center'
                                        style={{ height: '100%' }}
                                    >
                                        <Typography variant='subtitle1'>
                                            Select a conversation to see the
                                            thread
                                        </Typography>
                                    </Grid>
                                </Route>
                                <Route path={`${path}/:id`} exact>
                                    <MessageThread
                                        selectMessage={selectMessage}
                                        updateMessageList={updateMessageList}
                                        archiveToActiveRefresh={
                                            archiveToActiveRefresh
                                        }
                                    />
                                </Route>
                                <Redirect to="/dashboard" /> 
                            </Switch>
                        </Grid>
                    </Hidden>
                </Fragment>
            )}
        </Grid>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    accessLevel: state.auth.accessLevel
});
export default connect(mapStateToProps, { showInfo })(MessageCenter);
