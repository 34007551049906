import { useEffect, useState } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import { usePlaidLink } from 'react-plaid-link'
import * as Yup from 'yup'
import ErrorDialog from './ErrorDialog'
import {
    AccountBalanceOutlined,
    Add,
    CheckCircleOutline,
    HelpOutlineTwoTone,
} from '@material-ui/icons'

import UiText from '../../common/ui/UiText'
import FormHeaderText from '../../routing/Payroll/FormHeaderText'
import UiDialog from '../../common/ui/UiDialog'
import UiFormField from '../../common/ui/UiFormField'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import { COLORS } from '../../../variables/colors'
import InfoText from '../common/InfoText'
import { AppData, ApplicationStore, CompanyStepInfo } from '../../../models'
import { connect } from 'react-redux'
import {
    addBank,
    verifyBank,
    markStepAsCompleted,
} from '../../../services/apiService'
import Loader from '../../common/Loader'
import { formSubmitErrorHandler } from '../../../services/formService'
import NextActionButton from '../common/NextActionButton'
import { useBankingInfoState } from './provider/BankingInfoProvider'
import UiAlert from '../../common/ui/UiAlert'
import {
    ERROR_MESSAGE,
    QUERY_SUBSTRING,
    REDIRECT_URL,
} from './constants/banking-info.const'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
interface BankingInformationFormProps {
    appData: AppData
    companyStepInfo: CompanyStepInfo
    refreshSteps: () => void
    nextStep: () => void
}

enum MetadataStatus {
    InstitutionNotFound = 'institution_not_found',
}

function BankingInformationForm({
    appData,
    companyStepInfo,
    refreshSteps,
    nextStep,
}: BankingInformationFormProps) {
    const [addingBank, setAddingBank] = useState(false)
    const [verifingBank, setVerifingBank] = useState(false)
    const [completionInProgress, setCompletionInProgress] = useState(false)
    const isBankingStepCompleted =
        companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status
            .bank_account_addition

    const {
        bankInfo,
        setBankInfo,
        loading,
        linkToken,
        publicToken,
        setPublicToken,
        setMetaData,
        tokenError,
        loadToken,
        dialog,
        setDialog,
        isModalOpen,
        setIsModalOpen,
        isOAuthRedirect,
    } = useBankingInfoState()
    const { colorTheme } = useThemeContext()
    
    const onSuccess = (public_token: string, metadata: any) => {
        setPublicToken(public_token)
        setMetaData(metadata)
    }

    const onExit = (err: any, metadata: any) => {
        metadata?.status === MetadataStatus.InstitutionNotFound &&
            setDialog(true)
    }

    const config: any = {
        token: linkToken,
        onSuccess,
        onExit,
    }

    if (isOAuthRedirect) {
        const baseUrl = window.location.origin
        const url = window.location.href
        const queryString = url.split('?')[1]
        const redirectURL = url.includes('localhost')
            ? `${REDIRECT_URL.UAT}/${QUERY_SUBSTRING}${queryString}`
            : `${baseUrl}/${QUERY_SUBSTRING}${queryString}`
        config.receivedRedirectUri = redirectURL
    }

    const isBankAccountVerified = bankInfo?.verification_status === 'verified'
    const { open: openPlaidLink, ready } = usePlaidLink({
        ...config,
    })

    useEffect(() => {
        if (isOAuthRedirect && ready) {
            openPlaidLink()
        }
    }, [ready, openPlaidLink, isOAuthRedirect])

    const addBankAccount = (data: any, formHelpers: FormikHelpers<any>) => {
        setAddingBank(true)
        formSubmitErrorHandler(
            addBank(appData.current_business_id, data).then((val) => {
                setBankInfo(val)
                setAddingBank(false)
                setIsModalOpen(false)
            }),
            () => {
                setAddingBank(false)
            },
            formHelpers.setFieldError
        )
    }

    const verifyBankAccount = (data: any, formHelpers: FormikHelpers<any>) => {
        setVerifingBank(true)
        formSubmitErrorHandler(
            verifyBank(appData.current_business_id, {
                ...data,
                bank_account_uuid: bankInfo?.bank_account_uuid,
            }).then((val) => {
                setVerifingBank(false)

                if (val.verification_status === 'verified') {
                    refreshSteps()
                }
            }),
            () => {
                setVerifingBank(false)
            },
            formHelpers.setFieldError
        )
    }

    const validationSchema = Yup.object({
        routing_number: Yup.string()
            .required(ERROR_MESSAGE.REQUIRED_ROUTING_NUMBER)
            .matches(/[0-9]/g, ERROR_MESSAGE.INVALID_ROUTING_NUMBER)
            .length(9, ERROR_MESSAGE.ROUTING_LENGTH_VALIDATION),
        account_number: Yup.string()
            .required(ERROR_MESSAGE.REQUIRED_ACCOUNT_NUMBER)
            .matches(/[0-9]/g, ERROR_MESSAGE.ENTER_ACCOUNT_NUMBER_VALIDATION),
        account_type: Yup.string().required(
            ERROR_MESSAGE.REQUIRED_ACCOUNT_TYPE
        ),
    })

    const validationSchemaVerify = Yup.object({
        deposit_1: Yup.number()
            .typeError(ERROR_MESSAGE.REQUIRED_VALID_AMOUNT)
            .required(ERROR_MESSAGE.PAYMENT_ONE_REQUIRED),
        deposit_2: Yup.number()
            .typeError(ERROR_MESSAGE.REQUIRED_VALID_AMOUNT)
            .required(ERROR_MESSAGE.PAYMENT_TWO_REQUIRED),
    })

    const markBankStepAsCompleted = () => {
        setCompletionInProgress(true)
        markStepAsCompleted(appData.current_business_id, {
            step: 'bank_account_addition',
        }).then((res) => {
            if (res) {
                setCompletionInProgress(false)
                refreshSteps()
            }
        })
    }

    const Actions = (submit: () => void) => {
        return (
            <Box display="flex">
                <Box mr={2}>
                    <Button
                        variant="outlined"
                        onClick={() => setIsModalOpen(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </Box>
                {addingBank ? (
                    <Loader size={30} />
                ) : (
                    <Button
                        onClick={submit}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                )}
            </Box>
        )
    }

    const nextButtonCallout =
        isBankAccountVerified && !isBankingStepCompleted
            ? markBankStepAsCompleted
            : nextStep
    return isOAuthRedirect && !publicToken ? (
        <></>
    ) : (
        <>
            <Grid
                style={{
                    height: 'calc(100% - 132px)',
                    overflowX: 'hidden',
                    overflowY:
                        loading || companyStepInfo.loadingStepInfo
                            ? 'hidden'
                            : 'auto',
                }}
            >
                <FormHeaderText heading="Banking Information"/>
                <UiText>
                    Once verified, this bank account will be used to send
                    payments to your employees. After adding your account, we
                    will send two verification deposits.
                </UiText>
                {loading || companyStepInfo.loadingStepInfo || loadToken ? (
                    <Loader />
                ) : (
                    <Box mt={6}>
                        {tokenError && (
                            <UiAlert
                                icon={'alert'}
                                severity={'error'}
                                description={
                                    'Internal Server Error, Please try again'
                                }
                            />
                        )}
                        {!tokenError &&
                        !bankInfo?.routing_number &&
                        !loading ? (
                            <Box>
                                <Box my={4}>
                                    <UiText>No bank account added yet</UiText>
                                </Box>
                                <Box mb={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => openPlaidLink()}
                                        startIcon={<Add />}
                                    >
                                        Add Bank Account
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                mb={1}
                                justifyContent="space-between"
                                flexDirection="column"
                            >
                                <Box display="flex">
                                    <Box mr={3}>
                                        <Box
                                            borderRadius={50}
                                            bgcolor={colorTheme.grey200}
                                            width={40}
                                            height={40}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <AccountBalanceOutlined />
                                        </Box>
                                    </Box>
                                    <Box mr={3}>
                                        <UiText variant="motorcycle_90">
                                            {bankInfo?.account_type}
                                        </UiText>
                                        <UiText variant="motorcycle_90">
                                            Account: {bankInfo?.account_number}
                                        </UiText>
                                        <UiText variant="motorcycle_90">
                                            Routing: {bankInfo?.routing_number}
                                        </UiText>
                                        {bankInfo?.verification_status ===
                                        'verified' ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                color={colorTheme.primaryGreen}
                                                mt={1}
                                            >
                                                <Box display="flex" mr={1}>
                                                    <CheckCircleOutline />
                                                </Box>
                                                <UiText
                                                    weight="medium_500"
                                                    variant="motorcycle_90"
                                                >
                                                    Verified
                                                </UiText>
                                            </Box>
                                        ) : (
                                            ''
                                        )}
                                    </Box>
                                </Box>
                                {bankInfo?.verification_status ===
                                'awaiting_deposits' ? (
                                    <Box mt={1}>
                                        <InfoText
                                            heading="Awaiting Verification"
                                            text="We’ll send two verification deposits to the bank account above. Once sent, you will need to enter the deposit values on this screen."
                                        />
                                    </Box>
                                ) : (
                                    ''
                                )}
                                {bankInfo?.verification_status ===
                                'ready_for_verification' ? (
                                    <Box
                                        mt={3}
                                        p={2}
                                        border={`1px solid rgb(18, 23, 36, 0.2)`}
                                        borderRadius={8}
                                        mb={3}
                                    >
                                        <Box mr={3} display="flex">
                                            <Box mr={3}>
                                                <HelpOutlineTwoTone />
                                            </Box>
                                            <UiText
                                                style={{ marginRight: '5px' }}
                                                weight="medium_500"
                                            >
                                                Verify your bank account
                                            </UiText>
                                            <UiText>(Required)</UiText>
                                        </Box>
                                        <Box>
                                            Enter the amounts of the two
                                            verification deposits that were sent
                                            to your bank account. The payment
                                            order does not matter.
                                        </Box>
                                        <Box>
                                            <Formik
                                                initialValues={{}}
                                                onSubmit={verifyBankAccount}
                                                validationSchema={
                                                    validationSchemaVerify
                                                }
                                                enableReinitialize
                                            >
                                                {(formik) => {
                                                    return (
                                                        <Form>
                                                            <Box my={2} mt={4}>
                                                                <UiFormControlSelection
                                                                    type="currency"
                                                                    label={
                                                                        'Payment 1'
                                                                    }
                                                                    showFloatingLabel={
                                                                        true
                                                                    }
                                                                    fieldName="deposit_1"
                                                                />
                                                            </Box>
                                                            <Box my={2} mt={4}>
                                                                <UiFormControlSelection
                                                                    type="currency"
                                                                    label={
                                                                        'Payment 2'
                                                                    }
                                                                    showFloatingLabel={
                                                                        true
                                                                    }
                                                                    fieldName="deposit_2"
                                                                />
                                                            </Box>
                                                            {verifingBank ? (
                                                                <Loader
                                                                    justifyContent="flex-start"
                                                                    size={30}
                                                                />
                                                            ) : (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    type="submit"
                                                                >
                                                                    Verify Bank
                                                                    Account
                                                                </Button>
                                                            )}
                                                        </Form>
                                                    )
                                                }}
                                            </Formik>
                                        </Box>
                                    </Box>
                                ) : (
                                    ''
                                )}
                            </Box>
                        )}
                        <Box my={2} mt={4}>
                            <NextActionButton
                                loading={false || completionInProgress}
                                disabled={!bankInfo}
                                submitAction={nextButtonCallout}
                            />
                        </Box>
                    </Box>
                )}
                {isModalOpen && (
                    <Box>
                        <Formik
                            initialValues={{}}
                            onSubmit={addBankAccount}
                            validationSchema={validationSchema}
                            enableReinitialize
                        >
                            {(formik) => {
                                return (
                                    <UiDialog
                                        open={isModalOpen}
                                        handleClose={() =>
                                            setIsModalOpen(false)
                                        }
                                        title="Add Bank Account"
                                        size="sm"
                                        contentStyles={{ maxHeight: '600px' }}
                                        actions={Actions(formik.submitForm)}
                                    >
                                        <Form>
                                            <UiText>
                                                Once verified, this bank account
                                                will be used to send payments to
                                                your employees. After adding
                                                your account, we will send two
                                                verification deposits.
                                            </UiText>
                                            <Box my={2} mt={4}>
                                                <UiFormField
                                                    fastField={false}
                                                    type="text"
                                                    label={
                                                        'Routing Number (Required)'
                                                    }
                                                    labelSize={0}
                                                    showFloatingLabel={true}
                                                    fieldName="routing_number"
                                                />
                                            </Box>
                                            <Box my={2} mt={4}>
                                                <UiFormField
                                                    fastField={false}
                                                    type="text"
                                                    label={
                                                        'Account Number (Required)'
                                                    }
                                                    labelSize={0}
                                                    showFloatingLabel={true}
                                                    fieldName="account_number"
                                                />
                                            </Box>
                                            <Box my={2} mt={4}>
                                                <Box mb={1}>
                                                    <UiText
                                                        weight="medium_500"
                                                        variant="hatchback_125"
                                                    >
                                                        Account Type
                                                    </UiText>
                                                </Box>
                                                <UiFormControlSelection
                                                    type="radio"
                                                    label="Account Type"
                                                    fieldName="account_type"
                                                    radioOptionsData={[
                                                        {
                                                            label: 'Checking',
                                                            value: 'Checking',
                                                        },
                                                        {
                                                            label: 'Savings',
                                                            value: 'Savings',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        </Form>
                                    </UiDialog>
                                )
                            }}
                        </Formik>
                    </Box>
                )}
            </Grid>
            <div>
                <ErrorDialog
                    openModal={dialog}
                    closeModal={() => setDialog(false)}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
})
export default connect(mapStateToProps)(BankingInformationForm)
