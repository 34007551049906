import { useCallback, useEffect, useState } from 'react'
import { showError } from '../../../../services/formService'
import { getAllVehicleDetails } from '../../../../services/mileage-log/vehicleService'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { TOTAL_VEHICLES_PER_PAGE, Vehicles } from '../models/vehicle.model'

export const useVehicles = (
    isRefreshVehicles: boolean,
    currentPage: number
) => {
    const [isDataLoading, setIsDataLoading] = useState(false)
    const { currentAccountId } = useCurrentStore()
    const [vehiclesDetails, setVehiclesDetails] = useState<Vehicles>()


    const loadAllVehicleDetails = useCallback(() => {
        setIsDataLoading(true)
        getAllVehicleDetails(
            currentAccountId,
            currentPage.toString(),
            TOTAL_VEHICLES_PER_PAGE
        )
            .then((res: Vehicles) => {
                setIsDataLoading(false)
                setVehiclesDetails((prevDetails : Vehicles | undefined) =>{
                    if(prevDetails && currentPage > 1){
                       return {
                            ...res,
                            items: [...prevDetails.items, ...res.items],
                        }
                    }
                        return res;
                })
            })
            .catch((error) => {
                setIsDataLoading(false)
                showError(error?.statusText)
            })
    }, [currentAccountId, currentPage])

    useEffect(() => {
        loadAllVehicleDetails()
    }, [currentAccountId, loadAllVehicleDetails, isRefreshVehicles])
    return {
        vehiclesDetails,
        isDataLoading,
    }
}
