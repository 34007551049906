import React, { useRef, useState } from 'react'
import { 
    GoogleMap,
    StandaloneSearchBox,
    Marker
} from '@react-google-maps/api';
import {
    InputAdornment,
    TextField,
    makeStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
    LocationPayloadAddress
} from './forms/LocationFormUtils';
import { StateCountryProps } from './forms/LocationFormUtils';
import { showError } from '../../../services/formService';

enum ADDRESS_COMPONENTS {
    STREET_NUMBER = 'street_number',
    ROUTE         = 'route',
    LOCALITY      = 'locality',
    STATE         = 'administrative_area_level_1',
    COUNTRY       = 'country',
    ZIP_CODE      = 'postal_code',
    COUNTY        = 'administrative_area_level_2'
}

enum ADDRESS_FIELDS {
    ADDRESS  = 'address.address',
    CITY     = 'address.city',
    STATE    = 'address.state',
    COUNTRY  = 'address.country',
    ZIP_CODE = 'address.zip_code',
    COUNTY   = 'address.county'
}

const locationError = 'Location not found';

const useStyles = makeStyles(() => ({
    container: {
        margin: '.4rem 0rem .4rem 0rem'
    }
}));

const mapContainerStyle = {
  height: "16.563rem",
  width: "100%"
};

const mapContainerStyleEditView = {
    height: "14rem",
    width: "100%"
};

const center = {
  lat: 38.8892686,
  lng: -77.050176
};

enum USA_KEYS {
    UNITED_STATES = 'United States',
    USA           = 'USA'
}

interface Coordinates {
    setLat: React.Dispatch<number>;
    setLng: React.Dispatch<number>;
    isEditview: boolean;
    initialValues: LocationPayloadAddress;
    formikRef: any;
    formOptionsData: StateCountryProps;
}

const Map = ({
    setLat, 
    setLng, 
    isEditview,
    initialValues,
    formikRef,
    formOptionsData
}: Coordinates) => {
    const inputRef: any = useRef()
    const classNames = useStyles();
    const [formattedAddress, setFormattedAddress] = useState('');
    const [markerPosition, setMarkerPosition] = useState(center);

    const onSBLoad = (ref: google.maps.places.SearchBox) => {
        inputRef.current = ref
        setPosition(initialValues.latitude ?? center.lat, initialValues.longitude ?? center.lng);
    };

    function handlePlacesChanged() {
        const [place] = inputRef.current.getPlaces();
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        
        setPosition(lat, lng);
    }

    const onClickMap = React.useCallback(function callback(map) {
        let lat = map.latLng.lat()
        let lng = map.latLng.lng()
        setPosition(lat, lng);
      }, [])
    

    function setPosition(lat: number, lng: number){
        setLat(lat);
        setLng(lng);

        setMarkerPosition({
            lat: lat,
            lng: lng
        })

        if(lat === 0 && lng === 0) return;

        const geocoder = new google.maps.Geocoder();
        geocoder
        .geocode({ 
            location: {
                lat: lat,
                lng: lng
            }
        })
        .then((response) => {
            if (response.results[0]) {
                setFormattedAddress(response.results[0].formatted_address);
                setAddressFieldValues(response.results[0].address_components, lat, lng);
            }
        })
        .catch((e) => {
            showError(locationError)
        });
    }

    function addressComponent(arr: google.maps.GeocoderAddressComponent[], add: string){
        let addressComponentString = '';
        arr.forEach(function(item: google.maps.GeocoderAddressComponent) {
            if(item.types.includes(add)){
                addressComponentString = item.long_name;
            }
        })
        return addressComponentString;
    }

    function setAddressFieldValues(component: google.maps.GeocoderAddressComponent[], lat: number, lng: number){
        const streetNumber = addressComponent(component, ADDRESS_COMPONENTS.STREET_NUMBER);
        const route = addressComponent(component, ADDRESS_COMPONENTS.ROUTE);
        const locality = addressComponent(component, ADDRESS_COMPONENTS.LOCALITY);
        let state: string | null = addressComponent(component, ADDRESS_COMPONENTS.STATE);
        const zipCode = addressComponent(component, ADDRESS_COMPONENTS.ZIP_CODE);
        let country = addressComponent(component, ADDRESS_COMPONENTS.COUNTRY);
        const addressField = streetNumber + ' ' + route;
        const county = addressComponent(component, ADDRESS_COMPONENTS.COUNTY);
        const isFirstLoad = lat === center.lat && lng === center.lng;
        if(country && country === USA_KEYS.UNITED_STATES && state){
            state = getStateAbbr(state);
            country = USA_KEYS.USA
        }
        formikRef?.current?.setFieldValue(ADDRESS_FIELDS.ADDRESS, addressField === ' ' ? '' : addressField)
        formikRef?.current?.setFieldValue(ADDRESS_FIELDS.CITY, locality)
        if(isEditview || !isFirstLoad)
            formikRef?.current?.setFieldValue(ADDRESS_FIELDS.COUNTRY, country ? getCountryAbbr(country) : '')
        formikRef?.current?.setFieldValue(ADDRESS_FIELDS.ZIP_CODE, zipCode)
        formikRef?.current?.setFieldValue(ADDRESS_FIELDS.STATE, state)
        formikRef?.current?.setFieldValue(ADDRESS_FIELDS.COUNTY, county)
    }

    function getCountryAbbr(countryName: string): string | null {
        const country = formOptionsData.country.find(
            (item) => item.name.toLowerCase() === countryName.toLowerCase()
        )

        return country ? country.abbr : null
    }

    function getStateAbbr(stateName: string): string | null {
        const state = formOptionsData.state.find(
            (item) => item.name.toLowerCase() === stateName.toLowerCase()
        )
        return state ? state.abbr : null
    }

    return (
        <>
            <StandaloneSearchBox
                onLoad={onSBLoad}
                onPlacesChanged={handlePlacesChanged}
            >
                    <TextField
                        className={classNames.container}
                        placeholder={'Search Location'}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        size='small'
                        fullWidth
                        value={formattedAddress}
                        onChange={(e) => setFormattedAddress(e.target.value)}
                    />
            </StandaloneSearchBox>
            <GoogleMap
                mapContainerStyle={isEditview ? mapContainerStyleEditView : mapContainerStyle}
                center={markerPosition}
                zoom={15}
                onClick={onClickMap}
            >
                <Marker
                    position={markerPosition}
                />
                    { /* Child components, such as markers, info windows, etc. */ }
            </GoogleMap>
        </>
    );
}

export default Map;