import { Theme, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import UiDialog from "../../../components/common/ui/UiDialog";
import UiText from "../../../components/common/ui/UiText";
import UiButton from "../../../components/common/ui/UiButton";
import { commonStyles, mergeStyle, toRem16 } from "../../../styles/commonStyles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme:Theme) => ({
    dialogContainer: {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "640px",
            [theme.breakpoints.down('xs')]:{
                margin: '1rem'
            }
        },
        "& .MuiDialogContent-root": {
            overflow: "hidden",
            padding: '20px'
        },
    },
    margin32: {
        marginTop: "32px",
    },
    btnMargin: {
        margin: '2rem 0 1.5rem 0'
    },
    buttonStyle: {
        width: '100%',
        maxWidth: `${toRem16(300)} !important`,
        height: `${toRem16(45)} !important`,
    },
}));

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../../assets/animations/Thumsup.json"),
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
}

const SetupConfirmation = ({ open, handleClose }: any) => {
    const styles = commonStyles();
    const history = useHistory();
    const classes = useStyles();
    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            hideTitleSection={true}
            size="sm"
            customRootClass={classes.dialogContainer}
        >
            <div data-cy="payroll-setup-confirmation">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <div></div>
                    <Lottie options={defaultOptions} height={80} width={80} />
                    <div>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <div className={styles.textCenter}>
                    <div className={classes.margin32}>
                        <UiText variant="truck_175" weight="bold_700">
                            Payroll Setup Complete!
                        </UiText>
                    </div>
                    <UiText variant="hatchback_125" className={mergeStyle(styles.mt16, styles.mb16)}>
                        You can now finish setting up the employee records for each listed business owner.
                    </UiText>
                    <div className={classes.btnMargin} >
                        <UiButton
                            btnType="tertiary"
                            handleClick={() => {
                                history.push("/payroll/employees");
                            }}
                            customLabel={<UiText weight="semi_bold_600">Manage Employees</UiText>}
                            data-cy="view-emp-btn"
                            customClass={classes.buttonStyle}
                        />
                        <div className={styles.mt16}>
                            <UiButton
                                btnType="secondary"
                                handleClick={handleClose}
                                customLabel={<UiText weight="semi_bold_600">Close</UiText>}
                                data-cy="view-emp-btn"
                                customClass={classes.buttonStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </UiDialog>
    );
};

export default SetupConfirmation;
