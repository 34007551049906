import React, { Fragment, useMemo } from 'react';
import UiDialog from '../../common/ui/UiDialog'
import {
    makeStyles,
    Box,
    Theme,
    useMediaQuery,
    useTheme,
    Grid,
    GridSize,
    Button,
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ProductCategory } from '../../../models'
import UiFormControlSelection from '../../common/ui/UiFormControlSelection'
import Loader from '../../common/Loader'
import { createCategoryAction } from './actions'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'

const categoryFormMapping = [
    {
        key: 'title',
        type: 'text',
        placeholder: 'Name',
        md: 6,
        xs: 12,
        label: 'Product Group',
        my: 2,
    },
    {
        key: 'hasParent',
        type: 'checkbox',
        inlineLabel: 'Is Sub Category',
        md: 6,
        xs: 12,
        my: 1,
    },
    {
        key: 'parent',
        type: 'select',
        placeholder: '',
        md: 6,
        xs: 12,
        my: 1,
    },
]

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    root: {
        marginTop: '12px',
    },
    customPaper: {
        height: '100%',
        width: '100%',
        margin: '0px',
        maxHeight: '100%',
    },
    footerAction: {
        right: '0',
        width: '100%',
        bottom: '0',
        display: 'flex',
        padding: '0.5rem',
        position: 'absolute',
        background: (colorTheme) => colorTheme.primaryWhite,
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        'justify-content': 'flex-end',
        'z-index': 1,
        height: '45px'
    },
    cancelButton: {
        marginRight: theme.spacing(2),
    },
}))

interface CategoryModalProps {
    open: boolean
    handleClose: () => void
    productCategories: ProductCategory[] | undefined
    setFetchCategory: React.Dispatch<boolean>
    categoryCallback?: (category?: any) => void
}

const CreateCategoryModal = ({
    open,
    handleClose,
    productCategories,
    setFetchCategory,
    categoryCallback
}: CategoryModalProps) => {
    const { colorTheme } = useThemeContext()
    const classNames = useStyles(colorTheme)
    const theme = useTheme()
    // isMobileDevice
    const parentCategories = useMemo(()=> {
        return productCategories?.filter((category: ProductCategory)=> !category?.parent_title)
    },[productCategories])


    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))

    const onSubmit = (data: any, formActions: any) => {
        let params = {}
        const { title, hasParent, parent } = data
        params = { title, hasParent }
        if (hasParent) {
            params = { parent, ...params }
        }
        createCategoryAction(params, (category) => {
            categoryCallback?.(category)
            handleClose()
            setFetchCategory(true)
        }, formActions)
    }

    const initialValues = {
        title: '',
        parent: '',
        hasParent: false,
    }
    const validationSchema = Yup.object({
        title: Yup.string().required('Category Name is required'),
        hasParent: Yup.boolean(),
        parent: Yup.string().when('hasParent', {
            is: true,
            then: Yup.string().required('Must Select a Parent Category'),
        }),
    })

    const renderFormField = (property: any) => (
        <>
            <Box my={property.my}>{property?.label} </Box>
            <UiFormControlSelection
                placeholder={property?.placeholder}
                type={property.type}
                fieldName={property.key}
                required={property?.required}
                errorMessage={property?.errorMessage}
                {...(property.type === 'checkbox'
                    ? {
                          inlineLable: property.inlineLabel,
                      }
                    : {})}
                {...(property.type === 'select'
                    ? {
                          optionsData: property.key === 'parent' ? parentCategories : productCategories,
                          optionKey: 'title',
                          optionValue: 'id',
                      }
                    : {})}
            />
        </>
    )
    return (
        <Fragment>
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={`Create Category`}
                size="sm"
                hideTitleSection={isXsDown}
                customDialogStyles={
                    isXsDown ? { paper: classNames.customPaper } : {}
                }
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({
                        submitForm,
                        touched,
                        isValid,
                        isSubmitting,
                        values,
                    }) => {
                        return (
                            <Form>
                                <div style={{ marginBottom: '60px' }}>
                                    {categoryFormMapping.map(
                                        (property: any, index: number) => (
                                            <Fragment key={property.key}>
                                                <Grid container spacing={1}>
                                                    <Grid
                                                        key={property?.label}
                                                        item
                                                        xs={
                                                            property?.xs as GridSize
                                                        }
                                                        md={
                                                            property?.md as GridSize
                                                        }
                                                    >
                                                        {index !== 2 &&
                                                            renderFormField(
                                                                property
                                                            )}
                                                        {index === 2 &&
                                                            values.hasParent &&
                                                            renderFormField(
                                                                property
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        )
                                    )}
                                </div>
                                <div className={classNames.footerAction}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid justify="flex-end" container>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={handleClose}
                                                className={
                                                    classNames.cancelButton
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={submitForm}
                                            >
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    )}
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </UiDialog>
        </Fragment>
    )
}

export default CreateCategoryModal