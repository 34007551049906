import BankingPage from './banking-plaid/BankingPage'
import Banking from './Banking'
import { useBankDetailsState } from './provider/BankDetailsProvider'
import Loader from '../../common/Loader'
import { useEffect } from 'react'

const BankingInfoPage = () => {
    const { isFincity, loading: loadBankingProviderStatus, loadBankAccounts } = useBankDetailsState()
    useEffect(() => {
        loadBankAccounts(true)
    }, [])
    return (
        <>
            {loadBankingProviderStatus ? (
                <Loader />
            ) : isFincity ? (
                <Banking />
            ) : (
                <BankingPage />
            )}
        </>
    )
}

export default BankingInfoPage
