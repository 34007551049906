import React, { Fragment } from 'react';
import { Route, Switch} from 'react-router-dom'
import { Hidden, Container, makeStyles, Theme  } from '@material-ui/core';
import MessageCenter from '../message_center/MessageCenter';
import MessageThread from '../message_center/MessageThread';

export const MessageCenterRoutes: React.FC = () => {
  const classes = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
        },
    };
})();
  return (
    <Switch>
      <Fragment>
          <Hidden mdUp>
              <Route path='/message_center' exact>
                  <Container
                      className={classes.container}
                      maxWidth='lg'
                  >
                      <MessageCenter />
                  </Container>
              </Route>
              <Route path={`/message_center/:id`} exact>
                  <Container
                      className={classes.container}
                      maxWidth='lg'
                  >
                      <MessageThread />
                  </Container>
              </Route>
          </Hidden>
          <Hidden smDown>
              <Route path='/message_center'>
                  <Container
                      className={classes.container}
                      maxWidth='lg'
                  >
                      <MessageCenter />
                  </Container>
              </Route>
          </Hidden>
      </Fragment>          
    </Switch>
  )
}

