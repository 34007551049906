import React from 'react';
import EmptyView from './EmptyView';
import NoActivity from '../../../assets/images/NoActivity.svg'

export const NoActivityView = () => {
    return (
            <EmptyView 
                title="There aren’t any updates here yet."
                subtitle="Get timely updates on IRS news, industry trends, and new financial reports."
                image={NoActivity}
            />
    )
}

