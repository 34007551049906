import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    makeStyles,
} from '@material-ui/core'
import mediumLogo from '../../assets/logo/mediumLogo.svg'
import BackToBackPeople from '../../assets/images/BackToBackPeople.svg'
import SaveBusinessTaxImage from '../../assets/images/SaveTaxesImage.svg'
import circularCheck from '../../assets/icons-svg/check-circle-xsmall.svg'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import UiText from '../common/ui/UiText'
import { toRem16 } from '../../styles/commonStyles'
import { COLORS } from '../../variables/colors'
import { ContractorForm } from './ContractorForm'
import { CentereAligned, Container, InlineFlex } from './Wrapper'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import Icon from '../common/Icon'
import { logout } from '../../services/authService'
import { useHistory } from 'react-router-dom'
import {
    getLimitedAccessData,
    updateLimitedAccesData,
} from './apiServices/contractor.api'
import { Contractor } from './models/contractor.model'
import Loader from '../common/Loader'
import { useDispatch } from 'react-redux'
import { loadConfig } from '../../store/actions/config'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import { ERRORS } from './constants/contractor.const'
import * as Yup from 'yup'
import { FormObserver } from '../bookkeeping/reports/utils/Form/FormObserver'
import { getPartnerLogo, updateThemeAttribute } from '../common/whiteLabel/updateThemeAttibutes'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        appBarTitle: {
            flexGrow: 1,
        },
        avatarButton: {
            backgroundColor: (colorTheme) => `${colorTheme.primaryWhite}`,
            height: toRem16(40),
            border: (colorTheme) => `${toRem16(1)} solid ${colorTheme.test4}`,
            textTransform: 'none',
        },
        avatarButtonIcon: {
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${toRem16(2)} 0 ${toRem16(8)}`,
        },
        listItemIconRoot: {
            minWidth: toRem16(44),
        },
        scroll: {
            width: '100%',
            height: '100vh',
            overflowY: 'scroll',
        },
        mainImage: {
            marginBottom: toRem16(48),
        },
        textCustomStyle: {
            marginBottom: toRem16(16),
        },
        block: {
            display: 'block',
            minHeight: toRem16(160),
        },
        footer: {
            width: '100%',
            position: 'sticky',
            bottom: 0,
            left: 0,
            borderTop: (colorTheme) =>
                `${toRem16(1)} solid ${colorTheme.grey200}`,
            padding: toRem16(16),
            backgroundColor: (colorTheme) => `${colorTheme.primaryWhite}`,
            zIndex: 1,
        },
        footerAction: {
            maxWidth: toRem16(596),
            margin: '0 auto',
        },
        callContainer: {
            border: `1px solid ${COLORS.grey200}`,
            borderRadius: `${toRem16(8)}`,
            textAlign: 'center',
            margin: `${toRem16(48)} 0`,
        },
        textContainer: {
            padding: '2rem',
        },
        textGap: {
            marginBottom: toRem16(16),
        },
        taxSavingImageStyle: {
            maxWidth: '100%',
            borderRadius: `${toRem16(8)} ${toRem16(8)} 0 0`,
        },
        centerText: {
            textAlign: 'center',
            marginBottom: toRem16(16),
        },
        container: {
            width: '100%',
            '& .MuiTypography-body1':{
             fontFamily: 'Poppins',
            },
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: (colorTheme) => `${colorTheme.secondary}`,
            },
        },
        startFree: {
            backgroundColor: (colorTheme) => `${colorTheme.teal200}`,
            textAlign: 'center',
            borderRadius: toRem16(4),
            padding: `${toRem16(2)} ${toRem16(8)}`,
            width: toRem16(100),
            margin: `${toRem16(8)} auto`,
        },
        actionBtn: {
            background: (colorTheme) => colorTheme.primary,
            color: (colorTheme) => colorTheme.primaryWhite,
            padding: `${toRem16(8)} 1rem`,
            '&:hover': {
                background: (colorTheme) => colorTheme.primary,
                color: (colorTheme) => colorTheme.primaryWhite,
            },
            textTransform: 'none',
        },
        btnText: {
            textTransform: 'none',
        },
    }
})

function toNameFormate(name: string): string {
    return name.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
        return match.toUpperCase()
    })
}

export const validationSchema = Yup.object({
    first_name: Yup.string().required(ERRORS.FIRST_NAME),
    last_name: Yup.string().required(ERRORS.LAST_NAME),
    email: Yup.string()
        .email(ERRORS.VALID_EMAIL)
        .max(255)
        .required(ERRORS.EMAIL),
    phone: Yup.string().required(ERRORS.PHONE),
    type: Yup.string().required(ERRORS.TYPE),
    business_name: Yup.string().when('type', {
        is: 'business',
        then: Yup.string().required(ERRORS.BUSSINESS),
        otherwise: Yup.string().optional(),
      }),
    contractor_w_nine: Yup.array().of(
        Yup.object().shape({
            year: Yup.string().required(ERRORS.TAX),
        })
    ),
    tax_identification_type: Yup.string().required(ERRORS.TYPE),
    tax_identification_number: Yup.string().required(ERRORS.IDENTIFICATION),
    street: Yup.string().required(ERRORS.STREET),
    city: Yup.string().required(ERRORS.CITY),
    state: Yup.string().required(ERRORS.STATE),
    zip: Yup.string().required(ERRORS.ZIPCODE),
})

const contractorIndex = 0
const businessIndex = 1

export const ContractorLimitedPage = () => {
    const { colorTheme, updateColorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const [reviewInfo, setReviewInfo] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [scheduleCall, setScheduleCall] = useState<boolean>(false)
    const [avatarEl, setAvatarEl] = useState<Element | null>(null)
    const [contractorInfo, setContractorInfo] = useState<any>({})
    const [ownerBusiness, setOwnerBusiness] = useState<any>({})
    const [apiLoader, setApiLoader] = useState<boolean>(false)
    const formikRef = useRef<FormikProps<FormikValues>>(null)
    const [logoPath, setLogoPath] = useState(mediumLogo)
    const [formObserverValue, setFormObserverValue] = useState<any>({})
    let submitData: any
    const dispatch = useDispatch()
    const history = useHistory()

    const handleLogout = () => {
        logout()
        setAvatarEl(null)
    }

    const onformChangeCallback = (values: any) => {
        setFormObserverValue(values)
    }

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadConfig())
        getLimitedAccessData()
            .then((data: any) => {
                setIsLoading(false)
                setContractorInfo(data[contractorIndex])
                setOwnerBusiness(data[businessIndex])
                const partnerDetails = data[businessIndex]?.ownerPartnerDetails
                if(partnerDetails?.custom_style_info){
                    updateColorTheme(partnerDetails?.custom_style_info)
                    updateThemeAttribute(partnerDetails)
                    const partnerLogoUrl = getPartnerLogo(partnerDetails.partner_key ?? '');
                    setLogoPath(partnerLogoUrl);
                }
            })
            .catch((err) => {
                setIsLoading(false)
            })
    }, [])

    const goToScheduleCallPage = () => {
        setReviewInfo(false)
        setScheduleCall(true)
    }

    const handleUpdateContractor = (values: Contractor) => {
        setApiLoader(true)
        updateLimitedAccesData({
            ...values,
            tax_identification_number:
                `${values.tax_identification_number}`.replaceAll('-', ''),
        })
            .then(() => {
                setApiLoader(false)
                goToScheduleCallPage()
            })
            .catch((err) => {
                setApiLoader(false)
            })
    }

    const handleGoToDashboard = () => {
        history.push('/dashboard')
    }
    if (isLoading) {
        return <Loader />
    }
    return (
        <div className={classes.container}>
            <div>
                <Toolbar>
                    <div className={classes.appBarTitle}>
                        <img src={logoPath} alt="logo" />
                    </div>

                    <Button
                        variant="outlined"
                        className={classes.avatarButton}
                        onClick={(e) => {
                            setAvatarEl(e.currentTarget)
                        }}
                        data-cy="open-user-popover-btn"
                    >
                        <UiText>
                            {contractorInfo?.first_name}{' '}
                            {contractorInfo?.last_name}
                        </UiText>
                        <div className={classes.avatarButtonIcon}>
                            <img
                                alt={`User Icon`}
                                src={
                                    require(`../../assets/icons-svg/Profile.svg`)
                                        .default
                                }
                            />
                        </div>
                        {!!avatarEl ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </Button>
                </Toolbar>
                <Menu
                    open={!!avatarEl}
                    anchorEl={avatarEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    onClose={(e) => {
                        setAvatarEl(null)
                    }}
                    MenuListProps={{ disablePadding: true }}
                    elevation={4}
                >
                    <Divider />
                    <MenuItem
                        onClick={(e) => {
                            handleLogout()
                        }}
                        data-cy="sign-out-btn"
                    >
                        <ListItemIcon
                            classes={{ root: classes.listItemIconRoot }}
                        >
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </MenuItem>
                </Menu>
            </div>
            <CentereAligned>
                <div className={classes.scroll}>
                    {scheduleCall ? (
                        <Container
                            maxwidth={toRem16(480)}
                            padding={toRem16(16)}
                        >
                            <Container
                                padding={toRem16(16)}
                                backgroundcolor={COLORS.green1000}
                                borderradius={toRem16(4)}
                                width="68%"
                            >
                                <InlineFlex
                                    gap={toRem16(12)}
                                    marginbottom={toRem16(0)}
                                >
                                    <img
                                        src={circularCheck}
                                        alt="circular check icon"
                                    />
                                    <UiText variant="motorcycle_90">
                                        Your information was successfully saved
                                    </UiText>
                                </InlineFlex>
                            </Container>
                            <div className={classes.callContainer}>
                                <img
                                    src={SaveBusinessTaxImage}
                                    alt="tax-advisory"
                                    className={classes.taxSavingImageStyle}
                                />
                                <div className={classes.textContainer}>
                                    <UiText
                                        variant="suv_150"
                                        weight="semi_bold_600"
                                        className={classes.textGap}
                                    >
                                        Find out exactly how much you could save
                                        on business taxes
                                    </UiText>
                                    <UiText className={classes.textGap}>
                                        Discuss your unique business needs with
                                        a small business tax expert in a free
                                        30-minute business tax consultation.
                                    </UiText>
                                    <Button
                                        onClick={() => {
                                            window.open(
                                                `${window.location.origin}/sfapi/schedule/index.php?WID=${contractorInfo?.sf_lead_id}&EO=1&TID=1on1&EmailCampaignID=1099-contractor&APSBJ=1099-contractor`,
                                                '_blank'
                                            )
                                        }}
                                        startIcon={
                                            <Icon
                                                icon="calendar"
                                                svgColor={COLORS.primaryWhite}
                                            />
                                        }
                                        className={classes.actionBtn}
                                    >
                                        <UiText weight="medium_500">
                                            Schedule my Free Call
                                        </UiText>
                                    </Button>
                                </div>
                            </div>
                            <Container maxwidth={toRem16(304)}>
                                <div className={classes.startFree}>
                                    <UiText
                                        variant="motorcycle_90"
                                        weight="bold_700"
                                    >
                                        Start For Free
                                    </UiText>
                                </div>
                                <InlineFlex
                                    gap={toRem16(10)}
                                    marginbottom={toRem16(10)}
                                >
                                    <UiText
                                        variant="hatchback_125"
                                        weight="semi_bold_600"
                                        className={classes.centerText}
                                    >
                                        Save hundreds of hours managing your
                                        business
                                    </UiText>
                                </InlineFlex>
                                <InlineFlex
                                    gap={toRem16(10)}
                                    marginbottom={toRem16(10)}
                                >
                                    <img
                                        src={circularCheck}
                                        alt="circular check icon"
                                    />
                                    <UiText variant="motorcycle_90">
                                        Business Transaction Tracking
                                    </UiText>
                                </InlineFlex>
                                <InlineFlex
                                    gap={toRem16(10)}
                                    marginbottom={toRem16(10)}
                                >
                                    <img
                                        src={circularCheck}
                                        alt="circular check icon"
                                    />
                                    <UiText variant="motorcycle_90">
                                        Financial Reporting & Dashboards
                                    </UiText>
                                </InlineFlex>
                                <InlineFlex
                                    gap={toRem16(10)}
                                    marginbottom={toRem16(16)}
                                >
                                    <img
                                        src={circularCheck}
                                        alt="circular check icon"
                                    />
                                    <UiText variant="motorcycle_90">
                                        Trip & Mileage Management, & More!
                                    </UiText>
                                </InlineFlex>
                                <CentereAligned>
                                    <Button
                                        onClick={handleGoToDashboard}
                                        variant="outlined"
                                        className={classes.btnText}
                                    >
                                        <UiText weight="medium_500">
                                            Go To My Dashboard
                                        </UiText>
                                    </Button>
                                </CentereAligned>
                                <div className={classes.block} />
                            </Container>
                        </Container>
                    ) : (
                        <Container
                            maxwidth={toRem16(560)}
                            padding={toRem16(16)}
                        >
                            <CentereAligned>
                                <img
                                    src={BackToBackPeople}
                                    alt="back to back"
                                    className={classes.mainImage}
                                />
                            </CentereAligned>
                            <UiText
                                className={classes.textCustomStyle}
                                variant="suv_150"
                            >
                                👋 Hello{' '}
                                {toNameFormate(
                                    `${contractorInfo?.first_name} ${contractorInfo?.last_name}`
                                )}
                            </UiText>
                            <UiText className={classes.textCustomStyle}>
                                Welcome to 1-800Accountant!
                            </UiText>
                            <UiText className={classes.textCustomStyle}>
                                {`In order to get paid and process your Form
                                1099-NEC, ${
                                    ownerBusiness?.ownerBusinessName ?? ''
                                }  needs you to provide
                                some information.`}
                            </UiText>
                            {reviewInfo ? (
                                <Formik
                                    initialValues={{
                                        ...contractorInfo,
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={handleUpdateContractor}
                                    validateOnMount={true}
                                    validationSchema={validationSchema}
                                >
                                    {({
                                        handleSubmit,
                                        setFieldValue,
                                        values: formValues,
                                        setTouched,
                                        errors,
                                    }) => {
                                        submitData = handleSubmit
                                        return (
                                            <Form>
                                                <FormObserver
                                                    onformChange={
                                                        onformChangeCallback
                                                    }
                                                />
                                                <ContractorForm
                                                    formikRef={formikRef}
                                                    values={formValues}
                                                    hideTaxYear
                                                    isALlFieldsRequired
                                                    formObserverValue={formObserverValue}
                                                    isEditContarctor={!!contractorInfo.email}
                                                    setFieldValue={setFieldValue}
                                                />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.actionBtn}
                                    onClick={() => setReviewInfo(true)}
                                >
                                    <UiText weight="medium_500">
                                        Review my Information
                                    </UiText>
                                </Button>
                            )}
                            <div className={classes.block} />
                        </Container>
                    )}
                </div>
            </CentereAligned>
            {reviewInfo && (
                <div className={classes.footer}>
                    <div className={classes.footerAction}>
                        <Container maxwidth={toRem16(596)}>
                            {apiLoader ? (
                                <Loader />
                            ) : (
                                <Button

                                    className={classes.actionBtn}
                                    onClick={(e) => submitData(e)}
                                >
                                    <UiText weight="medium_500">
                                        Save Information
                                    </UiText>
                                </Button>
                            )}
                        </Container>
                    </div>
                </div>
            )}
        </div>
    )
}
