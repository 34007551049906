import React from 'react'
import UiText from '../../common/ui/UiText'
import { Button, Grid, makeStyles, Theme } from '@material-ui/core'
import { toRem16 } from '../../../styles/commonStyles'
import Icon from '../../common/Icon'
import moment from 'moment'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import Loader from '../../common/Loader'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    rescheduleBtn: {
        color: colorTheme => colorTheme.blue200,
    },
    dateStyle: {
        marginLeft: toRem16(10),
        marginRight: toRem16(10),
        display: 'flex',
        alignItems: 'center',
    },
    detailsContainer: {
        display: 'flex',
        marginTop: toRem16(12),
    },
    headerText: {
        paddingLeft: toRem16(8),
        marginTop: toRem16(12),
        marginBottom: toRem16(8),
    },
    callTimeStyle: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            marginTop: '2%',
        },
    },
    listLoader: {
        padding: `1rem 0 1rem 0`,
        height: toRem16(20)
    }
}))

const formatDate = (date: number) => {
    return (
        <>
            {moment(date * 1000).format('MMMM DD YYYY') +
                ' • ' +
                moment(date * 1000).format('hh:mm A')}
        </>
    )
}

export const AppointmentDetails = ({
    meetingStartDate,
    reschedule,
}: {
    meetingStartDate?: number
    reschedule?: () => void
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { isMobileDevice } = useDeviceSize()
    return (
        <div className={isMobileDevice ? '' : styles.detailsContainer}>
            <div className={styles.callTimeStyle}>
                <Icon icon="calendar" />
                <div className={styles.dateStyle}>
                    <UiText>{formatDate(meetingStartDate as number)}</UiText>
                </div>
            </div>
            <Button
                variant="text"
                className={styles.rescheduleBtn}
                onClick={() => {
                    reschedule?.()
                }}
            >
                Reschedule
            </Button>
        </div>
    )
}

export const Header = ({ text }: { text: string }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return <UiText className={styles.headerText}>{text}</UiText>
}

export const ListLoader = ()=> {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={styles.listLoader}
        >
            <Loader />
        </Grid>
    )
}