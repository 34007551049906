import React, {useState} from 'react'
import { dismissBlock } from '../AccountSetupItems/AccountSetupUtility'
import { MYLO_AD_BLOCK } from '../TodoSection/Todo.const'
import AdvertisementCard from './AdvertisementCard'
import MyloAdImage from '../../../assets/images/MyloAdImage.png'
import MyloAdModal from '../MyloAdModal'

const MyloAd = ({
    reload,
}: {
    reload: () => void
}) => {
    const [openMyloAdModal, setOpenMyloAdModal] = useState(false)
    return (
        <>
            <AdvertisementCard
                title="Get Business Insurance with the Best Coverage + Price!"
                subtitle="Shield your business from surprises like lawsuits, damaged property, and lost income that can bankrupt a growing company with help from Mylo."
                buttonText="Start My Quote"
                cardImage={MyloAdImage}
                onDismissClick={() => {
                    reload()
                    dismissBlock(MYLO_AD_BLOCK)
                }}
                buttonClick={() => {
                    setOpenMyloAdModal(true)
                }}
            />
            {openMyloAdModal ? (
                <MyloAdModal
                    open={openMyloAdModal}
                    handleClose={() => setOpenMyloAdModal(false)}
                />
            ) : (
                ''
            )}
        </>
        
    )
}

export default MyloAd