import { useContext, useEffect, useState } from 'react'
import { hasPermission } from '../../../services/aclService'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import GettingStarted from '../../adp-payroll/company/GettingStarted'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import SetupCompany from './SetupCompany'
import Loader from '../../common/Loader'
import { useADPCompanyContext } from '../provider/CompanyProvider'

const Company = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { setActiveRouteHeading, setActiveRouteHeadingIcon } =
        useContext(ActiveRoutingContext)
    const { appData } = useCurrentStore()
    const { isPurchaseDone } = useADPCompanyContext()

    useEffect(() => {
        setActiveRouteHeading('Company Details')
        setActiveRouteHeadingIcon('')
    }, [setActiveRouteHeading, setActiveRouteHeadingIcon])

    if (isLoading) {
        return <Loader />
    }

    return hasPermission('PAYROLL', appData.current_account) ||
        isPurchaseDone ? (
        <SetupCompany />
    ) : (
        <GettingStarted setIsLoading={setIsLoading} />
    )
}

export default Company
