import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { Typography, Button, makeStyles, Theme } from '@material-ui/core'
import UiConfirmationDialog from '../../common/ui/UiConfirmationDialog'
import arrowUnpublish from '../../../assets/icons-svg/arrowUnpublish.svg'
import { updateReconciliation } from '../../../services/apiService/'
import { showAlert } from '../../../store/actions/feedback'
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

/**
 * TODO - Add reconciliation API call here if reload is happen in reconciliation detail
 *  - for reload in mobile device in reconciliation details page the unpublish option is not there
 */
const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    deleteModal: {
        '& div:first-child': {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.5rem 0rem',
        },
        '& div': {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: (colorTheme) => colorTheme.black100,
            padding: '0.3rem 0rem',
        },
    },
}))

const UnpublishDialog = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const unpublishReconciliation = () => {
        setLoading(true)
        let params = {
            id: props.reconciliationId,
            is_submitted: false,
        }

        updateReconciliation(
            props.accountId,
            props.businessId,
            props.reconciliationId,
            params
        )
            .then((res) => {
                if (res) {
                    setLoading(false)
                    props?.reloadCurrentState?.()
                    props?.setOpenUnpublishDialog(false)
                    props?.setReconciliation(res)
                    dispatch(
                        showAlert({
                            alertType: 'success',
                            alertText: `Reconciliation has been unpublished successfully`,
                        })
                    )
                }
            })
            .catch((err) => {
                setLoading(false)
                props?.setOpenUnpublishDialog(false)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: `Something went wrong`,
                    })
                )
            })
    }
    return (
        <UiConfirmationDialog
            open={props?.openUnpublishDialog}
            message={
                <>
                    <Typography
                        variant="body1"
                        className={classes.deleteModal}
                        gutterBottom
                    >
                        <div>Unpublish Reconciliation?</div>
                        <div>it will no longer appear in generated report.</div>
                        <div>You can republish it at any time.</div>
                    </Typography>
                </>
            }
            showLoader={loading}
            handleClose={() => {
                props?.setOpenUnpublishDialog(false)
            }}
            confirmNode={
                <Button
                    variant="outlined"
                    startIcon={<img alt={'unpublish'} src={arrowUnpublish} />}
                    onClick={unpublishReconciliation}
                >
                    Unpublish
                </Button>
            }
            cancelButtonText="Cancel"
        />
    )
}

export default UnpublishDialog
