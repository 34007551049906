import React from 'react'
import AdvertisementCard from './AdvertisementCard'
import PayrollAdImage from '../../../assets/images/PayrollAdImage.svg'
import { dismissBlock, GetCurrentUserContactId } from '../AccountSetupItems/AccountSetupUtility'
import { PAYROLL_AD_BLOCK } from '../TodoSection/Todo.const'


const PayrollAd = ({
    reload,
}: {
    reload: () => void
}) => {
    const accountId = GetCurrentUserContactId()
    const meetingLink = `${window.origin}/sfapi/schedule/index.php?WID=${accountId}&EO=1&TID=BNA&EmailCampaignID=payroll-filings-portal-banner-top&APSBJ=portal-payroll-banner-top`

    return (
        <AdvertisementCard
            title="Affordable Payroll for Your Small Business"
            subtitle="Quick and easy payroll run for your small business, ensuring accuracy and compliance."
            buttonText="Schedule a Call to Learn More"
            cardImage={PayrollAdImage}
            onDismissClick={() => {
                reload()
                dismissBlock(PAYROLL_AD_BLOCK)
            }}
            buttonClick={() => {
                window.open(meetingLink, '_blank');
            }}
        />
    )
}

export default PayrollAd
