import { AppointmentEventsState } from '../../models'
import { Action, SET_APPOINTMENT_EVENTS } from '../actions/appointmentEvents'


export default function appData(state: AppointmentEventsState = { loading: true, events: []}, action: Action): any {
    switch (action.type) {
        case SET_APPOINTMENT_EVENTS:
            return action.state
        default:
            return state
    }
}