import { useState, useCallback, useEffect, useContext } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import {
    Button,
    Hidden,
    Container,
    makeStyles,
    Theme,
    Grid,
    Divider,
    Box,
    Typography,
    IconButton,
} from '@material-ui/core'
import { Add, FilterList } from '@material-ui/icons'
import SearchBox from '../../common/SearchBox'
import MenuDropdown from '../../common/MenuDropdown'
import MobileHeader from '../../common/MobileHeader'
import InvoiceStats from './InvoiceStats'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import InvoiceList from './InvoiceList'
import {
    getInvoices,
    getInvoiceStats,
} from '../../../services/apiService/invoice'
import {
    Invoice,
    LoadingStates,
    InvoiceQueryObject,
    PaginatedInvoiceResponse,
    InvoiceStatistics,
} from '../../../models'
import InvoicePreview from '../invoices/InvoicePreview'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import UiText from '../../../components/common/ui/UiText'
import {
    dropdownOptions,
    invoiceTypeMapping,
    defaultQueryObject,
    resetParams,
} from './InvoiceUtil'
import FilterModal from './FilterModal'
import UiChip from '../../../components/common/ui/UiChip'
import { formatFilterParams } from './FilterFormUtils'
import InvoiceFormModal from './Modal/InvoiceFormModal'
import { getFilterChips } from './FilterFormUtils';
import BannerComponent from '../invoices/Banner/BannerComponent';
import Loader from '../../../components/common/Loader';
import MerchantHelpBanner from '../invoices/Banner/MerchantHelpBanner'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { MERCHANT_TYPE_PAYRIX, MERCHANT_TYPE_NEWTEK } from './Banner/MerchantAccount/merchantFormUtils'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
        },
        listContent: {
            paddingBottom: theme.spacing(3),
            width: 'inherit',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up(1601)]: {
                justifyContent: 'flex-start',
            },
        },
        filterButton: {
            height: '40px',
            marginTop: '16px',
            marginLeft: '4px',
        },
        chipMargin: {
            marginTop: '8px',
            marginRight: '4px',
        },
        loaderContainer: {
            width: '100%',
            alignItems: 'center',
        },
    };
});

interface CustomizedState {
    from: string
    paymentStatus: string,
    invoice_period: string
}

const Invoices = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const classes = useStyles()
    const common = commonStyles()
    const { path } = useRouteMatch()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [invoiceType, setInvoiceType] = useState(0)
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
    const location = useLocation()
    const [queryObject, setQueryObject] =
        useState<InvoiceQueryObject>(defaultQueryObject)
    const [chipsToDisplay, setChipsToDisplay] = useState<
        {
            label: string
            key: string
        }[]
    >([])
    const [invoiceData, setInvoiceData] = useState<
        PaginatedInvoiceResponse & LoadingStates
    >({
        items: [],
        page: 1,
        perPage: 25,
        pageCount: 0,
        itemsCount: 0,
        loading: false,
        hasMore: false,
    });
    const [statsLoading, setStatsLoading] = useState(false)
    const [invoiceStats, setInvoiceStats] = useState<InvoiceStatistics>({
        overdueCount: 0,
        totalCount: 0,
        unpaidCount: 0,
        unpaidTotal: '',
    })
    const [listRef, setListRef] = useState<any>()
    const [listHeight, setListHeight] = useState('')

    useEffect(() => {
        if (listRef) {
            setListHeight(`calc(100vh - ${listRef?.offsetHeight + 190}px)`)
        }
    }, [listRef])

    const fetchInvoiceStats = useCallback(() => {
        setStatsLoading(true)
        getInvoiceStats().then((response: any) => {
            setInvoiceStats(response)
            setStatsLoading(false)
        })
    }, [])
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
    const invoicePaymentStatus =
        invoiceTypeMapping[`${dropdownOptions[invoiceType]}`]

    const setLoadingInvoiceData = useCallback(() => {
        setInvoiceData((prev: any) => ({
            ...prev,
            loading: true,
        }))
    }, [])

    const fetchInvoices = useCallback(
        (query: any = {}) => {
            let filteredParams = formatFilterParams(queryObject)
            const params = {
                perPage: 25,
                page: query?.page ? query?.page : 1,
                ...filteredParams,
            }
            getInvoices({ ...params, ...query }).then((response: any) => {
                if (response) {
                    const { itemsCount, page, perPage, pageCount, items } =
                        response
                    setInvoiceData((prev: any) => ({
                        ...prev,
                        items: page === 1 ? items : prev.items.concat(items),
                        page: page,
                        pageCount: pageCount,
                        perPage: perPage,
                        itemsCount: itemsCount,
                        loading: false,
                        hasMore: page < pageCount,
                    }))
                }
            })
        },
        [queryObject]
    );

    const loadMoreInvoices = () => {
        const { page } = invoiceData;
        !loading &&
            fetchInvoices({
                page: page + 1,
            });
    };

    const reloadInvoiceData = useCallback(() => {
        setLoadingInvoiceData()
        fetchInvoiceStats()
        fetchInvoices()
    }, [fetchInvoiceStats, fetchInvoices, setLoadingInvoiceData])

    useEffect(() => {
        setActiveRouteHeading('Invoices')
    }, [setActiveRouteHeading])

    useEffect(() => {
        reloadInvoiceData();
    }, [invoicePaymentStatus, reloadInvoiceData])
    
    useEffect(() => {
        // params are sent from invoice card in dashboard view
        const state = location.state as CustomizedState
        if(state?.from === 'dashboard') {
            setQueryObject((prev)=> ({
                ...prev,
                paymentStatus: state.paymentStatus,
                invoice_period: state.invoice_period
            }))
        }
    },[location.state])

    const { loading, items, hasMore, itemsCount } = invoiceData;

    const onChipDeleteCallout = (chipKey: string) => {
        const filterdChips = chipsToDisplay.filter(
            (e: any) => e.key !== chipKey
        );
        setChipsToDisplay(filterdChips);
        setQueryObject((prev: any) => ({
            ...prev,
            ...resetParams(chipKey),
        }));
        chipKey === 'term' && setSearchTerm('')
    };

    const { currentAccount } = useCurrentStore()
    const isMerchantApproved =
        currentAccount &&
        (currentAccount.is_newtek_merchant_approved ||
        currentAccount.is_payrix_merchant_approved)
        
    let merchantType = null;
    if (isMerchantApproved) {
        merchantType = currentAccount.is_payrix_merchant_approved
            ? MERCHANT_TYPE_PAYRIX
            : MERCHANT_TYPE_NEWTEK
    }

    return (
        <Container className={classes.container} maxWidth="lg">
            <MobileHeader title={'Invoices'} showBottomDivider={true} />
            <InvoiceFormModal
                open={openModal}
                handleClose={() => {
                    setOpenModal(false)
                }}
                isNew={true}
                modalTitle={'New Invoice'}
                reloadPage={reloadInvoiceData}
            />
            <FilterModal
                open={openFilterModal}
                handleClose={() => {
                    setOpenFilterModal(false)
                }}
                queryObject={queryObject}
                setQueryObject={setQueryObject}
                setInvoiceType={setInvoiceType}
                setChipsToDisplay={setChipsToDisplay}
            />
            <Grid container spacing={1}>
                <Grid
                    container
                    item
                    md={5}
                    sm={12}
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.content}
                >
                    <div
                        className={classes.listContent}
                        ref={(ref: any) => setListRef(ref)}
                    >
                        <BannerComponent />
                        {isMerchantApproved && <MerchantHelpBanner merchantType={merchantType}/>}
                        <div
                            className={mergeStyle(
                                common.flex,
                                common.spaceBetween,
                                common.mtb16
                            )}
                        >
                            <div className={classes.loaderContainer}>
                                {statsLoading ? (
                                    <Loader />
                                ) : (
                                    <InvoiceStats invoiceStats={invoiceStats} />
                                )}
                            </div>
                            <Box my={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Add />}
                                    onClick={() => setOpenModal(true)}
                                >
                                    New
                                </Button>
                            </Box>
                        </div>

                        {chipsToDisplay.map((chip: any) => (
                            <UiChip
                                customRootStyles={classes.chipMargin}
                                label={
                                    <Typography variant="body2">
                                        {chip?.label}
                                    </Typography>
                                }
                                onDeleteCallout={() => {
                                    onChipDeleteCallout(chip.key)
                                }}
                            />
                        ))}
                        <div className={common.flex}>
                            <Grid item md={12} xs={12} >
                                <div className={common.mt16}>
                                    <SearchBox
                                        searchTerm={searchTerm}
                                        setInput={(term) => {
                                            setSearchTerm(term)
                                        }}
                                        placeHolder={'Search'}
                                        notched={false}
                                        fullWidth={true}
                                        clearSearch={() => {
                                            onChipDeleteCallout('term')
                                        }}
                                        onKeyPressEvent={(event) => {
                                            if (event.key === 'Enter') {
                                                const chipsToDisplay =
                                                    getFilterChips({
                                                        ...queryObject,
                                                        term: searchTerm,
                                                    })
                                                setChipsToDisplay(chipsToDisplay)
                                                setQueryObject((prev: any) => ({
                                                    ...prev,
                                                    term: searchTerm,
                                                }))
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                            <div>
                                <Hidden smDown>
                                    <Button
                                        startIcon={<FilterList />}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            setOpenFilterModal(true)
                                        }}
                                        classes={{ root: classes.filterButton }}
                                    >
                                        Filters
                                    </Button>
                                </Hidden>
                                <Hidden mdUp>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setOpenFilterModal(true)
                                        }}
                                    >
                                        <FilterList />
                                    </IconButton>
                                </Hidden>
                            </div>
                        </div>
                    </div>
                    <Grid spacing={1} item xs={12} justify="space-between">
                        <div
                            className={mergeStyle(
                                common.mt16,
                                common.mb16,
                                common.flex,
                                common.spaceBetween
                            )}
                        >
                            <UiText> {loading ? 0 : itemsCount} invoices</UiText>
                            <MenuDropdown
                                options={dropdownOptions}
                                selectedIndex={dropdownOptions.findIndex(
                                    (status) =>
                                        status === queryObject?.paymentStatus
                                )}
                                setSelectedIndex={(index: number) => {
                                    setInvoiceType(index);
                                    setQueryObject((prev: any) => ({
                                        ...prev,
                                        paymentStatus: dropdownOptions[index],
                                    }));
                                }}
                                disabled={loading}
                            />
                        </div>
                        <InvoiceList
                            loading={loading}
                            loadMore={loadMoreInvoices}
                            data={items}
                            hasMore={hasMore}
                            selectedInvoice={selectedInvoice}
                            setSelectedInvoice={setSelectedInvoice}
                            listHeight={listHeight}
                        />
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid
                        sm={1}
                        item
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                    >
                        <Divider orientation="vertical" flexItem />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Switch>
                            <Route exact path={path}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="subtitle1">
                                        Select an invoice to view the details
                                    </Typography>
                                </Grid>
                            </Route>
                            <Route exact path={`${path}/:id`}>
                                <InvoicePreview
                                    selectedInvoice={selectedInvoice}
                                    setSelectedInvoice={setSelectedInvoice}
                                    reloadPage={reloadInvoiceData}
                                />
                            </Route>
                        </Switch>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    );
};

export default Invoices;
