import * as Yup from 'yup'
export const BusinessInfoFormSchema = () => {
    return Yup.object({
        doing_business_as: Yup.string(),
        business_name: Yup.string()
            .min(2, 'Business name should be at least 2 characters')
            .required('Business name is required'),
        trade_name: Yup.string(),
        business_type: Yup.string().required('Business type is required'),
        federal_filing_frequency: Yup.string().required(
            'Federal filing frequency is required'
        ),
        deposit_frequency: Yup.string().required(
            'Deposit frequency is required'
        ),
        industry: Yup.string().required('Industry is required'),
        ein: Yup.string().required(
            'Employee Identification Number is required'
        ),
        street: Yup.string().required('Street is required'),
        street_2: Yup.string(),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zip_code: Yup.string().required('Zipcode is required'),
    })
}
