import { Button, makeStyles, Theme } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useThemeContext } from '../whiteLabel/ColorThemeContext';
import UiDialog from './UiDialog';

interface UiErrorInfoDialogProps {
    message: any;
    open: boolean;
    handleClose: (result?: any) => void;
}

export default function UiErrorInfoDialog({
    message,
    open,
    handleClose,
}: UiErrorInfoDialogProps) {
    const { colorTheme } = useThemeContext()
    const classes = makeStyles((theme: Theme) => ({
        dialogContainer: {
            "& .MuiPaper-root": {
                [theme.breakpoints.down('xs')]:{
                    width: "100%",
                    margin: '1rem'
                }
            },
            "& .MuiDialogContent-root": {
                padding: '20px'
            },
        },
        formActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
        },
        bodySection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: 100,
            flexDirection: 'column',
        },
        confirmButton: {
            backgroundColor: `${colorTheme.grey400} !important`,
        },
    }))();
    return (

        <UiDialog
            open={open}
            handleClose={handleClose}
            title=''
            size='xs'
            hideTitleSection={true}
            customRootClass={classes.dialogContainer}
        >
            <Fragment>
                <div className={classes.bodySection}>{message}</div>
                <div className={classes.formActions}>
                    <div>
                        <Button
                            variant='contained'
                            onClick={handleClose}
                            color='primary'
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Fragment>
        </UiDialog>
    );
}
