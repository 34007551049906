import { ApplicationStore } from '../../models';
import { getFromLocalStorage } from '../../services/localStorageService';

export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_TRIAL_EXPIRY = 'SET_TRIAL_EXPIRY';
export const SET_ACCESS_LEVEL = 'SET_ACCESS_LEVEL';

export interface AuthAction {
    type: string;
    state: any;
}

export type Action = AuthAction;

export const setAuthData = (data: any): AuthAction => ({
    type: SET_AUTH_DATA,
    state: data,
});

const setUserRoles = (data: any): AuthAction => ({
    type: SET_USER_ROLES,
    state: data,
});

const setAccessLevel = (data: any): AuthAction => ({
    type: SET_ACCESS_LEVEL,
    state: data,
});

// const setTrialExpiry = (data: any): AuthAction => ({
//     type: SET_TRIAL_EXPIRY,
//     state: data,
// });

export function prepareUserRoles() {
    return (dispatch: any, getState: any) => {
        let { appData, auth }: ApplicationStore = getState();
        var roles: string[] = ['FREE'];
        let accessLevel = { free: true, platform: false, advisory: false };
        if (auth.access_token) {
            if (appData.user) {
                if (
                    appData.current_account.client_tier_permission ===
                        'advisory' ||
                    appData.user.accountant_mode
                ) {
                    roles = roles.concat(['PLATFORM', 'ADVISORY']);
                    accessLevel.platform = true;
                    accessLevel.advisory = true;
                } else if (
                    appData.current_account.client_tier_permission ===
                    'platform'
                ) {
                    roles.push('PLATFORM');
                    accessLevel.platform = true;
                }

                if (appData.user.is_lead) {
                    roles.push('LEAD');
                }
                if (appData.current_business_id) {
                    roles.push('BUSINESS');
                }
                if (appData.user.accountant_mode) {
                    roles.push('ACCOUNTANT');
                    if (getFromLocalStorage('accountant').is_admin) {
                        roles.push('ADMIN');
                    }
                }
            } else {
                roles.push('TRIAL_ENDED');
            }
        } else {
            roles.push('GUEST');
        }
        dispatch(setUserRoles({ roles }));
        dispatch(setAccessLevel({ accessLevel }));
    };
}
