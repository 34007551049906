import { ADPCompanyDetails } from '../../../../models/adp-payroll/company'
import { format, getYear } from 'date-fns'

export const AgreementData = (company: ADPCompanyDetails) => {
    const firstPaymentdate = company.paySchedule.first_payment_date
    const date = new Date(firstPaymentdate as string)
    const year = getYear(date)
    const month = format(date, 'MMM')
    const formattedDate = format(date, 'dd MMM yyyy')
    let agreementData = [
        {
            title: `In order for us to run your payroll, you’ll need to authorize ADP as 
                    ${company.companyDetails.business_name}’s power of attorney. This allows ADP to sign and file 
                    tax returns and make deposits and payments on your company’s behalf. 
                    Note: Once authority has been granted, it will remain in place until 
                    revoked by you or the Reporting Agent (ADP).`,
            field: 'agreement_1',
            label: 'I agree (Required)',
        },
        {
            title: `Do you authorize ADP to sign and file tax returns for ${company.companyDetails.business_name} 
                    from the ${month} of ${year} forward?.`,
            field: 'agreement_2',
            label: 'I agree (Required)',
        },
        {
            title: `Do you authorize ADP to make deposits and payments on behalf of 
                    ${company.companyDetails.business_name} starting in ${formattedDate}?`,
            field: 'agreement_3',
            label: 'I agree (Required)',
        },
        {
            title: `Do you authorize ADP to receive or request duplicate copies of tax 
                    information, notices, and other communications from the IRS, related 
                    to the authorizations granted in the two previous questions?`,
            field: 'agreement_4',
            label: 'I agree (Required)',
        },
        {
            title: `Do you authorize ADP to receive copies of notices from the IRS?`,
            field: 'agreement_5',
            label: 'I agree (Required)',
        },
        {
            title: `Do you authorize ADP to exchange otherwise confidential taxpayer information 
                    with the IRS, including responding to certain IRS notices relating to the Form 
                    W-2/1099 series information returns? This authority is effective for the calendar 
                    year beginning ${formattedDate} of the first payment date 
                    in Roll?`,
            field: 'agreement_6',
            label: 'I agree (Required)',
        },
        {
            title: `Do you appoint ADP as the Reporting Agent and grant ADP a limited power of attorney
                    with the authority to sign and file employment tax returns and make deposits electronically, 
                    on magnetic media, or on paper for all state and local jurisdictions in which the taxpayer 
                    is required to file tax returns and make tax deposits? ADP is also hereby authorized to receive 
                    notices, correspondence, and transcripts from all applicable state and local jurisdictions, 
                    resolve matters pertaining to these deposits and filings, and to request and receive deposit 
                    frequency data and any other information from applicable state and local jurisdictions related 
                    to taxpayer’s employment tax returns and deposits for the tax periods indicated in the previous 
                    questions and all returns filed and deposits made by ADP from the date hereof. This authorization 
                    shall include all applicable state and local forms and shall commence with the tax period indicated 
                    and shall remain in effect through all subsequent periods until either revoked by the taxpayer or 
                    terminated by ADP. Unless the taxpayer is required to file or deposit electronically, ADP will, in 
                    its discretion, file and make deposits on the taxpayer’s behalf using one of the filing methods: 
                    electronic, magnetic media, or paper.`,
            field: 'agreement_7',
            label: 'I agree (Required)',
        },
    ]
    return agreementData
}
