import { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import BusinessSelectSection from './BusinessSelectSection'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { removeFromLocalStorage } from '../../../../services/localStorageService'
import Navbar from './Navbar'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    businessListContainer: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '6.5rem',
        overflowX: 'hidden',
        overFlowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding: '0 1rem',
            marginTop: '1.5rem',
        },
    }
}))

const TOSBusinessOptPage = () => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)

    useEffect(() => {
        const handleBeforeUnload = () => {
            removeFromLocalStorage('adp_tos_redirect')
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            removeFromLocalStorage('adp_tos_redirect')
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    return (
        <div className={classes.container}>
            <Navbar />

            <div className={classes.businessListContainer}>
                <BusinessSelectSection />
            </div>
        </div>
    )
}

export default TOSBusinessOptPage
