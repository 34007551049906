import { Box, Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
    AppData,
    ApplicationStore,
    CompanyStepInfo,
    Config,
} from '../../../models';
import { CompanyAddress } from '../../../models/payroll/company';
import {
    getCompanyAddress,
    updateCompanyAddress,
    createCompanyAddress,
} from '../../../services/apiService';
import { formSubmitErrorHandler } from '../../../services/formService';
import Loader from '../../common/Loader';

import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import UiFormField from '../../common/ui/UiFormField';
import FormHeaderText from './FormHeaderText';
import NextActionButton from './NextActionButton';

interface BusinessAddressFormProps {
    appData: AppData;
    companyStepInfo: CompanyStepInfo;
    refreshSteps: () => void;
    config: Config;
    parentLoading: (loading: boolean) => void;
}
const BusinessAddressForm = forwardRef(
    (
        {
            appData,
            companyStepInfo,
            refreshSteps,
            config,
            parentLoading,
        }: BusinessAddressFormProps,
        ref
    ) => {
        const [initialValues, setInitialValues] = useState<any>({});
        const [loading, setLoading] = useState(true);
        const [submittingForm, setSubmittingForm] = useState(false);
        const companyAddressCreated =
            companyStepInfo.companyOnboardingStepInfo.onboarding_steps_status
                .address_addition;
        const formRef = useRef<any>();
        const [skipNext, setSkipNext] = useState(false);

        useEffect(() => {
            getCompanyAddress(appData.current_business_id)
                .then((data) => {
                    setInitialValues(data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }, []);

        useEffect(() => {
            parentLoading(submittingForm);
            return () => {
                parentLoading(false);
            };
        }, [submittingForm]);

        useImperativeHandle(
            ref,
            () => ({
                isFromDirty() {
                    return formRef.current?.dirty;
                },

                submit() {
                    if (formRef.current.isValid) {
                        setSkipNext(true);
                    }

                    return formRef.current.submitForm;
                },
            }),
            [formRef]
        );

        const onSubmit = (
            data: CompanyAddress,
            formHelpers: FormikHelpers<any>
        ) => {
            setSubmittingForm(true);
            formSubmitErrorHandler(
                (companyAddressCreated
                    ? updateCompanyAddress
                    : createCompanyAddress)(
                    appData.current_business_id,
                    data
                ).then((val) => {
                    if (skipNext) {
                        setSkipNext(false);
                        setInitialValues(val);
                        formHelpers.resetForm(val);
                    } else {
                        refreshSteps();
                    }

                    setSubmittingForm(false);
                }),
                () => {
                    setSubmittingForm(false);
                    setSkipNext(false);
                },
                formHelpers.setFieldError
            );
        };
        const validationSchema = Yup.object({
            street: Yup.string().required('Street is required'),
            street_line_2: Yup.string(),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            zip_code: Yup.string().required('Zipcode is required'),
            phone_number: Yup.string().required('Business Phone is required'),
        });

        return (
            <Grid
                style={{
                    height: 'calc(100% - 108px)',
                    overflowX: 'hidden',
                    overflowY:
                        loading || companyStepInfo.loadingStepInfo
                            ? 'hidden'
                            : 'auto',
                }}
            >
                <FormHeaderText
                    heading='Business Filing Address'
                    formType='(Required)'
                />
                {loading || companyStepInfo.loadingStepInfo ? (
                    <Loader />
                ) : (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                        innerRef={formRef}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <Box my={2} mt={4}>
                                        <UiFormField
                                            fastField={false}
                                            type='text'
                                            labelSize={0}
                                            label={'Street (Required)'}
                                            showFloatingLabel={true}
                                            fieldName='street'
                                        />
                                    </Box>
                                    <Box my={2} mt={4}>
                                        <UiFormField
                                            fastField={false}
                                            type='text'
                                            labelSize={0}
                                            label={'Street Line 2 (Optional)'}
                                            showFloatingLabel={true}
                                            fieldName='street_line_2'
                                        />
                                    </Box>
                                    <Box my={2} mt={4}>
                                        <UiFormField
                                            fastField={false}
                                            type='text'
                                            labelSize={0}
                                            label={'City (Required)'}
                                            showFloatingLabel={true}
                                            fieldName='city'
                                        />
                                    </Box>
                                    <Box my={2} mt={4}>
                                        <UiFormControlSelection
                                            type='select'
                                            optionsData={
                                                config.apiConfig.generic.states
                                            }
                                            optionKey='name'
                                            optionValue='name'
                                            placeholder='State'
                                            label='State'
                                            showFloatingLabel={true}
                                            fieldName='state'
                                        />
                                    </Box>
                                    <Box my={2} mt={4}>
                                        <UiFormControlSelection
                                            type='mask'
                                            label={'Zip Code (Required)'}
                                            showFloatingLabel={true}
                                            fieldName='zip_code'
                                            mask={[
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                            minLength={5}
                                        />
                                    </Box>
                                    <Box my={4}>
                                        <UiFormControlSelection
                                            type='mask'
                                            label={'Business Phone (Required)'}
                                            showFloatingLabel={true}
                                            fieldName='phone_number'
                                            mask={[
                                                '(',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                ')',
                                                ' ',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                '-',
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                                /[0-9]/,
                                            ]}
                                            minLength={10}
                                        />
                                    </Box>
                                    
                                    <Box my={2} mt={2}>
                                        <NextActionButton
                                            loading={submittingForm}
                                            submitAction={formik.submitForm}
                                        />
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Grid>
        );
    }
);

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    companyStepInfo: state.company,
    config: state.config,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
    BusinessAddressForm
);
