import { Button } from '@material-ui/core'
import { useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationStore, Config, Business } from '../../../../models';
import { loadPostAuthData } from '../../../../store/actions/appData';
import Loader from '../../../common/Loader';
import UiSection from '../../../common/ui/UiSection'
import BusinessDetailsEditPopup from './BusinessDetailsEditPopup';
import { useStyles } from '../../shared-styles'
import BusinessSettingView from '../../../settings/BusinessSettingView';

function BusinessDetailsSection({ business, config, loadPostAuthData }: {
    business: Business,
    config: Config;
    loadPostAuthData: any;
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [updatingBusiness, setUpdatingBusiness] = useState(false);
    const handleModalData = (result: any) => {
        if (result) {
            setUpdatingBusiness(true);
            loadPostAuthData()
                .then((res: any) => setUpdatingBusiness(false))
                .catch((err: any) => console.log(err));
        }
        setOpen(false);
    }

    return (
        <UiSection
            sectionHeading={
                <div className={classes.heading}>
                    Business Details
                    <Button variant="text" classes={{ root: classes.editButton }} onClick={() => setOpen(true)}>Edit</Button>
                </div>
            }
            expanded={true}
        >
            {
                updatingBusiness
                    ? <Loader />
                    : <>
                        <BusinessSettingView data={business} onBusinessPage={false} />
                        <BusinessDetailsEditPopup open={open} handleClose={handleModalData} />
                    </>
            }
        </UiSection>
    )
}

const mapStateToProps = ({ appData, config }: ApplicationStore) => ({
    business: appData.current_business,
    config
})

export default connect(mapStateToProps, { loadPostAuthData })(BusinessDetailsSection);