import {
    getResource,
    postResource,
    patchResource,
    deleteResource,
} from '../axiosService';

export function getReconciliations(account_id: string, business_id: string, params: any) {
    return getResource(
        `api/account/${account_id}/business/${business_id}/reconciliation`,
        undefined,
        params
    );
}

export function getReconciliation(account_id: string, business_id: string, reconciliation_id: string){
    return getResource(
        `api/account/${account_id}/business/${business_id}/reconciliation/${reconciliation_id}`,
        undefined
    );
}

export function deleteReconciliationById(account_id: string, business_id: string, reconciliation_id: string){
    return deleteResource(
        `api/account/${account_id}/business/${business_id}/reconciliation/${reconciliation_id}`
    );
}

export function createReconciliation(account_id: string, business_id: string, data: any){
    return postResource(
        `api/account/${account_id}/business/${business_id}/reconciliation`,data
    );
}

export function updateReconciliation(account_id: string, business_id: string, reconciliation_id: string, data: any){
    return patchResource(
        `api/account/${account_id}/business/${business_id}/reconciliation/${reconciliation_id}`,data
    );
}

//Transactions
export function getTransactions(account_id: string, business_id: string, params: any) {
    return getResource(
        `api/account/${account_id}/business/${business_id}/transaction`,
        undefined,
        params
    );
}

//Business Category
export function getBusinessCategories(account_id: string, business_id: string){
    return getResource(
        `api/account/${account_id}/business/${business_id}/account`,
        undefined
    );
}

// Reconciliation save

export function saveReconciliationData(account_id: string, business_id: string, params: any) {
    return patchResource(
        `api/account/${account_id}/business/${business_id}/transaction_reconciliation`,
        params
    );
}
